import { createSelector } from 'reselect';
import identity from 'lodash/identity';
import get from 'lodash/get';
import { RootState } from '../../../stores/types';
import { ExclusionState } from '../types';

const getExclusionState = createSelector<RootState, ExclusionState, ExclusionState>(
  identity,
  (state) => get(state, 'exclusions'),
);

export default getExclusionState;
