import React from 'react';
import { Tabs } from '@flowio/react-tabs';
import { css } from '@emotion/css';
import MarketingCard from '../MarketingCard';
import colors from '../../../../theme/tokens/colors';

export const marketingTabsStyles = css`
 & .marketingTab {
  cursor: pointer;
  min-width: 120px;
  text-align: center;
  height: 30px;
  text-transform: uppercase;
  color: ${colors.marketing['light-slate-gray']};

  &:hover {
    color: ${colors['flow-blue'][400]};
  }
}

 & .marketingPanel {
  padding: 0;
}
`;

interface TabOrPanelObj {
  content: JSX.Element | string;
  eventKey?: string;
}

interface Props {
  tabs: (JSX.Element | TabOrPanelObj)[];
  panels: (JSX.Element | TabOrPanelObj)[];
  defaultActiveKey?: string | number;
  onSelect?: () => void;
}

const MarketingTabs: React.FC<Props> = ({
  tabs,
  panels,
  defaultActiveKey,
}) => {
  const marketingTabs = tabs.map((tab, index) => ({
    eventKey: (tab as TabOrPanelObj).eventKey || index,
    content: (tab as TabOrPanelObj).content || tab,
    className: 'marketingTab',
  }));

  const marketingPanels = panels.map((panel, index) => ({
    eventKey: (panel as TabOrPanelObj).eventKey || index,
    content: <MarketingCard noBorderRadius={['top-left']}>{(panel as TabOrPanelObj).content || panel}</MarketingCard>,
    className: 'marketingPanel',
  }));

  return (
    <Tabs
      className={marketingTabsStyles}
      tabs={marketingTabs}
      panels={marketingPanels}
      defaultActiveKey={defaultActiveKey}
    />
  );
};

MarketingTabs.displayName = 'MarketingTabs';

export default MarketingTabs;
