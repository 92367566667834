import { Box } from '@flowio/react-box';
import React, { PureComponent } from 'react';
import assign from 'lodash/assign';
import noop from 'lodash/noop';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';
import raf from 'raf';
import { ItemHarmonizationStatus } from '@flowio/api-internal-constants';
import { addEvent, removeEvent } from '@flowio/browser-helpers';
import kebabCase from 'lodash/kebabCase';
import {
  Card, CardHeader, CardContent, CardFooter, CardEmptyState, CardTitle,
} from '@flowio/react-card';
import {
  Table,
  TableHead,
  TableRow,
  TableHeadColumn,
  TableBody,
} from '@flowio/react-table';
import { PageContent } from '@flowio/react-page';

import classNames from 'classnames';
import SearchWithScope from '../../../../../components/search-with-scope';
import BackToTopButton from '../../back-to-top-button';
import ExportButton from '../../export-button';
import ExportDialog from '../../export-dialog';
import Hs6TableRow from './hs6-table-row';
import { ClassificationRequestUpdateParams, MergedProps as Props } from '../../overview/types';
import * as styles from './hs6.styles';

interface State {
  isExportDialogOpened: boolean;
  displayBackToTop: boolean;
}

export default class ClassificationHs6 extends PureComponent<Props, State> {
  static displayName = 'ClassificationHs6';

  static defaultProps = {
    scope: '',
    q: '',
    codes: [],
    attributes: [],
    exportSuccessful: false,
    maxRecordsFetched: false,
    onRequestUpdate: noop,
    onRequestExport: noop,
    onRequestNextPage: noop,
    onRequestViewItem: noop,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      isExportDialogOpened: false,
      displayBackToTop: false,
    };
  }

  componentDidMount(): void {
    addEvent(window, 'scroll', this.handleScroll);
  }

  componentWillUnmount(): void {
    if (this.rafId) {
      raf.cancel(this.rafId);
    }
    removeEvent(window, 'scroll', this.handleScroll);
  }

  handleScroll = (): void => {
    if (this.rafId) {
      raf.cancel(this.rafId);
    }
    this.rafId = raf(this.maybeFetchMoreResults);
  };

  handleSearchChange = ({ scope }: { scope: string }): void => {
    const { scope: currentScope } = this.getUrlProps();

    if (currentScope !== scope) {
      this.updateRequest(assign({}, this.getUrlProps(), { scope }));
    }
  };

  handleSearchSubmit = ({ scope, value }: { scope: string; value: string }): void => (
    this.updateRequest(assign({}, this.getUrlProps(), { scope, q: value })));

  handleRequestExport = (): void => {
    this.setState({ isExportDialogOpened: true });
  };

  handleExportDialogClose = (): void => {
    this.setState({ isExportDialogOpened: false });
  };

  handleBackToTop = (): void => {
    window.scrollTo(0, 0);
  };

  getUrlProps(): ClassificationRequestUpdateParams {
    const { scope, q } = this.props;
    return { scope, q };
  }

  rafId: number | null = null;

  static scopes = [
    { key: '', label: 'All Items' },
    { key: 'with_codes', label: 'All Items with HS6' },
    { key: 'without_codes', label: 'All Items without HS6' },
  ];

  maybeFetchMoreResults = (): void => {
    const docRect = document.body.getBoundingClientRect();
    const viewport = document.documentElement.getBoundingClientRect();
    const scrollableHeight = docRect.height - viewport.height;
    const { displayBackToTop } = this.state;
    const { maxRecordsFetched, onRequestNextPage } = this.props;

    if (Math.abs(docRect.top) >= Math.floor(scrollableHeight) && !maxRecordsFetched) {
      onRequestNextPage();
    }

    if (docRect.top < -100 && !displayBackToTop) {
      this.setState({ displayBackToTop: true });
    }

    if (docRect.top > -100 && displayBackToTop) {
      this.setState({ displayBackToTop: false });
    }
  };

  updateRequest(nextProps: ClassificationRequestUpdateParams): void {
    const { onRequestUpdate } = this.props;
    onRequestUpdate(nextProps);
  }

  render(): JSX.Element {
    const {
      scope,
      q,
      codes,
      attributes,
      exportSuccessful,
      maxRecordsFetched,
      onRequestExport,
      onRequestViewItem,
    } = this.props;

    const {
      displayBackToTop,
      isExportDialogOpened,
    } = this.state;

    // create dropdown values from enum
    const newScopes = [{ key: '', label: 'All Statuses' },
      ...Object.values(ItemHarmonizationStatus).map((k) => {
        if (k === 'non_classifiable_not_enough_information') {
          return {
            key: k,
            label: 'Not Enough information',
          };
        }
        return ({
          key: k,
          label: startCase(k),
        });
      })];

    return (
      <PageContent>
        <Card className={styles.filters}>
          <CardHeader dividing>
            <Box alignItems="center" justifyContent="between" className={styles.cardHeaderRow}>
              <CardTitle content="Items" />
              <ExportButton className="export-button" onRequestExport={this.handleRequestExport} />
            </Box>
            <Box itemAuto className={styles.cardHeaderRow}>
              <SearchWithScope
                scopes={newScopes}
                defaultScope={scope}
                value={q}
                onChange={this.handleSearchChange}
                onSubmit={this.handleSearchSubmit}
              />
            </Box>
          </CardHeader>
          {isEmpty(codes) ? (
            <CardEmptyState>
              No matching items.
            </CardEmptyState>
          ) : (
            <CardContent>
              <Table displayDensity="cozy" selectable>
                <TableHead>
                  <TableRow>
                    <TableHeadColumn>Item Number</TableHeadColumn>
                    <TableHeadColumn>Product Id</TableHeadColumn>
                    <TableHeadColumn className={styles.mediumWidth}>
                      Country of Origin
                    </TableHeadColumn>
                    <TableHeadColumn>Item Name</TableHeadColumn>
                    <TableHeadColumn className={styles.mediumWidth}>HS Codes</TableHeadColumn>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {codes && attributes && codes.map<JSX.Element>((summary) => (
                    <Hs6TableRow
                      key={kebabCase([summary.item.number, summary.item.name].join('_'))}
                      summary={summary}
                      onRowClicked={onRequestViewItem}
                    />
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          )}
          <CardFooter />
        </Card>
        {maxRecordsFetched && (
          <section className={styles.maxRecordsFetched}>
            Showing first 250 results only. For all results, you may export the
            data by clicking
            <ExportButton
              className={styles.maxRecordsExportButton}
              onRequestExport={this.handleRequestExport}
            />
          </section>
        )}
        <BackToTopButton
          className={classNames(
            styles.backToTopButton, { [styles.backToTopButtonVisible]: displayBackToTop },
          )}
          onClick={this.handleBackToTop}
        />
        <ExportDialog
          isOpen={isExportDialogOpened}
          onCancel={this.handleExportDialogClose}
          onConfirm={this.handleExportDialogClose}
          onSubmit={onRequestExport}
          submitted={exportSuccessful || false}
          submitting={false}
        />
      </PageContent>
    );
  }
}
