import { $HttpResponse, ItemsGetParameters } from '@flowio/api-sdk';
import {
  RootState, ThunkDispatcher,
  ThunkExtraArguments,
  ThunkResult,
} from '../../../stores/types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';
import { WithPagingAndSortingResponse, WithPagingAndSortingResult } from '../../../utilities/types';
import withPagingAndSorting, { WithPagingAndSortingOptions, isPagingAndSortingResponse } from '../../../utilities/with-paging-and-sorting-v2';
import { getOrganizationId } from '../../organization';
import ActionTypes from '../constants/action-types';

export type ItemSearchOptions = Omit<ItemsGetParameters, 'organization' | 'headers' | 'sort' | 'offset' | 'limit'>;

export default function findCatalogItems(
  searchOptions: ItemSearchOptions,
  pagingOptions: WithPagingAndSortingOptions = {},
): ThunkResult<
  Promise<WithPagingAndSortingResult<io.flow.v0.models.Item[]> | io.flow.v0.models.GenericError>
  > {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<
  WithPagingAndSortingResult<io.flow.v0.models.Item[]>
  | io.flow.v0.models.GenericError
  > => {
    dispatch({
      type: ActionTypes.FIND_CATALOG_ITEMS_REQUEST,
    });

    const state = getState();
    const orgId = getOrganizationId(state);

    return withPagingAndSorting<io.flow.v0.models.Item[]>(
      pagingOptions,
      (params) => extra.api(getState()).items.get(
        {
          organization: orgId,
          ...params,
          ...searchOptions,
        },

      ),
    )().then((response: WithPagingAndSortingResponse<io.flow.v0.models.Item[]>
    | $HttpResponse<io.flow.v0.models.GenericError>) => {
      if (isPagingAndSortingResponse<io.flow.v0.models.Item[]>(response)) {
        dispatch({
          type: ActionTypes.FIND_CATALOG_ITEMS_SUCCESS,
          payload: response.result,
          options: pagingOptions,
        });
        return response.result;
      }

      dispatch({
        params: {},
        type: ActionTypes.FIND_CATALOG_ITEMS_FAILURE,
        payload: formatErrorResponseV2(response),
      });

      return response.body;
    });
  };
}
