import React from 'react';
import get from 'lodash/get';
import { PropertyPath } from 'lodash';
import PriceDetailRow from './price-detail-row';
import QueryTargetField, { QueryTargetFieldType, DataSource } from '../../../../../components/query-target-field';
import { ItemPriceDetails } from '../../../types';
import * as styles from './price-check-form.styles';

interface Props {
  className?: string;
  pricing?: io.flow.v0.models.Pricing;
  priceCheck?: ItemPriceDetails;
  onPriceCheck: Function;
}

const PriceCheckForm: React.FC<Props> = (props: Props) => {
  function getOrUndefined<T = unknown>(path: PropertyPath, defaultValue?: T): T {
    const { priceCheck } = props;
    return get(priceCheck, path, defaultValue);
  }

  const handleNewRequest = (dataSource: DataSource): void => {
    const { onPriceCheck } = props;
    if (dataSource) {
      onPriceCheck(dataSource.suggestion);
    }
  };
  const { pricing, className, priceCheck } = props;
  return (
    <section>
      <QueryTargetField
        hintText="Enter product name or number"
        style={{ display: 'block' }}
        type={QueryTargetFieldType.TYPE_ITEMS}
        onChange={handleNewRequest}
        defaultValue={priceCheck ? priceCheck.number : ''}
      />

      <section className={className || styles.priceCheckForm}>
        <div className={styles.productsRow}>
          <div className={styles.rowHeader}>
            Number
          </div>
          <div className={styles.rowHeader}>
            Name
          </div>
          <div className={styles.rowHeader}>
            Category
          </div>
        </div>
        <div className={styles.productsRow}>
          <div className={styles.productsRowCell}>
            {getOrUndefined('number')}
          </div>
          <div className={styles.productsRowCell}>
            {getOrUndefined('name')}
          </div>
          <div className={styles.productsRowCell}>
            {getOrUndefined('category')}
          </div>
        </div>
      </section>

      <section>
        <div className={styles.detailsRow}>
          <div className={styles.detailsHeaderColDesc} />
          <div className={styles.detailsHeaderColBase}>
            Base
          </div>
          <div className={styles.detailsHeaderColLocal}>
            Local
          </div>
        </div>
        <PriceDetailRow
          description={getOrUndefined('display.base.item_price.name', 'Item Price')}
          base={getOrUndefined('display.base.item_price.label')}
          local={getOrUndefined('display.local.item_price.label')}
          baseComponents={getOrUndefined('display.base.item_price.components')}
          localComponents={getOrUndefined('display.local.item_price.components')}
        />
        <PriceDetailRow
          description={getOrUndefined('display.base.margins.name', 'Margins')}
          base={getOrUndefined('display.base.margins.label')}
          local={getOrUndefined('display.local.margins.label')}
          baseComponents={getOrUndefined('display.base.margins.components')}
          localComponents={getOrUndefined('display.local.margins.components')}
        />
        <PriceDetailRow
          description={getOrUndefined('display.base.vat.name', 'VAT')}
          base={
            pricing && pricing.vat === 'included'
              ? getOrUndefined('display.base.vat.label')
              : 'Not Included'
          }
          local={
            pricing && pricing.vat === 'included'
              ? getOrUndefined('display.local.vat.label')
              : 'Not Included'
          }
          baseComponents={getOrUndefined('display.base.vat.components')}
          localComponents={getOrUndefined('display.local.vat.components')}
          showComponents={pricing && pricing.vat === 'included'}
        />
        <PriceDetailRow
          description={getOrUndefined('display.base.duty.name', 'Duty')}
          base={
            pricing && pricing.duty === 'included'
              ? getOrUndefined('display.base.duty.label')
              : 'Not Included'
          }
          local={
            pricing && pricing.duty === 'included'
              ? getOrUndefined('display.local.duty.label')
              : 'Not Included'
          }
          baseComponents={getOrUndefined('display.base.duty.components')}
          localComponents={getOrUndefined('display.local.duty.components')}
          showComponents={pricing && pricing.duty === 'included'}
        />
        <PriceDetailRow
          description={getOrUndefined('display.base.rounding.name', 'Rounding')}
          base={getOrUndefined('display.base.rounding.label')}
          local={getOrUndefined('display.local.rounding.label')}
          baseComponents={getOrUndefined('display.base.rounding.components')}
          localComponents={getOrUndefined('display.local.rounding.components')}
        />
        <PriceDetailRow
          description="Price"
          base={getOrUndefined('display.base.price.label')}
          local={getOrUndefined('display.local.price.label')}
          baseComponents={getOrUndefined('display.base.price.components')}
          localComponents={getOrUndefined('display.local.price.components')}
          total
        />
        <PriceDetailRow
          description={getOrUndefined('display.base.vat.name', 'VAT')}
          base={
            pricing && pricing.vat !== 'included'
              ? getOrUndefined('display.base.vat.label')
              : 'Already Included'
          }
          local={
            pricing && pricing.vat !== 'included'
              ? getOrUndefined('display.local.vat.label')
              : 'Already Included'
          }
          baseComponents={getOrUndefined('display.base.vat.components')}
          localComponents={getOrUndefined('display.local.vat.components')}
          showComponents={pricing && pricing.vat !== 'included'}
        />
        <PriceDetailRow
          description={getOrUndefined('display.base.duty.name', 'Duty')}
          base={
            pricing && pricing.duty !== 'included'
              ? getOrUndefined('display.base.duty.label')
              : 'Already Included'
          }
          local={
            pricing && pricing.duty !== 'included'
              ? getOrUndefined('display.local.duty.label')
              : 'Already Included'
          }
          baseComponents={getOrUndefined('display.base.duty.components')}
          localComponents={getOrUndefined('display.local.duty.components')}
          showComponents={pricing && pricing.duty !== 'included'}
        />
        <PriceDetailRow
          description="Total"
          base={getOrUndefined('display.base.total.label')}
          local={getOrUndefined('display.local.total.label')}
          baseComponents={getOrUndefined('display.base.total.components')}
          localComponents={getOrUndefined('display.local.total.components')}
          total
        />
      </section>
    </section>
  );
};

PriceCheckForm.displayName = 'PriceCheckForm';

PriceCheckForm.defaultProps = {
  pricing: undefined,
  priceCheck: undefined,
  className: styles.priceCheckForm,
};

export default PriceCheckForm;
