import update from 'immutability-helper';

import ActionTypes from '../constants/action-types';
import { ExperienceState } from '../types';
import { RootActionTypes } from '../../../stores/types';

type State = ExperienceState['entities']['item'];

const initialState: State = null;

export default function (state: State = initialState, action: RootActionTypes): State {
  switch (action.type) {
  /*
    This is used by product details check. Product details check should have
    its own reducer node. Remove this once that is the case.
   */
    case ActionTypes.FETCH_EXPERIENCE_ITEM_SUCCESS:
      return update(state, { $set: action.payload });
    default:
      return state;
  }
}
