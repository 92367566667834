import React from 'react';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { VpnKey } from '@flowio/react-icons';
import {
  Card, CardHeader, CardContent, CardFooter, CardDescription, CardTitle,
} from '@flowio/react-card';
import * as styles from './public-keys.styles';

const PublicKeys: React.FC<{ keys: io.flow.internal.v0.models.Key[] }> = ({ keys }) => (
  <Card>
    <CardHeader dividing>
      <CardTitle>Public Keys</CardTitle>
      <CardDescription>
        List of public keys associated with your organization.
      </CardDescription>
    </CardHeader>
    <CardContent>
      {(() => {
        let children;
        if (isEmpty(keys)) {
          children = (
            <p className="muted-text">
              There are no public keys associated with your organization.
            </p>
          );
        } else {
          children = (
            <ul className={styles.group}>
              {map(keys, ({ id }) => (
                <li key={id} className={styles.item}>
                  <span className={styles.icon}>
                    <VpnKey />
                  </span>
                  <span className={styles.key}>
                    {id}
                  </span>
                </li>
              ))}
            </ul>
          );
        }
        return children;
      })()}
    </CardContent>
    <CardFooter />
  </Card>
);

PublicKeys.displayName = 'PublicKeys';

PublicKeys.defaultProps = {
  keys: [],
};

export default PublicKeys;
