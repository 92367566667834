import { AlertTwoTone } from '@flowio/react-icons';
import { Box } from '@flowio/react-box';
import { Confirm } from '@flowio/react-confirm';

import React from 'react';

import OptinMessageChangeStatusForm from '../../OptinMessageChangeStatusForm/containers/OptinMessageChangeStatusForm';
import { Props } from '../types';

const OptinMessageChangeStatusConfirm: React.FC<Props> = ({
  initialValues,
  onSubmitSuccess,
  open,
  ...unhandledProps
}) => (
  <Confirm open={open} {...unhandledProps}>
    <Box alignItems="center" spacing="loose">
      <AlertTwoTone width="36" />
      <OptinMessageChangeStatusForm
        initialValues={initialValues}
        onSubmitSuccess={onSubmitSuccess}
      />
    </Box>
  </Confirm>
);

OptinMessageChangeStatusConfirm.displayName = 'OptinMessageChangeStatusConfirm';

OptinMessageChangeStatusConfirm.propTypes = {
};

OptinMessageChangeStatusConfirm.defaultProps = {
  initialValues: undefined,
  onSubmitSuccess: undefined,
  open: false,
};

export default OptinMessageChangeStatusConfirm;
