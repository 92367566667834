import React from 'react';
import { Form } from 'redux-form';
import noop from 'lodash/noop';
import { Box } from '@flowio/react-box';

import FeatureStatusFilter from '../FeatureStatusFilter';
import FeatureListSearch from '../FeatureListSearch';
import * as styles from '../features.styles';
import { FeatureListFilterFormProps } from '../../types/components';

const FeatureListFilterForm: React.FC<FeatureListFilterFormProps> = ({
  handleSubmit,
}) => (
  <Form onSubmit={handleSubmit}>
    <Box spacing="loose" alignItems="center">
      <div className={styles.label}>
        Search:
      </div>
      <FeatureListSearch classNames={styles.searchBox} />
      <div className={styles.label}>
        Status:
      </div>
      <FeatureStatusFilter />
    </Box>
  </Form>
);

FeatureListFilterForm.displayName = 'FeatureListSearch';

FeatureListFilterForm.defaultProps = {
  handleSubmit: noop,
};

export default FeatureListFilterForm;
