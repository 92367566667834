import { combineReducers } from 'redux';
import { reducer as fetchReducer } from '@flowio/redux-fetch';
import { reducer as formReducer } from 'redux-form';
import { reducer as formOverlayReducer } from '@flowio/redux-form-overlay';

import { reducer as analytics } from '../modules/analytics';
import { reducers as billing } from '../modules/billing';
import { reducer as exclusions } from '../modules/exclusions';
import { reducer as reference } from '../modules/reference';
import { reducer as loadingIndicator } from '../modules/loading-indicator';
import { reducer as marketing } from '../modules/marketing';
import { reducer as nonReturnable } from '../modules/non-returnable';
import { reducer as resources } from '../modules/resources';
import facebook from '../modules/facebook/reducers';
import failureSummaries from '../modules/failure-summaries/reducers';
import account from '../modules/account/reducer';
import auth from '../modules/auth/reducer';
import catalog from '../modules/catalog/reducers';
import customerService from '../modules/customer-service/reducers';
import defaultReducer from '../modules/console/reducers/default';
import discounts from '../modules/discounts/reducers/discounts';
import experience from '../modules/experience/reducers';
import experiments from '../modules/experiments/reducers';
import features from '../modules/features/reducers';
import fulfillment from '../modules/fulfillment/reducers';
import classification from '../modules/classification/reducers';
import home from '../modules/home/reducer';
import logistics from '../modules/logistics/reducers';
import optin from '../modules/optin/reducers';
import organization from '../modules/organization/reducers';
import queryBuilder from '../modules/query-builder/reducers';
import ratecards from '../modules/ratecards/reducers';
import search from '../modules/search/reducer';
import tokens from '../modules/tokens/reducer';
import ui from '../modules/console/reducers/ui';
import user from '../modules/user/reducer';
import utilities from '../modules/utilities/reducer';
import webhook from '../modules/webhook/reducer';
import pendingUsers from '../modules/pending-users/reducers/pending-users';
import globalSearch from '../modules/global-searchbox/reducers/globalSearch';
import checkoutConfiguration from '../modules/checkout-configuration/reducers';
import priceBookComponentState from '../modules/price-books/reducer';
import labs from '../modules/labs/reducer';
import { RootState, RootActionTypes } from './types';

const reducer = combineReducers<RootState, RootActionTypes>({
  account,
  analytics,
  auth,
  billing,
  catalog,
  checkoutConfiguration,
  classification,
  customerService,
  defaultReducer,
  exclusions,
  experience,
  experiments,
  facebook,
  features,
  fetch: fetchReducer,
  form: formReducer,
  formOverlay: formOverlayReducer,
  fulfillment,
  home,
  labs,
  loadingIndicator,
  logistics,
  nonReturnable,
  optin,
  organization,
  priceBookComponentState,
  queryBuilder,
  ratecards,
  reference,
  resources,
  search,
  tokens,
  ui,
  user,
  utilities,
  discounts,
  webhook,
  marketing,
  pendingUsers,
  failureSummaries,
  globalSearch,
});

export default reducer;
