import assign from 'lodash/assign';
import ActionTypes from '../../../experience/constants/action-types';
import { RootActionTypes } from '../../../../stores/types';
import { ConsoleExperienceState } from '../../types/state';

const initialState: ConsoleExperienceState = {
  updateSettingsSuccess: false,
  saveEditMarginsSuccess: false,
};

export default function experienceUiReducer(
  state = initialState,
  action: RootActionTypes,
): ConsoleExperienceState {
  switch (action.type) {
    case ActionTypes.UPDATE_EXPERIENCE_SUBMIT:
      return assign({}, state, { updateSettingsSuccess: false });
    case ActionTypes.UPDATE_EXPERIENCE_SUCCESS:
      return assign({}, state, { updateSettingsSuccess: true });
    case ActionTypes.UPDATE_PRICING_SUCCESS:
      return assign({}, state, { updatePricingSuccess: true });
    case ActionTypes.UPDATE_ITEM_MARGINS:
      return assign({}, state, { saveEditMarginsSuccess: true });
    default:
      return state;
  }
}
