import { Field } from 'redux-form';
import React from 'react';
import classNames from 'classnames/bind';
import curry from 'lodash/curry';
import get from 'lodash/get';
import find from 'lodash/find';
import map from 'lodash/map';

import styles from './QueryFilter.module.css';
import { SelectField } from '../../../../components/ReduxFormFieldAdapters';

type Props = {
  availableFilter: io.flow.v0.models.AvailableFilterStructured;
  name: string;
};

const cx = classNames.bind(styles);

const labels = {
  '>': 'is greater than',
  '>=': 'is greater than or equal to',
  '<': 'is less than',
  '<=': 'is less than or equal to',
  ':': 'is equal to',
  '!=': 'is not equal to',
  in: 'in',
  'not in': 'not in',
} as const;

const mapOperatorsToDropdownOptions = curry((operators: string[]) => map(operators, (operator) => ({
  label: get(labels, operator, operator),
  value: operator,
})));

const formatValue = curry((operators: string[], value: string) => {
  const options = mapOperatorsToDropdownOptions(operators);
  return find(options, { value });
});

const parseValue = curry((value) => get(value, 'value'));

const QueryFilterOperator: React.FC<Props> = ({
  availableFilter: {
    operators,
  },
  name,
}) => (
  <Field
    component={SelectField}
    name={name}
    format={formatValue(operators)}
    parse={parseValue}
    className={cx('queryFilterOperator')}
    selection
    options={mapOperatorsToDropdownOptions(operators)}
    labelKey="label"
    valueKey="value"
  />
);

QueryFilterOperator.displayName = 'QueryFilterOperator';

export default QueryFilterOperator;
