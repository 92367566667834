import React from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableHeadColumn,
  TableFoot,
  TableBody,
} from '@flowio/react-table';
import { LaneDirection } from '@flowio/api-constants';
import map from 'lodash/map';

import { css } from '@emotion/css';
import Pagination from '../../../../components/pagination';
import ShippingLaneListItem from '../ShippingLaneListItem';

import { ShippingLaneListProps } from '../../types/components';

const paginationStyles = css`
  margin-left: 1rem;
  margin-top: 10px;
`;

const ShippingLaneList: React.FC<ShippingLaneListProps> = ({
  onSelection,
  regions,
  paginatedShippingLanes,
  shippingLaneDirection,
  onUpdateShippingLanePage,
}) => {
  const {
    shippingLanes,
    pageNumber = 1,
    isFirstPage,
    isLastPage,
  } = paginatedShippingLanes;

  const isLaneOutbound = shippingLaneDirection === LaneDirection.OUTBOUND;

  return (
    <Table fixed selectable striped singleLine>
      <TableHead>
        <TableRow>
          <TableHeadColumn>{isLaneOutbound ? 'From: Center(s)' : 'From: Region'}</TableHeadColumn>
          <TableHeadColumn>{isLaneOutbound ? 'To: Region' : 'To: Center(s)'}</TableHeadColumn>
          <TableHeadColumn>Shipping Tiers</TableHeadColumn>
        </TableRow>
      </TableHead>
      <TableBody>
        {map(shippingLanes, (shippingLane, index) => (
          <ShippingLaneListItem
            currentIndex={index}
            key={shippingLane.id}
            onSelection={onSelection}
            regions={regions}
            shippingLane={shippingLane}
            shippingLaneDirection={shippingLaneDirection}
          />
        ))}
      </TableBody>
      <TableFoot>
        <Pagination
          className={paginationStyles}
          pageNumber={pageNumber + 1}
          isPreviousPageDisabled={isFirstPage}
          isNextPageDisabled={isLastPage}
          onRequestPreviousPage={() => onUpdateShippingLanePage(pageNumber - 1)}
          onRequestNextPage={() => onUpdateShippingLanePage(pageNumber + 1)}
        />
      </TableFoot>
    </Table>
  );
};

ShippingLaneList.displayName = 'ShippingLaneList';

export default ShippingLaneList;
