import { css } from '@emotion/css';

export const formGroup = css`
  & > div {
    display: flex;
  }

  & span {
    font-size: 14px;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
  }
  & label {
    line-height: 30px;
  }
`;

export const minHoursSinceSubmittedAt = css`
  & > div:first-child {
    width: 35%;
  }

  & > div:last-child {
    width: 65%;
  }

  & span {
    line-height: 20px;
    font-weight: normal;
  }
`;

export const checkBox = css`
  margin-bottom: 20px;
  margin-right: 5px;
  margin-bottom: 15px;
  display: inline !important;
`;
