import { css } from '@emotion/css';
import colors from '../../../../theme/tokens/colors';

export const form = css`
  padding-top: 8px;
`;

export const exportDialog = css`
  max-width: 480px;
`;

export const emailLabel = css`
  margin-top: 9px;
  color: ${colors.steel};
`;

export const exportDialogFooter = css`
  text-align: right;
`;

export const cancelBtn = css`
  margin-right: 16px;
  padding-right: 42px;
  padding-left: 42px;
  min-width: 150px;
`;

export const exportBtn = css`
  padding-right: 42px;
  padding-left: 42px;
  min-width: 150px;
`;
