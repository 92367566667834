import { compose } from 'redux';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { hideAllTariffCodes, showAllTariffCodes, setSelectedClassificationItems } from '../../../actions';
import ClassificationResults from '../components/classification-results';
import { getSelectedCountries, getShowAllTariffCodes } from '../../../selectors';
import { DispatchProps, ComponentProps, StateProps } from '../types';
import { ClassificationResultCountry } from '../../../types';
import { RootState } from '../../../../../stores/types';

const mapStateToProps: MapStateToProps<StateProps, ComponentProps, RootState> = (state) => ({
  selectedCountries: getSelectedCountries(state),
  showAllTariffCodes: getShowAllTariffCodes(state),
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, ComponentProps> = (dispatch) => ({
  onDropdownChange(
    values: ClassificationResultCountry[],
    showingAllTariffCodes: boolean,
  ): void {
    if (showingAllTariffCodes) {
      dispatch(hideAllTariffCodes());
    }
    dispatch(setSelectedClassificationItems(values));
  },
  onHideAllTariffCodes(): void {
    dispatch(hideAllTariffCodes());
  },
  onShowAllTariffCodes(): void {
    dispatch(setSelectedClassificationItems([]));
    dispatch(showAllTariffCodes());
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(ClassificationResults);
