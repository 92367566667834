import { css } from '@emotion/css';

export const exportDialog = css`
  max-width: 530px;

  & p {
    margin: 0;
  }

  & .text-field {
    margin-top: 1rem;
  }

  & .dialog-footer {
    display: flex;
    flex-flow: row nowrap;
    margin-left: -0.5rem;
    margin-right: -0.5rem;

    & .button {
      flex: 1;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
  }
`;

export const body = css`
  padding: 1.5rem 2rem;
`;

export const footer = css`
  border-top: 1px solid #e4e7eb;
  display: flex;
  flex-flow: row nowrap;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  width: 100%;
  text-align: center;
  ${body}
`;

export const footerAction = css`
  flex: 1;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  min-width: 210px;
`;

export const emailField = css`
  padding-top: 24px;
`;
