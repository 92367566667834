import { css } from '@emotion/css';
import colors from '../../../../../theme/tokens/colors';

export const helpLink = css`
  color:  ${colors.marketing['light-slate-gray']};
  font-weight: bold;
  text-decoration: underline;

  &:hover {
    color: ${colors.marketing['dark-slate-blue']};
  }
`;

export const marketingTable = css`
  margin: 40px 0;

  & .defaultSelect {
    color: ${colors.green[300]};
  }

  & .radio {
    & .radioLabel {
      padding: 0;
    }

    & .radioIndicator {
      margin: 0;
    }
  }
`;

export const selectionContainer = css`
  max-width: 960px;
`;
