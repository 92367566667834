import update from 'immutability-helper';
import isEmpty from 'lodash/isEmpty';
import { ExperienceStatus } from '@flowio/api-constants';

import ActionTypes from '../constants/action-types';
import getSearchValuesFromQuery from '../utilities/getSearchValuesFromQuery';
import getUnstructuredQueryFromQuery from '../utilities/getUnstructuredQueryFromQuery';
import { ExperienceState } from '../types';
import { RootActionTypes } from '../../../stores/types';

type State = ExperienceState['entities']['ui'];

const initialState: State = {
  statusFilters: [
    ExperienceStatus.ACTIVE,
    ExperienceStatus.DRAFT,
    ExperienceStatus.ARCHIVED,
  ],
  activePricingSection: '',
  ccOrderOpen: false,
  isExportExperienceSettingsModalOpen: false,
  searchValue: '',
  pricingFormatVisible: false,
};

export default function ExperienceUiStateReducer(
  state: State = initialState,
  action: RootActionTypes,
): State {
  switch (action.type) {
    case ActionTypes.CHANGE_PRICING_FORMAT_VISIBILITY:
      return update(state, {
        pricingFormatVisible: {
          $set: action.payload,
        },
      });
    case ActionTypes.OPEN_EXPORT_EXPERIENCE_SETTINGS_MODAL:
      return update(state, {
        isExportExperienceSettingsModalOpen: {
          $set: true,
        },
      });
    case ActionTypes.CLOSE_EXPORT_EXPERIENCE_SETTINGS_MODAL:
      return update(state, {
        isExportExperienceSettingsModalOpen: {
          $set: false,
        },
      });
    case ActionTypes.CLEAR_ACTIVE_PRICING_SECTION:
      return update(state, {
        activePricingSection: {
          $set: '',
        },
      });
    case ActionTypes.SET_ACTIVE_PRICING_SECTION:
      return update(state, {
        activePricingSection: {
          $set: action.payload,
        },
      });
    case ActionTypes.SET_ARCHIVE_EXPERIENCE:
      return update(state, {
        experienceToArchive: { $set: action.payload },
      });
    case ActionTypes.CLEAR_ARCHIVE_EXPERIENCE:
      return update(state, { $unset: ['experienceToArchive'] });
    case ActionTypes.SET_ACTIVE_EXPERIENCE:
      return update(state, {
        experienceToActivate: {
          $set: action.payload,
        },
      });
    case ActionTypes.CLEAR_ACTIVE_EXPERIENCE:
      return update(state, { $unset: ['experienceToActivate'] });
    case ActionTypes.SET_CLONE_EXPERIENCE:
      return update(state, {
        experienceToClone: { $set: action.payload },
      });
    case ActionTypes.CLEAR_CLONE_EXPERIENCE:
      return update(state, { $unset: ['experienceToClone'] });
    case ActionTypes.FETCH_EXPERIENCE_QUERY_BUILDER_FILTERS_SUCCESS:
    const filterValues = getSearchValuesFromQuery<ExperienceStatus>(action.payload.filters); // eslint-disable-line
      if (isEmpty(filterValues)) {
        return state;
      }

      return update(state, {
        searchValue: { $set: getUnstructuredQueryFromQuery(action.payload.filters) },
        statusFilters: { $set: filterValues },
      });
    case ActionTypes.SET_CC_ORDER_OPEN_STATE:
      return update(state, {
        ccOrderOpen: { $set: action.payload },
      });
    default:
      return state;
  }
}
