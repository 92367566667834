import { createSelector, OutputSelector } from 'reselect';
import { getIsLoading } from '@flowio/redux-fetch';
import get from 'lodash/get';
import matches from 'lodash/matches';
import some from 'lodash/some';
import { ReadyState as LoadingIndicatorReadyState } from '../../loading-indicator';

import { type FeatureKey } from '../constants/FeatureKey';
import { RootState } from '../../../stores/types';
import { DefaultReducerState, FeatureValueStatus } from '../types/state';
import { LoadingIndicatorState } from '../../loading-indicator/types';
import { ValueOf } from '../../../types';

export const getDefaultReducer = (state: RootState): DefaultReducerState => state.defaultReducer;
export const getUi = (state: RootState): RootState['ui'] => state.ui;
export const getLoadingIndicatorState = (state: RootState): LoadingIndicatorState => get(state, 'loadingIndicator');
export const getFeatureValuesStatus = (state: RootState): FeatureValueStatus => get(state, 'ui.featureValues.status');
export const getFeatureValues = (state: RootState): io.flow.internal.v0.unions.FeatureValue[] => get(state, 'ui.featureValues.entities');

export const getUser = createSelector(
  getDefaultReducer,
  (d) => d.user,
);

export const getUserToken = createSelector(getUser, (user) => get(user, 'auth'));

export const getUserId = createSelector(
  getUser,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  (u) => u!.id,
);

export const getTokenUiState = createSelector(
  getUi,
  (ui) => ui.tokens,
);

export const getCleartextTokenDialogId = createSelector(
  getTokenUiState,
  (ui) => ui.cleartextTokenDialogId,
);

export const getIsFetching = createSelector<RootState, LoadingIndicatorState, boolean, boolean>(
  getLoadingIndicatorState,
  getIsLoading,
  (loadingIndicator, isReduxFetchLoading) => (
    isReduxFetchLoading || loadingIndicator.readyState === LoadingIndicatorReadyState.SHOW),
);

export const getToasts = createSelector(
  getUi,
  (ui) => get(ui, 'toasts'),
);

export const checkFeatureValueByKey = (
  featureKey: ValueOf<FeatureKey>,
): OutputSelector<RootState, boolean, (
  res: any,
  ) => boolean> => createSelector(
  getFeatureValues,
  (featureValues): boolean => some(featureValues, matches({
    value: true,
    feature: {
      key: featureKey,
    },
  })),
);

export const shouldFetchFeatureValues = createSelector(
  getFeatureValuesStatus,
  (status) => status !== 'fulfilled',
);
