import { css } from '@emotion/css';
import colors from '../../../../theme/tokens/colors';

export const classificationSummaryCharts = css`
  padding-bottom: 24px;
  width: 100%;
  & .tooltip {
    background-color: rgba(0, 0, 0, 0.75);
    padding: 15px;
    border-radius: 2px;
    font-family: sans-serif;
    color: white;
    font-size: 14px;
    pointer-events: none;
    box-shadow: 0 0 5px #999999;
  }

  & .legend {
    border: 1px solid black;
  }
`;

export const link = css`
  &:hover {
    text-decoration: none;
  }

  &:hover > export const metric-card {
    border: 1px solid #b5bec9;
  }
`;

export const cardContent = css`
  padding-top: 16px;
`;

export const metricContainer = css`
  width: calc(33% - 6px);
`;

export const assignedItemsPercent = css`
  border-radius: 2px;
  background-color: #3182e3;
  color: white;
  font-size: 16px;
  font-weight: 500;
  padding: 3px 8px;
`;

export const assignedItemsNumber = css`
  color: ${colors['flow-blue'][400]};
  font-size: 32px;
  font-weight: 300;
  line-height: 38px;
  padding-bottom: 8px;
`;

export const unassignedItemsPercent = css`
  border-radius: 2px;
  background-color: #d2431f;
  color: white;
  font-size: 16px;
  font-weight: 500;
  padding: 3px 8px;
`;

export const unassignedItemsNumber = css`
  color: #921d00;
  font-size: 32px;
  font-weight: 300;
  line-height: 38px;
  padding-bottom: 8px;
`;

export const cardDescription = css`
  color: #4d5866;
  font-size: 14px;
  line-height: 16px;
  padding-top: 26px;
`;

export const charts = css`
  display: flex;
  justify-content: center;
`;

export const hs6 = css`
  text-align: center;
  padding: 1rem;
  width: 50%;
`;

export const chartContainer = css`
  display: inline-block;
  height: 112px;
  margin-bottom: 0.5rem;
  position: relative;
  width: 112px;
`;

export const breakdown = css`
  display: flex;
  font-weight: 300;
  justify-content: space-around;

  & a {
    color: inherit;
  }
`;

export const breakdownWithout = css`
  text-align: center;
  width: 148px;

  & a {
    border-radius: 4px;
    display: block;
    padding: 1rem 0.625rem;

    &:hover {
      background-color: ${colors.concrete};
      text-decoration: none;
    }
  }
`;

export const breakdownText = css`
  display: block;
  font-size: 0.875rem;
`;

export const breakdownPercent = breakdownText;

export const breakdownWith = css`
  color: ${colors['flow-blue'][400]};
  ${breakdownWithout}
`;

export const breakdownValue = css`
  ${breakdownText};
  font-size: 1.5rem !important;
`;

export const chartLabelOverlay = css`
  color: ${colors.white};
  font-size: 0.75rem;
  left: 0;
  position: absolute;
  top: 46px;
  width: 112px;
`;
