import DialogName from '../constants/DialogName';
import showDialog from './showDialog';
import { RootActionTypes } from '../../../stores/types';

function archiveOptinMessage(
  organizationId: string,
  optinAttribute: io.flow.internal.v0.models.OptinAttribute,
): RootActionTypes {
  return showDialog(DialogName.OPTIN_MESSAGE_STATUS_CONFIRM)({
    initialValues: {
      name: optinAttribute.name,
      optinAttributeKey: optinAttribute.optin_attribute_key,
      organizationId,
      status: 'archived',
      target: optinAttribute.target,
    },
  });
}

export default archiveOptinMessage;
