import React from 'react';
import { Link } from 'react-router';
import kebabCase from 'lodash/kebabCase';
import { Props } from '../types';

const displayName = 'EmailVerification';

const defaultProps = {
  error: undefined,
};

const EmailVerification: React.FC<Props> = ({ error }) => (
  <section className="page-auth full-screen flex items-center">
    {error ? (
      <div className="col-4 mx-auto">
        <h1 className="h4 bold">We were not able to verify your email.</h1>
        {error.messages && error.messages.map(({ message }) => (
          <p key={kebabCase(message)} className="error-text">
            {message}
          </p>
        ))}
      </div>
    ) : (
      <div className="col-4 mx-auto">
        <h1 className="h4 bold">You&rsquo;re verified on Flow.</h1>
        <p>
          Thanks for verifying your email with us.
          Click
          {' '}
          <Link to="/login">here</Link>
          {' '}
          to login.
        </p>
      </div>
    )}
  </section>
);

EmailVerification.displayName = displayName;
EmailVerification.defaultProps = defaultProps;

export default EmailVerification;
