import { browserHistory } from 'react-router';

function changeOptinPromptsPage(
  organizationId: string,
  optinAttributeKey: string,
  pageNumber: number,
  entriesPerPage: number,
): void {
  return browserHistory.push({
    pathname: `/${organizationId}/optin/messages/${optinAttributeKey}`,
    query: {
      entriesPerPage,
      pageNumber,
    },
  });
}

export default changeOptinPromptsPage;
