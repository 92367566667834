import { connect } from 'react-redux';
import { withFetch } from '@flowio/redux-fetch';
import get from 'lodash/get';
import {
  submit,
  getFormValues,
  FormAction,
} from 'redux-form';
import { OwnProps, StateProps, DispatchProps } from '../types';
import {
  fetchFacebookFeedForms,
  goToFeedsPage,
} from '../../../actions';
import AddFacebookCountryFeeds from '../components/AddFacebookCountryFeeds';
import {
  fetchCountries,
} from '../../../../reference/actions';
import { RootState, ThunkDispatcher } from '../../../../../stores/types';
import FormName from '../../../constants/FormName';

const FEED_FORMS_FORM = FormName.SELECT_FEED_FORMS_FORM;

const getAsyncState = (
  dispatch: ThunkDispatcher,
  _: () => RootState,
  ownProps: OwnProps,
): Promise<unknown[]> => Promise.all([
  dispatch(fetchCountries()),
  dispatch(
    fetchFacebookFeedForms({
      organizationId: ownProps.params.organization,
      catalogId: Number(ownProps.params.catalogId),
    }),
  ),
]);

const submitFeedForms = () => (
  dispatch: ThunkDispatcher,
): FormAction => dispatch(submit(FEED_FORMS_FORM));

const mapStateToProps = (
  state: RootState,
): StateProps => ({
  numSelected: get(getFormValues(FEED_FORMS_FORM)(state), ['forms', 'length'], 0),
});

const mapDispatchToProps = (
  dispatch: ThunkDispatcher,
  ownProps: OwnProps,
): DispatchProps => ({
  onFeedFormsConfirm: (): FormAction => dispatch(submitFeedForms()),
  onCancel: (): void => dispatch(
    goToFeedsPage({
      platformId: 'facebook',
      organizationId: ownProps.params.organization,
      accountId: ownProps.params.catalogId,
    }),
  ),
});

export default withFetch(getAsyncState)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AddFacebookCountryFeeds),
);
