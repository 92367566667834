import React from 'react';
import { AlertTwoTone } from '@flowio/react-icons';
import { Form, Field } from 'redux-form';
import { Box } from '@flowio/react-box';
import get from 'lodash/get';
import { css } from '@emotion/css';
import GenericError from '../../../../../components/GenericError';
import { Props } from '../types';

const genericErrorStyles = css`
  margin-bottom: 1rem;
`;

const OptinMessageDeleteForm: React.FC<Props> = ({
  handleSubmit,
  error,
  optinAttribute,
}) => (
  <Form onSubmit={handleSubmit}>
    <Field component="input" type="hidden" name="organizationId" />
    <Field component="input" type="hidden" name="optinAttributeKey" />
    {error && (<GenericError className={genericErrorStyles} error={error} />)}
    <Box alignItems="center" spacing="loose">
      <AlertTwoTone width="36" />
      <p>
        Are you sure you want to delete message
        &ldquo;
        <strong>
          {get(optinAttribute, 'name')}
        </strong>
        &rdquo;?
        This cannot be undone.
      </p>
    </Box>
  </Form>
);

OptinMessageDeleteForm.displayName = 'OptinMessageDeleteForm';

export default OptinMessageDeleteForm;
