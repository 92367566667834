import { MembershipsPutByIdResponse } from '@flowio/api-sdk';
import ActionTypes from '../constants/action-types';
import { ThunkDispatcher, RootState, ThunkExtraArguments } from '../../../stores/types';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import { PropType } from '../../../types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';
import toLegacyResponse from '../../../utilities/clients/to-legacy-response';

type ResponseType = PropType<MembershipsPutByIdResponse, 'body'>;

export default function updateRole(
  membership: io.flow.v0.models.Membership,
  newRoles: { role: io.flow.v0.enums.Role; roles: io.flow.v0.enums.FlowRole[] },
) {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<LegacyResponse<ResponseType>> => {
    dispatch({
      type: ActionTypes.CHANGE_ROLE_REQUEST,
    });

    return extra.api(getState()).memberships.putById({
      id: membership.id,
      body: newRoles,
    }).then((response) => {
      if (response.ok) {
        dispatch({
          type: ActionTypes.CHANGE_ROLE_SUCCESS,
          payload: response.body,
        });
      } else {
        dispatch({
          type: ActionTypes.CHANGE_ROLE_FAILURE,
          payload: formatErrorResponseV2(response),
        });
      }
      return toLegacyResponse<ResponseType>(response);
    });
  };
}
