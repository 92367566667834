import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import { ActionTypes } from '../constants';

function createRequestAction(): RootActionTypes {
  return {
    type: ActionTypes.FETCH_ITEM_CONTENT_REQUEST,
  };
}

function createSuccessAction(payload: io.flow.v0.models.CatalogItemDocument[]): RootActionTypes {
  return {
    type: ActionTypes.FETCH_ITEM_CONTENT_SUCCESS,
    payload,
  };
}

function createFailureAction(payload: FormattedErrorMessages): RootActionTypes {
  return {
    type: ActionTypes.FETCH_ITEM_CONTENT_FAILURE,
    payload,
  };
}

function getItemContent(
  organizationId: string,
  itemNumbers: string[],
): ThunkResult<Promise<void>> {
  return function getItemContentEffects(
    dispatch,
    getState,
    extra,
  ): Promise<void> {
    dispatch(createRequestAction());
    return extra.api(getState()).documents.getCatalog({
      organization: organizationId,
      q: `number in (${itemNumbers.join(' ')})`,
    }).then((response) => {
      if (response.ok) {
        dispatch(createSuccessAction(response.body));
        return;
      }

      const errors = formatErrorResponseV2(response);
      dispatch(createFailureAction(errors));
    });
  };
}

export default getItemContent;
