import { ActionTypes } from '../constants';
import { shouldFetchExperiences } from '../selectors';
import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';

interface FetchExperiencesOptions {
  entriesPerPage?: number;
  organizationId: string;
  shippingConfigurationKey: string;
}

function createRequestAction(
  organizationId: string,
  shippingConfigurationKey: string,
  entriesPerPage: number,
): RootActionTypes {
  return {
    type: ActionTypes.FETCH_EXPERIENCES_REQUEST,
    meta: {
      organizationId,
      shippingConfigurationKey,
      entriesPerPage,
    },
  };
}

function createSuccessAction(
  organizationId: string,
  shippingConfigurationKey: string,
  entriesPerPage: number,
  payload: io.flow.v0.models.Experience[],
): RootActionTypes {
  return {
    type: ActionTypes.FETCH_EXPERIENCES_SUCCESS,
    meta: {
      organizationId,
      shippingConfigurationKey,
      entriesPerPage,
    },
    payload,
  };
}

function createFailureAction(
  payload: FormattedErrorMessages,
  organizationId: string,
  shippingConfigurationKey: string,
  entriesPerPage: number,
): RootActionTypes {
  return {
    type: ActionTypes.FETCH_EXPERIENCES_FAILURE,
    meta: {
      organizationId,
      shippingConfigurationKey,
      entriesPerPage,
    },
    payload,
    error: true,
  };
}

function fetchExperiencesByShippingConfigurationKey({
  entriesPerPage = 25,
  organizationId,
  shippingConfigurationKey,
}: FetchExperiencesOptions): ThunkResult<Promise<void>> {
  return function fetchExperiencesByShippingConfigurationKeyEffects(
    dispatch,
    getState,
    extra,
  ): Promise<void> {
    if (!shouldFetchExperiences(shippingConfigurationKey)) {
      return Promise.resolve();
    }

    dispatch(createRequestAction(organizationId, shippingConfigurationKey, entriesPerPage));
    return extra.api(getState()).experiences.get({
      organization: organizationId,
      shipping_configuration_key: shippingConfigurationKey,
      limit: entriesPerPage,
    }).then((response) => {
      if (response.ok) {
        dispatch(createSuccessAction(
          organizationId,
          shippingConfigurationKey,
          entriesPerPage,
          response.body,
        ));
        return;
      }

      const errors = formatErrorResponseV2(response);
      dispatch(createFailureAction(
        errors,
        organizationId,
        shippingConfigurationKey,
        entriesPerPage,
      ));
    });
  };
}

export default fetchExperiencesByShippingConfigurationKey;
