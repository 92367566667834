import { combineReducers } from 'redux';

import clone from './clone';
import current from './current';
import selectedExperienceLogisticsSettings from './selectedExperienceLogisticsSettings';
import deliveredDutyOptions from './deliveredDutyOptions';
import deliveredDutyPreference from './deliveredDutyPreference';
import errors from './errors';
import experiences from './experiences';
import experienceLogisticsSettings from './experienceLogisticsSettings';
import margins from './margins';
import paymentMethodRules from './paymentMethodRules';
import priceFormat from './price-format';
import pricing from './pricing';
import defaults from './defaults';
import item from './item';
import itemPriceDetails from './item-price-details';
import ui from './ui';
import statistics from './statistics';
import statuses from './statuses';
import { ExperienceState, ExperienceEntitiesState } from '../types';
import { RootActionTypes } from '../../../stores/types';

export default combineReducers<ExperienceState, RootActionTypes>({
  current,
  entities: combineReducers<ExperienceEntitiesState, RootActionTypes>({
    clone,
    defaults,
    deliveredDutyOptions,
    deliveredDutyPreference,
    errors,
    experiences,
    experienceLogisticsSettings,
    item,
    itemPriceDetails,
    margins,
    paymentMethodRules,
    priceFormat,
    pricing,
    statistics,
    ui,
  }),
  selectedExperienceLogisticsSettings,
  statuses,
});
