import { createSelector } from 'reselect';
import get from 'lodash/get';
import getReturnPolicyWorksheet from './getReturnPolicyWorksheet';
import { RootState } from '../../../stores/types';
import { ReturnPolicyWorksheetState } from '../types';

/**
 * Returns the initial query builder model associated with the return policy
 * being created or edited.
 * @returns {QueryBuilder}
 */
const getQueryBuilderForReturnPolicyWorksheet = createSelector<
RootState, ReturnPolicyWorksheetState, io.flow.v0.models.QueryBuilder | undefined>(
  getReturnPolicyWorksheet,
  (worksheet) => get(worksheet, 'queryBuilder'),
);

export default getQueryBuilderForReturnPolicyWorksheet;
