import { createSelector } from 'reselect';
import identity from 'lodash/identity';

import DialogName from '../constants/DialogName';
import getDialogProp from './getDialogProp';

const getQueryBuilderForEditRuleWorksheet = createSelector(
  identity,
  getDialogProp(DialogName.EDIT_RULE_WORKSHEET, 'queryBuilder'),
);

export default getQueryBuilderForEditRuleWorksheet;
