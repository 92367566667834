import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { submit, isSubmitting } from 'redux-form';

import { hideDialog } from '../../../actions';
import { getIsDialogOpen, getDialogProp } from '../../../selectors';
import DialogName from '../../../constants/DialogName';
import FormName from '../../../constants/FormName';
import OptinContentDeleteConfirm from '../components/OptinContentDeleteConfirm';
import { RootState, ThunkDispatcher } from '../../../../../stores/types';
import { StateProps, DispatchProps } from '../types';

const confirmation = DialogName.DELETE_OPTIN_CONTENT_CONFIRM;
const form = FormName.DELETE_OPTIN_PROMPT_FORM;

const handleSubmit = () => submit(form);

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  disabled: isSubmitting(form),
  initialValues: getDialogProp(confirmation, 'initialValues'),
  open: getIsDialogOpen(confirmation),
  optinPrompt: getDialogProp(confirmation, 'optinPrompt'),
});

const mapDispatchToProps = (dispatch: ThunkDispatcher): DispatchProps => bindActionCreators({
  onCancel: hideDialog(confirmation),
  onOk: handleSubmit,
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(OptinContentDeleteConfirm);
