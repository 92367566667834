import { css } from '@emotion/css';
import colors from '../../../../../theme/tokens/colors';

export const platformCard = css`
  padding: 20px;
  margin-bottom: 40px;
  width: 100%;
  @include box-shadow(7px 7px 15px 0 #eaecf2);

  &:last-child {
    margin-bottom: 0;
    margin-top: 20px;
  }
`;

export const heading = css`
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;

  button {
    &.positive {
      border: none;
      background-color: #3dcf91;
      color: white;

      &:hover {
        background-color: #66b289;
        color: ${colors.white};
      }
    }
  }

  img {
    box-sizing: border-box;
    width: 33px;
    margin-right: 20px;
  }

  h2 {
    margin: 0;
    color: ${colors.marketing['dark-slate-blue']};
    flex-grow: 1;
  }
`;
