import React from 'react';
import { Link } from 'react-router';
import { Box, BoxItem } from '@flowio/react-box';
import SelectFeedForms from '../../../containers/SelectFeedForms';
import { Props } from '../types';
import MarketingCard, { MarketingCardHeading } from '../../MarketingCard';
import MarketingPage from '../../MarketingPage';
import MarketingButton from '../../MarketingButton';

const AddFacebookCountryFeeds: React.FC<Props> = ({
  params: { organization, catalogId },
  onFeedFormsConfirm,
  onCancel,
  numSelected,
}) => {
  const breadcrumbItems = [
    {
      content: 'Product Feeds',
      as: Link,
      to: `/${organization}/marketing`,
    },
    {
      content: 'Facebook Catalog',
      as: Link,
      to: `/${organization}/marketing/facebook/feeds/${catalogId}`,
    },
    {
      content: 'Add Country Feeds',
      active: true,
    },
  ];

  return (
    <MarketingPage
      breadcrumbItems={breadcrumbItems}
      heading="Add Facebook Feeds"
    >
      <MarketingCard boxShadow>
        <MarketingCardHeading
          showDivider={false}
          title="Add Country Feeds"
          subtitle="A country feed includes localized product data (link, availability, price, shipping cost) for the country you sell to."
        />
        <br />
        <SelectFeedForms
          platformId="facebook"
          organizationId={organization}
          showSteps={false}
        />
        <Box justifyContent="end" spacing="loose">
          <BoxItem>
            <MarketingButton onClick={onCancel}>
              Cancel
            </MarketingButton>
          </BoxItem>
          <BoxItem>
            <MarketingButton data-automation-id="add-country-feed" disabled={!numSelected} intent="positive" onClick={onFeedFormsConfirm}>
              Add
              {' '}
              {numSelected > 1 ? `${numSelected} Country Feeds` : 'Country Feed'}
            </MarketingButton>
          </BoxItem>
        </Box>
      </MarketingCard>
    </MarketingPage>
  );
};

AddFacebookCountryFeeds.displayName = 'AddFacebookCountryFeeds';

export default AddFacebookCountryFeeds;
