import { css } from '@emotion/react';
import React from 'react';
import { Box } from '@flowio/react-box';
import { Button } from '@flowio/react-button';
import { colors } from '../../../../../theme/tokens';

const { chart: { lineChartColorScale } } = colors;

const variantBtnStyles = css`
  padding: 10px;
  background: ${colors['flow-blue'][100]};
  span {
    color: ${colors['flow-blue'][400]};
  }
  color: white;
  border: none;
  pointer-events: none;
`;

const colorSquareStyles = (variantNumber: number) => css`
  height: 10px;
  width: 10px;
  margin-right: 5px;
  background-color: ${lineChartColorScale[variantNumber]}
`;

type Props = {
  variantNumber: number;
  name: string;
};

const VariantBadge: React.FC<Props> = ({ variantNumber, name }) => (
  <Box alignItems="center">
    <div css={colorSquareStyles(variantNumber)} />
    <Button
      css={variantBtnStyles}
      content={name}
      size="small"
      type="button"
    />
  </Box>
);

export default VariantBadge;
