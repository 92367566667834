import React from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableHeadColumn,
  TableBody,
} from '@flowio/react-table';
import map from 'lodash/map';

import OptinContentItem from './OptinContentItem';
import { OptinContentListProps } from '../types';

const OptinContentList: React.FC<OptinContentListProps> = ({
  languages,
  onDeleteOptinPrompt,
  onEditOptinPrompt,
  optinAttribute,
  optinPrompts,
  organizationId,
  regions,
}) => (
  <Table striped displayDensity="cozy">
    <TableHead>
      {(optinAttribute.target === 'checkout') && (
        <TableRow>
          <TableHeadColumn>
            Region
          </TableHeadColumn>
          <TableHeadColumn>
            Language
          </TableHeadColumn>
          <TableHeadColumn style={{ width: '142px' }}>
            Checkbox Setting
          </TableHeadColumn>
          <TableHeadColumn>
            Display Position
          </TableHeadColumn>
          <TableHeadColumn>
            Message
          </TableHeadColumn>
          <TableHeadColumn style={{ width: '142px' }}>
            Error Message
          </TableHeadColumn>
          <TableHeadColumn />
        </TableRow>
      )}
      {(optinAttribute.target === 'browse') && (
        <TableRow>
          <TableHeadColumn>
            Region
          </TableHeadColumn>
          <TableHeadColumn>
            Language
          </TableHeadColumn>
          <TableHeadColumn>
            Button Text
          </TableHeadColumn>
          <TableHeadColumn>
            Message
          </TableHeadColumn>
          <TableHeadColumn />
        </TableRow>
      )}
    </TableHead>
    <TableBody>
      {map(optinPrompts, (optinPrompt) => (
        <OptinContentItem
          key={optinPrompt.id}
          languages={languages}
          onEdit={onEditOptinPrompt}
          onDelete={onDeleteOptinPrompt}
          optinAttribute={optinAttribute}
          optinPrompt={optinPrompt}
          organizationId={organizationId}
          regions={regions}
        />
      ))}
    </TableBody>
  </Table>
);

OptinContentList.displayName = 'OptinContentList';

export default OptinContentList;
