import { DecoratedFormProps, reduxForm, SubmissionError } from 'redux-form';
import { $HttpResponse } from '@flowio/api-sdk';
import { createOptinAttribute, updateOptinAttribute } from '../../../actions';
import toOptinAttributeForm from '../../../utilities/toOptinAttributeForm';
import FormName from '../../../constants/FormName';
import OptinMessageForm from '../components/OptinMessageForm';
import isResponseOk from '../../../../../utilities/isResponseOk';
import { ThunkDispatcher } from '../../../../../stores/types';
import { OptinAttributeFormValues } from '../../../types';

function handleResponse(
  response: $HttpResponse,
): $HttpResponse {
  if (isResponseOk(response)) return response;
  throw new SubmissionError({ _error: response.body });
}

function submit(
  values: OptinAttributeFormValues,
  dispatch: ThunkDispatcher,
  props: DecoratedFormProps<OptinAttributeFormValues>,
) {
  const { organizationId, optinAttributeKey } = values;

  switch (props.form) {
    case FormName.CREATE_OPTIN_ATTRIBUTE_FORM:
      return dispatch(createOptinAttribute(
        organizationId,
        optinAttributeKey,
        toOptinAttributeForm(values),
      )).then(handleResponse);
    case FormName.UPDATE_OPTIN_ATTRIBUTE_FORM:
      return dispatch(updateOptinAttribute(
        organizationId,
        optinAttributeKey,
        toOptinAttributeForm(values),
      )).then(handleResponse);
    default:
      throw new Error('Invalid form submitted.');
  }
}

export default reduxForm<OptinAttributeFormValues, {}>({
  onSubmit: submit,
})(OptinMessageForm);
