import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { RootState, ThunkDispatcher, ThunkResult } from '../../../../../stores/types';
import { toggleAuthDialog } from '../../../actions';
import DialogName from '../../../constants/DialogName';
import { getDialogProperty } from '../../../selectors';
import AddAuthDialog from '../components/AddAuthDialog';
import { DispatchProps, StateProps } from '../types';
import { getIsInitialized } from '../../../../facebook/selectors';
import { onboard } from '../../../../facebook/actions';
import { createToast } from '../../../../console/actions';

const connectDialog = DialogName.AUTH_DIALOG;

const handleCancel = (): ThunkResult<void> => (dispatch: ThunkDispatcher): void => {
  dispatch(toggleAuthDialog(false));
};

const handleConnectToFB = (
  organizationId: string,
): ThunkResult<void> => (dispatch: ThunkDispatcher): void => {
  dispatch(onboard(organizationId))
    .then(() => {
      dispatch(toggleAuthDialog(false));
      browserHistory.push(`/${organizationId}/marketing/facebook/select-catalog`);
    })
    .catch((error) => dispatch(createToast({
      intent: 'negative',
      content: `Login Failed. ${error.message}`,
      icon: 'info-circle',
    })));
};

const mapStateToProps = createStructuredSelector<RootState, {}, StateProps>({
  open: getDialogProperty(connectDialog, 'open'),
  platformId: getDialogProperty(connectDialog, 'platformId'),
  organizationId: getDialogProperty(connectDialog, 'organizationId'),
  isManual: getDialogProperty(connectDialog, 'isManual'),
  isFBSdkInitialized: getIsInitialized,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatcher,
): DispatchProps => ({
  onCancelClick: () => dispatch(handleCancel()),
  onConnectFBClick: (organizationId) => dispatch(handleConnectToFB(organizationId)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(AddAuthDialog);
