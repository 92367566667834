import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';

import { authorizeRedirect } from '../../../actions';
import AuthRedirect from '../components/auth-redirect';
import { getAuthRedirectLocation, getAuthRedirectStatus } from '../../../selectors';
import { RootState, ThunkDispatcher } from '../../../../../stores/types';
import { DispatchProps, OwnProps, StateProps } from '../types';

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch: ThunkDispatcher,
  ownProps: OwnProps,
) => ({
  onRequestRedirect(): void {
    dispatch(authorizeRedirect(ownProps.location.query));
  },
});

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = (
  state: RootState,
): StateProps => ({
  redirect: getAuthRedirectLocation(state),
  status: getAuthRedirectStatus(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthRedirect);
