import { css } from '@emotion/css';
import colors from '../../../../../theme/tokens/colors';

export const container = css`
  margin-top: 1rem;
`;

export const row = css`
  line-height: 2rem;
`;

export const field = css`
  min-width: 32rem;
  width: 50%;
`;

export const statusLabel = css`
  color: ${colors.steel};
  margin-right: 8px;
  margin-top: 5px;
`;

export const label = css`
  color: ${colors.steel};
  display: inline-block;
  font-size: 0.875rem;
  line-height: 1rem;
  padding: 0.25rem 0;
  margin: 0;
  width: 8rem;
  vertical-align: top;
`;

export const value = css`
  display: inline-block;
  font-size: 1rem;
  padding: 0 0 0 1rem;
  margin: 0;
  width: auto;
`;

export const checkboxRow = css`
  padding: 8px;
  min-width: 114px;

  &:hover {
    background-color: ${colors.neutral[100]};
    cursor: pointer;
  }
`;

export const allCheckboxRow = css`
  ${checkboxRow}
  border-top: 1px solid ${colors.concrete};
`;

export const statusBox = css`
  margin: 0 8px;
`;

export const selectedStatusLabel = css`
  width: 100%;
  text-align: left;
`;

export const listContainer = css`
  padding: 4px 0 0 0;
`;
