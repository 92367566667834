import { combineReducers } from 'redux';
import countries from './countries';
import currencies from './currencies';
import languages from './languages';
import regions from './regions';
import statuses from './statuses';

export default combineReducers({
  entities: combineReducers({
    countries, currencies, languages, regions,
  }),
  statuses,
});
