import React from 'react';
import { Link } from 'react-router';
import { css } from '@emotion/react';
import { Banner } from '@flowio/react-banner';
import { Alert } from '@flowio/react-icons';
import { Button } from '@flowio/react-button';
import MarketingDialog from '../../../../marketing/components/MarketingDialog';
import { Props } from '../types';
import { colors, spacing, fontWeight } from '../../../../../theme/tokens';

const bannerStyles = css({
  alignItems: 'flex-start',
  p: {
    marginTop: '0',
  },
  a: {
    textDecoration: 'underline',
  },
  'p,a': {
    color: colors.yellow[500],
    fontWeight: fontWeight.medium,
  },
  svg: {
    width: spacing.xxl,
    color: colors.yellow[300],
    marginRight: spacing.l,
  },
});

const DisconnectFacebookModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onConfirm,
  organizationId,
}) => {
  const banner: JSX.Element = (
    <Banner
      intent="warning"
      css={bannerStyles}
      icon={Alert}
    >
      <p>
        Please be aware that if your Facebook account is currently being used to grant Flow
        access to Facebook Catalog Manager so that product feeds can be generated,
        disconnecting will prevent these feeds from continuing to update.
      </p>
      {organizationId && (
        <Link to={`/${organizationId}/marketing`}>Go to Product Feeds.</Link>
      )}
    </Banner>
  );

  return (
    <MarketingDialog
      isOpen={isOpen}
      subtitle={banner}
      title="Are you sure you want to disconnect Facebook?"
    >
      <Button content="Cancel" onClick={onClose} />
      <Button content="Yes, Disconnect" intent="primary" onClick={onConfirm} />
    </MarketingDialog>
  );
};

DisconnectFacebookModal.displayName = 'DisconnectFacebookModal';

export default DisconnectFacebookModal;
