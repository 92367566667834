import { createSelector } from 'reselect';
import getOptinState from './getOptinState';
import { RootState } from '../../../stores/types';
import { OptinState } from '../types';
import { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';

export default createSelector<RootState, OptinState, FormattedErrorMessages | undefined>(
  getOptinState,
  (optinState: OptinState) => optinState.activeOptinAttribute.error,
);
