import { createSelector } from 'reselect';
import identity from 'lodash/identity';
import get from 'lodash/get';
import { RootState } from '../../../stores/types';

const getFeatures = createSelector(
  identity,
  (state: RootState) => get(state, 'features'),
);

export default getFeatures;
