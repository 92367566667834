import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { withFetch } from '@flowio/redux-fetch';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import PaymentMethodRulesContainer from '../components/PaymentMethodRulesContainer';
import { getOrganizationId, fetchOrganization } from '../../../../organization';
import {
  getCurrentExperience,
  getCurrentExperienceKey,
  getPaymentMethodsDisplayOrder,
  getCCOrderOpenState,
} from '../../../selectors';
import {
  fetchPaymentMethodsDisplayOrder,
  fetchExperience,
  setCCOrderOpenState,
  updateCreditCardOrderDisplay,
  updatePaymentMethodsDisplayOrder,
  updatePaymentMethodsOrder,
} from '../../../actions';
import { getIsFlowEmployee } from '../../../../user/selectors';
import setPaymentMethodRuleDisplayState from '../../../actions/setPaymentMethodRuleDisplayState';
import { StateProps, OwnProps, DispatchProps } from '../types';
import { RootState, ThunkDispatcher, RootActionTypes } from '../../../../../stores/types';
import { fetchSpecificUserOrganizationMembership } from '../../../../user';

function getAsyncState(
  dispatch: ThunkDispatcher,
  _getState: () => RootState,
  ownProps: OwnProps,
): Promise<unknown[]> {
  const { organization, experienceKey } = ownProps.params;
  return Promise.all([
    dispatch(fetchOrganization(organization)),
    dispatch(fetchExperience(organization, experienceKey)),
    dispatch(fetchSpecificUserOrganizationMembership({
      organizationId: 'flow',
    })),
    dispatch(fetchPaymentMethodsDisplayOrder(organization, experienceKey)),
  ]);
}

const handlePaymentMethodRuleDisplayChange = ({
  organization,
  experienceKey,
  paymentMethodId,
  display,
}: {
  organization: string;
  experienceKey: string;
  paymentMethodId: string;
  display: boolean;
}) => (
  dispatch: ThunkDispatcher,
  getState: () => RootState,
): Promise<unknown> => Promise.resolve(
  dispatch(setPaymentMethodRuleDisplayState({ paymentMethodId, display })),
).then(() => {
  const state = getState();
  return dispatch(updatePaymentMethodsDisplayOrder({
    organizationId: organization,
    experienceKey,
    paymentMethods: getPaymentMethodsDisplayOrder(state),
  }));
});

const handlePaymentMethodRuleSwap = ({
  organization,
  experienceKey,
  sourceIndex,
  targetIndex,
}: {
  organization: string;
  experienceKey: string;
  sourceIndex: number;
  targetIndex: number;
}) => (
  dispatch: ThunkDispatcher,
  getState: () => RootState,
): Promise<unknown> => Promise.resolve(
  dispatch(updatePaymentMethodsOrder({ sourceIndex, targetIndex })),
).then(() => {
  const state = getState();
  return dispatch(updatePaymentMethodsDisplayOrder({
    organizationId: organization,
    experienceKey,
    paymentMethods: getPaymentMethodsDisplayOrder(state),
  }));
});

const handleUpdateCreditCardOrderDisplay = ({
  organization,
  experienceKey,
  cards,
}: {
  organization: string;
  experienceKey: string;
  cards: io.flow.v0.models.PaymentMethodRule[];
}) => (
  dispatch: ThunkDispatcher,
  getState: () => RootState,
): Promise<unknown[]> => Promise.all([
  dispatch(updateCreditCardOrderDisplay({
    cards,
  })),
  dispatch(updatePaymentMethodsDisplayOrder({
    organizationId: organization,
    experienceKey,
    paymentMethods: getPaymentMethodsDisplayOrder(getState()),
  })),
  dispatch(setCCOrderOpenState({ isOpen: false })),
]);

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = createStructuredSelector({
  isFlowEmployee: getIsFlowEmployee,
  paymentMethods: getPaymentMethodsDisplayOrder,
  organizationId: getOrganizationId,
  experience: getCurrentExperience,
  experienceKey: getCurrentExperienceKey,
  isCCSectionOpen: getCCOrderOpenState,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch: ThunkDispatcher,
  ownProps: OwnProps,
) => ({
  handlePaymentMethodRuleDisplayChange: (
    paymentMethodId,
    display,
  ): Promise<unknown> => dispatch(handlePaymentMethodRuleDisplayChange({
    organization: ownProps.params.organization,
    experienceKey: ownProps.params.experienceKey,
    paymentMethodId,
    display,
  })),
  handlePaymentMethodRuleSwap: (
    { sourceIndex, targetIndex },
  ): Promise<unknown> => dispatch(handlePaymentMethodRuleSwap({
    organization: ownProps.params.organization,
    experienceKey: ownProps.params.experienceKey,
    sourceIndex,
    targetIndex,
  })),
  handleCreditCardsReorder: (
    { cards },
  ): Promise<unknown[]> => dispatch(handleUpdateCreditCardOrderDisplay({
    organization: ownProps.params.organization,
    experienceKey: ownProps.params.experienceKey,
    cards,
  })),
  handleCCOrderOpen: (): RootActionTypes => dispatch(setCCOrderOpenState({ isOpen: true })),
  handleCCOrderClose: (): RootActionTypes => dispatch(setCCOrderOpenState({ isOpen: false })),
});

export default compose(
  withFetch(getAsyncState),
  connect(mapStateToProps, mapDispatchToProps),
)(PaymentMethodRulesContainer);
