import { Dispatch, compose, bindActionCreators } from 'redux';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';
import { withFetch } from '@flowio/redux-fetch';
import { ThunkAction } from 'redux-thunk';
import { fetchShopifySyncStatus } from '../../../actions';
import {
  getShopifySyncStatus,
  getShopifySyncStatusReadyState,
  getShopifySyncStatusError,
} from '../../../selectors';
import ShopifySyncStatus from '../components/ShopifySyncStatus';
import {
  RootState,
  RootActionTypes,
  ThunkDispatcher,
  ThunkExtraArguments,
} from '../../../../../stores/types';
import {
  StateProps,
  DispatchProps,
  OwnProps,
} from '../types';

const getAsyncState = (
  dispatch: ThunkDispatcher,
  _: () => RootState,
  props: OwnProps,
): Promise<any> => {
  const {
    params: {
      organization: organizationId,
    },
  } = props;

  return Promise.all([
    dispatch(fetchShopifySyncStatus(organizationId)),
  ]);
};

const queuePopulatedSelector = createSelector(
  getShopifySyncStatus,
  (syncState) => syncState && syncState.total > 0,
);

const handleRefresh = (
  organizationId: string,
) => (): ThunkAction<
Promise<void>, RootState, ThunkExtraArguments, RootActionTypes
> => fetchShopifySyncStatus(organizationId);

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch: Dispatch<RootActionTypes>,
  ownProps: OwnProps,
): DispatchProps => bindActionCreators({
  onRefresh: handleRefresh(ownProps.params.organization),
}, dispatch);

const mapStateToProps: MapStateToProps<
StateProps, OwnProps, RootState
> = createStructuredSelector<RootState, StateProps>({
  syncStatus: getShopifySyncStatus,
  hasData: queuePopulatedSelector,
  readyState: getShopifySyncStatusReadyState,
  error: getShopifySyncStatusError,
});

export default compose(
  withFetch(getAsyncState),
  connect(mapStateToProps, mapDispatchToProps),
)(ShopifySyncStatus);
