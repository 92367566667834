import update from 'immutability-helper';
import get from 'lodash/get';
import ActionTypes from '../constants/action-types';
import { ExperienceState } from '../types';
import { RootActionTypes } from '../../../stores/types';

type State = ExperienceState['entities']['itemPriceDetails'];

const initialState: State = {};

export default function (state: State = initialState, action: RootActionTypes): State {
  switch (action.type) {
    case ActionTypes.CLEAR_ITEM_PRICING:
      return update(state, { $set: {} });
    case ActionTypes.GET_ITEM_PRICE_DETAILS_SUCCESS: {
      const newCurrent = {
        ...action.payload,
        number: action.item.number,
        name: action.item.name,
        category: get(action, 'item.categories[0]'),
      };

      if (state) {
        return update(state, {
          current: {
            $set: newCurrent,
          },
        });
      }

      return {
        current: newCurrent,
      };
    }

    default:
      return state;
  }
}
