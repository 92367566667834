import React from 'react';
import { AlertTwoTone } from '@flowio/react-icons';
import { Form, Field } from 'redux-form';
import { Box } from '@flowio/react-box';
import find from 'lodash/find';
import get from 'lodash/get';
import { css } from '@emotion/css';
import GenericError from '../../../../../components/GenericError';
import { Props } from '../types';

const genericErrorStyles = css`
  margin-bottom: 1rem;
`;

const OptinContentDeleteForm: React.FC<Props> = ({
  error,
  handleSubmit,
  optinPrompt,
  regions,
}) => (
  <Form onSubmit={handleSubmit}>
    <Field component="input" type="hidden" name="organizationId" />
    <Field component="input" type="hidden" name="optinPromptId" />
    {error && (<GenericError className={genericErrorStyles} error={error} />)}
    <Box alignItems="center" spacing="loose">
      <AlertTwoTone width="36" />
      <p>
        Are you sure you want to delete content for region
        {' '}
        {' '}
        <strong>{optinPrompt ? get(find(regions, { id: optinPrompt.region }), 'name') : ''}</strong>
        ?
        This cannot be undone.
      </p>
    </Box>
  </Form>
);

OptinContentDeleteForm.displayName = 'OptinContentDeleteForm';

export default OptinContentDeleteForm;
