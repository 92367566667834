import React from 'react';
import { browserHistory } from 'react-router';
import { ReactReduxContext } from 'react-redux';
import getDisplayName from '../../utilities/react/get-display-name';
import { RootState } from '../../stores/types';

/**
 * A higher order component that redirects to a path if predicate
 * returns true.
 * @param {String|Object|Function} location The location to redirect
 * to. If specified as a function, it will be invoked with the current
 * application state tree and props passed to the wrapped component
 * as parameters, and return a location string or location descriptor
 * acceptable by React Router Redux push action.
 * @param {Function} predicate A function responsible for indicating
 * whether the user agent should be redirected. It will be invoked
 * with the current application state tree and props passed to the
 * wrapped component as parameters.
 * @return {Function}
 */

export type RedirectLocation = string | ((state: RootState, Props: any) => string);
export type RedirectPredicate = (state: RootState, Props: any) => boolean;
type Props = { [key: string]: unknown };
interface State {
  isRedirecting: boolean;
}

interface Options {
  reloadPredicate?: (state: RootState, props: any) => boolean;
}

export default function withRedirect(
  location: RedirectLocation,
  predicate: RedirectPredicate,
  options: Options = {},
): (Component: React.ElementType) => React.ReactNode {
  return function createContainer(Component: React.ElementType): React.ReactNode {
    class Redirect extends React.Component<Props, State> {
      static displayName = `WithRedirect(${getDisplayName(Component)})`;

      constructor(props: Props) {
        super(props);

        this.state = {
          isRedirecting: false,
        };
      }

      // eslint-disable-next-line camelcase
      UNSAFE_componentWillMount(): void {
        this.redirectIfNecessary(this.props);
      }

      // eslint-disable-next-line camelcase
      UNSAFE_componentWillReceiveProps(nextProps: Props): void {
        this.redirectIfNecessary(nextProps);
      }

      static contextType = ReactReduxContext;

      redirectIfNecessary(props: Props): void {
        const { isRedirecting } = this.state;
        const { store } = this.context;

        if (!isRedirecting) {
          const { getState } = store;
          const state = getState();

          if (predicate(state, props)) {
            this.setState({ isRedirecting: true });

            const reload = options.reloadPredicate && options.reloadPredicate(state, props);
            let url = location;

            if (typeof location === 'function') {
              url = location(state, props);
            }

            if (reload) {
              window.location.assign(url as string);
            } else {
              browserHistory.push(url as string);
            }
          }
        }
      }

      render(): React.ReactNode {
        const { isRedirecting } = this.state;
        if (!isRedirecting) {
          return (<Component {...this.props} />);
        }

        return null;
      }
    }

    return Redirect;
  };
}
