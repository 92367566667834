import { Ellipsis } from '@flowio/react-icons';
import React from 'react';
import classNames from 'classnames/bind';
import moment from 'moment';
import find from 'lodash/find';
import upperCase from 'lodash/upperCase';

import {
  Table,
  TableHead,
  TableRow,
  TableRowColumn,
  TableHeadColumn,
  TableBody,

} from '@flowio/react-table';
import {
  Dropdown, DropdownMenu, DropdownItem, DropdownDivider,
} from '@flowio/react-dropdown';
import { IconButton } from '@flowio/react-button';
import { DiscountListingTableProps } from '../../types';
import Status from '../../constants/Status';
// eslint-disable-next-line
const styles = require('./DiscountListingTable.module.css');

const cx = classNames.bind(styles);

function getFormattedDate(date: string): string {
  const momentDate = moment(date).utc().format('MMM DD, YYYY');
  return momentDate;
}

// Right now we are only doing subsidies so we just search for that specific
// rule. Also for v1 we are only having one offer per discount rule
function getDiscountType(offers: io.flow.v0.models.DiscountRuleOffer[]): string {
  const subsidy = find(offers, (offer) => offer.entitlement.discriminator === 'subsidy');

  if (subsidy) {
    const { targets } = subsidy.entitlement;
    const isTax = targets.includes('vat');

    return isTax ? 'Tax Subsidy' : 'Duty Subsidy';
  }

  return '-';
}

function getExperienceNames(
  experienceKeys: string[],
  experiences: io.flow.v0.models.Experience[],
): string {
  const formattedExperiences = experienceKeys.slice(0, 4).map((key) => {
    const experience = find(experiences, (exp) => exp.key === key);
    return experience ? experience.name : key;
  }).join(', ');
  return experienceKeys.length > 4 ? `${formattedExperiences}...` : formattedExperiences;
}

const DiscountListingTable: React.FunctionComponent<DiscountListingTableProps> = ({
  discounts,
  organization,
  experiences,
  onEdit,
  onDelete,
  onDisableDiscount,
  onEnableDiscount,
  hideLastColumn,
}) => (
  <Table>
    <TableHead>
      <TableRow>
        <TableHeadColumn className={cx('firstColumn')}>
          Name
        </TableHeadColumn>
        <TableHeadColumn>
          Type
        </TableHeadColumn>
        <TableHeadColumn>
          Experiences Applied to
        </TableHeadColumn>
        <TableHeadColumn>
          Start Date
        </TableHeadColumn>
        <TableHeadColumn>
          End Date
        </TableHeadColumn>
        <TableHeadColumn>
          Status
        </TableHeadColumn>
        {!hideLastColumn && (
          <TableHeadColumn className={cx('lastColumn')} />
        )}
      </TableRow>
    </TableHead>
    <TableBody>
      {discounts.map((discount, index) => (
        <TableRow
          striped={index % 2 === 1}
          className={cx('discountRow')}
          id={discount.id}
        >
          <TableRowColumn className={cx('firstColumn')}>
            {discount.name}
          </TableRowColumn>
          <TableRowColumn>
            {getDiscountType(discount.rule.offers)}
          </TableRowColumn>
          <TableRowColumn>
            {getExperienceNames(discount.experience_keys, experiences)}
          </TableRowColumn>
          <TableRowColumn>
            {getFormattedDate(discount.from_with_tz.datetime)}
          </TableRowColumn>
          <TableRowColumn>
            {discount.to_with_tz ? getFormattedDate(discount.to_with_tz.datetime) : '-'}
          </TableRowColumn>
          <TableRowColumn className={cx(`${discount.status}-status`, 'statusColumn')}>
            <div className={cx(`${discount.status}-dot`, 'statusDot')} />
            {upperCase(discount.status)}
          </TableRowColumn>
          {!hideLastColumn && (
            <TableRowColumn className={cx('menuColumn', 'lastColumn')}>
              <Dropdown
                trigger={<IconButton size="small" icon={Ellipsis} />}
              >
                <DropdownMenu>
                  <DropdownItem
                    onClick={(): void => onEdit(discount)}
                    content="Edit"
                  />
                  {discount.status === Status.Expired && (
                  <DropdownItem
                    onClick={(): void => onEnableDiscount(organization, discount)}
                    content="Enable"
                  />
                  )}
                  {discount.status !== Status.Expired && (
                  <DropdownItem
                    onClick={(): void => onDisableDiscount(organization, discount)}
                    content="Disable"
                  />
                  )}
                  <DropdownDivider />
                  <DropdownItem
                    onClick={(): void => onDelete(organization, discount.id)}
                    content="Delete"
                  />
                </DropdownMenu>
              </Dropdown>
            </TableRowColumn>
          )}
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

export default DiscountListingTable;
