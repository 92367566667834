export function hasOnlyFulfillmentRole(roles: io.flow.v0.enums.FlowRole[]): boolean {
  return roles.length === 1
      && roles[0] === 'organization_fulfillment';
}

export default function isFulfillmentUser(
  organizationId: string,
  memberships: io.flow.v0.models.Membership[],
): boolean {
  const orgMembership = memberships.find(
    (membership) => membership.organization.id === organizationId,
  );
  return (
    (orgMembership || false)
      && hasOnlyFulfillmentRole(orgMembership.roles)
  );
}
