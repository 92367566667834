import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { RootState, ThunkDispatcher } from '../../../../../stores/types';
import createToast from '../../../../console/actions/createToast';
import { disconnectChannel, toggleDisconnectChannelDialog } from '../../../actions';
import DialogName from '../../../constants/DialogName';
import Platforms from '../../../constants/Platforms';
import { getChannelsPartitioned, getDialogProperty } from '../../../selectors';
import DisconnectChannelDialog from '../components/DisconnectChannelDialog';
import { DispatchProps, OwnProps, StateProps } from '../types';

const disconnectDialog = DialogName.DISCONNECT_CHANNEL;

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  isOpen: getDialogProperty(disconnectDialog, 'open'),
  platform: getDialogProperty(disconnectDialog, 'channelType'),
  channels: getChannelsPartitioned,
});

const createMessageToast = (intent: 'positive' | 'negative', message: string) => createToast({
  content: message,
  icon: intent === 'positive' ? 'CheckCircle' : 'Error',
  intent,
});

const getToastMessage = (
  channelId: io.flow.internal.v0.enums.MarketingGatewayPlatform,
  isManual: boolean,
): string => {
  const { platformName } = Platforms(channelId);
  return `${platformName} has been successfully ${isManual ? 'removed' : 'disconnected'}.`;
};

const mapDispatchToProps = (
  dispatch: ThunkDispatcher,
  { organizationId }: OwnProps,
): DispatchProps => ({
  onDisconnectChannel(channelId, isManual) {
    return dispatch(disconnectChannel(organizationId, channelId)).then(
      () => {
        dispatch(
          createMessageToast('positive', getToastMessage(channelId, isManual)),
        );
      },
    ).catch(
      ({ message }) => dispatch(
        createMessageToast('negative', `Failed to ${isManual ? 'remove' : 'disconnect'} channel ${message}.`),
      ),
    ).finally(() => {
      dispatch(toggleDisconnectChannelDialog(false));
    });
  },
  hideModal: () => dispatch(toggleDisconnectChannelDialog(false)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DisconnectChannelDialog);
