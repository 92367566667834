import React from 'react';
import { MenuDivider } from '@flowio/react-menu';
import classNames from 'classnames';
import * as styles from './MarketingCard.styles';

interface Props {
  title?: string;
  subtitle?: string | JSX.Element;
  showDivider?: boolean;
}

const MarketingCardHeading: React.FC<Props> = ({
  title,
  subtitle,
  children,
  showDivider = true,
}) => ((title || subtitle) ? (
  <>
    <div className={classNames(styles.heading, { wrap: !children })}>
      {title && <h2 className="title">{title}</h2>}
      {children && (
        <div className="otherInfo">
          {children}
        </div>
      )}
    </div>
    {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
    {showDivider && <MenuDivider className="divider" />}
  </>
) : null);

MarketingCardHeading.displayName = 'MarketingCardHeading';

export default MarketingCardHeading;
