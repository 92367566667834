import React, { useState } from 'react';
import { Image } from '@flowio/react-image';
import { Button } from '@flowio/react-button';
import { css } from '@emotion/css';
import { Props } from '../types';
import MarketingDialog from '../../MarketingDialog';
import Platforms from '../../../constants/Platforms';

const containerStyles = css`
  position: relative;
`;

const bgImageStyles = css`
  width: 100%;
`;

const logoStyles = css`
  position: absolute !important; /* else the logo shifts outside the image */
  z-index: 1000;
  top: 80px;
  right: 100px;
`;

const ManualChannelsCreatedDialog: React.FC<Props> = ({
  platform,
  numFeeds,
}) => {
  const [open, setOpen] = useState(true);
  const { platformName } = Platforms(platform);

  const topContent = (
    <div className={containerStyles}>
      <Image accessibilityLabel="Manual channel diagram" className={bgImageStyles} source="https://cdn.flow.io/console/manual_channels_diagram.png" />
      <Image accessibilityLabel={`${platform} logo`} className={logoStyles} source={`https://cdn.flow.io/console/logo_${platform}.svg`} width="75" />
    </div>
  );

  return (
    <MarketingDialog
      data-automation-id="marketing-dialog"
      title={`${numFeeds} localized product feeds can be added to ${platformName} now!`}
      subtitle={`Simply copy and paste the feed URLs into your ${platformName} account to start running ads on ${platformName}.`}
      isOpen={open}
      topContent={topContent}
    >
      <Button
        intent="primary"
        onClick={() => setOpen(false)}
        content="Got It"
      />
    </MarketingDialog>
  );
};

ManualChannelsCreatedDialog.displayName = 'ManualChannelsCreatedDialog';

export default ManualChannelsCreatedDialog;
