import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { isInvalid, isSubmitting, submit } from 'redux-form';
import overSome from 'lodash/overSome';

import { hideDialog, refreshOptinPrompts } from '../../../actions';
import { getDialogProp, getIsDialogOpen } from '../../../selectors';
import FormName from '../../../constants/FormName';
import OptinContentWorksheet from '../components/OptinContentWorksheet';
import DialogName from '../../../constants/DialogName';
import createToast from '../../../../console/actions/createToast';
import { DispatchProps, StateProps } from '../types';
import { RootState, ThunkDispatcher } from '../../../../../stores/types';

const form = FormName.OPTIN_CONTENT_FORM;
const dialog = DialogName.OPTIN_CONTENT_WORKSHEET;

const submitForm = () => submit(form);

const handleSubmitSuccess = () => (dispatch: ThunkDispatcher) => Promise.all([
  dispatch(createToast({
    intent: 'positive',
    content: 'Content has been added.',
    icon: 'CheckCircle',
  })),
  dispatch(hideDialog(dialog)()),
  dispatch(refreshOptinPrompts()),
]);

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  initialValues: getDialogProp(dialog, 'initialValues'),
  isCancelDisabled: isSubmitting(form),
  isSaveDisabled: overSome([isInvalid(form), isSubmitting(form)]),
  open: getIsDialogOpen(dialog),
});

const mapDispatchToProps = (
  dispatch: ThunkDispatcher,
): DispatchProps => bindActionCreators({
  onCancel: hideDialog(dialog),
  onSave: submitForm,
  onSubmitSuccess: handleSubmitSuccess,
}, dispatch);

export default connect(
  mapStateToProps, mapDispatchToProps,
)(OptinContentWorksheet);
