import { createSelector } from 'reselect';
import get from 'lodash/get';
import find from 'lodash/find';

import getActiveShippingConfiguration from './getActiveShippingConfiguration';
import getActiveShippingLaneId from './getActiveShippingLaneId';
import { RootState } from '../../../stores/types';

export default createSelector<
RootState,
io.flow.v0.models.ShippingConfiguration,
string,
io.flow.v0.models.ShippingLane
| undefined
>(
  getActiveShippingConfiguration,
  getActiveShippingLaneId,
  (shippingConfiguration, shippingLaneId) => {
    const shippingLanes = get(shippingConfiguration, 'shipping_lanes');
    return find(shippingLanes, { id: shippingLaneId });
  },
);
