import * as d3 from 'd3';
import startCase from 'lodash/startCase';
import React, { useEffect } from 'react';
import { getStatusColor, getTooltipText } from './utilities';

interface Props {
  data: io.flow.internal.v0.models.ClassificationStatistics[];
  onLegendClick: (...args: any) => void;
  currentQuery: string;
}

const height = 111;
const width = 380;
const radius = 160;
const innerRadius = 100;
const endOfLegendX = 330;
const legendX = -290;
const legendY = -55;
const marginLeft = 140;
const marginTop = 10;
const chartPadding = 10;
const chartAttrs = {
  height,
  width,
  marginLeft,
  marginTop,
  chartPadding,
  innerRadius,
  radius,
};
const arc = d3.arc<d3.PieArcDatum<io.flow.internal.v0.models.ClassificationStatistics>>()
  .outerRadius(chartAttrs.radius)
  .innerRadius(chartAttrs.innerRadius);
const pie = d3.pie<io.flow.internal.v0.models.ClassificationStatistics>()
  .value((d) => d.number_of_items).sort(null);

const renderChart = (
  data: io.flow.internal.v0.models.ClassificationStatistics[],
  onLegendClick: (...args: any) => void,
  currentQuery: string,
) => {
  const svg = d3.select('#summaryClassificationChart')
    .append('svg')
    .attr('width', '100%')
    .attr('height', 400)
    .attr('class', 'summary-classification-chart')
    .append('g')
    .attr('transform', `translate(650, ${chartAttrs.radius + chartAttrs.marginTop})`)
    .attr('x', '50%')
    .attr('y', '50%')
    .attr('cx', '50%')
    .attr('cy', '50%')
    .attr('class', 'pie-chart-container')
    .style('width', '100%');

  const tooltip = d3.select('#summaryClassificationChart')
    .append('div')
    .attr('class', 'tooltip')
    .style('max-width', '200px')
    .style('display', 'none');

  const mouseover = () => {
    tooltip
      .style('display', 'inline-block')
      .style('position', 'absolute');
  };

  const mousemove = (type: string) => {
    const chartWidth = d3.select('.summary-classification-chart').property('clientWidth');
    tooltip
      .text(getTooltipText(type))
      .attr('cx', '50%')
      .style('right', `${chartWidth + legendX}px`)
      .style('top', `${d3.event.pageY - 30}px`);
  };

  const mouseout = () => {
    tooltip
      .style('display', 'none');
  };

  const sortedStatuses = data.sort((a, b) => d3.descending(a.status, b.status));

  svg.selectAll<SVGPathElement, d3.PieArcDatum<io.flow.internal.v0.models.ClassificationStatistics>>('.arc')
    .data<d3.PieArcDatum<io.flow.internal.v0.models.ClassificationStatistics>>(pie(sortedStatuses))
    .enter()
    .append('path')
    .attr('class', (d) => `arc arc-${d.data.status}`)
    .attr('fill', (d) => getStatusColor(d.data.status))
    .attr('x', '50%')
    .attr('y', '50%')
    .attr('d', (d) => arc(d))
    .attr('cx', '50%')
    .attr('cy', '50%');

  const legendG = svg.selectAll('.legend') // note appending it to mySvg and not svg to make positioning easier
    .data(pie(sortedStatuses))
    .enter().append('g')
    // place each legend on the right and bump each one down 20 pixels
    .attr('transform', (_, i) => `translate(${-300},${i * 25 + 20})`)
    .style('border', '1px solid black')
    .style('background', 'red')
    .style('cursor', 'pointer')
    .style('pointer-events', 'bounding-box')
    .attr('class', 'legend')
    .on('click', (d) => {
      onLegendClick({
        scope: d.data.status,
        q: currentQuery,
      });
      mouseout();
    });

  legendG.append('rect')
    .attr('y', (_, i) => ((legendY - 6) * i) - 18)
    .attr('x', legendX - 10)
    .attr('width', chartAttrs.width)
    .attr('height', '36')
    .attr('color', '#000000')
    .attr('opacity', 0)
    .on('mouseover', () => {
      d3.event.target.setAttribute('opacity', 0.05);
    })
    .on('mouseout', () => {
      d3.event.target.setAttribute('opacity', 0);
    });

  legendG.append('line')
    .style('stroke', '#cccfd3')
    .style('stroke-width', 1)
    .attr('x1', legendX - 10)
    .attr('y1', (_, i) => ((legendY - 6) * i) - 18)
    .attr('y2', (_, i) => ((legendY - 6) * i) - 18)
    .attr('x2', legendX + 370);

  legendG.append('line')
    .style('stroke', '#cccfd3')
    .style('stroke-width', 1)
    .attr('x1', legendX - 10)
    .attr('y1', (_, i) => ((legendY - 6) * i) + 18)
    .attr('y2', (_, i) => ((legendY - 6) * i) + 18)
    .attr('x2', legendX + 370);

  legendG.append('ellipse')
    .attr('cx', legendX)
    .attr('cy', (_, i) => (legendY - 6) * i)
    .attr('rx', 8)
    .attr('ry', 8)
    .attr('fill', (d) => getStatusColor(d.data.status));

  legendG.append('text') // add the text
    .text((d) => {
      if (d.data.status === 'non_classifiable_not_enough_information') return `Not Enough Information (${d.data.number_of_items.toLocaleString('en')})`;

      return `${startCase(d.data.status)} (${d.data.number_of_items.toLocaleString('en')})`;
    })
    .style('font-size', 14)
    .attr('y', (_, i) => ((legendY - 6) * i) + 4)
    .on('mouseover', mouseover)
    .on('mousemove', (d) => mousemove(d.data.status))
    .on('mouseout', mouseout)
    .attr('x', (legendX + 20));

  legendG.append('text') // add the text
    .text((d) => {
      const total = d3.sum(data, (item) => item.number_of_items);
      const percentage = d.data.number_of_items / total;
      const formattedPercentage = d3.format('.1%')(percentage);
      return `${formattedPercentage}`;
    })
    .style('font-size', 14)
    .style('color', 'gray')
    .attr('y', (_, i) => ((legendY - 6) * i) + 4)
    .attr('x', legendX + endOfLegendX);
};
const SummaryClassificationChart: React.FC<Props> = ({
  data = [],
  onLegendClick,
  currentQuery,
}) => {
  useEffect(() => {
    d3.select('.summary-classification-chart').remove();
    renderChart(data, onLegendClick, currentQuery);
  }, [currentQuery, data, onLegendClick]);

  return (
    <div id="summaryClassificationChart" />
  );
};

export default SummaryClassificationChart;
