import ProductDetails from '../../../modules/catalog/components/product-details/containers/product-details';
import ExclusionRuleIndex from '../../../modules/exclusions/containers/ExclusionRuleIndex';
import NonReturnableIndex from '../../../modules/non-returnable/containers/NonReturnableIndex';
import ProductCatalog from '../../../modules/catalog/components/product-catalog';

export default {
  path: ':organization/catalog',
  childRoutes: [
    {
      path: 'items',
      title: 'Items',
      component: ProductCatalog,
    },
    {
      path: 'exclusions',
      component: ExclusionRuleIndex,
      title: 'Exclusions',
    },
    {
      path: 'non-returnable',
      component: NonReturnableIndex,
      title: 'Non-Returnable',
    },
    {
      path: 'items/:number',
      title: 'Product Details',
      component: ProductDetails,
    },
  ],
};
