import React from 'react';
import capitalize from 'lodash/capitalize';
import noop from 'lodash/noop';
import {
  Card,
  CardHeader,
  CardContent,
  CardFooter,
  CardDescription,
  CardEmptyState,
  CardTitle,
} from '@flowio/react-card';
import { Switch } from '@flowio/react-switch';
import {
  Table,
  TableHead,
  TableRow, TableRowColumn,
  TableHeadColumn,
  TableBody,
} from '@flowio/react-table';
import { RegionsProps } from './types';

export default class Regions extends React.PureComponent<RegionsProps> {
  static displayName = 'Regions';

  static defaultProps = {
    regionSettings: [],
    onRequestEnableRegion: noop,
    onRequestDisableRegion: noop,
  };

  constructor(props: RegionsProps) {
    super(props);

    this.renderRegionSettingRow = this.renderRegionSettingRow.bind(this);
  }

  renderRegionSettingRow(regionSetting: io.flow.v0.models.RegionSetting) {
    const {
      onRequestEnableRegion = noop,
      onRequestDisableRegion = noop,
    } = this.props;

    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        onRequestEnableRegion(regionSetting);
      } else {
        onRequestDisableRegion(regionSetting);
      }
    };

    return (
      <TableRow key={regionSetting.id}>
        <TableRowColumn>
          {regionSetting.region}
        </TableRowColumn>
        <TableRowColumn>
          <Switch
            checked={regionSetting.status === 'enabled'}
            labelText={capitalize(regionSetting.status)}
            onChange={handleSwitchChange}
          />
        </TableRowColumn>
      </TableRow>
    );
  }

  render() {
    const { regionSettings = [] } = this.props;

    return (
      <section className="organization-regions">
        <Card>
          <CardHeader dividing>
            <CardTitle content="Region Settings" />
            <CardDescription>
              Control whether a region is available to your customers or not.
              An enabled region means that Flow will localize and sync catalog information,
              process payment, and/or orders for your organization.
            </CardDescription>
          </CardHeader>
          <CardContent>
            {!regionSettings.length && (
              <CardEmptyState>
                No Regions Available.
              </CardEmptyState>
            )}

            {!!regionSettings.length && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeadColumn>
                      Region
                    </TableHeadColumn>
                    <TableHeadColumn>
                      Status
                    </TableHeadColumn>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {regionSettings.map(this.renderRegionSettingRow)}
                </TableBody>
              </Table>
            )}
          </CardContent>
          <CardFooter />
        </Card>
      </section>
    );
  }
}
