import {
  RootState, ThunkDispatcher, ThunkExtraArguments, ThunkResult,
} from '../../../stores/types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';
import standardAction from '../../../utilities/standard-action';
import { ActionTypes } from '../constants';

export default function fetchStatistics(
  organizationId: string,
): ThunkResult<Promise<void>> {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<void> => {
    dispatch(standardAction(ActionTypes.FETCH_HARMONIZATION_STATISTICS_REQUEST));

    return extra.apiInternal(getState()).classificationStatistics.get({
      organization: organizationId,
    }).then((response) => {
      if (response.ok) {
        dispatch({
          type: ActionTypes.FETCH_HARMONIZATION_STATISTICS_SUCCESS,
          payload: response.body,
        });
      }

      dispatch({
        type: ActionTypes.FETCH_HARMONIZATION_STATISTICS_FAILURE,
        payload: formatErrorResponseV2(response),
      });
    });
  };
}
