import ActionTypes from '../constants/ActionTypes';
import {
  MarketingActions,
  MarketingFeedFormsState,
} from '../types';

const defaultState: MarketingFeedFormsState = {
  error: false,
  loading: false,
  forms: {} as Record<io.flow.internal.v0.enums.MarketingGatewaySchemaCompatibility,
  io.flow.internal.v0.models.MarketingGatewayFeedForm[]>,
};

export default function feedForms(
  state = defaultState,
  action: MarketingActions,
): MarketingFeedFormsState {
  switch (action.type) {
    case ActionTypes.FETCH_FEED_FORMS_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };

    case ActionTypes.FETCH_FEED_FORMS_SUCCESS:
    case ActionTypes.FETCH_FACEBOOK_FEED_FORMS_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        forms: action.payload,
      };
    case ActionTypes.FETCH_FEED_FORMS_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
      };

    default:
      return state;
  }
}
