import { Table } from '@flowio/react-table';
import React from 'react';
import { css } from '@emotion/css';
import PlatformAccountCard from '../../PlatformAccountCard';
import ChannelDetails from './ChannelDetails';
import { Props } from '../types';
import { ButtonShorthandProps } from '../../PlatformAccountCard/types';
import colors from '../../../../../theme/tokens/colors';

const infoStyles = css`
  margin-bottom: 10px;
  flex: 1;
  display: flex;
  color: ${colors.marketing['dark-slate-blue']};

  .wrapper td:first-child {
    width: 240px;
    font-weight: 500;
  }
`;

const ChannelCard: React.FC<Props> = ({
  onViewClick,
  isFbInitialized,
  onRenewAccess,
  onDisconnectChannel,
  organizationId,
  channel,
}) => {
  const { platform, integration_type: integrationType } = channel;
  const isManual = integrationType === 'manual';
  const renewAccessButton: ButtonShorthandProps = {
    content: 'Renew Access',
    outline: true,
    intent: 'neutral',
    disabled: false,
    onClick: onRenewAccess,
  };

  const viewAccountButton: ButtonShorthandProps = {
    content: 'View Channel',
    intent: 'primary',
    disabled: !!channel.error,
    onClick: (): void => {
      if (channel.details) {
        const accountId = (
          channel.details as io.flow.internal.v0.models.MarketingGatewayGoogleChannelDetails
        ).account_id
        || (
          channel.details as io.flow.internal.v0.models.MarketingGatewayFacebookChannelDetails
        ).catalog_id;

        onViewClick({
          organizationId,
          accountId,
          channelType: platform,
        });
      }
    },
  };

  const disconnectButton: ButtonShorthandProps = {
    onClick: () => onDisconnectChannel(platform),
    disabled: false,
    content: `${isManual ? 'Remove Connection' : 'Disconnect'}`,
  };

  const buttons = [viewAccountButton];

  if (platform === 'facebook' && isFbInitialized) {
    buttons.unshift(renewAccessButton);
  }

  buttons.unshift(disconnectButton);

  return (
    <PlatformAccountCard
      platform={platform}
      buttons={buttons}
    >
      <div className={infoStyles} data-automation-id="channel-summary-details">
        <Table striped className="wrapper">
          <ChannelDetails
            platform={channel.platform}
            details={channel.details}
            error={channel.error}
          />
        </Table>
      </div>
    </PlatformAccountCard>
  );
};

ChannelCard.displayName = 'ChannelCard';

export default ChannelCard;
