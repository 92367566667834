import React from 'react';
import { compose } from 'redux';
import { connect, MapStateToProps } from 'react-redux';
import { withFetch } from '@flowio/redux-fetch';

import { RootState, ThunkDispatcher } from '../../../../../stores/types';
import LabsIndex from '../components/LabsIndex';

import { fetchLabsSettings } from '../../../actions';
import { getLabs } from '../../../selectors';
import { StateProps, OwnProps } from '../types';

const fetchAsyncState = (
  dispatch: ThunkDispatcher,
  _getState: () => RootState,
  ownProps: OwnProps,
): Promise<void> => {
  const { organization } = ownProps.params;
  return dispatch(fetchLabsSettings(organization));
};

const mapStateToProps: MapStateToProps<
StateProps, OwnProps, RootState
> = (state: RootState, ownProps: OwnProps) => ({
  organizationId: ownProps.params.organization,
  labs: getLabs(state),
});

export default compose<React.FC<OwnProps>>(
  withFetch(fetchAsyncState),
  connect(mapStateToProps),
)(LabsIndex);
