import standardAction from '../../../utilities/standard-action';
import ActionTypes from '../constants/action-types';
import { RootActionTypes } from '../../../stores/types';
import { FullSelectedItem } from '../types';

export default function showReturnDetailDialog(
  items: FullSelectedItem[],
  pdfLabel?: string,
  pdfInvoice?: string,
): RootActionTypes {
  return standardAction(ActionTypes.SHOW_RETURN_DETAIL_DIALOG, { items, pdfLabel, pdfInvoice });
}
