import { css } from '@emotion/css';
import { css as cssReact } from '@emotion/react';
import colors from '../../../../theme/tokens/colors';

export const orderDetails = css`
  & .content-area-title {
    margin-top: 0;
    margin-bottom: 1.125rem;
  }
`;

export const row = css`
  line-height: 2rem;
`;

export const field = css`
  width: 50%;
  min-width: 32rem;
`;

export const card = css`
  padding-left: 0;
  padding-right: 0;
`;

export const paymentCard = css`
  max-height: 250px;
  overflow-y: scroll;
  ${card}
`;

export const label = css`
  color: #77818f;
  font-weight: 500;
  display: inline-block;
  font-size: 0.875rem;
  padding: 0;
  margin: 0;
  width: 8rem;
`;

export const paymentRefundBtn = css`
  display: inline-block;
  margin: 0.5rem 0;
  float: right;
`;

export const paymentReturnBtn = css`
  display: inline-block;
  margin: 0.5rem 0 0.5rem 0.5rem;
  float: right;
`;

export const giftMessageEmpty = css`
  color: #b5bec9;
  font-style: italic;
`;

export const value = css`
  width: auto;
  margin: 0;
  padding: 0 0 0 1rem;
  font-size: 1rem;
`;

export const giftMessage = css`
  white-space: pre-line;
  ${value}
`;

export const valuePlaceholder = css`
  color: ${colors.silver};
`;

export const actions = css`
  padding: 0;
  text-align: right;
`;

export const priceTotal = css`
  display: flex;
  flex-flow: row nowrap;

  & .order-price-breakdown {
    width: 50%;
  }
`;

export const priceTotalTitle = css`
  width: 50%;
  color: #17181a;
  font-size: 18px;
  font-weight: normal;
  line-height: 24px;
`;

export const fraudDialog = css`
  width: 480px;
  & .dialog-actions {
    justify-content: flex-end;
    margin: 0;
  }
`;

export const fraudAction = css`
  margin-left: 1rem;
`;

export const invoiceCard = css`
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
`;

export const addressHeader = css`
  color: ${colors['flow-blue'][400]};
  font-size: 18px;
  padding-top: 8px;
  font-weight: 500;
  padding-bottom: 12px;
`;

export const updatedAddressTitle = css`
  & h4 {
    color: ${colors['flow-blue'][400]};
    font-size: 18px;
    font-weight: 500;
    padding-left: 0;
  
    &:hover {
      color: ${colors['flow-blue'][300]};
    }
  
    & > svg {
      right: 16px;
    }
  }
`;

export const updatedAddressContent = cssReact`
  padding-left: 0;
  padding-right: 0;
  background-color: #FFF;
  border-top: none;
  padding-top: 0;
`;

export const updatedAddressPanel = cssReact`
  border-bottom: none;
  border-right: none;
  border-left: none;
  border-radius: 0;
`;

export const updatedAddressAccordion = css`
  padding-top: 16px;
`;

export const copyBtn = css`
  position: relative;
  margin-left: 6px;
  top: 1px;
`;
