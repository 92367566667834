import { ErrorResponse, isError } from '../types/misc';
import { PermissionsApiResponse, ValidatedPermissions } from '../types/store';
import requiredPermissions from '../constants/required-permissions';
import { interpretPermissions, verifySDKSetup } from './utils';

const getPermissions = (required = requiredPermissions): Promise<ValidatedPermissions> => {
  try {
    verifySDKSetup();
  } catch (error) {
    return Promise.reject(error);
  }

  return new Promise((resolve, reject) => {
    window.FB.api('/me/permissions', (response: ErrorResponse | PermissionsApiResponse) => {
      if (isError(response)) {
        reject(response.error);
      } else {
        resolve(interpretPermissions(response.data, required));
      }
    });
  });
};

export default getPermissions;
