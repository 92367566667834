import {
  Location,
} from 'history';
import { startCase } from 'lodash';
import { ItemSearchOptions, SearchFilter } from '../catalog/components/product-catalog/types';

const values = ['number', 'id', 'sku', 'product_title', 'product_id', 'vendor', 'barcode', 'physical_good'];

const getItemSearchFiltersFromUrl = (): SearchFilter[] => {
  const urlParams = new URLSearchParams(window.location.search);
  const searchFiltersFromUrl: SearchFilter[] = [];

  values.forEach((key) => {
    const value = urlParams.get(key);
    if (value !== null) {
      searchFiltersFromUrl.push(
        { label: startCase(key), value, key: key as keyof ItemSearchOptions },
      );
    }
  });

  return searchFiltersFromUrl;
};

const getItemSearchFiltersFromUrlSSR = (location?: any): SearchFilter[] => {
  const searchFiltersFromUrl: SearchFilter[] = [];

  values.forEach((key) => {
    const value = location.query[key];
    if (value !== null) {
      searchFiltersFromUrl.push(
        { label: startCase(key), value, key: key as keyof ItemSearchOptions },
      );
    }
  });

  return searchFiltersFromUrl;
};

export const getItemOptionsFromSearchFilters = (location: Location): ItemSearchOptions => {
  const searchFilters = getItemSearchFiltersFromUrlSSR(location);
  return Object.assign(
    {},
    ...searchFilters.map((filter) => ({ [filter.key]: filter.value })),
  );
};

export default getItemSearchFiltersFromUrl;
