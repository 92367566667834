const timing = {
  animationTimingFunction: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)',
};

export const animateRemoveElement = (height: number) => ({
  from: {
    display: 'block',
    height: `${height}px`,
  },
  '20%': {
    transform: 'scale3d(0.9, 0.9, 0.9)',
  },
  '50%': {
    transform: 'scale3d(1.1, 1.1, 1.1)',
  },
  '55%': {
    transform: 'scale3d(1.1, 1.1, 1.1)',
  },
  to: {
    height: 0,
    transform: 'scale3d(0.3, 0.3, 0.3)',
  },
});

export const bounceUpDown = {
  from: {
    animationTimingFunction: 'cubic-bezier(0.215, 0.610, 0.355, 1.000)',
  },

  '60%': {
    timing,
    transform: 'translate3d(0, -5px, 0)',
  },
  '75%': {
    timing,
    transform: 'translate3d(0, 5px, 0)',
  },
  '90%': {
    timing,
    transform: 'translate3d(0, -5px, 0)',
  },
  to: {
    timing,
    transform: 'none',
  },
};

export const fadeOut = {
  from: {
    opacity: 1,
  },

  to: {
    opacity: 0,
  },
};
