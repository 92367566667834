import { RootActionTypes, ThunkResult } from '../../../stores/types';

import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import ActionTypes from '../constants/ActionTypes';

function createRequestAction(): RootActionTypes {
  return {
    type: ActionTypes.UPDATE_SHIPPING_CONFIGURATION_REQUEST,
  };
}

function createSuccessAction(
  payload: io.flow.v0.models.ShippingConfiguration,
): RootActionTypes {
  return {
    type: ActionTypes.UPDATE_SHIPPING_CONFIGURATION_SUCCESS,
    payload,
  };
}

function createFailureAction(
  payload: FormattedErrorMessages,
): RootActionTypes {
  return {
    type: ActionTypes.UPDATE_SHIPPING_CONFIGURATION_FAILURE,
    payload,
    error: true,
  };
}

function updateShippingConfigurationByKey(
  organizationId: string,
  shippingConfigurationKey: string,
  shippingConfigurationForm: io.flow.v0.models.ShippingConfigurationForm,
): ThunkResult<Promise<RootActionTypes>> {
  return function updateShippingConfigurationByKeyEffects(
    dispatch,
    getState,
    extra,
  ): Promise<RootActionTypes> {
    dispatch(createRequestAction());
    return extra.api(getState()).shippingConfigurations.putByKey({
      body: shippingConfigurationForm,
      key: shippingConfigurationKey,
      organization: organizationId,
      expand: ['center'],
    }).then((response) => {
      if (response.ok) {
        return dispatch(createSuccessAction(response.body));
      }

      const errors = formatErrorResponseV2(response);
      return dispatch(createFailureAction(errors));
    });
  };
}

export default updateShippingConfigurationByKey;
