import { OutlineButton, Button } from '@flowio/react-button';

import React from 'react';
import { compose } from 'redux';
import { TextField } from '@flowio/react-text-field';
import isString from 'lodash/isString';
import noop from 'lodash/noop';
import kebabCase from 'lodash/kebabCase';

import Alert from '../../../../../components/alert/alert';
import withValidation from '../../../../../components/with-validation';
import { createMargin, updateMargin } from '../../../actions';
import withSubmit from '../../../../../components/with-submit';
import withRedirectSubmitted from '../../../../../components/with-redirect-submitted';
import validator, { Value } from '../../../../../utilities/validator';
import { getOrganizationId } from '../../../../organization';
import { parseQuery } from '../../../../../utilities/query';
import { WithValidationProps } from '../../../../../components/with-validation/with-validation';
import { FormattedErrorMessages } from '../../../../../utilities/format-error-response-v2';
import { LegacyResponse } from '../../../../../utilities/clients/types/server';
import { ThunkResult, RootState } from '../../../../../stores/types';
import QueryTargetField, { QueryTargetFieldType } from '../../../../../components/query-target-field';
import { DataSource } from '../../../../utilities/types';
import * as styles from './item-margin-form.styles';
import getTextFieldValue from '../../../../../utilities/getTextFieldValue';

interface Props {
  itemMargin: io.flow.v0.models.ItemMargin;
  currency: string;
  editing: boolean;
  error: FormattedErrorMessages;
  onSubmit: Function;
  submitting: boolean;
  onCancel: Function;
}

type ItemMarginFormData = Omit<io.flow.v0.models.ItemMarginPostForm, 'q'> & {
  q: DataSource[];
};

const ItemMarginForm: React.FC<Props & WithValidationProps> = ({
  currency,
  editing,
  error,
  fields: {
    name, q, percent, fixed,
  },
  fields,
  onSubmit,
  onSubmitValidate,
  onCancel,
}) => {
  const handleSubmit = ({ isValid }: WithValidationProps, formData: ItemMarginFormData): void => {
    if (isValid) {
      const action = editing ? 'update' : 'create';
      onSubmit(action, formData);
    }
  };

  const handleMarginChange = (options: DataSource[]): void => {
    fields.q.events.onBlur(options);
    fields.q.events.onChange(options);
  };

  const renderErrorsIfNecessary = (): React.ReactNode => {
    if (error && error.messages) {
      return error.messages.map(({ message }) => (
        <Alert key={kebabCase(message)} type="failure">
          {message}
        </Alert>
      ));
    }

    return null;
  };

  return (
    <form
      className="item-margin-form"
      name="new_item_margin"
      onSubmit={onSubmitValidate(handleSubmit)}
    >
      {renderErrorsIfNecessary()}
      <h3 className={styles.label}>
        Name
      </h3>
      <TextField
        onChange={(e) => name.events.onChange(e)}
        onBlur={(e) => name.events.onBlur(e)}
        className={styles.textField}
        name="name"
        hintText="Gucci Margin"
        intent={name.error ? 'negative' : 'neutral'}
        feedback={name.error}
        fluid
        value={getTextFieldValue(name.value)}
        aria-required
      />
      <h3 className={styles.label}>
        Targeting
      </h3>
      <div className={styles.queryTargetField}>
        <QueryTargetField
          errorText={q.error || undefined}
          hintText="Enter an item number or name"
          onChange={handleMarginChange}
          defaultValue={q.value as string[]}
          style={{ display: 'block' }}
          type={QueryTargetFieldType.TYPE_ALL}
          value={q.value}
          multiple
        />
      </div>
      <h3 className={styles.label}>
        Margin Type
      </h3>
      {((): React.ReactNode => {
        const marginErrors = [percent.error, fixed.error];
        return marginErrors.map((err) => {
          if (err) {
            return (
              <Alert key={kebabCase(err)} type="failure">
                {err}
              </Alert>
            );
          }
          return null;
        });
      })()}
      <div className={styles.marginType}>
        <TextField
          fluid
          labelCols={2}
          labelText="Percent Margin"
          rightIcon={(
            <span className={styles.addon}>
              %
            </span>
          )}
          name="percent"
          value={getTextFieldValue(percent.value)}
          inline
          onValueChange={(value: string): void => percent.events.onBlur(value)}
        />
      </div>

      <div className={styles.marginType}>
        <TextField
          fluid
          labelCols={2}
          labelText="Fixed Margin"
          rightIcon={(
            <span className={styles.addon}>
              {currency}
            </span>
          )}
          name="fixed"
          value={getTextFieldValue(fixed.value)}
          inline
          onValueChange={(value: string): void => fixed.events.onBlur(value)}
        />
      </div>

      <section className={styles.actions}>
        <OutlineButton
          content="Cancel"
          name="cancel"
          onClick={(): void => onCancel()}
          type="button"
        />
        {editing ? (
          <Button
            className={styles.primaryButton}
            content="Update Function"
            intent="primary"
            name="update_function"
            type="submit"
          />
        ) : (
          <Button
            className={styles.primaryButton}
            content="Create Function"
            intent="primary"
            name="create_function"
            type="submit"
          />
        )}
      </section>
    </form>
  );
};

function submitForm(
  action: string,
  formData: ItemMarginFormData,
  _state: unknown,
  ownProps: Props,
): ThunkResult<Promise<LegacyResponse<io.flow.v0.models.ItemMargin | unknown>>> {
  const queryString = formData.q.map((val: DataSource) => val.text).join(' ');

  const formattedData: io.flow.v0.models.ItemMarginPostForm = {
    ...formData,
    q: queryString,
  };

  switch (action) {
    case 'create': return createMargin(formattedData);
    case 'update': return updateMargin(ownProps.itemMargin.key, formattedData);
    default: throw new Error(`Unhandled form submission action: ${action}`);
  }
}

function getRedirectLocation(state: RootState): string {
  const organization = getOrganizationId(state);
  const experience = state.experience.current && state.experience.current.key;
  return `/${organization}/experience/${experience}/pricing`;
}

function parseMargin(value?: string): string | number {
  if (isString(value) && (value.trim() === '-' || /(0 - 9)*\./.exec(value.trim()))) {
    return value;
  }

  // parseFloat will strip out any trailing non-numeric characters
  return (value && parseFloat(value)) || 0;
}

ItemMarginForm.displayName = 'ItemMarginForm';

ItemMarginForm.defaultProps = {
  editing: false,
  itemMargin: undefined,
  error: undefined,
  submitting: undefined,
  onCancel: noop,
};

export default compose<React.FC<Partial<Props>>>(
  withSubmit(submitForm),
  withRedirectSubmitted(getRedirectLocation),
  withValidation(({ itemMargin = {} }: { itemMargin: Partial<io.flow.v0.models.ItemMargin> }) => ({
    name: {
      defaultValue: itemMargin.name,
      validations: [{
        required: true,
        message: 'Please enter a name for this margin.',
      }],
    },
    q: {
      defaultValue: (): ({ label?: string, text?: string } | undefined)[] => {
        if (itemMargin.q) {
          return parseQuery(itemMargin.q).map((query) => ({
            label: query.value,
            text: query.value,
          }));
        }

        return [];
      },
      validations: [{
        required: true,
        message: 'Please enter a targeting query.',
      }],
    },
    percent: {
      defaultValue: itemMargin.percent || 0,
      validations: (value: number, fields: WithValidationProps['fields']): string => {
        const fixedValue = fields.fixed ? fields.fixed.value : undefined;
        if (!value && !fixedValue) {
          return 'Please enter a Percent or Fixed margin value.';
        }

        // Allow the negative symbol for the initial character
        // adding a digit after will properly validate as numeric (below)
        if (value.toString().trim() === '-') return '';

        if (!validator.validate(value.toString(), { pattern: 'numeric' })) {
          return 'Fixed margin must be a number.';
        }

        return '';
      },
      parse: (value: Value): string | number => parseMargin(value as string),
      format: (value: Value): string => (value === 0 ? '' : (value as string).toString()),
    },
    fixed: {
      defaultValue: itemMargin.fixed || 0,
      validations: (value: Value): string => {
        // Allow the negative symbol for the initial character
        // adding a digit after will properly validate as numeric (below)
        if ((value as number).toString().trim() === '-') return '';

        if (!validator.validate((value as number).toString(), { pattern: 'numeric' })) {
          return 'Fixed margin must be a number.';
        }

        return '';
      },
      parse: (value: Value): string | number => parseMargin(value as string),
      format: (value: Value): string => (value === 0 ? '' : (value as string).toString()),
    },
    position: {
      defaultValue: itemMargin.position || 0,
      validations: [{
        required: false,
      }],
      parse: (value: Value): number => parseInt((value as string), 10) || 0,
    },
  })),
)(ItemMarginForm);
