import { $HttpResponse } from '@flowio/api-sdk';
import ActionTypes from '../constants/ActionTypes';
import { ApiError } from '../../../utilities/clients/api-error';
import standardAction from '../../../utilities/standard-action';
import {
  RootState, ThunkExtraArguments, ThunkResult, ThunkDispatcher,
} from '../../../stores/types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';

type Args = {
  organizationId: string;
  channelName: string;
};

const requestChannel = ({
  organizationId,
  channelName,
}: Args): ThunkResult<Promise<void>> => (
  dispatch: ThunkDispatcher,
  getState: () => RootState,
  extra: ThunkExtraArguments,
): Promise<void> => {
  dispatch(standardAction(ActionTypes.REQUEST_CHANNEL_REQUEST));
  const state = getState();

  return extra.apiInternal(state).marketingGatewayChannels.postNewChannelRequest({
    organization: organizationId,
    body: {
      requested_by_user_id: state.defaultReducer.user?.id || '',
      channel_name: channelName,
    },
  }).then((response) => {
    if (response.ok) {
      dispatch(
        standardAction(ActionTypes.REQUEST_CHANNEL_SUCCESS),
      );
      return;
    }

    const errorResponse = response as $HttpResponse<io.flow.v0.models.GenericError>;
    const error = new ApiError(errorResponse);

    dispatch({
      type: ActionTypes.REQUEST_CHANNEL_FAILURE,
      payload: formatErrorResponseV2(errorResponse),
    });
    throw error;
  });
};

export default requestChannel;
