import ActionTypes from '../constants/ActionTypes';
import {
  MarketingActions,
  MarketingConnectionConfirmationDialogState,
} from '../types';

const defaultState: MarketingConnectionConfirmationDialogState = {
  showDialog: false,
};

export default function connectionConfirmationDialogReducer(
  state = defaultState,
  action: MarketingActions,
): MarketingConnectionConfirmationDialogState {
  switch (action.type) {
    case ActionTypes.CONNECTION_CONFIRMATION_DIALOG_OPEN:
      return {
        ...state,
        showDialog: true,
        metadata: action.payload,
      };
    case ActionTypes.CONNECTION_CONFIRMATION_DIALOG_CLOSE:
      return {
        ...state,
        showDialog: false,
        metadata: undefined,
      };
    default:
      return state;
  }
}
