import ActionTypes from '../constants/action-types';

import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import { RootActionTypes, ThunkExtraArguments, ThunkResult } from '../../../stores/types';
import hideApproveFraudReviewDialog from './hide-approve-fraud-review-dialog';
import hideDeclineFraudReviewDialog from './hide-decline-fraud-review-dialog';
import { createToast } from '../../console/actions';

function createRequestAction(): RootActionTypes {
  return {
    type: ActionTypes.CREATE_FRAUD_REVIEW_DECISION_REQUEST,
  };
}

function createSuccessAction(
  payload: io.flow.internal.v0.models.FraudReviewDecision,
): RootActionTypes {
  return {
    type: ActionTypes.CREATE_FRAUD_REVIEW_DECISION_SUCCESS,
    payload,
  };
}

function createFailureAction(error: FormattedErrorMessages): RootActionTypes {
  return {
    type: ActionTypes.CREATE_FRAUD_REVIEW_DECISION_FAILURE,
    payload: error,
    error: true,
  };
}

export default function createFraudReviewDecision(
  organization: string,
  fraudReviewId: string,
  fraudReviewDecisionForm: io.flow.internal.v0.models.FraudReviewDecisionForm,
): ThunkResult<Promise<void>> {
  return function createFraudReviewDecisionEffects(
    dispatch,
    getState,
    extra: ThunkExtraArguments,
  ): Promise<void> {
    dispatch(createRequestAction());

    return extra.apiInternal(getState()).fraudReviewDecisions.post({
      body: fraudReviewDecisionForm,
      organization,
      id: fraudReviewId,
    }).then((response) => {
      if (response.ok) {
        dispatch(createSuccessAction(response.body));
        dispatch(hideApproveFraudReviewDialog());
        dispatch(hideDeclineFraudReviewDialog());
        return;
      }

      const formattedErrors = formatErrorResponseV2(response);
      dispatch(hideDeclineFraudReviewDialog());
      dispatch(hideApproveFraudReviewDialog());
      dispatch(createToast({
        intent: 'negative',
        content: 'Failed to update fraud status',
      }));
      dispatch(createFailureAction(formattedErrors));
    });
  };
}
