import { withFetch } from '@flowio/redux-fetch';
import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { RootActionTypes, RootState, ThunkDispatcher } from '../../../../../stores/types';
import {
  toggleReauthenticationModal,
} from '../../../../facebook/actions';
import {
  getIsInitialized as getIsFbInitialized,
} from '../../../../facebook/selectors';
import {
  fetchCountries,
  fetchLanguages,
  getCountries,
  getLanguages,
} from '../../../../reference';
import {
  fetchChannels,
  goToFeedsPage,
  toggleAuthDialog,
  toggleDisconnectChannelDialog,
  toggleRequestChannelsDialog,
} from '../../../actions';
import { getChannelsError } from '../../../selectors';
import getChannelsPartitioned from '../../../selectors/getChannelsPartitioned';
import { MarketingGatewayIntegratedPlatform } from '../../../types';
import LandingPage from '../components/LandingPage';
import {
  DispatchProps,
  GoToMarketingAccountArgs, OwnProps,
  StateProps,
} from '../types';

const getAsyncState = (
  dispatch: ThunkDispatcher,
  _getState: () => RootState,
  props: OwnProps,
): Promise<unknown[]> => {
  const { params } = props;
  const { organization: organizationId } = params;
  return Promise.all([
    dispatch(fetchLanguages()),
    dispatch(fetchCountries()),
    dispatch(fetchChannels({ organizationId })),
  ]);
};

const mapStateToProps = (
  state: RootState,
  {
    params: { organization },
  }: OwnProps,
): StateProps => ({
  organizationId: organization,
  error: getChannelsError(state),
  isFbInitialized: getIsFbInitialized(state),
  channels: getChannelsPartitioned(state),
  countries: getCountries(state),
  languages: getLanguages(state),
});

const handleRequestNewChannel = () => (
  dispatch: ThunkDispatcher,
): void => {
  dispatch(toggleRequestChannelsDialog(true));
};

const goMarketingAccountAndRetrieveFacebookAccount = ({
  organizationId,
  accountId,
  channelType,
}: GoToMarketingAccountArgs) => (dispatch: ThunkDispatcher): void => {
  dispatch(goToFeedsPage({
    organizationId,
    platformId: channelType,
    accountId,
  }));
};

const handleAddAccount = (
  organizationId: string,
  platformId: MarketingGatewayIntegratedPlatform,
  isManual?: boolean,
) => (
  dispatch: ThunkDispatcher,
): void => {
  dispatch(
    toggleAuthDialog(true, { organizationId, platformId, isManual }),
  );
};

const handleRenewAccess = (
) => (
  dispatch: ThunkDispatcher,
): void => {
  dispatch(
    toggleReauthenticationModal(true),
  );
};

const handleDisconnectChannel = (
  channel: io.flow.internal.v0.enums.MarketingGatewayPlatform,
) => (
  dispatch: ThunkDispatcher,
): void => {
  dispatch(
    toggleDisconnectChannelDialog(true, channel),
  );
};

const mapDispatchToProps = (
  dispatch: Dispatch<RootActionTypes>,
): DispatchProps => bindActionCreators({
  onDisconnectChannel: handleDisconnectChannel,
  onAccountCardClicked: goMarketingAccountAndRetrieveFacebookAccount,
  onRenewAccess: handleRenewAccess,
  onAddAccountClicked: handleAddAccount,
  onRequestNewChannel: handleRequestNewChannel,
}, dispatch);

export default compose(
  withFetch(getAsyncState),
  connect(mapStateToProps, mapDispatchToProps),
)(LandingPage);
