import { RSAA } from '@flowio/redux-async-middleware';
import { apiInternal } from '../../../utilities/clients';
import { createSuccessDescriptor, createFailureDescriptor } from '../../../utilities/redux-async-middleware';
import { ActionTypes } from '../constants';

function createCenterQueryBuilder(organizationId, queryBuilderForm) {
  // TODO: Assert query builder form is valid.
  return {
    [RSAA]: {
      types: [
        ActionTypes.CREATE_CATALOG_QUERY_BUILDER_REQUEST,
        createSuccessDescriptor({ type: ActionTypes.CREATE_CATALOG_QUERY_BUILDER_SUCCESS }),
        createFailureDescriptor({ type: ActionTypes.CREATE_CATALOG_QUERY_BUILDER_FAILURE }),
      ],
      callAPI(state) {
        return apiInternal.queryBuilders(state)
          .post(organizationId, {
            body: queryBuilderForm,
          });
      },
    },
  };
}

export default createCenterQueryBuilder;
