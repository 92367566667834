import {
  MarketingActions,
  MarketingGoogleConnectionAccountsState,
} from '../types';

import ActionTypes from '../constants/ActionTypes';
import ReadyStates from '../constants/ReadyStates';

const defaultState: MarketingGoogleConnectionAccountsState = { };

export default function (
  state = defaultState,
  action: MarketingActions,
): MarketingGoogleConnectionAccountsState {
  switch (action.type) {
    case ActionTypes.FETCH_ORGANIZATION_GOOGLE_ACCOUNTS_REQUEST:
      return {
        ...state,
        error: undefined,
      };

    case ActionTypes.FETCH_ORGANIZATION_GOOGLE_ACCOUNTS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        readyState: ReadyStates.FULFILLED,
      };

    case ActionTypes.FETCH_ORGANIZATION_GOOGLE_ACCOUNTS_FAILURE:
      return {
        ...state,
        error: action.payload,
        readyState: ReadyStates.REJECTED,
      };

    default:
      return state;
  }
}
