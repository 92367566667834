import update from 'immutability-helper';

import ActionTypes from '../constants/action-types';
import ReadyStates from '../constants/ready-states';
import { AnalyticsState } from '../types';
import { RootActionTypes } from '../../../stores/types';

const defaultState: AnalyticsState['destinationCountries'] = {
  readyState: ReadyStates.PENDING,
};

export default function (state: AnalyticsState['destinationCountries'] = defaultState, action: RootActionTypes): AnalyticsState['destinationCountries'] {
  switch (action.type) {
    case ActionTypes.FETCH_DESTINATION_COUNTRIES_REQUEST:
      return update(state, {
        $unset: ['error'],
        readyState: { $set: ReadyStates.LOADING },
        params: { $set: action.params },
      });
    case ActionTypes.FETCH_DESTINATION_COUNTRIES_SUCCESS:
      return update(state, {
        readyState: { $set: ReadyStates.FULFILLED },
        data: { $set: action.payload },
      });
    case ActionTypes.FETCH_DESTINATION_COUNTRIES_FAILURE:
      return update(state, {
        error: { $set: action.payload },
        readyState: { $set: ReadyStates.REJECTED },
      });
    default:
      return state;
  }
}
