import { RootState } from '../../stores/types';
import { getOrganizationId } from '../../modules/organization/selectors';

interface RollbarMiddlewareArgs<T> {
  state: RootState;
  options: T;
}

interface ExtendedRollbarMiddlewareArgs {
  organization: string;
}

/**
 * Add checkout specific 'extra' information for rollbar.
 */
export default function reduxRollbarMiddlewareExtend(
  { state, options }: RollbarMiddlewareArgs<object>,
): ExtendedRollbarMiddlewareArgs {
  const organization = getOrganizationId(state);

  return {
    ...options,
    organization,
  };
}
