import { $HttpResponse } from '@flowio/api-sdk';
import ActionTypes from '../constants/action-types';

import withPagingAndSorting, { isPagingAndSortingResponse } from '../../../utilities/with-paging-and-sorting-v2';
import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import { WithPagingAndSortingResponse, WithPagingAndSortingResult } from '../../../utilities/types';

function createRequestAction(): RootActionTypes {
  return {
    type: ActionTypes.FETCH_ORDERS_REQUEST,
  };
}

function createSuccessAction(
  payload: WithPagingAndSortingResult<io.flow.v0.models.Order[]>,
): RootActionTypes {
  return {
    type: ActionTypes.FETCH_ORDERS_SUCCESS,
    payload,
  };
}

function createFailureAction(errors: FormattedErrorMessages): RootActionTypes {
  return {
    type: ActionTypes.FETCH_ORDERS_FAILURE,
    error: true,
    payload: errors,
  };
}

export default function fetchOrder(
  organization: string,
  orderId: string,
): ThunkResult<Promise<void>> {
  return function fetchOrderEffects(
    dispatch,
    _getState,
    extra,
  ): Promise<void> {
    const options = {
      identifier: [orderId],
    };

    dispatch(createRequestAction());

    return withPagingAndSorting<io.flow.v0.models.Order[]>(
      options,
      (params, state) => extra.api(state).orders.get(
        {
          organization,
          ...params,
        },
      ),
    )().then((
      response:
      WithPagingAndSortingResponse<io.flow.v0.models.Order[]>
      | $HttpResponse<io.flow.v0.models.GenericError>,
    ) => {
      if (isPagingAndSortingResponse<io.flow.v0.models.Order[]>(response)) {
        dispatch(createSuccessAction(response.result));
        return;
      }

      const errors = formatErrorResponseV2(response);
      dispatch(createFailureAction(errors));
    });
  };
}
