import isEvent from './is-event';

/**
 * Returns the selected values from a collection of all <option> elements in a drop-down list.
 */
function getSelectedValues(options = []) {
  return options.reduce((selected, option) => {
    if (option.selected) selected.push(option.value);
    return selected;
  }, []);
}

/**
 * Returns the field value from a React SyntheticEvent object. If the specified parameter is not a
 * React SyntheticEvent, then it's considered to be the value for the field.
 */
export default function getValueFromEvent(event) {
  if (!isEvent(event)) {
    return event;
  }

  if (event.nativeEvent && event.nativeEvent.text !== undefined) {
    return event.nativeEvent.text;
  }

  const {
    target: {
      type, value, checked, files,
    }, dataTransfer,
  } = event;

  if (type === 'checkbox') {
    return checked;
  }

  if (type === 'file') {
    return files || (dataTransfer && dataTransfer.files);
  }

  if (type === 'select-multiple') {
    return getSelectedValues(event.target.options);
  }

  if (value !== '' && (type === 'number' || type === 'range')) {
    return parseFloat(value);
  }

  return value;
}
