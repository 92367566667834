import { createSelector } from 'reselect';

import CONTROL_ORGANIZATION_KEY from '../constants/feature-control-organization';
import { getOrganizationId } from '../../organization/selectors';

export const getIsControlOrganization = createSelector(
  getOrganizationId,
  (organizationId) => (organizationId === CONTROL_ORGANIZATION_KEY),
);

export default getIsControlOrganization;
