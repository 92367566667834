import keyMirror from '../../../utilities/keymirror';

const ActionTypes = keyMirror({
  FETCH_GLOBAL_RESULTS_REQUEST: null,
  FETCH_GLOBAL_RESULTS_SUCCESS: null,
  FETCH_GLOBAL_RESULTS_FAILURE: null,
  SET_RECENT_SEARCHES: null,
  GET_RECENT_SEARCHES: null,
  CLEAR_RECENT_SEARCHES: null,
  UPDATE_LOADING_STATE: null,
}, 'GLOBAL_SEARCH');

export default ActionTypes;
