import { css } from '@emotion/css';

export const formGroup = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  margin-bottom: 20px !important;
  align-items: center;

  & label {
    font-weight: 400;
  }

  font-weight: 400;
`;

export const exportsCard = css`
  padding: 0 15px;
`;

export const exportsForm = css`
  padding: 5px;
`;

export const exportsDialog = css`
  padding: 25px 15px;
`;

export const errorText = css`
  font-size: 14px;
`;

export const timeDropdownWrapper = css`
  width: 25%;
  display: inline-block !important;
  margin-right: 10px !important;

  &.format {
    width: 15%;
  }
`;

export const fileType = css`
  & svg {
    width: 12px !important;
  }
`;
