import update from 'immutability-helper';
import ActionTypes from '../constants/action-types';
import ReadyStates from '../constants/ready-states';
import { AnalyticsState } from '../types';
import { RootActionTypes } from '../../../stores/types';

const defaultState: AnalyticsState['gmvByCountry'] = {
  readyState: ReadyStates.PENDING,
  params: {},
  ui: {},
};

export default function (
  state: AnalyticsState['gmvByCountry'] = defaultState,
  action: RootActionTypes,
): AnalyticsState['gmvByCountry'] {
  switch (action.type) {
    case ActionTypes.FETCH_COUNTRY_REVENUE_REQUEST:
      return update(state, {
        $unset: ['error'],
        readyState: { $set: ReadyStates.LOADING },
        params: { $set: action.params },
      });
    case ActionTypes.FETCH_COUNTRY_REVENUE_SUCCESS:
      return update(state, {
        data: { $set: action.payload },
        readyState: { $set: ReadyStates.FULFILLED },
      });
    case ActionTypes.FETCH_COUNTRY_REVENUE_FAILURE:
      return update(state, {
        error: { $set: action.payload },
        readyState: { $set: ReadyStates.REJECTED },
      });

    case ActionTypes.EXPORT_TIMELINE_REQUEST:
      return update(state, {
        ui: {
          $unset: ['error', 'submitted'],
          submitting: { $set: true },
        },
      });
    case ActionTypes.EXPORT_TIMELINE_SUCCESS:
      return update(state, {
        ui: {
          $unset: ['submitting'],
          submitted: { $set: true },
        },
      });
    case ActionTypes.EXPORT_TIMELINE_FAILURE:
      return update(state, {
        ui: {
          $unset: ['submitting'],
          error: { $set: action.payload },
          submitted: { $set: true },
        },
      });
    case ActionTypes.EXPORT_TIMELINE_RESET_STATE:
      return update(state, {
        ui: {
          $unset: ['submitting', 'error', 'submitted'],
        },
      });
    default:
      return state;
  }
}
