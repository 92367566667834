import { ThunkResult } from '../../../stores/types';
import changeStatus from './change-status';
import toastStatusChange from './toastStatusChange';

export default function requestStatusChange(
  organizationId: string,
  featureKey: string,
  status: io.flow.internal.v0.enums.FeatureStatus,
): ThunkResult<Promise<void>> {
  return function requestStatusChangeSideEffects(dispatch) {
    return dispatch(changeStatus(organizationId, featureKey, status)).then((response) => {
      if (response.ok) {
        const feature = response.body;
        return dispatch(toastStatusChange(feature));
      }

      return undefined;
    });
  };
}
