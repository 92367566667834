import { formValues } from 'redux-form';
import QueryFilter from '../components/QueryFilter';

interface QueryFilterProps {
  availableFilters: io.flow.v0.models.AvailableFilterStructured[];
  name: string;
  selectedField?: string;
  selectedOperator?: string;
  onRequestRemove?: (...args: any) => void;
}

const mapValuesToProps = (props: QueryFilterProps) => {
  const { name } = props;
  return {
    selectedField: `${name}.field`,
    selectedOperator: `${name}.operator`,
  };
};

export default formValues<{}, QueryFilterProps>(mapValuesToProps)(QueryFilter);
