import { RootActionTypes } from '../../../stores/types';
import ActionTypes from '../constants/action-types';

const initialState = {
  showReauthenticationModal: false,
  showLogoutModal: false,
  isInitialized: false,
  isAuthenticating: false,
};

const ui = (state = initialState, action: RootActionTypes) => {
  switch (action.type) {
    case ActionTypes.INITIALIZE_SDK_SUCCESS: {
      return {
        ...state,
        isInitialized: true,
      };
    }
    case ActionTypes.REAUTHENTICATION_REQUEST: {
      return {
        ...state,
        isAuthenticating: true,
      };
    }
    case ActionTypes.REAUTHENTICATION_SUCCESS: {
      return {
        ...state,
        isAuthenticating: false,
        showReauthenticationModal: false,
      };
    }
    case ActionTypes.REAUTHENTICATION_FAILURE: {
      return {
        ...state,
        isAuthenticating: false,
      };
    }
    case ActionTypes.TOGGLE_REAUTHENTICATION_MODAL: {
      return {
        ...state,
        showReauthenticationModal: action.payload,
      };
    }
    case ActionTypes.TOGGLE_LOGOUT_MODAL: {
      return {
        ...state,
        showLogoutModal: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default ui;
