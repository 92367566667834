import { Close } from '@flowio/react-icons';
import { formValues } from 'redux-form';

import { Button } from '@flowio/react-button';
import React from 'react';
import includes from 'lodash/includes';
import noop from 'lodash/noop';

import * as styles from './AddRuleWorksheetForm.styles';

import RuleConditionField from './RuleConditionField';
import RuleConditionOperator from './RuleConditionOperator';
import RuleConditionValues from './RuleConditionValues';
import { RuleConditionProps } from '../../types/components';
import formatFilterValue from '../../utilities/formatFilterValue';

const RuleCondition: React.FC<RuleConditionProps> = ({
  availableFilters,
  name,
  onRequestRemove,
  selectedField = '',
  selectedOperator,
}) => {
  const selectedFilter = formatFilterValue(availableFilters, selectedField);
  const multiple = includes(['in', 'not in'], selectedOperator);

  return (
    <div className={styles.ruleCondition}>
      <div className={styles.ruleConditionFieldset}>
        <RuleConditionField name={`${name}.field`} availableFilters={availableFilters} />
        {selectedFilter && (
          <RuleConditionOperator name={`${name}.operator`} availableFilter={selectedFilter} />
        )}
        {selectedFilter && selectedOperator && (
          <RuleConditionValues
            name={`${name}.values`}
            availableFilter={selectedFilter}
            multiple={multiple}
          />
        )}
      </div>
      <Button
        className={styles.ruleConditionRemoveButton}
        onClick={onRequestRemove}
        type="button"
      >
        <Close width="16" height="16" />
      </Button>
    </div>
  );
};

RuleCondition.displayName = 'RuleCondition';

RuleCondition.defaultProps = {
  selectedField: undefined,
  selectedOperator: undefined,
  onRequestRemove: noop,
};

const mapValuesToProps = (props: RuleConditionProps) => {
  const { name } = props;
  return {
    selectedField: `${name}.field`,
    selectedOperator: `${name}.operator`,
  };
};

const DecoratedQueryFilter = formValues<
{},
RuleConditionProps
>(mapValuesToProps)(RuleCondition);

export default DecoratedQueryFilter;
