import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  getGoogleConnectionAccountError,
} from '../../../selectors';

import GoogleFeeds from '../components/GoogleFeeds';
import { RootState } from '../../../../../stores/types';
import { OwnProps, StateProps } from '../types';

const mapStateToProps = () => createStructuredSelector<RootState, {}, StateProps>({
  error: getGoogleConnectionAccountError,
});

export default connect<StateProps, {}, OwnProps, RootState>(mapStateToProps)(GoogleFeeds);
