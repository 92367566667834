import { ActionTypes } from '../constants';
import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';

interface UpdateShippingLaneOptions {
  organizationId: string;
  shippingConfigurationKey: string;
  shippingLaneId: string;
  shippingLaneForm: io.flow.v0.models.ShippingLaneForm;
}

function createRequestAction(): RootActionTypes {
  return {
    type: ActionTypes.UPDATE_SHIPPING_LANE_REQUEST,
  };
}

function createSuccessAction(
  payload: io.flow.v0.models.ShippingLane,
  organizationId: string,
  shippingConfigurationKey: string,
): RootActionTypes {
  return {
    type: ActionTypes.UPDATE_SHIPPING_LANE_SUCCESS,
    meta: {
      organizationId,
      shippingConfigurationKey,
    },
    payload,
  };
}

function createFailureAction(
  payload: FormattedErrorMessages,
): RootActionTypes {
  return {
    type: ActionTypes.UPDATE_SHIPPING_LANE_FAILURE,
    payload,
    error: true,
  };
}

function updateShippingLane({
  organizationId,
  shippingConfigurationKey,
  shippingLaneId,
  shippingLaneForm,
}: UpdateShippingLaneOptions): ThunkResult<
  Promise<RootActionTypes>
  > {
  return function updateShippingLaneEffects(
    dispatch,
    getState,
    extra,
  ): Promise<RootActionTypes> {
    dispatch(createRequestAction());

    return extra.api(getState()).shippingConfigurations.putLanesByKeyAndId({
      body: shippingLaneForm,
      id: shippingLaneId,
      key: shippingConfigurationKey,
      organization: organizationId,
      expand: ['center'],
    }).then((response) => {
      if (response.ok) {
        return dispatch(createSuccessAction(
          response.body,
          organizationId,
          shippingConfigurationKey,
        ));
      }

      const errors = formatErrorResponseV2(response);
      return dispatch(createFailureAction(errors));
    });
  };
}

export default updateShippingLane;
