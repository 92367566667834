import ActionTypes from '../constants/action-types';
import { RootActionTypes } from '../../../stores/types';
import { OrderNotesState } from '../types';

const initialState: OrderNotesState = {
  loading: true,
  errors: undefined,
  data: undefined,
};

export default function reducer(
  state = initialState,
  action: RootActionTypes,
): OrderNotesState {
  switch (action.type) {
    case ActionTypes.FETCH_ORDER_NOTES_REQUEST:
      return {
        loading: true,
        errors: undefined,
        data: undefined,
      };
    case ActionTypes.FETCH_ORDER_NOTES_FAILURE:
      return {
        loading: false,
        errors: action.payload,
        data: undefined,
      };
    case ActionTypes.FETCH_ORDER_NOTES_SUCCESS:
      return {
        loading: false,
        errors: undefined,
        data: action.payload,
      };
    case ActionTypes.ORDER_NOTE_CREATED:
      return {
        loading: false,
        errors: undefined,
        data: (state.data || []).concat(action.payload),
      };
    case ActionTypes.ORDER_NOTE_EDITED:
      return {
        loading: false,
        errors: undefined,
        data: state.data!.map(
          (note) => (note.id === action.payload.id ? action.payload : note),
        ),
      };
    case ActionTypes.ORDER_NOTE_DELETED:
      return {
        loading: false,
        errors: undefined,
        data: (state.data || []).filter((on) => on.id !== action.payload),
      };
    default:
      return state;
  }
}
