import { RootActionTypes } from '../../../stores/types';
import DialogName from '../constants/DialogName';
import updateDialog from './updateDialog';

interface ShowAddFacebookAccountDialogProps {
  organizationId: string;
  catalogId?: number;
}

const showFacebookCountryFeedDialog = (
  { organizationId, catalogId }: ShowAddFacebookAccountDialogProps,
): RootActionTypes => updateDialog(
  DialogName.FB_COUNTRY_FEED_DIALOG, {
    organizationId,
    catalogId,
    open: true,
  },

);

export default showFacebookCountryFeedDialog;
