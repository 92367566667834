import { Field } from 'redux-form';
import {
  Card, CardHeader, CardContent, CardTitle,
} from '@flowio/react-card';
import React from 'react';
import { TextField } from '../../../../components/ReduxFormFieldAdapters';

import presence from '../../../../utilities/validators/presence';

const validateName = [
  presence({
    allowEmpty: false,
    message: 'Enter shipping tier name',
  }),
];

const TierNameCard: React.FC<{}> = () => (
  <Card>
    <CardHeader dividing>
      <CardTitle content="Name Shipping Tier" />
    </CardHeader>
    <CardContent>
      <Field
        component={TextField}
        name="name"
        autoComplete="off"
        fluid
        id="name"
        inline
        labelText="Shipping Tier Name"
        labelFor="name"
        validate={validateName}
        gutter
      />
      <Field
        component={TextField}
        name="message"
        autoComplete="off"
        fluid
        id="message"
        inline
        labelText="Optional Message"
        labelFor="message"
        hint="(Optional) This message will show up after Shipping Tier Name and Delivery Window Estimates."
        gutter
      />
    </CardContent>
  </Card>
);

TierNameCard.displayName = 'TierNameCard';

export default TierNameCard;
