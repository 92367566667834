import { compose } from 'redux';
import { connect, MapStateToProps } from 'react-redux';
import { browserHistory, type RouterState } from 'react-router';
import { withFetch } from '@flowio/redux-fetch';
import head from 'lodash/head';
import isNil from 'lodash/isNil';
import moment from 'moment';
import map from 'lodash/map';

import AccountTransactions from '../components/account-transactions';
import { fetchOrganization, getOrganization } from '../../../../organization';
import changeAccount from '../../../actions/change-account';
import exportAccountOrders from '../../../actions/export-account-orders';
import exportAccountTransactions from '../../../actions/export-account-transactions';
import fetchOrganizationAccount from '../../../actions/fetch-organization-account';
import fetchOrganizationAccounts from '../../../actions/fetch-organization-accounts';
import fetchStatements from '../../../actions/fetch-statements';
import fetchTransactions from '../../../actions/fetch-transactions';
import getUserEmail from '../../../../console/selectors/get-user-email';
import {
  getAccountCurrentPageResults,
  getCurrentAccount,
  getIsTransactionCurrentPageFirstPage,
  getIsTransactionCurrentPageLastPage,
  getStatementsCurrentPageResults,
  getTransactionCurrentPageCreatedFrom,
  getTransactionCurrentPageCreatedTo,
  getTransactionCurrentPageError,
  getTransactionCurrentPageNumber,
  getTransactionCurrentPageResults,
  getTransactionCurrentPageStatement,
  getTransactionCurrentPageStatusFilter,
} from '../../../selectors';
import { ThunkDispatcher, ThunkResult } from '../../../../../middlewares/types';
import { RootState } from '../../../../../stores/types';
import { ExportAccountForm, FetchTransactionsParams, ExportResponseType } from '../../../types';
import { LegacyResponse } from '../../../../../utilities/clients/types/server';
import { DispatchProps, StateProps, OwnProps } from '../types';

const fetchAsyncState = (
  dispatch: ThunkDispatcher,
  getState: () => RootState,
  ownProps: RouterState,
): Promise<unknown> => {
  const { organization, key, currency } = ownProps.params;
  // What's going on here? If the currency code is not specified as a URL
  // parameter, then we will attempt to use the currency for the first account
  // available to the organization. In the event that an organization does not
  // have any accounts, we won't dispatch the remaining actions.
  return Promise.all([
    dispatch(fetchOrganization(organization)),
    dispatch(fetchOrganizationAccounts(organization)),
  ]).then((): Promise<any> => {
    const state = getState();
    const accounts = getAccountCurrentPageResults(state);
    const keyValue = isNil(key) ? head(map(accounts, 'key')) : key;
    const currencyCode = isNil(currency) ? head(map(accounts, 'currency')) : currency;

    if (isNil(keyValue) || isNil(currencyCode)) {
      return Promise.resolve();
    }

    return Promise.all([
      dispatch(changeAccount(keyValue)),
      dispatch(fetchOrganizationAccount(organization, keyValue)),
    ]).then(() => {
      const {
        // intial state of transactions is from 30 days ago.
        createdFrom = moment().subtract(30, 'd').format('YYYY-MM-DD'),
        createdTo = moment().format('YYYY-MM-DD'),
        entriesPerPage = 25,
        pageNumber = 1,
        statement,
        status,
      } = ownProps.location.query as FetchTransactionsParams;
      return Promise.all([
        dispatch(fetchStatements(organization, currencyCode, 1, 25)),
        dispatch(fetchTransactions(organization, currencyCode, keyValue, {
          createdFrom,
          createdTo,
          entriesPerPage,
          pageNumber,
          statement,
          status,
        })),
      ]);
    });
  });
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = (state: RootState) => ({
  account: getCurrentAccount(state),
  accounts: getAccountCurrentPageResults(state),
  createdFrom: getTransactionCurrentPageCreatedFrom(state),
  createdTo: getTransactionCurrentPageCreatedTo(state),
  defaultEmail: getUserEmail(state),
  firstPage: getIsTransactionCurrentPageFirstPage(state),
  lastPage: getIsTransactionCurrentPageLastPage(state),
  organization: getOrganization(state),
  pageNumber: getTransactionCurrentPageNumber(state),
  pageError: getTransactionCurrentPageError(state),
  statement: getTransactionCurrentPageStatement(state),
  statements: getStatementsCurrentPageResults(state),
  status: getTransactionCurrentPageStatusFilter(state),
  transactions: getTransactionCurrentPageResults(state),
});

const mapDispatchToProps = (
  _: ThunkDispatcher,
  ownProps: OwnProps,
): DispatchProps => ({
  onRequestPage({
    createdFrom,
    createdTo,
    entriesPerPage = 25,
    pageNumber,
    statement,
    status,
  }: {
    createdFrom?: string;
    createdTo?: string;
    entriesPerPage?: number;
    pageNumber?: number;
    statement?: string;
    status?: string;
  }): void {
    if (
      !createdFrom
      || !createdTo
    ) {
      throw new Error('AccountTransactions: Missing required parameters');
    }

    browserHistory.push({
      pathname: ownProps.location.pathname,
      query: {
        ...ownProps.location.query,
        createdFrom,
        createdTo,
        entriesPerPage,
        pageNumber,
        statement,
        status,
      },
    });
  },
  onRequestAccountChange(organization: string, account: string): void {
    browserHistory.push(`/${organization}/accounts/${account}/transactions`);
  },
  // All export actions are dispatched by the withSubmit higher-order component
  // wrapping the ExportDialog component. Therefore, we do not need to dispatch
  // from here.
  onRequestExportOrders(props: ExportAccountForm):
  ThunkResult<Promise<LegacyResponse<ExportResponseType>>> {
    return exportAccountOrders(props);
  },
  onRequestExportTransactions(props: ExportAccountForm):
  ThunkResult<Promise<LegacyResponse<ExportResponseType>>> {
    return exportAccountTransactions(props);
  },
});

export default compose(
  withFetch(fetchAsyncState),
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
)(AccountTransactions);
