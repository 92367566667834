import React, { FunctionComponent } from 'react';
import map from 'lodash/map';
import kebabCase from 'lodash/kebabCase';
import * as styles from './product-price.styles';

const displayName = 'ProductPrice';

const defaultProps = {
  prices: [],
};

interface ProductPriceProps {
  prices: io.flow.v0.unions.LocalizedPrice[];
}

const ProductPrice: FunctionComponent<ProductPriceProps> = ({ prices }) => (
  <section className="product-price">
    {map(prices, (price) => {
      if (price.key === 'localized_item_price') {
        return (
          <div key={kebabCase(price.label)}>
            <p className={styles.salePrice}>
              {price.label}
            </p>
            {price.includes && (
              <p className={styles.salePriceSettings}>
                {price.includes.label}
              </p>
            )}
          </div>
        );
      }

      if (price.key === 'localized_item_vat') {
        return (
          <dl key={kebabCase(`${price.key} ${price.label}`)} className={styles.levy}>
            <dt className={styles.levyLabel}>VAT</dt>
            <dd className={styles.levyAmount}>
              {price.label}
            </dd>
          </dl>
        );
      }

      if (price.key === 'localized_item_duty') {
        return (
          <dl key={kebabCase(`${price.key} ${price.label}`)} className={styles.levy}>
            <dt className={styles.levyLabel}>Duty</dt>
            <dd className={styles.levyAmount}>
              {price.label}
            </dd>
          </dl>
        );
      }

      return null;
    })}
  </section>
);

ProductPrice.displayName = displayName;
ProductPrice.defaultProps = defaultProps;

export default ProductPrice;
