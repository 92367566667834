import { createSelector } from 'reselect';
import get from 'lodash/get';
import identity from 'lodash/identity';
import { RootState } from '../../../stores/types';
import { AnalyticsState } from '../types';

// Returns the asynchronous state for the organization GMV by country request.
export default createSelector<RootState, RootState, AnalyticsState['gmvByCountry']['readyState']>(
  identity,
  (state) => get(state, 'analytics.gmvByCountry.readyState'),
);
