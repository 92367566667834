import { LaneDirection } from '@flowio/api-constants';
import React from 'react';
import get from 'lodash/get';
import ExperienceShippingLane from '../ExperienceShippingLane';

interface Props {
  organizationId: string;
  regions: io.flow.v0.models.Region[];
  shippingConfiguration?: io.flow.v0.models.ShippingConfiguration;
}

const ExperienceShippingConfiguration: React.FC<Props> = ({
  organizationId,
  regions,
  shippingConfiguration,
}) => {
  const shippingLanes = get(shippingConfiguration, 'shipping_lanes', []);
  return (
    <article>
      <ExperienceShippingLane
        organizationId={organizationId}
        regions={regions}
        shippingLanes={shippingLanes}
        tierDirection={LaneDirection.OUTBOUND}
      />
      <ExperienceShippingLane
        organizationId={organizationId}
        regions={regions}
        shippingLanes={shippingLanes}
        tierDirection={LaneDirection.RETURN}
      />
    </article>
  );
};

ExperienceShippingConfiguration.displayName = 'ExperienceShippingConfiguration';

export default ExperienceShippingConfiguration;
