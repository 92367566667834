function toNextStatusForm(
  status: io.flow.internal.v0.enums.FeatureStatus,
): io.flow.internal.v0.models.FeatureStatusForm {
  switch (status) {
    case 'draft':
      return { status: 'active' };
    case 'active':
      return { status: 'archived' };
    case 'archived':
      return { status: 'active' };
    default:
      throw new Error(`${status} is not a valid feature status.`);
  }
}

export default toNextStatusForm;
