import { ActionTypes } from '../constants';
import { RootActionTypes } from '../../../stores/types';
import { ClassificationResultCountry } from '../types';

export default function setSelectedClassificationItems(
  values: ClassificationResultCountry[],
): RootActionTypes {
  return {
    type: ActionTypes.SET_SELECTED_CLASSIFICATION_COUNTRIES,
    payload: values,
  };
}
