import keyMirror from '../../../utilities/keymirror';

const ActionTypes = keyMirror({
  FETCH_TIME_REQUEST: null,
  FETCH_TIME_SUCCESS: null,
  FETCH_TIME_FAILURE: null,
  FETCH_PENDING_USERS_REQUEST: null,
  FETCH_PENDING_USERS_SUCCESS: null,
  FETCH_PENDING_USERS_FAILURE: null,
  DECLINE_PENDING_USER_REQUEST: null,
  DECLINE_PENDING_USER_SUCCESS: null,
  DECLINE_PENDING_USER_FAILURE: null,
  APPROVE_PENDING_USER_REQUEST: null,
  APPROVE_PENDING_USER_SUCCESS: null,
  APPROVE_PENDING_USER_FAILURE: null,
});

export default ActionTypes;
