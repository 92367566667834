import update from 'immutability-helper';

import { ActionTypes } from '../constants';
import { RootActionTypes } from '../../../stores/types';
import { DialogState } from '../types/state';

const initialState: DialogState = {};

export default function (state = initialState, action: RootActionTypes) {
  switch (action.type) {
    case ActionTypes.UPDATE_DIALOG:
      return update(state, {
        [action.payload.type]: {
          $set: action.payload.props,
        },
      });
    case ActionTypes.DELETE_DIALOG:
      return update(state, {
        $unset: [action.payload.type],
      });
    default:
      return state;
  }
}
