import { ActionTypes } from '../constants';
import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import { CreateShippingLaneFailure, CreateShippingLaneSuccess } from '../types/actions';

function createRequestAction(): RootActionTypes {
  return {
    type: ActionTypes.CREATE_SHIPPING_LANE_REQUEST,
  };
}

function createSuccessAction(
  payload: io.flow.v0.models.ShippingLane,
  organizationId: string,
  shippingConfigurationKey: string,
): CreateShippingLaneSuccess {
  return {
    type: ActionTypes.CREATE_SHIPPING_LANE_SUCCESS,
    meta: {
      organizationId,
      shippingConfigurationKey,
    },
    payload,
  };
}

function createFailureAction(payload: FormattedErrorMessages): CreateShippingLaneFailure {
  return {
    type: ActionTypes.CREATE_SHIPPING_LANE_FAILURE,
    error: true,
    payload,
  };
}

function createShippingLane(
  organizationId: string,
  shippingConfigurationKey: string,
  shippingLaneForm: io.flow.v0.models.ShippingLaneForm,
): ThunkResult<Promise<CreateShippingLaneSuccess | CreateShippingLaneFailure>> {
  return function createShippingLaneEffects(
    dispatch,
    getState,
    extra,
  ): Promise<CreateShippingLaneSuccess | CreateShippingLaneFailure> {
    dispatch(createRequestAction());
    return extra.api(getState()).shippingConfigurations.postLanesByKey({
      body: shippingLaneForm,
      key: shippingConfigurationKey,
      organization: organizationId,
      expand: ['center'],
    }).then((response) => {
      if (response.ok) {
        return dispatch<CreateShippingLaneSuccess>(createSuccessAction(
          response.body,
          organizationId,
          shippingConfigurationKey,
        ));
      }

      const errors = formatErrorResponseV2(response);
      return dispatch<CreateShippingLaneFailure>(createFailureAction(errors));
    });
  };
}

export default createShippingLane;
