import BlazeIcon from '../components/LabsIndex/components/BlazeIcon';
import DefaultLabIcon from '../components/LabsIndex/components/DefaultLabIcon';
import PayLaterIcon from '../components/LabsIndex/components/PayLaterIcon';

type LabConfig = {
  displayName: string;
  displayDesc: string;
  icon?: React.FunctionComponent;
};

const labs: Record<string, LabConfig> = {
  pay_later: {
    displayName: 'Pay Later',
    displayDesc: 'Enable customers to pay their bill later',
    icon: PayLaterIcon,
  },
  blaze: {
    displayName: 'Blaze',
    displayDesc: 'The World\'s Fastest Checkout',
    icon: BlazeIcon,
  },
};

type AugmentedLab = io.flow.internal.v0.models.LabProjectSettings & Partial<LabConfig>;

const augmentLab = (
  lab: io.flow.internal.v0.models.LabProjectSettings,
): AugmentedLab => {
  if (labs[lab.flow_lab_project_key] != null) {
    return {
      ...lab,
      icon: DefaultLabIcon,
      ...labs[lab.flow_lab_project_key],
    };
  }
  return lab;
};

export default augmentLab;
