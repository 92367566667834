import {
  Field,
} from 'redux-form';
import { Card, CardHeader, CardContent } from '@flowio/react-card';
import { Plus } from '@flowio/react-icons';
import { Box, BoxItem } from '@flowio/react-box';
import { FormGroup, FormGroupIntent } from '@flowio/react-form-group';
import FlipMove from 'react-flip-move';
import React from 'react';
import { Text } from '@flowio/react-text';
import find from 'lodash/find';
import property from 'lodash/property';
import isEqual from 'lodash/isEqual';
import findLastIndex from 'lodash/findLastIndex';

import TierRule from '../TierRule';
import createDefaultTierRule from '../../utilities/createDefaultTierRule';
import { TierShippingPriceRuleBuilderCardProps } from '../../types/components';
import { SelectField } from '../../../../components/ReduxFormFieldAdapters';

const formatCurrency = (
  currencies: io.flow.v0.models.Currency[],
) => (value: string) => find(currencies, { iso_4217_3: value });
const parseCurrency = property('iso_4217_3');

const TierShippingPriceRuleBuilderCard: React.FC<TierShippingPriceRuleBuilderCardProps> = ({
  currencies,
  fields,
  meta,
}) => {
  const { error, warning } = meta;

  const actions = [{
    content: 'Add Rule',
    intent: 'primary',
    leftIcon: Plus,
    onClick() {
      fields.unshift(createDefaultTierRule());
    },
    outline: true,
    type: 'button',
  }];

  let feedbackText;
  let intent: FormGroupIntent = 'neutral';

  if (error != null) {
    feedbackText = error;
    intent = 'negative';
  } else if (warning != null) {
    feedbackText = warning;
    intent = 'warning';
  }

  return (
    <Card>
      <CardHeader actions={actions} caption="Define Shipping Price Rules" dividing />
      <CardContent>
        <Box alignItems="center" spacing="tight">
          <BoxItem>
            <Text>Shipping price set in</Text>
          </BoxItem>
          <BoxItem>
            <Field
              clearable={false}
              component={SelectField}
              name="currency"
              labelKey="iso_4217_3"
              format={formatCurrency(currencies)}
              options={currencies}
              parse={parseCurrency}
              searchable
              selection
              style={{ width: 80 }}
              valueKey="iso_4217_3"
              scrollableMenu
            />
          </BoxItem>
          <BoxItem>
            <Text>and convert to the corresponding currency set by each Experience.</Text>
          </BoxItem>
        </Box>
        <FormGroup intent={intent} feedback={feedbackText}>
          <FlipMove duration={300} easing="ease" staggerDelayBy={20} staggerDurationBy={15}>
            {fields.map((name, index) => (
              <div key={fields.get(index).id}>
                <TierRule
                  bottom={isEqual(index, findLastIndex(fields.getAll()))}
                  top={isEqual(index, 0)}
                  name={name}
                  onDemoteRule={() => fields.swap(index, index + 1)}
                  onPromoteRule={() => fields.swap(index, index - 1)}
                  onRemoveRule={() => fields.remove(index)}
                />
              </div>
            ))}
          </FlipMove>
        </FormGroup>
      </CardContent>
    </Card>
  );
};

TierShippingPriceRuleBuilderCard.displayName = 'TierShippingPriceRuleBuilderCard';

export default TierShippingPriceRuleBuilderCard;
