import { select, Selection } from 'd3';
import getXPosition from './get-x-position';
import { ChartAttributes } from './types/Charts';

export default function createCrosshairs<T>(
  data: Array<T>,
  svg: Selection<SVGSVGElement, unknown, HTMLElement, undefined>,
  tooltip: Selection<HTMLDivElement, T, HTMLElement, T>,
  chartAttrs: ChartAttributes,
  tooltipHtml: string[],
  tooltipWidth = 275,
): void {
  const {
    width,
    marginLeft,
    chartPadding,
    height,
  } = chartAttrs;

  svg.selectAll('path.crosshair')
    .data(data)
    .enter()
    .append('path')
    .attr('pointer-events', 'none')
    .attr('class', (_, index) => `crosshair index-${index}`)
    .attr('d', (_, index) => {
      const xPosition = getXPosition(data.length, index, chartAttrs) + marginLeft;
      return `M${xPosition},${height}L${xPosition},0`;
    })
    .attr('stroke-width', 2)
    .attr('fill', 'none')
    .attr('stroke', 'black')
    .attr('stroke-opacity', 0)
    .style('pointer-events', 'none');

  svg.selectAll('rect')
    .data(data)
    .enter()
    .append('rect')
    .attr('class', 'crosshair-box')
    .attr('height', height)
    .attr('width', (_, index) => {
      const rectWidth = (width - marginLeft) / (data.length - 1);
      if (index === 0) {
        return (rectWidth / 2) + chartPadding;
      }

      if (index === data.length - 1) {
        return ((rectWidth + marginLeft) / 2) + chartPadding;
      }

      return rectWidth;
    })
    .attr('fill', 'rgba(0,0,0,0)')
    .attr('y', 0)
    .attr('x', (_, index) => {
      const rectWidth = (width - marginLeft) / (data.length - 1);
      const xPosition = getXPosition(data.length, index, chartAttrs) + marginLeft;
      if (index === 0) {
        return xPosition - chartPadding;
      }

      return xPosition - (rectWidth / 2);
    })
    .on('mouseenter', (_, index) => {
      let crosshairBox;
      const selectedSVGNode = svg.node();
      let chartBox;

      const selectedNode = select<Element, unknown>(`path.crosshair.index-${index}`).node();

      if (selectedNode) {
        crosshairBox = selectedNode.getBoundingClientRect();

        if (selectedSVGNode) {
          chartBox = selectedSVGNode.getBoundingClientRect();
          svg.selectAll(`path.crosshair.index-${index}`)
            .transition()
            .duration(150)
            .attr('stroke-opacity', 0.25);

          const tooltipHtmlString = tooltipHtml[index];
          const leftPosition = (chartBox.right + marginLeft) - crosshairBox.right < tooltipWidth
            ? crosshairBox.left - tooltipWidth - 25 : crosshairBox.left;

          tooltip
            .style('display', 'inline-block')
            .style('left', `${leftPosition + 10}px`)
            .style('top', `${window.pageYOffset + crosshairBox.top + (height / 3)}px`)
            .html(`${tooltipHtmlString}`);
        }
      }
    })
    .on('mouseleave', (_, index) => {
      svg.selectAll(`path.crosshair.index-${index}`)
        .transition()
        .duration(150)
        .attr('stroke-opacity', 0);

      tooltip
        .style('display', 'none');
    });
}
