import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { FormGroup } from '@flowio/react-form-group';
import { css } from '@emotion/css';
import FormName from '../../../constants/FormName';
import presence from '../../../../../utilities/validators/presence';
import {
  cloneExperience,
} from '../../../actions';
import { CloneDialogFormData } from '../types';
import { ThunkDispatcher } from '../../../../../stores/types';
import { TextField } from '../../../../../components/ReduxFormFieldAdapters';

const formgroupStyle = css`
  label {
    margin-top: 8px;
  }
`;

interface Props {
  experienceToClone: io.flow.v0.models.Experience;
}

const handleExperienceNameValidation = [
  presence({ allowEmpty: false, message: 'Experience Name is required.' }),
];

const handleSubmit = (
  values: CloneDialogFormData,
  dispatch: ThunkDispatcher,
  props: Props,
): void => {
  dispatch(cloneExperience({
    experience: props.experienceToClone,
    newName: values.newName,
  }));
};

const ExperienceCloneForm: React.FC<Props> = () => (
  <form>
    <FormGroup
      inline
      labelFor="clone-name"
      labelText="Experience Name"
      className={formgroupStyle}
    >
      <Field
        autoFocus
        fluid
        component={TextField}
        id="clone-name"
        name="newName"
        validate={handleExperienceNameValidation}
      />
    </FormGroup>
  </form>
);

export default reduxForm<CloneDialogFormData, Props>({
  form: FormName.CLONE_EXPERIENCE,
  enableReinitialize: true,
  onSubmit: handleSubmit,
})(ExperienceCloneForm);
