import { createSelector } from 'reselect';
import get from 'lodash/get';
import getReturnPolicyDeleteConfirmation from './getReturnPolicyDeleteConfirmation';
import { RootState } from '../../../stores/types';
import { ReturnPolicyDeleteConfirmationState } from '../types';

const getReturnPolicyToBeDeleted = createSelector<
RootState, ReturnPolicyDeleteConfirmationState, io.flow.v0.models.ReturnPolicy | undefined>(
  getReturnPolicyDeleteConfirmation,
  (state) => get(state, 'returnPolicyToBeDeleted'),
);

export default getReturnPolicyToBeDeleted;
