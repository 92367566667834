/**
 * This action only works client side, as it needs the browser state when making
 * the request to the server. Since this action uses the `server` client, it
 * would be making calls to itself which is complicated / wrong.
 */

import {
  ThunkResult,
  ThunkDispatcher,
} from '../../../stores/types';
import { ActionTypes } from '../constants';
import { server } from '../../../utilities/clients';
import isResponseOk from '../../../utilities/isResponseOk';
import formatErrorResponse from '../../../utilities/format-error-response';
import { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import { AuthRedirectResponse } from '../types';

export default function authorizeDocs():
ThunkResult<Promise<LegacyResponse<AuthRedirectResponse | FormattedErrorMessages> | void>> {
  return (
    dispatch: ThunkDispatcher,
  ): Promise<LegacyResponse<AuthRedirectResponse | FormattedErrorMessages> | void> => {
    dispatch({
      type: ActionTypes.AUTHORIZE_DOCS_REQUEST,
    });

    if (process.browser) {
      return server.auth.authorizeDocs().then((response) => {
        if (isResponseOk(response)) {
          dispatch({
            type: ActionTypes.AUTHORIZE_DOCS_COMPLETED,
            payload: response.result,
          });
          return response;
        }
        const formattedErrorResponse = formatErrorResponse(response);
        dispatch({
          type: ActionTypes.AUTHORIZE_DOCS_FAILURE,
          payload: formattedErrorResponse.result,
        });
        return formattedErrorResponse;
      });
    }
    // Dispatch dummy success action for server env.
    return Promise.resolve().then(() => {
      dispatch({
        type: ActionTypes.AUTHORIZE_DOCS_COMPLETED,
        payload: {
          redirect: '',
        },
      });
    });
  };
}
