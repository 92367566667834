import { css } from '@emotion/css';
import colors from '../../../../theme/tokens/colors';

export const summaryContainer = css`
  display: flex;
  align-items: flex-start;
  z-index: 100;
`;

export const divider = css`
  align-self: center;
  height: 120px;
  width: 1px;
  background-color: ${colors.white};
  opacity: 0.5;
`;

export const infoContainer = css`
  flex-grow: 1;
  color: ${colors.marketing['dark-slate-blue']};
  margin: 10px;
  width: 180px;
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;

  h3 {
    margin-bottom: 5px;
  }

  span {
    display: inline-block;
    font-weight: bold;
  }
`;

export const productCount = css`
  height: 25px;
  width: 25x;
  padding: 10px;
  border-radius: 50%;
  background-color: ${colors['flow-blue'][400]};
  color: ${colors.concrete};
`;

export const countryCount = css`
  height: 25px;
  width: 25x;
  padding: 10px;
  border-radius: 50%;
  background-color: ${colors.red[300]};
  color: var(--concrete);
`;

export const countryName = css`
  &:not(:last-child):after {
    content: "•";
    padding: 5px;
  }
`;
