import React, { useEffect, useRef } from 'react';
import orderBy from 'lodash/orderBy';

import { Card, CardHeader, CardTitle } from '@flowio/react-card';
import ActivityItem from '../ActivityItem';
import { ActivityListProps } from '../../types/components';
import * as styles from './ActivityList.styles';

const ActivityList: React.FC<ActivityListProps> = ({
  activities,
}) => {
  const activityListRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    if (activityListRef && activityListRef.current) {
      activityListRef.current.scrollTop = activityListRef.current.scrollHeight;
    }
  // activities need to incl in dependancy array,
  // to scroll down activity list automatically when its are updated
  }, [activities, activityListRef]);

  return (
    <Card className={styles.card}>
      <CardHeader dividing>
        <CardTitle className={styles.heading}>Activities</CardTitle>
      </CardHeader>
      { activities.length > 0 ? (
        <ul ref={activityListRef} className={styles.activityList}>
          {orderBy(activities, ['timestamp'], ['asc']).map((activity) => (
            <ActivityItem key={activity.id} activity={activity} />
          ))}
        </ul>
      ) : <div className={styles.noActivity}>No Activites</div> }
    </Card>
  );
};

ActivityList.displayName = 'ActivityList';

ActivityList.defaultProps = {
  activities: [],
};

export default ActivityList;
