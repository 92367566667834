import DialogName from '../constants/DialogName';
import updateDialog from './updateDialog';
import { RootActionTypes } from '../../../stores/types';

const toggleRequestChannelsDialog = (
  open: boolean,
): RootActionTypes => updateDialog(
  DialogName.REQUST_CHANNELS, {
    open,
  },
);

export default toggleRequestChannelsDialog;
