import React from 'react';
import { CheckCircle, CircleOutline, PartialCircle } from '@flowio/react-icons';
import { colors } from '../../../../theme/tokens';

export interface CheckIconProps {
  selectedItemsCount: number;
  totalItemsCount: number;
  onClick: (checked: boolean) => void;
}

const CheckIcon: React.FC<CheckIconProps> = ({ selectedItemsCount, totalItemsCount, onClick }) => {
  const iconDimension = 32;
  const greenColor = '#2F8255';
  let checkboxIcon = (
    <CircleOutline
      fill={colors.steel}
      width={iconDimension}
      height={iconDimension}
      onClick={() => onClick(true)}
      data-test-id="unchecked"
    />
  );

  if (selectedItemsCount === totalItemsCount) {
    checkboxIcon = (
      <CheckCircle
        fill={greenColor}
        width={iconDimension}
        height={iconDimension}
        onClick={() => onClick(false)}
        data-test-id="partial-checked"
      />
    );
  }

  if (selectedItemsCount > 0 && selectedItemsCount < totalItemsCount) {
    checkboxIcon = (
      <PartialCircle
        fill={greenColor}
        width={iconDimension}
        height={iconDimension}
        onClick={() => onClick(true)}
        data-test-id="checked"
      />
    );
  }

  return checkboxIcon;
};

export default CheckIcon;
