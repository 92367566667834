import { css } from '@emotion/css';

export const rules = css`
  display: flex;
  flex-flow: column;
  align-items: start;
`;

export const rule = css`
  &:not(:first-child) {
    margin-top: 4px;
  }
`;

export const actionGroup = css`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
`;

export const moreAction = css`
  margin-left: 8px;
`;

export const state = css`
  display: inline-block;
`;

export const stateText = css`
  position: relative;
  top: 1px;
  display: inline-block;
  margin-left: 4px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  text-align: left;
  text-transform: uppercase;
`;

export const current = css`
  fill: #6cab35;
  color: #6cab35;
`;

export const updating = css`
  fill: #479aff;
  color: #479aff;
`;

export const deleting = css`
  fill: #d9021b;
  color: #d9021b;
`;
