import update from 'immutability-helper';

import { RootActionTypes } from '../../stores/types';
import { LabsState } from './types';
import ActionTypes from './constants/action-types';

const initialState: LabsState = {
  ui: {
    acceptDialogOpen: false,
  },
  labsSettings: [],
  acceptingUser: undefined,
  current: undefined,
  error: undefined,
};

function reducer(state = initialState, action: RootActionTypes): LabsState {
  switch (action.type) {
    case ActionTypes.FETCH_LABS_SETTINGS_REQUEST:
      return update(state, {
        labsSettings: {
          $set: [],
        },
      });
    case ActionTypes.FETCH_LABS_SETTINGS_SUCCESS:
      return update(state, {
        labsSettings: {
          $set: action.payload,
        },
      });
    case ActionTypes.FETCH_ACCEPTING_USER_FAILURE:
    case ActionTypes.FETCH_ACCEPTING_USER_REQUEST:
      return update(state, {
        acceptingUser: {
          $set: undefined,
        },
      });
    case ActionTypes.FETCH_ACCEPTING_USER_SUCCESS:
      return update(state, {
        acceptingUser: {
          $set: action.payload,
        },
      });
    case ActionTypes.FETCH_LAB_SETTINGS_BY_KEY_REQUEST:
      return update(state, {
        current: {
          $set: undefined,
        },
      });
    case ActionTypes.FETCH_LAB_SETTINGS_BY_KEY_SUCCESS:
    case ActionTypes.PUT_LAB_SETTINGS_BY_KEY_SUCCESS:
      return update(state, {
        current: {
          $set: action.payload,
        },
      });
    case ActionTypes.PUT_LAB_SETTINGS_BY_KEY_FAILURE:
    case ActionTypes.FETCH_LAB_SETTINGS_BY_KEY_FAILURE:
      return update(state, {
        error: {
          $set: action.payload,
        },
      });
    case ActionTypes.OPEN_ACCEPT_DIALOG:
      return update(state, {
        ui: {
          acceptDialogOpen: {
            $set: true,
          },
        },
      });
    case ActionTypes.CLOSE_ACCEPT_DIALOG:
      return update(state, {
        ui: {
          acceptDialogOpen: {
            $set: false,
          },
        },
      });
    default:
      return state;
  }
}

export default reducer;
