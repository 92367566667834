import { Link } from 'react-router';
import {
  Card, CardHeader, CardContent, CardEmptyState, CardTitle,
} from '@flowio/react-card';
import React from 'react';
import { Stack } from '@flowio/react-stack';
import isEmpty from 'lodash/isEmpty';
import { css } from '@emotion/react';
import FormName from '../../constants/FormName';

import ExperienceLogisticsSettingForm from '../ExperienceLogisticsSettingForm/containers/ExperienceLogisticsSettingForm';
import SaveChanges from '../../../../components/SaveChanges';

const emptyCardStateStyle = css({
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'center',
});

interface Props {
  organizationId: string;
  shippingConfigurations: io.flow.v0.models.ShippingConfiguration[];
}

const ExperienceLogisticsSettingCard: React.FC<Props> = ({
  organizationId,
  shippingConfigurations,
}) => (
  <Card>
    <CardHeader dividing>
      <Stack alignment="center" distribution="equalSpacing">
        <CardTitle content="Logistics" />
        {!isEmpty(shippingConfigurations) && (
          <SaveChanges form={FormName.EXPERIENCE_LOGISTICS_SETTING} />
        )}
      </Stack>
    </CardHeader>
    <CardContent>
      {isEmpty(shippingConfigurations) ? (
        <CardEmptyState css={emptyCardStateStyle}>
          <h3>No shipping configurations available</h3>
          <div>
            <span>
              Edit shipping configurations under
              {' '}
              <Link to={`/${organizationId}/logistics/shipping/configurations`}>
                Logistics
              </Link>
            </span>
          </div>
        </CardEmptyState>
      ) : (
        <ExperienceLogisticsSettingForm
          organizationId={organizationId}
          shippingConfigurations={shippingConfigurations}
        />
      )}
    </CardContent>
  </Card>
);

ExperienceLogisticsSettingCard.displayName = 'ExperienceLogisticsSettingCard';

export default ExperienceLogisticsSettingCard;
