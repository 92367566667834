import get from 'lodash/get';
import { RootState } from '../../stores/types';
import AuthState from './types/state';

export const getAuthDocs = (state: RootState): AuthState['docs'] => get(state, 'auth.docs');
export const getAuthRedirectLocation = (state: RootState): AuthState['redirect']['location'] => get(state, 'auth.redirect.location');
export const getAuthRedirectStatus = (state: RootState): AuthState['redirect']['status'] => get(state, 'auth.redirect.status');
export const getAssignedShop = (state: RootState): AuthState['assignedShop'] => get(state, 'auth.assignedShop');
export const getAssignedOrg = (state: RootState): AuthState['assignedOrg'] => get(state, 'auth.assignedOrg');
export const getShopifyParams = (state: RootState): AuthState['shopifyParams'] => get(state, 'auth.shopifyParams');
export const getIsAuthRedirecting = (state: RootState): AuthState['redirectInProgress'] => get(state, 'auth.redirectInProgress');
export const getShopifyAppError = (state: RootState): AuthState['shopifyAppError'] => get(state, 'auth.shopifyAppError');

export default {}; // no defualt export from this module
