import { css } from '@emotion/css';

export const content = css`
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  text-align: left;
  text-transform: uppercase;
  white-space: nowrap;

  &::before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    margin-right: 4px;
    height: 8px;
    width: 8px;
  }
`;

export const neutral = css`
  color: #b5bec9;

  &::before {
    background-color: #b5bec9;
  }
`;

export const positive = css`
  color: #6cab35;

  &::before {
    background-color: #6cab35;
  }
`;

export const negative = css`
  color: #d9021b;

  &::before {
    background-color: #d9021b;
  }
`;

export const warning = css`
  color: #e6b137;

  &::before {
    background-color: #e6b137;
  }
`;

export const info = css`
  color: #479aff;

  &::before {
    background-color: #479aff;
  }
`;
