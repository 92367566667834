import { createSelector } from 'reselect';
import get from 'lodash/get';
import getNonReturnableState from './getNonReturnableState';
import { RootState } from '../../../stores/types';
import { NonReturnableState, ReturnPolicyDeleteConfirmationState } from '../types';

const getReturnPolicyDeleteConfirmation = createSelector <
RootState, NonReturnableState, ReturnPolicyDeleteConfirmationState>(
  getNonReturnableState,
  (state) => get(state, 'dialogs.returnPolicyDeleteConfirmation'),
);

export default getReturnPolicyDeleteConfirmation;
