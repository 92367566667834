import ApiPropTypes from '@flowio/api-prop-types';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button } from '@flowio/react-button';
import { TextInput } from '@flowio/react-text-input';
import get from 'lodash/get';
import merge from 'merge';
import noop from 'lodash/noop';
import getErrorMessageForField from '../../../../utilities/react/get-error-message-for-field';
import * as styles from './profile.styles';

export default class BasicInformationForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      firstName: '',
      lastName: '',
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { errors, user } = this.props;
    const email = get(user, 'email');
    const firstName = get(user, 'name.first', '');
    const lastName = get(user, 'name.last', '');

    this.setState((prevState) => (merge.recursive(true, prevState, {
      email,
      firstName,
      lastName,
      errors,
    })));
  }

  handleEmailChange = (value) => {
    const { onModification } = this.props;
    onModification();
    return this.setState({ email: value });
  }

  handleFirstNameChange = (value) => {
    const { onModification } = this.props;
    onModification();
    return this.setState({ firstName: value });
  }

  handleLastNameChange = (value) => {
    const { onModification } = this.props;
    onModification();
    return this.setState({ lastName: value });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { email, firstName, lastName } = this.state;
    const { onSubmit } = this.props;
    const user = {
      email,
      name: {
        first: firstName,
        last: lastName,
      },
    };
    onSubmit(user);
  }

  render() {
    const { disabled } = this.props;
    const {
      email, firstName, lastName, errors,
    } = this.state;

    return (
      <form
        name="basic_information"
        className="basic-information__form"
        onSubmit={this.handleSubmit}
      >

        <section className={styles.formRow}>
          <div className={styles.formField}>
            <h3 className={styles.label}>Email Address</h3>
            <TextInput
              fluid
              name="email"
              hintText="Email Address"
              errorText={getErrorMessageForField('email', errors)}
              value={email}
              onValueChange={this.handleEmailChange}
              clearable
            />
          </div>
          <div className={styles.formField} />
        </section>
        <section className={styles.formRow}>
          <div className={styles.formField}>
            <h3 className={styles.label}>First Name</h3>
            <TextInput
              fluid
              name="firstName"
              hintText="First Name"
              errorText={getErrorMessageForField('firstName', errors)}
              aria-required="true"
              value={firstName}
              onValueChange={this.handleFirstNameChange}
              clearable
            />
          </div>
          <div className={styles.formField}>
            <h3 className={styles.label}>Last Name</h3>
            <TextInput
              fluid
              name="lastName"
              hintText="Last Name"
              errorText={getErrorMessageForField('lastName', errors)}
              aria-required="true"
              value={lastName}
              onValueChange={this.handleLastNameChange}
              clearable
            />
          </div>
        </section>

        <section className={styles.actions}>
          <Button
            type="submit"
            className={styles.actionButton}
            content="Update Informaiton"
            name="update_information"
            intent="primary"
            disabled={disabled}
          />
        </section>
      </form>
    );
  }
}
BasicInformationForm.displayName = 'BasicInformationForm';

BasicInformationForm.propTypes = {
  disabled: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.shape({
    field: PropTypes.string,
    message: PropTypes.string.isRequired,
  })),
  user: ApiPropTypes.user,
  onModification: PropTypes.func,
  onSubmit: PropTypes.func,
};

BasicInformationForm.defaultProps = {
  disabled: true,
  errors: [],
  user: {
    name: {},
  },
  onModification: noop,
  onSubmit: noop,
};
