import { RouterState } from 'react-router';
import { connect, MapDispatchToProps } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import {
  getPageSize,
  getPageResults,
  getIsFirstPage,
  getIsLastPage,
  getPageNumber,
} from '@flowio/redux-filtering-paging-sorting';
import { withFetch } from '@flowio/redux-fetch';

import { fetchOrganization, getOrganizationId } from '../../organization';
import { getNonReturnablePaginationState } from '../selectors';
import {
  changeNonReturnablePage,
  fetchReturnPolicies,
  showReturnPolicyWorksheet,
  showReturnPolicyDeleteConfirmation,
} from '../actions';
import NonReturnableIndex from '../components/NonReturnableIndex';
import {
  NonReturnableIndexStateProps,
  NonReturnableIndexDispatchProps,
  NonReturnableIndexProps,
} from '../types';
import { RootState, ThunkDispatcher } from '../../../stores/types';

function fetchAsyncState(
  dispatch: ThunkDispatcher,
  _getState: () => RootState,
  props: RouterState,
) {
  const { organization } = props.params;
  const { pageNumber, entriesPerPage } = props.location.query;
  return Promise.all([
    dispatch(fetchReturnPolicies(organization, pageNumber, entriesPerPage)),
    dispatch(fetchOrganization(organization)),
  ]);
}

const mapStateToProps = (state: RootState): NonReturnableIndexStateProps => {
  const pageNumber: number | string = getPageNumber(getNonReturnablePaginationState)(state);
  const isFirstPage: boolean = getIsFirstPage(getNonReturnablePaginationState)(state);
  const isLastPage: boolean = getIsLastPage(getNonReturnablePaginationState)(state);

  return {
    entriesPerPage: getPageSize(getNonReturnablePaginationState)(state),
    returnPolicies: getPageResults(getNonReturnablePaginationState)(state),
    organizationId: getOrganizationId(state),
    isFirstPage,
    isLastPage,
    pageNumber: Number(pageNumber),
  };
};

const mapDispatchToProps: MapDispatchToProps<
NonReturnableIndexDispatchProps,
{}
> = (dispatch) => bindActionCreators({
  onAddReturnPolicy: showReturnPolicyWorksheet,
  onEditReturnPolicy: showReturnPolicyWorksheet,
  onPageChange: changeNonReturnablePage,
  onRemoveReturnPolicy: showReturnPolicyDeleteConfirmation,
}, dispatch);

export default compose<React.FC<NonReturnableIndexProps>>(
  withFetch(fetchAsyncState),
  connect(mapStateToProps, mapDispatchToProps),
)(NonReturnableIndex);
