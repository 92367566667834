import update from 'immutability-helper';
import findIndex from 'lodash/findIndex';
import assign from 'lodash/assign';
import compact from 'lodash/compact';
import get from 'lodash/get';
import ActionTypes from './constants/action-types';

import { UtilityState, ReadyStates, OrderSummarySettings } from './types';
import { RootActionTypes } from '../../stores/types';

const initialState: UtilityState = {
  errors: {},
  centers: [],
  orderSummary: {},
  shopifySyncStatus: {},
  rates: {
    errors: [],
    results: [],
    isFirstPage: false,
    isLastPage: false,
  },
};

/**
 * A reducer that manages the "testing" data subset of the application state tree. It provides
 * the following shape:
 *
 *  {
 *    centers: [],
 *  }
 */
export default function utilitiesReducer(
  state = initialState,
  action: RootActionTypes,
): UtilityState {
  switch (action.type) {
    case ActionTypes.GET_CURRENCY_RATES_SUCCESS:
      return update(state, {
        rates: { $set: action.payload },
      });
    case ActionTypes.GET_CURRENCY_RATES_FAILURE:
      return update(state, {
        rates: {
          errors: { $set: action.payload.messages },
        },
      });
      // return { ...state, errors: action.payload.messages };
    case ActionTypes.GET_CENTERS_SUCCESS:
      return update(state, { centers: { $set: action.payload } });
    case ActionTypes.SET_ORDER_SUMMARY_SETTINGS: {
      return update(state, {
        orderSummary: {
          settings: {
            $apply: (settings): Partial<OrderSummarySettings> => assign(
              {},
              settings,
              action.payload,
              {
                items: compact(get(settings, 'items', [] as io.flow.v0.models.LocalizedLineItem[]).concat(action.payload.items)),
              },
            ),
          },
        },
      });
    }

    case ActionTypes.UPDATE_ITEM_QUANTITY: {
      const { item } = action.payload;
      const settings = get(state, 'orderSummary.settings');
      const indexOfItem = findIndex(
        settings.items,
        (i: io.flow.v0.models.LocalizedLineItem) => i.number === item.number,
      );
      return update(state, {
        orderSummary: {
          settings: {
            items: { $splice: [[indexOfItem, 1, action.payload.item]] },
          },
        },
      });
    }

    case ActionTypes.REMOVE_ORDER_SUMMARY_ITEM: {
      return update(state, {
        orderSummary: {
          settings: {
            $apply: (settings): Partial<OrderSummarySettings> => assign({}, settings, {
              items: get(settings, 'items', []).filter((i) => i.number !== action.payload),
            }),
          },
          orderEstimate: {
            $apply: (orderEstimate): Partial<io.flow.v0.models.Order> | undefined => {
              if (orderEstimate && get(orderEstimate, 'items', []).length === 1
            && get(orderEstimate, 'items', [])[0].number === action.payload) {
                return undefined;
              }
              return orderEstimate;
            },
          },
        },
      });
    }

    case ActionTypes.UPSERT_ORDER_ESTIMATE_SUCCESS:
      return update(state, {
        orderSummary: { orderEstimate: { $set: action.payload } },
        errors: { $set: {} },
      });

    case ActionTypes.UPSERT_ORDER_ESTIMATE_FAILURE:
      return update(state, {
        orderSummary: {
          settings: {
            $apply: (settings): Partial<OrderSummarySettings> => assign({}, settings, {
              items: get(settings, 'items', []).filter((item) => {
                const numbers = get(action, 'payload.numbers');

                return numbers && !numbers.includes(item.number);
              }),
            }),
          },
          orderEstimate: {
            $set: undefined,
          },
        },
        errors: {
          order_estimate: { $set: action.payload },
        },
      });

    case ActionTypes.CLEAR_ORDER_ESTIMATE:
      return update(state, { orderSummary: { orderEstimate: { $set: undefined } } });

      // Shopify Sync Status fetch actions
    case ActionTypes.FETCH_SHOPIFY_SYNC_STATUS_REQUEST:
      return update(state, {
        shopifySyncStatus: {
          $merge: {
            readyState: ReadyStates.LOADING,
          },
        },
      });
    case ActionTypes.FETCH_SHOPIFY_SYNC_STATUS_SUCCESS:
      return update(state, {
        shopifySyncStatus: {
          $merge: {
            readyState: ReadyStates.FULFILLED,
            data: action.payload,
          },
          $unset: ['error'],
        },
      });
    case ActionTypes.FETCH_SHOPIFY_SYNC_STATUS_FAILURE:
      return update(state, {
        shopifySyncStatus: {
          $merge: {
            readyState: ReadyStates.REJECTED,
            error: action.payload,
          },
        },
      });
    default:
      return state;
  }
}
