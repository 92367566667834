import { RouterState, withRouter } from 'react-router';
import { compose } from 'redux';
import { withFetch } from '@flowio/redux-fetch';
import { connect } from 'react-redux';
import querystring from 'querystring';
import Cookies from 'universal-cookie';
import { ThunkDispatcher } from '../../../middlewares/types';

import { RootState } from '../../../stores/types';
import { getUser } from '../../console';
import { getShop, setAuthRedirecting, setShopifyParams } from '../actions';
import ShopifyAuth from '../components/shopify-auth';
import { ShopifyAuthProps, ShopifyAuthStateProps, ShopifyAuthDispatchProps } from '../types/props';
import { getMemberships } from '../../user';
import {
  getAssignedOrg,
  getAssignedShop,
  getIsAuthRedirecting,
  getShopifyParams,
} from '../selectors';
import assignOrgToShop from '../actions/assign-org-to-shop';

// 1 day in seconds
const cookieExpiration = 24 * 60 * 60;

function fetchAsyncState(
  dispatch: ThunkDispatcher,
  _getState: () => RootState,
  ownProps: RouterState,
): Promise<any> {
  const { shop } = ownProps.location.query;
  const shopifyParams = ownProps.location.query;
  // Saving the params to state so we can redirect after :)
  dispatch(setShopifyParams(shopifyParams));
  return Promise.all([
    dispatch(getShop(shop)),
  ]);
}

function mapStateToProps(state: RootState): ShopifyAuthStateProps {
  const shopifyParams = getShopifyParams(state);
  const userAuth = getUser(state)?.auth;
  const organizations = getMemberships(state)
    .map((membership) => membership.organization as io.flow.v0.models.Organization);
  const assignedOrg = getAssignedOrg(state);
  // So querystring.stringify is annoying to use, it takes a ParsedUrlQueryInput
  // which is just a key as string to any of the basic types
  // IT does not accept any kind of model but if you just put in an object it works
  return {
    organizations,
    assignedOrg,
    redirectingInProgress: getIsAuthRedirecting(state),
    assignedShop: getAssignedShop(state),
    auth: userAuth,
    incomingShop: shopifyParams?.shop,
    needJwt: Boolean(shopifyParams?.needJwt),
    redirectParams: querystring.stringify({
      ...shopifyParams,
      org: assignedOrg,
    }),
  };
}

function mapDispatchToProps(dispatch: ThunkDispatcher): ShopifyAuthDispatchProps {
  return {
    onOrganizationClick: (
      organization: io.flow.v0.models.Organization,
      shopToAssign: string,
    ): void => {
      dispatch(assignOrgToShop(organization.id, shopToAssign));
    },
    onRedirect: (redirectParams: string, auth: string): void => {
      dispatch(setAuthRedirecting());
      const cookies = new Cookies();
      cookies.set('shopifyAppAuth', auth, {
        path: '/app/auth/shopify/callback',
        secure: true,
        maxAge: cookieExpiration,
      });
      const consoleDomain = process.env.CONSOLE_DOMAIN;
      window.location.href = `${consoleDomain}/app/auth/shopify/callback?${redirectParams}`;
    },
  };
}

export default compose<React.FC<ShopifyAuthProps>>(
  withRouter,
  withFetch(fetchAsyncState),
  connect(mapStateToProps, mapDispatchToProps),
)(ShopifyAuth);
