import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import ActionTypes from '../constants/action-types';
import { LabKey } from '../constants/lab-keys';

function requestAction(): RootActionTypes {
  return {
    type: ActionTypes.FETCH_LAB_SETTINGS_BY_KEY_REQUEST,
  };
}

function successAction(payload: io.flow.internal.v0.models.LabProjectSettings): RootActionTypes {
  return {
    type: ActionTypes.FETCH_LAB_SETTINGS_BY_KEY_SUCCESS,
    payload,
  };
}

function failureAction(payload: FormattedErrorMessages): RootActionTypes {
  return {
    type: ActionTypes.FETCH_LAB_SETTINGS_BY_KEY_FAILURE,
    payload,
  };
}

function fetchLabSettingsByKey(
  organizationId: string,
  labKey: LabKey,
): ThunkResult<Promise<void>> {
  return function fetchLabSettingsByKeyEffects(
    dispatch,
    getState,
    extra,
  ): Promise<void> {
    dispatch(requestAction());

    return extra.apiInternal(getState()).flowLabsProjectSettings
      .getByFlowLabProjectKey({
        flow_lab_project_key: labKey,
        organization: organizationId,
      }).then((response) => {
        if (response.ok) {
          dispatch(successAction(response.body));
          return;
        }

        const errors = formatErrorResponseV2(response);
        dispatch(failureAction(errors));
      });
  };
}

export default fetchLabSettingsByKey;
