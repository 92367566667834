import { PuffLoader } from '@flowio/react-icons';

import { Banner } from '@flowio/react-banner';
import { Button } from '@flowio/react-button';
import React from 'react';
import {
  Worksheet,
  WorksheetHeader,
  WorksheetBody,
  WorksheetTitle,
} from '@flowio/react-worksheet';
import isEmpty from 'lodash/isEmpty';
import ExclusionRuleForm from '../../containers/ExclusionRuleForm';

import FormName from '../../constants/FormName';
import * as styles from './styles';

type Status = 'pending' | 'loading' | 'fulfilled' | 'rejected';

interface ExclusionRuleWorksheetProps {
  availableRegions?: io.flow.v0.models.Region[];
  availableFilters?: io.flow.v0.models.AvailableFilterStructured[];
  initialValues?: {
    exclusionRuleId?: string;
    filters?: io.flow.v0.unions.QueryFilter[];
    organizationId?: string;
    regions?: string[];
  }
  submissionApiError?: io.flow.v0.models.GenericError;
  open?: boolean;
  onRequestCancel: (...args: any) => void;
  onRequestSave: (...args: any) => void;
  onSubmitSuccess: (...args: any) => void;
  status: Status;
  submitting?: boolean;
}

const ExclusionRuleWorksheet: React.FC<ExclusionRuleWorksheetProps> = ({
  availableFilters = [],
  availableRegions = [],
  initialValues = {},
  onRequestCancel,
  onRequestSave,
  onSubmitSuccess,
  open = false,
  status,
  submitting = false,
  submissionApiError,
  ...unhandledProps
}) => (
  <Worksheet {...unhandledProps} open={open}>
    <WorksheetHeader>
      {(status === 'fulfilled') && (
        <div className={styles.header}>
          <Button
            type="button"
            content="Cancel"
            disabled={submitting}
            onClick={onRequestCancel}
          />
          <WorksheetTitle content={isEmpty(initialValues.exclusionRuleId) ? 'New Exclusion' : 'Edit Exclusion'} />
          <Button
            type="button"
            disabled={submitting}
            intent="primary"
            content="Save"
            onClick={onRequestSave}
          />
        </div>
      )}
      {(status === 'rejected') && (
        <div className={styles.header}>
          <Button type="button" content="Close" onClick={onRequestCancel} />
        </div>
      )}
    </WorksheetHeader>
    <WorksheetBody>
      {(() => {
        switch (status) {
          case 'fulfilled':
            return (
              <ExclusionRuleForm
                form={FormName.ExclusionForm}
                submissionApiError={submissionApiError}
                availableFilters={availableFilters}
                availableRegions={availableRegions}
                initialValues={initialValues}
                onSubmitSuccess={onSubmitSuccess}
              />
            );
          case 'rejected':
            return (
              <Banner intent="negative">
                An exclusion rule cannot be created at this time.
                Please try again later.
                Contact
                <a href="mailto:help@flow.io">help@flow.io</a>
                if this issue persists.
              </Banner>
            );
          default:
            return (
              <div className={styles.loadingContainer}>
                <PuffLoader className={styles.loadingIcon} />
                <div className={styles.loadingText}>
                  Loading Exclusion Rule Builder, Please Wait...
                </div>
              </div>
            );
        }
      })()}
    </WorksheetBody>
  </Worksheet>
);

ExclusionRuleWorksheet.displayName = 'ExclusionRuleWorksheet';

ExclusionRuleWorksheet.defaultProps = {
  availableRegions: [],
  availableFilters: [],
  initialValues: {},
  open: false,
  submitting: false,
  submissionApiError: undefined,
};

export default ExclusionRuleWorksheet;
