import { css } from '@emotion/css';
import colors from '../../../../theme/tokens/colors';

const PADDING_VALUE = 6;
const padding = `${PADDING_VALUE}px`;
const DOUBLE_PADDING_VALUE = PADDING_VALUE * 2;
const DOUBLE_PADDING = `${DOUBLE_PADDING_VALUE}px`;
const ccIconWidth = 80;
const ccWidthHeightRatio = 0.6;

export const button = css`
  cursor: pointer;
`;

export const instructions = css`
  background-color: ${colors.mercury};
  padding: ${DOUBLE_PADDING} ${DOUBLE_PADDING} ${DOUBLE_PADDING} ${DOUBLE_PADDING_VALUE * 3}px;
  font-size: 14px;
  margin: 0 0 16px 0;
  border-radius: 7px;
`;

export const dndContainer = css`
  background-color: ${colors.concrete};
  border: solid 2px ${colors.concrete};
  border-radius: 7px;
`;

export const droppableContainer = css`
  padding: 0 ${padding} ${(PADDING_VALUE * 4)}px ${padding};

  &:nth-child(2n) {
    background: white;

    h2 {
      color: ${colors['flow-blue'][500]};
    }
  }

  & h2 {
    color: ${colors['flow-blue'][300]};
    padding: ${(PADDING_VALUE * 4)}px ${padding};
    margin: 0;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
  }
`;

export const ccsDroppable = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const dragContainer = css`
  padding: 0 ${padding};
  flex-basis: ${ccIconWidth}px;
  width: ${ccIconWidth}px;
`;

export const cc = css`
  border: solid 1px #efefef;
  border-radius: 4px;
  width: ${ccIconWidth}px;
  height: ${ccIconWidth * ccWidthHeightRatio}px;
  margin: 0;
  background-size: contain;
  background-repeat: no-repeat;
  box-shadow: 0 3px 8px 0 rgba(77,88,102,0.10);

  .dragging & {
    border: 0;
    box-shadow: 0 4px 8px 0 rgba(77,88,102,0.20);
  }
`;

export const ccName = css`
  display: block;
  text-align: center;
  font-size: 12px;
  color: ${colors.charcoal};
  margin-top: 8px;
`;
