import { SubmissionError } from 'redux-form';
import { reduxFormConfirm } from '@flowio/redux-form-overlay';

import { DELETE_MEMBERSHIP_FORM } from '../constants/form-names';
import { createToast } from '../../console/actions';
import { removeMembership } from '../actions';
import DeleteMembershipForm from '../components/delete-membership-form';
import { ThunkDispatcher } from '../../../stores/types';

function handleSubmit(values: io.flow.v0.models.Membership, dispatch: ThunkDispatcher) {
  dispatch(removeMembership(values)).then((response) => {
    if (!response.ok) {
      throw new SubmissionError({
        _error: 'Failed to removed member from organization',
      });
    }

    return response;
  });
}

function handleSubmitSuccess(_result: any, dispatch: ThunkDispatcher) {
  dispatch(createToast({
    content: 'Member has been removed',
    icon: 'Trash',
    intent: 'neutral',
  }));
}

export default reduxFormConfirm({
  form: DELETE_MEMBERSHIP_FORM,
  onSubmit: handleSubmit,
  onSubmitSuccess: handleSubmitSuccess,
  okText: 'Remove',
})(DeleteMembershipForm);
