import React, { Component } from 'react';
import ApiInternalPropTypes from '@flowio/api-internal-prop-types';
import * as d3 from 'd3';
import numeral from 'numeral';
import moment from 'moment';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import range from 'lodash/range';
import isEqual from 'lodash/isEqual';
import { interpolatePath } from 'd3-interpolate-path';

import generateColorScale from '../../../../../utilities/generate-color-scale';
import getXPosition from '../../../../../utilities/charting/get-x-position';
import createXScale from '../../../../../utilities/charting/create-x-scale';
import createYScale from '../../../../../utilities/charting/create-y-scale';
import createCrosshairs from '../../../../../utilities/charting/create-crosshair-tooltips';
import createExperimentTooltipHtml from '../../../../../utilities/charting/create-experiment-tooltip-html';
import * as styles from './experiment-results-line-chart.styles';

const height = 350;
const width = 1100;
const marginLeft = 40;
const marginBtm = 50;
const chartPadding = 10;
const xAxisTickAmount = 20;
const colorScale = generateColorScale();
const chartAttrs = {
  height,
  width,
  marginLeft,
  marginBtm,
  chartPadding,
};

let tooltip;

const keyToLabel = {
  visitor_count: 'Visitors',
  visitors_with_transactions_count: 'Unique Conversions',
  conversion_rate: 'CVR',
};

export default class ExperimentResultsLineChart extends Component {
  componentDidMount() {
    if (d3.select(`.${styles.resultsTooltip}`).empty()) {
      tooltip = d3.select('body')
        .append('div')
        .attr('class', styles.resultsTooltip);
    } else {
      tooltip = d3.select(`.${styles.resultsTooltip}`);
    }

    this.createResultsChart();
  }

  componentDidUpdate(previousProps) {
    const { results, selectedRow, valueKey } = this.props;
    if (previousProps.selectedRow !== selectedRow) {
      this.setSelectedKey(selectedRow);
      return;
    }

    if (!isEqual(results, previousProps.results) || valueKey !== previousProps.valueKey) {
      this.updateResultsChart();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  componentWillUnmount() {
    // Need to remove the tooltip, it might stick around if the chart is unmounted
    // This happens when the date range changes and there is no data
    d3.select(`.${styles.resultsTooltip}`).remove();
  }

  setSelectedKey = (key) => {
    d3.selectAll('.data-line')
      .transition()
      .duration(250)
      .attr('stroke-opacity', (d) => {
        if (d[0].key === key || key === '') {
          return 1;
        }
        return 0.1;
      });

    d3.selectAll('.data-area')
      .transition()
      .duration(250)
      .attr('opacity', (d) => {
        if (d[0].key === key) {
          return 0.5;
        }

        if (key === '') {
          return 0.2;
        }

        return 0.05;
      });
  }

  createBaseline = (initialY, dataLength) => {
    const xPosition = getXPosition(dataLength, dataLength - 1, chartAttrs);
    return `M0,${initialY}C${xPosition},${initialY},${xPosition},${initialY},${xPosition},${initialY}`;
  };

  createYGrid = (svg) => {
    svg.selectAll('.yAxis')
      .selectAll('.tick')
      .append('line')
      .attr('class', 'yAxis-grid')
      .attr('x1', 1)
      .attr('y1', 0)
      .attr('x2', width)
      .attr('y2', 0)
      .attr('stroke-width', 1)
      .attr('stroke', '#F2F2F2');
  }

  formatLineData = (data, valueKey) => range(data[0].results.length).map((index) => (
    data.map((d) => ({
      timestamp: d.timestamp,
      value: get(d.results[index], valueKey, 0),
      key: get(d.results[index], 'experiment_variant_key', ''),
    }))));

  formatAreaData = (data) => range(data[0].results.length).map((index) => (
    data.map((d) => ({
      timestamp: d.timestamp,
      upper: d.results[index].upper_bound,
      lower: d.results[index].lower_bound,
      key: d.results[index].experiment_variant_key,
    }))));

  createYAxis = (yScale) => d3.axisLeft(yScale)
    .tickFormat((count) => numeral(count).format('0.[00]a'))
    .tickArguments([5])
    .tickSize(0)
    .tickPadding([10]);

  createXAxis = (xScale, modValue) => d3.axisBottom(xScale)
      .tickFormat(date => date.length > 0 ? moment.utc(date).format('MM/DD/YY') : '') // eslint-disable-line
    .tickSize(0)
    .tickPadding([10])
    .tickValues(xScale.domain().filter((d, i) => !(i % modValue)));

  updateResultsChart() {
    const { results, valueKey, idToName } = this.props;
    const modValue = Math.ceil(results.length / xAxisTickAmount);

    const svg = d3.select('#experimentResults').select('svg');
    const xScale = createXScale(results, 'timestamp', chartAttrs);
    const xAxis = this.createXAxis(xScale, modValue);

    let yScale;

    if (valueKey === 'conversion_rate') {
      yScale = createYScale(results, 'upper_bound', chartAttrs, 'results');
    } else {
      yScale = createYScale(results, valueKey, chartAttrs, 'results');
    }

    const yAxis = this.createYAxis(yScale);

    const lineData = this.formatLineData(results, valueKey);

    const line = d3.line()
      .curve(d3.curveMonotoneX)
      .x((d) => xScale(d.timestamp))
      .y((d) => yScale(d.value));

    const areaData = this.formatAreaData(results);

    const area = d3.area()
      .curve(d3.curveMonotoneX)
      .x((d) => xScale(d.timestamp))
      .y0((d) => yScale(d.lower))
      .y1((d) => yScale(d.upper));

    const currentLines = svg.selectAll('.data-line')
      .data(lineData);

    svg.selectAll('.data-area').remove();

    svg.selectAll('.crosshair-box')
      .remove();

    svg.selectAll('path.crosshair')
      .remove();

    svg.selectAll('.xAxis')
      .call(xAxis);

    svg.selectAll('.xAxis')
      .selectAll('.domain')
      .attr('d', `M0,0L${width},0`);

    svg.selectAll('.yAxis')
      .transition()
      .duration(500)
      .call(yAxis);

    svg.selectAll('.domain')
      .attr('stroke-opacity', 0.5)
      .attr('stroke', '#808080');

    svg.selectAll('.tick')
      .selectAll('text')
      .attr('fill', '#808080');

    this.createYGrid(svg);

    if (valueKey === 'conversion_rate') {
      const baseArea = d3.area()
        .curve(d3.curveMonotoneX)
        .x((d) => xScale(d.timestamp))
        .y0(yScale(0))
        .y1(yScale(0));

      svg.selectAll('.data-area-container')
        .selectAll('path')
        .data(areaData)
        .enter()
        .append('path')
        .attr('class', (d, index) => `data-area data-area-${index}`)
        .attr('d', baseArea)
        .attr('fill', (d, index) => colorScale(index))
        .attr('opacity', 0.2)
        .transition()
        .duration(500)
        .ease(d3.easeExp)
        .attr('d', area);
    }

    currentLines.enter()
      .append('path')
      .attr('class', (d, index) => `data-line data-line-${index}`)
      .merge(currentLines)
      .transition()
      .duration(250)
      .attr('stroke-opacity', 1)
      .transition()
      .duration(500)
      .ease(d3.easeExp)
      .attrTween('d', (d, index) => {
        const oldLine = svg.select(`.data-line-${index}`).attr('d');
        const newLine = line(d);
        return interpolatePath(oldLine, newLine);
      })
      .attr('transform', `translate(${marginLeft},0)`);

    currentLines.exit().remove();

    const tooltipHtmlStrings = results.map(
      (res) => createExperimentTooltipHtml(res, styles, valueKey, keyToLabel, idToName),
    );

    createCrosshairs(results, svg, tooltip, chartAttrs, tooltipHtmlStrings);
  }

  createResultsChart() {
    const { results, valueKey, idToName } = this.props;
    const modValue = Math.ceil(results.length / xAxisTickAmount);

    let yScale;

    const xScale = createXScale(results, 'timestamp', chartAttrs);
    const xAxis = this.createXAxis(xScale, modValue);

    if (valueKey === 'conversion_rate') {
      yScale = createYScale(results, 'upper_bound', chartAttrs, 'results');
    } else {
      yScale = createYScale(results, valueKey, chartAttrs, 'results');
    }

    const yAxis = this.createYAxis(yScale);

    const line = d3.line()
      .curve(d3.curveMonotoneX)
      .x((d) => xScale(d.timestamp))
      .y((d) => yScale(d.value));

    const lineData = this.formatLineData(results, valueKey);

    const svg = d3.select('#experimentResults')
      .append('svg')
      .attr('style', 'padding-top: 5px')
      .attr('height', `${height + marginBtm}`)
      .attr('width', `${width + marginLeft}`);

    svg.append('g')
      .attr('transform', `translate(${marginLeft}, 0)`)
      .attr('class', 'yAxis')
      .call(yAxis);

    this.createYGrid(svg);

    svg.append('g')
      .attr('transform', `translate(${marginLeft}, ${height})`)
      .attr('class', 'xAxis')
      .call(xAxis);

    svg.selectAll('.xAxis')
      .selectAll('.domain')
      .attr('d', `M0,0L${width},0`);

    svg.selectAll('.domain')
      .attr('stroke-opacity', 0.5)
      .attr('stroke', '#808080');

    svg.selectAll('.tick')
      .selectAll('text')
      .attr('fill', '#808080');

    if (valueKey === 'conversion_rate') {
      const areaData = this.formatAreaData(results);
      const area = d3.area()
        .curve(d3.curveMonotoneX)
        .x((d) => xScale(d.timestamp))
        .y0((d) => yScale(d.lower))
        .y1((d) => yScale(d.upper));

      const baseArea = d3.area()
        .curve(d3.curveMonotoneX)
        .x((d) => xScale(d.timestamp))
        .y0(yScale(0))
        .y1(yScale(0));

      svg.append('g')
        .attr('class', 'data-area-container')
        .attr('transform', `translate(${marginLeft},0)`)
        .selectAll('path')
        .data(areaData)
        .enter()
        .append('path')
        .attr('class', (d, index) => `data-area data-area-${index}`)
        .attr('d', baseArea)
        .attr('fill', (d, index) => colorScale(index))
        .attr('opacity', 0.2)
        .transition()
        .duration(500)
        .ease(d3.easeExp)
        .attr('d', area);
    }

    svg.append('g').selectAll('path')
      .data(lineData)
      .enter()
      .append('path')
      .attr('class', (d, index) => `data-line data-line-${index}`)
      .attr('d', (d) => this.createBaseline(height, line(d).split('C').length))
      .attr('fill', 'none')
      .attr('stroke', (d, index) => colorScale(index))
      .attr('stroke-width', 3)
      .attr('stroke-opacity', 1)
      .attr('transform', `translate(${marginLeft})`)
      .transition()
      .duration(500)
      .ease(d3.easeExp)
      .attr('d', (d) => this.createBaseline(yScale(d[0].value), line(d).split('C').length))
      .transition()
      .duration(500)
      .ease(d3.easeExp)
      .attrTween('d', (d, index) => {
        const previous = svg.select(`.data-line-${index}`).attr('d');
        return interpolatePath(previous, line(d));
      })
      .attr('transform', `translate(${marginLeft})`)
      .style('pointer-events', 'none');

    const tooltipHtmlStrings = results.map(
      (res) => createExperimentTooltipHtml(res, styles, valueKey, keyToLabel, idToName),
    );

    createCrosshairs(results, svg, tooltip, chartAttrs, tooltipHtmlStrings);
  }

  // eslint-disable-next-line class-methods-use-this
  render() {
    return (
      <div className={styles.chartContainer} id="experimentResults" />
    );
  }
}
ExperimentResultsLineChart.displayName = 'ExperimentResultsLineChart';

ExperimentResultsLineChart.propTypes = {
  results: PropTypes.arrayOf(ApiInternalPropTypes.experimentResultsWithTimestamp).isRequired,
  idToName: PropTypes.object.isRequired, // eslint-disable-line
  valueKey: PropTypes.string.isRequired,
  selectedRow: PropTypes.string,
};

ExperimentResultsLineChart.defaultProps = {
  selectedRow: '',
};
