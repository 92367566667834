import assign from 'lodash/assign';
import AuthActionTypes from '../../auth/constants/action-types';
import { ConsoleActionTypes } from '../constants';
import { RootActionTypes } from '../../../stores/types';
import { DefaultReducerState } from '../types/state';
import { ConsolePushErrorAction } from '../types/actions';

const initialState: DefaultReducerState = {
  errors: [],
  isLoggedIn: false,
};

function addError(state: DefaultReducerState, action: ConsolePushErrorAction): DefaultReducerState {
  const errors = state.errors || [];
  // TODO: After the changes introduced on #457 our failure payload shape has diverged and we are
  // forced to check for the existence of a `messages` member for backward compatibility. We need to
  // go through all the error objects dispatched without using our custom async action middleware
  // and update them to follow the new payload shape.
  // https://github.com/flowcommerce/console/pull/457
  const updatedErrors = errors.concat(action.payload.messages || action.payload || []);

  return assign({}, state, { errors: updatedErrors });
}

export default function defaultReducer(
  state: DefaultReducerState = initialState,
  action: RootActionTypes,
): DefaultReducerState {
  switch (action.type) {
    case AuthActionTypes.AUTH_REGISTER_USER_COMPLETED:
    case AuthActionTypes.AUTH_LOGIN_USER_COMPLETED:
      return assign({}, state, {
        isLoggedIn: !!action.payload.auth,
        user: action.payload,
      });
    case AuthActionTypes.AUTH_LOGOUT_USER_SUCCESS:
      return assign({}, state, {
        isLoggedIn: false,
        user: {},
      });
    case ConsoleActionTypes.REDIRECT:
      return assign({}, state, { __redirect: action.payload.location });
    case ConsoleActionTypes.PUSH_ERROR:
      return addError(state, action);
    case ConsoleActionTypes.RESET_ERRORS:
      return assign({}, state, { errors: [] });
    default:
      return state;
  }
}
