import { css } from '@emotion/css';
import colors from '../../../../../theme/tokens/colors';

export const label = css`
  display: inline-block;
  font-size: 16px;
  line-height: 1.25;
  color: ${colors.steel};

  &:not(:first-child) {
    margin-left: 1rem;
  }
`;

export const status = css`
  font-style: italic;
  color: ${colors.silver};
`;

export const statementOption = css`
  white-space: nowrap;
`;

export const transactionsTableContainer = css`
  min-width: 768px;
  overflow: scroll;
  white-space: nowrap;
`;
