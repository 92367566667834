import update from 'immutability-helper';
import ActionTypes from '../constants/action-types';
import ReadyStates from '../constants/ready-states';
import { AnalyticsState } from '../types';
import { RootActionTypes } from '../../../stores/types';

const defaultState: AnalyticsState['gmvByTimeline'] = {
  readyState: ReadyStates.PENDING,
  params: {},
};

export default function (
  state: AnalyticsState['gmvByTimeline'] = defaultState,
  action: RootActionTypes,
): AnalyticsState['gmvByTimeline'] {
  switch (action.type) {
    case ActionTypes.FETCH_TIMELINE_REVENUE_REQUEST:
      return update(state, {
        $unset: ['error'],
        readyState: { $set: ReadyStates.LOADING },
        params: { $set: action.params },
      });
    case ActionTypes.FETCH_TIMELINE_REVENUE_SUCCESS:
      return update(state, {
        data: { $set: action.payload },
        readyState: { $set: ReadyStates.FULFILLED },
      });
    case ActionTypes.FETCH_TIMELINE_REVENUE_FAILURE:
      return update(state, {
        error: { $set: action.payload },
        readyState: { $set: ReadyStates.REJECTED },
      });
    default:
      return state;
  }
}
