import React from 'react';
import { Stack, StackItem } from '@flowio/react-stack';
import { Box, BoxItem } from '@flowio/react-box';
import { Label } from '@flowio/react-label';

import tierSurchargeToSentence from '../../utilities/tierSurchargeToSentence';
import * as styles from './TierList.styles';
import { TierListItemSurchargesProps } from '../../types/components';

const TierListItemSurcharges: React.FC<TierListItemSurchargesProps> = ({
  customerSurcharges,
  merchantSurcharges,
}) => (
  <Box spacing="loose">
    <BoxItem className={styles.tierLabel}>
      <Label content="Surcharges" />
    </BoxItem>
    <BoxItem>
      <Stack orientation="vertical" className={styles.tierValueStack}>
        {customerSurcharges.length > 0 && (
          <div>
            <StackItem>
              <span><b>Customer pays for</b></span>
            </StackItem>
            {customerSurcharges.map((surcharge) => (
              <StackItem key={surcharge.key}>
                <span className={styles.tierSurchargeKey}>
                  {tierSurchargeToSentence(surcharge.key)}
                </span>
              </StackItem>
            ))}
          </div>
        )}
        {merchantSurcharges.length > 0 && (
          <div>
            <StackItem>
              <span><b>Merchant pays for</b></span>
            </StackItem>
            {merchantSurcharges.map((surcharge) => (
              <StackItem key={surcharge.key}>
                <span className={styles.tierSurchargeKey}>
                  {tierSurchargeToSentence(surcharge.key)}
                </span>
              </StackItem>
            ))}
          </div>
        )}
        {merchantSurcharges.length === 0 && customerSurcharges.length === 0 && (
          <span><b>Shipping surcharges not set yet (default to Merchant Pays)</b></span>
        )}
      </Stack>
    </BoxItem>
  </Box>
);

TierListItemSurcharges.displayName = 'TierListItemSurcharges';

export default TierListItemSurcharges;
