import map from 'lodash/map';
import { createSelector } from 'reselect';
import getGoogleLocalizedFeeds from './getGoogleLocalizedFeeds';
import { RootState } from '../../../stores/types';

const getFeedsIds = (feeds: io.flow.internal.v0.models.MarketingGatewayFeed[]): string[] => {
  const feedsIds = map(feeds, (feed) => feed.id);
  return feedsIds;
};

export default createSelector<
RootState, io.flow.internal.v0.models.MarketingGatewayFeed[], string[]
>(
  getGoogleLocalizedFeeds,
  getFeedsIds,
);
