import { FeaturesPutReleaseByKeyResponse } from '@flowio/api-internal-sdk';
import ActionTypes from '../constants/action-types';
import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import toNextStatusForm from '../utilities/toNextStatusForm';

function createRequestAction(): RootActionTypes {
  return {
    type: ActionTypes.CHANGE_STATUS_REQUEST,
  };
}

function createSuccessAction(payload: io.flow.internal.v0.models.Feature): RootActionTypes {
  return {
    type: ActionTypes.CHANGE_STATUS_SUCCESS,
    payload,
  };
}

function createFailureAction(payload: FormattedErrorMessages): RootActionTypes {
  return {
    type: ActionTypes.CHANGE_STATUS_FAILURE,
    payload,
  };
}

export default function changeStatus(
  organization: string,
  key: string,
  status: io.flow.internal.v0.enums.FeatureStatus,
): ThunkResult<Promise<FeaturesPutReleaseByKeyResponse>> {
  return function changeStatusRequest(
    dispatch,
    getState,
    extra,
  ): Promise<FeaturesPutReleaseByKeyResponse> {
    dispatch(createRequestAction());
    const nextStatusForm = toNextStatusForm(status);
    return extra.apiInternal(getState()).features.putStatusByKey({
      key,
      organization,
      body: nextStatusForm,
    }).then((response) => {
      if (response.ok) {
        dispatch(createSuccessAction(response.body));
        return response;
      }

      const error = formatErrorResponseV2(response);

      dispatch(createFailureAction(error));
      return response;
    });
  };
}
