import { submit, isSubmitting } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import FormName from '../constants/FormName';
import DialogName from '../constants/DialogName';
import { getIsDialogOpen, getDialogProp } from '../selectors';
import { hideDialog } from '../actions';
import RuleDeleteConfirm from '../components/RuleDeleteConfirm';
import { RootState, ThunkDispatcher } from '../../../stores/types';
import { RuleDeleteConfirmStateProps, RuleDeleteConfirmValues } from '../types/components';

const confirmation = DialogName.DELETE_RULE_CONFIRM;
const form = FormName.DELETE_RULE_FORM;

const handleSubmit = () => submit(form);

const mapStateToProps = (state: RootState): RuleDeleteConfirmStateProps => ({
  disabled: isSubmitting(form)(state),
  initialValues: getDialogProp(confirmation, 'initialValues')(state) as RuleDeleteConfirmValues,
  open: getIsDialogOpen(confirmation)(state) as boolean,
});

const mapDispatchToProps = (dispatch: ThunkDispatcher) => bindActionCreators({
  onCancel: hideDialog(confirmation),
  onOk: handleSubmit,
}, dispatch);

export default compose<React.FC>(
  connect(mapStateToProps, mapDispatchToProps),
)(RuleDeleteConfirm);
