import { connect, MapDispatchToProps } from 'react-redux';
import { ThunkDispatcher } from '../../../../../stores/types';
import { fetchOrganization } from '../../../../organization';
import MarketingRoot from '../components/MarketingRoot';
import { DispatchProps, OwnProps } from '../types';

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch: ThunkDispatcher,
) => ({
  fetchOrganization: (organization: string) => {
    dispatch(fetchOrganization(organization));
  },
});

export default connect(null, mapDispatchToProps)(MarketingRoot);
