import React from 'react';
import isNil from 'lodash/isNil';
import validator, { ValidatorArgumentMap, Value } from '../../utilities/validator';
import getDisplayName from '../../utilities/react/get-display-name';
import getValueFromEvent from '../../utilities/react/get-value-from-event';
import isEvent from '../../utilities/react/is-event';

export type ValidationFieldsMap = Record<string, FieldShape>;

interface State {
  fields: ValidationFieldsMap;
  isDirty: boolean;
  isInvalid: boolean;
  isPristine: boolean;
  isTouched: boolean;
  isValid: boolean;
}

export interface FieldShape {
  events: {
    onBlur: Function;
    onChange: Function;
  };
  error?: string | null;
  isAutofilled: boolean;
  isDirty: boolean;
  isEmpty: boolean;
  isInvalid: boolean;
  isPristine: boolean;
  isRequired: boolean;
  isTouched: boolean;
  isValid: boolean;
  value: Value;
  initialValue: Value;
}

export interface WithValidationProps {
  autofill: Function;
  isDirty: boolean;
  isInvalid: boolean;
  isPristine: boolean;
  isTouched: boolean;
  isValid: boolean;
  onSubmitValidate: Function;
  reset: Function;
  fields: ValidationFieldsMap;
}

export interface ValidationSubmitFunction {
  (state: Partial<WithValidationProps>, formData: FormData): void;
}

type FormData = Record<string, WithValidationProps['fields']>;

interface FieldOptions {
  validations: ValidatorArgumentMap | ValidatorArgumentMap[];
  format?: (value: Value) => Value;
  normalize?: (value: Value, fields: ValidationFieldsMap) => Value;
  parse?: (value: Value) => Value;
  defaultValue?: Value | Function;
}

type Options = ((props: any) => Record<string, FieldOptions>) | Record<string, FieldOptions>;

// Utilities
// ---------

function getFormData(fields: WithValidationProps['fields']): FormData {
  return Object.keys(fields)
    .reduce((formData, fieldName) => ({ ...formData, [fieldName]: fields[fieldName].value }), {});
}

// Higher-Order Component
// ----------------------

export default function withValidation<P>(options: Options = {}) {
  return function createContainer(
    Component: React.ComponentType<P & WithValidationProps>,
  ): React.ComponentClass<P> {
    return class Validation extends React.Component<P, State> {
      static validateFieldWithValidator(
        _fieldName: string,
        fieldValue: Value,
        fieldRules: ValidatorArgumentMap | ValidatorArgumentMap[],
        isInitial: boolean,
      ): Partial<FieldShape> {
        const validations = Array.isArray(fieldRules) ? fieldRules : [fieldRules];
        const results = validations.map((validation) => validator.validate(fieldValue, validation));
        const isFieldEmpty = fieldValue === '';
        const isFieldRequired = validations.some((validation) => validation.required === true);
        // TODO: Could the required/valid logic be kept in validator utility? It would be nice for
        // us not to have to double check. If the required logic in the validator changes we have
        // to maintain it here too!
        const isFieldValid = (!isFieldRequired && isFieldEmpty)
          || results.every((isValid) => isValid);

        return {
          isEmpty: isFieldEmpty,
          isInvalid: !isFieldValid,
          isRequired: isFieldRequired,
          isValid: isFieldValid,
          value: fieldValue,
          error: isInitial || isFieldValid ? null : validations[results.indexOf(false)].message,
        };
      }

      // eslint-disable-next-line react/static-property-placement
      static displayName = `WithValidation(${getDisplayName(Component)})`;

      constructor(ownProps: P, ...args: any[]) {
        super(ownProps, args);

        if (typeof options === 'function') {
          this.options = options(ownProps);
        } else {
          this.options = options;
        }

        this.state = {
          fields: {},
          isValid: false,
          isDirty: false,
          isInvalid: false,
          isPristine: true,
          isTouched: false,
        };
      }

      // eslint-disable-next-line camelcase
      UNSAFE_componentWillMount(): void {
        this.reset();
      }

      handleBlur(fieldName: string, event: React.SyntheticEvent): void {
        const { parse, normalize } = this.getEventLifecyclesByFieldName(fieldName);
        const { fields } = this.state;

        let fieldValue = getValueFromEvent(event);

        if (parse) {
          fieldValue = parse(fieldValue);
        }

        if (normalize) {
          fieldValue = normalize(fieldValue, fields);
        }

        const nextField = this.validateField(fieldName, fieldValue);

        const prevField = fields[fieldName];

        this.updateFields({
          [fieldName]: {
            ...nextField,
            isAutofilled: prevField.isAutofilled && prevField.value === fieldValue,
            isTouched: true,
          },
        });
      }

      handleChange(fieldName: string, event: React.SyntheticEvent): void {
        const { parse, normalize } = this.getEventLifecyclesByFieldName(fieldName);
        const { fields } = this.state;

        let fieldValue = getValueFromEvent(event);

        if (parse) {
          fieldValue = parse(fieldValue);
        }

        if (normalize) {
          fieldValue = normalize(fieldValue, fields);
        }

        // Update field value, without performing any validation. In the future, we can consider
        // adding some option to validate on change.
        this.updateFields({
          [fieldName]: {
            isAutofilled: false,
            value: fieldValue,
          },
        });
      }

      handleSubmit = (handler: ValidationSubmitFunction) => (event: React.SyntheticEvent): void => {
        if (isEvent(event)) event.preventDefault();
        // Validate all form fields. It's highly possible for this operation to be redundant since
        // each field was likely validated on blur event. However, we're going to take the penalty
        // in order to ensure that submitting a blank form render errors and submitting a form as a
        // result of pressing the enter key on a focused field validates the field before
        // submitting.
        const fields = this.validateForm();
        const isFormValid = this.isFormValid(fields);
        const isFormPristine = this.isFormPristine(fields);
        const isFormTouched = this.isFormTouched(fields);
        this.updateFields(fields);
        handler({
          isDirty: !isFormPristine,
          isInvalid: !isFormValid,
          isPristine: isFormPristine,
          isTouched: isFormTouched,
          isValid: isFormValid,
        }, getFormData(fields));
      };

      getEventLifecyclesByFieldName(fieldName: string): Partial<FieldOptions> {
        const { parse, format, normalize } = this.options[fieldName];
        return { parse, format, normalize };
      }

      getValidationRulesByFieldName(
        fieldName: string,
      ): ValidatorArgumentMap | ValidatorArgumentMap[] {
        return this.options[fieldName].validations;
      }

      getChildProps(): WithValidationProps {
        const {
          fields,
          isDirty,
          isInvalid,
          isPristine,
          isTouched,
          isValid,
        } = this.state;

        const reducedFields = Object.keys(fields).reduce((fieldsObject, fieldName) => {
          const fieldState = fields[fieldName];
          const { format } = this.getEventLifecyclesByFieldName(fieldName);

          let fieldValue = fieldState.value;

          if (format) {
            fieldValue = format(fieldValue);
          }

          return {
            ...fieldsObject,
            [fieldName]: {
              ...fieldState,
              // Value, formatted only if format is specified,
              value: fieldValue,
              // Events that should be attached to the input fields
              events: {
                onChange: this.handleChange.bind(this, fieldName),
                onBlur: this.handleBlur.bind(this, fieldName),
              },
            },
          };
        }, {});

        return {
          // Inject properties injected from parent (ownProps)
          ...this.props,
          autofill: this.autofill,
          reset: this.reset,
          fields: reducedFields,
          isDirty,
          isInvalid,
          isPristine,
          isTouched,
          isValid,
          onSubmitValidate: this.handleSubmit,
        };
      }

      options: Record<string, FieldOptions>;

      /**
       * Sets the value and marks the fields as `isAutofilled` in the state. This is useful when
       * one or multiple fields need to be set programmatically, but in a way that lets the user
       * know (via a styling change using the `isAutofilled` property in each field) that it has
       * been autofilled for them programmatically.
       * @param {Object} fields An object where each property is a field with its value
       */
      autofill = (fields: Record<string, Value>): void => {
        const partialFields = Object.keys(fields).reduce((previousFields, fieldName) => {
          const fieldValue = fields[fieldName];
          const fieldState = this.validateField(fieldName, fieldValue);
          return { ...previousFields, [fieldName]: { ...fieldState, isAutofilled: true } };
        }, {});

        this.updateFields(partialFields);
      };

      /**
       * Resets all fields to their initial value
       */
      reset = (): void => {
        const initialFields = this.initializeFields();
        this.updateFields(initialFields);
      };

      initializeFields(): ValidationFieldsMap {
        return Object.keys(this.options).reduce((results, fieldName) => {
          const fieldOptions = this.options[fieldName];

          let fieldDefaultValue = fieldOptions.defaultValue;

          if (typeof fieldDefaultValue === 'function') {
            fieldDefaultValue = fieldDefaultValue(this.props);
          }

          const fieldInitialValue = isNil(fieldDefaultValue) ? '' : fieldDefaultValue;

          // Set initial field validity
          const nextStateForField = this.validateField(fieldName, fieldInitialValue, true);

          return {
            ...results,
            [fieldName]: {
              ...nextStateForField,
              name: fieldName,
              defaultValue: fieldDefaultValue,
              initialValue: fieldInitialValue,
              isTouched: false,
            },
          };
        }, {});
      }

      updateFields(partialFields: Record<string, Partial<FieldShape>>): void {
        this.setState((previousState) => {
          const nextFields = Object.keys(partialFields).reduce((previousFields, fieldName) => {
            const previousField = previousFields[fieldName];
            const partialField = partialFields[fieldName];
            const nextField = { ...previousField, ...partialField };
            const isPristine = nextField.value === nextField.initialValue;

            nextField.isPristine = isPristine;
            nextField.isDirty = !isPristine;

            return { ...previousFields, [fieldName]: nextField };
          }, previousState.fields);

          const isFormValid = this.isFormValid(nextFields);
          const isFormPristine = this.isFormPristine(nextFields);
          const isFormTouched = this.isFormTouched(nextFields);

          return {
            fields: nextFields,
            isDirty: !isFormPristine,
            isInvalid: !isFormValid,
            isPristine: isFormPristine,
            isTouched: isFormTouched,
            isValid: isFormValid,
          };
        });
      }

      /**
       * Validates the value of all fields in the form against their validation rules and returns
       * the next state for the fields that should eventually be used to update the state.
       */
      validateForm() {
        const { fields } = this.state;
        return Object.keys(fields).reduce((nextStateForFields, fieldName) => {
          const fieldState = fields[fieldName];
          const fieldValue = fieldState.value;
          const nextStateForField = this.validateField(fieldName, fieldValue);
          return { ...nextStateForFields, [fieldName]: { ...fieldState, ...nextStateForField } };
        }, {});
      }

      validateFieldWithCustomFunction(
        _fieldName: string,
        fieldValue: Value,
        fieldValidator: Function,
        isInitial: boolean,
      ): Partial<FieldShape> {
        const { fields } = this.state;
        const errorText = fieldValidator(fieldValue, fields);
        const isFieldEmpty = fieldValue === '';
        const isFieldRequired = !!errorText;
        const isFieldValid = !errorText;

        return {
          isEmpty: isFieldEmpty,
          isRequired: isFieldRequired,
          isValid: isFieldValid,
          isInvalid: !isFieldValid,
          value: fieldValue,
          error: isInitial || isFieldValid ? null : errorText,
        };
      }

      /**
       * Validates the value of a field against its validation rules
       * @param {String} fieldName - Name of the field to validate.
       * @param {Mixed} fieldValue - Value of the field to validate.
       * @param {Object|Array} fieldRules - Validation rules for the field to validate.
       * @returns {Object} the state of the field that should eventually be used to update the
       * component's state.
       */
      validateField(
        fieldName: string,
        fieldValue: Value,
        isInitial = false,
      ): Partial<FieldShape> {
        const fieldRules = this.getValidationRulesByFieldName(fieldName);

        if (typeof fieldRules === 'function') {
          return this.validateFieldWithCustomFunction(fieldName, fieldValue, fieldRules, isInitial);
        }

        return Validation.validateFieldWithValidator(fieldName, fieldValue, fieldRules, isInitial);
      }

      /**
       * Just returns a boolean indicating whether the form is valid. Does not run any
       * validation tests, just checks field validity (stored in state).
       */
      // eslint-disable-next-line react/destructuring-assignment
      isFormValid(fields = this.state.fields): boolean {
        return Object.keys(fields)
          .map((fieldName) => fields[fieldName])
          .every((field) => field.isValid);
      }

      /**
       * Returns a boolean indicating whether the form is the same as its initial values.
       */
      // eslint-disable-next-line react/destructuring-assignment
      isFormPristine(fields = this.state.fields): boolean {
        return Object.keys(fields)
          .map((fieldName) => fields[fieldName])
          .every((field) => field.isPristine);
      }

      /**
       * Returns a boolean indicating whether any of the form fields has been touched.
       */
      // eslint-disable-next-line react/destructuring-assignment
      isFormTouched(fields = this.state.fields): boolean {
        return Object.keys(fields)
          .map((fieldName) => fields[fieldName])
          .some((field) => field.isTouched);
      }

      /**
       * Just returns a boolean indicating whether a field is valid. Does not run any
       * validation tests, just checks field validity (stored in state).
       */
      isFieldValid(fieldName: string): boolean {
        const { fields } = this.state;
        return fields[fieldName].isValid;
      }

      render(): React.ReactNode {
        return (
          <Component {...this.getChildProps()} {...this.props} />
        );
      }
    };
  };
}
