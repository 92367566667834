enum ActionType {
  CreateExclusionRuleRequest = 'CreateExclusionRuleRequest',
  CreateExclusionRuleSuccess = 'CreateExclusionRuleSuccess',
  CreateExclusionRuleFailure = 'CreateExclusionRuleFailure',
  DeleteExclusionRuleFailure = 'DeleteExclusionRuleFailure',
  DeleteExclusionRuleRequest = 'DeleteExclusionRuleRequest',
  DeleteExclusionRuleSuccess = 'DeleteExclusionRuleSuccess',
  FetchExclusionRulesFailure = 'FetchExclusionRulesFailure',
  FetchExclusionRulesRequest = 'FetchExclusionRulesRequest',
  FetchExclusionRulesSuccess = 'FetchExclusionRulesSuccess',
  HideExclusionRuleDeleteConfirmation = 'HideExclusionRuleDeleteConfirmation',
  HideExclusionRuleWorksheet = 'HideExclusionRuleWorksheet',
  ShowExclusionRuleDeleteConfirmation = 'ShowExclusionRuleDeleteConfirmation',
  ShowExclusionRuleWorksheetFailure = 'ShowExclusionRuleWorksheetFailure',
  ShowExclusionRuleWorksheetRequest = 'ShowExclusionRuleWorksheetRequest',
  ShowExclusionRuleWorksheetSuccess = 'ShowExclusionRuleWorksheetSuccess',
  SubmitExclusionRuleForm = 'SubmitExclusionRuleForm',
  SubmitExclusionRuleFormError = 'SubmitExclusionRuleFormError',
  UpdateExclusionRuleRequest = 'UpdateExclusionRuleRequest',
  UpdateExclusionRuleSuccess = 'UpdateExclusionRuleSuccess',
  UpdateExclusionRuleFailure = 'UpdateExclusionRuleFailure',
  ShowExportDialog = 'ShowExportDialog',
  HideExportDialog = 'HideExportDialog',
}

export default ActionType;
