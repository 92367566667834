import Client from './client';
import { ClientService, LegacyResponse } from '../types/server';
import { ConsoleServerSuggestions } from '../../../types';

export default class Suggestions extends Client implements ClientService {
  host: string;

  auth: string;

  headers: Record<string, string>;

  constructor(host: string, auth: string, headers: Record<string, string>) {
    super({ serviceName: 'server' });

    this.host = host;
    this.auth = auth;
    this.headers = headers;
  }

  getCatalogItems(
    organization: string,
    options = {},
  ): Promise<LegacyResponse<ConsoleServerSuggestions>> {
    Object.assign(options, { method: 'GET' });
    return this.makeRequest(`${this.host}/${organization}/suggestion/catalog`, options);
  }
}
