type PlatformInfo = {
  platformName: string;
  shortName: string;
  accountUrl?: string;
  tokenExpiryMessage?: string;
};

const PlatformObject: Record<io.flow.internal.v0.enums.MarketingGatewayPlatform, PlatformInfo> = {
  google: {
    platformName: 'Google Merchant Center',
    shortName: 'Google',
  },
  facebook: {
    platformName: 'Facebook Catalog Manager',
    shortName: 'Facebook',
    accountUrl: 'https://business.facebook.com/commerce_manager',
    tokenExpiryMessage: 'To continue to update your Facebook localized product feeds, please re-authorize Flow\'s access to your Facebook Catalog Manager account.',
  },
  adroll: {
    platformName: 'AdRoll',
    shortName: 'AdRoll',
    accountUrl: 'https://app.adroll.com',
  },
  aliexpress: {
    platformName: 'AliExpress',
    shortName: 'AliExpress',
  },
  amazon: {
    platformName: 'Amazon',
    shortName: 'Amazon',
  },
  bing: {
    platformName: 'Bing',
    shortName: 'Bing',
  },
  criteo: {
    platformName: 'Criteo',
    shortName: 'Criteo',
    accountUrl: 'https://marketing.criteo.com/campaigns/management ',
  },
  fruugo: {
    platformName: 'Fruugo',
    shortName: 'Fruugo',
  },
  pinterest: {
    platformName: 'Pinterest',
    shortName: 'Pinterest',
  },
  rakuten_japan: {
    platformName: 'Rakuten',
    shortName: 'Rakuten',
  },
  snapchat: {
    platformName: 'Snapchat',
    shortName: 'Snapchat',
  },
  wish: {
    platformName: 'wish',
    shortName: 'wish',
  },
  stylight: {
    platformName: 'Stylight',
    shortName: 'Stylight',
  },
} as const;

const Platforms = (
  platform: io.flow.internal.v0.enums.MarketingGatewayPlatform,
): PlatformInfo => PlatformObject[platform] || {
  platformName: platform,
  shortName: platform,
};

export default Platforms;
