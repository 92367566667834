import React from 'react';
import { Form, Field } from 'redux-form';

import GenericError from '../../../../components/GenericError';
import { RuleDeleteConfirmFormProps } from '../../types/components';

const RuleDeleteConfirmForm: React.FC<RuleDeleteConfirmFormProps> = ({
  handleSubmit,
  error,
}) => (
  <Form onSubmit={handleSubmit}>
    <Field component="input" type="hidden" name="organizationId" />
    <Field component="input" type="hidden" name="feature" />
    <Field component="input" type="hidden" name="ruleId" />
    {error && (<GenericError error={error} />)}
    <div>
      This action cannot be undone. Are you sure you want to this rule?
    </div>
  </Form>
);

RuleDeleteConfirmForm.displayName = 'RuleDeleteConfirmForm';

RuleDeleteConfirmForm.defaultProps = {
  error: undefined,
};

export default RuleDeleteConfirmForm;
