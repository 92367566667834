import { browserHistory } from 'react-router';
import {
  ThunkResult,
} from '../../../stores/types';

interface GoToAddFeedsParams {
  organizationId: string;
  accountId?: string;
  platformId: io.flow.internal.v0.enums.MarketingGatewayPlatform;
  isOnboarding?: boolean;
}

function goToAddFeedsPage({
  organizationId,
  accountId,
  platformId,
  isOnboarding,
}: GoToAddFeedsParams): ThunkResult<void> {
  return (): void => browserHistory.push({
    pathname: `/${organizationId}/marketing/${platformId}/${accountId ? `${accountId}/` : ''}add-feeds`,
    query: { isOnboarding },
  });
}

export default goToAddFeedsPage;
