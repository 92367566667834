import ErrorTypes from '../constants/error-types';

declare global {
  interface Window {
    FB?: facebook.FacebookStatic;
    fbAsyncInit: () => void;
  }
}

const VERSION = 'v9.0';
const FACEBOOK_SDK_URL = 'https://connect.facebook.net/en_US/sdk.js';

const init = (): Promise<void> => {
  if (!process.browser) {
    throw new Error(ErrorTypes.SERVERSIDE_ERROR);
  }

  if (window.FB) {
    return Promise.resolve();
  }

  return new Promise((resolve, reject) => {
    const injectFBSdk = (): void => {
      const script = document.createElement('script');
      script.src = FACEBOOK_SDK_URL;
      script.async = true;
      script.addEventListener(
        'error',
        () => reject(new Error(ErrorTypes.INITIALIZATION_ERROR)),
      );

      const sibling = document.getElementsByTagName('script')[0];
      sibling?.parentNode?.insertBefore(script, sibling);
    };

    window.fbAsyncInit = () => {
      window.FB.init({
        appId: process.env.FACEBOOK_CLIENT_ID,
        version: VERSION,
        status: true,
      });

      if (window.FB) {
        resolve();
      } else {
        reject(new Error(ErrorTypes.INITIALIZATION_ERROR));
      }
    };

    injectFBSdk();
  });
};

export default init;
