import { Link } from 'react-router';
import ApiPropTypes from '@flowio/api-prop-types';
import PropTypes from 'prop-types';
import React from 'react';
import kebabCase from 'lodash/kebabCase';

import { OrganizationCard } from '../../organization';

const propTypes = {
  organizations: PropTypes.arrayOf(ApiPropTypes.organization),
};

const defaultProps = {
  organizations: [],
};

const Select = ({ organizations }) => (
  <section className="page-auth full-height-top-nav flex items-center">
    <section className="col-8 mx-auto center">
      <p className="h2">Choose An Organization</p>
      <div className="flex flex-wrap justify-center">
        {organizations.map((organization) => (
          <Link
            className="mb3 mr3"
            style={{ textDecoration: 'none' }}
            key={kebabCase(organization.id)}
            to={`/${organization.id}/organization/policies`}
          >
            <OrganizationCard organization={organization} />
          </Link>
        ))}
      </div>
    </section>
  </section>
);

Select.propTypes = propTypes;
Select.defaultProps = defaultProps;

export default Select;
