import { connect } from 'react-redux';
import {
  getSelectedOrderItems,
  getShippingLabelRequestStatus,
  getCurrentOrder,
  getCurrentItemContents,
  getInvoiceOfCurrentLabelResponse,
} from '../selectors';

import {
  changeItemQuantity,
  clearCurrentItems,
  clearCurrentOrder,
  deselectItem,
  selectItem,
  selectAllItems,
  printLabel,
  clearLabelResponse,
  printInvoice,
} from '../actions';
import { parseInteger } from '../../../utilities/numbers';
import Order from '../components/Order';
import { getOrganizationId } from '../../organization/selectors';
import { OrderDispatchProps, OrderStateProps } from '../types/components';
import { RootState, ThunkDispatcher } from '../../../stores/types';
import { getIsChannelOrgAdmin, getIsFulfillmentUser } from '../../user';

function mapStateToProps(state: RootState): OrderStateProps {
  return {
    shippingLabelRequestStatus: getShippingLabelRequestStatus(state),
    organizationId: getOrganizationId(state),
    order: getCurrentOrder(state),
    selectedOrderItems: getSelectedOrderItems(state),
    itemContents: getCurrentItemContents(state),
    isChannelOrgAdmin: getIsChannelOrgAdmin(state),
    isFulfillmentUser: getIsFulfillmentUser(state),
    invoiceOfCurrentLabelResponse: getInvoiceOfCurrentLabelResponse(state),
  };
}

function mapDispatchToProps(dispatch: ThunkDispatcher): OrderDispatchProps {
  return {
    onItemSelection(
      item: io.flow.v0.models.LocalizedLineItem,
      isSelected: boolean,
      index: number,
    ) {
      if (isSelected) {
        dispatch(selectItem({ item, index }));
      } else {
        dispatch(deselectItem({ item, index }));
      }
    },
    onItemQuantityChange(
      item: io.flow.v0.models.LocalizedLineItem,
      quantity: string,
      index: number,
    ) {
      dispatch(changeItemQuantity({
        index,
        number: item.number,
        quantity: Math.min(parseInteger(quantity, 10, 0), item.quantity),
      }));
    },
    onRequestPrintLabel() {
      dispatch(printLabel());
    },
    onRequestPrintInvoice() {
      dispatch(printInvoice());
    },
    onRequestClearOrder() {
      dispatch(clearCurrentOrder());
      dispatch(clearLabelResponse());
    },
    onRequestClearItems() {
      dispatch(clearCurrentItems());
    },
    onRequestSelectAllItems() {
      dispatch(selectAllItems());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Order);
