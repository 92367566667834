import { createSelector } from 'reselect';
import get from 'lodash/get';
import getExperiencesByShippingConfiguration from './getExperiencesByShippingConfiguration';

function shouldFetchExperiences(shippingConfigurationKey: string) {
  return createSelector(
    getExperiencesByShippingConfiguration,
    (experiencesByShippingConfiguration) => {
      const experience = get(experiencesByShippingConfiguration, shippingConfigurationKey);
      return experience == null || experience.status !== 'fulfilled';
    },
  );
}

export default shouldFetchExperiences;
