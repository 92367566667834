import ActionTypes from '../constants/ActionTypes';
import { RootActionTypes } from '../../../stores/types';

function hideFeedsCreationConfirmationDialog(): RootActionTypes {
  return {
    type: ActionTypes.FEEDS_CREATION_CONFIRMATION_DIALOG_CLOSE,
  };
}

export default hideFeedsCreationConfirmationDialog;
