import defaultTo from 'lodash/defaultTo';
import get from 'lodash/get';
import includes from 'lodash/includes';
import omitBy from 'lodash/omitBy';
import isUndefined from 'lodash/isUndefined';
import matchesProperty from 'lodash/matchesProperty';

import ContentElementType from '../constants/ContentElementType';
import ConsentType from '../constants/ConsentType';
import PromptOptions from '../constants/PromptOptions';
import PromptTarget from '../constants/PromptTarget';
import DisplayPosition from '../constants/DisplayPosition';

export function toOptinPromptForm(values) {
  const promptOptions = [];

  // Build prompt options
  const isNotice = values.consentType !== ConsentType.EXPLICIT;

  if (isNotice) {
    promptOptions.push(PromptOptions.NOTICE_ONLY);
  }

  if (values.isCheckboxDefaultChecked) {
    promptOptions.push(PromptOptions.CONSENT_BY_DEFAULT);
  }

  if (values.isCheckboxRequired) {
    promptOptions.push(PromptOptions.REQUIRE_CONSENT);
  }

  // Build prompt content elements
  const elements = {
    text: {
      type: ContentElementType.MARKDOWN,
      value: values.message,
    },
  };

  let display;

  if (values.optinAttributeTarget === PromptTarget.BROWSE) {
    if (isNotice) {
      elements.dismiss = {
        type: ContentElementType.PLAIN_TEXT,
        value: values.dismissButtonText,
      };
    } else {
      elements.accept = {
        type: ContentElementType.PLAIN_TEXT,
        value: values.acceptButtonText,
      };

      elements.reject = {
        type: ContentElementType.PLAIN_TEXT,
        value: values.rejectButtonText,
      };
    }
  } else if (values.optinAttributeTarget === PromptTarget.CHECKOUT) {
    elements.error_consent_missing = {
      type: ContentElementType.PLAIN_TEXT,
      value: values.errorText,
    };

    display = {
      discriminator: 'optin_prompt_checkout_display',
      display_position: values.displayPosition,
    };
  }

  return omitBy({
    optin_attribute_key: values.optinAttributeKey,
    region: values.region,
    options: promptOptions,
    content: {
      locale: values.language,
      status: values.contentStatus,
      elements: omitBy(elements, matchesProperty('value', undefined)),
    },
    display,
    position: 0,
  }, isUndefined);
}

export function toOptinContentFormValues(organizationId, optinAttribute, optinPrompt) {
  const promptOptions = get(optinPrompt, 'options');
  const isNotice = includes(promptOptions, PromptOptions.NOTICE_ONLY);
  return {
    organizationId,
    // NOTE: We use this to determine whether we are creating or updating an optin prompt.
    optinPromptId: get(optinPrompt, 'id'),
    optinAttributeKey: defaultTo(
      get(optinPrompt, 'optin_attribute.optin_attribute_key'),
      get(optinAttribute, 'optin_attribute_key'),
    ),
    // NOTE: We use this to create a proper optin prompt form
    optinAttributeTarget: get(optinAttribute, 'target'),
    // Hm, this one is modeled weird.
    consentType: isNotice ? ConsentType.IMPLICIT : ConsentType.EXPLICIT,
    contentStatus: defaultTo(
      get(optinPrompt, 'content.status'),
      get(optinAttribute, 'status'),
    ),
    region: get(optinPrompt, 'region'),
    language: get(optinPrompt, 'content.locale'),
    isCheckbox: !isNotice,
    isCheckboxRequired: includes(promptOptions, PromptOptions.REQUIRE_CONSENT),
    isCheckboxDefaultChecked: includes(promptOptions, PromptOptions.CONSENT_BY_DEFAULT),
    message: get(optinPrompt, 'content.elements.text.value'),
    acceptButtonText: get(optinPrompt, 'content.elements.accept.value'),
    rejectButtonText: get(optinPrompt, 'content.elements.reject.value'),
    dismissButtonText: get(optinPrompt, 'content.elements.dismiss.value'),
    displayPosition: get(optinPrompt, 'display.display_position', DisplayPosition.SUBMISSION),
    errorText: get(optinPrompt, 'content.elements.error_consent_missing.value'),
  };
}
