import React from 'react';
import noop from 'lodash/noop';
import BemHelper from '@flowio/bem-helper';
import { PageContent } from '@flowio/react-page';

import ClassificationNotAvailable from '../../not-available';
import SummaryCharts from '../../summary-charts';
import Hs6 from '../../hs6';
import { MergedProps as Props } from '../types';

const bem = new BemHelper('classification-overview');

const ClassificationOverview: React.FC<Props> = (props: Props) => {
  const {
    statistics,
    organization,
    onRequestUpdate,
    q,
  } = props;
  const available = false;

  return (
    <PageContent className={bem.block()}>
      {available ? (
        <ClassificationNotAvailable organization={organization} />
      ) : (
        <div>
          <SummaryCharts
            statistics={statistics}
            onLegendClick={onRequestUpdate}
            currentQuery={q}
          />
          <Hs6 {...props} />
        </div>
      )}
    </PageContent>
  );
};
ClassificationOverview.displayName = 'ClassificationOverview';

ClassificationOverview.defaultProps = {
  exportSuccessful: false,
  scope: '',
  q: '',
  codes: [],
  attributes: [],
  maxRecordsFetched: false,
  onRequestUpdate: noop,
  onRequestExport: noop,
  onRequestNextPage: noop,
  onRequestViewItem: noop,
};

export default ClassificationOverview;
