import DialogName from '../constants/DialogName';
import showDialog from './showDialog';

const activateOptinMessage = (
  organizationId: string,
  optinAttribute: io.flow.internal.v0.models.OptinAttribute,
) => showDialog(DialogName.OPTIN_MESSAGE_STATUS_CONFIRM)({
  initialValues: {
    name: optinAttribute.name,
    optinAttributeKey: optinAttribute.optin_attribute_key,
    organizationId,
    status: 'live',
    target: optinAttribute.target,
  },
});

export default activateOptinMessage;
