import { css } from '@emotion/css';
import { colors } from '../../../../theme/tokens';

export const title = css`
  color: ${colors.marketing['dark-slate-blue']};
`;

export const dropdown = css`
  width: 100%;
`;

export const noFeeds = css`
  margin-top: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${colors.chart.red};

  svg {
    width: 24px;
    height: 24px;
    margin-right: 12px;
    color: ${colors.chart.yellow};
  }
`;
