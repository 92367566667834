import { compose } from 'redux';
import withSubmit from '../../../../../components/with-submit';
import withValidate from '../../../../../components/with-validation';
import { createCatalogExport } from '../../../actions';
import ExportFormDialog from '../components/export-form-dialog';
import { Props } from '../types';
import { CreateCatalogExportResponse } from '../../../actions/create-catalog-export';
import { FormattedErrorMessages } from '../../../../../utilities/format-error-response-v2';

const ConnectExportFormDialog = compose<React.FC<Props>>(
  withSubmit<CreateCatalogExportResponse | FormattedErrorMessages>(
    (formData, _getState, props) => createCatalogExport(formData, props.q),
  ),
  withValidate<Props>(({
    email, numbers = [], q, sort = 'number',
  }) => ({
    email: {
      defaultValue: email,
      validations: [{
        required: true,
        message: 'An email address is required',
      }, {
        pattern: 'email',
        message: 'Please enter a valid email address',
      }],
    },
    numbers: {
      defaultValue: numbers,
      validations: {
        required: false,
      },
    },
    q: {
      defaultValue: q,
      validations: {
        required: false,
      },
    },
    sort: {
      defaultValue: sort,
      validations: {
        required: false,
      },
    },
  })),
)(ExportFormDialog);

export default ConnectExportFormDialog;
