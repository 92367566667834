import sortBy from 'lodash/sortBy';
import { browserHistory } from 'react-router';
import { LocationDescriptor } from 'history';
import { connect, MapStateToProps } from 'react-redux';
import Home from '../components/home';
import { defaultRedirect } from '../../console/actions';
import { RootState, ThunkDispatcher } from '../../../stores/types';
import { getUserMembershipOrganizations } from '../../user';

type StateProps = {
  organizations: io.flow.v0.models.Organization[];
};

const mapStateToProps: MapStateToProps<StateProps, {}, RootState> = (state) => ({
  organizations: sortBy(getUserMembershipOrganizations(state), 'name'),
});

function mapDispatchToProps(dispatch: ThunkDispatcher) {
  return {
    onRequestCreateOrganization(location: LocationDescriptor) {
      browserHistory.push(location);
    },
    onOrganizationCardClick(_event: Event, organization: io.flow.v0.models.Organization) {
      dispatch(defaultRedirect(organization.id));
    },
    redirectToDefaultOrg(organization: io.flow.v0.models.Organization) {
      dispatch(defaultRedirect(organization.id));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
