import { MembershipsDeleteByIdResponse } from '@flowio/api-sdk';
import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import ActionTypes from '../constants/action-types';

function createRequestAction(): RootActionTypes {
  return {
    type: ActionTypes.REMOVE_MEMBERSHIP_REQUEST,
  };
}

function createSuccessAction(params: { id: string }): RootActionTypes {
  return {
    type: ActionTypes.REMOVE_MEMBERSHIP_SUCCESS,
    params,
  };
}

function createFailureAction(payload: FormattedErrorMessages): RootActionTypes {
  return {
    type: ActionTypes.REMOVE_MEMBERSHIP_FAILURE,
    payload,
  };
}

export default function removeMembership(
  membership: io.flow.v0.models.Membership,
): ThunkResult<Promise<MembershipsDeleteByIdResponse>> {
  return function removeMembershipEffects(
    dispatch,
    getState,
    extra,
  ): Promise<MembershipsDeleteByIdResponse> {
    dispatch(createRequestAction());

    return extra.api(getState()).memberships.deleteById({
      id: membership.id,
    }).then((response) => {
      if (response.ok) {
        dispatch(createSuccessAction(membership));
        return response;
      }

      const errors = formatErrorResponseV2(response);
      dispatch(createFailureAction(errors));
      return response;
    });
  };
}
