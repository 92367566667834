import { Archive, Transaction } from '@flowio/react-icons';
import { Link } from 'react-router';
import { Box } from '@flowio/react-box';
import { Button, OutlineButton, ButtonGroup } from '@flowio/react-button';
import { Select } from '@flowio/react-select';
import React from 'react';
import { DateInputField } from '@flowio/react-date-input';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import toNumber from 'lodash/toNumber';
import moment from 'moment';
import toLower from 'lodash/toLower';
import {
  Card,
  CardHeader,
  CardContent,
  CardFooter,
  CardEmptyState,
  CardTitle,
} from '@flowio/react-card';
import {
  Table,
  TableBody,
  TableHead,
  TableHeadColumn,
  TableRow,
  TableRowColumn,
} from '@flowio/react-table';
import { PageContent } from '@flowio/react-page';
import { css } from '@emotion/react';
import { ExportStatus } from '@flowio/api-constants';
import AccountSelection from '../../account-selection';

import AccountSummary from '../../account-summary';
import Alert from '../../../../../components/alert';
import BillingTransactionStatus from '../../../constants/billing-transaction-status';
import ExportDialog from '../../export-dialog';
import FormattedDate from '../../../../../components/formatted-date';
import Pagination from '../../../../../components/pagination';
import { ThunkResult } from '../../../../../middlewares/types';

import { MergedProps as Props } from '../types';
import { LegacyResponse } from '../../../../../utilities/clients/types/server';
import { ExportResponseType } from '../../../types';
import * as styles from './account-transaction.styles';

const leftBtnStyles = css({
  borderRadius: '3px 0px 0px 3px',
});

const rightBtnStyles = css({
  borderRadius: '0px 3px 3px 0px',
});

const mapTypeToWords = (type: string): string => {
  switch (type) {
    case 'capture': return 'Capture';
    case 'refund': return 'Refund';
    case 'subscription': return 'Subscription';
    case 'transfer': return 'Transfer';
    default: return type;
  }
};

interface AccountTransactionState {
  isExportTransactionsDialogOpen: boolean;
  isExportOrdersDialogOpen: boolean;
}

export default class AccountTransactions extends
  React.PureComponent<Props, AccountTransactionState> {
  static displayName = 'AccountTransactions';

  static defaultProps = {
    defaultEmail: '',
    statement: 'none',
    pageError: undefined,
    status: '' as Props['status'],
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      isExportTransactionsDialogOpen: false,
      isExportOrdersDialogOpen: false,
    };
  }

  handleCreatedFromDateChange = (createdFrom: moment.Moment): void => {
    const { createdTo, onRequestPage } = this.props;
    onRequestPage({
      pageNumber: 1,
      createdTo,
      createdFrom: moment.utc(createdFrom).format('YYYY-MM-DD'),
    });
  };

  handleCreatedToDateChange = (createdTo: moment.Moment): void => {
    const { createdFrom, onRequestPage } = this.props;
    onRequestPage({
      pageNumber: 1,
      createdTo: moment.utc(createdTo).format('YYYY-MM-DD'),
      createdFrom,
    });
  };

  handleStatusChange = ({ value }: { value: io.flow.v0.enums.ExportStatus | '' }): void => {
    const { createdTo, createdFrom, onRequestPage } = this.props;
    onRequestPage({
      createdFrom,
      createdTo,
      pageNumber: 1,
      status: value === BillingTransactionStatus.Any ? undefined : value,
    });
  };

  handleStatementChange = ({ value }: { value: string }): void => {
    const {
      createdTo, createdFrom, status, onRequestPage,
    } = this.props;
    onRequestPage({
      createdFrom,
      createdTo,
      pageNumber: 1,
      statement: value !== 'none' ? value : undefined,
      status: status === BillingTransactionStatus.Any ? undefined : status,
    });
  };

  handleExportTransactionsButtonClick = (): void => {
    this.setState({ isExportTransactionsDialogOpen: true });
  };

  handleExportTransactionsCloseRequest = (): void => {
    this.setState({ isExportTransactionsDialogOpen: false });
  };

  handleExportTransactionsSubmitRequest =
  ({ email }: { email: string }): ThunkResult<Promise<LegacyResponse<ExportResponseType>>> => {
    const {
      createdFrom,
      createdTo,
      onRequestExportTransactions,
      organization,
      status,
      statement,
    } = this.props;

    // Need to return action object for withSubmit to dispatch it.
    return onRequestExportTransactions({
      organization: organization.id,
      emails: [email],
      createdFrom,
      createdTo,
      statement: statement !== 'none' ? statement : undefined,
      status,
    });
  };

  handleExportOrdersButtonClick = (): void => {
    this.setState({ isExportOrdersDialogOpen: true });
  };

  handleExportOrdersCloseRequest = (): void => {
    this.setState({ isExportOrdersDialogOpen: false });
  };

  handleExportOrdersSubmitRequest = (
    { email }: { email: string },
  ): ThunkResult<Promise<LegacyResponse<ExportResponseType>>> => {
    const {
      createdFrom,
      createdTo,
      onRequestExportOrders,
      organization,
      status,
      statement,
    } = this.props;

    // Need to return action for withSubmit to dispatch it.
    return onRequestExportOrders({
      organization: organization.id,
      emails: [email],
      createdFrom,
      createdTo,
      statement: statement !== 'none' ? statement : undefined,
      status: status === BillingTransactionStatus.Any ? undefined : status,
    });
  };

  handleRequestNextPage = (): void => {
    const {
      createdFrom, createdTo, pageNumber, onRequestPage, statement, status,
    } = this.props;

    onRequestPage({
      createdFrom,
      createdTo,
      pageNumber: toNumber(pageNumber) + 1,
      statement: statement !== 'none' ? statement : undefined,
      status: status === BillingTransactionStatus.Any ? undefined : status,
    });
  };

  handleRequestPreviousPage = (): void => {
    const {
      createdFrom, createdTo, pageNumber, onRequestPage, statement, status,
    } = this.props;

    onRequestPage({
      createdFrom,
      createdTo,
      pageNumber: toNumber(pageNumber) - 1,
      statement: statement !== 'none' ? statement : undefined,
      status: status === BillingTransactionStatus.Any ? undefined : status,
    });
  };

  render(): React.ReactElement {
    const {
      accounts,
      account,
      createdFrom,
      createdTo,
      defaultEmail,
      firstPage,
      lastPage,
      onRequestAccountChange,
      organization,
      pageError,
      statement,
      statements,
      status,
      transactions,
    } = this.props;

    const {
      isExportOrdersDialogOpen,
      isExportTransactionsDialogOpen,
    } = this.state;

    const hasAccounts = !isEmpty(accounts);
    const hasMultipleAccounts = hasAccounts && accounts.length > 1;
    const hasTransactions = !isEmpty(transactions);

    return (
      <PageContent>
        {!hasAccounts && (
          <Card>
            <CardContent>
              <CardEmptyState content="No accounts available. Please contact Flow to set up your accounts." />
            </CardContent>
            <CardFooter />
          </Card>
        )}
        {hasMultipleAccounts && (
          <Card>
            <CardHeader>
              <Box alignItems="center" justifyContent="between">
                <CardTitle content="Select Account" />
                <AccountSelection
                  accounts={accounts}
                  onRequestChange={onRequestAccountChange}
                  organization={organization}
                  value={account.currency}
                />
              </Box>
            </CardHeader>
          </Card>
        )}
        {hasAccounts && (
          <Card>
            <CardHeader dividing>
              <CardTitle content="Account Summary" />
            </CardHeader>
            <CardContent>
              <AccountSummary account={account} />
            </CardContent>
            <CardFooter />
          </Card>
        )}
        {hasAccounts && (
          <Card>
            <CardHeader dividing>
              <Box direction="column" spacing="tight">
                <ButtonGroup>
                  <Link to={`/${organization.id}/organization/accounts/${toLower(account.key)}/statements`}>
                    <OutlineButton
                      css={leftBtnStyles}
                      content="Statements"
                      leftIcon={Archive}
                    />
                  </Link>
                  <Link to={`/${organization.id}/organization/accounts/${toLower(account.currency)}/transactions`}>
                    <Button
                      css={rightBtnStyles}
                      content="Transactions"
                      leftIcon={Transaction}
                      intent="primary"
                    />
                  </Link>
                </ButtonGroup>
                <Box alignItems="center" spacing="tight">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label className={styles.label} htmlFor="statement">Statements:</label>
                  <Select
                    textInputProps={{ id: 'statement' }}
                    size="medium"
                    value={statement}
                    onValueChange={(val) => { this.handleStatementChange({ value: val }); }}
                    options={[
                      { value: 'none', content: 'None (Show all transactions)' },
                      ...(statements.map((s) => {
                        const fromDate = moment(s.period.from).tz(account.timezone.name).format('MMM. DD, YYYY');
                        const toDate = moment(s.period.to).tz(account.timezone.name).format('MMM. DD, YYYY');
                        return {
                          value: s.id,
                          content: `Statement ${s.id} (${fromDate} - ${toDate})`,
                        };
                      })),
                    ]}
                  />
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label className={styles.label} htmlFor="status">Status:</label>
                  <Select
                    textInputProps={{ id: 'status' }}
                    size="medium"
                    value={status}
                    onValueChange={(value) => {
                      this.handleStatusChange({ value: value as ExportStatus });
                    }}
                    options={Object.entries(BillingTransactionStatus).map(([key, value]) => ({
                      value,
                      content: key,
                    }))}
                  />
                </Box>
                <Box alignItems="center" spacing="tight">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label className={styles.label} htmlFor="createdFrom">From:</label>
                  <DateInputField
                    id="createdFrom"
                    maximumDate={createdTo && moment(createdTo).toDate()}
                    name="from"
                    onValueChange={this.handleCreatedFromDateChange}
                    value={createdFrom && moment(createdFrom).toDate()}
                    inline
                  />
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label className={styles.label} htmlFor="createdTo">To:</label>
                  <DateInputField
                    minimumDate={createdFrom && moment(createdFrom).toDate()}
                    id="createdTo"
                    name="to"
                    onValueChange={this.handleCreatedToDateChange}
                    value={createdTo && moment(createdTo).toDate()}
                    inline
                  />
                </Box>
              </Box>
            </CardHeader>
            {!hasTransactions ? (
              <CardContent fitted>
                <CardEmptyState content="No transactions available." />
              </CardContent>
            ) : (
              <CardContent fitted className={styles.transactionsTableContainer}>
                {pageError && map(pageError.messages, (message: string) => (
                  <Alert fullWidth type="failure">
                    {message}
                  </Alert>
                ))}
                <Table striped displayDensity="compact">
                  <TableHead>
                    <TableRow>
                      <TableHeadColumn>Date</TableHeadColumn>
                      <TableHeadColumn>Type</TableHeadColumn>
                      <TableHeadColumn>Description</TableHeadColumn>
                      <TableHeadColumn>Statement #</TableHeadColumn>
                      <TableHeadColumn>Posted</TableHeadColumn>
                      <TableHeadColumn textAlign="right">Amount</TableHeadColumn>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {map(transactions, ({
                      created_at: createdAt,
                      id,
                      type,
                      description,
                      posted_at: postedAt,
                      status: transactionStatus,
                      statement: transactionStatement,
                      value,
                    }) => (
                      <TableRow key={id}>
                        <TableRowColumn>
                          <FormattedDate utc timezone={account.timezone.name} format="MMM. DD, YYYY" value={createdAt} />
                        </TableRowColumn>
                        <TableRowColumn>
                          {mapTypeToWords(type)}
                        </TableRowColumn>
                        {(mapTypeToWords(type) !== description) ? (
                          <TableRowColumn>
                            {description}
                          </TableRowColumn>
                        ) : (
                          <TableRowColumn />
                        )}
                        <TableRowColumn>
                          {transactionStatement ? transactionStatement.id : (
                            <span className={styles.status}>pending</span>
                          )}
                        </TableRowColumn>
                        <TableRowColumn>
                          {transactionStatus !== 'posted' ? (
                            <span className={styles.status}>
                              {transactionStatus}
                            </span>
                          ) : (
                            <FormattedDate utc timezone={account.timezone.name} format="MMM. DD, YYYY" value={postedAt} />
                          )}
                        </TableRowColumn>
                        <TableRowColumn textAlign="right">
                          {value && value.label}
                        </TableRowColumn>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardContent>
            )}
            {!hasTransactions ? (
              <CardFooter />
            ) : (
              <CardFooter>
                <Box alignItems="center" justifyContent="between">
                  <Pagination
                    isNextPageDisabled={lastPage}
                    isPreviousPageDisabled={firstPage}
                    onRequestNextPage={this.handleRequestNextPage}
                    onRequestPreviousPage={this.handleRequestPreviousPage}
                  />
                  <Box>
                    <Button
                      content="Export Transactions"
                      onClick={this.handleExportTransactionsButtonClick}
                    />
                    <Button
                      content="Export Orders"
                      onClick={this.handleExportOrdersButtonClick}
                    />
                  </Box>
                </Box>
              </CardFooter>
            )}
          </Card>
        )}
        <ExportDialog
          defaultEmail={!defaultEmail ? '' : defaultEmail}
          isOpen={isExportTransactionsDialogOpen}
          onRequestClose={this.handleExportTransactionsCloseRequest}
          onRequestSubmit={this.handleExportTransactionsSubmitRequest}
          enableBackdropDismiss
          showBackdrop
          title="Export Transactions"
        />
        <ExportDialog
          defaultEmail={!defaultEmail ? '' : defaultEmail}
          isOpen={isExportOrdersDialogOpen}
          onRequestClose={this.handleExportOrdersCloseRequest}
          onRequestSubmit={this.handleExportOrdersSubmitRequest}
          enableBackdropDismiss
          showBackdrop
          title="Export Orders"
        />
      </PageContent>
    );
  }
}
