import { $HttpResponse } from '@flowio/api-sdk';
import toggleEndExperimentModal from './toggle-end-experiment-modal';
import {
  RootState,
  ThunkDispatcher,
  ThunkResult,
  ThunkExtraArguments,
} from '../../../stores/types';
import { ApiError } from '../../../utilities/clients/api-error';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';
import ActionTypes from '../constants/action-types';

export default function updateExperimentStatus(
  organization: string,
  key: string,
  status: io.flow.internal.v0.enums.Status,
  variantKey?: string,
): ThunkResult<Promise<io.flow.internal.v0.unions.Experiment>> {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<io.flow.internal.v0.unions.Experiment> => {
    dispatch({
      type: ActionTypes.UPDATE_EXPERIMENT_STATUS_REQUEST,
    });
    const state = getState();
    return extra.apiInternal(state).experiments.putByKeyAndStatus({
      organization,
      key,
      status,
      activate_variant_id: variantKey,
    }).then((response) => {
      if (response.ok) {
        dispatch({
          type: ActionTypes.UPDATE_EXPERIMENT_STATUS_SUCCESS,
          payload: response.body,
        });
        if (status === 'ended' && !variantKey) dispatch(toggleEndExperimentModal());
        return response.body;
      }
      const errorResponse = response as $HttpResponse<io.flow.v0.models.GenericError>;
      const error = new ApiError(
        errorResponse as $HttpResponse<io.flow.v0.models.GenericError>,
      );
      dispatch({
        type: ActionTypes.UPDATE_EXPERIMENT_STATUS_FAILURE,
        payload: formatErrorResponseV2(errorResponse),
      });
      throw error;
    });
  };
}
