import { createSelector } from 'reselect';

import getFeatures from './getFeatures';

export const getSearchValue = createSelector(
  getFeatures,
  (features) => features.ui.searchValue || '',
);

export default getSearchValue;
