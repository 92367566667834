import ApiInternalPropTypes from '@flowio/api-internal-prop-types';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableHeadColumn,
  TableBody,
} from '@flowio/react-table';
import ExportItem from './export-item';

const ExportsList = ({
  onDeleteExport,
  onEditExport,
  exports,
}) => (
  <Table striped>
    <TableHead>
      <TableRow>
        <TableHeadColumn>
          Organization
        </TableHeadColumn>
        <TableHeadColumn>
          File Type
        </TableHeadColumn>
        <TableHeadColumn>
          Export Schedule
        </TableHeadColumn>
      </TableRow>
    </TableHead>

    <TableBody>
      {exports.map((exportItem) => (
        <ExportItem
          exportItem={exportItem}
          key={exportItem.id}
          onDelete={onDeleteExport}
          onEdit={onEditExport}
        />
      ))}
    </TableBody>
  </Table>
);

ExportsList.displayName = 'ExportsList';

ExportsList.propTypes = {
  onDeleteExport: PropTypes.func.isRequired,
  onEditExport: PropTypes.func.isRequired,
  exports: PropTypes.arrayOf(ApiInternalPropTypes.optinAttribute).isRequired,
};

export default ExportsList;
