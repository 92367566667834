import assign from 'lodash/assign';
import ActionTypes from './constants/action-types';
import { SearchState } from './types/state';
import { RootActionTypes } from '../../stores/types';

const initialState: SearchState = {
  loading: false,
  catalogItems: {
    isFirstPage: true,
    isLastPage: true,
    results: [],
    params: {
      entriesPerPage: '25',
      pageNumber: '1',
      sort: 'name',
    },
  },
};

export default function catalogReducer(
  state: SearchState = initialState,
  action: RootActionTypes,
): SearchState {
  switch (action.type) {
    case ActionTypes.FIND_CATALOG_SUGGESTIONS_REQUEST:
      return assign({}, state, {
        loading: true,
      });
    case ActionTypes.FIND_CATALOG_ITEMS_SUCCESS:
      return assign({}, state, {
        loading: false,
        catalogItems: {
          ...action.payload,
          // Parameters passed to perform catalog search. Useful when returning to previous state
          // after route transition and possibly other things.
          params: action.options,
        },
      });
    case ActionTypes.FIND_CATALOG_ITEMS_LEGACY_SUCCESS:
      return assign({}, state, {
        loading: false,
        catalogItems: {
          ...action.payload,
          // Parameters passed to perform catalog search. Useful when returning to previous state
          // after route transition and possibly other things.
          params: action.options,
        },
      });
    case ActionTypes.FIND_CATALOG_ITEMS_LEGACY_REQUEST:
      return assign({}, state, {
        loading: true,
      });

    case ActionTypes.FIND_CATALOG_ITEMS_FAILURE:
      return assign({}, state, {
        loading: false,
      });
    case ActionTypes.CLEAR_CATALOG_OPTIONS:
      return assign({}, state, initialState);
    case ActionTypes.FIND_CATALOG_SUGGESTIONS_SUCCESS:
      return assign({}, state, { catalogSuggestions: action.payload, loading: false });
    default:
      return state;
  }
}
