import React from 'react';
import PropTypes from 'prop-types';
import ApiPropTypes from '@flowio/api-prop-types';
import { Card, CardContent, CardEmptyState } from '@flowio/react-card';
import isEmpty from 'lodash/isEmpty';
import { PageContent } from '@flowio/react-page';
import { css } from '@emotion/css';
import FailureSummariesList from '../failure-summaries-list';

const worksheetStyle = css`
  min-height: 536px;
  padding-top: 8px;
`;

const noReqStyle = css`
  min-height: 496px;
  margin: 20px;
`;

const FailureSummariesHome = ({ summaries, onRequeueFailures, onDeleteFailure }) => (
  <PageContent>
    <Card className={worksheetStyle}>
      <CardContent fitted>
        {isEmpty(summaries.filter((s) => s.summaries.length > 0)) ? (
          <CardEmptyState content="No Failure Summaries" className={noReqStyle} />
        ) : (
          <FailureSummariesList
            summaries={summaries}
            onRequeueFailures={onRequeueFailures}
            onDeleteFailure={onDeleteFailure}
          />
        )}
      </CardContent>
    </Card>
  </PageContent>
);

FailureSummariesHome.displayName = 'FailureSummariesHome';

FailureSummariesHome.propTypes = {
  summaries: PropTypes.arrayOf(ApiPropTypes.alertFailureSummary).isRequired,
  onRequeueFailures: PropTypes.func.isRequired,
  onDeleteFailure: PropTypes.func.isRequired,
};

export default FailureSummariesHome;
