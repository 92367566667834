import { css } from '@emotion/css';

export const card = css`
  height: 100%;
  display: flex;
  flex-flow: column;
`;

export const activityList = css`
  margin: 0;
  padding: 0;
  list-style: none;
  overflow-y: auto;
  height: 100%;
`;

export const heading = css`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.21;
  letter-spacing: normal;
  color: #0051b2;
  margin: 9px 0;
`;

export const noActivity = css`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #98a3b0;
`;
