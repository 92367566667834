import get from 'lodash/get';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { getOrganizationId } from '../../organization';
import { deleteWebhook } from '../actions';
import WebhookOverview from '../components/webhook-overview/webhook-overview';

function mapStateToProps(state, ownProps) {
  return {
    organization: getOrganizationId(state),
    showCreatedNotification: get(ownProps, 'location.state.webhookState') === 'created',
    showDeletedNotification: get(ownProps, 'location.state.webhookState') === 'deleted',
    webhooks: get(state, 'webhook.webhooks.results'),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const { organization } = ownProps;

  return {
    onRequestAddWebhook() {
      browserHistory.push(`/${organization}/organization/integrations/webhooks/new`);
    },
    onRequestEditWebhook(webhook) {
      browserHistory.push(`/${organization}/organization/integrations/webhooks/${webhook.id}`);
    },
    onRequestDeleteWebhook(webhook) {
      dispatch(deleteWebhook(webhook.id));
    },
  };
}

export default (connect(mapStateToProps, mapDispatchToProps)(WebhookOverview));
