import { combineReducers } from 'redux';

import activeShippingConfiguration from './activeShippingConfiguration';
import activeShippingLane from './activeShippingLane';
import centerQuery from './centerQuery';
import dialogs from './dialogs';
import experiences from './experiences';
import experiencesByShippingConfiguration from './experiencesByShippingConfiguration';
import ratecards from './ratecards';
import shippingConfigurations from './shippingConfigurations';
import surcharges from './surcharges';
import pagination from './pagination';
import { LogisticsEntitiesState, LogisticsRootState } from '../types/state';

export default combineReducers<LogisticsRootState>({
  activeShippingConfiguration,
  activeShippingLane,
  dialogs,
  experiencesByShippingConfiguration,
  entities: combineReducers <LogisticsEntitiesState>({
    experiences,
    pagination,
    ratecards,
    shippingConfigurations,
    surcharges,
  }),
  queries: combineReducers({
    center: centerQuery,
  }),
});
