import { ExperiencesGetPaymentAndMethodAndRulesByExperienceKeyResponse } from '@flowio/api-sdk';
import ActionTypes from '../constants/action-types';
import {
  ThunkDispatcher, RootState, ThunkExtraArguments, ThunkResult,
} from '../../../stores/types';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import { PropType } from '../../../types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';
import toLegacyResponse from '../../../utilities/clients/to-legacy-response';

type ResponseType = PropType<ExperiencesGetPaymentAndMethodAndRulesByExperienceKeyResponse, 'body'>;

export default function fetchPaymentMethodsDisplayOrder(
  organization: string,
  experienceKey: string,
): ThunkResult<Promise<LegacyResponse<ResponseType>>> {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<LegacyResponse<ResponseType>> => {
    dispatch({
      type: ActionTypes.FETCH_PAYMENT_METHODS_DISPLAY_ORDER_REQUEST,
    });
    return extra.api(getState()).experiences.getPaymentAndMethodAndRulesByExperienceKey({
      organization,
      experience_key: experienceKey,
    }).then((response) => {
      if (response.ok) {
        dispatch({
          type: ActionTypes.FETCH_PAYMENT_METHODS_DISPLAY_ORDER_SUCCESS,
          payload: response.body,
        });
      } else {
        dispatch({
          type: ActionTypes.FETCH_PAYMENT_METHODS_DISPLAY_ORDER_FAILURE,
          payload: formatErrorResponseV2(response),
        });
      }
      return toLegacyResponse<ResponseType>(response);
    });
  };
}
