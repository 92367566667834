import { ActionTypes } from '../constants';
import { ThunkResult } from '../../../stores/types';

export default function clearLabelResponse(): ThunkResult<Promise<void>> {
  return (dispatch): Promise<void> => {
    dispatch({
      type: ActionTypes.CLEAR_SHIPPING_LABEL,
    });
    return Promise.resolve();
  };
}
