import React from 'react';
import { Form, Field, InjectedFormProps } from 'redux-form';
import classnames from 'classnames/bind';

import { Box, BoxItem } from '@flowio/react-box';
import FormName from '../../../constants/FormName';
import { ReduxFormMultiCheckDropdown } from '../../../../experience/components/ExperieceStatusFilter/ExperienceStatusFilter';
import { MergedProps, DiscountListingFilterFormValues } from '../types';

// eslint-disable-next-line
const styles = require('./DiscountListingFilterForm.module.css');
const cx = classnames.bind(styles);

const dropdownOptions = [
  {
    value: 'expired',
    label: 'Expired',
  },
  {
    value: 'active',
    label: 'Active',
  },
  {
    value: 'scheduled',
    label: 'Scheduled',
  },
];

const DiscountListingFilterForm:
React.FC<MergedProps & InjectedFormProps<DiscountListingFilterFormValues, MergedProps>> = ({
  handleSubmit,
}) => (
  <Form onSubmit={handleSubmit}>
    <Box spacing="loose">
      <BoxItem>
        <span className={cx('statusLabel')}>Status:</span>
      </BoxItem>
      <BoxItem>
        <Field
          size="medium"
          name="discountStatusFilter"
          component={ReduxFormMultiCheckDropdown}
          formName={FormName.DISCOUNT_FILTER_FORM}
          multiple
          hintText="Filter by Status"
          statusFilters={dropdownOptions}
        />
      </BoxItem>
    </Box>
  </Form>
);

export default DiscountListingFilterForm;
