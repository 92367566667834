import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import {
  CREATE_API_KEY_SUBMIT,
  CREATE_API_KEY_SUCCESS,
  CREATE_API_KEY_FAILURE,
} from '../constants';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import toLegacyResponse from '../../../utilities/clients/to-legacy-response';

function createRequestAction(): RootActionTypes {
  return {
    type: CREATE_API_KEY_SUBMIT,
  };
}

function createSuccessAction(payload: io.flow.v0.models.OrganizationToken): RootActionTypes {
  return {
    type: CREATE_API_KEY_SUCCESS,
    payload,
  };
}

function createFailureAction(payload: FormattedErrorMessages): RootActionTypes {
  return {
    type: CREATE_API_KEY_FAILURE,
    payload,
  };
}

export default function createNewApiKey(
  organization: string,
  formData: io.flow.v0.models.OrganizationTokenForm,
): ThunkResult<Promise<LegacyResponse<
  io.flow.v0.models.OrganizationToken |
  undefined |
  io.flow.v0.models.GenericError
  >>> {
  return function createNewApiKeyEffects(
    dispatch,
    getState,
    extra,
  ): Promise<LegacyResponse<
    io.flow.v0.models.OrganizationToken |
    undefined |
    io.flow.v0.models.GenericError
    >> {
    dispatch(createRequestAction());

    return extra.api(getState()).organizationTokens.post({
      organization,
      body: formData,
    }).then((response) => {
      if (response.ok) {
        dispatch(createSuccessAction(response.body));
        return toLegacyResponse(response);
      }

      const errors = formatErrorResponseV2(response);
      dispatch(createFailureAction(errors));
      return toLegacyResponse(response);
    });
  };
}
