import React from 'react';
import noop from 'lodash/noop';
import {
  Worksheet, WorksheetHeader, WorksheetTitle, WorksheetBody,
} from '@flowio/react-worksheet';
import { Button } from '@flowio/react-button';
import { Box } from '@flowio/react-box';

import EditFeatureWorksheetForm from '../../containers/EditFeatureWorksheetForm';
import * as styles from '../features.styles';
import { EditFeatureWorksheetProps } from '../../types/components';

const EditFeatureWorksheet: React.FC<EditFeatureWorksheetProps> = ({
  open,
  onCancel,
  onSave,
  initialValues,
  isSaveDisabled,
}) => (
  <Worksheet open={open}>
    <WorksheetHeader>
      <Box alignItems="center" justifyContent="between" className={styles.dialogHeader}>
        <Button
          type="button"
          onClick={onCancel}
          content="Cancel"
        />
        <WorksheetTitle content="Edit Feature" />
        <Button
          type="button"
          intent="primary"
          disabled={isSaveDisabled}
          onClick={onSave}
          content="Save"
        />
      </Box>
    </WorksheetHeader>
    <WorksheetBody>
      <EditFeatureWorksheetForm initialValues={initialValues} />
    </WorksheetBody>
  </Worksheet>
);

EditFeatureWorksheet.displayName = 'EditFeatureWorksheet';

EditFeatureWorksheet.defaultProps = {
  initialValues: undefined,
  open: false,
  onCancel: noop,
  onSave: noop,
};

export default EditFeatureWorksheet;
