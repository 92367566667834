import { ExperiencesPutPaymentAndMethodAndRulesByExperienceKeyResponse } from '@flowio/api-sdk';
import ActionTypes from '../constants/action-types';
import {
  ThunkExtraArguments,
  RootState,
  ThunkDispatcher,
  ThunkResult,
} from '../../../stores/types';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import toLegacyResponse from '../../../utilities/clients/to-legacy-response';
import { PropType } from '../../../types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';

type ResponseType = PropType<ExperiencesPutPaymentAndMethodAndRulesByExperienceKeyResponse, 'body'>;

interface UpdatePaymentMethodsDisplayOrderParams {
  organizationId: string;
  experienceKey: string;
  paymentMethods: io.flow.v0.models.PaymentMethodRule[];
}

export default function updatePaymentMethodsDisplayOrder({
  organizationId,
  experienceKey,
  paymentMethods,
}: UpdatePaymentMethodsDisplayOrderParams): ThunkResult<Promise<LegacyResponse<ResponseType>>> {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<LegacyResponse<ResponseType>> => {
    dispatch({
      type: ActionTypes.UPDATE_PAYMENT_METHODS_DISPLAY_ORDER_REQUEST,
    });

    const form = paymentMethods.map<io.flow.v0.models.ExperiencePaymentMethodRuleForm>((pm) => ({
      payment_method_id: pm.payment_method.id,
      tags: pm.tags.map((tag) => tag.value as io.flow.v0.enums.ExperiencePaymentMethodTag),
    }));

    return extra.api(getState()).experiences.putPaymentAndMethodAndRulesByExperienceKey({
      organization: organizationId,
      experience_key: experienceKey,
      body: form,
    }).then((response) => {
      if (response.ok) {
        dispatch({
          type: ActionTypes.UPDATE_PAYMENT_METHODS_DISPLAY_ORDER_SUCCESS,
          payload: response.body,
        });
      } else {
        dispatch({
          type: ActionTypes.UPDATE_PAYMENT_METHODS_DISPLAY_ORDER_FAILURE,
          payload: formatErrorResponseV2(response),
        });
      }
      return toLegacyResponse<ResponseType>(response);
    });
  };
}
