import update from 'immutability-helper';
import ActionTypes from '../constants/ActionTypes';

const initialState = {
  recent: [],
  results: [],
  loading: false,
};

export default function globalSearchReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.FETCH_GLOBAL_RESULTS_SUCCESS:
      return update(state, { results: { $set: action.payload } });
    case ActionTypes.GET_RECENT_SEARCHES:
      return update(state, { recent: { $set: action.payload } });
    case ActionTypes.SET_RECENT_SEARCHES:
      return update(state, { recent: { $set: action.payload } });
    case ActionTypes.CLEAR_RECENT_SEARCHES:
      return update(state, { recent: { $set: [] } });
    case ActionTypes.UPDATE_LOADING_STATE:
      return update(state, { loading: { $set: action.payload } });
    default:
      return state;
  }
}
