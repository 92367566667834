import { LinearProgress } from '@flowio/react-linear-progress';
import React, { useEffect, useState } from 'react';
import { Button } from '@flowio/react-button';
import { Page, PageContent } from '@flowio/react-page';
import { useDispatch, useSelector } from 'react-redux';
import { browserHistory } from 'react-router';
import { flatMap, isEmpty, map } from 'lodash';
import { ThunkDispatcher } from '../../../../stores/types';
import InternalServerError from '../../../console/components/internal-server-error';
import NotFound from '../../../console/components/not-found';
import { fetchReplacementOrderBuilder, submitReplacementOrder } from '../../actions/replacementOrder';
import { getReplacementOrderBuilder } from '../../selectors';
import OrderDetailsHeader from '../order-details/OrderDetailsHeader';
import OrderDetailsOverview from '../order-details/OrderDetailsOverview';
import Alert from '../../../../components/alert';
import OrderNotes from '../OrderNotes';
import CustomerInfoForm from './CustomerInfoForm';
import ShippingAddressForm from './ShippingAddressForm';
import { AutoSaveState } from '../../types';
import AutoSaveIndicator from './AutoSaveIndicator';
import { DataStateStatus } from '../../../../utilities/redux/types';
import ItemsAndDeliveries from './ItemsAndDeliveries';
import { fetchOrganization, getOrganization } from '../../../organization';

interface OrderReplacementViewParams {
  params: {
    organization: string;
    number: string;
  }
}

const OrderReplacementView: React.FC<OrderReplacementViewParams> = ({ params }) => {
  const dispatch = useDispatch<ThunkDispatcher>();

  const [autoSaveState, setAutoSaveState] = useState<AutoSaveState>();
  const [lastModified, setLastModified] = useState(Date.now());
  const [saveError, setSaveError] = useState<string>();

  const {
    organization: organizationId,
    number: orderNumber,
  } = params;

  const organization = useSelector(getOrganization);

  const {
    data: orderBuilder,
    dataStateStatus,
  } = useSelector(getReplacementOrderBuilder);

  const errors = flatMap(orderBuilder?.errors, (error) => error.messages);

  const submit = () => {
    dispatch(
      submitReplacementOrder(organizationId, orderNumber),
    ).then(() => {
      browserHistory.push(`/${organizationId}/orders/${orderNumber}`);
    }).catch((error: Error) => {
      setSaveError(error.message);
    });
  };

  const handleAutoSaveStateChanged = (state: AutoSaveState) => {
    setAutoSaveState(state);
    setLastModified(Date.now());
  };

  useEffect(() => {
    dispatch(fetchOrganization(organizationId));
    dispatch(fetchReplacementOrderBuilder(organizationId, orderNumber));
  }, [organizationId, orderNumber, dispatch]);

  return (
    <Page>
      {(dataStateStatus === DataStateStatus.UNKNOWN
      || dataStateStatus === DataStateStatus.LOADING) && (
        <PageContent><LinearProgress /></PageContent>
      )}
      {dataStateStatus === DataStateStatus.ERROR && <InternalServerError showLink={false} />}
      {dataStateStatus === DataStateStatus.EMPTY && <NotFound showLink={false} />}
      {dataStateStatus === DataStateStatus.LOADED && orderBuilder?.order && (
        <>
          <OrderDetailsHeader organizationId={organizationId} order={orderBuilder.order}>
            <AutoSaveIndicator
              lastModified={lastModified}
              state={autoSaveState}
            />
            <Button
              content="Submit"
              intent="primary"
              onClick={() => submit()}
              disabled={autoSaveState === 'saving' || autoSaveState === 'dirty'}
            />
          </OrderDetailsHeader>
          {(!isEmpty(errors) || saveError) && (
            <Alert type="failure">
              {map(errors.concat(saveError || []), (message, index) => (
                <p key={index}>
                  {message}
                </p>
              ))}
            </Alert>
          )}
          <OrderDetailsOverview
            order={orderBuilder.order}
            organizationId={organizationId}
            showExperienceLink={false}
            expiresAt={orderBuilder.order.expires_at}
          />
          <OrderNotes organizationId={organizationId} orderNumber={orderNumber} />
          <CustomerInfoForm
            organizationId={organizationId}
            order={orderBuilder.order}
            onStateChange={(state) => handleAutoSaveStateChanged(state)}
            onError={(error) => setSaveError(error.message)}
          />
          <ShippingAddressForm
            organizationId={organizationId}
            order={orderBuilder.order}
            onStateChange={(state) => handleAutoSaveStateChanged(state)}
            onError={(error) => setSaveError(error.message)}
          />
          <ItemsAndDeliveries
            organization={organization}
            order={orderBuilder.order}
            onStateChange={(state) => handleAutoSaveStateChanged(state)}
          />
        </>
      )}
    </Page>
  );
};

export default OrderReplacementView;
