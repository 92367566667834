import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { submit, isSubmitting } from 'redux-form';
import { browserHistory } from 'react-router';

import { DialogName, FormName } from '../constants';
import { deleteDialog } from '../actions';
import { createToast } from '../../console/actions';
import { getDialogProperty } from '../selectors';
import ShippingConfigurationDeleteConfirmation from '../components/ShippingConfigurationDeleteConfirmation';
import { RootState, ThunkDispatcher } from '../../../stores/types';
import { DeleteShippingConfigurationSuccess } from '../types/actions';
import { ShippingConfigurationDeleteConfirmationDispatchProps, ShippingConfigurationDeleteConfirmationStateProps } from '../types/components';

const dialogName = DialogName.SHIPPING_CONFIGURATION_DELETE_CONFIRMATION;
const formName = FormName.SHIPPING_CONFIGURATION_DELETE_FORM;

function handleCancel() {
  return deleteDialog({ type: dialogName });
}

function handleOk() {
  return submit(formName);
}

function handleSubmitSuccess(result: DeleteShippingConfigurationSuccess) {
  return function handleSubmitSuccessSideEffects(dispatch: ThunkDispatcher) {
    const { organizationId } = result.meta;
    browserHistory.push(`/${organizationId}/logistics/shipping/configurations`);
    dispatch(deleteDialog({ type: dialogName }));
    dispatch(createToast({ content: 'Shipping configuration has been deleted.' }));
  };
}

const mapStateToProps = createStructuredSelector<
RootState,
ShippingConfigurationDeleteConfirmationStateProps
>({
  disabled: isSubmitting(formName),
  experiences: getDialogProperty(dialogName, 'experiences'),
  initialValues: getDialogProperty(dialogName, 'initialValues'),
  open: getDialogProperty(dialogName, 'open'),
});

const mapDispatchToProps = (dispatch: ThunkDispatcher) => bindActionCreators <
ShippingConfigurationDeleteConfirmationDispatchProps,
{}
>({
  onCancel: handleCancel,
  onOk: handleOk,
  onSubmitSuccess: handleSubmitSuccess,
}, dispatch);

export default connect<
ShippingConfigurationDeleteConfirmationStateProps,
ShippingConfigurationDeleteConfirmationDispatchProps,
{},
RootState
>(mapStateToProps, mapDispatchToProps)(
  ShippingConfigurationDeleteConfirmation,
);
