import { FacebookLoginStatusState } from './store';

export type ErrorResponse = {
  error: string;
};

export const isError = <T>(input: T | ErrorResponse): input is ErrorResponse => (
  (input as ErrorResponse).error !== undefined
);

export type ResourceApiResponse = {
  data: any;
};

export type LoginStatusInfo = Pick<FacebookLoginStatusState, 'permissions' | 'status'>;
