import { bindActionCreators, Dispatch, AnyAction } from 'redux';
import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';

import { newOrgNameChange, newOrgKeyChange, createOrganization } from '../../../actions';
import OrganizationCreate from '../components/organization-create';
import { RootState } from '../../../../../stores/types';
import { StateProps, DispatchProps } from '../types';
import { ErrorObj } from '../../../../../utilities/types';

const mapStateToProps: MapStateToProps<StateProps, {}, RootState> = (state: RootState) => {
  const uiDefault: { errors: ErrorObj[] } = {
    errors: [],
  };
  const {
    isLoggedIn,
    user,
  } = state.defaultReducer;

  return {
    ...uiDefault,
    isLoggedIn,
    user,
    ...state.account,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = (
  dispatch: Dispatch<AnyAction>,
) => bindActionCreators({
  onRequestChangeOrganizationName: newOrgNameChange,
  onRequestChangeOrganizationKey: newOrgKeyChange,
  onRequestCreateOrganization: createOrganization,
}, dispatch);

export default connect<StateProps, DispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(OrganizationCreate);
