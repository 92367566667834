import update from 'immutability-helper';
import { RootActionTypes } from '../../../stores/types';
import ActionType from '../constants/ActionType';
import { ExclusionRulesFormState } from '../types';

const initialState = {
  submissionError: undefined,
};

export default function (state: ExclusionRulesFormState = initialState, action: RootActionTypes) {
  switch (action.type) {
    case ActionType.SubmitExclusionRuleFormError:
      return update(state, {
        submissionError: {
          $set: action.payload,
        },
      });
    case ActionType.ShowExclusionRuleWorksheetRequest:
    case ActionType.SubmitExclusionRuleForm:
      return initialState;
    default:
      return state;
  }
}
