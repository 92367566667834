import LabsIndex from '../../../modules/labs/components/LabsIndex';
import PayLaterLab from '../../../modules/labs/components/PayLaterLab';
import BlazeLab from '../../../modules/labs/components/BlazeLab/containers/BlazeLab';
import { PlainRouteWithTitle } from '../../types';

const route: PlainRouteWithTitle = {
  path: ':organization/labs',
  indexRoute: { component: LabsIndex },
  title: 'Control Labs',
  childRoutes: [
    {
      path: 'blaze',
      indexRoute: { component: BlazeLab },
      title: 'Modern Checkout',
    },
    {
      path: 'pay_later',
      indexRoute: { component: PayLaterLab },
      title: 'Buy Now, Pay Later: A Win-Win for Retailers',
    },
  ],
};

export default route;
