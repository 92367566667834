import { css } from '@emotion/css';

export const priceCheckForm = css`
  & .searchbar {
    margin-top: 1rem;
    padding-left: 0;
    padding-right: 0;
  }
`;

/* Product Search Results */

export const rowHeader = css`
  color: #17181a;
  flex: 1 0;
  font-size: 0.75rem;
  padding: 1rem 0;
  text-transform: uppercase;
`;

export const productsRow = css`
  display: flex;
  border-bottom: 2px solid #e4e7eb;
`;

export const productsRowCell = css`
  flex: 1 0;
  padding: 1rem 0;
`;

export const productsCellHeader = css`
  color: #17181a;
  font-size: 0.75rem;
  text-transform: uppercase;
`;

/* Product Price Breakdown */
export const priceDeatils = css`
  margin-top: 1px;
`;

export const detailsRow = css`
  display: flex;
  padding: 0.5rem 0;
`;

const commonHeaderStyles = css`
color: #17181a;
font-size: 0.75rem;
padding: 0.625rem 0;
text-align: right;
text-transform: uppercase;
`;

export const detailsHeaderColDesc = css`
  ${commonHeaderStyles}
  flex: auto;
  text-transform: capitalize;
`;

export const detailsHeaderColBase = css`
${commonHeaderStyles}
width: 20%;
`;

export const detailsHeaderColLocal = css`
${commonHeaderStyles}
width: 20%;
`;

export const detailsColDesc = css`
  color: #17181a;
  flex: auto;
  text-transform: capitalize;
`;

export const detailsColBase = css`
  text-align: right;
  width: 20%;
`;

export const detailsColLocal = css`
  text-align: right;
  width: 20%;
`;

export const detailsRowTotal = css`
  border-top: 1px solid #e4e7eb;
`;

export const detailsColDescTotal = css`
  color: #17181a;
  font-weight: 500;
`;

export const detailsColBaseTotal = css`
  color: #17181a;
  font-weight: 500;
`;

export const detailsColLocalTotal = css`
  color: #17181a;
  font-weight: 500;
`;

export const components = css`
  font-size: 0.75rem;
  padding: 0.5rem;
  width: 240px;
`;

export const componentsRow = css`
  display: flex;
  padding: 0.25rem 0;
`;

export const componentsKey = css`
  flex: auto;
  text-transform: capitalize;
`;

export const valueHasComponents = css`
  border-bottom: 1px dashed #4d5866;
  cursor: pointer;
  display: inline-block;
`;
