import moment from 'moment';
import isExperimentWinner from '../../../../../utilities/experiments/checkProbability';

export const isExperienceVariant = (
  variant: io.flow.internal.v0.models.ExperienceVariant | io.flow.internal.v0.models.FeatureVariant,
): variant is io.flow.internal.v0.models.ExperienceVariant => variant.discriminator === 'experience_variant';

export const isFeatureVariant = (
  variant: io.flow.internal.v0.models.FeatureVariant | io.flow.internal.v0.models.ExperienceVariant,
): variant is io.flow.internal.v0.models.FeatureVariant => variant.discriminator === 'feature_variant';

export const getLengthOfExperiment = (
  experiment: io.flow.internal.v0.unions.Experiment,
): number => {
  const startTime = moment(experiment.started_at);
  const todaysDate = moment();
  return todaysDate.diff(startTime, 'days');
};

export const checkBaselineVariant = (
  variants: io.flow.internal.v0.unions.Variant[],
  key: string,
): boolean => {
  if (isExperienceVariant(variants[0])) {
    return key === variants[0].experience.key;
  }
  return false;
};

export const checkWinningVariant = (
  winningVariant: io.flow.internal.v0.unions.Variant, key: string,
): boolean => {
  if (isExperienceVariant(winningVariant)) {
    return key === winningVariant.experience.key;
  }
  return false;
};

export const getVariantKey = (
  variant: io.flow.internal.v0.unions.Variant,
): string | undefined => {
  if (isExperienceVariant(variant)) {
    return variant.experience.key;
  }
  return undefined;
};

export const isExperimentConclusive = (
  variantData: io.flow.internal.v0.unions.Variant[],
  experiment: io.flow.internal.v0.unions.Experiment,
): boolean => {
  const experimentLength = getLengthOfExperiment(experiment);
  if (experimentLength >= 14) {
    return variantData.some(isExperimentWinner);
  }
  return false;
};

export const getVariantName = (
  variant?: io.flow.internal.v0.unions.Variant,
): string | undefined => {
  if (variant) {
    return isExperienceVariant(variant) ? variant.experience.name : variant.value.name;
  }
  return '';
};

export const getWinningVariant = (
  variantData: io.flow.internal.v0.unions.Variant[],
  experiment: io.flow.internal.v0.unions.Experiment,
): io.flow.internal.v0.unions.Variant | undefined => {
  const winningVariant = variantData.find(isExperimentWinner);
  if (isExperimentConclusive(variantData, experiment) && winningVariant) {
    return winningVariant;
  }
  return undefined;
};
