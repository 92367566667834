import React from 'react';
import { Confirm } from '@flowio/react-confirm';

import RuleDeleteConfirmForm from '../../containers/RuleDeleteConfirmForm';
import { RuleDeleteConfirmProps } from '../../types/components';

const RuleDeleteConfirm: React.FC<RuleDeleteConfirmProps> = ({
  initialValues,
  open = false,
  ...unhandledProps
}) => (
  <Confirm
    {...unhandledProps}
    caption="Delete Rule"
    intent="negative"
    open={open}
  >
    <RuleDeleteConfirmForm
      initialValues={initialValues}
    />
  </Confirm>
);

RuleDeleteConfirm.displayName = 'RuleDeleteConfirm';

RuleDeleteConfirm.defaultProps = {
  initialValues: undefined,
  open: false,
};

export default RuleDeleteConfirm;
