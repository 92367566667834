import { createStructuredSelector } from 'reselect';
import { getPageNumber, getPageSize } from '@flowio/redux-filtering-paging-sorting';

import { getShippingConfigurationPaginationState } from '../selectors';
import { getOrganizationId } from '../../organization/selectors';
import fetchShippingConfigurations from './fetchShippingConfigurations';
import { RootActionTypes, RootState, ThunkResult } from '../../../stores/types';
import { FetchShippingConfigurationsOptions } from '../types/actions';

const getPageState = createStructuredSelector<RootState, FetchShippingConfigurationsOptions>({
  organizationId: getOrganizationId,
  entriesPerPage: getPageSize(getShippingConfigurationPaginationState),
  pageNumber: getPageNumber(getShippingConfigurationPaginationState),
});

export default function refreshShippingConfigurations(): ThunkResult<Promise<RootActionTypes>> {
  return function refreshShippingConfigurationsSideEffects(dispatch, getState) {
    return dispatch(fetchShippingConfigurations(getPageState(getState())));
  };
}
