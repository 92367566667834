import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';

import { ExportsPostResponse } from '@flowio/api-sdk';
import ActionTypes from '../constants/action-types';
import { RootActionTypes, ThunkResult } from '../../../stores/types';
import { ExportAccountForm, ExportResponseType } from '../types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import toLegacyResponse from '../../../utilities/clients/to-legacy-response';

enum AccountOrdersExportType {
  'account_orders_export_type' = 'account_orders_export_type',
}

export function exportAccountOrdersRequest(): RootActionTypes {
  return {
    type: ActionTypes.EXPORT_ACCOUNT_ORDERS_REQUEST,
  };
}

export function exportAccountOrdersSuccess(
  accountExport: io.flow.v0.models.Export,
): RootActionTypes {
  return {
    type: ActionTypes.EXPORT_ACCOUNT_ORDERS_SUCCESS,
    payload: accountExport,
  };
}

export function exportAccountOrdersFailure(
  error?: FormattedErrorMessages,
): RootActionTypes {
  return {
    type: ActionTypes.EXPORT_ACCOUNT_ORDERS_FAILURE,
    error: true,
    payload: error,
  };
}

export default function exportAccountOrders(form: ExportAccountForm):
ThunkResult<Promise<LegacyResponse<ExportResponseType>>> {
  return function exportAccountOrdersEffects(
    dispatch,
    getState,
    extra,
  ): Promise<LegacyResponse<ExportResponseType>> {
    dispatch(exportAccountOrdersRequest());

    const mergedOptions = omitBy({
      statement_id: form.statement,
      transaction_created_from: form.createdFrom,
      transaction_created_to: form.createdTo,
      transaction_status: form.status,
    }, isNil);

    return extra.api(getState()).exports.post({
      organization: form.organization,
      body: {
        emails: form.emails,
        type: {
          discriminator: AccountOrdersExportType.account_orders_export_type,
          ...mergedOptions,
        },
      },
    }).then((response: ExportsPostResponse) => {
      if (response.ok) {
        dispatch(exportAccountOrdersSuccess(response.body));
      } else {
        dispatch(exportAccountOrdersFailure(formatErrorResponseV2(response)));
      }

      return toLegacyResponse<ExportResponseType>(response);
    });
  };
}
