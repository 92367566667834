import { css } from '@emotion/css';
import colors from '../../../../theme/tokens/colors';

export const headerColumn = css`
  &.first {
    padding-left: 16px;
  }
`;

export const tracking = css`
  padding-left: 1rem !important;
`;

export const status = css`
  &.label_created {
    font-weight: 500;
    color: ${colors.aluminium};
  }

  &.in_transit {
    font-weight: 500;
    color: ${colors['flow-blue'][300]};
  }
`;

export const delivered = css`
  font-weight: 500;
  color: ${colors.chart.green};
`;

export const attemptFailed = css`
  font-weight: 500;
  color: ${colors.chart.green};
`;

export const exception = attemptFailed;
