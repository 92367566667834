import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  getPageResults,
  getPageNumber,
  getPageSize,
  getIsFirstPage,
  getIsLastPage,
  getIsSinglePage,
} from '@flowio/redux-filtering-paging-sorting';
import { withFetch } from '@flowio/redux-fetch';

import {
  activateOptinMessage,
  addOptinMessageSuccess,
  archiveOptinMessage,
  changeOptinMessagesPage,
  fetchOptinAttributes,
  hideDeleteOptinMessageConfirm,
  refreshOptinAttributes,
  showDeleteOptinMessageConfirm,
  showDialog,
  visitOptinMessageDetailsPage,
} from '../../../actions';

import { createToast } from '../../../../console/actions';
import { fetchOrganization } from '../../../../organization/actions';
import { getOptinAttributesPaginationState } from '../../../selectors';
import { getOrganizationId } from '../../../../organization/selectors';
import DialogName from '../../../constants/DialogName';
import OptinMessageHome from '../components/OptinMessageHome';
import { DispatchProps, OwnProps, StateProps } from '../types';
import { RootState, ThunkDispatcher } from '../../../../../stores/types';

const fetchAsyncState = (
  dispatch: ThunkDispatcher,
  _getState: () => RootState,
  props: OwnProps,
) => {
  const { params, location } = props;
  const { organization } = params;
  const { pageNumber, entriesPerPage } = location.query;
  return Promise.all([
    dispatch(fetchOrganization(organization)),
    dispatch(fetchOptinAttributes({
      organizationId: organization,
      pageNumber,
      entriesPerPage,
    })),
  ]);
};

const handleRemoveOptinMessageSuccess = () => (dispatch: ThunkDispatcher) => {
  dispatch(refreshOptinAttributes());
  dispatch(hideDeleteOptinMessageConfirm());
  dispatch(createToast({
    intent: 'neutral',
    content: 'Opt-in message has been deleted.',
    icon: 'Trash',
  }));
};

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  attributes: getPageResults(getOptinAttributesPaginationState),
  entriesPerPage: getPageSize(getOptinAttributesPaginationState),
  organizationId: getOrganizationId,
  pageNumber: getPageNumber(getOptinAttributesPaginationState),
  isFirstPage: getIsFirstPage(getOptinAttributesPaginationState),
  isLastPage: getIsLastPage(getOptinAttributesPaginationState),
  isSinglePage: getIsSinglePage(getOptinAttributesPaginationState),
});

const mapDispatchToProps = (dispatch: ThunkDispatcher): DispatchProps => bindActionCreators({
  onAddOptinMessage: showDialog(DialogName.CREATE_OPTIN_MESSAGE_WORKSHEET),
  onAddOptinMessageSuccess: addOptinMessageSuccess,
  onPageChange: changeOptinMessagesPage,
  onActivateOptinMessage: activateOptinMessage,
  onArchiveOptinMessage: archiveOptinMessage,
  onRemoveOptinMessage: showDeleteOptinMessageConfirm,
  onRemoveOptinMessageSuccess: handleRemoveOptinMessageSuccess,
  onViewOptinMessage: visitOptinMessageDetailsPage,
}, dispatch);

export default compose(
  withFetch(fetchAsyncState),
  connect(mapStateToProps, mapDispatchToProps),
)(OptinMessageHome);
