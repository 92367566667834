import { Box, BoxItem } from '@flowio/react-box';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { TextField } from '@flowio/react-text-field';
import React, { useState } from 'react';
import Button from '../../MarketingButton';
import validator from '../../../../../utilities/validator';
import MarketingDialog from '../../MarketingDialog';
import { MergedProps } from '../types';
import colors from '../../../../../theme/tokens/colors';

const Label = styled.div({
  width: '64px',
  fontWeight: 500,
  color: colors.marketing['dark-slate-blue'],
});

const DownloadFeedsDialog: React.FC<MergedProps> = ({
  showDialog,
  organizationId,
  onHandleDownloadAllFeeds,
  feedIds = [],
  onCancelFeedsClick,
}) => {
  const [emailError, setEmailError] = useState<string>();
  const [emailValue, setEmailValue] = useState<string>('');

  const onConfirmClick = (): void => {
    const isValidEmail = validator.validate(emailValue, { pattern: 'email' });
    if (isValidEmail) {
      onHandleDownloadAllFeeds(organizationId, emailValue, feedIds);
    } else {
      setEmailError('Valid email must be entered');
    }
  };

  const form: JSX.Element = (
    <Box spacing="loose" alignItems="center" css={css({ width: '100%' })}>
      <Label>Email</Label>
      <BoxItem flexAuto>
        <TextField
          fluid
          hintText="john.doe@flow.io"
          labelText="Email"
          feedback={emailError || ''}
          onChange={
            (e: React.ChangeEvent<HTMLInputElement>): void => setEmailValue(e.target.value)
          }
        />
      </BoxItem>
    </Box>
  );

  return (
    <MarketingDialog
      isOpen={showDialog}
      title="Download All Feeds"
      subtitle="An email will be sent to the email address provided once the export file is generated."
      form={form}
    >
      <Button onClick={onCancelFeedsClick} content="Cancel" />
      <Button onClick={onConfirmClick} intent="primary" content="Download" />
    </MarketingDialog>
  );
};

export default DownloadFeedsDialog;
