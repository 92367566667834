import { Field } from 'redux-form';
import { Box, BoxItem } from '@flowio/react-box';
import { Button } from '@flowio/react-button';
import {
  Card, CardHeader, CardContent, CardTitle,
} from '@flowio/react-card';
import React from 'react';
import { Text } from '@flowio/react-text';
import size from 'lodash/size';

import TierCarrierServiceLevelGroup from '../TierCarrierServiceLevelGroup';
import length from '../../../../utilities/validators/length';
import mapRatecardsToServices from '../../utilities/mapRatecardsToServices';
import pluralize from '../../../../utilities/strings/pluralize';
import presence from '../../../../utilities/validators/presence';
import { TierCarrierServiceLevelCardProps } from '../../types/components';

const handleServicesValidation = [
  presence({
    allowEmpty: false,
    message: 'Select one or more carrier service levels',
  }),
  length({
    minimum: 1,
    message: 'Select one or more carrier service levels',
  }),
];

const TierCarrierServiceLevelCard: React.FC<TierCarrierServiceLevelCardProps> = ({
  onSelectAll,
  onSelectNone,
  ratecards,
  selectedServices = [],
}) => {
  const availableServices = mapRatecardsToServices(ratecards);
  const availableServiceCount = size(availableServices);
  const selectedServiceCount = size(selectedServices);

  return (
    <Card>
      <CardHeader dividing>
        <CardTitle content="Select Carrier Service Levels" />
      </CardHeader>
      <CardContent>
        <Box alignItems="center">
          <BoxItem flexAuto>
            <Text>
              {`Select from available carrier services (${selectedServiceCount} of ${availableServiceCount} ${pluralize(availableServiceCount, 'service')} selected)`}
            </Text>
          </BoxItem>
          <BoxItem>
            <Box alignItems="center" spacing="tight">
              <Text intent="textSecondary">
                Select:
              </Text>
              <Button
                type="button"
                content="All"
                variant="flat"
                onClick={() => onSelectAll(availableServices)}
              />
              <Text>/</Text>
              <Button
                type="button"
                content="None"
                variant="flat"
                onClick={() => onSelectNone(availableServices)}
              />
            </Box>
          </BoxItem>
        </Box>
        <Field
          component={TierCarrierServiceLevelGroup}
          name="services"
          ratecards={ratecards}
          validate={handleServicesValidation}
        />
      </CardContent>
    </Card>
  );
};

TierCarrierServiceLevelCard.displayName = 'TierCarrierServiceLevelCard';

export default TierCarrierServiceLevelCard;
