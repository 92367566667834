import { Download, Plus } from '@flowio/react-icons';
import React, { PureComponent } from 'react';
import get from 'lodash/get';
import noop from 'lodash/noop';
import moment from 'moment';
import kebabCase from 'lodash/kebabCase';
import {
  Card, CardContent, CardFooter,
} from '@flowio/react-card';
import {
  Table,
  TableHead,
  TableHeadColumn,
  TableBody,
  TableRow,
  TableRowColumn,
} from '@flowio/react-table';

import { Button } from '@flowio/react-button';
import { PageHeader } from '@flowio/react-page';
import StatusLabel from '../../../../components/status-label';
import FormattedDate from '../../../../components/formatted-date';
import ConnectedImportDialog from './import-dialog';
import prettyLabel from '../../../../utilities/strings/pretty-label';
import Pagination from '../../../../components/pagination';
import { ImportsProps, ImportsState } from './types';
import * as importSectionStyles from './ImportSection.styles';
import * as styles from './imports.styles';

class Imports extends PureComponent<ImportsProps, ImportsState> {
  static displayName = 'Imports';

  static defaultProps = {
    importSuccess: false,
    isFirstPage: false,
    openDialog: false,
    onCreateImportEventSource: noop,
    onCloseImportEventSource: noop,
    onSubmit: noop,
    onToggleDialog: noop,
    organization: undefined,
    firstPage: false,
    pageNumber: 1,
    isLastPage: false,
    onRequestPage: noop,
    error: undefined,
    files: [],
    submitting: undefined,
    onFileEmptyError: noop,
    onUpdateFileStatus: noop,
    isFileEmpty: false,
    valid: false,
  };

  constructor(props: ImportsProps) {
    super(props);
    this.fileField = null;
    this.eventSource = null;
    this.emailField = null;

    /**
     * NOTE this function is temporary or incomplete. The FileReader API is not
     * widely supported. May want to replace with something that posts directly
     * to the server (would make the site hard refresh to upload)
     *
     * For now support is good enough until someone complains:
     *
     * http://caniuse.com/#feat=filereader
     */

    this.state = {
      importDialogOpen: false,
      selectedFile: null,
    };
  }

  componentWillUnmount() {
    const {
      onCloseImportEventSource = noop,
    } = this.props;

    onCloseImportEventSource();
  }

  handleRequestNextPage = () => {
    const {
      pageNumber = 1,
      onRequestPage = noop,
      isLastPage = false,
    } = this.props;

    if (isLastPage) {
      return;
    }
    onRequestPage({
      pageNumber: Number(pageNumber) + 1,
    });
  };

  handleRequestPreviousPage = () => {
    const {
      pageNumber = 1,
      onRequestPage = noop,
      isFirstPage = false,
    } = this.props;

    if (isFirstPage) {
      return;
    }
    onRequestPage({
      pageNumber: Number(pageNumber) - 1,
    });
  };

  getImportStatus = (fileResults?: io.flow.v0.models.ImportResults) => {
    if (!fileResults) {
      return (<span>N/A</span>);
    }

    const totalItems = fileResults.number_errors + fileResults.number_successful;
    const isSuccessful = fileResults.number_errors === 0;
    const isIntermediate = fileResults.number_errors < fileResults.number_successful;

    if (isSuccessful) {
      return (
        <span className={styles.successfulImport}>
          {`${fileResults.number_successful} / ${totalItems}`}
        </span>
      );
    }

    if (isIntermediate) {
      return (
        <span className={styles.intermediateImport}>
          {`${fileResults.number_successful} / ${totalItems}`}
        </span>
      );
    }

    return (
      <span className={styles.failImport}>
        {`${fileResults.number_successful} / ${totalItems}`}
      </span>
    );
  };

  getIntent = (status: string) => {
    switch (status) {
      case 'completed':
        return 'positive';
      case 'created':
        return 'neutral';
      case 'processing':
        return 'info';
      case 'failed':
        return 'negative';
      default: return 'neutral';
    }
  };

  // TODO FIgure out these refs.....
  setImportRef = (element: Element) => {
    this.fileField = element;
  };

  setEmailRef = (e: any) => {
    this.emailField = e.target.value;
  };

  withValidation = ({ defaultEmail }: { defaultEmail: string }) => ({
    email: {
      defaultValue: defaultEmail,
      validations: [{
        required: true,
        message: 'An email address is required',
      }, {
        pattern: 'email',
        message: 'Please enter a valid email address',
      }],
    },
  });

  selectFile = () => {
    this.setState({ selectedFile: this.fileField.files.item(0) });
  };

  toggleImportDialog = () => {
    const { importDialogOpen } = this.state;
    this.setState({ importDialogOpen: !importDialogOpen, selectedFile: null });
  };

  emailField: any;

  eventSource: any;

  fileField: any;

  isFileLinkValid = (awsUrl = '') => {
    const regex = new RegExp('X-Amz-Date=.*Z');
    const dateQueryParam = awsUrl.match(regex);

    if (!dateQueryParam) {
      return false;
    }

    const date = dateQueryParam[0].split('=')[1];

    return moment(date).startOf('day').isSame(moment().startOf('day'));
  };

  streamImports() {
    const {
      onCreateImportEventSource = noop,
    } = this.props;
    onCreateImportEventSource();
  }

  render() {
    const {
      error,
      isFileEmpty,
      openDialog,
      pageNumber,
      onToggleDialog,
      isFirstPage,
      isLastPage,
      files,
      submitting,
      importSuccess,
      valid,
    } = this.props;

    const {
      selectedFile,
    } = this.state;

    return (
      <div className={importSectionStyles.imports}>
        <PageHeader
          title={{
            content: 'Imports',
          }}
          primaryActions={[
            {
              intent: 'primary',
              id: 'import-btn',
              content: 'Import File',
              onClick: onToggleDialog,
              leftIcon: Plus,
            }]}
        />
        <Card>
          <CardContent>
            <Table striped>
              <TableHead>
                <TableRow>
                  <TableHeadColumn>
                    File Type
                  </TableHeadColumn>
                  <TableHeadColumn>
                    File Name
                  </TableHeadColumn>
                  <TableHeadColumn>
                    Import Date
                  </TableHeadColumn>
                  <TableHeadColumn>
                    Upload Status
                  </TableHeadColumn>
                  <TableHeadColumn>
                    Import Status
                  </TableHeadColumn>
                  <TableHeadColumn className={styles.resultFileColumn}>
                    Result File
                  </TableHeadColumn>
                </TableRow>
              </TableHead>

              <TableBody>
                {files && files.map((file, index) => (
                  <TableRow striped={index % 2 === 1} key={kebabCase(file.id)}>
                    <TableRowColumn>
                      {prettyLabel(file.type)}
                    </TableRowColumn>
                    <TableRowColumn>
                      {`${file.filename.substring(0, 50)}...`}
                    </TableRowColumn>
                    <TableRowColumn>
                      <FormattedDate utc format="MMM. DD, YYYY" value={file.started_at} />
                    </TableRowColumn>
                    <TableRowColumn>
                      <StatusLabel content={file.status} intent={this.getIntent(file.status)} />
                    </TableRowColumn>
                    <TableRowColumn>
                      {this.getImportStatus(file.results)}
                    </TableRowColumn>
                    <TableRowColumn className={styles.resultFileColumn}>
                      {this.isFileLinkValid(get(file, 'results.url')) && (
                        <a target="_blank" rel="noopener noreferrer" href={get(file, 'results.url')}>
                          <Button
                            variant="flat"
                            leftIcon={Download}
                          />
                        </a>
                      )}
                      {!this.isFileLinkValid(get(file, 'results.url')) && (
                        'N/A'
                      )}
                    </TableRowColumn>
                  </TableRow>
                ))}

              </TableBody>
            </Table>
            { openDialog ? (
              <ConnectedImportDialog
                defaultEmail="defaultEmail"
                error={error}
                isOpen={openDialog}
                onRequestClose={onToggleDialog}
                onFileChange={this.selectFile}
                selectedFile={selectedFile}
                setImportRef={this.setImportRef}
                setEmailRef={this.setEmailRef}
                submitting={submitting || false}
                submitted={importSuccess || false}
                title="Import File"
                valid={valid}
              />
            )
              : null}
          </CardContent>
          <CardFooter>
            <Pagination
              isPreviousPageDisabled={isFirstPage || isFileEmpty}
              pageNumber={pageNumber}
              // importSuccess={importSuccess}
              isNextPageDisabled={isLastPage || isFileEmpty}
              onRequestNextPage={this.handleRequestNextPage}
              onRequestPreviousPage={this.handleRequestPreviousPage}
            />
          </CardFooter>
        </Card>
      </div>
    );
  }
}

/**
 * NOTE this function is temporary or incomplete. The FileReader API is not
 * widely supported. May want to replace with something that posts directly
 * to the server (would make the site hard refresh to upload)
 *
 * For now support is good enough until someone complains:
 *
 * http://caniuse.com/#feat=filereader
 */

export default Imports;
