import defaults from 'lodash/defaults';
import assertPropTypes from './assertPropTypes';

export default function createAssertPropTypes(typeSpecs, defaultValues) {
  return function withAssertPropTypes(callback) {
    return function assertPropTypesHOF(values) {
      const props = defaults(values, defaultValues);
      assertPropTypes(typeSpecs, props, undefined, callback.name);
      return callback(props);
    };
  };
}
