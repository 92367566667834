import { css } from '@emotion/css';
import colors from '../../../../theme/tokens/colors';

export const shopifyOrderDetailRedirect = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: ${colors.neutral[100]};
  color: ${colors.steel};

  & .svg-icon {
    width: 150px;
    height: 42px;
    margin-bottom: 4rem;
    align-self: center;
  }
`;

export const container = css`
  display: flex;
  flex-flow: column;
  width: 480px;
  padding: 30px 36px;
  box-sizing: border-box;
`;
