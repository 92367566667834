import React from 'react';
import classNames from 'classnames/bind';

import RegionFlag from '../RegionFlag';
import styles from './RegionLabel.module.css';

const cx = classNames.bind(styles);

type Props = {
  region: io.flow.v0.models.Region;
  className?: string;
};

const RegionLabel: React.FC<Props> = ({
  className,
  region,
}) => (
  <div className={cx('label', className)}>
    <RegionFlag className={cx('flag')} region={region} />
    <div className={cx('text')}>
      {region.name}
    </div>
  </div>
);

RegionLabel.displayName = 'RegionLabel';

RegionLabel.defaultProps = {
  className: undefined,
};

export default RegionLabel;
