import { Link } from 'react-router';
import { Lens, Ellipsis } from '@flowio/react-icons';
import { Button, IconButton } from '@flowio/react-button';
import { Dropdown } from '@flowio/react-dropdown';
import React from 'react';
import { TableRow, TableRowColumn } from '@flowio/react-table';
import curry from 'lodash/curry';
import find from 'lodash/find';
import get from 'lodash/get';
import isString from 'lodash/isString';
import map from 'lodash/map';
import QueryFilterToken from '../../../query-builder/components/QueryFilterToken';
import * as styles from './styles';

interface ExclusionRuleItemProps {
  exclusionRule: io.flow.v0.models.ExclusionRule;
  organizationId: string;
  onEdit: (...args: any) => void;
  onRemove: (...args: any) => void;
  regions: io.flow.v0.models.Region[];
  currentIndex: number;
}

const ActionType = {
  Edit: 'edit',
  Delete: 'delete',
};

const toRegion = (
  curry((
    regions: io.flow.v0.models.Region[],
    regionId: string,
  ) => (isString(regionId) ? find(regions, { id: regionId }) : regionId))
);

class ExclusionRuleItem extends React.PureComponent<ExclusionRuleItemProps> {
  static displayName = 'ExclusionRuleItem';

  constructor(props: ExclusionRuleItemProps, context: any) {
    super(props, context);
    this.handleOptionSelection = this.handleOptionSelection.bind(this);
  }

  handleOptionSelection(_index: number, value?: string) {
    const { exclusionRule, onEdit, onRemove } = this.props;
    if (value === ActionType.Edit) {
      onEdit(exclusionRule);
    } else if (value === ActionType.Delete) {
      onRemove(exclusionRule);
    }
  }

  render() {
    const {
      exclusionRule,
      organizationId,
      regions,
      currentIndex,
    } = this.props;
    const exclusionRuleState = exclusionRule.statistics.state;

    return (
      <TableRow key={exclusionRule.id} striped={currentIndex % 2 === 1}>
        <TableRowColumn>
          <div className={styles.rules}>
            {map(exclusionRule.query.filters, (queryFilter, index) => (
              <QueryFilterToken key={index} className={styles.rule} queryFilter={queryFilter} />
            ))}
          </div>
        </TableRowColumn>
        <TableRowColumn>
          {/* eslint-disable-next-line import/namespace */}
          <span className={[styles.state, styles[exclusionRuleState]].join(' ')}>
            <Lens width={7} />
            <span className={styles.stateText}>
              {exclusionRuleState}
            </span>
          </span>
        </TableRowColumn>
        <TableRowColumn>
          {exclusionRule.statistics.number_items}
        </TableRowColumn>
        <TableRowColumn>
          <ul className={styles.regionList}>
            {map(map(exclusionRule.regions, toRegion(regions)), (region) => (
              <li key={get(region, 'id')} className={styles.regionItem}>
                {get(region, 'name')}
              </li>
            ))}
          </ul>
        </TableRowColumn>
        <TableRowColumn>
          <div className={styles.actionGroup}>
            <Link to={`/${organizationId}/catalog/items?q=${encodeURIComponent(`exclusion_rule_id:${exclusionRule.id}`)}`}>
              <Button content="View Items" type="button" />
            </Link>
            <Dropdown
              trigger={(<IconButton icon={Ellipsis} className={styles.moreAction} size="medium" />)}
              onItemSelection={this.handleOptionSelection}
              items={[
                { content: 'Edit', value: ActionType.Edit },
                { content: 'Delete', value: ActionType.Delete },
              ]}
            />
          </div>
        </TableRowColumn>
      </TableRow>
    );
  }
}

export default ExclusionRuleItem;
