import { browserHistory } from 'react-router';
import { ThunkResult } from '../../../stores/types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';

import ActionTypes from '../constants/action-types';

export default function createQueryBuilderFromFilterForm(
  organizationId: string,
  queryBuilderFilterForm: io.flow.v0.models.QueryBuilderFilterForm,
): ThunkResult<Promise<void>> {
  return function createQueryBuilderFromFilterFormEffects(dispatch, getState, extra) {
    dispatch({
      type: ActionTypes.CREATE_QUERY_BUILDER_FILTER_FORM_REQUEST,
    });
    return extra.apiInternal(getState()).features
      .postQueryAndBuilders({
        organization: organizationId,
        body: queryBuilderFilterForm,
      }).then((response) => {
        if (response.ok) {
          dispatch({
            type: ActionTypes.CREATE_QUERY_BUILDER_FILTER_FORM_SUCCESS,
            payload: response.body,
          });

          const query = response.body.q;
          const url = new URL(window.location.href);
          const { pathname, searchParams } = url;

          const oldQuery: any = {};
          searchParams.forEach((value, key) => {
            oldQuery[key] = value;
          });
          browserHistory.push({
            pathname,
            query: { ...oldQuery, q: query },
          });
          return;
        }

        const errors = formatErrorResponseV2(response);
        dispatch({
          type: ActionTypes.CREATE_QUERY_BUILDER_FILTER_FORM_FAILURE,
          payload: errors,
        });
      });
  };
}
