import ActionTypes from '../constants/action-types';
import {
  ThunkDispatcher, RootState, ThunkExtraArguments, ThunkResult,
} from '../../../stores/types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';

type FormData = { email: string } & Omit<io.flow.v0.models.AnalyticsExportType, 'discriminator'>;

interface ExportRevenueTimelineParams {
  organization: string;
  formData: FormData;
}

type ResponseType = io.flow.v0.models.Export | io.flow.v0.models.GenericError | undefined;

export default function exportRevenueTimeline(
  { organization, formData }: ExportRevenueTimelineParams,
): ThunkResult<Promise<ResponseType>> {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<ResponseType> => {
    dispatch({
      type: ActionTypes.EXPORT_TIMELINE_REQUEST,
    });

    const {
      email,
      ...restParams
    } = formData;

    return extra.api(getState()).exports.post({
      organization,
      body: {
        type: {
          ...restParams,
          discriminator: 'analytics_export_type',
        },
        emails: [email],
      },
    }).then((response) => {
      if (response.ok) {
        dispatch({
          type: ActionTypes.EXPORT_TIMELINE_SUCCESS,
          payload: response.body,
        });
        return response.body;
      }

      dispatch({
        type: ActionTypes.EXPORT_TIMELINE_FAILURE,
        payload: formatErrorResponseV2(response),
      });
      return response.body;
    });
  };
}
