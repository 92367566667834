import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { withFetch } from '@flowio/redux-fetch';
import { browserHistory, withRouter } from 'react-router';
import assign from 'lodash/assign';
import get from 'lodash/get';
import { compose } from 'redux';
import Overview from '../components/overview';
import { fetchOrganization, getOrganizationId } from '../../../../organization';
import {
  fetchStatistics,
  exportHs6,
  fetchItemSummaries,
} from '../../../actions';
import {
  getStatistics, getHs6Codes, getHs6UiState,
} from '../../../selectors';
import omitEmpty from '../../../../../utilities/omit-empty';
import standardAction from '../../../../../utilities/standard-action';
import { ActionTypes } from '../../../constants';
import { getAttributes } from '../../../../catalog';
import { getCatalogAttributes } from '../../../../catalog/selectors';
import {
  ClassificationOverviewStateProps,
  OwnProps,
  ClassificationOverviewDispatchProps,
  ClassificationRequestUpdateParams,
} from '../types';
import { RootState, ThunkDispatcher } from '../../../../../stores/types';
import { HarmonizationHs6ExportFormData } from '../../../types';
import resetHs6Pagination from '../../../actions/reset-hs6-pagination';

interface CodeOpts {
  has_codes?: boolean;
  status?: string;
  number?: string[];
}

function getCodeOpts(ownProps: OwnProps): CodeOpts {
  const status = get(ownProps, 'location.query.scope');
  const q = get(ownProps, 'location.query.q');

  const codeOpts: CodeOpts = {};
  codeOpts.status = status;

  if (q) {
    codeOpts.number = [q];
  }

  return codeOpts;
}

function getAsyncState(
  dispatch: ThunkDispatcher,
  _getState: () => RootState,
  ownProps: OwnProps,
): Promise<any> {
  const organization = get(ownProps, 'params.organization');
  const codeOpts = getCodeOpts(ownProps);

  return Promise.all([
    dispatch(fetchOrganization(organization)),
    dispatch(fetchStatistics(organization)),
    dispatch(fetchItemSummaries(organization, codeOpts)),
    dispatch(getAttributes(organization)),
  ]);
}

const mapStateToProps: MapStateToProps<
ClassificationOverviewStateProps,
OwnProps,
RootState> = (state, ownProps) => ({
  organization: getOrganizationId(state),
  statistics: getStatistics(state),
  ...assign({
    scope: get(ownProps, 'location.query.scope'),
    q: get(ownProps, 'location.query.q'),
    codes: getHs6Codes(state),
    attributes: getCatalogAttributes(state),
  }, getHs6UiState(state)),
});

const mapDispatchToProps: MapDispatchToProps<ClassificationOverviewDispatchProps, OwnProps> = (
  dispatch: ThunkDispatcher,
  ownProps: OwnProps,
) => {
  const { organization } = ownProps.params;

  return {
    onRequestUpdate(nextProps: ClassificationRequestUpdateParams): void {
      const { scope, q } = nextProps;
      const query = omitEmpty({ scope, q });
      const nextLocation = assign({}, ownProps.location, {
        query,
      });
      dispatch(resetHs6Pagination());
      browserHistory.push(nextLocation);
    },
    onRequestExport(formData: { email: string }): void {
      const { email } = formData;
      const codeOpts = getCodeOpts(ownProps);
      const exportForm: HarmonizationHs6ExportFormData = {
        discriminator: 'harmonization_hs6_export_type',
        email,
        ...codeOpts,
      };

      dispatch(exportHs6(organization, exportForm));
    },
    onRequestNextPage(): void {
      const codeOpts = getCodeOpts(ownProps);
      dispatch(standardAction(ActionTypes.NEXT_HARMONIZATION_HS6_CODES_PAGE));
      dispatch(fetchItemSummaries(organization, codeOpts));
    },
    onRequestViewItem(code: io.flow.internal.v0.models.ItemClassificationSummary): void {
      browserHistory.push(`/${organization}/catalog/items/${encodeURIComponent(code.item.number)}`);
    },
  };
};

export default compose<React.FC>(
  withRouter,
  withFetch(getAsyncState),
  connect(mapStateToProps, mapDispatchToProps),
)(Overview);
