import Client from './client';

export default class ChannelCurrencies extends Client {
  constructor(opts) {
    let options = opts;

    if (typeof opts === 'string') {
      options = { host: opts }; // convert host string to options object
    }

    options.serviceName = 'API Internal';

    super(options);
  }

  get(options = {}) {
    return this.makeRequest(`${this.host}/internal/channel/currencies`, options);
  }

  post(options = {}) {
    return this.makeRequest(`${this.host}/internal/channel/currencies`, {
      ...options,
       method: 'POST',
    });
  }

  getById(id, options = {}) {
    return this.makeRequest(`${this.host}/internal/channel/currencies/${id}`, options);
  }

  putById(id, options = {}) {
    return this.makeRequest(`${this.host}/internal/channel/currencies/${id}`, {
      ...options,
       method: 'PUT',
    });
  }

  deleteById(id, options = {}) {
    return this.makeRequest(`${this.host}/internal/channel/currencies/${id}`, {
      ...options,
       method: 'DELETE',
    });
  }

}
