import { css } from '@emotion/react';

const width = css`
  width: 25%;
`;

export const fieldColumn = width;
export const spacingBox = width;

export const alertBox = css`
  max-width: 64%;
`;

export const nameFieldColumn = css`
  width: 33%;
`;
