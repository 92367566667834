import { MapDispatchToProps, MapStateToProps, connect } from 'react-redux';
import { ThunkDispatcher, RootState } from '../../../stores/types';
import EditExperimentWorksheet from '../components/edit-experiment-worksheet/EditExperimentWorksheet';
import closeExperimentsModal from '../actions/close-experiments-modal';
import { getExperimentsModalOpen } from '../selectors';

interface OwnProps {
  organizationId: string;
}

interface StateProps {
  open: boolean;
}

interface DispatchProps {
  onClose: () => void;
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = (
  state: RootState,
) => ({
  open: getExperimentsModalOpen(state),
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch: ThunkDispatcher,
): DispatchProps => ({
  onClose(): void {
    dispatch(closeExperimentsModal());
  },
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(EditExperimentWorksheet);
