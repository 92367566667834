import { SubmissionError } from 'redux-form';
import { RootActionTypes } from '../../../stores/types';

// You should not use this if action from `@flowio/redux-async-middleware`
// is not consistently passed as parameter.
// No clue how I am supposed to type result here,
// Root action types don't work, this is a certain subset of actions
// All the failure actions are expected to have a payload of FormattedErrorMessages
export default function handleSubmitResult(
  result: RootActionTypes,
  failureActions: string[],
): RootActionTypes {
  if (failureActions.includes(result.type)) {
    if ('payload' in result && result.payload.messages) {
      throw new SubmissionError({
        _error: result.payload.messages,
      });
    } else {
      throw new Error(result.type);
    }
  }
  // Return result for downstream usage.
  return result;
}
