import { css } from '@emotion/css';
import colors from '../../../../../theme/tokens/colors';

export const imageWrapper = css`
  z-index: 2;
  align-items: center;
  justify-content: center;
  display: flex;

  & img {
    height: 40px;
  }

  & .platformWord {
    height: 30px;
  }

  & .facebook {
    color: white;
    height: 14px;
  }

  & .imageWrapperConnection {
    background-color: ${colors.gray[500]};
    height: 4px;
    width: 32px;
    margin: 24px;
    border-radius: 4px;
    z-index: 10000;
  }
`;

export const manualIndicator = css`
  position: absolute;
  display: flex;
  align-items: center;
  color: ${colors.marketing['light-slate-gray']};
  margin-top: 10px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;

  & svg {
    color: ${colors.marketing['dark-slate-blue']};
    margin-left: 8px;
  }
`;

export const textContent = css`
  text-align: left;

  & h3 {
    color: ${colors.marketing['dark-slate-blue']};
  }

  & p {
    color: ${colors.marketing['light-slate-gray']};
  }
`;
