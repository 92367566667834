import showDialog from './showDialog';
import DialogName from '../constants/DialogName';

function showDeleteOptinMessageConfirm(
  organizationId: string,
  optinAttribute: io.flow.internal.v0.models.OptinAttribute,
) {
  return showDialog(DialogName.DELETE_OPTIN_MESSAGE_CONFIRM)({
    initialValues: {
      organizationId,
      optinAttributeKey: optinAttribute.optin_attribute_key,
    },
    optinAttribute,
  });
}

export default showDeleteOptinMessageConfirm;
