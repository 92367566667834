import DialogName from '../constants/DialogName';
import updateDialog from './updateDialog';
import { RootActionTypes } from '../../../stores/types';

interface ToggleDisconnectPlatformDialogProps {
  platformId?: io.flow.internal.v0.enums.MarketingGatewayPlatform;
}

const toggleDisconnectPlatformDialog = (
  open: boolean,
  { platformId }: ToggleDisconnectPlatformDialogProps = {},
): RootActionTypes => updateDialog(
  DialogName.DISCONNECT_PLATFORM, {
    platformId,
    open,
  },
);

export default toggleDisconnectPlatformDialog;
