export default class ExponentialBackoff {
  constructor(func) {
    this.func = func;
  }

  factor = 2;

  delay = 1000;

  attempts = 0;

  maxAttempts = 10;

  backoff() {
    this.timeout = setTimeout(() => {
      this.func();
      this.delay *= this.factor;
      this.attempts += 1;
      if (this.attempts < this.maxAttempts) {
        this.backoff();
      }
    }, this.delay);
  }

  cancel() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }
}
