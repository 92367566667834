import { Form, Field } from 'redux-form';
import React from 'react';
import property from 'lodash/property';

import { css } from '@emotion/css';
import AnnotatedSection from '../../../../../components/AnnotatedSection';
import PromptTargets from '../../../constants/PromptTarget';
import presence from '../../../../../utilities/validators/presence';
import FormName from '../../../constants/FormName';
import { Props } from '../types';
import GenericError from '../../../../../components/GenericError';
import { TextField, SelectField } from '../../../../../components/ReduxFormFieldAdapters';

const errorStyles = css`
  margin-bottom: 1rem;
`;

type TargetOptionsObj = {
  content: string;
  value: io.flow.internal.v0.enums.PromptTarget;
};

const promptTargetOptions: TargetOptionsObj[] = [
  { content: 'Banner', value: PromptTargets.BROWSE },
  { content: 'Checkout', value: PromptTargets.CHECKOUT },
];

const nameValidation = [
  presence({ message: 'Enter message name', allowEmpty: false }),
];

const attributeValidation = [
  presence({ message: 'Enter message attribute', allowEmpty: false }),
];

const formatTarget = (
  val: io.flow.internal.v0.enums.PromptTarget,
): TargetOptionsObj | undefined => promptTargetOptions.find(({ value }) => value === val);

const parseTarget = property('value');

const handleTargetValidation = [
  presence({ message: 'Choose target', allowEmpty: false }),
];

const OptinMessageForm: React.FC<Props> = ({
  handleSubmit,
  error,
  form,
}) => (
  <Form onSubmit={handleSubmit}>
    <Field component="input" type="hidden" name="organizationId" />
    <Field component="input" type="hidden" name="status" />
    {error && <GenericError className={errorStyles} error={error} />}
    <AnnotatedSection.Group>
      <AnnotatedSection
        title="Name"
        description="For bookkeeping in Console only. Your customers will not see this."
      >
        <Field
          component={TextField}
          name="name"
          validate={nameValidation}
          autoComplete="off"
          fluid
          hintText="Enter Message Name"
        />
      </AnnotatedSection>
      <AnnotatedSection
        title="Attribute"
        description="A unique identifier for each message. Your customers will not see this."
      >
        <Field
          component={TextField}
          name="optinAttributeKey"
          validate={attributeValidation}
          autoComplete="off"
          disabled={form !== FormName.CREATE_OPTIN_ATTRIBUTE_FORM}
          fluid
          hintText="Message Attribute"
        />
      </AnnotatedSection>
      <AnnotatedSection
        title="Target"
        description="Select between a site-wide banner and a checkout-only message."
      >
        <Field
          component={SelectField}
          name="target"
          format={formatTarget}
          parse={parseTarget}
          validate={handleTargetValidation}
          disabled={form !== FormName.CREATE_OPTIN_ATTRIBUTE_FORM}
          fluid
          hintText="Select a Target"
          options={promptTargetOptions}
        />
      </AnnotatedSection>
    </AnnotatedSection.Group>
  </Form>
);

OptinMessageForm.displayName = 'OptinMessageForm';

export default OptinMessageForm;
