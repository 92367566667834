import { combineReducers } from 'redux';
import channels from './channels';
import connectionConfirmationDialog from './connectionConfirmationDialog';
import deleteFeedConfirmationDialog from './deleteFeedConfirmationDialog';
import dialogs from './dialogs';
import downloadFeedsDialog from './downloadFeedsDialog';
import facebookConnection from './facebookConnection';
import feedForms from './feedForms';
import feedsCreationConfirmationDialog from './feedsCreationConfirmationDialog';
import feedSources from './feedSources';
import googleConnectionAccounts from './googleConnectionAccounts';
import googleFeedsForms from './googleFeedsForms';
import productFeeds from './productFeeds';
import selectedFeedSource from './selectedFeedSource';

export default combineReducers({
  channels,
  connectionConfirmationDialog,
  deleteFeedConfirmationDialog,
  downloadFeedsDialog,
  dialogs,
  facebookConnection,
  feedForms,
  feedsCreationConfirmationDialog,
  feedSources,
  googleConnectionAccounts,
  googleFeedsForms,
  productFeeds,
  selectedFeedSource,
});
