import { css } from '@emotion/css';
import colors from '../../../../theme/tokens/colors';

export const divider = css`
  margin: 0;

  &.space {
    margin: 10px 0;
  }
`;

export const cardTitle = css`
  display: flex;
  margin: 0.5rem 0;

  .title {
    font-size: 18px;
    font-weight: 400;
    flex-grow: 1;
  }

  .variant-list {
    margin-left: 10px;
    color: ${colors.black};

    &.disabled {
      opacity: 0.5;
      color: #bfbfbf;
    }
  }
`;

export const settingContainer = css`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 0 0 10px;

  .label {
    color: ${colors.marketing['light-slate-gray']};
    width: 20%;
    line-height: 32px;
    font-weight: 500;

    &.adjust {
      max-width: 19%;
      line-height: 24px;
      transform: translateY(16px);
    }
  }

  .data {
    width: 70%;
    display: flex;
    flex-wrap: wrap;

    .experiment-name {
      width: 75%;
    }

    .experiment-description {
      width: 100%;
    }

    .experiment-session-query {
      width: 100%;
    }

    .experiment-type {
      width: 50%;
      box-sizing: border-box;

      &:not(:first-child) {
        padding-left: 10px;
      }

      .experiment-value {
        input:disabled {
          opacity: 0.5;
        }
      }
    }

    .experiment-field-description {
      font-size: 12px;
      padding-top: 8px;
      font-size: 0.75rem;
    }

    .experiment-session-query-description {
      font-size: 12px;
      padding-top: 8px;
      font-size: 0.75rem;
    }
  }
`;

export const variantContainer = css`
  background-color: ${colors.palerGray};
  display: flex;
  align-items: center;
  height: 48px;
  align-items: center;
  margin: 5px 0;

  span {
    margin: 10px;
  }

  .variant-name {
    flex-grow: 1;
    font-weight: 500;
  }

  .variant-number {
    color: ${colors.marketing['light-slate-gray']};
  }

  .variant-traffic {
    margin: 0;
    width: 40px;
  }

  .clear-button {
    margin-right: 10px;
    color: ${colors.black};
  }
`;

const errorWarnVariantCommonStyles = css`
  margin-top: 1rem;
  display: flex;
  align-items: center;

  .icon {
    height: 16px;
    width: 16px;
    margin: 0 7px;
  }
`;

export const variantWarn = css`
  ${errorWarnVariantCommonStyles}
  color: ${colors.charcoal};
`;

export const variantError = css`
  ${errorWarnVariantCommonStyles}
  color: ${colors.red[300]};
  font-weight: 500;

  .icon g path {
    &:first-child {
      fill: ${colors.red[300]};
    }
    &:last-child {
      fill: white;
    }
  }
`;

export const noVariants = css`
  height: 120px;
`;

export const experimentFormHeader = css`
  h1 {
    font-weight: 300;
    line-height: 1.5;
    color: ${colors.charcoal};
  }

  .save-btn {
    margin-right: 1rem;
  }
`;

export const globalBanner = css`
  display: flex;
  background-color: ${colors.mercury};
  border-radius: 5px;
  padding: 15px;
  align-items: flex-start;
  margin-bottom: 20px;

  svg {
    color: ${colors.steel};
    flex-grow: 2;
  }

  div {
    padding-left: 15px;
    * {
      margin: 0;
      padding: 0;
    }
  }
`;
