import { createSelector } from 'reselect';
import getMarketingState from './getMarketingState';
import { RootState } from '../../../stores/types';
import { MarketingState } from '../types';
import { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';

const getChannelsError = (
  {
    channels,
  }: MarketingState,
): FormattedErrorMessages | undefined => (channels.error || undefined);

export default createSelector<RootState, MarketingState, FormattedErrorMessages | undefined>(
  getMarketingState,
  getChannelsError,
);
