enum ActionTypes {
  'CHANGE_ACCOUNT' = 'BILLING.CHANGE_ACCOUNT',
  'EXPORT_ACCOUNT_ORDERS_REQUEST' = 'BILLING.EXPORT_ACCOUNT_ORDERS_REQUEST',
  'EXPORT_ACCOUNT_ORDERS_SUCCESS' = 'BILLING.EXPORT_ACCOUNT_ORDERS_SUCCESS',
  'EXPORT_ACCOUNT_ORDERS_FAILURE' = 'BILLING.EXPORT_ACCOUNT_ORDERS_FAILURE',
  'EXPORT_ACCOUNT_TRANSACTIONS_REQUEST' = 'BILLING.EXPORT_ACCOUNT_TRANSACTIONS_REQUEST',
  'EXPORT_ACCOUNT_TRANSACTIONS_SUCCESS' = 'BILLING.EXPORT_ACCOUNT_TRANSACTIONS_SUCCESS',
  'EXPORT_ACCOUNT_TRANSACTIONS_FAILURE' = 'BILLING.EXPORT_ACCOUNT_TRANSACTIONS_FAILURE',
  'FETCH_ORGANIZATION_ACCOUNT_REQUEST' = 'BILLING.FETCH_ORGANIZATION_ACCOUNT_REQUEST',
  'FETCH_ORGANIZATION_ACCOUNT_SUCCESS' = 'BILLING.FETCH_ORGANIZATION_ACCOUNT_SUCCESS',
  'FETCH_ORGANIZATION_ACCOUNT_FAILURE' = 'BILLING.FETCH_ORGANIZATION_ACCOUNT_FAILURE',
  'FETCH_ORGANIZATION_ACCOUNTS_REQUEST' = 'BILLING.FETCH_ORGANIZATION_ACCOUNTS_REQUEST',
  'FETCH_ORGANIZATION_ACCOUNTS_SUCCESS' = 'BILLING.FETCH_ORGANIZATION_ACCOUNTS_SUCCESS',
  'FETCH_ORGANIZATION_ACCOUNTS_FAILURE' = 'BILLING.FETCH_ORGANIZATION_ACCOUNTS_FAILURE',
  'FETCH_STATEMENTS_FAILURE' = 'BILLING.FETCH_STATEMENTS_FAILURE',
  'FETCH_STATEMENTS_REQUEST' = 'BILLING.FETCH_STATEMENTS_REQUEST',
  'FETCH_STATEMENTS_SUCCESS' = 'BILLING.FETCH_STATEMENTS_SUCCESS',
  'FETCH_TRANSACTIONS_FAILURE' = 'BILLING.FETCH_TRANSACTIONS_FAILURE',
  'FETCH_TRANSACTIONS_REQUEST' = 'BILLING.FETCH_TRANSACTIONS_REQUEST',
  'FETCH_TRANSACTIONS_SUCCESS' = 'BILLING.FETCH_TRANSACTIONS_SUCCESS',
}

export default ActionTypes;
