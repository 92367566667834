import ApiPropTypes from '@flowio/api-prop-types';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import BemHelper from '@flowio/bem-helper';
import map from 'lodash/map';
import { MenuDivider } from '@flowio/react-menu';
import { RadioButton, RadioButtonGroup } from '@flowio/react-radio-button';
import { TextInputField } from '@flowio/react-text-input';
import { Button } from '@flowio/react-button';
import { Box } from '@flowio/react-box';
import Alert from '../../../../components/alert/alert';
import CheckboxGroup from '../../../../components/checkbox-group';
import DeleteConfirmationDialog from '../delete-confirmation-dialog';
import { propTypes as withValidationPropTypes } from '../../../../components/with-validation';
import { EventTypes } from '../../constants';

if (process.browser) {
  require('./webhook-form.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('webhook-form');

export default class WebhookForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleCreateButtonClick = (event) => {
    event.preventDefault();
    this.submitForm('create');
  }

  handleUpdateButtonClick = (event) => {
    event.preventDefault();
    this.submitForm('update');
  }

  handleDeleteButtonClick = (event) => {
    event.preventDefault();
    this.setState({ showDeleteConfirmation: true });
  }

  handleDeleteConfirmationClose = () => {
    this.setState({ showDeleteConfirmation: false });
  }

  handleDeleteConfirmation = () => {
    this.submitForm('delete');
  }

  // Handles scenario where form is submitted without pressing submit button.
  handleSubmit = (event) => {
    const { editing } = this.props;
    event.preventDefault();
    this.submitForm(editing ? 'update' : 'create');
  };

  getFormData() {
    const { webhook } = this.props;
    const formData = {};

    if (webhook) {
      formData.id = webhook.id;
    }

    return formData;
  }

  submitForm(action) {
    const { onSubmitValidate, onSubmit } = this.props;
    onSubmitValidate(({ isValid }, formData) => {
      if (isValid) {
        onSubmit(action, {
          ...this.getFormData(),
          ...formData,
        });
      }
    })();
  }

  renderEventSelectorIfNecessary() {
    const { fields } = this.props;
    const { subscription, events } = fields;

    if (subscription.value !== 'custom') {
      return null;
    }
    return (
      <div>
        {(() => {
          let children = null;
          if (events.error) {
            children = (
              <Alert type="failure">
                <p>
                  {events.error}
                </p>
              </Alert>
            );
          }
          return children;
        })()}
        <CheckboxGroup
          name="events"
          value={events.value}
          className={bem.element('event-selector')}
          itemClassName={bem.element('event')}
          itemValueKey="value"
          itemLabelKey="label"
          items={EventTypes}
          onCheck={events.events.onBlur}
        />
      </div>
    );
  }

  renderCreateButtonIfNecessary() {
    const { editing, submitting } = this.props;
    if (editing) {
      return null;
    }

    return (
      <Button
        className={bem.element('create-button')}
        disabled={submitting}
        type="submit"
        name="action"
        value="create"
        content="Add Webhook"
        onClick={this.handleCreateButtonClick}
        intent="primary"
      />
    );
  }

  renderUpdateButtonIfNecessary() {
    const { editing, submitting } = this.props;
    if (!editing) {
      return null;
    }

    return (
      <Button
        className={bem.element('update-button')}
        disabled={submitting}
        type="submit"
        name="action"
        value="update"
        content="Update Webhook"
        style={{ marginRight: '1rem' }}
        onClick={this.handleUpdateButtonClick}
        intent="primary"
      />
    );
  }

  renderDeleteButtonIfNecessary() {
    const { editing, submitting } = this.props;
    if (!editing) {
      return null;
    }

    return (
      <Button
        className={bem.element('delete-button')}
        disabled={submitting}
        type="submit"
        name="action"
        value="delete"
        content="Delete Webhook"
        onClick={this.handleDeleteButtonClick}
        intent="negative"
      />
    );
  }

  render() {
    const { fields, error, webhook } = this.props;
    const { showDeleteConfirmation } = this.state;
    const { events, url, subscription } = fields;

    return (
      <form
        ref={(c) => { this.form = c; }}
        method="post"
        className={bem.block()}
        onSubmit={this.handleSubmit}
      >
        {(() => { // Render server errors.
          let children = null;
          if (error) {
            children = map(error.messages, ({ message }) => (
              <Alert type="failure">
                <p>
                  {message}
                </p>
              </Alert>
            ));
          }
          return children;
        })()}
        <p>
          We’ll send a <code>POST</code> request to the URL below with details of
          any subscribed events.
        </p>
        <TextInputField
          onChange={(e) => url.events.onChange(e)}
          onBlur={(e) => url.events.onChange(e)}
          name="url"
          value={url.value}
          feedback={url.error}
          labelText="Payload URL"
          fluid
          hintText="https://example.com/postreceive"
          required={url.isRequired}
          intent={url.error ? 'negative' : 'neutral'}
          clearable
          onClear={() => url.events.onChange('')}
        />
        <p>
          <strong>Which events would you like to trigger this webhook?</strong>
        </p>
        <RadioButtonGroup
          {...subscription.events}
          name="subscription"
          value={subscription.value}
          onChange={(event) => {
            const { value } = event.target;
            if (value === 'all') events.events.onBlur(['*']);
            else events.events.onBlur([]);
            subscription.events.onChange(value);
          }}
        >
          <Box direction="column" spacing="loose">
            <RadioButton
              className={bem.element('subscription')}
              value="all"
            >
              <span>&nbsp; Send me<strong> everything</strong>.</span>
            </RadioButton>
            <RadioButton
              className={bem.element('subscription')}
              value="custom"
              labelText="Let me select individual events."
            />
          </Box>
        </RadioButtonGroup>
        {this.renderEventSelectorIfNecessary()}
        <MenuDivider className={bem.element('divider')} />
        {this.renderCreateButtonIfNecessary()}
        {this.renderUpdateButtonIfNecessary()}
        {this.renderDeleteButtonIfNecessary()}
        <DeleteConfirmationDialog
          open={showDeleteConfirmation}
          webhookUrl={webhook && webhook.url}
          onRequestClose={this.handleDeleteConfirmationClose}
          onRequestConfirm={this.handleDeleteConfirmation}
        />
      </form>
    );
  }
}
WebhookForm.displayName = 'WebhookForm';

WebhookForm.propTypes = {
  ...withValidationPropTypes('events', 'url', 'subscription'),
  editing: PropTypes.bool,
  error: PropTypes.shape({
    messages: PropTypes.arrayOf(PropTypes.shape({
      code: PropTypes.string,
      message: PropTypes.string.isRequired,
    })),
  }),
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  webhook: ApiPropTypes.webhook,
};

WebhookForm.defaultProps = {
  editing: false,
  webhook: null,
  error: undefined,
  submitting: undefined,
};
