import { FeaturesDeleteRulesByKeyAndIdResponse } from '@flowio/api-internal-sdk';
import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import ActionTypes from '../constants/action-types';

function createRequestAction(feature: string, ruleId: string): RootActionTypes {
  return {
    type: ActionTypes.DELETE_RULE_BY_KEY_AND_ID_REQUEST,
    feature,
    ruleId,
  };
}

function createSuccessAction(feature: string, ruleId: string): RootActionTypes {
  return {
    type: ActionTypes.DELETE_RULE_BY_KEY_AND_ID_SUCCESS,
    feature,
    ruleId,
  };
}

function createFailureAction(
  feature: string,
  ruleId: string,
  payload: FormattedErrorMessages,
): RootActionTypes {
  return {
    type: ActionTypes.DELETE_RULE_BY_KEY_AND_ID_FAILURE,
    payload,
    feature,
    ruleId,
  };
}

export default function deleteRuleByKeyAndId(
  organizationId: string,
  feature: string,
  ruleId: string,
): ThunkResult<Promise<FeaturesDeleteRulesByKeyAndIdResponse>> {
  return function deleteRuleByKeyAndIdEffects(
    dispatch,
    getState,
    extra,
  ): Promise<FeaturesDeleteRulesByKeyAndIdResponse> {
    dispatch(createRequestAction(feature, ruleId));

    return extra.apiInternal(getState()).features.deleteRulesByKeyAndId({
      id: ruleId,
      key: feature,
      organization: organizationId,
    }).then((response) => {
      if (response.ok) {
        dispatch(createSuccessAction(feature, ruleId));
        return response;
      }

      const error = formatErrorResponseV2(response);
      dispatch(createFailureAction(feature, ruleId, error));
      return response;
    });
  };
}
