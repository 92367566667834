export enum DroppableTypes {
  PAYMENT_METHODS = 'payment_methods',
  CREDIT_CARDS_SHOWN = 'ccs_shown',
  CREDIT_CARDS_AVAIL = 'ccs_avail',
}

/**
 * @description The limit of CC icons that is allowed be configured.
 * This number is mirrored in Checkout where the icons are consumed and rendered.
 * @type {number}
 */
export const SHOW_NUMBER_OF_CCS = 6;

export default {
  DroppableTypes,
  SHOW_NUMBER_OF_CCS,
};
