import { createSelector } from 'reselect';

import getFeatures from './getFeatures';

export const getStatusFilterValues = createSelector(
  getFeatures,
  (features) => features.ui.statusFilters || [],
);

export default getStatusFilterValues;
