import { Box } from '@flowio/react-box';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { Link } from 'react-router';
import { OutlineButton } from '@flowio/react-button';
import Button from '../../MarketingButton';
import EmptyStates from '../../../constants/EmptyStates';
import MarketingEmptyState from '../../MarketingEmptyState/MarketingEmptyState';
import MarketingCard, { MarketingCardHeading } from '../../MarketingCard';
import AddFacebookCountryFeedDialog from '../../AddFacebookCountryFeedDialog/containers/AddFacebookCountryFeedDialog';
import { MergedProps as Props } from '../types';
import * as styles from './FacebookFeeds.styles';
import FacebookFeedsTable from '../../FacebookFeedsTable';
import MarketingPage from '../../MarketingPage';
import ExternalLink from '../../ExternalLink';
import FeedsPageTitle from '../../FeedsPageTitle';

const localizedFeedsText = (): JSX.Element => {
  const link = 'https://www.facebook.com/business/help/2144286692311411?id=725943027795860';

  return (
    <>
      Localized product information includes localized product data (link,
      availability, price) for all the other countries you sell to.
      Click
      {' '}
      <ExternalLink className={styles.helpLink} href={link}>here</ExternalLink>
      &nbsp;to learn more about setting up a localized catalog in multiple languages
      and countries on Facebook.
    </>
  );
};

const feedsSourceText = (): JSX.Element => {
  const link = (
    <>
      Product information includes all product information for your default
      language and country. Click&nbsp;
      <ExternalLink
        className={styles.helpLink}
        href="https://www.facebook.com/business/help/2144286692311411?id=725943027795860"
      >
        here
      </ExternalLink>
      &nbsp;to learn more about creating a data feed file for a catalog on Facebook.
    </>
  );
  return link;
};

const FacebookFeeds: React.FC<Props> = React.memo(({
  params: { organization },
  catalogId,
  feedSources,
  onRenewAccess,
  isFbInitialized,
  localizedFeeds,
  localizedFeedsIds,
  countries,
  accessTokenSummary,
  languages,
  onClickAddCountryFeed,
  onHandleDownloadAllFeeds,
}) => {
  const handleAddFeed = (): void => onClickAddCountryFeed(organization, catalogId);

  const handleDownloadFeedsButtonClick = (): void => onHandleDownloadAllFeeds(localizedFeedsIds);

  const breadcrumbProps = {
    items: [
      {
        content: 'Product Feeds',
        as: Link,
        to: `/${organization}/marketing`,
      },
      {
        content: 'Facebook Catalog',
        active: true,
      },
    ],
    metaData: (<b>{catalogId}</b>),
  };

  const hasCountryFeeds = !isEmpty(localizedFeeds);
  return (
    <>
      <MarketingPage
        breadcrumbItems={breadcrumbProps.items}
        heading={<FeedsPageTitle platform="facebook" />}
        cta={(
          <Button
            intent="primary"
            onClick={handleDownloadFeedsButtonClick}
            content="Download All Feeds"
          />
      )}
      >
        <MarketingCard boxShadow>
          <div>
            <MarketingCardHeading
              title="Product Information"
              showDivider={false}
              subtitle={feedsSourceText()}
            >
              {isFbInitialized && (
              <OutlineButton
                intent="neutral"
                data-automation-id="fb-renew-access"
                onClick={onRenewAccess}
              >
                Renew Access
              </OutlineButton>
              )}
            </MarketingCardHeading>

            <br />
            <FacebookFeedsTable
              countries={countries}
              accessTokenSummary={accessTokenSummary}
              languages={languages}
              feeds={feedSources as io.flow.internal.v0.models.MarketingGatewayFeedSourceFacebook[]}
            />
          </div>
        </MarketingCard>
        <br />
        <MarketingCard boxShadow>
          <MarketingCardHeading
            title="Localized Product Information"
            showDivider={false}
            subtitle={localizedFeedsText()}
          >
            {hasCountryFeeds
              ? (
                <Box justifyContent="end" spacing="loose">
                  <Button
                    onClick={handleDownloadFeedsButtonClick}
                  >
                    Download All Feeds
                  </Button>
                  <Button
                    intent="positive"
                    data-automation-id="facebook-add-feeds"
                    onClick={handleAddFeed}
                  >
                    Add Country
                  </Button>
                </Box>
              )
              : null}
          </MarketingCardHeading>
          <br />
          {hasCountryFeeds ? (
            <FacebookFeedsTable
              countries={countries}
              languages={languages}
              feeds={localizedFeeds}
            />
          ) : (
            <MarketingEmptyState
              title={EmptyStates.NO_FACEBOOK_COUNTRY_FEEDS}
              subtitle={EmptyStates.UPLOAD_FB_PRIMARY_FEEDS}
            >
              <Button
                data-automation-id="facebook-add-feeds"
                onClick={handleAddFeed}
                content={EmptyStates.FB_COUNTRY_FEEDS_BUTTON}
              />
            </MarketingEmptyState>
          )}
        </MarketingCard>
      </MarketingPage>
      <AddFacebookCountryFeedDialog catalogId={`${catalogId}`} />
    </>
  );
});

FacebookFeeds.displayName = 'FacebookFeeds';

export default FacebookFeeds;
