import React from 'react';
import get from 'lodash/get';
import noop from 'lodash/noop';
import {
  TableRow,
  TableRowColumn,
} from '@flowio/react-table';

interface Props {
  summary: io.flow.internal.v0.models.ItemClassificationSummary;
  onRowClicked: Function;
}

const Hs6TableRow: React.FC<Props> = ({ summary, onRowClicked }) => {
  function handleRowClicked(): void {
    onRowClicked(summary);
  }

  const { item, origin } = summary;

  return (
    <TableRow onClick={(): void => handleRowClicked()}>
      <TableRowColumn>
        {summary.item.number}
      </TableRowColumn>
      <TableRowColumn>
        {get(item, 'attributes.product_id')}
      </TableRowColumn>
      <TableRowColumn>
        {origin}
      </TableRowColumn>
      <TableRowColumn>
        {summary.item.name}
      </TableRowColumn>
      <TableRowColumn>
        {summary.hs6 ? summary.hs6 : 'N/A'}
      </TableRowColumn>
    </TableRow>
  );
};

Hs6TableRow.displayName = 'Hs6TableRow';

Hs6TableRow.defaultProps = {
  onRowClicked: noop,
};

export default Hs6TableRow;
