import { CurrenciesGetResponse } from '@flowio/api-sdk';
import { getCurrenciesStatus } from '../selectors';
import ActionTypes from '../constants/ActionTypes';
import ReadyState from '../../../utilities/redux/ReadyState';
import standardAction from '../../../utilities/standard-action';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';
import toLegacyResponse from '../../../utilities/clients/to-legacy-response';
import { ThunkDispatcher, RootState, ThunkExtraArguments } from '../../../stores/types';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import { PropType } from '../../../types';

type ResponseType = PropType<CurrenciesGetResponse, 'body'>;

export default function fetchCurrencies(q?: string) {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<LegacyResponse<ResponseType> | void> => {
    const state = getState();
    if (getCurrenciesStatus(state) === ReadyState.FULFILLED) {
      return Promise.resolve();
    }

    dispatch({
      type: ActionTypes.FETCH_CURRENCIES_REQUEST,
    });

    return extra.api(state).currencies.get({ q })
      .then((response) => {
        if (response.ok) {
          dispatch(standardAction(
            ActionTypes.FETCH_CURRENCIES_SUCCESS,
            response.body,
          ));
        } else {
          dispatch(standardAction(
            ActionTypes.FETCH_CURRENCIES_FAILURE,
            formatErrorResponseV2(response),
          ));
        }
        return toLegacyResponse<ResponseType>(response);
      });
  };
}
