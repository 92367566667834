import { ActionTypes } from '../constants';
import {
  ThunkDispatcher, RootState, ThunkExtraArguments, ThunkResult,
} from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import toLegacyResponse from '../../../utilities/clients/to-legacy-response';
import { defaultRedirect } from '../../console/actions';

export default function acceptInvitation(
  token: string,
): ThunkResult<Promise<LegacyResponse<io.flow.v0.models.Membership | FormattedErrorMessages>>> {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<LegacyResponse<io.flow.v0.models.Membership | FormattedErrorMessages>> => {
    dispatch({
      type: ActionTypes.ACCT_ORG_ACCEPT_INVITATION_SUBMIT,
    });
    return extra.api(getState()).invitations.putTokensByToken({
      token,
    }).then((response) => {
      if (response.ok) {
        dispatch({
          type: ActionTypes.ACCT_ORG_ACCEPT_INVITATION_COMPLETED,
          payload: response.body,
        });
        dispatch(defaultRedirect(response.body.organization.id));
        return toLegacyResponse(response);
      }

      const formattedErrors = formatErrorResponseV2(response);
      dispatch({
        type: ActionTypes.ACCT_ORG_ACCEPT_INVITATION_ERROR,
        payload: formattedErrors,
      });

      return toLegacyResponse({
        ...response,
        body: formattedErrors,
      });
    });
  };
}
