import React from 'react';
import { MarketingGatewayPlatform } from '@flowio/api-internal-constants';
import FacebookFeeds from '../../FacebookFeeds';
import ManualFeeds from '../../ManualFeeds';
import GoogleFeeds from '../../GoogleFeeds';
import { Props } from '../types';

const FeedsPage: React.FC<Props> = (props) => {
  const { params: { platformId, accountId } } = props;

  switch (platformId) {
    case MarketingGatewayPlatform.GOOGLE:
      return <GoogleFeeds {...props} />;
    case MarketingGatewayPlatform.FACEBOOK:
      if (accountId) {
        return <FacebookFeeds {...props} catalogId={Number(accountId)} />;
      }
      return <ManualFeeds platform={platformId} {...props} />;
    default:
      return <ManualFeeds platform={platformId} {...props} />;
  }
};

FeedsPage.displayName = 'FeedsPage';

export default FeedsPage;
