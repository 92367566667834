import isEmpty from 'lodash/isEmpty';
import { $HttpResponse } from '@flowio/api-internal-sdk';
import { PartialHttpResponse } from './clients/api-error';

/**
 * This file is a port from a previous format-error-reponse.js which as the name
 * suggest formats the error response. It's arguably outdated but as part of the TS
 * conversion (including the new TS SDK generate api client) we want to keep the status quo
 */

interface FormatErrorMessageOptions {
  messages?: Record<number, string>;
}

export interface MessageObject {
  message: string;
  field?: string;
  code?: string;
}

export interface FormattedErrorMessages {
  messages?: MessageObject[];
  [key: string]: unknown;
}

export interface FormattedErrorMessagesResponse {
  status: number;
  result: FormattedErrorMessages;
}

export const defaultHttpStatusMessages: Record<number, string> = {
  401: 'You are not authorized to perform this action at this time.',
  404: 'We could not find the resource you requested.',
};

const formatErrorResponseV2 = (
  response: PartialHttpResponse | $HttpResponse,
  options: FormatErrorMessageOptions = {},
): FormattedErrorMessages => {
  const errorResponse = response.body as io.flow.v0.models.GenericError;
  let messages: MessageObject[] = [];
  const {
    status,
  } = response;

  const httpStatusMessages: Record<number, string> = {
    ...defaultHttpStatusMessages,
    ...options.messages,
  };

  if (httpStatusMessages[status]) {
    messages = [{ message: httpStatusMessages[status] }];
  }

  if (errorResponse && errorResponse.code && Array.isArray(errorResponse.messages)) {
    if (response.body && response.body.messages) {
      messages = response.body.messages.map((message: string) => ({ message }));
    } else if (!isEmpty(response.body.message)) {
      messages = [{ message: response.body.message }];
    } else {
      messages = [{ message: response.body }];
    }
  }

  if (response.body) {
    return { ...response.body, ...{ messages } };
  }
  return { messages };
};

export default formatErrorResponseV2;
