import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { isInvalid, isSubmitting, submit } from 'redux-form';
import overSome from 'lodash/overSome';

import {
  getIsDialogOpen,
  getDialogProp,
  getAvailableFiltersForEditRuleWorksheet,
} from '../selectors';
import {
  hideEditRuleWorksheet,
} from '../actions';
import EditRuleWorksheet from '../components/EditRuleWorksheet';
import FormName from '../constants/FormName';
import DialogName from '../constants/DialogName';
import { RootState, ThunkDispatcher } from '../../../stores/types';
import {
  EditRuleWorksheetStateProps,
  EditRuleWorksheetValues,
  EditRuleWorksheetDispatchProps,
} from '../types/components';

const form = FormName.EDIT_RULE_FORM;
const worksheet = DialogName.EDIT_RULE_WORKSHEET;

const submitForm = () => submit(form);

const mapStateToProps = (state: RootState): EditRuleWorksheetStateProps => ({
  availableFilters: getAvailableFiltersForEditRuleWorksheet(state),
  isSaveDisabled: overSome([isSubmitting(form), isInvalid(form)])(state),
  initialValues: getDialogProp(worksheet, 'initialValues')(state) as Partial<EditRuleWorksheetValues>,
  open: getIsDialogOpen(worksheet)(state) as boolean,
});

function mapDispatchToProps(dispatch: ThunkDispatcher): EditRuleWorksheetDispatchProps {
  return bindActionCreators({
    onCancel: hideEditRuleWorksheet,
    onSave: submitForm,
  }, dispatch);
}

export default compose<React.FC>(
  connect(mapStateToProps, mapDispatchToProps),
)(EditRuleWorksheet);
