import { RootActionTypes } from '../../../stores/types';
import ActionTypes from '../constants/ActionTypes';
import { OptinActiveAttributeState } from '../types';

const initialState: OptinActiveAttributeState = {
  optinAttributeKey: undefined,
  status: 'pending',
};

const activeOptinAttribute = (
  state = initialState, action: RootActionTypes,
): OptinActiveAttributeState => {
  switch (action.type) {
    case ActionTypes.FETCH_OPTIN_ATTRIBUTE_FAILURE:
      return {
        ...state,
        status: 'rejected',
        optinAttributeKey: action.meta.optinAttributeKey,
      };
    case ActionTypes.FETCH_OPTIN_ATTRIBUTE_REQUEST:
      return {
        ...state,
        status: 'loading',
        optinAttributeKey: action.meta.optinAttributeKey,
      };
    case ActionTypes.FETCH_OPTIN_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        status: 'fulfilled',
        optinAttributeKey: action.meta.optinAttributeKey,
      };
    default:
      return state;
  }
};

export default activeOptinAttribute;
