import get from 'lodash/get';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withFetch } from '@flowio/redux-fetch';
import { RouterState } from 'react-router';

import { fetchOrganization } from '../../organization';
import {
  getCurrentFeature,
  getIsControlOrganization,
  getErrors,
  getRuleFilters,
} from '../selectors';
import {
  createFiltersForRuleQueries,
  fetchFeatureByKey,
  putFeatureByKey,
  requestStatusChange,
  showDeleteFeatureConfirm,
  showEditFeatureWorksheet,
  showReleaseFeatureConfirm,
  showAddRuleWorksheet,
  showEditRuleWorksheet,
  showDeleteRuleConfirm,
} from '../actions';
import FeatureDetail from '../components/FeatureDetail';
import { RootState, ThunkDispatcher } from '../../../stores/types';
import { FeatureDetailStateProps } from '../types/components';

function getAsyncState(
  dispatch: ThunkDispatcher,
  _getState: () => RootState,
  ownProps: RouterState,
) {
  const { organization, featureKey } = ownProps.params;
  return Promise.all([
    dispatch(fetchOrganization(organization)),
    dispatch(fetchFeatureByKey(organization, featureKey)),
  ]).then(([, response]) => {
    const rules = get(response, 'result.body.rules', []);
    return dispatch(createFiltersForRuleQueries(organization, rules));
  });
}

function mapStateToProps(state: RootState, ownProps: RouterState): FeatureDetailStateProps {
  const { organization } = ownProps.params;

  return {
    organizationId: organization,
    errors: getErrors(state),
    isControlOrganization: getIsControlOrganization(state),
    feature: getCurrentFeature(state),
    ruleFilters: getRuleFilters(state),
  };
}

const mapDispatchToProps = (dispatch: ThunkDispatcher) => bindActionCreators({
  onAddRule: showAddRuleWorksheet,
  onChangeFeature: putFeatureByKey,
  onDeleteFeature: showDeleteFeatureConfirm,
  onDeleteRule: showDeleteRuleConfirm,
  onEditFeature: showEditFeatureWorksheet,
  onEditRule: showEditRuleWorksheet,
  onReleaseFeature: showReleaseFeatureConfirm,
  requestStatusChange,
}, dispatch);

export default compose<React.FC>(
  withFetch(getAsyncState),
  connect(mapStateToProps, mapDispatchToProps),
)(FeatureDetail);
