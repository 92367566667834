import ActionTypes from '../constants/action-types';
import { getOrganizationId } from '../../organization';
import { getCurrentExperience } from '../selectors';
import { ThunkDispatcher, RootState, ThunkExtraArguments } from '../../../stores/types';
import { UpdateItemMarginsAction } from '../types';

/**
 * Overrides existing item margins for the current experience.
 */
export default function updateItemMargins(margins: io.flow.v0.models.ItemMargin[]) {
  return function updateItemMarginsSideEffects(
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<UpdateItemMarginsAction> {
    const state = getState();
    const organizationId = getOrganizationId(state);
    const experience = getCurrentExperience(state);

    if (!experience) {
      throw new Error('updateItemMargins: Missing current experience');
    }

    const itemMarginPromises = margins.map(
      (margin, index) => extra.api(state).experiences.putMarginsByExperienceKeyAndKey({
        experience_key: experience.key,
        organization: organizationId,
        key: margin.id,
        body: {
          name: margin.name,
          q: margin.q,
          fixed: margin.fixed,
          percent: margin.percent,
          position: index,
        },
      }),
    );

    return Promise.all(itemMarginPromises)
      .then(() => dispatch({ type: ActionTypes.UPDATE_ITEM_MARGINS, payload: margins }));
  };
}
