import { css } from '@emotion/css';
import colors from '../../../../../theme/tokens/colors';

const hOffset = '12px';
const arrow = '8px';
const buttonHeight = '40px';
const padding = '16px';

export const addChannelsTrigger = css`
  width: 200px;
  height: ${buttonHeight} !important;
  text-align: left !important;
`;

export const container = css`
  position: relative;

  & .addMoreChannelPopover {
    position: absolute;
    width: 288px;
    right: calc(100% + ${hOffset});
    top: 0;
    z-index: 1;
    background-color: ${colors.white};
    max-width: 270px;
    color: ${colors.marketing['dark-slate-blue']};
    padding: ${padding};
    border-radius: 4px;
    font-size: 12px;
    box-shadow: 0 0 8px 5px ${colors['flow-blue'][200]};
    box-sizing: border-box;

    &:after {
      content: '';
      position: absolute;
      left: 100%;
      top: (${buttonHeight} - ${arrow}) / 2;
      border-left: ${arrow} solid ${colors.white};
      border-top: ${arrow} solid transparent;
      border-bottom: ${arrow} solid transparent;
    }

    & h4 {
      margin: 0;
      font-size: 14px;
    }

    & button {
      float: right;
    }
  }
`;

export const menuPopover = css`
  overflow-y: visible !important;
  padding: 4px 0;

  &.allChannelsAdded {
    background-color: ${colors.marketing['dark-slate-blue']};

    &:after {
      content: '';
      position: absolute;
      top: -7px;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid ${colors.marketing['dark-slate-blue']};
      z-index: 1000;
      left: 50%;
    }

    & div {
      color: ${colors.white};
      padding: 12px;
      font-size: 12px;
      font-weight: 400;
      position: relative;
      border-radius: 4px;
    }
  }

  & .addChannelsMenu {
    font-weight: 500;
    width: 288px;
    max-width: 288px;

    .integrated {
      padding-bottom: ${padding};

      &.channelType:only-of-type {
        padding-bottom: 0;
      }
    }

    & .channelType {
      .channelTypeTitle,
      .platformItem {
        padding: 0 ${padding};
        font-size: 14px;

        img {
          margin-right: 5px;
        }
      }

      & .channelTypeTitle {
        line-height: 32px;
        margin: 0;
        color: ${colors.marketing['light-slate-gray']};
      }

      & .platformItem {
        color: ${colors.marketing['dark-slate-blue']};

        &.disabled {
          pointer-events: none;
          opacity: 0.5;
        }

        &:hover {
          cursor: pointer;
          background-color: ${colors.concrete};
        }

        & .platformName {
          line-height: 48px;
          margin-left: 8px;
        }
      }
    }

    & .connectGoogleFirst {
      background-color: ${colors['flow-blue'][200]};
      margin: 16px;
      padding: 16px;
      font-weight: normal;
      font-size: 12px;
      color: ${colors['flow-blue'][300]};
      border-radius: 4px;

      & button {
        margin-top: 8px;
      }
    }
  }
`;
