import { css } from '@emotion/css';
import colors from '../../../../theme/tokens/colors';

export const organizationCard = css`
  position: relative;
  display: block;
  width: 196px;
  height: 196px;
  padding: 15px 15px 49px;
  border: solid 1px #b5bec9;
  border-radius: 6px;
  background-color: ${colors.concrete};
  box-sizing: border-box;
  color: ${colors.charcoal};
  cursor: pointer;

  &:hover {
    padding: 14px 14px 48px;
    border: 2px solid ${colors['flow-blue'][400]};
    text-decoration: none;

    & .environment {
      bottom: 13px;
      left: 13px;
    }
  }

  &:active {
    background-color: ${colors.mercury};
  }
`;

export const name = css`
  width: 100%;
  height: 100%;
  color: ${colors.black};
  font-size: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const environment = css`
  position: absolute;
  left: 14px;
  bottom: 14px;
  padding: 0 6px;
  border-radius: 4px;
  background-color: ${colors.silver};
  box-sizing: border-box;
  color: ${colors.white};
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
`;
