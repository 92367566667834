import { ThunkDispatcher, ThunkResult } from '../../../stores/types';
import standardAction from '../../../utilities/standard-action';
import ActionTypes from '../constants/action-types';
import { logout as fbLogout } from '../fbSDK';

const logout = (): ThunkResult<Promise<void>> => (
  dispatch: ThunkDispatcher,
): Promise<void> => {
  dispatch(standardAction(ActionTypes.LOGOUT_REQUEST));

  return fbLogout()
    .then(({ success, status }) => {
      if (!success) {
        dispatch(standardAction(ActionTypes.LOGOUT_FAILURE));
        throw new Error('Error logging out of facebook');
      }

      dispatch(standardAction(ActionTypes.LOGOUT_SUCCESS, status));
    });
};

export default logout;
