import head from 'lodash/head';
import update from 'immutability-helper';

import ActionTypes from '../constants/action-types';
import { RootActionTypes } from '../../../stores/types';
import { UserMembershipState } from '../types';

const defaultState: Partial<UserMembershipState> = {};

export default function userMembershipReducer(
  state = defaultState,
  action: RootActionTypes,
): Partial<UserMembershipState> {
  switch (action.type) {
    case ActionTypes.FETCH_USER_MEMBERSHIP_SUCCESS:
    // TODO: Horrible. Actual logged in user is not first on the list.
      return update(state, { $set: head(action.payload) || {} });
    default:
      return state;
  }
}
