import ShopifyApp from '../../../modules/auth/containers/ShopifyApp';
import ShopifyAuth from '../../../modules/auth/containers/ShopifyAuth';

export default {
  path: 'shopify',
  childRoutes: [
    {
      path: 'auth',
      hideLeftNavigation: true,
      hideTopNavigation: true,
      component: ShopifyAuth,
    },
    {
      path: 'app',
      hideLeftNavigation: true,
      hideTopNavigation: true,
      component: ShopifyApp,
    },
  ],
};
