import invariant from 'invariant';
import ActionType from '../constants/ActionType';

/**
 * Creates an action responsible for displaying the return policy delete confirmation.
 * @throws {InvariantError} when required arguments are not provided.
 * @param {ReturnPolicy} returnPolicy The return policy to be deleted.
 */
export default function showReturnPolicyDeleteConfirmation(
  organizationId: string,
  returnPolicy: io.flow.v0.models.ReturnPolicy,
) {
  invariant(organizationId != null, 'An organization identifier must be provided');
  invariant(returnPolicy != null, 'A return policy must be provided');
  return {
    type: ActionType.ShowReturnPolicyDeleteConfirmation,
    payload: { organizationId, returnPolicy },
  };
}
