import {
  MarketingActions,
  MarketingProductFeedsState,
} from '../types';
import ActionTypes from '../constants/ActionTypes';

const defaultState: MarketingProductFeedsState = {
  productFeeds: [],
};

export default function productFeedsReducer(
  state = defaultState,
  action: MarketingActions,
): MarketingProductFeedsState {
  switch (action.type) {
    case ActionTypes.FETCH_ORGANIZATION_FEEDS_REQUEST:
      return {
        ...state,
        marketingId: action.payload.marketingId,
      };
    case ActionTypes.FETCH_ORGANIZATION_FEEDS_SUCCESS:
      return {
        ...state,
        error: undefined,
        productFeeds: action.payload.response,
      };

    case ActionTypes.FETCH_ORGANIZATION_FEEDS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
}
