import { Edit, CircleLoader, Plus } from '@flowio/react-icons';
import React, { MouseEventHandler, useState } from 'react';
import noop from 'lodash/noop';
import get from 'lodash/get';
import { Box } from '@flowio/react-box';
import { Button, OutlineButton } from '@flowio/react-button';
import {
  Card, CardActions, CardHeader, CardContent, CardFooter, CardTitle,
} from '@flowio/react-card';
import { Select } from '@flowio/react-select';
import { Label } from '@flowio/react-label';

import { css } from '@emotion/css';
import { SelectOption } from '@flowio/react-select';

const cardFooterStyles = css`
  text-align: right;
`;

const saveBtnStyles = css`
  margin-left: 16px;
`;

const priceBookLabelStyles = css`
  color: #77818f !important;
`;

const loadingIndicatorStyles = css`
  height: 32px;
  margin-right: 8px;
`;

interface Props {
  editable: boolean;
  priceBooks: io.flow.v0.models.PriceBook[];
  initialPriceBook: io.flow.v0.models.ExperiencePriceBookMapping;
  onCancelEdit: Function;
  onSavePriceBook: Function;
  onEditPriceBook: MouseEventHandler;
  onCreatePriceBook: MouseEventHandler;
  priceBookStatus: string;
}

// TODO: Add endpoint for actually saving the price book mappings
const PriceBookComponent: React.FC<Props> = ({
  onCancelEdit,
  priceBooks,
  initialPriceBook,
  onSavePriceBook,
  onEditPriceBook,
  onCreatePriceBook,
  editable,
  priceBookStatus,
}) => {
  const getFormattedSelectedPriceBook = (
    priceBookMapping: io.flow.v0.models.ExperiencePriceBookMapping,
  ): io.flow.v0.models.PriceBook | undefined => priceBooks.find(
    (pb) => pb.id === get(priceBookMapping, 'price_book.id'),
  );

  const [
    selectedPriceBook,
    setSelectedPriceBook,
  ] = useState<io.flow.v0.models.PriceBook | undefined>(
    getFormattedSelectedPriceBook(initialPriceBook),
  );

  const handleDropdownChange = (
    value: string,
  ): void => {
    const selectPriceBook: io.flow.v0.models.PriceBook | undefined = priceBooks
      .find((pb) => pb.key === value);
    setSelectedPriceBook(selectPriceBook);
  };

  const handleCancelEdit = (): void => {
    // Resets the form to the initial values
    setSelectedPriceBook(getFormattedSelectedPriceBook(initialPriceBook));
    onCancelEdit();
  };

  // partial type needed here to support initial option on dropdown
  const priceBookOptions: SelectOption[] = priceBooks
    .filter((priceBook: io.flow.v0.models.PriceBook) => priceBook.status === 'published')
    .map((pb) => ({ value: pb.key, content: pb.name }));

  priceBookOptions.push({ content: 'None', value: 'none' });

  const labelText = priceBookOptions.length > 0 ? 'Select the price book to use in this experience. Items individually will always be assigned price book prices first and then dynamic pricing second if no price book prices exist.'
    : 'Please create a price book first to assign it to an experience. Items individually will always be assigned price book prices first and then dynamic pricing second if no price book prices exist.';

  return (
    <Card>
      <CardHeader>
        <CardTitle content="Price Books" />
      </CardHeader>
      <CardContent>
        <Box justifyContent="between" alignItems="center">
          <Label className={priceBookLabelStyles} content={labelText} />
          {priceBookOptions.length > 0 && (
            <Select
              disabled={!editable}
              hintText="Select a price book"
              // Want to only show the published price books in the dropdown
              options={priceBookOptions}
              defaultValue={selectedPriceBook?.key || 'none'}
              onValueChange={handleDropdownChange}
            />
          )}
        </Box>
      </CardContent>
      <CardFooter className={cardFooterStyles}>
        <CardActions>
          {editable && (
            <div>
              {priceBookStatus === 'loading' && (
                <CircleLoader className={loadingIndicatorStyles} />
              )}
              <Button content="Cancel" onClick={handleCancelEdit} disabled={priceBookStatus === 'loading'} />
              <OutlineButton className={saveBtnStyles} content="Save" onClick={(): void => onSavePriceBook(selectedPriceBook, initialPriceBook)} intent="primary" disabled={priceBookStatus === 'loading'} />
            </div>
          )}
          {!editable && priceBookOptions.length > 0 && (
            <OutlineButton content="Assign Price Books" leftIcon={Edit} onClick={onEditPriceBook} intent="primary" />
          )}
          {!editable && priceBookOptions.length < 1 && (
            <OutlineButton content="Create Price Books" leftIcon={Plus} onClick={onCreatePriceBook} intent="primary" />
          )}
        </CardActions>
      </CardFooter>
    </Card>
  );
};

PriceBookComponent.defaultProps = {
  editable: false,
  priceBooks: [],
  initialPriceBook: undefined,
  onCancelEdit: noop,
  onSavePriceBook: noop,
  onEditPriceBook: noop,
  onCreatePriceBook: noop,
  priceBookStatus: undefined,
};

export default PriceBookComponent;
