import { $HttpResponse } from '@flowio/api-sdk';
import { ThunkResult, RootActionTypes } from '../../../stores/types';
import ActionTypes from '../constants/ActionTypes';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';

function createDiscountRequestAction(): RootActionTypes {
  return {
    type: ActionTypes.CREATE_DISCOUNT_REQUEST,
  };
}

function createDiscountSuccessAction(
  payload: io.flow.v0.models.DiscountRuleSettings,
): RootActionTypes {
  return {
    type: ActionTypes.CREATE_DISCOUNT_SUCCESS,
    payload,
  };
}

function createDiscountFailureAction(payload: FormattedErrorMessages): RootActionTypes {
  return {
    type: ActionTypes.CREATE_DISCOUNT_FAILURE,
    payload,
    error: true,
  };
}

export default function createDiscount(
  organization: string,
  form: io.flow.v0.models.DiscountRuleSettingsForm,
): ThunkResult<Promise<void | io.flow.v0.models.DiscountRuleSettings>> {
  return function createDiscountEffects(
    dispatch,
    getState,
    extra,
  ): Promise<void | io.flow.v0.models.DiscountRuleSettings> {
    dispatch(createDiscountRequestAction());
    return extra.api(getState()).discountRuleSettings.post({
      organization,
      body: form,
    }).then((response) => {
      if (response.ok) {
        dispatch(createDiscountSuccessAction(response.body));
        return response.body;
      }

      const errorResponse = response as $HttpResponse<io.flow.v0.models.GenericError>;

      dispatch(createDiscountFailureAction(formatErrorResponseV2(errorResponse)));
      return undefined;
    });
  };
}
