import update from 'immutability-helper';
import { ActionTypes } from '../constants';
import { LogisticPaginationState } from '../types/state';
import { RootActionTypes } from '../../../stores/types';

const initialState: LogisticPaginationState = {
  outbound_lanes_page: 0,
  return_lanes_page: 0,
};

export default function paginationReducer(
  state = initialState,
  action: RootActionTypes,
) {
  switch (action.type) {
    case ActionTypes.UPDATE_OUTBOUND_SHIPPING_LANES_PAGE:
      return update(state, {
        outbound_lanes_page: { $set: action.payload },
      });
    case ActionTypes.UPDATE_RETURN_SHIPPING_LANES_PAGE:
      return update(state, {
        return_lanes_page: { $set: action.payload },
      });
    case ActionTypes.RESET_SHIPPING_LANES_PAGES:
      return update(state, {
        outbound_lanes_page: { $set: 0 },
        return_lanes_page: { $set: 0 },
      });
    default:
      return state;
  }
}
