import { Box } from '@flowio/react-box';
import React from 'react';
import map from 'lodash/map';

import CenterListItem from '../CenterListItem';
import { CenterListProps } from '../../types/components';

const CenterList: React.FC<CenterListProps> = ({ centers, className, countries }) => (
  <Box className={className} direction="column">
    {map(centers, (center) => (
      <CenterListItem key={center.id} center={center} countries={countries} />
    ))}
  </Box>
);

CenterList.displayName = 'CenterList';

CenterList.defaultProps = {
  className: undefined,
};

export default CenterList;
