import { css } from '@emotion/css';

export const header = css`
  font-weight: 500;
`;

export const cellWrap = css`
  overflow-wrap: anywhere;
`;
export const cellSelect = css`
  user-select: all;
`;

export const first = css`
  padding-left: 1rem !important;
`;
