import React, { useEffect } from 'react';
import { Button } from '@flowio/react-button';
import { Box } from '@flowio/react-box';
import withDismiss, { WithDismissProps, Keys } from '../../../../../components/with-dismiss';

type Props = {
  wasNoticed?: boolean;
};

const AddChannelsButtonPopover: React.FC<WithDismissProps<Props>> = ({
  onDismiss,
  wasNoticed,
}) => {
  useEffect(() => {
    if (wasNoticed) {
      onDismiss();
    }
  }, [onDismiss, wasNoticed]);

  return (
    <Box className="addMoreChannelPopover" direction="column">
      <h4>Add more channels</h4>
      <p>Add localized product feeds to your selected channels here!</p>
      <Button onClick={onDismiss} intent="primary">Got It</Button>
    </Box>
  );
};

AddChannelsButtonPopover.displayName = 'AddChannelsButtonPopover';

export default withDismiss(AddChannelsButtonPopover, Keys.MARKETING_ADD_CHANNELS_BUTTON_POPOVER);
