import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import ActionTypes from '../constants/action-types';

function createRequestAction(): RootActionTypes {
  return {
    type: ActionTypes.FETCH_CURRENCIES_REQUEST,
  };
}

function createSuccessAction(payload: io.flow.v0.models.Currency[]): RootActionTypes {
  return {
    type: ActionTypes.FETCH_CURRENCIES_SUCCESS,
    payload,
  };
}

function createFailureMessage(payload: FormattedErrorMessages): RootActionTypes {
  return {
    type: ActionTypes.FETCH_CURRENCIES_FAILURE,
    payload,
  };
}

function fetchCurrencies(organizationId: string): ThunkResult<Promise<void>> {
  return function fetchCurrenciesEffects(
    dispatch,
    getState,
    extra,
  ): Promise<void> {
    dispatch(createRequestAction());

    return extra.api(getState()).organizations.getSettingsAndCurrenciesByOrganizationId({
      organization_id: organizationId,
    }).then((response) => {
      if (response.ok) {
        dispatch(createSuccessAction(response.body));
        return;
      }

      const error = formatErrorResponseV2(response);
      dispatch(createFailureMessage(error));
    });
  };
}

export default fetchCurrencies;
