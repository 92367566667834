import { createStructuredSelector } from 'reselect';
import { getPageNumber, getPageSize } from '@flowio/redux-filtering-paging-sorting';
import { getOptinAttributesPaginationState } from '../selectors';
import { getOrganizationId } from '../../organization/selectors';
import fetchOptinAttributes from './fetchOptinAttributes';
import { RootState, ThunkDispatcher } from '../../../stores/types';

type PageState = {
  organizationId: string,
  entriesPerPage: number,
  pageNumber: number,
};

const getPageState = createStructuredSelector<RootState, PageState>({
  organizationId: getOrganizationId,
  entriesPerPage: getPageSize(getOptinAttributesPaginationState),
  pageNumber: getPageNumber(getOptinAttributesPaginationState),
});

export default function refreshOptinAttributes() {
  return function refreshOptinAttributesSideEffects(
    dispatch: ThunkDispatcher,
    getState: () => RootState,
  ) {
    return dispatch(fetchOptinAttributes(getPageState(getState())));
  };
}
