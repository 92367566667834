import { Check } from '@flowio/react-icons';
/**
 * A component that manages the UX logic for actions within a card containing a form.
 *
 * IMPORTANT!
 * The name of the form MUST be passed to the "form" prop of this component.
 * It will be used to respond to form changes in the application state.
 *
 * IMPORTANT!
 * The form MUST be configured to reinitialize when initial values are updated.
 * Assumption is that initial values are read from application state and updated
 * on successful submission. If the form is not configured to
 * reinitialize when initial values are updated then the form will remain in
 * dirty state after submission, thus breaking the logic below.
 *
 * The logic is the following:
 *
 * A "Save" button is displayed at all times. The "Save" button is disabled
 * until changes are made to the form.
 *
 * A "Cancel" button is displayed unless changes have not been made or the
 * form was submitted successfully.
 *
 * A "Success" label is displayed when the form is submitted successfully.
 */

import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import {
  hasSubmitSucceeded,
  isPristine,
  isSubmitting,
  reset,
  submit,
  FormAction,
} from 'redux-form';
import { Box } from '@flowio/react-box';

import { Button } from '@flowio/react-button';
import PropTypes from 'prop-types';
import React, { MouseEvent } from 'react';
import classNames from 'classnames/bind';

import styles from './SaveChanges.module.css';
import { RootState, ThunkDispatcher } from '../../stores/types';

interface OwnProps {
  form: string;
  cancelButtonText?: string;
  submitButtonText?: string;
}

interface StateProps {
  pristine: boolean;
  submitting: boolean;
  submitSucceeded: boolean;
}

interface DispatchProps {
  onReset: () => FormAction;
  onSubmit: () => FormAction;
}

const cx = classNames.bind(styles);

function createStopEventAndYieldTo<T>(
  callback: ((event: MouseEvent) => T),
): (event: MouseEvent) => T {
  return function stopEventAndYieldTo(event: MouseEvent): T {
    event.preventDefault();
    event.stopPropagation();
    return callback(event);
  };
}

const SaveChanges: React.FC<StateProps & DispatchProps & OwnProps> = ({
  cancelButtonText,
  onReset,
  onSubmit,
  pristine,
  submitting,
  submitButtonText,
  submitSucceeded,
}) => (
  <Box alignItems="center" spacing="loose">
    {(pristine && submitSucceeded) && (
      <span className={cx('successLabel')}>
        <Check className={cx('successIcon')} />
        <span>Changes Saved.</span>
      </span>
    )}
    {(!pristine) && (
      <Button
        type="button"
        content={cancelButtonText}
        intent="neutral"
        disabled={submitting || pristine}
        onClick={createStopEventAndYieldTo(onReset)}
      />
    )}
    <Button
      type="submit"
      content={submitButtonText}
      intent="primary"
      disabled={submitting || pristine}
      onClick={createStopEventAndYieldTo(onSubmit)}
    />
  </Box>
);

SaveChanges.displayName = 'SaveChanges';

SaveChanges.propTypes = {
  cancelButtonText: PropTypes.string,
  form: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
  onReset: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  submitButtonText: PropTypes.string,
  submitSucceeded: PropTypes.bool.isRequired,
};

SaveChanges.defaultProps = {
  cancelButtonText: 'Cancel',
  submitButtonText: 'Save Changes',
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = (
  state: RootState,
  props: OwnProps,
) => {
  const { form } = props;
  return {
    pristine: isPristine(form)(state),
    submitting: isSubmitting(form)(state),
    submitSucceeded: hasSubmitSucceeded(form)(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch: ThunkDispatcher,
  props: OwnProps,
) => {
  const { form } = props;
  return {
    onReset: (): FormAction => dispatch(reset(form)),
    onSubmit: (): FormAction => dispatch(submit(form)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SaveChanges);
