import { ConsoleActionTypes } from '../console/constants';

const DefaultState = {};

function addError(state, action) {
  const errors = state.errors || [];
  const updatedErrors = errors.concat(action.payload);

  return { ...state, errors: updatedErrors };
}

export default function (state = DefaultState, action) {
  switch (action.type) {
    case ConsoleActionTypes.PUSH_ERROR:
      return addError(state, action);

    case ConsoleActionTypes.RESET_ERRORS:
      return { ...state, errors: [] };

    default:
      return state;
  }
}
