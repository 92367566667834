import { css } from '@emotion/css';

export const genericError = css`
  margin-bottom: 16px;
`;

export const ruleCondition = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 10px;
  border: 1px solid #e4e7eb;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.05);

  &:not(:first-child) {
    margin-top: 12px;
  }
`;

export const ruleConditionFieldset = css`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0;
  border: 0;
`;

export const ruleConditionErrorText = css`
  display: block;
  margin: 0.5rem 0 0;
  padding: 0;
  color: #d2431f;
`;

export const margin = css`
  margin: 6px !important;
`;

export const ruleConditionField = css`
  ${margin}
`;
export const ruleConditionOperator = css`
  ${margin}
`;
export const ruleConditionValues = css`
  ${margin}
`;
export const ruleConditionRemoveButton = css`
  ${margin}
`;
