import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { isInvalid, isSubmitting, submit } from 'redux-form';
import React from 'react';

import overSome from 'lodash/overSome';
import {
  getIsDialogOpen,
  getDialogProp,
} from '../selectors';
import {
  hideAddFeatureWorksheet,
} from '../actions';
import AddFeatureWorksheet from '../components/AddFeatureWorksheet';
import FormName from '../constants/FormName';
import DialogName from '../constants/DialogName';
import {
  AddFeatureWorksheetValues,
  AddFeatureWorksheetStateProps,
  AddFeatureWorksheetOwnProps,
} from '../types/components';
import { RootState, ThunkDispatcher } from '../../../stores/types';

const form = FormName.ADD_FEATURE_FORM;
const worksheet = DialogName.ADD_FEATURE_WORKSHEET;
const intialValuesDialogProp = 'initialValues';

const submitForm = () => submit(form);

const mapStateToProps = (state: RootState): AddFeatureWorksheetStateProps => ({
  isSaveDisabled: overSome(isSubmitting(form), isInvalid(form))(state),
  initialValues: getDialogProp(
    worksheet,
    intialValuesDialogProp,
  )(state) as Partial<AddFeatureWorksheetValues>,
  open: getIsDialogOpen(worksheet)(state) as boolean,
});

function mapDispatchToProps(dispatch: ThunkDispatcher) {
  return bindActionCreators({
    onCancel: hideAddFeatureWorksheet,
    onSave: submitForm,
  }, dispatch);
}

export default compose<React.FC<AddFeatureWorksheetOwnProps>>(
  connect(mapStateToProps, mapDispatchToProps),
)(AddFeatureWorksheet);
