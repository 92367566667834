import { RootActionTypes } from '../../../stores/types';
import { ActionTypes } from '../constants';

function changePricingFormatVisibility(visible: boolean): RootActionTypes {
  return {
    type: ActionTypes.CHANGE_PRICING_FORMAT_VISIBILITY,
    payload: visible,
  };
}

export default changePricingFormatVisibility;
