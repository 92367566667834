import { createSelector, Selector } from 'reselect';
import get from 'lodash/get';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import property from 'lodash/property';

import { getShippingConfigurations } from '../logistics/selectors';
import { RootState } from '../../stores/types';
import { ExperienceState } from './types';
import { FormattedErrorMessages } from '../../utilities/format-error-response-v2';

/**
 * This is here because we incorrectly add empty entities to the this node in the redux store. If
 * there is no data, they should be undefined. Instead of refactoring the entire experience node in
 * the store, this will simulate the desired behavior until we can incrementally fix and verify
 * each dependent container and action.
 */
const emptyAsUndefined = <T extends object>(
  obj: T | undefined | null,
): T | undefined => (isEmpty(obj) ? undefined : obj as T);

export const getExperienceState = (state: RootState): ExperienceState => get(state, 'experience');

export const getExperiences: Selector<RootState, io.flow.v0.models.Experience[]> = createSelector(
  getExperienceState,
  (state) => get(state, 'entities.experiences.results'),
);

export const getExperienceByKey = (
  experienceKey: string,
): Selector<RootState, io.flow.v0.models.Experience | undefined> => createSelector(
  getExperiences,
  (experiences) => find(experiences, { key: experienceKey }),
);

export const getCurrentExperience: Selector<
RootState, ExperienceState['current']
> = createSelector(
  getExperienceState,
  (state) => get(state, 'current', null),
);

export const getCurrentExperienceCountry: Selector<RootState, string | undefined> = createSelector(
  getCurrentExperience,
  (experience) => experience?.country,
);

export const getCurrentExperienceKey: Selector<RootState, string | undefined> = createSelector(
  getCurrentExperience,
  (experience) => get(experience, 'key'),
);

export const getCurrentExperienceCurrency = createSelector(
  getCurrentExperience,
  (experience) => get(experience, 'currency'),
);

export const getCurrentExperienceDeliveredDuty = createSelector(
  getCurrentExperience,
  (experience) => get(experience, 'delivered_duty'),
);

export const getCurrentExperienceSubcatalogId = createSelector(
  getCurrentExperience,
  (experience) => get(experience, 'subcatalog.id'),
);

/**
 * Returns a normalized object of all fetched experience logistic settings.
 * @returns {Object}
 */
export const getExperienceLogisticsSettings = createSelector<RootState, ExperienceState, ExperienceState['entities']['experienceLogisticsSettings']>(
  getExperienceState,
  property('entities.experienceLogisticsSettings'),
);

/**
 * Returns the identifier for the currently selected experience logistics settings.
 * @returns {String}
 */
export const getSelectedExperienceLogisticsSettings = createSelector<
RootState, ExperienceState, string
>(
  getExperienceState,
  property('selectedExperienceLogisticsSettings'),
);

/**
 * Returns the experience logistics settings for the current experience.
 * @returns {?ExperienceLogisticsSetting}
 */
export const getCurrentExperienceLogisticsSettings = createSelector<
RootState,
ExperienceState['entities']['experienceLogisticsSettings'],
ExperienceState['selectedExperienceLogisticsSettings'],
io.flow.v0.models.ExperienceLogisticsSettings
>(
  getExperienceLogisticsSettings,
  getSelectedExperienceLogisticsSettings,
  (experienceLogisticsSettings, selectedExperienceLogisticsSettings) => (
    get(experienceLogisticsSettings, selectedExperienceLogisticsSettings)
  ),
);

/**
 * Returns the shipping configuration key associated with the current experience.
 * @returns {?String}
 */
export const getCurrentExperienceShippingConfigurationKey = createSelector<
RootState,
io.flow.v0.models.ExperienceLogisticsSettings,
string
>(
  getCurrentExperienceLogisticsSettings,
  property('shipping_configuration.key'),
);

/**
 * Returns the shipping configuration associated with the current experience.
 * @returns {?ShippingConfiguration}
 */
export const getCurrentExperienceShippingConfiguration = createSelector(
  getShippingConfigurations,
  getCurrentExperienceShippingConfigurationKey,
  (shippingConfigurations, shippingConfigurationKey) => (
    find(shippingConfigurations, { key: shippingConfigurationKey })
  ),
);

export const getExperienceSearchResults = getExperiences;

export const getExperienceSearchPageInfo = createSelector<
RootState, ExperienceState, { isFirstPage: boolean; isLastPage: boolean }
>(
  getExperienceState,
  (state) => pick(get(state, 'entities.experiences'), ['isFirstPage', 'isLastPage']),
);

export const getExperienceDefaultValues = createSelector(
  getExperienceState,
  (state) => get(state, 'entities.defaults.value'),
);

export const getItemSuggestions = createSelector(
  getExperienceState,
  (state) => get(state, 'entities.itemSuggestions.results'),
);

export const getPricing = createSelector(
  getExperienceState,
  (state) => get(state, 'entities.pricing.current'),
);

export const getPricingErrors: Selector<RootState, FormattedErrorMessages> = createSelector(
  getExperienceState,
  (state) => get(state, 'entities.errors.pricing'),
);

export const getPriceFormat = createSelector(
  getExperienceState,
  (state) => emptyAsUndefined(get(state, 'entities.priceFormat')),
);

export const getExperienceItem = createSelector(
  getExperienceState,
  (state) => emptyAsUndefined(get(state, 'entities.item')),
);

export const getItemPriceDetails = createSelector<
RootState, ExperienceState, ExperienceState['entities']['itemPriceDetails']['current']
>(
  getExperienceState,
  (state) => get(state, 'entities.itemPriceDetails.current'),
);

export const getMargins = createSelector(
  getExperienceState,
  (state) => get(state, 'entities.margins.results'),
);

export const getMarginByKey = (key: string): Selector<
RootState, io.flow.v0.models.ItemMargin | undefined
> => createSelector(
  getMargins,
  (margins) => find(margins, { key }),
);

export const getMarginErrors: Selector<RootState, FormattedErrorMessages> = createSelector(
  getExperienceState,
  (state) => get(state, 'errors.margins'),
);

export const getDeliveredDutyOptions = createSelector<RootState, ExperienceState, ExperienceState['entities']['deliveredDutyOptions']>(
  getExperienceState,
  (state) => get(state, 'entities.deliveredDutyOptions'),
);

export const getDeliveredDutyPreference = createSelector(
  getExperienceState,
  (state) => get(state, 'entities.deliveredDutyPreference'),
);

export const getDeliveredDutyMethodPreference = createSelector<RootState, ExperienceState['entities']['deliveredDutyPreference'], ExperienceState['entities']['deliveredDutyPreference']['methods']>(
  getDeliveredDutyPreference,
  (preference) => get(preference, 'methods'),
);

export const getDeliveredDutyDefaultPreference = createSelector<RootState, ExperienceState['entities']['deliveredDutyPreference'], ExperienceState['entities']['deliveredDutyPreference']['default']>(
  getDeliveredDutyPreference,
  (preference) => get(preference, 'default'),
);

export const getDeliveredDutyDisplayPreference = createSelector<RootState, ExperienceState['entities']['deliveredDutyPreference'], ExperienceState['entities']['deliveredDutyPreference']['display']>(
  getDeliveredDutyPreference,
  (preference) => get(preference, 'display'),
);

export const getExperienceToArchive = createSelector<
RootState, ExperienceState, ExperienceState['entities']['ui']['experienceToArchive']
>(
  getExperienceState,
  (state) => get(state, 'entities.ui.experienceToArchive'),
);

export const getActivePricingSection = createSelector<
RootState, ExperienceState, ExperienceState['entities']['ui']['activePricingSection']
>(
  getExperienceState,
  (state) => get(state, 'entities.ui.activePricingSection'),
);

export const getExperienceToActivate = createSelector<
RootState, ExperienceState, ExperienceState['entities']['ui']['experienceToActivate']
>(
  getExperienceState,
  (state) => get(state, 'entities.ui.experienceToActivate'),
);

export const getExperienceToClone = createSelector(
  getExperienceState,
  (state) => get(state, 'entities.ui.experienceToClone'),
);

export const getExperienceSearchValues = createSelector(
  getExperienceState,
  (state) => get(state, 'entities.ui.searchValue', ''),
);

export const getExperienceStatusFilters = createSelector<
RootState, ExperienceState, ExperienceState['entities']['ui']['statusFilters']
>(
  getExperienceState,
  (state) => get(state, 'entities.ui.statusFilters'),
);

export const getPriceBookStatus = createSelector(
  getExperienceState,
  (state) => get(state, 'statuses.priceBooks.status'),
);

export const getCloneDialogLoadingState = createSelector(
  getExperienceDefaultValues,
  (state) => isEmpty(state),
);

export const getCloneExperienceDefaultName = createSelector(
  getExperienceDefaultValues,
  (state) => get(state, 'name'),
);

export const getCloneExperienceState = createSelector(
  getExperienceState,
  (state) => get(state, 'entities.clone'),
);

export const getCloneExperienceShouldPoll = createSelector(
  getCloneExperienceState,
  (state) => get(state, 'poll'),
);

export const getCloneExperienceModel = createSelector<RootState, ExperienceState['entities']['clone'], ExperienceState['entities']['clone']['data']>(
  getCloneExperienceState,
  (state) => get(state, 'data'),
);

export const getCloneExperienceError = createSelector(
  getCloneExperienceState,
  (state) => get(state, 'error'),
);

export const getSelectedExperience = createSelector(
  getExperienceState,
  (state) => get(state, 'entities.ui.selectedExperience'),
);

export const getCCOrderOpenState = createSelector(
  getExperienceState,
  (state) => get(state, 'entities.ui.ccOrderOpen'),
);

export const getPaymentMethodsDisplayOrder = createSelector(
  getExperienceState,
  (state) => get(state, 'entities.paymentMethodRules'),
);

export const getIsExportExperienceSettingsModalOpen = createSelector(
  getExperienceState,
  (state) => get(state, 'entities.ui.isExportExperienceSettingsModalOpen'),
);

export const getStatistics = createSelector<
RootState, ExperienceState, ExperienceState['entities']['statistics']
>(
  getExperienceState,
  (state) => get(state, 'entities.statistics'),
);

export const getIsPricingFormatVisible = createSelector(
  getExperienceState,
  (state) => get(state, 'entities.ui.pricingFormatVisible', false),
);
