import React from 'react';
import { createToast } from '../../console/actions';

const content = (error?: Error): JSX.Element => (
  <div>
    <strong>
      {error ? 'Failed to connect to your Facebook Catalog.' : 'Access to Facebook successfully renewed.'}
    </strong>
    <br />
    {error && <span>{error.message}</span>}
  </div>
);

const showAuthenticationToast = (error?: Error) => createToast({
  intent: error ? 'negative' : 'positive',
  content: content(error),
  icon: 'info-circle',
});

export default showAuthenticationToast;
