import { css } from '@emotion/css';

export const item = css`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

export const flag = css`
  display: block;
  height: 16px;
  width: auto;
  margin-right: 4px;
`;

export const address = css`
  flex: auto;
  min-width: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 32px;
  color: #4d5866;
`;

export const truncated = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
