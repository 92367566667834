import ActionTypes from '../constants/ActionTypes';
import { apiInternal } from '../../../utilities/clients';

export default function fetchFailureSummaries() {
  return {
    types: [
      ActionTypes.FETCH_FAILURE_SUMMARIES_REQUEST,
      ActionTypes.FETCH_FAILURE_SUMMARIES_SUCCESS,
      ActionTypes.FETCH_FAILURE_SUMMARIES_FAILURE,
    ],
    callAPI(state) {
      return apiInternal.alertFailureSummaries(state).get();
    },
  };
}
