import React from 'react';

type Props = {
  className?: string;
};

const PayLaterIcon: React.FC<Props> = ({
  className,
}) => (
  <svg width="64" height="48" viewBox="0 0 64 48" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M63.3104 28.7114H53.1544C52.9798 28.7114 52.837 28.8535 52.837 29.027V30.9208C52.837 31.0944 52.9798 31.2364 53.1544 31.2364H63.3104C63.4849 31.2364 63.6277 31.0944 63.6277 30.9208V29.027C63.6277 28.8535 63.4849 28.7114 63.3104 28.7114ZM57.9943 34.0771H53.1544C52.9798 34.0771 52.837 34.2191 52.837 34.3927V36.2864C52.837 36.46 52.9798 36.6021 53.1544 36.6021H57.9943C58.1689 36.6021 58.3117 36.46 58.3117 36.2864V34.3927C58.3117 34.2191 58.1689 34.0771 57.9943 34.0771ZM44.7559 25.1804H43.0381C42.7921 25.1804 42.5938 25.3776 42.5938 25.6222V35.4066C42.5938 35.5487 42.6612 35.6789 42.7762 35.7617L48.6834 40.0463C48.8817 40.1884 49.1594 40.1489 49.3023 39.9517L50.3218 38.5668V38.5629C50.4646 38.3656 50.421 38.0895 50.2226 37.9474L45.1962 34.3335V25.6222C45.2002 25.3776 44.9979 25.1804 44.7559 25.1804Z" fill="white" />
    <path d="M58.013 39.0854H55.7806C55.5643 39.0854 55.3596 39.1982 55.2438 39.385C54.7533 40.1671 54.1816 40.8909 53.525 41.5524C52.3934 42.6925 51.0763 43.5874 49.6125 44.21C48.0946 44.856 46.484 45.1828 44.8232 45.1828C43.1586 45.1828 41.548 44.856 40.034 44.21C38.5701 43.5874 37.2531 42.6925 36.1214 41.5524C34.9898 40.4122 34.1014 39.0854 33.4835 37.6106C32.8423 36.0853 32.5179 34.4627 32.5179 32.7856C32.5179 31.1085 32.8423 29.4897 33.4835 27.9605C34.1014 26.4858 34.9898 25.1589 36.1214 24.0188C37.2531 22.8786 38.5701 21.9837 40.034 21.3611C41.548 20.7152 43.1624 20.3883 44.8232 20.3883C46.4879 20.3883 48.0985 20.7152 49.6125 21.3611C51.0763 21.9837 52.3934 22.8786 53.525 24.0188C54.1816 24.6803 54.7533 25.404 55.2438 26.1861C55.3596 26.3729 55.5643 26.4858 55.7806 26.4858H58.013C58.2795 26.4858 58.4495 26.2056 58.3298 25.9682C55.8115 20.9214 50.7055 17.6411 45.0048 17.575C36.6583 17.4699 29.7254 24.3534 29.71 32.7544C29.6945 41.171 36.4652 48 44.8194 48C50.5935 48 55.7845 44.7081 58.3298 39.6029C58.4495 39.3655 58.2757 39.0854 58.013 39.0854Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M54.1625 0H3.68095C2.70504 0.00111075 1.76942 0.388352 1.07935 1.07677C0.389283 1.76519 0.00111341 2.69856 0 3.67213V37.2459C0.00111341 38.2195 0.389283 39.1529 1.07935 39.8413C1.76942 40.5297 2.70504 40.9169 3.68095 40.918H29.4797C28.9365 39.9231 28.4869 38.87 28.1426 37.7705H3.68095C3.54153 37.7704 3.40786 37.715 3.30927 37.6167C3.21068 37.5183 3.15524 37.385 3.1551 37.2459V13.8631H54.6884V17.8711C55.8428 18.6354 56.9018 19.5318 57.8435 20.5388V12.3195C57.8436 12.3151 57.8439 12.3108 57.8442 12.3064C57.8446 12.3008 57.8451 12.2952 57.8451 12.2893C57.8451 12.284 57.8447 12.2788 57.8443 12.2737C57.8439 12.2687 57.8435 12.2638 57.8435 12.2589V3.67213C57.8424 2.69856 57.4542 1.76519 56.7641 1.07677C56.0741 0.388352 55.1384 0.00111075 54.1625 0ZM3.68095 3.14754H54.1625C54.302 3.14768 54.4356 3.20299 54.5342 3.30134C54.6328 3.39969 54.6882 3.53304 54.6884 3.67213V10.7155H3.1551V3.67213C3.15524 3.53304 3.21068 3.39969 3.30927 3.30134C3.40786 3.20299 3.54153 3.14768 3.68095 3.14754ZM8.87723 32.0636C8.58138 31.7685 8.41517 31.3682 8.41517 30.9508C8.41517 30.5334 8.58138 30.1331 8.87723 29.838C9.17308 29.5429 9.57433 29.3771 9.99272 29.3771H18.4063C18.8247 29.3771 19.226 29.5429 19.5218 29.838C19.8177 30.1331 19.9839 30.5334 19.9839 30.9508C19.9839 31.3682 19.8177 31.7685 19.5218 32.0636C19.226 32.3588 18.8247 32.5246 18.4063 32.5246H9.99272C9.57433 32.5246 9.17308 32.3588 8.87723 32.0636Z" fill="white" />
  </svg>
);

export default PayLaterIcon;
