import { TriangleDown, Search } from '@flowio/react-icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import BemHelper from '@flowio/bem-helper';
import { Menu, MenuItem } from '@flowio/react-menu';
import { Popover } from '@flowio/react-popover';

import head from 'lodash/head';
import noop from 'lodash/noop';
import kebabCase from 'lodash/kebabCase';

import handleEnterKeyboardEvent from '../../utilities/enterKeyPressHandler';
import { colors } from '../../theme/tokens';

if (process.browser) {
  require('./search-with-scope.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('search-with-scope');

export default class SearchWithScope extends Component {
  constructor(props) {
    super(props);
    const { value, defaultScope } = this.props;
    this.state = {
      isOpen: false,
      value,
      scopeValue: defaultScope,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { defaultScope } = this.props;
    if (nextProps.defaultScope !== defaultScope) {
      this.setState({
        scopeValue: nextProps.defaultScope,
      });
    }
  }

  handleItemClick = (event, menuItemDetails) => {
    const { value: scopeValue } = menuItemDetails;
    const { onChange, value } = this.props;
    this.setState({ scopeValue });
    onChange({ scope: scopeValue, value });
    this.close();
  }

  handleInputChange = (event) => {
    const { onChange } = this.props;
    const { scopeValue } = this.state;
    const { value } = event.target;
    this.setState({ value });
    onChange({ scope: scopeValue, value });
    this.close();
  }

  handleMagnifyClick = () => {
    const { onSubmit } = this.props;
    const { scopeValue, value } = this.state;
    onSubmit({ scope: scopeValue, value });
    this.close();
  }

  handleRequestClose = () => {
    this.close();
  }

  handleEscKeyDownMenu = () => {
    this.close();
  }

  handleButtonClick = () => {
    this.open();
  }

  handleKeyDown = (event) => {
    const { onSubmit } = this.props;
    if (event.key === 'Enter') {
      const { scopeValue, value } = this.state;
      onSubmit({ value, scope: scopeValue });
    }
  }

  getLabel() {
    const { scopes } = this.props;
    const { scopeValue } = this.state;
    const scope = scopes.find((s) => s.key === scopeValue);
    const headScope = head(scopes);

    if (scope) {
      return scope.label;
    }

    if (headScope) {
      return headScope.label;
    }

    return '';
  }

  open() {
    this.setState({ isOpen: true });
  }

  close() {
    this.setState({ isOpen: false });
  }

  render() {
    const { className, scopes } = this.props;
    const { isOpen, value } = this.state;

    return (
      <div className={bem.block(className)}>
        <div className={bem.element('scope')} ref={(node) => { this.anchorRef = node; }}>
          <Popover
            open={isOpen}
            trigger={(
              <div
                className={bem.element('scope-button')}
                onClick={this.handleButtonClick}
                onKeyPress={handleEnterKeyboardEvent(this.handleButtonClick)}
                role="button"
                tabIndex={0}
              >
                <span className={bem.element('scope-button-label')}>
                  {this.getLabel()}
                </span>
                <span className={bem.element('down-arrow')}>
                  <TriangleDown />
                </span>
              </div>
            )}
            onClose={this.handleRequestClose}
          >
            <Menu className={bem.element('menu')}>
              {scopes.map((scope) => (
                <MenuItem
                  key={kebabCase([scope.key, scope.label])}
                  className={bem.element(`menu-${scope.key}`)}
                  value={scope.key}
                  onSelect={this.handleItemClick}
                  content={scope.label}
                />
              ))}
            </Menu>
          </Popover>
        </div>
        <input
          ref={(node) => { this.inputRef = node; }}
          className={bem.element('search-text')}
          placeholder="Search Item Numbers"
          type="text"
          value={value}
          onChange={this.handleInputChange}
          onKeyDown={this.handleKeyDown}
        />

        <button
          className={bem.element('magnify-button')}
          type="button"
          value={value}
          onClick={this.handleMagnifyClick}
        >
          <Search height={20} fill={colors.blue[600]} />
        </button>
      </div>
    );
  }
}
SearchWithScope.displayName = 'SearchWithScope';

SearchWithScope.propTypes = {
  scopes: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  defaultScope: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
};

SearchWithScope.defaultProps = {
  value: '',
  className: '',
  defaultScope: undefined,
  onChange: noop,
  onSubmit: noop,
};
