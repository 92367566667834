import { connect } from 'react-redux';
import { RootState, ThunkDispatcher } from '../../../../../stores/types';
import { createToast } from '../../../../console/actions';
import { getDialogProperty } from '../../../selectors';
import RequestChannelsDialog from '../components/RequestChannelsDialog';
import {
  DispatchProps,
  OwnProps,
  StateProps,
} from '../types';
import { DialogName } from '../../../constants';

import { requestChannel, toggleRequestChannelsDialog } from '../../../actions';

const mapStateToProps = (
  state: RootState,
): StateProps => ({
  isOpen: getDialogProperty(DialogName.REQUST_CHANNELS, 'open')(state),
});

const mapDispatchToProps = (
  dispatch: ThunkDispatcher,
  { organizationId }: OwnProps,
): DispatchProps => ({
  onCancel: () => {
    dispatch(toggleRequestChannelsDialog(false));
  },
  onConfirm: (value) => {
    dispatch(requestChannel({
      organizationId,
      channelName: value,
    }))
      .then(() => {
        dispatch(
          createToast({
            content: 'New channel request submitted.',
            intent: 'positive',
            autoCloseDuration: 2000,
          }),
        );
        dispatch(toggleRequestChannelsDialog(false));
      })
      .catch(() => {
        dispatch(
          createToast({
            content: 'An error has occurred, please try again.',
            intent: 'negative',
            autoCloseDuration: 2000,
          }),
        );
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestChannelsDialog);
