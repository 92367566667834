import { css } from '@emotion/css';
import colors from '../../../../../theme/tokens/colors';

export const greyed = css`
  color: ${colors.gray[800]};
`;

export const termsHeader = css`
  font-size: 14px;
  font-weight: 700;
`;

export const payLaterPartners = css`
  padding-top: 6px;
  font-size: 12px;
  color: ${colors.gray[700]};
  text-align: center;
`;

export const payLaterPartnersLogos = css`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const partnerLogo = css`
  mix-blend-mode: multiply;
`;
