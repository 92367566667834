import React from 'react';
import { PageContent } from '@flowio/react-page';

import ApiKeys from '../../containers/api-keys';
import PublicKeys from '../../containers/public-keys';
import WebhookOverview from '../../../webhook/containers/webhook-overview';
import OrganizationId from '../../containers/organization-id';
import { IntegrationsProps } from '../imports/types';

const Integrations: React.FC<IntegrationsProps> = ({
  organization,
}) => (
  <PageContent>
    <OrganizationId />
    <ApiKeys />
    <PublicKeys />
    <WebhookOverview organization={organization} />
  </PageContent>
);

Integrations.displayName = 'Integrations';

export default Integrations;
