import { createSelector } from 'reselect';
import get from 'lodash/get';
import getExclusionRuleDeleteConfirmation from './getExclusionRuleDeleteConfirmation';

const getHasExclusionRuleDeleteSucceeded = createSelector(
  getExclusionRuleDeleteConfirmation,
  (state) => get(state, 'deleteSucceeded'),
);

export default getHasExclusionRuleDeleteSucceeded;
