import { $HttpResponse } from '@flowio/api-sdk';
import { ThunkResult } from '../../../stores/types';
import { ApiError } from '../../../utilities/clients/api-error';
import { getOrganizationId } from '../../organization/selectors';
import ActionType from '../constants/ActionType';

export default function exportExclusions(email: string):
ThunkResult<Promise<io.flow.v0.models.Export>> {
  return function exportExclusionsEffect(
    dispatch,
    getState,
    extra,
  ): Promise<io.flow.v0.models.Export> {
    const state = getState();
    const organizationId: string = getOrganizationId(state);

    return extra.api(getState()).exports.post({
      organization: organizationId,
      body: {
        type: { discriminator: 'exclusion_rules_export_type' },
        emails: [
          email,
        ],
      },
    }).then((response) => {
      dispatch({ type: ActionType.HideExportDialog });
      if (response.ok) {
        return response.body;
      }

      const error = new ApiError(
        response as $HttpResponse<io.flow.v0.models.GenericError>,
      );

      throw error;
    });
  };
}
