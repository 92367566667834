import PropTypes from 'prop-types';
import React from 'react';
import { PageContent } from '@flowio/react-page';

const PolicyLayout = ({ children }) => (
  <PageContent>
    <div>
      {children}
    </div>
  </PageContent>
);

PolicyLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

PolicyLayout.displayName = 'PolicyLayout';

export default PolicyLayout;
