const featureKey = {
  GLOBAL_LOGISTICS_V2: 'global_logistics-v2',
  GLOBAL_PENDING_MEMBERS: 'global_pending_members',
  GLOBAL_SHOPIFY_SYNC_STATUS: 'global_shopify_sync_queue',
  GLOBAL_FAILURE_SUMMARIES: 'global_failure_summaries',
  GLOBAL_CONSOLE_FULFILLMENT: 'global_console_fulfillment',
  GLOBAL_WRAP_ITEM_NAME: 'global_wrap_item_name',
  GLOBAL_SEARCHBOX: 'global_searchbox',
  GLOBAL_PRODUCT_FEEDS: 'global_product_feeds',
  GLOBAL_PRICE_BOOKS: 'global_console_price_books',
  GLOBAL_EXPERIENCE_DUPLICATE: 'global_experience_duplicate',
  GLOBAL_CONSOLE_RETURNS: 'global_console_returns',
  GLOBAL_GOOGLE_SHOPPING_CAMPAIGNS: 'global_google_shopping_campaigns',
  GLOBAL_GIFT_MESSAGING: 'global_checkout_gift_messaging',
  GLOBAL_FLOW_LABS: 'global_labs_enabled',
  GLOBAL_REPLACEMENT_ORDER: 'global_replacement_order',
  GLOBAL_LABEL_ADDRESS_VALIDATION: 'global_label_address_validation',
} as const;

export type FeatureKey = typeof featureKey;

export default featureKey;
