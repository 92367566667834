import React from 'react';
import { Confirm } from '@flowio/react-confirm';

import FeatureDeleteConfirmForm from '../../containers/FeatureDeleteConfirmForm';
import { FeatureDeleteConfirmProps } from '../../types/components';

const FeatureDeleteConfirm: React.FC<FeatureDeleteConfirmProps> = ({
  initialValues,
  featureName,
  open = false,
  onOk,
  onCancel,
  disabled,
  ...unhandledProps
}) => (
  <Confirm
    {...unhandledProps}
    caption="Delete Feature"
    intent="negative"
    open={open}
    onCancel={onCancel}
    onOk={onOk}
    disabled={disabled}
  >
    <FeatureDeleteConfirmForm
      featureName={featureName}
      initialValues={initialValues}
    />
  </Confirm>
);

FeatureDeleteConfirm.displayName = 'FeatureDeleteConfirm';

FeatureDeleteConfirm.defaultProps = {
  initialValues: undefined,
  open: false,
};

export default FeatureDeleteConfirm;
