/* eslint-disable @typescript-eslint/no-var-requires */
import React from 'react';
import ReactDOM from 'react-dom';
import { browserHistory } from 'react-router';
import { getRollbar } from '@flowio/redux-rollbar-middleware/lib/rollbar';

import {
  createTracker,
  createBeaconReporter,
  createErrorsPlugin,
  createEventTimingPlugin,
  createLargestContentfulPaintPlugin,
  createLayoutInstabilityPlugin,
  createNavigationTimingPlugin,
  createNavigatorPlugin,
  createPaintTimingPlugin,
  createUserTimingPlugin,
} from '@flowio/browser-metrics';

import '../common/styles/base.css';
import configureStore from '../common/stores/configure-store';
import matchRoutes from '../common/utilities/react-router/match-routes';
import { mark, measure } from './utilities/performance';

// An example of how we can leverage User Timing API with our RUM library to
// measure part of the application. In this case the time to bootstrap the
// application is being measured.
mark('bootStart');

const reporter = createBeaconReporter({ url: '/_internal_/stats' });
try {
  createTracker({
    reporters: [reporter],
    plugins: [
      createErrorsPlugin(),
      createEventTimingPlugin(),
      createLargestContentfulPaintPlugin(),
      createLayoutInstabilityPlugin(),
      createNavigationTimingPlugin(),
      createNavigatorPlugin(),
      createPaintTimingPlugin(),
      createUserTimingPlugin(),
    ],
  });
} catch (error) {
  // Ignore this error.
}

function getInitialState() {
  try {
    return JSON.parse(document.getElementById('react-prefetch').innerText);
  } catch (error) {
    getRollbar((rollbar, extra) => {
      rollbar.error(error, extra);
    });
    return {};
  }
}

const container = document.getElementById('react-markup');
const initialState = getInitialState();
const store = configureStore(initialState);

function render() {
  // eslint-disable-next-line global-require
  const Root = require('./components/root').default;
  // eslint-disable-next-line global-require
  const routes = require('../common/router/routes').default;

  matchRoutes({ routes, history: browserHistory }).then(({ redirectLocation, renderProps }) => {
    if (redirectLocation) {
      browserHistory.replace(redirectLocation);
    } else {
      ReactDOM.render(
        <Root renderProps={renderProps} store={store} />,
        container,
      );
    }

    mark('bootEnd');
    measure('boot', 'bootStart', 'bootEnd');
  }).catch((error) => {
    throw error;
  });
}

render();

if (module.hot) {
  module.hot.accept([
    './components/root',
    '../common/router/routes',
  ], () => {
    // Workaround to support hot swapping of routes.
    // Follow this thread to understand the reasoning behind it.
    // https://github.com/ReactTraining/react-router/issues/2182
    setTimeout(() => {
      ReactDOM.unmountComponentAtNode(container);
      render();
    });
  });
}
