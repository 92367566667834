import { Field } from 'redux-form';
import ApiPropTypes from '@flowio/api-prop-types';
import { KeyCodes } from '@flowio/browser-helpers';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames/bind';
import curry from 'lodash/curry';
import first from 'lodash/first';
import get from 'lodash/get';
import map from 'lodash/map';
import {
  TextField, TagInput, SelectField, MultiSelectField,
} from '../../../../components/ReduxFormFieldAdapters';
import styles from './QueryFilter.module.css';

const cx = classNames.bind(styles);

const valueToOption = (value) => ({ content: value, value });

const formatValue = curry((isDropdown, isMultiple, value) => {
  let formatted = value;

  // values passed into components should not be arrays when multiple selections
  // are not supported.
  if (formatted && !isMultiple) {
    formatted = first(value);
  }

  // map values to dropdown options
  if (formatted && isDropdown) {
    if (isMultiple) {
      formatted = map(formatted, valueToOption);
    } else {
      formatted = valueToOption(formatted);
    }
  }

  if (formatted == null) formatted = '';

  return formatted;
});

const parseValue = curry((isDropdown, isMultiple, value) => {
  // A non-nullable value, at this point it can be:
  // - An array of options from multi-selection dropdown element
  // - An option from selection dropdown element
  // - An array of string values from chip edit element
  // - A string value from input element
  let formatted = value;

  // Map objects from dropdown option into simple string values
  if (isDropdown) {
    if (isMultiple) {
      formatted = map(formatted, 'value');
    } else {
      formatted = formatted.value;
    }
  }

  // Query values must be arrays
  // If the value is empty we just want an empty array
  if (!isMultiple) {
    if (formatted.length > 0) {
      formatted = [formatted];
    } else {
      formatted = [];
    }
  }

  return formatted;
});

const QueryFilterValues = ({
  availableFilter,
  multiple,
  name,
}) => {
  const validValues = get(availableFilter, 'valid_values');
  const validValueCount = validValues != null ? validValues.length : 0;

  if (validValueCount > 0) {
    if (multiple) {
      return (
        <Field
          component={MultiSelectField}
          name={name}
          format={formatValue(true, multiple)}
          parse={parseValue(true, multiple)}
          className={cx('queryFilterValues')}
          hintText={availableFilter.placeholder}
          options={map(validValues, valueToOption)}
          labelKey="content"
          valueKey="value"
        />
      );
    }

    return (
      <Field
        component={SelectField}
        name={name}
        format={formatValue(true, multiple)}
        parse={parseValue(true, multiple)}
        className={cx('queryFilterValues')}
        hintText={availableFilter.placeholder}
        options={map(validValues, valueToOption)}
        labelKey="content"
        valueKey="value"
      />
    );
  }

  if (multiple) {
    return (
      <Field
        component={TagInput}
        name={name}
        format={formatValue(false, multiple)}
        parse={parseValue(false, multiple)}
        addKeyCodes={[KeyCodes.Enter, KeyCodes.Comma]}
        addOnBlur
        autoComplete="off"
        className={cx('queryFilterValues')}
        hintText={availableFilter.placeholder}
        fluid
        inline
      />
    );
  }

  return (
    <Field
      component={TextField}
      hintText={availableFilter.placeholder}
      name={name}
      format={formatValue(false, multiple)}
      parse={parseValue(false, multiple)}
      autoComplete="off"
    />
  );
};

QueryFilterValues.displayName = 'QueryFilterValues';

QueryFilterValues.propTypes = {
  availableFilter: ApiPropTypes.availableFilterStructured.isRequired,
  name: PropTypes.string.isRequired,
  multiple: PropTypes.bool.isRequired,
};

export default QueryFilterValues;
