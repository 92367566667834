import { css } from '@emotion/css';
import colors from '../../../../../theme/tokens/colors';

export const ok = css`
  margin-left: 20px;
`;

export const noAccountsMsg = css`
  color: ${colors.white};
  background-color: rgba(#f40808, 0.7);
  margin-top: 30px;
  text-align: center;
  line-height: 65px;
  border-radius: 5px;
`;

export const selectionCard = css`
  max-width: 960px;
`;
