import { EventEmitter } from 'events';
import React, { Component } from 'react';
import * as d3 from 'd3';
import max from 'lodash/max';
import noop from 'lodash/noop';
import get from 'lodash/get';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import numeral from 'numeral';

import { css } from '@emotion/css';
import mapData from './data/world.geo.json';

const width = 1150;
const height = 560;

export const selectedStyles = css`
  fill: #4D5866 !important;
`;

interface Props {
  results: io.flow.internal.v0.models.OrderRevenueRegionChart;
  valueKey: string;
  eventListener: EventEmitter;
  organizationCountry: string;
  organizationCurrency: string;
}

interface State {
  selectedCountry: string;
}

class MetricByCountryMap extends Component<Props, State> {
  static displayName = 'MetricByCountryMap';

  static defaultProps = {
    valueKey: 'count',
    eventListener: noop,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      selectedCountry: '',
    };
  }

  componentDidMount(): void {
    const { eventListener, organizationCountry, organizationCurrency } = this.props;
    this.createResultsMap();

    eventListener.on('selectedNewCountry', (country) => {
      const { results, valueKey } = this.props;
      const { selectedCountry } = this.state;
      if (country !== selectedCountry) {
        this.updateSelectedCountry(
          country,
          selectedCountry,
          results,
          valueKey,
          organizationCountry,
          organizationCurrency,
        );
        this.setState({
          selectedCountry: country,
        });
      }
    });
  }

  componentDidUpdate(prevProps: Props): void {
    const {
      results,
      valueKey,
      eventListener,
      organizationCountry,
      organizationCurrency,
    } = this.props;

    if (!isEqual(results, prevProps.results) || valueKey !== prevProps.valueKey) {
      this.updateResultsChart();

      eventListener.on('selectedNewCountry', (country) => {
        const { selectedCountry } = this.state;
        if (country !== selectedCountry) {
          this.updateSelectedCountry(
            country,
            selectedCountry,
            results,
            valueKey,
            organizationCountry,
            organizationCurrency,
          );
          this.setState({
            selectedCountry: country,
          });
        }
      });
    }
  }

  createColorScale = (
    maxPoint: number,
    maxColor: string,
    minColor: string,
  ): d3.ScaleLinear<string, string> => d3.scaleLinear<string>()
    .domain([0, maxPoint])
    .range([minColor, maxColor])
    .interpolate(d3.interpolateRgb);

  mouseEnterCountry = (
    d: d3.ExtendedFeature,
    results: io.flow.internal.v0.models.OrderRevenueRegionChart,
    valueKey: string,
    eventListener: EventEmitter,
    organizationCountry: string,
    organizationCurrency: string,
  ): void => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const countryCode = d.properties!.iso_a3;
    const dataPoint = find(results.data, (res) => res.region.id.toUpperCase() === countryCode);
    const label = valueKey === 'count' ? 'Order Count' : 'Submitted Order Value';

    if (!dataPoint) {
      return;
    }

    const formattedMetric = valueKey === 'count'
      ? numeral(dataPoint.count).format('0.0a')
      : new Intl.NumberFormat(organizationCountry, {
        style: 'currency',
        currency: organizationCurrency,
        notation: 'compact',
        maximumSignificantDigits: 4,
      }).format(dataPoint.revenue.amount);

    d3.selectAll('.country-name')
      .text(dataPoint.region.name);

    d3.selectAll('.country-metric')
      .text(label);

    d3.selectAll('.country-metric-value')
      .text(formattedMetric);

    d3.selectAll('.tooltip-container')
      .attr('opacity', 1);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    d3.selectAll(`.${d.properties!.iso_a3}-country`)
      .classed(selectedStyles, true);

    this.setState({
      selectedCountry: countryCode,
    });

    eventListener.emit('selectedNewCountry', countryCode);
  };

  updateSelectedCountry = (
    selectedCountry: string,
    previousCountry: string,
    results: io.flow.internal.v0.models.OrderRevenueRegionChart,
    valueKey: string,
    organizationCountry: string,
    organizationCurrency: string,
  ): void => {
    const label = valueKey === 'count' ? 'Order Count' : 'Submitted Order Value';

    if (previousCountry) {
      const oldDataPoint = find(results.data,
        (res) => res.region.id.toUpperCase() === previousCountry.toUpperCase());

      if (oldDataPoint) {
        d3.selectAll(`.${previousCountry.toUpperCase()}-country`)
          .classed(selectedStyles, false);
      }
    }

    const dataPoint = find(results.data,
      (res) => res.region.id.toUpperCase() === selectedCountry.toUpperCase());

    const formattedValue = valueKey === 'count'
      ? numeral(dataPoint?.count || 0).format('0.0a')
      : new Intl.NumberFormat(organizationCountry, {
        style: 'currency',
        currency: organizationCurrency,
        notation: 'compact',
        maximumSignificantDigits: 4,
      }).format(dataPoint?.revenue.amount || 0);

    if (dataPoint) {
      d3.selectAll(`.${selectedCountry.toUpperCase()}-country`)
        .classed(selectedStyles, true);

      d3.selectAll('.country-name')
        .text(dataPoint.region.name);

      d3.selectAll('.country-metric')
        .text(label);

      d3.selectAll('.country-metric-value')
        .text(formattedValue);

      d3.selectAll('.tooltip-container')
        .attr('opacity', 1);
    } else {
      d3.selectAll('.tooltip-container')
        .attr('opacity', 0);
    }
  };

  mouseOutCountry = (d: d3.ExtendedFeature, eventListener: EventEmitter): void => {
    d3.selectAll('.tooltip-container')
      .attr('opacity', 0);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    d3.selectAll(`.${d.properties!.iso_a3}-country`)
      .classed(selectedStyles, false);

    this.setState({
      selectedCountry: '',
    });

    eventListener.emit('selectedNewCountry', '');
  };

  updateResultsChart(): void {
    const {
      results,
      valueKey,
      eventListener,
      organizationCountry,
      organizationCurrency,
    } = this.props;
    const currencyFormatter = new Intl.NumberFormat(organizationCountry, {
      style: 'currency',
      currency: organizationCurrency,
      notation: 'compact',
      maximumSignificantDigits: 4,
    });
    const minValue = valueKey === 'count' ? 0 : currencyFormatter.format(0);
    const maxColor = valueKey === 'count' ? '#128A00' : '#35C2F5';
    const minColor = valueKey === 'count' ? '#E1FEDD' : '#E6F7FE';
    const maxPoint = max(results.data.map((d) => get(d, valueKey)));
    const colorScale = this.createColorScale(maxPoint, maxColor, minColor);
    const maxValue = valueKey === 'count' ? numeral(maxPoint).format('0.0a') : currencyFormatter.format(maxPoint);

    d3.select('#legendGradient').remove();

    const gradient = d3.select('.country-map')
      .append('defs')
      .append('linearGradient')
      .attr('id', 'legendGradient')
      .attr('x1', '0%')
      .attr('x2', '0%')
      .attr('y1', '0%')
      .attr('y2', '100%');

    gradient.append('stop')
      .attr('offset', '0%')
      .attr('stop-color', maxColor)
      .attr('stop-opacity', 1);

    gradient.append('stop')
      .attr('offset', '100%')
      .attr('stop-color', '#FFF')
      .attr('stop-opacity', 1);

    d3.select('.legend-start')
      .text(minValue);

    d3.select('.legend-end')
      .text(maxValue);

    d3.selectAll('.legend-color')
      .attr('fill', 'url(#legendGradient)');

    d3.selectAll<SVGPathElement, d3.ExtendedFeature>('.country-path')
      .on('mouseenter', (d) => this.mouseEnterCountry(d, results, valueKey, eventListener, organizationCountry, organizationCurrency))
      .on('mouseleave', (d) => this.mouseOutCountry(d, eventListener))
      .transition()
      .duration(250)
      .style('fill', (d) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const countryCode = d.properties!.iso_a3;
        const dataPoint = find(results.data, (res) => res.region.id.toUpperCase() === countryCode);
        const dataValue = get(dataPoint, valueKey, 0);
        const colorValue = colorScale(dataValue);
        return dataValue === 0 || !colorValue ? '#FFF' : colorValue;
      });
  }

  createResultsMap(): void {
    const {
      results,
      valueKey,
      eventListener,
      organizationCountry,
      organizationCurrency,
    } = this.props;
    const currencyFormatter = new Intl.NumberFormat(organizationCountry, {
      style: 'currency',
      currency: organizationCurrency,
      notation: 'compact',
      maximumSignificantDigits: 4,
    });

    const maxPoint = max(results.data.map((d) => get(d, valueKey)));

    const maxValue = valueKey === 'count' ? numeral(maxPoint).format('0.0a') : currencyFormatter.format(maxPoint);

    const minValue = valueKey === 'count' ? 0 : currencyFormatter.format(0);

    const colorScale = this.createColorScale(maxPoint, '#35C2F5', '#E6F7FE');

    const projection = d3.geoMercator()
      .fitExtent(
        [[0, 0], [1182, 560]],
        mapData as d3.ExtendedFeatureCollection,
      );

    const path = d3.geoPath<d3.ExtendedFeature<d3.GeoGeometryObjects>>()
      .projection(projection);

    const svg = d3.select('#metricByCountryMap')
      .append('svg')
      .attr('class', 'country-map')
      .attr('width', width)
      .attr('height', height)
      .style('background-color', '#E5E8EC');

    const gradient = svg.append('defs')
      .append('linearGradient')
      .attr('id', 'legendGradient')
      .attr('x1', '0%')
      .attr('x2', '0%')
      .attr('y1', '0%')
      .attr('y2', '100%');

    gradient.append('stop')
      .attr('offset', '0%')
      .attr('stop-color', '#35C2F5')
      .attr('stop-opacity', 1);

    gradient.append('stop')
      .attr('offset', '100%')
      .attr('stop-color', '#FFF')
      .attr('stop-opacity', 1);

    svg.selectAll('path')
      .data<d3.ExtendedFeature<d3.GeoGeometryObjects>>(mapData.features)
      .enter()
      .append('path')
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      .attr('class', (d) => `${d.properties!.iso_a3}-country country-path`)
      .attr('d', path)
      .style('stroke', '#BDC6D1')
      .style('fill', (d) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const countryCode = d.properties!.iso_a3;
        const dataPoint = find(results.data, (res) => res.region.id.toUpperCase() === countryCode);
        const dataValue = get(dataPoint, valueKey, 0);
        const colorValue = colorScale(dataValue);
        return dataValue === 0 || !colorValue ? '#FFF' : colorValue;
      })
      .on('mouseenter', (d) => this.mouseEnterCountry(d, results, valueKey, eventListener, organizationCountry, organizationCurrency))
      .on('mouseleave', (d) => this.mouseOutCountry(d, eventListener))
      .style('stroke-width', '1');

    d3.selectAll('.country-map')
      .append('g')
      .attr('class', 'legend-container')
      .attr('transform', 'translate(1125, 100)');

    d3.selectAll('.legend-container')
      .append('rect')
      .attr('class', 'legend-color')
      .attr('width', 20)
      .attr('height', 350)
      .attr('fill', 'url(#legendGradient)');

    d3.select('.legend-container')
      .append('text')
      .attr('class', 'legend-start')
      .text(minValue)
      .attr('font-size', '12')
      .attr('fill', '#000')
      .attr('text-anchor', 'end')
      .attr('transform', 'translate(-5, 350)');

    d3.select('.legend-container')
      .append('text')
      .attr('class', 'legend-end')
      .text(maxValue)
      .attr('font-size', '12')
      .attr('fill', '#000')
      .attr('text-anchor', 'end')
      .attr('transform', 'translate(-5, 7)');

    d3.selectAll('.country-map')
      .append('g')
      .attr('class', 'tooltip-container')
      .attr('opacity', 0)
      .append('rect')
      .attr('class', 'tooltip-background')
      .attr('width', '175')
      .attr('height', '35')
      .attr('fill', '#4D5866')
      .attr('rx', 4)
      .attr('ry', 4)
      .attr('transform', 'translate(20, 500)');

    d3.selectAll('.tooltip-container')
      .append('text')
      .attr('class', 'country-name')
      .attr('font-size', '11')
      .attr('font-weight', 'bold')
      .attr('transform', 'translate(27, 513)')
      .attr('fill', '#FFF');

    d3.selectAll('.tooltip-container')
      .append('text')
      .attr('class', 'country-metric')
      .attr('font-size', '11')
      .attr('transform', 'translate(27, 528)')
      .attr('fill', '#FFF');

    d3.selectAll('.tooltip-container')
      .append('text')
      .attr('class', 'country-metric-value')
      .attr('font-size', '11')
      .attr('text-anchor', 'end')
      .attr('transform', 'translate(187, 528)')
      .attr('fill', '#FFF');
  }

  render() {
    return (
      <div id="metricByCountryMap" />
    );
  }
}

export default MetricByCountryMap;
