import { Ellipsis } from '@flowio/react-icons';
import { Link } from 'react-router';
import { ShippingConfigurationType } from '@flowio/api-constants';
import { Button, IconButton } from '@flowio/react-button';
import {
  Card, CardHeader, CardContent, CardFooter, CardTitle,
} from '@flowio/react-card';
import { Collapse } from '@flowio/react-collapse';
import {
  Dropdown, DropdownMenu, DropdownItem, DropdownDivider,
} from '@flowio/react-dropdown';
import React from 'react';
import { Stack } from '@flowio/react-stack';
import { Text } from '@flowio/react-text';
import get from 'lodash/get';
import size from 'lodash/size';

import { Description, DescriptionGroup } from '../../../../components/description';
import pluralize from '../../../../utilities/strings/pluralize';
import { ShippingConfigurationOverviewCardProps, ShippingConfigurationOverviewCardState } from '../../types/components';
import * as styles from './ShippingConfigurationOverviewCard.styles';

class ShippingConfigurationOverviewCard extends React.PureComponent<
ShippingConfigurationOverviewCardProps,
ShippingConfigurationOverviewCardState
> {
  static displayName = 'ShippingConfigurationOverviewCard';

  constructor(props: ShippingConfigurationOverviewCardProps, context: any) {
    super(props, context);

    this.handleDeleteItemSelection = this.handleDeleteItemSelection.bind(this);
    this.handleDuplicateItemSelection = this.handleDuplicateItemSelection.bind(this);
    this.handleRenameItemSelection = this.handleRenameItemSelection.bind(this);
    this.handleToggleExperienceList = this.handleToggleExperienceList.bind(this);

    this.state = {
      isExperienceListExpanded: false,
    };
  }

  handleDeleteItemSelection() {
    const {
      experiences,
      onDeleteShippingConfiguration,
      organizationId,
      shippingConfiguration,
    } = this.props;

    onDeleteShippingConfiguration(organizationId, shippingConfiguration, experiences);
  }

  handleDuplicateItemSelection() {
    const {
      onDuplicateShippingConfiguration,
      organizationId,
      shippingConfiguration,
    } = this.props;

    onDuplicateShippingConfiguration(organizationId, shippingConfiguration);
  }

  handleRenameItemSelection() {
    const {
      onEditShippingConfiguration,
      organizationId,
      shippingConfiguration,
    } = this.props;

    onEditShippingConfiguration(organizationId, shippingConfiguration);
  }

  handleToggleExperienceList() {
    const {
      isExperienceListExpanded,
    } = this.state;

    this.setState({
      isExperienceListExpanded: !isExperienceListExpanded,
    });
  }

  render() {
    const {
      experiences,
      organizationId,
      shippingConfiguration,
    } = this.props;

    const {
      isExperienceListExpanded,
    } = this.state;

    const experienceCount = size(experiences);
    const shippingLanes = get(shippingConfiguration, 'shipping_lanes');
    const shippingLaneCount = size(shippingLanes);
    const shippingConfigurationType = get(shippingConfiguration, 'type');

    return (
      <Card>
        <CardHeader dividing>
          <Stack alignment="center" distribution="equalSpacing">
            <CardTitle content="Shipping Configuration Overview" />
            <Dropdown trigger={(<IconButton icon={Ellipsis} size="small" />)}>
              <DropdownMenu>
                <DropdownItem content="Duplicate" onClick={this.handleDuplicateItemSelection} />
                <DropdownItem content="Rename" onClick={this.handleRenameItemSelection} />
                {shippingConfigurationType !== ShippingConfigurationType.DEFAULT && (
                <DropdownDivider />
                )}
                {shippingConfigurationType !== ShippingConfigurationType.DEFAULT && (
                <DropdownItem content="Delete" onClick={this.handleDeleteItemSelection} />
                )}
              </DropdownMenu>
            </Dropdown>
          </Stack>
        </CardHeader>
        <CardContent>
          <DescriptionGroup className="" spacing={false}>
            <Description term="Name" definition={get(shippingConfiguration, 'name')} />
            <Description term="Type" definition={get(shippingConfiguration, 'type')} />
            <Description term={pluralize(shippingLaneCount, 'Shipping Lane')} definition={shippingLaneCount.toString()} />
            <Description>
              <Description.Term content="Active Experiences" />
              <Description.Definition>
                <Stack distribution="equalSpacing">
                  <span>
                    {experienceCount >= 100 ? '99+' : experienceCount}
                  </span>
                  {(experienceCount > 0) && (
                    <Button
                      content={isExperienceListExpanded ? 'Hide Detail' : 'Show Detail'}
                      variant="flat"
                      onClick={this.handleToggleExperienceList}
                      size="small"
                    />
                  )}
                </Stack>
              </Description.Definition>
            </Description>
            {(experienceCount > 0) && (
              <Collapse expanded={isExperienceListExpanded}>
                <ul className={styles.experienceList}>
                  {experiences.map((experience) => (
                    <li className={styles.experienceListItem} key={experience.id}>
                      <Link to={`/${organizationId}/experience/${experience.key}/logistics/v2`}>
                        <Text className={styles.experienceName}>{experience.name}</Text>
                      </Link>
                    </li>
                  ))}
                </ul>
              </Collapse>
            )}
          </DescriptionGroup>
        </CardContent>
        <CardFooter />
      </Card>
    );
  }
}

export default ShippingConfigurationOverviewCard;
