import { Field } from 'redux-form';
import React from 'react';
import find from 'lodash/find';
import property from 'lodash/property';
import { DeliveredDuty } from '@flowio/api-constants';
import { InfoCircle } from '@flowio/react-icons';
import { Tooltip } from '@flowio/react-tooltip';

import { css } from '@emotion/react';
import { DeliveredDutyFormOption } from '../types';
import { SelectField } from '../../../../../components/ReduxFormFieldAdapters';

const infoCircleStyle = css`
  margin-left: 8px;
`;

const tooltipStyle = css`
  width: 200px !important;
  color: white !important;
`;

const options: DeliveredDutyFormOption<DeliveredDuty>[] = [
  { displayBy: 'Paid', identifyBy: DeliveredDuty.PAID },
  { displayBy: 'Unpaid', identifyBy: DeliveredDuty.UNPAID },
];

const handleFormat = (
  value: string,
): DeliveredDutyFormOption | undefined => find(options, { identifyBy: value as DeliveredDuty });
const handleParse = property<DeliveredDutyFormOption, string>('identifyBy');

interface Props {
  hasWarningMessage: boolean;
}

const DeliveredDutySelection: React.FC<Props> = ({
  hasWarningMessage,
}) => (
  <Field
    component={SelectField}
    disabled={hasWarningMessage}
    name="default"
    format={handleFormat}
    parse={handleParse}
    fluid
    hintText="Select Default Selection"
    id="default"
    inline
    labelCols={5}
    labelFor="default"
    labelIcon={(
      <Tooltip
        openOnHover
        css={tooltipStyle}
        trigger={<InfoCircle width={16} css={infoCircleStyle} />}
      >
        Choose which duty method to be default selected.
      </Tooltip>
    )}
    labelKey="displayBy"
    labelText="Default Selection"
    options={options}
    valueKey="identifyBy"
    gutter
  />
);

DeliveredDutySelection.displayName = 'DeliveredDutySelection';

export default DeliveredDutySelection;
