import {
  Card, CardHeader, CardContent, CardFooter, CardDescription, CardTitle,
} from '@flowio/react-card';
import { Box } from '@flowio/react-box';
import React from 'react';

import { toExperienceFormValues } from '../../utilities/converters';
import FormName from '../../constants/FormName';
import ExperienceForm from '../ExperienceForm';
import SaveChanges from '../../../../components/SaveChanges';

interface Props {
  countries: io.flow.v0.models.Country[];
  currencies: io.flow.v0.models.Currency[];
  experience: io.flow.v0.models.Experience;
  languages: io.flow.v0.models.Language[];
  organizationId: string;
  regions: io.flow.v0.models.Region[];
}

const UpdateExperienceForm: React.FC<Props> = ({
  countries,
  currencies,
  experience,
  languages,
  organizationId,
  regions,
}) => (
  <Card>
    <CardHeader dividing>
      <Box alignItems="center" justifyContent="between">
        <CardTitle content="Experience Settings" />
        <SaveChanges form={FormName.UPDATE_EXPERIENCE} />
      </Box>
      <CardDescription content="Tune this experience to your preferences" />
    </CardHeader>
    <CardContent>
      <ExperienceForm
        countries={countries}
        currencies={currencies}
        form={FormName.UPDATE_EXPERIENCE}
        initialValues={toExperienceFormValues(organizationId, experience)}
        languages={languages}
        regions={regions}
      />
    </CardContent>
    <CardFooter />
  </Card>
);

UpdateExperienceForm.displayName = 'UpdateExperienceForm';

export default UpdateExperienceForm;
