import { Pencil, Ellipsis } from '@flowio/react-icons';
import { Box } from '@flowio/react-box';
import { Button, IconButton } from '@flowio/react-button';

import Markdown from 'react-markdown';
import React from 'react';
import {
  TableRow,
  TableRowColumn,
} from '@flowio/react-table';
import find from 'lodash/find';
import get from 'lodash/get';
import capitalize from 'lodash/capitalize';

import { Dropdown, DropdownItem, DropdownMenu } from '@flowio/react-dropdown';
import OptinContentCheckboxSetting from '../../OptinContentCheckboxSetting';
import PromptTarget from '../../../constants/PromptTarget';
import RegionLabel from '../../../../reference/components/RegionLabel';
import { OptinContentItemProps } from '../types';

const OptinContentItem: React.FC<OptinContentItemProps> = ({
  onDelete,
  optinPrompt,
  organizationId,
  onEdit,
  optinAttribute,
  languages,
  regions,
}) => {
  const handleDeleteSelection = (): void => {
    onDelete(organizationId, optinPrompt);
  };

  const handleEditButtonClick = (): void => {
    onEdit(organizationId, optinAttribute, optinPrompt);
  };

  const optinPromptRegionId = get(optinPrompt, 'region');
  const region = find(regions, { id: optinPromptRegionId });
  const language = find(languages, { iso_639_2: get(optinPrompt, 'content.locale') });

  return (
    <TableRow>
      <TableRowColumn>
        {region ? (
          <RegionLabel region={region} />
        ) : (optinPromptRegionId)}
      </TableRowColumn>
      <TableRowColumn>
        {get(language, 'name')}
      </TableRowColumn>
      {(optinAttribute.target === PromptTarget.CHECKOUT) && (
        <TableRowColumn>
          <OptinContentCheckboxSetting optinPrompt={optinPrompt} />
        </TableRowColumn>
      )}
      {(optinAttribute.target === PromptTarget.CHECKOUT) && (
        <TableRowColumn>
          {capitalize(get(optinPrompt, 'display.display_position', 'submission'))}
        </TableRowColumn>
      )}
      {(optinAttribute.target === PromptTarget.BROWSE) && (
        <TableRowColumn>
          <div>
            {get(optinPrompt, 'content.elements.accept.value')}
          </div>
          <div>
            {get(optinPrompt, 'content.elements.reject.value')}
          </div>
          <div>
            {get(optinPrompt, 'content.elements.dismiss.value')}
          </div>
        </TableRowColumn>
      )}
      <TableRowColumn>
        <Markdown
          linkTarget="_blank"
        >
          {get(optinPrompt, 'content.elements.text.value')}
        </Markdown>
      </TableRowColumn>
      {(optinAttribute.target === PromptTarget.CHECKOUT) && (
        <TableRowColumn>
          {get(optinPrompt, 'content.elements.error_consent_missing.value')}
        </TableRowColumn>
      )}
      <TableRowColumn>
        <Box flexAuto justifyContent="end" spacing="loose">
          <Button
            content="Edit"
            leftIcon={Pencil}
            onClick={handleEditButtonClick}
          />
          <Dropdown
            trigger={<IconButton size="small" icon={Ellipsis} />}
          >
            <DropdownMenu>
              <DropdownItem
                content="Delete"
                onSelect={handleDeleteSelection}
              />
            </DropdownMenu>
          </Dropdown>
        </Box>
      </TableRowColumn>
    </TableRow>
  );
};

OptinContentItem.displayName = 'OptinContentItem';

export default OptinContentItem;
