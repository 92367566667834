import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withFetch } from '@flowio/redux-fetch';
import {
  fetchPendingUsers,
  declinePendingUser,
  approvePendingUser,
} from '../actions';

import { createToast } from '../../console/actions';
import getPendingUsers from '../selectors';
import PendingUsers from '../components/pending-users-home';

function getAsyncState(dispatch) {
  return dispatch(fetchPendingUsers());
}

const handleApprovePendingUser = (user) => (dispatch) => {
  dispatch(approvePendingUser(user.id)).then((response) => {
    if (!response.ok) {
      dispatch(createToast({
        intent: 'negative',
        content: `Request from "${user.email}" has returned an error!.`,
        icon: 'Alert',
      }));
    } else {
      dispatch(createToast({
        intent: 'positive',
        content: `Request from "${user.email}" has been approved.`,
        icon: 'CheckCircle',
      }));
    }
  });
};

const handleDeclinePendingUser = (user) => (dispatch) => {
  dispatch(declinePendingUser(user.id)).then((response) => {
    if (!response.ok) {
      dispatch(createToast({
        intent: 'negative',
        content: `Request from "${user.email}" has returned an error!.`,
        icon: 'Alert',
      }));
    } else {
      dispatch(createToast({
        intent: 'neutral',
        content: `Request from "${user.email}" has been declined.`,
        icon: 'InfoCircle',
      }));
    }
  });
};

const mapStateToProps = createStructuredSelector({
  users: getPendingUsers,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  onRequestApproveUser: handleApprovePendingUser,
  onRequestDeclineUser: handleDeclinePendingUser,
}, dispatch);

export default compose(
  withFetch(getAsyncState),
  connect(mapStateToProps, mapDispatchToProps),
)(PendingUsers);
