import React, { FunctionComponent } from 'react';
import BemHelper from '@flowio/bem-helper';
import { PageHeaderProps } from '../../types/props';

if (process.browser) {
  require('./page-header.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('page-header');

// export default function PageHeader(props) {
const PageHeader: FunctionComponent<PageHeaderProps> = ({
  children,
  className,
  ...otherProps
}) => (
  <div {...otherProps} className={bem.block(className)}>
    <div className={bem.element('container')}>
      {children}
    </div>
  </div>
);
PageHeader.displayName = 'PageHeader';

PageHeader.defaultProps = {
  className: undefined,
};

export default PageHeader;
