import { PlainRoute } from 'react-router';

export function stripLeadingSlash(path?: string): string {
  if (!path) {
    return '';
  }

  return path.startsWith('/') ? path.substring(1) : path;
}

export function interpolateHref(href: string, parameters: any): string {
  return href.split('/').map((k) => ((k.startsWith(':')) ? (parameters[k.slice(1)] || '') : k)).join('/');
}

/**
 * buildHref builds a path from the paths of previous routes given a route and an index

 * @param  {Object} route  A React Router v3 route
 * @param  {Number} index  The index of the route in React Router
 * @param  {Object} routes Routes from React Router
 * @return {String}        A string path to the current route compiled from all previous routes.
 */
export function buildHref(route: any, index: number, routes: PlainRoute[]): string {
  const sub = routes.slice(0, index);

  if (!sub.length) {
    return route.path || '';
  }

  const paths = sub.map((r) => r.path).map(stripLeadingSlash);
  const routePath = stripLeadingSlash(route.path);

  paths.push(routePath);

  return paths.join('/');
}

export default function generateHrefOnRoutes(routes: any[], params: any): any[] {
  return routes.filter((r) => (typeof r.path !== 'undefined')).map((route, index) => ({
    href: interpolateHref(buildHref(route, index, routes), params),
    ...route,
  }));
}
