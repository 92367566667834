import { css } from '@emotion/css';

export const pageContent = css`
  height: 85vh;
`;

export const activityListContainer = css`
  float: right;
  margin-left: 30px !important;
  width: 27%;
  height: 100%;
`;

export const order = css`
  width: 73%;
  height: 100%;
`;

export const fullHeightBox = css`
  height: 100%;
`;

export const orderWrapper = css`
  height: 89%;
`;

export const heading = css`
  /* to overide style of Heading component !important is needed */
  padding-bottom: 20px !important;
  font-weight: 400 !important;
  font-size: 24px !important;
  line-height: 26px !important;
  color: #043c80 !important;
`;
