import { Image } from '@flowio/react-image';
import React from 'react';

type Props = {
  region: io.flow.v0.models.Region;
  className?: string;
};

const RegionFlag: React.FC<Props> = ({
  region,
  ...unhandledProps
}) => {
  const isMultiCountry = region.countries.length > 1;
  const fileName = isMultiCountry ? region.id : region.countries[0];
  const accessibilityLabel = region.name;
  const source = `https://flowcdn.io/util/icons/flags-v2/svg/${fileName}.svg`;
  return (<Image {...unhandledProps} accessibilityLabel={accessibilityLabel} source={source} />);
};

RegionFlag.displayName = 'RegionFlag';

export default RegionFlag;
