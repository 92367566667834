import {
  Check, PaperPlane, Edit, Ellipsis,
} from '@flowio/react-icons';
import React from 'react';
import noop from 'lodash/noop';
import isUndefined from 'lodash/isUndefined';
import classNames from 'classnames';

import {
  Card, CardHeader, CardContent, CardFooter, CardTitle,
} from '@flowio/react-card';
import { Popover } from '@flowio/react-popover';
import { Box } from '@flowio/react-box';
import { Menu, MenuItem } from '@flowio/react-menu';
import { IconButton, OutlineButton } from '@flowio/react-button';

import StatusLabel from '../../../../components/status-label';
import FormattedDate from '../../../../components/formatted-date';
import FeatureStatus from '../../constants/feature-status';
import featureStatusIntent from '../../constants/feature-status-intent';
import * as styles from './FeatureDetailProfileCard.styles';
import { FeatureDetailProfileCardProps } from '../../types/components';

class FeatureDetailProfileCard extends React.PureComponent<FeatureDetailProfileCardProps> {
  static displayName = 'FeatureDetailProfileCard';

  static defaultProps = {
    isControlOrganization: false,
    onDeleteFeature: noop,
    onEditFeature: noop,
    onReleaseFeature: noop,
    requestStatusChange: noop,
  };

  constructor(props: FeatureDetailProfileCardProps, context: any) {
    super(props, context);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleReleaseClick = this.handleReleaseClick.bind(this);
  }

  handleChangeStatus() {
    const {
      requestStatusChange = noop,
      organizationId,
      feature,
    } = this.props;
    requestStatusChange(organizationId, feature.key, feature.status);
  }

  handleDeleteClick() {
    const {
      onDeleteFeature = noop,
      organizationId,
      feature,
    } = this.props;
    onDeleteFeature(organizationId, feature);
  }

  handleEditClick() {
    const {
      onEditFeature = noop,
      organizationId,
      feature,
    } = this.props;
    onEditFeature(organizationId, feature);
  }

  handleReleaseClick() {
    const {
      onReleaseFeature = noop,
      organizationId,
      feature,
    } = this.props;
    onReleaseFeature(organizationId, feature);
  }

  render() {
    const {
      feature,
      isControlOrganization,
    } = this.props;
    const {
      name,
      key,
      status,
      scope,
      type,
      released_at: released,
      description,
    } = feature;

    return (
      <Card>
        <CardHeader dividing>
          <Box alignItems="center" justifyContent="between">
            <CardTitle>Profile</CardTitle>
            <Box spacing="loose">
              {status === FeatureStatus.DRAFT && (
                <OutlineButton
                  content="Activate"
                  intent="primary"
                  leftIcon={Check}
                  onClick={this.handleChangeStatus}
                />
              )}
              {isControlOrganization
              && status === FeatureStatus.ACTIVE
              && isUndefined(released)
              && (
                <OutlineButton
                  content="Release"
                  intent="primary"
                  leftIcon={PaperPlane}
                  onClick={this.handleReleaseClick}
                />
              )}
              <OutlineButton
                content="Edit"
                leftIcon={Edit}
                onClick={this.handleEditClick}
              />
              <Popover
                openOnClick
                propagateClickEvent={false}
                trigger={<IconButton size="medium" icon={Ellipsis} />}
              >
                <Menu>
                  {status === FeatureStatus.DRAFT && (
                    <MenuItem content="Delete" onClick={this.handleDeleteClick} />
                  )}
                  {status === FeatureStatus.ACTIVE && (
                    <MenuItem content="Archive" onClick={this.handleChangeStatus} />
                  )}
                  {status === FeatureStatus.ARCHIVED && (
                    <MenuItem content="Unarchive" onClick={this.handleChangeStatus} />
                  )}
                </Menu>
              </Popover>
            </Box>
          </Box>
        </CardHeader>
        <CardContent>
          <Box alignContent="center" className={styles.row}>
            <div className={styles.field}>
              <span className={styles.label}>Name</span>
              <span className={styles.value}>
                {name}
              </span>
            </div>
            <div className={styles.field}>
              <span className={styles.label}>Key</span>
              <span className={styles.value}>
                {key}
              </span>
            </div>
          </Box>
          <Box alignContent="center" className={styles.row}>
            <div className={styles.field}>
              <span className={styles.label}>Status</span>
              <span className={styles.value}>
                <StatusLabel content={status} intent={featureStatusIntent[status]} />
              </span>
            </div>
            <div className={styles.field}>
              <span className={styles.label}>Scope</span>
              <span className={classNames(styles.value, styles.capitalize)}>
                {scope}
              </span>
            </div>
          </Box>
          <Box alignContent="center" className={styles.row}>
            <div className={styles.field}>
              <span className={styles.label}>Type</span>
              <span className={classNames(styles.value, styles.capitalize)}>
                {type}
              </span>
            </div>
            {isControlOrganization && (
              <div className={styles.field}>
                <span className={styles.label}>Released</span>
                <span className={styles.value}>
                  {isUndefined(released)
                    ? (<span className={styles.unavailableValue}>Not released</span>)
                    : (<FormattedDate value={released} format="MMM D, YYYY h:mm A" />)}
                </span>
              </div>
            )}
          </Box>
          <Box alignContent="center" className={styles.row}>
            <div>
              <span className={styles.label}>Description</span>
              <span className={styles.value}>
                {description}
              </span>
            </div>
          </Box>
        </CardContent>
        <CardFooter />
      </Card>
    );
  }
}

export default FeatureDetailProfileCard;
