import React, { FunctionComponent } from 'react';
import { OutlineButton } from '@flowio/react-button';
import BemHelper from '@flowio/bem-helper';
import isError from 'lodash/isError';
import { InternalServerErrorProps } from '../../types/props';

if (process.browser) {
  require('./internal-server-error.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('internal-server-error');

const isProduction = process.env.NODE_ENV === 'production';

const InternalServerError: FunctionComponent<InternalServerErrorProps> = ({
  className,
  error,
  showLink,
  ...otherProps
}) => (
  <article {...otherProps} className={bem.block(className)}>
    <h1 className={bem.element('title')}>
      500
    </h1>
    <p className={bem.element('subtitle')}>
      Internal Server Error
    </p>
    {!isProduction && isError(error) && (
      <div className={bem.element('error')}>
        <div className={bem.element('message')}>
          {error.name}
          :
          {error.message}
        </div>
        <div className={bem.element('stack')}>
          {error.stack}
        </div>
      </div>
    )}
    {showLink && (
      <a href="/">
        <OutlineButton
          intent="primary"
          content="Go to Console"
        />
      </a>
    )}
  </article>
);

InternalServerError.displayName = 'InternalServerError';

InternalServerError.defaultProps = {
  className: undefined,
  error: undefined,
  showLink: true,
};

export default InternalServerError;
