import { css } from '@emotion/react';
import { capitalize } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Banner, BannerText } from '@flowio/react-banner';
import { Box } from '@flowio/react-box';
import { IconButton } from '@flowio/react-button';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from '@flowio/react-card';
import {
  Alert,
  Close,
  Dash,
  InfoCircle,
  Plus,
} from '@flowio/react-icons';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from '@flowio/react-select';
import { Stack } from '@flowio/react-stack';
import {
  Table,
  TableBody,
  TableHeadColumn,
  TableRow,
  TableRowColumn,
} from '@flowio/react-table';
import QueryTargetField, { DataSource, QueryTargetFieldType } from '../../../../components/query-target-field';
import { ThunkDispatcher } from '../../../../stores/types';
import { fetchCurrencies, getCurrencies } from '../../../reference';
import {
  addReplacementOrderLineItem,
  fetchReplacementOrderBuilder,
  removeReplacementOrderLineItem,
  updateReplacementOrderLineItemQuantity,
  updateReplacementOrderSelections,
} from '../../actions/replacementOrder';
import { AutoSaveState } from '../../types';
import {
  deliveryOptionStyles,
  dynamicWidthColumnStyles,
  errorBannerStyles,
  borderStyles,
  tableFormHelpStyles,
  tableFormStyles,
  tableFormLabelStyles,
} from './styles';

interface ItemsAndDeliveriesProps {
  organization: io.flow.v0.models.Organization;
  order: io.flow.v0.models.Order;
  onStateChange: (state: AutoSaveState) => void;
}

interface SelectedDeliveryOptionDetailsProps {
  deliveryOption?: io.flow.v0.models.DeliveryOption;
  centerKey: string;
}

const getItemPrice = (order: io.flow.v0.models.Order, number: string) => {
  const line = order.lines?.find((l) => l.item_number === number);
  return line?.price;
};

const getLineItemId = (order: io.flow.v0.models.Order, number: string) => {
  const line = order.lines?.find((l) => l.item_number === number);
  return line?.id;
};

const getItemName = (order: io.flow.v0.models.Order, number: string) => {
  const item = order.items.find((i) => i.number === number);
  return item ? item.name : '';
};

const getShippingTierOptions = (
  order: io.flow.v0.models.Order,
  delivery: io.flow.v0.models.PhysicalDelivery,
) => delivery.options
  .filter((option) => option.delivered_duty === order.delivered_duty)
  .map((option) => ({
    content: option.tier.name,
    value: option.id,
  }));

const getSelectedDeliveryOption = (
  order: io.flow.v0.models.Order,
  delivery: io.flow.v0.models.PhysicalDelivery,
  index: number,
) => delivery.options.find((option) => order.selections && option.id === order.selections[index]);

const SelectedDeliveryOptionDetails: React.FC<SelectedDeliveryOptionDetailsProps> = ({
  deliveryOption,
  centerKey,
}) => (
  <div css={deliveryOptionStyles}>
    <div>{`Carrier & Service Level: ${deliveryOption?.service.id || ''}`}</div>
    <div>{`Delivery Window: ${deliveryOption?.window.label || ''}`}</div>
    <div>{`Center: ${centerKey}`}</div>
  </div>
);

SelectedDeliveryOptionDetails.defaultProps = { deliveryOption: undefined };

const ItemsAndDeliveries: React.FC<ItemsAndDeliveriesProps> = ({
  organization,
  order,
  onStateChange,
}) => {
  const dispatch = useDispatch<ThunkDispatcher>();

  const [error, setError] = useState<Error>();

  const reloadOrderBuilder = () => {
    onStateChange('saved');
    dispatch(fetchReplacementOrderBuilder(
      organization.id,
      order.number,
      false,
    ));
  };

  const handleError = (e: Error) => {
    setError(e);
    onStateChange('error');
  };

  const currencies: io.flow.v0.models.Currency[] = useSelector(getCurrencies);

  const localCurrency = currencies?.find((c) => c.iso_4217_3 === order.total.currency);
  const localSymbol = (localCurrency && localCurrency.symbols) ? localCurrency.symbols.primary : '';

  const baseCurrency = currencies?.find((c) => c.iso_4217_3 === order.total.base.currency);
  const baseSymbol = (baseCurrency && baseCurrency.symbols) ? baseCurrency.symbols.primary : '';

  const onUpdateQuantity = (id: string, quantity: number): void => {
    onStateChange('saving');
    dispatch(updateReplacementOrderLineItemQuantity(
      organization.id,
      order.number,
      id,
      quantity,
    )).then(reloadOrderBuilder).catch((e: Error) => handleError(e));
  };

  const onAddLineItem = (number: string): void => {
    dispatch(addReplacementOrderLineItem(
      organization.id,
      order.number,
      { number, quantity: 1 },
    )).then(reloadOrderBuilder).catch((e: Error) => handleError(e));
  };

  const onRemoveLineItem = (id: string): void => {
    dispatch(removeReplacementOrderLineItem(
      organization.id,
      order.number,
      id,
    )).then(reloadOrderBuilder).catch((e: Error) => handleError(e));
  };

  const onUpdateDeliveryOption = (
    index: number,
    id: string,
  ): void => {
    const selections = [...order.selections];
    selections[index] = id;

    dispatch(updateReplacementOrderSelections(
      organization.id,
      order.number,
      selections,
    )).then(reloadOrderBuilder).catch((e: Error) => handleError(e));
  };

  useEffect(() => {
    dispatch(fetchCurrencies());
  }, [dispatch]);

  return (
    <Card>
      <CardHeader caption="Items &amp; Deliveries" dividing />
      <CardContent>
        {error && (
          <Banner icon={Alert} intent="negative" text={error.message} css={errorBannerStyles} />
        )}
        <Table displayDensity="cozy">
          <TableRow key="item-search" css={borderStyles}>
            <TableRowColumn verticalAlign="top">
              <div css={tableFormLabelStyles}>Add Item</div>
            </TableRowColumn>
            <TableRowColumn colSpan={5} css={tableFormStyles}>
              <QueryTargetField
                hintText="Enter an item number or name"
                onChange={(dataSource: DataSource) => {
                  onAddLineItem(dataSource.suggestion.number);
                }}
                style={{ display: 'block' }}
                type={QueryTargetFieldType.TYPE_ITEMS}
              />
              <div css={tableFormHelpStyles}>
                Items are automatically grouped into deliveries based on their centers.
              </div>
            </TableRowColumn>
          </TableRow>
          {order?.deliveries.map((delivery, index) => (
            <TableBody key={delivery.id}>
              { delivery.discriminator === 'physical_delivery' && (
                <TableRow key={delivery.id} striped css={borderStyles}>
                  <TableRowColumn verticalAlign="top">
                    <div css={css({ fontSize: '1rem;', marginTop: '0.75rem' })}>{`Delivery #${index + 1}:`}</div>
                  </TableRowColumn>
                  <TableRowColumn colSpan={5} css={tableFormStyles}>
                    <Box alignItems="center" spacing="loose">
                      <div css={tableFormLabelStyles}>Shipping Tier:</div>
                      <Select
                        options={getShippingTierOptions(order, delivery)}
                        value={getSelectedDeliveryOption(order, delivery, index)?.id}
                        onValueChange={(value) => onUpdateDeliveryOption(index, value)}
                      />
                    </Box>
                    <SelectedDeliveryOptionDetails
                      centerKey={delivery.center?.key || ''}
                      deliveryOption={getSelectedDeliveryOption(order, delivery, index)}
                    />
                  </TableRowColumn>
                </TableRow>
              )}
              <TableRow key={`${delivery.id}-items`} striped css={borderStyles}>
                <TableHeadColumn css={dynamicWidthColumnStyles}>Number</TableHeadColumn>
                <TableHeadColumn>Name</TableHeadColumn>
                <TableHeadColumn css={dynamicWidthColumnStyles} textAlign="center">Quantity</TableHeadColumn>
                <TableHeadColumn css={dynamicWidthColumnStyles}>Base Price</TableHeadColumn>
                <TableHeadColumn css={dynamicWidthColumnStyles}>Local Price</TableHeadColumn>
                <TableHeadColumn />
              </TableRow>
              {delivery.items.map((item) => {
                const price = getItemPrice(order, item.number);
                const name = getItemName(order, item.number);
                const id = getLineItemId(order, item.number);

                return (
                  <TableRow key={id} css={borderStyles}>
                    <TableRowColumn css={dynamicWidthColumnStyles}>{item.number}</TableRowColumn>
                    <TableRowColumn>{name}</TableRowColumn>
                    <TableRowColumn verticalAlign="middle">
                      <Stack spacing="tight">
                        <IconButton
                          size="small"
                          icon={Dash}
                          onClick={() => { if (id) onUpdateQuantity(id, item.quantity - 1); }}
                          disabled={item.quantity < 2}
                        />
                        <span>{item.quantity}</span>
                        <IconButton
                          size="small"
                          icon={Plus}
                          onClick={() => { if (id) onUpdateQuantity(id, item.quantity + 1); }}
                        />
                      </Stack>
                    </TableRowColumn>
                    <TableRowColumn verticalAlign="middle" css={dynamicWidthColumnStyles}>{(price && price.base) ? price.base.label : ''}</TableRowColumn>
                    <TableRowColumn verticalAlign="middle" css={dynamicWidthColumnStyles}>{price ? price.label : ''}</TableRowColumn>
                    <TableRowColumn verticalAlign="middle" css={dynamicWidthColumnStyles}>
                      <IconButton
                        size="small"
                        icon={Close}
                        disabled={order.items.length === 1}
                        onClick={() => { if (id) onRemoveLineItem(id); }}
                      />
                    </TableRowColumn>
                  </TableRow>
                );
              })}
            </TableBody>
          ))}
          <TableBody key="order-totals">
            {order.prices.map((price) => (
              <TableRow key={price.key}>
                <TableRowColumn colSpan={3} textAlign="right" css={css({ height: '28px !important;' })}>
                  {price.name ? price.name : capitalize(price.key)}
                </TableRowColumn>
                <TableRowColumn textAlign="left" css={css({ height: '28px !important;' })}>{price.base.label}</TableRowColumn>
                <TableRowColumn textAlign="left" css={css({ height: '28px !important;' })}>{price.label}</TableRowColumn>
                <TableRowColumn css={css({ height: '28px !important;' })} />
              </TableRow>
            ))}
            <TableRow key="order-adjustment">
              <TableRowColumn colSpan={3} textAlign="right" css={css({ height: '28px !important;' })}>Replacement Order Adjustment</TableRowColumn>
              <TableRowColumn css={css({ color: '#d2431f;', height: '28px !important;' })} textAlign="left">
                <span css={css({ whiteSpace: 'nowrap' })}>{`-${order.total.base.label}`}</span>
              </TableRowColumn>
              <TableRowColumn css={css({ color: '#d2431f;', height: '28px !important;' })} textAlign="left">
                <span css={css({ whiteSpace: 'nowrap' })}>{`-${order.total.label}`}</span>
              </TableRowColumn>
              <TableRowColumn css={css({ height: '28px !important;' })} />
            </TableRow>
            <TableRow key="order-total-zero" css={css({ fontWeight: 'bold' })}>
              <TableRowColumn colSpan={3} textAlign="right" css={css({ height: '28px !important;' })}>Total</TableRowColumn>
              <TableRowColumn textAlign="left" css={css({ height: '28px !important;' })}>{`${baseSymbol}0.00`}</TableRowColumn>
              <TableRowColumn textAlign="left" css={css({ height: '28px !important;' })}>{`${localSymbol}0.00`}</TableRowColumn>
              <TableRowColumn css={css({ height: '28px !important;' })} />
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
      <CardFooter>
        <Banner icon={InfoCircle} intent="neutral">
          <BannerText>
            Shipping, duty, tax and other non-item costs of this
            replacement order will be billed to
            { ` ${organization.name}` }
          </BannerText>
        </Banner>
      </CardFooter>
    </Card>
  );
};

export default ItemsAndDeliveries;
