import Client from './client';
import {
  LegacyResponse,
} from '../types/server';

export default class Imports extends Client {
  constructor(host: string, auth: string, headers: Record<string, string>) {
    super({ serviceName: 'server' });

    this.host = host;
    this.auth = auth;
    this.headers = headers;
  }

  post(organization: string, options = {}): Promise<LegacyResponse> {
    Object.assign(options, { method: 'POST' });
    return this.makeRequest(`${this.host}/${organization}/imports`, options);
  }

  get(uid: string, organization = {}): Promise<LegacyResponse> {
    return this.makeRequest(`${this.host}/${organization}/imports/${uid}`, { method: 'GET' });
  }

  getAll(organization: string, options = {}): Promise<LegacyResponse> {
    Object.assign(options, { method: 'GET' });
    return this.makeRequest(`${this.host}/${organization}/imports`, { method: 'GET' });
  }
}
