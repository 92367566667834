import { MultiSelect, MultiSelectOption } from '@flowio/react-multi-select';
import curry from 'lodash/curry';
import PropTypes from 'prop-types';
import React from 'react';
import uniqBy from 'lodash/uniqBy';
import checkHttpStatus from '../../../utilities/check-http-status';
import { SearchDropdownValue } from '../types';

const loadOptions = curry((apiToken: string, organizationId: string, searchText: string) => {
  const fetchUrl = `https://api.flow.io/${organizationId}/search/item/query/suggestions?q=${encodeURIComponent(searchText)}`;
  const fetchOptions = {
    method: 'get',
    headers: {
      authorization: `Bearer ${apiToken}`,
    },
  };
  return fetch(fetchUrl, fetchOptions)
    .then(checkHttpStatus)
    .then((response) => response.json())
    .then((response) => (searchText && (response.length !== 1) ? {
      options: [{ q: searchText }, ...(response)],
    } : {
      options: [...(response)],
    }))
    .catch(() => ({ options: [] }));
});

interface Props {
  apiToken: string;
  organizationId: string;
  hintText: string;
  onChange: (queries: string[]) => void;
  triggerTheme?: string;
  defaultValue?: SearchDropdownValue[];
}

const SearchBox: React.FC<Props> = ({
  apiToken,
  organizationId,
  defaultValue,
  hintText,
  onChange,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [options, setOptions] = React.useState<MultiSelectOption[]>([]);
  const [selectedItems, setSelectedItems] = React.useState<string[]>([]);

  const handleOnInputValueChange = (query: string) => {
    setIsLoading(true);
    if (query) {
      loadOptions(apiToken, organizationId, query).then((r) => {
        const updatedOptions = [
          ...r.options.map((o) => ({ value: o.q, content: o.q })),
          ...selectedItems.map((o) => ({ value: o, content: o })),
        ];
        const uniqueOptions = uniqBy(updatedOptions, (obj) => obj.value);
        setOptions(uniqueOptions);
        setIsLoading(false);
      });
    }
  };

  return (
    <MultiSelect
      fluid
      options={options}
      loading={isLoading}
      value={selectedItems?.map((c) => c)}
      searchable={false}
      menuProps={{
        selectItemOnSpace: false,
      }}
      defaultValue={defaultValue?.map((v) => v.q)}
      hintText={hintText}
      onInputValueChange={handleOnInputValueChange}
      onValueChange={(changed) => {
        setSelectedItems(changed);
        onChange(changed);
      }}
    />
  );
};

SearchBox.displayName = 'SearchBox';

SearchBox.propTypes = {
  apiToken: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
};

export default SearchBox;
