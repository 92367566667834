import { ReportInterval } from '@flowio/api-internal-constants';
import ActionTypes from '../constants/action-types';
import { RootActionTypes } from '../../../stores/types';

export default function changeInterval(interval: ReportInterval): RootActionTypes {
  return {
    type: ActionTypes.CHANGE_INTERVAL,
    payload: interval,
  };
}
