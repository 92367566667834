import { ExternalLink as ExternalLinkIcon } from '@flowio/react-icons';
import React, { AnchorHTMLAttributes } from 'react';
import classNames from 'classnames';

import { css } from '@emotion/css';

const externalLinkStyles = css`
  color: currentColor;
  text-decoration: underline;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  & span:after {
    display: inline-block;
    width: 2px;
    content: '';
  }
`;

const ExternalLink: React.FC<AnchorHTMLAttributes<HTMLAnchorElement>> = ({
  children,
  target = '_blank',
  rel = 'noopener noreferrer',
  className,
  ...otherProps
}) => (
  <a
    className={classNames(externalLinkStyles, className)}
    target={target}
    rel={rel}
    {...otherProps}
  >
    <span>{children}</span>
    <ExternalLinkIcon height="12px" />
  </a>
);

ExternalLink.displayName = 'ExternalLink';

export default ExternalLink;
