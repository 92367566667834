import assign from 'lodash/assign';
import * as ActionTypes from './constants';

const initialState = [];

/**
 * A reducer that manages the experiences data subset of the application state tree. It provides the
 * following shape:
 *
 *  [
 *    {},
 *  ]
 */
export default function (state = initialState, action) {
  switch (action.type) {
    case ActionTypes.FETCH_API_KEYS_SUCCESS:
      return action.payload;

    case ActionTypes.CREATE_API_KEY_SUCCESS:
      return state.concat(action.payload);

    case ActionTypes.FETCH_CLEAR_TEXT_KEY_SUBMIT:
      return state.map((token) => {
        if (token.id === action.id) {
          return assign({}, token, { awaitingClearText: true });
        }
        return token;
      });

    case ActionTypes.FETCH_CLEAR_TEXT_KEY_SUCCESS:
      return state.map((token) => {
        if (token.id === action.id) {
          return assign({}, token, { awaitingClearText: false, cleartext: action.payload.value });
        }
        return token;
      });

    case ActionTypes.DELETE_API_KEY_SUCCESS:
      return state.filter((token) => token.id !== action.id);

    default:
      return state;
  }
}
