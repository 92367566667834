import React from 'react';
import PropTypes from 'prop-types';
import ApiPropTypes from '@flowio/api-prop-types';
import { Card, CardContent, CardEmptyState } from '@flowio/react-card';
import isEmpty from 'lodash/isEmpty';
import { PageContent } from '@flowio/react-page';
import { css } from '@emotion/css';
import PendingUsersList from '../pending-users-list';

const worksheetStyles = css`
  min-height: 536px;
  padding-top: 8px;
`;

const noReqStyles = css`
  min-height: 496px;
  margin: 20px;
`;

const PendingUsers = ({ users, onRequestApproveUser, onRequestDeclineUser }) => (
  <PageContent>
    <Card className={worksheetStyles}>
      <CardContent fitted>
        {isEmpty(users) ? (
          <CardEmptyState content="No Pending Requests" className={noReqStyles} />
        ) : (
          <PendingUsersList
            users={users}
            onRequestApproveUser={onRequestApproveUser}
            onRequestDeclineUser={onRequestDeclineUser}
          />
        )}
      </CardContent>
    </Card>
  </PageContent>
);

PendingUsers.displayName = 'Pending Users';

PendingUsers.propTypes = {
  users: PropTypes.arrayOf(ApiPropTypes.user).isRequired,
  onRequestApproveUser: PropTypes.func.isRequired,
  onRequestDeclineUser: PropTypes.func.isRequired,
};

export default PendingUsers;
