import update from 'immutability-helper';

import { ActionTypes } from '../constants';
import { RatecardState } from '../types/state';
import { RootActionTypes } from '../../../stores/types';

const initialState: RatecardState = [];

export default function ratecardsReducer(
  state = initialState,
  action: RootActionTypes,
) {
  switch (action.type) {
    case ActionTypes.FETCH_RATECARDS_SUCCESS:
      return update(state, {
        $set: action.payload,
      });
    default:
      return state;
  }
}
