import { createSelector } from 'reselect';
import filter from 'lodash/filter';
import getAvailableFiltersForReturnPolicyWorksheet from './getAvailableFiltersForReturnPolicyWorksheet';

import { RootState } from '../../../stores/types';

export default createSelector<
RootState, io.flow.v0.unions.AvailableFilter[]
| undefined, io.flow.v0.models.AvailableFilterStructured[]
| Array<any>
>(
  getAvailableFiltersForReturnPolicyWorksheet,
  (availableFilters) => filter(availableFilters, { discriminator: 'structured' }),
);
