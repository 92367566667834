import { css } from '@emotion/react';
import {
  DropdownMenu, Dropdown, DropdownItem, DropdownDivider,
} from '@flowio/react-dropdown';
import moment from 'moment';
import React, { useCallback } from 'react';
import { Ellipsis } from '@flowio/react-icons';
import {
  Table,
  TableBody, TableHead,
  TableHeadColumn, TableRow, TableRowColumn,
} from '@flowio/react-table';
import { colors } from '../../../../../theme/tokens';
import withNumberFormat from '../../../../../utilities/numbers/withNumberFormat';
import marketingTableStyle from '../../../styles/styles';
import { findCountryByCode, findLanguageByCode } from '../../../utilities/findReferenceData';
import FacebookAccessValue from '../../ChannelCard/components/FacebookAccessValue';
import FacebookFeedStatusLabel from '../../FacebookFeedStatusLabel';
import { Props } from '../types';

type FacebookFeed = io.flow.internal.v0.models.MarketingGatewayFeedSourceFacebook
| io.flow.internal.v0.models.MarketingGatewayFeed;

const ellipsisStyles = css`
  transform: rotate(90deg);
  cursor: pointer;
  fill: ${colors.marketing['light-slate-gray']};

  &:hover {
    fill: ${colors['flow-blue'][400]};
  }
`;

const DATE_FORMAT = 'MMM D';
const TIME_FORMAT = 'hh:mm A [GMT]Z';

const isFeedSource = (
  feed: FacebookFeed,
): feed is io.flow.internal.v0.models.MarketingGatewayFeedSourceFacebook => (
  feed as io.flow.internal.v0.models.MarketingGatewayFeedSourceFacebook
)
  .catalog_id !== undefined;

const handleDownloadRequest = (feed: io.flow.internal.v0.models.MarketingGatewayFeed): void => {
  window.open(feed.tsv_download_url, '_blank');
};

const FacebookFeedsTable: React.FC<Props> = ({
  countries,
  accessTokenSummary,
  languages,
  feeds = [],
  onDeleteFeedClick,
}) => {
  const timeStamp = (feed: FacebookFeed): string => {
    const updatedAtInfo = isFeedSource(feed) ? feed.last_uploaded_at : feed.updated_at;
    if (updatedAtInfo) {
      const time = moment(updatedAtInfo);
      return `${time.format(DATE_FORMAT)} at ${time.format(TIME_FORMAT)}`;
    }
    return '-';
  };

  const feedName = useCallback(
    (feed: FacebookFeed) => {
      const countryName = findCountryByCode(countries, feed.country)?.name;
      const languageName = findLanguageByCode(languages, feed.language)?.name;
      return `${countryName}${isFeedSource(feed) ? ` (${languageName})` : ''}`;
    }, [countries, languages],
  );

  const handleDelete = useCallback(
    (feed: io.flow.internal.v0.models.MarketingGatewayFeed) => {
      const country = findCountryByCode(countries, feed.country);
      const language = findLanguageByCode(languages, feed.language);
      if (country && language) {
        onDeleteFeedClick({
          country,
          language,
          id: feed.id,
          platform: 'facebook',
        });
      }
    }, [languages, countries, onDeleteFeedClick],
  );

  const isGenerating = (feed: FacebookFeed): boolean => (isFeedSource(feed)
    ? feed.feed_status === 'initialized' : feed.feed_state === 'initialized');

  return (
    <Table data-automation-id="feeds-table" css={marketingTableStyle(14)}>
      <TableHead>
        <TableRow>
          <TableHeadColumn>Data Feed</TableHeadColumn>
          <TableHeadColumn>Currency</TableHeadColumn>
          <TableHeadColumn textAlign="center"># of Items</TableHeadColumn>
          <TableHeadColumn>Last Update</TableHeadColumn>
          <TableHeadColumn>Status</TableHeadColumn>
          {accessTokenSummary && <TableHeadColumn data-automation-id="fb-access-col">Facebook Access</TableHeadColumn>}
          <TableHeadColumn />
        </TableRow>
      </TableHead>
      <TableBody>
        {feeds.map((feed) => (
          <TableRow key={`${feed.country}-${feed.language}`}>
            <TableRowColumn css={{ fontWeight: 500, minWidth: '200px' }} verticalAlign="middle"><span>{isFeedSource(feed) ? feed.name : feedName(feed)}</span></TableRowColumn>
            <TableRowColumn>{feed.currency}</TableRowColumn>
            <TableRowColumn textAlign="center">{feed.item_count ? withNumberFormat(feed.item_count) : '-'}</TableRowColumn>
            <TableRowColumn>{timeStamp(feed)}</TableRowColumn>
            <TableRowColumn>
              <FacebookFeedStatusLabel
                status={isFeedSource(feed) ? feed.feed_status : feed.feed_state}
              />
            </TableRowColumn>
            <TableRowColumn>
              {accessTokenSummary
               && <FacebookAccessValue accessTokenSummary={accessTokenSummary} /> }
            </TableRowColumn>
            <TableRowColumn>
              {!isFeedSource(feed) && (
                <Dropdown
                  position="bottom right"
                  trigger={<Ellipsis width={24} css={ellipsisStyles} />}
                >
                  <DropdownMenu>
                    {!isGenerating(feed) && (
                    <DropdownItem
                      onClick={(): void => handleDownloadRequest(feed)}
                    >
                      Download
                    </DropdownItem>
                    )}
                    <DropdownDivider />
                    <DropdownItem
                      onClick={(): void => handleDelete(feed)}
                    >
                      Delete
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              )}
            </TableRowColumn>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

FacebookFeedsTable.displayName = 'FacebookFeedsTable';

export default FacebookFeedsTable;
