import React from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableHeadColumn,
  TableBody,
} from '@flowio/react-table';
import map from 'lodash/map';

import OptinMessageItem from './OptinMessageItem';
import { Props } from '../types';

const OptinMessageList: React.FC<Props> = ({
  onActivateOptinMessage,
  onArchiveOptinMessage,
  onRemoveOptinMessage,
  onViewOptinMessage,
  optinAttributes,
  organizationId,
}) => (
  <Table selectable striped displayDensity="cozy">
    <TableHead>
      <TableRow>
        <TableHeadColumn>
          Name
        </TableHeadColumn>
        <TableHeadColumn>
          Attribute
        </TableHeadColumn>
        <TableHeadColumn style={{ width: 72 }}>
          Target
        </TableHeadColumn>
        <TableHeadColumn style={{ width: 72 }}>
          Status
        </TableHeadColumn>
        <TableHeadColumn />
      </TableRow>
    </TableHead>
    <TableBody>
      {map(optinAttributes, (optinAttribute, index) => (
        <OptinMessageItem
          currentIndex={index}
          key={optinAttribute.optin_attribute_key}
          onActivate={onActivateOptinMessage}
          onArchive={onArchiveOptinMessage}
          onRemove={onRemoveOptinMessage}
          onViewDetails={onViewOptinMessage}
          optinAttribute={optinAttribute}
          organizationId={organizationId}
        />
      ))}
    </TableBody>
  </Table>
);

OptinMessageList.displayName = 'OptinMessageList';

export default OptinMessageList;
