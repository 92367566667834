import { createSelector } from 'reselect';
import get from 'lodash/get';

import getNonReturnableState from './getNonReturnableState';
import { RootState } from '../../../stores/types';
import { NonReturnableState, ReturnPolicyFormState } from '../types';

const getReturnPolicyForm = createSelector<RootState, NonReturnableState, ReturnPolicyFormState>(
  getNonReturnableState,
  (state) => get(state, 'entities.returnPolicyForm'),
);

export default getReturnPolicyForm;
