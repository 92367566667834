/**
 * Creates an FSA success type descriptor for @flowio/redux-async-middleware
 * compatible with our @flowio/node-sdk library.
 * @param {{ type: string, meta: object }} descriptor
 */
export default function createSuccessDescriptor(descriptor) {
  return {
    type: descriptor.type,
    meta: descriptor.meta,
    payload: (state, response) => response.result,
  };
}
