import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Box, BoxItem } from '@flowio/react-box';
import { TextField } from '@flowio/react-text-field';
import MarketingDialog from '../../MarketingDialog';
import { Props } from '../types';
import Button from '../../MarketingButton';
import { colors } from '../../../../../theme/tokens';

const RequestChannelsDialog: React.FC<Props> = ({
  isOpen,
  onCancel,
  onConfirm,
}) => {
  const [inputValue, setInputValue] = useState('');
  useEffect(() => {
    setInputValue('');
  }, [isOpen]);

  const requestChannelsForm = (
    <Box spacing="loose" alignItems="center" justifyContent="start">
      <BoxItem css={css({ color: colors.marketing['dark-slate-blue'] })}><b>Channel</b></BoxItem>
      <BoxItem flexAuto>
        <TextField
          fluid
          hintText="Channel Name"
          labelText="Channel"
          onChange={
            (e: React.ChangeEvent<HTMLInputElement>): void => setInputValue(e.target.value)
          }
        />
      </BoxItem>
    </Box>
  );

  return (
    <MarketingDialog
      title="Request New Channel"
      subtitle="Don't see the channels you would like to create localized feeds for? Add your channel request here and be the first to know when it's available."
      form={requestChannelsForm}
      isOpen={isOpen}
    >
      <Button
        onClick={onCancel}
      >
        Cancel
      </Button>
      <Button
        disabled={!inputValue.length}
        onClick={() => onConfirm(inputValue)}
        intent="primary"
      >
        Submit Request
      </Button>
    </MarketingDialog>
  );
};

RequestChannelsDialog.displayName = 'RequestChannelsDialog';

export default RequestChannelsDialog;
