import { $HttpResponse } from '@flowio/api-sdk';
import {
  RootState, ThunkDispatcher, ThunkExtraArguments, ThunkResult,
} from '../../../stores/types';
import ActionTypes from '../constants/action-types';
import { WithPagingAndSortingResponse, WithPagingAndSortingResult } from '../../../utilities/types';
import withPagingAndSorting, { WithPagingAndSortingOptions, isPagingAndSortingResponse } from '../../../utilities/with-paging-and-sorting-v2';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';
import { getOrganizationId } from '../../organization';

// This is a legacy action that is still used in the application.
// it utilizes an endpoint that indexes withAlgolia
// It is used to find catalog items for legacy orgs only

export default function findCatalogItemsLegacy(options: WithPagingAndSortingOptions = {}):
ThunkResult<Promise<WithPagingAndSortingResult<io.flow.v0.models.CatalogItemDocument[]>
| io.flow.v0.models.GenericError>
> {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<
  WithPagingAndSortingResult<io.flow.v0.models.CatalogItemDocument[]>
  | io.flow.v0.models.GenericError
  > => {
    dispatch({
      type: ActionTypes.FIND_CATALOG_ITEMS_LEGACY_REQUEST,
    });
    const state = getState();

    return withPagingAndSorting<io.flow.v0.models.CatalogItemDocument[]>(
      options,
      (params) => extra.api(getState()).documents.getCatalog({
        organization: getOrganizationId(state),
        ...params,
      }),
    )().then((
      response: WithPagingAndSortingResponse<io.flow.v0.models.CatalogItemDocument[]>
      | $HttpResponse<io.flow.v0.models.GenericError>,
    ) => {
      if (isPagingAndSortingResponse<io.flow.v0.models.CatalogItemDocument[]>(response)) {
        dispatch({
          params: {},
          type: ActionTypes.FIND_CATALOG_ITEMS_LEGACY_SUCCESS,
          payload: response.result,
          options,
        });
        return response.result;
      }

      dispatch({
        params: {},
        type: ActionTypes.FIND_CATALOG_ITEMS_LEGACY_FAILURE,
        payload: formatErrorResponseV2(response),
      });

      return response.body;
    });
  };
}
