import { getPageNumber, getPageSize } from '@flowio/redux-filtering-paging-sorting/lib/selectors';
import invariant from 'invariant';
import { ThunkResult } from '../../../stores/types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';

import { createToast } from '../../console/actions';
import ActionType from '../constants/ActionType';
import { getExclusionRulesPaginationState } from '../selectors';
import fetchExclusionRules from './fetchExclusionRules';
import hideExclusionRuleWorksheet from './hideExclusionRuleWorksheet';

/**
 * Creates an action responsible for marking an exclusion rule in the specified
 * organization for deletion.
 * @param {String} organizationId - identifier for the organization to be updated.
 * @param {String} exclusionRuleId - identifier for the exclusion rule to be deleted.
 */

export default function createExclusionRule(
  organizationId: string,
  exclusionRuleForm: io.flow.v0.models.ExclusionRuleForm,
): ThunkResult<Promise<void>> {
  invariant(organizationId != null, 'An organization identifier must be provided');
  invariant(exclusionRuleForm != null, 'An exclusion rule form must be provided');
  return function createExclusionRuleEffects(dispatch, getState, extra) {
    const state = getState();
    const pageSize = getPageSize(getExclusionRulesPaginationState)(state);
    const pageNumber = getPageNumber(getExclusionRulesPaginationState)(state);

    dispatch({
      type: ActionType.CreateExclusionRuleRequest,
      meta: {
        organizationId,
        exclusionRuleForm,
      },
    });

    return extra.api(state).exclusionRules.post({
      organization: organizationId,
      body: exclusionRuleForm,
    }).then((response) => {
      if (response.ok) {
        dispatch({
          type: ActionType.CreateExclusionRuleSuccess,
          payload: response.body,
          meta: {
            organizationId,
            exclusionRuleForm,
          },
        });
        dispatch(fetchExclusionRules(organizationId, pageNumber, pageSize));
        dispatch(hideExclusionRuleWorksheet());
        dispatch(createToast({
          content: 'Exclusion rule added successfully',
        }));
        return;
      }

      const error = formatErrorResponseV2(response);
      dispatch({
        type: ActionType.CreateExclusionRuleFailure,
        meta: {
          organizationId,
          exclusionRuleForm,
        },
        payload: error,
      });
    });
  };
}
