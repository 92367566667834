import { InfoCircleOutline } from '@flowio/react-icons';
import { css } from '@emotion/react';
import React from 'react';
import classNames from 'classnames';
import capitalize from 'lodash/capitalize';

import Tooltip from '../../MarketingTooltip';
import { Props } from '../types';
import colors from '../../../../../theme/tokens/colors';

const statusStyles = css`
  &.initialized {
    font-style: italic;
  }
`;

const toolTipTriggerStyle = css({
  fill: colors.marketing['dark-slate-blue'],
  cursor: 'pointer',
  marginLeft: '8px',
  paddingRight: '2px',
});

const FacebookFeedStatusLabel: React.FC<Props> = ({
  status,
}) => {
  const content = (): JSX.Element | string => {
    switch (status) {
      case undefined:
        return '-';
      case 'initialized':
        return (
          <span>
            <i>Generating</i>
            <Tooltip
              openOnHover
              position="bottom"
              trigger={<InfoCircleOutline css={toolTipTriggerStyle} width={16} />}
              content="Flow is generating the product data for this country feed. Once the feed is ready, all product data will be uploaded to Facebook automatically."
            />
          </span>
        );
      case 'active':
        return 'All Good';
      default:
        return capitalize(status);
    }
  };

  return <span className={classNames(statusStyles, status)}>{content()}</span>;
};

FacebookFeedStatusLabel.displayName = 'FacebookFeedStatusLabel';

export default FacebookFeedStatusLabel;
