import uniqueId from 'lodash/uniqueId';
import { TierRuleOutcomeFormDiscriminatorValues } from '../constants';
import { FormTierRule } from '../types/components';

export default function createDefaultTierRule(): FormTierRule {
  return {
    id: uniqueId('tier-rule'),
    query: {
      operator: 'any',
      value: undefined,
    },
    outcome: {
      type: TierRuleOutcomeFormDiscriminatorValues.AT_COST,
      value: undefined,
    },
  };
}
