import { LegacyResponse } from '../types/server';
import Client from './client';
import { AccessToken } from './types';

export default class ShopfiyClient extends Client {
  host: string;

  auth: string;

  headers: Record<string, string> | undefined;

  constructor(host: string, auth: string, headers: Record<string, string>) {
    super({ serviceName: 'shopify' });

    this.auth = auth;
    this.host = host;
    this.headers = headers;
  }

  postAccessToken(shopDomain: string, options = {}): Promise<LegacyResponse<AccessToken>> {
    return this.makeRequest(`https://${shopDomain}/admin/oauth/access_token`, {
      ...options,
      method: 'POST',
    });
  }
}
