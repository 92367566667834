import { ActionTypes, ActivityKind } from '../constants';
import { getCurrentOrderNumber, getSelectedOrderItems } from '../selectors';
import createActivity from './createActivity';
import { getOrganizationId } from '../../organization';
import { ThunkResult } from '../../../stores/types';

export default function clearCurrentItems(): ThunkResult<void> {
  return function clearCurrentItemsEffects(dispatch, getState) {
    const currentOrderItems = getSelectedOrderItems(getState());
    const organizationId = getOrganizationId(getState());
    const currentOrderNumber = getCurrentOrderNumber(getState());

    if (currentOrderItems != null) {
      dispatch({ type: ActionTypes.CLEAR_CURRENT_ITEMS });
      dispatch(createActivity({
        kind: ActivityKind.CLEAR_ITEMS,
        organizationId,
        number: currentOrderNumber,
      }));
    }
  };
}
