import { createSelector } from 'reselect';
import get from 'lodash/get';
import getExclusionRuleWorksheet from './getExclusionRuleWorksheet';
import { RootState } from '../../../stores/types';
import { ExclusionRuleWorksheetState } from '../types';

/**
 * Returns identifier of the exclusion rule to be updated by the exclusion rule worksheet.
 * @returns {String}
 */
const getExclusionRuleIdForExclusionRuleWorksheet = createSelector<
RootState, ExclusionRuleWorksheetState, string>(
  getExclusionRuleWorksheet,
  (worksheet) => get(worksheet, 'exclusionRule.id'),
);

export default getExclusionRuleIdForExclusionRuleWorksheet;
