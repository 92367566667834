import { reduxForm } from 'redux-form';

import { ActionTypes, FormName } from '../constants';
import { createShippingConfigurationCopy } from '../actions';
import ShippingConfigurationCopyForm from '../components/ShippingConfigurationCopyForm';
import handleSubmitResult from '../utilities/handleSubmitResult';
import { ShippingConfigurationCopyFormProps, ShippingConfigurationCopyFormValues } from '../types/components';
import { RootActionTypes, ThunkDispatcher } from '../../../stores/types';

function shippingConfigurationCopyFormFromValues(values: ShippingConfigurationCopyFormValues) {
  return { name: values.name };
}

function handleSubmit(
  values: ShippingConfigurationCopyFormValues,
  dispatch: ThunkDispatcher,
): Promise<RootActionTypes> {
  const { organizationId, shippingConfigurationKey } = values;
  return dispatch(createShippingConfigurationCopy(
    organizationId,
    shippingConfigurationKey,
    shippingConfigurationCopyFormFromValues(values),
  )).then((result) => handleSubmitResult(
    result,
    [ActionTypes.CREATE_SHIPPING_CONFIGURATION_COPY_FAILURE],
  ));
}

const form = reduxForm<
ShippingConfigurationCopyFormValues,
ShippingConfigurationCopyFormProps
>({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  form: FormName.SHIPPING_CONFIGURATION_COPY_FORM,
  onSubmit: handleSubmit,
});

export default form(ShippingConfigurationCopyForm);
