import update from 'immutability-helper';
import ActionTypes from '../constants/action-types';
import { RootActionTypes } from '../../../stores/types';

const initialState: io.flow.v0.models.Tier[] = [];

export default function reducer(
  state = initialState,
  action: RootActionTypes,
): io.flow.v0.models.Tier[] {
  switch (action.type) {
    case ActionTypes.FETCH_RETURN_SHIPPING_TIERS_REQUEST:
      return update(state, { $set: [] });
    case ActionTypes.FETCH_RETURN_SHIPPING_TIERS_SUCCESS:
      return update(state, { $set: action.payload.filter((tier) => tier.direction === 'return') });
    case ActionTypes.FETCH_RETURN_SHIPPING_TIERS_FAILURE:
      return update(state, { $set: [] });
    default:
      return state;
  }
}
