import PropTypes from 'prop-types';
import React from 'react';

import ExperienceNavigation from '../../navigation';
import LandedCostForm from '../../LandedCostForm/containers/LandedCostForm';
import { MergedProps as Props } from '../types';

const LandedCostIndex: React.FC<Props> = ({
  organizationId,
  experienceKey,
}) => {
  if (!experienceKey) {
    throw new Error(`${LandedCostIndex.displayName}: Missing experienceKey`);
  }
  return (
    <section>
      <ExperienceNavigation organization={organizationId} experience={experienceKey} />
      <LandedCostForm experienceKey={experienceKey} />
    </section>
  );
};

LandedCostIndex.displayName = 'LandedCostIndex';

LandedCostIndex.propTypes = {
  organizationId: PropTypes.string.isRequired,
  experienceKey: PropTypes.string.isRequired,
};

export default LandedCostIndex;
