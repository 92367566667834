import { ChevronLeft } from '@flowio/react-icons';
import { Link } from 'react-router';
import { LaneDirection } from '@flowio/api-constants';
import { Button } from '@flowio/react-button';
import React from 'react';
import { Stack } from '@flowio/react-stack';
import get from 'lodash/get';
import { PageContent } from '@flowio/react-page';

import ShippingLaneProfileCard from '../ShippingLaneProfileCard';
import TierListCard from '../TierListCard';
import { ShippingLaneDetailPageProps } from '../../types/components';

const ShippingLaneDetailPage: React.FC<ShippingLaneDetailPageProps> = ({
  countries,
  // TODO: Consolidate CRUD tier callbacks into a single callback.
  onAddOutboundTier,
  onAddReturnTier,
  onEditOutboundTier,
  onEditReturnTier,
  onEditShippingLane,
  onDeleteOutboundTier,
  onDeleteReturnTier,
  onDeleteShippingLane,
  organizationId,
  regions,
  shippingConfiguration,
  shippingLane,
}) => {
  const tiers = shippingLane?.tiers || [];
  const direction = get(shippingLane, 'direction');
  const shippingConfigurationKey = get(shippingConfiguration, 'key');
  return (
    <PageContent>
      <Stack orientation="vertical" spacing="loose">
        <Link to={`/${organizationId}/logistics/shipping/configurations/${shippingConfigurationKey}`}>
          <Button
            content="Return to Shipping Configuration"
            variant="flat"
            leftIcon={ChevronLeft}
          />
        </Link>
        <ShippingLaneProfileCard
          countries={countries}
          onDeleteShippingLane={() => {
            onDeleteShippingLane(organizationId, shippingConfiguration, shippingLane);
          }}
          onEditShippingLane={() => {
            onEditShippingLane(organizationId, shippingConfiguration, shippingLane);
          }}
          regions={regions}
          shippingConfiguration={shippingConfiguration}
          shippingLane={shippingLane}
        />
        { direction === LaneDirection.OUTBOUND ? (
          <TierListCard
            direction={LaneDirection.OUTBOUND}
            onAddTier={() => onAddOutboundTier(organizationId, shippingLane)}
            onEditTier={(tier) => onEditOutboundTier(organizationId, shippingLane, tier)}
            onDeleteTier={(tier) => onDeleteOutboundTier(organizationId, shippingLane, tier)}
            tiers={tiers}
          />
        ) : (
          <TierListCard
            direction={LaneDirection.RETURN}
            onAddTier={() => onAddReturnTier(organizationId, shippingLane)}
            onEditTier={(tier) => onEditReturnTier(organizationId, shippingLane, tier)}
            onDeleteTier={(tier) => onDeleteReturnTier(organizationId, shippingLane, tier)}
            tiers={tiers}
          />
        )}
      </Stack>
    </PageContent>
  );
};

ShippingLaneDetailPage.displayName = 'ShippingLaneDetailPage';

export default ShippingLaneDetailPage;
