import includes from 'lodash/includes';
import isArray from 'lodash/isArray';
import isBoolean from 'lodash/isBoolean';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import trim from 'lodash/trim';
import { AtLeastOne } from './types';

export type ValidatorArgumentMap = AtLeastOne<ValidatorObject> & {
  message?: string;
};

export type LiteralValue = string | number | boolean;

export type Value = LiteralValue | LiteralValue[] | object | object[] | null | undefined;

// type ValidatorsMap = Record<string, ValidatorObject>;

type ValidatorFunction<T = any> = (
  value: Value,
  validatorArg: T,
) => boolean;
type ValidatorFunctionMap = Record<keyof ValidatorObject, ValidatorFunction>;

interface ValidatorPatterns {
  integer: RegExp;
  numeric: RegExp;
  digits: RegExp;
  alpha: RegExp;
  alphanum: RegExp;
  url: RegExp;
  email: RegExp;
}

type ValidatorAcceptanceValue = boolean;
type ValidatorArrLengthValue = number;
type ValidatorArrMinLengthValue = number;
type ValidatorArrMaxLengthValue = number;
type ValidatorIncludeValue = LiteralValue[];
type ValidatorLengthValue = number;
type ValidatorMaxValue = number;
type ValidatorMaxLengthValue = number;
type ValidatorMinValue = number;
type ValidatorMinLengthValue = number;
type ValidatorPatternValue = keyof ValidatorPatterns | RegExp;
type ValidatorRangeValue = [number, number];
type ValidatorRangeLengthValue = [number, number];
type ValidatorRequiredValue = boolean;

type ValidatorValues =
ValidatorAcceptanceValue
| ValidatorArrLengthValue
| ValidatorArrMinLengthValue
| ValidatorArrMaxLengthValue
| ValidatorIncludeValue
| ValidatorLengthValue
| ValidatorMaxValue
| ValidatorMaxLengthValue
| ValidatorMinValue
| ValidatorMinLengthValue
| ValidatorPatternValue
| ValidatorRangeValue
| ValidatorRangeLengthValue
| ValidatorRequiredValue;

interface ValidatorObject {
  acceptance: ValidatorAcceptanceValue;
  arrLength: ValidatorArrLengthValue;
  arrMinLength: ValidatorArrMinLengthValue;
  arrMaxLength: ValidatorArrMaxLengthValue;
  include: ValidatorIncludeValue;
  length: ValidatorLengthValue;
  max: ValidatorMaxValue;
  maxLength: ValidatorMaxLengthValue;
  min: ValidatorMinValue;
  minLength: ValidatorMinLengthValue;
  pattern: ValidatorPatternValue;
  range: ValidatorRangeValue;
  rangeLength: ValidatorRangeLengthValue;
  required: ValidatorRequiredValue;
}

// Validator
// ---------
//
// A simple utility class that validates values against a set of predefined validation rules.
//
// To define a validation rule you simply need to call the `addRule` method with the name of your
// rule and a function that defines the rule behavior and returns whether the value is valid.
//
// ```
// var validator = new Validator();
//
// validator.addRule('min', function (value, minValue) {
//   return typeof value === 'number' && (value >= minValue);
// });
//
// validator.validate(3, { min: 4 }); // false
// validator.validate(5, { min: 4 }); // true
// ```
//
// As you can see `min` is set as comparison rule to apply. You may supply as many validation rules
// as you desire. If the validation rule doesn't exist the value would be considered valid against
// that rule.
class Validator {
  validators: Partial<ValidatorFunctionMap> = {};

  // Validates a value against a set of validation rules. If a validation rule doesn't exist the
  // `value` will be considered valid for that particular rule.
  // @param {Mixed} value The value to be tested.
  // @param {Object} validators Rules to run against the given value.
  // @returns {Boolean} Whether the value passes all validation rules
  validate(value: Value, validators: ValidatorArgumentMap): boolean {
    return Object.keys(validators).map((name) => {
      const validatorType = name as keyof ValidatorObject;
      const validator = this.getValidatorByName(validatorType);
      return validator ? validator(value, validators[validatorType] as ValidatorValues) : true;
    }).every((isValid) => isValid);
  }

  // Returns validator that matches the given name. If a validator is not found returns `undefined`.
  // @param {String}
  // @returns {Function}
  getValidatorByName(name: keyof ValidatorObject): ValidatorFunction {
    return this.validators[name] as ValidatorFunction;
  }

  // Adds a single internal validation rule.
  // @param {String} name
  // @param {Function} fn
  // @returns {Validator}
  // addRule(name: 'required', fn: ValidatorFunction<ValidatorObject['required']>): Validator;

  // addRule(name: 'acceptance', fn: ValidatorFunction<'acceptance'>): Validator;

  addRule<T>(name: keyof ValidatorObject, fn: ValidatorFunction<T>): Validator {
    this.validators[name] = fn;
    return this;
  }

  // Adds a set of internal validation rules. Overides any existing rules.
  // @param {Object<String, Function>} rules
  // @returns {Validator}
  addRules(rules: ValidatorFunctionMap): Validator {
    Object.keys(rules).forEach((name: string) => {
      const ruleName = name as keyof ValidatorObject;
      this.addRule(ruleName, rules[ruleName]);
    });
    return this;
  }
}

// Singleton
// ---------

const validator = new Validator();

// Patterns
// --------
// Regular expressions used to validate that a string matches a particular character combination.

const patterns: ValidatorPatterns = {
  // Matches integers (e.g. 4, -10, 0)
  integer: /^(-?[1-9]\d*|0)$/,
  // Matches any number including decimal points (e.g. 10, -4.125, 0)
  numeric: /^-?(?:0$0(?=\d*\.)|[1-9]|0)\d*(\.\d+)?$/,
  // Matches any number including punctuations and decimal points (e.g. 10:45, (212) 345-1235)
  // eslint-disable-next-line no-useless-escape
  digits: /^[\d() .:\-\+#]+$/,
  // Matches letters (e.g. HeLLo)
  alpha: /^[a-zA-Z]+$/,
  // Matches letters and numbers (e.g. H3ll0)
  alphanum: /^[a-zA-Z0-9]*$/,
  // Matches any valid URL (https://gist.github.com/HenkPoley/8899766)
  // eslint-disable-next-line max-len, no-useless-escape
  url: /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|\[(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]).){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]).){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))\]|localhost|(?:xn--[a-z0-9\-]{1,59}|(?:(?:[a-z\u00a1-\uffff0-9]+-?){0,62}[a-z\u00a1-\uffff0-9]{1,63}))(?:\.(?:xn--[a-z0-9\-]{1,59}|(?:[a-z\u00a1-\uffff0-9]+-?){0,62}[a-z\u00a1-\uffff0-9]{1,63}))*(?:\.(?:xn--[a-z0-9\-]{1,59}|(?:[a-z\u00a1-\uffff]{2,63}))))(?::\d{2,5})?(?:\/[^\s]*)?$/i,
  // Matches any valid email
  // eslint-disable-next-line max-len, no-useless-escape
  email: /^(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]\.?){0,63}[a-z0-9!#$%&'*+\/=?^_`{|}~-]@(?:(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)*[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\])$/i,
};

// Built-in Validation Rules
// -------------------------

// Determines whether or not a value is empty
function hasValue(value: Value): boolean {
  return !(
    isNil(value)
    || (isString(value) && trim(value) === '')
    || (isArray(value) && isEmpty(value))
  );
}

// Validates that a required value is not empty. If a value is not
// required, then the value is not checked. If the value is a number
// it will always return true.
validator.addRule<ValidatorRequiredValue>('required', (value: Value, isRequired: ValidatorRequiredValue) => !isRequired || hasValue(value));

// Validates that something has to be accepted, (e.g. terms of use)
// `true` or 'true' are valid
validator.addRule<ValidatorAcceptanceValue>('acceptance', (value: Value, isAccepted: ValidatorAcceptanceValue) => !isAccepted || value === 'true' || (isBoolean(value) && value === true));

// Validates that the value has to be a number and equal to or
// greater than the min value specified.
validator.addRule<ValidatorMinValue>('min', (value: Value, minValue: ValidatorMinValue) => isNumber(value) && (value >= minValue));

// Validates that the value has to be a number and equal to or less
// than the max value specified
validator.addRule<ValidatorMaxValue>('max', (value: Value, maxValue: ValidatorMaxValue) => isNumber(value) && (value <= maxValue));

// Validates that the value has to be a number and equal to or
// between the two numbers specified.
validator.addRule<ValidatorRangeValue>('range', (value: Value, range: ValidatorRangeValue) => isNumber(value) && (value >= range[0]) && (value <= range[1]));

// Validates that the value has to be a string with length equal to
// the length value specified.
validator.addRule<ValidatorLengthValue>('length', (value: Value, length: ValidatorLengthValue) => isString(value) && (length === value.length));

// Validates the length of an array to be equal to the length provided
validator.addRule<ValidatorArrLengthValue>('arrLength', (value: Value, length: ValidatorArrLengthValue) => isArray(value) && (length === value.length));

// Validates that the value has to be a string with length equal to
// or greater than the min length value specified.
validator.addRule<ValidatorMinLengthValue>('minLength', (value: Value, minLength: ValidatorMinLengthValue) => isString(value) && (minLength <= value.length));

// Validates that the value has to be an array with a length equal to
// or greater than the min length value specified.
validator.addRule<ValidatorArrMinLengthValue>('arrMinLength', (value: Value, minLength: ValidatorArrMinLengthValue) => isArray(value) && (minLength <= value.length));

// Validates that the value has to be a string with length equal to
// or less than the max length value specified.
validator.addRule<ValidatorMaxLengthValue>('maxLength', (value: Value, maxLength: ValidatorMaxLengthValue) => isString(value) && (maxLength >= value.length));

// Validates that the value has to be an array with length equal to
// or less than the max length value specified.
validator.addRule<ValidatorArrMaxLengthValue>('arrMaxLength', (value: Value, maxLength: ValidatorArrMaxLengthValue) => isArray(value) && (maxLength >= value.length));

// Validates that the value has to be a string and equal to or
// between the two numbers specified.
validator.addRule<ValidatorRangeValue>('rangeLength', (value: Value, range: ValidatorRangeValue) => isString(value) && value.length >= range[0] && value.length <= range[1]);

// Validates that the value has to be equal to one of the elements
// in the specified array. Case sensitive matching.
validator.addRule<ValidatorIncludeValue>('include', (value: Value, values: ValidatorIncludeValue) => includes(values, value));

// Validates that the value has to match the pattern specified.
// Can be a regular expression or the name of one of the built in
// patterns.
validator.addRule<ValidatorPatternValue>(
  'pattern',
  (
    value: Value,
    pattern: ValidatorPatternValue,
  ) => !isEmpty(value)
    && isString(value)
    && (isString(pattern) ? patterns[pattern] : pattern).test(value),
);

export default validator;
