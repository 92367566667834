import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import map from 'lodash/map';
import { MarketingGatewayPlatform as Platform } from '@flowio/api-internal-constants';
import FormName from '../constants/FormName';
import SelectFeedForms from '../components/SelectFeedForms';

import {
  createFeedsBulk,
  showFeedsCreationConfirmationDialog,
} from '../actions';

import {
  getFeedForms,
} from '../selectors';

import {
  getCountries,
} from '../../reference/selectors';
import { encodeFeedSource } from '../utilities/feedSourceDecoder';

const formName = FormName.SELECT_FEED_FORMS_FORM;

const handleSubmit = (
  { organizationId, forms = [], primaryForms = [] },
  dispatch,
) => dispatch(createFeedsBulk(organizationId, [...forms, ...primaryForms]))
  .then(() => dispatch(
    showFeedsCreationConfirmationDialog({
      countries: map(forms, (form) => form.country),
    }),
  ));

const getInitialValues = (props, primaryForms) => ({
  organizationId: props.organizationId,
  forms: [],
  primaryForms,
});

const mapStateToProps = (state, props) => {
  const {
    facebook_primary: facebookPrimaryForms = [],
    facebook_country_override: facebookCountryOverrideForms = [],
    google: googleForms = [],
  } = getFeedForms(state) || {};

  const forms = (props.platformId === Platform.GOOGLE
    ? googleForms
    : facebookCountryOverrideForms
  )
    .filter(
      (form) => !facebookPrimaryForms.find((f) => encodeFeedSource(f) === encodeFeedSource(form)),
    );

  return {
    countries: getCountries(state),
    initialValues: getInitialValues(props, facebookPrimaryForms),
    forms,
  };
};

const ConnectedSelectFeedFormsForm = reduxForm({
  form: formName,
  onSubmit: handleSubmit,
})(SelectFeedForms);

export default connect(
  mapStateToProps,
)(ConnectedSelectFeedFormsForm);
