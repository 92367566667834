import { createQueryParameters } from '@flowio/redux-filtering-paging-sorting';
import { OptinAttributesGetResponse } from '@flowio/api-internal-sdk';
import ActionTypes from '../constants/ActionTypes';
import {
  RootState, ThunkExtraArguments, ThunkResult, ThunkDispatcher,
} from '../../../stores/types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';

const fetchOptinAttributes = ({
  organizationId = '',
  pageNumber = 1,
  entriesPerPage = 25,

}): ThunkResult<Promise<OptinAttributesGetResponse>> => (
  dispatch: ThunkDispatcher,
  getState: () => RootState,
  extra: ThunkExtraArguments,
): Promise<OptinAttributesGetResponse> => {
  dispatch({
    type: ActionTypes.FETCH_OPTIN_ATTRIBUTES_REQUEST,
    meta: {
      parameters: {
        organizationId,
        pageNumber,
        entriesPerPage,
      },
    },
  });

  return extra.apiInternal(getState()).optinAttributes.get({
    organization: organizationId,
    ...createQueryParameters({ entriesPerPage, pageNumber }),
  }).then((response) => {
    if (response.ok) {
      dispatch({
        type: ActionTypes.FETCH_OPTIN_ATTRIBUTES_SUCCESS,
        payload: response.body,
        meta: {
          parameters: {
            organizationId,
            pageNumber,
            entriesPerPage,
          },
        },
      });
    } else {
      dispatch({
        type: ActionTypes.FETCH_OPTIN_ATTRIBUTES_FAILURE,
        payload: formatErrorResponseV2(response),
      });
    }
    return response;
  });
};

export default fetchOptinAttributes;
