import ActionTypes from '../constants/ActionTypes';
import { apiInternal } from '../../../utilities/clients';

export default function declinePendingUser(id) {
  return {
    types: [
      ActionTypes.DECLINE_PENDING_USER_REQUEST,
      ActionTypes.DECLINE_PENDING_USER_SUCCESS,
      ActionTypes.DECLINE_PENDING_USER_FAILURE,
    ],
    callAPI(state) {
      return apiInternal.users(state).putStatusById(id, {
        body: JSON.stringify({
          status: 'inactive',
        }),
      });
    },
    payload: { id },
  };
}
