import { Ellipsis } from '@flowio/react-icons';
import React, { MouseEventHandler } from 'react';
import capitalize from 'lodash/capitalize';
import { IconButton } from '@flowio/react-button';
import {
  Dropdown, DropdownMenu, DropdownItem, DropdownDivider,
} from '@flowio/react-dropdown';
import {
  TableRow,
  TableRowColumn,
} from '@flowio/react-table';
import get from 'lodash/get';
import noop from 'lodash/noop';
import { css } from '@emotion/css';
import FormattedDate from '../../../../../components/formatted-date/formatted-date';

import StatusLabel from '../../../../../components/status-label';
import { ExperimentStatusKey, ExperimentStatusTransitionLabel } from '../../../constants/experiment-status';
import { getLabelIntentByStatus } from '../../../utils';

const experimentRow = css`
  .menuButton {
    svg {
      pointer-events: none;
      cursor: pointer;
    }
  }
`;

interface ExperimentListTableRowProps {
  currentIndex: number;
  experiment: io.flow.internal.v0.unions.Experiment;
  onEditExperiment: (key: string) => void;
  onViewExperiment: (key: string) => void;
  onDeleteExperiment: (key: string) => void;
  onUpdateExperimentStatus: (
    experiment: io.flow.internal.v0.unions.Experiment,
    status: io.flow.internal.v0.enums.Status,
  ) => void;
}

const ExperimentListTableRow: React.FC<ExperimentListTableRowProps> = ({
  currentIndex,
  experiment,
  onEditExperiment,
  onViewExperiment,
  onDeleteExperiment,
  onUpdateExperimentStatus,
}) => {
  const handleViewExperimentClick: MouseEventHandler<HTMLTableRowElement> = (event): void => {
    const element = event.target as HTMLElement;
    if (element && element.tagName !== 'BUTTON' && element.tagName !== 'LI') {
      onViewExperiment(experiment.key);
    }
  };

  const handleStatusChangeClick = (status: io.flow.internal.v0.enums.Status) => (): void => {
    onUpdateExperimentStatus(experiment, status);
  };

  const handleEditClick = (): void => {
    onEditExperiment(experiment.key);
  };

  const handleDeleteClick = (): void => {
    onDeleteExperiment(experiment.key);
  };

  return (
    <TableRow
      striped={currentIndex % 2 === 1}
      key={experiment.id}
      className={experimentRow}
      selectable
      onClick={handleViewExperimentClick}
    >
      <TableRowColumn>
        {experiment.name}
      </TableRowColumn>
      <TableRowColumn>
        {capitalize(experiment.discriminator)}
      </TableRowColumn>
      <TableRowColumn>
        <StatusLabel
          content={experiment.status}
          intent={getLabelIntentByStatus(experiment.status)}
        />
      </TableRowColumn>
      <TableRowColumn>
        { get(experiment, 'started_at')
          ? <FormattedDate value={get(experiment, 'started_at')} format="MMMM D, YYYY" />
          : '-' }
      </TableRowColumn>
      <TableRowColumn>
        { get(experiment, 'ended_at')
          ? <FormattedDate value={get(experiment, 'ended_at')} format="MMMM D, YYYY" />
          : '-' }
      </TableRowColumn>
      <TableRowColumn textAlign="center">
        { experiment.status !== ExperimentStatusKey.ARCHIVED && (
          <Dropdown
            position="bottom right"
            onClick={(e) => e.stopPropagation()}
            trigger={(
              <IconButton type="button" size="small" icon={Ellipsis} className="menuButton" />
            )}
          >
            <DropdownMenu>
              {
              experiment.status === ExperimentStatusKey.DRAFT
                && <DropdownItem onClick={handleEditClick}>Edit</DropdownItem>
            }
              <DropdownDivider />
              {experiment.transitions?.map((transition) => (
                <DropdownItem
                  onClick={handleStatusChangeClick(transition)}
                >
                  {ExperimentStatusTransitionLabel[transition]}
                </DropdownItem>
              ))}
              <DropdownDivider />
              {
              (experiment.status === ExperimentStatusKey.DRAFT
                || experiment.status === ExperimentStatusKey.SCHEDULED)
                && <DropdownItem onClick={handleDeleteClick}>Delete</DropdownItem>
            }
            </DropdownMenu>
          </Dropdown>
        )}
      </TableRowColumn>
    </TableRow>
  );
};

ExperimentListTableRow.displayName = 'ExperimentListTableRow';

ExperimentListTableRow.defaultProps = {
  onEditExperiment: noop,
  onViewExperiment: noop,
  onUpdateExperimentStatus: noop,
  onDeleteExperiment: noop,
};

export default ExperimentListTableRow;
