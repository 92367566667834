import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Dialog } from '@flowio/react-dialog';
import { Box } from '@flowio/react-box';
import colors from '../../../../theme/tokens/colors';

interface Props {
  isOpen: boolean;
  topContent?: JSX.Element | string;
  title?: JSX.Element | string;
  subtitle?: JSX.Element | string;
  form?: JSX.Element;
}

const dialogStyle = css`
  max-width: 480px;
  padding: 0;
`;

const ActionsContent = styled(Box)({
  marginTop: '32px',
});

const TopContent = styled.div({
  minHeight: '300px',
  backgroundColor: colors.neutral[200],
  position: 'relative',
  overflow: 'hidden',
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'center',
  alignItems: 'center',
});

const BottomContent = styled.div({
  backgroundColor: colors.white,
  margin: '24px',
  display: 'flex column',
});

const Title = styled.h3({
  margin: '8px 0',
  color: colors.marketing['dark-slate-blue'],
});

const Subtitle = styled.p({
  color: colors.marketing['light-slate-gray'],
});

const MarketingDialog: React.FC<Props> = ({
  children,
  isOpen,
  topContent,
  title,
  subtitle,
  form = null,
}) => {
  if (isOpen) {
    return (
      <Dialog
        backdrop
        open
        css={dialogStyle}
      >
        {topContent && (
          <TopContent data-automation-id="marketing-dialog">
            {topContent}
          </TopContent>
        )}
        <BottomContent data-automation-id="marketing-dialog">
          {title && <Title>{title}</Title>}
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
          {form}
          <ActionsContent
            justifyContent="end"
            spacing="loose"
            data-automation-id="marketing-dialog-actions"
          >
            {children}
          </ActionsContent>
        </BottomContent>
      </Dialog>
    );
  }
  return null;
};

MarketingDialog.displayName = 'MarketingDialog';

export default MarketingDialog;
