import { InvitationsPostResponse } from '@flowio/api-sdk';
import ActionTypes from '../constants/action-types';
import { ThunkDispatcher, RootState, ThunkExtraArguments } from '../../../stores/types';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import { PropType } from '../../../types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';
import toLegacyResponse from '../../../utilities/clients/to-legacy-response';

type ResponseType = PropType<InvitationsPostResponse, 'body'>;

export default function sendInvitation(invitationForm: io.flow.v0.models.InvitationForm) {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<LegacyResponse<ResponseType>> => {
    dispatch({
      type: ActionTypes.SEND_INVITATION_REQUEST,
    });

    return extra.api(getState()).invitations.post({
      body: invitationForm,
    }).then((response) => {
      if (response.ok) {
        dispatch({
          type: ActionTypes.SEND_INVITATION_SUCCESS,
          payload: response.body,
        });
      } else {
        dispatch({
          type: ActionTypes.SEND_INVITATION_FAILURE,
          payload: formatErrorResponseV2(response),
        });
      }
      return toLegacyResponse<ResponseType>(response);
    });
  };
}
