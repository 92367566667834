import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment-timezone';

import getUnhandledProps from '../../utilities/react/get-unhandled-props';

const SECOND = 1000;
const MINUTE = 1000 * 60;
const HOUR = 1000 * 60 * 60;
const DAY = 1000 * 60 * 60 * 24;

// The maximum timer delay value is a 32-bit signed integer.
// See: https://mdn.io/setTimeout
const MAX_TIMER_DELAY = 2147483647;

function selectUnits(delta) {
  const absDelta = Math.abs(delta);

  if (absDelta < MINUTE) {
    return 'second';
  }

  if (absDelta < HOUR) {
    return 'minute';
  }

  if (absDelta < DAY) {
    return 'hour';
  }

  // The maximum scheduled delay will be measured in days since the maximum
  // timer delay is less than the number of milliseconds in 25 days.
  return 'day';
}

function getUnitDelay(units) {
  switch (units) {
    case 'second': return SECOND;
    case 'minute': return MINUTE;
    case 'hour': return HOUR;
    case 'day': return DAY;
    default: return MAX_TIMER_DELAY;
  }
}

export default class FormattedRelative extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      now: moment().valueOf(),
    };
  }

  componentDidMount() {
    this.scheduleNextUpdate();
  }

  componentDidUpdate() {
    this.scheduleNextUpdate();
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  scheduleNextUpdate() {
    const { updateInterval, value } = this.props;
    const { now } = this.state;

    // If the `updateInterval` is falsy, including `0`, then auto updates
    // have been turned off, so we bail and skip scheduling an update.
    if (!updateInterval) {
      return;
    }

    const time = moment(value).valueOf();
    const delta = time - now;
    const units = selectUnits(delta);
    const unitDelay = getUnitDelay(units);
    const unitRemainder = Math.abs(delta % unitDelay);

    // We want the largest possible timer delay which will still display
    // accurate information while reducing unnecessary re-renders. The delay
    // should be until the next "interesting" moment, like a tick from
    // "1 minute ago" to "2 minutes ago" when the delta is 120,000ms.
    const delay = delta < 0
      ? Math.max(updateInterval, unitDelay - unitRemainder)
      : Math.max(updateInterval, unitRemainder);

    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      this.setState({ now: moment().valueOf() });
    }, delay);
  }

  render() {
    const { value } = this.props;
    const { now } = this.state;

    const unhandledProps = getUnhandledProps(FormattedRelative, this.props);

    return (
      <time {...unhandledProps} dateTime={value}>
        {moment.tz(value, moment.tz.guess()).from(now)}
      </time>
    );
  }
}
FormattedRelative.displayName = 'FormattedRelative';

FormattedRelative.propTypes = {
  updateInterval: PropTypes.number,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]).isRequired,
};

FormattedRelative.defaultProps = {
  updateInterval: 10 * SECOND,
};
