import React from 'react';
import {
  Dialog, DialogHeader, DialogFooter, DialogBody,
} from '@flowio/react-dialog';
import { Button } from '@flowio/react-button';

import ExclusionsExportForm from './ExclusionsExportForm';
import * as styles from './styles';

interface ExclusionsExportProps {
  isOpen: boolean;
  onCloseExportDialog: () => void;
  onSubmitExport: () => void;
}

const ExclusionsExport: React.FC<ExclusionsExportProps> = ({
  isOpen,
  onCloseExportDialog,
  onSubmitExport,
}) => (
  <Dialog
    className={styles.exportDialog}
    backdrop
    closeOnEscape
    open={isOpen}
  >
    <DialogHeader content="Export Exclusions" />
    <DialogBody>
      <div>
        An email containing the current exclusions will be sent.
        Please note this might take a few minutes.
      </div>
      {/* Need this because a form inside a dialog technically isnt a child. This means
          Form will never think it is in a component decorated with reduxForm */}
      <ExclusionsExportForm />
    </DialogBody>
    <DialogFooter className={styles.exportDialogFooter}>
      <Button className={styles.cancelBtn} content="Cancel" onClick={onCloseExportDialog} />
      <Button className={styles.exportBtn} content="Export" intent="primary" onClick={onSubmitExport} />
    </DialogFooter>
  </Dialog>
);

export default ExclusionsExport;
