import { connect } from 'react-redux';
import { reduxForm, FormSubmitHandler, DecoratedFormProps } from 'redux-form';
import { MarketingGatewayPlatform as Platform } from '@flowio/api-internal-constants';
import get from 'lodash/get';
import SelectFeedSource from '../components/SelectFeedSource';
import FormName from '../constants/FormName';
import SchemaCompatibility from '../constants/SchemaCompatibility';
import {
  getFeedSources,
  getSelectedFeedSource,
} from '../selectors';

import { isGoogleFeedSource } from '../types/utilities';
import {
  fetchFeedForms,
  changeSelectedFeedSource,
} from '../actions';

import { RootState, ThunkDispatcher, RootActionTypes } from '../../../stores/types';
import { MarketingGatewayIntegratedPlatform, SelectFeedSourceFormProps } from '../types';
import { doesMatch } from '../utilities/feedSourceDecoder';

const formName = FormName.SELECT_FEED_SOURCE_FORM;

export interface OwnProps {
  organizationId: string;
  platformId: MarketingGatewayIntegratedPlatform;
  accountId?: string;
}

export interface StateProps {
  feedSources: io.flow.internal.v0.unions.MarketingGatewayFeedSource[];
  initialValues: Partial<SelectFeedSourceFormProps>;
}

const handleSubmit: FormSubmitHandler<SelectFeedSourceFormProps, OwnProps & StateProps> = (
  values: SelectFeedSourceFormProps,
  dispatch: ThunkDispatcher,
  props: DecoratedFormProps<SelectFeedSourceFormProps, OwnProps & StateProps>,
): Promise<RootActionTypes> => {
  const feedSource = values.feed_source;

  return dispatch(fetchFeedForms({
    organizationId: values.organizationId,
    schemaCompatibilities: values.schema_compatibility,
    feedSource,
  })).then(() => dispatch(
    changeSelectedFeedSource(values.feed_source, props.platformId),
  ));
};

const mapStateToProps = (
  state: RootState,
  { platformId, organizationId, accountId }: OwnProps,
): StateProps => {
  const selectedFeedSource = getSelectedFeedSource(state);

  const feedSources: io.flow.internal.v0.unions.MarketingGatewayFeedSource[] = getFeedSources(
    state,
  );

  let feedSource: io.flow.internal.v0.unions.MarketingGatewayFeedSource | undefined;

  if (feedSources.length === 1) {
    // if there is no choice then we preselect the only option
    [feedSource] = feedSources;
  } else if (platformId !== Platform.GOOGLE) {
    if (accountId) {
      feedSource = feedSources.find((s) => isGoogleFeedSource(s) && doesMatch(accountId, s));
    }
  } else {
    // otherwise we use whatever is currently selected, if any
    feedSource = get(selectedFeedSource, 'source');
  }

  const initialValues = {
    organizationId,
    schema_compatibility: platformId === 'facebook' || platformId === 'google' ? SchemaCompatibility[platformId] : [],
    feed_source: feedSource,
  };

  return {
    feedSources,
    initialValues,
  };
};

const ConnectedSelectFeedSourceForm = reduxForm<SelectFeedSourceFormProps, OwnProps & StateProps>({
  form: formName,
  onSubmit: handleSubmit,
})(SelectFeedSource);

export default connect(
  mapStateToProps,
)(ConnectedSelectFeedSourceForm);
