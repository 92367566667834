import update from 'immutability-helper';

import ActionTypes from '../constants/action-types';
import { AnalyticsState } from '../types';
import { RootActionTypes } from '../../../stores/types';

const defaultState: AnalyticsState['gmvForm'] = {};

export default function (
  state: AnalyticsState['gmvForm'] = defaultState,
  action: RootActionTypes,
): AnalyticsState['gmvForm'] {
  switch (action.type) {
    case ActionTypes.CHANGE_CURRENT_DATE:
      return update(state, {
        currentDate: { $set: action.payload },
      });
    case ActionTypes.CHANGE_INTERVAL:
      return update(state, {
        interval: { $set: action.payload },
      });
    case ActionTypes.CHANGE_REGION:
      return update(state, {
        region: { $set: action.payload },
      });
    case ActionTypes.CHANGE_DATE_RANGE:
      return update(state, {
        startDate: { $set: action.payload.startDate },
        endDate: { $set: action.payload.endDate },
      });
    default:
      return state;
  }
}
