import React from 'react';

import AuthLayout from '../auth-layout';

const RegistrationConfirmation: React.FC<{}> = () => (
  <AuthLayout>
    <p>
      Thank you, your sign up request has been received.
    </p>
    <p>
      You will receive an email after your request has been approved,
      at which point you may log in.
    </p>
  </AuthLayout>
);

RegistrationConfirmation.displayName = 'RegistrationConfirmation';

export default RegistrationConfirmation;
