import ActionTypes from '../constants/ActionTypes';
import { MarketingActions } from '../types';
import standardAction from '../../../utilities/standard-action';

export interface ConfirmationDialogData {
  accountId: number;
  organizationId: string;
}

const showConnectionConfirmationDialog = (
  data: ConfirmationDialogData,
): MarketingActions => standardAction(
  ActionTypes.CONNECTION_CONFIRMATION_DIALOG_OPEN,
  data,
);

export default showConnectionConfirmationDialog;
