import React from 'react';
import get from 'lodash/get';
import moment from 'moment';
import {
  Table,
  TableHead,
  TableRow, TableRowColumn,
  TableHeadColumn,
  TableBody,
} from '@flowio/react-table';
import MarketingCard, { MarketingCardHeading } from '../MarketingCard';
import withNumberFormat from '../../../../utilities/numbers/withNumberFormat';
import {
  findCountryByCode,
  findLanguageByCode,
} from '../../utilities/findReferenceData';
import marketingTableStyle from '../../styles/styles';
import {
  GOOGLE_DATA_SOURCES_TITLE,
  GOOGLE_DATA_SOURCES_SUBTITLE,
} from '../../constants/Feeds';

interface Props {
  countries: io.flow.v0.models.Country[];
  languages: io.flow.v0.models.Language[];
  feeds: io.flow.internal.v0.models.MarketingGatewayFeedSourceGoogle[];
}

const GoogleDataSources: React.FC<Props> = ({
  feeds,
  countries,
  languages,
}) => {
  const getFeedTableRow = (
    feed: io.flow.internal.v0.models.MarketingGatewayFeedSourceGoogle,
  ): JSX.Element => {
    const country = findCountryByCode(countries, feed.country);
    const language = findLanguageByCode(languages, feed.language);
    const lastUploaded = moment(get(feed, 'last_uploaded')).format('MMMM Do YYYY, hh:mm');

    return (
      <TableRow key={feed.country}>
        <TableRowColumn>{feed.name}</TableRowColumn>
        <TableRowColumn>{get(country, 'name')}</TableRowColumn>
        <TableRowColumn textAlign="center">{get(language, 'name')}</TableRowColumn>
        <TableRowColumn>{lastUploaded}</TableRowColumn>
        <TableRowColumn textAlign="center">{feed.item_count ? withNumberFormat(feed.item_count) : '-'}</TableRowColumn>
      </TableRow>
    );
  };

  return (
    <MarketingCard boxShadow>
      <MarketingCardHeading
        showDivider={false}
        title={GOOGLE_DATA_SOURCES_TITLE}
        subtitle={GOOGLE_DATA_SOURCES_SUBTITLE}
      />
      <br />
      <Table css={marketingTableStyle()}>
        <TableHead>
          <TableRow>
            <TableHeadColumn>Feed Name</TableHeadColumn>
            <TableHeadColumn>Country of Sale</TableHeadColumn>
            <TableHeadColumn textAlign="center">Language</TableHeadColumn>
            <TableHeadColumn>Last Upload</TableHeadColumn>
            <TableHeadColumn textAlign="center">Products</TableHeadColumn>
          </TableRow>
        </TableHead>
        <TableBody>{feeds.map(getFeedTableRow)}</TableBody>
      </Table>

    </MarketingCard>
  );
};

GoogleDataSources.displayName = 'GoogleDataSources';

export default GoogleDataSources;
