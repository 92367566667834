import update from 'immutability-helper';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import slice from 'lodash/slice';
import map from 'lodash/map';
import { RootActionTypes } from '../../../stores/types';
import ActionType from '../constants/ActionType';
import { ExclusionRulePaginationState, PageStatus } from '../types';

const initialState: ExclusionRulePaginationState = {
  entities: {},
  pagination: {
    currentPage: 1,
    pages: {},
  },
};

export default function (
  state: ExclusionRulePaginationState = initialState,
  action: RootActionTypes,
) {
  switch (action.type) {
    case ActionType.FetchExclusionRulesRequest:
      return update(state, {
        pagination: {
          pages: {
            [action.meta.parameters.pageNumber]: {
              $set: {
                parameters: {
                  entriesPerPage: action.meta.parameters.entriesPerPage,
                  pageNumber: action.meta.parameters.pageNumber,
                  organizationId: action.meta.parameters.organizationId,
                },
                isFirstPage: get(state, `pagination.pages[${action.meta.parameters.pageNumber}].isFirstPage`, true),
                isLastPage: get(state, `pagination.pages[${action.meta.parameters.pageNumber}].isLastPage`, false),
                entities: get(state, `pagination.pages[${action.meta.parameters.pageNumber}].entities`, []),
                status: PageStatus.LOADING,
              },
            },
          },
        },
      });
    case ActionType.FetchExclusionRulesSuccess:
      return update(state, {
        entities: {
          $merge: keyBy(slice(action.payload, 0, action.meta.parameters.entriesPerPage), 'id'),
        },
        pagination: {
          currentPage: {
            $set: action.meta.parameters.pageNumber,
          },
          pages: {
            [action.meta.parameters.pageNumber]: {
              $merge: {
                error: undefined,
                isFirstPage: action.meta.parameters.pageNumber === 1,
                isLastPage:
                  action.payload.length
                  <= slice(action.payload, 0, action.meta.parameters.entriesPerPage).length,
                entities: map(slice(action.payload, 0, action.meta.parameters.entriesPerPage), 'id'),
                status: PageStatus.FULFILLED,
              },
            },
          },
        },
      });
    case ActionType.FetchExclusionRulesFailure:
      return update(state, {
        pagination: {
          pages: {
            [action.meta.parameters.pageNumber]: {
              $merge: {
                error: action.payload,
                status: PageStatus.REJECTED,
              },
            },
          },
        },
      });
    case ActionType.DeleteExclusionRuleSuccess:
      return update(state, {
        entities: {
          [action.meta.exclusionRuleId]: {
            $set: action.payload,
          },
        },
      });
    default:
      return state;
  }
}
