// import assign from 'lodash/assign';
import ActionTypes from '../constants/action-types';
import { ExperienceState } from '../types';
import { RootActionTypes } from '../../../stores/types';

type State = ExperienceState['entities']['statistics'];

const initialState: State = {};

export default function (state: State = initialState, action: RootActionTypes): State {
  switch (action.type) {
    case ActionTypes.GET_SUBCATALOG_STATISTICS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
