import { ExperiencesPutPricingByKeyResponse } from '@flowio/api-sdk';
import ActionTypes from '../constants/action-types';
import {
  ThunkExtraArguments,
  RootState,
  ThunkDispatcher,
  ThunkResult,
} from '../../../stores/types';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import toLegacyResponse from '../../../utilities/clients/to-legacy-response';
import { PropType } from '../../../types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';
import clearActivePricingSection from './clearActivePricingSection';
import { createToast } from '../../console/actions';
import { getPricing } from '../selectors';

type ResponseType = PropType<ExperiencesPutPricingByKeyResponse, 'body'>;

/**
 * Update the price_display of an experience
 */

export default function updatePricing(
  organization: string,
  experienceKey: string,
  pricing: io.flow.v0.models.Pricing,
  updateDisplay: boolean,
): ThunkResult<Promise<LegacyResponse<ResponseType>>> {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<LegacyResponse<ResponseType>> => {
    const state = getState();
    const resolvedPricing: io.flow.v0.models.Pricing = updateDisplay ? {
      ...pricing,
      rounding: getPricing(state).rounding,
    } : pricing;

    dispatch({
      type: ActionTypes.UPDATE_PRICING_SUBMIT,
      organization,
      experienceKey,
      pricing: resolvedPricing,
    });
    return extra.api(getState()).experiences.putPricingByKey({
      organization,
      key: experienceKey,
      body: resolvedPricing,
    }).then((response) => {
      if (response.ok) {
        dispatch({
          type: ActionTypes.UPDATE_PRICING_SUCCESS,
          payload: response.body,
          organization,
          experienceKey,
          pricing: resolvedPricing,
        });
        dispatch(clearActivePricingSection());
        dispatch(createToast({
          content: 'Updated pricing display successfully',
          intent: 'positive',
          icon: 'CheckCircle',
        }));
      } else {
        dispatch({
          type: ActionTypes.UPDATE_PRICING_FAILURE,
          payload: formatErrorResponseV2(response),
        });
        dispatch(createToast({
          content: response.body ? response.body.messages.join(' ') : 'Failed to update pricing',
          intent: 'negative',
          icon: 'ErrorOutline',
        }));
      }
      return toLegacyResponse<ResponseType>(response);
    });
  };
}
