import React, { useCallback, useState } from 'react';
import noop from 'lodash/noop';
import { Button } from '@flowio/react-button';
import { TextInputField } from '@flowio/react-text-input';
import getErrorMessageForField from '../../../../../utilities/react/get-error-message-for-field';
import { Props } from '../types';

const OrganizationCreate: React.FC<Props> = ({
  onRequestChangeOrganizationKey,
  onRequestChangeOrganizationName,
  onRequestCreateOrganization,
  user,
  errors,
}) => {
  const [orgName, setOrgName] = useState<string>();
  const [orgKey, setOrgKey] = useState<string>();

  const handleNameChange = useCallback((value) => {
    setOrgName(value);
    onRequestChangeOrganizationName(value);
  }, [onRequestChangeOrganizationName]);

  const handleKeyChange = useCallback((value) => {
    setOrgKey(value);
    onRequestChangeOrganizationKey(value);
  }, [onRequestChangeOrganizationKey]);

  const handleSubmit = useCallback((event) => {
    event.preventDefault();

    if (!user) {
      throw new Error('User not found');
    }

    const { auth } = user;

    onRequestCreateOrganization(auth, orgName, orgKey);
  }, [user, onRequestCreateOrganization, orgName, orgKey]);

  return (
    <section className="page-account full-screen flex items-center">
      <section className="col-4 mx-auto">
        {/* Commenting this out as part of TS conversion as the whole
            Error handling is borked as is and needs re-working

        <Errors errors={errors} /> */}
        <p className="center h2 mb0">
          New Organization
        </p>
        <form
          className="center"
          name="org_create_form"
          method="POST"
          action="/organization/create"
          onSubmit={handleSubmit}
        >
          <TextInputField
            className="col-10"
            style={{
              margin: '0 auto',
              paddingTop: '1rem',
            }}
            hintText="Organization Name"
            feedback={getErrorMessageForField('name', errors)}
            intent={getErrorMessageForField('name', errors) ? 'negative' : 'neutral'}
            name="org_name"
            fluid
            required
            onValueChange={handleNameChange}
          />
          <TextInputField
            fluid
            className="col-10"
            style={{
              margin: '0 auto',
              paddingTop: '1rem',
            }}
            name="org_key"
            feedback={getErrorMessageForField('id', errors)}
            intent={getErrorMessageForField('id', errors) ? 'negative' : 'neutral'}
            hintText="Unique Identifier for Organization"
            required
            onValueChange={handleKeyChange}
          />
          <Button
            className="col-10 mt2"
            content="Create Organization"
            name="create_org"
            intent="primary"
            type="submit"
          />
        </form>
      </section>
    </section>
  );
};

OrganizationCreate.displayName = 'OrganizationCreate';

OrganizationCreate.defaultProps = {
  errors: undefined,
  onRequestCreateOrganization: noop,
  onRequestChangeOrganizationKey: noop,
  onRequestChangeOrganizationName: noop,
};

export default OrganizationCreate;
