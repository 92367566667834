import { ActionTypes, ActivityKind } from '../constants';
import createActivity from './createActivity';
import { getOrganizationId } from '../../organization';
import { ThunkResult } from '../../../stores/types';
import { ChangeItemQuantityPayload } from '../types/actions';

const changeItemQuantity = ({
  number,
  quantity,
  index,
}: ChangeItemQuantityPayload): ThunkResult<void> => (dispatch, getState) => {
  const organizationId = getOrganizationId(getState());
  dispatch({
    type: ActionTypes.CHANGE_ITEM_QUANTITY,
    payload: { number, quantity, index },
  });

  dispatch(createActivity({
    kind: ActivityKind.CHANGE_QUANTITY,
    number,
    quantity,
    organizationId,
  }));
};

export default changeItemQuantity;
