const AccountSelection = {
  SELECT_GMC_ACCOUNT_TITLE: (hasMultiple: boolean): string => `${hasMultiple ? 'Select a ' : ''}Google Merchant Center account`,
  SELECT_GMC_ACCOUNT_SUBTITLE: (hasMultiple: boolean): string => `${hasMultiple ? 'Select an account to import' : 'Your Google account for importing'} product data for localization`,
  NO_GMC_ACCOUNTS_FOUND: 'No accounts were found. Are you sure this Google Account has access to the Merchant Center?',
  NO_GMC_FEEDS_FOUND: 'At least one approved feed is required in the selected account for Flow to create localized feeds. Please set up a product feed in your account.',
  CONNECTED_CONFIRM_DIALOG_TITLE: 'You have now connected Google Merchant Center to Flow and selected an account for your source data.',
  CONNECTED_CONFIRM_DIALOG_SUBTITLE: 'Step 2 is to choose your soure data and target countries. Once you\'ve reviewed your localized data you can create localized product feeds for your Google ads!',
  DISCONNECT_GMC_DIALOG_TITLE: 'Cancel connecting to Google Merchant Center',
  DISCONNECT_GMC_DIALOG_SUBTITLE: 'Flow will not have access to your Google Merchant Center account information.',
  MULTIPLE_ACCOUNTS_DIALOG_TITLE: (numAccounts: number): string => `You have ${numAccounts} Google Merchant Center accounts associated with your email account.`,
  MULTIPLE_ACCOUNTS_DIALOG_SUBTITLE: 'Be sure to select the right Google Merchant Center account and then the data source that you want to localize with Flow.',
};

export default AccountSelection;
