import React from 'react';
import { Box } from '@flowio/react-box';
import { Button } from '@flowio/react-button';
import {
  Worksheet, WorksheetHeader, WorksheetTitle, WorksheetBody,
} from '@flowio/react-worksheet';

import OptinContentForm from '../../OptinContentForm/containers/OptinContentForm';
import { Props } from '../types';

const OptinContentWorksheet: React.FC<Props> = ({
  languages,
  regions,
  optinAttribute,
  initialValues,
  isCancelDisabled,
  isSaveDisabled,
  onCancel,
  onSave,
  onSubmitSuccess,
  open,
}) => (
  <Worksheet open={open}>
    <WorksheetHeader>
      <Box flexAuto justifyContent="between">
        <Button content="Cancel" disabled={isCancelDisabled} onClick={onCancel} />
        <WorksheetTitle content="Add Content" />
        <Button content="Save" disabled={isSaveDisabled} intent="primary" onClick={onSave} />
      </Box>
    </WorksheetHeader>
    <WorksheetBody>
      <OptinContentForm
        initialValues={initialValues}
        languages={languages}
        onSubmitSuccess={onSubmitSuccess}
        optinAttribute={optinAttribute}
        regions={regions}
      />
    </WorksheetBody>
  </Worksheet>
);

OptinContentWorksheet.displayName = 'OptinContentWorksheet';

export default OptinContentWorksheet;
