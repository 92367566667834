import { combineReducers } from 'redux';
import { ActionTypes } from '../constants';
import createStatusReducer from '../../../utilities/redux/createStatusReducer';

const catalog = createStatusReducer([
  ActionTypes.CREATE_CATALOG_QUERY_BUILDER_REQUEST,
  ActionTypes.CREATE_CATALOG_QUERY_BUILDER_SUCCESS,
  ActionTypes.CREATE_CATALOG_QUERY_BUILDER_FAILURE,
]);

const center = createStatusReducer([
  ActionTypes.CREATE_CATALOG_QUERY_BUILDER_REQUEST,
  ActionTypes.CREATE_CATALOG_QUERY_BUILDER_SUCCESS,
  ActionTypes.CREATE_CATALOG_QUERY_BUILDER_FAILURE,
]);

export default combineReducers({ catalog, center });
