import ActionTypes from '../constants/ActionTypes';
import {
  MarketingActions,
  MarketingFeedsCreationConfirmationDialogState,
} from '../types';

const defaultState: MarketingFeedsCreationConfirmationDialogState = {
  showDialog: false,
};

export default function feedsCreationConfirmationDialogReducer(
  state = defaultState,
  action: MarketingActions,
): MarketingFeedsCreationConfirmationDialogState {
  switch (action.type) {
    case ActionTypes.FEEDS_CREATION_CONFIRMATION_DIALOG_OPEN:
      return {
        ...state,
        showDialog: true,
        data: action.data,
      };
    case ActionTypes.FEEDS_CREATION_CONFIRMATION_DIALOG_CLOSE:
      return {
        ...state,
        showDialog: false,
        data: undefined,
      };
    default:
      return state;
  }
}
