import React, { FunctionComponent } from 'react';
import {
  Error,
} from '@flowio/react-icons';

const ErrorIcon: FunctionComponent = () => {
  const iconContainerStyle = {
    left: '7px',
    top: '17px',
  };

  const iconStyle = {
    color: '#D50000',
    height: '20px',
    width: '20px',
  };

  return (
    <div className="absolute" style={iconContainerStyle}>
      <Error className="absolute" style={iconStyle} />
    </div>
  );
};

export default ErrorIcon;
