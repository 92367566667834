import { $HttpResponse } from '@flowio/api-sdk';
import ActionTypes from '../constants/ActionTypes';
import {
  RootState, ThunkExtraArguments, ThunkResult, ThunkDispatcher,
} from '../../../stores/types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';
import standardAction from '../../../utilities/standard-action';

const createOptinAttribute = (
  organizationId: string,
  optinAttributeKey: string,
  optinAttributeForm: io.flow.internal.v0.models.OptinAttributeForm,
): ThunkResult<Promise<$HttpResponse>> => (
  dispatch: ThunkDispatcher,
  getState: () => RootState,
  extra: ThunkExtraArguments,
): Promise<$HttpResponse> => {
  dispatch(standardAction(ActionTypes.CREATE_OPTIN_ATTRIBUTE_REQUEST));

  return extra.apiInternal(getState()).optinAttributes.putByKey({
    organization: organizationId,
    key: optinAttributeKey,
    body: optinAttributeForm,
  }).then((response) => {
    if (response.ok) {
      dispatch(standardAction(ActionTypes.CREATE_OPTIN_ATTRIBUTE_SUCCESS, response.body));
      return response;
    }

    dispatch({
      type: ActionTypes.CREATE_OPTIN_ATTRIBUTE_FAILURE,
      payload: formatErrorResponseV2(response),
    });
    return response;
  });
};

export default createOptinAttribute;
