import { connect } from 'react-redux';
import map from 'lodash/map';
import Select from '../components/select';

function mapStateToProps({ user }) {
  return {
    organizations:
      map(user.memberships, 'organization')
        .filter((o) => o.environment === 'production'),
  };
}

export default connect(mapStateToProps)(Select);
