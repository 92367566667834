import React, { useState } from 'react';
import { Button } from '@flowio/react-button';
import { Confirm } from '@flowio/react-confirm';
import { Checkbox } from '@flowio/react-checkbox';
import { Copy } from '@flowio/react-icons';
import { useDispatch, useSelector } from 'react-redux';
import { browserHistory } from 'react-router';
import { ThunkDispatcher } from '../../../../stores/types';
import { checkFeatureValueByKey } from '../../../console';
import FeatureKey from '../../../console/constants/FeatureKey';
import { createReplacementOrder } from '../../actions/replacementOrder';
import LocalStorageMap from '../../../../utilities/localStorageMap/localStorageMap';
import { createToast } from '../../../console/actions';
import { getIsCustomerServiceUser } from '../../../user';

interface ReplaceOrderButtohProps {
  organizationId: string;
  order: io.flow.v0.models.Order;
}

const ReplaceOrderButtoh: React.FC<ReplaceOrderButtohProps> = ({
  organizationId,
  order,
}) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [suppress, setSuppress] = useState<boolean>(false);
  const dispatch = useDispatch<ThunkDispatcher>();

  const suppressDialogMap = new LocalStorageMap<boolean>('suppressDialogs');

  const isGlobalReplacementOrdersEnabled = useSelector(
    checkFeatureValueByKey(FeatureKey.GLOBAL_REPLACEMENT_ORDER),
  );
  const isCustomerServiceUser = useSelector(getIsCustomerServiceUser);

  const onCreate = () => {
    if (suppress) {
      suppressDialogMap.setValue('replaceOrder', true);
    }

    dispatch(
      createReplacementOrder(organizationId, order.number),
    ).then((replacementOrder) => {
      setShowDialog(false);
      browserHistory.push(`/${organizationId}/orders/replacement/${replacementOrder.replacement_order.number}`);
    }).catch((error: Error) => {
      setShowDialog(false);
      dispatch(createToast({
        intent: 'negative',
        content: `Error creating Replacement Order: ${error.message}`,
      }));
    });
  };

  const handleReplaceOrderButtonPress = (): void => {
    if (suppressDialogMap.getValue('replaceOrder') === true) {
      onCreate();
    } else {
      setShowDialog(true);
    }
  };

  return (
    <>
      {isGlobalReplacementOrdersEnabled && !isCustomerServiceUser && (
        <Button
          content="Replace Order"
          intent="primary"
          disabled={!order.submitted_at}
          leftIcon={Copy}
          onClick={handleReplaceOrderButtonPress}
          variant="outline"
        />
      )}
      <Confirm
        open={showDialog}
        caption={`Creating a Replacement Order for Order ${order.number}`}
        okText="Create Order"
        onOk={onCreate}
        onCancel={() => setShowDialog(false)}
      >
        <p>
          &#8226; The shipping address, items, delivery options and order summary details
          will be copied.
        </p>
        <p>
          &#8226; Replacement orders do not require any forms of payment.
        </p>
        <p>
          &#8226; The shipping, duties, taxes and other non-item fees and cost of the replacement
          order will be billed to your organization.
        </p>
        <p>
          &#8226; Open replacement orders that are not submitted after 7 days will be deleted.
        </p>
        <p>
          <Checkbox
            labelText="Do not remind me again"
            onChange={(event) => setSuppress(event.currentTarget.checked)}
          />
        </p>
      </Confirm>
    </>
  );
};

export default ReplaceOrderButtoh;
