import React from 'react';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import debounce from 'lodash/debounce';
import {
  Card, CardHeader, CardContent, CardFooter, CardEmptyState, CardTitle,
} from '@flowio/react-card';
import { TextInput } from '@flowio/react-text-input';

import { Select } from '@flowio/react-select';

import OrderDetailSearchResults from '../order-detail-search-results';
import Pagination from '../../../../components/pagination';
import { OrderDetailSearchViewProps } from '../../types';
import * as styles from './order-detail-search-view.styles';

const displayName = 'OrderDetailSearchView';

const OrderDetailSearchView: React.FC<OrderDetailSearchViewProps> = ({
  searchText = '',
  searchResults = {},
  pageNumber = '1',
  onRequestNextPage = noop,
  onRequestOrderDetailSelection = noop,
  onRequestPreviousPage = noop,
  onRequestSearch = noop,
  onRequestStatusFilterChange = noop,
  onRequestFraudStatusFilterChange = noop,
  onRequestOrderTypeFilterChange = noop,
  statusFilterValue = 'any',
  fraudStatusFilterValue = 'all',
  orderTypeFilterValue = 'all',
  baseCurrency = '',
  organizationCountry = '',
  isGlobalReplacementOrdersEnabled = false,
}) => (
  <div className={styles.orderDetailSearchView}>
    <Card>
      <CardHeader dividing>
        <CardTitle>
          Order Search
        </CardTitle>

        <div className={styles.filters}>
          <TextInput
            className={styles.searchFilter}
            fluid
            defaultValue={searchText}
            hintText="Order number, customer name, tracking number &amp; more"
            onValueChange={debounce((search) => {
              if (onRequestSearch) {
                onRequestSearch(search);
              }
            }, 550)}
          />

          <div className={styles.statusFilter}>
            <div className={styles.statusLabel}>
              Order Type:
            </div>
            <Select
              textInputProps={{
                id: 'status',
              }}
              size="medium"
              fluid
              value={statusFilterValue}
              options={[
                { content: 'All', value: 'all' },
                { content: 'Open', value: 'open' },
                { content: 'Submitted', value: 'submitted' },
              ]}
              onValueChange={(value) => { onRequestStatusFilterChange({ value }); }}
            />
          </div>

          { isGlobalReplacementOrdersEnabled && (
            <div className={styles.statusFilter}>
              <div className={styles.statusLabel}>
                Order Type:
              </div>
              <Select
                textInputProps={{
                  id: 'order_type',
                }}
                size="medium"
                fluid
                onValueChange={(value) => { onRequestOrderTypeFilterChange({ value }); }}
                value={orderTypeFilterValue}
                options={[
                  { content: 'All', value: 'all' },
                  { content: 'Standard', value: 'standard' },
                  { content: 'Replacement', value: 'replacement' },
                ]}
              />
            </div>
          ) }

          <div className={styles.statusFilter}>
            <div className={styles.statusLabel}>
              Fraud Status:
            </div>
            <Select
              textInputProps={{
                id: 'fraud_status',
              }}
              size="medium"
              fluid
              onValueChange={(value) => { onRequestFraudStatusFilterChange({ value }); }}
              value={fraudStatusFilterValue}
              options={[
                { content: 'All', value: 'all' },
                { content: 'Pending', value: 'pending' },
                { content: 'In Review', value: 'review' },
                { content: 'Approved', value: 'approved' },
                { content: 'Declined', value: 'declined' },
              ]}
            />
          </div>
        </div>
      </CardHeader>
      <CardContent className={styles.searchResults}>
        {!isEmpty(searchResults.orderDetails) && (
          <div>
            <OrderDetailSearchResults
              baseCurrency={baseCurrency}
              organizationCountry={organizationCountry}
              orderDetails={searchResults.orderDetails}
              onOrderDetailSelection={onRequestOrderDetailSelection}
            />
          </div>
        )}
        {isEmpty(searchResults.orderDetails) && !isEmpty(searchText) && (
          <CardEmptyState content={(
            <span>
              Sorry, no matches found for
              {' '}
              <strong>{searchText}</strong>
            </span>
          )}
          />
        )}
        {isEmpty(searchResults.orderDetails) && isEmpty(searchText) && (
          <CardEmptyState content="No Results" />
        )}
      </CardContent>
      <CardFooter>
        <Pagination
          pageNumber={pageNumber}
          isPreviousPageDisabled={searchResults.isFirstPage}
          isNextPageDisabled={searchResults.isLastPage}
          onRequestPreviousPage={onRequestPreviousPage}
          onRequestNextPage={onRequestNextPage}
        />
      </CardFooter>
    </Card>
  </div>
);

OrderDetailSearchView.displayName = displayName;

export default OrderDetailSearchView;
