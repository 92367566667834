import Client from './client';

export default class ProcessorMerchants extends Client {
  constructor(opts) {
    let options = opts;

    if (typeof opts === 'string') {
      options = { host: opts }; // convert host string to options object
    }

    options.serviceName = 'API Internal';

    super(options);
  }

  get(processor, accountOrganization, accountKey, options = {}) {
    return this.makeRequest(`${this.host}/processors/${processor}/accounts/${accountOrganization}/${accountKey}/merchants`, options);
  }

  post(processor, accountOrganization, accountKey, options = {}) {
    return this.makeRequest(`${this.host}/processors/${processor}/accounts/${accountOrganization}/${accountKey}/merchants`, {
      ...options,
       method: 'POST',
    });
  }

  getByKey(processor, accountOrganization, accountKey, key, options = {}) {
    return this.makeRequest(`${this.host}/processors/${processor}/accounts/${accountOrganization}/${accountKey}/merchants/${key}`, options);
  }

  putByKey(processor, accountOrganization, accountKey, key, options = {}) {
    return this.makeRequest(`${this.host}/processors/${processor}/accounts/${accountOrganization}/${accountKey}/merchants/${key}`, {
      ...options,
       method: 'PUT',
    });
  }

  putModificationsByKey(processor, accountOrganization, accountKey, key, options = {}) {
    return this.makeRequest(`${this.host}/processors/${processor}/accounts/${accountOrganization}/${accountKey}/merchants/${key}/modifications`, {
      ...options,
       method: 'PUT',
    });
  }

  putModificationsAndStatusByKey(processor, accountOrganization, accountKey, key, options = {}) {
    return this.makeRequest(`${this.host}/processors/${processor}/accounts/${accountOrganization}/${accountKey}/merchants/${key}/modifications/status`, {
      ...options,
       method: 'PUT',
    });
  }

}
