import React from 'react';

import GoogleAnalytics from './google-analytics';

export default function useGoogleAnalytics(trackingId) {
  return {
    renderRouterContext: (child, renderProps) => (
      <GoogleAnalytics routerProps={renderProps} trackingId={trackingId}>
        {child}
      </GoogleAnalytics>
    ),
  };
}
