import { createSelector } from 'reselect';
import { CatalogState } from '../types';
import { RootState } from '../../../stores/types';

export const getCatalogState = (state: RootState): CatalogState => state.catalog;

export const getCatalogAttributes = createSelector(
  getCatalogState,
  (catalog) => catalog.attributes,
);

export const getHarmonizationResults = createSelector(
  getCatalogState,
  (catalog) => catalog.harmonizationResults,
);

export const getSelectedCountries = createSelector(
  getCatalogState,
  (catalog) => catalog.selectedCountries,
);

export const getShowAllTariffCodes = createSelector(
  getCatalogState,
  (catalog) => catalog.showAllTariffCodes,
);

export const getCatalogSearchTerm = createSelector(
  getCatalogState,
  (catalog) => catalog.currentSearchTerm,
);
