import { createSelector } from 'reselect';
import get from 'lodash/get';
import getExclusionState from './getExclusionState';
import { RootState } from '../../../stores/types';
import { ExclusionState, ExclusionRuleDeleteConfirmationState } from '../types';

const getExclusionRuleDeleteConfirmation = createSelector <
RootState, ExclusionState, ExclusionRuleDeleteConfirmationState>(
  getExclusionState,
  (state) => get(state, 'dialogs.exclusionRuleDeleteConfirmation'),
);

export default getExclusionRuleDeleteConfirmation;
