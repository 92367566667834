import React, { ChangeEvent } from 'react';
import { Form, Field } from 'redux-form';
import round from 'lodash/round';
import { getRollbar } from '@flowio/redux-rollbar-middleware/lib/rollbar';

// eslint-disable-next-line import/no-duplicates
import type Rollbar from 'rollbar';
// eslint-disable-next-line import/no-duplicates
import type { LogArgument } from 'rollbar';

import {
  Card,
  CardHeader,
  CardContent,
  CardTitle,
} from '@flowio/react-card';
import { FormGroup } from '@flowio/react-form-group';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableHeadColumn,
} from '@flowio/react-table';

import classNames from 'classnames';
import { TextField, Checkbox, SelectField } from '../../../../components/ReduxFormFieldAdapters';

import {
  OrderRefundFormProps,
  ItemRefundQuantity,
  RefundCalculationOptions,
  RefundDialogFormItems,
  DropdownOption,
  RefundTypeValues,
  RefundAmountValues,
  RefundShippingValues,
} from '../../types';
import encodeItemNumber from '../../utilities/encodeItemNumber';
import * as styles from './OrderRefundForm.styles';

const refundTypeOptions = [
  {
    value: RefundTypeValues.FULL,
    content: 'Full Refund',
  },
  {
    value: RefundTypeValues.PARTIAL,
    content: 'Partial Refund',
  },
  {
    value: RefundTypeValues.CUSTOM,
    content: 'Custom Refund',
  },
];

const refundAmountOptions = [
  {
    value: RefundAmountValues.ITEM,
    content: 'charged per item',
  },
  {
    value: RefundAmountValues.ORDER,
    content: 'charged for order',
  },
];

const refundShippingOptions = [
  {
    value: RefundShippingValues.VALUE,
    content: 'charged by value %',
  },
  {
    value: RefundShippingValues.QUANTITY,
    content: 'charged by quantity %',
  },
  {
    value: RefundShippingValues.ORDER,
    content: 'charged for order',
  },
];

const required = (value: number): string | void => (value || typeof value === 'number' ? undefined : 'Required');

export default class OrderRefundForm extends React.PureComponent<OrderRefundFormProps> {
  componentDidMount = (): void => {
    const {
      onComponentLoad,
    } = this.props;

    onComponentLoad();
  };

  componentWillUnmount = (): void => {
    const {
      onComponentUnmount,
    } = this.props;

    onComponentUnmount();
  };

  onItemQtyChange = (itemNumber: string, quantity?: string): void => {
    const {
      order,
      onRefundParamsChange,
      allocations,
    } = this.props;

    if (!quantity && Number(quantity) !== 0) {
      return;
    }

    const refundParamOptions = this.formatRefundParams({}, { [itemNumber]: Number(quantity) });

    onRefundParamsChange(order, refundParamOptions, allocations);
  };

  refundTypeHandler = (selectedOption?: DropdownOption): void => {
    const {
      order,
      onRefundParamsChange,
      allocations,
    } = this.props;

    if (!selectedOption) {
      return;
    }

    const refundParamOptions = this.formatRefundParams({ refundType: selectedOption.value });

    onRefundParamsChange(order, refundParamOptions, allocations);
  };

  checkboxTaxChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    const {
      order,
      onRefundParamsChange,
      allocations,
    } = this.props;

    const refundParamOptions = this.formatRefundParams({ refundTax: event.target.checked });

    onRefundParamsChange(order, refundParamOptions, allocations);
  };

  checkboxDutyChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    const {
      order,
      onRefundParamsChange,
      allocations,
    } = this.props;

    const refundParamOptions = this.formatRefundParams({ refundDuty: event.target.checked });

    onRefundParamsChange(order, refundParamOptions, allocations);
  };

  checkboxShippingChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    const {
      order,
      onRefundParamsChange,
      allocations,
    } = this.props;

    const refundParamOptions = this.formatRefundParams({ refundShipping: event.target.checked });

    onRefundParamsChange(order, refundParamOptions, allocations);
  };

  refundDutyTypeHandler = (selectedOption?: DropdownOption): void => {
    const {
      order,
      onRefundParamsChange,
      allocations,
    } = this.props;

    if (!selectedOption) {
      return;
    }

    const refundParamOptions = this.formatRefundParams({ refundDutyType: selectedOption.value });

    onRefundParamsChange(order, refundParamOptions, allocations);
  };

  refundTaxTypeHandler = (selectedOption?: DropdownOption): void => {
    const {
      order,
      onRefundParamsChange,
      allocations,
    } = this.props;

    if (!selectedOption) {
      return;
    }

    const refundParamOptions = this.formatRefundParams({ refundTaxType: selectedOption.value });

    onRefundParamsChange(order, refundParamOptions, allocations);
  };

  refundShippingTypeHandler = (selectedOption?: DropdownOption): void => {
    const {
      order,
      onRefundParamsChange,
      allocations,
    } = this.props;

    if (!selectedOption) {
      return;
    }

    const refundParamOptions = this.formatRefundParams({
      refundShippingType: selectedOption.value,
    });

    onRefundParamsChange(order, refundParamOptions, allocations);
  };

  formatRefundParams = (
    overrideProps: Partial<RefundCalculationOptions>,
    overrideItem?: RefundDialogFormItems,
  ): RefundCalculationOptions => {
    const {
      refundType,
      refundTax,
      refundDuty,
      refundShipping,
      refundTaxType,
      refundDutyType,
      refundShippingType,
      items,
    } = this.props;

    const paramOptionItems: ItemRefundQuantity[] = [];
    let newItems = {
      ...items,
    };

    if (overrideItem) {
      newItems = {
        ...newItems,
        ...overrideItem,
      };
    }

    Object.keys(newItems).forEach((itemNumber) => paramOptionItems.push({
      quantity: Number(newItems[encodeItemNumber(itemNumber)]),
      itemNumber,
    }));

    return {
      refundType,
      refundTax,
      refundDuty,
      refundShipping,
      refundTaxType,
      refundDutyType,
      refundShippingType,
      items: paramOptionItems,
      ...overrideProps,
    };
  };

  checkRefundAmount = (value: number): string | void => {
    const { order, amountRefunded, currencies } = this.props;
    const currency = currencies.find((curr) => curr.iso_4217_3 === order.total.currency);
    if (!currency) {
      getRollbar((rollbar: Rollbar, extra: LogArgument) => {
        rollbar.error(`Could not find currency ${order.total.currency} in reference data`, extra);
      });
      // Do not invalidate field if currency information is not available.
      return undefined;
    }
    const refundMax = round(
      order.total.amount - amountRefunded, currency.number_decimals,
    );

    if (value > refundMax) {
      return 'Refund amount larger than total left on order';
    }

    return undefined;
  };

  render(): React.ReactNode {
    const {
      amountRefunded,
      formErrors,
      order,
      refundType,
      refundTax,
      refundDuty,
      refundShipping,
      refundObj,
    } = this.props;

    const { currency } = order.total;
    const { country } = order.destination;
    const discount = order.prices.find((price) => price.key === 'discount');
    const surcharge = order.prices.find((price) => price.key === 'surcharges');
    const shipping = order.prices.find((price) => price.key === 'shipping');
    const tax = order.prices.find((price) => price.key === 'vat');
    const duty = order.prices.find((price) => price.key === 'duty');
    const currencyFormatter = new Intl.NumberFormat(country, { style: 'currency', currency });
    const isLongErrorMsg = formErrors.refundAmount && formErrors.refundAmount !== 'Required';

    return (
      <Form>
        <Card>
          <CardHeader dividing>
            <CardTitle content="Refund Settings" />
          </CardHeader>
          <CardContent>
            <FormGroup gutter labelCols={4} labelText="Order" inline>
              <Field
                selection
                onChange={(value: any): void => this.refundTypeHandler(value)}
                name="refundType"
                component={SelectField}
                options={refundTypeOptions}
              />
            </FormGroup>
            {refundType !== RefundTypeValues.CUSTOM && (
              <FormGroup labelCols={4} labelText="Duties, Taxes and Shipping" inline>
                <div className={styles.additionalRefundContainer}>
                  <Field
                    onChange={(
                      event: ChangeEvent<HTMLInputElement>,
                    ): void => this.checkboxDutyChangeHandler(event)}
                    component={Checkbox}
                    checked={refundDuty}
                    type="checkbox"
                    name="refundDuty"
                  />
                  <span className={styles.refundLabel}>
                    Refund
                    <b> duties</b>
                  </span>
                  {refundType !== RefundTypeValues.FULL && (
                    <Field
                      selection
                      onChange={(
                        value: any,
                      ): void => this.refundDutyTypeHandler(value)}
                      name="refundDutyType"
                      disabled={!refundDuty}
                      component={SelectField}
                      size="small"
                      options={refundAmountOptions}
                    />
                  )}
                </div>
                <div className={styles.additionalRefundContainer}>
                  <Field
                    onChange={(
                      event: ChangeEvent<HTMLInputElement>,
                    ): void => this.checkboxTaxChangeHandler(event)}
                    component={Checkbox}
                    checked={refundTax}
                    type="checkbox"
                    name="refundTax"
                  />
                  <span className={styles.refundLabel}>
                    Refund
                    <b>&nbsp; taxes</b>
                  </span>
                  {refundType !== RefundTypeValues.FULL && (
                    <Field
                      selection
                      name="refundTaxType"
                      component={SelectField}
                      disabled={!refundTax}
                      onChange={(
                        value: any,
                      ): void => this.refundTaxTypeHandler(value)}
                      size="small"
                      options={refundAmountOptions}
                    />
                  )}
                </div>
                <div className={styles.additionalRefundContainer}>
                  <Field
                    onChange={(
                      event: ChangeEvent<HTMLInputElement>,
                    ): void => this.checkboxShippingChangeHandler(event)}
                    component={Checkbox}
                    type="checkbox"
                    checked={refundShipping}
                    name="refundShipping"
                  />
                  <span className={styles.refundLabel}>
                    Refund
                    <b> shipping</b>
                  </span>
                  {refundType !== RefundTypeValues.FULL && (
                    <Field
                      selection
                      name="refundShippingType"
                      disabled={!refundShipping}
                      onChange={(
                        value: any,
                      ): void => this.refundShippingTypeHandler(value)}
                      component={SelectField}
                      size="small"
                      options={refundShippingOptions}
                    />
                  )}
                </div>
              </FormGroup>
            )}
          </CardContent>
        </Card>
        {refundType !== RefundTypeValues.CUSTOM && (
          <Card>
            <CardHeader dividing>
              <CardTitle content="Refund Items" />
            </CardHeader>
            <CardContent>
              <Table striped>
                <TableHead>
                  <TableRow>
                    <TableHeadColumn className={styles.quantityCol}>Quantity</TableHeadColumn>
                    <TableHeadColumn className={styles.productColumn}>Product</TableHeadColumn>
                    <TableHeadColumn />
                    <TableHeadColumn>Local Amount</TableHeadColumn>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {order.items.map((item) => {
                    const itemPrice = item.local.prices.find((price) => price.key === 'localized_item_price')?.amount || 0;
                    return (
                      <TableRow>
                        <TableCell className={styles.quantityCol}>
                          {refundType !== RefundTypeValues.FULL && (
                            <div className={styles.itemQtyFieldWrapper}>
                              <Field
                                className={styles.itemQtyField}
                                type="number"
                                onChange={(_e, v): void => (
                                  this.onItemQtyChange(encodeItemNumber(item.number), v)
                                )}
                                max={item.quantity}
                                min={0}
                                component={TextField}
                                name={`items.${encodeItemNumber(item.number)}`}
                              />
                              <span className={styles.maxQuantity}>
                                /
                                {item.quantity}
                              </span>
                            </div>
                          )}
                          {refundType === RefundTypeValues.FULL && (
                            <div>{item.quantity}</div>
                          )}
                        </TableCell>
                        <TableCell className={styles.productColumn}>
                          {item.name}
                        </TableCell>
                        <TableCell />
                        <TableCell>
                          {currencyFormatter.format(itemPrice)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  <TableRow className={styles.dividingRowAbove}>
                    <TableCell />
                    <TableCell />
                    <TableCell>Item Subtotal</TableCell>
                    {refundObj.refundItemSubtotalAmount !== 0 && (
                      <TableCell>
                        {currencyFormatter.format(refundObj.refundItemSubtotalAmount || 0)}
                      </TableCell>
                    )}
                    {refundObj.refundItemSubtotalAmount === 0 && (
                      <TableCell>-</TableCell>
                    )}
                  </TableRow>
                  {duty && duty.amount && (
                    <TableRow>
                      <TableCell />
                      <TableCell />
                      <TableCell>{duty.name}</TableCell>
                      {refundObj.refundDutyAmount && (
                        <TableCell>
                          {currencyFormatter.format(refundObj.refundDutyAmount)}
                        </TableCell>
                      )}
                      {!refundObj.refundDutyAmount && (
                        <TableCell>-</TableCell>
                      )}
                    </TableRow>
                  )}
                  {tax && tax.amount !== 0 && (
                    <TableRow>
                      <TableCell />
                      <TableCell />
                      <TableCell>{tax.name}</TableCell>
                      {refundObj.refundTaxAmount && (
                        <TableCell>
                          {currencyFormatter.format(refundObj.refundTaxAmount)}
                        </TableCell>
                      )}
                      {!refundObj.refundTaxAmount && (
                        <TableCell>-</TableCell>
                      )}
                    </TableRow>
                  )}
                  {shipping && shipping.amount !== 0 && (
                    <TableRow>
                      <TableCell />
                      <TableCell />
                      <TableCell>{shipping.name}</TableCell>
                      {refundObj.refundShippingAmount && (
                        <TableCell>
                          {currencyFormatter.format(refundObj.refundShippingAmount)}
                        </TableCell>
                      )}
                      {!refundObj.refundShippingAmount && (
                        <TableCell>-</TableCell>
                      )}
                    </TableRow>
                  )}
                  {surcharge && surcharge.amount !== 0 && (
                    <TableRow>
                      <TableCell />
                      <TableCell />
                      <TableCell>{surcharge.name}</TableCell>
                      {refundObj.refundSurchargeAmount && (
                        <TableCell>
                          {currencyFormatter.format(refundObj.refundSurchargeAmount)}
                        </TableCell>
                      )}
                      {!refundObj.refundSurchargeAmount && (
                        <TableCell>-</TableCell>
                      )}
                    </TableRow>
                  )}
                  {discount && discount.amount !== 0 && (
                    <TableRow>
                      <TableCell />
                      <TableCell />
                      <TableCell>{discount.name}</TableCell>
                      {refundObj.refundDiscountAmount && (
                        <TableCell>
                          {currencyFormatter.format(refundObj.refundDiscountAmount)}
                        </TableCell>
                      )}
                      {!refundObj.refundDiscountAmount && (
                        <TableCell>-</TableCell>
                      )}
                    </TableRow>
                  )}
                  <TableRow className={styles.dividingRowAbove}>
                    <TableCell />
                    <TableCell />
                    <TableCell><b>Refund Total</b></TableCell>
                    <TableCell>{currencyFormatter.format(refundObj.refundAmount || 0)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        )}
        <Card>
          <CardHeader title="Refund Amount" />
          <CardContent>
            <div className={styles.refundAmountWrapper}>
              <div
                className={styles.refundAmount}
              >
                <Field
                  inline
                  className={styles.amountField}
                  type="number"
                  max={order.total.amount}
                  validate={[required, this.checkRefundAmount]}
                  component={TextField}
                  name="refundAmount"
                  labelCols={6}
                  labelText="Refund Amount"
                  fluid
                  description={`${currencyFormatter.format(order.total.amount - amountRefunded)} available for refund`}
                />
              </div>
              <span
                className={classNames(
                  styles.amountCurrency, { [styles.shiftLeft]: isLongErrorMsg },
                )}
              >
                {order.total.currency}
              </span>
            </div>
            {/* <FormGroup
              descriptionText="Optional"
              labelCols={4}
              labelText="Reason for Refund"
              inline>
              <Field
                inline
                fluid
                hintText="Refund reason for this order (optional)"
                component={TextField}
                name="refundReason" />
            </FormGroup> */}
          </CardContent>
        </Card>
      </Form>
    );
  }
}
