import { compose, bindActionCreators } from 'redux';
import { createStructuredSelector, createSelector } from 'reselect';
import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import moment from 'moment';
import pickBy from 'lodash/pickBy';
import includes from 'lodash/includes';
import mapKeys from 'lodash/mapKeys';
import mapValues from 'lodash/mapValues';
import defaults from 'lodash/defaults';
import { reduxForm, FormErrors } from 'redux-form';
import { withRouter } from 'react-router';
import map from 'lodash/map';
import get from 'lodash/get';
import identity from 'lodash/identity';

import ReportInterval from '../../../constants/report-interval';
import exportRevenueTimeline from '../../../actions/exportRevenueTimeline';
import resetExportTimelineState from '../../../actions/resetExportTimelineState';
import { toDateRange } from '../../../utilities/date-range';
import DateRangeLiterals from '../../../constants/date-range-literals';
import { getOrganizationId } from '../../../../organization';
import withValidation from '../../../../../components/with-validation';
import FormName from '../../../constants/FormName';
import ExportDialogForm from '../components/ExportDialogForm';
import {
  StateProps, OwnProps, ExportDialogFormData, DispatchProps, ComponentProps,
} from '../types';
import { RootState, ThunkDispatcher } from '../../../../../stores/types';
import { AnalyticsState } from '../../../types';
import getQueryParamValue from '../../../../utilities/get-query-parameter-value';

const getAnalyticsState = createSelector<RootState, RootState, AnalyticsState['gmvByCountry']>(identity, (state) => get(state, 'analytics.gmvByCountry'));

// export for testing
export const mapQueryParamsToApiParams = (query: Record<string, null | undefined | string | string[]>): Omit<io.flow.v0.models.AnalyticsExportType, 'discriminator'> => {
  const intervalMap = {
    [ReportInterval.Daily]: 'day',
    [ReportInterval.Hourly]: 'hour',
    [ReportInterval.Weekly]: 'week',
    [ReportInterval.Monthly]: 'month',
  };
  const transformedData = mapValues(
    mapKeys(
      pickBy(
        query,
        (_val, key) => includes(['endDate', 'startDate', 'interval', 'region'], key),
      ),
      (_val, key) => {
        switch (key) {
          case 'endDate': return 'to';
          case 'startDate': return 'from';
          case 'interval':
          default: return key;
        }
      },
    ),
    (val, key) => {
      const value = getQueryParamValue(val);
      if (key === 'interval') {
        return intervalMap[value as ReportInterval] || value;
      }
      return value;
    },
  );

  const [defaultStartDate, defaultEndDate] = map(
    toDateRange(DateRangeLiterals.LAST_7_DAYS, moment.utc()),
    (date) => date.clone().toISOString(),
  );

  defaults(transformedData, {
    region: 'world',
    interval: 'day',
    to: defaultEndDate,
    from: defaultStartDate,
  });

  return transformedData;
};

const onRequestExport = (ownProps: OwnProps) => (formData: ExportDialogFormData) => (
  dispatch: ThunkDispatcher,
  getState: () => RootState,
): void => {
  const {
    email,
  } = formData;
  const {
    location,
  } = ownProps;
  const organization = getOrganizationId(getState());
  const exportFormData = mapQueryParamsToApiParams(location.query);
  dispatch(exportRevenueTimeline({ organization, formData: { email, ...exportFormData } }));
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch,
  ownProps,
) => bindActionCreators({
  onResetExportState: resetExportTimelineState,
  onRequestExport: onRequestExport(ownProps),
}, dispatch);

const withForm = reduxForm<ExportDialogFormData, OwnProps, FormErrors<ExportDialogFormData>>({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  form: FormName.ANALYTICS_EXPORT_DIALOG,
});

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = createStructuredSelector({
  submitted: createSelector<RootState, AnalyticsState['gmvByCountry'], boolean>(getAnalyticsState, (state) => get(state, 'ui.submitted')),
});

export default compose<React.FC<ComponentProps>>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withValidation<OwnProps>(({ defaultEmail }) => ({
    email: {
      defaultValue: defaultEmail,
      validations: [{
        required: true,
        message: 'An email address is required',
      }, {
        pattern: 'email',
        message: 'Please enter a valid email address',
      }],
    },
  })),
  withForm,
)(ExportDialogForm);
