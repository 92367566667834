import ActionTypes from '../constants/action-types';
import { ThunkResult } from '../../../middlewares/types';
import { RootActionTypes, ThunkExtraArguments } from '../../../stores/types';
import { ApiError } from '../../../utilities/clients/api-error';

interface Options {
  limit?: number;
  offset?: number;
  sort?: string;
  organization: string;
}

export function fetchCentersRequest(): RootActionTypes {
  return {
    type: ActionTypes.GET_CENTERS_REQUEST,
  };
}

export function fetchCentersSuccess(
  centers: io.flow.v0.models.Center[],
): RootActionTypes {
  return {
    type: ActionTypes.GET_CENTERS_SUCCESS,
    payload: centers,
  };
}

export function fetchCentersFailure(
  error: ApiError,
): RootActionTypes {
  return {
    type: ActionTypes.GET_CENTERS_FAILURE,
    error: true,
    payload: error,
  };
}

export default function fetchCenters(
  options: Options,
): ThunkResult<Promise<void>> {
  return function fetchCentersEffect(
    dispatch,
    getState,
    extra: ThunkExtraArguments,
  ): Promise<void> {
    dispatch(fetchCentersRequest());
    return extra.api(getState()).centers.get(options).then((response) => {
      if (response.ok) {
        dispatch(fetchCentersSuccess(response.body));
        return;
      }

      // TODO conversion-revisit
      const error = new ApiError(
        response,
      );
      dispatch(fetchCentersFailure(error));
      throw error;
    }).catch((error) => {
      dispatch(fetchCentersFailure(new ApiError(error)));
      throw error;
    });
  };
}
