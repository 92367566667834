import { compose } from 'redux';
import { connect } from 'react-redux';
import { getOrderDeliveryList } from '../selectors';
import OrderItems from '../components/order-items';
import { getIsCustomerServiceUser } from '../../user';
import { OrderItemsStateProps, OrderItemsProps } from '../types';
import { RootState } from '../../../stores/types';

const mapStateToProps = (state: RootState): OrderItemsStateProps => ({
  deliveries: getOrderDeliveryList(state),
  isCustomerServiceUser: getIsCustomerServiceUser(state),
});

export default compose(
  connect<OrderItemsStateProps, {}, OrderItemsProps, RootState>(mapStateToProps),
)(OrderItems);
