import update from 'immutability-helper';
import { RootActionTypes } from '../../../stores/types';
import ActionType from '../constants/ActionType';
import { ExclusionsExportDialogState } from '../types';

const initialState = {
  visible: false,
};

export default function exclusionsExportDialodReducer(
  state: ExclusionsExportDialogState = initialState,
  action: RootActionTypes,
) {
  switch (action.type) {
    case ActionType.ShowExportDialog:
      return update(state, {
        visible: { $set: true },
      });
    case ActionType.HideExportDialog:
      return update(state, {
        visible: { $set: false },
      });
    default:
      return state;
  }
}
