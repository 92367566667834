import React from 'react';
import { Form, Field } from 'redux-form';

import GenericError from '../../../../components/GenericError';
import { FeatureDeleteConfirmFormProps } from '../../types/components';

const FeatureDeleteConfirmForm: React.FC<FeatureDeleteConfirmFormProps> = ({
  handleSubmit,
  featureName,
  error,
}) => (
  <Form onSubmit={handleSubmit}>
    <Field component="input" type="hidden" name="organizationId" />
    <Field component="input" type="hidden" name="featureKey" />
    {error && (<GenericError error={error} />)}
    <div>
      This action can not be undone. Are you sure you want to delete feature
      {` ${featureName}`}
      ?
    </div>
  </Form>
);

FeatureDeleteConfirmForm.displayName = 'FeatureDeleteConfirmForm';

FeatureDeleteConfirmForm.defaultProps = {
  error: undefined,
};

export default FeatureDeleteConfirmForm;
