import React from 'react';
import { Confirm } from '@flowio/react-confirm';
import OptinContentDeleteForm from '../../OptinContentDeleteForm';
import { Props } from '../types';

const OptinContentDeleteConfirm: React.FC<Props> = ({
  initialValues,
  onSubmitSuccess,
  open,
  optinPrompt,
  regions,
  ...unhandledProps
}) => (
  <Confirm
    {...unhandledProps}
    intent="negative"
    open={open}
  >
    <OptinContentDeleteForm
      initialValues={initialValues}
      onSubmitSuccess={onSubmitSuccess}
      optinPrompt={optinPrompt}
      regions={regions}
    />
  </Confirm>
);

OptinContentDeleteConfirm.displayName = 'OptinContentDeleteConfirm';

export default OptinContentDeleteConfirm;
