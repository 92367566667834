import { css } from '@emotion/css';

export const settings = css`
  margin-top: 1rem;
`;

export const settingsForm = css`
  margin-top: 1rem;
`;

export const setting = css`
  border-bottom: 1px solid #e4e7eb;
  display: flex;
  padding: 0;
  line-height: 48px;

  &:first-child {
    border-top: 1px solid #e4e7eb;
  }
`;

export const settingCheckbox = css`
  border-bottom: 1px solid #e4e7eb;
  display: flex;
  padding: 0;
  line-height: 48px;

  &:first-child {
    border-top: 1px solid #e4e7eb;
  }
`;

export const settingLabel = css`
  flex: auto;
`;

export const settingInclusion = css`
  color: #9ba6b3;
  text-transform: uppercase;

  & .material-icons {
    margin-left: 1rem;
    vertical-align: middle;
  }
`;

export const settingEm = css`
  color: #9ba6b3;
  padding-left: 0.5rem;
`;
