import { withRouter, RouteComponentProps } from 'react-router';
import { connect, MapStateToProps } from 'react-redux';
import { isDirty } from 'redux-form';
import React from 'react';

import getDisplayName from '../../utilities/react/get-display-name';
import { RootState } from '../../stores/types';

const DEFAULT_LEAVE_MESSAGE = 'Are you sure you want to leave the page without saving changes?';

interface StateProps {
  isUnsaved: boolean;
}

type Props = StateProps & RouteComponentProps<StateProps, {}>;

function withPromptUnsavedChange(formName: string, leaveMessage = DEFAULT_LEAVE_MESSAGE) {
  return function createPromptUnsavedChangeContainer(
    WrappedComponent: React.ReactType,
  ): React.Component<Props> {
    class PromptUnsavedChange extends React.Component<Props> {
      static displayName = `PromptUnsavedChange(${getDisplayName(WrappedComponent)})`;

      static defaultProps = {
        isUnsaved: false,
      };

      componentDidUpdate(): void {
        const { isUnsaved } = this.props;
        this.promptUnsavedChange(isUnsaved);
      }

      componentWillUnmount(): void {
        window.onbeforeunload = null;
      }

      promptUnsavedChange(isUnsaved: boolean): void {
        const { router, route } = this.props;
        // Detecting page transition (prevent leaving by setting true)
        router.setRouteLeaveHook(route, () => {
          // eslint-disable-next-line no-alert
          if (isUnsaved) return window.confirm(leaveMessage);
          return undefined;
        });

        // Detecting browser close
        window.onbeforeunload = (isUnsaved && ((): string => leaveMessage)) || null;
      }

      render(): JSX.Element {
        return <WrappedComponent {...this.props} />;
      }
    }

    const mapStateToProps: MapStateToProps<StateProps, {}, RootState> = (
      state: RootState,
    ): StateProps => ({
      isUnsaved: isDirty(formName)(state),
    });

    return withRouter(
      // Unfortunately could not find right correct typings and found issue relating
      // to react-router 3 types. hence nasty cast to any :-(
      connect<StateProps, {}, {}, RootState>(mapStateToProps)(PromptUnsavedChange) as any,
    );
  };
}

export default withPromptUnsavedChange;
