import { ActionTypes } from '../constants';
import {
  ThunkResult, ThunkExtraArguments, RootState, ThunkDispatcher,
} from '../../../stores/types';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import toLegacyResponse from '../../../utilities/clients/to-legacy-response';
import standardAction from '../../../utilities/standard-action';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';

type ResponseBody = io.flow.v0.models.Attribute[] | undefined;

export default function getAttributes(
  organization: string,
): ThunkResult<Promise<LegacyResponse<ResponseBody>>> {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<LegacyResponse<ResponseBody>> => {
    dispatch(standardAction(ActionTypes.GET_ATTRIBUTES_REQUEST));

    return extra.api(getState()).attributes.get(
      { organization },
    ).then((response) => {
      if (response.ok) {
        dispatch({
          type: ActionTypes.GET_ATTRIBUTES_SUCCESS,
          payload: response.body,
        });
        return toLegacyResponse(response);
      }

      dispatch({
        type: ActionTypes.GET_ATTRIBUTES_FAILURE,
        payload: formatErrorResponseV2(response),
      });
      return toLegacyResponse(response);
    });
  };
}
