import createQueryBuilder from '../utilities/createQueryBuilder';

import ActionType from '../constants/ActionType';
import updateReturnPolicy from './updateReturnPolicy';
import createReturnPolicy from './createReturnPolicy';
import { ThunkResult } from '../../../stores/types';
import { ReturnPolicyFormValues } from '../types';

export default function submitReturnPolicyForm(
  values: ReturnPolicyFormValues,
): ThunkResult<Promise<void>> {
  return function sumitReturnPolicyFormEffects(
    dispatch,
    getState,
  ) {
    const state = getState();
    dispatch({ type: ActionType.SubmitReturnPolicyForm });
    return createQueryBuilder(
      state,
      values.organizationId,
      {
        discriminator: 'filter',
        filters: values.filters,
      },
    ).then((queryBuilder: io.flow.v0.models.QueryBuilder) => {
      const returnPolicyForm: io.flow.v0.models.ReturnPolicyForm = {
        q: queryBuilder.q,
      };
      const action = values.returnPolicyId
        ? updateReturnPolicy(values.organizationId, values.returnPolicyId, returnPolicyForm)
        : createReturnPolicy(values.organizationId, returnPolicyForm);

      return dispatch(action);
    }).catch((err: any) => {
      const formattedError = err.response ? err.response.result : err;

      dispatch({
        type: ActionType.SubmitReturnPolicyFormError,
        payload: formattedError,
        error: true,
      });
    });
  };
}
