import keyMirror from '../../../utilities/keymirror';

const ActionTypes = keyMirror({
  FETCH_FAILURE_SUMMARIES_REQUEST: null,
  FETCH_FAILURE_SUMMARIES_SUCCESS: null,
  FETCH_FAILURE_SUMMARIES_FAILURE: null,
  REQUEUE_FAILURES_REQUEST: null,
  REQUEUE_FAILURES_SUCCESS: null,
  REQUEUE_FAILURES_FAILURE: null,
  DELETE_FAILURE_REQUEST: null,
  DELETE_FAILURE_SUCCESS: null,
  DELETE_FAILURE_FAILURE: null,
});

export default ActionTypes;
