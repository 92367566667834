import ActionTypes from '../constants/ActionTypes';
import { apiInternal } from '../../../utilities/clients';

export default function deleteFailure(db, queue, id) {
  return {
    types: [
      ActionTypes.DELETE_FAILURE_REQUEST,
      ActionTypes.DELETE_FAILURE_SUCCESS,
      ActionTypes.DELETE_FAILURE_FAILURE,
    ],
    callAPI(state) {
      return apiInternal.alertFailureSummaries(state).deleteByDbAndQueueAndId(db, queue, id);
    },
    payload: { db, queue, id },
  };
}
