import { ResponseError } from '@flowio/redux-async-middleware';
import ActionTypes from '../constants/action-types';
import { getOrganizationId } from '../../organization/selectors';
import {
  ThunkDispatcher, RootState, ThunkExtraArguments, ThunkResult, RootActionTypes,
} from '../../../stores/types';

interface FetchCloneExperienceModelParams {
  experienceKey: string;
  experienceCloneId: string;
}

export default function fetchCloneExperienceModel({
  experienceKey,
  experienceCloneId,
}: FetchCloneExperienceModelParams): ThunkResult<Promise<RootActionTypes>> {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<RootActionTypes> => {
    dispatch({
      type: ActionTypes.FETCH_EXPERIENCE_CLONE_REQUEST,
    });
    const state = getState();
    const organization = getOrganizationId(state);
    return extra.api(state).experiences.getCloneByKeyAndId({
      organization,
      key: experienceKey,
      id: experienceCloneId,
    }).then((response) => {
      if (response.ok) {
        return dispatch({
          type: ActionTypes.FETCH_EXPERIENCE_CLONE_SUCCESS,
          payload: response.body,
        });
      }
      return dispatch({
        type: ActionTypes.FETCH_EXPERIENCE_CLONE_FAILURE,
        error: true,
        payload: new ResponseError(response.status, 'Response is outside the 2xx status code range', response.body),
      });
    });
  };
}
