import ActionTypes from '../../constants/action-types';
import { ConsoleActions } from '../../types/actions';

export interface RedirectState {
  redirectUrl?: string;
  toastMessage?: string;
}

const initialState: RedirectState = {
};

export default function (
  state = initialState,
  action: ConsoleActions,
): RedirectState {
  switch (action.type) {
    case ActionTypes.SET_REDIRECT_URL:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
