import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { submit, isSubmitting } from 'redux-form';

import { DialogName, FormName } from '../constants';
import { deleteDialog } from '../actions';
import { getDialogProperty } from '../selectors';
import ShippingLaneDeleteConfirmation from '../components/ShippingLaneDeleteConfirmation';
import { RootState, ThunkDispatcher } from '../../../stores/types';
import { ShippingLaneDeleteConfirmationDispatchProps, ShippingLaneDeleteConfirmationStateProps, ShippingLaneDeleteFormValues } from '../types/components';

const dialogName = DialogName.SHIPPING_LANE_DELETE_CONFIRMATION;
const formName = FormName.SHIPPING_LANE_DELETE_FORM;

function handleCancel() {
  return deleteDialog({ type: dialogName });
}

function handleOk() {
  return submit(formName);
}

const mapStateToProps = createStructuredSelector<
RootState,
ShippingLaneDeleteConfirmationStateProps
>({
  initialValues: getDialogProperty<ShippingLaneDeleteFormValues>(dialogName, 'initialValues'),
  isOpen: getDialogProperty<boolean>(dialogName, 'open'),
  isSubmitting: isSubmitting(formName),
});

const mapDispatchToProps = (dispatch: ThunkDispatcher) => bindActionCreators({
  onCancel: handleCancel,
  onOk: handleOk,
}, dispatch);

export default connect<
ShippingLaneDeleteConfirmationStateProps,
ShippingLaneDeleteConfirmationDispatchProps,
{},
RootState
>(mapStateToProps, mapDispatchToProps)(
  ShippingLaneDeleteConfirmation,
);
