import OrderSummaryCheck from '../../../modules/utilities/components/order-summary-check/containers/order-summary-check';
import ProductDetailsCheck from '../../../modules/utilities/containers/product-details-check';
import PriceCheck from '../../../modules/utilities/components/price-check/containers/price-check';
import ShopifySyncStatus from '../../../modules/utilities/components/ShopifySyncStatus/containers/ShopifySyncStatus';
import CurrencyRates from '../../../modules/utilities/containers/currency-rates';

export default {
  path: ':organization/utilities',
  childRoutes: [
    {
      path: 'order-check',
      component: OrderSummaryCheck,
      title: 'Utility & Tools',
    },
    {
      path: 'item-check',
      component: ProductDetailsCheck,
      title: 'Utility & Tools',
    },
    {
      path: 'price-check',
      component: PriceCheck,
      title: 'Utility & Tools',
    },
    {
      path: 'rates',
      component: CurrencyRates,
      title: 'Currency Rates',
    },
    {
      path: 'shopify-sync-status',
      component: ShopifySyncStatus,
      title: 'Shopify Sync Status',
    },
  ],
};
