import { ChevronLeft, ChevronRight } from '@flowio/react-icons';
import React from 'react';
import { Box } from '@flowio/react-box';
import { IconButton } from '@flowio/react-button';
import classNames from 'classnames/bind';

import styles from './pagination.module.css';

const cx = classNames.bind(styles);

type Props = {
  className?: string;
  isPreviousPageDisabled?: boolean;
  isNextPageDisabled?: boolean;
  pageNumber?: number | string;
  onRequestNextPage: () => void;
  onRequestPreviousPage: () => void;
};

const Pagination: React.FC<Props> = ({
  className,
  isPreviousPageDisabled,
  isNextPageDisabled,
  pageNumber,
  onRequestNextPage,
  onRequestPreviousPage,
}) => {
  const handlePrevButtonClick = () => {
    onRequestPreviousPage();
  };

  const handleNextButtonClick = () => {
    onRequestNextPage();
  };

  return (
    <Box className={cx('pagination', className)} alignItems="center" spacing="tight">
      <IconButton icon={ChevronLeft} size="small" variant="outline" disabled={isPreviousPageDisabled} onClick={handlePrevButtonClick} />
      {pageNumber && (
        <span>
          {pageNumber}
        </span>
      )}
      <IconButton icon={ChevronRight} size="small" variant="outline" disabled={isNextPageDisabled} onClick={handleNextButtonClick} />
    </Box>
  );
};

Pagination.displayName = 'Pagination';

export default Pagination;
