import { ScaleLinear, scaleLinear } from 'd3';
import get from 'lodash/get';
import max from 'lodash/max';
import maxBy from 'lodash/maxBy';
import { ChartAttributes } from './types/Charts';
import { ResultsObject } from '../../modules/utilities/types';

interface NestedResults {
  [key: string]: ResultsObject[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function createYScale<T extends Record<string, any>>(
  datum: T[],
  valueKey: string,
  chartAttrs: Partial<ChartAttributes>,
  resultsKey?: string,
): ScaleLinear<number, number> {
  const { height } = chartAttrs;
  let maxY;
  if (datum === null) {
    throw new Error('data passed to createYScale is bad, m\'kay!');
  }
  if (resultsKey && Array.isArray(datum[0][resultsKey])) {
    maxY = (
      max(datum.map((d: NestedResults) => get(maxBy(d[resultsKey], valueKey), valueKey))) || 0
    ) * 1.15;
  } else {
    const resultsObj: ResultsObject[] = datum as ResultsObject[];
    maxY = (max(resultsObj.map((d: ResultsObject) => get(d, valueKey))) as number || 0) * 1.15;
  }

  return scaleLinear()
    .range([height || 0, 0])
    .domain([0, maxY]);
}
