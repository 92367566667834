import React from 'react';

type Props = {
  className?: string;
};

const DefaultLabIcon: React.FC<Props> = ({
  className,
}) => (
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M49 52.5H38.5C37.572 52.4991 36.6823 52.13 36.0261 51.4739C35.37 50.8177 35.0009 49.928 35 49V38.5C35.0009 37.572 35.37 36.6823 36.0261 36.0261C36.6823 35.37 37.572 35.0009 38.5 35H49C49.928 35.0009 50.8177 35.37 51.4739 36.0261C52.13 36.6823 52.4991 37.572 52.5 38.5V49C52.4991 49.928 52.13 50.8177 51.4739 51.4739C50.8177 52.13 49.928 52.4991 49 52.5ZM38.5 38.5H38.4983L38.5 49H49V38.5H38.5Z" fill="#F2F5F7" />
    <path d="M31.5 45.5H21C19.608 45.4986 18.2735 44.945 17.2892 43.9608C16.305 42.9765 15.7514 41.642 15.75 40.25V33.25H19.25V40.25C19.2505 40.714 19.435 41.1588 19.7631 41.4869C20.0912 41.815 20.536 41.9995 21 42H31.5V45.5Z" fill="#F2F5F7" />
    <path d="M45.5 31.5H42V26.25C41.9995 25.786 41.815 25.3412 41.4869 25.0131C41.1588 24.685 40.714 24.5005 40.25 24.5H31.5V21H40.25C41.642 21.0014 42.9765 21.555 43.9608 22.5392C44.945 23.5235 45.4986 24.858 45.5 26.25V31.5Z" fill="#F2F5F7" />
    <path d="M26.25 31.5C25.9449 31.5002 25.6451 31.4205 25.3803 31.269L17.5 26.754L9.61978 31.269C9.32423 31.4382 8.98571 31.5175 8.64574 31.4972C8.30577 31.4769 7.97912 31.3578 7.70583 31.1546C7.43254 30.9513 7.2245 30.6727 7.10722 30.353C6.98993 30.0332 6.96849 29.6862 7.04553 29.3545L8.90228 21.3535L3.97078 16.0685C3.75524 15.8372 3.60637 15.5519 3.53999 15.2428C3.47361 14.9337 3.4922 14.6124 3.59379 14.313C3.69537 14.0136 3.87616 13.7474 4.11694 13.5425C4.35772 13.3376 4.6495 13.2018 4.96128 13.1495L12.3113 11.921L15.9268 4.48347C16.069 4.19152 16.2896 3.9449 16.564 3.7712C16.8384 3.59751 17.1557 3.50359 17.4805 3.49996C17.8052 3.49632 18.1245 3.58312 18.4027 3.75064C18.681 3.91815 18.9071 4.15978 19.0558 4.44847L22.9128 11.921L30.0475 13.1495C30.3586 13.2031 30.6493 13.3398 30.889 13.5452C31.1287 13.7506 31.3084 14.017 31.409 14.3162C31.5096 14.6153 31.5274 14.9362 31.4606 15.2446C31.3937 15.5531 31.2447 15.8378 31.0293 16.0685L26.0978 21.3535L27.9528 29.3545C28.0123 29.6109 28.0131 29.8775 27.9553 30.1343C27.8975 30.3912 27.7825 30.6317 27.6188 30.8379C27.4552 31.0441 27.2471 31.2107 27.0101 31.3253C26.7731 31.44 26.5133 31.4997 26.25 31.5ZM17.5 22.75C17.5 22.75 18.1003 23.065 18.3698 23.2207L23.6373 26.2377L22.2653 20.328L26.2588 16.0492L20.601 15.0745L17.5473 9.15597L14.6703 15.0745L8.75353 16.0632L12.733 20.328L11.361 26.2377L16.6285 23.2207C16.9015 23.065 17.5 22.75 17.5 22.75Z" fill="#F2F5F7" />
  </svg>
);

export default DefaultLabIcon;
