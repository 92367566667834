import DialogName from '../constants/DialogName';
import updateDialog from './updateDialog';
import { RootActionTypes } from '../../../stores/types';

const toggleDisconnectChannelDialog = (
  open: boolean,
  channelType?: io.flow.internal.v0.enums.MarketingGatewayPlatform,
): RootActionTypes => updateDialog(
  DialogName.DISCONNECT_CHANNEL, {
    channelType,
    open,
  },
);

export default toggleDisconnectChannelDialog;
