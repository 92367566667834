import { Button } from '@flowio/react-button';
import React, { useState, useEffect } from 'react';
import { TextField } from '@flowio/react-text-field';
import kebabCase from 'lodash/kebabCase';

import { RouterState } from 'react-router';
import AuthLayout from '../auth-layout';
import { AuthNavigation, AuthNavigationItem } from '../auth-navigation';
import ErrorAlert from '../../../../components/alert/error-alert';
import { WithValidationProps, ValidationSubmitFunction } from '../../../../components/with-validation/with-validation';
import { WithSubmitProps } from '../../../../components/with-submit/with-submit';
import * as styles from './registration.styles';
import getTextFieldValue from '../../../../utilities/getTextFieldValue';

type Props = WithValidationProps & WithSubmitProps & RouterState;

const Registration: React.FC<Props> = ({
  onSubmit,
  error,
  fields: { confirmPassword, email, password },
  onSubmitValidate,
  submitting,
  location,
}) => {
  const [isMounted, setIsMounted] = useState(false);
  const returnUrl = location.query.return_url ? encodeURIComponent(location.query.return_url) : '/';

  useEffect(() => {
    setIsMounted(true);

    return (): void => setIsMounted(false);
  }, [setIsMounted]);

  const handleSubmit: ValidationSubmitFunction = ({ isValid }, formData) => {
    if (isValid) onSubmit(formData);
  };

  return (
    <AuthLayout className={styles.registration}>
      <form noValidate onSubmit={onSubmitValidate(handleSubmit)}>
        {error && error.messages && error.messages.map(({ message }) => (
          <ErrorAlert key={kebabCase(message)}>
            {message}
          </ErrorAlert>
        ))}
        <TextField
          onChange={(e) => email.events.onChange(e)}
          onBlur={(e) => email.events.onBlur(e)}
          aria-required={email.isRequired}
          className={styles.email}
          clearable={false}
          intent={email.error ? 'negative' : 'neutral'}
          feedback={email.error}
          fluid
          hintText="Email"
          name="email"
          required={email.isRequired}
          value={getTextFieldValue(email.value)}
        />
        <TextField
          onChange={(e) => password.events.onChange(e)}
          onBlur={(e) => password.events.onBlur(e)}
          aria-required={password.isRequired}
          className={styles.password}
          clearable={false}
          intent={password.error ? 'negative' : 'neutral'}
          feedback={password.error}
          fluid
          hintText="Password"
          name="password"
          pattern=".{5,}"
          required={password.isRequired}
          type="password"
          value={getTextFieldValue(password.value)}
        />
        <TextField
          onChange={(e) => confirmPassword.events.onChange(e)}
          onBlur={(e) => confirmPassword.events.onBlur(e)}
          aria-required={confirmPassword.isRequired}
          clearable={false}
          intent={confirmPassword.error ? 'negative' : 'neutral'}
          feedback={confirmPassword.error}
          fluid
          hintText="Confirm Password"
          name="confirmPassword"
          pattern=".{5,}"
          required={confirmPassword.isRequired}
          type="password"
          value={getTextFieldValue(confirmPassword.value)}
        />
        <Button
          className={styles.button}
          content="Request Sign-up"
          disabled={!isMounted || submitting}
          fluid
          intent="primary"
          type="submit"
        />
      </form>
      <AuthNavigation>
        <AuthNavigationItem href={`/login?return_url=${returnUrl}`} label="Sign In" />
      </AuthNavigation>
    </AuthLayout>
  );
};

Registration.displayName = 'Registration';

export default Registration;
