import { createSelector } from 'reselect';
import property from 'lodash/property';
import getMarketingState from './getMarketingState';
import { RootState } from '../../../stores/types';
import { MarketingState } from '../types';
import DialogName from '../constants/DialogName';

export default createSelector<RootState, MarketingState, boolean>(
  getMarketingState,
  property(['dialogs', DialogName.DISCONNECT_PLATFORM, 'open']),
);
