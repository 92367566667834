import update from 'immutability-helper';

import ActionTypes from '../constants/action-types';
import { ExperienceState } from '../types';
import { RootActionTypes } from '../../../stores/types';

type State = ExperienceState['selectedExperienceLogisticsSettings'];

const initialState: State = '';

export default function (state: State = initialState, action: RootActionTypes): State {
  switch (action.type) {
    case ActionTypes.SELECT_EXPERIENCE_LOGISTICS_SETTINGS:
      return update(state, {
        $set: action.payload,
      });
    default: return state;
  }
}
