import { Image } from '@flowio/react-image';
import React from 'react';
import Button from '../MarketingButton';
import { MergedProps } from './types';
import MarketingDialog from '../MarketingDialog';

const modalTitle = 'Start adding localized product information to your Facebook catalog!';
const modalBody = 'Simply select the countries you want to add localized product data for. Flow will create and upload the selected country feeds to Facebook based on your Flow Experience settings.';

const AddFacebookCountryFeedDialog: React.FC<MergedProps> = ({
  open,
  onAddCountryClick,
  onCancelClick,
  organizationId,
  catalogId,
}) => (open ? (
  <MarketingDialog
    isOpen
    topContent={<Image accessibilityLabel="Modal image catalog localization" source="https://cdn.flow.io/console/modal-image-catalog-localization.png" />}
    title={modalTitle}
    subtitle={modalBody}
  >
    <Button onClick={onCancelClick} intent="neutral">Set up Later</Button>
    <Button onClick={(): void => onAddCountryClick(organizationId, catalogId)} intent="primary">Add Country Feed</Button>
  </MarketingDialog>
) : null);

AddFacebookCountryFeedDialog.displayName = 'AddFacebookCountryFeedDialog';

export default AddFacebookCountryFeedDialog;
