import ActionTypes from '../constants/action-types';
import { ThunkResult, ThunkExtraArguments, RootActionTypes } from '../../../stores/types';
import { ApiError } from '../../../utilities/clients/api-error';

export function fetchShopifySyncStatuRequest(): RootActionTypes {
  return {
    type: ActionTypes.FETCH_SHOPIFY_SYNC_STATUS_REQUEST,
  };
}

export function fetchShopifySyncStatusSuccess(
  organizationId: string,
  syncStatus: io.flow.v0.models.ShopifySyncStatus,
): RootActionTypes {
  return {
    type: ActionTypes.FETCH_SHOPIFY_SYNC_STATUS_SUCCESS,
    meta: {
      organizationId,
    },
    payload: syncStatus,
  };
}

export function fetchShopifySyncStatuFailure(
  error: ApiError,
): RootActionTypes {
  return {
    type: ActionTypes.FETCH_SHOPIFY_SYNC_STATUS_FAILURE,
    error: true,
    payload: error,
  };
}

export default function fetchShopifySyncStatus(
  organizationId: string,
): ThunkResult<Promise<void>> {
  return function fetchShopifySyncStatusEffect(
    dispatch,
    getState,
    extra: ThunkExtraArguments,
  ): Promise<void> {
    dispatch(fetchShopifySyncStatuRequest());
    return extra.api(getState())
      .shopifySyncStatuses.get({ organization: organizationId })
      .then((response) => {
        if (response.ok) {
          dispatch(fetchShopifySyncStatusSuccess(organizationId, response.body));
          return;
        }

        // TODO conversion-revisit
        const error = new ApiError(
          response,
        );
        dispatch(fetchShopifySyncStatuFailure(error));
      });
  };
}
