import React from 'react';
import { css } from '@emotion/react';
import { Interpolation } from '@emotion/core';
import noop from 'lodash/noop';
import kebabCase from 'lodash/kebabCase';
import {
  Table,
  TableHead,
  TableRow, TableRowColumn,
  TableHeadColumn,
  TableBody,

} from '@flowio/react-table';
import { Image } from '@flowio/react-image';
import * as styles from './product-catalog.styles';

interface Props {
  items: io.flow.v0.models.CatalogItemDocument[];
  attributes?: io.flow.v0.models.Attribute[];
  onItemSelection: Function;
}

interface Column {
  label: string;
}

const CatalogTable: React.FC<Props> = ({
  items,
  attributes,
  onItemSelection,
}) => {
  const getTableRowColumnStyle = (width?: string): Interpolation => css`
    td {
      padding-top: 10px;
      padding-bottom: 10px;
      text-overflow: clip;
      ${width && `width: ${width};`}
    }
  `;

  const getThumbnailStyle = (): React.CSSProperties => ({
    width: 'auto',
    height: '100px',
    minWidth: '100px',
    border: '1px solid rgb(210, 210, 210)',
    boxSizing: 'border-box',
  });

  // eslint-disable-next-line max-len
  const getThumbnailTableHeaderColumnStyle = (): Interpolation => css`
    th {
      width: 80px;
    }
  `;

  const getCustomItemAttributes = (
    attrs: io.flow.v0.models.Attribute[] = [],
  ): io.flow.v0.models.Attribute[] => attrs.filter((a) => a.options.show_in_catalog);

  const getColumns = (attrs: io.flow.v0.models.Attribute[] = []): Column[] => {
    let columns: Column[] = [
      { label: 'Number' },
      { label: 'Product Name' },
      { label: 'Product Category' },
    ];

    if (attrs.length) {
      const catalogAttributes = getCustomItemAttributes(attrs)
        .map((a) => ({ label: a.label }))
        .filter((a) => a.label) as Column[];
      columns = [columns[0]].concat(catalogAttributes).concat(columns.slice(1));
    }

    return columns;
  };

  const handleRowClick = (itemNumber: string): void => {
    onItemSelection(itemNumber);
  };

  return (
    <Table>
      <TableHead className={styles.catalogTableHeader}>
        <TableRow>
          {/* Testing was throwing a hissy fit about not having children */}
          <TableHeadColumn css={getThumbnailTableHeaderColumnStyle()}>
            {' '}
          </TableHeadColumn>
          {getColumns(attributes).map(({ label }) => (
            <TableHeadColumn key={kebabCase(label)}>
              {label}
            </TableHeadColumn>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map(({
          number,
          name,
          categories,
          images = { thumbnail: {} },
          ...itemProps
        }) => (
          <TableRow
            onClick={(): void => handleRowClick(number)}
            className={styles.catalogTableRow}
            key={kebabCase(`${number}-${name}`)}
          >
            <TableRowColumn css={getTableRowColumnStyle('80px')}>
              {((): React.ReactNode => {
                let source;
                if (Array.isArray(images) && images.length > 0) {
                  source = images[0].url;
                } else if (images.thumbnail) {
                  source = images.thumbnail.url;
                } else {
                  source = undefined;
                }
                if (!source) return ' ';
                return (
                  <Image
                    source={source}
                    accessibilityLabel={name}
                    style={getThumbnailStyle()}
                  />
                );
              })()}
            </TableRowColumn>
            <TableRowColumn css={getTableRowColumnStyle()}>
              {number}
            </TableRowColumn>
            {getCustomItemAttributes(attributes).map((attribute) => (
              <TableRowColumn
                key={kebabCase(attribute.id)}
                css={getTableRowColumnStyle()}
              >
                {itemProps.attributes[attribute.key]}
              </TableRowColumn>
            ))}
            <TableRowColumn css={getTableRowColumnStyle()}>
              {name}
            </TableRowColumn>
            <TableRowColumn
              css={getTableRowColumnStyle('20%')}
            >
              {categories.join(', ')}
            </TableRowColumn>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

CatalogTable.displayName = 'CatalogTable';

CatalogTable.defaultProps = {
  attributes: [],
  onItemSelection: noop,
};

export default CatalogTable;
