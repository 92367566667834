import ActionTypes from '../constants/ActionTypes';
import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';

function createRequestAction(): RootActionTypes {
  return {
    type: ActionTypes.CREATE_SHIPPING_CONFIGURATION_COPY_REQUEST,
  };
}

function createSuccessAction(
  payload: io.flow.v0.models.ShippingConfigurationCopy,
  organizationId: string,
  shippingConfigurationKey: string,
): RootActionTypes {
  return {
    type: ActionTypes.CREATE_SHIPPING_CONFIGURATION_COPY_SUCCESS,
    meta: {
      organizationId,
      shippingConfigurationKey,
    },
    payload,
  };
}

function createFailureAction(payload: FormattedErrorMessages): RootActionTypes {
  return {
    type: ActionTypes.CREATE_SHIPPING_CONFIGURATION_COPY_FAILURE,
    error: true,
    payload,
  };
}

function createShippingConfigurationCopy(
  organizationId: string,
  shippingConfigurationKey: string,
  shippingConfigurationCopyForm: io.flow.v0.models.ShippingConfigurationCopyForm,
): ThunkResult<Promise<RootActionTypes>> {
  return function createShippingConfigurationCopyEffects(
    dispatch,
    getState,
    extra,
  ): Promise<RootActionTypes> {
    dispatch(createRequestAction());

    return extra.api(getState()).shippingConfigurationsCopies.postByKey({
      body: shippingConfigurationCopyForm,
      key: shippingConfigurationKey,
      organization: organizationId,
    }).then((response) => {
      if (response.ok) {
        return dispatch(createSuccessAction(
          response.body,
          organizationId,
          shippingConfigurationKey,
        ));
      }

      const errors = formatErrorResponseV2(response);
      return dispatch(createFailureAction(errors));
    });
  };
}

export default createShippingConfigurationCopy;
