import { CancellationToken } from '@flowio/cancellation-token';

import { CentersPostQueryResponse } from '@flowio/api-sdk';
import {
  $HttpNotFound,
  $HttpUnauthorized,
  $HttpUnprocessableEntity,
} from '@flowio/api-internal-sdk';
import { ActionTypes } from '../constants';
import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';

interface QueryCenterParams {
  organizationId: string;
  queryBuilderForm: io.flow.v0.unions.QueryBuilderForm;
  cancellationToken?: any;
}

function createRequestAction(): RootActionTypes {
  return {
    type: ActionTypes.QUERY_CENTERS_REQUEST,
  };
}

function createSuccessAction(payload: io.flow.v0.models.Center[]): RootActionTypes {
  return {
    type: ActionTypes.QUERY_CENTERS_SUCCESS,
    payload,
  };
}

function createFailureAction(payload: FormattedErrorMessages): RootActionTypes {
  return {
    type: ActionTypes.QUERY_CENTERS_FAILURE,
    payload,
    error: true,
  };
}

function createCancelAction(): RootActionTypes {
  return {
    type: ActionTypes.QUERY_CENTERS_CANCELED,
  };
}

function queryCenters({
  organizationId,
  queryBuilderForm,
  cancellationToken = CancellationToken.none,
}: QueryCenterParams): ThunkResult<Promise<void>> {
  return function queryCentersSideEffects(
    dispatch,
    getState,
    extra,
  ): Promise<void> {
    const registration = cancellationToken.register(() => {
      dispatch(createCancelAction());
    });

    dispatch(createRequestAction());

    return extra.apiInternal(getState()).centerQueryBuilders.post({
      organization: organizationId,
      body: queryBuilderForm,
    }).then((response): Promise <
    CentersPostQueryResponse |
    $HttpUnauthorized<undefined> |
    $HttpNotFound<undefined> |
    $HttpUnprocessableEntity<any>
    > => {
      if (response.ok) {
        const centerQuery = {
          q: response.body.q,
        };

        return extra.api(getState()).centers.postQuery({
          organization: organizationId,
          body: centerQuery,
        });
      }
      return Promise.resolve(response);
    }).then((response) => {
      if (response.ok && !cancellationToken.cancellationRequested) {
        registration.unregister();
        dispatch(createSuccessAction(response.body));
        return;
      }

      const errors = formatErrorResponseV2(response);
      dispatch(createFailureAction(errors));
    });
  };
}

export default queryCenters;
