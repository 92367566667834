import React from 'react';
import {
  Page, PageHeader, PageTitle, PageContent,
} from '@flowio/react-page';
import { Breadcrumbs } from '@flowio/react-breadcrumbs';
import { Image } from '@flowio/react-image';
import { Box, BoxItem } from '@flowio/react-box';
import { Card, CardContent } from '@flowio/react-card';
import { Link } from '@flowio/react-link';
import { Button } from '@flowio/react-button';
import {
  Dialog,
  DialogTitle,
  DialogBody,
  DialogFooter,
  DialogActions,
} from '@flowio/react-dialog';
import noop from 'lodash/noop';
import moment from 'moment';

import * as styles from './LabsPage.styles';
import { MergedProps as Props } from '../types';
import augmentLab from '../../../utilities/augmentLab';

const LabsPage: React.FC<Props> = ({
  acceptButtonText,
  acceptedButtonText,
  acceptingUser,
  content,
  featureLink,
  imageSource,
  isOpen,
  lab,
  onAccept,
  onAcceptConfirmation,
  onAcceptLab,
  onCloseAcceptDialog,
  sidePanelContent,
  title,
}) => {
  if (!lab) {
    // loading lab data
    return null;
  }
  const augmentedLab = augmentLab(lab);
  return (
    <Page>
      <PageHeader>
        <PageTitle content="Control Labs" />
        <Breadcrumbs items={[{ content: 'Control Labs' }, { content: augmentedLab.displayName || title }]} />
      </PageHeader>
      <PageContent>
        <Card>
          <CardContent className={styles.cardContent}>
            <div className={styles.labHeroBg}>
              {imageSource && <div className={styles.heroTextBlend} />}
              {
                imageSource && (
                <Image
                  source={imageSource}
                  width={1200}
                  height={320}
                  accessibilityLabel="Hero Banner Image"
                />
                )
              }
              <div className={styles.labTitle}>
                {augmentedLab.icon && React.createElement<React.SVGAttributes<SVGElement>>(augmentedLab.icon, { className: 'icon' })}
                <h4>{title}</h4>
              </div>
            </div>
            <Box className={styles.boxContent} spacing="extraLoose" flexAuto>
              <BoxItem className={styles.details}>
                <div id="labContent" className={styles.content}>
                  {content}
                </div>
              </BoxItem>
              <BoxItem className={styles.listing}>
                <Button
                  className={styles.acceptBtn}
                  intent={lab.terms ? 'positive' : 'primary'}
                  type="button"
                  onClick={lab.terms ? noop : onAccept}
                  fluid
                  variant={lab.terms ? 'outline' : 'solid'}
                >
                  {lab.terms && (
                    acceptedButtonText || 'Accepted'
                  )}
                  {!lab.terms && (
                    acceptButtonText || 'Accept'
                  )}
                </Button>
                {lab.terms && (
                <div className={styles.accepted}>
                  Accepted
                  {acceptingUser && (
                    <>
                      {' by '}
                      <strong title={acceptingUser.email}>
                        <a href={`mailto:${acceptingUser.email}`}>
                          {acceptingUser.name.first}
                          {' '}
                          {acceptingUser.name.last}
                        </a>
                      </strong>
                    </>
                  )}
                  {` on ${moment.utc(lab.terms.accepted_at).format('MMM DD, YYYY')} `}

                </div>
                )}
                {!lab.terms && (
                <div className={styles.terms}>
                  By accepting, you agree to Flow&apos;s
                  {' '}
                  <Link external href="https://www.flow.io/policies/alpha-beta-terms/">terms and conditions</Link>
                  .
                </div>
                )}

                {featureLink && (
                <div className={styles.terms}>
                  To see the full feature description,&nbsp;
                  <Link external href={featureLink}>click here for details</Link>
                  .
                </div>
                )}

                {sidePanelContent}
              </BoxItem>
            </Box>
          </CardContent>
        </Card>
        <Dialog
          className={styles.acceptDialog}
          backdrop
          closeOnEscape
          onClose={onCloseAcceptDialog}
          open={isOpen}
        >
          <DialogTitle content="Accept Invitation?" />
          <DialogBody>
            Once accepted, the Flow team will reach out to you regarding the next steps to
            deploy the new feature.
          </DialogBody>
          <DialogFooter>
            <DialogActions>
              <Button onClick={onCloseAcceptDialog}>Cancel</Button>
              <Button onClick={() => onAcceptConfirmation(onAcceptLab)} intent="primary">Accept</Button>
            </DialogActions>
          </DialogFooter>
        </Dialog>
      </PageContent>
    </Page>
  );
};

export default LabsPage;
