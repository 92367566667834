import { ActionTypes } from '../constants';
import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import { DeleteShippingLaneFailure, DeleteShippingLaneSuccess } from '../types/actions';

interface DeleteShippingLaneByIdOptions {
  organizationId: string;
  shippingConfigurationKey: string;
  shippingLaneId: string;
}

function createRequestAction(): RootActionTypes {
  return {
    type: ActionTypes.DELETE_SHIPPING_LANE_REQUEST,
  };
}

function createSuccessAction(
  organizationId: string,
  shippingConfigurationKey: string,
  shippingLaneId: string,
): DeleteShippingLaneSuccess {
  return {
    type: ActionTypes.DELETE_SHIPPING_LANE_SUCCESS,
    meta: {
      organizationId,
      shippingConfigurationKey,
      shippingLaneId,
    },
  };
}

function createFailureAction(payload: FormattedErrorMessages): DeleteShippingLaneFailure {
  return {
    type: ActionTypes.DELETE_SHIPPING_LANE_FAILURE,
    error: true,
    payload,
  };
}

function deleteShippingLaneById({
  organizationId,
  shippingConfigurationKey,
  shippingLaneId,
}: DeleteShippingLaneByIdOptions): ThunkResult<
  Promise<DeleteShippingLaneSuccess | DeleteShippingLaneFailure>
  > {
  return function deleteShippingLaneByIdEffects(
    dispatch,
    getState,
    extra,
  ): Promise<DeleteShippingLaneSuccess | DeleteShippingLaneFailure> {
    dispatch(createRequestAction());

    return extra.api(getState()).shippingConfigurations.deleteLanesByKeyAndId({
      id: shippingLaneId,
      key: shippingConfigurationKey,
      organization: organizationId,
    }).then((response) => {
      if (response.ok) {
        const successAction = createSuccessAction(
          organizationId,
          shippingConfigurationKey,
          shippingLaneId,
        );
        dispatch(successAction);
        return successAction;
      }

      const errors = formatErrorResponseV2(response);
      const failureAction = createFailureAction(errors);
      dispatch(failureAction);
      return failureAction;
    });
  };
}

export default deleteShippingLaneById;
