import { isNotNil } from '@flowio/is';
import {
  type RootState,
  type ThunkDispatcher,
  type ThunkExtraArguments,
  type ThunkResult,
} from '../../../stores/types';
import ActionTypes from '../constants/action-types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';
import { getUserId } from '../../console';
import { getIsConnected } from '../selectors';
import checkCatalogAccess from './checkCatalogAccess';

const fetchAccessTokenSummary = (
  organization: string,
): ThunkResult<Promise<void>> => (
  dispatch: ThunkDispatcher,
  getState: () => RootState,
  extra: ThunkExtraArguments,
): Promise<void> => {
  dispatch({
    type: ActionTypes.FETCH_ACCESS_TOKEN_SUMMARY_REQUEST,
  });

  const state = getState();

  return extra.apiInternal(state).marketingGatewayFacebookConnectionAccessTokenSummaries.get({
    organization,
  }).then((response) => {
    if (response.ok) {
      if (
        response.body.user_id === getUserId(state)
        && getIsConnected(state)
        && isNotNil(response.body.catalog_id)) { // response.body.catalog_id can be undefined
        dispatch(checkCatalogAccess(response.body.catalog_id));
      }

      dispatch({
        type: ActionTypes.FETCH_ACCESS_TOKEN_SUMMARY_SUCCESS,
        payload: response.body,
      });
      return undefined;
    }

    dispatch({
      type: ActionTypes.FETCH_ACCESS_TOKEN_SUMMARY_FAILURE,
      payload: formatErrorResponseV2(response),
    });
    return undefined;
  });
};

export default fetchAccessTokenSummary;
