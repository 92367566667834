import DialogName from '../constants/DialogName';
import showDialog from './showDialog';

export default function showReleaseFeatureConfirm(
  organizationId: string,
  feature: io.flow.internal.v0.models.Feature,
) {
  return showDialog(DialogName.RELEASE_FEATURE_CONFIRM)({
    initialValues: {
      organizationId,
      featureKey: feature.key,
      featureName: feature.name,
    },
  });
}
