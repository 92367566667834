// Constants for discriminator
// https://app.apibuilder.io/flow/fulfillment/0.6.20#union-tier_rule_outcome

enum TierRuleOutcomeDiscriminatorValue {
  AMOUNT_MARGIN = 'amount_margin',
  AT_COST = 'at_cost',
  FLAT_RATE = 'flat_rate',
  PERCENT_MARGIN = 'percent_margin',
}

export default TierRuleOutcomeDiscriminatorValue;
