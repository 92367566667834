import { reduxFormConfirm } from '@flowio/redux-form-overlay';
import { SubmissionError } from 'redux-form';

import { deletePriceBookByKey } from '../../resources/operations/price-books';
import { createToast } from '../../console/actions';
import DeletePriceBook from '../components/DeletePriceBook';
import FormName from '../constants/FormName';

function handleSubmit(values, dispatch) {
  return dispatch(deletePriceBookByKey(values)).then((action) => {
    if (action.error) {
      throw new SubmissionError(action.payload.response);
    }

    return action;
  });
}

function handleSubmitSuccess(action, dispatch) {
  dispatch(createToast({
    content: 'Successfully deleted price book',
    intent: 'positive',
  }));
}

export default reduxFormConfirm({
  form: FormName.DELETE_PRICE_BOOK_FORM,
  intent: 'negative',
  onSubmit: handleSubmit,
  onSubmitSuccess: handleSubmitSuccess,
})(DeletePriceBook);
