import React from 'react';
import { Box, BoxItem } from '@flowio/react-box';
import { Label } from '@flowio/react-label';

import * as styles from './TierList.styles';
import { TierListItemDeliveryEstimateProps } from '../../types/components';

const TierListItemDeliveryEstimate: React.FC<TierListItemDeliveryEstimateProps> = ({
  isCustomTierEstimateType,
  customTierCopy,
}) => (
  <Box spacing="loose">
    <BoxItem className={styles.tierLabel}>
      <Label content="Delivery Window" />
    </BoxItem>
    <BoxItem>
      <span className={styles.tierDeliveryWindow}>
        {isCustomTierEstimateType ? 'Custom Copy' : 'Flow Delivery Window Estimate'}
      </span>
      {isCustomTierEstimateType && (
        <span className={styles.tierDeliveryCustomLabel}>{customTierCopy}</span>
      )}
    </BoxItem>
  </Box>
);

TierListItemDeliveryEstimate.displayName = 'TierListItemDeliveryEstimate';

export default TierListItemDeliveryEstimate;
