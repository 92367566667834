import Client from './client';

export default class OrganizationBillingStatements extends Client {
  constructor(opts) {
    let options = opts;

    if (typeof opts === 'string') {
      options = { host: opts }; // convert host string to options object
    }

    options.serviceName = 'API Internal';

    super(options);
  }

  get(organization, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/account/statements`, options);
  }

  getById(organization, id, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/account/statements/${id}`, options);
  }

  deleteById(organization, id, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/account/statements/${id}`, {
      ...options,
       method: 'DELETE',
    });
  }

  postEmailById(organization, id, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/account/statements/${id}/email`, {
      ...options,
       method: 'POST',
    });
  }

}
