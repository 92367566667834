import { createSelector } from 'reselect';
import get from 'lodash/get';
import map from 'lodash/map';

import getExclusionRuleWorksheet from './getExclusionRuleWorksheet';

/**
 * Returns the initial list of region identifiers associated with the exclusion
 * rule being created or edited. Generally, when a new exclusion rule is being
 * created the result is an empty list.
 */
const getRegionsForExclusionRuleWorksheet = createSelector(
  getExclusionRuleWorksheet,
  (state) => map(get(state, 'exclusionRule.regions'), 'id'),
);

export default getRegionsForExclusionRuleWorksheet;
