import update from 'immutability-helper';
import { RootActionTypes } from '../../../stores/types';
import ActionType from '../constants/ActionType';
import { ExclusionRuleDeleteConfirmationState } from '../types';

const initialState = {
  deleting: false,
  deleteFailed: false,
  deleteSucceeded: false,
  exclusionRuleToBeDeleted: undefined,
  organizationId: undefined,
  visibility: 'hidden',
};

export default function (
  state: ExclusionRuleDeleteConfirmationState = initialState,
  action: RootActionTypes,
) {
  switch (action.type) {
    case ActionType.ShowExclusionRuleDeleteConfirmation:
      return update(state, {
        deleting: { $set: false },
        deleteFailed: { $set: false },
        deleteSucceeded: { $set: false },
        exclusionRuleToBeDeleted: { $set: action.payload.exclusionRule },
        organizationId: { $set: action.payload.organizationId },
        visibility: { $set: 'visible' },
      });
    case ActionType.HideExclusionRuleDeleteConfirmation:
      return update(state, {
        exclusionRuleToBeDeleted: { $set: undefined },
        organizationId: { $set: undefined },
        visibility: { $set: 'hidden' },
      });
    case ActionType.DeleteExclusionRuleRequest:
      return update(state, {
        deleting: { $set: true },
        // Reset in case user tries to delete once again after the an error has occured
        deleteFailed: { $set: false },
        deleteSucceeded: { $set: false },
      });
    case ActionType.DeleteExclusionRuleSuccess:
      return update(state, {
        deleting: { $set: false },
        deleteSucceeded: { $set: true },
        visibility: { $set: 'hidden' },
      });
    case ActionType.DeleteExclusionRuleFailure:
      return update(state, {
        deleting: { $set: false },
        deleteFailed: { $set: true },
      });
    default:
      return state;
  }
}
