import React, { useCallback, useEffect } from 'react';
import BemHelper from '@flowio/bem-helper';
import {
  Card, CardHeader, CardContent, CardFooter, CardTitle,
} from '@flowio/react-card';

import Alert from '../../../../components/alert/alert';
import WebhookDeliveries from '../webhook-deliveries/webhook-deliveries';
import WebhookForm from '../../containers/webhook-form';
import ExponentialBackoff from '../../../../utilities/exponential-backoff';

type Props = {
  deliveries?: io.flow.v0.models.WebhookDelivery[];
  hasMoreDeliveries?: boolean;
  onRequestMoreDeliveries?: () => void;
  onRequestRedeliver?: () => void;
  onSyncDeliveries?: (pending: string[]) => void;
  showUpdatedNotification?: boolean;
  webhook?: io.flow.v0.models.Webhook;
};

if (process.browser) {
  require('./manage-webhook.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('manage-webhook');

const syncBackoff = new ExponentialBackoff(() => {});

const ManageWebhook: React.FC<Props> = ({
  deliveries = [],
  onSyncDeliveries,
  hasMoreDeliveries,
  onRequestRedeliver,
  onRequestMoreDeliveries,
  showUpdatedNotification,
  webhook,
}) => {
  const getPending = useCallback((): string[] => deliveries.filter(
    (d) => d.status === 'pending',
  ).map((d) => d.id), [deliveries]);

  const sync = useCallback(() => {
    const pending = getPending();

    if (pending.length) {
      if (onSyncDeliveries) {
        onSyncDeliveries(pending);
      }
    } else {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      syncBackoff.cancel();
    }
  }, [getPending, onSyncDeliveries]);

  syncBackoff.func = () => sync();

  useEffect(() => {
    if (getPending().length) {
      syncBackoff.backoff();
    }
  }, [getPending]);

  return (
    <div className={bem.block()}>
      <Card>
        <CardContent>
          {showUpdatedNotification && (
            <Alert>
              Okay, the webhook was successfully updated.
            </Alert>
          )}
          <WebhookForm editing webhook={webhook} />
        </CardContent>
      </Card>
      <Card>
        <CardHeader dividing>
          <CardTitle content="Recent Deliveries" />
        </CardHeader>
        <CardContent>
          <WebhookDeliveries
            deliveries={deliveries}
            hasMoreDeliveries={hasMoreDeliveries}
            onRequestRedeliver={onRequestRedeliver}
            onRequestMoreDeliveries={onRequestMoreDeliveries}
          />
        </CardContent>
        <CardFooter />
      </Card>
    </div>
  );
};
ManageWebhook.displayName = 'ManageWebhook';

export default ManageWebhook;
