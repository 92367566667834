import { Box } from '@flowio/react-box';
import { Button } from '@flowio/react-button';
import React from 'react';
import {
  Worksheet, WorksheetHeader, WorksheetTitle, WorksheetBody,
} from '@flowio/react-worksheet';
import FormName from '../../../constants/FormName';
import OptinMessageForm from '../../OptinMessageForm/containers/OptinMessageForm';
import { Props } from '../types';

const UpdateOptinMessageWorksheet: React.FC<Props> = ({
  initialValues,
  isCancelDisabled,
  isSaveDisabled,
  onCancel,
  onSave,
  onSubmit,
  onSubmitSuccess,
  open = false,
}) => (
  <Worksheet open={open}>
    <WorksheetHeader>
      <Box flexAuto justifyContent="between">
        <Button content="Cancel" disabled={isCancelDisabled} onClick={onCancel} />
        <WorksheetTitle content="Update Profile" />
        <Button content="Save" disabled={isSaveDisabled} intent="primary" onClick={onSave} />
      </Box>
    </WorksheetHeader>
    <WorksheetBody>
      <OptinMessageForm
        initialValues={initialValues}
        form={FormName.UPDATE_OPTIN_ATTRIBUTE_FORM}
        onSubmit={onSubmit}
        onSubmitSuccess={onSubmitSuccess}
      />
    </WorksheetBody>
  </Worksheet>
);

UpdateOptinMessageWorksheet.displayName = 'UpdateOptinMessageWorksheet';

export default UpdateOptinMessageWorksheet;
