import {
  getPageResults,
} from '@flowio/redux-resource-pagination-plugin';
import { getOrganizationId } from '../../organization';

export const getPriceBookMappingsResource = (state) => state.resources.priceBookMappings;

export const getPriceBookMappings = (state) => {
  const organizationId = getOrganizationId(state);
  const priceBookMappings = getPageResults(getPriceBookMappingsResource(state), organizationId);
  return priceBookMappings[0];
};
