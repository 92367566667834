import { isEmpty, isNotNil } from '@flowio/is';
import { DataState } from '../../types';
import { DataStateStatus } from './types';

export default function getDataStateStatus(state: DataState<unknown>): DataStateStatus {
  if (state.loading) {
    return DataStateStatus.LOADING;
  }

  if (state.loading === false && isNotNil(state.errors)) {
    return DataStateStatus.ERROR;
  }

  if (!isEmpty(state.data)) {
    return DataStateStatus.LOADED;
  }

  if (state.data === null || (Array.isArray(state.data) && state.data.length === 0)) {
    return DataStateStatus.EMPTY;
  }

  return DataStateStatus.UNKNOWN;
}
