import get from 'lodash/get';

import { createSelector } from 'reselect';
import { RootState } from '../../../stores/types';
import { LogisticsRootState } from '../types/state';
import getLogisticsState from './getLogisticsState';

export default createSelector<
RootState, LogisticsRootState, io.flow.v0.models.SurchargeSettingDisplay[]
>(
  getLogisticsState,
  (logisticState) => get(logisticState, 'entities.surcharges.settings'),
);
