import { TierStrategy } from '@flowio/api-constants';
import React from 'react';
import { Stack, StackItem } from '@flowio/react-stack';
import { Box, BoxItem } from '@flowio/react-box';
import { Airplane, Cost } from '@flowio/react-icons';
import { Label } from '@flowio/react-label';
import sortBy from 'lodash/sortBy';

import tierStrategyToWord from '../../utilities/tierStrategyToWord';

import * as styles from './TierList.styles';
import { TierListItemCarrierProps } from '../../types/components';

const TierListItemCarrier: React.FC<TierListItemCarrierProps> = ({
  strategy,
  tierServices,
}) => (
  <Box spacing="loose">
    <BoxItem className={styles.tierLabel}>
      <Label content="Carriers & Service Levels" />
    </BoxItem>
    <BoxItem>
      <Stack className={styles.tierValueStack} orientation="vertical">
        <StackItem>
          <span>
            Carrier selected by
            {
              strategy === TierStrategy.FASTEST ? (
                <Airplane className={styles.tierStrategyIcon} width={16} />
              ) : (
                <Cost className={styles.tierStrategyIcon} width={16} />
              )
            }
            <b>{tierStrategyToWord(strategy)}</b>
          </span>
        </StackItem>
        {sortBy(tierServices, 'carrier.name').map((service) => (
          <StackItem key={service.name}>
            <span className={styles.tierServices}>
              <b>
                {`${service.carrier.name} `}
              </b>
              {service.name}
            </span>
          </StackItem>
        ))}
      </Stack>
    </BoxItem>
  </Box>
);

TierListItemCarrier.displayName = 'TierListItemCarrier';

export default TierListItemCarrier;
