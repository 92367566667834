import * as d3 from 'd3';
import React, { Component } from 'react';
import { regions } from '@flowio/lib-reference-javascript';
import find from 'lodash/find';
import get from 'lodash/get';
import range from 'lodash/range';
import classNames from 'classnames';
import { toNumberWithCommas } from '../../../../utilities/numbers';
import * as styles from './product-catalog-overview.styles';

interface Props {
  experience: io.flow.v0.models.Experience;
  // number of assigned products
  productCount: number;
  // percent of products included in experience (e.g. 0.85)
  includedPercent: number;
  // percent of products excluded from experience (e.g. 0.15)
  excludedPercent: number;
  // percent of products restricted from experience (e.g 0.05)
  restrictedPercent: number;
}

interface PieData {
  id: string;
  value: number;
}

export default class ProductCatalogOverview extends Component<Props> {
  static displayName = 'ProductCatalogOverview';

  static defaultProps = {
    productCount: 0,
    includedPercent: 0,
    excludedPercent: 0,
    restrictedPercent: 0,
  };

  componentDidMount(): void {
    const { includedPercent, excludedPercent, restrictedPercent } = this.props;
    const data: PieData[] = [
      {
        id: 'included',
        value: includedPercent,
      },
      {
        id: 'excluded',
        value: excludedPercent,
      },
      {
        id: 'restricted',
        value: restrictedPercent,
      },
    ];
    const height = 55;
    const width = 55;
    const radius = 25;
    const innerRadius = 14;
    const arc = d3.arc<d3.PieArcDatum<PieData>>()
      .outerRadius(radius)
      .innerRadius(innerRadius);
    const pie = d3.pie<PieData>().value((d) => d.value).sort(null);
    const colorScale = d3.scaleOrdinal<number, string>().range(['rgb(33,145,236)', 'rgb(245,166,35)', 'rgb(232,48,23)']).domain(range(3));

    const svg = d3.select('#chartContainer')
      .append('svg')
      .attr('height', height)
      .attr('width', width)
      .attr('class', 'product-overview-chart')
      .attr('transform', 'translate(5, 5)');

    svg.selectAll('.arc')
      .data(pie(data))
      .enter()
      .append('path')
      .attr('class', 'arc')
      .attr('fill', (_d, index) => colorScale(index))
      .attr('d', (d) => arc(d))
      .attr('transform', `translate(${radius}, ${radius})`);
  }

  componentWillUnmount(): void {
    d3.select('.product-overview-chart').remove();
  }

  render(): JSX.Element {
    const {
      experience,
      productCount,
      includedPercent,
      excludedPercent,
      restrictedPercent,
    } = this.props;

    const region = find(regions.all, { id: experience.region.id });

    return (
      <div className="product-catalog-overview">
        <p>
          Overview of products assigned to
          {' '}
          {get(region, 'name', experience.region.id)}
          {' '}
          from your entire
          product catalog.
        </p>
        <div className="clearfix">
          <div className={styles.globalMetrics}>
            <dl className={styles.metric}>
              <dt className={styles.metricLabel}>Products</dt>
              <dd data-cucumber="product-count" className={styles.metricValue}>
                {toNumberWithCommas(productCount)}
              </dd>
            </dl>
          </div>
          <div className={styles.localMetrics}>
            <div id="chartContainer" className={styles.chartContainer} />
            <dl className={styles.metric}>
              <dt className={styles.metricLabel}>Included</dt>
              <dd
                className={classNames(
                  styles.metricValue, styles.metricValueInclusion, {
                    [styles.metricValueZero]: includedPercent === 0,
                  },
                )}
              >
                {`${includedPercent}%`}
              </dd>
            </dl>
            <dl className={styles.metric}>
              <dt className={styles.metricLabel}>Excluded</dt>
              <dd
                className={classNames(
                  styles.metricValue, styles.metricValueExclusion, {
                    [styles.metricValueZero]: excludedPercent === 0,
                  },
                )}
              >
                {`${excludedPercent}%`}
              </dd>
            </dl>
            <dl className={styles.metric}>
              <dt className={styles.metricLabel}>Restricted</dt>
              <dd
                className={classNames(
                  styles.metricValue, styles.metricValueRestriction, {
                    [styles.metricValueZero]: restrictedPercent === 0,
                  },
                )}
              >
                {`${restrictedPercent}%`}
              </dd>
            </dl>
          </div>
        </div>
      </div>
    );
  }
}
