import DialogName from '../constants/DialogName';
import showDialog from './showDialog';

export default function showDeleteRuleConfirm(
  organizationId: string,
  feature: io.flow.internal.v0.models.Feature,
  ruleId: string,
) {
  return showDialog(DialogName.DELETE_RULE_CONFIRM)({
    initialValues: {
      organizationId,
      feature,
      ruleId,
    },
  });
}
