import { Button } from '@flowio/react-button';
import React, { useEffect, useState } from 'react';
import { TextField } from '@flowio/react-text-field';
import kebabCase from 'lodash/kebabCase';

import { css } from '@emotion/css';
import AuthLayout from '../auth-layout';
import { AuthNavigation, AuthNavigationItem } from '../auth-navigation';
import ErrorAlert from '../../../../components/alert/error-alert';
import { WithValidationProps, ValidationSubmitFunction } from '../../../../components/with-validation/with-validation';
import { WithSubmitProps } from '../../../../components/with-submit/with-submit';
import getTextFieldValue from '../../../../utilities/getTextFieldValue';

const buttonStyles = css`
  margin-top: 114px;
`;

const actionsStyles = css`
  margin-top: 212px;
`;

type Props = WithValidationProps & WithSubmitProps;

const ForgotPassword: React.FC<Props> = ({
  onSubmit,
  error,
  fields: { email },
  onSubmitValidate,
  submitted,
  submitting,
}) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);

    return (): void => setIsMounted(false);
  }, [setIsMounted]);

  const handleSubmit: ValidationSubmitFunction = ({ isValid }, formData) => {
    if (isValid) onSubmit(formData);
  };

  return (
    <AuthLayout className="forgot-password">
      {submitted ? (
        <div>
          <p>
            You will receive an email with instructions on how to
            reset your password.
          </p>
          <AuthNavigation className={actionsStyles}>
            <AuthNavigationItem href="/login" label="Sign In" />
          </AuthNavigation>
        </div>
      ) : (
        <form noValidate onSubmit={onSubmitValidate(handleSubmit)}>
          {error && (
            <ErrorAlert>
              {error.messages && error.messages.map(({ message }) => (
                <p key={kebabCase(message)}>
                  {message}
                </p>
              ))}
            </ErrorAlert>
          )}
          <p>
            Enter your email and we&apos;ll send you information on
            how to reset your password.
          </p>
          <TextField
            onChange={(e) => email.events.onChange(e)}
            onBlur={(e) => email.events.onBlur(e)}
            aria-required={email.isRequired}
            clearable={false}
            intent={email.error ? 'negative' : 'neutral'}
            feedback={email.error}
            fluid
            hintText="Email"
            name="email"
            value={getTextFieldValue(email.value)}
            required={email.isRequired}
          />
          <Button
            className={buttonStyles}
            content="Reset Password"
            disabled={!isMounted || submitting}
            fluid
            intent="primary"
            type="submit"
          />
          <AuthNavigation>
            <AuthNavigationItem href="/login" label="Sign In" />
            <AuthNavigationItem href="/register" label="Request an Account" />
          </AuthNavigation>
        </form>
      )}
    </AuthLayout>
  );
};

ForgotPassword.displayName = 'ForgotPassword';

export default ForgotPassword;
