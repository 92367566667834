import { combineReducers } from 'redux';
import { createResourceReducer } from '@flowio/redux-resource';
import { paginationPlugin } from '@flowio/redux-resource-pagination-plugin';

export default combineReducers({
  currencies: createResourceReducer('currencies', {
    idAttribute: 'iso_4217_3',
  }),
  priceBooks: createResourceReducer('priceBooks', {
    plugins: [paginationPlugin],
    initialState: {
      ...paginationPlugin.initialState,
    },
  }),
  priceBookMappings: createResourceReducer('priceBookMappings', {
    plugins: [paginationPlugin],
    initialState: {
      ...paginationPlugin.initialState,
    },
  }),
  priceBookItems: createResourceReducer('priceBookItems', {
    plugins: [paginationPlugin],
    initialState: {
      ...paginationPlugin.initialState,
    },
  }),
});
