import React from 'react';
import { Image } from '@flowio/react-image';
import { TableBody, TableRow, TableRowColumn } from '@flowio/react-table';
import formatCount from '../../../utilities/formatCount';

type Props = {
  details?: io.flow.internal.v0.unions.MarketingGatewayChannelDetails;
};

const isSupportedDetails = (
  channelDetails?: io.flow.internal.v0.unions.MarketingGatewayChannelDetails,
): channelDetails is io.flow.internal.v0.models.MarketingGatewaySupportedChannelDetails => (
  !!channelDetails && (
    channelDetails as io.flow.internal.v0.models.MarketingGatewaySupportedChannelDetails
  ).discriminator === 'marketing_gateway_supported_channel_details'
);

const SupportedChannelDetails: React.FC<Props> = ({
  details,
}) => {
  const channelDetails = isSupportedDetails(details) ? details : undefined;

  return (
    <div>
      {channelDetails && (
        <TableBody>
          {channelDetails.datasources && channelDetails.datasources.length > 0 && (
            <TableRow>
              <TableRowColumn>Product Data Source</TableRowColumn>
              <TableRowColumn>
                <Image
                  width="16"
                  accessibilityLabel="google logo"
                  source="https://cdn.flow.io/console/logo_google.svg"
                />
                &nbsp;&nbsp;&nbsp;
                {channelDetails.datasources[0].name}
              </TableRowColumn>
            </TableRow>
          )}
          {channelDetails.localized_feed_count && (
            <TableRow>
              <TableRowColumn># of Localized Feeds</TableRowColumn>
              <TableRowColumn>{formatCount(channelDetails?.localized_feed_count, 'feed')}</TableRowColumn>
            </TableRow>
          )}
        </TableBody>
      )}
    </div>
  );
};

SupportedChannelDetails.displayName = 'SupportedChannelDetails';

export default SupportedChannelDetails;
