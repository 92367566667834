import { createSelector, Selector } from 'reselect';
import get from 'lodash/get';
import { RootState } from '../../../stores/types';
import { ClassificationState } from '../types';

export const getclassificationState: Selector<RootState, ClassificationState> = (
  state: RootState,
): ClassificationState => state.classification;

export const getStatistics = createSelector<
RootState, ClassificationState, ClassificationState['statistics']
>(
  [getclassificationState],
  (classification) => classification.statistics,
);

export const getHs6Codes = createSelector<
RootState, ClassificationState, ClassificationState['hs6']['results'] | undefined
>(
  getclassificationState,
  (classification) => classification.hs6.results,
);

export const getHs6UiState = createSelector<
RootState, ClassificationState, ClassificationState['ui']['hs6'] | undefined
>(
  getclassificationState,
  (classification) => get(classification, 'ui.hs6'),
);
