import withNumberFormat from '../../../utilities/numbers/withNumberFormat';

const formatCount = (count?: number, unit?: string) => {
  if (count === undefined) {
    return '-';
  }

  const suffix = unit ? ` ${unit}${count === 1 ? '' : 's'}` : '';

  return `${withNumberFormat(count)}${suffix}`;
};

export default formatCount;
