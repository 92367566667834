export default function findOrgRoles(
  organizationId: string,
  memberships: io.flow.v0.models.Membership[],
): io.flow.v0.enums.FlowRole[] {
  const orgMembership = memberships.find(
    (membership) => membership.organization.id === organizationId,
  );

  return orgMembership ? orgMembership.roles : [];
}
