import ActionTypes from '../constants/action-types';
import { RootActionTypes } from '../../../stores/types';

export default function setCloneExperience(
  experience: io.flow.v0.models.Experience,
): RootActionTypes {
  return {
    type: ActionTypes.SET_CLONE_EXPERIENCE,
    payload: experience,
  };
}
