import React from 'react';
import { Popover } from '@flowio/react-popover';
import classNames from 'classnames';
import Components from './components';
import * as styles from './price-check-form.styles';

const Mdash = (): JSX.Element => (
  <span>
    &mdash;
  </span>
);

interface Props {
  description: string;
  base?: string;
  local?: string;
  total?: boolean;
  baseComponents?: io.flow.v0.models.PriceDetailComponent[];
  localComponents?: io.flow.v0.models.PriceDetailComponent[];
  showComponents?: boolean;
}

const PriceDetailRow: React.FC<Props> = (props) => {
  const {
    description, base, local,
    total, baseComponents, localComponents, showComponents,
  } = props;

  return (
    <div className={classNames(styles.detailsRow, { [styles.detailsRowTotal]: total })}>
      <div
        className={classNames(
          styles.detailsColDesc,
          { [styles.detailsColDescTotal]: total },
        )}
      >
        {description}
      </div>
      <div
        className={classNames(styles.detailsColBase,
          { [styles.detailsColBaseTotal]: total })}
      >
        {baseComponents && baseComponents.length > 0 && (
          <Popover
            openOnHover
            trigger={(
              <span
                className={classNames({
                  [styles.valueHasComponents]: showComponents
                    && !!baseComponents && baseComponents.length,
                })}
              >
                {base || <Mdash />}
              </span>
            )}
          >
            <Components components={baseComponents} />
          </Popover>
        )}
        {baseComponents && baseComponents.length < 1 && (
          <span
            className={classNames({
              [styles.valueHasComponents]: showComponents
                && !!baseComponents && baseComponents.length,
            })}
          >
            {base || <Mdash />}
          </span>
        )}
      </div>
      <div
        className={classNames(styles.detailsColLocal,
          { [styles.detailsColLocalTotal]: total })}
      >
        {localComponents && localComponents.length > 0 && (
          <Popover
            openOnHover
            trigger={(
              <span
                className={classNames({
                  [styles.valueHasComponents]: showComponents
                    && !!localComponents && localComponents.length,
                })}
              >
                {local || <Mdash />}
              </span>
            )}
          >
            <Components components={localComponents} />
          </Popover>
        )}
        {localComponents && localComponents.length < 1 && (
          <span
            className={classNames({
              [styles.valueHasComponents]: showComponents
                && !!localComponents && localComponents.length,
            })}
          >
            {local || <Mdash />}
          </span>
        )}
      </div>
    </div>
  );
};
PriceDetailRow.displayName = 'PriceDetailRow';

PriceDetailRow.defaultProps = {
  base: undefined,
  local: undefined,
  total: undefined,
  baseComponents: [],
  localComponents: [],
  showComponents: true,
};

export default PriceDetailRow;
