import {
  match,
  MatchLocationArgs,
  MatchHistoryArgs,
  MatchCallback,
} from 'react-router';

interface MatchRoutesResult {
  redirectLocation: Parameters<MatchCallback>[1];
  renderProps: any;
}

export default function matchRoutes(
  options: MatchLocationArgs | MatchHistoryArgs,
): Promise<MatchRoutesResult> {
  return new Promise((resolve, reject) => {
    match(options, (error, redirectLocation, renderProps) => {
      if (error) reject(error);
      else resolve({ redirectLocation, renderProps });
    });
  });
}
