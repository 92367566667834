import filter from 'lodash/filter';
import update from 'immutability-helper';

import ActionTypes from '../../constants/action-types';
import { ToastState } from '../../types/state';
import { RootActionTypes } from '../../../../stores/types';

const defaultState: ToastState = [];

export default function (state = defaultState, action: RootActionTypes): ToastState {
  switch (action.type) {
    case ActionTypes.CREATE_TOAST:
      return update(state, { $push: [action.payload] });
    case ActionTypes.DELETE_TOAST:
      return filter(state, (toast) => toast.eventKey !== action.payload.eventKey);
    default:
      return state;
  }
}
