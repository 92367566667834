import DialogName from '../constants/DialogName';
import showDialog from './showDialog';

export default function showEditFeatureWorksheet(
  organizationId: string,
  feature: io.flow.internal.v0.models.Feature,
) {
  return showDialog(DialogName.EDIT_FEATURE_WORKSHEET)({
    initialValues: {
      organizationId,
      name: feature.name,
      key: feature.key,
      type: feature.type,
      description: feature.description,
      rules: feature.rules,
    },
  });
}
