import { browserHistory } from 'react-router';
import { RootState, ThunkDispatcher } from '../../../stores/types';
import { getOrganizationId } from '../../organization/selectors';

const visitOptinMessageHomePage = () => (
  _dispatch: ThunkDispatcher,
  getState: () => RootState,
): void => browserHistory.push({
  pathname: `/${getOrganizationId(getState())}/optin/messages`,
});

export default visitOptinMessageHomePage;
