import { ResponseError } from '@flowio/redux-async-middleware';

/**
 * Creates an FSA failure type descriptor for @flowio/redux-async-middleware
 * compatible with our @flowio/node-sdk library.
 * @param {{ type: string, meta: object }} descriptor
 */
export default function createFailureDescriptor(descriptor) {
  return {
    type: descriptor.type,
    meta: descriptor.meta,
    payload: (state, response) => {
      const {
        status,
        statusText = 'Response is outside the 2xx status code range',
        result,
      } = response;
      return new ResponseError(status, statusText, result);
    },
  };
}
