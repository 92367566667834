import { ExperiencePaymentMethodTag } from '@flowio/api-constants';

export const setPaymentMethodDisplayState = (
  paymentMethod: io.flow.v0.models.PaymentMethodRule,
  display: boolean,
): io.flow.v0.models.PaymentMethodRule => {
  if (paymentMethod == null) {
    throw new Error('setPaymentMethodDisplayState: paymentMethod can not be null');
  }
  const isShown = paymentMethod.tags.some((tag) => (
    tag.discriminator === 'experience_payment_method_tag'
    && tag.value === ExperiencePaymentMethodTag.DISPLAY
  ));

  if ((isShown && display) || (!isShown && !display)) {
    // do nothing
    return paymentMethod;
  }
  const newTags: io.flow.v0.unions.PaymentMethodTag[] = display
    ? [
      ...paymentMethod.tags,
      {
        discriminator: 'experience_payment_method_tag',
        value: ExperiencePaymentMethodTag.DISPLAY,
      },
    ] : paymentMethod.tags.filter((tag) => (!(
      tag.discriminator === 'experience_payment_method_tag'
      && tag.value === ExperiencePaymentMethodTag.DISPLAY
    )));

  return {
    ...paymentMethod,
    tags: newTags,
  };
};

export const findDisplayTagPredicate = (
  tag: io.flow.v0.unions.PaymentMethodTag,
): boolean => (tag.discriminator === 'experience_payment_method_tag' && tag.value === ExperiencePaymentMethodTag.DISPLAY);

export const sortByDisplayPosition = (
  a: io.flow.v0.models.PaymentMethodRule,
  b: io.flow.v0.models.PaymentMethodRule,
): number => a.display_position - b.display_position;

export const sortByDisplayThenPosition = (
  a: io.flow.v0.models.PaymentMethodRule,
  b: io.flow.v0.models.PaymentMethodRule,
): number => {
  const aDisplayed = a.tags && a.tags.find(findDisplayTagPredicate);
  const bDisplayed = b.tags && b.tags.find(findDisplayTagPredicate);
  if (aDisplayed && !bDisplayed) {
    return -1;
  }
  if (!aDisplayed && bDisplayed) {
    return 1;
  }
  return a.display_position - b.display_position;
};

/**
 * @param {object[]} pms
 * @returns {object[]}
 */
export const mapRulesWithSequentialDisplayPosition = (
  pms: io.flow.v0.models.PaymentMethodRule[],
): io.flow.v0.models.PaymentMethodRule[] => {
  if (!Array.isArray(pms)) {
    throw new Error('mapRulesWithSequentialDisplayPosition expects an array');
  }
  return pms.map((pm, i) => ({
    ...pm,
    display_position: i + 1,
  }));
};

export default {
  mapRulesWithSequentialDisplayPosition,
  setPaymentMethodDisplayState,
  sortByDisplayPosition,
  sortByDisplayThenPosition,
};
