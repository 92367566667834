import React from 'react';
import assign from 'lodash/assign';
import noop from 'lodash/noop';

import PricingDisplay from '../../pricing-display';
import ItemMargins from '../../item-margins';
import PriceCheck from '../../price-check';
import PriceRounding from '../../price-rounding';
import PricingFormat from '../../pricing-format';
import Navigation from '../../navigation';
import PriceBook from '../../PriceBook';
import { MergedProps as Props } from '../types';

export default class Pricing extends React.Component<Props> {
  static displayName = 'Pricing';

  static defaultProps = {
    activeSection: '',
    currencySettings: [],
    clearPriceCheck: noop,
    onCurrencyFunctionsNavigation: noop,
    onDeleteMargin: noop,
    onMarginEdit: noop,
    onNewMargin: noop,
    onSelectPriceBook: noop,
    onPriceCheck: noop,
    onPriceRoundingSave: noop,
    onPriceFormatSave: noop,
    onSaveDisplay: noop,
    onSaveEditMargins: noop,
    onSetActiveSection: noop,
    onSavePriceBook: noop,
    onCreatePriceBook: noop,
    onChangePricingFormatVisibility: noop,
    organizationCountry: '',
    priceCheck: undefined,
    priceFormat: undefined,
    initialPriceBook: undefined,
    priceBooks: undefined,
    selectedPriceBook: undefined,
    priceBookStatus: undefined,
    baseCurrency: 'USD',
    isPricingFormatVisible: false,
  };

  componentWillUnmount(): void {
    const { onSetActiveSection, clearPriceCheck } = this.props;
    clearPriceCheck();
    onSetActiveSection('');
  }

  handleMarginEdit = (id: string): void => {
    const { onMarginEdit } = this.props;
    onMarginEdit(id);
  };

  handleNewMargin = (): void => {
    const { onNewMargin } = this.props;
    onNewMargin();
  };

  handleEditPriceBook = (): void => {
    const { onSetActiveSection } = this.props;
    onSetActiveSection('price-book');
  };

  handleEditMargins = (): void => {
    const { onSetActiveSection } = this.props;
    onSetActiveSection('item-margins');
  };

  handleEditDisplay = (): void => {
    const { onSetActiveSection } = this.props;
    onSetActiveSection('displays');
  };

  handleEditRounding = (): void => {
    const { onSetActiveSection } = this.props;
    onSetActiveSection('rounding');
  };

  handleEditPriceFormat = (): void => {
    const { onSetActiveSection } = this.props;
    onSetActiveSection('pricing-format');
  };

  handleCancelEditSection = (): void => {
    const { onSetActiveSection } = this.props;
    onSetActiveSection('');
  };

  handlePriceRoundingSave = (rounding: io.flow.v0.models.Rounding): void => {
    const { pricing, onPriceRoundingSave } = this.props;
    const updatedPricing = assign({}, pricing, { rounding });
    onPriceRoundingSave(updatedPricing);
  };

  sectionEditing = (section: string): boolean => {
    const { activeSection } = this.props;
    return !!activeSection && section === activeSection;
  };

  render(): JSX.Element {
    const {
      experience,
      itemMargins,
      onDeleteMargin,
      onPriceCheck,
      onSaveDisplay,
      onSavePriceBook,
      onSaveEditMargins,
      onPriceFormatSave,
      organization,
      organizationCountry,
      priceCheck,
      pricing,
      priceFormat,
      priceBooks,
      initialPriceBook,
      onCreatePriceBook,
      priceBookStatus,
      baseCurrency,
      onChangePricingFormatVisibility,
      isPricingFormatVisible,
    } = this.props;

    if (!experience) {
      throw new Error(`${Pricing.displayName}: missing expected experience`);
    }

    if (!pricing.rounding) {
      throw new Error('Pricing: missing required props ["rounding"]');
    }

    return (
      <section className="experience-pricing">
        <Navigation
          organization={organization}
          experience={experience.key}
        />
        <PriceBook
          onCreatePriceBook={onCreatePriceBook}
          priceBooks={priceBooks}
          initialPriceBook={initialPriceBook}
          onCancelEdit={this.handleCancelEditSection}
          onSavePriceBook={onSavePriceBook}
          onEditPriceBook={this.handleEditPriceBook}
          priceBookStatus={priceBookStatus}
          editable={this.sectionEditing('price-book')}
        />
        <ItemMargins
          currency={baseCurrency}
          itemMargins={itemMargins}
          editable={this.sectionEditing('item-margins')}
          onMarginEdit={this.handleMarginEdit}
          onNewMargin={this.handleNewMargin}
          onSaveEditMargins={onSaveEditMargins}
          onDeleteMargin={onDeleteMargin}
          onEditMargins={this.handleEditMargins}
          onCancelEditMargins={this.handleCancelEditSection}
          organizationCountry={organizationCountry}
        />
        <PricingDisplay
          pricing={pricing}
          editable={this.sectionEditing('displays')}
          onEdit={this.handleEditDisplay}
          onCancel={this.handleCancelEditSection}
          onSave={onSaveDisplay}
        />
        <PriceRounding
          rounding={pricing.rounding}
          currency={experience.currency}
          onEdit={this.handleEditRounding}
          onSave={this.handlePriceRoundingSave}
          onCancel={this.handleCancelEditSection}
          editable={this.sectionEditing('rounding')}
        />
        <PricingFormat
          visible={isPricingFormatVisible}
          onChangeVisibility={onChangePricingFormatVisibility}
          priceFormat={priceFormat}
          editable={this.sectionEditing('pricing-format')}
          onSave={onPriceFormatSave}
          onEdit={this.handleEditPriceFormat}
          onCancel={this.handleCancelEditSection}
        />
        <PriceCheck
          pricing={pricing}
          priceCheck={priceCheck}
          onPriceCheck={onPriceCheck}
        />
      </section>
    );
  }
}
