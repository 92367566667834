import { createSelector } from 'reselect';
import get from 'lodash/get';
import getExclusionRuleWorksheet from './getExclusionRuleWorksheet';
import { RootState } from '../../../stores/types';
import { ExclusionRuleStatus, ExclusionRuleWorksheetState } from '../types';

/**
 * Returns the async status of the worksheet. Every time a worksheet is rendered
 * a query builder is created from the provided exclusion rule.
 * @returns {ReadyState}
 */
const getStatusForExclusionRuleWorksheet = createSelector<
RootState, ExclusionRuleWorksheetState, ExclusionRuleStatus>(
  getExclusionRuleWorksheet,
  (worksheet) => get(worksheet, 'status'),
);

export default getStatusForExclusionRuleWorksheet;
