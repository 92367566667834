import noop from 'lodash/noop';
import { Button } from '@flowio/react-button';
import { NonIdealState } from '@flowio/react-non-ideal-state';
import React from 'react';
import { Stack } from '@flowio/react-stack';
import {
  Worksheet, WorksheetHeader, WorksheetTitle, WorksheetBody,
} from '@flowio/react-worksheet';
import { PuffLoader } from '@flowio/react-icons';

import { css } from '@emotion/react';
import TierUpsertForm from '../../containers/TierUpsertForm';
import { TierWorksheetProps } from '../../types/components';

const puffloaderStyle = css({
  width: '90px',
  height: 'auto',
  marginBottom: '20px',
  color: '#77818f',
});

const TierWorksheet: React.FC<TierWorksheetProps> = ({
  currencies = [],
  initialValues,
  isLoading = false,
  isOpen = false,
  isSubmitting = false,
  method,
  onCancel,
  onSave,
  onSuccess = noop,
  ratecards = [],
  title,
}) => (
  <Worksheet open={isOpen}>
    <WorksheetHeader>
      <Stack alignment="center" distribution="equalSpacing">
        <Button type="button" content="Cancel" disabled={isSubmitting} onClick={onCancel} />
        <WorksheetTitle content={title} />
        <Button type="button" content="Save" disabled={isSubmitting || isLoading} intent="primary" onClick={onSave} />
      </Stack>
    </WorksheetHeader>
    <WorksheetBody>
      {isLoading ? (
        <NonIdealState
          icon={<PuffLoader css={puffloaderStyle} />}
          heading="Loading"
          description="Shipping tier builder is loading, please wait..."
        />
      ) : (
        <TierUpsertForm
          currencies={currencies}
          initialValues={initialValues}
          method={method}
          onSubmitSuccess={onSuccess}
          ratecards={ratecards}
        />
      )}
    </WorksheetBody>
  </Worksheet>
);

TierWorksheet.displayName = 'TierWorksheet';

export default TierWorksheet;
