import React from 'react';
import { css } from '@emotion/react';
import { InfoCircle } from '@flowio/react-icons';
import { Box, BoxItem } from '@flowio/react-box';
import { Page } from '@flowio/react-page';
import * as styles from './MarketingPage.styles';

import { Props } from './types';
import MarketingBreadcrumbs from './MarketingBreadcrumbs';
import ExternalLink from '../ExternalLink';
import colors from '../../../../theme/tokens/colors';

const infoIconStyle = css`
  fill: ${colors.marketing['light-slate-gray']};
`;

const MarketingPage: React.FC<Props> = ({
  heading,
  breadcrumbItems,
  cta,
  children,
}) => (
  <Page className={styles.marketingPage}>
    {breadcrumbItems && (
      <MarketingBreadcrumbs
        breadcrumbItems={breadcrumbItems}
      />
    )}
    <Box className={styles.marketingHeader} alignItems="center" justifyContent="end">
      {heading && (
        <BoxItem flexAuto>
          <h2 className={styles.marketingHeading}>{heading}</h2>
        </BoxItem>
      )}
      {cta && (
        <BoxItem>{cta}</BoxItem>
      )}
    </Box>
    {children}
    <Box className={styles.marketingFooter} alignItems="center">
      <InfoCircle className={styles.infoIcon} width={14} css={infoIconStyle} />
      <span>Learn more about&nbsp;</span>
      <ExternalLink href="https://docs.flow.io/docs/getting-started-with-feeds">localized product feeds</ExternalLink>
      <span>.</span>
    </Box>
  </Page>
);

MarketingPage.displayName = 'MarketingPage';

export default MarketingPage;
