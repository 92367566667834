import { $HttpResponse } from '@flowio/api-internal-sdk';
import ActionTypes from '../constants/action-types';

import withPagingAndSorting, { isPagingAndSortingResponse } from '../../../utilities/with-paging-and-sorting-v2';
import { RootActionTypes } from '../../../stores/types';
import { WithPagingAndSortingResult, WithPagingAndSortingResponse } from '../../../utilities/types';
import { ApiError } from '../../../utilities/clients/api-error';
import { ThunkResult } from '../../../middlewares/types';
import { StatementsPaginationParams, PaginationOptions } from '../types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';

export function fetchStatementsRequest(params: StatementsPaginationParams): RootActionTypes {
  return {
    type: ActionTypes.FETCH_STATEMENTS_REQUEST,
    params,
  };
}

export function fetchStatementsSuccess(
  statements: WithPagingAndSortingResult<io.flow.internal.v0.models.OrganizationBillingStatement[]>,
  params: StatementsPaginationParams,
): RootActionTypes {
  return {
    type: ActionTypes.FETCH_STATEMENTS_SUCCESS,
    params,
    payload: statements,
  };
}

export function fetchStatementsFailure(
  params: StatementsPaginationParams,
  error?: FormattedErrorMessages,
): RootActionTypes {
  return {
    type: ActionTypes.FETCH_STATEMENTS_FAILURE,
    error: true,
    params,
    payload: error,
  };
}

export default function fetchStatements(
  organization: string,
  currency: string,
  pageNumber?: number,
  entriesPerPage?: number,
): ThunkResult<Promise<void>> {
  return function fetchStatementsEffects(
    dispatch,
    getState,
    extra,
  ): Promise<void> {
    const mergedOptions: StatementsPaginationParams = {
      pageNumber: pageNumber || 1,
      entriesPerPage: entriesPerPage || 25,
      organization,
      currency,
    };

    return withPagingAndSorting<io.flow.internal.v0.models.OrganizationBillingStatement[]>(
      mergedOptions,
      (options: PaginationOptions) => {
        dispatch(fetchStatementsRequest(mergedOptions));
        return extra.apiInternal(getState()).organizationBillingStatements.get({
          offset: options.offset,
          limit: options.limit,
          ...mergedOptions,
        });
      },
    )().then((
      response: WithPagingAndSortingResponse<
      io.flow.internal.v0.models.OrganizationBillingStatement[]
      >
      | $HttpResponse<io.flow.v0.models.GenericError>,
    ) => {
      if (isPagingAndSortingResponse<
      io.flow.internal.v0.models.OrganizationBillingStatement[]
      >(response)
        && response.ok) {
        dispatch(fetchStatementsSuccess(response.result, mergedOptions));
        return;
      }

      const errorResponse = response as $HttpResponse<io.flow.v0.models.GenericError>;
      dispatch(fetchStatementsFailure(mergedOptions, formatErrorResponseV2(errorResponse)));
      throw new ApiError(errorResponse);
    });
  };
}
