import { Button } from '@flowio/react-button';
import React, { useState, useEffect } from 'react';
import { TextField } from '@flowio/react-text-field';
import kebabCase from 'lodash/kebabCase';

import AuthLayout from '../auth-layout';
import { AuthNavigation, AuthNavigationItem } from '../auth-navigation';
import ErrorAlert from '../../../../components/alert/error-alert';
import { WithSubmitProps } from '../../../../components/with-submit/with-submit';
import { WithValidationProps, ValidationSubmitFunction } from '../../../../components/with-validation/with-validation';
import * as styles from './login.styles';
import getTextFieldValue from '../../../../utilities/getTextFieldValue';

type Props = {};

type MergedProps = Props & WithSubmitProps & WithValidationProps;

const Login: React.FC<MergedProps> = ({
  error,
  fields: { username, password },
  onSubmit,
  onSubmitValidate,
  submitting,
}) => {
  const [isMounted, setIsMounted] = useState<boolean>(false);

  useEffect(() => {
    setIsMounted(true);

    return (): void => setIsMounted(false);
  }, []);

  const handleSubmit: ValidationSubmitFunction = ({ isValid }, formData) => {
    if (isValid) onSubmit(formData);
  };

  return (
    <AuthLayout className={styles.login}>
      <form onSubmit={onSubmitValidate(handleSubmit)} noValidate>
        {error && error.messages && error.messages.map(({ message }) => (
          <ErrorAlert key={kebabCase(message)}>
            {message}
          </ErrorAlert>
        ))}
        <TextField
          onChange={(e) => username.events.onChange(e)}
          onBlur={(e) => username.events.onBlur(e)}
          aria-required={username.isRequired}
          className={styles.username}
          clearable={false}
          intent={username.error ? 'negative' : 'neutral'}
          feedback={username.error}
          fluid
          hintText="Email"
          name="email"
          required={username.isRequired}
          value={getTextFieldValue(username.value)}
          gutter
        />
        <TextField
          onChange={(e) => password.events.onChange(e)}
          onBlur={(e) => password.events.onBlur(e)}
          aria-required={password.isRequired}
          clearable={false}
          intent={password.error ? 'negative' : 'neutral'}
          feedback={password.error}
          fluid
          name="password"
          hintText="Password"
          pattern=".{5,}"
          required={password.isRequired}
          type="password"
          value={getTextFieldValue(password.value)}
          gutter
        />
        <Button
          className={styles.button}
          content={submitting ? 'Signing In...' : 'Sign In'}
          disabled={!isMounted || submitting}
          fluid
          intent="primary"
          type="submit"
        />
      </form>
      <AuthNavigation>
        <AuthNavigationItem href="/password/reset" label="Reset Password" />
        <AuthNavigationItem href="/register" label="Request an Account" />
      </AuthNavigation>
    </AuthLayout>
  );
};

Login.displayName = 'Login';

export default Login;
