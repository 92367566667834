import ActionTypes from '../constants/action-types';
import makeGraphqlRequest from '../../utilities/actions/make-graphql-request';

// Need to use a thunk for now, ideally we would make a graphql middleware
export default function fetchInvoices(organization, orderNumber) {
  return (dispatch, getState) => makeGraphqlRequest({
    state: getState(),
    dispatch,
    actionTypes: [
      ActionTypes.FETCH_INVOICES_REQUEST,
      ActionTypes.FETCH_INVOICES_SUCCESS,
      ActionTypes.FETCH_INVOICES_FAILURE,
    ],
    body: {
      operationName: 'FetchInvoices',
      query: `query FetchInvoices($org: String!, $orderNumber: String) {
        consumerInvoices(organization: $org, orderNumber: $orderNumber) {
          id
          number
          date
          entity {
            name
          }
          documents {
            language
            url
          }
        }

        b2bInvoices(organization: $org, orderNumber: $orderNumber) {
          id
          number
          buyer {
            name
          }
          seller {
            country
            name
          }
          date
          documents {
            language
            url
          }
        }

        creditMemos(organization:$org, orderNumber: $orderNumber) {
          id
          number
          date
          documents{
            language
            url
          }
          entity {
            name
          }
        }

        b2bCreditMemos(organization:$org, orderNumber: $orderNumber) {
          id
          number
          date
          documents{
            language
            url
          }
          buyer {
            name
          }
          seller {
            country
            name
          }
        }
      }`,
      variables: {
        org: organization,
        orderNumber,
      },
    },
  });
}
