import { createSelector } from 'reselect';
import property from 'lodash/property';
import getMarketingState from './getMarketingState';
import { RootState } from '../../../stores/types';
import { MarketingState, DownloadsFeedsDialogState } from '../types';

export default createSelector<RootState, MarketingState, DownloadsFeedsDialogState>(
  getMarketingState,
  property(['downloadFeedsDialog']),
);
