import { createSelector } from 'reselect';
import get from 'lodash/get';
import getReturnPolicyWorksheet from './getReturnPolicyWorksheet';
import { RootState } from '../../../stores/types';
import { ReturnPolicyWorksheetState } from '../types';

/**
 * Returns identifier of the return policy to be updated by the return policy worksheet.
 * @returns {String}
 */
const getReturnPolicyIdForReturnPolicyWorksheet = createSelector<
RootState, ReturnPolicyWorksheetState, string>(
  getReturnPolicyWorksheet,
  (worksheet) => get(worksheet, 'returnPolicy.id'),
);

export default getReturnPolicyIdForReturnPolicyWorksheet;
