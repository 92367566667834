import { RootActionTypes } from '../../../stores/types';
import ActionTypes from '../constants/action-types';
import { FacebookAccessTokenSummaryState } from '../types/store';

const initialState = {
  loading: false,
};

const accessTokenSummary = (
  state = initialState,
  action: RootActionTypes,
): FacebookAccessTokenSummaryState => {
  switch (action.type) {
    case ActionTypes.FETCH_ACCESS_TOKEN_SUMMARY_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionTypes.FETCH_ACCESS_TOKEN_SUMMARY_SUCCESS: {
      return {
        loading: false,
        summary: action.payload,
      };
    }
    case ActionTypes.FETCH_ACCESS_TOKEN_SUMMARY_FAILURE: {
      return {
        loading: false,
        error: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default accessTokenSummary;
