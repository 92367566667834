import { InfoCircle } from '@flowio/react-icons';
import React, { useCallback } from 'react';
import { css } from '@emotion/react';
import {
  Form,
  Field,
  FieldArray,
  InjectedFormProps,
  WrappedFieldMetaProps,
} from 'redux-form';
import { Status } from '@flowio/api-internal-constants';
import { Button, OutlineButton } from '@flowio/react-button';
import {
  Card, CardHeader, CardContent, CardTitle, CardDescription,
} from '@flowio/react-card';
import { WorksheetHeader, WorksheetBody } from '@flowio/react-worksheet';

import { Select } from '@flowio/react-select';
import reduce from 'lodash/reduce';
import some from 'lodash/some';
import ExperimentTypes, { experimentTypes, ExperimentType } from '../../constants/experiment-types';
import * as styles from './EditExperiment.styles';
import ExperimentVariants from './ExperimentVariants';
import messages from '../../constants/messages';
import ExperimentEmailField from './ExperimentEmailField';
import validator from '../../../../utilities/validator';
import supportsSessionQuery from '../../../../utilities/experiment-supports-session-query';
import ExperimentSignificanceActionsField from './ExperimentSignificanceActionsField';
import { ExperimentVariantSummary } from '../../types';
import { TextField } from '../../../../components/ReduxFormFieldAdapters';

interface ExperimentWorksheetProps {
  name: string;
  key?: string;
  sessionQuery?: string;
  status: Status;
  discriminator?: io.flow.internal.v0.enums.ExperimentDiscriminatorKey;
  variants: io.flow.internal.v0.unions.VariantForm[];
  isNew: boolean;
}

interface EditExperimentFormProps extends InjectedFormProps<ExperimentWorksheetProps> {
  variants: io.flow.internal.v0.unions.VariantForm[];
  discriminatorValues: io.flow.internal.v0.models.ExperimentFormDefaultDiscriminatorValue[];
  onChangeExperimentValue: (
    d: io.flow.internal.v0.enums.ExperimentDiscriminatorKey,
    v: ExperimentVariantSummary,
  ) => void;
  onChangeDiscriminator: (d: io.flow.internal.v0.enums.ExperimentDiscriminatorKey) => void;
  isEquallyAllocated: boolean;
  isNew: boolean;
  onClose: () => void;
  organizationId: string;
  experimentFormScope: io.flow.internal.v0.enums.Scope;
}

const validateName = (name: string): string | undefined => {
  if (!name) {
    return 'Experiment name is required';
  }

  return undefined;
};

const validateVariants = (
  variants: io.flow.internal.v0.unions.VariantForm[],
): string | undefined => {
  if (!variants) {
    return undefined;
  }

  if (variants.length === 0) {
    return 'Please select the experiment variants';
  }

  if (variants.length > 3) {
    return 'Only 3 variants are allowed per experiment, please include only 3 variants';
  }

  if (some(variants, (variant) => variant.traffic_percentage <= 0)) {
    return 'Each variant must be assigned at least 1% of traffic';
  }

  if (reduce(variants, (sum, variant) => (sum + variant.traffic_percentage), 0) > 100) {
    return 'The traffic allocation cannot exceed 100%';
  }

  return undefined;
};

const validateEmails = (
  emails: string[] = [],
): string | undefined => {
  if (!emails.length) {
    return 'at least one email is required';
  }
  if (emails.find((value) => !validator.validate(value, { pattern: 'email' }))) {
    return 'please enter a valid email';
  }
  return undefined;
};

const worksheetBodyStyles = css({
  overflowY: 'scroll',
  maxHeight: '75vh',
});

const checkTraffic = (variants: io.flow.internal.v0.unions.VariantForm[]): string | undefined => {
  const sum = reduce(variants, (acc, { traffic_percentage: tp }) => acc + tp, 0);
  return sum < 100 ? `${100 - sum}% of traffic is not in this experiment` : undefined;
};

const EditExperimentForm: React.FC<EditExperimentFormProps> = ({
  change,
  pristine,
  invalid,
  handleSubmit,
  variants,
  initialValues,
  onChangeExperimentValue,
  onChangeDiscriminator,
  discriminatorValues,
  isEquallyAllocated,
  isNew,
  onClose,
  organizationId,
  experimentFormScope,
}: EditExperimentFormProps) => {
  const changeFormValues = useCallback(change, [change]);

  const [value, setValue] = React.useState<
  ExperimentVariantSummary | null
  >(null);
  const [experimentType, setExperimentType] = React.useState<ExperimentType>(ExperimentTypes[initialValues.discriminator || 'experience']);

  const handleSaveDraft = (): void => {
    change('status', Status.DRAFT);
  };

  const handleChangeExperimentType = (
    type: string,
  ): void => {
    const selectedExperimentType: ExperimentType | undefined = experimentTypes
      .find((e) => e.key === type);
    if (selectedExperimentType) {
      setExperimentType(selectedExperimentType);
      change('discriminator', selectedExperimentType.key);
      onChangeDiscriminator(selectedExperimentType.key);
      setValue(null);
    }
  };

  const handleChangeExperimentValue = (
    option: string,
  ): void => {
    const selectedOption: ExperimentVariantSummary | undefined = discriminatorValues
      .find((d) => d.key === option);
    if (selectedOption) {
      onChangeExperimentValue(experimentType.key, selectedOption);
      setValue(selectedOption);
    }
  };

  const showNameError = (_: object, meta: WrappedFieldMetaProps): boolean => (
    !!meta.error && !pristine
  );

  const globalBanner = (): JSX.Element => (
    <div className={styles.globalBanner}>
      <InfoCircle width={36} />
      <div>
        <h4>{messages.GLOBAL_BANNER_HEADING}</h4>
        <p>{messages.GLOBAL_BANNER_SUBHEADING}</p>
      </div>
    </div>
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Field component="input" type="hidden" name="key" />
      <Field component="input" type="hidden" name="discriminator" />
      <Field component="input" type="hidden" name="status" />
      <WorksheetHeader
        className={styles.experimentFormHeader}
        content={isNew ? 'New Experiment' : 'Edit Experiment'}
        leftDecoration={<Button type="button" content="Cancel" onClick={onClose} />}
        rightDecoration={(
          <div>
            <OutlineButton
              className="save-btn"
              onClick={handleSaveDraft}
              content="Save as Draft"
              disabled={invalid}
              type="submit"
              intent="primary"
            />
            <Button
              content={organizationId === 'flow' ? 'Schedule' : 'Launch'}
              disabled={invalid}
              type="submit"
              intent="primary"
            />
          </div>
        )}
      />
      <WorksheetBody css={worksheetBodyStyles}>
        {experimentFormScope === 'global' && globalBanner()}
        <Card>
          <CardHeader>
            <CardTitle className={styles.cardTitle}>
              <span className="title">Experiment Settings</span>
            </CardTitle>
          </CardHeader>
          <hr className={styles.divider} />
          <CardContent>
            <div className={styles.settingContainer}>
              <span className="label">Name</span>
              <div className="data">
                <Field
                  component={TextField}
                  name="name"
                  fluid
                  clearable={false}
                  validate={validateName}
                  shouldError={showNameError}
                  className="experiment-name"
                  hintText="Experiment Name"
                />
              </div>
            </div>
            <div className={styles.settingContainer}>
              <span className="label">Description</span>
              <div className="data">
                <Field
                  component={TextField}
                  name="description"
                  fluid
                  clearable={false}
                  className="experiment-description"
                  hintText="Experiment description (optional)"
                />
              </div>
            </div>
            <div className={styles.settingContainer}>
              <span className="label">Type</span>
              <div className="data">
                <div className="experiment-type">
                  <Select
                    fluid
                    onValueChange={handleChangeExperimentType}
                    value={experimentType.key}
                    options={experimentTypes.map((e) => ({ value: e.key, content: e.name }))}
                  />
                </div>
                <div className="experiment-type">
                  <Select
                    fluid
                    disabled={!discriminatorValues.length}
                    hintText={`Select ${experimentType.key === 'experience' ? 'region' : experimentType.key}`}
                    onValueChange={handleChangeExperimentValue}
                    value={value?.key}
                    options={discriminatorValues.map((d) => ({ value: d.key, content: d.name }))}
                  />
                </div>
                <div className="experiment-field-description">{experimentType.description}</div>
              </div>
            </div>
            {supportsSessionQuery(
              organizationId,
              experimentType.key,
              initialValues.sessionQuery,
            ) && (
              <div className={styles.settingContainer}>
                <span className="label">Session query</span>
                <div className="data">
                  <Field
                    component={TextField}
                    name="sessionQuery"
                    fluid
                    clearable={false}
                    className="experiment-session-query"
                    hintText="Session query (optional)"
                    hint={(
                      <div className="experiment-session-query-description">
                        Example: organization in (demo) and country in (CAN, USA)
                      </div>
)}
                  />
                </div>

              </div>
            )}
          </CardContent>
        </Card>
        <FieldArray
          name="variants"
          validate={validateVariants}
          warn={checkTraffic}
          component={ExperimentVariants}
          props={{
            variants,
            isEquallyAllocated,
            change: changeFormValues,
          }}
        />
        <Card>
          <CardHeader>
            <CardTitle className={styles.cardTitle}>
              <span className="title">Statistical Significance</span>
            </CardTitle>
            <CardDescription>
              A winner is declared when a variant&apos;s conversion rate has 95% probability to be
              best and the experiment has been running for at least 14 days.
            </CardDescription>
          </CardHeader>
          <hr className={styles.divider} />
          <CardContent>
            {experimentType.key === 'experience' && (
              <div className={styles.settingContainer}>
                <span className={['label', 'adjust'].join(' ')}>When a winner is declared</span>
                <div className="data">
                  <Field
                    component={ExperimentSignificanceActionsField}
                    name="significance_action"
                  />
                </div>
              </div>
            )}
            <div className={styles.settingContainer}>
              <span className="label">Email Alert</span>
              <div className="data">
                <Field
                  component={ExperimentEmailField}
                  name="emails"
                  validate={validateEmails}
                />
                <div className="experiment-field-description">An email will be sent when statistical significance is reached.</div>
              </div>
            </div>
          </CardContent>
        </Card>
      </WorksheetBody>
    </Form>
  );
};

EditExperimentForm.displayName = 'EditExperimentForm';

export default EditExperimentForm;
