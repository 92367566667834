import { createSelector } from 'reselect';
import filter from 'lodash/filter';
import get from 'lodash/get';
import getMarketingState from './getMarketingState';
import { RootState } from '../../../stores/types';
import { MarketingState } from '../types';

const getPrimaryFeeds = (
  state: MarketingState,
): io.flow.internal.v0.models.MarketingGatewayFeed[] => {
  const productFeeds = get(state, 'productFeeds.productFeeds');
  const primaryFeeds = filter(productFeeds, (feed) => get(feed, 'schema.schema_compatibility') === 'facebook_primary');
  return primaryFeeds;
};

export default createSelector<
RootState, MarketingState, io.flow.internal.v0.models.MarketingGatewayFeed[]
>(
  getMarketingState,
  getPrimaryFeeds,
);
