import { LaneDirection } from '@flowio/api-constants';
import {
  Card, CardHeader, CardContent, CardFooter, CardEmptyState,
} from '@flowio/react-card';
import { Plus } from '@flowio/react-icons';
import { NonIdealState } from '@flowio/react-non-ideal-state';
import React from 'react';
import isEmpty from 'lodash/isEmpty';

import TierList from '../TierList';
import { TierListCardProps } from '../../types/components';

const TierListCard: React.FC<TierListCardProps> = ({
  direction,
  onAddTier,
  onEditTier,
  onDeleteTier,
  tiers,
}) => (
  <Card>
    <CardHeader
      actions={[{
        content: direction === LaneDirection.RETURN ? 'Add Return Shipping Tier' : 'Add Outbound Shipping Tier',
        intent: 'primary',
        leftIcon: Plus,
        onClick: onAddTier,
        variant: 'outline',
      }]}
      caption={direction === LaneDirection.RETURN ? 'Return Shipping Tiers' : 'Outbound Shipping Tiers'}
      dividing
    />
    <CardContent>
      {isEmpty(tiers) ? (
        <CardEmptyState>
          {direction === LaneDirection.RETURN ? (
            <NonIdealState
              heading="No Return Shipping Tiers"
              description={'Click "Add Return Shipping Tier" button to add a return shipping tier.'}
            />
          ) : (
            <NonIdealState
              heading="No Shipping Tiers"
              description={'Click "Add Shipping Tier" button to add a shipping tier.'}
            />
          )}
        </CardEmptyState>
      ) : (
        <TierList
          onDeleteTier={onDeleteTier}
          onEditTier={onEditTier}
          tiers={tiers}
        />
      )}
    </CardContent>
    <CardFooter />
  </Card>
);

TierListCard.displayName = 'TierListCard';

export default TierListCard;
