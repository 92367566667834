import { ThunkResult } from '../../../stores/types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';
import ActionTypes from '../constants/action-types';

export default function fetchDebugTransactions(
  form: io.flow.internal.v0.models.DebugOrderTransactionForm,
):
  ThunkResult<Promise<io.flow.internal.v0.models.InternalDebugTransaction[] | void>> {
  return function debugOrderEffects(
    dispatch,
    getState,
    extra,
  ): Promise<io.flow.internal.v0.models.InternalDebugTransaction[] | void> {
    dispatch({
      type: ActionTypes.DEBUG_TRANSACTIONS_REQUEST,
    });

    return extra.apiInternal(getState()).debugTransactions.post({
      body: {
        ...form,
      },
    }).then((response) => {
      if (response.ok) {
        dispatch({
          type: ActionTypes.DEBUG_TRANSACTIONS_SUCCESS,
          payload: response.body,
        });
        return response.body;
      }
      dispatch({
        type: ActionTypes.DEBUG_TRANSACTIONS_FAILURE,
        payload: formatErrorResponseV2(response),
        error: true,
      });
      return undefined;
    });
  };
}
