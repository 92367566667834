import React from 'react';
import { Box, BoxItem } from '@flowio/react-box';
import { Label } from '@flowio/react-label';

import * as styles from './TierList.styles';
import { TierListItemOptionalMessageProps } from '../../types/components';

const TierListItemOptionalMessage: React.FC<TierListItemOptionalMessageProps> = ({
  message,
}) => (
  <Box spacing="loose">
    <BoxItem className={styles.tierLabel}>
      <Label content="Optional Message" />
    </BoxItem>
    <BoxItem>
      <span className={styles.tierMessage}>
        <span className={styles.customCopyLabel}>
          Custom Copy
        </span>
        {message}
      </span>
    </BoxItem>
  </Box>
);

TierListItemOptionalMessage.displayName = 'TierListItemOptionalMessage';

export default TierListItemOptionalMessage;
