export default function handleEnterKeyboardEvent(handler) {
  return function enterKeyPressHandler(e) {
    switch (e.key) {
      case 'Enter':
        handler(e);
        break;
      default:
    }
  };
}
