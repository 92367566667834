import { Check, Close } from '@flowio/react-icons';
import noop from 'lodash/noop';
import React from 'react';
import { Grid, Column, Row } from '@flowio/react-grid';
import { OutlineButton } from '@flowio/react-button';
import { Box } from '@flowio/react-box';
import Description from '../../../../components/description/description';

import FormattedDate from '../../../../components/formatted-date';
import DescriptionGroup from '../../../../components/description/description-group';
import StatusLabel from '../../../../components/status-label';
import * as styles from './orderFraudStatus.styles';

const getStatusIntent = (status: io.flow.v0.enums.FraudStatus): string => {
  switch (status) {
    case 'review':
      return 'warning';
    case 'approved':
      return 'positive';
    case 'declined':
      return 'negative';
    default:
      return 'neutral';
  }
};

const getRiskEvaluation = (riskEvaluation: string): string => {
  if (riskEvaluation === 'none') {
    return 'Disabled per client request';
  }

  return riskEvaluation;
};

const getResponsibleParty = (organization: string, responsibleParty?: string): string => {
  switch (responsibleParty) {
    case 'organization':
      return organization;
    case 'flow':
      return 'Flow';
    default:
      return organization;
  }
};

interface OrderFraudStatusProps {
  status?: io.flow.v0.enums.FraudStatus;
  lastUpdated?: string;
  description?: string;
  riskEvaluation?: string;
  organization?: string;
  responsibleParty?: string;
  pendingReview?: boolean;
  referenceId?: string;
  isFlowEmployee: boolean;
  handleApprove?: (...args: any) => any;
  handleDecline?: (...args: any) => any;
}

const OrderFraudStatus: React.FC<OrderFraudStatusProps> = ({
  status = 'pending',
  description = '',
  riskEvaluation = '',
  lastUpdated = '',
  organization = '',
  responsibleParty,
  pendingReview = false,
  isFlowEmployee,
  referenceId = '',
  handleApprove = noop,
  handleDecline = noop,
}) => (
  <Grid className={styles.fraudClearFix} gutter={false}>
    <Row gutter={false}>
      <Column span={6}>
        <DescriptionGroup spacing={false} className>
          <Description>
            <Description.Term className={styles.fraudLabel} content="Reference #" />
            <Description.Definition
              content={referenceId}
            />
          </Description>

          <Description>
            <Description.Term className={styles.fraudLabel} content="Status" />
            <Description.Definition>
              <StatusLabel content={status} intent={getStatusIntent(status)} />
            </Description.Definition>
          </Description>

          <Description term="Risk Evaluation" definition={getRiskEvaluation(riskEvaluation)}>
            <Description.Term className={styles.fraudLabel} content="Risk Evaluation" />
            <Description.Definition
              content={getRiskEvaluation(riskEvaluation)}
            />
          </Description>

          <Description term="Description" definition={description}>
            <Description.Term className={styles.fraudLabel} content="Description" />
            <Description.Definition content={description} />
          </Description>
        </DescriptionGroup>
      </Column>
      <Column span={6}>
        <DescriptionGroup spacing={false} className>
          <Description>
            <Description.Term className={styles.fraudLabel} content="Last Updated" />
            <Description.Definition>
              {lastUpdated ? (
                <FormattedDate value={lastUpdated} format="MMM DD, YYYY h:mm A" />
              ) : (
                <div>N/A</div>
              )}
            </Description.Definition>
          </Description>
        </DescriptionGroup>
      </Column>
    </Row>
    { (status === 'review' && isFlowEmployee) && (
      <div className={styles.reviewSection}>
        <Box spacing="loose">
          <OutlineButton
            className={styles.fraudReviewButton}
            disabled={!pendingReview}
            content="Approve Order"
            intent="primary"
            leftIcon={Check}
            onClick={handleApprove}
          />
          <OutlineButton
            className={styles.fraudReviewButton}
            disabled={!pendingReview}
            content="Deny Order"
            intent="negative"
            leftIcon={Close}
            onClick={handleDecline}
          />
        </Box>
        <div className={styles.fraudReviewMessage}>
          { pendingReview ? (
            <span>
              By manually approving or declining the order,&nbsp;
              <span className={styles.fraudResponsibleParty}>
                {getResponsibleParty(organization, responsibleParty)}
              </span>
              &nbsp;assumes the risk of this order.
            </span>
          ) : (
            <span>
              Your decision has been submitted. Reload this page
              after a few seconds to view updated status.
            </span>
          )}
        </div>
      </div>
    )}
  </Grid>
);

OrderFraudStatus.displayName = 'OrderFraudStatus';

export default OrderFraudStatus;
