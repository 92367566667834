import ActionTypes from '../constants/ActionTypes';
import { DialogNameType } from '../constants/DialogName';
import { DialogPayload } from '../types';

const hideDialog = (dialog: DialogNameType) => (payload?: DialogPayload) => ({
  type: ActionTypes.HIDE_DIALOG,
  meta: { name: dialog },
  payload,
});

export default hideDialog;
