import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { ThunkDispatcher } from '../../../../../stores/types';
import { toggleAuthDialog } from '../../../actions';
import AddChannelsButton from '../components/AddChannelsButton';
import { DispatchProps, OwnProps } from '../types';

const mapDispatchToProps = (
  dispatch: ThunkDispatcher,
  { organizationId }: OwnProps,
): DispatchProps => ({
  onConnectForSupportedChannels: () => {
    dispatch(toggleAuthDialog(true, {
      organizationId,
      platformId: 'google',
      isManual: true,
    }));
  },
  onAddChannel: (channel: io.flow.internal.v0.models.MarketingGatewayChannel): void => {
    if (channel.integration_type === 'manual') {
      browserHistory.push(`/${organizationId}/marketing/${channel.platform}/add-channels`);
    } else {
      dispatch(toggleAuthDialog(true, {
        organizationId,
        platformId: channel.platform,
        isManual: false,
      }));
    }
  },
});

export default connect(null, mapDispatchToProps)(AddChannelsButton);
