import {
  getPageResults,
  getCurrentPageNumber,
  getIsLastPage,
  getIsFirstPage,
} from '@flowio/redux-resource-pagination-plugin';
import { getOrganizationId } from '../../organization';

export const getPriceBookItemsResource = (state) => state.resources.priceBookItems;

export const getPriceBookItems = (state) => {
  const organizationId = getOrganizationId(state);
  const priceBookItems = getPageResults(getPriceBookItemsResource(state), organizationId);
  return priceBookItems;
};

export const getCurrentPriceBookItemsPage = (state) => getCurrentPageNumber(
  getPriceBookItemsResource(state),
);

export const getIsLastPriceBookItemsPage = (state) => getIsLastPage(
  getPriceBookItemsResource(state),
);

export const getIsFirstPriceBookItemsPage = (state) => getIsFirstPage(
  getPriceBookItemsResource(state),
);
