import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFetch } from '@flowio/redux-fetch';
import { browserHistory, type RouterState } from 'react-router';
import head from 'lodash/head';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import toLower from 'lodash/toLower';
import AccountStatements from '../components/account-statements';
import { fetchOrganization, getOrganization } from '../../../../organization';
import changeAccount from '../../../actions/change-account';
import fetchOrganizationAccount from '../../../actions/fetch-organization-account';
import fetchOrganizationAccounts from '../../../actions/fetch-organization-accounts';
import fetchStatements from '../../../actions/fetch-statements';
import {
  getAccountCurrentPageResults,
  getStatementsCurrentPageNumber,
  getStatementsCurrentPageResults,
  getIsStatementsCurrentPageFirstPage,
  getIsStatementsCurrentPageLastPage,
  getCurrentAccount,
} from '../../../selectors';
import { RootState } from '../../../../../stores/types';
import { ThunkDispatcher } from '../../../../../middlewares/types';
import { StateProps, DispatchProps, OwnProps } from '../types';

const fetchAsyncState = (
  dispatch: ThunkDispatcher,
  getState: () => RootState,
  ownProps: RouterState,
): Promise<any> => {
  const { organization, currency, key } = ownProps.params;
  const { pageNumber, entriesPerPage } = ownProps.location.query;
  return Promise.all([
    dispatch(fetchOrganization(organization)),
    dispatch(fetchOrganizationAccounts(organization)),
  // If the currency code is not specified as a URL parameter, then we will
  // attempt to use the currency for the first account available to the
  // organization. In the event that an organization does not have any
  // accounts, we won't dispatch the remaining actions.
  ]).then((): Promise<any> => {
    const state = getState();
    const accounts = getAccountCurrentPageResults(state);
    const keyValue = isNil(key) ? head(map(accounts, 'key')) : key;
    const currencyCode = isNil(currency) ? head(map(accounts, 'currency')) : currency;
    if (isNil(currencyCode) || isNil(keyValue)) {
      return Promise.resolve();
    }

    dispatch(changeAccount(keyValue));

    return Promise.all([
      dispatch(fetchOrganizationAccount(organization, toLower(keyValue))),
      dispatch(fetchStatements(
        organization,
        currencyCode,
        Number(pageNumber),
        Number(entriesPerPage),
      )),
    ]);
  });
};

const mapStateToProps = (state: RootState): StateProps => ({
  account: getCurrentAccount(state),
  accounts: getAccountCurrentPageResults(state),
  firstPage: getIsStatementsCurrentPageFirstPage(state),
  lastPage: getIsStatementsCurrentPageLastPage(state),
  organization: getOrganization(state),
  pageNumber: getStatementsCurrentPageNumber(state),
  statements: getStatementsCurrentPageResults(state),
});

const mapDispatchToProps = (_dispatch: ThunkDispatcher, props: OwnProps): DispatchProps => ({
  onRequestPage(pageNumber, entriesPerPage = 25): void {
    const { location } = props;
    browserHistory.push({
      pathname: location.pathname,
      query: {
        ...location.query,
        pageNumber,
        entriesPerPage,
      },
    });
  },
  onRequestAccountChange(organization, account): void {
    browserHistory.push(`/${organization}/accounts/${account}/statements`);
  },
});

export default compose(
  withFetch(fetchAsyncState),
  connect(mapStateToProps, mapDispatchToProps),
)(AccountStatements);
