import { $HttpResponse } from '@flowio/api-sdk';
import { LegacyResponse } from './types/server';

export default function toLegacyResponse<B = any>(
  response: $HttpResponse<B>,
): LegacyResponse<B> {
  return {
    ok: response.status >= 200 && response.status < 300,
    result: response.body,
    status: response.status,
  };
}
