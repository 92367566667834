import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import update from 'immutability-helper';

import featureStatus from '../constants/feature-status';
import { RootActionTypes } from '../../../stores/types';
import ActionTypes from '../constants/action-types';
import { FeatureUiState } from '../types/state';

const defaultState: FeatureUiState = {
  searchValue: '',
  statusFilters: [
    featureStatus.ACTIVE,
    featureStatus.DRAFT,
    featureStatus.ARCHIVED,
  ],
};

export default function (state = defaultState, action: RootActionTypes) {
  switch (action.type) {
    case ActionTypes.FETCH_QUERY_BUILDER_STATUS_FILTERS_SUCCESS: {
      const statusFilter = find(action.payload.filters, { field: 'feature.status' });
      const filterValues = get(statusFilter, 'values', []);
      const unstructuredFilter = find(action.payload.filters, { discriminator: 'unstructured' });
      const searchValue = get(unstructuredFilter, 'q', '');

      if (isEmpty(filterValues)) {
        return state;
      }

      return update(state, {
        searchValue: { $set: searchValue },
        statusFilters: { $set: filterValues },
      });
    }
    default:
      return state;
  }
}
