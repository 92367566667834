import get from 'lodash/get';
import { RootActionTypes } from '../../../stores/types';

import ActionTypes from '../constants/ActionTypes';
import { ActivitiesState } from '../types/state';

const initialState: ActivitiesState = {};

export default function (previousState = initialState, action: RootActionTypes) {
  switch (action.type) {
    case ActionTypes.CREATE_ACTIVITY:
      return {
        ...previousState,
        [action.payload.organizationId]: [
          ...get(previousState, action.payload.organizationId, []),
          action.payload,
        ],
      };
    default:
      return previousState;
  }
}
