enum ActionTypes {
  FETCH_LAB_SETTINGS_BY_KEY_REQUEST = 'LABS.FETCH_LAB_SETTINGS_BY_KEY_REQUEST',
  FETCH_LAB_SETTINGS_BY_KEY_SUCCESS = 'LABS.FETCH_LAB_SETTINGS_BY_KEY_SUCCESS',
  FETCH_LAB_SETTINGS_BY_KEY_FAILURE = 'LABS.FETCH_LAB_SETTINGS_BY_KEY_FAILURE',
  PUT_LAB_SETTINGS_BY_KEY_REQUEST = 'LABS.PUT_LAB_SETTINGS_BY_KEY_REQUEST',
  PUT_LAB_SETTINGS_BY_KEY_SUCCESS = 'LABS.PUT_LAB_SETTINGS_BY_KEY_SUCCESS',
  PUT_LAB_SETTINGS_BY_KEY_FAILURE = 'LABS.PUT_LAB_SETTINGS_BY_KEY_FAILURE',
  FETCH_LABS_SETTINGS_REQUEST = 'LABS.FETCH_LABS_SETTINGS_REQUEST',
  FETCH_LABS_SETTINGS_SUCCESS = 'LABS.FETCH_LABS_SETTINGS_SUCCESS',
  FETCH_LABS_SETTINGS_FAILURE = 'LABS.FETCH_LABS_SETTINGS_FAILURE',
  FETCH_ACCEPTING_USER_REQUEST = 'LABS.FETCH_ACCEPTING_USER_REQUEST',
  FETCH_ACCEPTING_USER_SUCCESS = 'LABS.FETCH_ACCEPTING_USER_SUCCESS',
  FETCH_ACCEPTING_USER_FAILURE = 'LABS.FETCH_ACCEPTING_USER_FAILURE',
  OPEN_ACCEPT_DIALOG = 'LABS.OPEN_ACCEPT_DIALOG',
  CLOSE_ACCEPT_DIALOG = 'LABS.CLOSE_ACCEPT_DIALOG',
}

export default ActionTypes;
