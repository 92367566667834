import React from 'react';
import { Box } from '@flowio/react-box';
import { IconButton } from '@flowio/react-button';
import { Copy } from '@flowio/react-icons';
import { Card, CardContent, CardHeader } from '@flowio/react-card';
import moment from 'moment';
import {
  capitalize,
  get,
  isEmpty,
  map,
} from 'lodash';
import { Link } from 'react-router';
import { useDispatch } from 'react-redux';
import { createToast } from '../../../console/actions';
import { RefundOrderSummaryState } from '../../types';
import Alert from '../../../../components/alert';
import * as styles from './order-details.styles';

interface OrderDetailsOverviewProps {
  organizationId: string;
  createdAt?: string;
  expiresAt?: string;
  order: io.flow.v0.models.Order;
  showExperienceLink: boolean;
  refundOrderSummary?: RefundOrderSummaryState;
  shopifyOrderId?: string;
}

const OrderDetailsOverview: React.FC<OrderDetailsOverviewProps> = ({
  organizationId,
  createdAt,
  expiresAt,
  order,
  showExperienceLink,
  refundOrderSummary,
  shopifyOrderId,
}) => {
  const dispatch = useDispatch();

  const orderOpened = createdAt ? moment(createdAt).format('llll') : '';
  const orderExpires = expiresAt ? moment(expiresAt).format('llll') : '';
  const orderSubmitted = order.submitted_at ? moment(order.submitted_at).format('llll') : '';
  const orderCancelledAt = order.attributes && order.attributes.cancelled_at ? moment(order.attributes.cancelled_at).format('llll') : '';

  const experienceKey = order.experience ? order.experience.key : '';
  const ExperienceName = get(order, 'items[0].local.experience.name');

  const experienceDisplayName = isEmpty(order.items)
    ? capitalize(experienceKey)
    : capitalize(ExperienceName);

  const onCopyValue = (value: string, name: string) => {
    navigator.clipboard.writeText(value).then(() => {
      dispatch(createToast({
        intent: 'positive',
        content: `Successfully copied ${name}`,
      }));
    }).catch(() => {
      dispatch(createToast({
        intent: 'negative',
        content: `Could not copy ${name}`,
      }));
    });
  };

  return (
    <Card>
      <CardHeader
        caption="Order Summary"
        dividing
      />
      <CardContent>
        {refundOrderSummary && refundOrderSummary.error && (
          <Alert type="failure">
            {map(refundOrderSummary.error.messages, ({ message }, index) => (
              <p key={index}>
                {message}
              </p>
            ))}
          </Alert>
        )}

        <Box alignContent="center" className={styles.row}>
          <div className={styles.field}>
            <span className={styles.label}>Order Number </span>
            <span className={styles.value}>
              {order.number}
            </span>
            <IconButton
              className={styles.copyBtn}
              onClick={(): void => onCopyValue(order.number, 'order number')}
              icon={Copy}
              variant="flat"
              size="small"
            />
          </div>

          {
            shopifyOrderId
            && (
            <div className={styles.field}>
              <span className={styles.label}>External Order ID</span>
              <span className={styles.value}>
                {shopifyOrderId}
              </span>
              <IconButton
                className={styles.copyBtn}
                onClick={(): void => onCopyValue(typeof shopifyOrderId === 'string' ? shopifyOrderId : '', 'external order ID')}
                icon={Copy}
                variant="flat"
                size="small"
              />
            </div>
            )
          }

        </Box>

        <Box alignContent="center" className={styles.row}>
          <div className={styles.field}>
            <span className={styles.label}>Order Open</span>
            <span className={styles.value}>
              {orderOpened}
            </span>
          </div>

          <div className={styles.field}>
            <span className={styles.label}>Experience</span>
            <span className={styles.value}>
              {!showExperienceLink ? experienceDisplayName : (
                <Link to={`/${organizationId}/experience/${experienceKey}/localization`}>
                  {experienceDisplayName}
                </Link>
              )}
            </span>
          </div>

        </Box>

        <Box alignContent="center" className={styles.row}>
          <div className={styles.field}>
            <span className={styles.label}>Delivered Duty</span>
            <span className={styles.value}>
              {order.delivered_duty}
            </span>
          </div>

          <div className={styles.field}>
            <span className={styles.label}>{expiresAt ? 'Expires' : 'Order Submitted'}</span>
            <span className={styles.value}>
              {orderExpires !== '' ? orderExpires : orderSubmitted}
            </span>
          </div>

        </Box>

        <Box alignContent="center" className={styles.row}>
          <div className={styles.field}>
            <span className={styles.label}>Order Type</span>
            <span className={styles.value}>
              {order.order_type === 'replacement' ? 'Replacement' : 'Standard' }
            </span>
          </div>

          {orderCancelledAt !== '' && (
          <div className={styles.field}>
            <span className={styles.label}>Order Cancelled</span>
            <span className={styles.value}>
              {orderCancelledAt}
            </span>
          </div>
          )}

        </Box>

      </CardContent>
    </Card>
  );
};

OrderDetailsOverview.defaultProps = {
  createdAt: undefined,
  expiresAt: undefined,
  refundOrderSummary: undefined,
};

export default OrderDetailsOverview;
