import List from '../../../modules/experiments/components/list/containers/list';
import ExperimentDetails from '../../../modules/experiments/components/experiment-details/containers/experiment-details';

export default {
  path: ':organization/experiments',
  indexRoute: { component: List },
  title: 'Experiments',
  childRoutes: [
    {
      path: ':experimentKey',
      component: ExperimentDetails,
      title: 'Experiment Summary',
    },
  ],
};
