enum ActionTypes {
  'DISCOUNT_FILTERS_UPDATED' = 'DISCOUNT_FILTERS_UPDATED',
  'FETCH_DISCOUNTS_REQUEST' = 'FETCH_DISCOUNTS_REQUEST',
  'FETCH_DISCOUNTS_SUCCESS' = 'FETCH_DISCOUNTS_SUCCESS',
  'FETCH_DISCOUNTS_FAILURE' = 'FETCH_DISCOUNTS_FAILURE',
  'FETCH_DISCOUNT_REQUEST' = 'FETCH_DISCOUNT_REQUEST',
  'FETCH_DISCOUNT_SUCCESS' = 'FETCH_DISCOUNT_SUCCESS',
  'FETCH_DISCOUNT_FAILURE' = 'FETCH_DISCOUNT_FAILURE',
  'SET_CURRENT_DISCOUNT' = 'SET_CURRENT_DISCOUNT',
  'CLEAR_CURRENT_DISCOUNT' = 'CLEAR_CURRENT_DISCOUNT',
  'UPDATE_DISCOUNT_REQUEST' = 'UPDATE_DISCOUNT_REQUEST',
  'UPDATE_DISCOUNT_SUCCESS' = 'UPDATE_DISCOUNT_SUCCESS',
  'UPDATE_DISCOUNT_FAILURE' = 'UPDATE_DISCOUNT_FAILURE',
  'CREATE_DISCOUNT_REQUEST' = 'CREATE_DISCOUNT_REQUEST',
  'CREATE_DISCOUNT_SUCCESS' = 'CREATE_DISCOUNT_SUCCESS',
  'CREATE_DISCOUNT_FAILURE' = 'CREATE_DISCOUNT_FAILURE',
  'DELETE_DISCOUNT_REQUEST' = 'DELETE_DISCOUNT_REQUEST',
  'DELETE_DISCOUNT_SUCCESS' = 'DELETE_DISCOUNT_SUCCESS',
  'DELETE_DISCOUNT_FAILURE' = 'DELETE_DISCOUNT_FAILURE',
  'SET_CURRENT_DISCOUNT_ID' = 'SET_CURRENT_DISCOUNT_ID',
}

export default ActionTypes;
