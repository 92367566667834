import { CircleLoader } from '@flowio/react-icons';
import { Button } from '@flowio/react-button';
import React from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { Card, CardHeader, CardContent } from '@flowio/react-card';
import { WorksheetHeader } from '@flowio/react-worksheet';
import kebabCase from 'lodash/kebabCase';
import noop from 'lodash/noop';
import { formValueSelector, submit } from 'redux-form';
import { Dialog, DialogBody } from '@flowio/react-dialog';
import Alert from '../../../../components/alert/alert';
import { IMPORTS_FORM } from '../../constants/form-names';
import ImportFileForm from '../../containers/imports-form';
import * as styles from './ImportSection.styles';
import {
  ImportDialogDispatchProps,
  ImportDialogOwnProps,
  ImportDialogProps,
  ImportDialogState,
  ImportDialogStateProps,
} from './types';
import { RootState, ThunkDispatcher } from '../../../../stores/types';

class ImportDialog extends React.PureComponent<ImportDialogProps, ImportDialogState> {
  static displayName = 'ImportDialog';

  static defaultProps = {
    defaultEmail: undefined,
    setEmailRef: noop,
    dispatch: noop,
    valid: false,
    setImportRef: noop,
    onImportTypeChange: noop,
    onFileChange: noop,
    selectedFile: undefined,
    isInvalid: false,
    isFileEmpty: false,
    importSuccess: false,
    error: undefined,
    onRequestClose: noop,
    onRequestSubmit: noop,
    fileName: undefined,
  };

  constructor(props: ImportDialogProps) {
    super(props);
    const { submitted } = this.props;
    this.state = {
      hasSubmitted: submitted,
    };
  }

  static getDerivedStateFromProps(props: ImportDialogProps, state: ImportDialogState) {
    return {
      hasSubmitted: props.submitted && !state.hasSubmitted,
    };
  }

  handleImportTypeChange = (_event: Event, importType: any) => {
    const {
      onImportTypeChange = noop,
    } = this.props;
    onImportTypeChange(importType.value);
  };

  render() {
    const {
      error,
      onRequestClose,
      valid,
      isFileEmpty,
      isOpen,
      fileName,
      handleSubmit,
      submitting,
    } = this.props;

    console.log('*****');
    console.log(error);

    return (
      <Dialog open={isOpen} className={styles.imports} onClose={onRequestClose} backdrop>
        <WorksheetHeader
          content="Import File"
          rightDecoration={<Button content="Import" intent="primary" onClick={handleSubmit} disabled={!valid} size="medium" />}
          leftDecoration={<Button content="Cancel" size="medium" onClick={onRequestClose} />}
        />
        <DialogBody>
          <div>
            <Card>
              <CardHeader
                caption="Import File"
                dividing
              />
              <CardContent>
                {submitting && (
                  <div className={styles.loadingIndicatorContainer}>
                    <CircleLoader className={styles.loadingIndicator} />
                    <div className={styles.loadingDescription}>File Upload in Progress</div>
                  </div>
                )}
                {!submitting && (
                  <>
                    <ImportFileForm fileName={fileName} />
                    {error && error.messages && error.messages.map((failure) => (
                      <Alert key={kebabCase(failure.message)} type="failure">
                        {failure.message}
                      </Alert>
                    ))}
                    {isFileEmpty && (
                      <Alert type="failure">
                        The file you uploaded is empty. Please try again.
                      </Alert>
                    )}
                  </>
                )}
              </CardContent>
            </Card>
          </div>
        </DialogBody>
      </Dialog>
    );
  }
}

function mapStateToProps(state: RootState): ImportDialogStateProps {
  const importFormSelector = formValueSelector(IMPORTS_FORM);
  return {
    fileName: get(importFormSelector(state, 'import_file'), '[0].name'),
  };
}

function mapDispatchToProps(dispatch: ThunkDispatcher): ImportDialogDispatchProps {
  return {
    handleSubmit: () => {
      dispatch(submit(IMPORTS_FORM));
    },
  };
}

export default connect<
ImportDialogStateProps,
ImportDialogDispatchProps,
ImportDialogOwnProps,
RootState
>(mapStateToProps, mapDispatchToProps)(ImportDialog);
