import { css } from '@emotion/css';
import { contentFrameMaxWidth } from '../../../../theme/theme';

export const accountProfile = css`
  padding-top: 4rem;
  margin: 0 auto;
  max-width: ${contentFrameMaxWidth}; /* add this as theme variable */
`;

export const label = css`
  margin-bottom: 1rem;
  font-size: 1.1rem;
  font-weight: 400;
`;

export const formRow = css`
  display: flex;
  margin-bottom: 1rem;
`;

export const formField = css`
  flex: 1 0;

  &:last-child {
    margin-left: 1rem;
  }
`;

export const actions = css`
  text-align: right;
`;

export const actionButton = css`
  &:last-child {
    margin-left: 1rem;
  }
`;
