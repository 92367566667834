import ActionTypes from '../constants/ActionTypes';
import { RootActionTypes } from '../../../stores/types';
import { DownloadsFeedsDialogState } from '../types';

const defaultState: DownloadsFeedsDialogState = {
  showDialog: false,
};

export default function downloadFeedsDialogReducer(
  state = defaultState,
  action: RootActionTypes,
): DownloadsFeedsDialogState {
  switch (action.type) {
    case ActionTypes.DOWNLOAD_FEEDS_DIALOG_OPEN:
      return {
        ...state,
        showDialog: true,
        feedIds: action.payload,
      };
    case ActionTypes.DOWNLOAD_FEEDS_DIALOG_CLOSE:
      return {
        ...state,
        showDialog: false,
      };
    default:
      return state;
  }
}
