import { compose } from 'redux';
import includes from 'lodash/includes';
import { createWebhook, updateWebhook, deleteWebhook } from '../actions';
import withSubmit from '../../../components/with-submit';
import withRedirectSubmitted from '../../../components/with-redirect-submitted';
import withValidation from '../../../components/with-validation';
import WebhookForm from '../components/webhook-form/webhook-form';
import { getOrganizationId } from '../../organization';

function submitForm(action, formData) {
  switch (action) {
    case 'create': return createWebhook(formData);
    case 'update': return updateWebhook(formData.id, formData);
    case 'delete': return deleteWebhook(formData.id, formData);
    default: throw new Error('Unhandled form submission action.');
  }
}

function getRedirectLocation(state, ownProps) {
  if (ownProps.editing) {
    if (ownProps.webhook) {
      return {
        pathname: `/${getOrganizationId(state)}/organization/integrations/webhooks/${ownProps.webhook.id}`,
        state: { webhookState: 'updated' },
      };
    }

    return {
      pathname: `/${getOrganizationId(state)}/organization/integrations`,
      state: { webhookState: 'deleted' },
    };
  }

  return {
    pathname: `/${getOrganizationId(state)}/organization/integrations`,
    state: { webhookState: 'created' },
  };
}

export default compose(
  withSubmit(submitForm),
  withRedirectSubmitted(getRedirectLocation),
  withValidation(({ webhook = { events: ['*'] } }) => ({
    events: {
      defaultValue: webhook.events,
      validations: ({ length }, fields) => {
        if (fields.subscription && fields.subscription.value !== 'all' && !length) {
          return 'You must subscribe to at least a single event';
        }

        return null;
      },
    },
    subscription: {
      defaultValue: includes(webhook.events, '*') ? 'all' : 'custom',
      validations: {
        includes: ['all', 'custom'],
        message: 'You must select a subscription',
      },
    },
    url: {
      defaultValue: webhook.url,
      validations: [{
        required: true,
        message: 'You must provide a URL for webhooks.',
      }, {
        pattern: 'url',
        message: 'This doesn\'t look like a valid URL.',
      }],
      parse: (value) => value.trim(),
    },
  })),
)(WebhookForm);
