import { RootActionTypes } from '../../../stores/types';
import { ActionTypes } from '../constants';

const changeActiveShippingLane = ({
  shippingLaneId,
}: { shippingLaneId: string }): RootActionTypes => ({
  type: ActionTypes.CHANGE_ACTIVE_SHIPPING_LANE,
  payload: shippingLaneId,
});

export default changeActiveShippingLane;
