import { Box } from '@flowio/react-box';
import { Button } from '@flowio/react-button';
import { Checkbox } from '@flowio/react-checkbox';
import { TextField } from '@flowio/react-text-field';
import { startCase } from 'lodash';
import React, { useState } from 'react';
import { Search } from '@flowio/react-icons';
import { ItemSearchOptions } from '../types';
import * as styles from './product-catalog.styles';

type Props = {
  selectedFilter: keyof ItemSearchOptions;
  onFilterSubmit: (
    searchType: keyof ItemSearchOptions,
    value: string | number | boolean,
  ) => void;
};

const ProductSearchFilter: React.FC<Props> = ({ selectedFilter, onFilterSubmit }) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [checkboxValue, setCheckboxValue] = useState(false);
  const isCheckbox = selectedFilter === 'physical_good';

  return (
    <>
      <Box direction="column" className={styles.filterContainer}>
        <Box spacing="loose" alignItems={isCheckbox ? 'center' : 'end'} justifyContent="between">
          { isCheckbox ? (
            <Checkbox
              checked={checkboxValue}
              onChange={(e): void => setCheckboxValue(e.target.checked)}
              labelText={startCase(selectedFilter)}
            />
          )
            : (
              <TextField
                value={inputValue}
                leftIcon={Search}
                onChange={(e): void => setInputValue(e.target.value)}
                labelText={startCase(selectedFilter)}
              />
            )}

          <Button
            type="button"
            content="Done"
            onClick={(): void => onFilterSubmit(selectedFilter, inputValue || checkboxValue)}
            intent="primary"
            disabled={false}
          />
        </Box>
      </Box>
    </>
  );
};

export default ProductSearchFilter;
