export enum ActionTypes {
  CLEAR_ACTIVE_EXPERIENCE = 'CLEAR_ACTIVE_EXPERIENCE',
  CLEAR_ARCHIVE_EXPERIENCE = 'CLEAR_ARCHIVE_EXPERIENCE',
  CLEAR_CLONE_EXPERIENCE = 'CLEAR_CLONE_EXPERIENCE',
  CLEAR_ACTIVE_PRICING_SECTION = 'CLEAR_ACTIVE_PRICING_SECTION',
  CLEAR_EXPERIENCE_DEFAULTS = 'CLEAR_EXPERIENCE_DEFAULTS',
  CLEAR_ITEM_PRICING = 'CLEAR_ITEM_PRICING',
  CREATE_EXPERIENCE_SUBMIT = 'CREATE_EXPERIENCE_SUBMIT',
  CREATE_EXPERIENCE_SUCCESS = 'CREATE_EXPERIENCE_SUCCESS',
  CREATE_EXPERIENCE_FAILURE = 'CREATE_EXPERIENCE_FAILURE',
  CREATE_MARGIN_SUBMIT = 'CREATE_MARGIN_SUBMIT',
  CREATE_MARGIN_SUCCESS = 'CREATE_MARGIN_SUCCESS',
  CREATE_MARGIN_FAILURE = 'CREATE_MARGIN_FAILURE',
  CLONE_EXPERIENCE_REQUEST = 'CLONE_EXPERIENCE_REQUEST',
  CLONE_EXPERIENCE_SUCCESS = 'CLONE_EXPERIENCE_SUCCESS',
  CLONE_EXPERIENCE_FAILED = 'CLONE_EXPERIENCE_FAILED',
  CLONE_EXPERIENCE_ERROR = 'CLONE_EXPERIENCE_ERROR',
  DELETE_MARGIN_SUBMIT = 'DELETE_MARGIN_SUBMIT',
  DELETE_MARGIN_SUCCESS = 'DELETE_MARGIN_SUCCESS',
  DELETE_MARGIN_FAILURE = 'DELETE_MARGIN_FAILURE',
  FETCH_DELIVERED_DUTY_OPTIONS_REQUEST = 'FETCH_DELIVERED_DUTY_OPTIONS_REQUEST',
  FETCH_DELIVERED_DUTY_OPTIONS_SUCCESS = 'FETCH_DELIVERED_DUTY_OPTIONS_SUCCESS',
  FETCH_DELIVERED_DUTY_OPTIONS_FAILURE = 'FETCH_DELIVERED_DUTY_OPTIONS_FAILURE',
  FETCH_DELIVERED_DUTY_PREFERENCE_REQUEST = 'FETCH_DELIVERED_DUTY_PREFERENCE_REQUEST',
  FETCH_DELIVERED_DUTY_PREFERENCE_SUCCESS = 'FETCH_DELIVERED_DUTY_PREFERENCE_SUCCESS',
  FETCH_DELIVERED_DUTY_PREFERENCE_FAILURE = 'FETCH_DELIVERED_DUTY_PREFERENCE_FAILURE',
  FETCH_EXPERIENCE_REQUEST = 'FETCH_EXPERIENCE_REQUEST',
  FETCH_EXPERIENCE_SUCCESS = 'FETCH_EXPERIENCE_SUCCESS',
  FETCH_EXPERIENCE_FAILURE = 'FETCH_EXPERIENCE_FAILURE',
  FETCH_ALL_EXPERIENCES_REQUEST = 'FETCH_ALL_EXPERIENCES_REQUEST',
  FETCH_ALL_EXPERIENCES_SUCCESS = 'FETCH_ALL_EXPERIENCES_SUCCESS',
  FETCH_ALL_EXPERIENCES_FAILURE = 'FETCH_ALL_EXPERIENCES_FAILURE',
  FETCH_EXPERIENCE_CLONE_REQUEST = 'FETCH_EXPERIENCE_CLONE_REQUEST',
  FETCH_EXPERIENCE_CLONE_SUCCESS = 'FETCH_EXPERIENCE_CLONE_SUCCESS',
  FETCH_EXPERIENCE_CLONE_FAILURE = 'FETCH_EXPERIENCE_CLONE_FAILURE',
  FETCH_EXPERIENCE_QUERY_BUILDER_FILTERS_REQUEST = 'FETCH_EXPERIENCE_QUERY_BUILDER_FILTERS_REQUEST',
  FETCH_EXPERIENCE_QUERY_BUILDER_FILTERS_SUCCESS = 'FETCH_EXPERIENCE_QUERY_BUILDER_FILTERS_SUCCESS',
  FETCH_EXPERIENCE_QUERY_BUILDER_FILTERS_FAILURE = 'FETCH_EXPERIENCE_QUERY_BUILDER_FILTERS_FAILURE',
  FETCH_EXPERIENCE_ITEM_REQUEST = 'FETCH_EXPERIENCE_ITEM_REQUEST',
  FETCH_EXPERIENCE_ITEM_SUCCESS = 'FETCH_EXPERIENCE_ITEM_SUCCESS',
  FETCH_EXPERIENCE_ITEM_FAILURE = 'FETCH_EXPERIENCE_ITEM_FAILURE',
  FETCH_EXPERIENCES_REQUEST = 'FETCH_EXPERIENCES_REQUEST',
  FETCH_EXPERIENCES_SUCCESS = 'FETCH_EXPERIENCES_SUCCESS',
  FETCH_EXPERIENCES_FAILURE = 'FETCH_EXPERIENCES_FAILURE',
  FETCH_EXPERIENCE_LOGISTICS_SETTING_REQUEST = 'FETCH_EXPERIENCE_LOGISTICS_SETTING_REQUEST',
  FETCH_EXPERIENCE_LOGISTICS_SETTING_SUCCESS = 'FETCH_EXPERIENCE_LOGISTICS_SETTING_SUCCESS',
  FETCH_EXPERIENCE_LOGISTICS_SETTING_FAILURE = 'FETCH_EXPERIENCE_LOGISTICS_SETTING_FAILURE',
  FETCH_PAYMENT_METHODS_DISPLAY_ORDER_REQUEST = 'FETCH_PAYMENT_METHODS_DISPLAY_ORDER_REQUEST',
  FETCH_PAYMENT_METHODS_DISPLAY_ORDER_SUCCESS = 'FETCH_PAYMENT_METHODS_DISPLAY_ORDER_SUCCESS',
  FETCH_PAYMENT_METHODS_DISPLAY_ORDER_FAILURE = 'FETCH_PAYMENT_METHODS_DISPLAY_ORDER_FAILURE',
  GET_EXPERIENCE_REQUEST = 'GET_EXPERIENCE_REQUEST',
  GET_EXPERIENCE_SUCCESS = 'GET_EXPERIENCE_SUCCESS',
  GET_EXPERIENCE_FAILURE = 'GET_EXPERIENCE_FAILURE',
  GET_EXPERIENCE_DEFAULTS_REQUEST = 'GET_EXPERIENCE_DEFAULTS_REQUEST',
  GET_EXPERIENCE_DEFAULTS_SUCCESS = 'GET_EXPERIENCE_DEFAULTS_SUCCESS',
  GET_EXPERIENCE_DEFAULTS_FAILURE = 'GET_EXPERIENCE_DEFAULTS_FAILURE',
  GET_EXPERIENCE_PRICING_REQUEST = 'GET_EXPERIENCE_PRICING_REQUEST',
  GET_EXPERIENCE_PRICING_SUCCESS = 'GET_EXPERIENCE_PRICING_SUCCESS',
  GET_EXPERIENCE_PRICING_FAILURE = 'GET_EXPERIENCE_PRICING_FAILURE',
  GET_ITEM_PRICE_DETAILS_REQUEST = 'GET_ITEM_PRICE_DETAILS_REQUEST',
  GET_ITEM_PRICE_DETAILS_SUCCESS = 'GET_ITEM_PRICE_DETAILS_SUCCESS',
  GET_ITEM_PRICE_DETAILS_FAILURE = 'GET_ITEM_PRICE_DETAILS_FAILURE',
  GET_ITEM_SUGGESTIONS_REQUEST = 'GET_ITEM_SUGGESTIONS_REQUEST',
  GET_ITEM_SUGGESTIONS_SUCCESS = 'GET_ITEM_SUGGESTIONS_SUCCESS',
  GET_ITEM_SUGGESTIONS_FAILURE = 'GET_ITEM_SUGGESTIONS_FAILURE',
  GET_MARGIN_REQUEST = 'GET_MARGIN_REQUEST',
  GET_MARGIN_SUCCESS = 'GET_MARGIN_SUCCESS',
  GET_MARGIN_FAILURE = 'GET_MARGIN_FAILURE',
  GET_MARGINS_REQUEST = 'GET_MARGINS_REQUEST',
  GET_MARGINS_SUCCESS = 'GET_MARGINS_SUCCESS',
  GET_MARGINS_FAILURE = 'GET_MARGINS_FAILURE',
  GET_PRICE_FORMAT_REQUEST = 'GET_PRICE_FORMAT_REQUEST',
  GET_PRICE_FORMAT_SUCCESS = 'GET_PRICE_FORMAT_SUCCESS',
  GET_PRICE_FORMAT_FAILURE = 'GET_PRICE_FORMAT_FAILURE',
  LOCATION_CHANGE_UPDATE_QUERY_REQUEST = 'LOCATION_CHANGE_UPDATE_QUERY_REQUEST',
  LOCATION_CHANGE_UPDATE_QUERY_FAILURE = 'LOCATION_CHANGE_UPDATE_QUERY_FAILURE',
  SELECT_EXPERIENCE = 'SELECT_EXPERIENCE',
  SET_ACTIVE_EXPERIENCE = 'SET_ACTIVE_EXPERIENCE',
  SET_ARCHIVE_EXPERIENCE = 'SET_ARCHIVE_EXPERIENCE',
  SET_ACTIVE_PRICING_SECTION = 'SET_ACTIVE_PRICING_SECTION',
  SET_CLONE_EXPERIENCE = 'SET_CLONE_EXPERIENCE',
  SET_PAYMENT_METHODS_DISPLAY_ORDER = 'SET_PAYMENT_METHODS_DISPLAY_ORDER',
  SET_CC_ORDER_OPEN_STATE = 'SET_CC_ORDER_OPEN_STATE',
  SELECT_EXPERIENCE_LOGISTICS_SETTINGS = 'SELECT_EXPERIENCE_LOGISTICS_SETTINGS',
  RESET_EXPERIENCE = 'RESET_EXPERIENCE',
  UPDATE_DELIVERED_DUTY_PREFERENCE_REQUEST = 'UPDATE_DELIVERED_DUTY_PREFERENCE_REQUEST',
  UPDATE_DELIVERED_DUTY_PREFERENCE_SUCCESS = 'UPDATE_DELIVERED_DUTY_PREFERENCE_SUCCESS',
  UPDATE_DELIVERED_DUTY_PREFERENCE_FAILURE = 'UPDATE_DELIVERED_DUTY_PREFERENCE_FAILURE',
  UPDATE_EXPERIENCE_SUBMIT = 'UPDATE_EXPERIENCE_SUBMIT',
  UPDATE_EXPERIENCE_SUCCESS = 'UPDATE_EXPERIENCE_SUCCESS',
  UPDATE_EXPERIENCE_FAILURE = 'UPDATE_EXPERIENCE_FAILURE',
  UPDATE_EXPERIENCE_LOGISTICS_SETTING_REQUEST = 'UPDATE_EXPERIENCE_LOGISTICS_SETTING_REQUEST',
  UPDATE_EXPERIENCE_LOGISTICS_SETTING_SUCCESS = 'UPDATE_EXPERIENCE_LOGISTICS_SETTING_SUCCESS',
  UPDATE_EXPERIENCE_LOGISTICS_SETTING_FAILURE = 'UPDATE_EXPERIENCE_LOGISTICS_SETTING_FAILURE',
  UPDATE_EXPERIENCE_STATUS_REQUEST = 'UPDATE_EXPERIENCE_STATUS_REQUEST',
  UPDATE_EXPERIENCE_STATUS_SUCCESS = 'UPDATE_EXPERIENCE_STATUS_SUCCESS',
  UPDATE_EXPERIENCE_STATUS_FAILURE = 'UPDATE_EXPERIENCE_STATUS_FAILURE',
  UPDATE_ITEM_MARGINS = 'UPDATE_ITEM_MARGINS',
  UPDATE_MARGIN_SUBMIT = 'UPDATE_MARGIN_SUBMIT',
  UPDATE_MARGIN_SUCCESS = 'UPDATE_MARGIN_SUCCESS',
  UPDATE_MARGIN_FAILURE = 'UPDATE_MARGIN_FAILURE',
  UPDATE_PAYMENT_METHODS_DISPLAY_ORDER_REQUEST = 'UPDATE_PAYMENT_METHODS_DISPLAY_ORDER_REQUEST',
  UPDATE_PAYMENT_METHODS_DISPLAY_ORDER_SUCCESS = 'UPDATE_PAYMENT_METHODS_DISPLAY_ORDER_SUCCESS',
  UPDATE_PAYMENT_METHODS_DISPLAY_ORDER_FAILURE = 'UPDATE_PAYMENT_METHODS_DISPLAY_ORDER_FAILURE',
  UPDATE_PRICING_SUBMIT = 'UPDATE_PRICING_SUBMIT',
  UPDATE_PRICING_SUCCESS = 'UPDATE_PRICING_SUCCESS',
  UPDATE_PRICING_FAILURE = 'UPDATE_PRICING_FAILURE',
  UPDATE_PRICE_FORMAT_REQUEST = 'UPDATE_PRICE_FORMAT_REQUEST',
  UPDATE_PRICE_FORMAT_SUCCESS = 'UPDATE_PRICE_FORMAT_SUCCESS',
  UPDATE_PRICE_FORMAT_FAILURE = 'UPDATE_PRICE_FORMAT_FAILURE',
  UPDATE_PRICE_BOOK_REQUEST = 'UPDATE_PRICE_BOOK_REQUEST',
  UPDATE_PRICE_BOOK_SUCCESS = 'UPDATE_PRICE_BOOK_SUCCESS',
  UPDATE_PRICE_BOOK_FAILURE = 'UPDATE_PRICE_BOOK_FAILURE',
  REMOVE_PRICE_BOOK_MAPPING_REQUEST = 'REMOVE_PRICE_BOOK_MAPPING_REQUEST',
  REMOVE_PRICE_BOOK_MAPPING_SUCCESS = 'REMOVE_PRICE_BOOK_MAPPING_SUCCESS',
  REMOVE_PRICE_BOOK_MAPPING_FAILURE = 'REMOVE_PRICE_BOOK_MAPPING_FAILURE',
  SAVE_PRICING_DISPLAY = 'SAVE_PRICING_DISPLAY',
  SET_SELECTED_EXPERIENCE = 'SET_SELECTED_EXPERIENCE',
  EXPERIENCE_EXPORT_SETTINGS_REQUEST = 'EXPERIENCE_EXPORT_SETTINGS_REQUEST',
  EXPERIENCE_EXPORT_SETTINGS_SUCCESS = 'EXPERIENCE_EXPORT_SETTINGS_SUCCESS',
  EXPERIENCE_EXPORT_SETTINGS_FAILURE = 'EXPERIENCE_EXPORT_SETTINGS_FAILURE',
  OPEN_EXPORT_EXPERIENCE_SETTINGS_MODAL = 'OPEN_EXPORT_EXPERIENCE_SETTINGS_MODAL',
  CLOSE_EXPORT_EXPERIENCE_SETTINGS_MODAL = 'CLOSE_EXPORT_EXPERIENCE_SETTINGS_MODAL',
  GET_SUBCATALOG_STATISTICS_REQUEST = 'GET_SUBCATALOG_STATISTICS_REQUEST',
  GET_SUBCATALOG_STATISTICS_SUCCESS = 'GET_SUBCATALOG_STATISTICS_SUCCESS',
  GET_SUBCATALOG_STATISTICS_FAILURE = 'GET_SUBCATALOG_STATISTICS_FAILURE',
  CHANGE_PRICING_FORMAT_VISIBILITY = 'CHANGE_PRICING_FORMAT_VISIBILITY',
}

export default ActionTypes;
