import React from 'react';
import BemHelper from '@flowio/bem-helper';
import map from 'lodash/map';
import { Button } from '@flowio/react-button';
import {
  ErrorOutline, Sync, Check, Lens,
} from '@flowio/react-icons';
import { Tabs } from '@flowio/react-tabs';
import {
  Panel, PanelHeader, PanelContent, PanelTitle,
} from '@flowio/react-panel';
import { Box, BoxItem } from '@flowio/react-box';
import Alert from '../../../../components/alert/alert';
import Highlight from '../../../../components/highlight';

type Props = {
  delivery: io.flow.v0.models.WebhookDelivery;
  onRequestRedeliver?: (id: string) => void;
};

if (process.browser) {
  require('./webhook-delivery-panel.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('webhook-delivery-panel');

function beautifyJson(json: string): string {
  try {
    return JSON.stringify(JSON.parse(json), null, 2);
  } catch (e) {
    return json;
  }
}

const repeat = (str: string, times: number): string => (new Array(times + 1)).join(str);

const pad = (num: number, maxLength: number): string => repeat('0', maxLength - num.toString().length) + num;

const formatDate = (date: Date): string => `${pad(date.getMonth() + 1, 2)}-${pad(date.getDate(), 2)}-${date.getFullYear()}`;

const formatTime = (date: Date): string => `${pad(date.getHours(), 2)}:${pad(date.getMinutes(), 2)}:${pad(date.getSeconds(), 2)}`;

const renderHttpHeaders = (headers: io.flow.v0.models.Header[]): JSX.Element => (
  <pre>
    {map(headers, (header, index) => (
      <span key={index}>
        <strong>
          {header.name}
          :
        </strong>
        {' '}
        {header.value}
        {'\n'}
      </span>
    ))}
  </pre>
);

const renderPanelHeader = (delivery: io.flow.v0.models.WebhookDelivery): JSX.Element => (
  <PanelHeader>
    <PanelTitle>
      <Box>
        {(() => {
          switch (delivery.status) {
            case 'failure':
              return <ErrorOutline className={bem.element('status')} color="#bd2c00" />;
            case 'success':
              return <Check className={bem.element('status')} color="#6cc644" />;
            default:
              return <Lens className={bem.element('status')} color="#999999" />;
          }
        })()}
        <BoxItem flexAuto className={bem.element('guid')}>
          {delivery.id}
        </BoxItem>
        {delivery.created_at && (
          <span className={bem.element('timestamp')}>
            {formatDate(new Date(delivery.created_at))}
            <span className="muted-text">
              {formatTime(new Date(delivery.created_at))}
            </span>
          </span>
        )}
      </Box>
    </PanelTitle>
  </PanelHeader>
);

const WebhookDeliveryPanel: React.FC<Props> = ({
  onRequestRedeliver,
  delivery,
}) => {
  const handleRedeliver = (): void => {
    if (onRequestRedeliver) {
      onRequestRedeliver(delivery.id);
    }
  };

  const tabs = [
    { content: 'Request', eventKey: 'request' },
    { content: `Response (${delivery?.latest?.status || 'pending'})`, eventKey: 'response' },
  ];

  const requestPanelContent = delivery.latest ? (
    <>
      {delivery.status === 'failure' && (
        <Alert type="warning">We couldn’t deliver this payload</Alert>
      )}
      <div>
        <h4 className={bem.element('label')}>Headers</h4>
        {renderHttpHeaders(delivery.latest.headers)}
        <h4 className={bem.element('label')}>Payload</h4>
        <Highlight snippet={beautifyJson(delivery.latest.body)} className="json" />
      </div>
    </>
  ) : '';

  const responsePanelContent = delivery.latest?.response ? (
    <div className={bem.element('response-details')}>
      <h4 className={bem.element('label')}>Status Code</h4>
      {delivery.latest.response.status}
      <h4 className={bem.element('label')}>Headers</h4>
      {renderHttpHeaders(delivery.latest.response.headers)}
      <h4 className={bem.element('label')}>Body</h4>
      {(() => {
        const { headers } = delivery.latest.response;
        const contentType = headers.find(({ name }) => name === 'Content-Type');
        if (contentType && contentType.value.indexOf('json') >= 0) {
          return <Highlight snippet={beautifyJson(delivery.latest.response.body)} className="json" />;
        }
        return (
          <pre>
            <code>{delivery.latest.response.body.substring(0, 5000)}</code>
          </pre>
        );
      })()}
    </div>
  ) : '';

  const panels = [
    { eventKey: 'request', content: requestPanelContent },
    { eventKey: 'response', content: responsePanelContent },
  ];

  return (
    <Panel
      className={bem.block()}
      collapsible
      key={delivery.id}
    >
      {renderPanelHeader(delivery)}
      <PanelContent>
        <Button
          css={{ marginBottom: '1rem', float: 'right' }}
          leftIcon={<Sync />}
          content="Redeliver"
          onClick={handleRedeliver}
        />
        {delivery.latest && (
          <Tabs
            defaultActiveKey="request"
            tabs={tabs}
            panels={panels}
          />
        )}
      </PanelContent>
    </Panel>
  );
};

WebhookDeliveryPanel.displayName = 'WebhookDeliveryPanel';

export default WebhookDeliveryPanel;
