import { Box, BoxItem } from '@flowio/react-box';
import capitalize from 'lodash/capitalize';
import React from 'react';
import supportsSessionQuery from '../../../../../utilities/experiment-supports-session-query';
import FormattedDate from '../../../../../components/formatted-date/formatted-date';
import StatusLabel from '../../../../../components/status-label';
import { getLabelIntentByStatus, getSignificanceActionMsg } from '../../../utils';
import ExperimentVariant from './experiment-variant';
import { isFeatureExperiment } from '../utilities/checkExperiment';
import * as styles from './experiment-details-summary.styles';

interface ExperimentDetailsSummaryProps {
  organizationId: string,
  variantData: io.flow.internal.v0.unions.Variant[];
  experiment: io.flow.internal.v0.unions.Experiment;
  region?: string;
}

const sessionQuery = (experiment: io.flow.internal.v0.unions.Experiment): string | undefined => {
  if (isFeatureExperiment(experiment)) {
    return experiment.session_query;
  }
  return undefined;
};

const ExperimentDetailsSummary: React.FC<ExperimentDetailsSummaryProps> = ({
  organizationId,
  experiment,
  variantData,
  region,
}) => (
  <Box className={styles.detailsContainer}>
    <BoxItem className="column" flexAuto>
      <Box className="metricContainer">
        <span className="label">Type</span>
        <span className="value">
          {experiment.discriminator === 'experience' ? 'Experiences' : capitalize(experiment.discriminator)}
        </span>
      </Box>

      <div className="metricContainer">
        {region
          ? (
            <Box>
              <span className="label">Region</span>
              <span className="value">
                {region}
              </span>
            </Box>
          ) : (
            <Box>
              <span className="label">Feature</span>
              <span className="value">
                {experiment.name}
              </span>
            </Box>
          )}
      </div>
      {experiment.description && (
        <div className="metricContainer">
          <Box>
            <span className="label">Description</span>
            <span className="value">
              {experiment.description}
            </span>
          </Box>
        </div>
      )}
      <Box className="metricContainer">
        <span className="trafficLabel">Variants & Traffic</span>
        <div>
          {variantData.map((data) => (
            <ExperimentVariant variant={data} />
          ))}
        </div>
      </Box>
      {supportsSessionQuery(organizationId, experiment.discriminator, sessionQuery(experiment)) && (
        <Box className="metricContainer">
          <span className="sessionQuery">Session Query</span>
          <div>
            {sessionQuery(experiment)}
          </div>
        </Box>
      )}
    </BoxItem>
    <BoxItem className="column">
      <Box className="metricContainer">
        <span className="label">Status</span>
        <span className={experiment.status}>
          <StatusLabel
            content={experiment.status}
            intent={getLabelIntentByStatus(experiment.status)}
          />
        </span>
      </Box>
      <Box className="metricContainer">
        <span className="label">Start Date</span>
        <span className="value">
          {experiment.started_at ? (<FormattedDate format="MMM, D, YYYY" value={experiment.started_at} />) : '-'}
        </span>
      </Box>
      <Box className="metricContainer">
        <span className="label">End Date</span>
        <span className="value">
          {experiment.ended_at ? (<FormattedDate format="MMM, D, YYYY" value={experiment.ended_at} />) : getSignificanceActionMsg(experiment)}
        </span>
      </Box>
      {experiment.emails.length > 0 && (
        <Box className="metricContainer">
          <span className="label">Email Alert</span>
          <span className="value">
            {experiment.emails.join('; ')}
          </span>
        </Box>
      )}
    </BoxItem>
  </Box>
);

export default ExperimentDetailsSummary;
