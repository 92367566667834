export enum DateRangeLiterals {
  CUSTOM = 'custom',
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  LAST_WEEK = 'lastweek',
  LAST_7_DAYS = 'last7days',
  LAST_30_DAYS = 'last30days',
  LAST_90_DAYS = 'last90days',
  THIS_MONTH = 'thismonth',
  LAST_MONTH = 'lastmonth',
  LAST_3_MONTHS = 'last3months',
  LAST_12_MONTHS = 'last12months',
  THIS_YEAR = 'thisyear',
  LAST_YEAR = 'lastyear',
}

export default DateRangeLiterals;
