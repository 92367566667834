import { createSelector } from 'reselect';
import { LaneDirection } from '@flowio/api-constants';
import get from 'lodash/get';
import filter from 'lodash/filter';

import getActiveShippingConfiguration from './getActiveShippingConfiguration';
import getReturnShippingLanesPageNumber from './getReturnShippingLanesPageNumber';

export default createSelector(
  getActiveShippingConfiguration,
  getReturnShippingLanesPageNumber,
  (shippingConfiguration, pageNumber) => {
    const shippingLanes = get(shippingConfiguration, 'shipping_lanes');
    const returnShippingLanes = filter(
      shippingLanes,
      (shippingLane) => shippingLane.direction === LaneDirection.RETURN,
    );
    const begin = pageNumber * 10;
    const end = begin + 10;
    const paginatedReturnLanes = returnShippingLanes.slice(begin, end);

    return {
      pageNumber,
      shippingLanes: paginatedReturnLanes,
      isFirstPage: begin === 0,
      isLastPage: end >= returnShippingLanes.length,
    };
  },
);
