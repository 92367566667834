import { css } from '@emotion/react';
import { colors, fontSize, spacing } from '../../../../theme/tokens';

export const labelStyles = css({
  color: colors.steel,
  fontWeight: 500,
  fontSize: fontSize[200],
  lineHeight: '2.5rem',
});

export const autoSaveIndicatorStyles = css({
  fontSize: fontSize[200],
  marginRight: spacing.xl,
});

export const errorBannerStyles = css({
  marginBottom: spacing.m,
});

export const dynamicWidthColumnStyles = css({
  width: '1%',
  whiteSpace: 'nowrap',
});

export const deliveryOptionStyles = css({
  margin: `${spacing.l} 0;`,
});

export const borderStyles = css({
  borderBottom: `1px solid ${colors.mercury};`,
});

export const tableFormLabelStyles = css({
  fontSize: fontSize[400],
  marginTop: spacing.xs,
});

export const tableFormStyles = css({ paddingLeft: '3rem;' });

export const tableFormHelpStyles = css({
  fontSize: fontSize[100],
  margin: `${spacing.m} 0;`,
});
