import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFetch } from '@flowio/redux-fetch';
import { RouterState } from 'react-router';
import { type UsersGetByIdResponse } from '@flowio/api-sdk';

import { RootState, ThunkDispatcher } from '../../../../../stores/types';
import { fetchLabByKey, putLabSettingsByKey, fetchAcceptingUser } from '../../../actions';
import LabKeys from '../../../constants/lab-keys';
import { getAcceptingUser, getLabProjectErrors, getCurrentLabProjectSettings } from '../../../selectors';
import { DispatchProps, StateProps } from '../types';
import BlazeLab from '../components/BlazeLab';

const fetchAsyncState = (
  dispatch: ThunkDispatcher,
  getState: () => RootState,
  ownProps: RouterState,
): Promise<void | UsersGetByIdResponse> => {
  const { organization } = ownProps.params;

  return dispatch(fetchLabByKey(organization, LabKeys.BLAZE)).then(() => {
    const state = getState();
    const labProjectSettings = getCurrentLabProjectSettings(state);
    if (labProjectSettings && labProjectSettings.terms) {
      return Promise.resolve(dispatch(fetchAcceptingUser(labProjectSettings.terms.accepted_by.id)));
    }
    return undefined;
  });
};

const mapStateToProps = (state: RootState): StateProps => ({
  acceptingUser: getAcceptingUser(state),
  labProjectSettings: getCurrentLabProjectSettings(state),
  error: getLabProjectErrors(state),
});

const mapDispatchToProps = (
  dispatch: ThunkDispatcher,
  ownProps: RouterState,
): DispatchProps => ({
  onAcceptLab: () => {
    const { organization } = ownProps.params;
    dispatch(putLabSettingsByKey(organization, LabKeys.BLAZE, true));
  },
});

export default compose<React.FC<{}>>(
  withFetch(fetchAsyncState),
  connect<
  StateProps,
  DispatchProps,
  RouterState,
  RootState>(mapStateToProps, mapDispatchToProps),
)(BlazeLab);
