import { css } from '@emotion/css';

export const policyContainer = css`
  margin-top: 1rem;
`;

export const policiesDivider = css`
  margin-bottom: 0;
`;

export const policyContent = css`
  padding-left: 15px;
  padding-right: 15px;
  height: 40px;
  line-height: center;
  &:nth-child(even) {
    background-color: #e8f9ff;
  }
`;

export const policyContentButton = css`
  height: 24px;
  width: 55px;
  margin-top: 8px;
  margin-bottom: 8px;
`;

export const policyContentBody = css`
  margin-top: 8px;
  margin-bottom: 8px;
`;
