import { Field, Form } from 'redux-form';
import React from 'react';
import { getFullUserName } from '../../utilities';
import { DeleteMembershipFormProps } from './types';

const DeleteMembershipForm: React.FC<DeleteMembershipFormProps> = ({
  membership,
  form,
  handleSubmit,
}) => {
  const membershipUser: io.flow.v0.models.User = membership.user as io.flow.v0.models.User;
  return (
    <Form name={form} onSubmit={handleSubmit}>
      <Field name="id" type="hidden" component="input" />
      <p>
        Are you sure you want to remove member
        {' '}
        {getFullUserName(membershipUser)}
        &quot;
        {membershipUser.email}
        &quot;?
      </p>
    </Form>
  );
};

DeleteMembershipForm.displayName = 'DeleteMembershipForm';

export default DeleteMembershipForm;
