import React from 'react';

import ExperienceNavigation from '../../navigation';
import ExperienceLogisticsSettingCard from '../../ExperienceLogisticsSettingCard';
import { MergedProps as Props } from '../types';

const ExperienceLogisticsSettingPage: React.FC<Props> = ({
  experienceKey,
  organizationId,
  shippingConfigurations,
}) => {
  if (!experienceKey) {
    throw new Error('ExperienceLogisticsSettingPage: Expected value for [experienceKey]');
  }
  return (
    <div>
      <ExperienceNavigation
        organization={organizationId}
        experience={experienceKey}
      />
      <ExperienceLogisticsSettingCard
        organizationId={organizationId}
        shippingConfigurations={shippingConfigurations}
      />
    </div>
  );
};

ExperienceLogisticsSettingPage.displayName = 'ExperienceLogisticsSettingPage';

ExperienceLogisticsSettingPage.defaultProps = {
  shippingConfigurations: [],
};

export default ExperienceLogisticsSettingPage;
