import Client from './client';

export default class ChannelAccounts extends Client {
  constructor(opts) {
    let options = opts;

    if (typeof opts === 'string') {
      options = { host: opts }; // convert host string to options object
    }

    options.serviceName = 'API Internal';

    super(options);
  }

  getChannelAndAccountsByChannelId(channelId, options = {}) {
    return this.makeRequest(`${this.host}/channel/${channelId}/accounts`, options);
  }

  getChannelAndAccountsByChannelIdAndCurrency(channelId, currency, options = {}) {
    return this.makeRequest(`${this.host}/channel/${channelId}/accounts/${currency}`, options);
  }

  getChannelAndAccountsAndContactsByChannelIdAndCurrency(channelId, currency, options = {}) {
    return this.makeRequest(`${this.host}/channel/${channelId}/accounts/${currency}/contacts`, options);
  }

  postChannelAndAccountsAndContactsByChannelIdAndCurrency(channelId, currency, options = {}) {
    return this.makeRequest(`${this.host}/channel/${channelId}/accounts/${currency}/contacts`, {
      ...options,
       method: 'POST',
    });
  }

  getChannelAndAccountsAndContactsByChannelIdAndCurrencyAndId(channelId, currency, id, options = {}) {
    return this.makeRequest(`${this.host}/channel/${channelId}/accounts/${currency}/contacts/${id}`, options);
  }

  deleteChannelAndAccountsAndContactsByChannelIdAndCurrencyAndId(channelId, currency, id, options = {}) {
    return this.makeRequest(`${this.host}/channel/${channelId}/accounts/${currency}/contacts/${id}`, {
      ...options,
       method: 'DELETE',
    });
  }

  getChannelAndAccountsAndStatisticsByChannelIdAndCurrency(channelId, currency, options = {}) {
    return this.makeRequest(`${this.host}/channel/${channelId}/accounts/${currency}/statistics`, options);
  }

  getChannelAndAccountsAndTransactionsByChannelIdAndCurrency(channelId, currency, options = {}) {
    return this.makeRequest(`${this.host}/channel/${channelId}/accounts/${currency}/transactions`, options);
  }

  putInternalAndChannelAndAccountsByChannelIdAndCurrency(channelId, currency, options = {}) {
    return this.makeRequest(`${this.host}/internal/channel/${channelId}/accounts/${currency}`, {
      ...options,
       method: 'PUT',
    });
  }

  deleteInternalAndChannelAndAccountsByChannelIdAndCurrency(channelId, currency, options = {}) {
    return this.makeRequest(`${this.host}/internal/channel/${channelId}/accounts/${currency}`, {
      ...options,
       method: 'DELETE',
    });
  }

  postInternalAndChannelAndAccountsAndTransactionsAndManualByChannelIdAndCurrency(channelId, currency, options = {}) {
    return this.makeRequest(`${this.host}/internal/channel/${channelId}/accounts/${currency}/transactions/manual`, {
      ...options,
       method: 'POST',
    });
  }

  deleteInternalAndChannelAndAccountsAndTransactionsAndManualByChannelIdAndCurrencyAndId(channelId, currency, id, options = {}) {
    return this.makeRequest(`${this.host}/internal/channel/${channelId}/accounts/${currency}/transactions/manual/${id}`, {
      ...options,
       method: 'DELETE',
    });
  }

}
