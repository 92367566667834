import {
  ShipmentIntegrationType, TierEstimateType, TierStrategy, Visibility,
} from '@flowio/api-constants';
import get from 'lodash/get';
import find from 'lodash/find';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';

import * as constants from '../constants';
import { fetchCurrencies as fetchOrganizationCurrencies } from '../../organization/actions';
import { fetchCurrencies, fetchRegions } from '../../reference/actions';
import { getCurrencies as getOrganizationCurrencies } from '../../organization/selectors';
import fetchSurchageSettings from './fetchSurchargeSettings';
import { getRatecards, getSurchargeSettings } from '../selectors';
import { getRegionCurrencies } from '../../reference/selectors';
import createDefaultTierRule from '../utilities/createDefaultTierRule';
import fetchRatecards from './fetchRatecards';
import updateDialog from './updateDialog';
import { ThunkResult } from '../../../stores/types';
import { FormTierRule, TierRuleOutcomeValue, TierRuleQueryValue } from '../types/components';

interface ShowTierWorksheetParams {
  method: constants.FormMethod;
  organizationId: string;
  shippingLane: io.flow.v0.models.ShippingLane;
  tier?: io.flow.v0.models.Tier;
  title: string;
}

function tierRuleQueryValuesFromTierRule(tierRule: io.flow.v0.models.TierRule): TierRuleQueryValue {
  if (tierRule.query === '*') {
    return { operator: 'any' };
  }

  const [operator, value] = tierRule.query.split(':');
  return { operator, value };
}

function tierRuleOutcomeValuesFromTierRule(
  tierRule: io.flow.v0.models.TierRule,
): TierRuleOutcomeValue {
  switch (tierRule.outcome.discriminator) {
    case constants.TierRuleOutcomeDiscriminatorValue.AMOUNT_MARGIN:
      return {
        type: constants.TierRuleOutcomeFormDiscriminatorValues.AMOUNT_MARGIN_FORM,
        value: tierRule.outcome.margin.amount,
      };
    case constants.TierRuleOutcomeDiscriminatorValue.FLAT_RATE:
      return {
        type: constants.TierRuleOutcomeFormDiscriminatorValues.FLAT_RATE_FORM,
        value: tierRule.outcome.price.amount,
        zeroAmountIndicator: tierRule.outcome.zero_amount_indicator,
      };
    case constants.TierRuleOutcomeDiscriminatorValue.PERCENT_MARGIN:
      return {
        type: constants.TierRuleOutcomeFormDiscriminatorValues.PERCENT_MARGIN,
        value: tierRule.outcome.percentage,
      };
    default:
      return {
        type: constants.TierRuleOutcomeFormDiscriminatorValues.AT_COST,
      };
  }
}

function tierRuleValuesFromTierRule(tierRule: io.flow.v0.models.TierRule): FormTierRule {
  return {
    id: tierRule.id,
    query: tierRuleQueryValuesFromTierRule(tierRule),
    outcome: tierRuleOutcomeValuesFromTierRule(tierRule),
  };
}

const getTierSurchargeSettings = (
  tierSurchargeSettings: io.flow.v0.models.SurchargeSetting[],
  surchargeSettingOptions: io.flow.v0.models.SurchargeSettingDisplay[],
) => reduce(
  tierSurchargeSettings,
  (surcharges, tierSurchargeSetting) => {
    const surchargeSetting = find(surchargeSettingOptions,
      (setting) => setting.key === tierSurchargeSetting.key);

    const surchargeOptionName = get(find(get(surchargeSetting, 'available', []), (option) => option.responsible_party === tierSurchargeSetting.responsible_party), 'name');

    return {
      ...surcharges,
      [tierSurchargeSetting.key]: {
        responsible_party: tierSurchargeSetting.responsible_party,
        name: surchargeOptionName,
      },
    };
  }, {},
);

const getDefaultSurchargeSettings = (
  surchargeSettingOptions: io.flow.v0.models.SurchargeSettingDisplay[],
) => reduce(
  surchargeSettingOptions,
  (surcharges, surchargeSettingOption) => {
    const surchargeSettingName = get(find(surchargeSettingOption.available, (option) => option.responsible_party === 'organization'), 'name');
    return {
      ...surcharges,
      [surchargeSettingOption.key]: {
        responsible_party: 'organization',
        name: surchargeSettingName,
      },
    };
  }, {},
);

function generateSurchargeSettings(
  surchargeSettingOptions: io.flow.v0.models.SurchargeSettingDisplay[],
  tierSurchargeSettings?: io.flow.v0.models.SurchargeSetting[],
) {
  if (tierSurchargeSettings) {
    return getTierSurchargeSettings(tierSurchargeSettings, surchargeSettingOptions);
  }
  return getDefaultSurchargeSettings(surchargeSettingOptions);
}

/**
 * An action responsible for loading the asynchronous shipping tier worksheet.
 * @param {FormMethod} props.method
 * @param {String} props.organizationId
 * @param {ShippingLane} props.shippingLane
 * @param {?Tier} props.tier
 * @param {String} props.title
 */
const showTierWorksheet = ({
  method,
  organizationId,
  shippingLane,
  tier,
  title,
}: ShowTierWorksheetParams): ThunkResult<Promise<void>> => (dispatch, getState) => {
  let tierRules: FormTierRule[] = [];
  let tierServices: string[] = [];

  if (tier) {
    if (tier.rules) {
      tierRules = map(tier.rules, (tierRule) => tierRuleValuesFromTierRule(tierRule));
    } else {
      tierRules = [createDefaultTierRule()];
    }

    if (tier.services) {
      tierServices = map(tier.services, 'id');
    }
  } else {
    tierRules = [createDefaultTierRule()];
  }

  return Promise.all([
    dispatch(fetchRegions()),
    dispatch(fetchCurrencies()),
    dispatch(fetchOrganizationCurrencies(organizationId)),
    dispatch(fetchSurchageSettings({
      organizationId,
    })),
    dispatch(fetchRatecards(organizationId)),
    dispatch(updateDialog(constants.DialogName.TIER_WORKSHEET, {
      loading: true,
      method,
      open: true,
      title,
    })),
  ]).then(() => {
    const organizationCurrencies = getOrganizationCurrencies(getState());
    const regionCurrencies = getRegionCurrencies(shippingLane.region)(getState());
    const surchargeSettings = generateSurchargeSettings(getSurchargeSettings(getState()), get(tier, 'surcharge_settings'));

    let currencies = [
      ...organizationCurrencies,
      ...regionCurrencies,
    ];

    currencies = sortBy(currencies, 'iso_4217_3');
    currencies = uniqBy(currencies, 'iso_4217_3');

    const initialCurrency = get(organizationCurrencies, '[0].iso_4217_3');

    const isBackup = get(tier, 'settings.availability', 'always') === 'backup';

    dispatch(updateDialog(constants.DialogName.TIER_WORKSHEET, {
      currencies,
      initialValues: {
        currency: get(tier, 'currency', initialCurrency),
        displayEstimateType: get(tier, 'display.estimate.type', TierEstimateType.CALCULATED),
        displayEstimateLabel: get(tier, 'display.estimate.label'),
        integration: get(tier, 'integration', ShipmentIntegrationType.INFORMATION),
        name: get(tier, 'name'),
        message: get(tier, 'message'),
        organizationId,
        rules: tierRules,
        services: tierServices,
        shippingConfigurationKey: shippingLane.shipping_configuration.key,
        shippingLaneId: shippingLane.id,
        strategy: get(tier, 'strategy', TierStrategy.FASTEST),
        tierId: get(tier, 'id'),
        visibility: get(tier, 'visibility', Visibility.PUBLIC),
        isBackup,
        surchargeSettings,
      },
      method,
      loading: false,
      open: true,
      ratecards: getRatecards(getState()),
      title,
    }));
  });
};

export default showTierWorksheet;
