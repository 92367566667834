import React from 'react';
import noop from 'lodash/noop';
import {
  Card, CardHeader, CardContent, CardDescription, CardTitle,
} from '@flowio/react-card';
import { css } from '@emotion/css';
import PriceCheckForm from './price-check-form';
import { ItemPriceDetails } from '../../types';

const priceCheckFormStyles = css`
  margin-top: 1rem;
`;

interface Props {
  priceCheck: ItemPriceDetails;
  onPriceCheck: Function;
  pricing: io.flow.v0.models.Pricing;
}

const PriceCheck: React.FC<Props> = ({
  pricing,
  priceCheck,
  onPriceCheck,
}) => (
  <Card id="price-check" className="price-check">
    <CardHeader dividing>
      <CardTitle content="Price Check" />
      <CardDescription content="Look up any product to view the price displayed to customers in this experience." />
    </CardHeader>
    <CardContent>
      <PriceCheckForm
        className={priceCheckFormStyles}
        pricing={pricing}
        priceCheck={priceCheck}
        onPriceCheck={onPriceCheck}
      />
    </CardContent>
  </Card>
);

PriceCheck.displayName = 'PriceCheck';

PriceCheck.defaultProps = {
  priceCheck: undefined,
  onPriceCheck: noop,
};

export default PriceCheck;
