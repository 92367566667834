enum ActionTypes {
  ADD_FEATURE_REQUEST = 'FEATURES.ADD_FEATURE_REQUEST',
  ADD_FEATURE_FAILURE = 'FEATURES.ADD_FEATURE_FAILURE',
  ADD_RULE_BY_KEY_REQUEST = 'FEATURES.ADD_RULE_BY_KEY_REQUEST',
  ADD_RULE_BY_KEY_SUCCESS = 'FEATURES.ADD_RULE_BY_KEY_SUCCESS',
  ADD_RULE_BY_KEY_FAILURE = 'FEATURES.ADD_RULE_BY_KEY_FAILURE',
  CHANGE_STATUS_REQUEST = 'FEATURES.CHANGE_STATUS_REQUEST',
  CHANGE_STATUS_SUCCESS = 'FEATURES.CHANGE_STATUS_SUCCESS',
  CHANGE_STATUS_FAILURE = 'FEATURES.CHANGE_STATUS_FAILURE',
  DELETE_FEATURE_BY_KEY_REQUEST = 'FEATURES.DELETE_FEATURE_BY_KEY_REQUEST',
  DELETE_FEATURE_BY_KEY_SUCCESS = 'FEATURES.DELETE_FEATURE_BY_KEY_SUCCESS',
  DELETE_FEATURE_BY_KEY_FAILURE = 'FEATURES.DELETE_FEATURE_BY_KEY_FAILURE',
  DELETE_RULE_BY_KEY_AND_ID_REQUEST = 'FEATURES.DELETE_RULE_BY_KEY_AND_ID_REQUEST',
  DELETE_RULE_BY_KEY_AND_ID_SUCCESS = 'FEATURES.DELETE_RULE_BY_KEY_AND_ID_SUCCESS',
  DELETE_RULE_BY_KEY_AND_ID_FAILURE = 'FEATURES.DELETE_RULE_BY_KEY_AND_ID_FAILURE',
  FETCH_FEATURES_REQUEST = 'FEATURES.FETCH_FEATURES_REQUEST',
  FETCH_FEATURES_SUCCESS = 'FEATURES.FETCH_FEATURES_SUCCESS',
  FETCH_FEATURES_FAILURE = 'FEATURES.FETCH_FEATURES_FAILURE',
  FETCH_FEATURE_BY_KEY_REQUEST = 'FEATURES.FETCH_FEATURE_BY_KEY_REQUEST',
  FETCH_FEATURE_BY_KEY_SUCCESS = 'FEATURES.FETCH_FEATURE_BY_KEY_SUCCESS',
  FETCH_FEATURE_BY_KEY_FAILURE = 'FEATURES.FETCH_FEATURE_BY_KEY_FAILURE',
  FETCH_QUERY_BUILDER_STATUS_FILTERS_REQUEST = 'FEATURES.FETCH_QUERY_BUILDER_STATUS_FILTERS_REQUEST',
  FETCH_QUERY_BUILDER_STATUS_FILTERS_SUCCESS = 'FEATURES.FETCH_QUERY_BUILDER_STATUS_FILTERS_SUCCESS',
  FETCH_QUERY_BUILDER_STATUS_FILTERS_FAILURE = 'FEATURES.FETCH_QUERY_BUILDER_STATUS_FILTERS_FAILURE',
  FETCH_RULE_QUERY_FILTER_REQUEST = 'FEATURES.FETCH_RULE_QUERY_FILTER_REQUEST',
  FETCH_RULE_QUERY_FILTER_SUCCESS = 'FEATURES.FETCH_RULE_QUERY_FILTER_SUCCESS',
  FETCH_RULE_QUERY_FILTER_FAILURE = 'FEATURES.FETCH_RULE_QUERY_FILTER_FAILURE',
  HIDE_DIALOG = 'FEATURES.HIDE_DIALOG',
  LOCATION_CHANGE_UPDATE_QUERY_REQUEST = 'FEATURES.LOCATION_CHANGE_UPDATE_QUERY_REQUEST',
  LOCATION_CHANGE_UPDATE_QUERY_SUCCESS = 'FEATURES.LOCATION_CHANGE_UPDATE_QUERY_SUCCESS',
  LOCATION_CHANGE_UPDATE_QUERY_FAILURE = 'FEATURES.LOCATION_CHANGE_UPDATE_QUERY_FAILURE',
  PUT_FEATURE_BY_KEY_REQUEST = 'FEATURES.PUT_FEATURE_BY_KEY_REQUEST',
  PUT_FEATURE_BY_KEY_SUCCESS = 'FEATURES.PUT_FEATURE_BY_KEY_SUCCESS',
  PUT_FEATURE_BY_KEY_FAILURE = 'FEATURES.PUT_FEATURE_BY_KEY_FAILURE',
  RELEASE_FEATURE_BY_KEY_REQUEST = 'FEATURES.RELEASE_FEATURE_BY_KEY_REQUEST',
  RELEASE_FEATURE_BY_KEY_SUCCESS = 'FEATURES.RELEASE_FEATURE_BY_KEY_SUCCESS',
  RELEASE_FEATURE_BY_KEY_FAILURE = 'FEATURES.RELEASE_FEATURE_BY_KEY_FAILURE',
  SHOW_DIALOG = 'FEATURES.SHOW_DIALOG',
  SHOW_ADD_RULE_WORKSHEET_REQUEST = 'FEATURES.SHOW_ADD_RULE_WORKSHEET_REQUEST',
  SHOW_ADD_RULE_WORKSHEET_SUCCESS = 'FEATURES.SHOW_ADD_RULE_WORKSHEET_SUCCESS',
  SHOW_ADD_RULE_WORKSHEET_FAILURE = 'FEATURES.SHOW_ADD_RULE_WORKSHEET_FAILURE',
  SHOW_EDIT_RULE_WORKSHEET_REQUEST = 'FEATURES.SHOW_EDIT_RULE_WORKSHEET_REQUEST',
  SHOW_EDIT_RULE_WORKSHEET_SUCCESS = 'FEATURES.SHOW_EDIT_RULE_WORKSHEET_SUCCESS',
  SHOW_EDIT_RULE_WORKSHEET_FAILURE = 'FEATURES.SHOW_EDIT_RULE_WORKSHEET_FAILURE',
  UPDATE_RULE_BY_KEY_AND_ID_REQUEST = 'FEATURES.UPDATE_RULE_BY_KEY_AND_ID_REQUEST',
  UPDATE_RULE_BY_KEY_AND_ID_SUCCESS = 'FEATURES.UPDATE_RULE_BY_KEY_AND_ID_SUCCESS',
  UPDATE_RULE_BY_KEY_AND_ID_FAILURE = 'FEATURES.UPDATE_RULE_BY_KEY_AND_ID_FAILURE',
  CREATE_QUERY_BUILDER_FILTER_FORM_REQUEST = 'FEATURES.CREATE_QUERY_BUILDER_FILTER_FORM_REQUEST',
  CREATE_QUERY_BUILDER_FILTER_FORM_SUCCESS = 'FEATURES.CREATE_QUERY_BUILDER_FILTER_FORM_SUCCESS',
  CREATE_QUERY_BUILDER_FILTER_FORM_FAILURE = 'FEATURES.CREATE_QUERY_BUILDER_FILTER_FORM_FAILURE',
  SUBMIT_FEATURE_RULE_FORM_REQUEST = 'FEATURES.SUBMIT_FEATURE_RULE_FORM_REQUEST',
  SUBMIT_FEATURE_RULE_FORM_SUCCESS = 'FEATURES.SUBMIT_FEATURE_RULE_FORM_SUCCESS',
  SUBMIT_FEATURE_RULE_FORM_FAILURE = 'FEATURES.SUBMIT_FEATURE_RULE_FORM_FAILURE',
}

export default ActionTypes;
