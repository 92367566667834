import { css } from '@emotion/css';

export const itemMarginSummary = css`
  display: flex;
  margin-top: 1rem;
`;

export const position = css`
  display: none;
  color: #17181a;
  margin-right: 1rem;
`;

export const positionEditable = css`
  display: inline-block !important; // esle display none overrides
`;

export const summary = css`
  flex: 2 0;
`;

export const itemChip = css`
  margin-right: 4px;
`;

export const description = css`
  margin-top: 0px;
`;

export const fixed = css`
  margin-bottom: 4px;
`;

export const name = css`
  color: #0051b2;
  font-size: 1.1rem;
  margin-top: 0;
  margin-bottom: 8px;
`;

export const highlight = css`
  color: #17181a;
`;

export const itemMarginCreate = css`
  padding-top: 1rem;
`;

export const itemMarginEdit = css`
  padding-top: 1rem;
`;
