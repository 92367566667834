import get from 'lodash/get';
import update from 'immutability-helper';
import ActionTypes from '../constants/action-types';
import { type RootActionTypes } from '../../../stores/types';
import { type WithPagingAndSortingOptions } from '../../../utilities/with-paging-and-sorting-v2';
import type { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';

export interface OrderDetailState {
  current: io.flow.internal.v0.models.OrderDetail;
  currentOriginal: Partial<io.flow.v0.models.Order>;
  results: io.flow.internal.v0.models.OrderDetail[];
  allocations: Partial<io.flow.v0.models.AllocationV2>;
  params: Partial<WithPagingAndSortingOptions>;
  isFirstPage: boolean;
  isLastPage: boolean;
  shippingLabels: Record<string, io.flow.v0.models.ShippingLabel>;
  shippingLabelsErrors: FormattedErrorMessages;
  addressValidation: io.flow.internal.v0.models.OrderValidation | null;
  addressValidationUser: io.flow.v0.models.User | null;
}

const initialState: OrderDetailState = {
  current: {
    notes: [],
    order: {
      discriminator: 'order',
      id: '',
      number: '',
      destination: {},
      customer: {
        name: {},
      },
      delivered_duty: 'paid',
      expires_at: '',
      items: [],
      deliveries: [],
      selections: [],
      attributes: {},
      total: {
        amount: 0,
        base: {
          amount: 0,
          currency: '',
          label: '',
        },
        currency: '',
        key: 'localized_total',
        label: '',
      },
      prices: [],
    },
    trackings: [],
    transactions: [],
    status: 'open',
    returns: [],
    created_at: '',
    updated_at: '',
  },
  addressValidation: null,
  addressValidationUser: null,
  currentOriginal: {},
  results: [],
  allocations: {},
  isFirstPage: true,
  isLastPage: false,
  params: {},
  shippingLabels: {},
  shippingLabelsErrors: {},
};

export default function orderDetailReducer(
  state = initialState,
  action: RootActionTypes,
): OrderDetailState {
  switch (action.type) {
    case ActionTypes.FETCH_ORDER_DETAILS_FAILURE:
      return update(state, {
        results: { $set: [] },
      });
    case ActionTypes.UPDATE_SHIPPING_ADDRESS_SUCCESS:
      return update(state, { current: { order: { $set: action.payload } } });
    case ActionTypes.FETCH_ORDER_DETAIL_SUCCESS:
      return { ...state, current: action.payload };
    case ActionTypes.FETCH_ORDER_VERSIONS_SUCCESS:
      return update(state, {
        currentOriginal: {
          $set: get(action, 'payload[0].order'),
        },
      });
    case ActionTypes.FETCH_ORDER_DETAILS_SUCCESS:
      return { ...state, ...action.payload, params: action.options };
    case ActionTypes.FETCH_ALLOCATIONS_REQUEST:
    case ActionTypes.FETCH_ALLOCATIONS_FAILURE:
      return update(state, {
        allocations: { $set: {} },
      });
    case ActionTypes.FETCH_ALLOCATIONS_SUCCESS:
      return update(state, {
        allocations: { $set: action.payload },
      });
    case 'FETCH_SHIPPING_LABEL_REQUEST':
      return update(state, {
        shippingLabels: {
          $unset: [action.params.labelId],
        },
      });
    case 'FETCH_SHIPPING_LABEL_SUCCESS':
      return update(state, {
        shippingLabels: {
          [action.payload.id]: { $set: action.payload },
        },
        shippingLabelsErrors: { $set: {} },
      });
    case 'FETCH_SHIPPING_LABEL_FAILURE':
      return update(state, {
        shippingLabelsErrors: { $set: action.errors },
      });

    case 'FETCH_ORDER_ADDRESS_VALIDATION':
      return update(state, {
        addressValidation: { $set: action.payload },
      });
    case 'FETCH_ADDRESS_VALIDATION_USER_REQUEST':
      return update(state, {
        addressValidationUser: { $set: null },
      });
    case 'FETCH_ADDRESS_VALIDATION_USER_SUCCESS':
      return update(state, {
        addressValidationUser: { $set: action.payload },
      });
    default:
      return state;
  }
}
