import React from 'react';

import { PageHeader, PageHeaderGroup } from '../../console/components/page-header';
import { PageNavigation, PageNavigationItem } from '../../console/components/page-navigation';

interface Props {
  experience: string;
  organization: string;
}

const ExperienceNavigation: React.FC<Props> = ({ organization, experience }) => (
  <PageHeader>
    <PageHeaderGroup>
      <PageNavigation>
        <PageNavigationItem label="Localization" href={`/${organization}/experience/${experience}/localization`} />
        <PageNavigationItem label="Tax and Duty" href={`/${organization}/experience/${experience}/landed-cost`} />
        <PageNavigationItem label="Pricing" href={`/${organization}/experience/${experience}/pricing`} />
        <PageNavigationItem label="Payment Methods" href={`/${organization}/experience/${experience}/payment-methods`} />
        <PageNavigationItem label="Logistics" href={`/${organization}/experience/${experience}/logistics/v2`} />
      </PageNavigation>
    </PageHeaderGroup>
  </PageHeader>
);

ExperienceNavigation.displayName = 'ExperienceNavigation';

export default ExperienceNavigation;
