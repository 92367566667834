import { createSelector } from 'reselect';
import identity from 'lodash/identity';
import getDialogProp from './getDialogProp';
import { RootState } from '../../../stores/types';

const getIsDialogOpen = (dialog: string) => createSelector<RootState, RootState, boolean>(
  identity,
  getDialogProp(dialog, 'isOpen'),
);

export default getIsDialogOpen;
