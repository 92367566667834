import { css } from '@emotion/css';

export const orderCheck = css`

  & .content-area-sub-title {
    margin-bottom: 1rem;
  }
`;

export const expCountry = css`
  display: flex;
`;

export const fieldLabel = css`
  display: block;
  font-weight: 500;
  margin: .5rem 0;
`;

export const centerField = css`
  margin-left: 3rem;
`;

export const priceName = css`
  display: inline-block;
  width: 50%;
`;
export const priceLabel = css`
  display: inline-block;
  margin-left: 1rem;
`;

export const priceNameTotal = css`
  font-weight: bold;
`;
export const priceLabelTotal = css`
  font-weight: bold;
`;

export const summary = css`
  border-top: 1px solid var(--concrete);
  display: flex;
  font-size: .875rem;
  justify-content: flex-end;
  margin-top: 2rem;
  padding-top: 2rem;
`;

export const deliveries = css`
  flex: auto;
`;

export const prices = css`
  width: 360px;
`;

export const orderPrice = css`
  display: flex;
  flex-wrap: wrap;
`;

export const delivery = css`
  display: flex;
`;

export const deliveryOption = css`
  border: 1px solid var(--silver);
  border-radius: 4px;
  cursor: pointer;
  margin-left: 1rem;
  padding: 1rem;

  &:hover {
    background-color: var(--mist);
  }

  &:first-child {
    margin-left: 0;
  }

  & p {
    margin-top: 0;
    margin-bottom: .5rem;
  }
`;

export const deliveryOptionSelected = css`
  background: var(--concrete);
`;

export const lineNumber = css`
  width: 70px;
`;

export const lineItemQuantity = css`
  max-width: 30px;
`;

export const lineItemQuantityInput = css`
  width: 100%;
`;

export const lineQuantity = css`
  width: 100px;
`;

export const lineConvertedPrice = css`
  width: 110px;
`;

export const lineTotal = css`
  width: 100px;
`;

export const lineItemName = css`
  line-height: 1.5rem;
`;

export const subtitle = css`
  font-size: 1.1rem;
  font-weight: 400;
`;

export const experienceSelect = css`
  max-height: '300px'
`;
