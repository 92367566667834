import { compose } from 'redux';
import { FormErrors, reduxForm, SubmissionError } from 'redux-form';
import get from 'lodash/get';

import {
  fetchFeatures,
  hideAddFeatureWorksheet,
  putFeatureByKey,
} from '../actions';
import createToast from '../../console/actions/createToast';
import AddFeatureWorksheetForm from '../components/AddFeatureWorksheetForm';
import FormName from '../constants/FormName';
import uniqueFeatureKeyValidation from '../utilities/uniqueFeatureKeyValidation';
import { AddFeatureWorksheetFormOwnProps, AddFeatureWorksheetValues } from '../types/components';
import { ThunkDispatcher } from '../../../stores/types';

function handleValidation(
  values: AddFeatureWorksheetValues,
): FormErrors<AddFeatureWorksheetValues> {
  const errors: FormErrors<AddFeatureWorksheetValues> = {};

  if (!values.organizationId) {
    errors.organizationId = 'An organization identifier is required';
  }

  if (!values.key) {
    errors.key = 'A key is required';
  }

  return errors;
}

function handleSumbit(values: AddFeatureWorksheetValues, dispatch: ThunkDispatcher) {
  const { organizationId, key } = values;
  const keyValidation = uniqueFeatureKeyValidation(values, dispatch);

  return keyValidation.then(() => dispatch(putFeatureByKey(organizationId, key, {
    featureForm: {
      name: values.name,
      rules: values.rules,
      type: values.type,
      description: values.description,
    },
  })).then((response) => {
    if (!response.ok) {
      const errorMessages = get(response, 'body.messages', []);
      throw new SubmissionError({
        _error: {
          ...response.body,
          messages: errorMessages,
        },
      });
    }
    return response;
  }));
}

function handleSumbitSuccess(
  _: any,
  dispatch: ThunkDispatcher,
  props: any,
) {
  dispatch(hideAddFeatureWorksheet());
  dispatch(createToast({
    content: `Feature \`${props.values.name}\` has been created.`,
    icon: 'CheckCircle',
    intent: 'positive',
  }));
  dispatch(fetchFeatures(props.values.organizationId));
}

export default compose<React.FC<AddFeatureWorksheetFormOwnProps>>(
  reduxForm({
    form: FormName.ADD_FEATURE_FORM,
    onSubmit: handleSumbit,
    validate: handleValidation,
    onSubmitSuccess: handleSumbitSuccess,
  }),
)(AddFeatureWorksheetForm);
