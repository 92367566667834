/**
 * @fileoverview
 * Reduces the currently active organization into the application state.
 */

import update from 'immutability-helper';
import { RootActionTypes } from '../../../stores/types';
import ActionTypes from '../constants/action-types';
import { CurrentOrgState } from '../types';

const defaultState: CurrentOrgState = {
  discriminator: 'organization',
  id: '',
  name: '',
  environment: 'sandbox',
};

export default function currentOrganizationReducer(
  state = defaultState,
  action: RootActionTypes,
): CurrentOrgState {
  switch (action.type) {
    case ActionTypes.FETCH_ORGANIZATION_SUCCESS:
      return update(state, { $set: action.payload });
    default:
      return state;
  }
}
