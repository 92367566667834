import React from 'react';
import classNames from 'classnames';
import handleEnterKeyboardEvent from '../../../../utilities/enterKeyPressHandler';
import { OrganizationCardProps } from './types';
import * as styles from './organization.styles';

export default class OrganizationCard extends React.Component<OrganizationCardProps> {
  static displayName = 'OrganizationCard';

  handleClick = (event: any) => {
    const { onClick, organization } = this.props;
    if (onClick) {
      onClick(event, organization);
    }
  };

  render() {
    const {
      className,
      onClick, // eslint-disable-line no-unused-vars
      organization,
      ...otherProps
    } = this.props;

    return (
      <div
        {...otherProps}
        className={classNames(styles.organizationCard, className)}
        onClick={this.handleClick}
        onKeyPress={handleEnterKeyboardEvent(this.handleClick)}
        role="button"
        tabIndex={0}
      >
        <div className={styles.name}>
          {organization.name}
        </div>
        {organization.environment === 'sandbox' && (
          <div className={classNames(styles.environment, 'environment')}>
            {organization.environment}
          </div>
        )}
      </div>
    );
  }
}
