import { browserHistory } from 'react-router';
import {
  RootState,
  ThunkResult,
  ThunkDispatcher,
} from '../../../stores/types';
import { getOrganizationId } from '../../organization';

export default function goToExperiment(
  experimentKey: string,
): ThunkResult<void> {
  return (
    _dispatch: ThunkDispatcher,
    getState: () => RootState,
  ): void => {
    const organization: string = getOrganizationId(getState());
    return browserHistory.push(`/${organization}/experiments/${experimentKey}`);
  };
}
