import { SubmissionError } from 'redux-form';
import { fetchFeatureByKey } from '../actions';
import isResponseOk from '../../../utilities/isResponseOk';
import { AddFeatureWorksheetValues } from '../types/components';
import { ThunkDispatcher } from '../../../stores/types';

export default function uniqueFeatureKeyValidation(
  values: AddFeatureWorksheetValues,
  dispatch: ThunkDispatcher,
) {
  const { organizationId, key } = values;
  return dispatch(fetchFeatureByKey(organizationId, key)).then((response) => {
    if (isResponseOk(response)) {
      throw new SubmissionError({
        key: 'This feature key has already been used. Feature keys must be unique',
      });
    }
  });
}
