import classNames from 'classnames';
import React, { ElementType, FunctionComponent } from 'react';
import * as styles from './step.styles';

export interface StepTitleProps {
  as?: ElementType;
  text?: string;
  children?: React.ReactNode;
  className?: string;
  to?: string;
}

const StepTitle: FunctionComponent<StepTitleProps> = ({
  as: Element = 'span',
  text,
  children,
  className = '',
  ...unhandledProps
}) => (
  <Element {...unhandledProps} className={classNames(className, styles.stepTitle)}>
    {children || text}
  </Element>
);

StepTitle.displayName = 'StepTitle';

export default StepTitle;
