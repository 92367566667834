import { createSelector } from 'reselect';
import get from 'lodash/get';
import getReturnPolicyWorksheet from './getReturnPolicyWorksheet';
import { RootState } from '../../../stores/types';
import { ReturnPolicyStatus, ReturnPolicyWorksheetState } from '../types';

/**
 * Returns the async status of the worksheet. Every time a worksheet is rendered
 * a query builder is created from the provided return policy.
 * @returns {ReadyState}
 */
const getStatusForReturnPolicyWorksheet = createSelector<
RootState, ReturnPolicyWorksheetState, ReturnPolicyStatus>(
  getReturnPolicyWorksheet,
  (worksheet) => get(worksheet, 'status'),
);

export default getStatusForReturnPolicyWorksheet;
