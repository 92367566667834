import { createSelector } from 'reselect';
import property from 'lodash/property';

import getLogisticsState from './getLogisticsState';
import { RootState } from '../../../stores/types';
import { LogisticsQueries, LogisticsRootState } from '../types/state';

export default createSelector<RootState, LogisticsRootState, LogisticsQueries>(
  getLogisticsState,
  property('queries'),
);
