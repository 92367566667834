import { css } from '@emotion/css';

export const descriptionGroup = css`
  display: flex;
  flex-flow: row nowrap;
`;

export const description = css`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  width: 50%;
  flex-flow: row wrap;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
`;

export const term = css`
  flex: 1 0 25%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  padding: 0.5rem 0.5rem 0.5rem 0;
  box-sizing: border-box;
  color: #77818f;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 16px;
`;

export const definition = css`
  flex: 1 0 75%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  box-sizing: border-box;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 16px;
  hyphens: auto;
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
`;
