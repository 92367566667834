import { Field } from 'redux-form';
import React from 'react';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import map from 'lodash/map';
import curry from 'lodash/curry';
import first from 'lodash/first';
import capitalize from 'lodash/capitalize';

import { KeyCodes } from '@flowio/browser-helpers';

import { RuleConditionValuesProps } from '../../types/components';
import {
  TextField, TagInput, MultiSelectField, SelectField,
} from '../../../../components/ReduxFormFieldAdapters';

interface ValueType {
  content: string;
  value: string;
}

const formatValue = curry((
  multiple: boolean,
  validValues: string[],
  value?: string,
): ValueType | string => {
  let formatted: string | ValueType | undefined = value;
  if (value && !multiple) {
    if (validValues && validValues.length) {
      formatted = { content: capitalize(formatted), value: first(formatted) || '' };
    } else {
      formatted = first(value);
    }
  }
  if (formatted == null) formatted = '';
  return formatted;
});

const parseValue = curry((multiple: boolean, value: ValueType | string) => {
  if (value && !multiple) {
    if (isObject(value)) { // We have a Dropdown component value
      return [value.value];
    }
    return [value]; // Value is presumed to be a primitive type: string, number, etc
  }
  return value;
});

const RuleConditionValues: React.FC<RuleConditionValuesProps> = ({
  availableFilter,
  multiple,
  name,
}) => {
  const validValues = get(availableFilter, 'valid_values');
  const validValuesDropDownOptions = map(validValues, (value) => ({
    content: capitalize(value),
    value,
  }));

  if (validValues) {
    if (multiple) {
      return (
        <Field
          component={MultiSelectField}
          multiple
          selection
          addKeyCodes={[KeyCodes.Enter, KeyCodes.Comma]}
          addOnBlur
          hintText={availableFilter.placeholder}
          name={name}
          options={validValuesDropDownOptions}
          format={formatValue(multiple, validValues)}
          parse={parseValue(multiple)}
        />
      );
    }

    return (
      <Field
        component={SelectField}
        selection
        hintText={availableFilter.placeholder}
        name={name}
        options={validValuesDropDownOptions}
        format={formatValue(multiple, validValues)}
        parse={parseValue(multiple)}
      />
    );
  }

  if (multiple) {
    return (
      <Field
        component={TagInput}
        addKeyCodes={[KeyCodes.Enter, KeyCodes.Comma]}
        addOnBlur
        hintText={availableFilter.placeholder}
        name={name}
        format={formatValue(multiple, validValues || [])}
        parse={parseValue(multiple)}
        fluid
      />
    );
  }

  return (
    <Field
      component={TextField}
      hintText={availableFilter.placeholder}
      name={name}
      format={formatValue(multiple, validValues || [])}
      parse={parseValue(multiple)}
    />
  );
};

RuleConditionValues.displayName = 'RuleConditionValues';

export default RuleConditionValues;
