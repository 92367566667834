import { createSelector } from 'reselect';
import property from 'lodash/property';
import getMarketingState from './getMarketingState';
import { MarketingState } from '../types';
import { RootState } from '../../../stores/types';

export default createSelector<
RootState, MarketingState, io.flow.internal.v0.enums.MarketingGatewayAccountConnectionStatus
>(
  getMarketingState,
  property(['googleConnectionAccounts', 'status']),
);
