import React, { MouseEventHandler } from 'react';
import {
  Dialog, DialogHeader, DialogFooter, DialogActions, DialogBody,
} from '@flowio/react-dialog';
import { MenuDivider } from '@flowio/react-menu';
import { Button } from '@flowio/react-button';
import noop from 'lodash/noop';

interface Props {
  open: boolean;
  onRequestClose: MouseEventHandler;
}

const SearchFunctionDialog: React.FC<Props> = ({
  open: isOpen,
  onRequestClose,
}) => {
  const renderDialogHeader = (): JSX.Element => {
    const containerStyle: React.CSSProperties = {
      paddingBottom: '1rem',
      borderBottom: '1px solid rgb(224, 224, 224)',
    };

    const titleStyle: React.CSSProperties = {
      margin: 0,
      fontSize: '1.125rem',
      fontWeight: 'normal',
    };

    const taglineStyle: React.CSSProperties = {
      margin: 0,
      color: '#4A4A4A',
      fontSize: '0.875rem',
      fontWeight: 'normal',
      lineHeight: '1',
    };

    return (
      <div style={containerStyle}>
        <h1 style={titleStyle}>Powerful Search Functions</h1>
        <p style={taglineStyle}>Search faster with these tips.</p>
      </div>
    );
  };

  return (
    <Dialog open={isOpen} backdrop>
      <DialogHeader>
        {renderDialogHeader()}
      </DialogHeader>
      <DialogBody style={{ fontSize: '0.875rem', color: '#4A4A4A' }}>
        <h2 className="h5">Use Your Keywords</h2>
        <p>
          Enter a keyword followed by a &#39;:&#39; for any
          field contained in your product catalog.
          Example,
          {' '}
          <span className="bold">Experience:Canada</span>
          {' '}
          will show you all the products
          contained in the Canadian Experience.
        </p>
        <h2 className="h5">Popular Keywords</h2>
        <ul>
          <li className="bold">Category:Jeans</li>
          <li className="bold">Experiences:Canada</li>
          <li className="bold">Brand:Theory</li>
          <li className="bold">ID:123456</li>
        </ul>
        <MenuDivider />
        <h2 className="h5">Create a Quick Search</h2>
        <p>
          Click any experience name or category from a product row and we&rsquo;ll create search
          automatically. Example, clicking Dresses under Category
          header will give you
          {' '}
          <span className="bold">Category:Dresses</span>
        </p>
      </DialogBody>
      <DialogFooter>
        <DialogActions>
          <Button
            intent="primary"
            content="Ok"
            onClick={onRequestClose}
          />
        </DialogActions>
      </DialogFooter>
    </Dialog>
  );
};

SearchFunctionDialog.displayName = 'SearchFunctionDialog';

SearchFunctionDialog.defaultProps = {
  open: false,
  onRequestClose: noop,
};

export default SearchFunctionDialog;
