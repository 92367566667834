import map from 'lodash/map';
import update from 'immutability-helper';

import { ActionTypes } from '../constants';
import { ExperiencesByShippingConfigurationState } from '../types/state';
import { RootActionTypes } from '../../../stores/types';

const initialState: ExperiencesByShippingConfigurationState = {};

export default function (state = initialState, action: RootActionTypes) {
  switch (action.type) {
    case ActionTypes.FETCH_EXPERIENCES_REQUEST:
      return update(state, {
        [action.meta.shippingConfigurationKey]: {
          $set: {
            status: 'loading',
            entities: [],
          },
        },
      });
    case ActionTypes.FETCH_EXPERIENCES_SUCCESS:
      return update(state, {
        [action.meta.shippingConfigurationKey]: {
          $set: {
            status: 'fulfilled',
            entities: map(action.payload, 'id'),
          },
        },
      });
    case ActionTypes.FETCH_EXPERIENCES_FAILURE:
      return update(state, {
        [action.meta.shippingConfigurationKey]: {
          $set: {
            status: 'rejected',
            entities: [],
          },
        },
      });
    case ActionTypes.INVALIDATE_SHIPPING_CONFIGURATION_EXPERIENCES:
      return update(state, {
        [action.payload]: {
          $set: {
            status: 'invalidated',
            entities: [],
          },
        },
      });
    default:
      return state;
  }
}
