enum ActivityKind {
  API_ERROR = 'api_error',
  ALREADY_SCANNED = 'already_scanned',
  CLEAR_ITEMS = 'clear_items',
  SELECT_ALL_ITEMS = 'select_all_items',
  CLEAR_ORDER = 'clear_order',
  CHANGE_QUANTITY = 'change_quantity',
  LABEL_NOT_PRINTED = 'label_not_printed',
  PRINT_ERROR_REPORT = 'print_error_report',
  PRINT_LABEL = 'print_label',
  PRINT_INVOICE = 'print_invoice',
  SCAN_ORDER = 'scan_order',
  SCAN_ITEM = 'scan_item',
  UNSCAN_ITEM = 'unscan_item',
  UNIDENTIFIED_ITEM = 'unidentified_item',
  UNIDENTIFIED_NUMBER = 'unidentified_number',
  UNIDENTIFIED_ACTIVITY = 'unidentified_activity',
}

export default ActivityKind;
