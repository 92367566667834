import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { CheckCircle, Trophy } from '@flowio/react-icons';
import { animateRemoveElement, bounceUpDown, fadeOut } from '../../../../../styles/animations/animations';
import { colors, radius, spacing } from '../../../../../theme/tokens';
import CountryFlag from '../../../../reference/components/CountryFlag';

const bounce = keyframes`${bounceUpDown}`;
const shrinkElement = keyframes`${animateRemoveElement(60)}`;
const bounceAnimation = keyframes`${fadeOut}`;

const combinedAnimations = css`${bounceAnimation}, ${shrinkElement}`;
const WrappedAnimation = styled.div<{ isPrimaryAnimation: boolean }>`
  animation:  ${(props) => (props.isPrimaryAnimation ? combinedAnimations : bounce)};
  animation-delay: ${(props) => (props.isPrimaryAnimation ? '1s, 1s' : '1.2s')};
  animation-fill-mode: forwards;
  animation-duration: ${(props) => (props.isPrimaryAnimation ? '0.7s, 1.2s' : '1.2s')};
`;

const CountryWrapper = styled.div`
  height: 50px;
  background: white;
  display: flex;
  align-items: center;
  padding: 0 ${spacing.l};
  border-radius: ${radius.small};
  width: 100%;
  min-width: 210px;

  span {
    color: ${colors.marketing['dark-slate-blue']};
    font-weight: 500;
    font-size: 14px;
  }

  img {
    margin-right: 10px
  }
`;

const StyledWrapper = styled.div`
   margin: 5px 0px;
   display: flex;
   align-items: center;
   width: 100%;
`;

const IconWrapper = styled.div<{ shouldHighlightBaseline: boolean }>`
  svg {
    fill:  ${(props) => (props.shouldHighlightBaseline ? colors.yellow[300] : colors.green[300])};
    margin-right: 10px;
    height: 20px;
  }
`;

const EmptyIcon = styled.div`
  width: 16px;
  margin-right: 10px;
  height: 20px;
`;

type Props = {
  isWinningVariant: boolean;
  isBaselineVariant: boolean;
  country?: io.flow.v0.models.Country;
  name?: string;
};

const VariantCountry: React.FC<Props> = ({
  isWinningVariant, name, country, isBaselineVariant,
}) => {
  const renderIcon = () => {
    if (isWinningVariant) {
      return <Trophy width={16} />;
    } if (isBaselineVariant) {
      return <CheckCircle width={16} />;
    }
    return <EmptyIcon>{' '}</EmptyIcon>;
  };

  const shouldHighlight = () => {
    if (isWinningVariant && isBaselineVariant) {
      return false;
    } if (isBaselineVariant) {
      return true;
    }
    return false;
  };

  return (
    <WrappedAnimation isPrimaryAnimation={!isWinningVariant}>
      <StyledWrapper>
        <IconWrapper shouldHighlightBaseline={shouldHighlight()}>
          {renderIcon()}
        </IconWrapper>
        <CountryWrapper>

          {country && <CountryFlag country={country} />}
          <span>{name}</span>
        </CountryWrapper>
      </StyledWrapper>
    </WrappedAnimation>
  );
};

export default VariantCountry;
