import { Plus } from '@flowio/react-icons';
/* eslint-disable react/forbid-prop-types */

import { WrappedFieldArrayProps } from 'redux-form';
import { Box } from '@flowio/react-box';
import { Button } from '@flowio/react-button';
import {
  Card, CardHeader, CardContent, CardFooter, CardTitle,
} from '@flowio/react-card';
import FlipMove from 'react-flip-move';
import React, { Component } from 'react';
import uniqueId from 'lodash/uniqueId';

import QueryFilter from '../../../query-builder/containers/QueryFilter';
import { QueryFilterListOwnProps } from '../../types';

type QueryFilterListProps = QueryFilterListOwnProps & WrappedFieldArrayProps;

class QueryFilterList extends Component<QueryFilterListProps> {
  static displayName = 'QueryFilterList';

  constructor(props: QueryFilterListProps, context: any) {
    super(props, context);
    this.handleAddRule = this.handleAddRule.bind(this);
  }

  handleAddRule() {
    const { availableFilters, fields } = this.props;

    if (availableFilters.length) {
      fields.unshift({
        field: availableFilters[0].field,
        operator: availableFilters[0].operators[0],
        values: [],
        // Add a unique identifier that we can use as the component key for
        // FLIP animation to work correctly.
        // https://github.com/erikras/redux-form/issues/2735
        uniqueId: uniqueId('filter'),
      });
    }
  }

  render() {
    const {
      availableFilters,
      fields,
      meta: {
        error,
        submitFailed,
      },
    } = this.props;

    return (
      <Card>
        <CardHeader dividing>
          <Box alignItems="center" justifyContent="between">
            <CardTitle>
              Item Exclusion Rules
            </CardTitle>
            <Button
              content="Add a Rule"
              intent="primary"
              leftIcon={Plus}
              onClick={this.handleAddRule}
              variant="outline"
              type="button"
            />
          </Box>
        </CardHeader>
        <CardContent subdued>
          <p>
            Items matching all the followings rules will be excluded.
          </p>
          <FlipMove duration={300} easing="ease" staggerDelayBy={20} staggerDurationBy={15}>
            {fields.map((name, index) => (
              <QueryFilter
                availableFilters={availableFilters}
                key={fields.get(index).uniqueId}
                name={name}
                onRequestRemove={() => fields.remove(index)}
              />
            ))}
          </FlipMove>
          {submitFailed && (
            <span>
              {error}
            </span>
          )}
        </CardContent>
        <CardFooter dividing>
          Once saved, it will take a while to update the exclusion throughout the product catalog.
          After the exclusion is updated, you will be able to view the full list of items excluded.
        </CardFooter>
      </Card>
    );
  }
}

export default QueryFilterList;
