import { AnyAction } from 'redux';
import { RootActionTypes } from '../stores/types';

export default function standardAction(type: RootActionTypes['type'], payload?: AnyAction['payload']): AnyAction {
  if (payload) {
    return {
      type,
      payload,
    };
  }

  return {
    type,
  };
}
