import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Confirm } from '@flowio/react-confirm';
import { deleteOrderNote } from '../../actions/orderNotes';
import { ThunkDispatcher } from '../../../../stores/types';

interface OrderNoteDeleteConfirmationProps {
  note?: io.flow.internal.v0.models.OrderNote;
  organizationId: string;
  orderNumber: string;
  onClose: () => void;
}

const OrderNoteDeleteConfirmation: React.FC<OrderNoteDeleteConfirmationProps> = ({
  note,
  organizationId,
  orderNumber,
  onClose,
}) => {
  const dispatch = useDispatch<ThunkDispatcher>();
  const [showError, setShowError] = useState<boolean>(false);

  const errorMessage = `An error has occurred while trying to delete the message.
    Please try again. Contact <a href="mailto:help@flow.io">help@flow.io</a> if this issue persists.`;

  const handleClose = () => {
    setShowError(false);
    onClose();
  };

  const handleOk = () => {
    setShowError(false);

    dispatch(deleteOrderNote(
      organizationId,
      orderNumber,
      note!.id,
    )).then(() => handleClose())
      .catch(() => setShowError(true));
  };

  return (
    <Confirm
      open={note !== undefined}
      okText="Delete"
      intent="negative"
      caption="Are you sure you want to delete the following message?"
      onCancel={handleClose}
      onOk={handleOk}
    >
      <p>
        { note?.note }
      </p>
      { showError && (
        // eslint-disable-next-line react/no-danger
        <p dangerouslySetInnerHTML={{ __html: errorMessage }} />
      ) }
    </Confirm>
  );
};

export default OrderNoteDeleteConfirmation;
