import Auth from './auth';
import Suggestions from './suggestions';
import Imports from './imports';
import GraphqlClient from './graphql-client';
import ShopifyClient from './shopify';
import Facebook from './facebook';

export default class ServerClient {
  auth: Auth;

  suggestions: Suggestions;

  facebook: Facebook;

  imports: Imports;

  graphqlClient: GraphqlClient;

  headers: Record<string, string> | undefined;

  shopify: ShopifyClient;

  constructor(host: string, auth: string, headers: Record<string, string>) {
    this.auth = new Auth(host, auth, headers);
    this.facebook = new Facebook(host, auth, headers);
    this.suggestions = new Suggestions(host, auth, headers);
    this.imports = new Imports(host, auth, headers);
    this.graphqlClient = new GraphqlClient(host, auth, headers);
    this.shopify = new ShopifyClient(host, auth, headers);
  }
}
