import { createSelector } from 'reselect';
import get from 'lodash/get';
import getReturnPolicysForm from './getReturnPolicyForm';
import { RootState } from '../../../stores/types';
import { ReturnPolicyFormState } from '../types';

/**
 * Returns identifier of the return policy to be updated by the return policy worksheet.
 * @returns {String}
 */
const getReturnPolicyIdForReturnPolicyWorksheet = createSelector<
RootState, ReturnPolicyFormState, io.flow.v0.models.GenericError | undefined>(
  getReturnPolicysForm,
  (returnPolicyForm) => get(returnPolicyForm, 'submissionError'),
);

export default getReturnPolicyIdForReturnPolicyWorksheet;
