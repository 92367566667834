import MarketingRoot from './components/MarketingRoot';
import AddChannels from './components/AddChannels';
import AddFeeds from './components/AddFeeds/containers/AddFeeds';
import AddFacebookCountryFeeds from './components/AddFacebookCountryFeeds';
import SelectGoogleAccount from './components/SelectGoogleAccount';
import SelectFacebookCatalog from './components/SelectFacebookCatalog';
import LandingPage from './components/LandingPage';
import FeedsPage from './components/FeedsPage';

export default {
  path: ':organization/marketing',
  component: MarketingRoot,
  indexRoute: { component: LandingPage },
  title: '', // Left blank as using title in Page Component now.
  childRoutes: [
    {
      path: ':platformId/feeds(/:accountId)',
      component: FeedsPage,
    },
    {
      path: 'channels',
      component: LandingPage,
    },
    {
      path: 'google/select-account',
      component: SelectGoogleAccount,
      hideNavigation: true,
      hideLeftNavigation: true,
    },
    {
      path: 'facebook/select-catalog',
      component: SelectFacebookCatalog,
      hideNavigation: true,
      hideLeftNavigation: true,
    },
    {
      path: ':platformId/add-channels',
      component: AddChannels,
    },
    {
      path: ':platformId/:catalogId/add-countries',
      component: AddFacebookCountryFeeds,
    },
    {
      path: ':platformId/add-feeds',
      component: AddFeeds,
    },
    {
      path: ':platformId/:accountId/add-feeds',
      component: AddFeeds,
    },
  ],
};
