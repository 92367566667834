import { bindActionCreators } from 'redux';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { hideExclusionRuleDeleteConfirmation, deleteExclusionRule } from '../actions';
import {
  getHasExclusionRuleDeleteFailed,
  getIsDeletingExclusionRule,
  getIsExclusionRuleDeleteConfirmationVisible,
  getOrganizationIdForExclusionRuleToBeDeleted,
  getExclusionRuleToBeDeleted,
} from '../selectors';
import ExclusionRuleDeleteConfirmation from '../components/ExclusionRuleDeleteConfirmation';
import {
  ExclusionRuleDeleteConfirmationStateProps,
  ExclusionRuleDeleteConfirmationDispatchProps,
} from '../types';
import { RootState } from '../../../stores/types';

const mapStateToProps: MapStateToProps<
ExclusionRuleDeleteConfirmationStateProps,
{},
RootState
> = createStructuredSelector({
  deleting: getIsDeletingExclusionRule,
  deleteFailed: getHasExclusionRuleDeleteFailed,
  open: getIsExclusionRuleDeleteConfirmationVisible,
  organizationId: getOrganizationIdForExclusionRuleToBeDeleted,
  exclusionRule: getExclusionRuleToBeDeleted,
});

const mapDispatchToProps: MapDispatchToProps<
ExclusionRuleDeleteConfirmationDispatchProps,
{}
> = (dispatch) => bindActionCreators({
  onOk: deleteExclusionRule,
  onCancel: hideExclusionRuleDeleteConfirmation,
}, dispatch);

export default connect<
ExclusionRuleDeleteConfirmationStateProps,
ExclusionRuleDeleteConfirmationDispatchProps,
{},
RootState
>(mapStateToProps, mapDispatchToProps)(ExclusionRuleDeleteConfirmation);
