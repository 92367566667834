import React from 'react';
import classNames from 'classnames';
import { css } from '@emotion/css';
import colors from '../../../../theme/tokens/colors';

export const pillStyles = css`
    display: inline-block;
    text-align: center;
    text-transform: capitalize;
    font-weight: bold;
    color: ${colors.white};
    padding: 5px 10px;
    border-radius: 10px;
    min-width: 100px;
  
    &.google {
      background-color: ${colors.red[300]};
    }
  
    &.facebook {
      background-color: ${colors.chart.blue};
    }
  
    &.flow {
      background-color: ${colors['flow-blue'][400]};
    }
  `;
interface Props {
  platform: io.flow.internal.v0.enums.MarketingGatewayPlatform | 'flow';
}

const ChannelPill: React.FC<Props> = ({ platform }) => (
  <span className={classNames(pillStyles, platform)}>{platform}</span>
);

ChannelPill.displayName = 'ChannelPill';

export default ChannelPill;
