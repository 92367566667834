import React from 'react';
import { useDispatch } from 'react-redux';
import { Field, Form, FormSpy } from 'react-final-form';
import { Card, CardContent, CardHeader } from '@flowio/react-card';
import { debounce, noop } from 'lodash';
import { Column, Grid, Row } from '@flowio/react-grid';
import TextField from '../../../../components/TextField';
import { ThunkDispatcher } from '../../../../stores/types';
import { updateCustomerInfo } from '../../actions/replacementOrder';
import { AutoSaveState } from '../../types';
import { labelStyles } from './styles';

interface CustomerInfoFormProps {
  organizationId: string;
  order: io.flow.v0.models.Order;
  onStateChange: (state: AutoSaveState) => void;
  onError: (error: Error) => void;
}

let autoSaveState: AutoSaveState;

const CustomerInfoForm: React.FC<CustomerInfoFormProps> = ({
  organizationId,
  order,
  onStateChange,
  onError,
}) => {
  const dispatch = useDispatch<ThunkDispatcher>();

  const handleStateChange = (state: AutoSaveState) => {
    if (autoSaveState !== state) {
      autoSaveState = state;
      onStateChange(state);
    }
  };

  const saveToApi = debounce((customerInfo: io.flow.v0.models.OrderCustomerForm) => {
    handleStateChange('saving');

    dispatch(updateCustomerInfo(
      organizationId,
      order.number,
      customerInfo,
    ))
      .then(() => handleStateChange('saved'))
      .catch((error: Error) => {
        handleStateChange('error');
        onError(error);
      });
  }, 1500);

  const save = (values: io.flow.v0.models.OrderCustomerForm) => {
    handleStateChange('dirty');
    saveToApi(values);
  };

  return (
    <Form
      onSubmit={noop}
      initialValues={order.customer}
      render={() => (
        <Card>
          <CardHeader caption="Customer Info" dividing />
          <CardContent>
            <FormSpy
              subscription={{ values: true, dirty: true }}
              onChange={(props) => {
                if (props.dirty) save(props.values);
              }}
            />
            <Grid gutter>
              <Row>
                <Column span={1} css={labelStyles}>First Name</Column>
                <Column span={5}>
                  <Field
                    component={TextField}
                    name="name.first"
                    fluid
                  />
                </Column>
                <Column span={1} css={labelStyles}>Last Name</Column>
                <Column span={5}>
                  <Field
                    component={TextField}
                    name="name.last"
                    fluid
                  />
                </Column>
              </Row>
              <Row>
                <Column span={1} css={labelStyles}>Phone</Column>
                <Column span={5}>
                  <Field
                    component={TextField}
                    name="phone"
                    fluid
                  />
                </Column>
                <Column span={1} css={labelStyles}>Email</Column>
                <Column span={5}>
                  <Field
                    component={TextField}
                    name="email"
                    fluid
                  />
                </Column>
              </Row>
            </Grid>
          </CardContent>
        </Card>
      )}
    />
  );
};

export default CustomerInfoForm;
