import Client from './client';

export default class ChannelBillingStatements extends Client {
  constructor(opts) {
    let options = opts;

    if (typeof opts === 'string') {
      options = { host: opts }; // convert host string to options object
    }

    options.serviceName = 'API Internal';

    super(options);
  }

  get(channelId, options = {}) {
    return this.makeRequest(`${this.host}/channel/${channelId}/account/statements`, options);
  }

  getById(channelId, id, options = {}) {
    return this.makeRequest(`${this.host}/channel/${channelId}/account/statements/${id}`, options);
  }

  deleteById(channelId, id, options = {}) {
    return this.makeRequest(`${this.host}/channel/${channelId}/account/statements/${id}`, {
      ...options,
       method: 'DELETE',
    });
  }

  postEmailById(channelId, id, options = {}) {
    return this.makeRequest(`${this.host}/channel/${channelId}/account/statements/${id}/email`, {
      ...options,
       method: 'POST',
    });
  }

}
