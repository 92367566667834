import { reduxForm, formValues } from 'redux-form';
import { compose } from 'redux';
import { connect, MapStateToProps } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { invalidateShippingConfigurationExperiences } from '../../../../logistics/actions';

import { updateExperienceLogisticsSetting } from '../../../actions';
import { createToast } from '../../../../console/actions';
import { getCurrentExperienceKey, getCurrentExperienceShippingConfigurationKey } from '../../../selectors';
import { getOrganizationId } from '../../../../organization/selectors';
import { getRegions } from '../../../../reference/selectors';
import ExperienceLogisticsSettingForm from '../components/ExperienceLogisticsSettingForm';
import FormName from '../../../constants/FormName';
import handleSubmitResult from '../../../../logistics/utilities/handleSubmitResult';
import {
  StateProps, OwnProps, ExperienceLogisticsSettingFormValues, MergedProps, ComponentProps,
} from '../types';
import { UpdateExperienceLogisticsSettingSuccessAction } from '../../../types';
import { RootActionTypes, RootState, ThunkDispatcher } from '../../../../../stores/types';
import { ActionTypes } from '../../../constants';

function experienceLogisticsSettingsPutFormFromValues(
  values: ExperienceLogisticsSettingFormValues,
): io.flow.v0.models.ExperienceLogisticsSettingsPutForm {
  if (!values.shippingConfigurationKey) {
    throw new Error('experienceLogisticsSettingsPutFormFromValues: Missing shipping configuration key');
  }
  return {
    shipping_configuration_key: values.shippingConfigurationKey,
  };
}

function handleChange(
  values: ExperienceLogisticsSettingFormValues,
  dispatch: ThunkDispatcher,
  _props: MergedProps,
  previousValues: ExperienceLogisticsSettingFormValues,
): void {
  const { shippingConfigurationKey: nextShippingConfigurationKey } = values;
  const { shippingConfigurationKey: prevShippingConfigurationKey } = previousValues;

  if (nextShippingConfigurationKey !== prevShippingConfigurationKey) {
    // Currently, the service allows experiences without an initial shipping
    // configuration, in which case there is no shipping configuration key
    // to invalidate.
    if (prevShippingConfigurationKey != null) {
      dispatch(invalidateShippingConfigurationExperiences(prevShippingConfigurationKey || ''));
    }

    dispatch(invalidateShippingConfigurationExperiences(nextShippingConfigurationKey || ''));
  }
}

function handleSubmitSuccess(
  result: UpdateExperienceLogisticsSettingSuccessAction,
  dispatch: ThunkDispatcher,
): void {
  dispatch(createToast({
    icon: 'InfoCircle',
    content: `Logistics configuration for "${result.payload.experience.key}" has been updated.`,
  }));
}

function handleSubmit(
  values: ExperienceLogisticsSettingFormValues,
  dispatch: ThunkDispatcher,
): Promise<RootActionTypes> {
  return dispatch(updateExperienceLogisticsSetting({
    organizationId: values.organizationId,
    experienceKey: values.experienceKey,
    experienceLogisticsSettingsPutForm: experienceLogisticsSettingsPutFormFromValues(values),
  })).then((result) => handleSubmitResult(
    result,
    [ActionTypes.UPDATE_EXPERIENCE_LOGISTICS_SETTING_FAILURE],
  ));
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = createStructuredSelector({
  initialValues: createStructuredSelector({
    experienceKey: getCurrentExperienceKey,
    organizationId: getOrganizationId,
    shippingConfigurationKey: getCurrentExperienceShippingConfigurationKey,
  }),
  regions: getRegions,
});

export default compose<React.FC<ComponentProps>>(
  connect<StateProps, {}, OwnProps, RootState>(mapStateToProps),
  reduxForm<ExperienceLogisticsSettingFormValues, MergedProps>({
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    form: FormName.EXPERIENCE_LOGISTICS_SETTING,
    onChange: handleChange,
    onSubmit: handleSubmit,
    onSubmitSuccess: handleSubmitSuccess,
  }),
  formValues({
    selectedShippingConfigurationKey: 'shippingConfigurationKey',
  }),
)(ExperienceLogisticsSettingForm);
