export const DefaultReturnSource: io.flow.v0.models.ReturnSourceFlow = {
  discriminator: 'return_source_flow',
  attributes: {},
};

export const DefaultReturnService: io.flow.v0.models.Return['service'] = {
  discriminator: 'service_summary',
  id: '',
  carrier: {
    id: '',
  },
  name: '',
};

export const DefaultReturnOrigin: io.flow.v0.models.Return['origin'] = {
  contact: {
    name: {},
  },
  location: {},
};

export const DefaultReturnDestination: io.flow.v0.models.Return['destination'] = {
  contact: {
    name: {},
  },
  location: {},
};
