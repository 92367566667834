import ActionTypes from './action-types';

import { RootActionTypes, ThunkResult } from '../../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../../utilities/format-error-response-v2';

const createRequestAction = (): RootActionTypes => ({
  type: ActionTypes.EXPORT_PRICE_BOOK_REQUEST,
});

const createSuccessAction = (payload: io.flow.v0.models.Export): RootActionTypes => ({
  type: ActionTypes.EXPORT_PRICE_BOOK_SUCCESS,
  payload,
});

const createFailureAction = (errors: FormattedErrorMessages): RootActionTypes => ({
  type: ActionTypes.EXPORT_PRICE_BOOK_FAILURE,
  error: true,
  payload: errors,
});

export default function exportPriceBook(
  organization: string,
  priceBookKey: string,
  email: string,
  itemIdentifier?: 'sku' | 'item_number',
): ThunkResult<Promise<boolean>> {
  return (dispatch, getState, extra): Promise<boolean> => {
    dispatch(createRequestAction());

    return extra.api(getState()).exports.post({
      organization,
      body: {
        emails: [email],
        type: {
          discriminator: 'price_book_item_export_type',
          price_book_key: priceBookKey,
          item_identifier: itemIdentifier,
        },
      },
    }).then((response) => {
      if (response.ok) {
        dispatch(createSuccessAction(response.body));
        return false;
      }

      const errors = formatErrorResponseV2(response);
      dispatch(createFailureAction(errors));
      return true;
    });
  };
}
