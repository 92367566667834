import { RootActionTypes } from '../../stores/types';

// https://redux.js.org/recipes/reducingboilerplate#generating-reducers
export default function createReducer<S = any>(
  initialState: S,
  handlers: Record<string, (state: S, action: RootActionTypes) => S>,
) {
  return function reducer(state: S = initialState, action: RootActionTypes): S {
    return (Object.prototype.hasOwnProperty.call(handlers, action.type))
      ? handlers[action.type](state, action)
      : state;
  };
}
