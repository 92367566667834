import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import ActionTypes from '../types/action-types';

function createRequestAction(): RootActionTypes {
  return {
    type: ActionTypes.FETCH_PRICE_BOOK_EXPORT_TYPES_REQUEST,
  };
}

function createSuccessAction(
  payload: io.flow.v0.models.PriceBookItemExportOptions,
): RootActionTypes {
  return {
    type: ActionTypes.FETCH_PRICE_BOOK_EXPORT_TYPES_SUCCESS,
    payload,
  };
}

function createFailureAction(payload: FormattedErrorMessages): RootActionTypes {
  return {
    type: ActionTypes.FETCH_PRICE_BOOK_EXPORT_TYPES_FAILURE,
    error: true,
    payload,
  };
}

function fetchPriceBookExportTypes(organization: string): ThunkResult<Promise<void>> {
  return (
    dispatch,
    getState,
    extra,
  ) => {
    dispatch(createRequestAction());

    return extra.api(getState()).priceBookItemsExportOptions.get({
      organization,
    }).then((result) => {
      if (result.ok) {
        dispatch(createSuccessAction(result.body));
        return;
      }

      const formattedErrorMessages = formatErrorResponseV2(result);
      dispatch(createFailureAction(formattedErrorMessages));
    });
  };
}

export default fetchPriceBookExportTypes;
