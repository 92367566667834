import { reduxForm, SubmissionError } from 'redux-form';
import { deleteShippingConfigurationByKey } from '../actions';
import { ActionTypes, FormName } from '../constants';
import ShippingConfigurationDeleteForm from '../components/ShippingConfigurationDeleteForm';
import { ShippingConfigurationDeleteFormProps, ShippingConfigurationDeleteFormValues } from '../types/components';
import { ThunkDispatcher } from '../../../stores/types';

function handleSubmit(values: ShippingConfigurationDeleteFormValues, dispatch: ThunkDispatcher) {
  const { organizationId, shippingConfigurationKey } = values;
  return dispatch(deleteShippingConfigurationByKey(
    organizationId,
    shippingConfigurationKey,
  )).then((result) => {
    if (result.type === ActionTypes.DELETE_SHIPPING_CONFIGURATION_FAILURE) {
      throw new SubmissionError({ _error: result.payload.messages });
    }

    // Resolve with FSA for downstream processing.
    return result;
  });
}

const form = reduxForm<
ShippingConfigurationDeleteFormValues,
ShippingConfigurationDeleteFormProps
>({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  form: FormName.SHIPPING_CONFIGURATION_DELETE_FORM,
  onSubmit: handleSubmit,
});

export default form(ShippingConfigurationDeleteForm);
