import update from 'immutability-helper';

import ActionTypes from '../constants/action-types';
import { RootActionTypes } from '../../../stores/types';
import { ReturnsState } from '../types';
import {
  DefaultReturnSource,
  DefaultReturnService,
  DefaultReturnOrigin,
  DefaultReturnDestination,
} from '../constants/default-store-values';

const initialState: ReturnsState = {
  return: {
    id: '',
    key: '',
    items: [],
    labels: [],
    source: DefaultReturnSource,
    service: DefaultReturnService,
    origin: DefaultReturnOrigin,
    destination: DefaultReturnDestination,
  },
  items: [],
  inProgress: false,
};

export default function reducer(state = initialState, action: RootActionTypes): ReturnsState {
  switch (action.type) {
    case ActionTypes.CREATE_RETURN_REQUEST:
      return update(state, { error: { $set: undefined }, inProgress: { $set: true } });
    case ActionTypes.CREATE_RETURN_SUCCESS:
      return update(state, { return: { $set: action.payload }, inProgress: { $set: false } });
    case ActionTypes.SET_RETURN_ITEMS:
      return update(state, { items: { $set: action.payload } });
    case ActionTypes.CREATE_RETURN_FAILURE:
      return update(state, { error: { $set: action.payload }, inProgress: { $set: false } });
    case ActionTypes.SHOW_RETURN_FORM_DIALOG:
      return update(state, { error: { $set: undefined }, inProgress: { $set: false } });
    default:
      return state;
  }
}
