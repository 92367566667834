import { css } from '@emotion/css';
import colors from '../../../../theme/tokens/colors';

export const dividingRowBelow = css`
  border-bottom: 1px solid ${colors.gray[300]};
`;

export const dividingRowAbove = css`
  border-top: 1px solid ${colors.gray[300]};
`;

export const itemQtyField = css`
  display: inline-block;
  margin-bottom: 0px !important;
  text-align: center;
  -moz-appearance: textfield;
  width: 40px !important;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    appearance: none !important;
    margin: 0  !important;
  }
  padding: 0 !important;
`;

export const itemQtyFieldWrapper = css`
  display: flex;
  align-items: baseline;
`;

export const amountField = css`
  display: inline !important;  // else display block overrides from FormGroup
`;

export const amountCurrency = css`
  padding-left: 12px;
  vertical-align: top;
  position: relative;
  top: 7px;
`;

export const refundLabel = css`
  padding: 0 8px;
`;

export const additionalRefundContainer = css`
  padding-bottom: 12px;
  display: flex;
  align-items: center;
`;

export const maxQuantity = css`
  padding-left: 8px;
  position: relative;
  bottom: 8px;
`;

export const quantityCol = css`
  padding-left: 10px;
  padding-right: 10px;
`;

export const refundDescription = css`
  color: ${colors.gray[800]};
  margin-left: 33.33%;
`;

export const refundAmount = css`
  width: 70%;
  margin-bottom: 0px !important;
`;

export const refundAmountWrapper = css`
  display: flex;
`;

export const shiftLeft = css`
  right: 47px;
`;

export const productColumn = css`
  width: 350px;
`;
