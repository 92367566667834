enum ActionTypes {
  CREATE_CATALOG_EXPORT_REQUEST = 'CREATE_CATALOG_EXPORT_REQUEST',
  CREATE_CATALOG_EXPORT_SUCCESS = 'CREATE_CATALOG_EXPORT_SUCCESS',
  CREATE_CATALOG_EXPORT_FAILURE = 'CREATE_CATALOG_EXPORT_FAILURE',
  FIND_CATALOG_ITEMS_REQUEST = 'FIND_CATALOG_ITEMS_REQUEST',
  FIND_CATALOG_ITEMS_SUCCESS = 'FIND_CATALOG_ITEMS_SUCCESS',
  FIND_CATALOG_ITEMS_FAILURE = 'FIND_CATALOG_ITEMS_FAILURE',
  FIND_CATALOG_ITEMS_LEGACY_REQUEST = 'FIND_CATALOG_ITEMS_LEGACY_REQUEST',
  FIND_CATALOG_ITEMS_LEGACY_SUCCESS = 'FIND_CATALOG_ITEMS_LEGACY_SUCCESS',
  FIND_CATALOG_ITEMS_LEGACY_FAILURE = 'FIND_CATALOG_ITEMS_LEGACY_FAILURE',
  FIND_CATALOG_SUGGESTIONS_REQUEST = 'FIND_CATALOG_SUGGESTIONS_REQUEST',
  FIND_CATALOG_SUGGESTIONS_SUCCESS = 'FIND_CATALOG_SUGGESTIONS_SUCCESS',
  FIND_CATALOG_SUGGESTIONS_FAILURE = 'FIND_CATALOG_SUGGESTIONS_FAILURE',
  CLEAR_CATALOG_OPTIONS = 'CLEAR_CATALOG_OPTIONS',
}

export default ActionTypes;
