import { RootState, ThunkDispatcher } from '../../../stores/types';
import ActionTypes from '../constants/action-types';
import { PartialHttpResponse } from '../../../utilities/clients/api-error';
import { server } from '../../../utilities/clients';
import isResponseOk from '../../../utilities/isResponseOk';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import formatErrorResponse from '../../../utilities/format-error-response';
import { LegacyResponse } from '../../../utilities/clients/types/server';

export default function logoutUser() {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
  ): Promise<LegacyResponse<void | FormattedErrorMessages>> => {
    dispatch({
      type: ActionTypes.AUTH_LOGOUT_USER_REQUEST,
    });

    return server.auth.logout().then((response) => {
      if (isResponseOk(response)) {
        if (getState().defaultReducer.user?.extra?.auth0Id != null) {
          window.location.href = '/auth0/logout';
        }

        dispatch({
          type: ActionTypes.AUTH_LOGOUT_USER_SUCCESS,
          payload: response.result || undefined,
        });
        return response;
      }

      const httpResponse: PartialHttpResponse = {
        body: response.result,
        status: response.status,
      };

      dispatch({
        type: ActionTypes.AUTH_LOGOUT_USER_FAILURE,
        payload: formatErrorResponseV2(httpResponse),
      });

      return formatErrorResponse(response);
    });
  };
}
