import ActionTypes from './constants/action-types';
import ReadyState from './constants/ready-state';
import AuthState from './types/state';
import { RootActionTypes } from '../../stores/types';

const DefaultState: AuthState = {
  redirect: {
    status: ReadyState.PENDING,
  },
  redirectInProgress: false,
  shopifyAppError: undefined,
};

export default function (state = DefaultState, action: RootActionTypes): AuthState {
  switch (action.type) {
    case ActionTypes.AUTH_FORGOT_PASSWORD_COMPLETED:
      return { ...state, resetSent: true };

    case ActionTypes.AUTHORIZE_DOCS_COMPLETED:
      return { ...state, docs: action.payload };

    case ActionTypes.AUTHORIZE_REDIRECT_COMPLETED:
      return {
        ...state,
        redirect: {
          location: action.payload.redirect,
          status: ReadyState.FULFILLED,
        },
      };

    case ActionTypes.AUTHORIZE_REDIRECT_REQUEST:
      return {
        ...state,
        redirect: {
          location: undefined,
          status: ReadyState.LOADING,
        },
      };

    case ActionTypes.AUTHORIZE_REDIRECT_FAILURE:
      return {
        ...state,
        redirect: {
          status: ReadyState.REJECTED,
        },
      };

    case ActionTypes.GET_SHOP_REQUEST:
      return {
        ...state,
        assignedShop: undefined,
        assignedOrg: undefined,
      };

    case ActionTypes.GET_SHOP_SUCCESS:
    case ActionTypes.ASSIGN_ORG_TO_SHOP_SUCCESS:
      return {
        ...state,
        assignedShop: action.payload.myshopify_domain,
        assignedOrg: action.payload.organization?.id,
      };

    case ActionTypes.SHOPIFY_SET_PARAMS:
      return {
        ...state,
        shopifyParams: action.payload,
      };
    case ActionTypes.REDIRECTING_TO_SHOPIFY_AUTH:
      return {
        ...state,
        redirectInProgress: true,
      };
    case ActionTypes.SET_SHOPIFY_APP_ERROR:
      return {
        ...state,
        shopifyAppError: action.payload,
      };
    default:
      return state;
  }
}
