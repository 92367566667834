import ExtendableError from 'es6-error';
import { $HttpResponse } from '@flowio/api-sdk';

// API errors your application handles.
type ErrorType = io.flow.v0.models.GenericError | undefined;

type Severity = 'critical' | 'error' | 'warning' | 'info';

export type PartialHttpResponse<T = any> = Pick<$HttpResponse<T>, 'body' | 'status'>;

function defaultSeverity(
  response: PartialHttpResponse<ErrorType>,
): Severity {
  if (response.status >= 500) return 'critical';
  if (response.status === 401) return 'error';
  return 'warning';
}

export class ApiError extends ExtendableError {
  /**
   * An unsuccessful response from the TypeScript client
   */
  response: PartialHttpResponse<ErrorType>;

  /**
   * A severity level that can be used to hint logging severity. For example,
   * a middleware to log FSA error actions to Rollbar depending on the
   * error severity.
   */
  severity: Severity;

  message: string;

  static critical(response: PartialHttpResponse<ErrorType>): ApiError {
    return new ApiError(response, 'critical');
  }

  static error(response: PartialHttpResponse<ErrorType>): ApiError {
    return new ApiError(response, 'error');
  }

  static warning(response: PartialHttpResponse<ErrorType>): ApiError {
    return new ApiError(response, 'warning');
  }

  static info(response: PartialHttpResponse<ErrorType>): ApiError {
    return new ApiError(response, 'info');
  }

  constructor(
    response: PartialHttpResponse<ErrorType> | $HttpResponse<ErrorType>,
    severity: Severity = defaultSeverity(response),
  ) {
    super();
    this.response = response;
    this.severity = severity;
    if (response.body && Array.isArray(response.body.messages)) {
      this.message = response.body.messages.join(' ');
    } else {
      this.message = 'API Error';
    }
  }
}

// Type guards to discriminate errors.
export function isGenericError(
  error: ErrorType,
): error is io.flow.v0.models.GenericError {
  return error != null && (error as io.flow.v0.models.GenericError).code === 'generic_error';
}

export function isOrderError(
  error: ErrorType,
): error is io.flow.v0.models.GenericError {
  return error != null && (error as io.flow.v0.models.GenericError).code === 'server_error';
}

export function isApiError(
  error: unknown,
): error is ApiError {
  return error instanceof ApiError;
}
