import ActionTypes from '../constants/action-types';

import { getOrganizationId } from '../../organization';
import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import showReturnConfirmation from './show-return-confirmation';
import setReturnItems from './set-return-items';
import setNotification from './set-notification';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import toLegacyResponse from '../../../utilities/clients/to-legacy-response';
import { DefaultReturnSource } from '../constants/default-store-values';

interface ReturnFormData extends io.flow.v0.models.ReturnForm {
  orderItems: io.flow.v0.models.LocalizedLineItem[];
}

const createRequestAction = (): RootActionTypes => ({
  type: ActionTypes.CREATE_RETURN_REQUEST,
});

const createSuccessAction = (payload: io.flow.v0.models.Return): RootActionTypes => ({
  type: ActionTypes.CREATE_RETURN_SUCCESS,
  payload,
});

const createFailureAction = (errors: FormattedErrorMessages): RootActionTypes => ({
  type: ActionTypes.CREATE_RETURN_FAILURE,
  payload: errors,
  error: true,
});

export default function createReturn(
  formData: ReturnFormData,
): ThunkResult<Promise<LegacyResponse<io.flow.v0.models.Return | FormattedErrorMessages>>> {
  return (
    dispatch,
    getState,
    extra,
  ): Promise<LegacyResponse<io.flow.v0.models.Return | FormattedErrorMessages>> => {
    const state = getState();
    const organizationId = getOrganizationId(state);
    dispatch(createRequestAction());
    dispatch(setReturnItems(formData.orderItems));

    return extra.api(state).returns.post({
      organization: organizationId,
      body: {
        items: formData.items,
        center_key: formData.center_key,
        destination: formData.destination,
        key: formData.key,
        order_number: formData.order_number,
        origin: formData.origin,
        service: formData.service,
        tier_id: formData.tier_id,
        source: DefaultReturnSource,
      },
    }).then((response) => {
      if (response.ok) {
        dispatch(createSuccessAction(response.body));
        dispatch(showReturnConfirmation());
        return toLegacyResponse(response);
      }

      const errors = formatErrorResponseV2(response);
      dispatch(setNotification('Failed to create return'));
      dispatch(createFailureAction(errors));
      return toLegacyResponse({
        ...response,
        body: errors,
      });
    });
  };
}
