import React from 'react';
import { isNotNil } from '@flowio/is';
import { Stack } from '@flowio/react-stack';
import { Label } from '@flowio/react-label';
import moment from 'moment';
import * as styles from './order-tracking-details-dialog.styles';

type Props = {
  notification: io.flow.v0.models.ShippingNotification;
};

const NotificationDetails: React.FC<Props> = ({
  notification,
}) => {
  const hasDimension = isNotNil(notification.package) && isNotNil(notification.package.dimensions);
  const dims: (keyof io.flow.v0.models.Dimension)[] = ['weight', 'width', 'diameter', 'depth', 'length'];
  return (
    <Stack spacing="loose" className={styles.labelDetails}>
      <Stack orientation="vertical" distribution="fill" spacing="loose">
        {notification.window && (
          <Stack distribution="fillEqually">
            <Label>Delivery Window</Label>
            <span>
              {notification.window.from !== notification.window.to && (
                `${moment(notification.window.from).format('Do MMM')} - `
              )}
              {moment(notification.window.to).format('Do MMM YYYY')}
            </span>
          </Stack>
        )}
        {hasDimension && (
          <Stack distribution="fillEqually">
            <Label>Package Dimensions</Label>
            <ul style={{ listStyleType: 'none', margin: 0, padding: 0 }}>
              {dims.map((dim) => {
                if (hasDimension) {
                  const currDim = notification.package.dimensions[dim];
                  if (isNotNil(currDim)) {
                    return <li key={dim}>{currDim.value} {currDim.units} ({dim})</li>;
                  }
                }
                return null;
              })}
            </ul>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default NotificationDetails;
