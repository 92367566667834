import React, { useState } from 'react';
import { css } from '@emotion/react';
import { formPropTypes, Form, Field } from 'redux-form';
import filter from 'lodash/filter';
import find from 'lodash/find';
import map from 'lodash/map';
import ApiPropTypes from '@flowio/api-prop-types';
import ApiInternalPropTypes from '@flowio/api-internal-prop-types';
import get from 'lodash/get';
import some from 'lodash/some';
import {
  Accordion, Panel, PanelContent, PanelHeader,
} from '@flowio/react-panel';
import { ChevronUp, ChevronDown } from '@flowio/react-icons';
import { Checkbox } from '@flowio/react-checkbox';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ExternalLink from '../ExternalLink';
import * as styles from './SelectFeedForms.styles';
import { findCountryByCode } from '../../utilities/findReferenceData';
import { colors } from '../../../../theme/tokens';
import { marketingBannerStyles } from '../../styles/styles';

const chevronStyling = css`
  margin-left: 8px;
  fill: ${colors.marketing['dark-slate-blue']};
`;

const UNAVAILABLE = (platform) => {
  switch (platform) {
    case 'google': {
      const link = 'https://support.google.com/merchants/answer/160637?hl=en';
      return (
        <p>
          A country is unavailable for selection when it already has an existing localized feed,
          does not have a Flow Experience that meets Google&apos;s country requirements for currency
          and language, or is not offered as a
          {' '}
          <ExternalLink className={styles.helpLink} href={link}>
            country of sale option
          </ExternalLink>
          {' '}
          by Google.
        </p>
      );
    }
    default: {
      const link = 'https://www.facebook.com/business/help/1155157871341714?id=176276233019487';
      return (
        <p>
          A country is unavailable for selection when it already has existing supplementary country
          information, does not have a Flow Experience associated with the country, or is not
          offered as a
          <ExternalLink className={styles.helpLink} href={link}>
            targeting option
          </ExternalLink> by Facebook.
        </p>
      );
    }
  }
};

const SelectFeedForms = ({
  countries,
  platformId,
  forms,
  handleSubmit,
  submitFailed,
  change,
  array,
}) => {
  const [isUnavailableOpen, setIsUnavailableOpen] = useState(false);

  const [selectedForms, setSelectedForms] = useState([]);

  const toggleUnavailableOpen = () => setIsUnavailableOpen((isOpen) => !isOpen);

  const unavailable = filter(
    countries, (c) => !some(forms, (form) => form.country === c.iso_3166_3),
  );

  const getCountryName = (country) => get(findCountryByCode(countries, country), 'name', country);

  const countryNames = () => map(forms, (form) => form.country);

  const handleSelectAllChange = ({ target }) => {
    const { checked } = target;
    if (checked) {
      setSelectedForms(countryNames);
      change('forms', forms);
    } else {
      setSelectedForms([]);
      array.removeAll('forms');
    }
  };

  const handleChange = ({ target }) => {
    const { value, checked } = target;
    const form = find(forms, (f) => f.country === value);

    setSelectedForms(
      (currentForms) => {
        if (checked) {
          array.push('forms', form);
          return [...currentForms, value];
        }
        const index = currentForms.indexOf(value);
        array.remove('forms', index);
        return filter(currentForms, (f) => f !== value);
      },
    );
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Field type="hidden" component="input" name="organizationId" />
      <Field type="hidden" component="input" name="forms" />
      {!!forms.length && (
        <div>
          <div className={styles.formHeading}>
            <h3 className={styles.title}>Available Country Feeds</h3>
            <Checkbox
              data-automation-id="select-all-countries"
              checked={forms.length === selectedForms.length}
              className={styles.selectAllCountriesCheckbox}
              labelText="Select All"
              onChange={handleSelectAllChange}
            />
          </div>
          <div className={styles.countriesSelection}>
            {forms.map((form) => (
              <Checkbox
                checked={selectedForms.indexOf(form.country) >= 0}
                value={form.country}
                key={form.country}
                onChange={handleChange}
                className={styles.countryCheckbox}
                labelText={getCountryName(form.country)}
              />
            ))}
          </div>
          <Accordion defaultActiveKey="false" id="accordion-target-countries">
            <Panel collapsible css={styles.unavailable} onSelect={toggleUnavailableOpen}>
              <PanelHeader className={styles.header}>
                <div className={styles.heading}>
                  <div>
                    <h3>Unavailable Countries</h3>
                    {isUnavailableOpen
                      ? <ChevronUp css={chevronStyling} width={16} />
                      : <ChevronDown css={chevronStyling} width={16} />}
                  </div>
                  {UNAVAILABLE(platformId)}
                </div>
              </PanelHeader>
              <PanelContent className={styles.content}>
                <ul className={styles.unavailableCountries}>
                  {map(unavailable, (country) => <li key={country.iso_3166_3}>{get(country, 'name')}</li>)}
                </ul>
              </PanelContent>
            </Panel>
          </Accordion>
        </div>
      )}
      {!forms.length && (
        <div className={classNames(marketingBannerStyles, 'neutral', 'padTop')}>
          There are no suitable countries available for creating localized feeds for this source.
        </div>
      )}
      {submitFailed && (
        <div className={classNames(marketingBannerStyles, 'negative', 'padTop')}>
          We are sorry but we have encountered an issue, please try again.
        </div>
      )}
    </Form>
  );
};

SelectFeedForms.displayName = 'SelectFeedForms';

SelectFeedForms.propTypes = {
  ...formPropTypes,
  platformId: ApiInternalPropTypes.marketingGatewayPlatform.isRequired,
  countries: PropTypes.arrayOf(ApiPropTypes.country).isRequired,
  forms: PropTypes.arrayOf(ApiInternalPropTypes.marketingGatewayFeedForm),
  handleSubmit: PropTypes.func.isRequired,
};

SelectFeedForms.defaultProps = {
  forms: [],
};

export default SelectFeedForms;
