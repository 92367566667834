import { compose } from 'redux';
import { withRouter, RouterState } from 'react-router';
import { connect } from 'react-redux';
import {
  initializeSDK,
  fetchAccessTokenSummary,
  toggleReauthenticationModal,
} from '../../../actions';
import { RootState, ThunkDispatcher } from '../../../../../stores/types';
import { DispatchProps, StateProps } from '../types';
import {
  getIsInitialized,
  getIsTokenExpired,
  getIsTokenOwner,
  getDaysToExpiry,
  getFacebookTokenSummary,
} from '../../../selectors';
import { createToast } from '../../../../console/actions';
import { CreateToastOptions } from '../../../../console/types';
import FacebookRoot from '../components/FacebookRoot';

const isRefresh = (query: Record<string, string>) => query.isRefresh === 'true';

const mapStateToProps = (
  state: RootState,
  { location: { query }, params: { organization } }: RouterState,
): StateProps => ({
  organization,
  isInitialized: getIsInitialized(state),
  isTokenOwner: getIsTokenOwner(state),
  isTokenExpired: getIsTokenExpired(state),
  accessTokenSummary: getFacebookTokenSummary(state),
  daysToExpiry: getDaysToExpiry(state),
  isRefresh: isRefresh(query),
});

const mapDispatchToProps = (
  dispatch: ThunkDispatcher,
  { location: { query }, params: { organization } }: RouterState,
): DispatchProps => ({
  initializeSDK: () => dispatch(initializeSDK(isRefresh(query))),
  fetchAccessTokenSummary: () => organization && dispatch(fetchAccessTokenSummary(organization)),
  createToast: (options: CreateToastOptions) => dispatch(createToast(options)),
  showReauthenticationModal: () => dispatch(toggleReauthenticationModal(true)),
});

export default compose<React.FC>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(FacebookRoot);
