import React from 'react';
import { Field } from 'redux-form';

import { Card, CardHeader, CardContent } from '@flowio/react-card';
import * as styles from './TierAdditionalSettingsCard.styles';
import { Checkbox } from '../../../../components/ReduxFormFieldAdapters';

const TierAdditionalSettingsCard: React.FC<{}> = () => (
  <Card>
    <CardHeader dividing>
      Additional Settings
    </CardHeader>
    <CardContent className={styles.additionalSettingsContainer}>
      <Field
        component={Checkbox}
        labelText="This is a backup shipping tier"
        name="isBackup"
        type="checkbox"
        theme={{
          indicator: styles.availabilityCheckbox,
        }}
      />
      <div className={styles.subText}>
        Backup shipping tier will only be displayed to customers when all other configured shipping
        tiers are unavailable.
      </div>
    </CardContent>
  </Card>
);

export default TierAdditionalSettingsCard;
