import { ValueOf } from '../../../types';

const labKeys = {
  BLAZE: 'blaze',
  PAY_LATER: 'pay_later',
} as const;

export type LabKey = ValueOf<typeof labKeys>;

export default labKeys;
