import React from 'react';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import groupBy from 'lodash/groupBy';
import { Select } from '@flowio/react-select';
import { currencies } from '@flowio/lib-reference-javascript';
import {
  Card, CardHeader, CardContent, CardFooter, CardDescription, CardTitle,
} from '@flowio/react-card';
import { PageContent } from '@flowio/react-page';

import CurrencyRatesTable from './currency-rates-table';
import Pagination from '../../../../components/pagination';

import { CurrencyDropdownOption, CurrencyRateProps } from '../../types';

export default class CurrencyRates extends React.PureComponent<CurrencyRateProps> {
  static displayName = 'CurrencyRates';

  handleBaseCurrencyChange = (currencyValue: string) => {
    const { onRequestBaseCurrencyChange } = this.props;
    if (onRequestBaseCurrencyChange) {
      onRequestBaseCurrencyChange(currencyValue);
    }
  };

  render() {
    const {
      rates = [],
      baseCurrencies,
      onRequestSort: propOnRequestSort,
      sortedColumn = undefined,
      sortOrder = undefined,
      onRequestPreviousPage: propOnRequestPreviousPage,
      onRequestNextPage: propOnRequestNextPage,
      isFirstPage,
      isLastPage,
    } = this.props;

    const currencyOptions: CurrencyDropdownOption[] = baseCurrencies.map((currency) => (
      {
        label: `${currency.iso_4217_3} - ${currency.name}`,
        value: currency.iso_4217_3,
      }
    ));
    return (
      <PageContent>
        <Card>
          <CardHeader dividing>
            <CardTitle content="Currency Rates" />
            <CardDescription content="View the currency conversion rates currently used for your prices. Flow core currencies are: CNY, CAD, EUR, GBP, AUD." />
          </CardHeader>
          <CardContent>
            <Select
              hintText="Rates in Base Currency"
              onValueChange={this.handleBaseCurrencyChange}
              options={currencyOptions.map((c) => ({ value: c.value, content: c.label }))}
            />
            {(() => {
              let children = null;

              if (isEmpty(rates)) {
                children = (
                  <p className="muted-text">No currency rates available.</p>
                );
              } else {
                children = map(groupBy(rates, 'base'), (targets, base) => {
                  const currency = currencies.find(base);
                  return (
                    <div key={currency.iso_4217_3} style={{ marginTop: '1rem' }}>
                      <h3>
                        {currency.name}
                      </h3>
                      <CurrencyRatesTable
                        onRequestSort={propOnRequestSort}
                        rates={targets}
                        sortedColumn={sortedColumn}
                        sortOrder={sortOrder}
                      />
                    </div>
                  );
                });
              }

              return children;
            })()}
          </CardContent>
          <CardFooter>
            <Pagination
              isPreviousPageDisabled={isFirstPage}
              isNextPageDisabled={isLastPage}
              onRequestPreviousPage={propOnRequestPreviousPage}
              onRequestNextPage={propOnRequestNextPage}
            />
          </CardFooter>
        </Card>
      </PageContent>
    );
  }
}
