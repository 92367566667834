import { css } from '@emotion/css';

export const fraudLabel = css`
  flex: 1 0 25% !important;
`;

export const reviewSection = css`
  margin-left: 13%;
  padding-top: 0.5rem;
`;

export const statusText = css`
  position: relative;
  display: inline-block;
  margin-left: 4px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  text-align: left;
  text-transform: uppercase;
`;

export const pending = css`
  color: #b5bec9;
  font-style: italic;
`;

export const fraudReviewButton = css`
  border-radius: 3px;
  height: 24px;
`;

export const fraudReviewMessage = css`
  color: #77818f;
  font-size: 12px;
  margin-top: 0.5rem;
`;

export const fraudResponsibleParty = css`
  text-transform: capitalize;
`;

export const fraudClearFix = css`
  &:after {
    content: "";
    display: table;
    clear: both;
  }
`;
