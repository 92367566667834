import { css } from '@emotion/css';
import colors from '../../../../theme/tokens/colors';

export const exportButton = css`
  border: 0;
  border-radius: 4px;
  background-color: ${colors.white};
  cursor: pointer;
  height: 25px;
  width: 40px;
`;

export const icon = css`
  color: ${colors.steel};
  vertical-align: middle;
  height: 100%;
`;
