import { ActionTypes } from '../constants';
import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';

interface DeleteTierOptions {
  organizationId: string;
  shippingConfigurationKey: string;
  shippingLaneId: string;
  tierId: string;
}

function createRequestAction(): RootActionTypes {
  return {
    type: ActionTypes.DELETE_TIER_REQUEST,
  };
}

function createSuccessAction(
  organizationId: string,
  shippingConfigurationKey: string,
  shippingLaneId: string,
  tierId: string,
): RootActionTypes {
  return {
    type: ActionTypes.DELETE_TIER_SUCCESS,
    meta: {
      organizationId,
      shippingConfigurationKey,
      shippingLaneId,
      tierId,
    },
  };
}

function createFailureAction(payload: FormattedErrorMessages): RootActionTypes {
  return {
    type: ActionTypes.DELETE_TIER_FAILURE,
    error: true,
    payload,
  };
}

/**
 * Creates an action responsible for deleting a shipping tier.
 * @param {String} props.organizationId
 * @param {String} props.tierId
 * @throws in development when parameters are invalid.
 */
function deleteTier({
  organizationId,
  shippingLaneId,
  shippingConfigurationKey,
  tierId,
}: DeleteTierOptions): ThunkResult<Promise<RootActionTypes>> {
  return function deleteTierEffects(
    dispatch,
    getState,
    extra,
  ): Promise<RootActionTypes> {
    dispatch(createRequestAction());

    return extra.api(getState()).tiers.deleteById({
      id: tierId,
      organization: organizationId,
    }).then((response) => {
      if (response.ok) {
        return dispatch(createSuccessAction(
          organizationId,
          shippingConfigurationKey,
          shippingLaneId,
          tierId,
        ));
      }

      const errors = formatErrorResponseV2(response);
      return dispatch(createFailureAction(errors));
    });
  };
}

export default deleteTier;
