import BemHelper from '@flowio/bem-helper';
import { LinearProgress } from '@flowio/react-linear-progress';
import React, { Component, ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { Toast, ToastGroup } from '@flowio/react-toast';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import noop from 'lodash/noop';
import compact from 'lodash/compact';
import { css } from '@emotion/react';
import TopNavigation from '../../top-navigation';
import LeftNavigation from '../../left-navigation';
import AccountNavigation from '../../account-navigation';
import ContentErrorBoundary from '../../content-error-boundary';
import { MergedProps as Props } from '../types';
import NotFound from '../../not-found';
import canViewPage from '../../../utilities/canViewPage';
import Facebook from '../../../../facebook/components/Facebook';

const loadingProgressIndicator = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
`;

if (process.browser) {
  // eslint-disable-next-line global-require
  require('./application.css');
}

const bem = new BemHelper('application');

const checkAuthentication = ({
  isAuthenticationRequired,
  isLoggedIn,
  onRequestLoginRedirect,
}: {
  isAuthenticationRequired: boolean;
  isLoggedIn: boolean;
  onRequestLoginRedirect: () => void;
}): void => {
  if (isAuthenticationRequired && !isLoggedIn) {
    onRequestLoginRedirect();
  }
};

export default class Application extends Component<Props> {
  static displayName = 'Application';

  static defaultProps = {
    __redirect: undefined,
    organization: undefined,
    fetching: false,
    hideTopNavigation: false,
    hideLeftNavigation: false,
    isCustomerSerivceUser: false,
    isChannelOrgAdmin: false,
    isFulfillmentUser: false,
    isClassificationUser: false,
    isGlobalPriceBooksEnabled: false,
    isGlobalFailureSummariesEnabled: false,
    isGlobalFulfillmentConsoleEnabled: false,
    isGlobalPendingMembersEnabled: false,
    isGlobalShopifySyncStatusEnabled: false,
    isGlobalConsoleReturnsEnabled: false,
    onMembershipChange: noop,
    onRequestCloseToast: noop,
    onRequestLoginRedirect: noop,
    routes: [],
    router: undefined,
    orgRoles: [],
    toasts: [],
    isFlowLabsEnabled: false,
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount(): void {
    checkAuthentication(this.props);
  }

  componentDidMount(): void {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { __redirect, onFollowRedirect } = this.props;
    if (__redirect) {
      onFollowRedirect(__redirect);
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: Props): void {
    checkAuthentication(nextProps);
  }

  handleCloseToast = (eventKey: string): void => {
    const { onRequestCloseToast } = this.props;
    onRequestCloseToast(eventKey);
  };

  handleMembershipChange = (
    _event: Event,
    value: { organization: io.flow.v0.models.Organization },
  ): void => {
    const { onMembershipChange } = this.props;
    onMembershipChange(value.organization.id);
  };

  getTitle(): string {
    const { routes } = this.props;
    return compact(routes.map((key) => key.title)).join(' - ');
  }

  isAccountUrl(): boolean {
    const {
      location,
    } = this.props;
    return location.pathname.startsWith('/account');
  }

  // FIXME: Using `this.props.params.organization` to render the left navigation is confusing.
  // We should be more explicit, which is the reason `hideLeftNavigation` was added
  // in the first place.
  render(): ReactElement {
    const {
      fetching,
      children,
      toasts,
      location,
      hideTopNavigation,
      hideLeftNavigation,
      isGlobalFulfillmentConsoleEnabled,
      isGlobalFailureSummariesEnabled,
      isGlobalPendingMembersEnabled,
      isGlobalShopifySyncStatusEnabled,
      isGlobalPriceBooksEnabled,
      isGlobalConsoleReturnsEnabled,
      isCustomerSerivceUser,
      isChannelOrgAdmin,
      isFulfillmentUser,
      isClassificationUser,
      isFlowLabsEnabled,
      isLoggedIn,
      onLeftNavigationItemClick,
      organization,
      orgRoles,
      user: propUser,
      routes: propRoutes,
      params: propParams,
      memberships: propMemberships,
    } = this.props;

    const pageAccessible = canViewPage(location.pathname, orgRoles);
    const protectedChildren = pageAccessible ? children : (<NotFound />);

    return (
      <div className={bem.block()}>
        <Helmet>
          <title>{this.getTitle()}</title>
        </Helmet>

        {fetching && (
          <LinearProgress css={loadingProgressIndicator} />
        )}
        <div>
          {hideTopNavigation || !pageAccessible ? null : (
            <TopNavigation
              isCustomerSerivceUser={isCustomerSerivceUser}
              isChannelOrgAdmin={isChannelOrgAdmin}
              isClassificationUser={isClassificationUser}
              isFulfillmentUser={isFulfillmentUser}
              onMembershipChange={this.handleMembershipChange}
              organization={get(this.props, 'organization.id')}
              user={propUser}
              routes={propRoutes}
              params={propParams}
              memberships={propMemberships}
            />
          )}
        </div>
        <div className={bem.element('content-frame', {
          limiter: !hideLeftNavigation,
        })}
        >
          {isEmpty(propParams.organization) || hideLeftNavigation || !pageAccessible ? null : (
            <LeftNavigation
              isFlowLabsEnabled={isFlowLabsEnabled}
              orgRoles={orgRoles}
              isGlobalPriceBooksEnabled={isGlobalPriceBooksEnabled}
              isGlobalFulfillmentConsoleEnabled={isGlobalFulfillmentConsoleEnabled}
              isGlobalFailureSummariesEnabled={isGlobalFailureSummariesEnabled}
              isGlobalPendingMembersEnabled={isGlobalPendingMembersEnabled}
              isGlobalShopifySyncStatusEnabled={isGlobalShopifySyncStatusEnabled}
              isGlobalConsoleReturnsEnabled={isGlobalConsoleReturnsEnabled}
              organization={propParams.organization}
            />
          )}

          {this.isAccountUrl()
            ? (
              <AccountNavigation
                organization={organization.id}
                currentPathName={location.pathname}
                onItemClick={onLeftNavigationItemClick}
              />
            ) : (null)}

          <main className={bem.element('main-content', {
            pushed: !hideLeftNavigation,
            nav: !hideTopNavigation,
          })}
          >
            <ContentErrorBoundary>
              {protectedChildren}
            </ContentErrorBoundary>
          </main>
        </div>
        {!!toasts.length && (
          <ToastGroup>
            {map(toasts, (toast) => (
              <Toast key={toast.eventKey} data-automation-id={`toast-${toast.intent}`} onClose={this.handleCloseToast} {...toast} />
            ))}
          </ToastGroup>
        )}
        {isLoggedIn && <Facebook />}
      </div>
    );
  }
}
