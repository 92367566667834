import { css } from '@emotion/css';
import { colors } from '../../../../theme/tokens';

export const noFeeds = css`
  flex-direction: column;
  background: ${colors.concrete};
  height: 100px;

  &.noFeedsCard {
    height: auto;
    margin-top: 40px;
  }

  p {
    font-size: 14px;
    font-weight: 500;
    color: ${colors.marketing['dark-slate-blue']};
    margin: 8px;
  }

  span {
    font-size: 14px;
  }
`;

export const content = css`
    margin-top: 32px;
`;
