import { RouterState } from 'react-router';
import { connect } from 'react-redux';
import { withFetch } from '@flowio/redux-fetch';
import { fetchRegionSettings, updateRegionSetting } from '../actions';
import Regions from '../components/regions';
import { getRegionSettings } from '../selectors';
import { RootState, ThunkDispatcher } from '../../../stores/types';
import { RegionsStateProps, RegionsDispatchProps, RegionsOwnProps } from '../components/regions/types';

function getAsyncState(
  dispatch: ThunkDispatcher,
  _getState: () => RootState,
  ownProps: RouterState,
) {
  const { organization } = ownProps.params;
  return Promise.all([
    dispatch(fetchRegionSettings(organization)),
  ]);
}

function mapStateToProps(state: RootState): RegionsStateProps {
  return {
    regionSettings: getRegionSettings(state),
  };
}

function mapDispatchToProps(
  dispatch: ThunkDispatcher,
  ownProps: RouterState,
): RegionsDispatchProps {
  const { organization } = ownProps.params;
  return {
    onRequestEnableRegion(regionSetting: io.flow.v0.models.RegionSetting) {
      dispatch(updateRegionSetting(organization, regionSetting, 'enabled'));
    },
    onRequestDisableRegion(regionSetting: io.flow.v0.models.RegionSetting) {
      dispatch(updateRegionSetting(organization, regionSetting, 'disabled'));
    },
  };
}

export default withFetch(getAsyncState)(connect<
RegionsStateProps,
RegionsDispatchProps,
RegionsOwnProps,
RootState
>(mapStateToProps, mapDispatchToProps)(Regions));
