import { $HttpResponse } from '@flowio/api-sdk';
import ActionTypes from '../constants/ActionTypes';
import { ApiError } from '../../../utilities/clients/api-error';
import standardAction from '../../../utilities/standard-action';
import {
  RootState, ThunkExtraArguments, ThunkResult, ThunkDispatcher,
} from '../../../stores/types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';

type Args = {
  organizationId: string;
  marketingId?: string;
  channelId?: io.flow.internal.v0.enums.MarketingGatewayPlatform;
};

const fetchFeeds = ({
  organizationId,
  marketingId,
  channelId,
}: Args): ThunkResult<Promise<void>> => (
  dispatch: ThunkDispatcher,
  getState: () => RootState,
  extra: ThunkExtraArguments,
): Promise<void> => {
  dispatch(standardAction(ActionTypes.FETCH_ORGANIZATION_FEEDS_REQUEST, { marketingId }));
  return extra.apiInternal(getState()).marketingGatewayFeeds.get({
    organization: organizationId,
    channel_id: channelId,
  }).then((response) => {
    if (response.ok) {
      dispatch(
        standardAction(ActionTypes.FETCH_ORGANIZATION_FEEDS_SUCCESS,
          { marketingId, response: response.body }),
      );
      return;
    }

    const errorResponse = response as $HttpResponse<io.flow.v0.models.GenericError>;
    const error = new ApiError(errorResponse);

    dispatch({
      type: ActionTypes.FETCH_ORGANIZATION_FEEDS_FAILURE,
      payload: formatErrorResponseV2(errorResponse),
    });
    throw error;
  });
};

export default fetchFeeds;
