import { RootActionTypes } from '../../../stores/types';
import { ActionTypes } from '../constants';
import { ShippingLabelState } from '../types/state';

const initialState: ShippingLabelState = {
  status: {
    idle: true,
    pending: false,
    fulfilled: false,
    rejected: false,
  },
  label: null,
  items: [],
};

export default function (previousState = initialState, action: RootActionTypes) {
  switch (action.type) {
    case ActionTypes.CREATE_SHIPPING_LABEL_REQUEST:
      return {
        ...previousState,
        status: {
          idle: false,
          pending: true,
          fulfilled: false,
          rejected: false,
        },
        label: null,
      };
    case ActionTypes.CREATE_SHIPPING_LABEL_SUCCESS:
      return {
        ...previousState,
        status: {
          idle: false,
          pending: false,
          fulfilled: true,
          rejected: false,
        },
        label: action.payload.label,
        items: action.payload.items,
      };
    case ActionTypes.CREATE_SHIPPING_LABEL_FAILURE:
      return {
        status: {
          idle: false,
          pending: false,
          fulfilled: false,
          rejected: true,
        },
        label: null,
        items: [],
      };
    case ActionTypes.CLEAR_SHIPPING_LABEL:
      return {
        ...previousState,
        label: null,
        items: [],
      };
    default:
      return previousState;
  }
}
