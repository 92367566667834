import { type FormErrors } from 'redux-form';
import { type WithRouterProps } from 'react-router';
import { ReactNode } from 'react';
import ActionTypes from './constants/action-types';

import { type FormattedErrorMessages } from '../../utilities/format-error-response-v2';
import { type WithPagingAndSortingResult } from '../../utilities/types';
import { type WithPagingAndSortingOptions } from '../../utilities/with-paging-and-sorting-v2';
import { type ParsedQueryStringEntity } from '../../utilities/query';
import { type GraphQLError } from '../utilities/types';
import {
  type FetchShippingLabelAction,
  type FetchShippingNotificationsAction,
  type FetchAddressValidationUserAction,
  type FetchOrderAddressValidationAction,
} from './actions';
import { OrderDetailState } from './reducers/order-detail';
import { type ShippingNotificationsReducersState } from './reducers/shippingNotifications';
import { DataState } from '../../types';
import { type CustomerServiceUiState } from './reducers/ui';

export interface EditShippingAddressFormValues extends io.flow.v0.models.OrderAddress {
  organizationId: string;
  orderNumber: string;
}

export type OrderNotesState = DataState<io.flow.internal.v0.models.OrderNote[]>;

export type AutoSaveState = 'dirty' | 'saving' | 'saved' | 'error';

export type ReplacementOrderState = DataState<io.flow.v0.models.OrderBuilder | null>;

export interface CustomerServiceState {
  invoices: InvoicesState;
  order: OrderState;
  orderDetail: OrderDetailState;
  orderNotes: OrderNotesState;
  refundOrderSummary: RefundOrderSummaryState;
  replacementOrder: ReplacementOrderState;
  returns: ReturnsState;
  returnShippingTiers: io.flow.v0.models.Tier[];
  shippingNotifications: ShippingNotificationsReducersState;
  debugTransactions: io.flow.internal.v0.models.InternalDebugTransaction[];
  ui: CustomerServiceUiState;
}

export interface ReturnsState {
  return: io.flow.v0.models.Return;
  items: io.flow.v0.models.LocalizedLineItem[];
  error?: FormattedErrorMessages;
  inProgress: boolean;
}

export interface RefundOrderSummaryState {
  amounts: {
    balance: number;
    balance_excluding_shipping?: number;
    shipping?: number;
  };
  captured: number;
  currency: string;
  order_number: string;
  refunded: number;
  error?: FormattedErrorMessages;
}

export interface FraudReviewState {
  error?: FormattedErrorMessages;
}

export interface OrderState {
  results: io.flow.v0.models.Order[];
  isLastPage: boolean;
  isFirstPage: boolean;
}

export interface InvoicesState {
  results: {
    businessInvoices: BusinessInvoiceProp[];
    consumerInvoices: InvoiceProp[];
    creditMemos: InvoiceProp[];
    businessCreditMemos: BusinessInvoiceProp[];
  };
  error: Partial<GraphQLError>;
}

export interface OrderDetailStateProps {
  addressValidation: io.flow.internal.v0.models.OrderValidation | null;
  addressValidationUser: io.flow.v0.models.User | null;
  allocations: io.flow.v0.models.AllocationV2;
  approveFraudReviewDialogState?: boolean;
  businessCreditMemos: BusinessInvoiceProp[];
  businessInvoices: BusinessInvoiceProp[];
  consumerInvoices: InvoiceProp[];
  creditMemos: InvoiceProp[];
  declineFraudReviewDialogState?: boolean;
  debugTransactions: io.flow.internal.v0.models.InternalDebugTransaction[];
  isOrderAddressValidationEnabled: boolean;
  isCustomerServiceUser?: boolean;
  isFlowEmployee: boolean;
  notificationMessage?: string;
  orderDetail: io.flow.internal.v0.models.OrderDetail;
  orderTrackingDetails?: {
    showOrderTrackingDetailsDialog: boolean;
    orderNumber: string | undefined;
    labelSummary: io.flow.internal.v0.models.LabelSummary | undefined;
    shippingNotification: io.flow.v0.models.ShippingNotification | undefined;
  };
  organization: io.flow.v0.models.Organization;
  originalShippingAddress?: io.flow.v0.models.OrderAddress;
  refundFormErrors: FormErrors<{}, string>;
  refundOrderSummary?: RefundOrderSummaryState;
  returnShippingTiers?: io.flow.v0.models.Tier[];
  refundDialogState?: {
    showRefundFormDialog: boolean;
  };
  returnDialogState: {
    showReturnFormDialog: boolean;
  };
  returnConfirmationState?: ReturnConfirmationState;
  returnDetailState: {
    showReturnDetailsDialog: boolean;
    showReturnNumberState: boolean;
    items: FullSelectedItem[];
    pdfLabel?: string;
    pdfInvoice?: string;
  };
  shippingNotifications: io.flow.v0.models.ShippingNotification[] | undefined;
  userEmail?: string;
}

export interface OrderDetailDispatchProps {
  onEditShippingAddressEdit: (
    shippingAddress: io.flow.v0.models.OrderAddress,
    organizationId: string,
    orderNumber: string,
    addressValidation: io.flow.internal.v0.models.OrderValidation | null,
    addressValidationUser: io.flow.v0.models.User | null,
    addressValidationMsg: ReactNode | undefined,
  ) => void;
  onRequestDismissNotificationMessage: () => void;
  onRequestHideRefundFormDialog: () => void;
  onRequestShowRefundFormDialog: () => void;
  onRequestHideReturnFormDialog: () => void;
  onRequestShowReturnFormDialog: (organization: string, experienceKey: string) => void;
  onRequestShowReturnDetailDialog: (
    items: FullSelectedItem[],
    pdfLabel: string,
    pdfInvoice: string,
  ) => void;
  onRequestHideReturnDetailDialog: () => void;
  onRequestShowReturnNumberInput: () => void;
  onRequestHideReturnNumberInput: () => void;
  onRequestHideorderTrackingDetailsDialog: () => void;
  onRequestHideApproveFraudReviewDialog: () => void;
  onRequestShowOrderTrackingDetailsDialog: (
    orderNumber: string,
    label: io.flow.internal.v0.models.LabelSummary | undefined,
    notification: io.flow.v0.models.ShippingNotification | undefined,
  ) => void;
  onRequestShowApproveFraudReviewDialog: () => void;
  onRequestHideDeclineFraudReviewDialog: () => void;
  onRequestShowDeclineFraudReviewDialog: () => void;
  onRequestOrganizationFraudReviewApprove: (organization: string, fraudReviewId: string) => void;
  onRequestOrganizationFraudReviewDecline: (organization: string, fraudReviewId: string) => void;
  onCopyAuthorizationIdentifier: (identifier: string) => void;
  onSubmitRefund: () => void;
  onRequestOrderAddressValidation: (organization: string, orderNumber: string) => void;
}

export type OrderDetailProps = OrderDetailDispatchProps & OrderDetailStateProps & WithRouterProps;

export interface ShopifyOrderDetailRedirectProps {
  onRequestRedirectToOrderDetails: () => void;
}

export interface OrderDetailSearchViewStateProps {
  searchText: string;
  searchResults: {
    orderDetails: io.flow.internal.v0.models.OrderDetail[];
    isFirstPage: boolean;
    isLastPage: boolean;
  };
  statusFilterValue?: string;
  orderTypeFilterValue?: string;
  fraudStatusFilterValue?: string;
  pageNumber: number;
  baseCurrency: string;
  organizationCountry: string;
  isGlobalReplacementOrdersEnabled: boolean;
}

export interface OrderDetailSearchViewDispatchProps {
  onRequestSearch: (searchText: string) => void;
  onRequestFraudStatusFilterChange: (option: ParsedQueryStringEntity) => void;
  onRequestStatusFilterChange: (option: ParsedQueryStringEntity) => void;
  onRequestOrderTypeFilterChange: (option: ParsedQueryStringEntity) => void;
  onRequestOrderDetailSelection: (order: io.flow.v0.models.Order) => void;
  onRequestPreviousPage: (entriesPerPage: number) => void;
  onRequestNextPage: (entriesPerPage: number) => void;
}

export type OrderDetailSearchViewProps =
  OrderDetailSearchViewStateProps &
  OrderDetailSearchViewDispatchProps;

export interface OrderItemsStateProps {
  deliveries?: OrderItemDelivery[];
  isCustomerServiceUser?: boolean;
}

export interface OrderItemsProps {
  allocations: io.flow.v0.models.AllocationV2;
  order: io.flow.v0.models.Order;
  organization: io.flow.v0.models.Organization;
}

export type OrderItemsMergedProps = OrderItemsStateProps & OrderItemsProps;

export interface SelectedItem {
  number: string;
  quantity: number;
  maxQuantity: number;
}

export interface FullSelectedItem extends SelectedItem {
  name: string;
}

export interface ReturnData extends io.flow.v0.models.Return {
  orderItems: io.flow.v0.models.LocalizedLineItem[];
  inProgress: boolean;
  error?: FormattedErrorMessages;
}

export interface ReturnConfirmationState {
  showReturnConfirmation: boolean;
  returnData: ReturnData;
}

export interface EntityProp {
  name: string;
  country?: string;
}

export interface DocumentProp {
  language: string;
  url: string;
}

export type CombinedLineItem = io.flow.v0.models.DeliveryItem
& io.flow.v0.models.LocalizedLineItem
& io.flow.v0.models.Line;

export interface OrderItemDeliveryItem extends CombinedLineItem {
  cid: string;
}

export interface OrderItemDeliveryOption extends io.flow.v0.models.DeliveryOption {
  selected: boolean;
}

export interface OrderItemDelivery {
  discriminator: 'physical_delivery';
  id: string;
  key?: string;
  center?: io.flow.v0.models.CenterSummary;
  items: OrderItemDeliveryItem[];
  options: OrderItemDeliveryOption[];
  special_services?: io.flow.v0.enums.PhysicalDeliverySpecialSerivce[];
  prices?: io.flow.v0.models.OrderPriceDetail[];
  total?: io.flow.v0.models.LocalizedTotal;
  goods_supply?: io.flow.v0.enums.GoodsSupply;
}

export interface InvoiceProp {
  id: string;
  number: string;
  entity: EntityProp;
  documents: DocumentProp[];
  date: string;
}

export interface BusinessInvoiceProp extends Omit<InvoiceProp, 'entity'> {
  date: string;
  buyer: EntityProp;
  seller: EntityProp;
}

export interface NormalizedMemo extends BusinessInvoiceProp {
  label: string;
}

export interface DropdownOption {
  content: string;
  value: string;
}

export interface ItemRefundQuantity {
  quantity: number;
  itemNumber: string;
}

export interface RefundCalculationOptions {
  refundType: string;
  refundTax: boolean;
  refundDuty: boolean;
  refundShipping: boolean;
  refundTaxType: string;
  refundDutyType: string;
  refundShippingType: string;
  items: ItemRefundQuantity[];
}

export interface RefundDialogFormItems {
  [key: string]: number;
}

export interface OrderRefundFormDispatchProps {
  onRefundParamsChange: (
    order: io.flow.v0.models.Order,
    options: RefundCalculationOptions,
    allocations: io.flow.v0.models.AllocationV2,
  ) => void;
  onComponentUnmount: () => void;
  onComponentLoad: () => void;
}

export interface OrderRefundStateProps {
  currencies: io.flow.v0.models.Currency[];
  formErrors: FormErrors<{
    refundAmount: string;
  }, string>;
  order: io.flow.v0.models.Order;
  refundType: RefundTypeValues;
  refundTax: boolean;
  refundDuty: boolean;
  refundShipping: boolean;
  refundTaxType: RefundAmountValues;
  refundDutyType: RefundAmountValues;
  refundShippingType: RefundShippingValues;
  items: RefundDialogFormItems;
  initialValues: Partial<OrderRefundFormValues>;
  refundObj: Partial<OrderRefundAmount>;
}

export interface OrderRefundPassedProps {
  allocations: io.flow.v0.models.AllocationV2;
  amountRefunded: number;
}

export type OrderRefundFormProps =
  OrderRefundFormDispatchProps &
  OrderRefundStateProps &
  OrderRefundPassedProps;

export interface OrderRefundFormValues {
  refundAmount: number;
  refundType: DropdownOption;
  refundTax: boolean;
  refundShipping: boolean;
  refundDuty: boolean;
  refundTaxType: DropdownOption;
  refundDutyType: DropdownOption;
  refundShippingType: DropdownOption;
  items: RefundDialogFormItems;
  orderNumber: string;
  orderCurrency: string;
}

export interface OrderRefundAmount {
  refundAmount: number;
  refundItemSubtotalAmount: number;
  refundDiscountAmount?: number;
  refundSurchargeAmount?: number;
  refundTaxAmount?: number;
  refundDutyAmount?: number;
  refundShippingAmount?: number;
}

export enum RefundTypeValues {
  FULL = 'full',
  PARTIAL = 'partial',
  CUSTOM = 'custom',
}

export enum RefundAmountValues {
  ITEM = 'item',
  ORDER = 'order',
}

export enum RefundShippingValues {
  VALUE = 'value',
  QUANTITY = 'quantity',
  ORDER = 'order',
}

export interface ErrorPayload {
  payload: FormattedErrorMessages;
  error: boolean;
}

export interface OrderRefundRequest {
  type: typeof ActionTypes.CREATE_REFUND_REQUEST;
}

export interface OrderRefundSuccess {
  type: typeof ActionTypes.CREATE_REFUND_SUCCESS;
  payload: io.flow.v0.models.Refund;
}

export interface OrderRefundFailure extends ErrorPayload {
  type: typeof ActionTypes.CREATE_REFUND_FAILURE;
}

export interface UpdateOrderRefundAmount {
  type: typeof ActionTypes.UPDATE_REFUND_AMOUNT;
  payload: OrderRefundAmount;
}

export interface ClearOrderRefundAmount {
  type: typeof ActionTypes.CLEAR_REFUND_AMOUNT;
}

export interface CreateFraudReviewDecisionRequest {
  type: typeof ActionTypes.CREATE_FRAUD_REVIEW_DECISION_REQUEST;
}

export interface CreateFraudReviewDecisionSuccess {
  type: typeof ActionTypes.CREATE_FRAUD_REVIEW_DECISION_SUCCESS;
  payload: io.flow.internal.v0.models.FraudReviewDecision;
}
export interface CreateFraudReviewDecisionFailure extends ErrorPayload {
  type: typeof ActionTypes.CREATE_FRAUD_REVIEW_DECISION_FAILURE;
}

export interface FetchAllocationsRequest {
  type: typeof ActionTypes.FETCH_ALLOCATIONS_REQUEST;
}

export interface FetchAllocationsSuccess {
  type: typeof ActionTypes.FETCH_ALLOCATIONS_SUCCESS;
  payload: io.flow.v0.models.AllocationV2;
}

export interface FetchAllocationsFailure extends ErrorPayload {
  type: typeof ActionTypes.FETCH_ALLOCATIONS_FAILURE;
}

export interface FetchOrdersRequest {
  type: typeof ActionTypes.FETCH_ORDERS_REQUEST;
}

export interface FetchOrdersSuccess {
  type: typeof ActionTypes.FETCH_ORDERS_SUCCESS;
  payload: WithPagingAndSortingResult<io.flow.v0.models.Order[]>;
}

export interface FetchOrdersFailure extends ErrorPayload {
  type: typeof ActionTypes.FETCH_ORDERS_FAILURE;
}

export interface FetchOrderDetailRequest {
  type: typeof ActionTypes.FETCH_ORDER_DETAIL_REQUEST;
}

export interface FetchOrderDetailSuccess {
  type: typeof ActionTypes.FETCH_ORDER_DETAIL_SUCCESS;
  payload: io.flow.internal.v0.models.OrderDetail;
}
export interface FetchOrderDetailFailure extends ErrorPayload {
  type: typeof ActionTypes.FETCH_ORDER_DETAIL_FAILURE;
}

export interface FetchOrderNotesRequest {
  type: typeof ActionTypes.FETCH_ORDER_NOTES_REQUEST;
}

export interface FetchOrderNotesSuccess {
  type: typeof ActionTypes.FETCH_ORDER_NOTES_SUCCESS;
  payload: io.flow.internal.v0.models.OrderNote[];
}

export interface FetchOrderNotesFailure extends ErrorPayload {
  type: typeof ActionTypes.FETCH_ORDER_NOTES_FAILURE;
}

export interface FetchOrderDetailsRequest {
  type: typeof ActionTypes.FETCH_ORDER_DETAILS_REQUEST;
  options: WithPagingAndSortingOptions;
}

export interface FetchOrderDetailsSuccess {
  type: typeof ActionTypes.FETCH_ORDER_DETAILS_SUCCESS;
  payload: WithPagingAndSortingResult<io.flow.internal.v0.models.OrderDetail[]>;
  options: WithPagingAndSortingOptions;
}

export interface FetchOrderDetailsFailure extends ErrorPayload {
  type: typeof ActionTypes.FETCH_ORDER_DETAILS_FAILURE;
  options: WithPagingAndSortingOptions;
}

export interface FetchOrderVersionsRequest {
  type: typeof ActionTypes.FETCH_ORDER_VERSIONS_REQUEST;
}

export interface FetchOrderVersionsSuccess {
  type: typeof ActionTypes.FETCH_ORDER_VERSIONS_SUCCESS;
  payload: io.flow.v0.models.OrderVersion[];
}

export interface FetchOrderVersionsFailure extends ErrorPayload {
  type: typeof ActionTypes.FETCH_ORDER_VERSIONS_FAILURE;
}

export interface FetchOrderRefundSummaryRequest {
  type: typeof ActionTypes.FETCH_REFUND_ORDER_SUMMARY_REQUEST;
}

export interface FetchOrderRefundSummarySuccess {
  type: typeof ActionTypes.FETCH_REFUND_ORDER_SUMMARY_SUCCESS;
  payload: io.flow.v0.models.RefundOrderSummary;
}

export interface FetchOrderRefundSummaryFailure extends ErrorPayload {
  type: typeof ActionTypes.FETCH_REFUND_ORDER_SUMMARY_FAILURE;
}

export interface FetchOrderRefundSummaryNotFound extends ErrorPayload {
  type: typeof ActionTypes.FETCH_REFUND_ORDER_SUMMARY_NOT_FOUND;
}

export interface FetchOrderReturnSummaryRequest {
  type: typeof ActionTypes.FETCH_RETURN_ORDER_SUMMARY_REQUEST;
}

export interface FetchOrderReturnSummarySuccess {
  type: typeof ActionTypes.FETCH_RETURN_ORDER_SUMMARY_SUCCESS;
  payload: io.flow.v0.models.Tier;
}

export interface FetchOrderReturnSummaryFailure extends ErrorPayload {
  type: typeof ActionTypes.FETCH_RETURN_ORDER_SUMMARY_FAILURE;
}

export interface HideApproveFraudReviewDialog {
  type: typeof ActionTypes.HIDE_APPROVE_FRAUD_REVIEW_DIALOG;
}

export interface HideDeclineFraudReviewDialog {
  type: typeof ActionTypes.HIDE_DECLINE_FRAUD_REVIEW_DIALOG;
}

export interface HideOrderTrackingDetailDialog {
  type: typeof ActionTypes.HIDE_ORDER_TRACKING_DETAIL_DIALOG;
}

export interface HideRefundFormDialog {
  type: typeof ActionTypes.HIDE_REFUND_FORM_DIALOG;
}

export interface HideReturnConfirmation {
  type: typeof ActionTypes.HIDE_RETURN_CONFIRMATION;
}

export interface HideReturnDetailDialog {
  type: typeof ActionTypes.HIDE_RETURN_DETAIL_DIALOG;
}

export interface HideReturnFormDialog {
  type: typeof ActionTypes.HIDE_RETURN_FORM_DIALOG;
}

export interface HideReturnNumberInput {
  type: typeof ActionTypes.HIDE_RETURN_NUMBER_INPUT;
}

export interface ResetNotification {
  type: typeof ActionTypes.RESET_NOTIFICATION;
}

export interface ResetRefundOrderSummary {
  type: typeof ActionTypes.RESET_REFUND_ORDER_SUMMARY;
}

export interface SetNotification {
  type: typeof ActionTypes.SET_NOTIFICATION;
  payload: string;
}

export interface SetReturnItems {
  type: typeof ActionTypes.SET_RETURN_ITEMS;
  payload: io.flow.v0.models.LocalizedLineItem[];
}

export interface ShowApproveFraudReviewDialog {
  type: typeof ActionTypes.SHOW_APPROVE_FRAUD_REVIEW_DIALOG;
}

export interface ShowDeclineFraudReviewDialog {
  type: typeof ActionTypes.SHOW_DECLINE_FRAUD_REVIEW_DIALOG;
}

export interface ShowOrderTrackingDetailDialog {
  type: typeof ActionTypes.SHOW_ORDER_TRACKING_DETAIL_DIALOG;
  payload: {
    orderNumber: string;
    labelSummary?: io.flow.internal.v0.models.LabelSummary;
    shippingNotification?: io.flow.v0.models.ShippingNotification;
  };
}

export interface ShowOrderReturnDetailDialog {
  type: typeof ActionTypes.SHOW_RETURN_DETAIL_DIALOG;
  payload: {
    items: FullSelectedItem[];
    pdfLabel?: string;
    pdfInvoice?: string;
  };
}

export interface ShowRefundFormDialog {
  type: typeof ActionTypes.SHOW_REFUND_FORM_DIALOG;
}

export interface ShowReturnConfirmation {
  type: typeof ActionTypes.SHOW_RETURN_CONFIRMATION;
}

export interface ShowReturnNumberInput {
  type: typeof ActionTypes.SHOW_RETURN_NUMBER_INPUT;
}

export interface ShowReturnFormDialogRequest {
  type: typeof ActionTypes.FETCH_RETURN_SHIPPING_TIERS_REQUEST;
}

export interface ShowReturnFormDialogSuccess {
  type: typeof ActionTypes.FETCH_RETURN_SHIPPING_TIERS_SUCCESS;
  payload: io.flow.v0.models.Tier[];
}

export interface ShowReturnFormDialogFailure extends ErrorPayload {
  type: typeof ActionTypes.FETCH_RETURN_SHIPPING_TIERS_FAILURE;
}

export interface UpdateShippingAddressRequest {
  type: typeof ActionTypes.UPDATE_SHIPPING_ADDRESS_REQUEST;
}

export interface UpdateShippingAddressSuccess {
  type: typeof ActionTypes.UPDATE_SHIPPING_ADDRESS_SUCCESS;
  payload: io.flow.v0.models.Order;
}

export interface UpdateShippingAddressFailure extends ErrorPayload {
  type: typeof ActionTypes.UPDATE_SHIPPING_ADDRESS_FAILURE;
}

export interface CreateReturnRequest {
  type: typeof ActionTypes.CREATE_RETURN_REQUEST;
}

export interface CreateReturnSuccess {
  type: typeof ActionTypes.CREATE_RETURN_SUCCESS;
  payload: io.flow.v0.models.Return;
}

export interface CreateReturnFailure extends ErrorPayload {
  type: typeof ActionTypes.CREATE_RETURN_FAILURE;
}

export interface ShowReturnFormDialog {
  type: typeof ActionTypes.SHOW_RETURN_FORM_DIALOG;
}

export interface FetchInvoiceRequest {
  type: typeof ActionTypes.FETCH_INVOICES_REQUEST;
}

export interface FetchInvoiceSuccess {
  type: typeof ActionTypes.FETCH_INVOICES_SUCCESS;
  payload: {
    consumerInvoices: InvoiceProp[];
    creditMemos: InvoiceProp[];
    b2bInvoices: BusinessInvoiceProp[];
    b2bCreditMemos: BusinessInvoiceProp[];
  };
}

export interface FetchInvoiceFailure {
  type: typeof ActionTypes.FETCH_INVOICES_FAILURE;
  error: string;
  payload: GraphQLError;
}

export interface OrderNoteCreated {
  type: typeof ActionTypes.ORDER_NOTE_CREATED;
  payload: io.flow.internal.v0.models.OrderNote;
}

export interface OrderNoteEdited {
  type: typeof ActionTypes.ORDER_NOTE_EDITED;
  payload: io.flow.internal.v0.models.OrderNote;
}

export interface OrderNoteDeleted {
  type: typeof ActionTypes.ORDER_NOTE_DELETED;
  payload: string;
}

export interface ReplacementOrderSubmitted {
  type: typeof ActionTypes.REPLACEMENT_ORDER_SUBMITTED;
  payload: io.flow.v0.models.Order;
}

export interface FetchReplacementOrderBuilderRequest {
  type: typeof ActionTypes.FETCH_REPLACEMENT_ORDER_BUILDER_REQUEST;
}

export interface FetchReplacementOrderBuilderSuccess {
  type: typeof ActionTypes.FETCH_REPLACEMENT_ORDER_BUILDER_SUCCESS;
  payload: io.flow.v0.models.OrderBuilder | null;
}

export interface FetchReplacementOrderBuilderFailure extends ErrorPayload {
  type: typeof ActionTypes.FETCH_REPLACEMENT_ORDER_BUILDER_FAILURE;
}

export interface DebugTransactionsRequest {
  type: typeof ActionTypes.DEBUG_TRANSACTIONS_REQUEST;
}

export interface DebugTransactionsSuccess {
  type: typeof ActionTypes.DEBUG_TRANSACTIONS_SUCCESS;
  payload: io.flow.internal.v0.models.InternalDebugTransaction[];
}

export interface DebugTransactionsFailure extends ErrorPayload {
  type: typeof ActionTypes.DEBUG_TRANSACTIONS_FAILURE;
}

export type CustomerServiceActions =
  ShowReturnFormDialog |
  ShowReturnNumberInput |
  ShowOrderReturnDetailDialog |
  ShowReturnConfirmation |
  ShowRefundFormDialog |
  FetchInvoiceRequest |
  FetchInvoiceSuccess |
  FetchInvoiceFailure |
  CreateReturnRequest |
  CreateReturnSuccess |
  CreateReturnFailure |
  UpdateShippingAddressRequest |
  UpdateShippingAddressSuccess |
  UpdateShippingAddressFailure |
  ShowOrderTrackingDetailDialog |
  ShowDeclineFraudReviewDialog |
  ShowApproveFraudReviewDialog |
  SetReturnItems |
  SetNotification |
  ResetRefundOrderSummary |
  ResetNotification |
  HideReturnNumberInput |
  HideReturnFormDialog |
  HideReturnDetailDialog |
  HideReturnConfirmation |
  HideRefundFormDialog |
  ShowReturnFormDialogRequest |
  ShowReturnFormDialogSuccess |
  ShowReturnFormDialogFailure |
  HideOrderTrackingDetailDialog |
  HideDeclineFraudReviewDialog |
  HideApproveFraudReviewDialog |
  OrderRefundRequest |
  OrderRefundSuccess |
  OrderRefundFailure |
  FetchOrderAddressValidationAction |
  FetchAddressValidationUserAction |
  FetchOrderReturnSummaryRequest |
  FetchOrderReturnSummarySuccess |
  FetchOrderReturnSummaryFailure |
  FetchOrderDetailsRequest |
  FetchOrderDetailsSuccess |
  FetchOrderDetailsFailure |
  FetchOrderNotesRequest |
  FetchOrderNotesSuccess |
  FetchOrderNotesFailure |
  FetchOrderRefundSummaryRequest |
  FetchOrderRefundSummarySuccess |
  FetchOrderRefundSummaryFailure |
  FetchOrderRefundSummaryNotFound |
  FetchOrderVersionsRequest |
  FetchOrderVersionsSuccess |
  FetchOrderVersionsFailure |
  FetchOrderDetailRequest |
  FetchOrderDetailSuccess |
  FetchOrderDetailFailure |
  FetchOrdersRequest |
  FetchOrdersSuccess |
  FetchOrdersFailure |
  FetchAllocationsRequest |
  FetchAllocationsSuccess |
  FetchAllocationsFailure |
  CreateFraudReviewDecisionRequest |
  CreateFraudReviewDecisionSuccess |
  CreateFraudReviewDecisionFailure |
  ClearOrderRefundAmount |
  UpdateOrderRefundAmount |
  OrderNoteCreated |
  OrderNoteEdited |
  OrderNoteDeleted |
  ReplacementOrderSubmitted |
  FetchReplacementOrderBuilderRequest |
  FetchReplacementOrderBuilderSuccess |
  FetchReplacementOrderBuilderFailure |
  FetchShippingNotificationsAction |
  FetchShippingLabelAction |
  DebugTransactionsRequest |
  DebugTransactionsSuccess |
  DebugTransactionsFailure;
