import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames/bind';

import styles from './AnnotatedSection.module.css';

const cx = classNames.bind(styles);

const AnnotatedSectionGroup = ({
  className,
  children,
}) => (
  <div className={cx('annotatedSectionGroup', className)}>
    {children}
  </div>
);

AnnotatedSectionGroup.displayName = 'AnnotatedSectionGroup';

AnnotatedSectionGroup.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

AnnotatedSectionGroup.defaultProps = {
  className: '',
};

export default AnnotatedSectionGroup;
