import * as d3 from 'd3';
import React from 'react';
import get from 'lodash/get';
import map from 'lodash/map';
import isEqual from 'lodash/isEqual';
import reduce from 'lodash/reduce';
import noop from 'lodash/noop';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import debounce from 'lodash/debounce';
import numeral from 'numeral';
import { EventEmitter } from 'events';

import generateColorScale from '../../../../utilities/generate-color-scale';
import * as styles from './MetricByCountryChart.styles';

const height = 350;
const width = 350;
const expandedRadius = 160;
const radius = 144;
const innerRadius = 89;
const marginLeft = 30;
const marginTop = 35;
const chartPadding = 10;
const countFormatStringLong = '0,0';
const percentageFormatString = '0.0%';
const colorScale = generateColorScale();
const chartAttrs = {
  height,
  width,
  marginLeft,
  marginTop,
  chartPadding,
  expandedRadius,
  innerRadius,
  radius,
};
const arc = d3.arc<d3.PieArcDatum<MmmPieData>>()
  .outerRadius(chartAttrs.radius)
  .innerRadius(chartAttrs.innerRadius);
const arcOuter = d3.arc<d3.PieArcDatum<MmmPieData>>()
  .outerRadius(chartAttrs.expandedRadius)
  .innerRadius(chartAttrs.innerRadius);
const pie = d3.pie<MmmPieData>().value((d: MmmPieData) => d.value).sort(null);

interface Props {
  data: io.flow.internal.v0.models.OrderRevenueRegionChart;
  valueKey: string;
  eventListener: EventEmitter;
  organizationCountry: string;
  organizationCurrency: string;
}

interface State {
  currentlySelectedCountry: string;
  totalValue: number;
}

interface MmmPieData {
  id: string;
  label: string;
  value: number;
}

export default class MetricByCountryChart extends React.Component<Props, State> {
  static displayName = 'TimelineChart';

  static defaultProps = {
    valueKey: 'revenue.amount',
    eventListener: noop,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      currentlySelectedCountry: '',
      totalValue: 0,
    };
  }

  componentDidMount(): void {
    const { eventListener } = this.props;

    this.createResultsChart();

    eventListener.on('selectedNewCountry', (country) => {
      const { currentlySelectedCountry } = this.state;

      if (currentlySelectedCountry !== country) {
        this.updateSelectedCountry(country);
      }
    });
  }

  componentDidUpdate(prevProps: Props): void {
    const { valueKey, data, eventListener } = this.props;
    if (get(prevProps, 'valueKey') !== valueKey || !isEqual(get(prevProps, 'data'), data)) {
      d3.select(`.${styles.legendTable}`).remove();
      d3.select('.metric-by-country-chart').remove();
      this.createResultsChart();

      eventListener.on('selectedNewCountry', (country) => {
        const { currentlySelectedCountry } = this.state;

        if (currentlySelectedCountry !== country) {
          this.updateSelectedCountry(country);
        }
      });
    }
  }

  // Triggers on mouse over, it basically transisitons the selected arc to a enlarged version
  // Also changes the center text on the chart.
  onMouseOver = (
    d: d3.PieArcDatum<MmmPieData>,
    svg: { select: (selector: string) => d3.Selection<d3.BaseType, unknown, d3.BaseType, unknown> },
    totalValue: number,
    valueKey: string,
    organizationCountry: string,
    organizationCurrency: string,
  ): void => {
    const intlNumberFormatter = new Intl.NumberFormat(organizationCountry, {
      style: 'currency',
      currency: organizationCurrency,
      notation: 'compact',
      maximumSignificantDigits: 4,
    });
    const formattedValue = valueKey === 'count'
      ? numeral(d.data.value).format('0.[00]a')
      : intlNumberFormatter.format(d.data.value);

    svg.select(`.arc-${d.data.id}`)
      .transition()
      .duration(300)
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      .attr('d', arcOuter(d)!);

    svg.select('.country-label')
      .text(d.data.label);

    svg.select('.country-value')
      .text(formattedValue);

    svg.select('.country-percentage')
      .text(`${numeral((d.data.value / totalValue)).format(percentageFormatString)}`);
  };

  // Triggers on mouse out, it transisitions the selected arc to its normal size
  // and resets the text in the center of the chart.
  onMouseOut = (
    d: d3.PieArcDatum<MmmPieData>,
    svg: { select: (selector: string) => d3.Selection<d3.BaseType, unknown, d3.BaseType, unknown> },
    totalValue: number,
    valueKey: string,
    organizationCountry: string,
    organizationCurrency: string,
  ): void => {
    const intlNumberFormatter = new Intl.NumberFormat(organizationCountry, {
      style: 'currency',
      currency: organizationCurrency,
      notation: 'compact',
      maximumSignificantDigits: 4,
    });
    const formattedValue = valueKey === 'count'
      ? numeral(totalValue).format('0.[00]a')
      : intlNumberFormatter.format(totalValue);

    svg.select(`.arc-${d.data.id}`)
      .transition()
      .duration(300)
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      .attr('d', arc(d)!);

    svg.select('.country-label')
      .text('All Countries');

    svg.select('.country-value')
      .text(formattedValue);

    svg.select('.country-percentage').text('');
  };

  updateSelectedCountry = (newSelectedCountry: string): void => {
    const { totalValue, currentlySelectedCountry } = this.state;
    const { valueKey, organizationCountry, organizationCurrency } = this.props;
    const intlNumberFormatter = new Intl.NumberFormat(organizationCountry, {
      style: 'currency',
      currency: organizationCurrency,
      notation: 'compact',
      maximumSignificantDigits: 4,
    });

    const formattedTotalValue = valueKey === 'count'
      ? numeral(totalValue).format('0.[00]a')
      : intlNumberFormatter.format(totalValue);

    if (currentlySelectedCountry) {
      const arcExists = !d3.select(`.arc-${currentlySelectedCountry.toLowerCase()}`).empty();
      let selector = '';

      if (arcExists) {
        selector = `.arc-${currentlySelectedCountry.toLowerCase()}`;
      } else {
        selector = '.arc-other';
      }

      const arcData = d3.select<SVGPathElement, d3.PieArcDatum<MmmPieData>>(selector).data()[0];

      d3.select(selector)
        .transition()
        .duration(300)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .attr('d', arc(arcData)!);

      d3.select('.country-label')
        .text('All Countries');

      d3.select('.country-value')
        .text(formattedTotalValue);

      d3.select('.country-percentage').text('');
    }

    if (newSelectedCountry) {
      const arcExists = !d3.select(`.arc-${newSelectedCountry.toLowerCase()}`).empty();
      let selector = '';

      if (arcExists) {
        selector = `.arc-${newSelectedCountry.toLowerCase()}`;
      } else {
        selector = '.arc-other';
      }

      const arcData = d3.select<SVGPathElement, d3.PieArcDatum<MmmPieData>>(selector).data()[0];

      const formattedArcValue = valueKey === 'count'
        ? numeral(arcData.data.value).format('0.[00]a')
        : intlNumberFormatter.format(arcData.data.value);

      d3.select(selector)
        .transition()
        .duration(300)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .attr('d', arcOuter(arcData)!);

      d3.select('.country-label')
        .text(arcData.data.label);

      d3.select('.country-value')
        .text(formattedArcValue);

      d3.select('.country-percentage')
        .text(`${numeral((arcData.data.value / totalValue)).format(percentageFormatString)}`);
    }

    this.setState({
      currentlySelectedCountry: newSelectedCountry,
      totalValue,
    });
  };

  formatPieData = (
    data: io.flow.internal.v0.models.OrderRevenueRegionDataPoint[],
    valueKey: string,
  ): MmmPieData[] => {
    const formattedData = this.formatLegendData(data, valueKey);
    const topCountries = formattedData.splice(0, 7);
    const otherMetricValue = reduce(formattedData, (sum, datum) => sum + datum.value, 0);

    topCountries.push({
      id: 'other',
      label: 'Other',
      value: otherMetricValue,
    });

    return topCountries;
  };

  formatLegendData = (
    data: io.flow.internal.v0.models.OrderRevenueRegionDataPoint[],
    valueKey: string,
  ): MmmPieData[] => {
    const groupedData = groupBy(data, (datum) => get(datum, 'region.id'));
    return orderBy(map(groupedData, (datum) => {
      const totalMetric = reduce(datum, (sum, d) => sum + get(d, valueKey), 0);
      return {
        id: get(datum[0], 'region.id'),
        label: get(datum[0], 'region.name'),
        value: totalMetric,
      };
    }), ['value'], ['desc']);
  };

  // I used D3 to create the table because it gives an easy way to bind the dataset to each row
  // and trigger the hover interaction on the pie chart.
  createLegend(props: Props, formattedData: MmmPieData[], totalValue: number): void {
    const {
      valueKey,
      eventListener,
      organizationCountry,
      organizationCurrency,
    } = props;
    const svg = d3.select('#analyticsPieLegend');
    const table = svg.append('table').attr('class', styles.legendTable);
    const headerRow = table.append('thead').append('tr').attr('class', styles.tableHeaderRow);
    const tableBody = table.append('tbody');

    headerRow
      .append('td')
      .attr('class', styles.headerCell);

    headerRow
      .append('td')
      .attr('class', styles.headerCell)
      .html('Country');

    headerRow
      .append('td')
      .attr('class', styles.headerCellPadded)
      .html(valueKey === 'revenue.amount' ? 'Submitted Order Value' : 'Order Count');

    headerRow
      .append('td')
      .attr('class', styles.headerCellPadded)
      .html('%');

    // Creates the actual rows based on the passed in data.
    // You can imagine the data -> enter pattern to basically be a map
    // over all the data objects.
    tableBody.selectAll(`.${styles.legendRow}`)
      .data(formattedData)
      .enter()
      .append('tr')
      .attr('class', (d) => `${styles.legendRow} legend-row-${d.id}`)
      .on('mouseenter', debounce((d, index) => {
        const arcData = index > 6 ? d3.select<SVGPathElement, d3.PieArcDatum<MmmPieData>>('.arc-other').data()[0] : d3.select<SVGPathElement, d3.PieArcDatum<MmmPieData>>(`.arc-${d.id}`).data()[0];
        this.onMouseOver(
          arcData,
          d3,
          totalValue,
          valueKey,
          organizationCountry,
          organizationCurrency,
        );
        eventListener.emit('selectedNewCountry', d.id);
      }, 100))
      .on('mouseout', debounce((d, index) => {
        const arcData = index > 6 ? d3.select<SVGPathElement, d3.PieArcDatum<MmmPieData>>('.arc-other').data()[0] : d3.select<SVGPathElement, d3.PieArcDatum<MmmPieData>>(`.arc-${d.id}`).data()[0];
        this.onMouseOut(
          arcData,
          d3,
          totalValue,
          valueKey,
          organizationCountry,
          organizationCurrency,
        );
        eventListener.emit('selectedNewCountry', '');
      }, 100))
      .each((d, index) => {
        const row = d3.select(`.legend-row-${d.id}`);
        const colorIndex = index > 6 ? 7 : index;
        const intlNumberFormatter = new Intl.NumberFormat(organizationCountry, {
          style: 'currency',
          currency: organizationCurrency,
        });
        const formattedValue = valueKey === 'count'
          ? numeral(d.value).format(countFormatStringLong)
          : intlNumberFormatter.format(d.value);

        // The color is dynamic so I can't set it in the CSS
        row.append('td')
          .attr('class', styles.tableColor)
          .append('div')
          .attr('class', styles.colorDiv)
          .style('background-color', colorScale(colorIndex));

        row.append('td')
          .attr('class', styles.tableLabel)
          .html(d.label);

        row.append('td')
          .attr('class', styles.tableValue)
          .html(formattedValue);

        row.append('td')
          .attr('class', styles.tablePercentage)
          .html(`${numeral((d.value / totalValue)).format(percentageFormatString)}`);
      });
  }

  createResultsChart(): void {
    const {
      data,
      valueKey,
      eventListener,
      organizationCountry,
      organizationCurrency,
    } = this.props;
    const { currentlySelectedCountry } = this.state;
    const formattedData = this.formatPieData(data.data, valueKey);
    const totalValue = reduce(formattedData, (sum, datum) => sum + datum.value, 0);
    const intlNumberFormatter = new Intl.NumberFormat(organizationCountry, {
      style: 'currency',
      currency: organizationCurrency,
      notation: 'compact',
      maximumSignificantDigits: 4,
    });
    const formattedValue = valueKey === 'count'
      ? numeral(totalValue).format('0.[00]a')
      : intlNumberFormatter.format(totalValue);
    this.setState({
      totalValue,
      currentlySelectedCountry,
    });

    // Creates the svg that will hold the pie chart.
    const svg = d3.select('#analyticsPieChart')
      .append('svg')
      .attr('width', chartAttrs.width)
      .attr('height', chartAttrs.height)
      .classed('metric-by-country-chart', true)
      .append('g')
      .attr('class', 'pie-chart-container')
      .attr('transform', `translate(${chartAttrs.radius + chartAttrs.marginLeft}, ${chartAttrs.radius + chartAttrs.marginTop})`);

    // This actually creates the 'arcs' in the pie chart.
    // It enters each point of data passed in and creates the appropriate paths
    // based on the given data.
    svg.selectAll<SVGPathElement, d3.PieArcDatum<MmmPieData>>('.arc')
      .data<d3.PieArcDatum<MmmPieData>>(pie(formattedData))
      .enter()
      .append('path')
      .attr('class', (d) => `arc arc-${d.data.id}`)
      .attr('fill', (_d, index) => colorScale(index))
      .attr('d', (d) => arc(d))
      .on('mouseenter', debounce((d: d3.PieArcDatum<MmmPieData>) => {
        this.onMouseOver(d, svg, totalValue, valueKey, organizationCountry, organizationCurrency);
        eventListener.emit('selectedNewCountry', d.data.id);
      }, 100))
      .on('mouseout', debounce((d: d3.PieArcDatum<MmmPieData>) => {
        this.onMouseOut(d, svg, totalValue, valueKey, organizationCountry, organizationCurrency);
        eventListener.emit('selectedNewCountry', '');
      }, 100));

    // Appends the text elements that are inside the donut chart.
    svg.append('text')
      .attr('class', 'country-label')
      .text('All Countries')
      .style('font-size', '14')
      .style('font-weight', 'bold')
      .attr('text-anchor', 'middle')
      .attr('transform', 'translate(0, -23)');

    svg.append('text')
      .style('font-size', '14')
      .attr('class', 'country-value')
      .text(formattedValue)
      .attr('text-anchor', 'middle')
      .attr('transform', 'translate(0, 5)');

    svg.append('text')
      .style('font-size', '14')
      .attr('class', 'country-percentage')
      .attr('text-anchor', 'middle')
      .attr('transform', 'translate(0, 32)');

    this.createLegend(this.props, this.formatLegendData(data.data, valueKey), totalValue);
  }

  render(): JSX.Element {
    return (
      <div>
        <div className={styles.chartContainer} id="analyticsPieChart" />
        <div className={styles.legendContainer} id="analyticsPieLegend" />
      </div>
    );
  }
}
