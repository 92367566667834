import React from 'react';
import { ErrorOutline } from '@flowio/react-icons';
import { getRollbar } from '@flowio/redux-rollbar-middleware/lib/rollbar';
import { Banner } from '@flowio/react-banner';
import Rollbar, { LogArgument } from 'rollbar';
import { MergedProps as Props } from '../types';

interface State {
  error?: Error | null;
}

const isProduction = process.env.NODE_ENV === 'production';

export default class ContentErrorBoundary extends React.Component<Props, State> {
  static displayName = 'ContentErrorBoundary';

  constructor(props: Props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    getRollbar((rollbar: Rollbar, extra: LogArgument) => {
      rollbar.error(error, extra);
    });
    if (!isProduction) {
      // eslint-disable-next-line no-console
      console.error(error);
      // eslint-disable-next-line no-console
      console.error(errorInfo);
    }
  }

  reloadApplication = (): void => {
    const { organization } = this.props;
    window.location.assign(`/${organization || ''}`);
  };

  render(): React.ReactNode {
    const { error } = this.state;
    if (error !== null) {
      return (
        <Banner
          action={{
            content: 'Reload Application',
            onClick: this.reloadApplication,
          }}
          heading="Application Error"
          icon={ErrorOutline}
          intent="negative"
          text="Unfortunately an error has occurred. Our technical team have been notified."
        />
      );
    }
    const { children } = this.props;
    if (children != null) {
      return children;
    }

    return (
      <div>An unknown error occurred.</div>
    );
  }
}
