import React, { CSSProperties } from 'react';
import { InfoCircle } from '@flowio/react-icons';
import { Box } from '@flowio/react-box';
import { FlatButton } from '@flowio/react-button';
import { Popover } from '@flowio/react-popover';
import { Description, DescriptionGroup } from '../../../../components/description';
import * as accountSummaryStyles from './account-summary.styles';
import FormattedDate from '../../../../components/formatted-date';

interface StyleTypes {
  iconButton: CSSProperties;
  infoIcon: CSSProperties;
  tooltip: CSSProperties;
  tooltipText: CSSProperties;
}

const styles: StyleTypes = {
  iconButton: {
    width: 16,
    height: 16,
    padding: 0,
  },
  infoIcon: {
    width: 16,
    height: 16,
  },
  tooltip: {
    display: 'block',
    width: 260,
    padding: '12px 16px',
    backgroundColor: '#808080',
    boxSizing: 'border-box',
    borderRadius: 6,
    color: '#ffffff',
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: '1.25',
    textAlign: 'center',
    whiteSpace: 'normal',
  },
  tooltipText: {
    whiteSpace: 'normal',
  },
};

interface AccountSummaryProps {
  account: io.flow.internal.v0.models.OrganizationAccount;
}

const AccountSummary: React.FunctionComponent<AccountSummaryProps> = ({ account }) => (
  <DescriptionGroup className="account-summary" spacing={false}>
    <Description>
      <Description.Term content="Current Balance" />
      <Description.Definition>
        <span className={accountSummaryStyles.balance}>
          {account.statistics.balance.label}
        </span>
        <span className={accountSummaryStyles.pending}>
          (
          {account.statistics.pending.label}
          {' '}
          pending)
        </span>
      </Description.Definition>
    </Description>
    <Description
      term="Transfers, YTD"
      definition={account.statistics.transfers.label}
    />
    <Description
      term="Currency"
      definition={account.currency}
    />
    <Description>
      <Description.Term content="Account Timezone" />
      <Description.Definition>
        <Box alignItems="center">
          <span>
            {account.timezone.description}
          </span>
          <Popover
            openOnHover
            hoverOpenDelay={0}
            position="top"
            offset="0px -5px"
            trigger={(
              <FlatButton intent="neutral">
                <InfoCircle className={accountSummaryStyles.infoIcon} />
              </FlatButton>
            )}
          >
            <div style={styles.tooltip}>
              <span style={styles.tooltipText}>
                This is the timezone reflected on all the statements and transactions.
              </span>
            </div>
          </Popover>
        </Box>
      </Description.Definition>
    </Description>
    <Description>
      <Description.Term content="Next Statement" />
      <Description.Definition>
        <Box alignItems="center">
          <FormattedDate
            format="LL"
            utc
            timezone={account.timezone.name}
            value={account.next_statement.date}
          />
          {account.payment_conditions && (
            <Popover
              openOnHover
              hoverOpenDelay={0}
              position="top"
              offset="0px -5px"
              trigger={(
                <FlatButton intent="neutral">
                  <InfoCircle className={accountSummaryStyles.infoIcon} />
                </FlatButton>
              )}
            >
              <div style={styles.tooltip}>
                <span style={styles.tooltipText}>
                  Unless
                  {' '}
                  {account.payment_conditions}
                  , in which case next
                  payment will be sent immediately.
                </span>
              </div>
            </Popover>
          )}
        </Box>
      </Description.Definition>
    </Description>
  </DescriptionGroup>
);

AccountSummary.displayName = 'AccountSummary';

export default AccountSummary;
