import { ActionTypes } from '../constants';
import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';

function createRequestAction(): RootActionTypes {
  return {
    type: ActionTypes.FETCH_RATECARDS_REQUEST,
  };
}

function createSuccessAction(
  payload: io.flow.v0.models.Ratecard[],
): RootActionTypes {
  return {
    type: ActionTypes.FETCH_RATECARDS_SUCCESS,
    payload,
  };
}

function createFailureAction(payload: FormattedErrorMessages): RootActionTypes {
  return {
    type: ActionTypes.FETCH_RATECARDS_FAILURE,
    payload,
    error: true,
  };
}

// TODO: One day an organization may have more ratecards than maximum number of
// resources fetchable per page, which would require refactor to support
// carrier service level selections.
function fetchRatecards(organizationId: string): ThunkResult<Promise<void>> {
  return function fetchRatecardsEffect(
    dispatch,
    getState,
    extra,
  ): Promise<void> {
    dispatch(createRequestAction());

    return extra.api(getState()).ratecards.get({
      organization: organizationId,
      limit: 100,
    }).then((response) => {
      if (response.ok) {
        dispatch(createSuccessAction(response.body));
        return;
      }

      const errors = formatErrorResponseV2(response);
      dispatch(createFailureAction(errors));
    });
  };
}

export default fetchRatecards;
