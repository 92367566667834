import { Field } from 'redux-form';
import { Box } from '@flowio/react-box';
import { FormGroup, FormGroupIntent } from '@flowio/react-form-group';
import { RadioButton } from '@flowio/react-radio-button';
import React from 'react';
import { Text } from '@flowio/react-text';
import find from 'lodash/find';
import { css } from '@emotion/css';
import { ShippingConfigurationMethodProps } from '../../types/components';
import { SelectField } from '../../../../components/ReduxFormFieldAdapters';

const radioLabel = css`
  padding: 15px
`;

const ShippingConfigurationMethod: React.FC<
ShippingConfigurationMethodProps
> = ({ input, meta, shippingConfigurations }) => {
  let feedbackText;
  let intent: FormGroupIntent = 'neutral';

  if (meta.error && meta.touched) {
    feedbackText = meta.error;
    intent = 'negative';
  } else if (meta.warning && meta.touched) {
    feedbackText = meta.warning;
    intent = 'warning';
  }

  return (
    <FormGroup
      feedback={feedbackText}
      inline
      intent={intent}
      labelText="Method"
    >
      <RadioButton
        checked={input.value === 'pristine'}
        fluid
        name={input.name}
        onBlur={input.onBlur}
        onChange={input.onChange}
        onFocus={input.onFocus}
        value="pristine"
      >
        <Text
          className={radioLabel}
          intent="textSecondary"
        >
          Start with a blank shipping configuration
        </Text>
      </RadioButton>
      <RadioButton
        checked={input.value === 'duplicate'}
        fluid
        name={input.name}
        onBlur={input.onBlur}
        onChange={input.onChange}
        onFocus={input.onFocus}
        value="duplicate"
      >
        <Box className={radioLabel} direction="column" inline spacing="loose">
          <Text
            intent="textSecondary"
          >
            Duplicate from existing shipping configurations
          </Text>
          <Field
            name="shippingConfigurationKey"
            component={SelectField}
            disabled={input.value !== 'duplicate'}
            fluid
            format={(
              value: io.flow.v0.models.ShippingConfiguration,
            ) => find(shippingConfigurations, { key: value })}
            labelKey="name"
            options={shippingConfigurations}
            parse={(option: io.flow.v0.models.ShippingConfiguration) => option.key}
            selection
            valueKey="key"
          />
        </Box>
      </RadioButton>
    </FormGroup>
  );
};

ShippingConfigurationMethod.displayName = 'ShippingConfigurationMethod';

export default ShippingConfigurationMethod;
