import React from 'react';

import {
  Dialog, DialogHeader, DialogFooter, DialogBody,
} from '@flowio/react-dialog';
import { Button } from '@flowio/react-button';

import ExperienceSettingsExportForm from './ExperienceSettingsExportForm';
import * as styles from './ExperienceSettingsExport.styles';

interface ExperienceSettingsExportProps {
  isOpen: boolean;
  onCloseExportDialog: () => void;
  onSubmitExport: () => void;
}

const ExperienceSettingsExport: React.FC<ExperienceSettingsExportProps> = ({
  isOpen,
  onCloseExportDialog,
  onSubmitExport,
}) => (
  <Dialog
    className={styles.exportDialog}
    backdrop
    closeOnEscape
    open={isOpen}
  >
    <DialogHeader content="Export Experience Settings" />
    <DialogBody>
      <div>
        An email containing the current settings of all Experiences will be sent.
        Please note this might take a few minutes.
      </div>
      {/* Need this because a form inside a dialog technically isnt a child. This means
          Form will never think it is in a component decorated with reduxForm */}
      <ExperienceSettingsExportForm />
    </DialogBody>
    <DialogFooter className={styles.exportDialogFooter}>
      <Button className={styles.cancelBtn} content="Cancel" onClick={onCloseExportDialog} />
      <Button className={styles.exportBtn} content="Export" intent="primary" onClick={onSubmitExport} />
    </DialogFooter>
  </Dialog>
);

export default ExperienceSettingsExport;
