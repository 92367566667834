import React, { FunctionComponent } from 'react';
import isEmpty from 'lodash/isEmpty';
import lowerCase from 'lodash/lowerCase';
import map from 'lodash/map';
import { css } from '@emotion/css';
import { colors } from '../../../../theme/tokens';

const productDescriptionStyles = css`
  margin-top: 1rem;
  & dl {
    margin: 0;
    padding: 0;
  }

  & dt {
    color: ${colors.charcoal};
    font-weight: 500;
    font-size: 0.875rem;
    text-transform: capitalize;

    &:not(:first-child) {
      margin-top: 1rem;
    }
  }

  & dd {
    margin: 0.5rem 0 0;
    color: ${colors.steel};
    font-size: 0.875rem;
    word-break: break-all;
  }
`;

const displayName = 'ProductDescription';

const defaultProps = {
  attributes: undefined,
  categories: [],
  description: undefined,
};

interface ProductDescriptionProps {
  attributes?: object;
  categories: string[];
  description?: string;
}

const ProductDescription: FunctionComponent<ProductDescriptionProps> = ({
  attributes,
  categories = [],
  description,
}) => (
  <section className={productDescriptionStyles}>
    <dl>
      {description && (
        <>
          <dt>Description</dt>
          ,
          <dd>{description}</dd>
        </>
      )}
      {!isEmpty(categories) && (
        <>
          <dt>Categories</dt>
          <dd>{categories.join(', ')}</dd>
        </>
      )}
      {!isEmpty(attributes) && map(attributes, (attribute, name) => (
        <>
          <dt>{lowerCase(name)}</dt>
          <dd>{attribute}</dd>
        </>
      ))}
    </dl>
  </section>
);

ProductDescription.displayName = displayName;
ProductDescription.defaultProps = defaultProps;

export default ProductDescription;
