import { css } from '@emotion/css';

export const header = css`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const loadingContainer = css`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

export const loadingIcon = css`
  width: 64px;
  height: auto;
`;

export const loadingText = css`
  display: block;
  margin-top: 1rem;
  font-size: 14px;
  text-align: center;
`;
