import assign from 'lodash/assign';
import filter from 'lodash/filter';
import map from 'lodash/map';
import update from 'immutability-helper';
import ActionTypes from '../constants/action-types';

import { RootActionTypes } from '../../../stores/types';
import { MembershipState } from '../types';

const defaultState: MembershipState = {
  memberships: [],
};

export default function membershipsReducer(
  state: MembershipState = defaultState,
  action: RootActionTypes,
): MembershipState {
  switch (action.type) {
    case ActionTypes.FETCH_MEMBERSHIPS_SUCCESS: {
      if (action.payload.length === 0) {
        return state;
      }
      const newState: MembershipState = {
        ...state,
        memberships: [
          ...state.memberships,
        ],
      };

      action.payload.forEach((val) => {
        const index = state.memberships.findIndex((membership) => (
          membership.organization.id === val.organization.id
          && membership.user.id === val.user.id
        ));
        if (index > -1) {
          newState.memberships[index] = val;
        } else {
          newState.memberships.push(val);
        }
      });

      return newState;
    }
    case ActionTypes.CHANGE_ROLE_SUCCESS:
      return update(state, {
        memberships: {
          $set: map(state.memberships, (membership) => {
            if (membership.id === action.payload.id) {
              return assign({}, membership, { role: action.payload.role });
            }
            return membership;
          }),
        },
      });
    case ActionTypes.REMOVE_MEMBERSHIP_SUCCESS:
      return update(state, {
        memberships: {
          $set: filter(
            state.memberships,
            (membership) => membership.id !== action.params.id,
          ),
        },
      });
    case ActionTypes.SET_EDITING_MEMBERSHIP:
      if (action.payload) {
        return update(state, {
          editingMembership: { $set: action.payload },
        });
      }
      return update(state, {
        $unset: ['editingMembership'],
      });
    default:
      return state;
  }
}
