import uniqueId from 'lodash/uniqueId';
import get from 'lodash/get';
import ActionTypes from '../constants/ActionType';

import createQueryBuilder from '../utilities/createQueryBuilder';
import { toQueryBuilderQueryForm } from '../../features/utilities';
import { ThunkResult } from '../../../stores/types';

export default function showReturnPolicyWorksheet(
  organizationId: string,
  returnPolicy: io.flow.v0.models.ReturnPolicy,
): ThunkResult<Promise<void>> {
  return function showReturnPolicyWorksheetEffects(dispatch, getState) {
    const state = getState();
    dispatch({
      type: ActionTypes.ShowReturnPolicyWorksheetRequest,
      payload: {
        organizationId,
        returnPolicy,
      },
    });
    return createQueryBuilder(
      state,
      organizationId,
      toQueryBuilderQueryForm(get(returnPolicy, 'q')),
    ).then((queryBuilder: io.flow.v0.models.QueryBuilder) => {
      dispatch({
        type: ActionTypes.ShowReturnPolicyWorksheetSuccess,
        payload: {
          returnPolicy,
          queryBuilder: {
            ...queryBuilder,
            filters: queryBuilder.filters.map((filter: io.flow.v0.unions.QueryFilter) => ({
              ...filter, // Add a unique identifier that we can use as the component key for
              // FLIP animation to work correctly.
              // https://github.com/erikras/redux-form/issues/2735
              uniqueId: uniqueId('filter'),
            })),
          },
        },
      });
    }).catch((error: any) => {
      dispatch({
        type: ActionTypes.ShowReturnPolicyWorksheetFailure,
        payload: error.response ? error.response.result : error,
      });
    });
  };
}
