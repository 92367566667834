import { Card } from '@flowio/react-card';
import styled from '@emotion/styled';
import { Image } from '@flowio/react-image';
import React from 'react';
import Button from '../../MarketingButton';
import { OwnProps } from '../types';
import * as styles from './PlatformAccountCard.styles';
import Platforms from '../../../constants/Platforms';
import { boxShadow } from '../../../styles/styles';

const StyledButton = styled(Button)`
  margin-right: 10px;
`;

const PlatformAccountCard: React.FC<OwnProps> = React.memo(({
  platform,
  buttons,
  children,
}) => (
  <Card
    data-automation-id="platform-account-card"
    className={styles.platformCard}
    css={boxShadow('7px 7px 15px 0 #eaecf2')}
  >
    <div className={styles.heading}>
      <Image
        accessibilityLabel={`${platform} logo`}
        source={`https://cdn.flow.io/console/logo_${platform}.svg`}
      />
      <h2>{Platforms(platform).platformName}</h2>
      {buttons && buttons.map((b) => <StyledButton className={b.intent} {...b} />) }
    </div>
    {children}
  </Card>
));

PlatformAccountCard.displayName = 'PlatformAccountCard';

export default PlatformAccountCard;
