import React from 'react';
import classNames from 'classnames';

import * as styles from './StatusLabel.styles';

type Props = {
  content: string;
  intent: keyof typeof styles;
};

const StatusLabel: React.FC<Props> = ({
  content,
  intent = 'neutral',
}) => (
  // eslint-disable-next-line import/namespace
  <div className={classNames(styles.content, styles[intent])}>
    {content}
  </div>
);

StatusLabel.displayName = 'StatusLabel';

export default StatusLabel;
