import { ThunkResult } from '../../../stores/types';
import ActionTypes from '../constants/action-types';
import { RuleFilter } from '../types/components';
import {
  createQueryBuilder,
  toQueryBuilderQueryForm,
} from '../utilities';

export default function createFiltersForRuleQueries(
  organizationId: string,
  rules: io.flow.internal.v0.unions.FeatureRule[] = [],
): ThunkResult<Promise<void>> {
  return function createFiltersForRuleQueriesSideEffects(
    dispatch,
    getState,
  ): Promise<void> {
    dispatch({ type: ActionTypes.FETCH_RULE_QUERY_FILTER_REQUEST });
    const promises: Promise<RuleFilter>[] = rules.map(
      (rule) => createQueryBuilder(
        getState(),
        organizationId,
        toQueryBuilderQueryForm(rule.query),
      ).then((result) => ({
        id: rule.id,
        filters: result.filters,
      })),
    );

    return Promise.all(promises).then((ruleFilters) => {
      dispatch({
        type: ActionTypes.FETCH_RULE_QUERY_FILTER_SUCCESS,
        payload: ruleFilters,
      });
    }).catch((error) => {
      if (error.response) {
        dispatch({
          type: ActionTypes.FETCH_RULE_QUERY_FILTER_FAILURE,
          payload: error.response.result,
        });
      }
    });
  };
}
