import { combineReducers } from 'redux';

import accountsPagination from './accounts-pagination';
import accounts from './accounts';
import account from './current-account';
import statementsPagination from './statements-pagination';
import statements from './statements';
import transactionsPagination from './transactions-pagination';
import transactions from './transactions';
import { RootActionTypes } from '../../../stores/types';
import { BillingState, BillingPaginationsState } from '../types';

export default combineReducers<BillingState, RootActionTypes>({
  account,
  entities: combineReducers({
    accounts,
    statements,
    transactions,
  }),
  paginations: combineReducers<BillingPaginationsState, RootActionTypes>({
    accounts: accountsPagination,
    statements: statementsPagination,
    transactions: transactionsPagination,
  }),
});
