import {
  FormGroup, FormGroupDescriptionShorthand,
  FormGroupFeedbackShorthand, FormGroupHintShorthand,
  FormGroupIntent, FormGroupSize,
} from '@flowio/react-form-group';
import React from 'react';
import { TagInput, TagInputIntent } from '@flowio/react-tag-input';
import { WrappedFieldInputProps, WrappedFieldMetaProps, WrappedFieldProps } from 'redux-form';
import defaultShouldError from './utilities/defaultShouldError';
import defaultShouldWarn from './utilities/defaultShouldWarn';

type FormGroupProps = {
  children?: never;
  description?: FormGroupDescriptionShorthand;
  feedback?: FormGroupFeedbackShorthand;
  gutter?: boolean;
  hint?: FormGroupHintShorthand;
  inline?: boolean;
  intent?: FormGroupIntent;
  labelCols?: number
  labelFor?: string;
  labelIcon?: React.ComponentType | React.ReactElement;
  labelText?: string;
  labelSize?: FormGroupSize;
};

  type UnhandledTagInputProps = Omit<typeof TagInput, keyof FormGroupProps>;

  type TagInputFormProps = FormGroupProps & UnhandledTagInputProps;

type ReduxFormTagInputProps = WrappedFieldProps &
{
  shouldError?: (
    input: WrappedFieldInputProps, meta: WrappedFieldMetaProps, props?: any
  ) => boolean,
  shouldWarn?: (input: WrappedFieldInputProps, meta: WrappedFieldMetaProps, props?: any) => boolean
} & TagInputFormProps;

const ReduxFormTagInput: React.FC<ReduxFormTagInputProps> = (props) => {
  const {
    input, meta, shouldWarn, shouldError, children, ...unhandledProps
  } = props;

  const { error, warning } = meta;

  let errorText;
  let intent: TagInputIntent = 'neutral';

  if (error && shouldError && shouldError(input, meta, unhandledProps)) {
    errorText = error;
    intent = 'negative';
  } else if (warning && shouldWarn && shouldWarn(input, meta, unhandledProps)) {
    errorText = warning;
    intent = 'warning';
  }

  const handleValueChange = (value: string[]) => {
    input.onChange([...value]);
  };

  const handleOnBlur = () => {
    // on passing any value causes error and page breaks when blur event is emitted.
    input.onBlur(undefined);
  };

  const {
    description,
    feedback,
    gutter,
    hint,
    inline,
    labelCols,
    labelFor,
    labelIcon,
    labelSize,
    labelText,
    ...tagInputProps
  } = unhandledProps;

  return (
    <FormGroup
      description={description}
      feedback={errorText}
      gutter={gutter}
      hint={hint}
      inline={inline}
      intent={intent}
      labelCols={labelCols}
      labelFor={labelFor}
      labelIcon={labelIcon}
      labelSize={labelSize}
      labelText={labelText}
    >
      <TagInput
        {...tagInputProps}
        name={input.name}
        onFocus={input.onFocus}
        onBlur={handleOnBlur}
        onValueChange={handleValueChange}
        onRemove={handleValueChange}
        value={input.value}
        intent={intent}
        id={labelFor}
      />
    </FormGroup>
  );
};

ReduxFormTagInput.defaultProps = {
  shouldError: defaultShouldError,
  shouldWarn: defaultShouldWarn,
};

export default ReduxFormTagInput;
