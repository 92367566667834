import keyMirror from '../../../utilities/keymirror';

const ActionTypes = keyMirror({
  CREATE_REFUND_FAILURE: null,
  CREATE_REFUND_REQUEST: null,
  CREATE_REFUND_SUCCESS: null,
  CREATE_RETURN_FAILURE: null,
  CREATE_RETURN_REQUEST: null,
  CREATE_RETURN_SUCCESS: null,
  CREATE_FRAUD_REVIEW_DECISION_SUCCESS: null,
  CREATE_FRAUD_REVIEW_DECISION_FAILURE: null,
  CREATE_FRAUD_REVIEW_DECISION_REQUEST: null,
  FETCH_ALLOCATIONS_REQUEST: null,
  FETCH_ALLOCATIONS_SUCCESS: null,
  FETCH_ALLOCATIONS_FAILURE: null,
  FETCH_INVOICES_REQUEST: null,
  FETCH_INVOICES_SUCCESS: null,
  FETCH_INVOICES_FAILURE: null,
  FETCH_ORDER_VERSIONS_REQUEST: null,
  FETCH_ORDER_VERSIONS_SUCCESS: null,
  FETCH_ORDER_VERSIONS_FAILURE: null,
  FETCH_ORDERS_REQUEST: null,
  FETCH_ORDERS_SUCCESS: null,
  FETCH_ORDERS_FAILURE: null,
  FETCH_ORDER_DETAIL_FAILURE: null,
  FETCH_ORDER_DETAIL_REQUEST: null,
  FETCH_ORDER_DETAIL_SUCCESS: null,
  FETCH_ORDER_DETAILS_FAILURE: null,
  FETCH_ORDER_DETAILS_REQUEST: null,
  FETCH_ORDER_DETAILS_SUCCESS: null,
  FETCH_ORDER_NOTES_FAILURE: null,
  FETCH_ORDER_NOTES_REQUEST: null,
  FETCH_ORDER_NOTES_SUCCESS: null,
  FETCH_REFUND_ORDER_SUMMARY_FAILURE: null,
  FETCH_REFUND_ORDER_SUMMARY_NOT_FOUND: null,
  FETCH_REFUND_ORDER_SUMMARY_REQUEST: null,
  FETCH_REFUND_ORDER_SUMMARY_SUCCESS: null,
  FETCH_RETURN_ORDER_SUMMARY_FAILURE: null,
  FETCH_RETURN_ORDER_SUMMARY_REQUEST: null,
  FETCH_RETURN_ORDER_SUMMARY_SUCCESS: null,
  FETCH_RETURN_SHIPPING_TIERS_FAILURE: null,
  FETCH_RETURN_SHIPPING_TIERS_REQUEST: null,
  FETCH_RETURN_SHIPPING_TIERS_SUCCESS: null,
  HIDE_APPROVE_FRAUD_REVIEW_DIALOG: null,
  HIDE_DECLINE_FRAUD_REVIEW_DIALOG: null,
  HIDE_REFUND_FORM_DIALOG: null,
  HIDE_RETURN_CONFIRMATION: null,
  HIDE_RETURN_DETAIL_DIALOG: null,
  HIDE_RETURN_FORM_DIALOG: null,
  SET_RETURN_ITEMS: null,
  HIDE_ORDER_TRACKING_DETAIL_DIALOG: null,
  SHOW_APPROVE_FRAUD_REVIEW_DIALOG: null,
  SHOW_DECLINE_FRAUD_REVIEW_DIALOG: null,
  SHOW_ORDER_TRACKING_DETAIL_DIALOG: null,
  SHOW_RETURN_CONFIRMATION: null,
  SHOW_REFUND_FORM_DIALOG: null,
  SHOW_RETURN_DETAIL_DIALOG: null,
  SHOW_RETURN_FORM_DIALOG: null,
  SHOW_RETURN_NUMBER_INPUT: null,
  HIDE_RETURN_NUMBER_INPUT: null,
  SET_NOTIFICATION: null,
  RESET_NOTIFICATION: null,
  RESET_REFUND_ORDER_SUMMARY: null,
  UPDATE_SHIPPING_ADDRESS_REQUEST: null,
  UPDATE_SHIPPING_ADDRESS_SUCCESS: null,
  UPDATE_SHIPPING_ADDRESS_FAILURE: null,
  UPDATE_REFUND_AMOUNT: null,
  CLEAR_REFUND_AMOUNT: null,
  ORDER_NOTE_CREATED: null,
  ORDER_NOTE_EDITED: null,
  ORDER_NOTE_DELETED: null,
  FETCH_REPLACEMENT_ORDER_BUILDER_REQUEST: null,
  FETCH_REPLACEMENT_ORDER_BUILDER_SUCCESS: null,
  FETCH_REPLACEMENT_ORDER_BUILDER_FAILURE: null,
  DEBUG_TRANSACTIONS_REQUEST: null,
  DEBUG_TRANSACTIONS_SUCCESS: null,
  DEBUG_TRANSACTIONS_FAILURE: null,
  REPLACEMENT_ORDER_UPDATED: null,
  REPLACEMENT_ORDER_SUBMITTED: null,
}, 'CUSTOMER_SERVICE');

export default ActionTypes;
