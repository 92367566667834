import {
  getVariantName,
} from '../components/experiment-details/utilities/checkVariant';

const GLOBAL_BANNER_HEADING = 'This is a global experiment testing Flow Features across all clients.';
const GLOBAL_BANNER_SUBHEADING = 'Traffic of all live global experiments is capped to 15% of each client\'s overall traffic. Flow\'s commercial team will be notified of the experiment setup immediately, and this experiment will launch in 3 days.';
const NOT_SIGNIFICANT_HEADING = 'Experiment results may not be statistically significant. We recommend the test continue.';
const SIGNIFICANCE_DEFINITION = 'An experiment is classified as statistically significant when a 95% confidence has been reached and at least a 14 day period has passed.';
const SCHEDULED_TOAST_MESSAGE = 'Experiment will start in 3 days. An email has been sent to the Flow team';
const LAUNCHED_TOAST_MESSAGE = 'Experiment is now live!';
export const getVariantSuccessMsg = (winningVariant: io.flow.internal.v0.unions.Variant) => `${getVariantName(winningVariant)} - was succesfully implemented`;

export default {
  GLOBAL_BANNER_HEADING,
  GLOBAL_BANNER_SUBHEADING,
  NOT_SIGNIFICANT_HEADING,
  SIGNIFICANCE_DEFINITION,
  SCHEDULED_TOAST_MESSAGE,
  LAUNCHED_TOAST_MESSAGE,
};
