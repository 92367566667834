import { css } from '@emotion/css';
import shadow from '../../../../theme/tokens/shadows';
import colors from '../../../../theme/tokens/colors';

export const authLayout = css`
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
`;

export const content = css`
  display: flex;
  flex-flow: column;
  width: 320px;
  margin: 156px 0 0;
  padding: 40px 32px 24px;
  border-radius: 4px;
  box-shadow: ${shadow.light};
  box-sizing: border-box;
  background-color: ${colors.white};

  & p {
    margin: 0 0 1rem;
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: ${colors.steel};
  }
`;

export const footer = css`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 64px;
  font-family: Roboto;
  font-size: 12px;
  line-height: 1.25;
  color: #77818f;
`;

export const logo = css`
  width: 150px;
  height: 42px;
  margin-bottom: 40px;
  align-self: center;
`;
