import React from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableHeadColumn,
  TableBody,

} from '@flowio/react-table';
import noop from 'lodash/noop';
import ExperimentsListTableRow from './experimentsListTableRow';

interface ExperimentListTableProps {
  experiments: io.flow.internal.v0.unions.Experiment[];
  onEditExperiment: (key: string, experiment: io.flow.internal.v0.unions.Experiment[]) => void;
  onViewExperiment: (key: string) => void;
  onUpdateExperimentStatus: (
    experiment: io.flow.internal.v0.unions.Experiment,
    status: io.flow.internal.v0.enums.Status,
  ) => void;
  onDeleteExperiment: (key: string) => void;
}

const ExperimentListTable: React.FC<ExperimentListTableProps> = ({
  experiments,
  onEditExperiment,
  onViewExperiment,
  onUpdateExperimentStatus,
  onDeleteExperiment,
}) => (
  <Table striped displayDensity="compact">
    <TableHead>
      <TableRow>
        <TableHeadColumn>
          Name
        </TableHeadColumn>
        <TableHeadColumn>
          Type
        </TableHeadColumn>
        <TableHeadColumn>
          Status
        </TableHeadColumn>
        <TableHeadColumn>
          Start Date
        </TableHeadColumn>
        <TableHeadColumn>
          End Date
        </TableHeadColumn>
        <TableHeadColumn />
      </TableRow>
    </TableHead>
    <TableBody>
      {experiments.map((experiment, index) => (
        <ExperimentsListTableRow
          currentIndex={index}
          key={experiment.key}
          experiment={experiment}
          onEditExperiment={(k: string): void => onEditExperiment(k, experiments)}
          onViewExperiment={onViewExperiment}
          onUpdateExperimentStatus={onUpdateExperimentStatus}
          onDeleteExperiment={onDeleteExperiment}
        />
      ))}
    </TableBody>
  </Table>
);

ExperimentListTable.displayName = 'ExperimentListTable';

ExperimentListTable.defaultProps = {
  experiments: [],
  onEditExperiment: noop,
  onViewExperiment: noop,
  onUpdateExperimentStatus: noop,
  onDeleteExperiment: noop,
};

export default ExperimentListTable;
