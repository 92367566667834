import { RootActionTypes, ThunkResult } from '../../../stores/types';
import { ActionTypes } from '../constants';

import createCenterQueryBuilder from './createCenterQueryBuilder';
import updateShippingLane from './updateShippingLane';

interface CreateCenterQueryBuilderAndUpdateShippingLaneOptions {
  organizationId: string;
  queryBuilderForm: io.flow.v0.unions.QueryBuilderForm;
  regionId: string;
  shippingConfigurationKey: string;
  shippingLaneId: string;
  shippingLaneDirection: io.flow.v0.enums.LaneDirection;
}

const createCenterQueryBuilderAndUpdateShippingLane = ({
  organizationId,
  queryBuilderForm,
  regionId,
  shippingConfigurationKey,
  shippingLaneId,
  shippingLaneDirection,
}:
CreateCenterQueryBuilderAndUpdateShippingLaneOptions): ThunkResult<Promise<RootActionTypes>> => (
  dispatch,
) => dispatch(createCenterQueryBuilder(
  organizationId,
  queryBuilderForm,
)).then((result) => {
  if (result.type === ActionTypes.CREATE_CENTER_QUERY_BUILDER_FAILURE) {
    return Promise.resolve(result);
  }

  const queryBuilder = result.payload;
  const shippingLaneForm = { from: queryBuilder.q, to: regionId, direction: shippingLaneDirection };
  return dispatch(updateShippingLane({
    organizationId,
    shippingConfigurationKey,
    shippingLaneId,
    shippingLaneForm,
  }));
});

export default createCenterQueryBuilderAndUpdateShippingLane;
