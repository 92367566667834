import { RSAA } from '@flowio/redux-async-middleware';
import ApiPropTypes from '@flowio/api-prop-types';
import PropTypes from 'prop-types';

import { ActionTypes } from '../constants';
import { apiInternal } from '../../../utilities/clients';
import { createSuccessDescriptor, createFailureDescriptor } from '../../../utilities/redux-async-middleware';
import { withAssertPropTypes } from '../../../utilities/prop-types';

const createCenterQueryBuilder = ({
  organizationId,
  queryBuilderForm,
}) => ({
  [RSAA]: {
    types: [
      ActionTypes.CREATE_CENTER_QUERY_BUILDER_REQUEST,
      createSuccessDescriptor({ type: ActionTypes.CREATE_CENTER_QUERY_BUILDER_SUCCESS }),
      createFailureDescriptor({ type: ActionTypes.CREATE_CENTER_QUERY_BUILDER_FAILURE }),
    ],
    callAPI(state) {
      return apiInternal.centerQueryBuilders(state)
        .post(organizationId, {
          body: queryBuilderForm,
        });
    },
  },
});

export default withAssertPropTypes({
  organizationId: PropTypes.string.isRequired,
  queryBuilderForm: ApiPropTypes.queryBuilderForm.isRequired,
})(createCenterQueryBuilder);
