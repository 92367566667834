const ReadyStates = {
  // Request has not been sent for this resource.
  PENDING: 'pending',
  // Request for this resource is currently in flight.
  LOADING: 'loading',
  // Request for this resource completed successfully.
  FULFILLED: 'fulfilled',
  // Request for this resource completed unsuccessfully.
  REJECTED: 'rejected',
};

export default ReadyStates;
