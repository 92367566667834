import Client from './client';

export default class Authorizations extends Client {
  constructor(opts) {
    let options = opts;

    if (typeof opts === 'string') {
      options = { host: opts }; // convert host string to options object
    }

    options.serviceName = 'API Internal';

    super(options);
  }

  postParameters(organization, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/authorizations/parameters`, {
      ...options,
       method: 'POST',
    });
  }

  getMetadataByKey(organization, key, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/authorizations/${key}/metadata`, options);
  }

  putPayloadByKey(organization, key, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/authorizations/${key}/payload`, {
      ...options,
       method: 'PUT',
    });
  }

  postPaymentAndDetailsAndAdyenByKey(organization, key, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/authorizations/${key}/payment/details/adyen`, {
      ...options,
       method: 'POST',
    });
  }

}
