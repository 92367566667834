import React, { FunctionComponent } from 'react';
import BemHelper from '@flowio/bem-helper';
import { PageHeaderGroupProps } from '../../types/props';

if (process.browser) {
  require('./page-header-group.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('page-header-group');

// PageHeaderGroup contains a collection of components for you. It is recommended that all
// components in a PageHeader are contained within a PageHeaderGroup.
const PageHeaderGroup: FunctionComponent<PageHeaderGroupProps> = ({ children, className }) => (
  <div className={bem.block(className)}>
    {children}
  </div>
);

PageHeaderGroup.displayName = 'PageHeaderGroup';

PageHeaderGroup.defaultProps = {
  className: '',
};

export default PageHeaderGroup;
