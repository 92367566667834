import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { browserHistory } from 'react-router';
import { submit } from 'redux-form';
import { withFetch } from '@flowio/redux-fetch';
import { fetchOrganization, getOrganization } from '../../../../organization';
import FeatureKey from '../../../../console/constants/FeatureKey';
import { checkFeatureValueByKey } from '../../../../console/selectors';
import {
  clearActiveExperience,
  clearArchiveExperience,
  fetchExperiences,
  getExperienceDefaults,
  goToCreateExperiencePage,
  goToExperienceDetails,
  setActiveExperience,
  setArchiveExperience,
  setCloneExperience,
  updateExperienceStatus,
  fetchExperienceQueryBuilderFilters,
  openExportExperienceSettingsModal,
  closeExportExperienceSettingsModal,
} from '../../../actions';
import {
  getExperienceSearchResults,
  getExperienceSearchPageInfo,
  getExperienceToActivate,
  getExperienceToArchive,
  getIsExportExperienceSettingsModalOpen,
} from '../../../selectors';
import ExperienceListing from '../components/ExperienceListing';
import ExperienceStatus from '../../../constants/experience-status';
import pushLocationWithExperienceFilters from '../../../utilities/pushLocationWithExperienceFilters';
import toExperienceQueryBuilderForm from '../../../utilities/toExperienceQueryBuilderForm';
import FormName from '../../../constants/FormName';
import { RootState, ThunkDispatcher } from '../../../../../stores/types';
import { StateProps, OwnProps, DispatchProps } from '../types';
import getQueryParamValue from '../../../../utilities/get-query-parameter-value';

const defaultFetchExperienceQ = 'and status in (active, draft)';

function getAsyncState(
  dispatch: ThunkDispatcher,
  _getState: () => RootState,
  ownProps: OwnProps,
): Promise<unknown[]> {
  const { organization } = ownProps.params;
  const locationQuery = toExperienceQueryBuilderForm(
    ownProps.location.query,
    defaultFetchExperienceQ,
  );
  const { location: { query: propsQuery } } = ownProps;
  const fetchExperienceOptions = {
    ...propsQuery,
    q: propsQuery.q || defaultFetchExperienceQ,
  };

  return Promise.all([
    dispatch(fetchOrganization(organization)),
    dispatch(fetchExperiences(organization, fetchExperienceOptions)),
    dispatch(
      fetchExperienceQueryBuilderFilters(organization, locationQuery),
    ),
  ]);
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = (
  state: RootState,
  ownProps: OwnProps,
) => ({
  experiences: getExperienceSearchResults(state),
  organization: getOrganization(state),
  pageInfo: getExperienceSearchPageInfo(state),
  currentPage: parseInt(getQueryParamValue(ownProps.location.query.pageNumber), 10) || 1,
  duplicationEnabled: checkFeatureValueByKey(FeatureKey.GLOBAL_EXPERIENCE_DUPLICATE)(state),
  experienceToArchive: getExperienceToArchive(state),
  experienceToActivate: getExperienceToActivate(state),
  isExportExperienceSettingsModalOpen: getIsExportExperienceSettingsModalOpen(state),
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch: ThunkDispatcher,
  ownProps: OwnProps,
) => ({
  onArchiveExperience(experience): void {
    dispatch(setArchiveExperience(experience));
  },
  onArchiveCancellation(): void {
    dispatch(clearArchiveExperience());
  },
  onActivateExperience(experience): void {
    dispatch(setActiveExperience(experience));
  },
  onOpenExportExperienceSettingsModal(): void {
    dispatch(openExportExperienceSettingsModal());
  },
  onActivateCancellation(): void {
    dispatch(clearActiveExperience());
  },
  onCreateExperience(): void {
    dispatch(goToCreateExperiencePage());
  },
  onExperienceClick(organization, experience): void {
    dispatch(goToExperienceDetails(organization, experience));
  },
  onCloseExportDialog(): void {
    dispatch(closeExportExperienceSettingsModal());
  },
  onSubmitExportDialog(): void {
    dispatch(submit(FormName.EXPORT_EXPERIENCE));
  },
  onRequestExperienceListFilterChange: (filters): void => {
    dispatch(
      pushLocationWithExperienceFilters(ownProps.params.organization, ownProps.location, filters),
    );
  },
  onSetExperienceStatus(organization, experience, status): void {
    dispatch(updateExperienceStatus(organization.id, experience.key, status));

    if (status === ExperienceStatus.ARCHIVING || status === ExperienceStatus.ARCHIVED) {
      dispatch(clearArchiveExperience());
    }

    if (status === ExperienceStatus.ACTIVE) {
      dispatch(clearActiveExperience());
    }
  },
  onRequestCloneExperience(organization, experience): Promise<unknown[]> {
    return Promise.all([
      dispatch(setCloneExperience(experience)),
      dispatch(getExperienceDefaults(organization.id, experience.region.id)),
    ]);
  },
  onRequestNextPage(): void {
    const currentPage = parseInt(getQueryParamValue(ownProps.location.query.pageNumber), 10) || 1;
    const pageNumber = currentPage + 1;

    browserHistory.push({
      pathname: ownProps.location.pathname,
      query: { ...ownProps.location.query, pageNumber },
    });
  },
  onRequestPreviousPage(): void {
    const currentPage = parseInt(getQueryParamValue(ownProps.location.query.pageNumber), 10) || 1;
    const pageNumber = Math.max(1, currentPage - 1);

    browserHistory.push({
      pathname: ownProps.location.pathname,
      query: { ...ownProps.location.query, pageNumber },
    });
  },
});

export default withFetch(getAsyncState)(
  connect(mapStateToProps, mapDispatchToProps)(ExperienceListing),
);
