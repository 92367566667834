import { UserCircle } from '@flowio/react-icons';
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component, ReactElement } from 'react';
import { Link } from 'react-router';
import BemHelper from '@flowio/bem-helper';
import { Menu, MenuItem } from '@flowio/react-menu';
import { Popover } from '@flowio/react-popover';
import { FlatButton } from '@flowio/react-button';
import { AccountDropDownMenuState } from '../../types/state';

if (process.browser) {
  require('./account-drop-down-menu.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('account-drop-down-menu');

export default class AccountDropDownMenu extends Component<{}, AccountDropDownMenuState> {
  static displayName = 'AccountDropDownMenu';

  constructor(props: any) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  handleItemClick = (): void => {
    this.close();
  };

  handleOpen = (): void => {
    this.open();
  };

  handleClose = (): void => {
    this.close();
  };

  handleEscKeyDownMenu = (): void => {
    this.close();
  };

  open(): void {
    this.setState({ isOpen: true });
  }

  close(): void {
    this.setState({ isOpen: false });
  }

  render(): ReactElement {
    const listStyle = {
      paddingTop: 0,
      paddingBottom: 0,
    };

    const { isOpen } = this.state;

    return (
      <div className={bem.block()}>
        <Popover
          position="bottom right"
          open={isOpen}
          onOpen={this.handleOpen}
          onClose={this.handleClose}
          className={bem.element('popover')}
          openOnClick
          trigger={(
            <FlatButton
              className={isOpen ? bem.element('button', 'open') : bem.element('button')}
              leftIcon={UserCircle}
            />
          )}
        >
          <Menu
            style={listStyle}
            onItemSelection={this.handleItemClick}
          >
            <MenuItem
              className={bem.element('label')}
            >
              <Link
                className={bem.element('text')}
                to="/account/profile"
              >
                Account Settings
              </Link>
            </MenuItem>
            <MenuItem
              className={bem.element('label')}
            >
              <Link
                className={bem.element('text')}
                to="/logout"
              >
                Sign Out
              </Link>
            </MenuItem>
          </Menu>
        </Popover>
      </div>
    );
  }
}
