import { ThunkResult } from '../../../stores/types';
import { createToast } from '../../console/actions';
import FeatureStatus from '../constants/feature-status';

export default function toastStatusChange(
  feature: io.flow.internal.v0.models.Feature,
): ThunkResult<void> {
  return (dispatch) => {
    if (feature.status === FeatureStatus.ACTIVE) {
      dispatch(createToast({
        content: `Feature \`${feature.name}\` has been activated.`,
        icon: 'InfoCircle',
        intent: 'info',
      }));
    }
    if (feature.status === FeatureStatus.ARCHIVED) {
      dispatch(createToast({
        content: `Feature \`${feature.name}\` has been archived.`,
        icon: 'Package',
        intent: 'neutral',
      }));
    }
  };
}
