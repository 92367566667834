import { compose } from 'redux';
import { reduxForm, SubmissionError } from 'redux-form';
import RuleDeleteConfirmForm from '../components/RuleDeleteConfirmForm';

import createToast from '../../console/actions/createToast';
import {
  deleteRuleByKeyAndId,
  hideDeleteRuleConfirm,
} from '../actions';
import FormName from '../constants/FormName';
import isResponseOk from '../../../utilities/isResponseOk';
import { RuleDeleteConfirmFormOwnProps, RuleDeleteConfirmValues } from '../types/components';
import { ThunkDispatcher } from '../../../stores/types';

function submit(values: RuleDeleteConfirmValues, dispatch: ThunkDispatcher) {
  const { organizationId, feature, ruleId } = values;
  return dispatch(deleteRuleByKeyAndId(organizationId, feature.key, ruleId))
    .then((response) => {
      if (isResponseOk(response)) return response;
      throw new SubmissionError({ _error: response.body });
    });
}

function handleSubmitSuccess(_: any, dispatch: ThunkDispatcher) {
  dispatch(hideDeleteRuleConfirm());
  dispatch(createToast({
    content: 'A rule had been deleted',
    icon: 'Trash',
    intent: 'neutral',
  }));
}

export default compose<React.FC<RuleDeleteConfirmFormOwnProps>>(
  reduxForm({
    form: FormName.DELETE_RULE_FORM,
    onSubmit: submit,
    onSubmitSuccess: handleSubmitSuccess,
  }),
)(RuleDeleteConfirmForm);
