import { compose } from 'redux';
import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { browserHistory } from 'react-router';
import { withFetch } from '@flowio/redux-fetch';
import map from 'lodash/map';
import moment from 'moment';
import { ReportInterval } from '@flowio/api-internal-constants';

import { fetchOrganization, getBaseOrganizationCountry, getBaseOrganizationCurrency } from '../../../../organization';
import { toDateRange } from '../../../utilities/date-range';
import Analytics from '../components/Analytics';
import DateRangeLiterals from '../../../constants/date-range-literals';
import changeCurrentDate from '../../../actions/change-current-date';
import changeDateRange from '../../../actions/change-date-range';
import changeInterval from '../../../actions/change-interval';
import changeRegion from '../../../actions/change-region';
import fetchCountryRevenue from '../../../actions/fetch-country-revenue';
import fetchDestinationCountries from '../../../actions/fetch-destination-countries';
import fetchTimelineRevenue from '../../../actions/fetch-timeline-revenue';
import getCurrentDate from '../../../selectors/get-current-date';
import getDestinationCountries from '../../../selectors/get-destination-countries';
import getGmvByCountry from '../../../selectors/get-gmv-by-country';
import getGmvByTimeline from '../../../selectors/get-gmv-by-timeline';
import getGmvCurrency from '../../../selectors/get-gmv-currency';
import getGmvEndDate from '../../../selectors/get-gmv-end-date';
import getGmvInterval from '../../../selectors/get-gmv-interval';
import getGmvRegion from '../../../selectors/get-gmv-region';
import getGmvStartDate from '../../../selectors/get-gmv-start-date';
import getGmvTotal from '../../../selectors/get-gmv-total';
import getNumberOfOrders from '../../../selectors/get-number-of-orders';
import getOrderCountByCountry from '../../../selectors/get-order-count-by-country';
import { ThunkDispatcher, RootState } from '../../../../../stores/types';
import { StateProps, DispatchProps, OwnProps } from '../types';
import getQueryParamValue from '../../../../utilities/get-query-parameter-value';

const getReportInterval = (val: string): ReportInterval => {
  switch (val) {
    case ReportInterval.WEEKLY: return ReportInterval.WEEKLY;
    case ReportInterval.MONTHLY: return ReportInterval.MONTHLY;
    case ReportInterval.HOURLY: return ReportInterval.HOURLY;
    default: return ReportInterval.DAILY;
  }
};

const getAsyncState = (
  dispatch: ThunkDispatcher,
  _getState: () => RootState,
  props: OwnProps,
): Promise<any> => {
  const { params, location } = props;
  const { organization } = params;

  const currentDate = moment.utc();
  const defaultRegion = 'world';
  const defaultInterval = ReportInterval.DAILY;
  const [defaultStartDate, defaultEndDate] = map(
    toDateRange(DateRangeLiterals.LAST_7_DAYS, currentDate),
    (date) => date.clone().toISOString(),
  );
  const interval = getReportInterval(getQueryParamValue(location.query.interval, defaultInterval));
  const region = getQueryParamValue(location.query.region, defaultRegion);
  const startDate = getQueryParamValue(location.query.startDate, defaultStartDate);
  const endDate = getQueryParamValue(location.query.endDate, defaultEndDate);

  return Promise.all([
    dispatch(fetchDestinationCountries(organization)),
    dispatch(fetchOrganization(organization)),
    dispatch(fetchCountryRevenue(organization, 'world', startDate, endDate)),
    dispatch(fetchTimelineRevenue(
      organization,
      region,
      startDate,
      endDate,
      interval,
    )),
  ]).then(() => {
    // Dispatch after to avoid re-rendering chart until data is fetched.
    dispatch(changeCurrentDate(currentDate.toISOString()));
    dispatch(changeDateRange(startDate, endDate));
    dispatch(changeInterval(interval));
    dispatch(changeRegion(region));
  });
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = (
  state: RootState,
): StateProps => ({
  countries: getDestinationCountries(state),
  currency: getGmvCurrency(state),
  currentDate: getCurrentDate(state),
  organizationCountry: getBaseOrganizationCountry(state),
  organizationCurrency: getBaseOrganizationCurrency(state),
  endDate: getGmvEndDate(state),
  gmvByCountry: getGmvByCountry(state),
  gmvByTimeline: getGmvByTimeline(state),
  interval: getGmvInterval(state),
  numberOfOrders: getNumberOfOrders(state),
  orderCountByCountry: getOrderCountByCountry(state),
  region: getGmvRegion(state),
  startDate: getGmvStartDate(state),
  total: getGmvTotal(state),
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (_, props) => ({
  onRequestDateRangeChange({ startDate, endDate }): void {
    const { location } = props;
    browserHistory.push({
      pathname: location.pathname,
      query: {
        ...location.query,
        startDate,
        endDate,
      },
    });
  },
  onRequestRegionChange({ region }): void {
    const { location } = props;
    browserHistory.push({
      pathname: location.pathname,
      query: {
        ...location.query,
        region,
      },
    });
  },
  onRequestIntervalChange({ interval }): void {
    const { location } = props;
    browserHistory.push({
      pathname: location.pathname,
      query: {
        ...location.query,
        interval,
      },
    });
  },
});

export default compose(
  withFetch(getAsyncState),
  connect(mapStateToProps, mapDispatchToProps),
)(Analytics);
