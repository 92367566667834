import update from 'immutability-helper';

import ActionTypes from '../constants/action-types';
import { ExperienceState } from '../types';
import { RootActionTypes } from '../../../stores/types';

type State = ExperienceState['entities']['defaults'];

const initialState: State = {};

export default function (state: State = initialState, action: RootActionTypes): State {
  switch (action.type) {
    case ActionTypes.CLEAR_EXPERIENCE_DEFAULTS:
      return update(state, { $unset: ['value'] });
    case ActionTypes.GET_EXPERIENCE_DEFAULTS_SUCCESS:
      return update(state, { value: { $set: action.payload } });
    default:
      return state;
  }
}
