import { compose } from 'redux';
import { connect } from 'react-redux';
import { RootState } from '../../../stores/types';

import {
  getAnalyticsById,
  getDomainById,
  getBehaviorById,
  getSettingsById,
} from '../selectors';
import ConfigurationDetail, { OwnProps, StateProps } from '../components/configuration-detail';

function mapStateToProps(state: RootState, ownProps: OwnProps): StateProps {
  const { id } = ownProps;
  return {
    analytics: getAnalyticsById(id)(state),
    behavior: getBehaviorById(id)(state),
    settings: getSettingsById(id)(state),
    domain: getDomainById(id)(state),
  };
}

const ConnectedConfigurationDetail = compose(
  connect(mapStateToProps),
)(ConfigurationDetail);

export default ConnectedConfigurationDetail;
