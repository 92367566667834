import { Visible, Trash } from '@flowio/react-icons';
import React from 'react';
import { IconButton } from '@flowio/react-button';
import { Popover } from '@flowio/react-popover';
import noop from 'lodash/noop';
import QueryTarget from '../../../experience/components/query-target';
import { KeySummaryProps } from './types';
import * as styles from './api-keys.styles';

const defaultProps = {
  onViewClearText: noop,
  onDelete: noop,
};

const ApiKeySummary: React.FC<KeySummaryProps> = ({
  token,
  onViewClearText = noop,
  onDelete = noop,
}) => {
  const iconButtonStyles = {
    padding: 0,
    width: '24px',
    height: '24px',
    verticalAlign: 'middle',
    marginLeft: '1rem',
  };

  function handleViewClearText() {
    onViewClearText(token.id);
  }

  function handleDelete() {
    onDelete(token.id);
  }

  function renderLabel() {
    return (
      <div className={styles.description}>
        {token.description}
        <span className={styles.shortKey}>{`(${token.partial} - ${token.discriminator === ('organization_token' || 'partner_token') ? token.environment : ''})`}</span>
      </div>
    );
  }

  function renderActions() {
    const tooltipStyle: React.CSSProperties = {
      display: 'block',
      width: 260,
      padding: '12px 16px',
      backgroundColor: '#808080',
      boxSizing: 'border-box',
      borderRadius: 6,
      color: '#ffffff',
      fontSize: 14,
      fontWeight: 'bold',
      lineHeight: '1.25',
      textAlign: 'center',
      whiteSpace: 'normal',
    };

    return (
      <div className={styles.activeKeysActions}>
        <Popover
          offset="0px -5px"
          position="top"
          trigger={(
            <IconButton
              variant="flat"
              size="small"
              icon={Visible}
              css={iconButtonStyles}
              onClick={handleViewClearText}
            />
          )}
          openOnHover
        >
          <div style={tooltipStyle}>
            View Full API Key
          </div>
        </Popover>

        <IconButton
          variant="flat"
          css={iconButtonStyles}
          icon={Trash}
          size="small"
          onClick={handleDelete}
        />
      </div>
    );
  }

  return (
    <QueryTarget
      className=""
      type={(token.discriminator === ('organization_token' || 'partner_token')) && (token.environment === 'production') ? 'inclusion' : 'exclusion'}
      label={renderLabel()}
      actions={renderActions()}
    />
  );
};

ApiKeySummary.displayName = 'ApiKeySummary';
ApiKeySummary.defaultProps = defaultProps;

export default ApiKeySummary;
