import { combineReducers } from 'redux';
import current from './feature-details';
import list from './feature-list';
import dialogs from './dialogs';
import errors from './errors';
import ruleFilters from './ruleFilters';
import ui from './features-ui';
import { FeatureEntities, FeatureState } from '../types/state';
import { RootActionTypes } from '../../../stores/types';

export default combineReducers<FeatureState, RootActionTypes>({
  ui,
  current,
  entities: combineReducers<FeatureEntities, RootActionTypes>({
    ruleFilters,
  }),
  list,
  dialogs,
  errors,
});
