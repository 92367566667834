import { createSelector } from 'reselect';
import property from 'lodash/property';

import getQueries from './getQueries';
import { RootState } from '../../../stores/types';
import { LogisticsQueries } from '../types/state';

export default createSelector<RootState, LogisticsQueries, io.flow.v0.models.Center[]>(
  getQueries,
  property('center.result'),
);
