import { Image } from '@flowio/react-image';
import React from 'react';

type Props = {
  country: io.flow.v0.models.Country;
  className?: string;
};

const CountryFlag: React.FC<Props> = ({
  country,
  ...unhandledProps
}) => {
  const accessibilityLabel = country.name;
  const source = `https://flowcdn.io/util/icons/flags-v2/svg/${country.iso_3166_3}.svg`;
  return (<Image {...unhandledProps} accessibilityLabel={accessibilityLabel} source={source} />);
};

CountryFlag.displayName = 'CountryFlag';

export default CountryFlag;
