import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import { WithPagingAndSortingResult } from '../../../utilities/types';
import withPagingAndSorting, { isPagingAndSortingResponse, WithPagingAndSortingOptions } from '../../../utilities/with-paging-and-sorting-v2';
import ActionTypes from '../constants/action-types';

interface GetCurrencyRatesOptions extends WithPagingAndSortingOptions {
  baseCurrency?: string;
}

function createRequestAction(): RootActionTypes {
  return {
    type: ActionTypes.GET_CURRENCY_RATES_REQUEST,
  };
}

function createSuccessAction(
  payload: WithPagingAndSortingResult<io.flow.v0.models.Rate[]>,
): RootActionTypes {
  return {
    type: ActionTypes.GET_CURRENCY_RATES_SUCCESS,
    payload,
  };
}

function createFailureAction(payload: FormattedErrorMessages): RootActionTypes {
  return {
    type: ActionTypes.GET_CURRENCY_RATES_FAILURE,
    error: true,
    payload,
  };
}

function getCurrencyRates(
  organization: string,
  options: GetCurrencyRatesOptions,
): ThunkResult<Promise<void>> {
  return function getCurrencyRatesEffects(
    dispatch,
    getState,
    extra,
  ): Promise<void> {
    dispatch(createRequestAction());

    return withPagingAndSorting<io.flow.v0.models.Rate[]>(
      options,
      (params) => extra.api(getState()).rates.get({
        organization,
        base: options.baseCurrency,
        ...params,
      }),
    )().then((response) => {
      if (isPagingAndSortingResponse<io.flow.v0.models.Rate[]>(response)) {
        dispatch(createSuccessAction(response.result));
      } else {
        const formattedError = formatErrorResponseV2(response);
        dispatch(createFailureAction(formattedError));
      }
    });
  };
}

export default getCurrencyRates;
