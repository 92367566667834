import { css } from '@emotion/css';
import colors from '../../../../theme/tokens/colors';

export const steps = css`
  /* !important to overide step defualt styles */
  padding-inline-start: unset !important;
  justify-content: space-between !important;

  // line between step markers
  &:before {
    width: 70% !important;
    left: 15% !important;
  }
`;

export const step = css`
  width: 25%;
  z-index: 1;

  & span {
    &:first-child {
      display: none;
    }

    &:last-child {
      pointer-events: none;
      text-transform: none;
      color: ${colors.marketing['dark-slate-blue']};
      font-weight: 700;

      &:after {
        content: "";
        background-color: white;
        height: 10px;
        bottom: calc(50% - 5px);
        width: 50%;
        right: 25%;
        display: block;
        position: absolute;
        z-index: -1;
      }
    }
  }
`;

export const active = css`
  & span:last-child {
    &:before {
      background-color: ${colors.marketing.positive};
    }
  }
`;
