import uniqueId from 'lodash/uniqueId';
import ActionTypes from '../constants/action-types';
import { CreateToastOptions } from '../types/actions';
import { RootActionTypes } from '../../../stores/types';

const defaultOptions = {
  autoCloseDuration: 8000,
  intent: 'info',
};

/**
 * Creates an action responsible for creating toasts in the application state.
 * @param {Object} options - properties passed to the toast component.
 */
export default function createToast(options: CreateToastOptions): RootActionTypes {
  return {
    type: ActionTypes.CREATE_TOAST,
    payload: {
      eventKey: uniqueId('toast'),
      ...defaultOptions,
      ...options,
    },
  };
}
