import omitBy from 'lodash/omitBy';
import { ExperiencesPutMarginsByExperienceKeyAndKeyResponse } from '@flowio/api-sdk';
import ActionTypes from '../constants/action-types';
import { getOrganizationId } from '../../organization';
import { ThunkDispatcher, ThunkExtraArguments, RootState } from '../../../stores/types';
import { PropType } from '../../../types';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import toLegacyResponse from '../../../utilities/clients/to-legacy-response';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';

/**
 * Update a margin by Key
 *
 * @param {String} organization
 * @param {String} experienceKey
 * @param {String} marginKey
 * @param {Object} formData - The updated Experience
 * @return {Promise} Promise of a margin. Rejects with apidoc errors.
 */

type ResponseType = PropType<ExperiencesPutMarginsByExperienceKeyAndKeyResponse, 'body'>;

export default function updateMargin(
  marginKey: string,
  itemMarginForm: io.flow.v0.models.ItemMarginPutForm,
) {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<LegacyResponse<ResponseType>> => {
    const state = getState();
    if (!state.experience.current) {
      throw new Error('updateMargin: current experience expected.');
    }
    const organization = getOrganizationId(state);
    const experienceKey = state.experience.current.key;

    dispatch({
      type: ActionTypes.UPDATE_MARGIN_SUBMIT,
      itemMarginForm,
    });

    return extra.api(state).experiences.putMarginsByExperienceKeyAndKey({
      experience_key: experienceKey,
      organization,
      key: marginKey,
      body: (omitBy(itemMarginForm, (value, key) => (key === 'fixed' || key === 'percent') && value === 0) as io.flow.v0.models.ItemMarginPutForm),
    }).then((response) => {
      if (response.ok) {
        dispatch({
          type: ActionTypes.UPDATE_MARGIN_SUCCESS,
          itemMarginForm,
          payload: response.body,
        });
      } else {
        dispatch({
          type: ActionTypes.UPDATE_MARGIN_FAILURE,
          payload: formatErrorResponseV2(response),
        });
      }
      return toLegacyResponse<ResponseType>(response);
    });
  };
}
