import { AnyAction } from 'redux';
import ReadyStates from './ReadyState';

export type AnyActionWithParams = AnyAction & { params: unknown };

export type AnyActionWithPayload = AnyAction & { payload: unknown };

export enum DataStateStatus {
  LOADED = 'LOADED',
  LOADING = 'LOADING',
  EMPTY = 'EMPTY',
  ERROR = 'ERROR',
  UNKNOWN = 'UNKNOWN',
}

export interface StatusState {
  error?: AnyActionWithPayload['payload'];
  params?: AnyActionWithParams['params'];
  status: ReadyStates;
}
