import { SetEditingMembershipAction } from '../types';
import { ActionTypes } from '../constants';

export default function setEditingMember(
  membership?: io.flow.v0.models.Membership,
): SetEditingMembershipAction {
  return {
    type: ActionTypes.SET_EDITING_MEMBERSHIP,
    payload: membership,
  };
}
