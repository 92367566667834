import { compose } from 'redux';
import { reduxForm, SubmissionError } from 'redux-form';

import { $HttpResponse } from '@flowio/api-internal-sdk';
import { deleteOptinPromptById } from '../../../actions';
import FormName from '../../../constants/FormName';
import OptinContentDeleteForm from '../components/OptinContentDeleteForm';
import isResponseOk from '../../../../../utilities/isResponseOk';
import { ThunkDispatcher } from '../../../../../stores/types';
import { OwnProps } from '../types';
import { DeleteOptinContentConfirmValues } from '../../../types';

function handleResponse(
  response: $HttpResponse,
): $HttpResponse {
  if (isResponseOk(response)) return response;
  throw new SubmissionError({ _error: response.body });
}

function submit(
  values: DeleteOptinContentConfirmValues,
  dispatch: ThunkDispatcher,
): Promise<$HttpResponse<undefined>> {
  const { organizationId, optinPromptId } = values;
  return dispatch(deleteOptinPromptById(organizationId, optinPromptId))
    .then(handleResponse);
}

export default compose<React.FC<OwnProps>>(
  reduxForm<DeleteOptinContentConfirmValues, OwnProps>({
    form: FormName.DELETE_OPTIN_PROMPT_FORM,
    onSubmit: submit,
  }),
)(OptinContentDeleteForm);
