import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFetch } from '@flowio/redux-fetch';
import { setOverlayProps } from '@flowio/redux-form-overlay';
import { browserHistory } from 'react-router';
import get from 'lodash/get';

import PriceBookDetails from '../components/PriceBookDetails';
import { readPriceBookItems, readPriceBookByKey, readCurrencies } from '../../resources/operations';
import { getOrganization, fetchOrganization } from '../../organization';
import { getPriceBook } from '../../resources/selectors/price-books';
import {
  getIsFirstPriceBookItemsPage,
  getIsLastPriceBookItemsPage,
  getCurrentPriceBookItemsPage,
  getPriceBookItems,
  getCurrencies,
} from '../../resources/selectors';
import FormName from '../constants/FormName';
import { getUserEmail } from '../../console';
import fetchPriceBookExportTypes from '../actions';
import { getPriceBookExportOptions } from '../selectors';

const toInitialValues = (organizationId, priceBook) => ({
  currency: get(priceBook, 'currency'),
  includes: get(priceBook, 'includes.key'),
  key: get(priceBook, 'key'),
  name: get(priceBook, 'name'),
  organizationId,
  status: get(priceBook, 'status'),
});

const fetchAsyncState = (dispatch, getState, ownProps) => {
  const { organization, priceBookKey } = ownProps.params;
  const { pageNumber } = ownProps.location.query;

  return Promise.all([
    dispatch(readCurrencies()),
    dispatch(readPriceBookByKey({
      organizationId: organization,
      priceBookKey,
    })),
    dispatch(readPriceBookItems({
      organizationId: organization,
      pageNumber: pageNumber || 1,
      priceBookKey,
    })),
    dispatch(fetchPriceBookExportTypes(organization)),
    dispatch(fetchOrganization(organization)),
  ]);
};

const mapStateToProps = (state, ownProps) => {
  const { priceBookKey } = ownProps.params;
  return {
    currencies: getCurrencies(state),
    priceBook: getPriceBook(priceBookKey)(state),
    priceBookItems: getPriceBookItems(state),
    firstPage: getIsFirstPriceBookItemsPage(state),
    lastPage: getIsLastPriceBookItemsPage(state),
    organization: getOrganization(state),
    pageNumber: getCurrentPriceBookItemsPage(state),
    email: getUserEmail(state),
    priceBookExportTypes: getPriceBookExportOptions(state),
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  onRequestPage(pageNumber) {
    const { location } = props;
    browserHistory.push({
      pathname: location.pathname,
      query: {
        ...location.query,
        pageNumber,
      },
    });
  },
  onExportPriceBook(organization, priceBook, email) {
    dispatch(setOverlayProps(FormName.EXPORT_PRICE_BOOK_FORM, {
      initialValues: {
        organization,
        priceBookKey: priceBook.key,
        email,
        itemIdentifier: 'item_number',
      },
      priceBook,
      open: true,
      title: 'Export Price Book',
    }));
  },
  onDeletePriceBook(organizationId, priceBook) {
    dispatch(setOverlayProps(FormName.DELETE_PRICE_BOOK_FORM, {
      caption: 'Delete Price Book',
      initialValues: {
        key: priceBook.key,
        organizationId,
      },
      open: true,
      priceBook,
      title: 'Delete Price Book',
    }));
  },
  onEditPriceBook(organizationId, priceBook) {
    dispatch(setOverlayProps(FormName.UPSERT_PRICE_BOOK_FORM, {
      initialValues: toInitialValues(organizationId, priceBook),
      open: true,
      title: 'Edit Price Book',
    }));
  },
  onUploadPriceBook(organizationId) {
    dispatch(setOverlayProps(FormName.IMPORT_PRICE_BOOK_FORM, {
      initialValues: {
        organizationId,
      },
      saveButton: {
        content: 'Upload',
        intent: 'primary',
      },
      open: true,
      title: 'Upload Price Book',
    }));
  },
});

export default compose(
  withFetch(fetchAsyncState),
  connect(mapStateToProps, mapDispatchToProps),
)(PriceBookDetails);
