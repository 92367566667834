/* eslint-disable max-len */
import { differenceWith, isEqual } from 'lodash';
import createShippingLabel from './createShippingLabel';
import { getOrganizationId } from '../../organization/selectors';
import {
  getSelectedOrderItems,
  getCurrentOrderNumber,
  getItemsOfPrevShippingLabel,
  getShippingLabel,
} from '../selectors';
import { ActivityKind } from '../constants';
import createActivity from './createActivity';
import changeSearchValue from './changeSearchValue';
import { ThunkResult } from '../../../stores/types';
import { SelectedOrderItem } from '../types/components';

function toSummaryShippingLabelForm(
  orderNumber: string,
  selectedItems: io.flow.v0.models.LineItemForm[],
): io.flow.v0.unions.ShippingLabelForm {
  return {
    discriminator: 'summary_shipping_label_form',
    order_number: orderNumber,
    items: selectedItems,
  };
}

interface GetShippingLabelToPrintResponse {
  label: io.flow.v0.models.ShippingLabel,
  organizationId: string,
  orderNumber: string,
}

export default function getShippingLabelToPrint(): ThunkResult<Promise<GetShippingLabelToPrintResponse | null>> {
  return function getShippingLabelToPrintEffects(dispatch, getState): Promise<GetShippingLabelToPrintResponse | null> {
    const organizationId = getOrganizationId(getState());
    const orderNumber = getCurrentOrderNumber(getState());
    const currentSelectedItems = getSelectedOrderItems(getState());
    const prevSelectedItems = getItemsOfPrevShippingLabel(getState());
    const selectedItemsChanged: boolean = differenceWith(
      currentSelectedItems, prevSelectedItems, isEqual,
    ).length > 0;
    if (selectedItemsChanged || currentSelectedItems.length === 0) {
      const selectedItems: io.flow.v0.models.LineItemForm[] = currentSelectedItems.map((selectedItem: SelectedOrderItem) => ({
        number: selectedItem.number,
        quantity: selectedItem.quantity,
      }));
      dispatch(changeSearchValue(''));
      return dispatch(createShippingLabel(
        organizationId,
        toSummaryShippingLabelForm(orderNumber, selectedItems),
        currentSelectedItems,
      )).then((response) => {
        if (response.ok) {
          return {
            label: response.body,
            organizationId,
            orderNumber,
          };
        }
        dispatch(createActivity({
          kind: ActivityKind.API_ERROR,
          error: response.body?.messages.join(', ') || '',
          statusCode: response.body?.code || 'not_found',
          organizationId,
        }));
        return null;
      });
    }
    const label = getShippingLabel(getState());
    return Promise.resolve({
      label,
      organizationId,
      orderNumber,
    });
  };
}
