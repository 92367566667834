import ActionTypes from '../constants/action-types';
import standardAction from '../../../utilities/standard-action';
import {
  RootState, ThunkExtraArguments, ThunkResult, ThunkDispatcher,
} from '../../../stores/types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';

type FetchExperimentsReturn = io.flow.internal.v0.unions.Experiment | undefined;

export default function fetchExperiments(
  organization: string,
  key: string,
): ThunkResult<Promise<FetchExperimentsReturn>> {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<FetchExperimentsReturn> => {
    dispatch({
      type: ActionTypes.FETCH_EXPERIMENT_REQUEST,
    });

    return extra.apiInternal(getState()).experiments.getByKey({
      organization,
      key,
    }).then((response) => {
      if (response.ok) {
        dispatch(standardAction(ActionTypes.FETCH_EXPERIMENT_SUCCESS, response.body));
        return response.body;
      }

      dispatch({
        type: ActionTypes.FETCH_EXPERIMENT_FAILURE,
        payload: formatErrorResponseV2(response),
      });

      return response.body;
    });
  };
}
