import { $HttpResponse } from '@flowio/api-internal-sdk';
import ActionTypes from '../constants/action-types';
import { RootActionTypes, ThunkResult } from '../../../stores/types';
import { ApiError } from '../../../utilities/clients/api-error';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';

export function fetchOrganizationAccountRequest(): RootActionTypes {
  return {
    type: ActionTypes.FETCH_ORGANIZATION_ACCOUNT_REQUEST,
  };
}

export function fetchOrganizationAccountSuccess(
  account: io.flow.internal.v0.models.OrganizationAccount,
): RootActionTypes {
  return {
    type: ActionTypes.FETCH_ORGANIZATION_ACCOUNT_SUCCESS,
    payload: account,
  };
}

export function fetchOrganizationAccountFailure(
  error?: FormattedErrorMessages,
): RootActionTypes {
  return {
    type: ActionTypes.FETCH_ORGANIZATION_ACCOUNT_FAILURE,
    error: true,
    payload: error,
  };
}

export default function fetchOrganizationAccount(organization: string, key: string):
ThunkResult<Promise<void>> {
  return function fetchAccountEffects(
    dispatch,
    getState,
    extra,
  ): Promise<void> {
    dispatch(fetchOrganizationAccountRequest());

    return extra.apiInternal(getState()).organizationAccounts.getByKey({
      organization,
      key,
    }).then((response) => {
      if (response.ok) {
        dispatch(fetchOrganizationAccountSuccess(response.body));
        return;
      }

      dispatch(fetchOrganizationAccountFailure(formatErrorResponseV2(response)));
      throw new ApiError(response as unknown as $HttpResponse);
    });
  };
}
