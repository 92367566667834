import { Alert } from '@flowio/react-icons';
import { css } from '@emotion/react';
import { MarketingGatewayPlatform as Platform } from '@flowio/api-internal-constants';
import React from 'react';
import {
  Field,
  Form,
  InjectedFormProps,
} from 'redux-form';

import capitalize from 'lodash/capitalize';
import MarketingCard from '../MarketingCard';
import * as styles from './SelectFeedSource.styles';
import ChannelPill from '../ChannelPill';
import { MarketingGatewayIntegratedPlatform, SelectFeedSourceFormProps } from '../../types';
import Dropdown from './SelectFeedSourceSelection';
import Platforms from '../../constants/Platforms';
import colors from '../../../../theme/tokens/colors';

import type {
  OwnProps, StateProps,
} from '../../containers/SelectFeedSource';

interface Props extends InjectedFormProps<SelectFeedSourceFormProps, OwnProps & StateProps> {
  organizationId: string;
  platformId: MarketingGatewayIntegratedPlatform;
  feedSources: io.flow.internal.v0.unions.MarketingGatewayFeedSource[];
}

const alertIconStyling = css`
  margin-right: 12px;
  fill: ${colors.chart.yellow};
`;

const SelectFeedSource: React.FC<Props> = ({
  platformId,
  feedSources,
  handleSubmit,
}) => (
  <Form onSubmit={handleSubmit}>
    <Field type="hidden" name="organizationId" component="input" />
    <Field type="hidden" name="schema_compatibility" component="input" />
    <MarketingCard>
      {platformId === Platform.GOOGLE && <ChannelPill platform={platformId} />}
      <h3 className={styles.title}>Source</h3>
      <Field
        component={Dropdown}
        name="feed_source"
        className={styles.dropdown}
        feedSources={feedSources}
      />
      {!feedSources.length && (
        <div className={styles.noFeeds}>
          <Alert width={24} css={alertIconStyling} />
          <p>
            {`You dont have any feed sources available. Please set up a product feed in your ${Platforms('google').platformName} account before proceeding to set up your ${capitalize(platformId)} catalog data.`}
          </p>
        </div>
      )}
    </MarketingCard>
  </Form>
);

SelectFeedSource.displayName = 'SelectFeedSource';

export default SelectFeedSource;
