import withRedirect from '../with-redirect';
import { RedirectLocation } from '../with-redirect/with-redirect';

// A higher-order component that redirects to a path when a form component is submitted. Normally,
// you would wrap/decorate the returned component with `withSubmit`.
// https://github.com/reactjs/redux/issues/297#issuecomment-124116124
export default function withRedirectSubmitted(
  location: RedirectLocation,
): (Component: React.ElementType) => React.ReactNode {
  return withRedirect(location, (_state, { submitted }) => !!submitted);
}
