import { compose } from 'redux';
import { reduxForm, SubmissionError } from 'redux-form';

import { OptinAttributesDeleteByKeyResponse } from '@flowio/api-internal-sdk';
import { deleteOptinAttributeByKey } from '../../../actions';
import FormName from '../../../constants/FormName';
import OptinMessageDeleteForm from '../components/OptinMessageDeleteForm';
import isResponseOk from '../../../../../utilities/isResponseOk';
import { OptinMessagesDeleteFormValues, OwnProps } from '../types';
import { ThunkDispatcher } from '../../../../../stores/types';

function handleResponse(
  response: OptinAttributesDeleteByKeyResponse,
): OptinAttributesDeleteByKeyResponse {
  if (isResponseOk(response)) return response;
  throw new SubmissionError({ _error: response.body });
}

function submit(values: OptinMessagesDeleteFormValues, dispatch: ThunkDispatcher) {
  const { organizationId, optinAttributeKey } = values;
  return dispatch(
    deleteOptinAttributeByKey(organizationId, optinAttributeKey),
  ).then(handleResponse);
}

export default compose<React.FC<OwnProps>>(
  reduxForm<OptinMessagesDeleteFormValues, OwnProps>({
    form: FormName.DELETE_OPTIN_ATTRIBUTE_FORM,
    onSubmit: submit,
  }),
)(OptinMessageDeleteForm);
