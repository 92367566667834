import React from 'react';
import classNames from 'classnames/bind';

import CountryFlag from '../CountryFlag';
import styles from './CountryLabel.module.css';

const cx = classNames.bind(styles);

type Props = {
  country: io.flow.v0.models.Country;
};

const CountryLabel: React.FC<Props> = ({ country }) => (
  <span className={cx('label')}>
    <CountryFlag className={cx('flag')} country={country} />
    <span className={cx('text')}>
      {country.name}
    </span>
  </span>
);

CountryLabel.displayName = 'CountryLabel';

export default CountryLabel;
