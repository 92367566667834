import { css } from '@emotion/css';
import colors from '../../../../theme/tokens/colors';

export const marketingPage = css`
  background-color: ${colors.gray[100]};
  padding: 40px;
  margin: auto;
  margin-top: 20px;
`;

export const marketingHeader = css`
  margin: 0 0 40px 0;
`;

export const marketingHeading = css`
  margin: 0;
  color: ${colors.marketing['dark-slate-blue']};
  font-weight: 400;
`;

export const marketingBreadcrumbs = css`
  margin-bottom: 16px;

  & nav ol li {
    & a,
    & span {
      font-weight: 500;
    }

    & a {
      color: ${colors.marketing['light-slate-gray']};
    }

    & span {
      color: ${colors.marketing['dark-slate-blue']};
    }

    &:last-child {
      &:after {
        content: none;
      }
    }
  }
`;

export const marketingFooter = css`
  margin-top: 24px;
  color: ${colors.marketing['light-slate-gray']};
  font-size: 14px;
`;

export const infoIcon = css`
  margin-right: 8px;
`;
