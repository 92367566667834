import PropTypes from 'prop-types';
import React from 'react';
import BemHelper from '@flowio/bem-helper';

if (process.browser) {
  require('./description-group.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('description-group');

const DescriptionGroup = ({
  children, className, spacing, ...otherProps
}) => (
  <div {...otherProps} className={bem.block(className, { spacing })}>
    {children}
  </div>
);

DescriptionGroup.displayName = 'DescriptionGroup';

DescriptionGroup.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  spacing: PropTypes.bool,
};

DescriptionGroup.defaultProps = {
  className: undefined,
  spacing: true,
};

export default DescriptionGroup;
