import assign from 'lodash/assign';
import { ActionTypes } from '../constants';
import { CatalogState } from '../types';
import { RootActionTypes } from '../../../stores/types';

const initialState: CatalogState = {
  attributes: undefined,
  errors: [],
  item: undefined,
  functions: [],
  organization: undefined,
  suggestions: [],
  harmonizationResults: undefined,
  selectedCountries: [],
  showAllTariffCodes: false,
  currentSearchTerm: '',
};

export default function catalogReducer(
  state = initialState,
  action: RootActionTypes,
): CatalogState {
  switch (action.type) {
    case ActionTypes.CHANGED_CATALOG_SEARCH:
      return assign({}, state, { currentSearchTerm: action.payload });
    case ActionTypes.GET_CATALOG_SUGGESTION_REQUEST:
      return assign({}, state, { suggestions: [] });
    case ActionTypes.GET_CATALOG_SUGGESTION_SUCCESS:
      return assign({}, state, { suggestions: action.payload });
    case ActionTypes.FETCH_CATALOG_ITEM_SUCCESS:
      return assign({}, state, { errors: [], item: action.payload });
    case ActionTypes.FETCH_CATALOG_ITEM_FAILURE:
      return assign({}, state, { errors: action.payload?.messages });
    case ActionTypes.FETCH_ITEM_HARMONIZATION_SUCCESS:
      return assign({}, state, {
        harmonizationResults: action.payload,
      });
    case ActionTypes.GET_ATTRIBUTES_SUCCESS:
      return assign({}, state, { attributes: action.payload });
    case ActionTypes.SET_SELECTED_CLASSIFICATION_COUNTRIES:
      return assign({}, state, {
        selectedCountries: action.payload,
      });
    case ActionTypes.SHOW_ALL_TARIFF_CODES:
      return assign({}, state, { showAllTariffCodes: true });
    case ActionTypes.HIDE_ALL_TARIFF_CODES:
      return assign({}, state, { showAllTariffCodes: false });
    default:
      return state;
  }
}
