import { Box, BoxItem } from '@flowio/react-box';
import { Image } from '@flowio/react-image';
import { Button } from '@flowio/react-button';
import { Card } from '@flowio/react-card';
import classNames from 'classnames';
import React from 'react';
import { MergedProps } from '../types';
import onBoardingCardStyles from './OnboardingCard.styles';
import { MarketingCardHeading } from '../../MarketingCard';
import Platforms from '../../../constants/Platforms';
import { MarketingGatewayIntegratedPlatform } from '../../../types';

const MAX_MANUAL_CARDS = 5;
const MANUAL_CARDS_LINE_COUNT = 3;

const getImageUrl = (platform: io.flow.internal.v0.enums.MarketingGatewayPlatform): string => `https://cdn.flow.io/console/logo_${platform}.svg`;

const OnboardingCard: React.FC<MergedProps> = ({
  platforms = [],
  integrationType,
  onboardPlatform,
}) => {
  if (!platforms.length) {
    return null;
  }

  const { platform: firstPlatform } = platforms[0];
  const isManual = integrationType === 'manual';
  const title = isManual ? 'Generate feeds for more channels' : Platforms(firstPlatform).platformName;
  const subtitle = isManual ? `Generate localized product feeds for more channels with the product data in your ${Platforms('google').platformName} account.` : `Generate and upload localized product feeds to ${Platforms(firstPlatform).platformName}.`;
  const buttonContent = isManual ? `Import Product Data from ${Platforms('google').shortName}` : `Connect to ${Platforms(firstPlatform).shortName}`;

  const handleOnboardClick = () => onboardPlatform(
    isManual ? 'google' : firstPlatform as MarketingGatewayIntegratedPlatform,
    isManual,
  );

  return (
    <Card data-automation-id="onboarding-card">
      <Box className={onBoardingCardStyles}>
        <Box
          direction="column"
          justifyContent="center"
          alignItems="center"
          className={classNames('logoContainer', ...platforms.map(({ platform }) => platform))}
        >
          <Box justifyContent="start" wrap>
            {platforms.slice(0, MAX_MANUAL_CARDS).map(({ platform }) => (
              <Image
                key={platform}
                width={56}
                className={classNames(`${isManual ? 'manual' : platform}Icon`)}
                source={getImageUrl(platform)}
                accessibilityLabel={`${platform} logo`}
              />
            ))}
            {platforms.length > MAX_MANUAL_CARDS && (
              <div className="othersCard">+ more</div>
            )}
          </Box>
          {isManual && platforms.length <= MANUAL_CARDS_LINE_COUNT && (
            <BoxItem className="moreChannels">
              <span>(+ more channels to come)</span>
            </BoxItem>
          )}
          {!isManual && (
            <Image
              width={250}
              className="productCards"
              source="https://cdn.flow.io/console/product-cards.svg"
              accessibilityLabel="onboarding logo"
            />
          )}
        </Box>

        <Box
          direction="column"
          className="textContainer"
          justifyContent="between"
          data-automation-id="onboarding-card-action"
        >
          <MarketingCardHeading
            showDivider={false}
            title={title}
            subtitle={subtitle}
          />
          {firstPlatform === 'google' ? (
            <Image
              className="googleConnectButton"
              onClick={handleOnboardClick}
              source="https://cdn.flow.io/console/google_signin.png"
              accessibilityLabel="Google Connect Button"
            />
          ) : (
            <Button
              content={buttonContent}
              onClick={handleOnboardClick}
            />
          )}
        </Box>
      </Box>
    </Card>
  );
};

OnboardingCard.displayName = 'OnboardingCard';

export default OnboardingCard;
