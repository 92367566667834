import DialogName from '../constants/DialogName';
import showDialog from './showDialog';

export default function showAddFeatureWorksheet(organizationId: string) {
  return showDialog(DialogName.ADD_FEATURE_WORKSHEET)({
    initialValues: {
      organizationId,
      type: 'boolean',
      rules: [],
    },
  });
}
