import { RootState, ThunkDispatcher, ThunkExtraArguments } from '../../../stores/types';
import { ActionTypes } from '../constants';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';
import toLegacyResponse from '../../../utilities/clients/to-legacy-response';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import { HarmonizationHs6ExportFormData } from '../types';

type PostResponse = io.flow.v0.models.Export | io.flow.v0.models.GenericError | undefined;

export default function exportHs6(
  organization: string,
  formData: HarmonizationHs6ExportFormData,
) {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<LegacyResponse<PostResponse>> => {
    dispatch({
      type: ActionTypes.EXPORT_HS6_REQUEST,
      formData,
    });

    const exportType: io.flow.v0.unions.ExportType = {
      discriminator: 'harmonization_hs6_export_type',
      numbers: formData.numbers,
      has_codes: formData.has_codes,
      sort: formData.sort,
    };

    return extra.api(getState()).exports.post({
      organization,
      body: {
        type: exportType,
        emails: [formData && formData.email],
      },
    }).then((response) => {
      if (response.ok) {
        dispatch({
          type: ActionTypes.EXPORT_HS6_SUCCESS,
          payload: response.body,
          formData,
        });
        return toLegacyResponse<io.flow.v0.models.Export>(response);
      }

      dispatch({
        params: {},
        type: ActionTypes.EXPORT_HS6_FAILURE,
        payload: formatErrorResponseV2(response),
      });

      return toLegacyResponse<io.flow.v0.models.GenericError | undefined>(response);
    });
  };
}
