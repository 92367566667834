import { MapStateToProps, connect } from 'react-redux';
import {
  getCountries, getLanguages,
} from '../../reference/selectors';
import {
  getFeedSources,
} from '../selectors';
import GoogleDataSources from '../components/GoogleDataSources';

import { RootState } from '../../../stores/types';
import { isGoogleFeedSource } from '../types/utilities';

interface StateProps {
  feeds: io.flow.internal.v0.models.MarketingGatewayFeedSourceGoogle[];
  countries: io.flow.v0.models.Country[];
  languages: io.flow.v0.models.Language[];
}

const mapStateToProps: MapStateToProps<StateProps, {}, RootState> = (
  state: RootState,
): StateProps => {
  const sources: io.flow.internal.v0.models.MarketingGatewayFeedSourceGoogle[] = getFeedSources(
    state,
  ).filter(isGoogleFeedSource);

  return { // eslint-disable-line
    feeds: sources,
    countries: getCountries(state),
    languages: getLanguages(state),
  };
};

export default connect(mapStateToProps)(GoogleDataSources);
