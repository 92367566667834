import Create from '../../../modules/experience/components/create/containers/create';
import ExperienceLogisticsSettingPage from '../../../modules/experience/components/ExperienceLogisticsSettingPage';
import LandedCostIndex from '../../../modules/experience/components/LandedCostIndex/containers/LandedCostIndex';
import Listing from '../../../modules/experience/components/ExperienceListing/containers/ExperienceListing';
import Localization from '../../../modules/experience/components/localization/containers/localization';
import Pricing from '../../../modules/experience/components/pricing/containers/pricing';
import ItemMarginCreate from '../../../modules/experience/components/item-margin-create/containers/item-margin-create';
import ItemMarginEdit from '../../../modules/experience/components/item-margin-edit/containers/item-margin-edit';
import PaymentMethodRules from '../../../modules/experience/components/PaymentMethodRulesContainer';
import { PlainRouteWithTitle } from '../../types';

const ExperienceRoutes: PlainRouteWithTitle = {
  path: ':organization/experience',
  indexRoute: { component: Listing },
  title: 'Experiences',
  childRoutes: [
    {
      path: ':experienceKey/landed-cost',
      component: LandedCostIndex,
      title: 'Landed Cost',
    },
    {
      path: ':experienceKey/localization',
      component: Localization,
      title: 'Localization',
    },
    {
      path: ':experienceKey/pricing',
      component: Pricing,
      title: 'Pricing',
    },
    {
      path: ':experienceKey/pricing/margins/create',
      component: ItemMarginCreate,
      title: 'Item Margin Create',
    },
    {
      path: ':experienceKey/pricing/margins/:marginKey',
      component: ItemMarginEdit,
      title: 'Item Margin Edit',
    },
    {
      path: ':experienceKey/payment-methods',
      component: PaymentMethodRules,
      title: 'Payment Methods Display Order',
    },
    {
      path: ':experienceKey/logistics/v2',
      component: ExperienceLogisticsSettingPage,
      title: 'Logistics',
    },
    {
      path: 'create',
      component: Create,
      title: 'Create',
    },
  ],
};

export default ExperienceRoutes;
