import Client from './client';

export default class ShopifyMarketsShops extends Client {
  constructor(opts) {
    let options = opts;

    if (typeof opts === 'string') {
      options = { host: opts }; // convert host string to options object
    }

    options.serviceName = 'API Internal';

    super(options);
  }

  get(options = {}) {
    return this.makeRequest(`${this.host}/internal/shopify/markets/shops`, options);
  }

  getByOrganization(organization, options = {}) {
    return this.makeRequest(`${this.host}/internal/shopify/markets/shops/${organization}`, options);
  }

  putByOrganization(organization, options = {}) {
    return this.makeRequest(`${this.host}/internal/shopify/markets/shops/${organization}`, {
      ...options,
       method: 'PUT',
    });
  }

  deleteByOrganization(organization, options = {}) {
    return this.makeRequest(`${this.host}/internal/shopify/markets/shops/${organization}`, {
      ...options,
       method: 'DELETE',
    });
  }

}
