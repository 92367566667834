import update from 'immutability-helper';
import { RootActionTypes } from '../../../stores/types';
import ActionTypes from '../constants/action-types';
import { FeatureDialogsState } from '../types/state';

const initialState: FeatureDialogsState = {};

export default function (state = initialState, action: RootActionTypes) {
  switch (action.type) {
    case ActionTypes.SHOW_DIALOG:
      return update(state, {
        [action.meta.name]: {
          $set: { isOpen: true, ...action.payload },
        },
      });
    case ActionTypes.HIDE_DIALOG:
      return update(state, {
        [action.meta.name]: {
          $set: { isOpen: false },
        },
      });
    default:
      return state;
  }
}
