import styled from '@emotion/styled';
import BemHelper from '@flowio/bem-helper';
import noop from 'lodash/noop';
import React, { FunctionComponent } from 'react';
import {
  Toolbar,
  ToolbarActions, ToolbarBranding,
  ToolbarItem,
} from '@flowio/react-toolbar';
import colors from '../../../../theme/tokens/colors';
import GlobalSearchBox from '../../../global-searchbox/containers/global-searchbox';
import { TopNavigationProps } from '../../types/props';
import Logo from '../logo/logo';
import TopNavigationActions from './top-navigation-actions';

if (process.browser) {
  require('./top-navigation.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('top-navigation');

const displayName = 'TopNavigation';

const defaultProps = {
  organization: undefined,
  memberships: [],
  onMembershipChange: noop,
  isCustomerSerivceUser: false,
  isChannelOrgAdmin: false,
  isFulfillmentUser: false,
  isClassificationUser: false,
  routes: [],
  params: {},
};
const StyledToolbar = styled(Toolbar)`
  background: ${colors.flowBlack};
`;

const StyledToolbarItem = styled(ToolbarItem)`
    margin: auto;
  `;
const TopNavigation: FunctionComponent<TopNavigationProps> = ({
  user,
  memberships,
  isCustomerSerivceUser,
  isChannelOrgAdmin,
  isFulfillmentUser,
  isClassificationUser,
  organization,
  routes,
  params,
  onMembershipChange,
}) => (
  <header className={bem.block()}>
    <StyledToolbar>
      <ToolbarBranding className={bem.element('logo-container')}>
        <Logo className={bem.element('logo')} />
      </ToolbarBranding>
      <StyledToolbarItem className={bem.element('top-items')}>
        {!isFulfillmentUser && !isCustomerSerivceUser
          && !isChannelOrgAdmin && !isClassificationUser && (
          <GlobalSearchBox routes={routes} params={params} />
        )}
      </StyledToolbarItem>
      <ToolbarActions className={bem.element('top-actions')}>
        <TopNavigationActions
          user={user}
          memberships={memberships}
          organization={organization}
          onMembershipChange={onMembershipChange}
          showNotificationBell={!isChannelOrgAdmin && !isFulfillmentUser}
        />
      </ToolbarActions>
    </StyledToolbar>
  </header>
);

TopNavigation.displayName = displayName;
TopNavigation.defaultProps = defaultProps;

export default TopNavigation;
