import update from 'immutability-helper';

import { DiscountState } from '../types';
import ActionTypes from '../constants/ActionTypes';
import Status from '../constants/Status';
import { RootActionTypes } from '../../../stores/types';

const initialState: DiscountState = {
  ui: {
    discountStatusFilter: [
      Status.Scheduled,
      Status.Active,
      Status.Expired,
    ],
  },
  currentDiscount: undefined,
  discounts: [],
};

export default function (state = initialState, action: RootActionTypes): DiscountState {
  switch (action.type) {
    case ActionTypes.FETCH_DISCOUNT_SUCCESS:
      return update(state, {
        currentDiscount: { $set: action.payload },
      });
    case ActionTypes.FETCH_DISCOUNTS_SUCCESS:
      return update(state, {
        discounts: { $set: action.payload },
      });
    case ActionTypes.DISCOUNT_FILTERS_UPDATED:
      return update(state, {
        ui: {
          $set: {
            ...action.payload,
          },
        },
      });
    case ActionTypes.CLEAR_CURRENT_DISCOUNT:
      return update(state, {
        currentDiscount: { $set: undefined },
      });
    default:
      return state;
  }
}
