import { createSelector } from 'reselect';
import property from 'lodash/property';

import getLogistisState from './getLogisticsState';
import { RootState } from '../../../stores/types';
import { LogisticsRootState, RatecardState } from '../types/state';

export default createSelector<RootState, LogisticsRootState, RatecardState>(
  getLogistisState,
  property('entities.ratecards'),
);
