import { bindActionCreators, compose } from 'redux';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { isSubmitting, submit } from 'redux-form';

import { hideReturnPolicyWorksheet } from '../actions';
import {
  getInitialValuesForReturnPolicyWorksheet,
  getIsReturnPolicyWorksheetVisible,
  getStatusForReturnPolicyWorksheet,
  getStructuredAvailableFiltersForReturnPolicyWorksheet,
  getReturnPolicyFormError,
} from '../selectors';
import FormName from '../constants/FormName';
import ReturnPolicyWorksheet from '../components/ReturnPolicyWorksheet';
import {
  ReturnPolicyWorksheetStateProps,
  ReturnPolicyWorksheetDispatchProps,
} from '../types';
import { RootState } from '../../../stores/types';

const mapStateToProps: MapStateToProps<
ReturnPolicyWorksheetStateProps, {}, RootState> = (state) => ({
  availableFilters: getStructuredAvailableFiltersForReturnPolicyWorksheet(state),
  initialValues: getInitialValuesForReturnPolicyWorksheet(state),
  open: getIsReturnPolicyWorksheetVisible(state),
  status: getStatusForReturnPolicyWorksheet(state),
  submissionApiError: getReturnPolicyFormError(state),
  submitting: isSubmitting(FormName.ReturnPolicyForm)(state),
});

const remoteSubmitReturnPolicyForm = () => submit(FormName.ReturnPolicyForm);

const mapDispatchToProps: MapDispatchToProps<
ReturnPolicyWorksheetDispatchProps, {}> = (dispatch) => bindActionCreators({
  onRequestCancel: hideReturnPolicyWorksheet,
  onSubmitSuccess: hideReturnPolicyWorksheet,
  onRequestSave: remoteSubmitReturnPolicyForm,
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(ReturnPolicyWorksheet);
