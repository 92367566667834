export enum ReadyStates {
  // Request has not been sent for this resource.
  PENDING = 'pending',
  // Request for this resource is currently in flight.
  LOADING = 'loading',
  // Requestfor this resource completed successfully.
  FULFILLED = 'fulfilled',
  // Request for this resource completed unsuccessfully.
  REJECTED = 'rejected',
}

export interface CenterQueryState {
  status: ReadyStates;
  result: io.flow.v0.models.Center[];
}

export interface DialogState {
  [name: string]: any;
}

export interface ExperiencesState {
  [id: string]: io.flow.v0.models.Experience;
}

export interface ExperiencesByShippingConfigurationState {
  [shippingConfigurationKey: string]: {
    status: string;
    entities: string[];
  };
}

export interface LogisticPaginationState {
  outbound_lanes_page: number;
  return_lanes_page: number;
}

export type RatecardState = io.flow.v0.models.Ratecard[];

export interface ShippingConfigurationsState {
  entities: {
    [shippingConfigurationKey: string]: io.flow.v0.models.ShippingConfiguration;
  }
}

export interface SurchargesState {
  settings: io.flow.v0.models.SurchargeSettingDisplay[];
}

export interface LogisticsEntitiesState {
  experiences: ExperiencesState;
  pagination: LogisticPaginationState;
  ratecards: RatecardState;
  shippingConfigurations: ShippingConfigurationsState;
  surcharges: SurchargesState;
}

export interface LogisticsQueries {
  center: CenterQueryState;
}

export interface LogisticsRootState {
  activeShippingConfiguration: string;
  activeShippingLane: string;
  dialogs: DialogState;
  experiencesByShippingConfiguration: ExperiencesByShippingConfigurationState;
  entities: LogisticsEntitiesState;
  queries: LogisticsQueries;
}
