import { PaymentMethodType } from '@flowio/api-constants';
import sumBy from 'lodash/sumBy';
import ActionTypes from '../constants/action-types';
import { getPaymentMethodsDisplayOrder } from '../selectors';
import { ThunkDispatcher, RootState } from '../../../stores/types';

/**
 * @description So this method kinda needs an essay to explain what is going on...
 * On the UI credit/debit cards are rendered as one entity, but they are infact n multiple entites.
 * This code takes the index of the first card in the list and the target index to where all cards
 * should be moved to. It then makes sure it selects all cards from the list (not assuming that they
 * are all sequential). Then it removes them from the new list and adds them all to in the right
 * location where the user has DnD'd them. This ensures the status quo of all cards being listed
 * together is maintained. This also works for a sane use case where you just want to move one item
 * to another index.
 *
 * This what happens when the server-side model is not appropriate for the UX model being
 * implemented :-o
 *
 * neccessary GIF: https://media.giphy.com/media/12NUbkX6p4xOO4/giphy.gif
 */
interface UpdatePaymentMethodsOrderParams {
  sourceIndex: number;
  targetIndex: number;
}

export default function updatePaymentMethodsOrder(
  {
    sourceIndex,
    targetIndex,
  }: UpdatePaymentMethodsOrderParams,
) {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
  ): void => {
    const state = getState();
    const pms: io.flow.v0.models.PaymentMethodRule[] = getPaymentMethodsDisplayOrder(state);
    const cards = pms.filter((pm) => pm.payment_method.type === PaymentMethodType.CARD);

    const cardsSpliceIndex = pms.findIndex(
      (pm) => pm.payment_method.type === PaymentMethodType.CARD,
    );

    let indexesToMove = [
      sourceIndex > cardsSpliceIndex
        ? sourceIndex + cards.length - 1
        : sourceIndex,
    ];
    const normalisedTargetIndex = targetIndex > cardsSpliceIndex
      ? targetIndex + cards.length - 1
      : targetIndex;

    // If it's cards being reordered, it's party time!
    // We need to find out where they are in the array to be sure, to be sure.
    // i.e. not presuming they are sequential
    if (pms[indexesToMove[0]].payment_method.type === PaymentMethodType.CARD) {
      // if dragged item is a CC, we need to move all the CCs
      indexesToMove = pms.reduce<number[]>((acc, pm, i) => {
        if (pm.payment_method.type === PaymentMethodType.CARD) {
          acc.push(i);
        }
        return acc;
      }, []);
    }
    indexesToMove.sort((a, b) => (a - b));

    // if the target index is after the item we are swapping, it should be decremented.
    // by 1 for every item that is at a lower index.
    const targetAfterSourceSplice = normalisedTargetIndex - Math.max(sumBy(
      indexesToMove, (i) => (normalisedTargetIndex > i ? 1 : 0),
    ) - 1, 0);

    // create a copy of the array
    const pmsCopy = [...pms];
    // create a new array to hold elements being moved.
    const movingItems: io.flow.v0.models.PaymentMethodRule[] = [];

    // Splice elements to be moved out of array and into holding array.
    indexesToMove.reduce<number[]>((acc, index) => {
      const indexShift = sumBy(acc, (i) => (i < index ? 1 : 0));
      movingItems.push(...pmsCopy.splice(index - indexShift, 1));
      acc.push(index - indexShift);
      return acc;
    }, []);

    // splice moving items back into array at desired target location.
    pmsCopy.splice(targetAfterSourceSplice, 0, ...movingItems);

    // re-set all display positions for new order.
    const reIndexedPms = pmsCopy.map((pm, i) => ({
      ...pm,
      display_position: i,
    }));

    dispatch({
      type: ActionTypes.SET_PAYMENT_METHODS_DISPLAY_ORDER,
      payload: reIndexedPms,
    });
  };
}
