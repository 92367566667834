import { Confirm } from '@flowio/react-confirm';
import React from 'react';
import noop from 'lodash/noop';

import ShippingLaneDeleteForm from '../../containers/ShippingLaneDeleteForm';
import { ShippingLaneDeleteConfirmationProps } from '../../types/components';

const ShippingLaneDeleteConfirmation: React.FC<ShippingLaneDeleteConfirmationProps> = ({
  initialValues = {},
  isOpen = false,
  isSubmitting = false,
  onCancel = noop,
  onOk = noop,
}) => (
  <Confirm
    caption="Delete Shipping Lane"
    disabled={isSubmitting}
    intent="negative"
    onCancel={onCancel}
    onOk={onOk}
    open={isOpen}
  >
    <ShippingLaneDeleteForm initialValues={initialValues} />
  </Confirm>
);

ShippingLaneDeleteConfirmation.displayName = 'ShippingLaneDeleteConfirmation';

export default ShippingLaneDeleteConfirmation;
