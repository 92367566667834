import { connect } from 'react-redux';
import { RootState } from '../../../stores/types';
import PublicKeys from '../components/public-keys';
import { getPublicKeys } from '../selectors';

function mapStateToProps(state: RootState) {
  const publicKeys = getPublicKeys(state);

  return {
    isFirstPage: publicKeys.isFirstPage,
    isLastPage: publicKeys.isLastPage,
    keys: publicKeys.results,
  };
}

export default (connect(mapStateToProps)(PublicKeys));
