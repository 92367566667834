import { $HttpResponse } from '@flowio/api-sdk';
import { ThunkResult, RootActionTypes } from '../../../stores/types';
import ActionTypes from '../constants/ActionTypes';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';

function updateDiscountRequestAction(): RootActionTypes {
  return {
    type: ActionTypes.UPDATE_DISCOUNT_REQUEST,
  };
}

function updateDiscountSuccessAction(
  payload: io.flow.v0.models.DiscountRuleSettings,
): RootActionTypes {
  return {
    type: ActionTypes.UPDATE_DISCOUNT_SUCCESS,
    payload,
  };
}

function updateDiscountFailureAction(payload: FormattedErrorMessages): RootActionTypes {
  return {
    type: ActionTypes.UPDATE_DISCOUNT_FAILURE,
    payload,
    error: true,
  };
}

export default function updateDiscount(
  organization: string,
  discountId: string,
  form: io.flow.v0.models.DiscountRuleSettingsForm,
): ThunkResult<Promise<void | io.flow.v0.models.DiscountRuleSettings>> {
  return function updateDiscountEffects(
    dispatch,
    getState,
    extra,
  ): Promise<void | io.flow.v0.models.DiscountRuleSettings> {
    const state = getState();
    dispatch(updateDiscountRequestAction());

    return extra.api(state).discountRuleSettings.putById({
      id: discountId,
      organization,
      body: form,
    }).then((response) => {
      if (response.ok) {
        dispatch(updateDiscountSuccessAction(response.body));
        return response.body;
      }

      const errorResponse = response as $HttpResponse<io.flow.v0.models.GenericError>;

      dispatch(updateDiscountFailureAction(formatErrorResponseV2(errorResponse)));
      return undefined;
    });
  };
}
