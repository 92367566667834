import OrderDetailSearchView from '../../../modules/customer-service/containers/order-detail-search-view';
import OrderDetails from '../../../modules/customer-service/containers/order-details';
import OrderReplacement from '../../../modules/customer-service/components/OrderReplacement';

export default {
  path: ':organization/orders',
  childRoutes: [{
    path: 'search',
    component: OrderDetailSearchView,
    title: 'Customer Service',
  }, {
    path: 'replacement/:number',
    component: OrderReplacement,
    title: 'Order Replacement',
  }, {
    path: ':number',
    component: OrderDetails,
    title: 'Order Details',
  }],
};
