import { RouterState } from 'react-router';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withFetch } from '@flowio/redux-fetch';
import { LaneDirection } from '@flowio/api-constants';
import get from 'lodash/get';

import * as actions from '../actions';
import { DialogName, FormMethod } from '../constants';
import { fetchCountries, fetchRegions } from '../../reference/actions';
import { getActiveShippingConfiguration, getActiveShippingLane } from '../selectors';
import { fetchOrganization } from '../../organization/actions';
import { getOrganizationId } from '../../organization/selectors';
import { getCountries, getRegions } from '../../reference/selectors';
import ShippingLaneDetailPage from '../components/ShippingLaneDetailPage';
import { RootState, ThunkDispatcher } from '../../../stores/types';
import { ShippingLaneDetailPageStateProps } from '../types/components';

function handleAddOutboundTier(
  organizationId: string,
  shippingLane: io.flow.v0.models.ShippingLane,
) {
  return actions.showTierWorksheet({
    method: FormMethod.POST,
    organizationId,
    shippingLane,
    title: 'Add Shipping Tier',
  });
}

function handleAddReturnTier(
  organizationId: string,
  shippingLane: io.flow.v0.models.ShippingLane,
) {
  return actions.showTierWorksheet({
    method: FormMethod.POST,
    organizationId,
    shippingLane,
    title: 'Add Return Shipping Tier',
  });
}

function handleDeleteTier(
  organizationId: string,
  shippingLane: io.flow.v0.models.ShippingLane,
  tier: io.flow.v0.models.Tier,
) {
  return actions.updateDialog(DialogName.TIER_DELETE_CONFIRMATION, {
    open: true,
    initialValues: {
      organizationId,
      shippingConfigurationKey: shippingLane.shipping_configuration.key,
      shippingLaneId: shippingLane.id,
      tierId: tier.id,
    },
  });
}

function handleDeleteShippingLane(
  organizationId: string,
  shippingConfiguration: io.flow.v0.models.ShippingConfiguration,
  shippingLane: io.flow.v0.models.ShippingLane,
) {
  return actions.updateDialog(DialogName.SHIPPING_LANE_DELETE_CONFIRMATION, {
    open: true,
    initialValues: {
      organizationId,
      shippingConfigurationKey: shippingConfiguration.key,
      shippingLaneId: shippingLane.id,
    },
  });
}

function handleEditTier(
  organizationId: string,
  shippingLane: io.flow.v0.models.ShippingLane,
  tier: io.flow.v0.models.Tier,
) {
  return actions.showTierWorksheet({
    method: FormMethod.PUT,
    organizationId,
    shippingLane,
    tier,
    title: tier.direction === LaneDirection.OUTBOUND ? 'Update Shipping Tier' : 'Update Return Shipping Tier',
  });
}

function handleEditShippingLane(
  organizationId: string,
  shippingConfiguration: io.flow.v0.models.ShippingConfiguration,
  shippingLane: io.flow.v0.models.ShippingLane,
) {
  const shippingConfigurationKey = get(shippingConfiguration, 'key');
  return actions.showShippingLaneWorksheet({
    method: FormMethod.PUT,
    organizationId,
    shippingConfigurationKey,
    shippingLane,
    shippingLaneDirection: shippingLane.direction,
    title: shippingLane.direction === LaneDirection.OUTBOUND ? 'Update Outbound Shipping Lane' : 'Update Return Shipping Lane',
  });
}

const fetchState = (
  dispatch: ThunkDispatcher,
  _getState: () => RootState,
  props: RouterState,
) => {
  const { params } = props;
  const { organization, shippingConfigurationKey, shippingLaneId } = params;
  return Promise.all([
    dispatch(fetchOrganization(organization)),
    dispatch(fetchCountries()),
    dispatch(fetchRegions()),
    dispatch(actions.fetchShippingConfigurationByKey({
      organizationId: organization,
      shippingConfigurationKey,
    })),
    dispatch(actions.changeActiveShippingConfiguration({
      shippingConfigurationKey,
    })),
    dispatch(actions.changeActiveShippingLane({
      shippingLaneId,
    })),
  ]);
};

const mapStateToProps = createStructuredSelector<RootState, ShippingLaneDetailPageStateProps>({
  countries: getCountries,
  organizationId: getOrganizationId,
  regions: getRegions,
  shippingConfiguration: getActiveShippingConfiguration,
  shippingLane: getActiveShippingLane,
});

const mapDispatchToProps = (dispatch: ThunkDispatcher) => bindActionCreators({
  onAddOutboundTier: handleAddOutboundTier,
  onAddReturnTier: handleAddReturnTier,
  onDeleteOutboundTier: handleDeleteTier,
  onDeleteReturnTier: handleDeleteTier,
  onDeleteShippingLane: handleDeleteShippingLane,
  onEditOutboundTier: handleEditTier,
  onEditReturnTier: handleEditTier,
  onEditShippingLane: handleEditShippingLane,
}, dispatch);

export default compose<React.FC>(
  withFetch(fetchState),
  connect(mapStateToProps, mapDispatchToProps),
)(ShippingLaneDetailPage);
