/**
 * Creates an action for fetching the Gross Merchandise Value (GMV) for an organization over a
 * period of time.
 * @param {String} organization - An organization unique identifier.
 * @param {String} region - A region for fetching GMV data (e.g. "world").
 * @param {String} startDate - An ISO 8601 formatted date time string used as the start date for
 * fetching GMV data (e.g. "2017-07-11T04:00:00.000Z").
 * @param {String} endDate - An ISO 8601 formatted date time string used as the end date for
 * fetching GMV data (e.g. "2017-07-18T03:59:59.999Z").
 * @param {ReportInterval} interval - Data returned will be aggregated to the provided interval.
 */
import isEqual from 'lodash/isEqual';
import { ReportInterval } from '@flowio/api-internal-constants';
import { OrderRevenueTimelineChartsGetResponse } from '@flowio/api-internal-sdk';
import ActionTypes from '../constants/action-types';
import ReadyStates from '../constants/ready-states';
import getGmvByTimelineParams from '../selectors/get-gmv-by-timeline-params';
import getGmvByTimelineReadyState from '../selectors/get-gmv-by-timeline-ready-state';
import { ThunkDispatcher, RootState, ThunkExtraArguments } from '../../../stores/types';
import { PropType } from '../../../types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';
import { FetchTimelineRevenueParams } from '../types';

export default function fetchTimelineRevenue(
  organization: string,
  region: string,
  startDate: string,
  endDate: string,
  interval: ReportInterval,
) {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<PropType<OrderRevenueTimelineChartsGetResponse, 'body'> | void> => {
    const state = getState();

    const params: FetchTimelineRevenueParams = {
      organization, region, startDate, endDate, interval,
    };

    if (isEqual({
      params: getGmvByTimelineParams(state),
      readyState: getGmvByTimelineReadyState(state),
    }, {
      params,
      readyState: ReadyStates.FULFILLED,
    })) {
      return Promise.resolve();
    }

    dispatch({
      type: ActionTypes.FETCH_TIMELINE_REVENUE_REQUEST,
      params,
    });

    return extra.apiInternal(state).orderRevenueTimelineCharts.get({
      organization,
      region,
      on_or_after: startDate,
      on_or_before: endDate,
      interval,
    }).then((response) => {
      if (response.ok) {
        dispatch({
          type: ActionTypes.FETCH_TIMELINE_REVENUE_SUCCESS,
          payload: response.body,
          params,
        });
        return response.body;
      }

      dispatch({
        type: ActionTypes.FETCH_TIMELINE_REVENUE_FAILURE,
        payload: formatErrorResponseV2(response),
      });
      return response.body;
    });
  };
}
