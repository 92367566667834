import { FormErrors } from 'redux-form';
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { Button } from '@flowio/react-button';

import { Worksheet, WorksheetHeader, WorksheetBody } from '@flowio/react-worksheet';
import OrderRefundForm from '../../containers/OrderRefundForm';

interface OrderRefundDialogProps {
  allocations: io.flow.v0.models.AllocationV2;
  amountRefunded: number;
  formErrors: FormErrors<{}, string>;
  isOpen: boolean;
  onRequestCancel: () => void;
  onSubmit: () => void;
}

const OrderRefundDialog: React.FC<OrderRefundDialogProps> = ({
  allocations,
  amountRefunded,
  formErrors,
  isOpen,
  onRequestCancel,
  onSubmit,
}) => (
  <Worksheet open={isOpen}>
    <WorksheetHeader
      content="Refund Order Payment"
      leftDecoration={<Button content="Cancel" type="button" onClick={onRequestCancel} />}
      rightDecoration={<Button intent="primary" type="button" content="Issue Refund" onClick={onSubmit} disabled={!isEmpty(formErrors)} />}
    />
    <WorksheetBody>
      <div>
        <OrderRefundForm allocations={allocations} amountRefunded={amountRefunded} />
      </div>
    </WorksheetBody>
  </Worksheet>
);

export default OrderRefundDialog;
