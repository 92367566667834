import { css } from '@emotion/css';

export const preview = css`
  padding: 8px;
  border-radius: 4px;
  background-color: #f2f5f7;
`;

export const content = css`
  position: relative;
  padding: 8px 16px 16px;
  border-radius: 4px;
  background-color: #ffffff;
`;

export const watermark = css`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  color: color(#77818f);
  font-size: 50px;
  font-weight: 700;
  text-align: center;
  opacity: 0.15;
  transform: translate(-50%, -50%) rotate(-45deg);
  user-select: none;
`;

export const heading = css`
  margin: 0;
  color: #17181a;
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
  text-transform: uppercase;
`;

export const deliveredDuty = css`
  margin: 0;
  color: #4d5866;
  font-size: 14px;
  line-height: 24px;
`;

export const customerChoice = css`
  margin: 0;
  color: #4d5866;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-decoration: underline;
  cursor: pointer;
`;

export const disclaimer = css`
  display: block;
  padding: 0 14px;
  margin: 14px 0 6px;
  color: #77818f;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
`;

export const section = css`
  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const radioLabel = css`
  margin-left: 5px;
`;
