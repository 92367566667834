import ForgotPassword from '../../../modules/auth/containers/forgot-password';
import Login from '../../../modules/auth/containers/login';
import Logout from '../../../modules/auth/components/logout/containers/logout';
import Registration from '../../../modules/auth/containers/registration';
import RegistrationConfirmation from '../../../modules/auth/components/registration-confirmation';
import ResetPassword from '../../../modules/auth/containers/reset-password';

export default {
  childRoutes: [
    /* Redirect routes. Defined for backward compatibility. */
    {
      path: 'auth/login',
      authRequired: false,
      onEnter: (nextState, replace) => replace(`/login${nextState.location.search}`),
    },
    {
      path: 'auth/register',
      authRequired: false,
      onEnter: (nextState, replace) => replace('/register'),
    },
    {
      path: 'auth/password/reset',
      authRequired: false,
      onEnter: (nextState, replace) => replace('/password/reset'),
    },
    {
      path: 'auth/password/reset/:token',
      authRequired: false,
      onEnter: (nextState, replace) => replace(`/password/reset/${nextState.params.token}`),
    },
    {
      path: 'password/reset',
      component: ForgotPassword,
      authRequired: false,
      hideNavigation: true,
      title: 'Forgot Password',
    },
    {
      path: 'login',
      component: Login,
      authRequired: false,
      hideNavigation: true,
      title: 'Log In',
    },
    {
      path: 'logout',
      component: Logout,
      authRequired: false,
      hideNavigation: true,
      title: 'Log Out',
    },
    {
      path: 'register',
      component: Registration,
      authRequired: false,
      hideNavigation: true,
      title: 'Registration',
    },
    {
      path: 'register/confirmation',
      component: RegistrationConfirmation,
      authRequired: false,
      hideNavigation: true,
      title: 'Registration Confirmation',
    },
    {
      path: 'password/reset/:token',
      component: ResetPassword,
      authRequired: false,
      hideNavigation: true,
      title: 'Reset Password',
    },
  ],
};
