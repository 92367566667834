import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import FeatureListFilterForm from '../components/FeatureListFilterForm';
import FormName from '../constants/FormName';
import {
  getStatusFilterValues,
  getSearchValue,
} from '../selectors';

import {
  FeatureListFilterFormOwnProps,
  FeatureListFilterFormStateProps,
  FeatureListFilterFormValues,
} from '../types/components';
import { RootState, ThunkDispatcher } from '../../../stores/types';

function handleSubmit(
  values: FeatureListFilterFormValues,
  _dispatch: ThunkDispatcher,
  props: FeatureListFilterFormOwnProps,
) {
  const {
    featureSearch,
    featureStatusFilter,
  } = values;

  const { onRequestFeatureListFilterChange } = props;
  const searchFilter: io.flow.v0.models.QueryFilterUnstructuredForm = {
    q: featureSearch,
    discriminator: 'unstructured',
  };
  const statusFilter: io.flow.v0.models.QueryFilterStructuredForm = {
    discriminator: 'structured',
    field: 'feature.status',
    operator: 'in',
    values: featureStatusFilter,
  };
  const filters: io.flow.v0.unions.QueryFilterForm[] = [searchFilter, statusFilter];

  onRequestFeatureListFilterChange(filters);
}

function mapStateToProps(state: RootState): FeatureListFilterFormStateProps {
  const statusFilterValue = getStatusFilterValues(state);
  const searchValue = getSearchValue(state);

  return {
    initialValues: {
      featureStatusFilter: statusFilterValue,
      featureSearch: searchValue,
    },
  };
}

export default compose<React.FC<FeatureListFilterFormOwnProps>>(
  connect(mapStateToProps),
  reduxForm({
    form: FormName.FEATURE_LIST_FILTER_FORM,
    onSubmit: handleSubmit,
  }),
)(FeatureListFilterForm);
