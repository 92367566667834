import React from 'react';
import { TableBody, TableRow, TableRowColumn } from '@flowio/react-table';
import { Banner } from '@flowio/react-banner';
import formatCount from '../../../utilities/formatCount';

type Props = {
  details?: io.flow.internal.v0.unions.MarketingGatewayChannelDetails;
  error?: io.flow.internal.v0.models.MarketingGatewayError;
};

const isGoogleDetails = (
  channelDetails?: io.flow.internal.v0.unions.MarketingGatewayChannelDetails,
): channelDetails is io.flow.internal.v0.models.MarketingGatewayGoogleChannelDetails => (
  !!channelDetails && (
    channelDetails as io.flow.internal.v0.models.MarketingGatewayGoogleChannelDetails
  ).discriminator === 'marketing_gateway_google_channel_details'
);

const GoogleChannelDetails: React.FC<Props> = ({
  details,
  error,
}) => {
  const googleDetails = isGoogleDetails(details) ? details : undefined;

  return (
    <>
      {error && (
        <Banner intent="negative" heading="Error" text={error.messages.join('\n')} />
      )}
      {googleDetails && (
        <TableBody>
          <TableRow>
            <TableRowColumn>Account Name</TableRowColumn>
            <TableRowColumn>{googleDetails.account_name}</TableRowColumn>
          </TableRow>
          <TableRow>
            <TableRowColumn>Account ID</TableRowColumn>
            <TableRowColumn>{googleDetails.account_id}</TableRowColumn>
          </TableRow>
          <TableRow>
            <TableRowColumn># of Localized Feeds</TableRowColumn>
            <TableRowColumn>{formatCount(googleDetails.localized_feed_count, 'feed')}</TableRowColumn>
          </TableRow>
        </TableBody>
      )}
    </>
  );
};

GoogleChannelDetails.displayName = 'GoogleChannelDetails';

export default GoogleChannelDetails;
