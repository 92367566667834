import createQueryBuilder from '../utilities/createQueryBuilder';

import ActionType from '../constants/ActionType';
import updateExclusionRule from './updateExclusionRule';
import createExclusionRule from './createExclusionRule';
import { ThunkResult } from '../../../stores/types';
import { ExclusionRuleFormValues } from '../types';

export default function submitExclusionRuleForm(
  values: ExclusionRuleFormValues,
): ThunkResult<Promise<void>> {
  return function sumitExclusionRuleFormEffects(
    dispatch,
    getState,
  ) {
    const state = getState();
    dispatch({ type: ActionType.SubmitExclusionRuleForm });
    return createQueryBuilder(
      state,
      values.organizationId,
      {
        discriminator: 'filter',
        filters: values.filters,
      },
    ).then((queryBuilder: io.flow.v0.models.QueryBuilder) => {
      const exclusionRuleForm = {
        q: queryBuilder.q,
        regions: values.regions,
      };
      const action = values.exclusionRuleId
        ? updateExclusionRule(values.organizationId, values.exclusionRuleId, exclusionRuleForm)
        : createExclusionRule(values.organizationId, exclusionRuleForm);

      return dispatch(action);
    }).catch((err: any) => {
      const formattedError = err.response ? err.response.result : err;

      dispatch({
        type: ActionType.SubmitExclusionRuleFormError,
        payload: formattedError,
        error: true,
      });
    });
  };
}
