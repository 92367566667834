import classNames from 'classnames';
import get from 'lodash/get';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { ResultsObject } from '../../modules/utilities/types';

export default function createAnalyticsTooltipHtml(
  data: ResultsObject,
  styles: Record<string, string>,
  valueKey: string,
  startDate: number,
  endDate: number,
): string {
  return ReactDOMServer.renderToStaticMarkup(
    <table className={styles.tooltipTable}>
      <thead>
        <tr>
          <th className={classNames(styles.dateColumn, styles.headColumn)}>Date Range</th>
          <th className={classNames(styles.valueColumn, styles.headColumn)}>Count</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className={styles.dateColumn}>
            <span>
              {moment.utc(startDate).format('MM/DD/YY h:mm')}
              {' '}
              -
              {moment.utc(endDate).format('MM/DD/YY h:mm')}
            </span>
          </td>
          <td className={styles.valueColumn}>{numeral(get(data, valueKey, 0)).format('0.[00]a')}</td>
        </tr>
      </tbody>
    </table>,
  );
}
