import {
  CircleLoader, Close, Printer, InfoCircle,
} from '@flowio/react-icons';
import {
  Card, CardActions, CardHeader, CardContent, CardFooter, CardTitle,
} from '@flowio/react-card';
import { Box } from '@flowio/react-box';
import React, { useEffect, useState } from 'react';
import { Button } from '@flowio/react-button';
import { Link } from '@flowio/react-link';
import { css } from '@emotion/react';

import { Stack } from '@flowio/react-stack';
import { Text } from '@flowio/react-text';
import matches from 'lodash/matches';
import reduce from 'lodash/reduce';
import get from 'lodash/get';

import { Banner } from '@flowio/react-banner';
import OrderItem from '../OrderItem';
import * as styles from './Order.styles';
import { OrderProps } from '../../types/components';

const emptyCardTitleStyles = css({
  minHeight: '400px',
  paddingLeft: '16px',
  paddingTop: '16px',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '20px',
  color: '#98A3B0',
});

const contentFooterContainerCss = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const Order: React.FC<OrderProps> = ({
  itemContents,
  order,
  organizationId,
  onRequestPrintLabel,
  onRequestPrintInvoice,
  onRequestClearItems,
  onRequestClearOrder,
  onItemQuantityChange,
  onItemSelection,
  onRequestSelectAllItems,
  selectedOrderItems,
  shippingLabelRequestStatus,
  isChannelOrgAdmin,
  isFulfillmentUser,
  invoiceOfCurrentLabelResponse,
}) => {
  const [showInvoiceRequiredBanner, setShowInvoiceRequiredBanner] = useState<boolean>(false);

  useEffect(() => {
    const bannerVisibility = invoiceOfCurrentLabelResponse?.required || false;
    setShowInvoiceRequiredBanner(bannerVisibility);
  }, [invoiceOfCurrentLabelResponse]);

  return (
    <Card className={styles.orderCardContainer}>
      {(order != null) ? (
        <>
          <CardHeader dividing>
            <Box justifyContent="between" alignItems="center">
              <Stack orientation="vertical">
                <CardTitle className={styles.orderCardTitle}>
                  Order
                  {` ${order.number}`}
                </CardTitle>
                {/* Linter complains mad hard without the 'to' */}
                {
                !isFulfillmentUser && !isChannelOrgAdmin && (
                <Link
                  className={styles.viewOrderDetails}
                  external
                  href={`/${organizationId}/orders/${order.number}`}
                >
                  View Order Details
                </Link>
                )
              }
              </Stack>
              <Button
                onClick={onRequestClearOrder}
                disabled={get(shippingLabelRequestStatus, 'pending')}
                content="Clear Order"
                intent="negative"
                size="medium"
                variant="outline"
                leftIcon={Close}
              />
            </Box>
          </CardHeader>
          <CardContent className={styles.orderCardContent}>
            <Box direction="column" spacing="none">
              {order.items.map((item, index) => {
                const selectedItem = selectedOrderItems.find((sItem) => sItem.number === item.number
                && sItem.index === index);
                const selectedQuantity = selectedItem != null ? selectedItem.quantity : 0;
                const itemContent = itemContents.find(matches({ number: item.number }));
                return (
                  <OrderItem
                    disabled={get(shippingLabelRequestStatus, 'pending')}
                    item={item}
                    index={index}
                    itemContent={itemContent}
                    onQuantityChange={onItemQuantityChange}
                    onSelection={onItemSelection}
                    selectedQuantity={selectedQuantity}
                  />
                );
              })}
            </Box>
          </CardContent>
          {
              showInvoiceRequiredBanner && (
              <Banner
                icon={InfoCircle}
                intent="warning"
                onDismiss={() => setShowInvoiceRequiredBanner(false)}
                heading="This order requires a printed commercial invoice."
                text="Please make sure a commercial invoice is printed and attached to the order."
                className={styles.invoiceBanner}
              />
              )
            }
          <CardFooter css={contentFooterContainerCss}>
            <Box alignItems="center" justifyContent="start" flexAuto className={styles.orderFooterActions}>
              <Text className={styles.selectedQuantity}>
                <b>
                  {`${reduce(selectedOrderItems, (acc, selectedItem) => acc + selectedItem.quantity, 0)} `}
                </b>
                /
                {` ${reduce(order.items, (acc, item) => item.quantity + acc, 0)} `}
                items
              </Text>
              <CardActions
                actions={[{
                  content: 'Select All',
                  onClick: onRequestSelectAllItems,
                  disabled: get(shippingLabelRequestStatus, 'pending'),
                }, {
                  content: 'Select None',
                  onClick: onRequestClearItems,
                  disabled: get(shippingLabelRequestStatus, 'pending'),
                }]}
              />
            </Box>
            <div className={styles.printLabelContainer}>
              {get(shippingLabelRequestStatus, 'pending') && (
                <CircleLoader className={styles.loadingIndicator} />
              )}
              {
                invoiceOfCurrentLabelResponse && invoiceOfCurrentLabelResponse.required && (
                  <Button
                    disabled={get(shippingLabelRequestStatus, 'pending')}
                    content="Print Invoice"
                    intent="primary"
                    size="medium"
                    onClick={onRequestPrintInvoice}
                    variant="outline"
                    leftIcon={Printer}
                  />
                )
              }
              <Button
                disabled={get(shippingLabelRequestStatus, 'pending')}
                content="Print Label"
                intent="primary"
                size="medium"
                onClick={onRequestPrintLabel}
                variant="solid"
                leftIcon={Printer}
                css={{ marginLeft: '10px' }}
              />
            </div>
          </CardFooter>
        </>
      ) : (
        <CardTitle css={emptyCardTitleStyles}>Please scan or enter order number</CardTitle>
      )}
    </Card>
  );
};

Order.displayName = 'Order';

Order.defaultProps = {
  itemContents: undefined,
  shippingLabelRequestStatus: undefined,
  order: undefined,
  organizationId: undefined,
};

export default Order;
