import Client from './client';

export default class Streams extends Client {
  constructor(opts) {
    let options = opts;

    if (typeof opts === 'string') {
      options = { host: opts }; // convert host string to options object
    }

    options.serviceName = 'API Internal';

    super(options);
  }

  get(channelId, options = {}) {
    return this.makeRequest(`${this.host}/channel/${channelId}/billing/replication/streams`, options);
  }

  getByKey(channelId, key, options = {}) {
    return this.makeRequest(`${this.host}/channel/${channelId}/billing/replication/streams/${key}`, options);
  }

  putByKey(channelId, key, options = {}) {
    return this.makeRequest(`${this.host}/channel/${channelId}/billing/replication/streams/${key}`, {
      ...options,
       method: 'PUT',
    });
  }

  deleteByKey(channelId, key, options = {}) {
    return this.makeRequest(`${this.host}/channel/${channelId}/billing/replication/streams/${key}`, {
      ...options,
       method: 'DELETE',
    });
  }

}
