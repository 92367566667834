import { apiInternal } from '../../../utilities/clients';
import checkHttpStatus from '../../../utilities/check-http-status';
import { RootState } from '../../../stores/types';

interface QueryBuilderResponse {
  result: io.flow.v0.models.QueryBuilder;
}

export default function createQueryBuilder(
  state: RootState,
  organizationId: string,
  queryBuilderForm: io.flow.v0.unions.QueryBuilderForm,
): Promise<io.flow.v0.models.QueryBuilder> {
  return apiInternal.features(state)
    .postRuleAndQueryAndBuilders(organizationId, { body: JSON.stringify(queryBuilderForm) })
    .then(checkHttpStatus)
    .then((response: QueryBuilderResponse) => response.result);
}
