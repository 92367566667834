import { Dispatch } from 'redux';
import { $HttpResponse } from '@flowio/api-sdk';

import { ActionTypes } from '../constants';
import withPagingAndSorting, { isPagingAndSortingResponse } from '../../../utilities/with-paging-and-sorting-v2';
import {
  ThunkResult, RootState, ThunkExtraArguments,
} from '../../../stores/types';
import { WithPagingAndSortingResponse } from '../../../utilities/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import toLegacyResponse from '../../../utilities/clients/to-legacy-response';
import { LegacyResponse } from '../../../utilities/clients/types/server';

interface FetchScheduledExportsOptions {
  entriesPerPage?: number;
  pageNumber?: number;
}

export default function fetchScheduledExports(
  options: FetchScheduledExportsOptions = {},
): ThunkResult<Promise<
  WithPagingAndSortingResponse<io.flow.v0.models.ScheduledExport[]>
  | LegacyResponse<FormattedErrorMessages>
  >> {
  return (
    dispatch: Dispatch,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<
  WithPagingAndSortingResponse<io.flow.v0.models.ScheduledExport[]>
  | LegacyResponse<FormattedErrorMessages>
  > => {
    const {
      entriesPerPage, pageNumber,
    } = options;

    dispatch({
      type: ActionTypes.FETCH_SCHEDULED_EXPORTS_REQUEST,
      params: pageNumber ? { pageNumber } : {},
    });

    return withPagingAndSorting<io.flow.v0.models.ScheduledExport[]>(
      { pageNumber, entriesPerPage },
      (params) => extra.api(getState()).scheduledExports.get(params),
    )().then((
      response: WithPagingAndSortingResponse<io.flow.v0.models.ScheduledExport[]>
      | $HttpResponse<io.flow.v0.models.GenericError>,
    ) => {
      if (isPagingAndSortingResponse<io.flow.v0.models.ScheduledExport[]>(response)) {
        dispatch({
          params: {},
          type: ActionTypes.FETCH_SCHEDULED_EXPORTS_SUCCESS,
          payload: response.result,
        });
        return response;
      }

      const formattedErrors = formatErrorResponseV2(response);

      dispatch({
        params: {},
        type: ActionTypes.FETCH_SCHEDULED_EXPORTS_FAILURE,
        payload: formattedErrors,
      });

      return toLegacyResponse({
        ...response,
        body: formattedErrors,
      });
    });
  };
}
