import { css } from '@emotion/css';

export const sectionTitle = css`
  font-size: 1.1rem;
  font-weight: 400;
`;

export const actions = css`
  text-align: right;
`;

export const summary = css`
  color: #17181a;
  font-weight: 500;
`;

export const inputs = css`
  align-items: center;
  display: flex;
  margin-bottom: 1rem;
`;

export const selectFieldType = css`
  margin-left: 1rem !important;
`;

export const roundingText = css`
  display: block;
  height: 54px;
  line-height: 54px;
  margin-left: 1rem;
`;

export const divider = css`
  margin: 1rem 0;
`;

export const textField = css`
  & input::-webkit-outer-spin-button,
  & input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  & input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
`;
