import React from 'react';
import { compose } from 'redux';
import { TextInputField } from '@flowio/react-text-input';
import { Select } from '@flowio/react-select';
import noop from 'lodash/noop';
import kebabCase from 'lodash/kebabCase';
import { Button } from '@flowio/react-button';

import Alert from '../../../../components/alert/alert';
import withValidation from '../../../../components/with-validation';
import createNewApiKey from '../../../tokens/actions/createNewApiKey';
import withSubmit from '../../../../components/with-submit';
import { getOrganizationId } from '../..';
import { RootState } from '../../../../stores/types';
import { ApiKeyFormProps } from './types';
import { WithValidationProps } from '../../../../components/with-validation/with-validation';
import { WithSubmitProps } from '../../../../components/with-submit/with-submit';
import * as styles from './api-keys.styles';

class ApiKeyForm extends React.PureComponent<
ApiKeyFormProps &
WithValidationProps &
WithSubmitProps
> {
  static displayName = 'ApiKeyForm';

  static defaultProps = {
    allowEnvironmentSelection: false,
    error: undefined,
    onSubmit: noop,
  };

  handleSubmit = ({ isValid }: { isValid: boolean }, formData: any) => {
    const { onSubmit = noop } = this.props;
    if (isValid) {
      onSubmit(formData);
    }
  };

  handleEnvironmentChange = (environment: string) => {
    const { fields } = this.props;
    fields.environment.events.onBlur(environment);
    fields.environment.events.onChange(environment);
  };

  handleNameChange = (value: any) => {
    const { fields } = this.props;
    const { description } = fields;

    description.events.onChange(value);
    description.events.onBlur(value);
  };

  render() {
    const {
      allowEnvironmentSelection, fields, error, onSubmitValidate,
    } = this.props;
    const { description } = fields;
    const environments = [
      {
        value: 'sandbox',
        content: 'All Sandbox Organizations',
      },
      {
        value: 'production',
        content: 'Production Only',
      },
    ];

    return (
      <div>
        {(() => {
          if (error && error.messages) {
            return error.messages.map(({ message }) => (
              <Alert key={kebabCase(message)} type="failure">
                {message}
              </Alert>
            ));
          }
          return null;
        })()}
        <form
          className={styles.newApiKey}
          name="new_api_key"
          onSubmit={onSubmitValidate(this.handleSubmit)}
        >

          <TextInputField
            onValueChange={this.handleNameChange}
            className={styles.newApiKeyDescription}
            name="description"
            hintText="Production, Test, etc"
            feedback={description.error}
            fluid
            intent={description.error ? 'negative' : 'neutral'}
          />
          {(() => {
            if (allowEnvironmentSelection) {
              return (
                <div className={styles.environment}>
                  <Select
                    onValueChange={this.handleEnvironmentChange}
                    options={environments}
                    hintText="Environment"
                  />
                </div>
              );
            }
            return null;
          })()}
          <div className={styles.newApiKeyButton}>
            <Button
              type="submit"
              name="create_key"
              content="Create API Key"
              css={{ marginLeft: '1rem' }}
              intent="primary"
            />
          </div>
        </form>
      </div>
    );
  }
}

function submitForm(formData: any, state: RootState) {
  const organization = getOrganizationId(state);
  return createNewApiKey(organization, formData);
}

export default compose<React.FC<ApiKeyFormProps>>(
  withSubmit(submitForm),
  withValidation({
    description: {
      validations: [{
        required: true,
        message: 'Description is required',
      }],
    },
    environment: {
      defaultValue: 'sandbox',
      validations: [{
        required: true,
        message: 'Environment is required',
      }],
    },
  }),
)(ApiKeyForm);
