/**
 * @fileoverview
 * Reduces the currently active organization into the application state.
 */

import update from 'immutability-helper';
import { RootActionTypes } from '../../../stores/types';
import ActionTypes from '../constants/action-types';
import { ImportsState } from '../types';

const defaultState: ImportsState = {
  isFileEmpty: false,
  openDialog: false,
  eventSource: null,
  currentPage: 1,
  files: [],
};

export default function importsReducer(
  state = defaultState,
  action: RootActionTypes,
): ImportsState {
  switch (action.type) {
    case ActionTypes.IMPORT_FILE_EMPTY:
      return update(state, {
        isFileEmpty: {
          $set: true,
        },
      });
    case ActionTypes.IMPORT_FILE_REQUEST:
      return update(state, {
        isFileEmpty: {
          $set: false,
        },
      });
    case ActionTypes.FETCH_IMPORTS_SUCCESS:
      return {
        ...state,
        files: [...action.payload.results],
        isFirstPage: action.payload.isFirstPage,
        isLastPage: action.payload.isLastPage,
        openDialog: false,
        isFileEmpty: false,
      };

    case ActionTypes.TOGGLE_IMPORT_DIALOG:
      return update(state, {
        openDialog: { $set: !state.openDialog },
      });

    case ActionTypes.UPDATE_IMPORT_FILES_STATUS:
      return {
        ...state,
        files: action.payload,
      };

    case ActionTypes.FETCH_IMPORTS_REQUEST:
      return {
        ...state,
        pageNumber: action.params.pageNumber,
      };

    case ActionTypes.IMPORT_FILE_SUCCESS:
      return {
        ...state,
        files: [action.payload, ...state.files],
        openDialog: false,
        isFileEmpty: false,
      };

    case ActionTypes.IMPORT_EVENTSOURCE_CREATE:
      return {
        ...state,
        eventSource: action.payload,
      };

    case ActionTypes.IMPORT_EVENTSOURCE_CLOSE:
      return {
        ...state,
        eventSource: null,
      };

    default:
      return state;
  }
}
