/**
 * @fileoverview
 * A factory that creates a status reducer for async actions that follows established conventions.
 *
 * Usage:
 *
 * // Typical action creator leveraging async middleware API
 * export default function fetchOrganizationById(organizationId) {
 *   const params = { id: organizationId };
 *   return {
 *     types: [
 *       ActionTypes.FETCH_ORGANIZATION_REQUEST,
 *       ActionTypes.FETCH_ORGANIZATION_SUCCESS,
 *       ActionTypes.FETCH_ORGANIZATION_FAILURE,
 *     ],
 *     payload: { params },
 *     callAPI: state => flow.organization(state).getById({ params }),
 *   };
 * }
 *
 * // Typical reducer
 * import { combineReducers } from 'redux';
 * export default combineReducers({
 *   ...
 *   statuses: combineReducers({
       organization: createStatusReducer([
 *       ActionTypes.FETCH_ORGANIZATION_REQUEST,
 *       ActionTypes.FETCH_ORGANIZATION_SUCCESS,
 *       ActionTypes.FETCH_ORGANIZATION_FAILURE,
 *     ]),
 *   }),
 *   ...
 * });
 */

import { AnyAction, Reducer } from 'redux';
import update from 'immutability-helper';
import createReducer from './createReducer';
import ReadyState from './ReadyState';
import { StatusState, AnyActionWithParams, AnyActionWithPayload } from './types';

export default function createStatusReducer(types: AnyAction['type'][]): Reducer<StatusState, AnyAction> {
  const [REQUEST, SUCCESS, FAILURE] = types;

  const initialState: StatusState = {
    error: undefined,
    params: undefined,
    status: ReadyState.PENDING,
  };

  return createReducer<StatusState>(initialState, {
    [REQUEST]: (state: StatusState, action: AnyAction) => update(state, {
      error: { $set: undefined },
      params: { $set: (action as AnyActionWithParams).params },
      status: { $set: ReadyState.LOADING },
    }),
    [SUCCESS]: (state) => update(state, {
      status: { $set: ReadyState.FULFILLED },
    }),
    [FAILURE]: (state, action) => update(state, {
      error: { $set: (action as AnyActionWithPayload).payload },
      status: { $set: ReadyState.REJECTED },
    }),
  });
}
