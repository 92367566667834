import { $HttpResponse } from '@flowio/api-internal-sdk';
import ActionTypes from '../constants/action-types';

import withPagingAndSorting, { isPagingAndSortingResponse } from '../../../utilities/with-paging-and-sorting-v2';
import { RootActionTypes } from '../../../stores/types';
import { WithPagingAndSortingResult, WithPagingAndSortingResponse } from '../../../utilities/types';
import { ApiError } from '../../../utilities/clients/api-error';
import { ThunkResult } from '../../../middlewares/types';
import { PaginationParams, PaginationOptions } from '../types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';

export function fetchOrganizationAccountsRequest(params: PaginationParams): RootActionTypes {
  return {
    type: ActionTypes.FETCH_ORGANIZATION_ACCOUNTS_REQUEST,
    params,
  };
}

export function fetchOrganizationAccountsSuccess(
  accounts: WithPagingAndSortingResult<io.flow.internal.v0.models.OrganizationAccount[]>,
  params: PaginationParams,
): RootActionTypes {
  return {
    type: ActionTypes.FETCH_ORGANIZATION_ACCOUNTS_SUCCESS,
    payload: accounts,
    params,
  };
}

export function fetchOrganizationAccountsFailure(
  params: PaginationParams,
  error?: FormattedErrorMessages,
): RootActionTypes {
  return {
    type: ActionTypes.FETCH_ORGANIZATION_ACCOUNTS_FAILURE,
    error: true,
    params,
    payload: error,
  };
}

export default function fetchOrganizationAccounts(
  organization: string,
  pageNumber?: number,
  entriesPerPage?: number,
): ThunkResult<Promise<void>> {
  return function fetchAccountsEffects(
    dispatch,
    getState,
    extra,
  ): Promise<void> {
    const mergedOptions: PaginationParams = {
      pageNumber: pageNumber || 1,
      entriesPerPage: entriesPerPage || 25,
      organization,
    };

    return withPagingAndSorting<io.flow.internal.v0.models.OrganizationAccount[]>(
      mergedOptions,
      (options: PaginationOptions) => {
        dispatch(fetchOrganizationAccountsRequest(mergedOptions));
        return extra.apiInternal(getState()).organizationAccounts.get({
          offset: options.offset,
          limit: options.limit,
          ...mergedOptions,
        });
      },
    )().then((
      response: WithPagingAndSortingResponse<io.flow.internal.v0.models.OrganizationAccount[]>
      | $HttpResponse<io.flow.v0.models.GenericError>,
    ) => {
      if (
        isPagingAndSortingResponse<io.flow.internal.v0.models.OrganizationAccount[]>(response)
        && response.ok
      ) {
        dispatch(fetchOrganizationAccountsSuccess(response.result, mergedOptions));
        return;
      }

      const errorResponse = response as $HttpResponse<io.flow.v0.models.GenericError>;
      dispatch(fetchOrganizationAccountsFailure(
        mergedOptions,
        formatErrorResponseV2(errorResponse),
      ));
      throw new ApiError(errorResponse);
    });
  };
}
