import React from 'react';
import { PageContent } from '@flowio/react-page';
import { ShippingConfigurationListingPageProps } from '../../types/components';

import ShippingConfigurationListCard from '../ShippingConfigurationListCard';

const ShippingConfigurationListingPage: React.FC<ShippingConfigurationListingPageProps> = ({
  entriesPerPage,
  isFirstPage,
  isLastPage,
  isSinglePage,
  onAddShippingConfiguration,
  onDeleteShippingConfiguration,
  onDuplicateShippingConfiguration,
  onPageChange,
  onViewShippingConfiguration,
  organizationId,
  pageNumber,
  shippingConfigurations,
}) => (
  <PageContent>
    <ShippingConfigurationListCard
      entriesPerPage={entriesPerPage}
      isFirstPage={isFirstPage}
      isLastPage={isLastPage}
      isSinglePage={isSinglePage}
      onAddShippingConfiguration={() => onAddShippingConfiguration(organizationId)}
      onDeleteShippingConfiguration={onDeleteShippingConfiguration}
      onDuplicateShippingConfiguration={onDuplicateShippingConfiguration}
      onPageChange={onPageChange}
      onViewShippingConfiguration={onViewShippingConfiguration}
      organizationId={organizationId}
      pageNumber={pageNumber}
      shippingConfigurations={shippingConfigurations}
    />
  </PageContent>
);

ShippingConfigurationListingPage.displayName = 'ShippingConfigurationListingPage';

export default ShippingConfigurationListingPage;
