import { Plus } from '@flowio/react-icons';
import React from 'react';
import noop from 'lodash/noop';
import isEmpty from 'lodash/isEmpty';

import {
  Card, CardHeader, CardContent, CardEmptyState, CardTitle,
} from '@flowio/react-card';
import { Box } from '@flowio/react-box';
import { OutlineButton } from '@flowio/react-button';
import { PageContent } from '@flowio/react-page';

import FeatureListFilterForm from '../../containers/FeatureListFilterForm';
import FeatureList from '../FeatureList';
import AddFeatureWorksheet from '../../containers/AddFeatureWorksheet';
import GenericError from '../../../../components/GenericError';
import { FeatureListingProps } from '../../types/components';

const Features: React.FC<FeatureListingProps> = ({
  errors,
  organization,
  isControlOrganization,
  features,
  onAddFeatureClick = noop,
  onFeatureViewDetailClick,
  onRequestNextPage,
  onRequestPreviousPage,
  onRequestFeatureListFilterChange = noop,
  onRequestStatusChange,
  pageNumber,
}) => (
  <PageContent>
    {!isEmpty(errors) && (
      <GenericError error={errors} />
    )}
    <Card>
      <CardHeader dividing>
        <Box alignItems="center" justifyContent="between">
          <CardTitle content="Features" />
          <OutlineButton
            content="Add Feature"
            leftIcon={Plus}
            intent="primary"
            onClick={() => onAddFeatureClick(organization)}
          />
        </Box>
        <FeatureListFilterForm
          onRequestFeatureListFilterChange={onRequestFeatureListFilterChange}
        />
      </CardHeader>
      <CardContent>
        {!isEmpty(features) && (
          <FeatureList
            organization={organization}
            features={features}
            isControlOrganization={isControlOrganization}
            onRequestStatusChange={onRequestStatusChange}
            onFeatureViewDetailClick={onFeatureViewDetailClick}
            onRequestNextPage={onRequestNextPage}
            onRequestPreviousPage={onRequestPreviousPage}
            pageNumber={pageNumber}
          />
        )}
        {isEmpty(features) && (
          <CardEmptyState content="No Results" />
        )}
      </CardContent>
    </Card>
    <AddFeatureWorksheet isControlOrganization={isControlOrganization} />

  </PageContent>
);

Features.displayName = 'Features';

Features.defaultProps = {
  isControlOrganization: false,
  errors: [],
  features: { results: [] },
  onAddFeatureClick: noop,
  onFeatureViewDetailClick: noop,
  onRequestPreviousPage: noop,
  onRequestNextPage: noop,
  onRequestFeatureListFilterChange: noop,
  onRequestStatusChange: noop,
  pageNumber: 1,
};

export default Features;
