import { OrganizationsGetByOrganizationIdResponse } from '@flowio/api-sdk';
import ActionTypes from '../constants/action-types';
import { RootActionTypes, ThunkResult, ThunkExtraArguments } from '../../../stores/types';
import { ApiError } from '../../../utilities/clients/api-error';
import { PropType } from '../../../types';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import toLegacyResponse from '../../../utilities/clients/to-legacy-response';

export function fetchOrganizationRequest(): RootActionTypes {
  return {
    type: ActionTypes.FETCH_ORGANIZATION_REQUEST,
  };
}

export function fetchOrganizationSuccess(
  organization: io.flow.v0.models.Organization,
): RootActionTypes {
  return {
    type: ActionTypes.FETCH_ORGANIZATION_SUCCESS,
    payload: organization,
  };
}

export function fetchOrganizationFailure(error?: io.flow.v0.models.GenericError): RootActionTypes {
  return {
    type: ActionTypes.FETCH_ORGANIZATION_FAILURE,
    payload: error,
  };
}

type ResponseType = PropType<OrganizationsGetByOrganizationIdResponse, 'body'>;

export default function fetchOrganization(
  organization: string,
): ThunkResult<Promise<LegacyResponse<ResponseType>>> {
  return function fetchOrganizationEffects(
    dispatch,
    getState,
    extra: ThunkExtraArguments,
  ): Promise<LegacyResponse<ResponseType>> {
    dispatch(fetchOrganizationRequest());
    const state = getState();

    return extra.api(state).organizations.getByOrganizationId({
      organization_id: organization,
    }).then((response) => {
      if (response.ok) {
        dispatch(fetchOrganizationSuccess(response.body));
        return toLegacyResponse(response);
      }

      const error = new ApiError(response);
      dispatch(fetchOrganizationFailure(response.body));
      throw error;
    });
  };
}
