import React from 'react';
import {
  Form,
  reduxForm,
  Field,
  InjectedFormProps,
} from 'redux-form';
import { FormGroup } from '@flowio/react-form-group';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { RootState, ThunkDispatcher } from '../../../../stores/types';
import { getUserEmail } from '../../../console';
import FormName from '../../constants/FormName';
import { exportExclusions } from '../../actions';
import { createToast } from '../../../console/actions';
import { emailRegex } from '../../../../utilities/regex';
import * as styles from './styles';
import { TextField } from '../../../../components/ReduxFormFieldAdapters';

interface ExclusionsExportFormValues {
  email: string;
}

const ExclusionsExportForm: React.FC<InjectedFormProps> = () => (
  <Form className={styles.form}>
    <FormGroup inline labelFor="email" labelText="Email">
      <Field
        required
        component={TextField}
        name="email"
        fluid
      />
    </FormGroup>
  </Form>
);

function submit(values: ExclusionsExportFormValues, dispatch: ThunkDispatcher):
Promise<io.flow.v0.models.Export> {
  return dispatch(exportExclusions(values.email));
}

function mapStateToProps(state: RootState) {
  return {
    initialValues: {
      email: getUserEmail(state),
    },
  };
}

export default compose<React.FC>(
  connect(mapStateToProps),
  reduxForm({
    form: FormName.ExclusionsExportForm,
    onSubmit: submit,
    validate: (values) => {
      // eslint-disable-next-line no-control-regex
      if (!values.email) {
        return {
          email: 'Required',
        };
      }

      if (!emailRegex.test(values.email)) {
        return {
          email: 'Please enter a valid email address',
        };
      }

      return {};
    },
    onSubmitSuccess: (result: io.flow.v0.models.Export, dispatch) => {
      const emails = result.deliveries.map((delivery) => delivery.email).join(', ');
      return dispatch(createToast({
        intent: 'positive',
        content: `Exclusions will be sent to ${emails} shortly.`,
      }));
    },
    onSubmitFail: (action, dispatch) => {
      if (!action?.email) {
        dispatch(createToast({
          intent: 'negative',
          content: 'There was an error, please try to create an export again',
        }));
      }
    },
  }),
)(ExclusionsExportForm);
