import { css } from '@emotion/css';
import colors from '../../../../../theme/tokens/colors';

export const listing = css`
  border: none;
  margin-bottom: 1rem;
  overflow: hidden;
  padding-top: 1rem;
`;

/* using the row column theme but it still won't set the padding */
export const tableRowColumn = css`
  padding: 0 !important;
`;

export const firstColumn = css`
  padding: 0 0 0 16px !important;
`;

export const lastColumn = css`
  padding: 0 16px 0 0 !important;
`;

export const filtersContainer = css`
  padding-top: 8px;
`;

export const dialogTextContainer = css`
  padding-top: 18px;
`;

export const dialogIcon = css`
  width: 35px;
  margin-right: 20px;
  display: inline-block;
`;

export const filtersBox = css`
  width: 100%;
`;

export const dialogText = css`
  width: calc(100%-55px);
`;

export const actionCol = css`
  float: right;
`;

export const experienceRow = css`
  height: 40px;
`;

export const viewDetailsBtn = css`
  /* Why is color always a problem!?!?!? */
  background-color: white !important;
  margin-right: 8px;
  &:hover {
    cursor: pointer;
  }
`;

export const primaryIcon = css`
  fill: #3283e6;
  width: 16px;
`;

export const statusCommonStyles = css`
  font-size: 12px;
  font-weight: bold;
`;

export const active = css`
  color: ${colors.chart.green};
  ${statusCommonStyles}
`;

export const draft = css`
  color: ${colors.chart.yellow};
  ${statusCommonStyles}
`;

const colorSilverStyle = css`
  color: ${colors.silver};
`;

export const archiving = css`
  ${statusCommonStyles}
  ${colorSilverStyle}
`;

export const archived = css`
  ${statusCommonStyles}
  ${colorSilverStyle}
`;

export const dotCommonStyles = css`
  border-radius: 8px;
  display: inline-block;
  margin-right: 4px;
  height: 8px;
  width: 8px;
`;

export const activeDot = css`
  background-color: ${colors.chart.green};
  ${dotCommonStyles}
`;

export const draftDot = css`
  background-color: ${colors.chart.yellow};
  ${dotCommonStyles}
`;

export const archivingDot = css`
  ${dotCommonStyles}
  background-color: ${colors.silver};
`;

export const archivedDot = css`
  ${dotCommonStyles}
  background-color: ${colors.silver};
`;

export const countryFlag = css`
  border-radius: 2px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  margin-right: 8px;
  position: relative;
  top: 2px;
  width: 21px;
`;

export const addIcon = css`
  fill: white !important;
`;

export const welcome = css`
  margin: 3rem auto;
  max-width: 40%;
  text-align: center;
`;

export const welcomeHeadline = css`
  color: #17181a;
  font-size: 1.5rem;
  font-weight: 300;
`;

export const link = css`
  color: ${colors.charcoal};

  &:hover {
    textdecoration: none;
  }

  &:link,
  &:visited = {
    color: ${colors.charcoal};
  }
`;

export const floatingButton = css`
  margin-top: 2rem;
  text-align: center;
`;

export const floatingButtonDocked = css`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  margin-top: 0;
`;

export const noCardPadding = css`
  padding-left: 0px !important;
  padding-right: 0px !important;
`;

export const addtionalOptionsTrigger = css`
  margin-left: 8px;
`;

export const dialogAction = css`
  min-width: 150px;
`;
