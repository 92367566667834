import React from 'react';
import { useDispatch } from 'react-redux';
import { FormApi } from 'final-form';
import { Field, Form } from 'react-final-form';
import { Button } from '@flowio/react-button';
import { editOrderNote } from '../../actions/orderNotes';
import { IOrderNoteForm } from './types';
import TextField from '../../../../components/TextField';
import { ThunkDispatcher } from '../../../../stores/types';
import { inlineButtonStyles, inlineFormStyles, inlineInputStyles } from './styles';

interface EditOrderNoteFormProps {
  organizationId: string;
  orderNumber: string;
  note: io.flow.internal.v0.models.OrderNote;
  onClose: () => void;
}

const EditOrderNoteForm: React.FC<EditOrderNoteFormProps> = ({
  note,
  organizationId,
  orderNumber,
  onClose,
}) => {
  const dispatch = useDispatch<ThunkDispatcher>();

  const initialValues: IOrderNoteForm = { note: note.note };

  const submit = (values: IOrderNoteForm, form: FormApi) => dispatch(editOrderNote(
    organizationId,
    orderNumber,
    note.id,
    values.note!,
  )).then(
    () => {
      form.restart();
      onClose();
    },
  ).catch(() => ({
    note: 'An error has occurred while trying to edit the message. Please try again.',
  }));

  return (
    <Form
      onSubmit={submit}
      initialValues={initialValues}
      render={({
        handleSubmit,
        submitting,
        pristine,
        hasValidationErrors,
      }) => (
        <form onSubmit={handleSubmit} css={inlineFormStyles}>
          <div css={inlineInputStyles}>
            <Field
              component={TextField}
              name="note"
              showFeedback={false}
              validate={(value: string) => (!value ? 'Required' : undefined)}
              fluid
            />
          </div>
          <Button type="button" onClick={onClose} css={inlineButtonStyles}>Cancel</Button>
          <Button type="submit" disabled={hasValidationErrors || submitting || pristine} css={inlineButtonStyles}>Submit</Button>
        </form>
      )}
    />
  );
};

export default EditOrderNoteForm;
