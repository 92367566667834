import React from 'react';
import { Select } from '@flowio/react-select';
import { css } from '@emotion/css';

const accountSelectionStyles = css`
  min-width: 192px;
`;

export interface AccountSelectionProps {
  // Accounts available to the organization
  accounts: io.flow.internal.v0.models.OrganizationAccount[];
  // Called with the identifiers for the organization and account the customer wants to view
  onRequestChange: (...arg: any) => any;
  // Organization customer is currently logged into
  organization: io.flow.v0.models.Organization;
  // Account currently selected
  value: string;
}

export interface DropdownOption {
  value: string;
}

export default class AccountSelection extends React.PureComponent<AccountSelectionProps> {
  static displayName = 'AccountSelection';

  handleChange = (option: DropdownOption): void => {
    const { onRequestChange, organization } = this.props;
    onRequestChange(organization.id, option.value);
  };

  render(): React.ReactElement {
    const { accounts, value } = this.props;
    return (
      <Select
        className={accountSelectionStyles}
        value={value}
        onValueChange={(val) => this.handleChange({ value: val })}
        options={accounts.map((acc) => ({
          content: `${acc.currency} ${acc.statistics.balance.label}`,
          value: acc.currency,
        }))}
      />
    );
  }
}
