import { combineReducers } from 'redux';
import ActionTypes from '../constants/ActionTypes';
import createStatusReducer from '../../../utilities/redux/createStatusReducer';

export default combineReducers({
  countries: createStatusReducer([
    ActionTypes.FETCH_COUNTRIES_REQUEST,
    ActionTypes.FETCH_COUNTRIES_SUCCESS,
    ActionTypes.FETCH_COUNTRIES_FAILURE,
  ]),
  currencies: createStatusReducer([
    ActionTypes.FETCH_CURRENCIES_REQUEST,
    ActionTypes.FETCH_CURRENCIES_SUCCESS,
    ActionTypes.FETCH_CURRENCIES_FAILURE,
  ]),
  languages: createStatusReducer([
    ActionTypes.FETCH_LANGUAGES_REQUEST,
    ActionTypes.FETCH_LANGUAGES_SUCCESS,
    ActionTypes.FETCH_LANGUAGES_FAILURE,
  ]),
  regions: createStatusReducer([
    ActionTypes.FETCH_REGIONS_REQUEST,
    ActionTypes.FETCH_REGIONS_SUCCESS,
    ActionTypes.FETCH_REGIONS_FAILURE,
  ]),
});
