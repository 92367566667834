import { createSelector } from 'reselect';

import getFeatures from './getFeatures';

const getErrors = createSelector(
  getFeatures,
  (features) => features.errors,
);

export default getErrors;
