import { submit, isSubmitting } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import FormName from '../constants/FormName';
import DialogName from '../constants/DialogName';
import { getIsDialogOpen, getDialogProp } from '../selectors';
import { hideDialog } from '../actions';
import FeatureReleaseConfirm from '../components/FeatureReleaseConfirm';
import { RootState, ThunkDispatcher } from '../../../stores/types';
import {
  FeatureReleaseConfirmValues,
  FeatureReleaseConfirmDispatchProps,
  FeatureReleaseConfirmStateProps,
  FeatureReleaseConfirmOwnProps,
} from '../types/components';

const confirmation = DialogName.RELEASE_FEATURE_CONFIRM;
const form = FormName.RELEASE_FEATURE_FORM;

const handleSubmit = () => submit(form);

const mapStateToProps = (state: RootState): FeatureReleaseConfirmStateProps => ({
  disabled: isSubmitting(form)(state),
  initialValues: getDialogProp(confirmation, 'initialValues')(state) as Partial<FeatureReleaseConfirmValues>,
  open: getIsDialogOpen(confirmation)(state) as boolean,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatcher,
): FeatureReleaseConfirmDispatchProps => bindActionCreators({
  onCancel: hideDialog(confirmation),
  onOk: handleSubmit,
}, dispatch);

export default compose<React.FC<FeatureReleaseConfirmOwnProps>>(
  connect(mapStateToProps, mapDispatchToProps),
)(FeatureReleaseConfirm);
