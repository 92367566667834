import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withFetch } from '@flowio/redux-fetch';
import {
  fetchFailureSummaries,
  requeueFailures,
  deleteFailure,
} from '../actions';

import { createToast } from '../../console/actions';
import { getSummaries } from '../selectors';
import FailureSummaries from '../components/failure-summaries-home';

function getAsyncState(dispatch) {
  return dispatch(fetchFailureSummaries());
}

const handleRequeueFailures = (database) => (dispatch) => {
  dispatch(requeueFailures(database)).then((response) => {
    if (!response.ok) {
      dispatch(createToast({
        intent: 'negative',
        content: `Requeuing event failures in database [${database}] has returned an error!.`,
        icon: 'Alert',
      }));
    } else {
      dispatch(createToast({
        intent: 'positive',
        content: `Requeuing event failures in database [${database}] is complete.`,
        icon: 'CheckCircle',
      }));

      dispatch(getAsyncState(dispatch));
    }
  });
};

const handleDeleteFailure = (database, queue, id) => (dispatch) => {
  dispatch(deleteFailure(database, queue, id)).then((response) => {
    if (!response.ok) {
      dispatch(createToast({
        intent: 'negative',
        content: `Deleting event failures in database [${database}] has returned an error!.`,
        icon: 'Alert',
      }));
    } else {
      dispatch(createToast({
        intent: 'positive',
        content: `Deleting event failure in database [${database}] is complete.`,
        icon: 'CheckCircle',
      }));

      dispatch(getAsyncState(dispatch));
    }
  });
};

const mapStateToProps = createStructuredSelector({
  summaries: getSummaries,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  onRequeueFailures: handleRequeueFailures,
  onDeleteFailure: handleDeleteFailure,
}, dispatch);

export default compose(
  withFetch(getAsyncState),
  connect(mapStateToProps, mapDispatchToProps),
)(FailureSummaries);
