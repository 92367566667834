import React, { FunctionComponent } from 'react';
import { Card, CardHeader, CardContent } from '@flowio/react-card';
import {
  Table,
  TableHead,
  TableRow, TableRowColumn,
  TableHeadColumn,
  TableBody,
} from '@flowio/react-table';
import * as styles from './checkout-configuration.styles';

export interface StateProps {
  analytics?: io.flow.internal.v0.models.CheckoutAnalytics;
  behavior?: io.flow.internal.v0.models.CheckoutBehavior;
  settings?: io.flow.internal.v0.models.CheckoutSettings;
  domain?: string;
}

export interface OwnProps { id: string }

type ConfigurationDetailProps = StateProps & OwnProps;

const ConfigurationDetail: FunctionComponent<ConfigurationDetailProps> = ({
  analytics,
  domain,
  settings,
  behavior,
}) => {
  const trackers = analytics ? analytics.trackers : [];
  const useBaseCurrency = analytics && analytics.use_base_currency ? 'true' : 'false';
  const timeout = settings && settings.session_persistence_timeout;
  const attributeKeys = settings && settings.session_persistence_attribute_keys;

  return (
    <div>
      <Card>
        {domain != null && (
          <CardHeader
            caption={domain}
            dividing
          />
        )}
        <CardContent>

          {analytics != null && (
            <Card>
              <CardHeader caption="Analytics" />
              <CardContent>
                <div className={styles.field}>
                  <span className={styles.label}>Use base currency: </span>
                  <span className={styles.value}>
                    {useBaseCurrency}
                  </span>
                </div>
                <Table striped displayDensity="comfortable">
                  <TableHead>
                    <TableRow>
                      <TableHeadColumn>Tracker Id</TableHeadColumn>
                      <TableHeadColumn>Name</TableHeadColumn>
                      <TableHeadColumn>Type</TableHeadColumn>
                      <TableHeadColumn>Plugins</TableHeadColumn>
                    </TableRow>
                  </TableHead>
                  {trackers != null && trackers.map((tracker) => (
                    <TableBody>
                      <TableRowColumn>{tracker.tracker_id}</TableRowColumn>
                      <TableRowColumn>{tracker.discriminator}</TableRowColumn>
                      {tracker.discriminator === 'google_analytics' && (
                        <TableRowColumn>{tracker.plugins.join(', ')}</TableRowColumn>
                      )}
                    </TableBody>
                  ))}
                </Table>
              </CardContent>
            </Card>
          )}

          <Card>
            <CardHeader caption="Behavior" />
            <CardContent>
              <div className={styles.field}>
                <span className={styles.label}>Customer email prompt: </span>
                <span className={styles.value}>
                  {behavior && behavior.customer_info.email.prompt}
                </span>
              </div>
              <div className={styles.field}>
                <span className={styles.label}>Shipping address prompt: </span>
                <span className={styles.value}>
                  {behavior && behavior.shipping_address.prompt}
                </span>
              </div>
              <div className={styles.field}>
                <span className={styles.label}>Shipping method prompt: </span>
                <span className={styles.value}>
                  {behavior && behavior.shipping_method.prompt}
                </span>
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardHeader caption="Settings" />
            <CardContent>
              <div className={styles.field}>
                <span className={styles.label}>Session persistence timeout: </span>
                <span className={styles.value}>
                  { timeout ? `${timeout.value} ${timeout.unit}` : 'Not Available' }
                </span>
              </div>
              <div className={styles.field}>
                <span className={styles.label}>Session persistence attribute keys: </span>
                <span className={styles.value}>
                  {attributeKeys && attributeKeys.join(', ')}
                </span>
              </div>
            </CardContent>
          </Card>

        </CardContent>
      </Card>
    </div>
  );
};

export default ConfigurationDetail;
