import ActionTypes from '../constants/action-types';
import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';

const createRequestAction = (): RootActionTypes => ({
  type: ActionTypes.FETCH_ORDER_DETAIL_REQUEST,
});

const createSuccessAction = (payload: io.flow.internal.v0.models.OrderDetail): RootActionTypes => ({
  type: ActionTypes.FETCH_ORDER_DETAIL_SUCCESS,
  payload,
});

const createFailureAction = (errors: FormattedErrorMessages): RootActionTypes => ({
  type: ActionTypes.FETCH_ORDER_DETAIL_FAILURE,
  error: true,
  payload: errors,
});

export default function fetchOrderDetailByNumber(
  organization: string,
  orderNumber: string,
): ThunkResult<Promise<io.flow.internal.v0.models.OrderDetail | void>> {
  return (dispatch, getState, extra): Promise<io.flow.internal.v0.models.OrderDetail | void> => {
    dispatch(createRequestAction());
    return extra.apiInternal(getState()).orderDetails.getByNumber({
      organization,
      number: orderNumber,
    }).then((response): io.flow.internal.v0.models.OrderDetail | void => {
      if (response.ok) {
        dispatch(createSuccessAction(response.body));
        return response.body;
      }

      const errors = formatErrorResponseV2(response);
      dispatch(createFailureAction(errors));
      return undefined;
    });
  };
}
