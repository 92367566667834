import React, { useState, useEffect, useCallback } from 'react';
import Cookies from 'cookies-js';
import Keys from './keys';

/**
 * A higher order component that takes a key and checks the browser cookies
 * to see if it has been set already, indicating the wrapped component
 * has been previously dismissed. If so, the component is not rendered
 * @param {String} key The cookie value to check for
 * If present and set and set to 'true' then the component is not
 * rendered, otherwise the component is injected with an additional
 * onDismiss prop, which can be used as a callback to indicate
 * that the component should be dismissed and not shown again
 * @return {Function}
 */

export type Key = typeof Keys[keyof typeof Keys];

export type WithDismissProps<P = {}> = P & {
  onDismiss: () => void;
};

const WithDismiss = <P extends object>(
  WrappedComponent: React.FC<WithDismissProps<P>>,
  key: Key,
) => {
  const WithDismissComponent: React.FC<P> = (props) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
      const result = Cookies.get(key);
      setShow(!(result === 'true'));
    }, []);

    const onDismiss = useCallback(() => {
      Cookies.set(key, 'true');
      setShow(false);
    }, []);

    return show ? <WrappedComponent onDismiss={onDismiss} {...props} /> : null;
  };

  WithDismissComponent.displayName = `WithDismiss(${WrappedComponent.displayName || 'Component'})`;

  return WithDismissComponent;
};

export default WithDismiss;
