import React from 'react';
import { Link } from 'react-router';
import { Banner, BannerText } from '@flowio/react-banner';
import GoogleLocalizedFeeds from '../../GoogleLocalizedFeeds/containers/GoogleLocalizedFeeds';
import GoogleDataSources from '../../../containers/GoogleDataSources';
import MarketingTabs from '../../MarketingTabs';
import MarketingPage from '../../MarketingPage';
import { MergedProps } from '../types';
import Platforms from '../../../constants/Platforms';
import FeedsPageTitle from '../../FeedsPageTitle';

const GoogleFeeds: React.FC<MergedProps> = ({
  params: { accountId, organization },
  location,
  error,
}) => {
  if (error) {
    return (
      <Banner intent="negative">
        <BannerText>An error has occurred, please try again later.</BannerText>
      </Banner>
    );
  }

  const breadcrumbProps = {
    items: [
      {
        content: 'Product Feeds',
        as: Link,
        to: `/${organization}/marketing`,
      },
      {
        content: 'Google Feeds',
        active: true,
      },
    ],
    metaData: (
      <>
        <b>{Platforms('google').platformName}</b>
        {' '}
        |
        {' '}
        {organization}
        {' '}
        •
        {' '}
        {accountId}
      </>
    ),
  };

  const tabsProps = {
    defaultActiveKey: location.query.activeTab || 'feeds',
    tabs: [
      {
        content: 'Feeds',
        eventKey: 'feeds',
      },
      {
        content: 'Data Sources',
        eventKey: 'dataSources',
      },
    ],
    panels: [
      {
        eventKey: 'feeds',
        content: <GoogleLocalizedFeeds organizationId={organization} accountId={accountId} />,
      },
      {
        eventKey: 'dataSources',
        content: <GoogleDataSources />,
      },
    ],
  };

  return (
    <MarketingPage
      heading={<FeedsPageTitle platform="google" />}
      breadcrumbItems={breadcrumbProps.items}
    >

      <MarketingTabs {...tabsProps} />
    </MarketingPage>
  );
};

GoogleFeeds.displayName = 'GoogleFeeds';

export default GoogleFeeds;
