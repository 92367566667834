/**
 * Use radius tokens to change the border-radius size (rounded corners).
 */
const radius = {
  circle: '50%',
  large: 8,
  medium: 4,
  small: 2,
} as const;

export default radius;
