import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { isInvalid, isSubmitting, submit } from 'redux-form';
import overSome from 'lodash/overSome';

import { hideDialog } from '../../../actions';
import { getDialogProp, getIsDialogOpen } from '../../../selectors';
import FormName from '../../../constants/FormName';
import CreateOptinMessageWorksheet from '../components/CreateOptinMessageWorksheet';
import DialogName from '../../../constants/DialogName';
import { RootState, ThunkDispatcher } from '../../../../../stores/types';
import { DispatchProps, StateProps } from '../types';

const form = FormName.CREATE_OPTIN_ATTRIBUTE_FORM;
const worksheet = DialogName.CREATE_OPTIN_MESSAGE_WORKSHEET;

const submitForm = () => submit(form);

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  initialValues: getDialogProp(worksheet, 'initialValues'),
  isCancelDisabled: isSubmitting(form),
  isSaveDisabled: overSome([isInvalid(form), isSubmitting(form)]),
  open: getIsDialogOpen(worksheet),
});

const mapDispatchToProps = (dispatch: ThunkDispatcher): DispatchProps => bindActionCreators({
  onCancel: hideDialog(worksheet),
  onSave: submitForm,
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(CreateOptinMessageWorksheet);
