import { createSelector } from 'reselect';

import { RootState } from '../../stores/types';
import { DiscountState } from './types';

export const getDiscountsState:
(state: RootState) => DiscountState = (state: RootState) => state.discounts;

export const getDiscountFilters = createSelector(
  getDiscountsState,
  (state) => state.ui,
);

export const getDiscountStatusFilter = createSelector(
  getDiscountFilters,
  (state) => state.discountStatusFilter,
);

export const getDiscounts = createSelector(
  getDiscountsState,
  (state) => state.discounts,
);

export const getCurrentDiscount = createSelector(
  getDiscountsState,
  (state) => state.currentDiscount,
);
