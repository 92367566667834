import update from 'immutability-helper';
import ActionTypes from '../constants/action-types';
import { RootActionTypes } from '../../../stores/types';

export default function (state = '', action: RootActionTypes): string {
  switch (action.type) {
    case ActionTypes.CHANGE_ACCOUNT:
      return update(state, { $set: action.payload });
    default:
      return state;
  }
}
