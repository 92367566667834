import { ReactType } from 'react';
import get from 'lodash/get';
import keys from 'lodash/keys';
import omit from 'lodash/omit';

/**
 * Returns an object consisting of props beyond the scope of the specified
 * component. Useful for getting and spreading unknown props from the user.
 * @param {Function} component A stateful or stateless React component.
 * @param {Object} props A React element props object
 * @returns {Object} A shallow copy of the prop object
 */
export default function getUnhandledProps<T extends object>(
  component: ReactType,
  props: T,
): Partial<T> {
  return omit(props, keys(get(component, 'propTypes', {})));
}
