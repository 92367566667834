import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';

/**
 * Utility to "cast" an authorization model into a billing address.
 * @param {Authorization} authorization
 * @return {OrderAddress}
 */
export default function orderAddressFromAuthorization(authorization) {
  return omitBy({
    streets: get(authorization, 'address.streets'),
    city: get(authorization, 'address.city'),
    province: get(authorization, 'address.province'),
    postal: get(authorization, 'address.postal'),
    country: get(authorization, 'address.country'),
    contact: omitBy({
      name: omitBy({
        first: get(authorization, 'customer.name.first'),
        last: get(authorization, 'customer.name.last'),
      }, isUndefined),
      company: get(authorization, 'address.company'),
      email: get(authorization, 'customer.email'),
      phone: get(authorization, 'customer.phone'),
    }, isUndefined),
  }, isUndefined);
}
