import update from 'immutability-helper';
import ActionTypes from '../constants/ActionTypes';

const defaultState = [];

export default function (state = defaultState, action) {
  switch (action.type) {
    case ActionTypes.FETCH_COUNTRIES_SUCCESS:
      return update(state, { $set: action.payload });
    default:
      return state;
  }
}
