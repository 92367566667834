import { AnyAction } from 'redux';
import { close, reduxFormWorksheet } from '@flowio/redux-form-overlay';
import { createToast } from '../../console/actions';

import FormNames from '../constants/FormNames';
import EditShippingAddressForm from '../components/EditShippingAddressForm';
import updateShippingAddress from '../actions/update-shipping-address';
import { ThunkDispatcher, RootActionTypes } from '../../../stores/types';
import { EditShippingAddressFormValues } from '../types';

function handleSubmit(
  values: EditShippingAddressFormValues,
  dispatch: ThunkDispatcher,
): Promise<void | io.flow.v0.models.Order> {
  const { organizationId, orderNumber } = values;
  return dispatch(updateShippingAddress(organizationId, orderNumber, values));
}

function handleSuccess(
  action: AnyAction,
  dispatch: ThunkDispatcher,
): Promise<[RootActionTypes, any]> | RootActionTypes {
  if (action) {
    return Promise.all([
      dispatch(createToast({
        content: 'Successfully updated shipping address',
        intent: 'positive',
      })),
      dispatch(close(FormNames.EDIT_SHIPPING_ADDRESS_FORM)),
    ]);
  }

  return dispatch(createToast({
    content: 'Could not update shipping address, please try again',
    intent: 'negative',
  }));
}

export default reduxFormWorksheet({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  form: FormNames.EDIT_SHIPPING_ADDRESS_FORM,
  onSubmit: handleSubmit,
  onSubmitSuccess: handleSuccess,
})(EditShippingAddressForm);
