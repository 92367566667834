import { ActionTypes, ActivityKind } from '../constants';
import { getCurrentOrderNumber } from '../selectors';
import createActivity from './createActivity';
import { getOrganizationId } from '../../organization';
import { ThunkResult } from '../../../stores/types';

export default function selectAllItems(): ThunkResult<void> {
  return function selectAllItemsEffects(dispatch, getState) {
    const currentOrderNumber = getCurrentOrderNumber(getState());
    const organizationId = getOrganizationId(getState());
    if (currentOrderNumber != null) {
      dispatch({ type: ActionTypes.SELECT_ALL_ITEMS });
      dispatch(createActivity({
        kind: ActivityKind.SELECT_ALL_ITEMS,
        number: currentOrderNumber,
        organizationId,
      }));
    }
  };
}
