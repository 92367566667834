import { OrderAddressValidationsPostAddressValidationByOrganizationParameters, OrderAddressValidationsPostAddressValidationByOrganizationResponse } from '@flowio/api-internal-sdk';
import { ThunkResult } from '../../../stores/types';

export default function updateOrderAddressValidation(
  organization: string,
  body: OrderAddressValidationsPostAddressValidationByOrganizationParameters['body'],
): ThunkResult<Promise<OrderAddressValidationsPostAddressValidationByOrganizationResponse>> {
  return (_dispatch, getState, extra) => extra.apiInternal(
    getState(),
  ).orderAddressValidations.postAddressValidationByOrganization({
    organization,
    body,
  });
}
