import { Link } from 'react-router';
import { OutlineButton } from '@flowio/react-button';
import React from 'react';
import {
  Card,
  CardContent,
  CardEmptyState,
  CardFooter,
} from '@flowio/react-card';
import * as styles from './not-available.styles';

interface Props {
  organization: string;
}

const ClassificationNotAvailable: React.FC<Props> = ({ organization }) => (
  <section>
    <Card>
      <CardContent>
        <CardEmptyState>
          <p className={styles.copy}>
            No items available. Please import your product catalog first.
          </p>
        </CardEmptyState>
      </CardContent>
      <CardFooter>
        <div className={styles.buttonContainer}>
          <Link to={`/${organization}/catalog/items`}>
            <OutlineButton
              className={styles.button}
              content="Go to Product Catalog"
              intent="primary"
            />
          </Link>
        </div>
      </CardFooter>
    </Card>
  </section>
);

ClassificationNotAvailable.displayName = 'ClassificationNotAvailable';

export default ClassificationNotAvailable;
