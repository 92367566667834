import { Close, AlertTwoTone, InfoCircle } from '@flowio/react-icons';
import React, { useState, useEffect } from 'react';
import { Field, WrappedFieldArrayProps, WrappedFieldProps } from 'redux-form';
import {
  Card, CardHeader, CardContent, CardEmptyState, CardTitle,
} from '@flowio/react-card';
import { Button, IconButton } from '@flowio/react-button';
import { Select } from '@flowio/react-select';
import partition from 'lodash/partition';
import * as styles from './EditExperiment.styles';
import { TextField } from '../../../../components/ReduxFormFieldAdapters';

interface ExperimentVariantsProps
  extends WrappedFieldArrayProps<io.flow.internal.v0.unions.VariantForm> {
  change: (
    field: string,
    value: number | string | io.flow.internal.v0.unions.VariantForm[],
  ) => void;
  variants: io.flow.internal.v0.unions.VariantForm[];
  isEquallyAllocated: boolean;
}

const ExperimentNameField: React.FC<WrappedFieldProps> = ({
  input,
}: WrappedFieldProps) => <span className="variant-name">{input.value}</span>;

const ExperimentVariants: React.FC<ExperimentVariantsProps> = React.memo(({
  fields,
  meta,
  change,
  variants,
  isEquallyAllocated,
}: ExperimentVariantsProps) => {
  const [variantOptions, setVariantOptions] = useState<
  io.flow.internal.v0.unions.VariantForm[]
  >([]);
  const [selectedVariants, setSelectedVariants] = useState<
  io.flow.internal.v0.unions.VariantForm[]
  >([]);

  useEffect(() => {
    const [assigned, unassigned] = partition(
      variants, (v: io.flow.internal.v0.unions.VariantForm) => !!v.traffic_percentage,
    );
    setVariantOptions(unassigned);
    setSelectedVariants(assigned);
    change('variants', assigned);
  }, [variants, change]);

  const handleAddVariant = (varientKey: string): void => {
    const variant = variantOptions.find((v) => v.key === varientKey);

    if (!variant) {
      return;
    }

    // If the variant traffic was equally allocated, redistribute
    if (isEquallyAllocated) {
      const base = Math.floor(100 / (fields.length + 1));
      const adjustment = 100 % (fields.length + 1);

      fields.forEach((name, i) => {
        change(`${name}.traffic_percentage`, base + (i < adjustment ? 1 : 0));
      });

      fields.push({
        ...variant,
        traffic_percentage: base,
      });
    } else {
      fields.push({
        ...variant,
        traffic_percentage: 0,
      });
    }
    setSelectedVariants((vs) => [...vs, variant]);
    setVariantOptions((os) => os.filter((o) => o.key !== variant.key));
  };

  const handleRemove = (index: number): void => {
    const variant = selectedVariants[index];

    // If the variant traffic was equally allocated, redistribute
    if (isEquallyAllocated) {
      const base = Math.floor(100 / (fields.length - 1));
      let adjustment = 100 % (fields.length - 1);

      if (adjustment > index) {
        adjustment += 1;
      }

      fields.forEach((name, i) => {
        change(`${name}.traffic_percentage`, base + (i < adjustment ? 1 : 0));
      });
    }

    fields.remove(index);
    setSelectedVariants((vs) => vs.filter((v) => v.key !== variant.key));
    setVariantOptions((os) => [...os, variant]);
  };

  const handleEvenlyDistributeTraffic = (): void => {
    const base: number = Math.floor(100 / fields.length);
    const adjustment: number = 100 % fields.length;

    fields.forEach((name, index) => {
      change(`${name}.traffic_percentage`, base + (index < adjustment ? 1 : 0));
    });
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle className={styles.cardTitle}>
          <span className="title">Variants and Traffic Allocation</span>
          <Button
            type="button"
            disabled={isEquallyAllocated}
            onClick={handleEvenlyDistributeTraffic}
          >
            Allocate Traffic Equally
          </Button>
          <Select
            className={['variant-list', !variantOptions.length ? 'disabled' : ''].join(' ')}
            disabled={!variantOptions.length}
            onValueChange={handleAddVariant}
            options={variantOptions
              .map((o) => ({ value: o.key, content: o.key }))}
            hintText="Add Variant"
          />
        </CardTitle>
      </CardHeader>
      <hr className={styles.divider} />
      <CardContent>
        {!fields.length ? (
          <CardEmptyState
            className={styles.noVariants}
          >
            Please set up the experiment type.
          </CardEmptyState>
        )
          : (
            <>
              {fields.map((variant, index) => (
                <div className={styles.variantContainer}>
                  <span className="variant-number">{`Variant ${index + 1}`}</span>
                  <Field
                    name={`${variant}.name`}
                    component={ExperimentNameField}
                  />
                  <Field
                    component={TextField}
                    clearable={false}
                    className="variant-traffic"
                    parse={(value: string): number => Number(value) || 0}
                    name={`${variant}.traffic_percentage`}
                  />
                  <span>% Traffic</span>
                  <IconButton
                    disabled={fields.length <= 1}
                    className="clear-button"
                    type="button"
                    icon={Close}
                    size="small"
                    onClick={(): void => handleRemove(index)}
                  />
                </div>
              ))}
              {meta.error && (
              <div className={styles.variantError}>
                <AlertTwoTone className="icon" />
                {meta.error}
              </div>
              )}
              {!meta.error && meta.warning && (
              <div className={styles.variantWarn}>
                <InfoCircle className="icon" />
                {meta.warning}
              </div>
              )}
            </>
          )}
      </CardContent>
    </Card>
  );
});

ExperimentVariants.displayName = 'ExperimentVariants';

export default ExperimentVariants;
