import { createSelector } from 'reselect';
import get from 'lodash/get';

import getNonReturnableState from './getNonReturnableState';
import { RootState } from '../../../stores/types';
import { NonReturnableState, ReturnPolicyWorksheetState } from '../types';

const getReturnPolicyWorksheet = createSelector<
RootState, NonReturnableState, ReturnPolicyWorksheetState
>(
  getNonReturnableState,
  (state) => get(state, 'dialogs.returnPolicyWorksheet'),
);

export default getReturnPolicyWorksheet;
