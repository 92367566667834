import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { browserHistory } from 'react-router';
import { getAllResults } from '@flowio/redux-filtering-paging-sorting';
import { reduxFormWorksheet } from '@flowio/redux-form-overlay';

import { createToast } from '../../console/actions';
import { createShippingConfiguration, createShippingConfigurationCopy } from '../actions';
import { getShippingConfigurationPaginationState } from '../selectors';
import ActionTypes from '../constants/ActionTypes';
import CreateShippingConfigurationForm from '../components/CreateShippingConfigurationForm';
import FormName from '../constants/FormName';
import handleSubmitResult from '../utilities/handleSubmitResult';
import { CreateShippingConfigurationFormValues } from '../types/components';
import { ThunkDispatcher } from '../../../middlewares/types';
import { RootActionTypes } from '../../../stores/types';

/**
 * @typedef {Object} CreateShippingConfigurationFormValues
 * @property {?String} shippingConfigurationKey
 * @property {String} name
 * @property {String} method
 * @property {String} organizationId
 */

/**
 * Creates a shipping configuration form from form values.
 * @param {CreateShippingConfigurationFormValues} values
 * @returns {ShippingConfigurationForm}
 */
function shippingConfigurationFormFromValues(values: CreateShippingConfigurationFormValues) {
  return { name: values.name };
}

function shippingConfigurationCopyFormFromValues(values: CreateShippingConfigurationFormValues) {
  return { name: values.name };
}

function handleSubmit(values: CreateShippingConfigurationFormValues, dispatch: ThunkDispatcher) {
  const {
    method,
    organizationId,
    shippingConfigurationKey = '',
  } = values;

  switch (method) {
    case 'duplicate':
      return dispatch(createShippingConfigurationCopy(
        organizationId,
        shippingConfigurationKey,
        shippingConfigurationCopyFormFromValues(values),
      )).then((result) => handleSubmitResult(
        result,
        [ActionTypes.CREATE_SHIPPING_CONFIGURATION_COPY_FAILURE],
      ));
    default:
      return dispatch(createShippingConfiguration(
        organizationId,
        shippingConfigurationFormFromValues(values),
      )).then((result) => handleSubmitResult(
        result,
        [ActionTypes.CREATE_SHIPPING_CONFIGURATION_FAILURE],
      ));
  }
}

function handleSuccess(action: RootActionTypes, dispatch: ThunkDispatcher) {
  switch (action.type) {
    case ActionTypes.CREATE_SHIPPING_CONFIGURATION_SUCCESS:
      browserHistory.push(`/${action.meta.organizationId}/logistics/shipping/configurations/${action.payload.key}`);
      dispatch(createToast({
        content: 'Shipping configuration has been added.',
      }));
      break;
    case ActionTypes.CREATE_SHIPPING_CONFIGURATION_COPY_SUCCESS:
      browserHistory.push(`/${action.meta.organizationId}/logistics/shipping/configurations/${action.payload.new.key}`);
      dispatch(createToast({
        content: 'Shipping configuration has been duplicated.',
      }));
      break;
    default:
      break;
  }
}

const mapStateToProps = createStructuredSelector({
  shippingConfigurations: getAllResults(getShippingConfigurationPaginationState),
});

export default compose<React.FC>(
  connect(mapStateToProps),
  reduxFormWorksheet({
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    form: FormName.CREATE_SHIPPING_CONFIGURATION_FORM,
    onSubmit: handleSubmit,
    onSubmitSuccess: handleSuccess,
  }),
)(CreateShippingConfigurationForm);
