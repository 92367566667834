import ActionTypes from '../constants/action-types';
import standardAction from '../../../utilities/standard-action';
import { UtilityActions } from '../types';

interface ChangeItem {
  number: string;
  quantity: string;
}

export default function updateItemQuantity(item: ChangeItem): UtilityActions {
  return standardAction(ActionTypes.UPDATE_ITEM_QUANTITY, { item });
}
