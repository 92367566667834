import map from 'lodash/map';
import update from 'immutability-helper';

import ActionTypes from '../constants/action-types';
import { RootActionTypes } from '../../../stores/types';
import { ExperienceState } from '../types';

type State = ExperienceState['entities']['experiences'];

const initialState: State = {
  isFirstPage: true,
  isLastPage: true,
  results: [],
};

// TODO: We should implement proper pagination like in the other modules.
export default function (state: State = initialState, action: RootActionTypes): State {
  switch (action.type) {
    case ActionTypes.FETCH_EXPERIENCES_SUCCESS:
      return update(state, { $merge: action.payload });
    case ActionTypes.UPDATE_EXPERIENCE_STATUS_SUCCESS:
      return update(state, {
        results: {
          $apply: (
            experiences: io.flow.v0.models.Experience[],
          ): io.flow.v0.models.Experience[] => map(experiences, (experience) => (
            (experience.id === action.payload.id ? action.payload : experience)
          )),
        },
      });
    case ActionTypes.FETCH_ALL_EXPERIENCES_SUCCESS:
      return update(state, { $set: action.payload });
    default:
      return state;
  }
}
