import ActionTypes from '../constants/action-types';
import { RootActionTypes, ThunkResult, ThunkExtraArguments } from '../../../stores/types';
import { ApiError } from '../../../utilities/clients/api-error';

export function fetchCheckoutConfigurationRequest(): RootActionTypes {
  return {
    type: ActionTypes.FETCH_CHECKOUT_CONFIGURATION_REQUEST,
  };
}

export function fetchCheckoutConfigurationSuccess(
  configurations: io.flow.internal.v0.models.CheckoutConfiguration[],
): RootActionTypes {
  return {
    type: ActionTypes.FETCH_CHECKOUT_CONFIGURATION_SUCCESS,
    payload: configurations,
  };
}

export function fetchCheckoutConfigurationFailure(
  error?: io.flow.v0.models.GenericError,
): RootActionTypes {
  return {
    type: ActionTypes.FETCH_CHECKOUT_CONFIGURATION_FAILURE,
    payload: error,
  };
}

export default function fetchCheckoutConfigurations(
  organization: string,
): ThunkResult<Promise<undefined | io.flow.internal.v0.models.CheckoutConfiguration[]>> {
  return function fetchCheckoutConfigurationEffects(
    dispatch,
    getState,
    extra: ThunkExtraArguments,
  ): Promise<undefined | io.flow.internal.v0.models.CheckoutConfiguration[]> {
    dispatch(fetchCheckoutConfigurationRequest());

    return extra.apiInternal(getState()).checkoutConfigurations.get({
      organization,
    }).then((response) => {
      if (response.ok) {
        dispatch(fetchCheckoutConfigurationSuccess(response.body));
        return response.body;
      }

      const error = new ApiError(
        response,
      );
      dispatch(fetchCheckoutConfigurationFailure(response.body));
      throw error;
    });
  };
}
