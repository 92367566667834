import { createSelector } from 'reselect';
import { RootState } from '../../stores/types';
import { PriceBookComponentState } from './types/types';

export const getPriceBookComponentState = (
  state: RootState,
): PriceBookComponentState => state.priceBookComponentState;

export const getPriceBookExportOptions = createSelector(
  getPriceBookComponentState,
  (state) => state.options.available_identifiers,
);
