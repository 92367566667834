import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { downloadAllFeeds, hideDownloadFeedsDialog, showDownloadFeedsDialog } from '../../../actions';
import DownloadFeedsDialog from '../components/DownloadFeedsDialog';
import { getDownloadFeedsDialogData } from '../../../selectors';
import { ThunkDispatcher, RootState } from '../../../../../stores/types';
import { DispatchProps, StateProps, OwnProps } from '../types';

const mapStateToProps = (state: RootState): StateProps => getDownloadFeedsDialogData(state);

const handleDownloadAllFeeds = (
  organizationId: string,
  email: string,
  feedIds: string[],
) => (dispatch: ThunkDispatcher): void => {
  dispatch(downloadAllFeeds(
    organizationId,
    email,
    feedIds,
  ));
};

const mapDispatchToProps = (dispatch: ThunkDispatcher): DispatchProps => bindActionCreators({
  handleDownloadFeedsButtonClick: showDownloadFeedsDialog,
  onHandleDownloadAllFeeds: handleDownloadAllFeeds,
  onCancelFeedsClick: hideDownloadFeedsDialog,
}, dispatch);

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(DownloadFeedsDialog);
