import { RSAA } from '@flowio/redux-async-middleware';
import { operationTypes } from '@flowio/redux-resource';
import { api } from '../../../../utilities/clients';
import { createResourceTypeDescriptors } from '../../helpers';

export default function readCurrencies() {
  return {
    [RSAA]: {
      types: createResourceTypeDescriptors({
        operationType: operationTypes.READ,
        resourceType: 'currencies',
      }),
      callAPI: (state) => api.currencies(state).get(),
    },
  };
}
