import { RouterState, browserHistory } from 'react-router';
import { compose } from 'redux';
import { isValid } from 'redux-form';
import { connect } from 'react-redux';
import { withFetch } from '@flowio/redux-fetch';
import Imports from '../components/imports';
import {
  updateFileStatus, getAllImportedFiles, fetchOrganization, toggleDialog, closeImportEventSource,
} from '../actions';
import {
  getDetailedUser,
} from '../../user/actions';

import {
  getFormError, getIsFormSubmitted, getIsFileEmpty, getImportedFiles, getImportsCurrentPageNumber,
  getIsImportFirstPage, getIsImportLastPage, getCurrentAccount, getOrganization,
  getOpenDialogStatus,
  getIsFormSubmitting,
} from '../selectors';

import {
  getUserId,
} from '../../console/selectors';
import ActionTypes from '../constants/action-types';
import { IMPORTS_FORM } from '../constants/form-names';
import { RootState, ThunkDispatcher } from '../../../stores/types';

function getAsyncState(
  dispatch: ThunkDispatcher,
  getState: () => RootState,
  ownProps: RouterState,
) {
  const {
    entriesPerPage = 25,
    pageNumber,
  } = ownProps.location.query;
  const {
    organization,
  } = ownProps.params;

  const state = getState();

  return Promise.all([
    dispatch(fetchOrganization(organization)),
    dispatch(getDetailedUser(getUserId(state))),
    dispatch(getAllImportedFiles({
      entriesPerPage,
      pageNumber,
      sort: 'created_at',
      sortOrder: 'descending',
      organization,
    })),
  ]);
}

function mapStateToProps(state: RootState) {
  return {
    error: getFormError('imports')(state),
    organization: getOrganization(state),
    account: getCurrentAccount(state),
    valid: isValid(IMPORTS_FORM)(state),
    openDialog: getOpenDialogStatus(state),
    isFileEmpty: getIsFileEmpty(state),
    pageNumber: getImportsCurrentPageNumber(state),
    isFirstPage: getIsImportFirstPage(state),
    isLastPage: getIsImportLastPage(state),
    importSuccess: getIsFormSubmitted('imports')(state),
    submitting: getIsFormSubmitting('imports')(state),
    files: getImportedFiles(state),
  };
}

function mapDispatchToProps(dispatch: ThunkDispatcher, ownProps: RouterState) {
  return {
    onFileEmptyError: () => dispatch(
      {
        type: ActionTypes.IMPORT_FILE_EMPTY,
      },
    ),
    onToggleDialog() {
      dispatch(toggleDialog());
    },
    onUpdateFileStatus(files: FileList) {
      dispatch(updateFileStatus(files));
    },
    onGetImportedFiles() {
      dispatch(getAllImportedFiles());
    },
    onCloseImportEventSource() {
      dispatch(closeImportEventSource());
    },
    onRequestPage({
      entriesPerPage = 25,
      pageNumber,
    }: { entriesPerPage: number, pageNumber: number | string }) {
      browserHistory.push({
        pathname: ownProps.location.pathname,
        query: {
          ...ownProps.location.query,
          entriesPerPage,
          pageNumber,
        },
      });
    },
  };
}

export default compose<React.FC>(
  withFetch(getAsyncState),
  connect(mapStateToProps, mapDispatchToProps),
)(Imports);
