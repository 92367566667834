import React from 'react';
import { Field, Form, InjectedFormProps } from 'redux-form';
import noop from 'lodash/noop';
import { Box, BoxItem } from '@flowio/react-box';
import { css } from '@emotion/react';

import { Search } from '@flowio/react-icons';
import ExperienceStatusFilter from '../../ExperieceStatusFilter';

import { MergedProps as Props, ExperienceListFilterFormValues } from '../types';
import { TextField } from '../../../../../components/ReduxFormFieldAdapters';

const statusFilterStyles = css({
  float: 'right',
});

const ExperienceListFilterForm: React.FC<
Props & InjectedFormProps<ExperienceListFilterFormValues, Props>
> = ({
  handleSubmit,
}) => (
  <Form onSubmit={handleSubmit}>
    <Box spacing="loose" alignItems="center">
      <BoxItem style={{ width: '50%' }}>
        <Box spacing="loose" alignItems="center">
          <BoxItem>
            <div>
              Search:
            </div>
          </BoxItem>
          <BoxItem style={{ minWidth: '80%' }}>
            <Field
              name="experienceSearch"
              hintText="Enter Experience Name or Key..."
              rightIcon={Search}
              type="text"
              fluid
              component={TextField}
              clearable
            />
          </BoxItem>
        </Box>
      </BoxItem>
      <BoxItem style={{ width: '50%' }}>
        <Box spacing="loose" alignItems="center" css={statusFilterStyles}>
          <BoxItem>
            <div>
              Status:
            </div>
          </BoxItem>
          <BoxItem style={{ width: '20%' }}>
            <ExperienceStatusFilter />
          </BoxItem>
        </Box>
      </BoxItem>
    </Box>
  </Form>
);

ExperienceListFilterForm.displayName = 'ExperienceListFilterForm';

ExperienceListFilterForm.defaultProps = {
  handleSubmit: noop,
};

export default ExperienceListFilterForm;
