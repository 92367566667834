// Constants for discriminator
// https://app.apibuilder.io/flow/fulfillment/0.6.26#union-tier_rule_outcome_form

enum TierRuleOutcomeFormDiscriminatorValues {
  AMOUNT_MARGIN_FORM = 'amount_margin_form',
  AT_COST = 'at_cost',
  FLAT_RATE_FORM = 'flat_rate_form',
  PERCENT_MARGIN = 'percent_margin',
}

export default TierRuleOutcomeFormDiscriminatorValues;
