/**
 * Use these tokens for changing the line-height of elements.
 */
const lineHeight = {
  100: '0.875rem',
  200: '1rem',
  300: '1.0625rem',
  400: '1.125rem',
  500: '1.25rem',
  600: '1.3125rem',
  700: '1.4375rem',
  800: '1.5625rem',
  900: '1.75rem',
} as const;

export default lineHeight;
