import React from 'react';
import { FormApi } from 'final-form';
import { useDispatch } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { Button } from '@flowio/react-button';
import { createOrderNote } from '../../actions/orderNotes';
import { IOrderNoteForm } from './types';
import TextField from '../../../../components/TextField';
import { ThunkDispatcher } from '../../../../stores/types';
import { inlineButtonStyles, inlineFormStyles, inlineInputStyles } from './styles';

interface CreateOrderNoteFormProps {
  organizationId: string;
  orderNumber: string;
}

const CreateOrderNoteForm: React.FC<CreateOrderNoteFormProps> = ({
  organizationId,
  orderNumber,
}) => {
  const dispatch = useDispatch<ThunkDispatcher>();

  const submit = (values: IOrderNoteForm, form: FormApi) => dispatch(createOrderNote(
    organizationId,
    orderNumber,
    values.note!,
  )).then(() => {
    form.restart();
  }).catch(() => ({
    note: 'An error has occurred while trying to create the message. Please try again.',
  }));

  return (
    <Form
      onSubmit={submit}
      render={({ handleSubmit, submitting, pristine }) => (
        <form onSubmit={handleSubmit} css={inlineFormStyles}>
          <div css={inlineInputStyles}>
            <Field
              component={TextField}
              name="note"
              showFeedback={false}
              validate={(value: string) => (!value ? 'Required' : undefined)}
              placeholder="Enter new log message..."
              fluid
            />
          </div>
          <Button css={inlineButtonStyles} type="submit" disabled={submitting || pristine}>Submit</Button>
        </form>
      )}
    />
  );
};

export default CreateOrderNoteForm;
