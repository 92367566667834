import {
  ThunkDispatcher, ThunkResult, ThunkExtraArguments, RootState,
} from '../../../stores/types';
import { ActionTypes } from '../constants';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import toLegacyResponse from '../../../utilities/clients/to-legacy-response';
import { LegacyResponse } from '../../../utilities/clients/types/server';

export default function newPassword(token: string, password: string):
ThunkResult<Promise<LegacyResponse<io.flow.v0.unions.ExpandableUser | FormattedErrorMessages>>> {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<LegacyResponse<io.flow.v0.unions.ExpandableUser | FormattedErrorMessages>> => {
    dispatch({
      type: ActionTypes.AUTH_NEW_PASSWORD_SUBMIT,
    });

    return extra.api(getState()).passwordResetForms.post({
      body: { token, password },
    }).then((response) => {
      if (response.ok) {
        dispatch({
          type: ActionTypes.AUTH_NEW_PASSWORD_COMPLETED,
          payload: response.body,
        });
        return toLegacyResponse(response);
      }

      dispatch({
        type: ActionTypes.AUTH_NEW_PASSWORD_ERROR,
        payload: formatErrorResponseV2(response),
      });

      return toLegacyResponse({
        ...response,
        body: formatErrorResponseV2(response),
      });
    });
  };
}
