import {
  Field, autofill, clearFields, formValues,
} from 'redux-form';
import { ZeroAmountIndicator } from '@flowio/api-constants';
import { connect } from 'react-redux';
import { compose, Dispatch } from 'redux';
import { Box } from '@flowio/react-box';
import React from 'react';
import find from 'lodash/find';

import { FormName, TierRuleOutcomeFormDiscriminatorValues } from '../../constants';
import { TierRuleOutcomeOwnProps, TierRuleOutcomeProps } from '../../types/components';
import { RootActionTypes } from '../../../../stores/types';
import { SelectField, TextField } from '../../../../components/ReduxFormFieldAdapters';

const outcomeTypeOptions = [
  { content: 'at cost', value: TierRuleOutcomeFormDiscriminatorValues.AT_COST },
  { content: 'flat rate', value: TierRuleOutcomeFormDiscriminatorValues.FLAT_RATE_FORM },
  { content: 'percent margin', value: TierRuleOutcomeFormDiscriminatorValues.PERCENT_MARGIN },
  // TODO: While this option is defined in our API specification, it is not
  // actually implemented by the service. We are opting to keep the code
  // present in Console until the day comes that it can be utilized by users.
  // { content: 'amount margin', value: TierRuleOutcomeFormDiscriminatorValues.AMOUNT_MARGIN_FORM },
];

const zeroAmountIndicatorOptions = [
  { content: '"0" in local currency', value: ZeroAmountIndicator.ZERO },
  { content: '"FREE"', value: ZeroAmountIndicator.FREE },
];

interface ParseZeroAmountOption {
  value: ZeroAmountIndicator;
}

interface ParseOutcomeTypeOption {
  value: TierRuleOutcomeFormDiscriminatorValues;
}

const formatOutcomeType = (
  value: TierRuleOutcomeFormDiscriminatorValues,
) => find(outcomeTypeOptions, { value });
const parseOutcomeType = (option: ParseOutcomeTypeOption) => option.value;

const formatZeroAmountIndicator = (
  value: ZeroAmountIndicator,
) => find(zeroAmountIndicatorOptions, { value });
const parseZeroAmountIndicator = (option: ParseZeroAmountOption) => option.value;

const isZeroValue = (value = '') => Number.parseFloat(value) === 0;

const TierRuleOutcome: React.FC<TierRuleOutcomeProps> = ({
  name,
  onTypeChange,
  onValueChange,
  outcomeValue,
  selectedCurrency,
  selectedOutcomeType,
}) => (
  <Box direction="column" spacing="tight">
    <Box alignItems="center" spacing="tight">
      <span>Set shipping pricing as</span>
      <Field
        component={SelectField}
        name={`${name}.type`}
        format={formatOutcomeType}
        parse={parseOutcomeType}
        onChange={onTypeChange}
        options={outcomeTypeOptions}
        selection
        labelKey="content"
        valueKey="value"
      />
      {(selectedOutcomeType !== TierRuleOutcomeFormDiscriminatorValues.AT_COST) && (
        <span>of</span>
      )}
      {(selectedOutcomeType !== TierRuleOutcomeFormDiscriminatorValues.AT_COST) && (
        <Field
          component={TextField}
          onChange={onValueChange}
          name={`${name}.value`}
        />
      )}
      {(selectedOutcomeType === TierRuleOutcomeFormDiscriminatorValues.PERCENT_MARGIN) && (
        <span>%</span>
      )}
      {(selectedOutcomeType === TierRuleOutcomeFormDiscriminatorValues.FLAT_RATE_FORM) && (
        <span>{selectedCurrency}</span>
      )}
      {(selectedOutcomeType === TierRuleOutcomeFormDiscriminatorValues.AMOUNT_MARGIN_FORM) && (
        <span>{selectedCurrency}</span>
      )}
    </Box>
    {(selectedOutcomeType === TierRuleOutcomeFormDiscriminatorValues.FLAT_RATE_FORM)
      && isZeroValue(outcomeValue) && (
      <Box alignItems="center" spacing="tight">
        <span>Display in checkout with price indicator of</span>
        <Field
          component={SelectField}
          format={formatZeroAmountIndicator}
          labelKey="content"
          name={`${name}.zeroAmountIndicator`}
          options={zeroAmountIndicatorOptions}
          parse={parseZeroAmountIndicator}
          selection
          valueKey="value"
        />
      </Box>
    )}
  </Box>
);

TierRuleOutcome.displayName = 'TierRuleOutcome';

const mapValuesToProps = (props: TierRuleOutcomeProps) => {
  const { name } = props;
  return {
    outcomeValue: `${name}.value`,
    selectedCurrency: 'currency',
    selectedOutcomeType: `${name}.type`,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<RootActionTypes>, props: TierRuleOutcomeProps) => ({
  onTypeChange(_event: any, nextValue: TierRuleOutcomeFormDiscriminatorValues) {
    if (nextValue === TierRuleOutcomeFormDiscriminatorValues.FLAT_RATE_FORM
      && isZeroValue(props.outcomeValue)) {
      dispatch(autofill(FormName.TIER_UPSERT_FORM, `${props.name}.zeroAmountIndicator`, ZeroAmountIndicator.FREE));
    } else {
      dispatch(clearFields(FormName.TIER_UPSERT_FORM, false, false, `${props.name}.zeroAmountIndicator`));
    }
  },
  onValueChange(_event: any, nextValue: TierRuleOutcomeFormDiscriminatorValues) {
    if (props.selectedOutcomeType === TierRuleOutcomeFormDiscriminatorValues.FLAT_RATE_FORM
      && isZeroValue(nextValue)) {
      dispatch(autofill(FormName.TIER_UPSERT_FORM, `${props.name}.zeroAmountIndicator`, ZeroAmountIndicator.FREE));
    } else {
      dispatch(clearFields(FormName.TIER_UPSERT_FORM, false, false, `${props.name}.zeroAmountIndicator`));
    }
  },
});

export default compose<React.FC<TierRuleOutcomeOwnProps>>(
  formValues(mapValuesToProps),
  connect(null, mapDispatchToProps),
)(TierRuleOutcome);
