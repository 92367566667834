import { createSelector } from 'reselect';
import { getIndexedEntities } from '@flowio/redux-filtering-paging-sorting';
import values from 'lodash/values';

import getShippingConfigurationPaginationState from './getShippingConfigurationPaginationState';

export default createSelector(
  getIndexedEntities(getShippingConfigurationPaginationState),
  (indexedRecords) => values(indexedRecords),
);
