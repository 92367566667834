import { compose } from 'redux';
import { reduxForm, formValues, SubmissionError } from 'redux-form';
import { OptinPromptsPostPromptsResponse, OptinPromptsPutPromptsByIdResponse } from '@flowio/api-internal-sdk';
import { $HttpResponse } from '@flowio/api-sdk';
import { toOptinPromptForm } from '../../../utilities/converters';
import { createOptinPrompt, updateOptinPrompt } from '../../../actions';
import FormName from '../../../constants/FormName';
import OptinContentForm from '../components/OptinContentForm';
import {
  OwnProps,
  OptinContentFormValueProps,
} from '../types';
import { ThunkDispatcher } from '../../../../../stores/types';
import { OptinContentFormValues } from '../../../types';

type SubmitResponse = OptinPromptsPutPromptsByIdResponse | OptinPromptsPostPromptsResponse;

const handleSubmit = (
  values: OptinContentFormValues,
  dispatch: ThunkDispatcher,
): Promise<$HttpResponse> => {
  const { organizationId, optinPromptId } = values;
  const optinPromptForm = toOptinPromptForm(values) as io.flow.internal.v0.models.OptinPromptForm;

  let promise: Promise<SubmitResponse>;

  if (optinPromptId) {
    promise = dispatch(updateOptinPrompt(organizationId, optinPromptId, optinPromptForm));
  } else {
    promise = dispatch(createOptinPrompt(organizationId, optinPromptForm));
  }

  return promise.then((response) => {
    if (response.ok) {
      return response;
    }

    throw new SubmissionError({ _error: response.body });
  });
};

export default compose<React.FC<OwnProps>>(
  reduxForm({
    form: FormName.OPTIN_CONTENT_FORM,
    onSubmit: handleSubmit,
  }),
  formValues<OptinContentFormValueProps, OwnProps>({
    isCheckbox: 'isCheckbox',
    selectedConsentType: 'consentType',
    selectedLanguageCode: 'language',
    selectedRegionId: 'region',
  }),
)(OptinContentForm);
