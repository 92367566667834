import { bindActionCreators, compose } from 'redux';
import { connect, MapDispatchToProps } from 'react-redux';
import { isInvalid, isSubmitting, submit } from 'redux-form';
import overSome from 'lodash/overSome';

import {
  getIsDialogOpen,
  getDialogProp,
  getAvailableFiltersForAddRuleWorksheet,
} from '../selectors';
import {
  hideAddRuleWorksheet,
} from '../actions';
import AddRuleWorksheet from '../components/AddRuleWorksheet';
import FormName from '../constants/FormName';
import DialogName from '../constants/DialogName';
import { RootState, ThunkDispatcher } from '../../../stores/types';
import {
  AddRuleWorksheetDispatchProps,
  AddRuleWorksheetStateProps,
  AddRuleWorksheetValues,
} from '../types/components';

const form = FormName.ADD_RULE_FORM;
const worksheet = DialogName.ADD_RULE_WORKSHEET;

const submitForm = () => submit(form);

const mapStateToProps = (state: RootState): AddRuleWorksheetStateProps => ({
  availableFilters: getAvailableFiltersForAddRuleWorksheet(state),
  isSaveDisabled: overSome([isSubmitting(form), isInvalid(form)])(state),
  initialValues: getDialogProp(worksheet, 'initialValues')(state) as Partial<AddRuleWorksheetValues>,
  open: getIsDialogOpen(worksheet)(state) as boolean,
});

function mapDispatchToProps(
  dispatch: ThunkDispatcher,
): MapDispatchToProps<AddRuleWorksheetDispatchProps, {}> {
  return bindActionCreators({
    onCancel: hideAddRuleWorksheet,
    onSave: submitForm,
  }, dispatch);
}

export default compose<React.FC>(
  connect(mapStateToProps, mapDispatchToProps),
)(AddRuleWorksheet);
