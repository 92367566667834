import curry from 'lodash/curry';
import identity from 'lodash/identity';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';

interface ValueWithLength {
  length: number;
}

interface ValidatorOptions {
  message: string;
  maximum?: number;
  minimum?: number;
  is?: number;
  tokenizer?: (value: string | object) => ValueWithLength;
}

/**
 * The length validator will check the length of a string or any object with
 * the length property.
 *
 * You may specify the following length constraints:
 *
 * - `is` The value has to have exactly this length.
 * - `minimum` The value cannot be shorter than this value.
 * - `maximum` The value cannot be longer than this value.
 *
 * You can also use `message` option to specify the message for all errors.
 *
 * Per default the number of characters are counted (using the length property),
 * if you want to count something else you can specify the tokenizer option
 * which should be a function that takes a single argument (the value) and
 * returns a value that should be used when counting.
 *
 * The tokenizer will never be called with nil or undefined as an argument.
 *
 * IMPORTANT!
 * A value must have a numeric value for the length property.
 *
 * IMPORTANT!
 * A value that evaluates to nil or undefined is valid.
 * Use the presence validator if you do not want to allow empty values.
 *
 * @example
 *
 * length({
 *  minimum: 3,
 *  message: 'must be at least 3 characters long'
 * }, 'password');
 *
 * length({
 *  maximum: 5,
 *  message: 'must not be longer than 5 words`,
 *  tokenizer: value => value.split(/\s+/g),
 * })
 */
export default curry((options: ValidatorOptions, value: string | ValueWithLength) => {
  const {
    is,
    maximum,
    message,
    minimum,
    tokenizer = identity,
  } = options;

  // Empty values are allowed
  if (isNil(value)) {
    return undefined;
  }

  const { length } = tokenizer(value) as ValueWithLength;

  if (!isNumber(length)) {
    return message; // invalid type
  }

  if (isNumber(is) && length !== is) {
    return message; // wrong length
  }

  if (isNumber(minimum) && length < minimum) {
    return message; // too short
  }

  if (isNumber(maximum) && length > maximum) {
    return message; // too long
  }

  return undefined;
});
