import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  isInvalid,
  isSubmitting,
  submit,
  isPristine,
} from 'redux-form';
import { RootState, ThunkDispatcher } from '../../../../../stores/types';
import { DispatchProps, StateProps } from '../types';

import { hideDialog } from '../../../actions';
import { getDialogProp, getIsDialogOpen } from '../../../selectors';
import FormName from '../../../constants/FormName';
import UpdateOptinMessageWorksheet from '../components/UpdateOptinMessageWorksheet';
import DialogName from '../../../constants/DialogName';

const form = FormName.UPDATE_OPTIN_ATTRIBUTE_FORM;
const dialog = DialogName.UPDATE_OPTIN_MESSAGE_WORKSHEET;

const submitForm = () => submit(form);

const mapStateToProps = (state: RootState): StateProps => ({
  initialValues: getDialogProp(dialog, 'initialValues')(state),
  isCancelDisabled: isSubmitting(form)(state),
  isSaveDisabled: isPristine(form)(state) || isInvalid(form)(state) || isSubmitting(form)(state),
  open: getIsDialogOpen(dialog)(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatcher): DispatchProps => bindActionCreators({
  onCancel: hideDialog(dialog),
  onSave: submitForm,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpdateOptinMessageWorksheet);
