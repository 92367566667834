import { ResponseError } from '@flowio/redux-async-middleware';
import ActionTypes from '../constants/action-types';
import {
  ThunkDispatcher, RootState, ThunkExtraArguments, ThunkResult, RootActionTypes,
} from '../../../stores/types';

interface FetchExperienceLogisticsSettingParams {
  organizationId: string;
  experienceKey: string;
}

export default function fetchExperienceLogisticsSetting({
  organizationId,
  experienceKey,
}: FetchExperienceLogisticsSettingParams): ThunkResult<Promise<RootActionTypes>> {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<RootActionTypes> => {
    dispatch({
      type: ActionTypes.FETCH_EXPERIENCE_LOGISTICS_SETTING_REQUEST,
    });
    return extra.api(getState()).experienceLogisticsSettings.get({
      organization: organizationId,
      experience_key: experienceKey,
    }).then((response) => {
      if (response.ok) {
        return dispatch({
          type: ActionTypes.FETCH_EXPERIENCE_LOGISTICS_SETTING_SUCCESS,
          payload: response.body,
        });
      }
      return dispatch({
        type: ActionTypes.FETCH_EXPERIENCE_LOGISTICS_SETTING_FAILURE,
        error: true,
        meta: {},
        payload: new ResponseError(response.status, response.statusText, response.body),
      });
    });
  };
}
