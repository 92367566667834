import update from 'immutability-helper';

import { RootActionTypes } from '../../../stores/types';
import { PriceBookComponentState } from '../types/types';
import ActionTypes from '../types/action-types';

const initialState: PriceBookComponentState = {
  options: {
    available_identifiers: [],
  },
};

export default function priceBooksReducer(
  state = initialState,
  action: RootActionTypes,
): PriceBookComponentState {
  switch (action.type) {
    case ActionTypes.FETCH_PRICE_BOOK_EXPORT_TYPES_REQUEST:
      return update(state, {
        options: {
          available_identifiers: {
            $set: [],
          },
        },
        error: {
          $set: undefined,
        },
      });
    case ActionTypes.FETCH_PRICE_BOOK_EXPORT_TYPES_SUCCESS:
      return update(state, {
        options: {
          $set: action.payload,
        },
        error: {
          $set: undefined,
        },
      });
    case ActionTypes.FETCH_PRICE_BOOK_EXPORT_TYPES_FAILURE:
      return update(state, {
        options: {
          available_identifiers: {
            $set: [],
          },
        },
        error: {
          $set: action.payload,
        },
      });
    default:
      return state;
  }
}
