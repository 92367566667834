import React from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableHeadColumn,
  TableBody,
} from '@flowio/react-table';
import map from 'lodash/map';
import { css } from '@emotion/css';
import ReturnPolicyItem from '../ReturnPolicyItem';

const statusColumnStyles = css`
  width: 120px;
`;

const countColumnStyles = css`
  width: 120px;
`;

interface ReturnPolicyListProps {
  returnPolicies: io.flow.v0.models.ReturnPolicy[];
  organizationId: string;
  onEditReturnPolicy: (...args: any) => void;
  onRemoveReturnPolicy: (...args: any) => void;
}

const ReturnPolicyList: React.FC<ReturnPolicyListProps> = ({
  returnPolicies,
  onEditReturnPolicy,
  onRemoveReturnPolicy,
  organizationId,
}) => (
  <Table fixed striped singleLine>
    <TableHead>
      <TableRow>
        <TableHeadColumn>
          Rules
        </TableHeadColumn>
        <TableHeadColumn className={statusColumnStyles}>
          Status
        </TableHeadColumn>
        <TableHeadColumn className={countColumnStyles}>
          # of Items
        </TableHeadColumn>
        <TableHeadColumn>
          Non-Returnable In
        </TableHeadColumn>
        <TableHeadColumn />
      </TableRow>
    </TableHead>
    <TableBody>
      {map(returnPolicies, (returnPolicy, index) => (
        <ReturnPolicyItem
          currentIndex={index}
          key={returnPolicy.id}
          returnPolicy={returnPolicy}
          onEdit={onEditReturnPolicy}
          onRemove={onRemoveReturnPolicy}
          organizationId={organizationId}
        />
      ))}
    </TableBody>
  </Table>
);

ReturnPolicyList.displayName = 'ReturnPolicyList';

export default ReturnPolicyList;
