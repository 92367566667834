import { server } from '../../../utilities/clients';
import { getUserToken } from '../../console/selectors';
import checkHttpStatus from '../../../utilities/check-http-status';

const defaultSuccess = (action, dispatch, response) => dispatch({
  type: action,
  payload: response.result.data,
});

const defaultFailure = (action, dispatch, error) => {
  if (!error.response) throw error;
  dispatch({ type: action, payload: error.response });
};

export default function makeGraphqlRequest(options = {}) {
  const {
    state,
    dispatch,
    actionTypes,
    body,
    onSuccess = defaultSuccess,
    onFailure = defaultFailure,
  } = options;

  const auth = getUserToken(state);
  const [requestAction, successAction, failureAction] = actionTypes;

  dispatch({ type: requestAction });

  return server.graphqlClient.makeGraphqlQuery({
    body: JSON.stringify(body),
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  }).then(checkHttpStatus)
    .then((response) => {
      onSuccess(successAction, dispatch, response);
    }, (error) => {
      onFailure(failureAction, dispatch, error);
    });
}
