export enum ActionTypes {
  CHANGE_EDIT_EXPERIMENT = 'CHANGE_EDIT_EXPERIMENT',
  CHANGE_CURRENT_EXPERIMENT = 'CHANGE_CURRENT_EXPERIMENT',
  CHANGE_NEW_EXPERIMENT = 'CHANGE_NEW_EXPERIMENT',
  CLOSE_EXPERIMENTS_MODAL = 'CLOSE_EXPERIMENTS_MODAL',
  CREATE_AND_START_EXPERIMENT_REQUEST = 'CREATE_AND_START_EXPERIMENT_REQUEST',
  CREATE_AND_START_EXPERIMENT_SUCCESS = 'CREATE_AND_START_EXPERIMENT_SUCCESS',
  CREATE_AND_START_EXPERIMENT_FAILURE = 'CREATE_AND_START_EXPERIMENT_FAILURE',
  CREATE_EXPERIMENT_REQUEST = 'CREATE_EXPERIMENT_REQUEST',
  CREATE_EXPERIMENT_SUCCESS = 'CREATE_EXPERIMENT_SUCCESS',
  CREATE_EXPERIMENT_FAILURE = 'CREATE_EXPERIMENT_FAILURE',
  FETCH_EXPERIMENT_FORM_DEFAULTS_REQUEST = 'FETCH_EXPERIMENT_FORM_DEFAULTS_REQUEST',
  FETCH_EXPERIMENT_FORM_DEFAULTS_SUCCESS = 'FETCH_EXPERIMENT_FORM_DEFAULTS_SUCCESS',
  FETCH_EXPERIMENT_FORM_DEFAULTS_FAILURE = 'FETCH_EXPERIMENT_FORM_DEFAULTS_FAILURE',
  FETCH_EXPERIMENT_REQUEST = 'FETCH_EXPERIMENT_REQUEST',
  FETCH_EXPERIMENT_SUCCESS = 'FETCH_EXPERIMENT_SUCCESS',
  FETCH_EXPERIMENT_FAILURE = 'FETCH_EXPERIMENT_FAILURE',
  FETCH_EXPERIMENT_RESULTS_REQUEST = 'FETCH_EXPERIMENT_RESULTS_REQUEST',
  FETCH_EXPERIMENT_RESULTS_SUCCESS = 'FETCH_EXPERIMENT_RESULTS_SUCCESS',
  FETCH_EXPERIMENT_RESULTS_FAILURE = 'FETCH_EXPERIMENT_RESULTS_FAILURE',
  FETCH_EXPERIMENT_DISCRIMINATOR_VALUES_REQUEST = 'FETCH_EXPERIMENT_DISCRIMINATOR_VALUES_REQUEST',
  FETCH_EXPERIMENT_DISCRIMINATOR_VALUES_SUCCESS = 'FETCH_EXPERIMENT_DISCRIMINATOR_VALUES_SUCCESS',
  FETCH_EXPERIMENT_DISCRIMINATOR_VALUES_FAILURE = 'FETCH_EXPERIMENT_DISCRIMINATOR_VALUES_FAILURE',
  FETCH_EXPERIMENT_VARIANTS_REQUEST = 'FETCH_EXPERIMENT_VARIANTS_REQUEST',
  FETCH_EXPERIMENT_VARIANTS_SUCCESS = 'FETCH_EXPERIMENT_VARIANTS_SUCCESS',
  FETCH_EXPERIMENT_VARIANTS_FAILURE = 'FETCH_EXPERIMENT_VARIANTS_FAILURE',
  FETCH_EXPERIMENTS_REQUEST = 'FETCH_EXPERIMENTS_REQUEST',
  FETCH_EXPERIMENTS_SUCCESS = 'FETCH_EXPERIMENTS_SUCCESS',
  FETCH_EXPERIMENTS_FAILURE = 'FETCH_EXPERIMENTS_FAILURE',
  OPEN_EXPERIMENTS_MODAL = 'OPEN_EXPERIMENTS_MODAL',
  TOGGLE_END_EXPERIMENT_MODAL = 'TOGGLE_END_EXPERIMENT_MODAL',
  TOGGLE_IMPLEMENT_VARIANT_MODAL = 'TOGGLE_IMPLEMENT_VARIANT_MODAL',
  UPDATE_EXPERIMENT_REQUEST = 'UPDATE_EXPERIMENT_REQUEST',
  UPDATE_EXPERIMENT_SUCCESS = 'UPDATE_EXPERIMENT_SUCCESS',
  UPDATE_EXPERIMENT_FAILURE = 'UPDATE_EXPERIMENT_FAILURE',
  DELETE_EXPERIMENT_REQUEST = 'DELETE_EXPERIMENT_REQUEST',
  DELETE_EXPERIMENT_SUCCESS = 'DELETE_EXPERIMENT_SUCCESS',
  DELETE_EXPERIMENT_FAILURE = 'DELETE_EXPERIMENT_FAILURE',
  UPDATE_EXPERIMENT_STATUS_REQUEST = 'UPDATE_EXPERIMENT_STATUS_REQUEST',
  UPDATE_EXPERIMENT_STATUS_SUCCESS = 'UPDATE_EXPERIMENT_STATUS_SUCCESS',
  UPDATE_EXPERIMENT_STATUS_FAILURE = 'UPDATE_EXPERIMENT_STATUS_FAILURE',
}

export default ActionTypes;
