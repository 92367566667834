import React from 'react';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import PromptOptions from '../../../constants/PromptOptions';
import { Props } from '../types';

const OptinContentCheckboxSetting: React.FC<Props> = ({
  optinPrompt,
}) => {
  const options = get(optinPrompt, 'options', []);
  const content = [];

  if (isEmpty(options)) {
    content.push('None');
  } else if (includes(options, PromptOptions.NOTICE_ONLY)) {
    content.push('None');
  } else {
    if (includes(options, PromptOptions.REQUIRE_CONSENT)) {
      content.push('Required');
    } else {
      content.push('Optional');
    }

    if (includes(options, PromptOptions.CONSENT_BY_DEFAULT)) {
      content.push('Pre-Checked');
    }
  }

  return <span>{content.join(', ')}</span>;
};

OptinContentCheckboxSetting.displayName = 'OptinContentCheckboxSetting';

export default OptinContentCheckboxSetting;
