import React from 'react';
import { Button, ButtonIntent } from '@flowio/react-button';
import { Interpolation } from '@emotion/core';
import { colors } from '../../../../theme/tokens';

type OwnProps = {
  intent?: ButtonIntent;
};

type InheritedProps = Omit<React.ComponentProps<typeof Button>, keyof OwnProps>;

type ButtonProps = OwnProps & InheritedProps;

const marketingStyles = ({ intent }: OwnProps): Interpolation => ({
  backgroundColor: intent === 'positive' ? colors.marketing.positive : 'none',
  cursor: 'pointer',
});

const MarketingButton: React.FC<ButtonProps> = ({
  children,
  intent = 'neutral',
  size = 'medium',
  ...otherProps
}) => (
  <Button
    css={marketingStyles({ intent })}
    size={size}
    intent={intent}
    {...otherProps}
  >
    {children}
  </Button>
);

MarketingButton.displayName = 'MarketingButton';

export default MarketingButton;
