import { $HttpResponse } from '@flowio/api-sdk';
import ActionTypes from '../constants/action-types';
import { ApiError } from '../../../utilities/clients/api-error';
import standardAction from '../../../utilities/standard-action';
import {
  RootState, ThunkExtraArguments, ThunkResult, ThunkDispatcher,
} from '../../../stores/types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';

export default function fetchExperimentDiscriminatorValues(
  organization: string,
  discriminator_key: io.flow.internal.v0.enums.ExperimentDiscriminatorKey = 'experience',
): ThunkResult<Promise<void>> {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<void> => {
    dispatch({
      type: ActionTypes.FETCH_EXPERIMENT_DISCRIMINATOR_VALUES_REQUEST,
    });

    return extra.apiInternal(getState()).experimentFormDefaults.getDiscriminatorByDiscriminatorKey({
      organization,
      discriminator_key,
    }).then((response) => {
      if (response.ok) {
        dispatch(
          standardAction(
            ActionTypes.FETCH_EXPERIMENT_DISCRIMINATOR_VALUES_SUCCESS, response.body.discriminator,
          ),
        );
        return;
      }

      const errorResponse = response as $HttpResponse<io.flow.v0.models.GenericError>;
      const error = new ApiError(errorResponse);

      dispatch({
        type: ActionTypes.FETCH_EXPERIMENT_DISCRIMINATOR_VALUES_FAILURE,
        payload: formatErrorResponseV2(errorResponse),
      });
      throw error;
    });
  };
}
