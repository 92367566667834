import { createSelector } from 'reselect';
import property from 'lodash/property';
import getMarketingState from './getMarketingState';
import { RootState } from '../../../stores/types';
import { MarketingState } from '../types';

export default createSelector<RootState, MarketingState, MarketingState['productFeeds']['marketingId']>(
  getMarketingState,
  property(['productFeeds', 'marketingId']),
);
