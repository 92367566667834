import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import ActionTypes from '../constants/action-types';
import { LabKey } from '../constants/lab-keys';
import fetchAcceptingUser from './fetch-accepting-user';

function requestAction(): RootActionTypes {
  return {
    type: ActionTypes.PUT_LAB_SETTINGS_BY_KEY_REQUEST,
  };
}

function successAction(payload: io.flow.internal.v0.models.LabProjectSettings): RootActionTypes {
  return {
    type: ActionTypes.PUT_LAB_SETTINGS_BY_KEY_SUCCESS,
    payload,
  };
}

function failureAction(payload: FormattedErrorMessages): RootActionTypes {
  return {
    type: ActionTypes.PUT_LAB_SETTINGS_BY_KEY_FAILURE,
    payload,
  };
}

function putLabSettingsByKey(
  organizationId: string,
  labKey: LabKey,
  accepted: boolean,
): ThunkResult<Promise<void>> {
  return function putLabSettingsByKeyEffects(
    dispatch,
    getState,
    extra,
  ): Promise<void> {
    const state = getState();
    dispatch(requestAction());

    return extra.apiInternal(state).flowLabsProjectSettings.putByFlowLabProjectKey({
      organization: organizationId,
      flow_lab_project_key: labKey,
      body: {
        terms: {
          accepted,
        },
      },
    }).then((response) => {
      if (response.ok) {
        if (response.body.terms) {
          dispatch(fetchAcceptingUser(response.body.terms.accepted_by.id)).then(() => {
            dispatch(successAction(response.body));
          });
        } else {
          dispatch(successAction(response.body));
        }
        return;
      }

      const errors = formatErrorResponseV2(response);
      dispatch(failureAction(errors));
    });
  };
}

export default putLabSettingsByKey;
