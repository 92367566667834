import ActionTypes from '../constants/ActionTypes';

const defaultState = [];

export default function failureSummariesReducer(state = defaultState, action) {
  switch (action.type) {
    case ActionTypes.FETCH_FAILURE_SUMMARIES_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
