import React from 'react';
import { Button, OutlineButton } from '@flowio/react-button';
import MarketingDialog from '../../../marketing/components/MarketingDialog';

interface Props {
  isOpen: boolean;
  onCloseDialog: () => void;
  onConfirm: () => void;
}

const EndExperimentDialog: React.FC<Props> = ({
  isOpen,
  onCloseDialog,
  onConfirm,
}) => (
  <MarketingDialog
    title="Are you sure you want to end this experiment?"
    subtitle="Ending this experiment will result in no further data being collected and cannot be undone."
    isOpen={isOpen}
  >
    <OutlineButton
      content="Cancel"
      onClick={onCloseDialog}
    />
    <Button
      intent="primary"
      content="End Experiment"
      onClick={onConfirm}
    />
  </MarketingDialog>
);

export default EndExperimentDialog;
