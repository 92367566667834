import React from 'react';
import { Stack } from '@flowio/react-stack';
import { Button } from '@flowio/react-button';
import {
  Dialog, DialogHeader, DialogFooter, DialogBody,
} from '@flowio/react-dialog';
import noop from 'lodash/noop';

import ExportDialogForm from '../../ExportDialogForm';
import { MergedProps } from '../types';
import * as styles from '../ExportDialog.styles';

const ExportDialog: React.FC<MergedProps> = ({
  exportError,
  isOpen,
  onRequestSubmit,
  submitted,
  submitting,
  onRequestClose: handleRequestClose,
  onResetExportState: handleResetExportState,
}) => {
  const handleDialogClose = (): void => {
    handleRequestClose();
    handleResetExportState();
  };
  return (
    <Dialog
      open={isOpen}
      backdrop
      className={styles.billingExportDialog}
      onClose={handleDialogClose}
    >
      <DialogHeader content="Export Submitted Order Value Over Time (USD)" />
      <DialogBody>
        <ExportDialogForm
          error={exportError}
        />
      </DialogBody>
      {submitted ? (
        <DialogFooter>
          <Button
            content="Got It"
            intent="primary"
            fluid
            onClick={handleDialogClose}
            size="large"
            type="button"
          />
        </DialogFooter>
      ) : (
        <DialogFooter>
          <Stack distribution="fillEqually" spacing="loose">
            <Button
              content="Cancel"
              fluid
              onClick={handleDialogClose}
              disabled={submitting}
              size="large"
              type="button"
            />
            <Button
              content="Export"
              fluid
              intent="primary"
              disabled={submitting}
              onClick={onRequestSubmit}
              size="large"
              type="submit"
            />
          </Stack>
        </DialogFooter>
      )}
    </Dialog>
  );
};

ExportDialog.displayName = 'ExportDialog';

ExportDialog.defaultProps = {
  exportError: undefined,
  isOpen: false,
  onRequestClose: noop,
  onRequestSubmit: noop,
  onResetExportState: noop,
  submitted: false,
  submitting: false,
};

export default ExportDialog;
