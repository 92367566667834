import classNames from 'classnames';
import React from 'react';
import StepGroup, { StepGroupProps } from './step-group';
import StepTitle, { StepTitleProps } from './step-title';
import * as styles from './step.styles';

interface Props {
  children?: React.ReactElement;
  className?: string;
  stepNumber?: number;
  active?: boolean;
  completed?: boolean;
  title?: string;
}

interface StepType extends React.FunctionComponent<Props> {
  Group: React.FunctionComponent<StepGroupProps>;
  Title: React.FunctionComponent<StepTitleProps>;
}

const Step: StepType = ({
  children,
  completed = false,
  title,
  stepNumber = 0,
  active = false,
  className = '',
  ...unhandledProps
}) => {
  if (children != null) {
    return (
      <div
        {...unhandledProps}
        className={classNames(
          styles.step,
          className,
          { [styles.completed]: completed },
          { [styles.active]: active },
        )}
      >
        <span className={styles.stepNumber}>{stepNumber}</span>
        {children}
      </div>
    );
  }

  return (
    <div {...unhandledProps} className={styles.step}>
      <span className={styles.stepNumber}>1</span>
      <StepTitle text={title} />
    </div>
  );
};

Step.displayName = 'Step';

Step.defaultProps = {
  children: undefined,
  stepNumber: 0,
  active: false,
  completed: false,
  title: undefined,
};

Step.Group = StepGroup;
Step.Title = StepTitle;

export default Step;
