import { Plus } from '@flowio/react-icons';
import React from 'react';
import noop from 'lodash/noop';

import { Box } from '@flowio/react-box';
import { Button } from '@flowio/react-button';
import {
  Card, CardHeader, CardContent, CardFooter, CardEmptyState,
} from '@flowio/react-card';
import {
  Page,
  PageTitle,
  PageActions,
  PageContent,
} from '@flowio/react-page';

import { css } from '@emotion/css';
import DiscountListingTable from '../../DiscountListingTable';
import Pagination from '../../../../../components/pagination';
import DiscountListingFilterForm from '../../DiscountListingFilterForm/containers/DiscountListingFilterForm';
import { MergedProps as Props } from '../types';

const addDiscountBtnStyles = css({
  marginTop: '8px',
});

const pageHeaderStyles = css`
  margin-left: 17px;
  margin-right: 17px;
  margin-top: 16px;
`;

const cardContentStyles = css`
  padding-right: 0px;
  padding-left: 0px;
`;

export default class DiscountListing extends React.PureComponent<Props> {
  onRequestNextPage = (): void => {
    const {
      pageNumber,
      onFetchNewPage = noop,
    } = this.props;
    const pageToUse = pageNumber || 1;

    onFetchNewPage(pageToUse + 1);
  };

  onRequestPreviousPage = (): void => {
    const {
      pageNumber,
      onFetchNewPage = noop,
    } = this.props;

    const pageToUse = pageNumber || 1;

    if (pageToUse > 1) {
      onFetchNewPage(pageToUse - 1);
    }
  };

  render(): React.ReactElement {
    const {
      organization,
      addDiscount,
      discounts,
      experiences,
      pageNumber,
      isFirstPage,
      isLastPage,
      onRequestDiscountFilterChange,
      onDisableDiscount,
      onEnableDiscount,
      onEdit,
      onDelete,
    } = this.props;

    return (
      <Page>
        <Box justifyContent="between" className={pageHeaderStyles}>
          <PageTitle content="Discounts" />
          <PageActions>
            <Button className={addDiscountBtnStyles} leftIcon={Plus} content="Add Discount" intent="primary" onClick={(): void => addDiscount()} />
          </PageActions>
        </Box>
        <PageContent>
          <Card>
            <CardHeader dividing>
              <DiscountListingFilterForm
                onRequestDiscountFilterChange={onRequestDiscountFilterChange}
              />
            </CardHeader>
            <CardContent className={cardContentStyles}>
              {discounts.length > 0 && (
                <DiscountListingTable
                  organization={organization}
                  experiences={experiences}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  onDisableDiscount={onDisableDiscount}
                  onEnableDiscount={onEnableDiscount}
                  discounts={discounts}
                />
              )}
              {discounts.length < 1 && (
                <CardEmptyState content="No Discounts found" />
              )}
            </CardContent>
            <CardFooter dividing>
              <Pagination
                pageNumber={pageNumber}
                isNextPageDisabled={isLastPage}
                isPreviousPageDisabled={isFirstPage}
                onRequestNextPage={this.onRequestNextPage}
                onRequestPreviousPage={this.onRequestPreviousPage}
              />
            </CardFooter>
          </Card>
        </PageContent>
      </Page>
    );
  }
}
