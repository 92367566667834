import { combineReducers } from 'redux';
import experience from './experience';
import featureValues from './featureValues';
import toasts from './toasts';
import redirect from './redirect';
import tokens from './tokens';
import user from './user';

export default combineReducers({
  experience,
  featureValues,
  redirect,
  toasts,
  tokens,
  user,
});
