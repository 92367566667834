import { css } from '@emotion/css';
import colors from '../../../../theme/tokens/colors';

export const feedItem = css`
  & table {
    margin-top: 5px;
  }

  & .feedGenerating {
    font-style: italic;
    margin: auto;
    color: ${colors.marketing['light-slate-gray']};
  }
`;

export const feedCountry = css`
  & .feedCountryName {
    padding-left: 10px;
  }

  & .infoIcon {
    fill: ${colors.marketing['dark-slate-blue']};
    margin-left: 4px;
  }
`;

export const green = css`
  color: ${colors.chart.green};
`;
