import { TriangleUp, TriangleDown, Close } from '@flowio/react-icons';
import React from 'react';
import get from 'lodash/get';
import map from 'lodash/map';
import find from 'lodash/find';
import head from 'lodash/head';
import noop from 'lodash/noop';
import clone from 'lodash/clone';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';
import classnames from 'classnames/bind';

import { Box } from '@flowio/react-box';
import {
  Table,
  TableHead,
  TableRow, TableRowColumn,
  TableHeadColumn,
  TableBody,
} from '@flowio/react-table';
import { Button, IconButton } from '@flowio/react-button';

import QueryFilterToken from '../../../query-builder/components/QueryFilterToken';
import QueryFilterTokenStyles from '../../../query-builder/components/QueryFilterToken/QueryFilterToken.module.css';
import { AvailabilityRuleListProps } from '../../types/components';

const cx = classnames.bind(QueryFilterTokenStyles);

class AvailabilityRuleList extends React.PureComponent<AvailabilityRuleListProps> {
  static displayName = 'AvailabilityRuleList';

  static defaultProps = {
    onDeleteRuleClick: noop,
    onEditRuleClick: noop,
    onRuleChange: noop,
    ruleFilters: [],
  };

  constructor(props: AvailabilityRuleListProps, context: any) {
    super(props, context);
    this.handlePromoteRule = this.handlePromoteRule.bind(this);
    this.handleDemoteRule = this.handleDemoteRule.bind(this);
  }

  handlePromoteRule(ruleId: string) {
    const {
      rules,
      onRuleChange = noop,
    } = this.props;
    const copy = map(rules, clone);

    const promoteRuleIndex = findIndex(copy, { id: ruleId });

    // already highest rule
    if (promoteRuleIndex <= 0) return;

    const promotedRule = head(copy.splice(promoteRuleIndex, 1));
    // Do to the if statement we know promoted rule exists
    copy.splice(promoteRuleIndex - 1, 0, promotedRule!);
    onRuleChange(copy);
  }

  handleDemoteRule(ruleId: string) {
    const {
      rules,
      onRuleChange = noop,
    } = this.props;
    const copy = map(rules, clone);

    const demoteRuleIndex = findIndex(copy, { id: ruleId });

    // already lowest rule
    if (demoteRuleIndex >= copy.length - 1) return;

    const demotedRule = head(copy.splice(demoteRuleIndex, 1));
    // Again due to the above if statement we know it exists
    copy.splice(demoteRuleIndex + 1, 0, demotedRule!);
    onRuleChange(copy);
  }

  render() {
    const {
      onDeleteRuleClick = noop,
      onEditRuleClick = noop,
      rules,
      ruleFilters,
    } = this.props;

    return (
      <Table displayDensity="compact" striped>
        <TableHead>
          <TableRow>
            <TableHeadColumn>Priority</TableHeadColumn>
            <TableHeadColumn>Conditions</TableHeadColumn>
            <TableHeadColumn>Values</TableHeadColumn>
            <TableHeadColumn />
          </TableRow>
        </TableHead>
        <TableBody>
          {map(rules, (rule, index) => {
            const upDisabled = (index <= 0);
            const downDisabled = (index >= rules.length - 1);
            const ruleFilter = find(ruleFilters, (filter) => filter.id === rule.id);
            const filters = get(ruleFilter, 'filters', []);

            return (
              <TableRow key={rule.id}>
                <TableRowColumn>
                  <Box alignItems="center" justifyContent="between" style={{ maxWidth: '5rem' }}>
                    <IconButton
                      disabled={upDisabled}
                      icon={TriangleUp}
                      onClick={() => this.handlePromoteRule(rule.id)}
                      size="small"
                    />
                    <span>{rule.position}</span>
                    <IconButton
                      disabled={downDisabled}
                      icon={TriangleDown}
                      size="small"
                      onClick={() => this.handleDemoteRule(rule.id)}
                    />
                  </Box>
                </TableRowColumn>
                <TableRowColumn>
                  {isEmpty(filters) && (
                    <div className={cx('token')}>
                      <span className={cx('values')}>{rule.query}</span>
                    </div>
                  )}
                  {!isEmpty(filters) && (
                    map(filters, (filter, key) => (
                      <QueryFilterToken
                        key={key}
                        className=""
                        queryFilter={filter}
                      />
                    ))
                  )}
                </TableRowColumn>
                <TableRowColumn>{String(rule.value)}</TableRowColumn>
                <TableRowColumn>
                  <Box spacing="loose" justifyContent="end">
                    <Button
                      content="Edit"
                      onClick={() => onEditRuleClick(rule)}
                      size="small"
                    />
                    <IconButton
                      icon={Close}
                      variant="outline"
                      intent="negative"
                      size="small"
                      onClick={() => onDeleteRuleClick(rule.id)}
                    />
                  </Box>
                </TableRowColumn>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }
}

export default AvailabilityRuleList;
