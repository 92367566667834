import {
  Field,
  FieldArray,
  Form,
  InjectedFormProps,
} from 'redux-form';
import {
  Card, CardHeader, CardContent, CardFooter, CardTitle,
} from '@flowio/react-card';
import React from 'react';
import { css } from '@emotion/css';
import QueryFilterList from './QueryFilterList';

import GenericError from '../../../../components/GenericError';
import { ReturnPolicyFormProps, QueryFilterListOwnProps, ReturnPolicyFormValues } from '../../types';

const genericErrorStyles = css`
  margin-bottom: 16px;
`;

const ReturnPolicyForm: React.FC<
ReturnPolicyFormProps & InjectedFormProps<ReturnPolicyFormValues, ReturnPolicyFormProps>
> = ({
  availableFilters,
  error,
  submissionApiError,
  handleSubmit,
}) => (
  <Form onSubmit={handleSubmit}>
    <Field component="input" type="hidden" name="returnPolicyId" />
    <Field component="input" type="hidden" name="organizationId" />
    {error && (
      <GenericError className={genericErrorStyles} error={error} />
    )}
    {submissionApiError && (
      <GenericError className={genericErrorStyles} error={submissionApiError} />
    )}
    <Card>
      <CardHeader dividing>
        <CardTitle>
          Regions
        </CardTitle>
      </CardHeader>
      <CardContent subdued>
        Non-Returnable rules are applied to the world (All regions and countries).
      </CardContent>
      <CardFooter dividing />
    </Card>
    <FieldArray<QueryFilterListOwnProps>
      component={QueryFilterList}
      name="filters"
      availableFilters={availableFilters}
    />
  </Form>
);

ReturnPolicyForm.displayName = 'ReturnPolicyForm';

ReturnPolicyForm.defaultProps = {
  error: undefined,
  submissionApiError: undefined,
};

export default ReturnPolicyForm;
