const colors = {
  white: '#FFFFFF',
  black: '#000000',
  transparent: 'transparent',
  flowBlack: '#262832',
  mercury: '#e4e7eb',
  concrete: '#f2f5f7',
  charcoal: '#4d5866',
  steel: '#77818f',
  silver: '#b5bec9',
  aluminium: '#9ba6b3',
  paleGray: '#dbdee8',
  palerGray: '#eff1f5',
  mutedFlowBlue: '#e6f2ff',
  skyFlowBlue: '#0378ff',
  brightRed: '#ff75b',
  marketing: {
    'dark-slate-blue': '#334681',
    'light-slate-gray': '#7883a2',
    positive: '#3DCF91',
  },
  chart: {
    blue: '#35c2f5',
    yellow: '#e6b137',
    green: '#128a00',
    orange: '#b4810b',
    red: '#921d00',
    lavender: '#8769bd',
    lineChartColorScale: [
      '#37A9DD', '#6CAB35', '#CFBD21',
      '#D57C2C', '#C24942', '#8769BD', '#247093', '#487223',
      '#A19100', '#8E531D', '#82302C', '#5A467E', '#53CEF0',
      '#95D050', '#E8DD34', '#ECA644', '#E06B62', '#B091DD',
    ],
  },
  neutral: {
    100: '#FAFBFC',
    200: '#F1F5F9',
    300: '#E8EEF6',
    400: '#D2DDEA',
    500: '#B8C6D6',
    600: '#9AA7B8',
    700: '#69788A',
    800: '#464D56',
    900: '#14171A',
  },
  'flow-blue': {
    100: '#e1efff',
    200: '#a4cbfc',
    300: '#3283e6',
    400: '#0051b2',
    500: '#0c2d53',
  },
  blue: {
    100: '#F9FAFF',
    200: '#D5E1FF',
    300: '#B1C9FF',
    400: '#709EFC',
    500: '#3C7EEE',
    600: '#1766D6',
    700: '#0051B2',
    800: '#05356F',
    900: '#041326',
  },
  red: {
    100: '#fAE4dE',
    200: '#FCD9CD',
    300: '#D2431F',
    400: '#EE8867',
    500: '#DB5C35',
    600: '#BE3B14',
    700: '#952303',
    800: '#601400',
    900: '#260800',
  },
  green: {
    100: '#DAFDD4',
    200: '#DAFDD4',
    300: '#128A00',
    400: '#0F5E03',
    500: '#0F5E03',
    600: '#4DB87A',
    700: '#338D5D',
    800: '#1C5638',
    900: '#081A11',
  },
  yellow: {
    100: '#FFF7E5',
    200: '#FFE7B0',
    300: '#DEB250',
    400: '#B4810B',
    500: '#5E4200',
    600: '#EDA116',
    700: '#D58C00',
    800: '#986500',
    900: '#261A00',
  },
  gray: {
    100: '#fafbfc',
    200: '#f2f5f7',
    300: '#e4e7eb',
    400: '#d8dde3',
    500: '#b5bec9',
    600: '#9ba6b3',
    700: '#77818f',
    800: '#4d5866',
    900: '#17181a',
  },
  'sky-blue': {
    100: '#e8f9ff',
    200: '#aae9ff',
    300: '#35c2f5',
    400: '#087aa3',
    500: '#134658',
  },
  purple: {
    100: '#ecdeff',
    200: '#c194ff',
    300: '#7b35de',
    400: '#411480',
    500: '#210b40',
  },
};

export default colors;
