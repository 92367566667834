import update from 'immutability-helper';
import { RootActionTypes } from '../../../stores/types';
import { ActionTypes } from '../../experience';
import { ExperiencesState } from '../types';

const defaultState: ExperiencesState = {
  results: [],
  isFirstPage: true,
  isLastPage: true,
};

export default function experiencesReducer(
  state = defaultState,
  action: RootActionTypes,
): ExperiencesState {
  switch (action.type) {
    case ActionTypes.FETCH_EXPERIENCES_SUCCESS:
      return update(state, { $set: action.payload });
    default:
      return state;
  }
}
