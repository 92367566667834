import * as d3 from 'd3';
import React from 'react';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import moment from 'moment';
import numeral from 'numeral';
import { ReportInterval } from '@flowio/api-internal-constants';
import { interpolatePath } from 'd3-interpolate-path';

import generateColorScale from '../../../../utilities/generate-color-scale';
import getXPosition from '../../../../utilities/charting/get-x-position';
import createXScale from '../../../../utilities/charting/create-x-scale';
import createYScale from '../../../../utilities/charting/create-y-scale';
import createCrosshairs from '../../../../utilities/charting/create-crosshair-tooltips';
import createAnalyticsTooltipHtml from '../../../../utilities/charting/create-analytics-tooltip-html';
import * as styles from './TimelineChart.styles';

interface LineData {
  timestamp: string;
  value: number;
  key: string;
}

interface XScale extends d3.AxisScale<string> {
  (d: string): number;
}

interface YScale extends d3.AxisScale<number> {
  (d: number): number;
}

let tooltip: d3.Selection<HTMLDivElement, unknown, HTMLElement, unknown>;

const height = 350;
const width = 1100;
const marginLeft = 40;
const marginBtm = 50;
const chartPadding = 10;
const xAxisTickAmount = 20;
const colorScale = generateColorScale();
const chartAttrs = {
  height,
  width,
  marginLeft,
  marginBtm,
  chartPadding,
};

const area = (xScale: XScale, yScale: YScale): d3.Area<LineData> => d3.area<LineData>()
  .curve(d3.curveMonotoneX)
  .x((d) => xScale(d.timestamp))
  .y0((d) => yScale(d.value))
  .y1(yScale(0));

const baseArea = (xScale: XScale, yScale: YScale): d3.Area<LineData> => d3.area<LineData>()
  .curve(d3.curveMonotoneX)
  .x((d) => xScale(d.timestamp))
  .y0(yScale(0))
  .y1(yScale(0));

const betweenArea = (
  xScale: XScale,
  yScale: YScale,
  lineData: LineData[],
): d3.Area<LineData> => d3.area<LineData>()
  .curve(d3.curveMonotoneX)
  .x((d) => xScale(d.timestamp))
  .y0(yScale(lineData[0].value))
  .y1(yScale(0));

interface Props {
  endDate: string;
  interval: ReportInterval;
  regionName: string;
  valueKey: string;
  colorIndex: number;
  timeline: io.flow.internal.v0.models.OrderRevenueTimelineChart;
  organizationCountry: string;
  organizationCurrency: string;
}

export default class TimelineChart extends React.Component<Props> {
  static displayName = 'TimelineChart';

  static defaultProps = {
    valueKey: 'revenue.amount',
    colorIndex: 0,
  };

  componentDidMount(): void {
    if (d3.select(`.${styles.resultsTooltip}`).empty()) {
      tooltip = d3.select('body')
        .append('div')
        .attr('class', styles.resultsTooltip);
    } else {
      tooltip = d3.select(`.${styles.resultsTooltip}`);
    }

    this.createResultsChart();
  }

  componentDidUpdate(): void {
    this.updateResultsChart();
  }

  componentWillUnmount(): void {
    // Need to remove the tooltip, it might stick around if the chart is unmounted
    // This happens when the date range changes and there is no data
    d3.select(`.${styles.resultsTooltip}`).remove();
  }

  getTickValues = (
    xScale: XScale,
    modValue: number,
    interval: ReportInterval,
  ): string[] => {
    if (interval === ReportInterval.HOURLY) {
      const formattedDates = xScale.domain().filter((date) => date).map((date) => moment(date).format('MM/DD/YY'));
      const uniqDates = uniq(formattedDates);

      // Need to remove the first entry since there
      // is not enough space between it and the second point
      return uniqDates.filter((_date, index) => index > 0).map((date) => moment(date, 'MM/DD/YY').toISOString());
    }
    return xScale.domain().filter((_d, i) => !(i % modValue));
  };

  // Make these their own utilities functions
  createYAxis = (yScale: YScale): d3.Axis<number> => d3.axisLeft(yScale)
    .tickFormat((count) => numeral(count).format('0.[00]a'))
    .tickArguments([10])
    .tickSize(0)
    .tickPadding(10);

  createXAxis = (
    xScale: XScale,
    modValue: number,
    interval: ReportInterval,
  ): d3.Axis<string> => d3.axisBottom(xScale)
    .tickFormat(date => date.length > 0 ? moment(date).utc().format('MM/DD/YY') : '') // eslint-disable-line
    .tickSize(0)
    .tickPadding(10)
    .tickValues(this.getTickValues(xScale, modValue, interval));

  formatLineData = (
    results: io.flow.internal.v0.models.OrderRevenueTimelineChart,
    valueKey: string,
    key: string,
  ): LineData[] => results.data.map((d) => ({
    timestamp: d.timestamp,
    value: get(d, valueKey),
    key,
  }));

  createYGrid = (svg: d3.Selection<SVGSVGElement, unknown, HTMLElement, unknown>): void => {
    svg.selectAll('.yAxis')
      .selectAll('.tick')
      .append('line')
      .attr('class', 'yAxis-grid')
      .attr('x1', 1)
      .attr('y1', 0)
      .attr('x2', width)
      .attr('y2', 0)
      .attr('stroke-width', 1)
      .attr('stroke', '#F2F2F2');
  };

  createBaseline = (initialY: number, dataLength: number): string => {
    const xPosition = getXPosition(dataLength, dataLength - 1, chartAttrs);
    return `M0,${initialY}C${xPosition},${initialY},${xPosition},${initialY},${xPosition},${initialY}`;
  };

  updateResultsChart(): void {
    const {
      endDate,
      timeline,
      valueKey,
      regionName,
      interval,
      colorIndex,
      organizationCountry,
      organizationCurrency,
    } = this.props;

    const modValue = Math.ceil(timeline.data.length / xAxisTickAmount);
    const yScale = createYScale(timeline.data, valueKey, chartAttrs);
    const yAxis = this.createYAxis(yScale);
    const svg = d3.select('#analyticsResultChart').select<SVGSVGElement>('svg');

    const xScale = createXScale<io.flow.internal.v0.models.OrderRevenueTimelineDataPoint>(timeline.data, 'timestamp', chartAttrs);
    const xAxis = this.createXAxis(xScale, modValue, interval);

    const line = d3.line<LineData>()
      .curve(d3.curveMonotoneX)
      .x((d) => xScale(d.timestamp))
      .y((d) => yScale(d.value));

    const lineData = sortBy<LineData>(this.formatLineData(timeline, valueKey, valueKey), 'timestamp');

    const oldPaths: string[] = [];
    svg.selectAll('.data-line')
      .each((_datum, index, nodeList) => {
        oldPaths.push(d3.select(nodeList[index]).attr('d'));
      });

    const currentLines = svg.selectAll<SVGPathElement, LineData>('.data-line')
      .data([lineData]);

    const currentCircles = svg.selectAll<SVGCircleElement, unknown>('.data-circle')
      .data(lineData);

    const currentAreas = svg.selectAll<SVGPathElement, LineData>('.data-area')
      .data([lineData]);

    svg.selectAll('.crosshair-box')
      .remove();

    svg.selectAll('path.crosshair')
      .remove();

    svg.selectAll<SVGSVGElement, string>('.xAxis')
      .call(xAxis);

    svg.selectAll('.xAxis')
      .selectAll('.domain')
      .attr('d', `M0,0L${width},0`);

    svg.selectAll<SVGSVGElement, number>('.yAxis')
      .transition()
      .duration(500)
      .call(yAxis);

    svg.selectAll('.domain')
      .attr('stroke-opacity', 0.5)
      .attr('stroke', '#808080');

    svg.selectAll('.tick')
      .selectAll('text')
      .attr('fill', '#808080');

    svg.selectAll('defs')
      .remove();

    const areaGradient = svg.append('defs')
      .append('linearGradient')
      .attr('id', 'areaGradient')
      .attr('x1', '0%')
      .attr('y1', '15%')
      .attr('x2', '0%')
      .attr('y2', '100%');

    areaGradient.append('stop')
      .attr('offset', '15%')
      .attr('stop-color', colorScale(colorIndex));
    areaGradient.append('stop')
      .attr('offset', '100%')
      .attr('stop-color', 'white');

    this.createYGrid(svg);

    currentAreas.enter()
      .append('path')
      .attr('class', (_d, index) => `data-area data-area-${index}`)
      .merge(currentAreas)
      .attr('fill', 'url(#areaGradient)')
      .attr('opacity', 0.2)
      .transition()
      .delay(250)
      .duration(500)
      .ease(d3.easeExp)
      .attrTween('d', (d, index) => {
        const oldArea = svg.select(`.data-area-${index}`).attr('d');
        return interpolatePath(oldArea, area(xScale, yScale)(d) as string);
      });

    currentAreas.exit().remove();

    currentLines.enter()
      .append('path')
      .attr('class', (_d, index) => `data-line data-line-${index}`)
      .merge(currentLines)
      .transition()
      .duration(1000)
      .ease(d3.easeExp)
      .attrTween('d', (d, index) => {
        const newLine = line(d);
        return interpolatePath(oldPaths[index], newLine as string);
      })
      .attr('stroke', colorScale(colorIndex))
      .attr('transform', `translate(${marginLeft},0)`);

    currentLines.exit().remove();

    if (interval !== ReportInterval.HOURLY) {
      currentCircles.enter()
        .append('circle')
        .attr('class', (_d, index) => `data-circle data-circle-${index}`)
        .merge(currentCircles)
        .attr('fill', colorScale(colorIndex))
        .attr('r', '4')
        .transition()
        .delay(500)
        .duration(500)
        .ease(d3.easeExp)
        .attrTween('transform', (d, index) => {
          const oldTransform = svg.select(`.data-circle-${index}`).attr('transform');
          const translateString = `translate(${marginLeft + xScale(d.timestamp)}, ${yScale(d.value)})`;
          return d3.interpolateTransformSvg(oldTransform, translateString);
        });

      currentCircles.exit().remove();
    }

    const metricName = valueKey === 'revenue.amount' ? 'GMV' : '# of Orders';
    const tooltipHtml = timeline.data.map(
      (res, index) => {
        const isLast = index === timeline.data.length - 1;
        return createAnalyticsTooltipHtml(
          res,
          styles,
          valueKey,
          metricName,
          regionName,
          interval,
          colorIndex,
          endDate,
          isLast,
          organizationCountry,
          organizationCurrency,
        );
      },
    );

    createCrosshairs(timeline.data, svg, tooltip, chartAttrs, tooltipHtml, 190);
  }

  createResultsChart(): void {
    const {
      timeline,
      valueKey,
      regionName,
      interval,
      colorIndex,
      endDate,
      organizationCountry,
      organizationCurrency,
    } = this.props;
    const modValue = Math.ceil(timeline.data.length / xAxisTickAmount);
    const yScale = createYScale<io.flow.internal.v0.models.OrderRevenueTimelineDataPoint>(
      timeline.data,
      valueKey,
      chartAttrs,
    );
    const yAxis = this.createYAxis(yScale);

    const xScale = createXScale(timeline.data, 'timestamp', chartAttrs);
    const xAxis = this.createXAxis(xScale, modValue, interval);

    const line = d3.line<LineData>()
      .curve(d3.curveMonotoneX)
      .x((d) => xScale(d.timestamp))
      .y((d) => yScale(d.value));

    const lineData = sortBy<LineData>(this.formatLineData(timeline, valueKey, valueKey), 'timestamp');

    const svg = d3.select<SVGSVGElement, unknown>('#analyticsResultChart')
      .append('svg')
      .attr('style', 'padding-top: 5px')
      .attr('height', `${height + marginBtm}`)
      .attr('width', `${width + marginLeft}`);

    const areaGradient = svg.append('defs')
      .append('linearGradient')
      .attr('id', 'areaGradient')
      .attr('x1', '0%')
      .attr('y1', '15%')
      .attr('x2', '0%')
      .attr('y2', '100%');

    areaGradient.append('stop')
      .attr('offset', '15%')
      .attr('stop-color', colorScale(colorIndex));
    areaGradient.append('stop')
      .attr('offset', '100%')
      .attr('stop-color', 'white');

    svg.append('g')
      .attr('transform', `translate(${marginLeft}, 0)`)
      .attr('class', 'yAxis')
      .call(yAxis);

    this.createYGrid(svg);

    svg.append('g')
      .attr('transform', `translate(${marginLeft}, ${height})`)
      .attr('class', 'xAxis')
      .call(xAxis);

    svg.selectAll('.xAxis')
      .selectAll('.domain')
      .attr('d', `M0,0L${width},0`);

    svg.selectAll('.domain')
      .attr('stroke-opacity', 0.5)
      .attr('stroke', '#808080');

    svg.selectAll('.tick')
      .selectAll('text')
      .attr('fill', '#808080');

    svg.append('g')
      .attr('class', 'data-area-container')
      .attr('transform', `translate(${marginLeft},0)`)
      .selectAll('path')
      .data([lineData])
      .enter()
      .append('path')
      .attr('class', (_d, index) => `data-area data-area-${index}`)
      .attr('d', baseArea(xScale, yScale))
      .style('fill', 'url(#areaGradient)')
      .attr('opacity', 0.2)
      .transition()
      .duration(500)
      .ease(d3.easeExp)
      .attr('d', betweenArea(xScale, yScale, lineData))
      .transition()
      .duration(500)
      .ease(d3.easeExp)
      .attr('d', area(xScale, yScale));

    svg.append('g').selectAll('path')
      .data([lineData])
      .enter()
      .append('path')
      .attr('class', (_d, index) => `data-line data-line-${index}`)
      .attr('d', (d) => this.createBaseline(height, (line(d) as string).split('C').length))
      .attr('fill', 'none')
      .attr('stroke', colorScale(colorIndex))
      .attr('stroke-width', 3)
      .attr('stroke-opacity', 1)
      .attr('transform', `translate(${marginLeft})`)
      .transition()
      .duration(500)
      .ease(d3.easeExp)
      .attr('d', (d) => this.createBaseline(yScale(d[0].value), (line(d) as string).split('C').length))
      .transition()
      .duration(500)
      .ease(d3.easeExp)
      .attrTween('d', (d, index) => {
        const previous = svg.select(`.data-line-${index}`).attr('d');
        return interpolatePath(previous, (line(d) as string));
      })
      .attr('transform', `translate(${marginLeft})`)
      .style('pointer-events', 'none');

    if (interval !== ReportInterval.HOURLY) {
      svg.append('g')
        .attr('class', 'data-circle-container')
        .selectAll('circle')
        .data(lineData)
        .enter()
        .append('circle')
        .attr('class', (_d, index) => `data-circle data-circle-${index}`)
        .attr('fill', colorScale(colorIndex))
        .attr('r', '4')
        .attr('transform', (d) => `translate(${marginLeft + xScale(d.timestamp)}, ${yScale(0)})`)
        .transition()
        .duration(500)
        .ease(d3.easeExp)
        .attr('transform', (d) => `translate(${marginLeft + xScale(d.timestamp)}, ${yScale(lineData[0].value)})`)
        .transition()
        .duration(500)
        .ease(d3.easeExp)
        .attr('transform', (d) => {
          const x = xScale(d.timestamp);
          const y = yScale(d.value);

          return `translate(${marginLeft + x}, ${y})`;
        });
    }

    const metricName = valueKey === 'revenue.amount' ? 'GMV' : '# of Orders';
    const tooltipHtml = timeline.data.map(
      (res, index) => {
        const isLast = index === timeline.data.length - 1;

        return createAnalyticsTooltipHtml(
          res,
          styles,
          valueKey,
          metricName,
          regionName,
          interval,
          colorIndex,
          endDate,
          isLast,
          organizationCountry,
          organizationCurrency,
        );
      },
    );

    createCrosshairs(timeline.data, svg, tooltip, chartAttrs, tooltipHtml, 190);
  }

  render(): JSX.Element {
    return (
      <div className={styles.chartContainer} id="analyticsResultChart" />
    );
  }
}
