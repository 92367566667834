import { WrappedFieldProps } from 'redux-form';
import { Box } from '@flowio/react-box';
import { RadioButton } from '@flowio/react-radio-button';
import React from 'react';
import classNames from 'classnames';
import ConsentType from '../../../constants/ConsentType';
import * as styles from './OptinConsentTypeOptions.styles';

const OptinConsentTypeOptions: React.FC<WrappedFieldProps> = ({
  input,
  meta,
}) => {
  const isExplicitSelected = input.value === ConsentType.EXPLICIT;
  const isImplicitSelected = input.value === ConsentType.IMPLICIT;
  return (
    <div>
      <Box spacing="loose">
        <RadioButton
          checked={isExplicitSelected}
          className={classNames(styles.explicit, { selected: isExplicitSelected })}
          labelText="Explicit"
          onBlur={input.onBlur}
          onChange={input.onChange}
          onFocus={input.onFocus}
          name={input.name}
          value={ConsentType.EXPLICIT}
        />
        <RadioButton
          checked={isImplicitSelected}
          className={classNames(styles.implicit, { selected: isImplicitSelected })}
          labelText="Implicit"
          onBlur={input.onBlur}
          onChange={input.onChange}
          onFocus={input.onFocus}
          name={input.name}
          value={ConsentType.IMPLICIT}
        />
      </Box>
      {isExplicitSelected && (
        <p className={styles.helpText}>
          Customers need to click &ldquo;Accept&rdquo; button for cookies
          to be set.
        </p>
      )}
      {isImplicitSelected && (
        <p className={styles.helpText}>
          Inform customers that cookies are set automatically.
        </p>
      )}
      {meta.touched && meta.error && (
        <p>
          {meta.error}
        </p>
      )}
    </div>
  );
};

OptinConsentTypeOptions.displayName = 'OptinConsentTypeOptions';

export default OptinConsentTypeOptions;
