import { RootActionTypes, ThunkResult } from '../../../stores/types';
import ActionTypes from '../constants/ActionTypes';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import { DiscountFetchParams } from '../types';

function fetchDiscountsRequestAction(): RootActionTypes {
  return {
    type: ActionTypes.FETCH_DISCOUNTS_REQUEST,
  };
}

function fetchDiscountsSuccessAction(
  payload: io.flow.v0.models.DiscountRuleSettings[],
): RootActionTypes {
  return {
    type: ActionTypes.FETCH_DISCOUNTS_SUCCESS,
    payload,
  };
}

function fetchDiscountsFailureAction(payload: FormattedErrorMessages): RootActionTypes {
  return {
    type: ActionTypes.FETCH_DISCOUNTS_FAILURE,
    error: true,
    payload,
  };
}

export default function fetchDiscounts(
  organization: string,
  params: DiscountFetchParams,
): ThunkResult<Promise<void>> {
  return function fetchDiscountsEffects(
    dispatch,
    getState,
    extra,
  ): Promise<void> {
    const state = getState();
    dispatch(fetchDiscountsRequestAction());

    return extra.api(state).discountRuleSettings.get({
      organization,
      ...params,
    }).then((response) => {
      if (response.ok) {
        dispatch(fetchDiscountsSuccessAction(response.body));
        return;
      }

      dispatch(fetchDiscountsFailureAction(formatErrorResponseV2(response)));
    });
  };
}
