import { $HttpResponse } from '@flowio/api-sdk';
import { format } from './sort';
import { WithPagingAndSortingResponse } from './types';

/**
 * Use this utility when calling a Flow API resource with paging and sorting enabled from your async
 * action creators. It follows an agreed upon convention for determining the state of pagination
 * since the API responses do not include any at the moment.
 * @param {String} [options.entriesPerPage = 25] A string representing the number of results per
 * request.
 * @param {String} [options.pageNumber = 1] A string representing the page number
 * @param {String} [options.sort] A string representing the property by which the results
 * should be sorted.
 * @param {String} [options.sortOrder] A string representing in which direction the results should
 * be sorted. Should be one of `"ascending"` or `"descending"`.
 * @param {Function} callback A function executed with the computed params to be sent over with the
 * request and all arguments passed to the returned function when called. It MUST return a promise
 * that is settled after the request is completed.
 * @param {Boolean} merge For covenience, other properties in `options` will be merged with the
 * `params` object passed into your `callback` to reduce the need for your `callback` to deal with
 * that when other query parameters, unrelated to paging and sorting, are passed in. If you want to
 * disable this feature just set the `merge` parameter to `false`.
 * @returns {Function}
 */

export interface WithPagingAndSortingOptions {
  entriesPerPage?: string | number;
  pageNumber?: number | string;
  sort?: string;
  sortOrder?: string;
  [key: string]: any;
}

interface RequestParams {
  limit: number;
  offset: number;
  sort: string;
}

export function isPagingAndSortingResponse<T>(
  response: WithPagingAndSortingResponse<T> | $HttpResponse,
): response is WithPagingAndSortingResponse<T> {
  return (response as WithPagingAndSortingResponse<T>).result !== undefined;
}

export default function withPagingAndSorting<T>(
  options: WithPagingAndSortingOptions = {},
  callback: (...arg: any[]) => Promise<$HttpResponse>,
  merge = true,
) {
  return (...args: any[]): Promise<WithPagingAndSortingResponse<T> | $HttpResponse> => {
    const {
      entriesPerPage, pageNumber, sort, sortOrder, ...otherParams
    } = options;

    let params: RequestParams = { limit: 25, offset: 0, sort: '' };

    if (merge) {
      params = { ...params, ...otherParams };
    }

    if (entriesPerPage) {
      params.limit = typeof entriesPerPage === 'string' ? parseInt(entriesPerPage, 10) : entriesPerPage;
    }

    if (pageNumber) {
      const parsedPageNumber = (typeof pageNumber === 'string' ? parseInt(pageNumber, 10) : pageNumber);
      params.offset = (parsedPageNumber - 1) * params.limit;
    }

    if (sort && sortOrder) {
      params.sort = format({ value: sort, direction: sortOrder });
    }

    // By requesting an additional item as part of the request we can determine whether the user
    // has reached the last page. Later we just need to make sure we are not sending more items
    // than requested.
    params.limit += 1;

    return callback(params, ...args).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        const result = {
          results: response.body,
          isFirstPage: false,
          isLastPage: false,
        };

        result.isFirstPage = params.offset === 0;
        result.isLastPage = result.results.length !== params.limit;

        if (!result.isLastPage) {
          result.results = result.results.slice(0, result.results.length - 1);
        }

        return { ok: true, status: response.status, result };
      }

      return response;
    });
  };
}
