import { css } from '@emotion/css';

export const invoiceDownload = css`
  color: #4d5866 !important;
  &:hover {
    text-decoration: none;
  }
  & a {
    text-decoration: none;
    color: inherit;
  }
`;

export const downloadButton = css`
  text-align: right;
`;

export const downloadIcon = css`
  color: #4d5866;
  width: 8px !important;
`;
