import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import { Checkbox } from '@flowio/react-checkbox';

type CheckboxProps = typeof Checkbox;
type ReduxFormCheckboxProps = WrappedFieldProps & CheckboxProps;

const ReduxFormCheckbox: React.FC<ReduxFormCheckboxProps> = ({
  input, meta, ...unhandledProps
}) => (
  <Checkbox
    {...unhandledProps}
    checked={input.checked}
    name={input.name}
    onBlur={input.onBlur}
    onChange={input.onChange}
    onFocus={input.onFocus}
    value={input.value.toString()}
  />
);

export default ReduxFormCheckbox;
