import { $HttpResponse } from '@flowio/api-sdk';
import ActionTypes from '../constants/action-types';
import {
  RootState, ThunkExtraArguments, ThunkResult, ThunkDispatcher,
} from '../../../stores/types';
import { ApiError } from '../../../utilities/clients/api-error';
import standardAction from '../../../utilities/standard-action';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';

export default function fetchExperimentFormDefaults(
  organization: string,
  key: string,
): ThunkResult<Promise<void>> {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<void> => {
    dispatch({
      type: ActionTypes.FETCH_EXPERIMENT_FORM_DEFAULTS_REQUEST,
    });

    return extra.apiInternal(getState()).experimentFormDefaults.getByExperimentKey({
      organization,
      experiment_key: key,
    }).then((response) => {
      if (response.ok) {
        dispatch(
          standardAction(ActionTypes.FETCH_EXPERIMENT_FORM_DEFAULTS_SUCCESS, response.body),
        );
        return;
      }

      const errorResponse = response as $HttpResponse<io.flow.v0.models.GenericError>;

      const error = new ApiError(
        errorResponse as $HttpResponse<io.flow.v0.models.GenericError>,
      );

      dispatch({
        type: ActionTypes.FETCH_EXPERIMENT_FORM_DEFAULTS_FAILURE,
        payload: formatErrorResponseV2(errorResponse),
      });
      throw error;
    });
  };
}
