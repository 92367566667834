import { ErrorResponse, isError } from '../types/misc';
import { verifySDKSetup } from './utils';

const getLoginStatus = (): Promise<facebook.LoginStatus> => {
  try {
    verifySDKSetup();
  } catch (error) {
    return Promise.reject(error);
  }

  return new Promise((resolve, reject) => {
    window.FB.getLoginStatus((response: facebook.StatusResponse | ErrorResponse) => {
      if (isError(response)) {
        reject(response.error);
      } else {
        resolve(response.status);
      }
    });
  });
};

export default getLoginStatus;
