import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFetch } from '@flowio/redux-fetch';
import { createStructuredSelector } from 'reselect';
import {
  completeConnection,
  fetchGoogleAccounts,
  hideConnectionConfirmationDialog,
  goToAddFeedsPage,
  goToLandingPage,
  toggleDisconnectPlatformDialog,
  showConnectionConfirmationDialog,
} from '../../../actions';
import {
  getGoogleConnectionAccounts,
  getShowConnectionConfirmationDialog,
} from '../../../selectors';
import SelectGoogleAccount from '../components/SelectGoogleAccount';
import { RootState, RootActionTypes, ThunkDispatcher } from '../../../../../stores/types';
import { createToast } from '../../../../console/actions';
import {
  DispatchProps,
  OwnProps,
  StateProps,
  FunctionProps,
} from '../types';

const PLATFORM: io.flow.internal.v0.enums.MarketingGatewayPlatform = 'google';

const getAsyncState = (
  dispatch: ThunkDispatcher,
  _: () => RootState,
  props: OwnProps,
): Promise<unknown[]> => {
  const { params: { organization: organizationId } } = props;
  return Promise.all([
    dispatch(fetchGoogleAccounts({ organizationId })),
  ]);
};

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  accounts: getGoogleConnectionAccounts,
  showConnectionConfirmDialog: getShowConnectionConfirmationDialog,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatcher,
  {
    location: { query: { intent } },
    params: { organization: organizationId },
  }: OwnProps,
): DispatchProps => ({
  handleCreateChannel: (
    { selectedAccountId }: FunctionProps,
  ): Promise<void> => dispatch(completeConnection({
    organizationId,
    accountId: Number(selectedAccountId),
    platformId: 'google',
  }))
    .then(() => {
      if (intent) {
        dispatch(goToLandingPage(organizationId, {
          query: { intent },
        }));
      } else {
        dispatch(showConnectionConfirmationDialog({
          accountId: Number(selectedAccountId),
          organizationId,
        }));
      }
    })
    .catch(() => {
      dispatch(
        createToast({
          intent: 'negative',
          content: 'Sorry something went wrong, please try again',
        }),
      );
    }),
  onDisconnectButtonClicked: (): RootActionTypes => dispatch(
    toggleDisconnectPlatformDialog(true, { platformId: PLATFORM }),
  ),
  onConnectionConfirmDialogClose: ({
    selectedAccountId,
  }: FunctionProps): void => {
    dispatch(hideConnectionConfirmationDialog());
    return dispatch(
      goToAddFeedsPage({
        organizationId,
        platformId: PLATFORM,
        accountId: selectedAccountId ? `${selectedAccountId}` : undefined,
        isOnboarding: true,
      }),
    );
  },
});

export default compose(
  withFetch(getAsyncState),
  connect(mapStateToProps, mapDispatchToProps),
)(SelectGoogleAccount);
