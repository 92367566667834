import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { createToast } from '../../../../console/actions';
import {
  getCountries,
  getLanguages,
} from '../../../../reference/selectors';
import {
  goToAddFeedsPage,
  showDownloadFeedsDialog,
} from '../../../actions';
import {
  getProductFeeds,
  getLocalizedFeeds,
  getFeedSources,
} from '../../../selectors';
import ManualFeeds from '../components/ManualFeeds';
import {
  RootState,
  RootActionTypes,
  ThunkResult,
  ThunkDispatcher,
} from '../../../../../stores/types';
import { encodeFeedSource } from '../../../utilities/feedSourceDecoder';
import { OwnProps, StateProps, DispatchProps } from '../types';
import { MarketingActions } from '../../../types';

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = (
  state: RootState,
): StateProps => {
  const localizedFeeds = getLocalizedFeeds()(state);
  const feedSource = getFeedSources(state)
    .find((source) => localizedFeeds.some(
      (
        feed: io.flow.internal.v0.models.MarketingGatewayFeed,
      ) => feed.feed_source.country === source.country
        && feed.feed_source.language === source.language
        && feed.feed_source.currency === source.currency,
    ));

  return ({
    feedSource,
    localizedFeeds,
    productFeeds: getProductFeeds(state),
    countries: getCountries(state),
    languages: getLanguages(state),
  });
};

const handleClickAddCountryFeed = (
  organizationId: string,
  productFeeds: io.flow.internal.v0.models.MarketingGatewayFeed[],
): ThunkResult<void> => {
  const accountId = productFeeds.length ? encodeFeedSource(productFeeds[0]) : undefined;

  return goToAddFeedsPage({
    organizationId,
    accountId,
    platformId: 'facebook',
  });
};

const handleDownloadAllFeedsClick = (
  feeds: string[],
): MarketingActions => showDownloadFeedsDialog(feeds);

const handleCopySuccess = (country = 'Country'): RootActionTypes => createToast({
  content: `${country} feed URL copied`,
  intent: 'positive',
  autoCloseDuration: 2000,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch: ThunkDispatcher,
): DispatchProps => ({
  onClickAddCountryFeed: (
    organizationId: string,
    productFeeds: io.flow.internal.v0.models.MarketingGatewayFeed[],
  ): void => dispatch(handleClickAddCountryFeed(organizationId, productFeeds)),
  onDownloadFeedsButtonClick: (
    feeds: string[],
  ): RootActionTypes => dispatch(handleDownloadAllFeedsClick(feeds)),
  onCopied: (country: string): RootActionTypes => dispatch(handleCopySuccess(country)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManualFeeds);
