import { Field, formValues } from 'redux-form';
import { Box } from '@flowio/react-box';
import React from 'react';
import find from 'lodash/find';
import { TierRuleQueryCombinedProps } from '../../types/components';
import { TextField, SelectField } from '../../../../components/ReduxFormFieldAdapters';

const options = [
  { content: 'any', value: 'any' },
  { content: 'greater than or equal to', value: 'gte' },
  { content: 'less than or equal to', value: 'lte' },
];

const formatOperator = (value: string) => find(options, { value });

const parseOperator = (option: { value: string }) => option.value;

const TierRuleQuery: React.FC<TierRuleQueryCombinedProps> = ({
  name,
  selectedCurrency,
  selectedQueryOperator,
}) => (
  <Box alignItems="center" spacing="tight">
    <span>When order value is</span>
    <Field
      component={SelectField}
      name={`${name}.operator`}
      format={formatOperator}
      parse={parseOperator}
      options={options}
      labelKey="content"
      valueKey="value"
    />
    {(selectedQueryOperator !== 'any') && (
      <Field component={TextField} name={`${name}.value`} />
    )}
    {(selectedQueryOperator !== 'any') && (
      <span>
        {selectedCurrency}
      </span>
    )}
  </Box>
);

TierRuleQuery.displayName = 'TierRuleQuery';

const mapValuesToProps = (props: TierRuleQueryCombinedProps) => {
  const { name } = props;
  return {
    selectedCurrency: 'currency',
    selectedQueryOperator: `${name}.operator`,
  };
};

export default formValues<{}, TierRuleQueryCombinedProps>(mapValuesToProps)(TierRuleQuery);
