import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import DisconnectConfirmDialog from '../components/DisconnectConfirmDialog';
import {
  getShowDisconnectConfirmDialog,
  getShowDisconnectConfirmDialogData,
} from '../../../selectors';
import { ThunkDispatcher, RootState, RootActionTypes } from '../../../../../stores/types';
import {
  toggleDisconnectPlatformDialog,
  disconnectPlatformAccount,
  goToLandingPage,
} from '../../../actions';
import { DispatchProps, StateProps } from '../types';
import { MarketingGatewayIntegratedPlatform } from '../../../types';

const handleCancelClick = () => (dispatch: ThunkDispatcher): RootActionTypes => dispatch(
  toggleDisconnectPlatformDialog(false),
);

type FunctionProps = {
  platformId: MarketingGatewayIntegratedPlatform;
  organizationId: string;
};

const handleConfirmClick = ({
  organizationId,
  platformId,
}: FunctionProps) => (dispatch: ThunkDispatcher): Promise<RootActionTypes> => dispatch(
  disconnectPlatformAccount({ organizationId, platformId }),
).then(() => dispatch(
  goToLandingPage(organizationId),
)).then(() => dispatch(
  toggleDisconnectPlatformDialog(false),
));

const mapStateToProps = createStructuredSelector<RootState, {}, StateProps>({
  isOpen: getShowDisconnectConfirmDialog,
  dialogData: getShowDisconnectConfirmDialogData,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatcher,
): DispatchProps => bindActionCreators({
  onCancel: handleCancelClick,
  onConfirm: handleConfirmClick,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DisconnectConfirmDialog);
