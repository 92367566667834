import { compose } from 'redux';
import { InjectedFormProps, reduxForm, SubmissionError } from 'redux-form';
import FeatureDeleteConfirmForm from '../components/FeatureDeleteConfirmForm';

import createToast from '../../console/actions/createToast';
import {
  deleteFeatureByKey,
  goToFeatureListingPage,
  hideDeleteFeatureConfirm,
} from '../actions';
import FormName from '../constants/FormName';
import isResponseOk from '../../../utilities/isResponseOk';
import { FeatureDeleteConfirmValues, FeatureDeleteConfirmFormOwnProps } from '../types/components';
import { ThunkDispatcher } from '../../../stores/types';

function submit(values: FeatureDeleteConfirmValues, dispatch: ThunkDispatcher) {
  const { organizationId, featureKey } = values;
  return dispatch(deleteFeatureByKey(organizationId, featureKey))
    .then((response) => {
      if (isResponseOk(response)) return response;
      throw new SubmissionError({ _error: response.body });
    });
}

function handleSubmitSuccess(
  _: any,
  dispatch: ThunkDispatcher,
  props: InjectedFormProps<FeatureDeleteConfirmValues>,
) {
  const { initialValues } = props;
  const { organizationId, featureName } = initialValues;
  dispatch(hideDeleteFeatureConfirm());
  goToFeatureListingPage(organizationId || '');
  dispatch(createToast({
    content: `Feature \`${featureName}\` has been deleted.`,
    icon: 'Trash',
    intent: 'neutral',
  }));
}

export default compose<React.FC<FeatureDeleteConfirmFormOwnProps>>(
  reduxForm({
    form: FormName.DELETE_FEATURE_FORM,
    onSubmit: submit,
    onSubmitSuccess: handleSubmitSuccess,
  }),
)(FeatureDeleteConfirmForm);
