/* eslint-disable react/no-find-dom-node */

import PropTypes from 'prop-types';
import React from 'react';
import { findDOMNode } from 'react-dom';
import hljs from 'highlight.js';

if (process.browser) {
  require('highlight.js/styles/github.css'); // eslint-disable-line global-require
}

/**
 * React component for syntax highlighting using highlight.js
 */
export default class Highlight extends React.Component {
  componentDidMount() {
    this.highlightCode();
  }

  componentDidUpdate() {
    this.highlightCode();
  }

  highlightCode() {
    const domNode = findDOMNode(this);
    const nodes = domNode.querySelectorAll('pre code');
    if (nodes.length > 0) {
      for (let i = 0; i < nodes.length; i += 1) {
        hljs.highlightBlock(nodes[i]);
      }
    }
  }

  render() {
    const { className, snippet } = this.props;
    return (
      <pre>
        <code className={className}>
          {snippet}
        </code>
      </pre>
    );
  }
}
Highlight.displayName = 'Hightlight';

Highlight.propTypes = {
  snippet: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Highlight.defaultProps = {
  className: undefined,
};
