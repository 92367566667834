import { Link } from 'react-router';
import { Button } from '@flowio/react-button';
import React, { useState, useEffect } from 'react';
import { TextField } from '@flowio/react-text-field';
import kebabCase from 'lodash/kebabCase';

import AuthLayout from '../auth-layout';
import ErrorAlert from '../../../../components/alert/error-alert';
import { WithValidationProps, ValidationSubmitFunction } from '../../../../components/with-validation/with-validation';
import { WithSubmitProps } from '../../../../components/with-submit/with-submit';
import * as styles from './reset-password.styles';
import getTextFieldValue from '../../../../utilities/getTextFieldValue';

interface OwnProps {
  token: string;
}

type Props = OwnProps & WithValidationProps & WithSubmitProps;

const ResetPassword: React.FC<Props> = ({
  onSubmit,
  error,
  fields: { confirmPassword, password },
  onSubmitValidate,
  submitting,
  submitted,
  token,
}) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);

    return (): void => setIsMounted(false);
  }, []);

  const handleSubmit: ValidationSubmitFunction = ({ isValid }, formData) => {
    if (isValid) onSubmit(formData);
  };

  return (
    <AuthLayout className={styles.resetPassword}>
      {submitted ? (
        <div>
          <p>Your password has been reset successfully.</p>
          <Link to="/login">
            <Button
              className={styles.button}
              content="Go to Login"
              fluid
              intent="primary"
              type="button"
            />
          </Link>
        </div>
      ) : (
        <form noValidate onSubmit={onSubmitValidate(handleSubmit)}>
          {error && error.messages && error.messages.map(({ message }) => (
            <ErrorAlert key={kebabCase(message)}>
              {message}
            </ErrorAlert>
          ))}
          <input type="hidden" name="token" value={token} />
          <TextField
            onChange={(e) => password.events.onChange(e)}
            onBlur={(e) => password.events.onBlur(e)}
            className={styles.password}
            clearable={false}
            type="password"
            name="password"
            value={getTextFieldValue(password.value)}
            intent={password.error ? 'negative' : 'neutral'}
            feedback={password.error}
            fluid
            hintText="Enter New Password"
            aria-required="true"
            required
            pattern=".{5,}"
          />
          <TextField
            onChange={(e) => confirmPassword.events.onChange(e)}
            onBlur={(e) => confirmPassword.events.onBlur(e)}
            clearable={false}
            type="password"
            name="confirmPassword"
            intent={confirmPassword.error ? 'negative' : 'neutral'}
            feedback={confirmPassword.error}
            fluid
            hintText="Confirm New Password"
            aria-required="true"
            required
            pattern=".{5,}"
          />
          <Button
            className={styles.button}
            content="Update Password"
            disabled={!isMounted || submitting}
            fluid
            intent="primary"
            type="submit"
          />
        </form>
      )}
    </AuthLayout>
  );
};

ResetPassword.displayName = 'ResetPassword';

export default ResetPassword;
