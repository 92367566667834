import React from 'react';
import { SegmentedControl } from '@flowio/react-segmented-control';
import { WrappedFieldProps } from 'redux-form';

type ReduxFormSegmentedControlProps = WrappedFieldProps & typeof SegmentedControl;

const ReduxFormSegmentedControl: React.FC<ReduxFormSegmentedControlProps> = (props) => {
  const { input, meta, ...unhandledProps } = props;
  return (
    <SegmentedControl
      {...unhandledProps}
      name={input.name}
      onValueChange={input.onChange}
      onFocus={input.onFocus}
      onBlur={input.onBlur}
      value={input.value}
    />
  );
};

export default ReduxFormSegmentedControl;
