import assign from 'lodash/assign';
import isPlainObject from 'lodash/isPlainObject';
import reduce from 'lodash/reduce';

/**
 * @template T
 * @param {T} object
 * @param {string} [prefix]
 * @param {string} [delimiter = '.']
 * @returns {{ [key in keyof T]: key }}
 */
export default function keyMirror(object, prefix, delimiter = '.') {
  if (!isPlainObject(object)) {
    throw new Error('keyMirror(...): Argument must be an object.');
  }

  return reduce(object, (result, value, key) => assign({}, result, {
    [key]: prefix ? `${prefix}${delimiter}${key}` : `${key}`,
  }), {});
}
