import ActionTypes from '../constants/ActionTypes';
import { DeleteFeedConfirmationDialogProps, MarketingActions } from '../types';

export default function showDeleteFeedConfirmationDialog(
  data: DeleteFeedConfirmationDialogProps,
): MarketingActions {
  return {
    type: ActionTypes.DELETE_FEED_CONFIRMATION_DIALOG_OPEN,
    data,
  };
}
