import invariant from 'invariant';
import { getPageNumber, getPageSize } from '@flowio/redux-filtering-paging-sorting/lib/selectors';
import ActionType from '../constants/ActionType';

import { getNonReturnablePaginationState } from '../selectors';
import fetchReturnPolicies from './fetchReturnPolicies';
import hideReturnPolicyWorksheet from './hideReturnPolicyWorksheet';
import { createToast } from '../../console/actions';
import { ThunkResult } from '../../../stores/types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';

/**
 * Creates an action responsible for marking an return policy in the specified
 * organization for deletion.
 * @param {String} organizationId - identifier for the organization to be updated.
 * @param {String} returnPolicyId - identifier for the return policy to be deleted.
 */
export default function updateReturnPolicy(
  organizationId: string,
  returnPolicyId: string,
  returnPolicyForm: io.flow.v0.models.ReturnPolicyForm,
): ThunkResult<Promise<void>> {
  invariant(organizationId != null, 'An organization identifier must be provided');
  invariant(returnPolicyId != null, 'A return policy identifier must be provided');
  invariant(returnPolicyForm != null, 'A return policy form must be provided');
  return function updateReturnPolicyEffects(dispatch, getState, extra) {
    const state = getState();
    const pageSize = getPageSize(getNonReturnablePaginationState)(state);
    const pageNumber = getPageNumber(getNonReturnablePaginationState)(state);
    dispatch({
      type: ActionType.UpdateReturnPolicyRequest,
      meta: {
        organizationId,
        returnPolicyForm,
        returnPolicyId,
      },
    });

    return extra.api(state).returnPolicies.putById({
      organization: organizationId,
      id: returnPolicyId,
      body: returnPolicyForm,
    }).then((response) => {
      if (response.ok) {
        dispatch({
          type: ActionType.UpdateReturnPolicySuccess,
          payload: response.body,
          meta: {
            organizationId,
            returnPolicyForm,
            returnPolicyId,
          },
        });
        dispatch(fetchReturnPolicies(organizationId, pageNumber, pageSize));
        dispatch(hideReturnPolicyWorksheet());
        dispatch(createToast({
          content: 'Return policy updated successfully',
        }));
        return;
      }

      const errors = formatErrorResponseV2(response);
      dispatch({
        type: ActionType.UpdateReturnPolicyFailure,
        payload: errors,
        meta: {
          organizationId,
          returnPolicyForm,
          returnPolicyId,
        },
      });
    });
  };
}
