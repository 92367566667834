import { RootActionTypes } from '../../../stores/types';
import ActionTypes from '../constants/action-types';

const hideDialog = (dialog: string) => (payload?: any): RootActionTypes => ({
  type: ActionTypes.HIDE_DIALOG,
  meta: { name: dialog },
  payload,
});

export default hideDialog;
