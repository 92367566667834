import { RSAA } from '@flowio/redux-async-middleware';
import { operationTypes } from '@flowio/redux-resource';
import PropTypes from 'prop-types';

import { api } from '../../../../utilities/clients';
import { createResourceTypeDescriptors } from '../../helpers';
import { withAssertPropTypes } from '../../../../utilities/prop-types';

export default withAssertPropTypes({
  organizationId: PropTypes.string.isRequired,
  priceBookKey: PropTypes.string.isRequired,
})(({ organizationId, priceBookKey }) => ({
  [RSAA]: {
    types: createResourceTypeDescriptors({
      operationType: operationTypes.READ,
      resourceType: 'priceBooks',
      list: organizationId,
    }),
    callAPI: (state) => api.priceBooks(state).getByKey(organizationId, priceBookKey),
  },
}));
