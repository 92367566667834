import { ActionTypes } from '../constants';
import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';

interface UpdateTierOptions {
  organizationId: string;
  shippingConfigurationKey: string;
  shippingLaneId: string;
  tierId: string;
  tierForm: io.flow.v0.models.TierForm;
}

function createRequestAction(): RootActionTypes {
  return {
    type: ActionTypes.UPDATE_TIER_REQUEST,
  };
}

function createSuccessAction(
  payload: io.flow.v0.models.Tier,
  organizationId: string,
  shippingConfigurationKey: string,
  shippingLaneId: string,
  tierId: string,
): RootActionTypes {
  return {
    type: ActionTypes.UPDATE_TIER_SUCCESS,
    meta: {
      organizationId,
      shippingConfigurationKey,
      shippingLaneId,
      tierId,
    },
    payload,
  };
}

function createFailureAction(
  payload: FormattedErrorMessages,
): RootActionTypes {
  return {
    type: ActionTypes.UPDATE_TIER_FAILURE,
    payload,
    error: true,
  };
}

/**
 * Creates an action responsible for updating a shipping tier.
 * @param {String} props.organizationId
 * @param {String} props.shippingConfigurationKey
 * @param {String} props.shippingLaneId
 * @param {String} props.tierId
 * @param {TierForm} props.tierForm
 * @throws
 */
function updateTier({
  organizationId,
  shippingConfigurationKey,
  shippingLaneId,
  tierId,
  tierForm,
}: UpdateTierOptions): ThunkResult<Promise<RootActionTypes>> {
  return function updateTierEffects(
    dispatch,
    getState,
    extra,
  ): Promise<RootActionTypes> {
    dispatch(createRequestAction());

    return extra.api(getState()).tiers.putById({
      body: tierForm,
      id: tierId,
      organization: organizationId,
    }).then((response) => {
      if (response.ok) {
        return dispatch(createSuccessAction(
          response.body,
          organizationId,
          shippingConfigurationKey,
          shippingLaneId,
          tierId,
        ));
      }

      const errors = formatErrorResponseV2(response);
      return dispatch(createFailureAction(errors));
    });
  };
}

export default updateTier;
