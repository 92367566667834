import {
  Card, CardHeader, CardContent, CardFooter, CardEmptyState,
} from '@flowio/react-card';
import { Plus } from '@flowio/react-icons';
import { NonIdealState } from '@flowio/react-non-ideal-state';
import React from 'react';
import isEmpty from 'lodash/isEmpty';

import Pagination from '../../../../components/pagination';
import ShippingConfigurationList from '../ShippingConfigurationList';
import { ShippingConfigurationListCardProps } from '../../types/components';

class ShippingConfigurationListCard extends React.PureComponent<
ShippingConfigurationListCardProps
> {
  static displayName = 'ShippingConfigurationListCard';

  constructor(props: ShippingConfigurationListCardProps, context: any) {
    super(props, context);
    this.handleRequestNextPage = this.handleRequestNextPage.bind(this);
    this.handleRequestPreviousPage = this.handleRequestPreviousPage.bind(this);
  }

  handleRequestNextPage() {
    this.pageChange(1);
  }

  handleRequestPreviousPage() {
    this.pageChange(-1);
  }

  pageChange(delta: number) {
    const {
      entriesPerPage,
      onPageChange,
      organizationId,
      pageNumber,
    } = this.props;

    const nextPage = Number(pageNumber) + delta;

    onPageChange(organizationId, nextPage, entriesPerPage);
  }

  render() {
    const {
      isFirstPage,
      isLastPage,
      isSinglePage,
      onAddShippingConfiguration,
      onDeleteShippingConfiguration,
      onDuplicateShippingConfiguration,
      onViewShippingConfiguration,
      organizationId,
      pageNumber,
      shippingConfigurations,
    } = this.props;

    const actions = [{
      content: 'Add Shipping Configuration',
      leftIcon: Plus,
      intent: 'primary',
      onClick: onAddShippingConfiguration,
      variant: 'outline',
    }];

    return (
      <Card>
        <CardHeader actions={actions} caption="Shipping Configurations" dividing />
        <CardContent fitted>
          {isEmpty(shippingConfigurations) ? (
            <CardEmptyState>
              <NonIdealState
                heading="No Shipping Configurations"
                description={'Click "Add Shipping Configuration" button to add a shipping configuration.'}
              />
            </CardEmptyState>
          ) : (
            <ShippingConfigurationList
              onDeleteShippingConfiguration={onDeleteShippingConfiguration}
              onDuplicateShippingConfiguration={onDuplicateShippingConfiguration}
              onViewShippingConfiguration={onViewShippingConfiguration}
              organizationId={organizationId}
              shippingConfigurations={shippingConfigurations}
            />
          )}
        </CardContent>
        <CardFooter>
          {!isSinglePage && (
            <Pagination
              isNextPageDisabled={isLastPage}
              isPreviousPageDisabled={isFirstPage}
              onRequestNextPage={this.handleRequestNextPage}
              onRequestPreviousPage={this.handleRequestPreviousPage}
              pageNumber={pageNumber}
            />
          )}
        </CardFooter>
      </Card>
    );
  }
}

export default ShippingConfigurationListCard;
