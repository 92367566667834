import { first } from 'lodash';
import { ThunkExtraArguments, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import ActionTypes from '../constants/action-types';
import {
  FetchOrderNotesFailure,
  FetchOrderNotesRequest,
  FetchOrderNotesSuccess,
  OrderNoteCreated,
  OrderNoteDeleted,
  OrderNoteEdited,
} from '../types';

function fetchOrderNotesRequest(): FetchOrderNotesRequest {
  return { type: ActionTypes.FETCH_ORDER_NOTES_REQUEST };
}

function fetchOrderNotesSuccess(
  payload: io.flow.internal.v0.models.OrderNote[],
): FetchOrderNotesSuccess {
  return {
    type: ActionTypes.FETCH_ORDER_NOTES_SUCCESS,
    payload,
  };
}

function fetchOrderNotesFailure(errors: FormattedErrorMessages): FetchOrderNotesFailure {
  return {
    type: ActionTypes.FETCH_ORDER_NOTES_FAILURE,
    error: true,
    payload: errors,
  };
}

function orderNoteCreated(payload: io.flow.internal.v0.models.OrderNote): OrderNoteCreated {
  return {
    type: ActionTypes.ORDER_NOTE_CREATED,
    payload,
  };
}

function orderNoteEdited(payload: io.flow.internal.v0.models.OrderNote): OrderNoteEdited {
  return {
    type: ActionTypes.ORDER_NOTE_EDITED,
    payload,
  };
}

function orderNoteDeleted(payload: string): OrderNoteDeleted {
  return {
    type: ActionTypes.ORDER_NOTE_DELETED,
    payload,
  };
}

export function fetchOrderNotes(
  organization: string,
  orderNumber: string,
): ThunkResult<Promise<void>> {
  return async function fetchOrderNotesEffects(
    dispatch,
    getState,
    extra: ThunkExtraArguments,
  ): Promise<void> {
    dispatch(fetchOrderNotesRequest());

    const result = await extra.apiInternal(getState()).orderDetails.getNotesByNumber({
      organization,
      number: orderNumber,
    });

    if (result.ok) {
      dispatch(fetchOrderNotesSuccess(result.body));
    } else {
      dispatch(fetchOrderNotesFailure(formatErrorResponseV2(result)));
    }

    return Promise.resolve();
  };
}

export function createOrderNote(
  organization: string,
  orderNumber: string,
  note: string,
): ThunkResult<Promise<OrderNoteCreated>> {
  return async function createOrderNoteEffects(
    dispatch,
    getState,
    extra: ThunkExtraArguments,
  ): Promise<OrderNoteCreated> {
    const result = await extra.apiInternal(getState()).orderDetails.postNotesByNumber({
      organization,
      number: orderNumber,
      body: { note },
    });

    if (!result.ok) {
      return Promise.reject(new Error(`${result.body?.code || 500}: ${first(result.body?.messages) || 'An Error Occurred'}`));
    }

    return Promise.resolve(dispatch(orderNoteCreated(result.body)));
  };
}

export function editOrderNote(
  organization: string,
  orderNumber: string,
  noteId: string,
  note: string,
): ThunkResult<Promise<OrderNoteEdited>> {
  return async function createOrderNoteEffects(
    dispatch,
    getState,
    extra: ThunkExtraArguments,
  ): Promise<OrderNoteEdited> {
    const result = await extra.apiInternal(getState()).orderDetails.putNotesByNumberAndId({
      organization,
      number: orderNumber,
      id: noteId,
      body: { note },
    });

    if (!result.ok) {
      return Promise.reject(new Error(`${result.body?.code || 500}: ${first(result.body?.messages) || 'An Error Occurred'}`));
    }

    return Promise.resolve(dispatch(orderNoteEdited(result.body)));
  };
}

export function deleteOrderNote(
  organization: string,
  orderNumber: string,
  noteId: string,
): ThunkResult<Promise<OrderNoteDeleted>> {
  return async function deleteOrderNoteEffects(
    dispatch,
    getState,
    extra: ThunkExtraArguments,
  ): Promise<OrderNoteDeleted> {
    const result = await extra.apiInternal(getState()).orderDetails.deleteNotesByNumberAndId({
      organization,
      number: orderNumber,
      id: noteId,
    });

    if (!result.ok) {
      return Promise.reject(new Error(`deleteOrderNote: An Error Occurred: ${result.status}`));
    }

    return Promise.resolve(dispatch(orderNoteDeleted(noteId)));
  };
}
