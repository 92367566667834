import Memberships from '../../../modules/organization/components/memberships/containers/memberships';
import CreateWebhook from '../../../modules/webhook/components/create-webhook';
import ManageWebhook from '../../../modules/webhook/containers/manage-webhook';
import PolicyOverview from '../../../modules/policies/containers/policy-overview';
import Imports from '../../../modules/organization/containers/imports';
import Regions from '../../../modules/organization/containers/regions';
import AccountStatements from '../../../modules/billing/components/account-statements/containers/account-statements';
import AccountTransactions from '../../../modules/billing/components/account-transactions/containers/account-transactions';
import GdprFaq from '../../../modules/policies/containers/gdpr-faq';
import TermsAndConditions from '../../../modules/policies/components/terms-and-conditions';
import FeatureListing from '../../../modules/features/containers/FeatureListingRouteContainer';
import FeatureDetail from '../../../modules/features/containers/FeatureDetailsRouteContainer';
import Integrations from '../../../modules/organization/containers/integrations';

export default {
  path: ':organization/organization',
  childRoutes: [
    {
      path: 'members',
      component: Memberships,
      title: 'Organization Settings',
    },
    {
      path: 'features',
      indexRoute: { component: FeatureListing },
      title: 'Organization Settings',
      childRoutes: [{
        path: ':featureKey',
        component: FeatureDetail,
        title: 'Feature Details',
      }],
    },
    {
      path: 'integrations',
      indexRoute: { component: Integrations },
      title: 'Integrations',
      childRoutes: [{
        path: 'webhooks/new',
        component: CreateWebhook,
        title: 'Add Webhook',
      },
      {
        path: 'webhooks/:webhookId',
        component: ManageWebhook,
        title: 'Manage Webhook',
      }],
    },
    {
      path: 'policies',
      indexRoute: { component: PolicyOverview },
      title: 'Organization Settings',
      childRoutes: [{
        path: 'terms',
        component: TermsAndConditions,
        title: 'Policies',
      }, {
        path: 'gdpr',
        component: GdprFaq,
        title: 'Policies',
      }],
    },
    {
      path: 'accounts',
      title: 'Organization Settings',
      indexRoute: { component: AccountStatements },
      childRoutes: [{
        path: ':currency',
        component: AccountStatements,
      }, {
        path: ':currency/statements',
        component: AccountStatements,
      }, {
        path: ':currency/transactions',
        component: AccountTransactions,
      }],
    },
    {
      path: 'imports',
      component: Imports,
    },
    {
      path: 'settings/regions',
      component: Regions,
      title: 'Organization Settings',
    },
  ],
};
