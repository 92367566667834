import Client from './client';

export default class RatecardServiceFeesOverrides extends Client {
  constructor(opts) {
    let options = opts;

    if (typeof opts === 'string') {
      options = { host: opts }; // convert host string to options object
    }

    options.serviceName = 'API Internal';

    super(options);
  }

  get(ratecardId, options = {}) {
    return this.makeRequest(`${this.host}/internal/ratecard/${ratecardId}/service/fees/override`, options);
  }

  put(ratecardId, options = {}) {
    return this.makeRequest(`${this.host}/internal/ratecard/${ratecardId}/service/fees/override`, {
      ...options,
       method: 'PUT',
    });
  }

  delete(ratecardId, options = {}) {
    return this.makeRequest(`${this.host}/internal/ratecard/${ratecardId}/service/fees/override`, {
      ...options,
       method: 'DELETE',
    });
  }

}
