import { Field } from 'redux-form';
import {
  Card, CardHeader, CardContent, CardTitle,
} from '@flowio/react-card';
import React from 'react';

import TierDeliveryWindow from '../TierDeliveryWindow';
import presence from '../../../../utilities/validators/presence';

const handleEstimatedDisplayTypeValidation = [
  presence({
    allowEmpty: false,
    message: 'Select delivery window display',
  }),
];

const TierDeliveryWindowCard: React.FC<{}> = () => (
  <Card>
    <CardHeader dividing>
      <CardTitle content="Select Delivery Window Display" />
    </CardHeader>
    <CardContent>
      <Field
        name="displayEstimateType"
        component={TierDeliveryWindow}
        validate={handleEstimatedDisplayTypeValidation}
      />
    </CardContent>
  </Card>
);

TierDeliveryWindowCard.displayName = 'TierDeliveryWindowCard';

export default TierDeliveryWindowCard;
