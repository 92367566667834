import React, { FunctionComponent } from 'react';
import { OutlineButton } from '@flowio/react-button';
import BemHelper from '@flowio/bem-helper';
import { NotFoundProps } from '../../types/props';

if (process.browser) {
  require('./not-found.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('not-found');

const NotFound: FunctionComponent<NotFoundProps> = ({ className, showLink, ...otherProps }) => (
  <article {...otherProps} className={bem.block(className)}>
    <h1 className={bem.element('title')}>
      404
    </h1>
    <p className={bem.element('subtitle')}>
      Page Not Found
    </p>
    {showLink && (
      <a href="/">
        <OutlineButton
          intent="primary"
          content="Go To Console"
        />
      </a>
    )}
  </article>
);

NotFound.displayName = 'NotFound';

NotFound.defaultProps = {
  showLink: true,
  className: undefined,
};

export default NotFound;
