/* global ga */

import noop from 'lodash/noop';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import React, { Component } from 'react';
import { Tag } from '@flowio/react-tag-input';
import { Search, ArcLoader as Spinner } from '@flowio/react-icons';

import { Popover } from '@flowio/react-popover';
import { Menu, MenuItem } from '@flowio/react-menu';
import { TextInput } from '@flowio/react-text-input';
import updateRecentSearches from '../../utilities/updateRecentSearches';
import getRecentSearches from '../../utilities/getRecentSearches';
import * as styles from './global-searchbox.styles';
import { hasRouteComponent, hasRouteTitle, generateHrefOnRoutes } from '../../../console/components/top-navigation/utilities';
import { DecoratedRoute } from '../../../console/types/props';
import { colors } from '../../../../theme/tokens';

export interface SearchResult {
  q: string;
  id: string;
  type: string;
}

export interface GlobalSearchBoxStateProps {
  organizationId: string;
  globalSearchResults: Array<SearchResult>;
  loading: boolean;
}

export interface GlobalSearchBoxDispatchProps {
  onInputChange: (organization: string, searchText: string, recent: SearchResult[]) => unknown;
  onUpdateLoading: (loading: boolean) => void;
  onRequestOption: (path: string, updatedRecents: SearchResult[]) => void;
}

export interface GlobalSearchBoxPassedProps {
  routes: DecoratedRoute[];
  params: Record<string, string>; // eslint-disable-line react/forbid-prop-types
}

type GlobalSearchBoxProps =
  GlobalSearchBoxPassedProps &
  GlobalSearchBoxDispatchProps &
  GlobalSearchBoxStateProps;

interface GlobalSearchBoxState {
  searchText: string;
  searchTextFieldValue: string;
}

export default class GlobalSearchBox extends Component<GlobalSearchBoxProps, GlobalSearchBoxState> {
  static displayName = 'GlobalSearchBox';

  static defaultProps = {
    globalSearchResults: [],
    loading: false,
    routes: [],
    params: {},
    onInputChange: noop,
    onUpdateLoading: noop,
    onRequestOption: noop,
  };

  constructor(props: GlobalSearchBoxProps) {
    super(props);
    this.state = {
      searchText: '',
      searchTextFieldValue: '',
    };
  }

  componentDidMount(): void {
    const { onInputChange, organizationId } = this.props;

    const recent = getRecentSearches(organizationId);
    onInputChange(organizationId, '', recent.reverse());
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: GlobalSearchBoxProps): void {
    const { organizationId, onInputChange } = this.props;
    if (nextProps.organizationId !== organizationId) {
      const recent = getRecentSearches(nextProps.organizationId);
      onInputChange(nextProps.organizationId, '', recent.reverse());
    }
  }

  handleInputChange(searchText: string): void {
    const { onUpdateLoading } = this.props;
    this.setState({ searchTextFieldValue: searchText });

    onUpdateLoading(true);
    this.inputChangeDebounce(searchText);
  }

  handleRequestOption(option: SearchResult, index?: number): void {
    const { organizationId, onRequestOption } = this.props;
    const { searchText } = this.state;

    if (!isNil(index)) {
      // TODO: Get GA in global
      // eslint-disable-next-line
      // @ts-ignore
      ga('send', 'event', 'global search', 'result selected', searchText, index);
    }

    const updatedRecents: SearchResult[] = updateRecentSearches(organizationId, option);
    let path = '';

    switch (option.type) {
      case 'Item':
        path = `/${organizationId}/catalog/items/${encodeURIComponent(option.id)}`;
        break;
      case 'Order':
        path = `/${organizationId}/orders/${encodeURIComponent(option.id)}`;
        break;
      case 'Experience':
        path = `/${organizationId}/experience/${encodeURIComponent(option.id)}/localization`;
        break;
      case 'Experiment':
        path = `/${organizationId}/experiments/${option.id}`;
        break;
      default:
        break;
    }

    onRequestOption(path, updatedRecents);
    this.setState({ searchTextFieldValue: option.q });
  }

  inputChangeDebounce = debounce((searchText) => {
    const {
      onInputChange,
      onUpdateLoading,
      organizationId,
    } = this.props;

    this.setState({ searchText });

    const recent = getRecentSearches(organizationId);
    onInputChange(organizationId, searchText, recent.reverse());

    onUpdateLoading(false);
  }, 600);

  render(): React.ReactElement {
    const {
      globalSearchResults,
      loading,
      routes,
      params,
    } = this.props;
    const { searchTextFieldValue } = this.state;

    const processedRoutes = generateHrefOnRoutes(routes, params);
    const filteredRoutes = processedRoutes.filter(
      (route) => hasRouteComponent(route) && hasRouteTitle(route),
    );

    return (
      <>
        {!isEmpty(filteredRoutes) && (
        <Popover
          openOnFocus
          onClose={() => this.setState({ searchTextFieldValue: '' })}
          trigger={(
            <TextInput
              leftIcon={<Search fill={colors.neutral[500]} />}
              rightIcon={loading ? <Spinner fill={colors.neutral[500]} /> : null}
              hintText="Search Anything..."
              onValueChange={(s) => this.handleInputChange(s)}
              clearable
              value={searchTextFieldValue}
              className={styles.searchInput}
              fluid
            />
        )}
        >
          <Menu selectItemOnSpace={false}>
            { globalSearchResults.map((g, i) => (
              <MenuItem
                onClick={() => this.handleRequestOption(g, i)}
                className={styles.menuItem}
                index={i}
                content={g.q}
                key={g.id}
                icon={(
                  <Tag
                    intent="neutral"
                    size="small"
                    content={g.type}
                  />
              )}
                value={g.id}
              />
            )) }
          </Menu>
        </Popover>
        )}
      </>
    );
  }
}
