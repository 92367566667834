import { css } from '@emotion/react';
import {
  Table,
  TableHead,
  TableRow,
  TableHeadColumn,
  TableBody,
} from '@flowio/react-table';

import map from 'lodash/map';
import partition from 'lodash/partition';
import React from 'react';
import FeedItem from '../FeedItem/FeedItem';
import marketingTableStyle from '../../styles/styles';

const pageContentStyles = css`
  margin-top: 30px;

  & p {
    margin-top: 0;
    line-height: 1.29;
    font-size:14px;
    color: #4d5866;
  }
  & table {
    margin-top: 5px;
  }
`;

interface Props {
  onCopy: (url: string, country: string) => void;
  countries: io.flow.v0.models.Country[];
  languages: io.flow.v0.models.Language[];
  countryFeeds: io.flow.internal.v0.models.MarketingGatewayFeed[];
  platform: io.flow.internal.v0.enums.MarketingGatewayPlatform,
  feedSource?: io.flow.internal.v0.unions.MarketingGatewayFeedSource;
}

const cellStyle = css({
  width: '15%',
});

const selectContainerStyle = css({
  width: '40%',
});

const CountryFeeds: React.FC<Props> = React.memo(({
  countryFeeds,
  feedSource,
  onCopy,
  countries,
  languages,
  platform,
}) => {
  const isSourceFeed = ({ country, language }: io.flow.internal.v0.models.MarketingGatewayFeed) => (
    feedSource
    && country === feedSource.country
    && language === feedSource.language
  );

  const [sourceFeeds, localizedFeeds] = partition(countryFeeds, isSourceFeed);

  return (
    <div css={pageContentStyles}>
      <Table css={marketingTableStyle}>
        <TableHead>
          <TableRow>
            <TableHeadColumn css={selectContainerStyle}>Feed</TableHeadColumn>
            <TableHeadColumn textAlign="center" css={cellStyle}>Available Products</TableHeadColumn>
            <TableHeadColumn textAlign="center" css={cellStyle}>Status</TableHeadColumn>
            <TableHeadColumn textAlign="center" css={cellStyle}>Copy URL</TableHeadColumn>
            <TableHeadColumn textAlign="center" css={cellStyle}>Download Feed</TableHeadColumn>
          </TableRow>
        </TableHead>
        <TableBody>
          {map([...sourceFeeds, ...localizedFeeds],
            (feed) => (
              <FeedItem
                key={feed.id}
                onCopy={onCopy}
                feed={feed}
                languages={languages}
                countries={countries}
                platform={platform}
                isSourceFeed={isSourceFeed(feed)}
                isCombinedFeedChannel={!!(sourceFeeds.length && !localizedFeeds.length)}
              />
            ))}
        </TableBody>
      </Table>
    </div>
  );
});

CountryFeeds.displayName = 'CountryFeeds';

export default CountryFeeds;
