import { $HttpResponse } from '@flowio/api-internal-sdk';
import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import { WithPagingAndSortingResponse, WithPagingAndSortingResult } from '../../../utilities/types';
import withPagingAndSorting, { isPagingAndSortingResponse, WithPagingAndSortingOptions } from '../../../utilities/with-paging-and-sorting-v2';
import ActionTypes from '../constants/action-types';
import { FetchFeaturesOptions } from '../types/actions';

function createRequestAction(options: FetchFeaturesOptions): RootActionTypes {
  return {
    type: ActionTypes.FETCH_FEATURES_REQUEST,
    options,
  };
}

function createSuccessAction(
  options: FetchFeaturesOptions,
  payload: WithPagingAndSortingResult<io.flow.internal.v0.models.Feature[]>,
): RootActionTypes {
  return {
    type: ActionTypes.FETCH_FEATURES_SUCCESS,
    options,
    payload,
  };
}

function createFailureAction(
  payload: FormattedErrorMessages,
  options: FetchFeaturesOptions,
): RootActionTypes {
  return {
    type: ActionTypes.FETCH_FEATURES_FAILURE,
    payload,
    options,
  };
}

export default function fetchFeatures(
  organizationId: string,
  options: FetchFeaturesOptions = { params: {} },
): ThunkResult<Promise<void>> {
  return function fetchFeaturesEffect(
    dispatch,
    getState,
    extra,
  ): Promise<void> {
    dispatch(createRequestAction(options));

    return withPagingAndSorting<io.flow.internal.v0.models.Feature[]>(
      options.params,
      (resOptions: WithPagingAndSortingOptions) => extra.apiInternal(getState()).features.get({
        organization: organizationId,
        ...resOptions,
      }),
    )().then((response: WithPagingAndSortingResponse<io.flow.internal.v0.models.Feature[]> |
    $HttpResponse<io.flow.v0.models.GenericError>) => {
      if (
        isPagingAndSortingResponse<io.flow.internal.v0.models.Feature[]>(response)
        && response.ok
      ) {
        dispatch(createSuccessAction(options, response.result));
        return;
      }

      const errorResponse = response as $HttpResponse<io.flow.v0.models.GenericError>;
      const error = formatErrorResponseV2(errorResponse);

      dispatch(createFailureAction(error, options));
    });
  };
}
