import { MarketingGatewayIntegratedPlatform } from '../types';

const
  SchemaCompatibility: Record<
  MarketingGatewayIntegratedPlatform,
  io.flow.internal.v0.enums.MarketingGatewaySchemaCompatibility[]
  > = {
    google: ['google'],
    facebook: ['facebook_country_override', 'facebook_primary'],
  };

export default SchemaCompatibility;
