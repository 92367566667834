import { getPageNumber, getPageSize } from '@flowio/redux-filtering-paging-sorting/lib/selectors';
import invariant from 'invariant';
import { ThunkResult } from '../../../stores/types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';

import { createToast } from '../../console/actions';
import ActionType from '../constants/ActionType';
import { getNonReturnablePaginationState } from '../selectors';
import fetchReturnPolicies from './fetchReturnPolicies';
import hideReturnPolicyWorksheet from './hideReturnPolicyWorksheet';

/**
 * Creates an action responsible for creating a return policy
 * @param {String} organizationId - identifier for the organization to be updated.
 * @param {io.flow.v0.models.ReturnPolicyForm} returnPolicyForm - Return policy form.
 */

export default function createReturnPolicy(
  organizationId: string,
  returnPolicyForm: io.flow.v0.models.ReturnPolicyForm,
): ThunkResult<Promise<void>> {
  invariant(organizationId != null, 'An organization identifier must be provided');
  invariant(returnPolicyForm != null, 'A return policy form must be provided');
  return function createNonReturnableRuleEffects(dispatch, getState, extra) {
    const state = getState();
    const pageSize = getPageSize(getNonReturnablePaginationState)(state);
    const pageNumber = getPageNumber(getNonReturnablePaginationState)(state);

    dispatch({
      type: ActionType.CreateReturnPolicyRequest,
      meta: {
        organizationId,
        returnPolicyForm,
      },
    });

    return extra.api(state).returnPolicies.post({
      organization: organizationId,
      body: returnPolicyForm,
    }).then((response) => {
      if (response.ok) {
        dispatch({
          type: ActionType.CreateReturnPolicySuccess,
          payload: response.body,
          meta: {
            organizationId,
            returnPolicyForm,
          },
        });
        dispatch(fetchReturnPolicies(organizationId, pageNumber, pageSize));
        dispatch(hideReturnPolicyWorksheet());
        dispatch(createToast({
          content: 'Non-Returnable rule added successfully',
        }));
        return;
      }

      const error = formatErrorResponseV2(response);
      dispatch({
        type: ActionType.CreateReturnPolicyFailure,
        meta: {
          organizationId,
          returnPolicyForm,
        },
        payload: error,
      });
    });
  };
}
