import { RouterState } from 'react-router';
import { compose } from 'redux';
import { withFetch } from '@flowio/redux-fetch';

import { fetchOrganization } from '../../organization/actions';
import Fulfillment from '../components/Fulfillment';
import { RootState, ThunkDispatcher } from '../../../stores/types';

const fetchAsyncState = (
  dispatch: ThunkDispatcher,
  _getState: () => RootState,
  props: RouterState,
) => {
  const { params } = props;
  const { organization } = params;
  return Promise.all([
    dispatch(fetchOrganization(organization)),
  ]);
};

const ConnectedFulfillment = compose(
  withFetch(fetchAsyncState),
)(Fulfillment);

export default ConnectedFulfillment;
