import { createSelector } from 'reselect';
import get from 'lodash/get';
import getExclusionRuleWorksheet from './getExclusionRuleWorksheet';
import { RootState } from '../../../stores/types';
import { ExclusionRuleWorksheetState } from '../types';

/**
 * Returns a list of regions for which an exclusion can be created.
 * @returns {Region[]}
 */
const getAvailableRegionsForExclusionRuleWorksheet = createSelector<
RootState, ExclusionRuleWorksheetState, io.flow.v0.models.Region[]>(
  getExclusionRuleWorksheet,
  (worksheet) => get(worksheet, 'availableRegions'),
);

export default getAvailableRegionsForExclusionRuleWorksheet;
