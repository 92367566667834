import { ShippingLabelsPostResponse } from '@flowio/api-sdk';
import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import { ActionTypes } from '../constants';
import { SelectedOrderItem } from '../types/components';

function createRequestAction(): RootActionTypes {
  return {
    type: ActionTypes.CREATE_SHIPPING_LABEL_REQUEST,
  };
}

function createSuccessAction(
  label: io.flow.v0.models.ShippingLabel,
  items: SelectedOrderItem[],
): RootActionTypes {
  return {
    type: ActionTypes.CREATE_SHIPPING_LABEL_SUCCESS,
    payload: {
      label,
      items,
    },
  };
}

function createFailureAction(payload: FormattedErrorMessages): RootActionTypes {
  return {
    type: ActionTypes.CREATE_SHIPPING_LABEL_FAILURE,
    payload,
  };
}

function createShippingLabel(
  organizationId: string,
  shippingLabelForm: io.flow.v0.unions.ShippingLabelForm,
  newLabelItems: SelectedOrderItem[],
): ThunkResult<Promise<ShippingLabelsPostResponse>> {
  return function createShippingLabelEffects(
    dispatch,
    getState,
    extra,
  ): Promise<ShippingLabelsPostResponse> {
    dispatch(createRequestAction());

    return extra.api(getState()).shippingLabels.post({
      organization: organizationId,
      body: shippingLabelForm,
    }).then((response) => {
      if (response.ok) {
        dispatch(createSuccessAction(response.body, newLabelItems));
        return response;
      }

      const error = formatErrorResponseV2(response);
      dispatch(createFailureAction(error));
      return response;
    });
  };
}

export default createShippingLabel;
