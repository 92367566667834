import { combineReducers } from 'redux';
import ActionTypes from '../constants/action-types';
import createStatusReducer from '../../../utilities/redux/createStatusReducer';

export default combineReducers({
  current: createStatusReducer([
    ActionTypes.FETCH_ORGANIZATION_REQUEST,
    ActionTypes.FETCH_ORGANIZATION_SUCCESS,
    ActionTypes.FETCH_ORGANIZATION_FAILURE,
  ]),
  countries: createStatusReducer([
    ActionTypes.FETCH_ORGANIZATION_COUNTRIES_REQUEST,
    ActionTypes.FETCH_ORGANIZATION_COUNTRIES_SUCCESS,
    ActionTypes.FETCH_ORGANIZATION_COUNTRIES_FAILURE,
  ]),
  currencies: createStatusReducer([
    ActionTypes.FETCH_CURRENCIES_REQUEST,
    ActionTypes.FETCH_CURRENCIES_SUCCESS,
    ActionTypes.FETCH_CURRENCIES_FAILURE,
  ]),
  memberships: createStatusReducer([
    ActionTypes.FETCH_MEMBERSHIPS_REQUEST,
    ActionTypes.FETCH_MEMBERSHIPS_SUCCESS,
    ActionTypes.FETCH_MEMBERSHIPS_FAILURE,
  ]),
  publicKeys: createStatusReducer([
    ActionTypes.FETCH_PUBLIC_KEYS_REQUEST,
    ActionTypes.FETCH_PUBLIC_KEYS_SUCCESS,
    ActionTypes.FETCH_PUBLIC_KEYS_FAILURE,
  ]),
  userMembership: createStatusReducer([
    ActionTypes.FETCH_USER_MEMBERSHIP_REQUEST,
    ActionTypes.FETCH_USER_MEMBERSHIP_SUCCESS,
    ActionTypes.FETCH_USER_MEMBERSHIP_FAILURE,
  ]),
});
