import { ChevronDown, Plus } from '@flowio/react-icons';
import React, { useState, useEffect, ChangeEvent } from 'react';
import capitalize from 'lodash/capitalize';
import filter from 'lodash/filter';
import map from 'lodash/map';
import {
  Card, CardHeader, CardContent, CardEmptyState, CardTitle,
} from '@flowio/react-card';
import { Box } from '@flowio/react-box';
import { Button, OutlineButton } from '@flowio/react-button';
import { Checkbox } from '@flowio/react-checkbox';
import { Dropdown } from '@flowio/react-dropdown';
import * as styles from './experimentList.styles';
import ExperimentListTable from '../experimentsListTable';
import EditExperimentWorksheet from '../../../containers/edit-experiment-worksheet';
import EndExperimentDialog from '../../end-experiment/end-experiment-dialog';
import { MergedProps as Props } from '../types';
import { RootActionTypes } from '../../../../../stores/types';

const filteredStatusOptions = (orgId: string): io.flow.internal.v0.enums.Status[] => {
  const statuses: io.flow.internal.v0.enums.Status[] = ['live', 'draft', 'scheduled', 'ended', 'archived'];
  if (orgId === 'flow') {
    return statuses;
  }
  return statuses.filter((status) => status !== 'scheduled');
};

const statusToInputProps = (
  statusArr: io.flow.internal.v0.enums.Status[],
  organizationId: string,
): string => {
  const formattedStatus = map(statusArr, (status) => capitalize(status));
  // eslint-disable-next-line no-nested-ternary
  return formattedStatus.length === filteredStatusOptions(organizationId).length
    ? 'All'
    : formattedStatus.length ? formattedStatus.join(', ') : 'None';
};

const ExperimentsList: React.FC<Props> = ({
  experiments,
  selectedExperiment,
  organizationId,
  onRequestFilter,
  onNewExperiment,
  onEditExperiment,
  onViewExperiment,
  onDeleteExperiment,
  toggleEndExperimentModal,
  onUpdateExperimentStatus,
  onCreateToast,
  location,
  status: statusProp,
}) => {
  const [statusOptions, setStatusOptions] = useState<io.flow.internal.v0.enums.Status[]>([]);
  const [status, setStatus] = useState<io.flow.internal.v0.enums.Status[]>([]);
  const [statusInput, setStatusInput] = useState<string>('');

  useEffect(() => {
    setStatusOptions(filteredStatusOptions(organizationId));
  }, [organizationId]);

  useEffect(() => {
    const { query } = location;
    const { toastMsg } = query;
    if (toastMsg) onCreateToast(toastMsg as string);
  }, [location, onCreateToast]);

  useEffect(() => {
    setStatus(statusProp);
    setStatusInput(statusToInputProps(statusProp, organizationId));
  }, [statusProp, organizationId]);

  const checkedStatus = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    if (target) {
      const updatedStatus = target.checked
        ? [...status, target.value as io.flow.internal.v0.enums.Status]
        : filter(status, (s) => s !== target.value);
      setStatus(updatedStatus);
      setStatusInput(statusToInputProps(updatedStatus, organizationId));
    }
  };

  const checkedAll = (): void => {
    if (status.length < statusOptions.length) {
      setStatus(statusOptions);
      setStatusInput('All');
    } else {
      setStatus([]);
      setStatusInput('');
    }
  };

  const handleUpdateExperimentStatus = (experiment: io.flow.internal.v0.unions.Experiment,
    experimentStatus: io.flow.internal.v0.enums.Status): void => {
    if (experimentStatus === 'ended') {
      toggleEndExperimentModal(experiment);
    } else {
      onUpdateExperimentStatus(experiment.key, experimentStatus);
    }
  };

  const emptyExperimentStatus = status.length === statusOptions.length || status.length === 0 ? 'experiments' : `${status.join(' or ')} experiments`;
  const pendingEndExperiment = selectedExperiment !== undefined;
  return (
    <div className={styles.container}>
      <EndExperimentDialog
        isOpen={pendingEndExperiment}
        onCloseDialog={(): void => toggleEndExperimentModal()}
        onConfirm={(): Promise<RootActionTypes> => onUpdateExperimentStatus(selectedExperiment.key, 'ended')}
      />
      <Card>
        <CardHeader dividing>
          <Box justifyContent="between" alignItems="center">
            <CardTitle>
              All Experiments
            </CardTitle>
            <OutlineButton
              content="Add Experiment"
              leftIcon={Plus}
              intent="primary"
              size="medium"
              onClick={onNewExperiment}
            />
          </Box>
          <Box style={{ paddingTop: '8px' }} alignItems="center">
            <span className={styles.statusLabel}>
              Status:
            </span>
            <Dropdown
              trigger={(
                <Button rightIcon={ChevronDown} style={{ minWidth: '130px' }} className={styles.selectedStatusLabel}>
                  {statusInput}
                </Button>
              )}
              closeOnSelection={false}
              onClose={(): void => onRequestFilter({ status })}
            >
              <div>
                {statusOptions.map((opt) => (
                  <div className={styles.checkboxRow} key={opt}>
                    <Checkbox
                      className={styles.statusBox}
                      onChange={checkedStatus}
                      value={opt}
                      checked={status.includes(opt)}
                      labelText={capitalize(opt)}
                    />
                  </div>
                ))}
                <div className={styles.allCheckboxRow}>
                  <Checkbox
                    className={styles.statusBox}
                    onChange={checkedAll}
                    checked={status.length === statusOptions.length}
                    indeterminate={status.length < statusOptions.length && status.length > 0}
                    labelText="All"
                  />
                </div>
              </div>
            </Dropdown>
          </Box>

        </CardHeader>
        <CardContent className={styles.listContainer}>
          {
            (experiments && experiments.length) ? (
              <ExperimentListTable
                onEditExperiment={onEditExperiment}
                onUpdateExperimentStatus={handleUpdateExperimentStatus}
                onViewExperiment={onViewExperiment}
                onDeleteExperiment={onDeleteExperiment}
                experiments={experiments}
              />
            ) : (
              <CardContent fitted>
                <CardEmptyState content={`No ${emptyExperimentStatus}. Click the Add an Experiment button to start.`} />
              </CardContent>
            )
          }
        </CardContent>
      </Card>
      <EditExperimentWorksheet organizationId={organizationId} />
    </div>
  );
};

ExperimentsList.displayName = 'ExperimentsList';

ExperimentsList.defaultProps = {
  status: [],
};

export default ExperimentsList;
