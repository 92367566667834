import { Field } from 'redux-form';
import React from 'react';
import classNames from 'classnames/bind';
import curry from 'lodash/curry';
import get from 'lodash/get';
import find from 'lodash/find';
import styles from './QueryFilter.module.css';
import { SelectField } from '../../../../components/ReduxFormFieldAdapters';

const cx = classNames.bind(styles);

const formatValue = curry((
  availableFilters: io.flow.v0.models.AvailableFilterStructured[],
  value: any,
) => find(availableFilters, { field: value }));
const parseValue = curry((value) => get(value, 'field'));

interface QueryFilterFieldProps {
  availableFilters: io.flow.v0.models.AvailableFilterStructured[];
  name: string;
}

class QueryFilterField extends React.PureComponent<QueryFilterFieldProps> {
  static displayName = 'QueryFilterField';

  static formatValue = formatValue;

  static parseValue = parseValue;

  render() {
    const {
      availableFilters,
      name,
    } = this.props;

    return (
      <Field
        component={SelectField}
        name={name}
        format={formatValue(availableFilters)}
        parse={parseValue}
        className={cx('queryFilterField')}
        intent="primary"
        selection
        options={availableFilters}
        labelKey="field"
        valueKey="field"
      />
    );
  }
}

export default QueryFilterField;
