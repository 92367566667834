import { RootActionTypes } from '../../../stores/types';
import { ActionTypes } from '../constants';

const deleteDialog = ({ type }: { type: string }): RootActionTypes => ({
  type: ActionTypes.DELETE_DIALOG,
  payload: {
    type,
  },
});

export default deleteDialog;
