import assign from 'lodash/assign';
import { $HttpResponse } from '@flowio/api-sdk';
import ActionTypes from '../constants/action-types';
import withPagingAndSorting, { WithPagingAndSortingOptions, isPagingAndSortingResponse } from '../../../utilities/with-paging-and-sorting-v2';
import { removeOperator } from '../../../utilities/query';
import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import { WithPagingAndSortingResult, WithPagingAndSortingResponse } from '../../../utilities/types';

const createRequestAction = (options: Partial<WithPagingAndSortingOptions>): RootActionTypes => ({
  type: ActionTypes.FETCH_ORDER_DETAILS_REQUEST,
  options,
});

const createSuccessAction = (
  payload: WithPagingAndSortingResult<io.flow.internal.v0.models.OrderDetail[]>,
  options: Partial<WithPagingAndSortingOptions>,
): RootActionTypes => ({
  type: ActionTypes.FETCH_ORDER_DETAILS_SUCCESS,
  payload,
  options,
});

const createFailureAction = (
  errors: FormattedErrorMessages,
  options: Partial<WithPagingAndSortingOptions>,
): RootActionTypes => ({
  type: ActionTypes.FETCH_ORDER_DETAILS_FAILURE,
  options,
  error: true,
  payload: errors,
});

export default function fetchOrderDetails(
  organization: string,
  options: Partial<WithPagingAndSortingOptions> = {},
): ThunkResult<Promise<void>> {
  return (dispatch, getState, extra): Promise<void> => {
    const optionsCopy: Partial<WithPagingAndSortingOptions> = assign({}, options);

    if (options.q) {
      optionsCopy.q = removeOperator(options.q, 'status:all');
    }
    dispatch(createRequestAction(optionsCopy));

    return withPagingAndSorting<io.flow.internal.v0.models.OrderDetail[]>(
      options,
      (params) => extra.apiInternal(getState()).orderDetails.get({
        organization,
        ...params,
      }),
    )().then((
      response: WithPagingAndSortingResponse<
      io.flow.internal.v0.models.OrderDetail[]> |
      $HttpResponse<io.flow.v0.models.GenericError
      >,
    ) => {
      if (isPagingAndSortingResponse<io.flow.internal.v0.models.OrderDetail[]>(response)) {
        dispatch(createSuccessAction(response.result, optionsCopy));
        return;
      }

      const errors = formatErrorResponseV2(response);
      dispatch(createFailureAction(errors, optionsCopy));
    });
  };
}
