import { connect, MapStateToProps } from 'react-redux';
import { compose } from 'redux';
import { withFetch } from '@flowio/redux-fetch';

import { fetchDeliveredDutyOptions, fetchExperience } from '../../../actions';
import { getCurrentExperienceKey } from '../../../selectors';
import { fetchOrganization } from '../../../../organization/actions';
import { getOrganizationId } from '../../../../organization/selectors';
import FormName from '../../../constants/FormName';
import LandedCostIndex from '../components/LandedCostIndex';
import fetchDeliveredDutyPreference from '../../../actions/fetchDeliveredDutyPreference';
import withPromptUnsavedChange from '../../../../../components/withPromptUnsavedChange';
import { ThunkDispatcher, RootState } from '../../../../../stores/types';
import { StateProps, OwnProps } from '../types';

function fetchData(
  dispatch: ThunkDispatcher,
  _getState: () => RootState,
  props: OwnProps,
): Promise<unknown[]> {
  const { organization, experienceKey } = props.params;
  return Promise.all([
    dispatch(fetchOrganization(organization)),
    dispatch(fetchExperience(organization, experienceKey)),
    dispatch(fetchDeliveredDutyOptions(organization, experienceKey)),
    dispatch(fetchDeliveredDutyPreference(organization, experienceKey)),
  ]);
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = (state) => ({
  organizationId: getOrganizationId(state),
  experienceKey: getCurrentExperienceKey(state),
});

const LEAVE_MESSAGE = 'You\'ve made changes to Landed Cost settings. Are you sure you want to leave the page without saving?';

export default compose<React.FC>(
  withFetch(fetchData),
  connect(mapStateToProps),
  withPromptUnsavedChange(FormName.LANDED_COST, LEAVE_MESSAGE),
)(LandedCostIndex);
