import { css } from '@emotion/css';
import colors from '../../../../theme/tokens/colors';

export const globalSearchbox = css`
  border: 0;
  padding: 0px 8px 0px 8px;

  & span {
    font-size: 12px;
  }
`;

export const searchInput = css`
  background: ${colors.charcoal};
  color: white;
  border-radius: 5px;
  height: 30px;
  border: none !important;
  color: ${colors.white} !important;
`;

export const menuItem = css`
  &:hover {
    background-color: ${colors['flow-blue'][100]};
  }
`;
