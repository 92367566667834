import { Plus, Ellipsis, Lens } from '@flowio/react-icons';
import React, { Component } from 'react';
import ApiPropTypes from '@flowio/api-prop-types';
import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
  CardFooter,
  CardEmptyState,
  CardTitle,
} from '@flowio/react-card';
import {
  Dropdown, DropdownMenu, DropdownItem, DropdownDivider,
} from '@flowio/react-dropdown';
import {
  Table,
  TableHead,
  TableRow, TableRowColumn,
  TableHeadColumn,
  TableBody,

} from '@flowio/react-table';
import { Button, IconButton } from '@flowio/react-button';
import { Box } from '@flowio/react-box';
import { css } from '@emotion/react';

import {
  Page,
  PageHeader,
  PageContent,
  PageActions,
} from '@flowio/react-page';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import classNames from 'classnames/bind';

import styles from './PriceBookList.module.css';
import Pagination from '../../../../components/pagination';
import DeletePriceBookConfirm from '../../containers/DeletePriceBookConfirm';
import UpsertPriceBookWorksheet from '../../containers/UpsertPriceBookWorksheet';
import PriceBookImport from '../../containers/PriceBookImport';
import ExportPriceBookConfirm from '../../containers/ExportPriceBookConfirm';

const cx = classNames.bind(styles);

const menuButtonStyles = css({
  marginTop: '7px',
});

class PriceBookList extends Component {
  constructor(props, context) {
    super(props, context);

    this.onTableRowClick = this.onTableRowClick.bind(this);
  }

  handleRequestNextPage = () => {
    const {
      pageNumber,
      onRequestPage,
    } = this.props;
    onRequestPage(parseInt(pageNumber, 10) + 1);
  }

  handleRequestPreviousPage = () => {
    const {
      pageNumber,
      onRequestPage,
    } = this.props;
    onRequestPage(parseInt(pageNumber, 10) - 1);
  }

  onTableRowClick = (priceBookKey) => {
    const {
      organization,
      onRequestDetails,
    } = this.props;

    onRequestDetails(organization, priceBookKey);
  }

  render() {
    const {
      currencies,
      onAddPriceBook,
      onDeletePriceBook,
      onEditPriceBook,
      priceBookExportTypes,
      organization,
      priceBooks,
      firstPage,
      lastPage,
      email,
      onUploadPriceBook,
      onExportPriceBook,
    } = this.props;
    return (
      <Page className={cx('page')}>
        <PageHeader>
          <Box alignItems="center" justifyContent="end">
            <PageActions>
              <Box alignItems="center" justifyContent="between">
                <Button
                  className={cx('uploadBtn')}
                  content="Upload Prices via CSV"
                  intent="primary"
                  leftIcon={Plus}
                  onClick={() => onUploadPriceBook(organization.id)}
                />
                <a href="https://cdn.flow.io/util/import/templates/price_book_items_sample.csv">
                  <Button content="Download Blank Template" />
                </a>
              </Box>
            </PageActions>
          </Box>
        </PageHeader>
        <PageContent>
          <Card>
            <CardHeader dividing>
              <Box justifyContent="between" alignItems="center">
                <CardTitle content="Price Books" />
                <CardActions
                  actions={[{
                    content: 'Add Price Book',
                    onClick() {
                      onAddPriceBook(organization.id);
                    },
                  }]}
                />
              </Box>
            </CardHeader>
            <CardContent fitted>
              {!isEmpty(priceBooks) && (
                <Table striped className={cx('priceBookTable')}>
                  <TableHead>
                    <TableRow>
                      <TableHeadColumn>Price Book</TableHeadColumn>
                      <TableHeadColumn>Key</TableHeadColumn>
                      <TableHeadColumn>Currency</TableHeadColumn>
                      <TableHeadColumn>Tax and Duty</TableHeadColumn>
                      <TableHeadColumn>Status</TableHeadColumn>
                      <TableHeadColumn />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {priceBooks.map((priceBook, index) => {
                      let taxAndDutyMessage;

                      switch (priceBook.includes.key) {
                        case 'duty':
                          taxAndDutyMessage = 'Duty Only';
                          break;
                        case 'vat':
                          taxAndDutyMessage = 'Tax Only';
                          break;
                        case 'vat_and_duty':
                          taxAndDutyMessage = 'Included';
                          break;
                        default:
                          taxAndDutyMessage = 'Excluded';
                          break;
                      }
                      return (
                        <TableRow
                          striped={index % 2 === 1}
                          key={priceBook.id}
                          className={cx('priceBookRow')}
                          onClick={() => this.onTableRowClick(priceBook.key)}
                        >
                          <TableRowColumn>{priceBook.name}</TableRowColumn>
                          <TableRowColumn>{priceBook.key}</TableRowColumn>
                          <TableRowColumn>{priceBook.currency}</TableRowColumn>
                          <TableRowColumn>{taxAndDutyMessage}</TableRowColumn>
                          <TableRowColumn className={cx(priceBook.status)}>
                            <Lens className={cx('statusIcon')} />
                            {priceBook.status}
                          </TableRowColumn>
                          <TableRowColumn className={cx('actionRow')}>
                            <Dropdown
                              onClick={(e) => e.stopPropagation()}
                              trigger={<IconButton css={menuButtonStyles} icon={Ellipsis} size="small" />}
                            >
                              <DropdownMenu>
                                <DropdownItem
                                  content="Export"
                                  onClick={() => {
                                    onExportPriceBook(organization.id, priceBook, email);
                                  }}
                                />
                                <DropdownDivider />
                                <DropdownItem
                                  content="Edit"
                                  onClick={() => {
                                    onEditPriceBook(organization.id, priceBook);
                                  }}
                                />
                                <DropdownDivider />
                                <DropdownItem
                                  content="Delete"
                                  onClick={() => {
                                    onDeletePriceBook(organization.id, priceBook);
                                  }}
                                />
                              </DropdownMenu>
                            </Dropdown>
                          </TableRowColumn>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              )}
              {isEmpty(priceBooks) && (
                <CardEmptyState content="No price books available" />
              )}
            </CardContent>
            <CardFooter>
              <Pagination
                isNextPageDisabled={lastPage}
                isPreviousPageDisabled={firstPage}
                onRequestNextPage={this.handleRequestNextPage}
                onRequestPreviousPage={this.handleRequestPreviousPage}
              />
            </CardFooter>
          </Card>
          <DeletePriceBookConfirm />
          <ExportPriceBookConfirm priceBookExportTypes={priceBookExportTypes} />
          <UpsertPriceBookWorksheet currencies={currencies} />
          <PriceBookImport />
        </PageContent>
      </Page>
    );
  }
}

PriceBookList.propTypes = {
  currencies: PropTypes.arrayOf(ApiPropTypes.currency).isRequired,
  organization: ApiPropTypes.organization.isRequired,
  priceBooks: PropTypes.arrayOf(ApiPropTypes.priceBook),
  firstPage: PropTypes.bool.isRequired,
  lastPage: PropTypes.bool.isRequired,
  pageNumber: PropTypes.string.isRequired,
  onUploadPriceBook: PropTypes.func,
  onAddPriceBook: PropTypes.func.isRequired,
  onDeletePriceBook: PropTypes.func.isRequired,
  onEditPriceBook: PropTypes.func.isRequired,
  onRequestPage: PropTypes.func.isRequired,
  onRequestDetails: PropTypes.func.isRequired,
  onExportPriceBook: PropTypes.func.isRequired,
  priceBookExportTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  email: PropTypes.string.isRequired,
};

PriceBookList.defaultProps = {
  priceBooks: undefined,
  onUploadPriceBook: noop,
};

export default PriceBookList;
