import { createSelector } from 'reselect';
import getFacebook from './getFacebook';
import StatusTypes from '../constants/status-types';

const getIsConnected = createSelector(
  getFacebook,
  (facebook) => facebook?.loginStatus?.status === StatusTypes.connected,
);

export default getIsConnected;
