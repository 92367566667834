import { createTheme } from '@flowio/react-theme';
import themeCorporate from '@flowio/react-theme-corporate';
import {
  colors,
  fonts,
  fontSize,
  fontWeight,
  lineHeight,
  radius,
} from './tokens';

const theme = createTheme({
  ...themeCorporate,
  border: {
    radius: {
      ...themeCorporate.border.radius,
      banner: radius.medium,
      checkbox: 3,
      image: radius.medium,
    },
  },
  buttons: {
    shadows: 'none',
  },
  size: {
    ...themeCorporate.size,
    button: {
      small: {
        height: 24,
        padding: 4,
      },
      medium: {
        height: 32,
        padding: 8,
      },
      large: {
        height: 44,
        padding: 14,
      },
    },
  },
  color: {
    background: {
      ...themeCorporate.color.background,
      banner: {
        info: colors.blue[600],
        negative: colors.red[200],
        neutral: colors.neutral[300],
        positive: colors.green[200],
        warning: colors.yellow[200],
      },
      button: {
        flat: {
          negative: {
            active: colors.red[700],
            disabled: colors.neutral[100],
            focused: colors.red[400],
            hover: colors.red[400],
          },
          neutral: {
            active: colors.neutral[400],
            disabled: colors.neutral[100],
            focused: colors.neutral[400],
            hover: colors.neutral[400],
          },
          positive: {
            active: colors.green[700],
            disabled: colors.neutral[100],
            focused: colors.green[400],
            hover: colors.green[400],
          },
          primary: {
            active: colors.blue[700],
            disabled: colors.neutral[100],
            focused: colors.blue[400],
            hover: colors.blue[400],
          },
          warning: {
            active: colors.yellow[700],
            disabled: colors.neutral[100],
            focused: colors.yellow[400],
            hover: colors.yellow[400],
          },
        },
        outline: {
          negative: {
            active: colors.transparent,
            disabled: colors.neutral[100],
            focused: colors.transparent,
            hover: colors.transparent,
          },
          neutral: {
            active: colors.transparent,
            disabled: colors.neutral[100],
            focused: colors.transparent,
            hover: colors.transparent,
          },
          positive: {
            active: colors.transparent,
            disabled: colors.neutral[100],
            focused: colors.transparent,
            hover: colors.transparent,
          },
          primary: {
            active: colors.transparent,
            disabled: colors.neutral[100],
            focused: colors.transparent,
            hover: colors.transparent,
          },
          warning: {
            active: colors.transparent,
            disabled: colors.neutral[100],
            focused: colors.transparent,
            hover: colors.transparent,
          },
        },
        solid: {
          negative: {
            active: colors.red[700],
            base: colors.red[500],
            disabled: colors.neutral[100],
            focused: colors.red[400],
            hover: colors.red[400],
          },
          neutral: {
            active: colors.neutral[400],
            base: colors.white,
            disabled: colors.neutral[100],
            focused: colors.neutral[400],
            hover: colors.neutral[400],
          },
          positive: {
            active: colors.green[700],
            base: colors.green[700],
            disabled: colors.neutral[100],
            focused: colors.green[400],
            hover: colors.green[400],
          },
          primary: {
            active: colors.blue[700],
            base: colors.blue[500],
            disabled: colors.neutral[100],
            focused: colors.blue[400],
            hover: colors.blue[400],
          },
          warning: {
            active: colors.yellow[700],
            base: colors.yellow[500],
            disabled: colors.neutral[100],
            focused: colors.yellow[400],
            hover: colors.yellow[400],
          },
        },
      },
      checkbox: {
        neutral: {
          checked: colors.blue[500],
          checkedDisabled: colors.neutral[300],
          unchecked: colors.white,
          uncheckedDisabled: colors.neutral[300],
        },
        negative: {
          checked: colors.red[500],
          checkedDisabled: colors.neutral[300],
          unchecked: colors.red[100],
          uncheckedDisabled: colors.neutral[300],
        },
      },
      menuItem: {
        active: colors.blue[500],
        base: colors.white,
        disabled: '#FAFBFC',
        highlighted: colors.blue[200],
      },
    },
    border: {
      ...themeCorporate.color.border,
      button: {
        outline: {
          negative: {
            active: colors.red[700],
            base: colors.red[500],
            disabled: colors.neutral[500],
            focused: colors.red[400],
            hover: colors.red[300],
          },
          neutral: {
            active: colors.neutral[800],
            base: colors.neutral[700],
            disabled: colors.neutral[500],
            focused: colors.neutral[500],
            hover: colors.neutral[500],
          },
          positive: {
            active: colors.green[700],
            base: colors.green[500],
            disabled: colors.neutral[500],
            focused: colors.green[400],
            hover: colors.green[300],
          },
          primary: {
            active: colors.blue[700],
            base: colors.blue[500],
            disabled: colors.neutral[500],
            focused: colors.blue[400],
            hover: colors.blue[300],
          },
          warning: {
            active: colors.yellow[700],
            base: colors.yellow[500],
            disabled: colors.neutral[500],
            focused: colors.yellow[400],
            hover: colors.yellow[300],
          },
        },
        solid: {
          negative: {
            active: colors.red[700],
            base: colors.red[500],
            disabled: colors.neutral[500],
            focused: colors.red[400],
            hover: colors.red[400],
          },
          neutral: {
            active: colors.neutral[400],
            base: colors.neutral[500],
            disabled: colors.neutral[500],
            focused: colors.neutral[400],
            hover: colors.neutral[400],
          },
          positive: {
            active: colors.green[700],
            base: colors.green[500],
            disabled: colors.neutral[500],
            focused: colors.green[400],
            hover: colors.green[400],
          },
          primary: {
            active: colors.blue[700],
            base: colors.blue[500],
            disabled: colors.neutral[500],
            focused: colors.blue[400],
            hover: colors.blue[400],
          },
          warning: {
            active: colors.yellow[700],
            base: colors.yellow[500],
            disabled: colors.neutral[500],
            focused: colors.yellow[400],
            hover: colors.yellow[400],
          },
        },
      },
      checkbox: {
        negative: {
          checked: colors.red[500],
          checkedDisabled: colors.neutral[500],
          unchecked: colors.red[500],
          uncheckedDisabled: colors.neutral[500],
        },
        neutral: {
          checked: colors.blue[500],
          checkedDisabled: colors.neutral[500],
          unchecked: colors.blue[500],
          uncheckedDisabled: colors.neutral[500],
        },
      },
      notchedOutline: {
        base: colors.neutral[400],
        disabled: colors.neutral[200],
        focused: colors.blue[500],
        negative: colors.red[500],
        positive: colors.green[500],
        warning: colors.yellow[500],
      },
      tabButton: {
        base: colors.blue[400],
        active: colors.blue[500],
      },
    },
    icon: {
      menuItemSelectedIndicator: colors.blue[500],
    },
    text: {
      ...themeCorporate.color.text,
      banner: {
        info: colors.white,
        negative: colors.red[700],
        neutral: colors.black,
        positive: colors.black,
        warning: colors.black,
      },
      button: {
        flat: {
          negative: {
            active: colors.red[100],
            base: colors.red[100],
            disabled: colors.neutral[500],
            focused: colors.red[100],
            hover: colors.red[100],
          },
          neutral: {
            active: colors.neutral[800],
            base: colors.neutral[800],
            disabled: colors.neutral[500],
            focused: colors.neutral[800],
            hover: colors.neutral[800],
          },
          positive: {
            active: colors.green[100],
            base: colors.green[100],
            disabled: colors.neutral[500],
            focused: colors.green[100],
            hover: colors.green[100],
          },
          primary: {
            active: colors.blue[100],
            base: colors.blue[100],
            disabled: colors.neutral[500],
            focused: colors.blue[100],
            hover: colors.blue[100],
          },
          warning: {
            active: colors.yellow[100],
            base: colors.yellow[100],
            disabled: colors.neutral[500],
            focused: colors.yellow[100],
            hover: colors.yellow[100],
          },
        },
        outline: {
          negative: {
            active: colors.red[500],
            base: colors.red[500],
            disabled: colors.neutral[500],
            focused: colors.red[500],
            hover: colors.red[300],
          },
          neutral: {
            active: colors.neutral[800],
            base: colors.neutral[700],
            disabled: colors.neutral[300],
            focused: colors.neutral[700],
            hover: colors.neutral[700],
          },
          positive: {
            active: colors.green[700],
            base: colors.green[500],
            disabled: colors.neutral[500],
            focused: colors.green[500],
            hover: colors.green[300],
          },
          primary: {
            active: colors.blue[700],
            base: colors.blue[500],
            disabled: colors.neutral[500],
            focused: colors.blue[500],
            hover: colors.blue[300],
          },
          warning: {
            active: colors.yellow[700],
            base: colors.yellow[500],
            disabled: colors.neutral[500],
            focused: colors.yellow[500],
            hover: colors.yellow[300],
          },
        },
        solid: {
          negative: {
            active: colors.red[100],
            base: colors.red[100],
            disabled: colors.neutral[500],
            focused: colors.red[100],
            hover: colors.red[100],
          },
          neutral: {
            active: colors.neutral[800],
            base: colors.neutral[800],
            disabled: colors.neutral[500],
            focused: colors.neutral[800],
            hover: colors.neutral[800],
          },
          positive: {
            active: colors.green[100],
            base: colors.green[100],
            disabled: colors.neutral[500],
            focused: colors.green[100],
            hover: colors.green[100],
          },
          primary: {
            active: colors.blue[100],
            base: colors.blue[100],
            disabled: colors.neutral[500],
            focused: colors.blue[100],
            hover: colors.blue[100],
          },
          warning: {
            active: colors.yellow[100],
            base: colors.yellow[100],
            disabled: colors.neutral[500],
            focused: colors.yellow[100],
            hover: colors.yellow[100],
          },
        },
        link: {
          base: colors.blue[600],
          disabled: colors.blue[600],
          active: colors.blue[700],
          focused: colors.blue[600],
          hover: colors.blue[600],
        },
      },
      tabButton: {
        base: colors.neutral[900],
      },
      floatingLabelInput: {
        clearIndicator: colors.neutral[900],
        control: {
          disabled: colors.neutral[700],
          base: colors.neutral[900],
        },
        icon: {
          base: colors.neutral[700],
          disabled: colors.neutral[600],
          negative: colors.red[500],
          positive: colors.green[500],
          warning: colors.yellow[500],
        },
      },
      menuItem: {
        active: colors.white,
        base: '#4D5866',
        disabled: '#B5BEC9',
      },
    },
  },
  palette: {
    negative: colors.red[500],
    positive: colors.green[500],
    primary: colors.blue[500],
    warning: colors.yellow[500],
    text: {
      primary: colors.neutral[900],
      secondary: colors.neutral[800],
      tertiary: colors.neutral[700],
    },
  },
  shadow: {
    ...themeCorporate.shadow,
    tabButton: {
      active: `inset 0 0 0 1px ${colors.blue[500]}`,
    },
  },
  typography: {
    ...themeCorporate.typography,
    h1: {
      fontFamily: fonts.heading,
      fontSize: fontSize[900],
      fontWeight: fontWeight.regular,
      lineHeight: lineHeight[900],
    },
    h2: {
      fontFamily: fonts.heading,
      fontSize: fontSize[800],
      fontWeight: fontWeight.regular,
      lineHeight: lineHeight[800],
    },
    h3: {
      fontFamily: fonts.heading,
      fontSize: fontSize[700],
      fontWeight: fontWeight.regular,
      lineHeight: lineHeight[700],
    },
    h4: {
      fontFamily: fonts.heading,
      fontSize: fontSize[600],
      fontWeight: fontWeight.regular,
      lineHeight: lineHeight[600],
    },
    h5: {
      fontFamily: fonts.heading,
      fontSize: fontSize[500],
      fontWeight: fontWeight.regular,
      lineHeight: lineHeight[500],
    },
    h6: {
      fontFamily: fonts.heading,
      fontSize: fontSize[400],
      fontWeight: fontWeight.regular,
      lineHeight: lineHeight[400],
    },
    body: {
      fontFamily: fonts.heading,
      fontSize: fontSize[200],
      fontWeight: fontWeight.regular,
      lineHeight: lineHeight[200],
    },
    banner: {
      text: {
        fontFamily: fonts.body,
        fontWeight: fontWeight.medium,
        fontSize: fontSize[200],
        lineHeight: lineHeight[400],
      },
      title: {
        fontFamily: fonts.heading,
        fontWeight: fontWeight.bold,
        fontSize: fontSize[300],
        lineHeight: lineHeight[300],
      },
    },
    button: {
      small: {
        fontFamily: fonts.roboto,
        fontWeight: fontWeight.medium,
        fontSize: fontSize[100],
        lineHeight: lineHeight[100],
      },
      medium: {
        fontFamily: fonts.roboto,
        fontWeight: fontWeight.medium,
        fontSize: fontSize[200],
        lineHeight: lineHeight[200],
      },
      large: {
        fontFamily: fonts.roboto,
        fontWeight: fontWeight.medium,
        fontSize: fontSize[400],
        lineHeight: lineHeight[400],
      },
    },
    caption: {
      fontFamily: fonts.heading,
      fontSize: fontSize[100],
      fontWeight: fontWeight.regular,
      lineHeight: lineHeight[200],
    },
  },
  confirm: {
    minButtonWidth: '150px',
  },
});

export const contentFrameMaxWidth = '1440px';

export default theme;
