import { Box } from '@flowio/react-box';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router';
import map from 'lodash/map';
import BemHelper from '@flowio/bem-helper';
import {
  Card, CardHeader, CardContent, CardFooter, CardDescription, CardTitle,
} from '@flowio/react-card';
import { Button } from '@flowio/react-button';
import Alert from '../../../../components/alert/alert';
import DeleteConfirmationDialog from '../delete-confirmation-dialog';

type Props = {
  onRequestAddWebhook: () => void;
  onRequestEditWebhook: (webhook: io.flow.v0.models.Webhook) => void;
  onRequestDeleteWebhook: (webhook: io.flow.v0.models.Webhook) => void;
  organization: string;
  showCreatedNotification?: boolean;
  showDeletedNotification?: boolean;
  webhooks?: io.flow.v0.models.Webhook[];
};

if (process.browser) {
  require('./webhook-overview.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('webhook-overview');

const WebhookOverview: React.FC<Props> = ({
  webhooks = [],
  onRequestEditWebhook,
  onRequestDeleteWebhook,
  organization,
  onRequestAddWebhook,
  showCreatedNotification = false,
  showDeletedNotification = false,
}) => {
  const [showCreatedNotificationState, setShowCreatedNotification] = useState<boolean>(false);
  const [showDeletedNotificationState, setShowDeletedNotification] = useState<boolean>(false);
  const [showDeleteWebhookConfirm, setShowDeleteWebhookConfirm] = useState<boolean>(false);
  const [webhookToBeDeleted, setWebhookToBeDeleted] = useState<io.flow.v0.models.Webhook | null>();

  useEffect(() => {
    setShowCreatedNotification(showCreatedNotification);
    setShowDeletedNotification(showDeletedNotification);
    setShowDeleteWebhookConfirm(false);
    setWebhookToBeDeleted(null);
  }, [showCreatedNotification, showDeletedNotification]);

  const handleDismissCreateNotification = () => {
    setShowCreatedNotification(false);
  };

  const handleDismissDeleteNotification = () => {
    setShowDeletedNotification(false);
  };

  const handleEditButtonPress = (webhook: io.flow.v0.models.Webhook) => {
    if (onRequestEditWebhook) {
      onRequestEditWebhook(webhook);
    }
  };

  const handleDeleteButtonPress = (webhook: io.flow.v0.models.Webhook) => {
    setShowDeleteWebhookConfirm(true);
    setWebhookToBeDeleted(webhook);
  };

  const handleDeleteConfirmation = () => {
    const webhook = webhookToBeDeleted;
    setShowDeleteWebhookConfirm(false);
    setWebhookToBeDeleted(null);
    if (webhook) {
      onRequestDeleteWebhook(webhook);
    }
  };

  const handleCloseDeleteConfirmation = () => {
    setShowDeleteWebhookConfirm(false);
    setWebhookToBeDeleted(null);
  };

  return (
    <Card>
      <CardHeader dividing>
        {(() => {
          if (!showCreatedNotificationState) return null;
          return (
            <Alert dismissible onRequestDismiss={handleDismissCreateNotification}>
              Okay, that webhook was created successfully.
            </Alert>
          );
        })()}
        {(() => {
          if (!showDeletedNotificationState) return null;
          return (
            <Alert dismissible onRequestDismiss={handleDismissDeleteNotification}>
              Okay, the webhook was deleted successfully.
            </Alert>
          );
        })()}
        <Box alignItems="center" justifyContent="between">
          <CardTitle>Webhooks</CardTitle>
          <Button intent="primary" content="Add webhook" onClick={onRequestAddWebhook} />
        </Box>
        <CardDescription>
          Webhooks allow external services to be notified when certain events happen in Flow
          and your integration. When the specified events happen, Flow will send a POST
          request to each of the URLs you provide.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <ul className={bem.element('list-group')}>
          {map(webhooks, (webhook) => (
            <li className={bem.element('list-item')} key={webhook.id}>
              <Link to={`/${organization}/organization/integrations/webhooks/${webhook.id}`}>
                {webhook.url}
              </Link>
              <div className={bem.element('action-container')}>
                <Button
                  css={{
                    marginRight: '10px',
                  }}
                  variant="outline"
                  intent="primary"
                  content="Edit"
                  onClick={() => {
                    handleEditButtonPress(webhook);
                  }}
                />
                <Button
                  variant="outline"
                  intent="negative"
                  content="Delete"
                  onClick={() => {
                    handleDeleteButtonPress(webhook);
                  }}
                />
              </div>
            </li>
          ))}
        </ul>
      </CardContent>
      <CardFooter />
      <DeleteConfirmationDialog
        open={showDeleteWebhookConfirm}
        webhookUrl={webhookToBeDeleted ? webhookToBeDeleted.url : ''}
        onRequestClose={handleCloseDeleteConfirmation}
        onRequestConfirm={handleDeleteConfirmation}
      />
    </Card>
  );
};

WebhookOverview.displayName = 'WebhookOverview';

export default WebhookOverview;
