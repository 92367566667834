import { reduxFormWorksheet } from '@flowio/redux-form-overlay';

import { createToast } from '../../console/actions';
import { updateShippingConfiguration } from '../actions';
import FormName from '../constants/FormName';
import UpdateShippingConfigurationForm from '../components/UpdateShippingConfigurationForm';
import handleSubmitResult from '../utilities/handleSubmitResult';
import { UpdateShippingConfigurationFormValues } from '../types/components';
import { RootActionTypes, ThunkDispatcher } from '../../../stores/types';
import { ActionTypes } from '../constants';

/**
 * @typedef {Object} UpdateShippingConfigurationFormValues
 * @property {String} shippingConfigurationKey
 * @property {String} name
 * @property {String} organizationId
 */

/**
 * Creates a shipping configuration form from form values.
 * @param {UpdateShippingConfigurationFormValues} values
 * @returns {ShippingConfigurationForm}
 */
function shippingConfigurationFormFromValues(values: UpdateShippingConfigurationFormValues) {
  return { name: values.name };
}

/**
 * Handles side effects upon requesting submission of shipping
 * configuration form.
 * @param {UpdateShippingConfigurationFormValues} values
 * @param {Function} dispatch
 * @param {Object} props
 */
function handleSubmit(values: UpdateShippingConfigurationFormValues, dispatch: ThunkDispatcher) {
  const { organizationId, shippingConfigurationKey } = values;
  return dispatch(updateShippingConfiguration(
    organizationId,
    shippingConfigurationKey,
    shippingConfigurationFormFromValues(values),
  )).then((result) => handleSubmitResult(
    result,
    [ActionTypes.UPDATE_SHIPPING_CONFIGURATION_FAILURE],
  ));
}

function handleSuccess(_action: RootActionTypes, dispatch: ThunkDispatcher) {
  dispatch(createToast({
    content: 'Shipping configuration has been updated.',
  }));
}

export default reduxFormWorksheet({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  form: FormName.UPDATE_SHIPPING_CONFIGURATION_FORM,
  onSubmit: handleSubmit,
  onSubmitSuccess: handleSuccess,
})(UpdateShippingConfigurationForm);
