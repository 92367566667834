import { createSelector } from 'reselect';
import identity from 'lodash/identity';
import get from 'lodash/get';
import { RootState } from '../../../stores/types';
import { NonReturnableState } from '../types';

const getNonReturnableState = createSelector<RootState, NonReturnableState, NonReturnableState>(
  identity,
  (state) => get(state, 'nonReturnable'),
);

export default getNonReturnableState;
