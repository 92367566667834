import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getCountries } from '../../reference/selectors';
import { getCenterQueryResult, getIsLoadingCenterQuery } from '../selectors';
import CenterQueryResult from '../components/CenterQueryResult';
import { RootState } from '../../../stores/types';
import { CenterQueryResultProps } from '../types/components';

const mapStateToProps = createStructuredSelector<RootState, CenterQueryResultProps>({
  centers: getCenterQueryResult,
  countries: getCountries,
  isLoading: getIsLoadingCenterQuery,
});

export default connect(mapStateToProps)(CenterQueryResult);
