import { createSelector } from 'reselect';
import get from 'lodash/get';
import pick from 'lodash/pick';
import values from 'lodash/values';

import getExperiences from './getExperiences';
import getExperiencesByShippingConfiguration from './getExperiencesByShippingConfiguration';

const getExperiencesByShippingConfigurationKey = (
  shippingConfigurationKey: string,
) => createSelector(
  getExperiences,
  getExperiencesByShippingConfiguration,
  (experiences, shippingConfigurations) => {
    const ids = get(shippingConfigurations, [shippingConfigurationKey, 'entities']);
    return values(pick(experiences, ids));
  },
);

export default getExperiencesByShippingConfigurationKey;
