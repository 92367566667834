import { TierRuleOutcomeDiscriminatorValue } from '../constants';

export default function tierRuleOutcomeToSentence(tierRule: io.flow.v0.models.TierRule): string {
  if (tierRule.outcome.discriminator === TierRuleOutcomeDiscriminatorValue.FLAT_RATE) {
    if (tierRule.outcome.price.amount === 0) {
      return 'Shipping is free';
    }
    return `Shipping is ${tierRule.outcome.price.label}`;
  }

  if (tierRule.outcome.discriminator === TierRuleOutcomeDiscriminatorValue.AMOUNT_MARGIN) {
    if (tierRule.outcome.margin.amount < 0) {
      return `Shipping is at cost - ${tierRule.outcome.margin.label} markup`;
    }
    return `Shipping is at cost + ${tierRule.outcome.margin.label} markup`;
  }

  if (tierRule.outcome.discriminator === TierRuleOutcomeDiscriminatorValue.PERCENT_MARGIN) {
    if (tierRule.outcome.percentage < 0) {
      return `Shipping is at cost - ${tierRule.outcome.percentage}% markup`;
    }
    return `Shipping is at cost + ${tierRule.outcome.percentage}% markup`;
  }

  if (tierRule.outcome.discriminator === TierRuleOutcomeDiscriminatorValue.AT_COST) {
    return 'Shipping is at cost';
  }

  return '';
}
