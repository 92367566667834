import { ThunkDispatcher, ThunkResult } from '../../../stores/types';
import standardAction from '../../../utilities/standard-action';
import ActionTypes from '../constants/action-types';
import StatusTypes from '../constants/status-types';
import { LoginStatusInfo } from '../types/misc';
import { getLoginStatus, getPermissions } from '../fbSDK';

const fetchLoginStatus = (): ThunkResult<Promise<LoginStatusInfo>> => (
  dispatch: ThunkDispatcher,
): Promise<LoginStatusInfo> => {
  dispatch(standardAction(ActionTypes.FETCH_LOGIN_STATUS_REQUEST));

  return getLoginStatus()
    .then((status) => {
      if (status === StatusTypes.connected) {
        return getPermissions()
          .then((permissions) => {
            const loginStatus = {
              status,
              permissions,
            };
            dispatch(standardAction(ActionTypes.FETCH_LOGIN_STATUS_SUCCESS, loginStatus));
            return loginStatus;
          });
      }
      dispatch(standardAction(ActionTypes.FETCH_LOGIN_STATUS_SUCCESS, { status }));
      return { status };
    })
    .catch((error) => {
      dispatch(standardAction(ActionTypes.FETCH_LOGIN_STATUS_FAILURE, error));
      throw error;
    });
};

export default fetchLoginStatus;
