import { SubmissionError } from 'redux-form';
import { compose } from 'redux';
import { reduxFormWorksheet } from '@flowio/redux-form-overlay';

import { createToast } from '../../console/actions';
import { createPriceBook, updatePriceBookByKey } from '../../resources/operations';
import FormName from '../constants/FormName';
import UpsertPriceBook from '../components/UpsertPriceBook';

function toPriceBookForm(values) {
  return {
    currency: values.currency,
    includes: values.includes,
    name: values.name,
    status: values.status,
  };
}

function handleValidation(values) {
  const errors = {};

  if (!values.currency) {
    errors.currency = 'Currency code is required';
  }

  if (!values.name) {
    errors.name = 'Name is required';
  }

  if (!values.includes) {
    errors.includes = 'Levies included is required';
  }

  if (!values.status) {
    errors.status = 'Status is required';
  }

  return errors;
}

function handleSubmit(values, dispatch) {
  const { key, organizationId } = values;

  const operation = key != null ? updatePriceBookByKey({
    organizationId,
    priceBookKey: key,
    priceBookForm: toPriceBookForm(values),
  }) : createPriceBook({
    organizationId,
    priceBookForm: toPriceBookForm(values),
  });

  return dispatch(operation).then((action) => {
    if (action.error) {
      throw new SubmissionError({
        _error: action.payload.response,
      });
    }

    return action;
  });
}

function handleSubmitSuccess(action, dispatch) {
  dispatch(createToast({
    content: 'Successfully created price book',
    intent: 'positive',
  }));
}

export default compose(
  reduxFormWorksheet({
    enableReinitialize: true,
    form: FormName.UPSERT_PRICE_BOOK_FORM,
    keepDirtyOnReinitialize: true,
    onSubmit: handleSubmit,
    onSubmitSuccess: handleSubmitSuccess,
    validate: handleValidation,
  }),
)(UpsertPriceBook);
