import { compose } from 'redux';
import { connect } from 'react-redux';
import { browserHistory, type RouterState } from 'react-router';
import { withFetch } from '@flowio/redux-fetch';
import pick from 'lodash/pick';

import { fetchOrganization } from '../../organization';
import {
  getErrors,
  getFeatureList,
  getIsAddFeatureWorksheetVisible,
  getIsControlOrganization,
} from '../selectors';
import {
  createQueryBuilderFromFilterForm,
  fetchFeatures,
  fetchFilterValuefromQueryBuilderQuery,
  requestStatusChange,
  goToFeatureDetailPage,
  showAddFeatureWorksheet,
  hideAddFeatureWorksheet,
} from '../actions';
import toQueryBuilderQueryForm from '../utilities/toQueryBuilderQueryForm';
import FeatureListing from '../components/FeatureListing';

import { toQueryBuilderFilterForm } from '../utilities';
import { RootState, ThunkDispatcher } from '../../../stores/types';

function getAsyncState(
  dispatch: ThunkDispatcher,
  _getState: () => RootState,
  ownProps: RouterState,
) {
  const { organization } = ownProps.params;
  const locationQuery = ownProps.location.query;
  return Promise.all([
    dispatch(fetchOrganization(organization)),
    dispatch(fetchFeatures(organization, { params: locationQuery })),
    dispatch(
      fetchFilterValuefromQueryBuilderQuery(organization, toQueryBuilderQueryForm(locationQuery.q)),
    ),
  ]);
}

function handleRequestPage(
  newPageNumber: number,
  routerState: RouterState,
  entriesPerPage = 25,
) {
  return browserHistory.push({
    pathname: routerState.location.pathname,
    query: { ...routerState.location.query, pageNumber: newPageNumber, entriesPerPage },
  });
}

function mapStateToProps(
  state: RootState,
  ownProps: RouterState,
) {
  const { organization } = ownProps.params;
  const paginationProps = pick(ownProps.location.query, ['pageNumber']);
  paginationProps.pageNumber = paginationProps.pageNumber
    && parseInt(paginationProps.pageNumber, 10);

  return {
    errors: getErrors(state),
    organization,
    isControlOrganization: getIsControlOrganization(state),
    features: getFeatureList(state),
    isAddFeatureWorksheetOpen: getIsAddFeatureWorksheetVisible(state),
    ...paginationProps,
  };
}

function mapDispatchToProps(
  dispatch: ThunkDispatcher,
  ownProps: RouterState,
) {
  const { organization } = ownProps.params;

  return {
    onFeatureViewDetailClick(organizationId: string, featureKey: string) {
      goToFeatureDetailPage(organizationId, featureKey);
    },
    onAddFeatureClick: (organizationId: string) => {
      dispatch(showAddFeatureWorksheet(organizationId));
    },
    onRequestCancelAddFeatureWorksheet: () => {
      dispatch(hideAddFeatureWorksheet());
    },
    onRequestFeatureListFilterChange: (filters: io.flow.v0.unions.QueryFilterForm[]) => {
      dispatch(createQueryBuilderFromFilterForm(organization, toQueryBuilderFilterForm(filters)));
    },
    onRequestStatusChange: (key: string, status: io.flow.internal.v0.enums.FeatureStatus) => {
      dispatch(requestStatusChange(organization, key, status));
    },
    onRequestNextPage: (pageNumber: number) => {
      handleRequestPage(pageNumber + 1, ownProps);
    },
    onRequestPreviousPage: (pageNumber: number) => {
      handleRequestPage(pageNumber - 1, ownProps);
    },
  };
}
export default compose<React.FC>(
  withFetch(getAsyncState),
  connect(mapStateToProps, mapDispatchToProps),
)(FeatureListing);
