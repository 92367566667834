import { Box, BoxItem } from '@flowio/react-box';
import React from 'react';
import { PageContent } from '@flowio/react-page';

import { Heading } from '@flowio/react-text';
import ActivityList from '../../containers/ActivityList';
import Order from '../../containers/Order';
import SearchBar from '../../containers/SearchBar';
import * as styles from './Fulfillment.styles';

const Fulfillment: React.FC = () => (
  <PageContent className={styles.pageContent}>
    <Heading className={styles.heading} variant="h3">Fulfillment</Heading>
    <Box spacing="extraLoose" className={styles.fullHeightBox}>
      <BoxItem className={styles.order}>
        <Box direction="column" spacing="loose" className={styles.fullHeightBox}>
          <SearchBar />
          <BoxItem className={styles.orderWrapper}>
            <Order />
          </BoxItem>
        </Box>
      </BoxItem>
      <BoxItem className={styles.activityListContainer}>
        <ActivityList />
      </BoxItem>
    </Box>
  </PageContent>
);

Fulfillment.displayName = 'Fulfillment';

export default Fulfillment;
