import { css } from '@emotion/css';

export const imports = css`
  margin-top: 1rem;

  & .dialog-body {
    padding: 24px 32px;
    padding: 1.5rem 1rem;
  }

  & table tbody tr td:last-child {
    width: 12%;
  }
`;

export const uploadImportForm = css`
  padding: 20px;
`;

export const formGroup = css`
  display: flex !important;
  flex-direction: row;
  /* text-align: center; */
  justify-content: center;
  align-content: center;
  margin-bottom: 20px;
  align-items: center;
  font-weight: 500;

  & label {
    font-weight: 400;
  }

  & .errorText {
    font-weight: bold;
    font-size: 12px;
  }

  & .field-email {
    width: 100%;
  }

  & .field-email > div > div {
    width: 100%;
  }

  & > div:first-child {
    width: 40%;
  }

  & input[name="email"] {
    width: 100%;
    padding: 10px;
    margin: 0;
  }

  & .file-name {
    display: block;
    font-size: 12px;
    margin-top: 10px;
    font-weight: 400;
    margin-left: 1px;
  }

  & #import_type svg {
    width: 10px;
  }

  & input[name="import_file"] {
    display: none;
  }

  & input[name="email"] > div {
    width: 18px;
  }
`;

export const label = css`
  display: block;
  margin-bottom: 1rem;
`;

export const labelText = css`
  display: block;
  font-weight: 500;
`;

export const uploadButton = css`
  margin-top: 1rem;
`;

export const fieldFile = css`
  display: none;
`;

export const errorText = css`
  font-size: 12px;
  font-weight: 500;
`;

export const fileLabel = css`
  border: 1px solid #ccc;
  display: inline-block;
  padding: 0px 12px;
  font-size: 14px;
  border-radius: 3px;
  cursor: pointer;
`;

export const loadingIndicatorContainer = css`
  text-align: center;
`;

export const loadingDescription = css`
  font-size: 14px;
  margin-top: 8px;
`;

export const loadingIndicator = css`
  width: 75px;
  height: 75px;
`;
