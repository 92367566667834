import { FlowRole, Role } from '@flowio/api-constants';

export function getFullUserName(user: io.flow.v0.models.User): string {
  return `${user.name.first || ''} ${user.name.last || ''}`.trim();
}

const memberRoles: io.flow.v0.enums.FlowRole[] = [
  FlowRole.ORGANIZATION_CUSTOMER_SERVICE,
  FlowRole.ORGANIZATION_FINANCE,
  FlowRole.ORGANIZATION_FULFILLMENT,
  FlowRole.ORGANIZATION_MARKETING,
  FlowRole.ORGANIZATION_MERCHANT,
  FlowRole.CHANNEL_ORGANIZATION_ADMIN,
  FlowRole.ORGANIZATION_CLASSIFICATION,
];

export function getRolesForUser(
  role: io.flow.v0.enums.Role
  | FlowRole.ORGANIZATION_CUSTOMER_SERVICE
  | FlowRole.CHANNEL_ORGANIZATION_ADMIN
  | FlowRole.ORGANIZATION_FULFILLMENT
  | FlowRole.ORGANIZATION_CLASSIFICATION,
): { role: io.flow.v0.enums.Role; roles: io.flow.v0.enums.FlowRole[] } {
  if (role === FlowRole.ORGANIZATION_CUSTOMER_SERVICE) {
    return {
      role: Role.MEMBER,
      roles: [
        FlowRole.ORGANIZATION_CUSTOMER_SERVICE,
      ],
    };
  }
  if (role === FlowRole.CHANNEL_ORGANIZATION_ADMIN) {
    return {
      role: Role.MEMBER,
      roles: [
        FlowRole.CHANNEL_ORGANIZATION_ADMIN,
      ],
    };
  }
  if (role === FlowRole.ORGANIZATION_FULFILLMENT) {
    return {
      role: Role.MEMBER,
      roles: [
        FlowRole.ORGANIZATION_FULFILLMENT,
      ],
    };
  }
  if (role === FlowRole.ORGANIZATION_CLASSIFICATION) {
    return {
      role: Role.MEMBER,
      roles: [
        FlowRole.ORGANIZATION_CLASSIFICATION,
      ],
    };
  }
  if (role === Role.MEMBER) {
    return {
      role,
      roles: memberRoles,
    };
  }
  if (role === Role.ADMIN) {
    return {
      role,
      roles: [
        ...memberRoles,
        FlowRole.ORGANIZATION_ADMIN,
      ],
    };
  }
  throw new Error(`getRolesForRole: unknown user role [${role}]`);
}

export function isFlowGlobaleMember(membership: io.flow.v0.models.Membership): boolean {
  if (membership.user.discriminator === 'user') {
    return membership.user.email?.includes('@flow.io') || membership.user.email?.includes('@global-e.com') || false;
  }
  return false;
}
