import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames/bind';

import AnnotatedSectionGroup from './AnnotatedSectionGroup';
import styles from './AnnotatedSection.module.css';

const cx = classNames.bind(styles);

const AnnotatedSection = ({
  className,
  children,
  description,
  title,
}) => (
  <section className={cx('annotatedSection', className)}>
    <div className={cx('annotation')}>
      {title && (
        <div className={cx('title')}>
          <h1>
            {title}
          </h1>
        </div>
      )}
      {description && (
        <div className={cx('description')}>
          <p>
            {description}
          </p>
        </div>
      )}
    </div>
    <div className={cx('content')}>
      {children}
    </div>
  </section>
);

AnnotatedSection.displayName = 'AnnotatedSection';

AnnotatedSection.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
};

AnnotatedSection.defaultProps = {
  children: undefined,
  className: '',
  description: undefined,
  title: undefined,
};

AnnotatedSection.Group = AnnotatedSectionGroup;

export default AnnotatedSection;
