import React from 'react';
import { ReactReduxContext } from 'react-redux';
import getDisplayName from '../../utilities/react/get-display-name';
import checkHttpStatus from '../../utilities/check-http-status';
import { RootState, ThunkResult, ThunkDispatcher } from '../../stores/types';
import { FormattedErrorMessages } from '../../utilities/format-error-response-v2';
import { LegacyResponse } from '../../utilities/clients/types/server';

/**
 * A higher-order component that manages the state for a form component when submitted.
 *
 * The `submitAction` argument should be an asynchronous action creator that returns a promise after
 * going through your asynchronous middleware and is settled with a response when the form data is
 * processed by the server. The action will be dispatched when the wrapped form component `onSubmit`
 * event callback is triggered.
 *
 * The following props are injected into the wrapped component:
 *
 * - `submitted: Boolean` - Indicates the form was submitted successfully
 * - `submitting: Boolean` - Indicates the form is being submitted
 * - `error: Any` - An error received from the server when the form is submitted.
 *
 * https://github.com/reactjs/redux/issues/297#issuecomment-124116124
 */

export interface WithSubmitProps {
  // Depends on how the developer decides to format errors when rejecting promises, but because
  // this is our own higher-order component and we know that we are using our own redux async
  // middleware that reformat errors, it will be defined to match the shape of our errors.
  error: FormattedErrorMessages;
  onSubmit: Function;
  submitted: boolean;
  submitting: boolean;
}

interface ResponseWithErrors {
  response: LegacyResponse<FormattedErrorMessages>;
}

interface SubmitState {
  submitting: boolean;
  submitted: boolean;
  error?: FormattedErrorMessages;
}

export default function withSubmit<T>(
  submitAction: (...args: any[]) => ThunkResult<Promise<LegacyResponse<T>>>,
): (Form: React.ElementType) => React.ReactNode {
  return function createContainer(Form: React.ElementType): React.ReactNode {
    class Submit extends React.Component<WithSubmitProps, SubmitState> {
      // eslint-disable-next-line react/static-property-placement
      static displayName = `WithSubmit(${getDisplayName(Form)})`;

      constructor(props: WithSubmitProps) {
        super(props);
        this.state = {
          submitting: false,
          submitted: false,
          error: undefined,
        };
      }

      componentDidMount(): void {
        this.mounted = true;
      }

      componentWillUnmount(): void {
        this.mounted = false;
      }

      handleSubmit = (...args: any[]): Promise<void> => {
        const { store } = this.context;
        const {
          dispatch,
          getState,
        }: {
          dispatch: ThunkDispatcher;
          getState: () => RootState;
        } = store;
        this.setStateSafely({ submitting: true, error: undefined, submitted: false });
        // Returning promise for testing purposes.
        return dispatch(submitAction(...args, getState(), this.props))
          .then(checkHttpStatus)
          .then(() => {
            this.setStateSafely({
              submitted: true,
              submitting: false,
            });
          }, (error: ResponseWithErrors) => {
            // Response status from backend service is outside the success range.
            console.log('setting errror', error);
            if (error && error.response) {
              this.setStateSafely({
                error: error.response.result,
                submitting: false,
              });
            // Construct error from thrown exceptions
            } else if (error instanceof Error) {
              this.setStateSafely({
                error: {
                  messages: [{
                    message: error.message,
                  }],
                },
                submitting: false,
              });
            // Eh...
            } else {
              this.setStateSafely({ submitting: false });
            }
          });
      };

      setStateSafely(nextState: Partial<SubmitState>, callback?: () => void): void {
        // Necessary this warning:
        //
        //   Warning: setState(...): Can only update a mounted or mounting
        //   component. This usually means you called setState() on an
        //   unmounted component.
        //
        // which occurs when decorated components are removed in response to
        // some action dispatched as a side effect of submitting the form.
        if (this.mounted === true) {
          this.setState(nextState as SubmitState, callback);
        }
      }

      mounted = false;

      // eslint-disable-next-line react/static-property-placement
      static contextType = ReactReduxContext;

      render(): React.ReactNode {
        const {
          error,
          submitting,
          submitted,
        } = this.state;

        return (
          <Form
            {...this.props}
            error={error}
            submitting={submitting}
            submitted={submitted}
            onSubmit={this.handleSubmit}
          />
        );
      }
    }

    return Submit;
  };
}
