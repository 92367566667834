import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import { Select, SelectOption } from '@flowio/react-select';

type Props = WrappedFieldProps & {
  feedSources: io.flow.internal.v0.unions.MarketingGatewayFeedSource[];
};

// used "!!" to make it absolutly unique cause "-" was found in the name
const mapSourceToOption = (
  feedSource: io.flow.internal.v0.unions.MarketingGatewayFeedSource,
): SelectOption => ({
  content: `${feedSource.name} (${feedSource.country}/${feedSource.language})`,
  value: `${feedSource.country}!!${feedSource.language}!!${feedSource.currency}!!${feedSource.name}`,
});

const SelectFeedSourceSelection: React.FC<Props> = ({
  input: {
    onChange,
    value,
  },
  feedSources = [],
}) => {
  const options: SelectOption[] = feedSources.map(mapSourceToOption);

  const handleChange = (val: string): void => {
    const [country, language, currency, name] = val.split('!!');
    const match = feedSources.find((feedSource) => (
      feedSource.country === country
        && feedSource.currency === currency
          && feedSource.language === language
            && name.startsWith(feedSource.name)
    ));
    onChange(match);
  };

  return (
    <Select
      fluid
      value={value && mapSourceToOption(value).value}
      options={options}
      textInputProps={{ id: 'select-feed-source' }}
      hintText="Select a data source..."
      onValueChange={handleChange}
    />
  );
};

SelectFeedSourceSelection.displayName = 'SelectFeedSourceSelection';

export default SelectFeedSourceSelection;
