import React from 'react';
import { Copy } from '@flowio/react-icons';
import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { IconButton } from '@flowio/react-button';
import { createToast } from '../../console/actions';

const copyIconStyle = css({
  position: 'relative',
  marginLeft: '6px',
  top: '1px',
});

const CopyButton: React.FC<{ value?: string }> = ({ value = undefined }) => {
  const dispatch = useDispatch();

  const onCopy = () => {
    if (!value) {
      return;
    }
    navigator.clipboard.writeText(value).then(() => {
      dispatch(createToast({
        intent: 'positive',
        content: 'Successfully copied.',
      }));
    }).catch(() => {
      dispatch(createToast({
        intent: 'negative',
        content: 'Could not copy.',
      }));
    });
  };

  return (
    <>
      { value && (
        <IconButton
          css={copyIconStyle}
          onClick={onCopy}
          icon={Copy}
          variant="flat"
          size="small"
        />
      )}
    </>
  );
};

export default CopyButton;
