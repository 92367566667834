import { ItemHarmonizationsGetResponse } from '@flowio/api-internal-sdk';
import { ActionTypes } from '../constants';
import {
  ThunkResult, ThunkExtraArguments, RootState, ThunkDispatcher,
} from '../../../stores/types';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import toLegacyResponse from '../../../utilities/clients/to-legacy-response';
import standardAction from '../../../utilities/standard-action';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';
import { PropType } from '../../../types';

type ResponseBody = PropType<ItemHarmonizationsGetResponse, 'body'>;

export default function fetchItemHarmonization(
  organization: string,
  itemNumber: string,
): ThunkResult<Promise<LegacyResponse<ResponseBody>>> {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<LegacyResponse<ResponseBody>> => {
    dispatch(standardAction(ActionTypes.FETCH_ITEM_HARMONIZATION_REQUEST));

    return extra.apiInternal(getState()).itemHarmonizations.get(
      { organization, number: [itemNumber] },
    ).then((response) => {
      if (response.ok) {
        dispatch({
          type: ActionTypes.FETCH_ITEM_HARMONIZATION_SUCCESS,
          payload: response.body,
        });
        return toLegacyResponse(response);
      }

      dispatch({
        type: ActionTypes.FETCH_ITEM_HARMONIZATION_FAILURE,
        payload: formatErrorResponseV2(response),
      });
      return toLegacyResponse(response);
    });
  };
}
