import { css } from '@emotion/css';
import colors from '../../../../../theme/tokens/colors';

export const headerTitle = css`
  display: inline-block;
  width: 50%;
`;

export const headerAction = css`
  display: inline-block;
  width: 50%;
  text-align: right;
`;

export const experimentHeading = css`
  margin: 0;
  color: ${colors.marketing['dark-slate-blue']};
  font-weight: normal;
`;

export const metricInputWrapper = css`
  display: flex;
  justify-content: space-between;
  margin: 10px 0 25px 0;
`;

export const metricInput = css`
  padding-right: 1rem !important;

  & input {
    height: 30px;
    margin-top: 5px;
  }
`;

export const activeBtn = css`
  background-color: ${colors.mutedFlowBlue} !important;
  color: ${colors['flow-blue'][400]} !important;
`;

export const dateInputGroup = css`
  width: 100%;
  & > input {
    width: 100%;
    margin-top: 5px;
  }
`;

export const timeseriesDropdown = css`
  margin-left: 8px;
  width: 110px;
`;

export const timeseriesInput = css`
  width: 100%;
`;

export const dateEdit = css`
  padding-right: 1rem;
`;

export const emptyChart = css`
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(
    45deg,
    rgba(53, 53, 53, 0.05) 5%,
    transparent 5%,
    transparent 50%,
    rgba(53, 53, 53, 0.05) 50%,
    rgba(53, 53, 53, 0.05) 55%,
    transparent 55%,
    transparent
  );
  background-size: 8px 8px;
  height: 300px;
  width: 100%;
`;
