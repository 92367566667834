import isNil from 'lodash/isNil';
import toNumber from 'lodash/toNumber';
import omitBy from 'lodash/omitBy';
import { $HttpResponse } from '@flowio/api-internal-sdk';
import ActionTypes from '../constants/action-types';

import withPagingAndSorting, { isPagingAndSortingResponse } from '../../../utilities/with-paging-and-sorting-v2';
import { RootActionTypes } from '../../../stores/types';
import { WithPagingAndSortingResult, WithPagingAndSortingResponse } from '../../../utilities/types';
import { ApiError } from '../../../utilities/clients/api-error';
import { ThunkResult } from '../../../middlewares/types';
import { TransactionPaginationParams, FetchTransactionsParams, PaginationOptions } from '../types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';

export function fetchTransactionsRequest(params: TransactionPaginationParams): RootActionTypes {
  return {
    type: ActionTypes.FETCH_TRANSACTIONS_REQUEST,
    params,
  };
}

export function fetchTransactionsSuccess(
  transactions: WithPagingAndSortingResult<io.flow.internal.v0.unions.Transaction[]>,
  params: TransactionPaginationParams,
): RootActionTypes {
  return {
    type: ActionTypes.FETCH_TRANSACTIONS_SUCCESS,
    params,
    payload: transactions,
  };
}

export function fetchTransactionsFailure(
  params: TransactionPaginationParams,
  error?: FormattedErrorMessages,
): RootActionTypes {
  return {
    type: ActionTypes.FETCH_TRANSACTIONS_FAILURE,
    error: true,
    params,
    payload: error,
  };
}

export default function fetchTransactions(
  organization: string,
  currency: string,
  key: string,
  params?: FetchTransactionsParams,
): ThunkResult<Promise<void>> {
  return function fetchTransactionsEffects(
    dispatch,
    getState,
    extra,
  ): Promise<void> {
    const pageNumber = params?.pageNumber ? toNumber(params.pageNumber) : 1;
    const entriesPerPage = params?.entriesPerPage ? params.entriesPerPage : 25;

    const mergedOptions = omitBy<TransactionPaginationParams>({
      pageNumber,
      entriesPerPage,
      statement: params?.statement,
      created_on_or_after: params?.createdFrom,
      created_on_or_before: params?.createdTo,
      status: params?.status,
      organization,
      currency,
    }, isNil);

    return withPagingAndSorting<io.flow.internal.v0.unions.Transaction[]>(
      mergedOptions,
      (options: PaginationOptions) => {
        dispatch(fetchTransactionsRequest(mergedOptions as TransactionPaginationParams));
        const apiMergedOptions = omitBy({
          offset: options.offset,
          limit: options.limit,
          ...mergedOptions,
        }, isNil);
        return extra.apiInternal(getState())
          .organizationAccounts.getTransactionsByKey({
            organization,
            key,
            ...apiMergedOptions,
          });
      },
    )().then((
      response: WithPagingAndSortingResponse<io.flow.internal.v0.unions.Transaction[]>
      | $HttpResponse<io.flow.v0.models.GenericError>,
    ) => {
      if (isPagingAndSortingResponse<io.flow.internal.v0.unions.Transaction[]>(response)
        && response.ok) {
        dispatch(fetchTransactionsSuccess(
          response.result,
          mergedOptions as TransactionPaginationParams,
        ));
        return;
      }

      const errorResponse = response as $HttpResponse<io.flow.v0.models.GenericError>;
      dispatch(fetchTransactionsFailure(
        mergedOptions as TransactionPaginationParams,
        formatErrorResponseV2(errorResponse),
      ));
      throw new ApiError(errorResponse);
    });
  };
}
