import { Check } from '@flowio/react-icons';
import { Field, Form, InjectedFormProps } from 'redux-form';
import {
  Banner,
  BannerText,
  BannerList,
  BannerItem,
  BannerTitle,
} from '@flowio/react-banner';
import { Card, CardHeader, CardContent } from '@flowio/react-card';
import { Grid, Column, Row } from '@flowio/react-grid';
import React from 'react';
import map from 'lodash/map';
import size from 'lodash/size';
import isEqual from 'lodash/isEqual';

import { DeliveredDutyDisplayType } from '@flowio/api-constants';
import { css } from '@emotion/react';

import DeliveredDutyMethods from './DeliveredDutyMethods';
import DeliveredDutySelection from './DeliveredDutySelection';
import DeliveredDutyDisplay from './DeliveredDutyDisplay';
import LandedCostSinglePreview from '../../LandedCostSinglePreview';
import LandedCostListingPreview from '../../LandedCostListingPreview';
import { MergedProps as Props, LandedCostFormValues } from '../types';
import { FormattedErrorMessages } from '../../../../../utilities/format-error-response-v2';

const successLabelStyles = css`
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  color: #6cab35;
  font-size: 14px;
  font-weight: 500;
`;

const successIconStyles = css`
  display: inline-block;
  width: 16px;
  height: auto;
  margin-right: 4px;
  color: #6cab35;
  fill: #6cab35;
`;

const extraSelectedMessagingStyles = css`
  margin-bottom: 32px;
  border-radius: 4px;
`;

const extraMessagingStyles = css({
  marginBottom: '32px',
  borderRadius: '4px',
  marginRight: '10px',
});

const LandedCostForm: React.FC<Props & InjectedFormProps<
LandedCostFormValues, Props, FormattedErrorMessages
>> = ({
  deliveredDutyOptions,
  error,
  experienceKey,
  handleSubmit,
  pristine,
  reset,
  selectedDeliveredDutyMethods,
  selectedDeliveredDutyDefault,
  selectedDeliveredDutyDisplay,
  submitSucceeded,
  submitting,
}) => {
  const cancelButton = {
    type: 'button',
    content: 'Cancel',
    intent: 'neutral',
    disabled: submitting || pristine,
    onClick(event: React.MouseEvent): void {
      // To avoid shitty browsers from submitting the form anyways.
      event.preventDefault();
      event.stopPropagation();
      reset();
    },
  };

  const saveButton = {
    type: 'submit',
    content: 'Save Changes',
    intent: 'primary',
    disabled: submitting || pristine,
  };

  const successLabel = (
    <span css={successLabelStyles}>
      <Check css={successIconStyles} />
      Changes Saved.
    </span>
  );

  const cardActions = [];

  if (pristine && submitSucceeded) {
    cardActions.push(successLabel);
  } else if (!pristine) {
    cardActions.push(cancelButton);
  }

  cardActions.push(saveButton);

  const multipleDeliveredDutyMethods = size(selectedDeliveredDutyMethods) > 1;

  const hasWarningMessage = deliveredDutyOptions.message ? deliveredDutyOptions.message.type === 'warning' : false;

  const selectedWarningOption = deliveredDutyOptions.options.find(
    (option) => isEqual(option.methods.sort(), selectedDeliveredDutyMethods.sort()),
  );

  const hasSelectedOptionWarning = selectedWarningOption && selectedWarningOption.message ? selectedWarningOption.message.type === 'warning' : false;

  return (
    <Form onSubmit={handleSubmit}>
      <Card>
        <CardHeader
          actions={cardActions}
          caption="Tax and Duty Settings at Checkout"
          description="Choose whether customers pay tax and duty upfront or not, or allow them to choose from either option at checkout."
          dividing
        />
        <CardContent>
          {error && (
            <Banner intent="negative">
              <BannerText>
                The following errors occurred when submitting this form
              </BannerText>
              <BannerList>
                {map(error.messages, ({ message }, index) => (
                  <BannerItem key={index} content={message} />
                ))}
              </BannerList>
            </Banner>
          )}
          {deliveredDutyOptions.message && deliveredDutyOptions.message.type === 'notification' && (
            <Banner intent="neutral" css={extraMessagingStyles}>
              <BannerTitle>{deliveredDutyOptions.message.title}</BannerTitle>
              <BannerText>
                {deliveredDutyOptions.message.content}
              </BannerText>
            </Banner>
          )}
          {deliveredDutyOptions.message && hasWarningMessage && (
            <Banner intent="negative" css={extraMessagingStyles}>
              <BannerTitle>{deliveredDutyOptions.message.title}</BannerTitle>
              <BannerText>
                {deliveredDutyOptions.message.content}
              </BannerText>
            </Banner>
          )}
          <Grid gutter={false}>
            <Row gutter={false}>
              <Column span={6}>
                <Field component="input" type="hidden" name="experienceKey" />
                <Field component="input" type="hidden" name="organizationId" />
                <DeliveredDutyMethods
                  deliveredDutyOptions={deliveredDutyOptions}
                  hasWarningMessage={hasWarningMessage}
                />
                {multipleDeliveredDutyMethods && (
                  <DeliveredDutySelection hasWarningMessage={hasWarningMessage} />
                )}
                {multipleDeliveredDutyMethods && (
                  <DeliveredDutyDisplay hasWarningMessage={hasWarningMessage} />
                )}
                {hasSelectedOptionWarning && selectedWarningOption && (
                  <Banner intent="negative" css={extraSelectedMessagingStyles}>
                    <BannerText>
                      {selectedWarningOption.message?.text}
                    </BannerText>
                  </Banner>
                )}
              </Column>
              <Column span={6}>
                {(
                  selectedDeliveredDutyDisplay === DeliveredDutyDisplayType.SINGLE
                  && !hasWarningMessage
                ) && (
                  <LandedCostSinglePreview
                    customerChoice={multipleDeliveredDutyMethods}
                    deliveredDuty={selectedDeliveredDutyDefault}
                    experienceKey={experienceKey}
                  />
                )}
                {(
                  selectedDeliveredDutyDisplay === DeliveredDutyDisplayType.ALL
                  && !hasWarningMessage
                ) && (
                  <LandedCostListingPreview
                    deliveredDuty={selectedDeliveredDutyDefault}
                    experienceKey={experienceKey}
                  />
                )}
              </Column>
            </Row>
          </Grid>
        </CardContent>
      </Card>
    </Form>
  );
};

LandedCostForm.displayName = 'LandedCostForm';

LandedCostForm.defaultProps = {
  error: undefined,
  selectedDeliveredDutyMethods: [],
  selectedDeliveredDutyDefault: undefined,
  selectedDeliveredDutyDisplay: undefined,
};

export default LandedCostForm;
