import { $HttpResponse } from '@flowio/api-sdk';
import ActionTypes from '../constants/action-types';
import { ApiError } from '../../../utilities/clients/api-error';
import standardAction from '../../../utilities/standard-action';
import {
  RootState, ThunkExtraArguments, ThunkResult, ThunkDispatcher,
} from '../../../stores/types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';

interface ExperimentsQueryParams {
  id?: string[];
  key?: string;
  status?: io.flow.internal.v0.enums.Status[];
  limit?: number; // The maximum number of results to return
  offset?: number; // The number of results to skip before returning results
  sort?: string;
}

export default function fetchExperiments(
  organization: string,
  options: ExperimentsQueryParams = {},
): ThunkResult<Promise<void>> {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<void> => {
    dispatch(standardAction(ActionTypes.FETCH_EXPERIMENTS_REQUEST));

    return extra.apiInternal(getState()).experiments.get({
      organization,
      ...options,
    }).then((response) => {
      if (response.ok) {
        dispatch(standardAction(
          ActionTypes.FETCH_EXPERIMENTS_SUCCESS,
          response.body,
        ));
        return;
      }

      const errorResponse = response as $HttpResponse<io.flow.v0.models.GenericError>;
      const error = new ApiError(errorResponse);

      dispatch(standardAction(
        ActionTypes.FETCH_EXPERIMENTS_FAILURE,
        formatErrorResponseV2(response),
      ));
      throw error;
    });
  };
}
