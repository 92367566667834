import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import noop from 'lodash/noop';
import ApiPropTypes from '@flowio/api-prop-types';
import {
  Card, CardHeader, CardContent, CardFooter,
} from '@flowio/react-card';
import { Plus } from '@flowio/react-icons';
import { PageHeader } from '@flowio/react-page';
import ExportsList from './exports-list';
import ConnectedExportDialog from './exports-dialog';
import Pagination from '../../../../components/pagination';

class Exports extends PureComponent {
  constructor(props) {
    super(props);
    this.fileField = null;
    this.emailField = null;

    this.state = {
      addMode: false,
      selectedExport: null,
    };
  }

  handleRequestNextPage = () => {
    const {
      pageNumber, onRequestPage, isLastPage,
    } = this.props;

    if (isLastPage) {
      return;
    }
    onRequestPage({
      pageNumber: parseInt(pageNumber, 10) + 1,
    });
  }

  handleRequestPreviousPage = () => {
    const {
      pageNumber, onRequestPage, firstPage,
    } = this.props;

    if (firstPage) {
      return;
    }
    onRequestPage({
      pageNumber: parseInt(pageNumber, 10) - 1,
    });
  }

  onCreateExport = (formData) => {
    const { onCreateExport } = this.props;
    onCreateExport(formData);
  }

  onCancel = () => {
    const { onCloseExport } = this.props;
    onCloseExport();
  }

  toggleExportDialog = () => {
    const { onToggleDialog } = this.props;
    onToggleDialog();
    this.setState({ addMode: true });
  }

  editExport = (id) => {
    const { exports, onSelectExport } = this.props;
    const exportToEdit = exports.find((exportItem) => exportItem.id === id);
    onSelectExport(exportToEdit);
    this.setState({ addMode: false });
  }

  deleteExport = (id) => {
    const { onDeleteExport } = this.props;
    onDeleteExport(id);
  }

  render() {
    const {
      error,
      exports,
      exportCreated,
      onToggleDialog,
      valid,
      firstPage,
      onCreateExport,
      submitting,
      isFileEmpty,
      pageNumber,
      openDialog,
      lastPage,
    } = this.props;

    const {
      addMode,
      selectedExport,
    } = this.state;

    const hasContent = exports && exports.length > 0;
    return (
      <div>
        <PageHeader
          title={{
            content: 'Exports',
          }}
        />
        <Card>
          <CardHeader
            actions={[
              {
                intent: 'primary',
                id: 'add-btn',
                content: 'Add Export Schedule',
                onClick: onToggleDialog,
                leftIcon: Plus,
              }]}
            caption="Export Schedule"
            dividing
          />
          <CardContent>
            {hasContent
              ? (
                <ExportsList
                  onEditExport={this.editExport}
                  onDeleteExport={this.deleteExport}
                  exports={exports}
                />
              ) : null }

            {openDialog ? (
              <ConnectedExportDialog
                {...this.props}
                selectedExport={selectedExport}
                exportCreated={exportCreated}
                valid={valid}
                onCreateExport={onCreateExport}
                error={error}
                addMode={addMode}
                isOpen={openDialog}
                onRequestClose={this.onCancel}
                submitting={submitting}
                onRequestSubmit={this.handleSubmit}
                enableBackdropDismiss
                showBackdrop
              />
            ) : null}

          </CardContent>
          <CardFooter>
            <Pagination
              isPreviousPageDisabled={lastPage}
              isFileEmpty={isFileEmpty}
              pageNumber={pageNumber}
              isNextPageDisabled={firstPage}
              onRequestNextPage={this.handleRequestNextPage}
              onRequestPreviousPage={this.handleRequestPreviousPage}
            />
          </CardFooter>
        </Card>
      </div>
    );
  }
}

Exports.displayName = 'Exports';

Exports.propTypes = {
  exports: PropTypes.arrayOf(ApiPropTypes.scheduledExport).isRequired,
  firstPage: PropTypes.bool,
  openDialog: PropTypes.bool,
  onToggleDialog: PropTypes.func,
  lastPage: PropTypes.bool,
  submitting: PropTypes.bool,
  valid: PropTypes.bool,
  pageNumber: PropTypes.number,
  onRequestPage: PropTypes.func,
  onCloseExport: PropTypes.func,
  onCreateExport: PropTypes.func,
  onSelectExport: PropTypes.func,
  isLastPage: PropTypes.bool,
  isFileEmpty: PropTypes.bool,
  error: PropTypes.shape({
    messages: PropTypes.arrayOf(PropTypes.shape({
      message: PropTypes.string.isRequired,
    })),
  }),
  exportCreated: PropTypes.bool,
  onDeleteExport: PropTypes.func,
};

Exports.defaultProps = {
  error: undefined,
  exportCreated: false,
  valid: false,
  openDialog: false,
  onToggleDialog: noop,
  onCloseExport: noop,
  onSelectExport: noop,
  onCreateExport: noop,
  onDeleteExport: noop,
  onRequestPage: noop,
  submitting: false,
  firstPage: false,
  lastPage: false,
  pageNumber: 1,
  isFileEmpty: false,
  isLastPage: false,
};

export default Exports;
