import { Field } from 'redux-form';
import React from 'react';
import curry from 'lodash/curry';
import get from 'lodash/get';

import formatFilterValue from '../../utilities/formatFilterValue';
import * as styles from './AddRuleWorksheetForm.styles';
import { RuleConditionFieldProps } from '../../types/components';
import { SelectField } from '../../../../components/ReduxFormFieldAdapters';

const parseValue = curry((value) => get(value, 'field'));

class RuleConditionField extends React.PureComponent<RuleConditionFieldProps> {
  static displayName = 'RuleConditionField';

  parseValue = parseValue;

  render() {
    const {
      availableFilters,
      name,
    } = this.props;

    return (
      <Field
        component={SelectField}
        name={name}
        format={formatFilterValue(availableFilters)}
        parse={parseValue}
        className={styles.ruleConditionField}
        intent="primary"
        selection
        options={availableFilters}
        labelKey="field"
        valueKey="field"
      />
    );
  }
}

export default RuleConditionField;
