import update from 'immutability-helper';
import filter from 'lodash/filter';
import ActionTypes from '../constants/action-types';

import { RootActionTypes } from '../../../stores/types';

const defaultState: io.flow.internal.v0.models.Feature = {
  id: '',
  key: '',
  name: '',
  rules: [],
  scope: 'global',
  status: 'draft',
  type: 'boolean',
};

export default function (state = defaultState, action: RootActionTypes) {
  switch (action.type) {
    case ActionTypes.ADD_RULE_BY_KEY_SUCCESS:
    case ActionTypes.CHANGE_STATUS_SUCCESS:
    case ActionTypes.FETCH_FEATURE_BY_KEY_SUCCESS:
    case ActionTypes.PUT_FEATURE_BY_KEY_SUCCESS:
    case ActionTypes.RELEASE_FEATURE_BY_KEY_SUCCESS:
    case ActionTypes.UPDATE_RULE_BY_KEY_AND_ID_SUCCESS:
      return update(state, {
        $set: action.payload,
      });
    case ActionTypes.DELETE_RULE_BY_KEY_AND_ID_SUCCESS: {
      return update(state, {
        rules: {
          $set: filter(
            state.rules,
            (rule) => rule.id !== action.ruleId,
          ),
        },
      });
    }
    default:
      return state;
  }
}
