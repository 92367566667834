import { css } from '@emotion/css';

export const itemMarginForm = css`
  & .alert {
    margin: 1rem 0;
  }
`;

export const addon = css`
  width: auto !important; /* override theme for text input icons */
  font-size: 14px;
`;

export const marginType = css`
  color: #17181a;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 1rem;
  margin-bottom: 1rem;

  & .text-field__group {
    width: 150px;
  }
`;

export const label = css`
  margin-bottom: 1rem;
  font-size: 1.1rem;
  font-weight: 400;
`;

export const textField = css`
  margin-bottom: 1rem;
`;

export const queryTargetField = css`
  margin-bottom: 1rem;
`;

export const marginTypeValueText = css`
  line-height: 48px;
  margin-left: 1rem;
  vertical-align: middle;
`;

export const actions = css`
  text-align: right;
`;

export const primaryButton = css`
  margin-left: 1rem;
`;
