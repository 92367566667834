import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import lowerCase from 'lodash/lowerCase';
import { EventType } from '@flowio/api-constants';

export enum ActionTypes {
  'CREATE_WEBHOOK_REQUEST' = 'CREATE_WEBHOOK_REQUEST',
  'CREATE_WEBHOOK_SUCCESS' = 'CREATE_WEBHOOK_SUCCESS',
  'CREATE_WEBHOOK_FAILURE' = 'CREATE_WEBHOOK_FAILURE',
  'DELETE_WEBHOOK_REQUEST' = 'DELETE_WEBHOOK_REQUEST',
  'DELETE_WEBHOOK_SUCCESS' = 'DELETE_WEBHOOK_SUCCESS',
  'DELETE_WEBHOOK_FAILURE' = 'DELETE_WEBHOOK_FAILURE',
  'GET_WEBHOOK_REQUEST' = 'GET_WEBHOOK_REQUEST',
  'GET_WEBHOOK_SUCCESS' = 'GET_WEBHOOK_SUCCESS',
  'GET_WEBHOOK_FAILURE' = 'GET_WEBHOOK_FAILURE',
  'FETCH_WEBHOOKS_REQUEST' = 'FETCH_WEBHOOKS_REQUEST',
  'FETCH_WEBHOOKS_SUCCESS' = 'FETCH_WEBHOOKS_SUCCESS',
  'FETCH_WEBHOOKS_FAILURE' = 'FETCH_WEBHOOKS_FAILURE',
  'GET_WEBHOOK_DELIVERY_REQUEST' = 'GET_WEBHOOK_DELIVERY_REQUEST',
  'GET_WEBHOOK_DELIVERY_SUCCESS' = 'GET_WEBHOOK_DELIVERY_SUCCESS',
  'GET_WEBHOOK_DELIVERY_FAILURE' = 'GET_WEBHOOK_DELIVERY_FAILURE',
  'GET_WEBHOOK_DELIVERIES_REQUEST' = 'GET_WEBHOOK_DELIVERIES_REQUEST',
  'GET_WEBHOOK_DELIVERIES_SUCCESS' = 'GET_WEBHOOK_DELIVERIES_SUCCESS',
  'GET_WEBHOOK_DELIVERIES_FAILURE' = 'GET_WEBHOOK_DELIVERIES_FAILURE',
  'REDELIVER_REQUEST' = 'REDELIVER_REQUEST',
  'REDELIVER_SUCCESS' = 'REDELIVER_SUCCESS',
  'REDELIVER_FAILURE' = 'REDELIVER_FAILURE',
  'SYNC_WEBHOOK_DELIVERIES_REQUEST' = 'SYNC_WEBHOOK_DELIVERIES_REQUEST',
  'SYNC_WEBHOOK_DELIVERIES_SUCCESS' = 'SYNC_WEBHOOK_DELIVERIES_SUCCESS',
  'SYNC_WEBHOOK_DELIVERIES_FAILURE' = 'SYNC_WEBHOOK_DELIVERIES_FAILURE',
  'UPDATE_WEBHOOK_REQUEST' = 'UPDATE_WEBHOOK_REQUEST',
  'UPDATE_WEBHOOK_SUCCESS' = 'UPDATE_WEBHOOK_SUCCESS',
  'UPDATE_WEBHOOK_FAILURE' = 'UPDATE_WEBHOOK_FAILURE',
}

export const EventTypes = map(sortBy(Object.values(EventType)), (eventType) => ({
  label: lowerCase(eventType),
  value: eventType,
}));
