import { createSelector } from 'reselect';
import get from 'lodash/get';

import getExclusionState from './getExclusionState';
import { RootState } from '../../../stores/types';
import { ExclusionState, ExclusionRulesFormState } from '../types';

const getExclusionRulesForm = createSelector<RootState, ExclusionState, ExclusionRulesFormState>(
  getExclusionState,
  (state) => get(state, 'entities.exclusionRulesForm'),
);

export default getExclusionRulesForm;
