import React from 'react';

import CreateShippingConfigurationWorksheet from '../../containers/CreateShippingConfigurationWorksheet';
import ShippingConfigurationCopyWorksheet from '../../containers/ShippingConfigurationCopyWorksheet';
import ShippingConfigurationDeleteConfirmation from '../../containers/ShippingConfigurationDeleteConfirmation';
import ShippingLaneDeleteConfirmation from '../../containers/ShippingLaneDeleteConfirmation';
import ShippingLaneWorksheet from '../../containers/ShippingLaneWorksheet';
import TierWorksheet from '../../containers/TierWorksheet';
import TierDeleteConfirmation from '../../containers/TierDeleteConfirmation';
import UpdateShippingConfigurationWorksheet from '../../containers/UpdateShippingConfigurationWorksheet';

const LogisticsRoot: React.FC<{
  children?: React.ReactNode
}> = ({ children }) => (
  <div>
    {children}
    <CreateShippingConfigurationWorksheet />
    <ShippingConfigurationCopyWorksheet />
    <ShippingConfigurationDeleteConfirmation />
    <ShippingLaneDeleteConfirmation />
    <ShippingLaneWorksheet />
    <TierWorksheet />
    <TierDeleteConfirmation />
    <UpdateShippingConfigurationWorksheet />
  </div>
);

LogisticsRoot.displayName = 'LogisticsRoot';

export default LogisticsRoot;
