import Client from './client';

export default class ExperimentFormDefaults extends Client {
  constructor(opts) {
    let options = opts;

    if (typeof opts === 'string') {
      options = { host: opts }; // convert host string to options object
    }

    options.serviceName = 'API Internal';

    super(options);
  }

  getDiscriminatorByDiscriminatorKey(organization, discriminatorKey, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/experiment/form/defaults/discriminator/${discriminatorKey}`, options);
  }

  getDiscriminatorByDiscriminatorKeyAndValue(organization, discriminatorKey, value, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/experiment/form/defaults/discriminator/${discriminatorKey}/${value}`, options);
  }

  getByExperimentKey(organization, experimentKey, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/experiment/form/defaults/${experimentKey}`, options);
  }

}
