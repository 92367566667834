import React from 'react';

const ConsoleMetaLogo: React.FC<
React.SVGProps<SVGSVGElement>
> = (
  {
    preserveAspectRatio = 'xMidYMid meet',
    viewBox = '120 -15 120 56',
    ...unhandledProps
  },
) => (
  <svg {...unhandledProps} preserveAspectRatio={preserveAspectRatio} viewBox={viewBox}>
    <path d="M132.385 4.79505C130.49 4.79505 128.993 5.42779 127.893 6.69327C126.8 7.95089 126.254 9.67619 126.254 11.8692C126.254 14.125 126.78 15.87 127.834 17.104C128.895 18.3302 130.404 18.9433 132.361 18.9433C133.564 18.9433 134.935 18.7271 136.476 18.2948V20.0516C135.281 20.4996 133.807 20.7236 132.055 20.7236C129.516 20.7236 127.555 19.9533 126.171 18.4127C124.796 16.8721 124.108 14.6831 124.108 11.8456C124.108 10.0692 124.438 8.51289 125.098 7.17667C125.766 5.84045 126.725 4.81077 127.975 4.08763C129.233 3.3645 130.71 3.00294 132.408 3.00294C134.216 3.00294 135.796 3.33306 137.148 3.99331L136.299 5.71468C134.994 5.10159 133.689 4.79505 132.385 4.79505Z" fill="#C0CDF0" />
    <path d="M155.638 11.8456C155.638 14.6045 154.938 16.7739 153.539 18.3538C152.148 19.9337 150.21 20.7236 147.726 20.7236C145.188 20.7236 143.227 19.9494 141.843 18.4009C140.468 16.8446 139.78 14.6517 139.78 11.822C139.78 9.01594 140.472 6.84261 141.855 5.30203C143.238 3.75358 145.203 2.97935 147.75 2.97935C150.226 2.97935 152.16 3.76537 153.551 5.3374C154.942 6.90942 155.638 9.07882 155.638 11.8456ZM141.902 11.8456C141.902 14.1801 142.397 15.9525 143.388 17.163C144.386 18.3656 145.832 18.9669 147.726 18.9669C149.636 18.9669 151.079 18.3656 152.053 17.163C153.028 15.9604 153.515 14.1879 153.515 11.8456C153.515 9.52685 153.028 7.77011 152.053 6.57537C151.087 5.37277 149.652 4.77147 147.75 4.77147C145.84 4.77147 144.386 5.3767 143.388 6.58716C142.397 7.78976 141.902 9.54257 141.902 11.8456Z" fill="#C0CDF0" />
    <path d="M173.397 20.4878H171.109L161.689 6.02123H161.595C161.72 7.71902 161.783 9.27533 161.783 10.6901V20.4878H159.932V3.25053H162.196L171.593 17.6582H171.687C171.671 17.4459 171.636 16.766 171.581 15.6185C171.526 14.463 171.506 13.6377 171.522 13.1425V3.25053H173.397V20.4878Z" fill="#C0CDF0" />
    <path d="M188.302 15.9014C188.302 17.4184 187.752 18.6014 186.652 19.4503C185.551 20.2992 184.058 20.7236 182.171 20.7236C180.128 20.7236 178.556 20.4603 177.455 19.9337V18.0001C178.163 18.2988 178.933 18.5346 179.766 18.7075C180.599 18.8804 181.425 18.9669 182.242 18.9669C183.578 18.9669 184.584 18.7154 185.26 18.2123C185.936 17.7014 186.274 16.994 186.274 16.0901C186.274 15.4927 186.152 15.0054 185.909 14.6281C185.673 14.2429 185.272 13.8892 184.706 13.567C184.148 13.2447 183.295 12.8792 182.148 12.4705C180.544 11.8967 179.397 11.2168 178.705 10.4308C178.021 9.64475 177.679 8.619 177.679 7.35352C177.679 6.02516 178.178 4.96797 179.177 4.18196C180.175 3.39594 181.495 3.00294 183.138 3.00294C184.852 3.00294 186.428 3.31734 187.866 3.94615L187.241 5.6911C185.818 5.09373 184.435 4.79505 183.091 4.79505C182.03 4.79505 181.201 5.02299 180.603 5.47888C180.006 5.93477 179.707 6.56751 179.707 7.3771C179.707 7.97447 179.817 8.46573 180.037 8.85088C180.257 9.22816 180.627 9.57794 181.146 9.90021C181.672 10.2146 182.474 10.5644 183.551 10.9495C185.359 11.5941 186.6 12.2858 187.276 13.0246C187.96 13.7635 188.302 14.7224 188.302 15.9014Z" fill="#C0CDF0" />
    <path d="M207.228 11.8456C207.228 14.6045 206.529 16.7739 205.13 18.3538C203.738 19.9337 201.801 20.7236 199.317 20.7236C196.778 20.7236 194.817 19.9494 193.434 18.4009C192.058 16.8446 191.37 14.6517 191.37 11.822C191.37 9.01594 192.062 6.84261 193.445 5.30203C194.829 3.75358 196.794 2.97935 199.341 2.97935C201.817 2.97935 203.75 3.76537 205.141 5.3374C206.533 6.90942 207.228 9.07882 207.228 11.8456ZM193.493 11.8456C193.493 14.1801 193.988 15.9525 194.978 17.163C195.976 18.3656 197.423 18.9669 199.317 18.9669C201.227 18.9669 202.669 18.3656 203.644 17.163C204.619 15.9604 205.106 14.1879 205.106 11.8456C205.106 9.52685 204.619 7.77011 203.644 6.57537C202.677 5.37277 201.243 4.77147 199.341 4.77147C197.431 4.77147 195.976 5.3767 194.978 6.58716C193.988 7.78976 193.493 9.54257 193.493 11.8456Z" fill="#C0CDF0" />
    <path d="M211.523 20.4878V3.25053H213.527V18.6721H221.132V20.4878H211.523Z" fill="#C0CDF0" />
    <path d="M234.115 20.4878H224.506V3.25053H234.115V5.03085H226.511V10.584H233.656V12.3526H226.511V18.6957H234.115V20.4878Z" fill="#C0CDF0" />
  </svg>
);

ConsoleMetaLogo.displayName = 'ConsoleMetaLogo';

export default ConsoleMetaLogo;
