import { ThunkDispatcher, ThunkResult } from '../../../stores/types';
import standardAction from '../../../utilities/standard-action';
import ActionTypes from '../constants/action-types';
import ErrorTypes from '../constants/error-types';
import { getHasResourceAccess, login } from '../fbSDK';
import saveToken from './saveToken';
import { fetchChannels } from '../../marketing/actions';

const reauthenticate = (
  organization: string,
  resourceId?: number,
): ThunkResult<Promise<void>> => (
  dispatch: ThunkDispatcher,
): Promise<void> => {
  dispatch(standardAction(ActionTypes.REAUTHENTICATION_REQUEST));

  return login()
    .then(({ success, accessToken }) => {
      if (!success || !accessToken) {
        const error = new Error(ErrorTypes.PERMISSIONS_ERROR);
        dispatch(standardAction(ActionTypes.REAUTHENTICATION_FAILURE, error));
        throw error;
      }

      return getHasResourceAccess(resourceId)
        .then((hasAccess) => {
          if (hasAccess) {
            return dispatch(saveToken(organization, accessToken))
              .then(() => {
                dispatch(standardAction(ActionTypes.REAUTHENTICATION_SUCCESS));
              })
              .catch(() => {
                const error = new Error(ErrorTypes.GENERIC_ERROR);
                dispatch(standardAction(ActionTypes.REAUTHENTICATION_FAILURE, error));
                throw error;
              })
              .finally(() => {
                // Always update channels after a reauth
                dispatch(fetchChannels({ organizationId: organization }));
              });
          }

          const error = new Error(ErrorTypes.CATALOG_ACCESS_ERROR(resourceId));
          dispatch(standardAction(ActionTypes.REAUTHENTICATION_FAILURE, error));
          throw error;
        });
    });
};

export default reauthenticate;
