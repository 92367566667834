import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import get from 'lodash/get';
import { Image } from '@flowio/react-image';
import { File } from '@flowio/react-icons';
import { Box, BoxItem } from '@flowio/react-box';
import {
  findCountryByCode,
  findLanguageByCode,
} from '../../utilities/findReferenceData';
import withNumberFormat from '../../../../utilities/numbers/withNumberFormat';
import { isGoogleFeedSource } from '../../types/utilities';
import { MarketingGatewayIntegratedPlatform } from '../../types';
import Platforms from '../../constants/Platforms';
import { colors } from '../../../../theme/tokens';

interface Props {
  countries: io.flow.v0.models.Country[];
  languages: io.flow.v0.models.Language[];
  feedSource: io.flow.internal.v0.unions.MarketingGatewayFeedSource;
}

const feedSourcePlatform = (
  source: io.flow.internal.v0.unions.MarketingGatewayFeedSource,
): MarketingGatewayIntegratedPlatform | undefined => {
  switch (source.discriminator) {
    case 'marketing_gateway_feed_source_google':
      return 'google';
    case 'marketing_gateway_feed_source_facebook':
      return 'facebook';
    default:
      return undefined;
  }
};

const productDataSourceStyles = css`
  margin-top: 24px;
  padding: 24px;
  position: relative;
  background-color: ${colors.concrete};
  color: ${colors.marketing['dark-slate-blue']};
`;

const fileIconStyles = css`
  position: relative;
  margin-right: 10px;
`;

const sourceLogoStyles = css`
  position: absolute;
  right: 0;
  top: 12px;
`;

const Title = styled.span({
  fontWeight: 500,
});

const MetaHeading = styled.h4({
  fontSize: '16px',
  margin: '0 0 10px 0',
  color: colors.marketing['dark-slate-blue'],
});

const MetaContainer = styled.div({
  fontSize: '13px',
  margin: '0',
  color: colors.marketing['light-slate-gray'],
  lineHeight: '20px',
});

const ProductDataSource: React.FC<Props> = React.memo(({
  feedSource,
  countries,
  languages,
}) => {
  const sourcePlatform = feedSourcePlatform(feedSource);

  return (
    <Box css={productDataSourceStyles}>
      <BoxItem css={fileIconStyles}>
        <File height={24} css={css({ color: colors.marketing['dark-slate-blue'] })} />
        <Image height={12} accessibilityLabel={`${sourcePlatform}`} source={`https://cdn.flow.io/console/logo_${sourcePlatform}.svg`} css={sourceLogoStyles} />
      </BoxItem>
      <MetaContainer>
        <MetaHeading>{feedSource.name}</MetaHeading>
        <div>
          <Title>{sourcePlatform ? Platforms(sourcePlatform).platformName : 'Unknown'}</Title>
          {' '}
          •
          {' '}
          <Title>Account ID</Title>
          :
          {' '}
          {isGoogleFeedSource(feedSource) ? feedSource.account_id : '-'}
        </div>
        <div>
          <Title>
            Country
          </Title>
          :
          {' '}
          {get(findCountryByCode(countries, feedSource.country), 'name')}
          &nbsp;•&nbsp;
          <Title>
            Language
          </Title>
          :
          {' '}
          {get(findLanguageByCode(languages, feedSource.language), 'name')}
          &nbsp;•&nbsp;
          <Title>
            # of Items
          </Title>
          :
          {' '}
          {feedSource.item_count ? withNumberFormat(feedSource.item_count) : '-'}
        </div>
      </MetaContainer>
    </Box>
  );
});

ProductDataSource.displayName = 'ProductDataSource';

export default ProductDataSource;
