import { css } from '@emotion/react';
import { findById as findRegionById } from '@flowio/lib-reference-javascript/lib/regions';
import { SelectField as Select } from '@flowio/react-select';
import { DateInputField } from '@flowio/react-date-input';
import find from 'lodash/find';
import get from 'lodash/get';
import noop from 'lodash/noop';
import reduce from 'lodash/reduce';
import sortBy from 'lodash/sortBy';
import moment, { Moment } from 'moment';
import React, { Component } from 'react';
import { Banner } from '@flowio/react-banner';
import {
  Card, CardContent, CardEmptyState, CardHeader, CardTitle,
} from '@flowio/react-card';
import { Alert, InfoCircle, Trophy } from '@flowio/react-icons';
import { colors } from '../../../../../theme/tokens';
import messages from '../../../constants/messages';
import metricSummaryValues from '../../../constants/metric-values';
import EndExperimentDialog from '../../end-experiment/end-experiment-dialog';
import {
  MergedProps as Props, MetricDropdownOption, MetricDropdownValue,
} from '../types';
import { isFeatureExperiment } from '../utilities/checkExperiment';
import { getWinningVariant, isExperienceVariant } from '../utilities/checkVariant';
import ExperimentDetailsHeading from './experiment-details-heading';
import ExperimentDetailsSummary from './experiment-details-summary';
import ImplementWinningVariantModal from '../../../containers/implement-winning-variant';
import ExperimentMetricsSummary from './experiment-metrics-summary';
import ExperimentResultsLineChart from './experiment-results-line-chart';
import * as styles from './experiment-details.styles';

const bannerIconStyle = (color: string) => ({
  fill: color,
  width: '2rem',
  alignSelf: 'start',
  margin: '0 1rem 0 0.5rem',
});

const warningBanner = css({
  marginBottom: '20px',
  svg: bannerIconStyle(colors.chart.yellow),
});

const positiveBanner = css({
  marginBottom: '20px',
  svg: bannerIconStyle(colors.chart.green),
});

const globalBanner = css({
  alignItems: 'center',
  backgroundColor: `${colors.mercury}`,
  marginBottom: '20px',
  svg: bannerIconStyle(colors.charcoal),
});

// Will use once by week and by month are ready
// const timeseriesMapping = {
//   daily: 'By Day',
//   weekly: 'By Week',
//   monthly: 'By Month',
// };

interface State {
  valueKey: keyof io.flow.internal.v0.models.ExperimentResults;
  selectedMetric: { value: MetricDropdownValue, content: string },
  startResultsDate: Moment | string;
  endResultsDate: Moment | string;
  selectedMetricRow: string;
}

export default class ExperimentDetails extends Component<Props, State> {
  static displayName = 'ExperimentDetails';

  static defaultProps = {
    endResultsDate: '',
    experimentResults: [],
    onEndExperiment: noop,
    startResultsDate: '',
    // Will re-enable once monthly and weekly are ready
    // timeseriesType: '',
    updateExperimentResults: noop,
  };

  constructor(props: Props, context: any) {
    super(props, context);
    const { experiment } = this.props;
    this.startDateChanged = this.startDateChanged.bind(this);
    this.endDateChanged = this.endDateChanged.bind(this);
    // Will re-enable once monthly and weekly are ready
    // this.timeseriesTypeChanged = this.timeseriesTypeChanged.bind(this);
    this.metricRowClick = this.metricRowClick.bind(this);
    this.state = {
      valueKey: 'conversion_rate',
      // Will re-enable once monthly and weekly are ready
      // timeseries: 'daily',
      selectedMetricRow: '',
      selectedMetric: { value: 'conversion_rate', content: 'Conversion Rate' },
      startResultsDate: moment(experiment.started_at),
      endResultsDate: experiment.ended_at
        ? moment(experiment.ended_at) : moment(),
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount(): void {
    const { startResultsDate, endResultsDate } = this.props;

    if (typeof startResultsDate === 'string' && startResultsDate.length) {
      this.setState({
        startResultsDate,
      });
    }

    if (typeof endResultsDate === 'string' && endResultsDate.length) {
      this.setState({
        endResultsDate,
      });
    }
    // Will re-enable once monthly and weekly are ready
    // if (timeseriesType.length) {
    //   this.setState({
    //     timeseries: timeseriesType,
    //   });
    // }
  }

  // Will re-enable once monthly and weekly are ready
  // timeseriesTypeChanged(timeseries) {
  //   this.setState({
  //     timeseries,
  //     selectedMetricRow: '',
  //   });

  //   this.props.updateExperimentResults(
  //     moment(this.state.startResultsDate).toISOString(),
  //     moment(this.state.endResultsDate).toISOString(),
  //     timeseries,
  //   );
  // }

  handleEndExperiment = (winningVariant?: io.flow.internal.v0.unions.Variant): void => {
    const { toggleEndExperimentModal } = this.props;
    toggleEndExperimentModal(winningVariant);
  };

  handleMetricChange = (selectedMetric: MetricDropdownOption | undefined) => {
    if (!selectedMetric) {
      return;
    }
    this.setState(
      {
        selectedMetric,
        valueKey: selectedMetric.value,
      },
    );
  };

  getBanner = (variantData: io.flow.internal.v0.unions.Variant[]): JSX.Element | null => {
    const { experiment } = this.props;
    if (experiment.status !== 'live') {
      return null;
    }
    let banner = (
      <Banner
        css={warningBanner}
        intent="warning"
        heading={messages.NOT_SIGNIFICANT_HEADING}
        icon={Alert}
        text={messages.SIGNIFICANCE_DEFINITION}
      />
    );
    const winningVariant = getWinningVariant(variantData, experiment);
    if (winningVariant) {
      banner = (
        <Banner
          css={positiveBanner}
          intent="positive"
          heading={`The winning variant is ${winningVariant.discriminator === 'experience_variant' ? winningVariant.experience.name : winningVariant.value.name}`}
          icon={Trophy}
          text={`Experiment results are statistically significant. ${messages.SIGNIFICANCE_DEFINITION}`}
        />
      );
    }
    return banner;
  };

  getGlobalBanner = (): JSX.Element | null => {
    const { experiment } = this.props;

    if (isFeatureExperiment(experiment) && experiment.scope === 'global') {
      return (
        <Banner
          css={globalBanner}
          intent="neutral"
          heading="This is a global experiment testing Flow Features across all clients."
          icon={InfoCircle}
        />
      );
    }

    return null;
  };

  sortResults = (
    data: io.flow.internal.v0.models.ExperimentResultsWithTimestamp[],
    variantOrder: string[],
  ): io.flow.internal.v0.models.ExperimentResultsWithTimestamp[] => data.map((datum) => {
    const sortedResults = sortBy(datum.results,
      (res) => variantOrder.indexOf(res.experiment_variant_key));
    return {
      ...datum,
      results: sortedResults,
    };
  });

  calculateUtcOffset = (date: Moment): string => {
    const offSet = date.utcOffset();
    const formattedDate = moment(date).startOf('day').utcOffset(offSet).format();
    return formattedDate;
  };

  startDateChanged(date: Moment): void {
    const { updateExperimentResults } = this.props;
    const { endResultsDate } = this.state;
    let formattedEndDate: Moment | string = endResultsDate;

    // check if end date is moment object if so then format with utc offset
    if (moment.isMoment(formattedEndDate)) {
      formattedEndDate = this.calculateUtcOffset(formattedEndDate);
    }
    const formattedStartDate = this.calculateUtcOffset(date);
    this.setState({
      startResultsDate: formattedStartDate,
      selectedMetricRow: '',
    });

    updateExperimentResults(
      formattedStartDate,
      formattedEndDate,
    );
  }

  endDateChanged(date: Moment): void {
    const { updateExperimentResults } = this.props;
    const { startResultsDate } = this.state;
    let formattedStartDate: string;

    // check if start date is moment object if so then format with utc offset
    if (moment.isMoment(startResultsDate)) {
      formattedStartDate = this.calculateUtcOffset(startResultsDate);
    } else {
      formattedStartDate = startResultsDate;
    }

    const formattedEndDate = this.calculateUtcOffset(date);

    this.setState({
      endResultsDate: formattedEndDate,
      selectedMetricRow: '',
    });

    updateExperimentResults(
      formattedStartDate,
      formattedEndDate,
    );
  }

  metricRowClick(variantKey: string): void {
    this.setState((prevState) => ({
      selectedMetricRow: variantKey === prevState.selectedMetricRow ? '' : variantKey,
    }));
  }

  render(): JSX.Element {
    const {
      experiences,
      experimentResults,
      experiment,
      organization,
      variants,
      toggleEndExperimentModal,
      country,
      isEndExperimentModalOpen,
      onEndExperiment,
    } = this.props;
    const {
      valueKey, startResultsDate, endResultsDate, selectedMetricRow, selectedMetric,
    } = this.state;

    const hasExperimentMetrics = reduce(variants,
      (sum, data) => { // eslint-disable-line arrow-body-style
        return data.experiment_results ? sum + Object.keys(data.experiment_results).length : sum;
      }, 0) > 0;
    const idToName: Record<string, string> = {};
    variants.forEach((variant) => {
      const keyNameHolder = variant.discriminator === 'experience_variant' ? variant.experience : variant.value;
      idToName[get(keyNameHolder, 'key')] = get(keyNameHolder, 'name', keyNameHolder.key);
    });

    const winningVariant = getWinningVariant(variants, experiment);

    const variantOrder: string[] = variants.map(
      // eslint-disable-next-line no-confusing-arrow
      (v) => isExperienceVariant(v) ? v.experience.key : v.value.key,
    );
    const sortedResults = this.sortResults(experimentResults, variantOrder);
    const region = findRegionById(get(find(experiences, ['key', get(experiment.variants[0], 'experience.key')]), 'region.id'));
    // Will re-enable once monthly and weekly are ready
    // const selectedTimeseries = this.state.timeseries;

    // Had to create array first and then push values
    // was getting type error for metric value type not supported
    // as we only use metric summary values for which showInDropdown = true
    // and typescript was unable resolve the types when using .filter.
    const metricSummaryValuesOptions: MetricDropdownOption[] = [];
    Object.values(metricSummaryValues).forEach((o) => {
      if (o.showInDropdown) {
        metricSummaryValuesOptions.push({ value: o.value, content: o.content });
      }
    });
    return (
      <div style={{ paddingTop: '1.5rem' }}>
        {isEndExperimentModalOpen && (
          <EndExperimentDialog
            isOpen={isEndExperimentModalOpen}
            onCloseDialog={() => toggleEndExperimentModal()}
            onConfirm={() => onEndExperiment(experiment.key)}
          />
        )}

        {winningVariant && (
        <ImplementWinningVariantModal
          winningVariant={winningVariant}
          organization={organization}
          country={country}
        />
        )}
        <ExperimentDetailsHeading
          organizationId={organization.id}
          experiment={experiment}
          onEndExperiment={(): void => this.handleEndExperiment(winningVariant)}
        />
        <div>
          {this.getGlobalBanner()}
          {this.getBanner(variants)}
        </div>
        <Card>
          <CardHeader dividing>
            <CardTitle className={styles.headerTitle}>
              Experiment Settings
            </CardTitle>
          </CardHeader>
          <CardContent>
            <ExperimentDetailsSummary
              organizationId={organization.id}
              experiment={experiment}
              variantData={variants}
              region={get(region, 'name')}
            />
          </CardContent>
        </Card>

        <Card>
          <CardHeader dividing>
            <CardTitle className={styles.headerTitle}>
              Metrics Summary
            </CardTitle>
          </CardHeader>
          <CardContent>
            <ExperimentMetricsSummary
              winningVariant={winningVariant}
              variants={variants}
            />
          </CardContent>
        </Card>

        <Card>
          <CardHeader dividing>
            <CardTitle>
              Metrics Over Time
            </CardTitle>
          </CardHeader>
          <CardContent>
            {hasExperimentMetrics && (
              <div className={styles.metricInputWrapper}>
                <Select
                  labelText="Metric"
                  onValueChange={(value) => this.handleMetricChange(
                    metricSummaryValuesOptions
                      .find((o) => o.value === value),
                  )}
                  value={selectedMetric.value}
                  options={metricSummaryValuesOptions}
                  className={styles.metricInput}
                  textInputProps={{ size: 'small' }}
                  labelSize="small"
                />
                <DateInputField
                  labelText="From"
                  fluid
                  minimumDate={experiment.started_at}
                  maximumDate={endResultsDate}
                  onValueChange={this.startDateChanged}
                  value={startResultsDate}
                />
                <DateInputField
                  labelText="To"
                  fluid
                  minimumDate={startResultsDate}
                  maximumDate={experiment.ended_at || moment()}
                  onValueChange={this.endDateChanged}
                  value={endResultsDate}
                />
              </div>
            )}
            {hasExperimentMetrics && (
              <div>
                {experimentResults.length > 0 && (
                  <ExperimentResultsLineChart
                    idToName={idToName}
                    results={sortedResults}
                    selectedRow={selectedMetricRow}
                    valueKey={valueKey}
                  />
                )}
                {experimentResults.length < 1 && (
                  <div className={styles.emptyChart}>
                    No historical results for that date range
                  </div>
                )}
              </div>
            )}
            {!hasExperimentMetrics && (
              <CardEmptyState>
                Metrics are being collected and will be displayed as soon as
                sufficient data are available.
              </CardEmptyState>
            )}
          </CardContent>
        </Card>
      </div>
    );
  }
}
