import { compose } from 'redux';
import { connect, MapStateToProps } from 'react-redux';
import { createStructuredSelector, Selector } from 'reselect';
import { withFetch } from '@flowio/redux-fetch';
import { getAllResults } from '@flowio/redux-filtering-paging-sorting';

import { fetchExperience, fetchExperienceLogisticsSetting, selectExperienceLogisticsSettings } from '../../../actions';
import { fetchRegions } from '../../../../reference/actions';
import { fetchOrganization } from '../../../../organization/actions';
import { fetchShippingConfigurations, fetchShippingConfigurationByKey } from '../../../../logistics/actions';
import { getCurrentExperienceKey } from '../../../selectors';
import { getOrganizationId } from '../../../../organization/selectors';
import { getShippingConfigurationPaginationState } from '../../../../logistics/selectors';
import ExperienceLogisticsSettingPage from '../components/ExperienceLogisticsSettingPage';
import { ThunkDispatcher, RootState, RootActionTypes } from '../../../../../stores/types';
import { OwnProps, StateProps } from '../types';
import {
  FetchExperienceLogisticsSettingFailureAction,
  FetchExperienceLogisticsSettingSuccessAction,
} from '../../../types';

const fetchState = (
  dispatch: ThunkDispatcher,
  _getState: () => RootState,
  props: OwnProps,
): Promise<unknown> => {
  const { organization, experienceKey } = props.params;
  return Promise.all([
    dispatch(fetchOrganization(organization)),
    dispatch(fetchRegions()),
    dispatch(fetchExperience(organization, experienceKey)),
    // Fetch maximum number of shipping configurations per page to display as
    // initial options of selection dropdown until we add support for paginated
    // dropdown options.
    Promise.all<RootActionTypes>([
      dispatch(fetchShippingConfigurations({
        organizationId: organization,
        entriesPerPage: 100,
        experienceKey,
      })),
      // Fetch experience logistics setting.
      dispatch(fetchExperienceLogisticsSetting({
        organizationId: organization,
        experienceKey,
      })),
    ]).then((results) => {
      if ((results[1] as FetchExperienceLogisticsSettingFailureAction).error) {
        return Promise.all<RootActionTypes>([
          // Clear selected experience logistics settings when application
          // fails to receive one. Generally, this occurs when an experience
          // logistics settings is not available for the current experience.
          dispatch(selectExperienceLogisticsSettings({
            experienceLogisticsSettingsId: '',
          })),
        ]);
      }

      return Promise.all([
        dispatch(selectExperienceLogisticsSettings({
          experienceLogisticsSettingsId: (
            results[1] as FetchExperienceLogisticsSettingSuccessAction
          ).payload.id,
        })),
        // Fetch shipping configuration in experience settings to make sure
        // we have available as options, otherwise details for initial
        // shipping configuration selection won't be rendered on initial mount.
        dispatch(fetchShippingConfigurationByKey({
          organizationId: organization,
          shippingConfigurationKey: (
            results[1] as FetchExperienceLogisticsSettingSuccessAction
          ).payload.shipping_configuration.key,
          experienceKey,
        })),
      ]);
    }),
  ]);
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = createStructuredSelector({
  organizationId: getOrganizationId,
  experienceKey: getCurrentExperienceKey,
  shippingConfigurations: (
    getAllResults(getShippingConfigurationPaginationState) as
      Selector<RootState, io.flow.v0.models.ShippingConfiguration[]>
  ),
});

export default compose(
  withFetch(fetchState),
  connect(mapStateToProps),
)(ExperienceLogisticsSettingPage);
