import { ThunkDispatcher, ThunkResult } from '../../../stores/types';
import standardAction from '../../../utilities/standard-action';
import ActionTypes from '../constants/action-types';
import { login as fbLogin } from '../fbSDK';
import saveToken from './saveToken';
import ErrorTypes from '../constants/error-types';

const onboard = (
  organization: string,
): ThunkResult<Promise<void>> => (
  dispatch: ThunkDispatcher,
): Promise<void> => {
  dispatch(standardAction(ActionTypes.ONBOARD_REQUEST));

  return fbLogin()
    .then(({ success, accessToken }) => {
      if (!success || !accessToken) {
        const error = new Error(ErrorTypes.PERMISSIONS_ERROR);
        dispatch(standardAction(ActionTypes.ONBOARD_FAILURE, error));
        throw error;
      }

      return dispatch(saveToken(organization, accessToken))
        .then(() => {
          dispatch(standardAction(ActionTypes.ONBOARD_SUCCESS));
        })
        .catch(() => {
          const error = new Error(ErrorTypes.GENERIC_ERROR);
          dispatch(standardAction(ActionTypes.ONBOARD_FAILURE, error));
          throw error;
        });
    });
};

export default onboard;
