import { createSelector } from 'reselect';
import get from 'lodash/get';
import identity from 'lodash/identity';
import pick from 'lodash/pick';
import toLower from 'lodash/toLower';
import values from 'lodash/values';

import {
  BillingState,
  StatementsPaginationState,
  StatementsPageState,
  TransactionsPaginationState,
  TransactionsPageState,
} from '../types';
import { RootState } from '../../../stores/types';

export const getBillingState: (state: RootState) => BillingState = createSelector(
  identity,
  (state: RootState) => get(state, 'billing'),
);

/* Accounts */

export const getAccountEntities = createSelector(
  getBillingState,
  (state) => get(state, ['entities', 'accounts']),
);

export const getAccountPagination = createSelector(
  getBillingState,
  (state) => get(state, ['paginations', 'accounts']),
);

export const getAccountCurrentPageNumber = createSelector(
  getAccountPagination,
  (pagination) => get(pagination, 'currentPage'),
);

export const getAccountCurrentPage = createSelector(
  getAccountPagination,
  getAccountCurrentPageNumber,
  (pagination, pageNumber) => get(pagination, `pages.${pageNumber}`),
);

export const getIsAccountCurrentPageFirstPage = createSelector(
  getAccountCurrentPage,
  (page) => get(page, 'isFirstPage'),
);

export const getIsAccountCurrentPageLastPage = createSelector(
  getAccountCurrentPage,
  (page) => get(page, 'isLastPage'),
);

export const getAccountCurrentPageError = createSelector(
  getAccountCurrentPage,
  (page) => get(page, 'error'),
);

export const getAccountCurrentPageResults = createSelector(
  getAccountEntities,
  getAccountCurrentPage,
  (accounts, page) => values(pick(accounts, get(page, 'ids'))),
);

export const getCurrentAccountCurrencyCode = createSelector(
  getBillingState,
  (state) => toLower(get(state, 'account')),
);

export const getCurrentAccountKey = createSelector(
  getBillingState,
  (state) => toLower(get(state, 'account')),
);

export const getCurrentAccount = createSelector(
  getAccountEntities,
  getCurrentAccountKey,
  (accounts, key) => get(accounts, key),
);

export const getCurrentAccountCreatedDate = createSelector(
  getCurrentAccount,
  (account) => get(account, 'created_at'),
);

/* Statements */

export const getStatementEntities = createSelector(
  getBillingState,
  (state) => get(state, ['entities', 'statements']),
);

export const getStatementPagination = createSelector(
  getBillingState,
  (state) => get(state, ['paginations', 'statements']),
);

export const getStatementsCurrentPage = createSelector<
RootState, StatementsPaginationState, StatementsPageState
>(
  getStatementPagination,
  (pagination) => get(pagination, `pages.${get(pagination, 'currentPage')}`),
);

export const getStatementsCurrentPageNumber = createSelector(
  getStatementsCurrentPage,
  (page) => get(page, 'pageNumber'),
);

export const getStatementsCurrentPageResults = createSelector(
  getStatementEntities,
  getStatementsCurrentPage,
  (statements, page) => values(pick(statements, get(page, 'ids'))),
);

export const getStatementCurrentPageError = createSelector(
  getStatementsCurrentPage,
  (page) => get(page, 'error'),
);

export const getIsStatementsCurrentPageFirstPage = createSelector(
  getStatementsCurrentPage,
  (page) => get(page, 'isFirstPage'),
);

export const getIsStatementsCurrentPageLastPage = createSelector(
  getStatementsCurrentPage,
  (page) => get(page, 'isLastPage'),
);

/* Transactions */

export const getTransactionEntities = createSelector(
  getBillingState,
  (state) => get(state, ['entities', 'transactions']),
);

export const getTransactionPagination = createSelector(
  getBillingState,
  (state) => get(state, 'paginations.transactions'),
);

export const getTransactionCurrentPage = createSelector<
RootState, TransactionsPaginationState, TransactionsPageState
>(
  getTransactionPagination,
  (pagination) => get(pagination, `pages.${get(pagination, 'currentPage')}`),
);

export const getTransactionCurrentPageNumber = createSelector(
  getTransactionCurrentPage,
  (page) => get(page, 'pageNumber', 1),
);

export const getTransactionCurrentPageCreatedFrom = createSelector(
  getTransactionCurrentPage,
  (page) => get(page, 'createdFrom'),
);

export const getTransactionCurrentPageCreatedTo = createSelector(
  getTransactionCurrentPage,
  (page) => get(page, 'createdTo'),
);

export const getTransactionCurrentPageStatusFilter = createSelector(
  getTransactionCurrentPage,
  (page) => get(page, 'statusFilter'),
);

export const getTransactionCurrentPageStatement = createSelector(
  getTransactionCurrentPage,
  (page) => get(page, 'statement'),
);

export const getTransactionCurrentPageResults = createSelector(
  getTransactionEntities,
  getTransactionCurrentPage,
  (transactions, page) => values(pick(transactions, get(page, 'ids'))),
);

export const getTransactionCurrentPageError = createSelector(
  getTransactionCurrentPage,
  (page) => get(page, 'error'),
);

export const getIsTransactionCurrentPageFirstPage = createSelector(
  getTransactionCurrentPage,
  (page) => get(page, 'isFirstPage', true),
);

export const getIsTransactionCurrentPageLastPage = createSelector(
  getTransactionCurrentPage,
  (page) => get(page, 'isLastPage', true),
);
