import React from 'react';
import {
  Card, CardHeader, CardContent, CardFooter,
} from '@flowio/react-card';
import noop from 'lodash/noop';

import { toExperienceFormValues } from '../../../utilities/converters';
import ExperienceForm from '../../ExperienceForm';
import FormName from '../../../constants/FormName';

import { MergedProps as Props } from '../types';

if (process.browser) {
  require('./styles.css'); // eslint-disable-line global-require
}

const CreateExperience: React.FC<Props> = ({
  countries,
  currencies,
  languages,
  measurementSystems,
  onCancel,
  onSave,
  onSuccess,
  organizationId,
  regions,
}) => (
  <section className="create-experience">
    <Card id="settings">
      <CardHeader
        actions={[
          { content: 'Cancel', onClick: onCancel },
          { content: 'Save & Continue', intent: 'primary', onClick: onSave },
        ]}
        dividing
        caption="Name &amp; Define Your Regions"
        description="Give your experience a custom name or the name of the region you&apos;re selling in."
      />
      <CardContent>
        <ExperienceForm
          countries={countries}
          currencies={currencies}
          form={FormName.CREATE_EXPERIENCE}
          initialValues={toExperienceFormValues(organizationId)}
          languages={languages}
          measurementSystems={measurementSystems}
          onSubmitSuccess={onSuccess}
          regions={regions}
        />
      </CardContent>
      <CardFooter />
    </Card>
  </section>
);

CreateExperience.displayName = 'CreateExperience';

CreateExperience.defaultProps = {
  onCancel: noop,
  onSave: noop,
};

export default CreateExperience;
