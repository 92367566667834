import ActionTypes from '../constants/action-types';
import { server } from '../../../utilities/clients';
import { getOrganizationId } from '../selectors';
import { RootActionTypes, ThunkResult } from '../../../stores/types';
import { ImportFileParams } from '../types';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';

function createRequestAction(): RootActionTypes {
  return {
    type: ActionTypes.IMPORT_FILE_REQUEST,
  };
}

function createSuccessAction(payload: any): RootActionTypes {
  return {
    type: ActionTypes.IMPORT_FILE_SUCCESS,
    payload,
  };
}

function createFailureAction(payload: FormattedErrorMessages): RootActionTypes {
  return {
    type: ActionTypes.IMPORT_FILE_FAILURE,
    payload,
  };
}

export default function importFile(
  importData: ImportFileParams,
): ThunkResult<Promise<LegacyResponse>> {
  return function importFileEffects(
    dispatch,
    getState,
  ): Promise<LegacyResponse> {
    const organizationId = getOrganizationId(getState());

    dispatch(createRequestAction());

    return server.imports.post(organizationId, {
      body: JSON.stringify(importData),
    }).then((response) => {
      if (response.ok) {
        dispatch(createSuccessAction(response.result));
        return response;
      }
      // Legacy response just sends the error as the result text
      dispatch(createFailureAction({
        messages: [{
          message: response.result.messages[0],
        }],
      }));

      return response;
    });
  };
}
