import { Field, Form, FieldArray } from 'redux-form';
import React from 'react';

import RuleConditions from '../AddRuleWorksheetForm/RuleConditions';
import AnnotatedSection from '../../../../components/AnnotatedSection';
import GenericError from '../../../../components/GenericError';
import * as segmentedControlTheme from '../featuresSegmentedControl.styles';
import { EditRuleWorksheetFormProps, RuleConditionsOwnProps } from '../../types/components';
import { TextField, SegmentedControl } from '../../../../components/ReduxFormFieldAdapters';

const EditRuleWorksheetForm: React.FC<EditRuleWorksheetFormProps> = ({
  availableFilters,
  initialValues,
  handleSubmit,
  error,
}) => (
  <Form onSubmit={handleSubmit}>
    <Field component="input" type="hidden" name="organizationId" />
    <Field component="input" type="hidden" name="key" />
    <Field component="input" type="hidden" name="type" />
    <Field component="input" type="hidden" name="id" />
    {error && (
      <GenericError error={error} />
    )}
    <FieldArray<RuleConditionsOwnProps>
      name="conditions"
      component={RuleConditions}
      availableFilters={availableFilters || []}
    />
    <AnnotatedSection
      title="Value"
      description="This value will be returned when all specified conditions are met."
    >
      {initialValues.type === 'string' && (
        <Field
          name="value"
          component={TextField}
          autoComplete="off"
          fluid
          hintText="Enter Value"
        />
      )}
      {initialValues.type === 'boolean' && (
        <Field
          name="value"
          component={SegmentedControl}
          theme={segmentedControlTheme}
          segments={[{
            id: 'type-boolean-value-true',
            key: 'type-boolean-value-true',
            content: 'True',
            value: 'true',
          }, {
            id: 'type-boolean-value-false',
            key: 'type-boolean-value-false',
            content: 'False',
            value: 'false',
          }]}
        />
      )}
    </AnnotatedSection>
  </Form>
);

EditRuleWorksheetForm.displayName = 'EditRuleWorksheetForm';

EditRuleWorksheetForm.defaultProps = {
  initialValues: undefined,
  availableFilters: [],
  error: undefined,
};

export default EditRuleWorksheetForm;
