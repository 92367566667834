import update from 'immutability-helper';
import get from 'lodash/get';

import ActionTypes from '../constants/action-types';
import { RootActionTypes } from '../../../stores/types';
import { InvoicesState } from '../types';

const initialState: InvoicesState = {
  results: {
    businessInvoices: [],
    consumerInvoices: [],
    businessCreditMemos: [],
    creditMemos: [],
  },
  error: {},
};

export default function invoices(state = initialState, action: RootActionTypes): InvoicesState {
  switch (action.type) {
    case ActionTypes.FETCH_INVOICES_REQUEST:
      return initialState;
    case ActionTypes.FETCH_INVOICES_SUCCESS:

      return update(state, {
        results: {
          businessInvoices: {
            $set: get(action.payload, 'b2bInvoices', []),
          },
          consumerInvoices: {
            $set: get(action.payload, 'consumerInvoices', []),
          },
          creditMemos: {
            $set: get(action.payload, 'creditMemos'),
          },
          businessCreditMemos: {
            $set: get(action.payload, 'b2bCreditMemos'),
          },
        },
        error: {
          $set: {},
        },
      });
    case ActionTypes.FETCH_INVOICES_FAILURE:
      return update(state, {
        results: {
          businessInvoices: {
            $set: [],
          },
          consumerInvoices: {
            $set: [],
          },
          creditMemos: {
            $set: [],
          },
        },
        error: {
          $set: action.payload,
        },
      });
    default:
      return state;
  }
}
