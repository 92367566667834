import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withFetch } from '@flowio/redux-fetch';
import {
  getPageResults,
  getPageNumber,
  getPageSize,
  getIsFirstPage,
  getIsLastPage,
  getIsSinglePage,
} from '@flowio/redux-filtering-paging-sorting';

import {
  activateOptinMessage,
  archiveOptinMessage,
  changeOptinPromptsPage,
  fetchOptinAttributeByKey,
  fetchOptinPrompts,
  hideDeleteOptinContentConfirm,
  hideDeleteOptinMessageConfirm,
  refreshOptinPrompts,
  showDeleteOptinContentConfirm,
  showDeleteOptinMessageConfirm,
  showDialog,
  updateOptinMessageSuccess,
  visitOptinMessageHomePage,
} from '../../../actions';

import { createToast } from '../../../../console/actions';
import { fetchOrganization } from '../../../../organization/actions';
import { fetchRegions, fetchLanguages } from '../../../../reference/actions';
import { getActiveOptinAttribute, getOptinPromptsPaginationState } from '../../../selectors';
import { getOrganizationId } from '../../../../organization/selectors';
import { getRegions, getLanguages } from '../../../../reference/selectors';
import OptinMessageDetailView from '../components/OptinMessageDetailView';
import DialogName from '../../../constants/DialogName';
import { RootState, ThunkDispatcher } from '../../../../../stores/types';
import { DispatchProps, OwnProps, StateProps } from '../types';

const fetchData = (
  dispatch: ThunkDispatcher,
  _getState: () => RootState,
  props: OwnProps,
) => {
  const { params, location } = props;
  const { organization, optinAttributeKey } = params;
  const { entriesPerPage, pageNumber } = location.query;
  return Promise.all([
    dispatch(fetchOptinAttributeByKey(organization, optinAttributeKey)),
    dispatch(fetchOrganization(organization)),
    dispatch(fetchOptinPrompts({
      organizationId: organization,
      entriesPerPage,
      pageNumber,
      queryParams: {
        expand: 'content',
        optin_attribute_key: optinAttributeKey,
      },
    })),
    // If necessary, we can offload this request until the worksheets that need
    // it are opened to speed up page load.
    dispatch(fetchRegions()),
    dispatch(fetchLanguages()),
  ]);
};

const handleDeleteOptinMessageSuccess = () => (dispatch: ThunkDispatcher): void => {
  dispatch(visitOptinMessageHomePage());
  dispatch(hideDeleteOptinMessageConfirm());
  dispatch(createToast({
    intent: 'neutral',
    content: 'Opt-in message has been deleted.',
    icon: 'Trash',
  }));
};

const handleDeleteOptinContentSuccess = () => (dispatch: ThunkDispatcher): void => {
  dispatch(refreshOptinPrompts());
  dispatch(hideDeleteOptinContentConfirm());
  dispatch(createToast({
    intent: 'neutral',
    content: 'Content has been deleted.',
    icon: 'Trash',
  }));
};

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  entriesPerPage: getPageSize(getOptinPromptsPaginationState),
  isFirstPage: getIsFirstPage(getOptinPromptsPaginationState),
  isLastPage: getIsLastPage(getOptinPromptsPaginationState),
  isSinglePage: getIsSinglePage(getOptinPromptsPaginationState),
  languages: getLanguages,
  optinAttribute: getActiveOptinAttribute,
  optinPrompts: getPageResults(getOptinPromptsPaginationState),
  organizationId: getOrganizationId,
  pageNumber: getPageNumber(getOptinPromptsPaginationState),
  regions: getRegions,
});

const mapDispatchToProps = (dispatch: ThunkDispatcher): DispatchProps => bindActionCreators({
  onActivateOptinMessage: activateOptinMessage,
  onAddContent: showDialog(DialogName.OPTIN_CONTENT_WORKSHEET),
  onArchiveOptinMessage: archiveOptinMessage,
  onEditContent: showDialog(DialogName.OPTIN_CONTENT_WORKSHEET),
  onDeleteOptinContent: showDeleteOptinContentConfirm,
  onDeleteOptinContentSuccess: handleDeleteOptinContentSuccess,
  onDeleteOptinMessage: showDeleteOptinMessageConfirm,
  onDeleteOptinMessageSuccess: handleDeleteOptinMessageSuccess,
  onEditOptinMessage: showDialog(DialogName.UPDATE_OPTIN_MESSAGE_WORKSHEET),
  onEditOptinMessageSuccess: updateOptinMessageSuccess,
  onPageChange: changeOptinPromptsPage,
}, dispatch);

export default compose(
  withFetch(fetchData),
  connect(mapStateToProps, mapDispatchToProps),
)(OptinMessageDetailView);
