const DialogName = {
  CREATE_OPTIN_MESSAGE_WORKSHEET: 'createOptinMessageWorksheet',
  DELETE_OPTIN_MESSAGE_CONFIRM: 'optinMessageDeleteConfirm',
  DELETE_OPTIN_CONTENT_CONFIRM: 'optinContentDeleteConfirm',
  OPTIN_CONTENT_WORKSHEET: 'optinContentWorksheet',
  OPTIN_BANNER_CONTENT_WORKSHEET: 'optinBannerContentWorksheet',
  OPTIN_CHECKOUT_CONTENT_WORKSHEET: 'optinCheckoutContentWorksheet',
  OPTIN_MESSAGE_STATUS_CONFIRM: 'optinMessageStatusConfirm',
  UPDATE_OPTIN_MESSAGE_WORKSHEET: 'updateOptinMessageWorksheet',
} as const;

export type DialogNameType = typeof DialogName[keyof typeof DialogName];

export default DialogName;
