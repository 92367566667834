import { Trash } from '@flowio/react-icons';
import React, { FunctionComponent } from 'react';
import { TableRow, TableRowColumn } from '@flowio/react-table';
import BemHelper from '@flowio/bem-helper';
import times from 'lodash/times';
import noop from 'lodash/noop';
import { SelectField } from '@flowio/react-select';
import { Button } from '@flowio/react-button';

const bem = new BemHelper('order-check');

const displayName = 'Line';

const defaultProps = {
  onQuantityChange: noop,
  onRemoveItem: noop,
};

interface LineProps {
  line: io.flow.v0.models.Line;
  itemName: string;
  onQuantityChange: (...args: any) => any;
  onRemoveItem: (...args: any) => any;
}

const LineComponent: FunctionComponent<LineProps> = ({
  line, itemName, onQuantityChange, onRemoveItem,
}) => {
  const handleQuantityChange = (quantity: string): void => {
    onQuantityChange(line.item_number, quantity);
  };
  const generatedOptions = times(Math.max(5, line.quantity * 2), (n) => ({
    value: (n + 1).toString(),
  }));
  const value = {
    value: line.quantity.toString(),
  };

  function handleRemoveItem(): void {
    onRemoveItem(line.item_number);
  }

  return (
    <TableRow>
      <TableRowColumn>
        {line.item_number}
      </TableRowColumn>
      <TableRowColumn className={bem.element('line-item-name')}>
        {itemName}
      </TableRowColumn>
      <TableRowColumn className={bem.element('line-item-quantity')}>
        <SelectField
          value={value.value}
          className={bem.element('line-item-quantity-input')}
          onValueChange={handleQuantityChange}
          options={generatedOptions.map((o) => ({ value: o.value, content: o.value }))}
          fluid
        />
      </TableRowColumn>
      <TableRowColumn>
        {line.price.label}
      </TableRowColumn>
      <TableRowColumn>
        {line.total.label}
      </TableRowColumn>
      <TableRowColumn>
        <Button
          variant="flat"
          leftIcon={Trash}
          size="small"
          onClick={handleRemoveItem}
        />
      </TableRowColumn>
    </TableRow>
  );
};

LineComponent.displayName = displayName;
LineComponent.defaultProps = defaultProps;

export default LineComponent;
