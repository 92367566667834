import { reduxFormWorksheet } from '@flowio/redux-form-overlay';
import { SubmissionError } from 'redux-form';

import { exportPriceBookByKey } from '../../resources/operations/price-books';
import { createToast } from '../../console/actions';
import ExportPriceBook from '../components/ExportPriceBook';
import FormName from '../constants/FormName';

function handleSubmit(values, dispatch) {
  return dispatch(exportPriceBookByKey(
    values.organization,
    values.priceBookKey,
    values.email,
    values.itemIdentifier,
  )).then((error) => {
    if (error) {
      throw new SubmissionError('Failed to export price book, please try again later');
    }
  });
}

function handleSubmitSuccess(action, dispatch) {
  dispatch(createToast({
    content: 'Exporting price book',
    intent: 'positive',
  }));
}

function handleValidation(values) {
  const errors = {};

  if (!values.email) {
    errors.email = 'Email is required';
  }

  return errors;
}

export default reduxFormWorksheet({
  form: FormName.EXPORT_PRICE_BOOK_FORM,
  enableReinitialize: true,
  onSubmit: handleSubmit,
  onSubmitSuccess: handleSubmitSuccess,
  validate: handleValidation,
  saveButton: {
    content: 'Export',
    intent: 'primary',
  },
})(ExportPriceBook);
