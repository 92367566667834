import OptinMessageHome from '../components/OptinMessageHome';
import OptinMessageDetailView from '../components/OptinMessageDetailView';

export default {
  path: ':organization/optin/messages',
  title: 'Customer Opt-Ins',
  indexRoute: {
    component: OptinMessageHome,
  },
  childRoutes: [{
    path: ':optinAttributeKey',
    component: OptinMessageDetailView,
    title: 'Details',
  }],
};
