/** @jsx jsx */

import { jsx } from '@emotion/react';
import { KeyCodes } from '@flowio/browser-helpers';
import { Collapse } from '@flowio/react-collapse';
import React from 'react';

import { NavHeader, NavItemGroup, NavList } from '@flowio/react-nav';

const defaultProps = {
  defaultExpanded: false,
};

type DefaultProps = typeof defaultProps;

type OwnProps = {
  children?: React.ReactNode;
  className?: string;
  expanded?: boolean;
  header?: string;
  icon?: string;
  onCollapse?: () => void;
  onExpand?: () => void;
} & DefaultProps;

type UnhandledProps = Omit<
React.HTMLAttributes<HTMLLIElement>,
keyof OwnProps
>;

type Props = OwnProps & UnhandledProps;

type State = {
  expanded: boolean;
};

function getInitialState(props: Props): State {
  const { defaultExpanded, expanded } = props;
  return {
    expanded: expanded != null ? expanded : defaultExpanded,
  };
}

const getDerivedStateFromProps: React.GetDerivedStateFromProps<Props, State> = (
  props: Props,
  state: State,
): State => {
  const { expanded: expandedProp } = props;
  const { expanded: expandedState } = state;
  return {
    expanded: expandedProp != null ? expandedProp : expandedState,
  };
};

class ConsoleNavItemGroup extends React.Component<Props, State> {
  static defaultProps = defaultProps;

  static getDerivedStateFromProps = getDerivedStateFromProps;

  constructor(props: Props) {
    super(props);
    this.state = getInitialState(props);
    this.handleHeaderClick = this.handleHeaderClick.bind(this);
    this.handleHeaderKeyDown = this.handleHeaderKeyDown.bind(this);
  }

  handleHeaderClick(
    event: React.MouseEvent<HTMLHeadingElement>,
  ): void {
    event.preventDefault();
    event.stopPropagation();
    this.toggleExpanded();
  }

  handleHeaderKeyDown(
    event: React.KeyboardEvent<HTMLHeadingElement>,
  ): void {
    switch (event.keyCode) {
      case KeyCodes.Enter:
        this.toggleExpanded();
        break;
      default:
        break;
    }
  }

  isControlled(): boolean {
    const { expanded } = this.props;
    return expanded != null;
  }

  toggleExpanded(): void {
    const { onCollapse, onExpand } = this.props;
    const { expanded } = this.state;

    if (!this.isControlled()) {
      this.setState({
        expanded: !expanded,
      });
    }

    if (expanded) {
      if (typeof onCollapse === 'function') {
        onCollapse();
      }
    } else if (typeof onExpand === 'function') {
      onExpand();
    }
  }

  render(): React.ReactElement {
    const {
      children,
      header,
      icon,
    } = this.props;

    const {
      expanded,
    } = this.state;

    return (
      <NavItemGroup>
        <React.Fragment>
          <NavHeader
            text={header}
            icon={icon}
            expanded={expanded}
            onClick={this.handleHeaderClick}
            onKeyDown={this.handleHeaderKeyDown}
          />
          <Collapse expanded={expanded}>
            <NavList>
              {children != null ? children : null}
            </NavList>
          </Collapse>
        </React.Fragment>
      </NavItemGroup>

    );
  }
}

export default ConsoleNavItemGroup;
