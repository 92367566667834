import { ActionTypes } from '../constants';
import { ThunkDispatcher, RootState, ThunkExtraArguments } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import toLegacyResponse from '../../../utilities/clients/to-legacy-response';

const errorMessages: Record<number, string> = {
  404: 'Invitation not found or expired.',
};

export default function getOrganizationInvitation(token: string) {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<LegacyResponse<io.flow.v0.models.Invitation | FormattedErrorMessages>> => {
    dispatch({
      type: ActionTypes.GET_ORGANIZATION_INVITATION_REQUEST,
    });
    const state = getState();
    return extra.api(state).invitations.getTokensByToken({
      token,
      expand: ['organization'],
    }).then((response) => {
      if (response.ok) {
        dispatch({
          type: ActionTypes.GET_ORGANIZATION_INVITATION_SUCCESS,
          payload: response.body,
        });
        return toLegacyResponse(response);
      }

      const formattedErrors = formatErrorResponseV2(response, { messages: errorMessages });

      dispatch({
        type: ActionTypes.GET_ORGANIZATION_INVITATION_FAILURE,
        payload: formattedErrors,
      });

      return toLegacyResponse({
        ...response,
        body: formattedErrors,
      });
    });
  };
}
