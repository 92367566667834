import React from 'react';
import { Worksheet, WorksheetHeader, WorksheetBody } from '@flowio/react-worksheet';
import { Button, OutlineButton } from '@flowio/react-button';
import MembershipForm from '../membership-form';
import { MembershipFormValues } from '../membership-form/types';

interface Props {
  /** @description Worksheet open state.  */
  open?: boolean;
  onFormSubmit: (
    values: MembershipFormValues,
    editMembership: io.flow.v0.models.Membership | null,
  ) => void;
  /** @description current logged in user.  */
  userMembership: io.flow.v0.models.Membership;
  /** @description user being edited, if non-existant new user is being invited. */
  editMembership: io.flow.v0.models.Membership | null;
  organizationKey: string;
  onRequestClose: () => void;
  onRequestSubmitInvitationForm: () => void;
}

const MembershipWorksheet: React.FC<Props> = ({
  editMembership,
  open,
  onFormSubmit,
  onRequestClose,
  onRequestSubmitInvitationForm,
  organizationKey,
  userMembership,
}) => {
  const isEditingMember = !!editMembership;

  return (
    <Worksheet open={open}>
      <WorksheetHeader
        leftDecoration={(
          <OutlineButton
            content="Cancel"
            onClick={onRequestClose}
          />
        )}
        content={isEditingMember ? 'Edit Member' : 'Invite Member'}
        rightDecoration={(
          <Button
            content={isEditingMember ? 'Save' : 'Send Invite'}
            intent="primary"
            onClick={onRequestSubmitInvitationForm}
          />
        )}
      />

      <WorksheetBody>
        <MembershipForm
          onFormSubmit={onFormSubmit}
          organizationKey={organizationKey}
          editMembership={editMembership}
          userMembership={userMembership}
        />
      </WorksheetBody>
    </Worksheet>
  );
};

MembershipWorksheet.displayName = 'MembershipWorksheet';

MembershipWorksheet.defaultProps = {
  open: false,
};

export default MembershipWorksheet;
