import moment from 'moment';
import React from 'react';
import { Button } from '@flowio/react-button';
import { Trash, Edit } from '@flowio/react-icons';
import { useSelector } from 'react-redux';
import { css } from '@emotion/react';
import {
  Table,
  TableHead,
  TableRow,
  TableHeadColumn,
  TableBody,
  TableRowColumn,
} from '@flowio/react-table';
import { getUserId } from '../../../console';
import { dynamicWidthColumn, tableColumn } from './styles';

interface OrderNotesListProps {
  notes: io.flow.internal.v0.models.OrderNote[];
  onDelete: (note: io.flow.internal.v0.models.OrderNote) => void;
  onEdit: (note: io.flow.internal.v0.models.OrderNote) => void;
}

function getUserDisplayName(user: io.flow.v0.models.User): string {
  let name = '';

  if (user.name.first && user.name.last) {
    name = `${user.name.first} ${user.name.last.charAt(0).toUpperCase()}.`;
  } else {
    name = user.name.first || user.name.last || '';
  }

  if (user.email) {
    name = name ? `${name} (${user.email})` : `(${user.email})`;
  }

  if (!name || user.email === 'otto@flow.io') {
    name = 'System Generated';
  }

  return name;
}

const OrderNotesList: React.FC<OrderNotesListProps> = ({
  notes,
  onDelete,
  onEdit,
}) => {
  const currentUserId = useSelector(getUserId);

  return (
    <Table striped>
      <TableHead>
        <TableRow>
          <TableHeadColumn css={dynamicWidthColumn}>Date</TableHeadColumn>
          <TableHeadColumn css={dynamicWidthColumn}>Added by</TableHeadColumn>
          <TableHeadColumn>Message</TableHeadColumn>
          <TableHeadColumn css={dynamicWidthColumn} />
        </TableRow>
      </TableHead>
      <TableBody>
        { notes.map((note) => (
          <TableRow key={note.id}>
            <TableRowColumn css={[tableColumn, dynamicWidthColumn]}>
              { moment(note.created_at).format('llll') }
            </TableRowColumn>
            <TableRowColumn
              css={[tableColumn, dynamicWidthColumn, css({
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '225px',
              })]}
            >
              { getUserDisplayName(note.user) }
            </TableRowColumn>
            <TableRowColumn css={tableColumn}>{ note.note }</TableRowColumn>
            <TableRowColumn css={[tableColumn, dynamicWidthColumn]}>
              { (note.user.id === currentUserId) && (
                <>
                  <Button
                    content="Edit"
                    variant="flat"
                    leftIcon={Edit}
                    onClick={() => onEdit(note)}
                    size="small"
                  />
                  <Button
                    content="Delete"
                    variant="flat"
                    leftIcon={Trash}
                    intent="negative"
                    onClick={() => onDelete(note)}
                    size="small"
                    css={css({ marginLeft: '0.375rem', color: '#EE8867' })}
                  />
                </>
              ) }
            </TableRowColumn>
          </TableRow>
        )) }
      </TableBody>
    </Table>
  );
};

export default OrderNotesList;
