import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import { RouterState } from 'react-router';
import ExperienceStatus from '../constants/experience-status';

const searchFilter: io.flow.v0.models.QueryFilterUnstructuredForm = {
  q: '',
  discriminator: 'unstructured',
};

const statusFilter: io.flow.v0.models.QueryFilterStructuredForm = {
  discriminator: 'structured',
  field: 'status',
  operator: 'in',
  values: [
    ExperienceStatus.ACTIVE,
    ExperienceStatus.DRAFT,
  ],
};

export default function toExperienceQueryBuilderForm(
  f: RouterState['location']['query'],
  defaultFilters: string,
): io.flow.v0.models.QueryBuilderFilterForm {
  if (isEmpty(f)) {
    return {
      discriminator: 'filter',
      filters: [searchFilter, statusFilter],
    };
  }

  const queryFilters: { q?: string; [key: string]: unknown }[] = isArray(f) ? f : [f];

  return {
    discriminator: 'filter',
    filters: queryFilters.map((d) => {
      const filter: io.flow.v0.models.QueryFilterUnstructuredForm = {
        discriminator: 'unstructured',
        q: defaultFilters,
        ...d,
      };

      return filter;
    }),
  };
}
