import React, { useEffect, useState } from 'react';
import { isUndefined } from 'lodash';
import { Toast, ToastGroup } from '@flowio/react-toast';
import { Props } from '../types';
import FacebookReauthenticationModal from '../../ReauthenticationModal';
import DisconnectFacebookModal from '../../DisconnectModal';

const expiringText = (numDays: number) => (
  <div>
    <strong>
      {`Access to your Facebook Business Manager account is due to expire in ${numDays} days.`}
    </strong>
    <br />
    <span>
      To ensure your Facebook Catalog continues to update with changes to your Flow
      Product Catalog, simply renew access now
    </span>
  </div>
);

const expiredText = (
  <div>
    <strong>
      Access to your Facebook Business Manager account has expired
    </strong>
    <br />
    <span>
      Your Facebook Catalog is no longer updating with changes to your Flow Product Catalog.
      To resolve this, simply renew access now.
    </span>
  </div>
);

const FacebookRoot: React.FC<Props> = ({
  isInitialized,
  initializeSDK,
  isTokenExpired,
  fetchAccessTokenSummary,
  organization,
  daysToExpiry,
  accessTokenSummary,
  showReauthenticationModal,
  isRefresh,
}) => {
  const [expiryToasts, setExpiryToasts] = useState<React.ComponentProps<typeof Toast>[]>([]);

  useEffect(() => {
    if (!isInitialized) {
      initializeSDK();
    } else if (organization) {
      fetchAccessTokenSummary(organization);
    }
  }, [organization, isInitialized, initializeSDK, fetchAccessTokenSummary]);

  useEffect(() => {
    if (isTokenExpired) {
      if (isRefresh) {
        showReauthenticationModal();
      } else {
        setExpiryToasts([{
          intent: 'negative',
          content: expiredText,
        }]);
      }
    } else if (!isUndefined(daysToExpiry) && daysToExpiry < 7) {
      if (isRefresh) {
        showReauthenticationModal();
      } else {
        const hasExpired = daysToExpiry < 0;
        setExpiryToasts([{
          intent: hasExpired ? 'negative' : 'warning',
          content: hasExpired ? expiredText : expiringText(daysToExpiry),
        }]);
      }
    }
  }, [isTokenExpired, daysToExpiry, isRefresh, showReauthenticationModal]);

  const handleCloseToast = (): void => setExpiryToasts([]);

  const handleRenew = (): void => {
    showReauthenticationModal();
    handleCloseToast();
  };

  return (
    <div>
      <DisconnectFacebookModal />
      {accessTokenSummary && organization && (
        <FacebookReauthenticationModal
          organizationId={organization}
        />
      )}
      <ToastGroup>
        {expiryToasts && expiryToasts.map((props) => (
          <Toast
            key={`facebook-toast-${props.intent}`}
            data-automation-id={`facebook-toast-${props.intent}`}
            icon="info-circle"
            actionText="Renew"
            autoCloseDuration={0}
            onClose={handleCloseToast}
            onActionClick={handleRenew}
            {...props}
          />
        ))}
      </ToastGroup>
    </div>
  );
};

FacebookRoot.displayName = 'FacebookRoot';

export default FacebookRoot;
