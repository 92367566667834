import React, { PureComponent } from 'react';
import noop from 'lodash/noop';
import find from 'lodash/find';
import map from 'lodash/map';
import { Select } from '@flowio/react-select';
import {
  Card, CardHeader, CardContent, CardFooter, CardDescription, CardTitle,
} from '@flowio/react-card';
import { PageContent } from '@flowio/react-page';

import { css } from '@emotion/css';
import { PriceCheckForm } from '../../../../experience';
import { MergedProps as Props } from '../types';
import Alert from '../../../../../components/alert';

const subtitleStyles = css`
  font-size: 1.1rem;
  font-weight: 400;
`;

export default class PriceCheck extends PureComponent<Props> {
  static displayName = 'PriceCheck';

  static defaultProps = {
    experiences: [],
    onComponentUnmount: noop,
    onPriceCheck: noop,
    onExperienceChange: noop,
    experience: undefined,
    pricing: undefined,
    priceCheck: undefined,
  };

  componentWillUnmount(): void {
    const { onComponentUnmount } = this.props;
    onComponentUnmount();
  }

  handleExperienceChange = (value: string): void => {
    const { priceCheck, onExperienceChange } = this.props;
    onExperienceChange({ experience: value, item: priceCheck ? priceCheck.number : '' });
  };

  handlePriceCheck = (item: io.flow.v0.models.LineItem): void => {
    const { experience, onPriceCheck } = this.props;
    onPriceCheck({ item: item.number, experience });
  };

  render(): React.ReactNode {
    const {
      experience,
      experiences,
      pricing,
      priceCheck,
      errors,
    } = this.props;

    const defaultExp = find(experiences, (exp) => exp.key === experience);

    return (
      <PageContent>
        <Card id="price-check">
          <CardHeader dividing>
            <CardTitle content="Price Check" />
            <CardDescription content="Lookup any product in your catalog and view the final displayed price to customers for this experience." />
          </CardHeader>
          <CardContent>
            {errors && map(errors.messages, ({ message }) => (
              <Alert type="failure">
                {message}
              </Alert>
            ))}
            <h3 className={subtitleStyles}>
              Choose Experience
            </h3>
            <Select
              onValueChange={this.handleExperienceChange}
              hintText="Select Experience"
              options={experiences.map((e) => ({ value: e.key, content: e.name }))}
              value={defaultExp?.key}
              fluid
            />
            <h3 className={subtitleStyles}>
              Find an item
            </h3>
            <PriceCheckForm
              pricing={pricing}
              priceCheck={priceCheck}
              onPriceCheck={this.handlePriceCheck}
            />
          </CardContent>
          <CardFooter />
        </Card>
      </PageContent>
    );
  }
}
