import React from 'react';
import get from 'lodash/get';
import noop from 'lodash/noop';
import {
  Table,
  TableHead,
  TableRow, TableRowColumn,
  TableHeadColumn,
  TableBody,
} from '@flowio/react-table';
import classNames from 'classnames';
import FormattedDate from '../../../../components/formatted-date';
import * as styles from './order-detail-search-results.styles';

const displayName = 'OrderDetailSearchResults';

interface OrderDetailSearchResultsProps {
  onOrderDetailSelection?: (...args: any) => void;
  orderDetails?: io.flow.internal.v0.models.OrderDetail[];
  baseCurrency: string;
  organizationCountry: string;
}

const tableRowColumnStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const firstTableRowColumnStyle = {
  paddingLeft: '1rem',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const tableSubmittedRowColumnStyle = {
  ...tableRowColumnStyle,
  width: '170px',
};

const tablePriceRowColumnStyle = {
  ...tableRowColumnStyle,
  width: '1%',
  whiteSpace: 'nowrap',
  textAlign: 'right',
} as const;

const tableStyle = {
  width: 'inherit',
};

const getCustomerEmail = (order: io.flow.v0.models.Order): string => get(order, 'customer.email', '');

const getOrderTotalBasePrice = (
  order: io.flow.v0.models.Order,
  currency: string,
  country: string,
): string => new Intl.NumberFormat(country, { style: 'currency', currency }).format(order.total.base.amount);

const OrderDetailSearchResults: React.FC<OrderDetailSearchResultsProps> = ({
  onOrderDetailSelection = noop,
  orderDetails = [],
  baseCurrency,
  organizationCountry,
}) => (
  <Table displayDensity="compact" fixed singleLine css={tableStyle}>
    <TableHead>
      <TableRow className="header">
        <TableHeadColumn className={classNames(styles.headerCol, 'first')}>Order Number</TableHeadColumn>
        <TableHeadColumn className={styles.headerCol}>Customer</TableHeadColumn>
        <TableHeadColumn className={classNames(styles.headerCol, 'submitted')}>Open</TableHeadColumn>
        <TableHeadColumn className={classNames(styles.headerCol, 'submitted')}>Submitted</TableHeadColumn>
        <TableHeadColumn className={classNames(styles.headerCol, 'price')}>Total</TableHeadColumn>
        <TableHeadColumn className={classNames(styles.headerCol, 'price')}>{ baseCurrency }</TableHeadColumn>
      </TableRow>
    </TableHead>
    <TableBody>
      {orderDetails.map(({ order, submitted_at: submittedAt, created_at: createdAt }, index) => (
        <TableRow
          onClick={(): void => onOrderDetailSelection(order)}
          key={order.id}
          selectable
          striped={!!(index % 2)}
        >
          <TableRowColumn css={firstTableRowColumnStyle}>
            {order.number}
          </TableRowColumn>
          <TableRowColumn css={tableRowColumnStyle}>
            {getCustomerEmail(order)}
          </TableRowColumn>
          <TableRowColumn css={tableSubmittedRowColumnStyle}>
            {createdAt ? (
              <FormattedDate value={createdAt} format="MMM DD YYYY HH:mm z" />
            ) : (
              <span className={styles.notSubmitted}>Not Opened</span>
            )}
          </TableRowColumn>
          <TableRowColumn css={tableSubmittedRowColumnStyle}>
            {submittedAt ? (
              <FormattedDate value={submittedAt} format="MMM DD YYYY HH:mm z" />
            ) : (
              <span className={styles.notSubmitted}>Not Submitted</span>
            )}
          </TableRowColumn>
          <TableRowColumn css={tablePriceRowColumnStyle}>
            {`${order.total.label}`}
          </TableRowColumn>
          <TableRowColumn css={tablePriceRowColumnStyle}>
            { getOrderTotalBasePrice(order, baseCurrency, organizationCountry) }
          </TableRowColumn>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

OrderDetailSearchResults.displayName = displayName;

export default OrderDetailSearchResults;
