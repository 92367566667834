import { css } from '@emotion/css';
import { colors } from '../../../../theme/tokens';

export const step = css`
  flex: 0 1 auto;
  position: relative;
 }
`;

export const completed = css`
  span {
    color: white;
  }
  & :before {
    content: "";
    background: ${colors.green[300]};
  }
`;

export const active = css`
  & :before {
    content: "";
    background: white;
  }
`;

export const stepNumber = css`
  margin-left: auto;
  font-size: 14px;
  position: absolute;
  top: 42px;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-right: auto;
`;

export const stepGroup = css`
  position: relative;
  display: flex;
  justify-content: space-evenly;
  list-style: none;
  width: 50%;
  margin: auto;
  @apply --reset-box-model;

  &.completed {
    &:before {
      background-color: #bfbfbf;
    }
  }

  /* The line rendered between each step */
  &:before {
    content: "";
    position: absolute;
    top: 41px;
    width: 60%;
    height: 2px;
    background-color: ${colors.gray[500]};
    left: 25%;
  }
`;

export const stepTitle = css`
  display: block;
  padding-top: 25px;
  color: #808080;
  font-size: 0.625rem;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  /*
    Renders the circle that indicates whether the step is completed.
    The large top margin is used to vertically align line between each step
    in the middle of the circle.
  */
  &:before {
    content: "";
    display: block;
    background: ${colors.gray[300]};
    margin: 0 auto 12px;
    border-radius: 50px;
    color: #808080;
    font-size: 18px;
    line-height: 40px;
    width: 36px;
    height: 35px;

    &.completed {
      background-color: #bfbfbf;
    }
  }
`;
