import { $HttpOk } from '@flowio/api-sdk';
import { ActivityKind } from '../constants';
import readOrderByNumber from './readOrderByNumber';
import readOrderByIdentifier from './readOrderByIdentifier';
import { getCurrentOrder } from '../selectors';
import createActivity from './createActivity';
import setCurrentOrder from './setCurrentOrder';
import scanItem from './scanItem';
import clearCurrentOrder from './clearCurrentOrder';
import clearCurrentItems from './clearCurrentItems';
import printLabel from './printLabel';
import getItemContent from './getItemContent';
import { ThunkResult } from '../../../stores/types';
import clearLabelResponse from './clearLabelResponse';

function scanNumber({
  number,
  organizationId,
}: {
  number: string,
  organizationId: string,
}): ThunkResult<Promise<void>> {
  return function scanNumberEffects(dispatch, getState) {
    const currentOrder: io.flow.v0.models.Order = getCurrentOrder(getState());

    if (currentOrder != null) {
      if (number === 'clearorder') {
        dispatch(clearCurrentOrder());
        dispatch(clearLabelResponse());
        return Promise.resolve();
      }

      if (number === 'clearselecteditems') {
        dispatch(clearCurrentItems());
        return Promise.resolve();
      }

      if (number === 'printlabel') {
        return dispatch(printLabel());
      }

      const indices: number[] = [];

      const items = currentOrder.items.filter((currentItem, index) => {
        if (currentItem.number === number) {
          indices.push(index);
          return true;
        }

        return false;
      });
      if (items && items.length > 0) {
        items.forEach((item, i) => dispatch(scanItem({ item, index: indices[i] })));
        return Promise.resolve();
      }
    }

    return dispatch(readOrderByNumber(
      organizationId,
      number,
    )).then((
      response,
    ): Promise<
    $HttpOk<io.flow.internal.v0.models.OrderDetail[] | undefined>
    | $HttpOk<io.flow.internal.v0.models.OrderDetail> | undefined> => {
      // Failed to read order by number, maybe it's an order identifier?
      if (!response.ok) {
        return dispatch(readOrderByIdentifier(organizationId, number)).then((res) => {
          if (res.ok) {
            return res;
          }
          return undefined;
        });
      }

      return Promise.resolve(response);
    }).then((action) => {
      // If we got orders, change current order and fetch item contents.
      if (action
        && action.ok
        && ((!Array.isArray(action.body) && action.body)
        || (Array.isArray(action.body) && action.body.length > 0))
      ) {
        const returnedOrder: io.flow.v0.models.Order = Array.isArray(action.body)
          ? action.body[0].order : action.body.order;
        dispatch(createActivity({
          kind: ActivityKind.SCAN_ORDER,
          number,
          organizationId,
        }));
        dispatch(clearLabelResponse());

        const itemNumbers = returnedOrder.items.map((item) => item.number);

        return dispatch(getItemContent(
          organizationId,
          itemNumbers,
        )).then(() => {
          const firstNumber = returnedOrder.number;
          dispatch(setCurrentOrder(firstNumber));
        });
      }

      // If an order is not set, then merchant must be trying to find an order,
      // otherwise assume merchant tried to scan an item in the current order.
      if (currentOrder == null) {
        dispatch(createActivity({
          kind: ActivityKind.UNIDENTIFIED_NUMBER,
          number,
          organizationId,
        }));
        return Promise.resolve();
      }

      dispatch(createActivity({
        kind: ActivityKind.UNIDENTIFIED_ITEM,
        number,
        organizationId,
      }));
      return Promise.resolve();
    });
  };
}

export default scanNumber;
