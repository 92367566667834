import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { browserHistory } from 'react-router';
import { withFetch } from '@flowio/redux-fetch';
import { OrganizationType } from '@flowio/api-constants';
import {
  ItemSearchOptions, OwnProps, StateProps, DispatchProps,
} from '../types';
import { fetchOrganization, getOrganization, getOrganizationId } from '../../../../organization';
import { findCatalogItems, findCatalogItemsLegacy } from '../../../../search/actions';
import { getAttributes, changedCatalogSearch } from '../../../actions';
import { getUserToken } from '../../../../console';
import ProductCatalog from '../components/product-catalog';
import { getCatalogSearchTerm } from '../../../selectors';
import { ThunkDispatcher, RootState } from '../../../../../stores/types';
import getQueryParamValue from '../../../../utilities/get-query-parameter-value';
import { getItemOptionsFromSearchFilters } from '../../../../utilities/get-item-search-filters-from-url';

function getAsyncState(
  dispatch: ThunkDispatcher,
  _getState: () => RootState,
  ownProps: OwnProps,
): Promise<any> {
  const { organization } = ownProps.params;
  return Promise.all([
    dispatch(fetchOrganization(organization)),
    dispatch(getAttributes(organization)),
  ]).then(([org]) => {
    const isChannelOrg = org.result?.type === OrganizationType.CHANNEL;
    return Promise.all([
      dispatch(changedCatalogSearch(getQueryParamValue(ownProps.location.query.q))),
      isChannelOrg
        ? dispatch(findCatalogItems(getItemOptionsFromSearchFilters(ownProps.location), {
          entriesPerPage: getQueryParamValue(ownProps.location.query.entriesPerPage),
          pageNumber: getQueryParamValue(ownProps.location.query.pageNumber),
        }))
        : dispatch(findCatalogItemsLegacy({
          q: ownProps.location.query.q,
          entriesPerPage: getQueryParamValue(ownProps.location.query.entriesPerPage),
          pageNumber: getQueryParamValue(ownProps.location.query.pageNumber),
        })),
    ]);
  });
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = (state) => {
  const { catalog, defaultReducer, search } = state;

  return {
    ...catalog,
    apiToken: getUserToken(state) || '',
    email: defaultReducer.user?.email,
    isFirstPage: search.catalogItems.isFirstPage,
    isLastPage: search.catalogItems.isLastPage,
    items: search.catalogItems.results,
    catalogSearchTerm: getCatalogSearchTerm(state),
    organizationId: getOrganizationId(state),
    organization: getOrganization(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (dispatch, ownProps) => ({
  onRequestSearch(searchText): void {
    dispatch(changedCatalogSearch(searchText));
    browserHistory.push({
      pathname: ownProps.location.pathname,
      query: {
        ...ownProps.location.query,
        q: searchText,
        pageNumber: 1,
      },
    });
  },

  onRequestSMPItemsSearch(searchOptions: ItemSearchOptions): void {
    dispatch(findCatalogItems(searchOptions) as any);
  },
  onRequestNextPage(entriesPerPage = 25): void {
    const currentPage = parseInt(getQueryParamValue(ownProps.location.query.pageNumber), 10) || 1;
    const pageNumber = currentPage + 1;

    browserHistory.push({
      pathname: ownProps.location.pathname,
      query: { ...ownProps.location.query, pageNumber, entriesPerPage },
    });
  },
  onRequestPreviousPage(entriesPerPage = 25): void {
    const currentPage = parseInt(getQueryParamValue(ownProps.location.query.pageNumber), 10) || 1;
    const pageNumber = Math.max(1, currentPage - 1);

    browserHistory.push({
      pathname: ownProps.location.pathname,
      query: { ...ownProps.location.query, pageNumber, entriesPerPage },
    });
  },
  onRequestProduct(number): void {
    browserHistory.push({
      pathname: `${ownProps.location.pathname}/${encodeURIComponent(number)}`,
    });
  },
});

export default withFetch(getAsyncState)(
  connect(mapStateToProps, mapDispatchToProps)(ProductCatalog),
);
