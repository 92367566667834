import { css } from '@emotion/css';
import colors from '../../../../theme/tokens/colors';

const noBorderRad = (pos: string) => css`
  &.no-#{${pos}}-radius {
    border-#{${pos}}-radius: 0;
  }
`;

export const container = css`
  border: 1px solid ${colors.mercury};
  border-radius: 10px;
  padding: 40px;
  background-color: ${colors.white};
  position: relative;
  overflow: hidden;

  ${noBorderRad('top-left')}
  ${noBorderRad('top-right')}
  ${noBorderRad('bottom-left')}
  ${noBorderRad('bottom-right')}

  &.noPadding {
    padding: 0;
  }
`;

export const subtitle = css`
  color: ${colors.marketing['light-slate-gray']};
  font-size: 15px;
  margin: 0;
`;

export const actions = css`
  &.padding {
    padding: 40px;
  }

  & .title {
    color: ${colors.marketing['dark-slate-blue']};
  }


  & .divider {
    margin: 40px 0;
    width: 100%;
  }
`;

export const heading = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  &.wrap {
    flex-wrap: wrap;
  }

  & h2 {
    margin: 0;
  }

  ${actions}
`;
