import { css } from '@emotion/css';
import { colors, radius } from '../../../../theme/tokens';

export const orderItems = css`
  padding: 0.5rem 0rem;

  & table {
    border-collapse: collapse;
    font-size: 0.875rem;
    width: 100%;
  }

  & th,
  & td {
    padding: 0.5rem 1rem;
    line-height: 1.15;
  }
`;

export const tooltip = css`
  background-color: #77818f;
  border-radius: 0.25rem;
  color: white;
  font-size: 0.75rem;
  padding: 0.5rem;
  width: 200px;

  &.center:before {
    position: absolute;
    left: 102px;
    bottom: -7px;
    content: "";
    width: 0;
    height: 0;
    border-left: 0.3125rem solid transparent;
    border-right: 0.3125rem solid transparent;
    border-top: 0.5rem solid #77818f;
  }

  &.right:before {
    position: absolute;
    left: 179px;
    bottom: -7px;
    content: "";
    width: 0;
    height: 0;
    border-left: 0.3125rem solid transparent;
    border-right: 0.3125rem solid transparent;
    border-top: 0.5rem solid #77818f;
  }
`;

export const itemNumber = css`
  padding-left: 1rem !important;
`;

export const itemImage = css`
  max-width: 110px;
`;

export const localPrice = css`
  padding-right: 1rem;
`;

export const basePrice = css`
  background-color: rgba(248, 231, 28, 0.15);
`;

export const columnHead = css`
  font-weight: bold;
  color: ${colors.aluminium};
  fill: ${colors.aluminium};
  text-align: left;
  &.first {
    padding-left: 1rem;
  }

  &.last {
    padding-right: 1rem;
  }
`;

export const subtotal = css`
  color: ${colors.aluminium};
`;

export const total = css`
  font-weight: bold;
`;

export const currency = css`
  text-align: left;
`;

export const deliveryOption = css`
  color: ${colors.aluminium};
  padding-top: 0.3125rem !important;
  vertical-align: top;

  & .delivery-label {
    color: ${colors.aluminium};
    font-weight: 500;
    padding-bottom: 0.25rem;
    display: inline-block;
  }

  & .delivery-number {
    color: ${colors.aluminium};
    font-weight: 500;
    right: 15px;
    position: absolute;
    top: 0.3125rem;
  }

  & td {
    line-height: 1rem;

    &:first-child {
      position: relative;
      padding-left: 3rem;

      &:before {
        content: "";
        position: relative;
        bottom: 25px;
        left: 60%;
        width: 12px;
        display: block;
        height: 0.75rem;
        border-left: 0.125rem solid #9ba6b3;
        border-bottom: 0.125rem solid #9ba6b3;
        box-sizing: border-box;
      }
    }
  }
`;

export const deliveryOptionTier = css`
  color: black;
`;

export const subtotalDetailContainer = css`
  padding: 8px;
  font-size: 12px;
`;

export const subtotalLabel = css`
  border-bottom: 1px dashed ${colors.aluminium};
`;

export const subtotalDetailLabel = css`
  display: inline-block;
  margin-right: 32px;
`;

export const subtotalDetailValue = css`
  float: right;
  text-anchor: start;
`;

export const discountNameTag = css`
  background-color: #BEF5C8;
  border-radius: ${radius.small}px;
  color: #194C32;
  font-weight: bold;
  text-align: center;
  padding: 1px;
`;
