import { createSelector } from 'reselect';
import get from 'lodash/get';
import getExclusionRuleDeleteConfirmation from './getExclusionRuleDeleteConfirmation';
import { RootState } from '../../../stores/types';
import { ExclusionRuleDeleteConfirmationState } from '../types';

const getIsDeletingExclusionRule = createSelector<
RootState, ExclusionRuleDeleteConfirmationState, boolean>(
  getExclusionRuleDeleteConfirmation,
  (state) => get(state, 'deleting'),
);

export default getIsDeletingExclusionRule;
