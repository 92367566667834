/* Feature Listing Form */

import { css } from '@emotion/css';

export const searchBox = css`
  width: 360px;
`;

/* Feature Listing Table */

export const featureListRow = css`
  cursor: pointer;
  &:hover {
    background-color: #e6f2ff !important; /* override striped table */
  }
`;

export const label = css`
  color: #77818f;
  flex: auto;
`;

export const featureScopeLabel = css`
  text-transform: capitalize;
`;

export const actionCol = css`
  text-align: right;
`;

export const lastColumn = css`
  padding: 0 16px 0 0;
`;

export const releasedCol = css`
  white-space: nowrap;
`;

/* Features Dialogs / Worksheets */

export const dialogHeader = css`
  width: 100%;
`;

export const pagination = css`
  margin-top: 8px;
  text-align: right;
`;
