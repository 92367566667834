import ActionTypes from '../constants/action-types';
import { ExperimentsExperimentValuesState, ExperimentsActions } from '../types';

const initialState: ExperimentsExperimentValuesState = {
  values: [],
  loading: false,
  error: false,
};

export default function (
  state: ExperimentsExperimentValuesState = initialState,
  action: ExperimentsActions,
): ExperimentsExperimentValuesState {
  switch (action.type) {
    case ActionTypes.FETCH_EXPERIMENT_DISCRIMINATOR_VALUES_REQUEST:
      return {
        values: [],
        loading: true,
        error: false,
      };
    case ActionTypes.FETCH_EXPERIMENT_DISCRIMINATOR_VALUES_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload,
      };
    default:
      return state;
  }
}
