import { createSelector } from 'reselect';
import { ReportInterval } from '@flowio/api-internal-constants';
import get from 'lodash/get';
import identity from 'lodash/identity';
import { RootState } from '../../../stores/types';

export default createSelector<RootState, RootState, ReportInterval>(
  identity,
  (state) => get<RootState, 'analytics.gmvForm.interval'>(state, 'analytics.gmvForm.interval'),
);
