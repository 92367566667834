import { css } from '@emotion/css';

export const resultsTooltip = css`
  background-color: rgba(25, 25, 25, 0.9);
  border-radius: 4px;
  color: white;
  display: none;
  font-size: 12px;
  line-height: 12px;
  padding: 4px;
  pointer-events: none;
  position: absolute;
  text-align: center;
  width: 275px;
`;

export const dateColumn = css`
  text-align: left;
`;

export const valueColumn = css`
  text-align: right;
`;

export const tooltipTable = css`
  width: 100%;
`;

export const container = css`
  text-align: center;
`;
