import update from 'immutability-helper';
import { RootActionTypes } from '../../../stores/types';
import ActionTypes from '../constants/action-types';
import { CurrenciesState } from '../types';

const defaultState: CurrenciesState = [];

export default function currenciesReducer(
  state = defaultState,
  action: RootActionTypes,
): CurrenciesState {
  switch (action.type) {
    case ActionTypes.FETCH_CURRENCIES_SUCCESS:
      return update(state, { $set: action.payload });
    default:
      return state;
  }
}
