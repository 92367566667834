import ActionTypes from '../constants/ActionTypes';
import { apiInternal } from '../../../utilities/clients';

export default function fetchPendingUsers() {
  return {
    types: [
      ActionTypes.FETCH_PENDING_USERS_REQUEST,
      ActionTypes.FETCH_PENDING_USERS_SUCCESS,
      ActionTypes.FETCH_PENDING_USERS_FAILURE,
    ],
    callAPI(state) {
      return apiInternal.users(state).getPending();
    },
  };
}
