import { connect } from 'react-redux';
import { compose } from 'redux';

import PolicyOverview from '../components/policy-overview/policy-overview';

function mapStateToProps(state, ownProps) {
  return {
    organization: ownProps.params.organization,
  };
}

export default compose(
  connect(mapStateToProps),
)(PolicyOverview);
