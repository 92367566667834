import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import ActionTypes from '../constants/action-types';

function createRequestAction(): RootActionTypes {
  return {
    type: ActionTypes.ASSIGN_ORG_TO_SHOP_REQUEST,
  };
}

function createSuccessAction(payload: io.flow.internal.v0.models.Shop): RootActionTypes {
  return {
    type: ActionTypes.ASSIGN_ORG_TO_SHOP_SUCCESS,
    payload,
  };
}

function createFailureAction(errors: FormattedErrorMessages): RootActionTypes {
  return {
    type: ActionTypes.ASSIGN_ORG_TO_SHOP_FAILURE,
    payload: errors,
  };
}

function assignOrgToShop(organizationId: string, shopToAssign: string): ThunkResult<Promise<void>> {
  return function assignOrgToShopEffects(
    dispatch,
    getState,
    extra,
  ): Promise<void> {
    const state = getState();

    dispatch(createRequestAction());

    return extra.apiInternal(state).shops.putByMyshopifyDomain({
      myshopify_domain: shopToAssign,
      body: {
        organization_id: organizationId,
      },
    }).then((response) => {
      if (response.ok) {
        dispatch(createSuccessAction(response.body));
        return;
      }

      const errors = formatErrorResponseV2(response);
      dispatch(createFailureAction(errors));
    });
  };
}

export default assignOrgToShop;
