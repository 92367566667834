import React from 'react';
import classNames from 'classnames';
import * as styles from './MarketingCard.styles';
import { boxShadow as boxShadowStyles } from '../../styles/styles';

type BorderProp = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';

interface Props {
  noBorderRadius?: BorderProp[];
  boxShadow?: boolean;
  noPadding?: boolean;
  extraClass?: string;
}

const MarketingCard: React.FC<Props> = ({
  children,
  noBorderRadius = [],
  boxShadow = false,
  noPadding = false,
  extraClass,
}) => (
  <div className={classNames(extraClass, styles.container, { noPadding }, ...noBorderRadius.map((pos) => `no-${pos}-radius`))} css={boxShadow && boxShadowStyles('14px 14px 30px 0 #eaecf2')}>
    {children}
  </div>
);

MarketingCard.displayName = 'MarketingCard';

export default MarketingCard;
