import { $HttpResponse } from '@flowio/api-sdk';
import { ThunkResult } from '../../../stores/types';
import { ApiError } from '../../../utilities/clients/api-error';
import { createToast } from '../../console/actions';
import hideDownloadFeedsDialog from './hideDownloadFeedsDialog';

export default function downloadAllFeeds(organization: string,
  emailValue: string, countryFeedIds: string[]):
  ThunkResult<Promise<void | undefined>> {
  return function downloadAllFeedsEffect(
    dispatch,
    getState,
    extra,
  ): Promise<void | undefined> {
    const form: io.flow.internal.v0.models.MarketingGatewayFeedExportForm = {
      emails: [emailValue],
      feed_ids: countryFeedIds,
    };

    return extra.apiInternal(getState()).marketingGatewayFeeds.postExport({
      organization,
      body: {
        ...form,
      },
    }).then((response) => {
      if (response.ok) {
        dispatch(hideDownloadFeedsDialog());
        dispatch(createToast({
          content: 'All feeds downloaded',
          intent: 'positive',
        }));
        return;
      }

      const errorResponse = response as $HttpResponse<io.flow.v0.models.GenericError>;
      const error = new ApiError(errorResponse);
      dispatch(hideDownloadFeedsDialog());
      dispatch(createToast({
        content: 'Unable to download feeds',
        intent: 'negative',
      }));
      throw error;
    });
  };
}
