import React from 'react';
import { Box } from '@flowio/react-box';
import { Image } from '@flowio/react-image';
import Platforms from '../../../constants/Platforms';
import { Props } from '../types';

const FeedsPageTitle: React.FC<Props> = ({
  platform,
}) => (
  <Box inline alignItems="center" spacing="loose">
    <Image
      height={['facebook', 'google'].indexOf(platform) < 0 ? 40 : 32}
      accessibilityLabel={`${platform} logo`}
      source={`https://cdn.flow.io/console/logo_${platform}.svg`}
      css={{ display: 'block' }}
    />
    <span>{Platforms(platform).platformName}</span>
  </Box>
);

FeedsPageTitle.displayName = 'FeedsPageTitle';

export default FeedsPageTitle;
