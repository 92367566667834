import { Ellipsis } from '@flowio/react-icons';
import React from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableRowColumn,
  TableHeadColumn,
  TableBody,
} from '@flowio/react-table';
import { IconButton, OutlineButton } from '@flowio/react-button';
import { Dropdown, DropdownMenu, DropdownItem } from '@flowio/react-dropdown';
import { startCase } from 'lodash';
import { Role } from '@flowio/api-constants';
import * as styles from './memberships-list.styles';
import { DispatchProps } from '../memberships/types';
import { getFullUserName } from '../../utilities';
import { hasOnlyCustomerServiceRoles } from '../../../console/utilities/is-customer-service-user';
import { hasOnlyClassificationRoles } from '../../../console/utilities/is-classification-user';

function hasRemoveUserPermission(membership: io.flow.v0.models.Membership): boolean {
  return membership.role === Role.ADMIN;
}

function hasEditUserPermission(membership: io.flow.v0.models.Membership): boolean {
  return membership.role === Role.ADMIN
    || membership.role === Role.MEMBER;
}

function getMembershipRole(membership: io.flow.v0.models.Membership): string {
  if (hasOnlyClassificationRoles(membership.roles)) {
    return 'Classification User';
  } if (hasOnlyCustomerServiceRoles(membership)) {
    return 'Customer Support';
  }
  return startCase(membership.role);
}

interface Props {
  memberships: io.flow.v0.models.Membership[];
  onEditMember: (membership: io.flow.v0.models.Membership) => void;
  onRemoveMember: DispatchProps['onRemoveMember'];
  user: io.flow.v0.models.Membership;
}

const MembershipList: React.FC<Props> = ({
  memberships,
  onEditMember,
  onRemoveMember,
  user,
}) => {
  const hasEditRole = hasEditUserPermission(user);
  const hasRemoveRole = hasRemoveUserPermission(user);
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeadColumn>Email</TableHeadColumn>
          <TableHeadColumn>Name</TableHeadColumn>
          <TableHeadColumn>Role</TableHeadColumn>
          {(hasEditRole || hasRemoveRole) && (
            <TableHeadColumn />
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {memberships.map((membership, index) => {
          const isCurrentUser = user.id === membership.id;
          return (
            <TableRow striped={index % 2 === 1} key={membership.id}>
              <TableRowColumn>

                {(membership.user as io.flow.v0.models.User).email}
              </TableRowColumn>
              <TableRowColumn>
                {getFullUserName(membership.user as io.flow.v0.models.User)}
              </TableRowColumn>
              <TableRowColumn>
                {getMembershipRole(membership)}
              </TableRowColumn>
              {(hasEditRole || hasRemoveRole) && (
                <TableRowColumn className={styles.memberActions}>
                  {hasEditRole && (
                    <OutlineButton
                      className={styles.memberEditCta}
                      content="Edit"
                      onClick={(): void => onEditMember(membership)}
                      size="small"
                    />
                  )}
                  {hasRemoveRole && (
                    <Dropdown
                      trigger={<IconButton className={styles.memberMenuCta} size="small" icon={Ellipsis} />}
                    >
                      <DropdownMenu>
                        <DropdownItem
                          className={styles.memberMenuItem}
                          disabled={isCurrentUser}
                          onClick={(): void => {
                            if (!isCurrentUser) {
                              onRemoveMember(membership);
                            }
                          }}
                        >
                          Remove Member
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  )}
                </TableRowColumn>
              )}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

MembershipList.displayName = 'MembershipList';

export default MembershipList;
