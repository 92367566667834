import React, { useEffect, useState } from 'react';
import AddAuthDialog from '../../AddAuthDialog/containers/AddAuthDialog';
import FeedsCreationConfirmationDialog from '../../../containers/FeedsCreationConfirmationDialog';
import DisconnectConfirmDialog from '../../DisconnectConfirmDialog';
import DeleteFeedConfirmationDialog from '../../DeleteFeedConfirmationDialog/containers/DeleteFeedConfirmationDialog';
import DisconnectChannelDialog from '../../DisconnectChannelDialog/containers/DisconnectChannelDialog';
import DownloadFeedsDialog from '../../DownloadFeedsDialog';
import ErrorDialog from '../../ErrorDialog';
import RequestChannelsDialog from '../../RequestChannelsDialog';
import { Props } from '../types';

const MarketingRoot: React.FC<Props> = ({
  params: {
    organization,
    platformId,
    accountId,
    catalogId,
  },
  children,
  location: { query },
  fetchOrganization,
}) => {
  const [showErrorDialog, setShowErrorDialog] = useState(false);

  useEffect(() => {
    if (query.authError) {
      setShowErrorDialog(true);
    }
  }, [query]);

  useEffect(() => {
    fetchOrganization(organization);
  }, [fetchOrganization, organization]);

  return (
    <div>
      {children}
      <AddAuthDialog />
      <DisconnectConfirmDialog
        organizationId={organization}
      />
      <DisconnectChannelDialog organizationId={organization} />
      <FeedsCreationConfirmationDialog
        organizationId={organization}
        platformId={platformId}
        accountId={accountId || catalogId}
      />
      <DeleteFeedConfirmationDialog
        organizationId={organization}
      />
      <DownloadFeedsDialog
        organizationId={organization}
      />
      <RequestChannelsDialog
        organizationId={organization}
      />
      <ErrorDialog
        organizationId={organization}
        isOpen={showErrorDialog}
        onClose={(): void => setShowErrorDialog(false)}
        errorCode={query.authError}
      />
    </div>
  );
};

MarketingRoot.displayName = 'MarketingRoot';

export default MarketingRoot;
