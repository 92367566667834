import { css } from '@emotion/css';

export const container = css`
  font-size: 14px;
`;

export const title = css`
  display: inline-block;
  padding: 0;
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1export const 2;
  letter-spacing: normal;
  color: #0051b2;
`;

export const tier = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 30px;
`;

export const tierName = css`
  flex: 0 0 auto;
  background-color: #e6f2ff;
  border-radius: 2px;
  border: solid 1px #fff;
  padding: 0 9px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
  text-align: center;
  color: #0051b2;
`;

export const tierRules = css`
  flex: 1;
  padding-left: 1rem;
  padding-right: 1rem;
`;

export const centers = css`
  display: flex;
  margin-left: 4px;
  margin-right: 4px;
  margin-top: 1px;
  align-items: center;
`;

export const laneText = css`
  margin-top: 1px;
`;

export const region = css`
  margin-left: 4px;
`;

export const laneDescription = css`
  display: flex;
  align-items: center;
`;
