import ActionTypes from '../constants/action-types';
import toQueryBuilderFilterForm from '../utilities/toQueryBuilderFilterForm';
import { toFeatureRuleForm } from '../utilities';
import addRuleByKey from './addRuleByKey';
import updateRuleByKeyAndId from './updateRuleByKeyAndId';
import { createToast } from '../../console/actions';
import { ThunkResult } from '../../../stores/types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';
import { AddRuleWorksheetFormValues } from '../types/components';

export default function submitFeatureRuleForm(
  values: AddRuleWorksheetFormValues,
): ThunkResult<Promise<void>> {
  return function submitFeatureRuleFormEffects(dispatch, getState, extra) {
    dispatch({
      type: ActionTypes.SUBMIT_FEATURE_RULE_FORM_REQUEST,
    });

    return extra.apiInternal(getState()).features.postRuleAndQueryAndBuilders({
      organization: values.organizationId,
      body: toQueryBuilderFilterForm(values.conditions),
    }).then((response) => {
      if (response.ok) {
        const queryBuilder = response.body;
        const featureRuleForm = toFeatureRuleForm(
          values.type,
          queryBuilder.q,
          values.value,
        );

        dispatch({
          type: ActionTypes.SUBMIT_FEATURE_RULE_FORM_SUCCESS,
          payload: queryBuilder,
        });

        if (!values.id) {
          dispatch(addRuleByKey(
            values.organizationId,
            values.key,
            {
              featureRuleForm,
            },
          ));
        } else {
          dispatch(updateRuleByKeyAndId(
            values.organizationId,
            values.key,
            values.id,
            {
              featureRuleForm,
            },
          ));
        }

        return;
      }

      const errors = formatErrorResponseV2(response);
      dispatch({
        type: ActionTypes.SUBMIT_FEATURE_RULE_FORM_FAILURE,
        payload: errors,
      });
      dispatch(createToast({
        content: 'Failed to submit rule, try again later',
        icon: 'Error',
        intent: 'negative',
      }));
    });
  };
}
