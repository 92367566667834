import { connect, MapStateToProps } from 'react-redux';
import { withFetch } from '@flowio/redux-fetch';
import ExperienceLocalization from '../components/localization';
import { fetchOrganization, getOrganizationId } from '../../../../organization';
import {
  fetchCountries, fetchCurrencies, fetchLanguages, fetchRegions,
} from '../../../../reference/actions';
import {
  getCountries, getCurrencies, getLanguages, getRegions,
} from '../../../../reference/selectors';
import { fetchExperience, getSubcatalogStatistics } from '../../../actions';
import { getCurrentExperience, getCurrentExperienceSubcatalogId, getStatistics } from '../../../selectors';
import { ThunkDispatcher, RootState } from '../../../../../stores/types';
import { OwnProps, StateProps } from '../types';

function getAsyncState(
  dispatch: ThunkDispatcher,
  getState: () => RootState,
  ownProps: OwnProps,
): Promise<unknown[]> {
  const { organization, experienceKey } = ownProps.params;
  return Promise.all([
    dispatch(fetchOrganization(organization)),
    dispatch(fetchExperience(organization, experienceKey)),
    dispatch(fetchRegions()),
    dispatch(fetchCountries()),
    dispatch(fetchCurrencies()),
    dispatch(fetchLanguages()),
  ]).then(() => {
    const subcatalogId = getCurrentExperienceSubcatalogId(getState());
    return Promise.all([
      dispatch(getSubcatalogStatistics(organization, subcatalogId)),
    ]);
  });
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = (state: RootState) => {
  const { ui } = state;
  return {
    ...ui.experience,
    countries: getCountries(state),
    currencies: getCurrencies(state),
    languages: getLanguages(state),
    experience: getCurrentExperience(state),
    organization: getOrganizationId(state),
    previousLocation: `/${getOrganizationId(state)}/experience`,
    regions: getRegions(state),
    statistics: getStatistics(state),
  };
};

export default withFetch(getAsyncState)(connect(mapStateToProps)(ExperienceLocalization));
