import { css } from '@emotion/css';
import colors from '../../../../theme/tokens/colors';

export const balance = css`
  display: inline-block;
  font-weight: 500;
`;

export const pending = css`
  display: inline-block;
  margin-left: 1rem;
  font-size: 0.875rem;
  font-style: italic;
  color: ${colors.silver};
`;

export const infoIcon = css`
  width: 16px;
  height: 16px;
  color: ${colors.steel};
`;
