import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import React from 'react';
import StaticContainer from 'react-static-container';
import curry from 'lodash/fp/curry';

import { getOrganizationId } from '../../../organization/selectors';
import { getUserToken } from '../../../console/selectors';
import { api } from '../../../../utilities/clients';
import checkHttpStatus from '../../../../utilities/check-http-status';
import CancelError from './cancel-error';
import { LegacyResponse } from '../../../../utilities/clients/types/server';
import { RootState } from '../../../../stores/types';

type CancellationCallback = () => Promise<unknown>;

/**
 * Enables the ability to cancel the promise returned by the specified callback. You will know that
 * your promise was cancelled when the promise returned is rejected with a CancelError.
 * @param {Function} callback A function that returns the promise you want to enable cancellation.
 * @param {Promise} cancel A promise that is settled when the promise should be cancelled.
 */
const withCancellation = curry<CancellationCallback, Promise<unknown[]>, Promise<unknown>>(
  (callback: CancellationCallback, cancel: Promise<unknown[]>) => {
    const both = (x: CancellationCallback): [CancellationCallback, CancellationCallback] => [x, x];
    const p1 = callback();
    const p2 = cancel.then(...both(() => Promise.reject(new CancelError('The operation was cancelled'))));
    return Promise.race([p1, p2]);
  },
);

const defaultRenderFetched = (
  conversion: io.flow.v0.models.PriceWithBase,
): string => conversion.label;

interface Props {
  amount: string | number;
  base: string;
  experienceKey?: string;
  organization: string;
  renderLoading?: Function;
  renderFailure?: Function;
  renderFetched?: Function;
  target?: string;
  token?: string;
}

interface State {
  status: string;
  conversion?: io.flow.v0.models.PriceWithBase;
}

class CurrencyConversion extends React.PureComponent<Props, State> {
  static displayName = 'CurrencyConversion';

  static defaultProps = {
    experienceKey: undefined,
    renderLoading: undefined,
    renderFailure: undefined,
    renderFetched: defaultRenderFetched,
    target: undefined,
  };

  constructor(props: Props) {
    super(props);
    this.state = this.getInitialState();
    this.fetchConversionIml = withCancellation(this.fetchConversion.bind(this));
  }

  getInitialState(): State {
    return { status: 'pending' };
  }

  componentDidMount(): void {
    this.updateConversion();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(): void {
    this.shouldFlush = true;
  }

  componentDidUpdate(): void {
    if (this.shouldFlush) {
      this.shouldFlush = false;
      this.cancelConversion();
      this.updateConversion();
    }
  }

  componentWillUnmount(): void {
    this.cancelConversion();
  }

  shouldFlush = false;

  fetchConversionIml: Function;

  cancel?: Function;

  cancelConversion(): void {
    if (this.cancel) {
      this.cancel();
      this.cancel = undefined;
    }
  }

  fetchConversion(): Promise<LegacyResponse<io.flow.v0.models.PriceWithBase>> {
    const {
      amount,
      base,
      experienceKey,
      organization,
      target,
      token,
    } = this.props;

    const params: {
      experience?: string;
      currency?: string;
    } = {};

    if (experienceKey) {
      params.experience = experienceKey;
    } else if (target) {
      params.currency = target;
    }

    return api.experiences().getConversionsByBaseAndAmount(organization, base, amount, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params,
    });
  }

  updateConversion(): Promise<void> {
    const cancel = new Promise((resolve) => { this.cancel = resolve; });
    this.setState({ status: 'loading' });
    return this.fetchConversionIml(cancel)
      .then(checkHttpStatus)
      .then((response: LegacyResponse<io.flow.v0.models.PriceWithBase>) => {
        this.setState({ conversion: response.result, status: 'fulfilled' });
      }).catch((error: CancelError | Error) => {
        if (error.name !== 'CancelError') {
          this.setState({ status: 'rejected' });
        }
      });
  }

  render(): JSX.Element {
    const { conversion, status } = this.state;
    const { renderLoading, renderFailure, renderFetched } = this.props;

    let shouldUpdate = true;
    let children;

    if (status === 'loading' || status === 'pending') {
      if (renderLoading) {
        children = renderLoading();
      }
    } else if (status === 'rejected') {
      if (renderFailure) {
        children = renderFailure();
      }
    } else if (status === 'fulfilled') {
      if (renderFetched) {
        children = renderFetched(conversion);
      }
    }

    if (typeof children === 'undefined') {
      children = null;
      shouldUpdate = false;
    }

    return (
      <StaticContainer shouldUpdate={shouldUpdate}>
        <span>{children}</span>
      </StaticContainer>
    );
  }
}

const mapStateToProps = createStructuredSelector<
RootState, { organization: string; token?: string }
>({
  organization: getOrganizationId,
  token: getUserToken,
});

export default connect(mapStateToProps)(CurrencyConversion);
