import React from 'react';
import BemHelper from '@flowio/bem-helper';

if (process.browser) {
  require('./query-target.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('query-target');

interface Props {
  /**
   * @property {ReactElement} Action element(s) to display on the right side.
   */
  actions: React.ReactNode;

  /**
   * @property {String}
   */
  className: string;

  /**
   * @property {ReactElement|String} This is the block element that contains the query text. If a
   * string is passed in, a div tag will be rendered.
   */
  label: React.ReactNode;

  /**
   * @property {String} Type of query target.
   */
  type: 'exclusion' | 'inclusion' | 'restriction';
}

const QueryTarget: React.FC<Props> = ({
  actions,
  label,
  type,
  className,
}) => {
  const renderActionsIfNecessary = (): React.ReactNode => {
    if (!React.Children.count(actions)) {
      return null;
    }

    return (
      <div className={bem.element('actions')}>
        {React.Children.toArray(actions)}
      </div>
    );
  };

  const renderLabel = (): React.ReactNode => {
    if (React.isValidElement(label)) {
      return label;
    }

    return (
      <div className={bem.element('label')}>
        {label}
      </div>
    );
  };

  const modifiers = {
    inclusion: type === 'inclusion',
    exclusion: type === 'exclusion',
    restriction: type === 'restriction',
  };

  return (
    <div className={bem.block(modifiers, className)}>
      {renderLabel()}
      {renderActionsIfNecessary()}
    </div>
  );
};

QueryTarget.displayName = 'QueryTarget';

QueryTarget.defaultProps = {
  actions: undefined,
  className: undefined,
};

export default QueryTarget;
