import { server } from '../../../utilities/clients';
import {
  RootState, ThunkDispatcher, ThunkResult,
} from '../../../stores/types';
import ActionTypes from '../constants/action-types';
import { getOrganizationId } from '../../organization';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import isResponseOk from '../../../utilities/isResponseOk';
import formatErrorResponse from '../../../utilities/format-error-response';
import { ConsoleServerSuggestions } from '../../../types';
import { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';

export default function findCatalogSuggestions(
  searchText: string,
): ThunkResult<
  Promise<LegacyResponse<ConsoleServerSuggestions | FormattedErrorMessages>>
  > {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
  ): Promise<LegacyResponse<ConsoleServerSuggestions | FormattedErrorMessages>> => {
    dispatch({
      type: ActionTypes.FIND_CATALOG_SUGGESTIONS_REQUEST,
    });

    const state = getState();

    return server.suggestions.getCatalogItems(
      getOrganizationId(state),
      { params: { q: searchText } },
    ).then((response) => {
      if (isResponseOk(response)) {
        dispatch({
          params: {},
          type: ActionTypes.FIND_CATALOG_SUGGESTIONS_SUCCESS,
          payload: response.result,
        });
        return response;
      }

      const formattedErrorResponse = formatErrorResponse(response);
      dispatch({
        params: {},
        type: ActionTypes.FIND_CATALOG_SUGGESTIONS_FAILURE,
        payload: formattedErrorResponse.result,
      });

      return formattedErrorResponse;
    });
  };
}
