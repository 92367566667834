import update from 'immutability-helper';
import { RootActionTypes } from '../../../stores/types';
import ActionType from '../constants/ActionType';
import { ReturnPolicyWorksheetState, ReturnPolicyStatus } from '../types';

const initialState = {
  error: undefined,
  returnPolicy: undefined,
  queryBuilder: undefined,
  status: 'pending' as ReturnPolicyStatus,
  visibility: 'hidden',
};

export default function returnPolicyWorksheetReducer(
  state: ReturnPolicyWorksheetState = initialState,
  action: RootActionTypes,
) {
  switch (action.type) {
    case ActionType.HideReturnPolicyWorksheet:
      return update(state, {
        visibility: { $set: 'hidden' },
      });
    case ActionType.ShowReturnPolicyWorksheetRequest:
      return update(state, {
        error: { $set: undefined },
        returnPolicy: { $set: undefined },
        queryBuilder: { $set: undefined },
        status: { $set: 'loading' },
        visibility: { $set: 'visible' },
      });
    case ActionType.ShowReturnPolicyWorksheetSuccess:
      return update(state, {
        returnPolicy: { $set: action.payload.returnPolicy },
        queryBuilder: { $set: action.payload.queryBuilder },
        status: { $set: 'fulfilled' },
      });
    case ActionType.ShowReturnPolicyWorksheetFailure:
      return update(state, {
        error: { $set: action.payload },
        status: { $set: 'rejected' },
      });
    case ActionType.SubmitReturnPolicyFormError:
      return update(state, {
        error: { $set: action.payload },
        status: { $set: 'fulfilled' },
      });
    default:
      return state;
  }
}
