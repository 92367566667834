import { $HttpResponse } from '@flowio/api-sdk';
import ActionTypes from '../constants/ActionTypes';
import { ApiError } from '../../../utilities/clients/api-error';
import standardAction from '../../../utilities/standard-action';
import {
  RootState, ThunkExtraArguments, ThunkResult, ThunkDispatcher,
} from '../../../stores/types';

export default function createFeedsBulk(
  organization: string,
  forms: io.flow.internal.v0.models.MarketingGatewayFeedForm[],
): ThunkResult<Promise<void>> {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<void> => {
    dispatch({
      type: ActionTypes.CREATE_FEEDS_BULK_REQUEST,
    });

    return extra.apiInternal(getState()).marketingGatewayFeeds.postBulk({
      organization,
      body: forms,
    }).then((response) => {
      if (response.ok) {
        dispatch(standardAction(ActionTypes.CREATE_FEEDS_BULK_SUCCESS, response.body));
        return;
      }

      const errorResponse = response as $HttpResponse<io.flow.v0.models.GenericError>;
      const error = new ApiError(errorResponse);

      dispatch(standardAction(ActionTypes.CREATE_FEEDS_BULK_FAILURE));
      throw error;
    });
  };
}
