import { Form, Field } from 'redux-form';
import React from 'react';
import { Stack } from '@flowio/react-stack';

import { css } from '@emotion/css';
import GenericError from '../../../../components/GenericError';
import presence from '../../../../utilities/validators/presence';
import { UpdateShippingConfigurationFormProps } from '../../types/components';
import { TextField } from '../../../../components/ReduxFormFieldAdapters';

const fieldsetStyles = css`
  padding: 16px;
  border-radius: 4px;
  border: solid 1px #e6e6e6;
  box-sizing: border-box;
`;

const validateName = [
  presence({
    message: 'Enter name',
    allowEmpty: false,
  }),
];

const UpdateShippingConfigurationForm: React.FC<UpdateShippingConfigurationFormProps> = ({
  error,
  handleSubmit,
}) => (
  <Form onSubmit={handleSubmit}>
    <Stack orientation="vertical" spacing="loose">
      {error && (
        <GenericError error={error} />
      )}
      <div className={fieldsetStyles}>
        <Field
          component="input"
          type="hidden"
          name="organizationId"
        />
        <Field
          component="input"
          type="hidden"
          name="shippingConfigurationKey"
        />
        <Field
          autoComplete="off"
          component={TextField}
          fluid
          inline
          id="name"
          name="name"
          labelFor="name"
          labelText="Name"
          validate={validateName}
        />
      </div>
    </Stack>
  </Form>
);

UpdateShippingConfigurationForm.displayName = 'UpdateShippingConfigurationForm';

export default UpdateShippingConfigurationForm;
