import React, { FunctionComponent } from 'react';

interface ErrorMessageProps {
  message: string;
}

const ErrorMessage: FunctionComponent<ErrorMessageProps> = ({ message }) => {
  const messageStyle = {
    color: '#D50000',
  };

  return (
    <div
      className="error opensans py2 pl3 ml1 f4"
      style={messageStyle}
    >
      {message}
    </div>
  );
};

ErrorMessage.displayName = 'ErrorMessage';

export default ErrorMessage;
