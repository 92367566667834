import assign from 'lodash/assign';
import { compose } from 'redux';
import { type RouterState, browserHistory } from 'react-router';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { isValid } from 'redux-form';
import { withFetch } from '@flowio/redux-fetch';
import Exports from '../components/exports/exports';
import {
  fetchScheduledExports, deleteExport, setSelectedExport, closeExport, toggleDialog,
} from '../actions';
import {
  getExports, getExportsError, getUiState, getExportsCurrentPageNumber, getIsExportsFirstPage,
  getIsExportsLastPage,
  getExportsDialogStatus,
} from '../selectors';
import { ThunkDispatcher, RootState } from '../../../stores/types';
import { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import { WithPagingAndSortingResponse } from '../../../utilities/types';
import { LegacyResponse } from '../../../utilities/clients/types/server';

interface StateProps {
  error?: FormattedErrorMessages;
  valid: boolean;
  openDialog: boolean;
  pageNumber: number;
  firstPage: boolean;
  lastPage: boolean;
  exports: io.flow.v0.models.ScheduledExport[];
  exportCreated?: boolean;
}

interface DispatchProps {
  onDeleteExport: (id: string) => void;
  onToggleDialog: () => void;
  onRequestPage: (args: { entriesPerPage: number; pageNumber: number }) => void;
  onSelectExport: (exportItem: io.flow.v0.models.ScheduledExport) => void;
  onCloseExport: () => void;
}

type OwnProps = RouterState;

function getAsyncState(dispatch: ThunkDispatcher):
Promise<
WithPagingAndSortingResponse<io.flow.v0.models.ScheduledExport[]>
| LegacyResponse<FormattedErrorMessages>
> {
  return dispatch(fetchScheduledExports());
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = (
  state: RootState,
): StateProps => (assign({}, getUiState(state), {
  error: getExportsError(state),
  valid: isValid('scheduledExportsForm')(state),
  openDialog: getExportsDialogStatus(state),
  pageNumber: getExportsCurrentPageNumber(state),
  firstPage: getIsExportsFirstPage(state),
  lastPage: getIsExportsLastPage(state),
  exports: getExports(state),
}));

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch: ThunkDispatcher,
  ownProps: OwnProps,
): DispatchProps => ({
  onDeleteExport(id: string): void {
    dispatch(deleteExport(id));
  },
  onToggleDialog(): void {
    dispatch(toggleDialog());
  },
  onRequestPage(
    { entriesPerPage = 25, pageNumber }: { entriesPerPage: number; pageNumber: number },
  ): void {
    dispatch(fetchScheduledExports({ entriesPerPage, pageNumber }));
    browserHistory.push({
      pathname: ownProps.location.pathname,
      query: {
        ...ownProps.location.query,
        entriesPerPage,
        pageNumber,
      },
    });
  },
  onSelectExport(exportItem: io.flow.v0.models.ScheduledExport): void {
    dispatch(setSelectedExport(exportItem));
  },
  onCloseExport(): void {
    dispatch(closeExport());
  },
});

export default compose(
  withFetch(getAsyncState),
  connect<StateProps, DispatchProps, OwnProps, RootState>(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(Exports);
