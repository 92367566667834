import ActionTypes from '../constants/action-types';

import standardAction from '../../../utilities/standard-action';
import { RootActionTypes } from '../../../stores/types';

interface Settings {
  experience: string;
  center: string;
  items: io.flow.v0.models.LocalizedLineItem[];
  selections: string;
}

export default function setOrderSummaryItem(settings: Partial<Settings>): RootActionTypes {
  return standardAction(ActionTypes.SET_ORDER_SUMMARY_SETTINGS, settings);
}
