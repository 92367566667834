import { browserHistory } from 'react-router';
import { getOrganizationId } from '../../organization';
import { ThunkDispatcher, RootState } from '../../../stores/types';

export default function goToExperienceLocalizationPage(experienceKey: string) {
  return (
    _dispatch: ThunkDispatcher,
    getState: () => RootState,
  ): void => {
    const organization = getOrganizationId(getState());
    browserHistory.push(`/${organization}/experience/${experienceKey}/localization`);
  };
}
