import { connect } from 'react-redux';
import { browserHistory, type RouterState } from 'react-router';
import { withFetch } from '@flowio/redux-fetch';
import { compose } from 'redux';
import moment from 'moment';
import { ThunkDispatcher } from '../../../../../middlewares/types';

import DiscountListing from '../components/DiscountListing';
import { RootState } from '../../../../../stores/types';
import { fetchExperiences } from '../../../../experience/actions';
import { getExperiences } from '../../../../experience';
import fetchDiscounts from '../../../actions/fetchDiscounts';
import deleteDiscount from '../../../actions/deleteDiscount';
import { getDiscounts } from '../../../selectors';
import updateDiscount from '../../../actions/updateDiscount';
import updateDiscountFilters from '../../../actions/updateDiscountFilters';
import { createToast } from '../../../../console/actions';
import { fetchOrganization } from '../../../../organization';
import { StateProps, DispatchProps, OwnProps } from '../types';

const PAGE_SIZE = 25;

const getAsyncState = (
  dispatch: ThunkDispatcher,
  _getState: () => RootState,
  ownProps: RouterState,
): Promise<unknown[]> => {
  const {
    params: {
      organization,
    },
    location: {
      query,
    },
  } = ownProps;
  const { pageNumber, status }: { pageNumber?: string; status?: string | string[] } = query;

  let statusToUse: string[] = [];
  let pageNumberToUse = 0;

  if (status) {
    if (typeof status === 'string') {
      statusToUse = [status];
      dispatch(updateDiscountFilters(statusToUse));
    } else {
      statusToUse = status;
      dispatch(updateDiscountFilters(statusToUse));
    }
  }

  if (pageNumber) {
    pageNumberToUse = (Number(pageNumber) - 1);
  }

  return Promise.all([
    dispatch(fetchOrganization(organization)),
    dispatch(fetchExperiences(organization, { limit: 100 })),
    dispatch(fetchDiscounts(organization, {
      limit: PAGE_SIZE,
      offset: pageNumberToUse * PAGE_SIZE,
      status: statusToUse,
    })),
  ]);
};

function mapStateToProps(state: RootState, ownProps: OwnProps): StateProps {
  const {
    location: {
      query,
    },
    params: {
      organization,
    },
  } = ownProps;
  const discounts = getDiscounts(state);
  const { pageNumber }: { pageNumber?: string } = query;

  return {
    organization,
    experiences: getExperiences(state),
    pageNumber: pageNumber ? Number(pageNumber) : 1,
    isLastPage: discounts.length < PAGE_SIZE,
    isFirstPage: !pageNumber || pageNumber === '1',
    discounts,
  };
}

function mapDispatchToProps(
  dispatch: ThunkDispatcher,
  ownProps: any,
): DispatchProps {
  return {
    onRequestDiscountFilterChange: (status: string[]): void => {
      browserHistory.push({
        pathname: ownProps.location.pathname,
        query: {
          ...ownProps.location.query,
          status,
        },
      });
    },
    onEdit: (discount: io.flow.v0.models.DiscountRuleSettings): void => {
      const pathName = `${ownProps.location.pathname}/${discount.id}`;
      browserHistory.push({
        pathname: pathName,
      });
    },
    addDiscount: (): void => {
      const pathName = `${ownProps.location.pathname}/new`;
      browserHistory.push({
        pathname: pathName,
      });
    },
    onFetchNewPage: (pageNumber: number): void => {
      browserHistory.push({
        pathname: ownProps.location.pathname,
        query: { ...ownProps.location.query, pageNumber },
      });
    },
    onEnableDiscount: (
      organization: string,
      discount: io.flow.v0.models.DiscountRuleSettings,
    ): void => {
      const discountForm: io.flow.v0.models.DiscountRuleSettingsForm = {
        name: discount.name,
        from_with_tz: discount.from_with_tz,
        experience_keys: discount.experience_keys,
        rule: discount.rule,
      };
      Promise.resolve(dispatch(updateDiscount(organization, discount.id, discountForm)))
        .then((result: void | io.flow.v0.models.DiscountRuleSettings) => {
          if (result) {
            dispatch(createToast({
              intent: 'positive',
              content: 'Successfully enabled the discount',
            }));
            browserHistory.push({
              pathname: ownProps.location.pathname,
              query: ownProps.location.query,
            });
          } else {
            dispatch(createToast({
              intent: 'negative',
              content: 'Encountered a problem enabling the discount',
            }));
          }
        });
    },
    onDisableDiscount: (
      organization: string,
      discount: io.flow.v0.models.DiscountRuleSettings,
    ): void => {
      const formattedDate = moment().minute(0).toISOString();
      const discountForm: io.flow.v0.models.DiscountRuleSettingsForm = {
        name: discount.name,
        from_with_tz: {
          datetime: formattedDate,
          timezone: 'UTC',
        },
        to_with_tz: {
          datetime: formattedDate,
          timezone: 'UTC',
        },
        experience_keys: discount.experience_keys,
        rule: discount.rule,
      };
      Promise.all([dispatch(updateDiscount(organization, discount.id, discountForm))])
        .then((result: (void | io.flow.v0.models.DiscountRuleSettings)[]) => {
          if (result[0]) {
            dispatch(createToast({
              intent: 'positive',
              content: 'Successfully disabled the discount',
            }));
            browserHistory.push({
              pathname: ownProps.location.pathname,
              query: ownProps.location.query,
            });
          } else {
            dispatch(createToast({
              intent: 'negative',
              content: 'Encountered a problem disabling the discount',
            }));
          }
        });
    },
    onDelete: (organization: string, discountId: string): void => {
      Promise.all([dispatch(deleteDiscount(organization, discountId))]).then(
        (result: (void | boolean)[]): void => {
          if (result[0]) {
            dispatch(createToast({
              intent: 'positive',
              content: 'Successfully deleted the discount',
            }));
            browserHistory.push({
              pathname: ownProps.location.pathname,
              query: ownProps.location.query,
            });
          } else {
            dispatch(createToast({
              intent: 'negative',
              content: 'Encountered a problem deleting the discount',
            }));
          }
        },
      );
    },
  };
}

export default compose(
  withFetch(getAsyncState),
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
)(DiscountListing);
