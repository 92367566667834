import ActionTypes from '../constants/ActionTypes';
import { RootActionTypes } from '../../../stores/types';

export default function updateDiscountFilters(
  discountStatusFilter: string[],
): RootActionTypes {
  return {
    type: ActionTypes.DISCOUNT_FILTERS_UPDATED,
    payload: {
      discountStatusFilter,
    },
  };
}
