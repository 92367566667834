import { OptinAttributesGetByKeyResponse } from '@flowio/api-internal-sdk';
import ActionTypes from '../constants/ActionTypes';
import {
  RootState, ThunkExtraArguments, ThunkResult, ThunkDispatcher,
} from '../../../stores/types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';

const fetchOptinAttributeByKey = (
  organization: string,
  optinAttributeKey: string,
): ThunkResult<Promise<OptinAttributesGetByKeyResponse>> => (
  dispatch: ThunkDispatcher,
  getState: () => RootState,
  extra: ThunkExtraArguments,
): Promise<OptinAttributesGetByKeyResponse> => {
  const meta = {
    organizationId: organization,
    optinAttributeKey,
  };

  dispatch({
    type: ActionTypes.FETCH_OPTIN_ATTRIBUTE_REQUEST,
    meta,
  });

  return extra.apiInternal(getState()).optinAttributes.getByKey({
    organization,
    key: optinAttributeKey,
  }).then((response) => {
    if (response.ok) {
      dispatch({
        type: ActionTypes.FETCH_OPTIN_ATTRIBUTE_SUCCESS,
        payload: response.body,
        meta,
      });
    } else {
      dispatch({
        type: ActionTypes.FETCH_OPTIN_ATTRIBUTE_FAILURE,
        payload: formatErrorResponseV2(response),
        meta,
      });
    }

    return response;
  });
};

export default fetchOptinAttributeByKey;
