import { css } from '@emotion/css';
import colors from '../../../../theme/tokens/colors';

export const outerContainer = css`
  padding: 4px 0;
`;

export const container = css`
  align-items: center;
  border: solid 1px ${colors.gray[300]};
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 3px 8px 0 rgba(77,88,102,0.10);
  display: flex;
  height: 48px;
  justify-content: space-between;
  z-index: 1;

  &.dragging {
    box-shadow: 0 4px 8px 0 rgba(77,88,102,0.20);
  }
`;

export const dragHandle = css`
  width: 48px;
  border-right: solid 1px ${colors.gray[300]};
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  flex-basis: auto;
  cursor: grab;
`;

export const logoContainer = css`
  margin: 3px 16px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 40px;
  height: 24px;
`;

export const name = css`
  flex-grow: 1;
  font-size: 14px;
  color: ${colors.gray[900]};
`;

export const ccTooltip = css`
  margin-left: 8px;
  color: ${colors.gray[800]};
`;

export const disabled = css`
  .drag-handle {
    cursor: not-allowed !important; /* else will have cursor grab */
  }
`;

export const cardLogos = css`
  display: flex;
  flex-direction: row;
  border-right: solid 1px #eeeeee;
  align-items: center;
  margin-right: 12px;
  flex-shrink: 0;

  & .logo-container {
    margin: 0 4px;
  }
`;

export const editCardsLink = css`
  text-decoration: underline;
  cursor: pointer;
  display: block;
  font-size: 14px;
  letter-spacing: 0;
  margin: 0 12px;
  color: ${colors['flow-blue'][400]};
`;

export const switchInput = css`
  .slidewindow {
    margin-right: 16px;
  }
  &:checked + .slidewindow .switch-slider {
    background-color: ${colors.green[300]} !important; /* or else will not overide default slider styles */
  }
`;

export const showToggle = css`
  font-weight: 500;
`;

export const switchThumb = css`
  vertical-align: -5.5px;
`;
