import { compose } from 'redux';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { withFetch } from '@flowio/redux-fetch';
import assign from 'lodash/assign';
import { fetchOrganization, getOrganizationId } from '../../organization';
import resetExperience from '../../experience/actions/reset-experience';
import fetchExperienceItemByNumber from '../../experience/actions/fetch-experience-item-by-number';
import fetchAllExperiences from '../../experience/actions/fetch-all-experiences';
import fetchExperience from '../../experience/actions/fetchExperience';
import fetchPricing from '../../experience/actions/get-pricing';
import fetchPriceFormat from '../../experience/actions/get-price-format';
import {
  getPricing,
  getPriceFormat,
  getExperienceSearchResults,
  getCurrentExperience,
  getCurrentExperienceDeliveredDuty,
  getCurrentExperienceCurrency,
  getExperienceItem,
} from '../../experience/selectors';
import ProductDetailsCheck from '../components/product-details-check';
import { ProductDetailsFormData } from '../components/product-details-form/product-details-form';
import { RootState, ThunkDispatcher } from '../../../stores/types';

interface OwnProps {
  params: {
    organization: string;
  };
  location: {
    query: {
      experience: string;
      product: string;
    };
  };
}

interface StateProps {
  currency?: string;
  item: io.flow.v0.models.Item;
  experience: io.flow.v0.models.Experience | null;
  experiences: io.flow.v0.models.Experience[];
  landedCostSetting?: io.flow.v0.enums.DeliveredDuty;
  // Unique identifier for the organization
  organization: string;
  priceFormatSetting: io.flow.v0.models.ExperienceCurrencyFormat;
  priceSettings?: io.flow.v0.models.Pricing;
}

interface DispatchProps {
  onSubmit: (formData: ProductDetailsFormData) => void;
}

function getAsyncState(
  dispatch: ThunkDispatcher,
  _getState: () => RootState,
  ownProps: OwnProps,
): Promise<unknown[]> {
  const promises: unknown[] = [];
  const { organization } = ownProps.params;
  const { experience, product } = ownProps.location.query;

  // This is synchronous so it should finish immediately. We need to reset the experience state
  // to avoid unexpected results when experiences are reduced by another sub-application.
  promises.push(Promise.resolve(dispatch(resetExperience())));

  promises.push(dispatch(fetchOrganization(organization)));
  promises.push(dispatch(fetchAllExperiences(organization)));

  if (product) {
    promises.push(dispatch(fetchExperienceItemByNumber(organization, product, {
      experience,
    })));
  }

  if (experience) {
    promises.push(dispatch(fetchExperience(organization, experience)));
    promises.push(dispatch(fetchPricing(organization, experience)));
    promises.push(dispatch(fetchPriceFormat(organization, experience)));
  }

  return Promise.all(promises);
}

const mapStateToProps = (state: RootState): StateProps => ({
  experience: getCurrentExperience(state),
  experiences: getExperienceSearchResults(state),
  item: getExperienceItem(state),
  organization: getOrganizationId(state),
  priceSettings: getPricing(state),
  priceFormatSetting: getPriceFormat(state),
  landedCostSetting: getCurrentExperienceDeliveredDuty(state),
  currency: getCurrentExperienceCurrency(state),
});

const mapDispatchToProps = (
  _: ThunkDispatcher,
  ownProps: OwnProps,
): DispatchProps => ({
  onSubmit(formData: ProductDetailsFormData): void {
    const nextLocation = assign({}, ownProps.location, {
      query: {
        experience: formData.experience,
        product: formData.product.text,
      },
    });
    browserHistory.push(nextLocation);
  },
});

export default compose(
  withFetch(getAsyncState),
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
)(ProductDetailsCheck);
