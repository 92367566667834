import React from 'react';
import PropTypes from 'prop-types';
import AccountSelection from '../../constants/AccountSelection';
import Button from '../MarketingButton';
import MarketingDialog from '../MarketingDialog';

interface Props {
  isOpen: boolean;
  onClick: () => void;
}

const {
  CONNECTED_CONFIRM_DIALOG_TITLE,
  CONNECTED_CONFIRM_DIALOG_SUBTITLE,
} = AccountSelection;

const ConnectionConfirmationDialog: React.FC<Props> = ({
  isOpen,
  onClick,
}) => (
  <MarketingDialog
    isOpen={isOpen}
    title={CONNECTED_CONFIRM_DIALOG_TITLE}
    subtitle={CONNECTED_CONFIRM_DIALOG_SUBTITLE}
  >
    <Button intent="primary" onClick={onClick}>Got it</Button>
  </MarketingDialog>
);

ConnectionConfirmationDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ConnectionConfirmationDialog;
