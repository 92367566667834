import React from 'react';
import { DateInputField } from '@flowio/react-date-input';
import { WrappedFieldInputProps, WrappedFieldMetaProps, WrappedFieldProps } from 'redux-form';
import { FormGroupIntent } from '@flowio/react-form-group';

type ReduxFormDateEditProps = WrappedFieldProps &
{
  shouldError?: (
    input: WrappedFieldInputProps, meta: WrappedFieldMetaProps, props?: any
  ) => boolean,
  shouldWarn?: (input: WrappedFieldInputProps, meta: WrappedFieldMetaProps, props?: any) => boolean
} & typeof DateInputField;

const ReduxFormDateEdit: React.FC<ReduxFormDateEditProps> = (props) => {
  const {
    input, meta, shouldWarn, shouldError, children, ...unhandledProps
  } = props;

  const { error, warning } = meta;

  let feedbackText;
  let intent: FormGroupIntent = 'neutral';

  if (error && shouldError && shouldError(input, meta)) {
    feedbackText = error;
    intent = 'negative';
  } else if (warning && shouldWarn && shouldWarn(input, meta)) {
    feedbackText = warning;
    intent = 'warning';
  }

  return (
    <DateInputField
      {...unhandledProps}
      feedback={feedbackText}
      intent={intent}
      name={input.name}
      onBlur={input.onBlur}
      onValueChange={input.onChange}
      onFocus={input.onFocus}
      value={input.value}
    />
  );
};

export default ReduxFormDateEdit;
