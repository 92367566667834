import { createSelector } from 'reselect';
import getMarketingState from './getMarketingState';
import { RootState } from '../../../stores/types';
import { MarketingState } from '../types';

export default createSelector<
RootState, MarketingState, io.flow.internal.v0.models.MarketingGatewayGoogleAccountSummary[]
>(
  getMarketingState,
  ({ googleConnectionAccounts }: MarketingState):
  io.flow.internal.v0.models.MarketingGatewayGoogleAccountSummary[] => (
    googleConnectionAccounts.accounts || []
  ),
);
