import ActionTypes from '../constants/action-types';
import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';

function createRequestAction(): RootActionTypes {
  return {
    type: ActionTypes.FETCH_REGION_SETTINGS_REQUEST,
  };
}

function createSuccessAction(
  payload: io.flow.v0.models.RegionSetting[],
): RootActionTypes {
  return {
    type: ActionTypes.FETCH_REGION_SETTINGS_SUCCESS,
    payload,
  };
}

function createFailureAction(payload: FormattedErrorMessages): RootActionTypes {
  return {
    type: ActionTypes.FETCH_REGION_SETTINGS_FAILURE,
    payload,
  };
}

export default function fetchRegionSettings(
  organization: string,
): ThunkResult<Promise<void>> {
  return function fetchRegionSettingsEffects(
    dispatch,
    getState,
    extra,
  ): Promise<void> {
    dispatch(createRequestAction());
    return extra.api(getState()).organizations.getSettingsAndRegionsByOrganizationId({
      organization_id: organization,
    }).then((response) => {
      if (response.ok) {
        dispatch(createSuccessAction(response.body));
        return;
      }

      const errors = formatErrorResponseV2(response);
      dispatch(createFailureAction(errors));
    });
  };
}
