import update from 'immutability-helper';
import { RootActionTypes } from '../../../stores/types';
import { DataState } from '../../../types';

export type ShippingNotificationsReducersState = Record<
string, Record<string, DataState<io.flow.v0.models.ShippingNotification[]>>
>;

function getInitialState(): ShippingNotificationsReducersState {
  return {};
}

function bootstrapOrderState(
  state: ShippingNotificationsReducersState,
  organizationId: string,
  orderNumber: string,
) {
  return {
    [organizationId]: {
      ...(state[organizationId] || {}),
      [orderNumber]: {
        ...((state[organizationId] && state[organizationId][orderNumber]) || {}),
      },
    },
    ...state,
  };
}

export default function shippingNotificationsReducer(
  state: ShippingNotificationsReducersState = getInitialState(),
  action: RootActionTypes,
): ShippingNotificationsReducersState {
  switch (action.type) {
    case 'FETCH_SHIPPING_NOTIFICATIONS_REQUEST':
      return update(state, {
        [action.params.organizationId]: (org) => update(org || {}, {
          [action.params.orderNumber]: (order) => update(order || {}, {
            loading: { $set: true },
            $unset: ['data', 'errors'],
          }),
        }),
      });
    case 'FETCH_SHIPPING_NOTIFICATIONS_SUCCESS':
      return update(bootstrapOrderState(
        state,
        action.params.organizationId,
        action.params.orderNumber,
      ), {
        [action.params.organizationId]: {
          [action.params.orderNumber]: {
            data: { $set: action.payload },
            loading: { $set: false },
            $unset: ['errors'],
          },
        },
      });
    case 'FETCH_SHIPPING_NOTIFICATIONS_FAILURE':
      return update(bootstrapOrderState(
        state,
        action.params.organizationId,
        action.params.orderNumber,
      ), {
        [action.params.organizationId]: {
          [action.params.orderNumber]: {
            $unset: ['data'],
            loading: { $set: false },
            errors: { $set: action.errors },
          },
        },
      });
    default:
      return state;
  }
}
