import { RouterState } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFetch } from '@flowio/redux-fetch';

import Integrations from '../components/integrations';
import fetchWebhooks from '../../webhook/actions/fetchWebhooks';
import { fetchOrganization, fetchMemberships, fetchPublicKeys } from '../actions';
import fetchApiKeys from '../../tokens/actions/fetchApiKeys';
import { fetchUserOrganizationMemberships } from '../../user';
import { getOrganizationId } from '../selectors';
import { RootState, ThunkDispatcher } from '../../../stores/types';

function getAsyncState(
  dispatch: ThunkDispatcher,
  _getState: () => RootState,
  ownProps: RouterState,
) {
  const { organization } = ownProps.params;

  return Promise.all([
    dispatch(fetchOrganization(organization)),
    dispatch(fetchWebhooks(organization, { entriesPerPage: 100 })),
    dispatch(fetchApiKeys(organization)),
    dispatch(fetchMemberships(organization)),
    dispatch(fetchUserOrganizationMemberships()),
    dispatch(fetchPublicKeys(organization)),
  ]);
}

function mapStateToProps(state: RootState) {
  return {
    organization: getOrganizationId(state),
  };
}

export default compose(
  withFetch(getAsyncState),
  connect(mapStateToProps),
)(Integrations);
