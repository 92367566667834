import ActionTypes from '../constants/ActionTypes';
import { DialogNameType } from '../constants/DialogName';
import { DialogPayload } from '../types';

const showDialog = (dialog: DialogNameType) => (payload: DialogPayload) => ({
  type: ActionTypes.SHOW_DIALOG,
  meta: { name: dialog },
  payload,
});

export default showDialog;
