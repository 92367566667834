import { Stack } from '@flowio/react-stack';
import { Breadcrumbs } from '@flowio/react-breadcrumbs';
import { PageActions, PageHeader, PageTitle } from '@flowio/react-page';
import React, { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@flowio/react-box';
import { ButtonShorthand, IconButton } from '@flowio/react-button';
import { Copy } from '@flowio/react-icons';
import { css } from '@emotion/react';
import { createToast } from '../../../console/actions';

interface OrderDetailsHeaderProps {
  organizationId: string;
  order: io.flow.v0.models.Order;
  // eslint-disable-next-line react/require-default-props
  actions?: ButtonShorthand[];
  // eslint-disable-next-line react/require-default-props
  children?: ReactNode;
}

const OrderDetailsHeader: React.FC<OrderDetailsHeaderProps> = ({
  organizationId,
  order,
  actions,
  children,
}) => {
  const dispatch = useDispatch();

  const onCopyOrderLink = () => {
    navigator.clipboard.writeText(window.location.href).then(() => {
      dispatch(createToast({
        intent: 'positive',
        content: 'Successfully copied link to order',
      }));
    }).catch(() => {
      dispatch(createToast({
        intent: 'negative',
        content: 'Could not copy link to order',
      }));
    });
  };

  return (
    <PageHeader>
      <Breadcrumbs items={[{ content: 'Customer Service', href: `/${organizationId}/orders/search` }]} />
      <Stack alignment="center" distribution="equalSpacing">
        <PageTitle>
          {`Order ${order.number}`}
          <IconButton
            css={css({ marginLeft: '0.25rem' })}
            icon={Copy}
            onClick={(): void => onCopyOrderLink()}
            variant="flat"
            intent="neutral"
            size="medium"
          />
        </PageTitle>
        {(actions || children) && (
          <PageActions spacing="tight" actions={actions}>
            {children && (
              <Box spacing="tight" alignItems="center">
                {children}
              </Box>
            )}
          </PageActions>
        )}
      </Stack>
      {order.order_type === 'replacement' && (
        <span css={css({
          position: 'relative',
          top: '-1.25rem',
          color: '#d2431f',
        })}
        >
          Replacement Order
        </span>
      )}
    </PageHeader>
  );
};

export default OrderDetailsHeader;
