import Client from './client';

export default class SpotRates extends Client {
  constructor(opts) {
    let options = opts;

    if (typeof opts === 'string') {
      options = { host: opts }; // convert host string to options object
    }

    options.serviceName = 'API Internal';

    super(options);
  }

  get(options = {}) {
    return this.makeRequest(`${this.host}/internal/currency/spot-rates`, options);
  }

  getVersions(options = {}) {
    return this.makeRequest(`${this.host}/internal/currency/spot-rates/versions`, options);
  }

}
