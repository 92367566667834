import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import {
  FETCH_API_KEYS_SUBMIT,
  FETCH_API_KEYS_SUCCESS,
  FETCH_API_KEYS_FAILURE,
} from '../constants';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import toLegacyResponse from '../../../utilities/clients/to-legacy-response';

function createRequestAction(): RootActionTypes {
  return {
    type: FETCH_API_KEYS_SUBMIT,
  };
}

function createSuccessAction(payload: io.flow.v0.unions.Token[]): RootActionTypes {
  return {
    type: FETCH_API_KEYS_SUCCESS,
    payload,
  };
}

function createFailureAction(payload: FormattedErrorMessages): RootActionTypes {
  return {
    type: FETCH_API_KEYS_FAILURE,
    payload,
  };
}

export default function createNewApiKey(
  organization: string,
): ThunkResult<Promise<LegacyResponse<
  io.flow.v0.unions.Token[] |
  undefined
  >>> {
  return function createNewApiKeyEffects(
    dispatch,
    getState,
    extra,
  ): Promise<LegacyResponse<
    io.flow.v0.unions.Token[] |
    undefined
    >> {
    dispatch(createRequestAction());

    return extra.api(getState()).tokens.get({
      organization,
    }).then((response) => {
      if (response.ok) {
        dispatch(createSuccessAction(response.body));
        return toLegacyResponse(response);
      }

      const errors = formatErrorResponseV2(response);
      dispatch(createFailureAction(errors));
      return toLegacyResponse(response);
    });
  };
}
