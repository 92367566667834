import { OptinAttributesPutByKeyResponse } from '@flowio/api-internal-sdk';
import ActionTypes from '../constants/ActionTypes';
import {
  RootState, ThunkExtraArguments, ThunkResult, ThunkDispatcher,
} from '../../../stores/types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';
import standardAction from '../../../utilities/standard-action';

const deleteOptinAttributeByKey = (
  organization: string,
  optinAttributeKey: string,
  optinAttributeForm: io.flow.internal.v0.models.OptinAttributeForm,
): ThunkResult<Promise<OptinAttributesPutByKeyResponse>> => (
  dispatch: ThunkDispatcher,
  getState: () => RootState,
  extra: ThunkExtraArguments,
): Promise<OptinAttributesPutByKeyResponse> => {
  dispatch(standardAction(ActionTypes.UPDATE_OPTIN_ATTRIBUTE_REQUEST));

  return extra.apiInternal(getState()).optinAttributes.putByKey({
    organization,
    key: optinAttributeKey,
    body: optinAttributeForm,
  }).then((response) => {
    if (response.ok) {
      dispatch(standardAction(ActionTypes.UPDATE_OPTIN_ATTRIBUTE_SUCCESS, response.body));
    } else {
      dispatch({
        type: ActionTypes.UPDATE_OPTIN_ATTRIBUTE_FAILURE,
        payload: formatErrorResponseV2(response),
      });
    }
    return response;
  });
};

export default deleteOptinAttributeByKey;
