import { combineReducers } from 'redux';
import assign from 'lodash/assign';
import map from 'lodash/map';
import update from 'immutability-helper';
import toLower from 'lodash/toLower';
import ActionTypes from '../constants/action-types';
import ReadyState from '../constants/ready-state';
import { RootActionTypes } from '../../../stores/types';
import { AccountsPagesState, AccountsPageState } from '../types';

const mapByKey = (accounts: io.flow.internal.v0.models.OrganizationAccount[]):
string[] => map(accounts, (account) => toLower(account.key));

function pages(state = {}, action: RootActionTypes): AccountsPagesState {
  switch (action.type) {
    case ActionTypes.FETCH_ORGANIZATION_ACCOUNTS_REQUEST:
      return update(state, {
        [action.params.pageNumber]: {
          $apply: (page: AccountsPageState): AccountsPageState => assign({}, page, {
            status: ReadyState.LOADING,
            entriesPerPage: action.params.entriesPerPage,
            pageNumber: action.params.pageNumber,
          }),
        },
      });
    case ActionTypes.FETCH_ORGANIZATION_ACCOUNTS_SUCCESS:
      return update(state, {
        [action.params.pageNumber]: {
          $merge: {
            ids: mapByKey(action.payload.results),
            status: ReadyState.FULFILLED,
            isFirstPage: action.payload.isFirstPage,
            isLastPage: action.payload.isLastPage,
          },
        },
      });
    case ActionTypes.FETCH_ORGANIZATION_ACCOUNTS_FAILURE:
      return update(state, {
        [action.params.pageNumber]: {
          $merge: {
            error: action.payload,
            status: ReadyState.REJECTED,
          },
        },
      });
    default:
      return state;
  }
}

function currentPage(state = 1, action: RootActionTypes): number {
  switch (action.type) {
    case ActionTypes.FETCH_ORGANIZATION_ACCOUNTS_REQUEST:
      return state;
    case ActionTypes.FETCH_ORGANIZATION_ACCOUNTS_SUCCESS:
      return action.params.pageNumber;
    case ActionTypes.FETCH_ORGANIZATION_ACCOUNTS_FAILURE:
      return action.params.pageNumber;
    default:
      return state;
  }
}

export default combineReducers({
  currentPage,
  pages,
});
