import React, { FunctionComponent } from 'react';
import { Link } from 'react-router';
import { PageNavigationItemProps } from '../../../types/props';

if (process.browser) {
  require('./styles.css'); // eslint-disable-line global-require
}

const displayName = 'PageNavigationItem';

const defaultProps = {
  onlyActiveOnIndex: true,
};

const PageNavigationItem: FunctionComponent<PageNavigationItemProps> = ({
  href,
  label,
  onlyActiveOnIndex,
}) => (
  <Link
    className="page-navigation-item"
    activeClassName="active"
    to={href}
    onlyActiveOnIndex={onlyActiveOnIndex}
  >
    {label}
  </Link>
);

PageNavigationItem.displayName = displayName;
PageNavigationItem.defaultProps = defaultProps;

export default PageNavigationItem;
