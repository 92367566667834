const steps = [
  {
    title: 'Select a business account',
    subtitle: 'Select the Facebook business account that includes the catalogs you would like to localize',
  },
  {
    title: 'Select a catalog',
    subtitle: 'Select the Facebook catalog you would like to ad localized product information to.',
  },
] as const;

export default steps;
