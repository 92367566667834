import { MarketingActions } from '../types';
import ActionTypes from '../constants/ActionTypes';
import standardAction from '../../../utilities/standard-action';

function changeSelectedFeedSource(
  source: io.flow.internal.v0.unions.MarketingGatewayFeedSource,
  platformId: io.flow.internal.v0.enums.MarketingGatewayPlatform,
): MarketingActions {
  return standardAction(ActionTypes.CHANGE_SELECTED_FEED_SOURCE, {
    source,
    platformId,
  });
}

export default changeSelectedFeedSource;
