/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import FormattedRelative from '../../../../components/formatted-relative';
import { AutoSaveState } from '../../types';
import { autoSaveIndicatorStyles } from './styles';

interface AutoSaveIndicatorProps {
  state: AutoSaveState | undefined;
  lastModified: number;
}

const AutoSaveIndicator: React.FC<AutoSaveIndicatorProps> = ({
  state,
  lastModified,
}) => (
  <span css={autoSaveIndicatorStyles}>
    { (state === 'saving' || state === 'dirty') && <span>Saving...</span> }
    { (state === 'saved' || state === 'error') && (
      <>
        Changes saved&nbsp;<FormattedRelative value={lastModified - 10000} />
      </>
    )}
  </span>
);

export default AutoSaveIndicator;
