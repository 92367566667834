import {
  Field,
  FieldArray,
  Form,
  InjectedFormProps,
} from 'redux-form';
import {
  Card, CardHeader, CardContent, CardFooter, CardTitle,
} from '@flowio/react-card';
import React from 'react';
import find from 'lodash/find';
import map from 'lodash/map';
import { css } from '@emotion/css';
import QueryFilterList from './QueryFilterList';

import GenericError from '../../../../components/GenericError';
import { ExclusionRuleFormProps, QueryFilterListOwnProps, ExclusionRuleFormValues } from '../../types';
import { MultiSelectField } from '../../../../components/ReduxFormFieldAdapters';

const genericErrorStyle = css`
  margin-bottom: 16px;
`;

const formatRegions = (regions: io.flow.v0.models.Region[]) => (
  values: string[],
) => map(values, (value) => find(regions, { id: value }));

const parseRegions = (values: io.flow.v0.models.Region[]) => map(values, 'id');

const ExclusionRuleForm: React.FC<
ExclusionRuleFormProps & InjectedFormProps<ExclusionRuleFormValues, ExclusionRuleFormProps>
> = ({
  availableFilters,
  availableRegions,
  error,
  submissionApiError,
  handleSubmit,
}) => (
  <Form onSubmit={handleSubmit}>
    <Field component="input" type="hidden" name="exclusionRuleId" />
    <Field component="input" type="hidden" name="organizationId" />
    {error && (
      <GenericError className={genericErrorStyle} error={error} />
    )}
    {submissionApiError && (
      <GenericError className={genericErrorStyle} error={submissionApiError} />
    )}
    <Card>
      <CardHeader dividing>
        <CardTitle>
          Regions
        </CardTitle>
      </CardHeader>
      <CardContent subdued>
        <Field
          component={MultiSelectField}
          name="regions"
          format={formatRegions(availableRegions)}
          parse={parseRegions}
          fluid
          searchable
          options={availableRegions}
          labelKey="name"
          valueKey="id"
        />
      </CardContent>
      <CardFooter dividing />
    </Card>
    <FieldArray<QueryFilterListOwnProps>
      component={QueryFilterList}
      name="filters"
      availableFilters={availableFilters}
    />
  </Form>
);

ExclusionRuleForm.displayName = 'ExclusionRuleForm';

ExclusionRuleForm.defaultProps = {
  error: undefined,
  submissionApiError: undefined,
};

export default ExclusionRuleForm;
