import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import assign from 'lodash/assign';
import some from 'lodash/some';

import { closeClearTextKeyDialog } from '../actions';
import { getMemberships } from '../../user';
import { getOrganization } from '../selectors';
import { getUserId, getTokenUiState, getCleartextTokenDialogId } from '../../console';
import {
  getClearTextKey, deleteApiKey, getTokens,
} from '../../tokens';
import ApiKeys from '../components/api-keys';
import { RootState, ThunkDispatcher } from '../../../stores/types';
import createNewApiKey from '../../tokens/actions/createNewApiKey';

export function shouldAllowEnvironmentSelection(
  userId: string,
  userMemberships: io.flow.v0.models.Membership[],
  organization: io.flow.v0.models.Organization,
) {
  const hasAdminMembership = some(userMemberships, (m) => m.user.id === userId && m.role === 'admin');
  return organization.environment === 'production' && hasAdminMembership;
}

function mapStateToProps(state: RootState) {
  const organization = getOrganization(state);
  const userId = getUserId(state);
  const memberships = getMemberships(state);
  return assign({}, getTokenUiState(state), {
    tokens: getTokens(state),
    organization,
    cleartextTokenDialogId: getCleartextTokenDialogId(state),
    allowEnvironmentSelection: shouldAllowEnvironmentSelection(userId, memberships, organization),
  });
}

function mapDispatchToProps(dispatch: ThunkDispatcher) {
  return bindActionCreators({
    onDeleteApiKey: deleteApiKey,
    onViewClearText: getClearTextKey,
    onCreateKey: createNewApiKey,
    onCloseClearTextKeyDialog: closeClearTextKeyDialog,
  }, dispatch);
}

export default compose<React.FC>(
  connect(mapStateToProps, mapDispatchToProps),
)(ApiKeys);
