import moment from 'moment';

const getDaysUntilExpiry = (
  summary: io.flow.internal.v0.models.MarketingGatewayFacebookConnectionAccessTokenSummary,
): string => {
  const daysUntilExpire = moment(summary.expires_at).diff(moment(), 'days');
  if (daysUntilExpire === 0) {
    return 'Expires today';
  }
  return `${daysUntilExpire} days time`;
};

const getFBAccessText = (
  summary: io.flow.internal.v0.models.MarketingGatewayFacebookConnectionAccessTokenSummary,
) => {
  const { expires_at: expiresAt, token_status: tokenStatus } = summary;
  if (tokenStatus === 'active') {
    return `Expires ${moment(expiresAt).format('MMM D YYYY')} (${getDaysUntilExpiry(summary)})`;
  }
  if (tokenStatus === 'unknown') {
    return 'Failed to Connect';
  }
  if (tokenStatus === 'expired') {
    return 'Expired';
  }

  return '';
};

export default getFBAccessText;
