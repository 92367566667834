import React, { FunctionComponent } from 'react';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import {
  Card, CardHeader, CardContent, CardFooter, CardTitle,
} from '@flowio/react-card';
import { PageContent } from '@flowio/react-page';
import { css } from '@emotion/css';
import ExperienceSettings from '../experience-settings';

import ProductDetails from '../product-details';
import ProductDetailsForm from '../product-details-form';
import { colors } from '../../../../theme/tokens';

const overviewStyles = css`
  margin: 0;
  color: ${colors.steel};
`;

const itemCardStyles = css`
  background-color: ${colors.neutral[100]};
`;

export interface ProductDetailsCheckProps {
  currency?: string;
  item: io.flow.v0.models.Item;
  experience: io.flow.v0.models.Experience | null;
  experiences: io.flow.v0.models.Experience[];
  landedCostSetting?: io.flow.v0.enums.DeliveredDuty;
  // Callback function that is fired when form is submitted
  onSubmit: (...args: any) => any;
  // Unique identifier for the organization
  organization: string;
  priceFormatSetting: io.flow.v0.models.ExperienceCurrencyFormat;
  priceSettings?: io.flow.v0.models.Pricing;
}

const ProductDetailsCheck: FunctionComponent<ProductDetailsCheckProps> = ({
  currency,
  item,
  experience,
  experiences = [],
  landedCostSetting,
  onSubmit = noop,
  organization,
  priceFormatSetting,
  priceSettings,
}) => (
  <PageContent>
    <Card>
      <CardHeader dividing>
        <CardTitle content="Item Check" />
      </CardHeader>
      <CardContent>
        <p className={overviewStyles}>
          For any experience and item, see what your product detail page should
          look like to your customer.
        </p>
        <ProductDetailsForm
          experiences={experiences}
          experience={experience}
          item={item}
          onValidSubmit={onSubmit}
        />
      </CardContent>
      <CardFooter />
    </Card>
    {experience
          && landedCostSetting
          && currency
          && !isEmpty(organization)
          && priceSettings
          && !isEmpty(priceFormatSetting)
          && (
            <Card>
              <CardHeader dividing>
                <CardTitle content={`Current Experience Settings (${experience.name})`} />
              </CardHeader>
              <CardContent>
                <ExperienceSettings
                  pricing={priceSettings}
                  priceFormat={priceFormatSetting}
                  landedCostSetting={landedCostSetting}
                  currency={currency}
                />
              </CardContent>
            </Card>
          )}
    {!isEmpty(item) && (
      <Card>
        <CardHeader dividing>
          <CardTitle content="Item Product Page Sample" />
        </CardHeader>
        <CardContent className={itemCardStyles}>
          <ProductDetails item={item} />
        </CardContent>
        <CardFooter />
      </Card>
    )}
  </PageContent>
);
ProductDetailsCheck.displayName = 'ProductDetailsCheck';

export default ProductDetailsCheck;
