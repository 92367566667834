import React from 'react';
import get from 'lodash/get';
import find from 'lodash/find';

import CountryFlag from '../../../reference/components/CountryFlag';
import * as styles from './CenterListItem.styles';
import { CenterListItemProps } from '../../types/components';

const CenterListItem: React.FC<CenterListItemProps> = ({ center, countries }) => {
  const countryIsoCode = get(center, 'address.location.country');
  const country = find(countries, { iso_3166_3: countryIsoCode });
  const name = get(center, 'name');
  const streets = get(center, 'address.location.streets');
  const province = get(center, 'address.location.province');
  const city = get(center, 'address.location.city');
  const postal = get(center, 'address.location.postal');
  return (
    <div className={styles.item}>
      {(country != null) && (
        <CountryFlag className={styles.flag} country={country} />
      )}
      <div className={styles.address}>
        <div className={styles.truncated}>
          {(name != null) && (
            <span>
              {name}
            </span>
          )}
          {' ('}
          {(streets != null) && (
            <span>
              {streets.join(', ')}
              {', '}
            </span>
          )}
          {(province != null) && (
            <span>
              {province}
              {', '}
            </span>
          )}
          {(city != null) && (
            <span>
              {city}
              {', '}
            </span>
          )}
          {(postal != null) && (
            <span>
              {postal}
            </span>
          )}
          )
        </div>
      </div>
    </div>
  );
};

CenterListItem.displayName = 'CenterListItem';

export default CenterListItem;
