import {
  Plus, Ellipsis, AlertTwoTone, InfoCircleTwoTone,
} from '@flowio/react-icons';
import { css } from '@emotion/react';
import React from 'react';
import get from 'lodash/get';
import noop from 'lodash/noop';
import { Box, BoxItem } from '@flowio/react-box';
import { Button, OutlineButton, IconButton } from '@flowio/react-button';
import {
  Card, CardHeader, CardContent, CardFooter, CardEmptyState, CardTitle,
} from '@flowio/react-card';
import {
  Dialog, DialogFooter, DialogActions, DialogBody,
} from '@flowio/react-dialog';
import {
  Dropdown, DropdownMenu, DropdownItem, DropdownDivider,
} from '@flowio/react-dropdown';

import {
  Table,
  TableHead,
  TableRow,
  TableRowColumn,
  TableHeadColumn,
  TableBody,
} from '@flowio/react-table';
import { regions } from '@flowio/lib-reference-javascript';
import ExperienceStatus from '../../../constants/experience-status';

import Pagination from '../../../../../components/pagination';
import RegionLabel from '../../../../reference/components/RegionLabel';
import ExperienceListFilterForm from '../../ExperienceListFilterForm/containers/ExperienceListFilterForm';
import ExperienceCloneDialog from '../../ExperienceCloneDialog/containers/ExperienceCloneDialog';
import ExperienceSettingsExport from '../../ExperienceSettingsExport';
import { MergedProps as Props } from '../types';
import * as styles from './ExperienceListing.styles';

const tableStyling = {
  rowCol: css`
    td {
      padding: 0 !important;
    }
  `,
  firstCol: css`
    td {
      padding: 0 0 0 16px !important;
    }
  `,
  lastCol: css`
    td {
      padding: 0 16px 0 0 !important;
    }
  `,
};

const cardStyles = {
  content: css({
    paddingLeft: '0px',
    paddingRight: '0px',
  }),
  footer: css({
    borderTop: 'none',
  }),
};

const ExperienceListing: React.FC<Props> = ({
  duplicationEnabled,
  experiences,
  experienceToActivate,
  experienceToArchive,
  onActivateCancellation,
  onActivateExperience,
  onArchiveCancellation,
  onArchiveExperience,
  onCloseExportDialog,
  onCreateExperience,
  onExperienceClick,
  onRequestCloneExperience,
  onRequestExperienceListFilterChange,
  onSetExperienceStatus,
  organization,
  inline,
  currentPage,
  onRequestNextPage,
  onRequestPreviousPage,
  onOpenExportExperienceSettingsModal,
  onSubmitExportDialog,
  isExportExperienceSettingsModalOpen,
  // onMenuClick,
  pageInfo: {
    isFirstPage,
    isLastPage,
  },
}) => {
  const renderMenuContent = (experience: io.flow.v0.models.Experience): JSX.Element[] => {
    const menuItems = [];

    if (experience.status === ExperienceStatus.ACTIVE
      || experience.status === ExperienceStatus.DRAFT) {
      menuItems.push(
        <>
          <DropdownItem
            style={{ backgroundColor: 'white' }}
            content="Archive"
            onClick={(): void => onArchiveExperience(experience)}
          />
          <DropdownDivider />
        </>,
      );
    }
    if (experience.status === ExperienceStatus.DRAFT) {
      menuItems.push(
        <>
          <DropdownItem
            style={{ backgroundColor: 'white' }}
            content="Activate"
            onClick={(): void => onActivateExperience(experience)}
          />
          <DropdownDivider />
        </>,
      );
    }
    if (experience.status === ExperienceStatus.ARCHIVING
      || experience.status === ExperienceStatus.ARCHIVED) {
      menuItems.push(
        <>
          <DropdownItem
            style={{ backgroundColor: 'white' }}
            content="Unarchive"
            onClick={(): void => onActivateExperience(experience)}
          />
          <DropdownDivider />
        </>,
      );
    }

    if (duplicationEnabled) {
      menuItems.push(<DropdownItem
        style={{ backgroundColor: 'white' }}
        content="Copy"
        onClick={(): Promise<unknown[]> => onRequestCloneExperience(organization, experience)}
      />);
    }

    return (menuItems);
  };

  return (
    <section className={styles.listing}>
      <Card>
        <CardHeader dividing>
          <Box justifyContent="between" alignContent="center">
            <CardTitle content="Your Organization's Experiences" />
            <Box justifyContent="end" alignContent="end">
              <OutlineButton
                intent="primary"
                content="Add Experience"
                leftIcon={Plus}
                onClick={(): void => onCreateExperience()}
              />
              <Dropdown
                inline={inline}
                onClick={(e) => e.stopPropagation()}
                trigger={<IconButton className={styles.addtionalOptionsTrigger} size="medium" icon={Ellipsis} />}
              >
                <DropdownMenu>
                  <DropdownItem content="Export Experience Settings" onClick={onOpenExportExperienceSettingsModal} />
                </DropdownMenu>
              </Dropdown>
            </Box>
          </Box>
          <Box className={styles.filtersContainer}>
            <BoxItem className={styles.filtersBox}>
              <ExperienceListFilterForm
                onRequestExperienceListFilterChange={onRequestExperienceListFilterChange}
              />
            </BoxItem>
          </Box>
        </CardHeader>
        <CardContent css={cardStyles.content}>
          {experiences.length > 0 && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeadColumn className={styles.firstColumn}>
                    Region
                  </TableHeadColumn>
                  <TableHeadColumn>
                    Name
                  </TableHeadColumn>
                  <TableHeadColumn>
                    Status
                  </TableHeadColumn>
                  <TableHeadColumn />
                </TableRow>
              </TableHead>
              <TableBody>
                {experiences.map((experience, index) => {
                  const region = regions.findById(experience.region.id);
                  // eslint-disable-next-line import/namespace
                  const experienceStatusStyle = experience.status ? styles[experience.status] : '';
                  const experienceStatusDotStyle: 'activeDot' | 'draftDot' | 'archivingDot' | 'archivedDot' | '' = experience.status ? `${experience.status}Dot` : 'draftDot';
                  return (
                    <TableRow
                      striped={index % 2 === 1}
                      selectable
                      key={experience.id}
                      onClick={(): void => onExperienceClick(organization, experience)}
                    >
                      <TableRowColumn css={tableStyling.firstCol}>
                        {(region != null) ? (
                          <RegionLabel region={region} />
                        ) : experience.region.id}
                      </TableRowColumn>
                      <TableRowColumn css={tableStyling.rowCol}>
                        {experience.name}
                      </TableRowColumn>
                      <TableRowColumn className={experienceStatusStyle} css={tableStyling.rowCol}>
                        {/* eslint-disable-next-line import/namespace */}
                        <div className={styles[experienceStatusDotStyle]} />
                        {(experience.status || '').toUpperCase()}
                      </TableRowColumn>
                      <TableRowColumn className={styles.actionCol} css={tableStyling.lastCol}>
                        <Dropdown
                          onClick={(e) => e.stopPropagation()}
                          inline={inline}
                          trigger={<IconButton css={{ marginTop: '8px' }} className="itemDropdownMenuTrigger" size="small" icon={Ellipsis} />}
                        >
                          <DropdownMenu>
                            {renderMenuContent(experience)}
                          </DropdownMenu>
                        </Dropdown>
                      </TableRowColumn>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
          {experiences.length < 1 && (
            <CardEmptyState content="No Experiences Available" />
          )}
        </CardContent>
        <CardFooter css={cardStyles.footer}>
          <Box alignItems="center" justifyContent="between">
            <Pagination
              pageNumber={currentPage}
              isPreviousPageDisabled={isFirstPage}
              isNextPageDisabled={isLastPage}
              onRequestPreviousPage={onRequestPreviousPage}
              onRequestNextPage={onRequestNextPage}
            />
          </Box>
        </CardFooter>
      </Card>
      {experienceToArchive && (
        <Dialog
          backdrop
          closeOnEscape
          open={!!experienceToArchive}
          onClose={onArchiveCancellation}
          style={{ margin: '0 auto', width: '480px' }}
        >
          <DialogBody>
            <Box alignContent="start" className={styles.dialogTextContainer}>
              <div className={styles.dialogIcon}>
                <AlertTwoTone />
              </div>
              <span className={styles.dialogText}>
                {`Are you sure you want to archive the Experience "${get(experienceToArchive, 'name')}"?`}
              </span>
            </Box>
          </DialogBody>
          <DialogFooter>
            <DialogActions>
              <Button className={styles.dialogAction} content="Cancel" onClick={onArchiveCancellation} />
              {get(experienceToArchive, 'status') === ExperienceStatus.DRAFT && (
                <Button className={styles.dialogAction} content="Archive" intent="negative" onClick={(): void => onSetExperienceStatus(organization, experienceToArchive, ExperienceStatus.ARCHIVED)} />
              )}
              {get(experienceToArchive, 'status') === ExperienceStatus.ACTIVE && (
                <Button className={styles.dialogAction} content="Archive" intent="negative" onClick={(): void => onSetExperienceStatus(organization, experienceToArchive, ExperienceStatus.ARCHIVING)} />
              )}
            </DialogActions>
          </DialogFooter>
        </Dialog>
      )}
      {experienceToActivate && (
        <Dialog
          backdrop
          closeOnEscape
          open={!!experienceToActivate}
          onClose={onActivateCancellation}
          style={{ margin: '0 auto', width: '480px' }}
        >
          <DialogBody>
            <Box alignContent="start">
              <div className={styles.dialogIcon}>
                <InfoCircleTwoTone />
              </div>
              <span className={styles.dialogText}>
                {`Are you sure you want to activate the Experience "${get(experienceToActivate, 'name')}"?`}
              </span>
            </Box>
          </DialogBody>
          <DialogFooter>
            <DialogActions>
              <Button className={styles.dialogAction} content="Cancel" onClick={onActivateCancellation} />
              <Button className={styles.dialogAction} content="Activate" intent="positive" onClick={(): void => onSetExperienceStatus(organization, experienceToActivate, ExperienceStatus.ACTIVE)} />
            </DialogActions>
          </DialogFooter>
        </Dialog>
      )}
      <ExperienceSettingsExport
        isOpen={isExportExperienceSettingsModalOpen}
        onCloseExportDialog={onCloseExportDialog}
        onSubmitExport={onSubmitExportDialog}
      />
      <ExperienceCloneDialog />
    </section>
  );
};

ExperienceListing.displayName = 'ExperienceListing';

ExperienceListing.defaultProps = {
  currentPage: 1,
  duplicationEnabled: false,
  experienceToActivate: undefined,
  experienceToArchive: undefined,
  inline: false,
  onActivateExperience: noop,
  onActivateCancellation: noop,
  onArchiveCancellation: noop,
  onArchiveExperience: noop,
  onCloseExportDialog: noop,
  onCreateExperience: noop,
  onExperienceClick: noop,
  onRequestCloneExperience: (): Promise<unknown[]> => Promise.resolve([]),
  onRequestExperienceListFilterChange: noop,
  onOpenExportExperienceSettingsModal: noop,
  onRequestNextPage: noop,
  onRequestPreviousPage: noop,
  onSetExperienceStatus: noop,
  onSubmitExportDialog: noop,
  pageInfo: {
    isFirstPage: true,
    isLastPage: true,
  },
};

export default ExperienceListing;
