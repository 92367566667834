import { $HttpResponse } from '@flowio/api-sdk';
import ActionTypes from '../constants/action-types';
import standardAction from '../../../utilities/standard-action';
import withPagingAndSortingV2, { isPagingAndSortingResponse, WithPagingAndSortingOptions } from '../../../utilities/with-paging-and-sorting-v2';
import {
  ThunkDispatcher, ThunkExtraArguments, RootState, RootActionTypes, ThunkResult,
} from '../../../stores/types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';
import { WithPagingAndSortingResponse, WithPagingAndSortingResult } from '../../../utilities/types';

type FetchAllExperiencesOptions = WithPagingAndSortingOptions;

export default function fetchAllExperiences(
  organization: string,
  options: FetchAllExperiencesOptions = {},
): ThunkResult<Promise<RootActionTypes | void>> {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<RootActionTypes | void> => {
    let results: io.flow.v0.models.Experience[] = [];

    function fetchNextExperiences(pageNumber: number): Promise<RootActionTypes | void> {
      return withPagingAndSortingV2<io.flow.v0.models.Experience[]>({
        entriesPerPage: 100,
        pageNumber,
        ...options,
      },
      (params) => extra.api(getState()).experiences.get({
        organization,
        ...params,
      }))().then((
        response: WithPagingAndSortingResponse<io.flow.v0.models.Experience[]> | $HttpResponse,
      ) => {
        if (isPagingAndSortingResponse<io.flow.v0.models.Experience[]>(response) && response.ok) {
          results = results.concat(response.result.results);
          if (!response.result.isLastPage) {
            return fetchNextExperiences(pageNumber + 1);
          }

          return dispatch(
            standardAction(ActionTypes.FETCH_ALL_EXPERIENCES_SUCCESS, {
              ...response.result, results,
            } as WithPagingAndSortingResult<io.flow.v0.models.Experience[]>),
          );
        }

        if ((response as $HttpResponse).body) {
          dispatch(
            standardAction(
              ActionTypes.FETCH_ALL_EXPERIENCES_FAILURE,
              formatErrorResponseV2(response as $HttpResponse),
            ),
          );
        }

        throw new Error('Error fetching page');
      });
    }

    dispatch(standardAction(ActionTypes.FETCH_ALL_EXPERIENCES_REQUEST));
    return fetchNextExperiences(1);
  };
}
