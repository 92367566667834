import { MetricDropdownValue } from '../components/experiment-details/types';

type MetricSummaryValue = {
  content: string,
  value: MetricDropdownValue,
  showInDropdown: true,
  tooltip: string,
} | {
  content: string,
  value: 'probability_of_being_best' | 'conversion_range',
  showInDropdown: false,
  tooltip: string,
};

type MetricSummaryValueMap = {
  [key: string]: MetricSummaryValue;
};

const metricSummaryValues: MetricSummaryValueMap = {
  possibilityOfBeingBest: {
    content: 'Probability to be best',
    value: 'probability_of_being_best',
    showInDropdown: false,
    tooltip: "This is the likelihood of the variant being the winner. It's based on the current difference in the conversion rates between the variants.",
  },
  numbeOfUniqueConversions: {
    content: 'Unique Conversions',
    value: 'visitors_with_transactions_count',
    showInDropdown: true,
    tooltip: 'Number of customers that completed at least one order. Individual customers that complete multiple orders are only counted once.',
  },
  numberOfVisitors: {
    content: 'Unique Visitors',
    value: 'visitor_count',
    showInDropdown: true,
    tooltip: 'Total number of individuals assigned to each variant.',
  },
  conversionRate: {
    content: 'Conversion Rate',
    value: 'conversion_rate',
    showInDropdown: true,
    tooltip: 'Number of unique conversions divided by unique visits.',
  },
  conversionRateRange: {
    content: 'Conversion Rate Range',
    value: 'conversion_range',
    showInDropdown: false,
    tooltip: 'The lower and upper bound of the conversion rate for this variant, based on current results.',
  },
  revenue: {
    content: 'Total Revenue',
    showInDropdown: true,
    tooltip: 'Accumulative value of all completed orders for each variant.',
    value: 'revenue_generated',
  },

  totalOrders: {
    content: 'Total Orders',
    value: 'total_order_count',
    showInDropdown: true,
    tooltip: 'Total number of completed orders for each variant. If an individual customer completes multiple orders, every order they complete is counted.',
  },

  averageOrderValue: {
    content: 'Average Order Value',
    value: 'average_order_value',
    showInDropdown: true,
    tooltip: 'The average amount spent each time a visitor places an order on your site.',
  },

} as const;

export default metricSummaryValues;
