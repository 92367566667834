import { combineReducers } from 'redux';

import activeOptinAttribute from './activeOptinAttribute';
import attributes from './attributes';
import dialogs from './dialogs';
import prompts from './prompts';

export default combineReducers({
  activeOptinAttribute,
  dialogs,
  entities: combineReducers({
    attributes,
    prompts,
  }),
});
