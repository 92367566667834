import ActionTypes from '../constants/action-types';
import { ApiError } from '../../../utilities/clients/api-error';
import standardAction from '../../../utilities/standard-action';
import {
  RootState, ThunkExtraArguments, ThunkResult, ThunkDispatcher,
} from '../../../stores/types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';

interface ResultsRequestParams {
  start?: string;
  end?: string;
  timeseries?: io.flow.internal.v0.enums.TimeseriesType;
}

export default function fetchExperimentResults(
  organization: string,
  key: string,
  params: ResultsRequestParams = {},
): ThunkResult<Promise<void>> {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<void> => {
    dispatch({
      type: ActionTypes.FETCH_EXPERIMENT_RESULTS_REQUEST,
    });
    return extra.apiInternal(getState()).experimentResultsWithTimestamps.getCumulative({
      organization,
      key,
      ...params,
    }).then((response) => {
      if (response.ok) {
        dispatch(
          standardAction(
            ActionTypes.FETCH_EXPERIMENT_RESULTS_SUCCESS,
            response.body,
          ),
        );
        return;
      }

      const error = new ApiError(response);

      dispatch({
        type: ActionTypes.FETCH_EXPERIMENT_RESULTS_FAILURE,
        payload: formatErrorResponseV2(response),
      });

      throw error;
    });
  };
}
