import { css } from '@emotion/css';
import React, { FunctionComponent } from 'react';
import { colors } from '../../../../theme/tokens';

const shortDescriptionStyles = css`
  margin: 0;
  color: ${colors.charcoal};
  font-size: 1.375rem;
  font-weight: 300;
`;

const productCodeStyles = css`
  margin: 0.75rem 0 0;
  color: ${colors.charcoal};
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1rem;
`;

const displayName = 'ProductOverview';

interface ProductOverviewProps {
  productCode?: string;
  shortDescription?: string;
}

const ProductOverview: FunctionComponent<ProductOverviewProps> = ({
  productCode,
  shortDescription,
}) => (
  <header>
    <h1 className={shortDescriptionStyles}>
      {shortDescription}
    </h1>
    <h4 className={productCodeStyles}>
      {productCode}
    </h4>
  </header>
);

ProductOverview.displayName = displayName;

export default ProductOverview;
