import assign from 'lodash/assign';
import * as ActionTypes from '../../../user/constants';
import { UserState } from '../../types/state';
import { RootActionTypes } from '../../../../stores/types';

const initialState: UserState = {
  updateBasicInformationSuccess: false,
};

export default function userUiReducer(state = initialState, action: RootActionTypes) {
  switch (action.type) {
    case ActionTypes.UPDATE_USER_SUCCESS:
      return assign({}, state, { updateBasicInformationSuccess: true });
    default:
      return state;
  }
}
