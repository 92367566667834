import { compose } from 'redux';
import { connect, MapDispatchToProps } from 'react-redux';
import { logoutUser } from '../../../actions';
import Logout from '../components/logout';
import { ThunkDispatcher } from '../../../../../stores/types';
import { DispatchProps } from '../types';
import { FormattedErrorMessages } from '../../../../../utilities/format-error-response-v2';
import { LegacyResponse } from '../../../../../utilities/clients/types/server';

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = (dispatch: ThunkDispatcher) => ({
  onRequestLogout:
    (): Promise<LegacyResponse<void | FormattedErrorMessages>> => dispatch(logoutUser()),
});

export default compose(connect(null, mapDispatchToProps))(Logout);
