import React, { FunctionComponent } from 'react';
import startCase from 'lodash/startCase';
import get from 'lodash/get';
import find from 'lodash/find';
import capitalize from 'lodash/capitalize';
import { currencies } from '@flowio/lib-reference-javascript';

import { Description, DescriptionGroup } from '../../../../components/description';
import getRoundingSentence from '../../../experience/utilities/get-rounding-sentence';

const landedCostSettingStrategies = [
  { value: 'paid', label: 'Duties Paid Only' },
  { value: 'unpaid', label: 'Duties Unpaid Only' },
];

// if (process.browser) {
//   require('./experience-settings.css'); // eslint-disable-line global-require
// }

function formatPriceDisplay(pricing: io.flow.v0.models.Pricing): string {
  const sentences = [];

  if (pricing.vat) {
    sentences.push(`VAT ${startCase(pricing.vat)}`);
  }

  if (pricing.duty) {
    sentences.push(`Duty ${startCase(pricing.duty)}`);
  }

  return sentences.join(', ');
}

interface ExperienceSettingsProps {
  pricing: io.flow.v0.models.Pricing;
  priceFormat: io.flow.v0.models.ExperienceCurrencyFormat;
  landedCostSetting: string;
  currency: string;
}

const ExperienceSettings: FunctionComponent<ExperienceSettingsProps> = ({
  pricing, priceFormat, landedCostSetting, currency,
}) => {
  const currencySymbols = get(currencies.find(currency), 'symbols');
  const selectedSymbol = get(currencySymbols, priceFormat.symbol);
  const currencySymbol = !selectedSymbol ? currencySymbols[0] : selectedSymbol;

  return (
    // Need className here for typescript to be happy. Will go away once we convert DescriptionGroup
    <DescriptionGroup spacing={false} className={undefined}>
      <Description
        term="Price Display"
        definition={formatPriceDisplay(pricing)}
      />
      <Description
        term="Landed Cost"
        definition={get(find(landedCostSettingStrategies, { value: landedCostSetting }), 'label')}
      />
      <Description
        term="Remove Trailing Zeroes"
        definition={priceFormat.label_formatters.includes('strip_trailing_zeros') ? 'Yes' : 'No'}
      />
      <Description
        term="Currency Symbol"
        definition={`${capitalize(priceFormat.symbol)} (${currencySymbol})`}
      />
      <Description
        term="Price Rounding"
        definition={getRoundingSentence(pricing.rounding, currency)}
      />
    </DescriptionGroup>
  );
};

ExperienceSettings.displayName = 'ExperienceSettings';

export default ExperienceSettings;
