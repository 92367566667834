import ActionTypes from '../constants/action-types';
import { RootActionTypes } from '../../../stores/types';

export default function setActiveExperience(
  experience: io.flow.v0.models.Experience,
): RootActionTypes {
  return {
    type: ActionTypes.SET_ACTIVE_EXPERIENCE,
    payload: experience,
  };
}
