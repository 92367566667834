import { Plus } from '@flowio/react-icons';
/* eslint-disable react/forbid-prop-types */

import React from 'react';
import uniqueId from 'lodash/uniqueId';
import FlipMove from 'react-flip-move';
import { Box } from '@flowio/react-box';

import { Button } from '@flowio/react-button';
import {
  Card, CardHeader, CardContent, CardFooter, CardTitle,
} from '@flowio/react-card';
import RuleCondition from './RuleCondition';

import { RuleConditionsProps } from '../../types/components';

class RuleConditions extends React.PureComponent<RuleConditionsProps> {
  static displayName = 'RuleConditions';

  constructor(props: RuleConditionsProps, context: any) {
    super(props, context);
    this.handleAddCondition = this.handleAddCondition.bind(this);
  }

  handleAddCondition() {
    const { availableFilters, fields } = this.props;

    if (availableFilters.length && availableFilters[0].discriminator === 'structured') {
      fields.unshift({
        field: availableFilters[0].field,
        operator: availableFilters[0].operators[0],
        values: [],
        uniqueId: uniqueId('filter'),
      });
    }
  }

  render() {
    const {
      availableFilters,
      fields,
      meta: {
        error,
        submitFailed,
      },
    } = this.props;
    // Filter does not infer the type even though the discriminator is enough
    // to determind if the filter is structured or not
    const structuredAvailableFilters: io.flow.v0.models.AvailableFilterStructured[] = availableFilters.filter((availableFilter) => availableFilter.discriminator === 'structured') as io.flow.v0.models.AvailableFilterStructured[];

    return (
      <Card>
        <CardHeader dividing>
          <Box alignItems="center" justifyContent="between">
            <CardTitle>
              Conditions
            </CardTitle>
            <Button
              content="Add Condition"
              intent="primary"
              leftIcon={Plus}
              onClick={this.handleAddCondition}
              variant="outline"
              type="button"
            />
          </Box>
        </CardHeader>
        <CardContent>
          <p>
            All following conditions must be met for the specified value to be returned
          </p>
          <FlipMove duration={300} easing="ease" staggerDelayBy={20} staggerDurationBy={15}>
            {fields.map((name: string, index: number) => (
              <RuleCondition
                availableFilters={structuredAvailableFilters}
                key={fields.get(index).uniqueId}
                name={name}
                onRequestRemove={() => fields.remove(index)}
              />
            ))}
          </FlipMove>
          {submitFailed && (
            <span>
              {error}
            </span>
          )}
        </CardContent>
        <CardFooter />
      </Card>
    );
  }
}

export default RuleConditions;
