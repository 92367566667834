import Client from './client';

export default class Orders extends Client {
  constructor(opts) {
    let options = opts;

    if (typeof opts === 'string') {
      options = { host: opts }; // convert host string to options object
    }

    options.serviceName = 'API Internal';

    super(options);
  }

  getInternalByNumber(organization, number, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/orders/internal/${number}`, options);
  }

  postGiftAndCardAndRedemptionsByNumber(organization, number, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/orders/${number}/gift/card/redemptions`, {
      ...options,
       method: 'POST',
    });
  }

  postGiftAndCardsByNumber(organization, number, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/orders/${number}/gift/cards`, {
      ...options,
       method: 'POST',
    });
  }

  deleteGiftAndCardsByNumberAndId(organization, number, id, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/orders/${number}/gift/cards/${id}`, {
      ...options,
       method: 'DELETE',
    });
  }

  postGiftCardsAndAuthorizeByNumber(organization, number, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/orders/${number}/gift_cards/authorize`, {
      ...options,
       method: 'POST',
    });
  }

  putModificationsAndEmailByNumber(organization, number, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/orders/${number}/modifications/email`, {
      ...options,
       method: 'PUT',
    });
  }

  putSubmissionsAndCheckByNumber(organization, number, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/orders/${number}/submissions/check`, {
      ...options,
       method: 'PUT',
    });
  }

}
