import { css } from '@emotion/css';
import colors from '../../../../../theme/tokens/colors';

export const productDetails = css`
  padding-top: 1rem;
`;

export const info = css`
  display: flex;
  flex-flow: row wrap;
`;

export const infoLabel = css`
  width: 112px;
  padding: 16px 0;
  text-align: left;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  color: #77818f;
`;

export const infoValue = css`
  width: calc(100% - 112px);
  margin: 0;
  padding: 16px 0;
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
  color: #4d5866;
`;

export const gallery = css`
  display: flex;
  flex-flow: row wrap;
`;

export const figure = css`
  margin: 0 16px 0 0;
`;

export const caption = css`
  font-size: 12px;
  font-weight: normal;
  line-height: 14px;
  text-align: left;
  color: #9ba6b3;
`;

export const image = css`
  width: auto;
  height: 128px;
  border-radius: 4px;
  background-color: #d8d8d8;
`;

export const dimension = css`
  display: flex;
`;

export const dimensionSubtitle = css`
  width: 100px;
  margin: 0;
  padding-right: 1rem;
  box-sizing: border-box;
  font-size: 1rem;
  font-weight: 400;
  line-height: 24px;
`;

export const attributes = css`
  display: flex;
  flex-flow: row wrap;
  margin: 0;
`;

export const attribute = css`
  display: flex;
  flex-flow: row nowrap;
  height: 24px;
  margin: 0 0.5rem 0.75rem 0;
  padding: 0 0.5rem;
  border-radius: 2px;
  box-sizing: border-box;
  background-color: ${colors.concrete};
  font-size: 1rem;
  line-height: 24px;
  overflow: hidden;
`;

export const attributeLabel = css`
  margin: 0;
  padding: 0;

  &:after {
    content: ':';
  }
`;

export const attributeValue = css`
  margin-left: 0.25rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const hs10Table = css`
  margin-top: -16px;
  border-collapse: collapse;

  & td,
  & th {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  & tbody tr:nth-child(even) td {
    background: ${colors.gray[100]};
  }
`;

export const hs10OriginColumn = css`
  width: 176px;
`;

export const hs10DestinationColumn = css`
  width: 208px;
`;

export const hs10CodeColumn = css`
  width: auto;
`;

export const hs10Button = css`
  margin-top: 1rem;
`;
