import { connect } from 'react-redux';
import { RootState, ThunkDispatcher } from '../../../../../stores/types';
import { reauthenticate } from '../../../actions';
import showAuthenticationToast from '../../../actions/showAuthenticationToast';
import toggleReauthenticationModal from '../../../actions/toggleReauthenticationModal';
import {
  getFacebookTokenSummary,
  getIsAuthenticating,
  getIsTokenOwner,
  getShowReauthenticationModal,
} from '../../../selectors';
import ReauthenticationModal from '../components/ReauthenticationModal';
import { DispatchProps, StateProps, OwnProps } from '../types';

const mapStateToProps = (state: RootState): StateProps => ({
  isOpen: getShowReauthenticationModal(state),
  accessTokenSummary: getFacebookTokenSummary(state),
  isTokenOwner: getIsTokenOwner(state),
  isAuthenticating: getIsAuthenticating(state),
});

const mapDispatchToProps = (
  dispatch: ThunkDispatcher,
  ownProps: OwnProps,
): DispatchProps => ({
  hideModal: () => dispatch(toggleReauthenticationModal(false)),
  handleReauthenticationRequest: (catalogId?: number) => dispatch(
    reauthenticate(ownProps.organizationId, catalogId),
  )
    .then(() => dispatch(showAuthenticationToast()))
    .catch((error) => dispatch(showAuthenticationToast(error))),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReauthenticationModal);
