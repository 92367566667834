import React, { useEffect, useState } from 'react';
import { Toast, ToastGroup } from '@flowio/react-toast';
import { Card, CardHeader, CardContent } from '@flowio/react-card';
import { Box, BoxItem } from '@flowio/react-box';
import { Button } from '@flowio/react-button';
import { css } from '@emotion/react';
import BasicInformationForm from './basic-information-form';
import { spacing } from '../../../../theme/tokens';
import * as styles from './profile.styles';

type Props = {
  user: io.flow.v0.models.User;
  onBasicInformationUpdate?: (user: io.flow.v0.models.UserPutForm) => void;
  onDisconnectFacebook: () => void;
  updateBasicInformationSuccess?: boolean;
  isLoggedIntoFacebook: boolean;
};

const AccountProfile: React.FC<Props> = ({
  updateBasicInformationSuccess,
  user,
  onBasicInformationUpdate,
  onDisconnectFacebook,
  isLoggedIntoFacebook,
}) => {
  const [basicInfoFormDisabled, setBasicInfoFormDisabled] = useState(true);
  const [toastContent, setToastContent] = useState([] as string[]);

  useEffect(() => {
    if (updateBasicInformationSuccess) {
      setBasicInfoFormDisabled(true);
      setToastContent(['Successfully updated basic information!']);
    }
  }, [updateBasicInformationSuccess]);

  const handleBasicInfoModification = () => {
    setBasicInfoFormDisabled(false);
  };

  const handleBasicInformationSnackbarClose = () => {
    setToastContent([]);
  };

  return (
    <section className={styles.accountProfile}>
      <Card id="basic-information" className="basic-information">
        <CardHeader dividing>
          Basic Information
        </CardHeader>
        <CardContent>
          <BasicInformationForm
            disabled={basicInfoFormDisabled}
            user={user}
            onModification={handleBasicInfoModification}
            onSubmit={onBasicInformationUpdate}
          />
        </CardContent>
      </Card>
      {isLoggedIntoFacebook && (
        <Card>
          <CardHeader dividing>
            Connected Accounts
          </CardHeader>
          <CardContent>
            <Box alignItems="center" css={css({ marginTop: spacing.l })}>
              <BoxItem flexAuto>Facebook</BoxItem>
              <Button intent="primary" content="Disconnect Facebook" onClick={onDisconnectFacebook} />
            </Box>
          </CardContent>
        </Card>
      )}
      <ToastGroup>
        {toastContent.map((content) => (
          <Toast
            onClose={handleBasicInformationSnackbarClose}
            className="account-toast"
            key="toasty"
            eventKey="toasty"
            intent="positive"
            icon="CheckCircle"
            content={content}
          />
        ))}
      </ToastGroup>

    </section>
  );
};

AccountProfile.displayName = 'AccountProfile';

export default AccountProfile;
