import React from 'react';
import { Box } from '@flowio/react-box';
import capitalize from 'lodash/capitalize';
import { css } from '@emotion/css';
import Button from '../../MarketingButton';
import MarketingDialog from '../../MarketingDialog';
import ExternalLink from '../../ExternalLink';
import { Props, Errors } from '../types';
import colors from '../../../../../theme/tokens/colors';

const furtherInfoStyles = css`
  font-size: 12px;
  color: ${colors.marketing['light-slate-gray']};

  & a {
    text-decoration: underline;
  }
`;

const subtitle = (errorCode: Errors, platform: string): string => {
  switch (errorCode) {
    case 'facebook_cancelled':
      return 'It seems like you have not completed the authorization steps with Facebook. Would you like to try connecting to your Facebook account again?';
    case 'facebook_permissions':
      return 'Please make sure you have accepted all requested permissions to manage your Facebook business accounts and catalogs';
    default:
      return `Unfortunately, something went wrong on our end when connecting to ${capitalize(platform)}. Please try again later.`;
  }
};

const ErrorDialog: React.FC<Props> = ({
  isOpen,
  onClose,
  errorCode,
  organizationId,
}) => {
  if (!isOpen || !errorCode) {
    return null;
  }

  const platform = errorCode.split('_')[0];

  const onConfirm = (): void => window.location.assign(`/${organizationId}/auth/${platform}`);

  return (
    <MarketingDialog
      title={`Error connecting to ${capitalize(platform)}`}
      subtitle={(
        <>
          <span>{subtitle(errorCode, platform)}</span>
          <p className={furtherInfoStyles}>
            Unsure what the issue is? See our
            {' '}
            <ExternalLink href={`https://docs.flow.io/docs/${platform}-requirements`}>requirements guide</ExternalLink>
            .
          </p>
        </>
      )}
      isOpen={isOpen}
    >
      <Box spacing="loose" justifyContent="center">
        <Button
          onClick={onClose}
        >
          Close
        </Button>
        <Button
          data-automation-id={`${platform}-start-onboarding`}
          intent="positive"
          onClick={onConfirm}
        >
          Connect Again
        </Button>
      </Box>
    </MarketingDialog>
  );
};

ErrorDialog.displayName = 'ErrorDialog';

export default ErrorDialog;
