import React from 'react';
import { Stack, StackItem } from '@flowio/react-stack';
import * as styles from './ActivityItem.styles';
import FormattedRelative from '../../../../components/formatted-relative';

export interface ActivityItemContentProps {
  title: string;
  subtitle: string;
  timestamp: number;
  icon: React.FC<React.SVGProps<SVGSVGElement>>,
}

const ActivityItemContent: React.FC<ActivityItemContentProps> = ({
  title, subtitle, timestamp, icon: Icon,
}) => (
  <Stack className={styles.activityContentWrapper} orientation="horizontal" spacing="tight">
    <StackItem className={styles.activityContentIconWrapper}>
      <Icon className={styles.icon} />
    </StackItem>
    <StackItem className={styles.activityContentMsgWrapper}>
      <Stack orientation="vertical" spacing="tight">
        <StackItem>
          <Stack spacing="tight">
            <b className="message">
              {title}
            </b>
            <StackItem>
              <FormattedRelative className={styles.timestamp} value={timestamp} />
            </StackItem>
          </Stack>
        </StackItem>
        {subtitle && (
          <div className="message">
            {subtitle}
          </div>
        )}
      </Stack>
    </StackItem>
  </Stack>
);

export default ActivityItemContent;
