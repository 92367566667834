import React from 'react';
import { OwnProps } from '../types';

const TargetType = {
  browse: 'Banner',
  checkout: 'Checkout',
};

const OptinMessageTarget: React.FC<OwnProps> = ({
  optinAttribute: { target },
}) => (
  <span>
    {TargetType[target] || target}
  </span>
);

OptinMessageTarget.displayName = 'OptinMessageTarget';

export default OptinMessageTarget;
