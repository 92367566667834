import { ThunkExtraArguments, RootState } from './types';
import { createClient as createApiClient, ApiClientV2 } from '../utilities/clients/api-v2';
import { createClient as createApiInternalClient, ApiInternalClientV2 } from '../utilities/clients/api-internal-v2';
import { getFetchWithAuth } from '../utilities/clients/fetch';

export default function createThunkExtraArguments(): ThunkExtraArguments {
  return {
    api: (
      state: RootState,
    ): ApiClientV2 => createApiClient({ fetch: getFetchWithAuth(state) }),
    apiInternal: (
      state: RootState,
    ): ApiInternalClientV2 => createApiInternalClient({ fetch: getFetchWithAuth(state) }),
  };
}
