import { createSelector } from 'reselect';
import get from 'lodash/get';

import getExclusionState from './getExclusionState';
import { RootState } from '../../../stores/types';
import { ExclusionState, ExclusionRuleWorksheetState } from '../types';

const getExclusionRuleWorksheet = createSelector<
RootState, ExclusionState, ExclusionRuleWorksheetState
>(
  getExclusionState,
  (state) => get(state, 'dialogs.exclusionRuleWorksheet'),
);

export default getExclusionRuleWorksheet;
