import React from 'react';
import {
  Page, PageHeader, PageTitle, PageContent,
} from '@flowio/react-page';
import { Text } from '@flowio/react-text';
import { Check } from '@flowio/react-icons';
import { Link } from 'react-router';

import * as styles from './LabsIndex.styles';

import { ComponentProps as Props } from '../types';
import augmentLab from '../../../utilities/augmentLab';

const LabsIndex: React.FC<Props> = ({
  organizationId,
  labs,
}) => {
  const augmentedLabs = labs.map(augmentLab);
  labs.sort((lab) => {
    if (lab.terms) return 1;
    return -1;
  });

  return (
    <Page>
      <PageHeader>
        <PageTitle content="Control Labs" />
        <Text className={styles.pageDescription}>
          Get started with new features by first accepting terms.
        </Text>
      </PageHeader>
      <PageContent className={styles.pageContent}>
        <div className={styles.labTiles}>
          {augmentedLabs.map((lab, index) => (lab.displayName ? (
            <Link to={`/${organizationId}/labs/${lab.flow_lab_project_key}`} className={styles.labLink}>
              <div className={styles.labTile(index)}>
                <div className={styles.labTitleContainer}>
                  {lab.icon && React.createElement<React.SVGAttributes<SVGElement>>(lab.icon, { className: 'icon' }) }
                  <h4 className={styles.labTitle}>{lab.displayName}</h4>
                </div>
                <span>{lab.displayDesc}</span>
                {lab.terms && (
                  <div className={styles.accepted}>
                    <Check className={styles.acceptedTick} width="18" />
                  </div>
                )}
              </div>
            </Link>
          ) : null))}
        </div>
      </PageContent>
    </Page>
  );
};

export default LabsIndex;
