import React, { Component, ReactElement } from 'react';
import findIndex from 'lodash/findIndex';
import reduce from 'lodash/reduce';
import { Checkbox } from '@flowio/react-checkbox';
import { TextInputField } from '@flowio/react-text-input';
import {
  Table,
  TableHead,
  TableRow, TableRowColumn,
  TableHeadColumn,
  TableBody,
} from '@flowio/react-table';
import kebabCase from 'lodash/kebabCase';
import * as styles from './item-form.styles';

interface ItemFormProps {
  items: io.flow.v0.models.LocalizedLineItem[];
  onItemChange: (...args: any) => void;
}

interface ItemFormState {
  selectedItems: {
    number: string;
    quantity: number;
    maxQuantity: number;
  }[];
}

class ItemForm extends Component<ItemFormProps, ItemFormState> {
  static displayName = 'ItemForm';

  constructor(props: ItemFormProps) {
    super(props);
    const { items } = this.props;
    this.state = {
      selectedItems: items.map((item) => ({
        number: item.number,
        quantity: 0,
        maxQuantity: item.quantity,
      })),
    };
  }

  onItemQuantityChange = (value: string, itemNumber: string): void => {
    const { selectedItems: stateSelectedItems } = this.state;
    const { onItemChange } = this.props;
    const selectedItems = stateSelectedItems.slice();
    const itemIndex = findIndex(selectedItems, (item) => item.number === itemNumber);
    let quantityValue = parseInt(value, 10);

    if (itemIndex < 0) {
      return;
    }

    if (quantityValue < 0) {
      quantityValue = 0;
    } else if (quantityValue > selectedItems[itemIndex].maxQuantity) {
      quantityValue = selectedItems[itemIndex].maxQuantity;
    }

    selectedItems[itemIndex].quantity = quantityValue;

    onItemChange(selectedItems);
    this.setState({ selectedItems });
  };

  getCheckedState = (itemNumber: string): boolean => {
    const { selectedItems: stateSelectedItems } = this.state;
    const selectedItem = stateSelectedItems.find((item) => item.number === itemNumber);

    if (selectedItem && selectedItem.quantity === selectedItem.maxQuantity) {
      return true;
    }

    return false;
  };

  getIndeterminateState = (itemNumber: string): boolean => {
    const { selectedItems: stateSelectedItems } = this.state;
    const selectedItem = stateSelectedItems.find((item) => item.number === itemNumber);

    if (selectedItem
      && selectedItem.quantity < selectedItem.maxQuantity
      && selectedItem.quantity > 0) {
      return true;
    }

    return false;
  };

  getHeaderIndeterminate = (): boolean => {
    const { selectedItems } = this.state;
    const totalMaxQuantity = (
      reduce(selectedItems, (sum, item) => sum + item.maxQuantity, 0));
    const totalQuantity = (
      reduce(selectedItems, (sum, item) => sum + item.quantity, 0));

    if (totalQuantity > 0 && totalQuantity < totalMaxQuantity) {
      return true;
    }

    return false;
  };

  getHeaderChecked = (): boolean => {
    const { selectedItems } = this.state;
    const totalMaxQuantity = (
      reduce(selectedItems, (sum, item) => sum + item.maxQuantity, 0));
    const totalQuantity = (
      reduce(selectedItems, (sum, item) => sum + item.quantity, 0));
    if (totalMaxQuantity === totalQuantity) {
      return true;
    }

    return false;
  };

  checkedHeaderBox = (): void => {
    const { selectedItems: stateSelectedItems } = this.state;
    const { onItemChange } = this.props;
    const totalQuantity = reduce(stateSelectedItems,
      (sum, item) => sum + item.quantity, 0);

    if (totalQuantity > 0) {
      const selectedItems = stateSelectedItems.slice().map((item) => ({
        ...item,
        quantity: 0,
      }));

      onItemChange(selectedItems);
      this.setState({ selectedItems });
    } else {
      const selectedItems = stateSelectedItems.slice().map((item) => ({
        ...item,
        quantity: item.maxQuantity,
      }));

      onItemChange(selectedItems);
      this.setState({ selectedItems });
    }
  };

  checkedItemBox = (itemNumber: string): void => {
    const { selectedItems: stateSelectedItems } = this.state;
    const { onItemChange } = this.props;
    const selectedItems = stateSelectedItems.slice();
    const itemIndex = findIndex(selectedItems, (item) => item.number === itemNumber);

    if (itemIndex < 0) {
      return;
    }

    if (selectedItems[itemIndex].quantity <= 0) {
      selectedItems[itemIndex].quantity = selectedItems[itemIndex].maxQuantity;
    } else {
      selectedItems[itemIndex].quantity = 0;
    }
    onItemChange(selectedItems);
    this.setState({ selectedItems });
  };

  render(): ReactElement {
    const {
      items,
    } = this.props;

    const { selectedItems } = this.state;

    return (
      <Table striped displayDensity="compact">
        <TableHead>
          <TableRow>
            <TableHeadColumn className={styles.returnItemsFirstCol}>
              <Checkbox
                onChange={(): void => this.checkedHeaderBox()}
                checked={this.getHeaderChecked()}
                indeterminate={this.getHeaderIndeterminate()}
              />
            </TableHeadColumn>
            <TableHeadColumn>Quantity</TableHeadColumn>
            <TableHeadColumn>Number</TableHeadColumn>
            <TableHeadColumn>Item Name and Variant</TableHeadColumn>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item, index) => (
            <TableRow key={kebabCase(`${item.number} ${index}`)}>
              <TableRowColumn className={styles.returnItemsFirstCol}>
                <Checkbox
                  onChange={(): void => this.checkedItemBox(item.number)}
                  checked={this.getCheckedState(item.number)}
                  indeterminate={this.getIndeterminateState(item.number)}
                />
              </TableRowColumn>
              <TableRowColumn className={styles.returnItemsSecondCol}>
                <TextInputField
                  size="small"
                  defaultValue="0"
                  onValueChange={(
                    value: string,
                  ): void => this.onItemQuantityChange(value, item.number)}
                  value={String(selectedItems[index].quantity)}
                  type="number"
                  inline
                  clearable
                />
                <span className={styles.itemQtyTextInputLabel}>{` / ${item.quantity}`}</span>
              </TableRowColumn>
              <TableRowColumn>{item.number}</TableRowColumn>
              <TableRowColumn>{item.name}</TableRowColumn>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }
}

export default ItemForm;
