export const getStatusFromError = (
  error?: io.flow.internal.v0.models.MarketingGatewayError,
): io.flow.internal.v0.enums.MarketingGatewayFacebookTokenStatus | undefined => {
  if (!error) {
    return 'active';
  }

  switch (error.code) {
    case 'invalid_oauth_token_error':
      return 'expired';
    case 'facebook_api_error':
      return 'unknown';
    default:
      return undefined;
  }
};

export default {
  getStatusFromError,
};
