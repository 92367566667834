import get from 'lodash/get';
import find from 'lodash/find';

export default function getUnstructuredQueryFromQuery(
  filters: io.flow.v0.unions.QueryFilter[],
): string {
  if (filters.length === 1 && filters[0].discriminator === 'unstructured') {
    const reg = new RegExp('(.*) and');
    const currentSearch = reg.exec(filters[0].q);
    return currentSearch ? currentSearch[1] : '';
  }

  const unstructuredFilter = find(filters, { discriminator: 'unstructured' });
  return get(unstructuredFilter, 'q', '');
}
