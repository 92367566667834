import OrganizationSelect from '../../../modules/docs/containers/select';

export default {
  path: '/docs',
  childRoutes: [{
    path: 'select',
    hideLeftNavigation: true,
    component: OrganizationSelect,
    title: 'Select Organization',
  }],
};
