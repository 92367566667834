import { ExperiencesPostQueryAndBuildersResponse } from '@flowio/api-sdk';
import ActionTypes from '../constants/action-types';
import {
  ThunkDispatcher, RootState, ThunkExtraArguments, ThunkResult,
} from '../../../stores/types';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import { PropType } from '../../../types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';
import toLegacyResponse from '../../../utilities/clients/to-legacy-response';

type ResponseType = PropType<ExperiencesPostQueryAndBuildersResponse, 'body'>;

export default function fetchExperienceQueryBuilderFilters(
  organization: string,
  queryBuilderForm: io.flow.v0.unions.QueryBuilderForm,
): ThunkResult<Promise<LegacyResponse<ResponseType>>> {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<LegacyResponse<ResponseType>> => {
    dispatch({
      type: ActionTypes.FETCH_EXPERIENCE_QUERY_BUILDER_FILTERS_REQUEST,
    });
    return extra.api(getState()).experiences.postQueryAndBuilders({
      organization,
      body: queryBuilderForm,
    }).then((response) => {
      if (response.ok) {
        dispatch({
          type: ActionTypes.FETCH_EXPERIENCE_QUERY_BUILDER_FILTERS_SUCCESS,
          payload: response.body,
        });
      } else {
        dispatch({
          type: ActionTypes.FETCH_EXPERIENCE_QUERY_BUILDER_FILTERS_FAILURE,
          payload: formatErrorResponseV2(response),
        });
      }
      return toLegacyResponse<ResponseType>(response);
    });
  };
}
