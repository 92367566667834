import React from 'react';
import noop from 'lodash/noop';
import { Export } from '@flowio/react-icons';
import classNames from 'classnames';
import colors from '../../../../theme/tokens/colors';
import * as styles from './export-button.styles';

interface Props {
  className: string;
  onRequestExport: React.MouseEventHandler;
}

const ExportButton: React.FC<Props> = ({ className, onRequestExport }) => (
  <button className={classNames(styles.exportButton, className)} type="button" onClick={onRequestExport}>
    <Export fill={colors.steel} css={{ height: '100%' }} />
  </button>
);

ExportButton.displayName = 'ExportButton';
ExportButton.defaultProps = {
  className: '',
  onRequestExport: noop,
};

export default ExportButton;
