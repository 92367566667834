import values from 'lodash/values';

import FeatureKey from '../constants/FeatureKey';

export default function createFeatureValueQuery(organizationId: string): string {
  const featureKeys = values(FeatureKey);
  const featureQuery: string[] = [];

  if (organizationId != null) {
    featureQuery.push(`organization.id:${organizationId}`);
  }

  if (featureKeys.length > 0) {
    featureQuery.push(`feature.key in (${featureKeys.join(' or ')})`);
  }

  return featureQuery.join(' or ');
}
