import { connect, MapStateToProps } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getActivities } from '../selectors';
import ActivityList from '../components/ActivityList';
import { ActivityListProps } from '../types/components';
import { RootState } from '../../../stores/types';

const mapStateToProps: MapStateToProps<
ActivityListProps,
{},
RootState
> = createStructuredSelector({
  activities: getActivities,
});

export default connect<ActivityListProps, {}, {}, RootState>(mapStateToProps)(ActivityList);
