import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { fieldInputPropTypes } from 'redux-form';

const FileDragAndDrop = ({
  children,
  onChange,
  ...unhandledProps
}) => {
  const dropHandler = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'drop') {
      onChange(e.dataTransfer.files);
    }
  }, [onChange]);

  return (
    <div
      onDrop={dropHandler}
      onDragEnter={dropHandler}
      onDragLeave={dropHandler}
      onDragOver={dropHandler}
      {...unhandledProps}
    >
      {children != null && (
        children
      )}
      {children == null && (
        <span>Drag file here to upload</span>
      )}
    </div>
  );
};

FileDragAndDrop.propTypes = {
  input: PropTypes.shape(fieldInputPropTypes),
  onChange: PropTypes.func,
  children: PropTypes.node,
};

FileDragAndDrop.defaultProps = {
  input: undefined,
  onChange: undefined,
  children: undefined,
};

export default FileDragAndDrop;
