import { Trash, Edit } from '@flowio/react-icons';
import { TierEstimateType, TierStrategy } from '@flowio/api-constants';
import { Button, IconButton } from '@flowio/react-button';
import React from 'react';
import { Stack, StackItem } from '@flowio/react-stack';
import get from 'lodash/get';
import filter from 'lodash/filter';
import matchesProperty from 'lodash/matchesProperty';
import {
  Card, CardActions, CardHeader, CardContent, CardTitle,
} from '@flowio/react-card';
import { Tag } from '@flowio/react-tag-input';

import TierListAvailability from './TierListAvailability';
import TierListItemSurcharges from './TierListItemSurcharges';
import TierListItemRules from './TierListItemRules';
import TierListItemCarrier from './TierListItemCarrier';
import TierListItemDeliveryEstimate from './TierListItemDeliveryEstimate';
import * as styles from './TierList.styles';
import tierStrategyToWord from '../../utilities/tierStrategyToWord';
import TierListItemName from './TierListItemName';
import TierListItemOptionalMessage from './TierListItemOptionalMessage';
import { TierListItemProps } from '../../types/components';

const isCustomTierEstimateType = matchesProperty('display.estimate.type', TierEstimateType.CUSTOM);
const getCustomTierEstimate = (tier: io.flow.v0.models.Tier) => get(tier, 'display.estimate.label', '');

const TierListItem: React.FC<TierListItemProps> = ({ tier, onDelete, onEdit }) => {
  const tierRules = get(tier, 'rules');
  const tierServices = get(tier, 'services');
  const merchantSurcharges = filter(tier.surcharge_settings, (setting) => setting.responsible_party === 'organization');
  const customerSurcharges = filter(tier.surcharge_settings, (setting) => setting.responsible_party === 'customer');
  const availabilityString = get(tier, 'settings.availability', 'always') === 'always' ? 'Always Considered' : 'Backup';

  return (
    <article className={styles.tier}>
      <Card>
        <CardHeader className={styles.tierHeader}>
          <CardTitle className={styles.tierTitle}>
            {tier.name}
            <Tag
              size="small"
              className={(
                tier.strategy === TierStrategy.FASTEST
                  ? styles.fastestTier
                  : styles.lowCostTier
                )}
              content={tierStrategyToWord(tier.strategy)}
            />
          </CardTitle>
          <CardActions className={styles.tierActions}>
            <IconButton
              className={styles.deleteAction}
              icon={Trash}
              onClick={() => onDelete(tier)}
            />
            <Button leftIcon={Edit} content="Edit" onClick={() => onEdit(tier)} />
          </CardActions>
        </CardHeader>
        <CardContent>
          <Stack distribution="fillEqually" spacing="loose">
            <StackItem className={styles.tierListItem}>
              <Stack distribution="fill" spacing="loose" orientation="vertical">
                <StackItem>
                  <div className={styles.tierSubHeader}>Shipping Tier Display</div>
                </StackItem>
                <StackItem>
                  <TierListItemName name={tier.name} />
                </StackItem>
                <StackItem>
                  <TierListItemDeliveryEstimate
                    isCustomTierEstimateType={isCustomTierEstimateType(tier)}
                    customTierCopy={getCustomTierEstimate(tier)}
                  />
                </StackItem>
                <StackItem>
                  <TierListAvailability availabilityString={availabilityString} />
                </StackItem>
                {tier.message && (
                  <StackItem>
                    <TierListItemOptionalMessage message={tier.message} />
                  </StackItem>
                )}
              </Stack>
            </StackItem>
            <StackItem className={styles.tierListItem}>
              <Stack orientation="vertical" distribution="fill" spacing="loose">
                <StackItem>
                  <div className={styles.tierSubHeader}>Shipping Services &amp; Price</div>
                </StackItem>
                <StackItem>
                  <TierListItemCarrier strategy={tier.strategy} tierServices={tierServices} />
                </StackItem>
                <StackItem>
                  <TierListItemRules tierRules={tierRules} currencyCode={get(tier, 'currency', '')} />
                </StackItem>
                <StackItem>
                  <TierListItemSurcharges
                    merchantSurcharges={merchantSurcharges}
                    customerSurcharges={customerSurcharges}
                  />
                </StackItem>
              </Stack>
            </StackItem>
          </Stack>
        </CardContent>
      </Card>
    </article>
  );
};

TierListItem.displayName = 'TierListItem';

export default TierListItem;
