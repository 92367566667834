import { css } from '@emotion/react';
import capitalize from 'lodash/capitalize';
import styled from '@emotion/styled';
import { Tooltip } from '@flowio/react-tooltip';
import React from 'react';
import { InfoCircleOutline } from '@flowio/react-icons';
import colors from '../../../../../theme/tokens/colors';
import getFBAccessText from '../../../utilities/getFBAccessText';
import accessTokenTooltips from '../../../../facebook/constants/access-status-tooltips';

export const AccessValue = styled.div<{ isError: boolean }>`
  text-decoration: underline;
  cursor:pointer;
  display:flex;
  align-items: center;
  color: ${(props) => (props.isError ? colors.red[600] : 'auto')};
`;

const infoCircleStyle = css`
  fill: ${colors.red[600]};
  margin-left: 5px;
`;

type Props = {
  accessTokenSummary?: io.flow.internal.v0.models
    .MarketingGatewayFacebookConnectionAccessTokenSummary;
  status?: io.flow.internal.v0.enums.MarketingGatewayFacebookTokenStatus;
};

const FacebookAccessValue: React.FC<Props> = ({
  accessTokenSummary,
  status,
}) => {
  const tokenStatus = accessTokenSummary?.token_status || status;

  if (!tokenStatus) {
    return <span>Unknown</span>;
  }

  const isInvalid = tokenStatus === 'expired' || tokenStatus === 'unknown';

  return (
    <Tooltip
      openOnHover
      position="top left"
      trigger={
        (
          <AccessValue data-automation-id="fb-access-value" isError={isInvalid}>
            {accessTokenSummary ? getFBAccessText(accessTokenSummary) : capitalize(tokenStatus)}
            {isInvalid && <InfoCircleOutline width={16} css={infoCircleStyle} />}
          </AccessValue>
        )
      }
      content={accessTokenTooltips[accessTokenSummary?.token_status || tokenStatus]}
    />
  );
};

export default FacebookAccessValue;
