import Client from './client';

export default class StoreConnections extends Client {
  constructor(opts) {
    let options = opts;

    if (typeof opts === 'string') {
      options = { host: opts }; // convert host string to options object
    }

    options.serviceName = 'API Internal';

    super(options);
  }

  getByDomain(domain, options = {}) {
    return this.makeRequest(`${this.host}/magento/stores/${domain}`, options);
  }

  putByDomain(domain, options = {}) {
    return this.makeRequest(`${this.host}/magento/stores/${domain}`, {
      ...options,
       method: 'PUT',
    });
  }

  deleteByDomain(domain, options = {}) {
    return this.makeRequest(`${this.host}/magento/stores/${domain}`, {
      ...options,
       method: 'DELETE',
    });
  }

  postInstallByDomain(domain, options = {}) {
    return this.makeRequest(`${this.host}/magento/stores/${domain}/install`, {
      ...options,
       method: 'POST',
    });
  }

}
