import { css } from '@emotion/css';
import colors from '../../../../../theme/tokens/colors';

export const helpLink = css`
  color: ${colors.marketing['light-slate-gray']};
  font-weight: bold;
  text-decoration: underline;

  &:hover {
    color: ${colors.marketing['dark-slate-blue']};
  }
`;

export const facebookImg = css`
  margin-right: 10px;
`;
