import get from 'lodash/get';
import { connect } from 'react-redux';
import { withFetch } from '@flowio/redux-fetch';
import { fetchOrganization, getOrganization, getOrganizationId } from '../../organization';
import {
  getWebhook, getWebhookDeliveries, redeliver, syncDeliveries,
} from '../actions';
import ManageWebhook from '../components/manage-webhook/manage-webhook';

function getAsyncState(dispatch, getState, ownProps) {
  const { organization, webhookId } = ownProps.params;
  return Promise.all([
    dispatch(fetchOrganization(organization)),
    dispatch(getWebhook(organization, webhookId)),
    dispatch(getWebhookDeliveries(organization, webhookId)),
  ]);
}

function mapStateToProps(state, ownProps) {
  return {
    previousLocation: `/${getOrganizationId(state)}/organization/webhooks`,
    deliveries: get(state, 'webhook.deliveries.results'),
    hasMoreDeliveries: !get(state, 'webhook.deliveries.isLastPage'),
    organization: getOrganization(state),
    showUpdatedNotification: get(ownProps, 'location.state.webhookState') === 'updated',
    webhook: get(state, 'webhook.webhook'),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const { organization, webhookId } = ownProps.params;
  return {
    onRequestRedeliver(deliveryId) {
      return dispatch(redeliver(deliveryId));
    },
    onRequestMoreDeliveries(params) {
      return dispatch(getWebhookDeliveries(organization, webhookId, params));
    },
    onSyncDeliveries(pending) {
      return dispatch(syncDeliveries(organization, pending));
    },
  };
}

export default withFetch(getAsyncState)(
  connect(mapStateToProps, mapDispatchToProps)(ManageWebhook),
);
