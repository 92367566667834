import { css } from '@emotion/css';
import colors from '../../../../../theme/tokens/colors';

export const radioGroup = css`
  display: flex;
  flex-direction: column;
  & > label {
    flex-basis: 56px; /* height of adjoining Radio Buttons */
  }
`;

export const radioLabel = css`
  font-weight: 500;
  color: ${colors.gray[900]} !important;
`;

export const gridRow = css`
  display: flex;
  flex-direction: column;
  & > div {
    flex-basis: 56px; /* height of adjoining Radio Buttons */
    display: flex;
    align-items: center;
  }
`;

export const formGroup = css`
  &  legend {
    height: 34px;
    display: flex;
    align-items: center;
  }
`;

export const grey = css`
  color: ${colors.gray[400]};
`;

export const memberUser = css`
  & label {
    color: ${colors.gray[400]} !important;
  }
`;
