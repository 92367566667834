import { Trash } from '@flowio/react-icons';
import { Button, IconButton } from '@flowio/react-button';
import {
  Card, CardHeader, CardContent, CardFooter,
} from '@flowio/react-card';
import { Collapse } from '@flowio/react-collapse';
import React from 'react';
import { Stack, StackItem } from '@flowio/react-stack';
import get from 'lodash/get';
import find from 'lodash/find';
import size from 'lodash/size';

import { css } from '@emotion/css';
import { Description, DescriptionGroup } from '../../../../components/description';
import CenterList from '../CenterList';
import QueryFilterTokenList from '../../../query-builder/components/QueryFilterTokenList';
import QueryFilterToken from '../../../query-builder/components/QueryFilterToken';
import RegionLabel from '../../../reference/components/RegionLabel';
import pluralize from '../../../../utilities/strings/pluralize';
import { ShippingLaneProfileCardProps, ShippingLaneProfileCardState } from '../../types/components';
import CenterListItem from '../CenterListItem';

const centerListStyles = css`
  margin-left: 20%;
  padding-left: 1rem;
`;

const descriptionTextStyles = css`
  display: flex;
  align-items: center;
`;

class ShippingLaneProfileCard extends React.PureComponent<
ShippingLaneProfileCardProps,
ShippingLaneProfileCardState
> {
  static displayName = 'ShippingLaneProfileCard';

  constructor(props: ShippingLaneProfileCardProps, context: any) {
    super(props, context);
    this.handleToggleCenterList = this.handleToggleCenterList.bind(this);
    this.handleToggleQueryFilterList = this.handleToggleQueryFilterList.bind(this);
    this.state = {
      isCenterListExpanded: false,
      isQueryFilterListExpanded: false,
    };
  }

  handleToggleCenterList() {
    const { isCenterListExpanded } = this.state;
    this.setState({
      isCenterListExpanded: !isCenterListExpanded,
    });
  }

  handleToggleQueryFilterList() {
    const { isQueryFilterListExpanded } = this.state;
    this.setState({
      isQueryFilterListExpanded: !isQueryFilterListExpanded,
    });
  }

  render() {
    const {
      countries,
      onDeleteShippingLane,
      onEditShippingLane,
      regions,
      shippingConfiguration,
      shippingLane,
    } = this.props;

    const {
      isCenterListExpanded,
      isQueryFilterListExpanded,
    } = this.state;

    const centers: io.flow.v0.models.Center[] = get(shippingLane, 'centers') as io.flow.v0.models.Center[];
    const centerCount = size(centers);

    const queryFilters = get(shippingLane, 'query.filters', []);
    const queryFilterCount = size(queryFilters);

    const regionId = get(shippingLane, 'region');
    const region = find(regions, { id: regionId });
    const regionLabel = region == null ? regionId : (
      <RegionLabel region={region} />
    );

    const actions = [
      <IconButton icon={Trash} onClick={onDeleteShippingLane} />,
      <Button content="Edit Shipping Lane" onClick={onEditShippingLane} />,
    ];

    return (
      <Card>
        <CardHeader
          actions={actions}
          caption="Shipping Lane Overview"
          dividing
        />
        <CardContent>
          <DescriptionGroup className="" spacing={false}>
            <Description
              term="Shipping Configuration"
              definition={get(shippingConfiguration, 'name')}
            />
            <Description>
              <Description.Term content={pluralize(queryFilterCount, 'Center Selection Rule')} />
              <Description.Definition>
                {queryFilterCount === 0 && (
                  <span>All Centers Selected</span>
                )}
                {queryFilterCount === 1 && (
                  <QueryFilterToken queryFilter={queryFilters[0]} />
                )}
                {queryFilterCount > 1 && (
                  <Stack distribution="equalSpacing">
                    <StackItem className={descriptionTextStyles}>
                      {queryFilterCount}
                      {' '}
                      {pluralize(queryFilterCount, 'Selection Rule')}
                    </StackItem>
                    <Button
                      content={isQueryFilterListExpanded ? 'Hide Detail' : 'Show Detail'}
                      variant="flat"
                      onClick={this.handleToggleQueryFilterList}
                      size="small"
                    />
                  </Stack>
                )}
              </Description.Definition>
            </Description>
            {queryFilterCount > 1 && (
              <Collapse expanded={isQueryFilterListExpanded}>
                <QueryFilterTokenList className={centerListStyles} queryFilters={queryFilters} />
              </Collapse>
            )}
            <Description>
              <Description.Term content={pluralize(centerCount, 'Center')} />
              <Description.Definition>
                {centerCount === 0 && (
                  <span>0 Centers</span>
                )}
                {centerCount === 1 && (
                  <CenterListItem center={centers[0]} countries={countries} />
                )}
                {centerCount > 1 && (
                  <Stack distribution="equalSpacing">
                    <span>
                      {centerCount}
                      {' '}
                      {pluralize(centerCount, 'Center')}
                    </span>
                    <Button
                      content={isCenterListExpanded ? 'Hide Detail' : 'Show Detail'}
                      variant="flat"
                      onClick={this.handleToggleCenterList}
                      size="small"
                    />
                  </Stack>
                )}
              </Description.Definition>
            </Description>
            {centerCount > 1 && (
              <Collapse expanded={isCenterListExpanded}>
                <CenterList centers={centers} className={centerListStyles} countries={countries} />
              </Collapse>
            )}
            <Description>
              <Description.Term content="Region" />
              <Description.Definition>
                {regionLabel}
              </Description.Definition>
            </Description>
          </DescriptionGroup>
        </CardContent>
        <CardFooter />
      </Card>
    );
  }
}

export default ShippingLaneProfileCard;
