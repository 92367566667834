import { createSelector } from 'reselect';
import getMarketingState from './getMarketingState';
import { RootState } from '../../../stores/types';
import { MarketingState } from '../types';

type FeedFormsObject = Record<io.flow.internal.v0.enums.MarketingGatewaySchemaCompatibility,
io.flow.internal.v0.models.MarketingGatewayFeedForm[]>;

export default createSelector<RootState, MarketingState, FeedFormsObject>(
  getMarketingState,
  ({ feedForms }: MarketingState): FeedFormsObject => feedForms.forms,
);
