import { connect } from 'react-redux';
import { withFetch } from '@flowio/redux-fetch';
import { compose } from 'redux';
import GdprFaq from '../components/gdpr-faq/gdpr-faq';
import { checkFeatureValues } from '../../console/actions';

function getAsyncState(dispatch, getState, ownProps) {
  const { organization } = ownProps.params;
  return dispatch(checkFeatureValues({
    organizationId: organization,
  }));
}

function mapStateToProps(state, ownProps) {
  return {
    organization: ownProps.params.organization,
  };
}

export default compose(
  withFetch(getAsyncState),
  connect(mapStateToProps),
)(GdprFaq);
