import { createSelector } from 'reselect';
import get from 'lodash/get';

const getPendingUsersState = (state) => get(state, 'pendingUsers');

const getPendingUsers = createSelector(
  getPendingUsersState,
  (state) => get(state, 'users'),
);

export default getPendingUsers;
