import { css } from '@emotion/css';
import { colors } from '../../../../../theme/tokens';
import { labColors } from '../../../styles/labs.styles';

export const cardContent = css`
  padding: 0;
`;

export const bannerImage = css`
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
`;

export const boxContent = css`
  padding-right: 16px;
  padding-left: 16px;
`;

export const contentTitle = css`
  font-size: 18px;
  padding-bottom: 16px;
  color: #0051b2;
`;

export const content = css`
  white-space: break-spaces;
  font-size: 14px;
  line-height: 17px;
  padding-bottom: 18px;
  padding-left: 16px;
  margin-right: 16px;
  margin-top: 1px;
`;

export const details = css`
  width: 70%;
`;

export const listing = css`
  width: 30%;
  text-align: center;
`;

export const consentField = css`
  padding-bottom: 32px;
`;

export const acceptBtn = css`
  cursor: pointer;
  margin-bottom: 8px;
  margin-top: 6px;
`;

export const terms = css`
  color: ${colors.gray[800]};
  font-size: 12px;
  line-height: 15px;
  padding-bottom: 18px;
`;

export const accepted = css`
  color: ${colors.gray[900]};
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  padding-bottom: 18px;
`;

export const acceptDialog = css`
  width: 480px;
`;

const bgColor = labColors[3];

export const labHeroBg = css`
  min-height: 120px;
  position: relative;
  margin-bottom: 16px;

  background-image: linear-gradient(
    70deg,
    rgba(${bgColor.red}, ${bgColor.green}, ${bgColor.blue}, 1) 0%,
    rgba(${bgColor.red}, ${bgColor.green}, ${bgColor.blue}, 0.63) 50%,
    rgba(${bgColor.red}, ${bgColor.green}, ${bgColor.blue}, 0.67) 50%,
    rgba(${bgColor.red}, ${bgColor.green}, ${bgColor.blue}, 0.43) 65%,
    rgba(${bgColor.red}, ${bgColor.green}, ${bgColor.blue}, 0.47) 65%,
    rgba(${bgColor.red}, ${bgColor.green}, ${bgColor.blue}, 0.23) 80%,
    rgba(${bgColor.red}, ${bgColor.green}, ${bgColor.blue}, 0.27) 80%,
    rgba(${bgColor.red}, ${bgColor.green}, ${bgColor.blue}, 0.2) 100%
  );
`;

export const labTitle = css`
  display: flex;
  position: absolute;
  align-items: center;
  bottom: 16px;
  left: 32px;
  color: ${colors.gray[200]};
  font-size: 24px;
  z-index: 2;

  & h4 {
    margin: 0 0 0 14px;
  }
`;

export const heroTextBlend = css`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  background-image: linear-gradient(
    0deg,
    #868686 0%,
    rgba(196, 196, 196, 0) 100%
  );
  mix-blend-mode: multiply;
`;
