import React from 'react';
import { css } from '@emotion/css';
import PolicyLayout from '../policy-layout/policy-layout';

const tos = css`
  text-align: center;
  height: 1200px;
`;

const TermsAndConditions = () => (
  <PolicyLayout>
    <div className={tos}>
      <a href="https://link.flow.io/terms/client">View Terms and Conditions.</a>
    </div>
  </PolicyLayout>
);

TermsAndConditions.displayName = 'Terms and Conditions';

export default TermsAndConditions;
