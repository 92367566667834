import update from 'immutability-helper';

import ActionTypes from '../constants/action-types';
import { ExperienceState } from '../types';
import { RootActionTypes } from '../../../stores/types';

type State = ExperienceState['entities']['experienceLogisticsSettings'];

const initialState: State = {};

export default function (state: State = initialState, action: RootActionTypes): State {
  switch (action.type) {
    case ActionTypes.FETCH_EXPERIENCE_LOGISTICS_SETTING_SUCCESS:
      return update(state, {
        [action.payload.id]: {
          $set: action.payload,
        },
      });
    case ActionTypes.UPDATE_EXPERIENCE_LOGISTICS_SETTING_SUCCESS:
      return update(state, {
        [action.payload.id]: {
          $set: action.payload,
        },
      });
    default: return state;
  }
}
