import { RouterState } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFetch } from '@flowio/redux-fetch';
import { fetchOrganization } from '../../organization';

import fetchOrder from '../actions/fetch-order';
import redirectToOrderDetails from '../actions/redirect-to-order-details';
import ShopifyOrderDetailRedirect from '../components/shopify-order-detail-redirect';
import { RootState, ThunkDispatcher } from '../../../stores/types';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import { ShopifyOrderDetailRedirectProps } from '../types';

const fetchAsyncState = (
  dispatch: ThunkDispatcher,
  _getState: () => RootState,
  ownProps: RouterState,
): Promise<[
  LegacyResponse<io.flow.v0.models.Organization | undefined>,
  void,
]> => {
  const { organization, order } = ownProps.params;
  return Promise.all([
    dispatch(fetchOrganization(organization)),
    dispatch(fetchOrder(organization, order)), // fetchOrderByShopifyOrderId
  ]);
};

const mapStateToProps = (): any => ({});

const mapDispatchToProps = (dispatch: ThunkDispatcher): ShopifyOrderDetailRedirectProps => ({
  onRequestRedirectToOrderDetails: (): void => {
    dispatch(redirectToOrderDetails());
  },
});

export default compose <React.FC<ShopifyOrderDetailRedirectProps>>(
  withFetch(fetchAsyncState),
  connect(mapStateToProps, mapDispatchToProps),
)(ShopifyOrderDetailRedirect);
