import { ExperiencesPostCloneByKeyResponse } from '@flowio/api-sdk';
import ActionTypes from '../constants/action-types';
import { getOrganizationId } from '../../organization/selectors';
import {
  ThunkDispatcher,
  RootState,
  ThunkExtraArguments,
  RootActionTypes,
  ThunkResult,
} from '../../../stores/types';
import { PropType } from '../../../types';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import toLegacyResponse from '../../../utilities/clients/to-legacy-response';

type ResponseType = PropType<ExperiencesPostCloneByKeyResponse, 'body'>;

function cloneExperienceSuccessAction(payload: io.flow.v0.models.ExperienceClone): RootActionTypes {
  return {
    type: ActionTypes.CLONE_EXPERIENCE_SUCCESS,
    payload,
  };
}

function cloneExperienceErrorAction(error?: { messages: string[] }): RootActionTypes {
  return {
    type: ActionTypes.CLONE_EXPERIENCE_ERROR,
    payload: (error?.messages || ['Unknown Error']).join('; '),
  };
}

interface CloneExperienceParams {
  experience: io.flow.v0.models.Experience;
  newName: string;
}

function cloneExperience({
  experience,
  newName,
}: CloneExperienceParams): ThunkResult<Promise<LegacyResponse<ResponseType>>> {
  return function cloneExperienceSideEffects(
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<LegacyResponse<ResponseType>> {
    dispatch({ type: ActionTypes.CLONE_EXPERIENCE_REQUEST });
    const state = getState();
    const organization = getOrganizationId(state);

    return extra.api(state).experiences.postCloneByKey({
      organization,
      key: experience.key,
      body: {
        name: newName,
      },
    }).then((response) => {
      if (response.ok) dispatch(cloneExperienceSuccessAction(response.body));
      else dispatch(cloneExperienceErrorAction(response.body));
      return toLegacyResponse<ResponseType>(response);
    });
  };
}

export default cloneExperience;
