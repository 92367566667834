import React from 'react';
import classNames from 'classnames';

import Step from '../../../utilities/components/step';
import * as styles from './WizardBreadCrumbs.styles';

const STEPS = [
  'Choose Source',
  'Choose Target Countries',
];

interface Props {
  stepNumber: number;
}

const WizardBreadCrumbs: React.FC<Props> = ({
  stepNumber,
}) => {
  const isDone = (index: number): boolean => stepNumber >= index + 1;

  return (
    <Step.Group className={styles.steps}>
      {
        STEPS.map((step, index) => (
          <Step
            key={step.replace(' ', '-').toLowerCase()}
            className={classNames(styles.step, { [styles.active]: isDone(index) })}
            active={isDone(index)}
            stepNumber={index + 1}
          >
            <Step.Title>{step}</Step.Title>
          </Step>
        ))
      }
    </Step.Group>
  );
};

WizardBreadCrumbs.displayName = 'WizardBreadCrumbs';

export default WizardBreadCrumbs;
