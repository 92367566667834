import { RouterState, browserHistory } from 'react-router';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { setOverlayProps } from '@flowio/redux-form-overlay';
import { LaneDirection } from '@flowio/api-constants';
import { withFetch } from '@flowio/redux-fetch';
import get from 'lodash/get';

import * as actions from '../actions';
import { DialogName, FormMethod, FormName } from '../constants';
import { fetchOrganization } from '../../organization/actions';
import { fetchRegions } from '../../reference/actions';
import {
  getActiveShippingConfiguration,
  getExperiencesByShippingConfigurationKey,
  getOutboundShippingLanes,
  getReturnShippingLanes,
} from '../selectors';
import { getOrganizationId } from '../../organization/selectors';
import { getRegions } from '../../reference/selectors';
import ShippingConfigurationDetailPage from '../components/ShippingConfigurationDetailPage';
import { RootState, ThunkDispatcher } from '../../../stores/types';
import { ShippingConfigurationDetailPageStateProps } from '../types/components';

const fetchState = (
  dispatch: ThunkDispatcher,
  _getState: () => RootState,
  props: RouterState,
) => {
  const { params } = props;
  const { organization, shippingConfigurationKey } = params;

  return Promise.all([
    dispatch(actions.changeActiveShippingConfiguration({
      shippingConfigurationKey,
    })),
    dispatch(fetchRegions()),
    dispatch(fetchOrganization(organization)),
    dispatch(actions.fetchExperiences({
      entriesPerPage: 100,
      organizationId: organization,
      shippingConfigurationKey,
    })),
    dispatch(actions.fetchShippingConfigurationByKey({
      organizationId: organization,
      shippingConfigurationKey,
    })),
    dispatch(actions.resetShippingLanePages()),
  ]);
};

function handleAddShippingLane(
  organizationId: string,
  shippingConfigurationKey: string,
  shippingLaneDirection: LaneDirection,
) {
  return actions.showShippingLaneWorksheet({
    method: FormMethod.POST,
    organizationId,
    shippingConfigurationKey,
    shippingLaneDirection,
    title: shippingLaneDirection === LaneDirection.OUTBOUND ? 'Add Outbound Shipping Lane' : 'Add Return Shipping Lane',
  });
}

function handleDeleteShippingConfiguration(
  organizationId: string,
  shippingConfiguration: string,
  experiences: io.flow.v0.models.Experience[],
) {
  const shippingConfigurationKey = get(shippingConfiguration, 'key');
  return actions.updateDialog(DialogName.SHIPPING_CONFIGURATION_DELETE_CONFIRMATION, {
    experiences,
    open: true,
    initialValues: {
      organizationId,
      shippingConfigurationKey,
    },
  });
}

function handleDuplicateShippingConfiguration(
  organizationId: string,
  shippingConfiguration: io.flow.v0.models.ShippingConfiguration,
) {
  return actions.updateDialog(DialogName.SHIPPING_CONFIGURATION_COPY_WORKSHEET, {
    initialValues: {
      organizationId,
      shippingConfigurationKey: shippingConfiguration.key,
    },
    open: true,
    shippingConfiguration,
    title: 'Create Shipping Configuration',
  });
}

function handleEditShippingConfiguration(
  organizationId: string,
  shippingConfiguration: io.flow.v0.models.ShippingConfiguration,
) {
  return setOverlayProps(FormName.UPDATE_SHIPPING_CONFIGURATION_FORM, {
    initialValues: {
      name: get(shippingConfiguration, 'name'),
      organizationId,
      shippingConfigurationKey: get(shippingConfiguration, 'key'),
    },
    open: true,
    title: 'Update Shipping Configuration',
  });
}

function handleViewShippingLane(
  organizationId: string,
  shippingConfigurationKey: string,
  shippingLane: io.flow.v0.models.ShippingLane,
) {
  return browserHistory.push(`/${organizationId}/logistics/shipping/configurations/${shippingConfigurationKey}/lanes/${shippingLane.id}`);
}

function handleUpdateOutboundShippingLanePage(pageNumber: number) {
  return actions.updateOutboundShippingLanePage(pageNumber);
}

function handleUpdateReturnShippingLanePage(pageNumber: number) {
  return actions.updateReturnShippingLanePage(pageNumber);
}

function mapStateToProps(
  state: RootState,
  props: RouterState,
): ShippingConfigurationDetailPageStateProps {
  const { params } = props;
  const { shippingConfigurationKey } = params;
  return {
    experiences: getExperiencesByShippingConfigurationKey(shippingConfigurationKey)(state),
    organizationId: getOrganizationId(state),
    regions: getRegions(state),
    shippingConfiguration: getActiveShippingConfiguration(state),
    outboundPaginatedShippingLanes: getOutboundShippingLanes(state),
    returnPaginatedShippingLanes: getReturnShippingLanes(state),
  };
}

const mapDispatchToProps = (dispatch: ThunkDispatcher) => bindActionCreators({
  onAddShippingLane: handleAddShippingLane,
  onDeleteShippingConfiguration: handleDeleteShippingConfiguration,
  onDuplicateShippingConfiguration: handleDuplicateShippingConfiguration,
  onEditShippingConfiguration: handleEditShippingConfiguration,
  onViewShippingLane: handleViewShippingLane,
  onUpdateOutboundShippingLanePage: handleUpdateOutboundShippingLanePage,
  onUpdateReturnShippingLanePage: handleUpdateReturnShippingLanePage,
}, dispatch);

export default compose<React.FC>(
  withFetch(fetchState),
  connect(mapStateToProps, mapDispatchToProps),
)(ShippingConfigurationDetailPage);
