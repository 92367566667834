import showDialog from './showDialog';
import DialogName from '../constants/DialogName';

function showDeleteOptinContentConfirm(
  organizationId: string,
  optinPrompt: io.flow.internal.v0.models.OptinPrompt,
) {
  return showDialog(DialogName.DELETE_OPTIN_CONTENT_CONFIRM)({
    initialValues: {
      organizationId,
      optinPromptId: optinPrompt.id,
    },
    optinPrompt,
  });
}

export default showDeleteOptinContentConfirm;
