import { compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, submit } from 'redux-form';

import FormName from '../../../constants/FormName';
import { RootState, RootActionTypes, ThunkDispatcher } from '../../../../../stores/types';
import DiscountListingFilterForm from '../components/DiscountListingFilterForm';
import { getDiscountStatusFilter } from '../../../selectors';
import {
  DiscountFormValues,
} from '../../../types';
import updateDiscountFilters from '../../../actions/updateDiscountFilters';
import {
  StateProps, DispatchProps, OwnProps, MergedProps,
} from '../types';

function handleSubmit(
  values: DiscountFormValues,
  dispatch: Dispatch<RootActionTypes>,
  props: OwnProps,
): void {
  const { onRequestDiscountFilterChange } = props;

  const defaultValues = [
    'active',
    'scheduled',
    'expired',
  ];

  dispatch(updateDiscountFilters(values.discountStatusFilter));
  onRequestDiscountFilterChange(
    values.discountStatusFilter.length < 1 ? defaultValues : values.discountStatusFilter,
  );
}

function mapStateToProps(state: RootState): StateProps {
  const discountStatusFilter = getDiscountStatusFilter(state);

  return {
    initialValues: {
      discountStatusFilter,
    },
  };
}

function mapDispatchToProps(
  dispatch: ThunkDispatcher,
): DispatchProps {
  return {
    onDropdownClose: (): void => {
      dispatch(submit(FormName.DISCOUNT_FILTER_FORM));
    },
  };
}

export default compose<React.FC<OwnProps>>(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
  reduxForm<DiscountFormValues, MergedProps>({
    form: FormName.DISCOUNT_FILTER_FORM,
    onSubmit: handleSubmit,
  }),
)(DiscountListingFilterForm);
