import { compose } from 'redux';
import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { browserHistory } from 'react-router';
import includes from 'lodash/includes';
import delay from 'lodash/delay';
import get from 'lodash/get';
import { submit } from 'redux-form';
import { ExperienceCloneStatus } from '@flowio/api-constants';
import { createStructuredSelector, createSelector } from 'reselect';
import ActionTypes from '../../../constants/action-types';
import {
  getExperienceToClone,
  getCloneDialogLoadingState,
  getCloneExperienceDefaultName,
  getCloneExperienceError,
  getCloneExperienceModel,
  getCloneExperienceShouldPoll,
} from '../../../selectors';
import {
  getOrganization,
} from '../../../../organization';
import ExperienceCloneDialog from '../components/ExperienceCloneDialog';

import {
  clearCloneExperience,
  clearExperienceDefaults,
  fetchCloneExperienceModel,
} from '../../../actions';
import FormName from '../../../constants/FormName';
import {
  HandlePollCloneExperienceParams,
  StateProps,
  DispatchProps,
  OwnProps,
} from '../types';
import { RootState, ThunkDispatcher } from '../../../../../stores/types';

const handleCloneExperienceSubmit = (dispatch: ThunkDispatcher): void => {
  dispatch(submit(FormName.CLONE_EXPERIENCE));
};

const handleDialogClose = (dispatch: ThunkDispatcher): Promise<unknown[]> => Promise.all([
  dispatch(clearCloneExperience()),
  dispatch(clearExperienceDefaults()),
]);

const handlePollCloneExperience = ({
  experienceKey,
  experienceCloneId,
}: HandlePollCloneExperienceParams) => (
  dispatch: ThunkDispatcher,
  getState: () => RootState,
): Promise<void> => dispatch(fetchCloneExperienceModel({
  experienceKey,
  experienceCloneId,
})).then((response) => {
  if (response.type !== ActionTypes.FETCH_EXPERIENCE_CLONE_SUCCESS) {
    throw new Error('Error getting Experience clone model');
  }
  return response;
})
  .then(({ payload }) => {
    if (includes([
      ExperienceCloneStatus.PENDING,
      ExperienceCloneStatus.UPDATING,
    ], payload.status)) {
      delay(() => dispatch(handlePollCloneExperience({
        experienceKey,
        experienceCloneId,
      })), 5000);
    } else if (payload.status === ExperienceCloneStatus.COMPLETED) {
      const organization = getOrganization(getState());
      const clonedExperienceKey = get(payload, 'cloned_experience.key');
      dispatch(clearCloneExperience());
      dispatch(clearExperienceDefaults());
      browserHistory.push(`/${organization.id}/experience/${clonedExperienceKey}/localization`);
    } else if (payload.status === ExperienceCloneStatus.FAILED) {
      dispatch({
        type: ActionTypes.CLONE_EXPERIENCE_FAILED,
      });
      dispatch({
        type: ActionTypes.CLONE_EXPERIENCE_ERROR,
        payload: 'Duplication of experience failed, please try again.',
      });
    }
  });

const getInitialValues = createSelector(
  getCloneExperienceDefaultName,
  (state) => ({
    newName: state,
  }),
);

const mapStateToProps: MapStateToProps<
StateProps, OwnProps, RootState
> = createStructuredSelector<RootState, StateProps>({
  cloneExperienceModel: getCloneExperienceModel,
  cloneError: getCloneExperienceError,
  experienceShouldPoll: getCloneExperienceShouldPoll,
  experienceToClone: getExperienceToClone,
  initialValues: getInitialValues,
  isLoading: getCloneDialogLoadingState,
  organization: getOrganization,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch: ThunkDispatcher,
) => ({
  onCloneCancellation: (): Promise<unknown[]> => handleDialogClose(dispatch),
  onPollCloneExperienceModel: (
    args: HandlePollCloneExperienceParams,
  ): Promise<void> => dispatch(handlePollCloneExperience(args)),
  handleCloneExperienceSubmit: (): void => handleCloneExperienceSubmit(dispatch),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(ExperienceCloneDialog);
