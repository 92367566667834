import assign from 'lodash/assign';
import filter from 'lodash/filter';
import map from 'lodash/map';
import uniqBy from 'lodash/uniqBy';
import ActionTypes from '../constants/action-types';

const defaultState = [];

const mapRateCardsToCarriers = (ratecards) => {
  const services = uniqBy(map(ratecards, 'service'), 'id');
  const carriers = map(uniqBy(map(services, 'carrier'), 'id'), (carrier) => assign({}, carrier, {
    services: filter(services, (service) => service.carrier.id === carrier.id),
  }));
  return carriers;
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case ActionTypes.FETCH_RATECARDS_SUCCESS:
      return mapRateCardsToCarriers(action.payload);
    default:
      return state;
  }
}
