import { ActionTypes } from '../constants';
import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import { CreateCenterQueryBuilderFailure, CreateCenterQueryBuilderSuccess } from '../types/actions';

function createRequestAction(): RootActionTypes {
  return {
    type: ActionTypes.CREATE_CENTER_QUERY_BUILDER_REQUEST,
  };
}

function createSuccessAction(
  payload: io.flow.v0.models.QueryBuilder,
): CreateCenterQueryBuilderSuccess {
  return {
    type: ActionTypes.CREATE_CENTER_QUERY_BUILDER_SUCCESS,
    payload,
  };
}

function createFailureAction(payload: FormattedErrorMessages): CreateCenterQueryBuilderFailure {
  return {
    type: ActionTypes.CREATE_CENTER_QUERY_BUILDER_FAILURE,
    error: true,
    payload,
  };
}

// An action responsible for create a query builder. Generally, this action is
// not reduced into the application state, instead it is used as a utility to
// ensure consistency while resolving promise chains.
function createCenterQueryBuilder(
  organizationId: string,
  queryBuilderForm: io.flow.v0.unions.QueryBuilderForm,
): ThunkResult<Promise<CreateCenterQueryBuilderSuccess | CreateCenterQueryBuilderFailure>> {
  return function createCenterQueryBuilderEffects(
    dispatch,
    getState,
    extra,
  ): Promise<CreateCenterQueryBuilderSuccess | CreateCenterQueryBuilderFailure> {
    dispatch(createRequestAction());

    return extra.apiInternal(getState()).centerQueryBuilders.post({
      body: queryBuilderForm,
      organization: organizationId,
    }).then((response) => {
      if (response.ok) {
        return dispatch<CreateCenterQueryBuilderSuccess>(createSuccessAction(response.body));
      }

      const errors = formatErrorResponseV2(response);
      return dispatch<CreateCenterQueryBuilderFailure>(createFailureAction(errors));
    });
  };
}

export default createCenterQueryBuilder;
