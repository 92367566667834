import { Form, Field } from 'redux-form';
import React from 'react';
import get from 'lodash/get';

import { css } from '@emotion/css';
import GenericError from '../../../../components/GenericError';
import presence from '../../../../utilities/validators/presence';
import { ShippingConfigurationCopyFormCombinedProps } from '../../types/components';
import { TextField } from '../../../../components/ReduxFormFieldAdapters';

const errorStyles = css`
  margin-bottom: 1rem;
`;

const fieldsetStyles = css`
  padding: 16px;
  border-radius: 4px;
  border: solid 1px #e6e6e6;
  box-sizing: border-box;
`;

const validateName = [
  presence({ message: 'Enter name', allowEmpty: false }),
];

const ShippingConfigurationCopyForm: React.FC<ShippingConfigurationCopyFormCombinedProps> = ({
  error,
  handleSubmit,
  shippingConfiguration,
}) => (
  <Form onSubmit={handleSubmit}>
    {error && (
      <GenericError className={errorStyles} error={error} />
    )}
    <div className={fieldsetStyles}>
      <Field component="input" type="hidden" name="organizationId" />
      <Field component="input" type="hidden" name="shippingConfigurationKey" />
      <Field
        component={TextField}
        fluid
        hint={`Duplicating from "${get(shippingConfiguration, 'name')}" shipping configuration`}
        inline
        id="name"
        name="name"
        labelFor="name"
        labelText="Name"
        validate={validateName}
      />
    </div>
  </Form>
);

ShippingConfigurationCopyForm.displayName = 'ShippingConfigurationCopyForm';

export default ShippingConfigurationCopyForm;
