import { Confirm } from '@flowio/react-confirm';
import React, { PureComponent } from 'react';
import get from 'lodash/get';
import defaultTo from 'lodash/defaultTo';

import { css } from '@emotion/css';
import { ExclusionRuleDeleteConfirmationProps } from '../../types';

const deleteConfirmationStyle = css({
  width: '480px !important',
});

const errorMessageStyle = css`
  margin: 1rem 0 0;
  color: #d2431f;
`;

class ExclusionRuleDeleteConfirmation extends PureComponent<ExclusionRuleDeleteConfirmationProps> {
  static displayName = 'ExclusionRuleDeleteConfirmation';

  static defaultProps = {
    exclusionRule: undefined,
    organizationId: undefined,
  };

  constructor(props: ExclusionRuleDeleteConfirmationProps, context: any) {
    super(props, context);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  handleConfirm() {
    const {
      exclusionRule,
      onOk,
      organizationId = '',
    } = this.props;
    const exclusionRuleId = get(exclusionRule, 'id', '');
    onOk(organizationId, exclusionRuleId);
  }

  render() {
    const {
      deleting,
      deleteFailed,
      exclusionRule,
      onCancel,
      open,
    } = this.props;
    const exclusionRuleDescription = get(exclusionRule, 'description');
    const exclusionRuleQuery = get(exclusionRule, 'q');
    return (
      <Confirm
        className={deleteConfirmationStyle}
        caption="Are you absolutely sure?"
        disabled={deleting}
        onCancel={onCancel}
        onOk={this.handleConfirm}
        open={open}
        intent="negative"
      >
        <p>
          This action
          <strong> cannot </strong>
          be undone. Continue deleting exclusion rule
          <strong>{` ${defaultTo(exclusionRuleDescription, exclusionRuleQuery)}`}</strong>
          ?
        </p>
        {deleteFailed && (
          <p className={errorMessageStyle}>
            An error has occurred while trying to delete exclusion rule.
            Please try again later.
            Contact
            <a href="mailto:help@flow.io">help@flow.io</a>
            if this issue persists.
          </p>
        )}
      </Confirm>
    );
  }
}

export default ExclusionRuleDeleteConfirmation;
