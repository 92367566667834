import update from 'immutability-helper';
import { RootActionTypes } from '../../../stores/types';
import ActionType from '../constants/ActionType';
import { ExclusionRuleWorksheetState, ExclusionRuleStatus } from '../types';

const initialState = {
  availableRegions: [],
  error: undefined,
  exclusionRule: undefined,
  queryBuilder: undefined,
  status: 'pending' as ExclusionRuleStatus,
  visibility: 'hidden',
};

export default function (
  state: ExclusionRuleWorksheetState = initialState,
  action: RootActionTypes,
) {
  switch (action.type) {
    case ActionType.HideExclusionRuleWorksheet:
      return update(state, {
        visibility: { $set: 'hidden' },
      });
    case ActionType.ShowExclusionRuleWorksheetRequest:
      return update(state, {
        availableRegions: { $set: [] },
        error: { $set: undefined },
        exclusionRule: { $set: undefined },
        queryBuilder: { $set: undefined },
        status: { $set: 'loading' },
        visibility: { $set: 'visible' },
      });
    case ActionType.ShowExclusionRuleWorksheetSuccess:
      return update(state, {
        availableRegions: { $set: action.payload.regions },
        exclusionRule: { $set: action.payload.exclusionRule },
        queryBuilder: { $set: action.payload.queryBuilder },
        status: { $set: 'fulfilled' },
      });
    case ActionType.ShowExclusionRuleWorksheetFailure:
      return update(state, {
        error: { $set: action.payload },
        status: { $set: 'rejected' },
      });
    case ActionType.SubmitExclusionRuleFormError:
      return update(state, {
        error: { $set: action.payload },
        status: { $set: 'fulfilled' },
      });
    default:
      return state;
  }
}
