import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import { ActionTypes } from '../constants';

interface FetchWebhooksParams {
  entriesPerPage?: number;
  pageNumber?: string;
}

function createRequestAction(): RootActionTypes {
  return {
    type: ActionTypes.FETCH_WEBHOOKS_REQUEST,
  };
}

function createSuccessAction(payload: any): RootActionTypes {
  return {
    type: ActionTypes.FETCH_WEBHOOKS_SUCCESS,
    payload,
  };
}

function createFailureAction(payload: FormattedErrorMessages): RootActionTypes {
  return {
    type: ActionTypes.FETCH_WEBHOOKS_FAILURE,
    payload,
  };
}

export default function fetchWebhooks(
  organization: string,
  params: FetchWebhooksParams = {},
): ThunkResult<Promise<any>> {
  return function fetchWebhooksEffects(
    dispatch,
    getState,
    extra,
  ): Promise<any> {
    const limit = params.entriesPerPage ? params.entriesPerPage + 1 : 26;
    const offset = params.pageNumber ? (Number(params.pageNumber) - 1) * limit : 0;

    dispatch(createRequestAction());

    return extra.api(getState()).webhooks.getWebhooksByOrganization({
      organization,
      limit,
      offset,
    }).then((response) => {
      if (response.ok) {
        const isFirstPage = offset === 0;
        const isLastPage = response.body.length <= limit;

        dispatch(createSuccessAction({
          status: response.status,
          result: {
            isFirstPage,
            isLastPage,
            results: isLastPage ? response.body : response.body.slice(0, response.body.length - 1),
          },
        }));

        return response;
      }

      const errors = formatErrorResponseV2(response);
      dispatch(createFailureAction(errors));
      return response;
    });
  };
}
