import { reduxForm, FormErrors, SubmissionError } from 'redux-form';
import { MapStateToProps, connect } from 'react-redux';

import { compose } from 'redux';
import { FlowRole, Role } from '@flowio/api-constants';
import { isEmpty } from 'lodash';
import { MEMBERSHIP_FORM } from '../../../constants/form-names';
import MembershipForm from '../components/membership-form';
import {
  MembershipFormValues, OwnProps, StateProps,
} from '../types';
import { ThunkDispatcher, RootState } from '../../../../../stores/types';
import { hasOnlyCustomerServiceRoles } from '../../../../console/utilities/is-customer-service-user';
import validator from '../../../../../utilities/validator';
import { hasOnlyChannelOrgAdminRoles } from '../../../../console/utilities/is-channel-organization-admin-user';
import { hasOnlyFulfillmentRole } from '../../../../console/utilities/isFulfillmentUser';

function validateForm(values: MembershipFormValues): boolean {
  const errors: FormErrors<MembershipFormValues> = {};
  if (!validator.validate(values.email, {
    pattern: 'email',
    required: true,
  })) {
    errors.email = 'Please enter a valid email address.';
  }
  if (!isEmpty(errors)) {
    throw new SubmissionError(errors);
  }
  return true;
}

function handleSubmit(
  values: MembershipFormValues,
  _dispatch: ThunkDispatcher,
  ownProps: OwnProps,
): void {
  validateForm(values);
  ownProps.onFormSubmit(values, ownProps.editMembership);
}

function getInitalValues(
  organization: string,
  editMembership: io.flow.v0.models.Membership | null,
): MembershipFormValues {
  if (editMembership == null) {
    return {
      organization,
      email: '',
      role: Role.MEMBER,
    };
  }
  if (hasOnlyCustomerServiceRoles(editMembership)) {
    return {
      organization,
      email: (editMembership.user as io.flow.v0.models.User).email || '',
      role: FlowRole.ORGANIZATION_CUSTOMER_SERVICE,
    };
  }
  if (hasOnlyChannelOrgAdminRoles(editMembership.roles)) {
    return {
      organization,
      email: (editMembership.user as io.flow.v0.models.User).email || '',
      role: FlowRole.CHANNEL_ORGANIZATION_ADMIN,
    };
  }
  if (hasOnlyFulfillmentRole(editMembership.roles)) {
    return {
      organization,
      email: (editMembership.user as io.flow.v0.models.User).email || '',
      role: FlowRole.ORGANIZATION_FULFILLMENT,
    };
  }
  return {
    organization,
    email: (editMembership.user as io.flow.v0.models.User).email || '',
    role: (editMembership.role as Role) || Role.MEMBER,
  };
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = (
  _state: RootState,
  ownProps: OwnProps,
) => ({
  hasAdminRole: ownProps.userMembership.role === Role.ADMIN,
  initialValues: getInitalValues(ownProps.organizationKey, ownProps.editMembership),
  isEditingSelf: (ownProps.editMembership || false)
    && ownProps.userMembership.id === ownProps.editMembership.id,
  hasOnlyChannelOrgAdminRole: hasOnlyChannelOrgAdminRoles(ownProps.userMembership.roles),
});

export default compose<React.FC<OwnProps>>(
  connect(mapStateToProps),
  reduxForm<MembershipFormValues, OwnProps>({
    form: MEMBERSHIP_FORM,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    onSubmit: handleSubmit,
  }),
)(MembershipForm);
