import ActionTypes from '../constants/ActionTypes';
import { apiInternal } from '../../../utilities/clients';

export default function fetchGlobalResults(organization, searchText) {
  const params = { q: searchText };
  return {
    types: [
      ActionTypes.FETCH_GLOBAL_RESULTS_REQUEST,
      ActionTypes.FETCH_GLOBAL_RESULTS_SUCCESS,
      ActionTypes.FETCH_GLOBAL_RESULTS_FAILURE,
    ],
    callAPI: (state) => apiInternal.globalSearchResults(state).get(organization, { params }),
  };
}
