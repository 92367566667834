import { RootActionTypes } from '../../../stores/types';
import DialogName from '../constants/DialogName';
import updateDialog from './updateDialog';

const hideAddFacebookCountryFeedDialog = (
): RootActionTypes => updateDialog(
  DialogName.FB_COUNTRY_FEED_DIALOG, {
    open: false,
  },
);

export default hideAddFacebookCountryFeedDialog;
