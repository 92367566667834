import { Field } from 'redux-form';
import React from 'react';
import curry from 'lodash/curry';
import get from 'lodash/get';
import find from 'lodash/find';
import map from 'lodash/map';

import * as styles from './AddRuleWorksheetForm.styles';
import { RuleConditionOperatorProps } from '../../types/components';
import { SelectField } from '../../../../components/ReduxFormFieldAdapters';

const labels = {
  '>': 'Is Greater Than',
  '>=': 'Is Greater Than Or Equal To',
  '<': 'Is Less Than',
  '<=': 'Is Less Than Or Equal To',
  ':': 'Is Equal To',
  '!=': 'Is Not Equal To',
  in: 'In',
  'not in': 'Not In',
};

const mapOperatorsToDropdownOptions = curry((operators: string[]) => map(operators, (operator) => ({
  label: get(labels, operator, operator),
  value: operator,
})));

const formatValue = curry((operators: string[], value: string) => {
  const options = mapOperatorsToDropdownOptions(operators);
  return find(options, { value });
});

const parseValue = curry((value) => get(value, 'value'));

const RuleConditionOperator: React.FC<RuleConditionOperatorProps> = ({
  availableFilter: {
    operators,
  },
  name,
}) => (
  <Field
    component={SelectField}
    name={name}
    format={formatValue(operators)}
    parse={parseValue}
    className={styles.ruleConditionOperator}
    selection
    options={mapOperatorsToDropdownOptions(operators)}
    labelKey="label"
    valueKey="value"
  />
);

RuleConditionOperator.displayName = 'RuleConditionOperator';

export default RuleConditionOperator;
