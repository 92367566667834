import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { withFetch } from '@flowio/redux-fetch';
import { acceptInvitation, getOrganizationInvitation, verifyInvitationUser } from '../../../actions';
import { getDetailedUser } from '../../../../user/actions';
import OrganizationInvitations from '../components/organization-invitations';
import { RootState, ThunkDispatcher } from '../../../../../stores/types';
import { OwnProps, StateProps, DispatchProps } from '../types';
import { LegacyResponse } from '../../../../../utilities/clients/types/server';
import { FormattedErrorMessages } from '../../../../../utilities/format-error-response-v2';

function getAsyncState(
  dispatch: ThunkDispatcher,
  getState: () => RootState,
  ownProps: OwnProps,
): Promise<unknown> {
  const state = getState();

  // When user is already logged in, we want to verify whether the logged in
  // user is the one being invited to join the organization. This detail will
  // toggle the view rendered.
  if (state.defaultReducer.isLoggedIn && state.defaultReducer.user) {
    return Promise.all([
      dispatch(getDetailedUser(state.defaultReducer.user.id)),
      dispatch(getOrganizationInvitation(ownProps.params.token)),
    ]).then(() => dispatch(verifyInvitationUser()));
  }

  return dispatch(getOrganizationInvitation(ownProps.params.token));
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = (
  { account, defaultReducer }: RootState,
) => ({
  error: account.error.invitation,
  invitation: account.invitation,
  isLoggedIn: defaultReducer.isLoggedIn,
  invitationUserVerified: account.invitationUserVerified,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch: ThunkDispatcher,
  { params }: OwnProps,
) => ({
  onRequestJoinOrganization: ():
  Promise<
  LegacyResponse<io.flow.v0.models.Membership | FormattedErrorMessages>
  > => dispatch(acceptInvitation(params.token)),
});

export default withFetch(getAsyncState)(
  connect<StateProps, DispatchProps, OwnProps, RootState>(
    mapStateToProps,
    mapDispatchToProps,
  )(OrganizationInvitations),
);
