import {
  ShippingLabel, Invoice, PuffLoader,
} from '@flowio/react-icons';
import React, { useEffect } from 'react';
import get from 'lodash/get';
import { Banner, BannerText } from '@flowio/react-banner';
import { Box, BoxItem } from '@flowio/react-box';
import {
  Table,
  TableHead,
  TableRow, TableRowColumn,
  TableHeadColumn,
  TableBody,
} from '@flowio/react-table';
import { OutlineButton } from '@flowio/react-button';

import {
  Card, CardHeader, CardContent, CardTitle,
} from '@flowio/react-card';
import { css } from '@emotion/react';
import { isEmpty, isNil, isNotNil } from '@flowio/is';
import { useSelector } from 'react-redux';
import kebabCase from 'lodash/kebabCase';
import * as styles from './order-tracking-details-dialog.styles';
import { colors } from '../../../../theme/tokens';
import { getShippingLabels, getShippingLabelErrors } from '../../selectors';
import useThunkDispatcher from '../../../../hooks/useThunkDispatcher';
import fetchShippingLabel from '../../actions/fetchShippingLabel';
import LabelDetails from './LabelDetails';
import { type CustomerServiceUiState } from '../../reducers/ui';
import NotificationDetails from './NotificationDetails';

interface OrderTrackingDetailsDialogProps {
  organizationId: string;
  fullItems?: io.flow.v0.models.LocalizedLineItem[];
  orderTrackingDetails?: CustomerServiceUiState['orderTrackingDetails'];
}

function getRequiredLabel(
  pdfLabel?: io.flow.label.v0.models.ShippingLabelDocument,
  pdfInvoice?: io.flow.label.v0.models.ShippingLabelDocument,
): string {
  const requiredLabelParts: string[] = [];

  if (pdfInvoice?.required === false) {
    requiredLabelParts.push('Commercial Invoice');
  }
  if (pdfLabel?.required === false) {
    requiredLabelParts.push('Shipping Label');
  }

  return `${requiredLabelParts.join(' and ')} ${requiredLabelParts.length > 1 ? 'have' : 'has'} been transmitted electronically. ${requiredLabelParts.length > 1 ? 'Printed copies are' : 'A printed copy is'} not required for shipment.`;
}

const requiredBanner = css({
  backgroundColor: '#e6b137',
  borderRadius: '4px',
  marginBottom: '16px',
});

const requiredBannerText = css({
  color: '#17181a',
});

const OrderTrackingDetailsDialog: React.FC<OrderTrackingDetailsDialogProps> = ({
  organizationId,
  orderTrackingDetails,
  fullItems = [],
}) => {
  const fullShippingLabels = useSelector(getShippingLabels);
  const shippingLabelsErrors = useSelector(getShippingLabelErrors);
  const dispatch = useThunkDispatcher();
  const detailType = isNotNil(orderTrackingDetails?.labelSummary) ? 'label' : 'notification';

  useEffect(() => {
    const labelSummary = orderTrackingDetails?.labelSummary;
    if (detailType === 'label' && isNotNil(labelSummary) && isNil(fullShippingLabels[labelSummary.id])) {
      dispatch(fetchShippingLabel({
        organizationId,
        labelId: labelSummary?.id,
      }));
    }
  }, [detailType, dispatch, fullShippingLabels, orderTrackingDetails, organizationId]);

  if (isNil(orderTrackingDetails)) {
    return null;
  }

  if (detailType === 'label' && isNil(orderTrackingDetails.labelSummary)) {
    return <><PuffLoader height={32} /></>;
  }

  const label = orderTrackingDetails.labelSummary?.label;
  const invoice = orderTrackingDetails.labelSummary?.invoice;
  const items = (
    detailType === 'label'
      ? orderTrackingDetails.labelSummary?.package?.items
      : orderTrackingDetails.shippingNotification?.package?.items
  ) || [];
  const fullLabel = detailType === 'label' ? fullShippingLabels[orderTrackingDetails.labelSummary!.id] : undefined;

  if (items.length === 0 && isNil(label) && isNil(invoice)) {
    return (<div>No shipment details to view</div>);
  }

  return (
    <div>
      {(isNotNil(label?.pdf) || isNotNil(invoice?.pdf)) && (
      <>
        {(!label?.required || !invoice?.required) && (
          <Banner css={requiredBanner} intent="warning">
            <BannerText css={requiredBannerText}>
              {getRequiredLabel(label, invoice)}
            </BannerText>
          </Banner>
        )}
        <Card>
          <CardHeader dividing>
            <CardTitle content="Label & Invoice" />
          </CardHeader>
          <CardContent>
            <Box alignContent="center" justifyContent="around" className={styles.labelContainer}>
              {isNotNil(label) && isNotNil(label.pdf) && (
                <BoxItem>
                  <Box spacing="extraLoose">
                    <BoxItem className={styles.iconWrapper}>
                      <ShippingLabel fill={colors['flow-blue'][400]} width={52} />
                    </BoxItem>
                    <BoxItem className={styles.labelInfoContainer}>
                      <span>Shipping Label(s)</span>
                      <div className={styles.downloadBtnContainer}>
                        <a href={label.pdf} target="_blank" rel="noopener noreferrer">
                          <OutlineButton intent="primary" content="Download" size="small" />
                        </a>
                      </div>
                    </BoxItem>
                  </Box>
                </BoxItem>
              )}
              {isNotNil(invoice) && isNotNil(invoice.pdf) && (
                <BoxItem>
                  <Box spacing="extraLoose">
                    <BoxItem className={styles.iconWrapper}>
                      <Invoice fill={colors['flow-blue'][400]} width={52} />
                    </BoxItem>
                    <BoxItem className={styles.labelInfoContainer}>
                      <span>Commercial Invoice</span>
                      <div className={styles.downloadBtnContainer}>
                        <a href={invoice.pdf} target="_blank" rel="noopener noreferrer">
                          <OutlineButton intent="primary" content="Download" size="small" />
                        </a>
                      </div>
                    </BoxItem>
                  </Box>
                </BoxItem>
              )}
            </Box>
          </CardContent>
        </Card>
      </>
      )}
      <Card>
        <CardHeader dividing>
          <CardTitle
            style={{ display: 'inline-block', paddingRight: '0.5rem' }}
            content={detailType === 'label' ? 'Label Information' : 'Information'}
          />
        </CardHeader>
        <CardContent>
          {!isEmpty(shippingLabelsErrors) && shippingLabelsErrors.messages
            && shippingLabelsErrors.messages.map(({ message }) => (
              <p key={kebabCase(message)} className="error-text">
                Could not load label information. Error: {message}
              </p>
            ))}
          {detailType === 'notification' && (
            <NotificationDetails notification={orderTrackingDetails.shippingNotification!} />
          )}
          {detailType === 'label' && isEmpty(shippingLabelsErrors) && (isNil(fullLabel) ? (
            <PuffLoader height={32} />
          ) : (
            <LabelDetails label={fullLabel} />
          ))}

        </CardContent>
      </Card>
      {items && !isEmpty(items) && (
      <Card>
        <CardHeader dividing>
          <CardTitle style={{ display: 'inline-block', paddingRight: '0.5rem' }} content="Items" />
        </CardHeader>
        <CardContent>
          <Table striped displayDensity="compact">
            <TableHead>
              <TableRow>
                <TableHeadColumn>Item #</TableHeadColumn>
                <TableHeadColumn>Item Name</TableHeadColumn>
                <TableHeadColumn>Qty</TableHeadColumn>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                <TableRow key={item.number} data-number={item.number}>
                  <TableRowColumn>
                    {item.number}
                  </TableRowColumn>
                  <TableRowColumn>
                    {get(fullItems.find((fullItem) => fullItem.number === item.number), 'name')}
                  </TableRowColumn>
                  <TableRowColumn>
                    {item.quantity}
                  </TableRowColumn>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
      )}
    </div>
  );
};

OrderTrackingDetailsDialog.displayName = 'OrderTrackingDetailsDialog';

export default OrderTrackingDetailsDialog;
