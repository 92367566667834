import React from 'react';
import { css } from '@emotion/react';
import { Box, BoxItem } from '@flowio/react-box';
import { Button } from '@flowio/react-button';
import { Image } from '@flowio/react-image';
import { Plus } from '@flowio/react-icons';
import { Props } from '../types';
import MarketingDialog from '../../MarketingDialog';
import Platforms from '../../../constants/Platforms';
import spacing from '../../../../../theme/tokens/spacing';
import { colors, fontWeight, radius } from '../../../../../theme/tokens';

const MAX_ITEMS = 5;

const ManualChannelsDialog: React.FC<Props> = ({
  isOpen,
  onClose,
  channels = [],
}) => {
  if (!isOpen || !channels.length) {
    return null;
  }

  const visibleChannels = channels.splice(0, MAX_ITEMS);

  const graphicStyles = css({
    marginTop: visibleChannels.length < MAX_ITEMS ? 0 : spacing.marketing,
    height: '240px',
    color: colors.marketing['dark-slate-blue'],
    zIndex: 100,
  });

  const plusIconStyles = css({
    marginRight: spacing.xl,
    path: {
      stroke: colors.marketing['dark-slate-blue'],
      strokeWidth: '2px',
    },
  });

  const channelDetailsStyles = css({
    width: 288,
    backgroundColor: colors.white,
    borderRadius: radius.large,
    margin: spacing.s,
    padding: `${spacing.s} ${spacing.xl}`,
  });

  const platformNameStyles = css({
    marginLeft: spacing.s,
    fontWeight: fontWeight.medium,
  });

  const topContent = (
    <Box css={graphicStyles} direction="column" alignContent="center" justifyContent="center">
      {visibleChannels.map((channel) => (
        <Box alignItems="center">
          <Plus css={plusIconStyles} height={20} />
          <Box css={channelDetailsStyles} alignItems="center">
            <BoxItem><Image accessibilityLabel={`${channel} logo`} source={`https://cdn.flow.io/console/logo_${channel}.svg`} width={32} /></BoxItem>
            <BoxItem css={platformNameStyles}>{Platforms(channel).platformName}</BoxItem>
          </Box>
        </Box>
      ))}
    </Box>
  );

  return (
    <MarketingDialog
      title="Start adding channel-specific localized feeds!"
      subtitle="You have successfully imported your product data from your Google Merchant Center account to Flow. Now start adding localized feeds to your marketing channels!"
      isOpen={isOpen}
      topContent={topContent}
    >
      <Button
        intent="primary"
        onClick={onClose}
        content="Got It"
      />
    </MarketingDialog>
  );
};

ManualChannelsDialog.displayName = 'ManualChannelsDialog';

export default ManualChannelsDialog;
