import isEqual from 'lodash/isEqual';
import { OrderRevenueRegionChartsGetResponse } from '@flowio/api-internal-sdk';
import ActionTypes from '../constants/action-types';
import ReadyStates from '../constants/ready-states';
import getGmvByCountryParams from '../selectors/get-gmv-by-country-params';
import getGmvByCountryReadyState from '../selectors/get-gmv-by-country-ready-state';

import { ThunkDispatcher, RootState, ThunkExtraArguments } from '../../../stores/types';
import { PropType } from '../../../types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';
import { FetchCountryRevenueParams } from '../types';

/**
 * Creates an action for fetching the Gross Merchandise Value (GMV) grouped by country for an
 * organization over a period of time.
 * @param {String} organization - An organization unique identifier.
 * @param {String} region - A region for fetching GMV data (e.g. "world").
 * @param {String} startDate - An ISO 8601 formatted date time string used as the start date for
 * fetching GMV data (e.g. "2017-07-11T04:00:00.000Z").
 * @param {String} endDate - An ISO 8601 formatted date time string used as the end date for
 * fetching GMV data (e.g. "2017-07-18T03:59:59.999Z").
 */
export default function fetchCountryRevenue(
  organization: string,
  region: string,
  startDate: string,
  endDate: string,
) {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<PropType<OrderRevenueRegionChartsGetResponse, 'body'> | void> => {
    const state = getState();

    const params: FetchCountryRevenueParams = {
      organization, region, startDate, endDate,
    };

    if (isEqual({
      readyState: getGmvByCountryReadyState(state),
      params: getGmvByCountryParams(state),
    }, {
      readyState: ReadyStates.FULFILLED,
      params,
    })) {
      return Promise.resolve();
    }

    dispatch({
      type: ActionTypes.FETCH_COUNTRY_REVENUE_REQUEST,
      params,
    });

    return extra.apiInternal(state).orderRevenueRegionCharts.get({
      organization,
      regions: [region],
      on_or_after: startDate,
      on_or_before: endDate,
    }).then((response) => {
      if (response.ok) {
        dispatch({
          type: ActionTypes.FETCH_COUNTRY_REVENUE_SUCCESS,
          payload: response.body,
          params,
        });
        return response.body;
      }

      dispatch({
        type: ActionTypes.FETCH_COUNTRY_REVENUE_FAILURE,
        payload: formatErrorResponseV2(response),
      });
      return response.body;
    });
  };
}
