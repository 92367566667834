import { css } from '@emotion/react';
import { css as cssClass } from '@emotion/css';
import { colors } from '../../../theme/tokens';

const concrete = '#f2f5f7';

const marketingTableStyle = (fontSize = 12) => css`
  font-size: ${fontSize}px;
  color: ${colors.marketing['dark-slate-blue']};

  tr {
    th, td {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  thead {
    font-weight: 500;
    background-color: ${concrete};

    th {
      color: ${colors.marketing['dark-slate-blue']};
    }
  }

  tbody {
    tr {
      line-height: 28px;
      border-bottom: 1px solid ${concrete};
    }
  }
`;

export const boxShadow = (value: string) => css`
  -webkit-box-shadow: ${value};
  -moz-box-shadow: ${value};
  box-shadow: ${value};
`;

export const marketingBannerStyles = cssClass`
    color: ${colors.white};
    margin-top: 30px;
    text-align: center;
    border-radius: 5px;
    padding: 20px;

    &.padBottom {
      margin-top: 0;
      margin-bottom: 30px;
    }

    &.neutral {
      background-color: ${colors.chart.blue} !important;
    }

    &.negative {
      background-color: rgba(244, 8, 8, 0.7) !important;
    }
`;

export default marketingTableStyle;
