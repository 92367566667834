import { PuffLoader } from '@flowio/react-icons';

import { Banner } from '@flowio/react-banner';
import { Button } from '@flowio/react-button';
import React from 'react';
import {
  Worksheet,
  WorksheetHeader,
  WorksheetBody,
  WorksheetTitle,
} from '@flowio/react-worksheet';
import isEmpty from 'lodash/isEmpty';
import ReturnPolicyForm from '../../containers/ReturnPolicyForm';

import * as styles from './ReturnPolicyWorksheet.styles';
import FormName from '../../constants/FormName';

type Status = 'pending' | 'loading' | 'fulfilled' | 'rejected';

interface ReturnPolicyWorksheetProps {
  availableFilters?: io.flow.v0.models.AvailableFilterStructured[];
  initialValues?: {
    returnPolicyId?: string;
    filters?: io.flow.v0.unions.QueryFilter[];
    organizationId?: string;
  }
  submissionApiError?: io.flow.v0.models.GenericError;
  open?: boolean;
  onRequestCancel: (...args: any) => void;
  onRequestSave: (...args: any) => void;
  onSubmitSuccess: (...args: any) => void;
  status: Status;
  submitting?: boolean;
}

const ReturnPolicyWorksheet: React.FC<ReturnPolicyWorksheetProps> = ({
  availableFilters = [],
  initialValues = {},
  onRequestCancel,
  onRequestSave,
  onSubmitSuccess,
  open = false,
  status,
  submitting = false,
  submissionApiError,
  ...unhandledProps
}) => (
  <Worksheet {...unhandledProps} open={open}>
    <WorksheetHeader>
      {(status === 'fulfilled') && (
        <div className={styles.header}>
          <Button
            type="button"
            content="Cancel"
            disabled={submitting}
            onClick={onRequestCancel}
          />
          <WorksheetTitle content={isEmpty(initialValues.returnPolicyId) ? 'New Non-Returnable Rule' : 'Edit Non-Returnable Rule'} />
          <Button
            type="button"
            disabled={submitting}
            intent="primary"
            content="Save"
            onClick={onRequestSave}
          />
        </div>
      )}
      {(status === 'rejected') && (
        <div className={styles.header}>
          <Button type="button" content="Close" onClick={onRequestCancel} />
        </div>
      )}
    </WorksheetHeader>
    <WorksheetBody>
      {(() => {
        switch (status) {
          case 'fulfilled':
            return (
              <ReturnPolicyForm
                form={FormName.ReturnPolicyForm}
                submissionApiError={submissionApiError}
                availableFilters={availableFilters}
                initialValues={initialValues}
                onSubmitSuccess={onSubmitSuccess}
              />
            );
          case 'rejected':
            return (
              <Banner intent="negative">
                A non-returnable rule cannot be created at this time.
                Please try again later.
                Contact
                <a href="mailto:help@flow.io">help@flow.io</a>
                if this issue persists.
              </Banner>
            );
          default:
            return (
              <div className={styles.loadingContainer}>
                <PuffLoader className={styles.loadingIcon} />
                <div className={styles.loadingText}>
                  Loading Non-Returnable Builder, Please Wait...
                </div>
              </div>
            );
        }
      })()}
    </WorksheetBody>
  </Worksheet>
);

ReturnPolicyWorksheet.displayName = 'ReturnPolicyWorksheet';

ReturnPolicyWorksheet.defaultProps = {
  availableFilters: [],
  initialValues: {},
  open: false,
  submitting: false,
  submissionApiError: undefined,
};

export default ReturnPolicyWorksheet;
