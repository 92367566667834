import { PaymentMethodType } from '@flowio/api-constants';
import ActionTypes from '../constants/action-types';
import { getPaymentMethodsDisplayOrder } from '../selectors';
import { mapRulesWithSequentialDisplayPosition } from '../utilities/payment-method-rules';
import { ThunkDispatcher, RootState } from '../../../stores/types';
import { SetPaymentMethodsDisplayOrderAction } from '../types';

interface UpdateCreditCardOrderDisplayParams {
  cards: io.flow.v0.models.PaymentMethodRule[];
}

export default function updateCreditCardOrderDisplay({
  cards,
}: UpdateCreditCardOrderDisplayParams) {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
  ): SetPaymentMethodsDisplayOrderAction => {
  // get and copy pms array
    const pms: io.flow.v0.models.PaymentMethodRule[] = getPaymentMethodsDisplayOrder(getState());

    // we need to ensure all ccs in the list are together
    const firstCardIndex = pms.findIndex((pm) => pm.payment_method.type === PaymentMethodType.CARD);
    const justPms = pms.filter((pm) => pm.payment_method.type !== PaymentMethodType.CARD);
    justPms.splice(firstCardIndex, 0, ...cards);
    const updatedPms = mapRulesWithSequentialDisplayPosition(justPms);

    return dispatch({
      type: ActionTypes.SET_PAYMENT_METHODS_DISPLAY_ORDER,
      payload: updatedPms,
    });
  };
}
