export const FETCH_API_KEYS_SUBMIT = 'FETCH_API_KEYS_SUBMIT';
export const FETCH_API_KEYS_SUCCESS = 'FETCH_API_KEYS_SUCCESS';
export const FETCH_API_KEYS_FAILURE = 'FETCH_API_KEYS_FAILURE';

export const FETCH_CLEAR_TEXT_KEY_SUBMIT = 'FETCH_CLEAR_TEXT_KEY_SUBMIT';
export const FETCH_CLEAR_TEXT_KEY_SUCCESS = 'FETCH_CLEAR_TEXT_KEY_SUCCESS';

export const CREATE_API_KEY_SUBMIT = 'CREATE_API_KEY_SUBMIT';
export const CREATE_API_KEY_SUCCESS = 'CREATE_API_KEY_SUCCESS';
export const CREATE_API_KEY_FAILURE = 'CREATE_API_KEY_FAILURE';

export const DELETE_API_KEY_SUBMIT = 'DELETE_API_KEY_SUBMIT';
export const DELETE_API_KEY_SUCCESS = 'DELETE_API_KEY_SUCCESS';
