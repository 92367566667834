import { Form, Field } from 'redux-form';
import React from 'react';
import { Stack } from '@flowio/react-stack';
import isEmpty from 'lodash/isEmpty';

import { css } from '@emotion/css';
import GenericError from '../../../../components/GenericError';
import ShippingConfigurationMethod from '../ShippingConfigurationMethod';
import presence from '../../../../utilities/validators/presence';
import { CreateShippingConfigurationFormProps } from '../../types/components';
import { TextField } from '../../../../components/ReduxFormFieldAdapters';

const fieldsetStyles = css`
  padding: 16px;
  border-radius: 4px;
  border: solid 1px #e6e6e6;
  box-sizing: border-box;
`;

const validateName = [
  presence({
    message: 'Enter name',
    allowEmpty: false,
  }),
];

const validateMethod = [
  presence({
    message: 'Choose a method',
    allowEmpty: false,
  }),
];

// NOTE: If there are not shipping configurations available then it doesn't
// make sense to show an option to create or duplicate a shipping configuration.
const CreateShippingConfigurationForm: React.FC<CreateShippingConfigurationFormProps> = ({
  error,
  handleSubmit,
  shippingConfigurations = [],
}) => (
  <Form onSubmit={handleSubmit}>
    <Stack orientation="vertical" spacing="loose">
      {error && (
        <GenericError error={error} />
      )}
      <div className={fieldsetStyles}>
        <Field
          component="input"
          name="organizationId"
          type="hidden"
        />
        <Field
          autoComplete="off"
          component={TextField}
          fluid
          inline
          id="name"
          name="name"
          labelFor="name"
          labelText="Name"
          validate={validateName}
        />
        {!isEmpty(shippingConfigurations) && (
          <Field
            component={ShippingConfigurationMethod}
            name="method"
            shippingConfigurations={shippingConfigurations}
            validate={validateMethod}
          />
        )}
      </div>
    </Stack>
  </Form>
);

CreateShippingConfigurationForm.displayName = 'CreateShippingConfigurationForm';

export default CreateShippingConfigurationForm;
