import { createSelector } from 'reselect';
import property from 'lodash/property';
import getMarketingState from './getMarketingState';
import { RootState } from '../../../stores/types';
import { MarketingState, FeedsCreationConfirmationDialogProps } from '../types';

export default createSelector<RootState, MarketingState, FeedsCreationConfirmationDialogProps>(
  getMarketingState,
  property(['feedsCreationConfirmationDialog', 'data']),
);
