import { RootActionTypes } from '../../../stores/types';
import ActionTypes from '../constants/action-types';

type DebugOrderState = io.flow.internal.v0.models.InternalDebugTransaction[];

const initialState: DebugOrderState = [];

export default function reducer(state = initialState, action: RootActionTypes): DebugOrderState {
  switch (action.type) {
    case ActionTypes.DEBUG_TRANSACTIONS_SUCCESS:
      return [...action.payload];
    default:
      return state;
  }
}
