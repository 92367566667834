import React from 'react';
import { WrappedFieldInputProps, WrappedFieldMetaProps, WrappedFieldProps } from 'redux-form';
import { FormGroupIntent } from '@flowio/react-form-group';
import { TextField, TextFieldProps } from '@flowio/react-text-field';
import defaultShouldError from './utilities/defaultShouldError';
import defaultShouldWarn from './utilities/defaultShouldWarn';

type ReduxFormTextFieldProps = WrappedFieldProps &
{
  shouldError?: (
    input: WrappedFieldInputProps, meta: WrappedFieldMetaProps, props?: any
  ) => boolean,
  shouldWarn?: (input: WrappedFieldInputProps, meta: WrappedFieldMetaProps, props?: any) => boolean
} & TextFieldProps;

const ReduxFormTextField: React.FC<ReduxFormTextFieldProps> = (props) => {
  const {
    input, meta, shouldWarn, shouldError, ...unhandledProps
  } = props;

  const { error, warning } = meta;

  let feedbackText;
  let intent: FormGroupIntent = 'neutral';

  if (error && shouldError && shouldError(input, meta)) {
    feedbackText = error;
    intent = 'negative';
  } else if (warning && shouldWarn && shouldWarn(input, meta)) {
    feedbackText = warning;
    intent = 'warning';
  }

  const handleValueChange = (value: string) => {
    input.onChange(value);
  };

  if (unhandledProps.multiLine) {
    // TextArea does not support "name" prop
    return (
      <TextField
        {...unhandledProps}
        onValueChange={handleValueChange}
        feedback={feedbackText}
        intent={intent}
        onBlur={input.onBlur}
        onFocus={input.onFocus}
        value={input.value}
      />
    );
  }

  return (
    <TextField
      {...unhandledProps}
      onValueChange={handleValueChange}
      feedback={feedbackText}
      intent={intent}
      name={input.name}
      onBlur={input.onBlur}
      onFocus={input.onFocus}
      value={input.value}
    />
  );
};

ReduxFormTextField.defaultProps = {
  shouldError: defaultShouldError,
  shouldWarn: defaultShouldWarn,
};

export default ReduxFormTextField;
