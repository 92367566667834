import { css } from '@emotion/css';

export const submitButton = css`
  display: none;
`;

export const cardContent = css`
  display: flex;
`;

export const labelContainer = css`
  width: 20%;
  padding-right: 8px;
  box-sizing: border-box;
`;

export const labelTitle = css`
  font-size: 14px;
  line-height: 16px;
`;

export const labelSubTitle = css`
  font-size: 12px;
  line-height: 14px;
`;

export const form = css`
  flex-grow: 1;
  align-items: center;
  display: flex;
`;

export const formField = css`
  width: 100%;
`;
