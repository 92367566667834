import { FormattedErrorMessages } from '../../utilities/format-error-response-v2';
import ActionTypes from './constants/ActionType';

interface ErrorPayload {
  payload: FormattedErrorMessages;
}

export interface PaginationMetaData {
  parameters: {
    entriesPerPage: number;
    organizationId: string;
    pageNumber: number;
  }
}
export interface ReturnPolicyFormProps {
  availableFilters: io.flow.v0.models.AvailableFilterStructured[];
  error?: io.flow.v0.models.GenericError;
  submissionApiError?: io.flow.v0.models.GenericError;
}

export interface ReturnPolicyFormValues {
  organizationId: string;
  returnPolicyId?: string;
  filters: any[];
}

export interface QueryFilterListOwnProps {
  availableFilters: io.flow.v0.models.AvailableFilterStructured[];
}

export interface NonReturnableState {
  dialogs: {
    returnPolicyDeleteConfirmation: ReturnPolicyDeleteConfirmationState;
    returnPolicyWorksheet: ReturnPolicyWorksheetState;
  };
  entities: {
    nonReturnable: NonReturnablePaginationState;
    returnPolicyForm: ReturnPolicyFormState;
  };
}
export interface ReturnPolicyPaginationParams {
  entriesPerPage: number;
  pageNumber: number;
  organizationId: string;
}

export enum PageStatus {
  LOADING = 'loading',
  FULFILLED = 'fulfilled',
  REJECTED = 'rejected',
}

export interface NonReturnablePaginationState {
  entities: Record<string, io.flow.v0.models.ReturnPolicy>;
  pagination: {
    currentPage: number;
    pages: Record<number, {
      parameters: ReturnPolicyPaginationParams;
      error?: FormattedErrorMessages;
      isFirstPage: boolean;
      isLastPage: boolean;
      entities: string[];
      status: PageStatus;
    }>
  }
}

export interface ReturnPolicyWorksheetState {
  error?: io.flow.v0.models.GenericError;
  returnPolicy?: io.flow.v0.models.ReturnPolicy;
  queryBuilder?: io.flow.v0.models.QueryBuilder;
  status: ReturnPolicyStatus;
  visibility: string;
}

export interface ReturnPolicyFormState {
  submissionError?: io.flow.v0.models.GenericError;
}

export interface ReturnPolicyDeleteConfirmationState {
  deleting: boolean;
  deleteFailed: boolean;
  deleteSucceeded: boolean;
  returnPolicyToBeDeleted?: io.flow.v0.models.ReturnPolicy;
  organizationId?: string;
  visibility: string;
}

export type ReturnPolicyStatus = 'pending' | 'loading' | 'fulfilled' | 'rejected';

export interface ReturnPolicyWorksheetValues {
  returnPolicyId?: string;
  filters?: io.flow.v0.unions.QueryFilter[];
  organizationId?: string;
}

export interface ReturnPolicyWorksheetStateProps {
  availableFilters?: io.flow.v0.models.AvailableFilterStructured[];
  initialValues?: ReturnPolicyWorksheetValues;
  open?: boolean;
  status: ReturnPolicyStatus;
  submissionApiError?: io.flow.v0.models.GenericError;
  submitting?: boolean;
}

export interface ReturnPolicyWorksheetDispatchProps {
  onRequestCancel: (...args: any) => void;
  onRequestSave: (...args: any) => void;
  onSubmitSuccess: (...args: any) => void;
}

export type ReturnPolicyWorksheetProps =
  ReturnPolicyWorksheetStateProps &
  ReturnPolicyWorksheetDispatchProps;

export interface NonReturnableIndexStateProps {
  entriesPerPage: number;
  returnPolicies: io.flow.v0.models.ReturnPolicy[];
  isFirstPage: boolean;
  isLastPage: boolean;
  organizationId: string;
  pageNumber: number;
}

export interface NonReturnableIndexDispatchProps {
  onAddReturnPolicy: (...args: any) => void;
  onEditReturnPolicy: (...args: any) => void;
  onPageChange: (...args: any) => void;
  onRemoveReturnPolicy: (...args: any) => void;
}

export type NonReturnableIndexProps =
  NonReturnableIndexStateProps &
  NonReturnableIndexDispatchProps;

export interface ReturnPolicyDeleteConfirmationStateProps {
  deleting: boolean;
  deleteFailed: boolean;
  open: boolean;
  organizationId?: string;
  returnPolicy?: io.flow.v0.models.ReturnPolicy;
}

export interface ReturnPolicyDeleteConfirmationDispatchProps {
  onCancel: () => void;
  onOk: (organizationId: string, returnPolicyId: string) => void;
}

export type ReturnPolicyDeleteConfirmationProps =
  ReturnPolicyDeleteConfirmationStateProps &
  ReturnPolicyDeleteConfirmationDispatchProps;

export interface ShowReturnPolicyWorksheetRequest {
  type: ActionTypes.ShowReturnPolicyWorksheetRequest;
  payload: {
    organizationId: string;
    returnPolicy: io.flow.v0.models.ReturnPolicy;
  },
}

export interface ShowReturnPolicyWorksheetSuccess {
  type: ActionTypes.ShowReturnPolicyWorksheetSuccess;
  payload: {
    returnPolicy: io.flow.v0.models.ReturnPolicy;
    queryBuilder: io.flow.v0.models.QueryBuilder;
  }
}

export interface ShowReturnPolicyWorksheetFailure {
  type: ActionTypes.ShowReturnPolicyWorksheetFailure;
  payload: any;
}

export interface FetchReturnPoliciesRequest {
  type: ActionTypes.FetchReturnPoliciesRequest;
  meta: PaginationMetaData,
}

export interface FetchReturnPoliciesSuccess {
  type: ActionTypes.FetchReturnPoliciesSuccess;
  payload: io.flow.v0.models.ReturnPolicy[];
  meta: PaginationMetaData,
}

export interface FetchReturnPoliciesFailure extends ErrorPayload {
  type: ActionTypes.FetchReturnPoliciesFailure;
  meta: PaginationMetaData,
}

export interface SubmitReturnPolicyForm {
  type: ActionTypes.SubmitReturnPolicyForm;
}

export interface SubmitReturnPolicyFormError {
  type: ActionTypes.SubmitReturnPolicyFormError;
  error: boolean;
  payload: any;
}

export interface DeleteReturnPolicyRequest {
  type: ActionTypes.DeleteReturnPolicyRequest;
  meta: {
    organizationId: string;
    returnPolicyId: string;
  },
}

export interface DeleteReturnPolicySuccess {
  type: ActionTypes.DeleteReturnPolicySuccess;
  payload: any;
  meta: {
    organizationId: string;
    returnPolicyId: string;
  },
}

export interface DeleteReturnPolicyFailure extends ErrorPayload {
  type: ActionTypes.DeleteReturnPolicyFailure;
  meta: {
    organizationId: string;
    returnPolicyId: string;
  },
}

export interface UpdateReturnPolicyRequest {
  type: ActionTypes.UpdateReturnPolicyRequest;
  meta: {
    organizationId: string;
    returnPolicyId: string;
    returnPolicyForm: io.flow.v0.models.ReturnPolicyForm;
  },
}

export interface UpdateReturnPolicySuccess {
  type: ActionTypes.UpdateReturnPolicySuccess;
  payload: io.flow.v0.models.ReturnPolicy;
  meta: {
    organizationId: string;
    returnPolicyId: string;
    returnPolicyForm: io.flow.v0.models.ReturnPolicyForm;
  },
}

export interface UpdateReturnPolicyFailure extends ErrorPayload {
  type: ActionTypes.UpdateReturnPolicyFailure;
  meta: {
    organizationId: string;
    returnPolicyId: string;
    returnPolicyForm: io.flow.v0.models.ReturnPolicyForm;
  },
}

export interface CreateReturnPolicyRequest {
  type: ActionTypes.CreateReturnPolicyRequest;
  meta: {
    organizationId: string;
    returnPolicyForm: io.flow.v0.models.ReturnPolicyForm;
  },
}

export interface CreateReturnPolicySuccess {
  type: ActionTypes.CreateReturnPolicySuccess;
  payload: io.flow.v0.models.ReturnPolicy;
  meta: {
    organizationId: string;
    returnPolicyForm: io.flow.v0.models.ReturnPolicyForm;
  },
}

export interface CreateReturnPolicyFailure extends ErrorPayload {
  type: ActionTypes.CreateReturnPolicyFailure;
  meta: {
    organizationId: string;
    returnPolicyForm: io.flow.v0.models.ReturnPolicyForm;
  },
}

export interface HideReturnPolicyWorksheet {
  type: ActionTypes.HideReturnPolicyWorksheet;
}

export interface HideReturnPolicyDeleteConfirmation {
  type: ActionTypes.HideReturnPolicyDeleteConfirmation;
}

export interface ShowReturnPolicyDeleteConfirmation {
  type: ActionTypes.ShowReturnPolicyDeleteConfirmation;
  payload: {
    organizationId: string;
    returnPolicy: io.flow.v0.models.ReturnPolicy;
  }
}

export type NonReturnableActions =
  SubmitReturnPolicyForm |
  SubmitReturnPolicyFormError |
  ShowReturnPolicyWorksheetRequest |
  ShowReturnPolicyWorksheetSuccess |
  ShowReturnPolicyWorksheetFailure |
  ShowReturnPolicyDeleteConfirmation |
  HideReturnPolicyDeleteConfirmation |
  HideReturnPolicyWorksheet |
  FetchReturnPoliciesRequest |
  FetchReturnPoliciesSuccess |
  FetchReturnPoliciesFailure |
  UpdateReturnPolicyRequest |
  UpdateReturnPolicySuccess |
  UpdateReturnPolicyFailure |
  DeleteReturnPolicyRequest |
  DeleteReturnPolicySuccess |
  DeleteReturnPolicyFailure |
  CreateReturnPolicyRequest |
  CreateReturnPolicySuccess |
  CreateReturnPolicyFailure;
