import ActionTypes from '../constants/action-types';
import { RootActionTypes } from '../../../stores/types';

export default function setArchiveExperience(
  experience: io.flow.v0.models.Experience,
): RootActionTypes {
  return {
    type: ActionTypes.SET_ARCHIVE_EXPERIENCE,
    payload: experience,
  };
}
