import React from 'react';
import kebabCase from 'lodash/kebabCase';

import ErrorIcon from './error-icon';
import ErrorMessage from './error-message';
import { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';

if (process.browser) {
  require('./errors.css'); // eslint-disable-line global-require
}

interface Props {
  errors?: FormattedErrorMessages['messages'];
}

const Errors: React.FC<Props> = ({ errors }) => {
  if (!errors || !errors.length) {
    return null;
  }

  const errorMessages = errors
    .filter((e) => !e.field) // ignore if targeted to specific field.
    .map((error) => <ErrorMessage message={error.message} key={kebabCase(error.message)} />);

  if (!errorMessages.length) {
    return null;
  }

  return (
    <div className="relative notification-error">
      <ErrorIcon />
      {errorMessages}
    </div>
  );
};

Errors.defaultProps = {
  errors: [],
};

export default Errors;
