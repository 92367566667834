import uniqueId from 'lodash/uniqueId';
import ActionTypes from '../constants/action-types';

import {
  toQueryBuilderQueryForm,
} from '../utilities';
import showDialog from './showDialog';
import DialogName from '../constants/DialogName';
import { getCurrentFeature } from '../selectors';
import { ThunkResult } from '../../../stores/types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';

export default function showEditRuleWorksheet(
  organizationId: string,
  rule: io.flow.internal.v0.unions.FeatureRule,
): ThunkResult<Promise<void>> {
  return function showEditRuleWorksheetEffects(dispatch, getState, extra) {
    const state = getState();
    const currentFeature = getCurrentFeature(state);

    dispatch({
      type: ActionTypes.SHOW_EDIT_RULE_WORKSHEET_REQUEST,
    });

    return extra.apiInternal(state).features.postRuleAndQueryAndBuilders(
      {
        organization: organizationId,
        body: toQueryBuilderQueryForm(rule.query),
      },
    ).then((response) => {
      if (response.ok) {
        dispatch({
          type: ActionTypes.SHOW_EDIT_RULE_WORKSHEET_SUCCESS,
          payload: response.body,
        });

        dispatch(showDialog(DialogName.ADD_RULE_WORKSHEET)({
          initialValues: {
            organizationId,
            key: currentFeature.key,
            type: currentFeature.type,
            value: String(rule.value),
            id: rule.id,
            conditions: response.body.filters,
          },
          queryBuilder: {
            ...response.body,
            filters: response.body.filters.map((f) => ({
              ...f, //
              // I straight stole this from Exclusion Rules:
              //
              // Add a unique identifier that we can use as the component key for
              // FLIP animation to work correctly.
              // https://github.com/erikras/redux-form/issues/2735
              uniqueId: uniqueId('filter'),
            })),
          },
        }));
        return;
      }

      const errors = formatErrorResponseV2(response);
      dispatch({
        type: ActionTypes.SHOW_EDIT_RULE_WORKSHEET_FAILURE,
        payload: errors,
      });
    });
  };
}
