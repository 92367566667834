import { Field } from 'redux-form';
import { Close } from '@flowio/react-icons';
import { IconButton } from '@flowio/react-button';
import React from 'react';
import classnames from 'classnames/bind';
import includes from 'lodash/includes';
import noop from 'lodash/noop';
import QueryFilterField from './QueryFilterField';

import QueryFilterOperator from './QueryFilterOperator';
import QueryFilterValues from './QueryFilterValues';
import styles from './QueryFilter.module.css';

const cx = classnames.bind(styles);

interface QueryFilterProps {
  availableFilters: io.flow.v0.models.AvailableFilterStructured[];
  name: string;
  selectedField?: string;
  selectedOperator?: string;
  onRequestRemove?: (...args: any) => void;
}

const QueryFilter: React.FC<QueryFilterProps> = ({
  availableFilters,
  name,
  onRequestRemove,
  selectedField,
  selectedOperator,
}) => {
  const selectedFilter = QueryFilterField.formatValue(availableFilters, selectedField);
  const multiple = includes(['in', 'not in'], selectedOperator);

  return (
    <div className={cx('queryFilter')}>
      <div className={cx('queryFilterFieldset')}>
        <Field component="input" type="hidden" name="discriminator" />
        <QueryFilterField
          name={`${name}.field`}
          availableFilters={availableFilters}
        />
        {selectedFilter && (
          <QueryFilterOperator
            name={`${name}.operator`}
            availableFilter={selectedFilter}
          />
        )}
        {selectedFilter && selectedOperator && (
          <QueryFilterValues
            name={`${name}.values`}
            availableFilter={selectedFilter}
            multiple={multiple}
          />
        )}
      </div>
      <IconButton
        className={cx('queryFilterRemoveButton')}
        icon={Close}
        onClick={onRequestRemove}
        size="small"
        type="button"
      />
    </div>
  );
};

QueryFilter.displayName = 'QueryFilter';

QueryFilter.defaultProps = {
  onRequestRemove: noop,
};

export default QueryFilter;
