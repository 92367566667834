import ActionTypes from '../constants/action-types';
import { RootActionTypes } from '../../../stores/types';
import { ReplacementOrderState } from '../types';

const initialState: ReplacementOrderState = {
  loading: true,
  errors: undefined,
  data: undefined,
};

export default function reducer(
  state = initialState,
  action: RootActionTypes,
): ReplacementOrderState {
  switch (action.type) {
    case ActionTypes.FETCH_REPLACEMENT_ORDER_BUILDER_REQUEST:
      return {
        loading: true,
        errors: undefined,
        data: undefined,
      };
    case ActionTypes.FETCH_REPLACEMENT_ORDER_BUILDER_FAILURE:
      return {
        loading: false,
        errors: action.payload,
        data: undefined,
      };
    case ActionTypes.FETCH_REPLACEMENT_ORDER_BUILDER_SUCCESS:
      return {
        loading: false,
        errors: undefined,
        data: action.payload,
      };
    default:
      return state;
  }
}
