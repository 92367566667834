import { Close } from '@flowio/react-icons';
import React from 'react';
import BemHelper from '@flowio/bem-helper';
import { Button } from '@flowio/react-button';

if (process.browser) {
  require('./alert.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('alert');

type Props = {
  type?: 'info' | 'warning' | 'failure' | 'success';
  fullWidth?: boolean;
  className?: string;
  dismissible?: boolean;
  onRequestDismiss?: () => void;
};

/**
 * An Alert component provides a way for displaying short messages.
 */
const Alert: React.FC<Props> = ({
  type = 'info',
  fullWidth,
  className,
  children,
  dismissible,
  onRequestDismiss,
  ...unhandledProps
}) => {
  const modifiers = {
    failure: type === 'failure',
    'full-width': fullWidth,
    info: type === 'info',
    success: type === 'success',
    warning: type === 'warning',
  };

  return (
    <div {...unhandledProps} className={bem.block(modifiers, className)} role="alert">
      <div className={bem.element('text')}>
        {children}
      </div>
      {dismissible && (
        <div className={bem.element('actions')}>
          <Button
            className={bem.element('close')}
            leftIcon={Close}
            variant="flat"
            onClick={onRequestDismiss}
            size="small"
          />
        </div>
      )}
    </div>
  );
};
Alert.displayName = 'Alert';

export default Alert;
