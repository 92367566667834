import { LaneDirection } from '@flowio/api-constants';
import {
  Card, CardHeader, CardContent, CardFooter, CardEmptyState,
} from '@flowio/react-card';
import { Plus } from '@flowio/react-icons';
import { NonIdealState } from '@flowio/react-non-ideal-state';
import React from 'react';
import isEmpty from 'lodash/isEmpty';

import ShippingLaneList from '../ShippingLaneList';
import { ShippingLaneListCardProps } from '../../types/components';

const ShippingLaneListCard: React.FC<ShippingLaneListCardProps> = ({
  onAddShippingLane,
  onViewShippingLane,
  onUpdateShippingLanePage,
  organizationId,
  regions,
  shippingConfigurationKey,
  paginatedShippingLanes,
  shippingLaneDirection,
}) => {
  const actions = [{
    content: shippingLaneDirection === LaneDirection.OUTBOUND ? 'Add Outbound Shipping Lane' : 'Add Return Shipping Lane',
    intent: 'primary',
    leftIcon: Plus,
    onClick: () => onAddShippingLane(
      organizationId,
      shippingConfigurationKey,
      shippingLaneDirection,
    ),
    variant: 'outline',
  }];

  return (
    <Card>
      <CardHeader
        actions={actions}
        caption={shippingLaneDirection === LaneDirection.OUTBOUND ? 'Outbound Shipping Lanes' : 'Return Shipping Lanes'}
        dividing
      />
      <CardContent fitted>
        {isEmpty(paginatedShippingLanes.shippingLanes) ? (
          <CardEmptyState>
            <NonIdealState
              heading="No Shipping Lanes"
              description={'Click "Add Shipping Lane" button to add a shipping lane.'}
            />
          </CardEmptyState>
        ) : (
          <ShippingLaneList
            onUpdateShippingLanePage={onUpdateShippingLanePage}
            onSelection={(shippingLane) => (
              onViewShippingLane(organizationId, shippingConfigurationKey, shippingLane)
            )}
            regions={regions}
            paginatedShippingLanes={paginatedShippingLanes}
            shippingLaneDirection={shippingLaneDirection}
          />
        )}
      </CardContent>
      <CardFooter />
    </Card>
  );
};

ShippingLaneListCard.displayName = 'ShippingLaneListCard';

export default ShippingLaneListCard;
