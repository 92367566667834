import React from 'react';
import noop from 'lodash/noop';

import { Button } from '@flowio/react-button';
import { Box } from '@flowio/react-box';
import {
  Worksheet, WorksheetHeader, WorksheetTitle, WorksheetBody,
} from '@flowio/react-worksheet';

import AddRuleWorksheetForm from '../../containers/AddRuleWorksheetForm';
import * as styles from '../features.styles';
import { AddRuleWorksheetProps } from '../../types/components';

const AddRuleWorksheet: React.FC<AddRuleWorksheetProps> = ({
  availableFilters,
  initialValues,
  isSaveDisabled,
  open,
  onCancel,
  onSave,
}) => (
  <Worksheet open={open}>
    <WorksheetHeader>
      <Box alignItems="center" justifyContent="between" className={styles.dialogHeader}>
        <Button
          type="button"
          onClick={onCancel}
          content="Cancel"
        />
        <WorksheetTitle content>
          Add Rule
        </WorksheetTitle>
        <Button
          type="button"
          intent="primary"
          disabled={isSaveDisabled}
          onClick={onSave}
          content="Save"
        />
      </Box>
    </WorksheetHeader>
    <WorksheetBody>
      <AddRuleWorksheetForm
        initialValues={initialValues}
        availableFilters={availableFilters}
      />
    </WorksheetBody>
  </Worksheet>
);

AddRuleWorksheet.displayName = 'AddRuleWorksheet';

AddRuleWorksheet.defaultProps = {
  availableFilters: [],
  open: false,
  onCancel: noop,
  onSave: noop,
};

export default AddRuleWorksheet;
