import { css } from '@emotion/css';
import { colors } from '../../../../../theme/tokens';
import { labColors } from '../../../styles/labs.styles';

export const pageContent = css`
  padding: 0;
`;

export const pageDescription = css`
  color: ${colors.gray[800]};
`;

export const labTiles = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export const tileBackground = (color: { red: number, green: number, blue: number }) => {
  const { red, green, blue } = color;
  const bg = [`rgba(${red}, ${green}, ${blue}, 0.97) 0%`];
  for (let i = 1; i < 6; i += 1) {
    bg.push(`rgba(${red}, ${green}, ${blue}, ${1 - (0.03 * (i - 1))}) ${20 * i}%`);
    bg.push(`rgba(${red}, ${green}, ${blue}, ${1 - (0.03 * i)}) ${20 * i}%`);
  }
  bg.push(`rgba(${red}, ${green}, ${blue}, 0.87) 100%`);
  return css`
      background-image: linear-gradient(70deg, ${bg.join(', ')})
    `;
};

export const labTile = (index: number): string => css`
  font-size: 13px;
  border-radius: 4px;
  height: 160px;
  color: ${colors.gray[200]};
  box-sizing: border-box;
  padding: 24px;
  position: relative;
  text-decoration: none;
  ${tileBackground(labColors[index])}
`;

export const accepted = css`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 42px;
  height: 42px;

  background-image: linear-gradient(
    135deg,
    transparent 0%,
    transparent 50%,
    rgba(0, 0, 0, 0.4) 50%,
    rgba(0, 0, 0, 0.4) 100%
  );
`;

export const acceptedTick = css`
  position: absolute;
  bottom: 6px;
  right: 4px;
  fill: ${colors.gray[400]};
  transform: scaleY(0.75);
`;

export const labTitleContainer = css`
  display: flex;
  align-items: center;
  padding-bottom: 34px;
`;

export const labTitle = css`
  font-size: 24px;
  margin: 4px 0 4px 14px;
`;

export const labLink = css`
  display: block;
  flex-basis: 30%;
  margin: 16px 16px 16px 0;

  &:hover {
    text-decoration: none;
  }
  text-decoration: none;
`;
