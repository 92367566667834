import { compose } from 'redux';
import { withFetch } from '@flowio/redux-fetch';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import AddChannels from '../components/AddChannels';
import { fetchFeedSources, createChannel } from '../../../actions';
import { getFeedSources } from '../../../selectors';
import { OwnProps, DispatchProps } from '../types';
import { RootState, ThunkDispatcher } from '../../../../../stores/types';
import { createToast } from '../../../../console/actions';

const getAsyncState = (
  dispatch: ThunkDispatcher,
  _getState: () => RootState,
  { params }: OwnProps,
): Promise<void[]> => {
  const {
    organization: organizationId,
    platformId,
  } = params;

  return Promise.all([
    dispatch(fetchFeedSources({
      organizationId,
      destinationPlatform: platformId,
    })),
  ]);
};

const mapStateToProps = (
  state: RootState,
) => ({
  feedSources: getFeedSources(state),
});

const mapDispatchToProps = (
  dispatch: ThunkDispatcher,
  { params: { organization, platformId } }: OwnProps,
): DispatchProps => ({
  onConfirm: (source: io.flow.internal.v0.unions.MarketingGatewayFeedSource) => {
    dispatch(
      createChannel(organization, platformId, source),
    ).then(() => {
      browserHistory.push(`/${organization}/marketing/${platformId}/feeds?isNew=true`);
    }).catch(() => {
      dispatch(createToast({
        intent: 'negative',
        content: 'Something went wrong, please try again',
      }));
    });
  },
  onCancel: () => browserHistory.push(`/${organization}/marketing`),
});

export default compose(
  withFetch(getAsyncState),
  connect(mapStateToProps, mapDispatchToProps),
)(AddChannels);
