import { OptinPromptsPostPromptsResponse } from '@flowio/api-internal-sdk';
import ActionTypes from '../constants/ActionTypes';
import {
  RootState, ThunkExtraArguments, ThunkResult, ThunkDispatcher,
} from '../../../stores/types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';
import standardAction from '../../../utilities/standard-action';

const createOptinPrompt = (
  organizationId: string,
  optinPromptForm: io.flow.internal.v0.models.OptinPromptForm,
): ThunkResult<Promise<OptinPromptsPostPromptsResponse>> => (
  dispatch: ThunkDispatcher,
  getState: () => RootState,
  extra: ThunkExtraArguments,
): Promise<OptinPromptsPostPromptsResponse> => {
  dispatch(standardAction(ActionTypes.CREATE_OPTIN_PROMPT_REQUEST));

  return extra.apiInternal(getState()).optinPrompts.postPrompts({
    organization: organizationId,
    body: optinPromptForm,
  }).then((response) => {
    if (response.ok) {
      dispatch(standardAction(ActionTypes.CREATE_OPTIN_PROMPT_SUCCESS, response.body));
    } else {
      dispatch({
        type: ActionTypes.CREATE_OPTIN_PROMPT_FAILURE,
        payload: formatErrorResponseV2(response),
      });
    }

    return response;
  });
};

export default createOptinPrompt;
