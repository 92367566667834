import { ExperiencesDeleteMarginsByExperienceKeyAndKeyResponse } from '@flowio/api-sdk';
import ActionTypes from '../constants/action-types';
import {
  ThunkResult, ThunkDispatcher, RootState, ThunkExtraArguments,
} from '../../../stores/types';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import { PropType } from '../../../types';
import toLegacyResponse from '../../../utilities/clients/to-legacy-response';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';

type ResponseType = PropType<ExperiencesDeleteMarginsByExperienceKeyAndKeyResponse, 'body'>;

export default function deleteMargin(
  organization: string,
  experienceKey: string,
  marginKey: string,
): ThunkResult<Promise<LegacyResponse<ResponseType>>> {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<LegacyResponse<ResponseType>> => {
    dispatch({
      type: ActionTypes.DELETE_MARGIN_SUBMIT,
      experienceKey,
      marginKey,
      organization,
    });

    return extra.api(getState()).experiences.deleteMarginsByExperienceKeyAndKey({
      organization,
      experience_key: experienceKey,
      key: marginKey,
    }).then((response) => {
      if (response.ok) {
        dispatch({
          type: ActionTypes.DELETE_MARGIN_SUCCESS,
          experienceKey,
          marginKey,
          organization,
        });
      } else {
        dispatch({
          type: ActionTypes.DELETE_MARGIN_FAILURE,
          error: formatErrorResponseV2(response),
        });
      }

      return toLegacyResponse<ResponseType>(response);
    });
  };
}
