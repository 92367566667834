import { createSelector } from 'reselect';
import get from 'lodash/get';
import getReturnPolicyDeleteConfirmation from './getReturnPolicyDeleteConfirmation';
import { RootState } from '../../../stores/types';
import { ReturnPolicyDeleteConfirmationState } from '../types';

const getIsDeletingReturnPolicy = createSelector<
RootState, ReturnPolicyDeleteConfirmationState, boolean>(
  getReturnPolicyDeleteConfirmation,
  (state) => get(state, 'deleting'),
);

export default getIsDeletingReturnPolicy;
