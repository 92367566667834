import omitBy from 'lodash/omitBy';
import { ExperiencesPostMarginsByExperienceKeyResponse } from '@flowio/api-sdk';
import ActionTypes from '../constants/action-types';
import { getOrganizationId } from '../../organization';
import { getCurrentExperienceKey } from '../selectors';
import {
  ThunkExtraArguments, RootState, ThunkDispatcher, ThunkResult,
} from '../../../stores/types';
import toLegacyResponse from '../../../utilities/clients/to-legacy-response';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import { PropType } from '../../../types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';

type ResponseType = PropType<ExperiencesPostMarginsByExperienceKeyResponse, 'body'>;

export default function createMargin(
  itemMarginForm: io.flow.v0.models.ItemMarginPostForm,
): ThunkResult<Promise<LegacyResponse<ResponseType>>> {
  return function createMarginEffects(
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<LegacyResponse<ResponseType>> {
    const state = getState();
    const organization = getOrganizationId(state);
    const experienceKey = getCurrentExperienceKey(state);
    const filteredItemMargins = omitBy(itemMarginForm, (value, key) => (key === 'fixed' || key === 'percent') && value === 0) as io.flow.v0.models.ItemMarginPostForm;

    if (!experienceKey) {
      throw new Error('createMargin: Missing required experience key');
    }

    dispatch({
      type: ActionTypes.CREATE_MARGIN_SUBMIT,
      itemMarginForm,
    });

    return extra.api(getState()).experiences.postMarginsByExperienceKey({
      organization,
      experience_key: experienceKey,
      body: filteredItemMargins,
    }).then((response) => {
      if (response.ok) {
        dispatch({
          type: ActionTypes.CREATE_MARGIN_SUCCESS,
          itemMarginForm,
          payload: response.body,
        });
      } else {
        dispatch({
          type: ActionTypes.CREATE_MARGIN_FAILURE,
          error: formatErrorResponseV2(response),
        });
      }
      return toLegacyResponse<ResponseType>(response);
    });
  };
}
