/**
 * A component responsible for displaying error messages received from a
 * Flow API call. Typically, you would render this component within forms.
 */

import {
  Banner,
  BannerText,
  BannerItem,
  BannerList,
} from '@flowio/react-banner';
import PropTypes from 'prop-types';
import React from 'react';
import map from 'lodash/map';

const GenericError = ({
  error = {},
  ...unhandledProps
}) => (
  <Banner {...unhandledProps} intent="negative">
    <BannerText>
      The following errors occurred when submitting your request
    </BannerText>
    <BannerList>
      {
        typeof error === 'string' ? (
          <BannerItem content={error} />
        ) : (
          map((Array.isArray(error) ? error : error.messages), (message) => ((typeof message === 'string') ? (
            <BannerItem key={message} content={message} />
          ) : (
            <BannerItem key={message.message} content={message.message} />
          )
          ))
        )
      }
    </BannerList>
  </Banner>
);

GenericError.displayName = 'GenericError';

GenericError.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.shape({
      code: PropTypes.oneOf(['generic_error']).isRequired,
      messages: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          message: PropTypes.string.isRequired,
        }),
      ])).isRequired,
    }),
  ]).isRequired,
};

export default GenericError;
