import { createSelector } from 'reselect';
import get from 'lodash/get';
import map from 'lodash/map';
import identity from 'lodash/identity';
import toLower from 'lodash/toLower';

import { findById as findRegionById } from '@flowio/lib-reference-javascript/lib/regions';
import { RootState } from '../../stores/types';
import { DialogName } from './constants';

export const getOrganizationState = (state: RootState) => get(state, 'organization');

export const getOrganization = createSelector(
  getOrganizationState,
  (state) => get(state, 'current'),
);

export const getBaseOrganizationCurrency = createSelector(
  getOrganization,
  (state) => get(state, 'defaults.base_currency'),
);

export const getBaseOrganizationCountry = createSelector(
  getOrganization,
  (state) => get(state, 'defaults.country'),
);

export const getBillingState = createSelector(
  identity,
  (state) => get(state, 'billing'),
);

export const getAccountEntities = createSelector(
  getBillingState,
  (state) => get(state, 'entities.accounts'),
);

export const getCurrentAccountKey = createSelector(
  getBillingState,
  (state) => toLower(get(state, 'account')),
);

export const getCurrentAccount = createSelector(
  getAccountEntities,
  getCurrentAccountKey,
  (accounts, key) => get(accounts, key),
);

export const getOrganizationCountries = createSelector(
  getOrganizationState,
  (state) => get(state, 'entities.countries'),
);

export const getAccountPagination = createSelector(
  getBillingState,
  (state) => get(state, 'paginations.accounts'),
);

/**
 * Get the organization that was last used to fetch countries. Used for caching results.
 */
export const getOrganizationCountriesOrganization = createSelector(
  getOrganizationState,
  (state) => get(state, 'statuses.countries.params.organization'),
);

export const getOrganizationCountriesReadyState = createSelector(
  getOrganizationState,
  (state) => get(state, 'statuses.countries.status'),
);

export const getOrganizationId = createSelector(
  getOrganization,
  (organization) => get(organization, 'id'),
);

export const getMemberships = createSelector(
  getOrganizationState,
  (state) => get(state, 'entities.memberships.memberships'),
);

export const getEditingMembership = createSelector(
  getOrganizationState,
  (state) => get(state, 'entities.memberships.editingMembership'),
);

export const getUserMembership = createSelector(
  getOrganizationState,
  (state) => get(state, 'entities.userMembership'),
);

export const getPublicKeys = createSelector(
  getOrganizationState,
  (state) => get(state, 'entities.publicKeys'),
);

export const getExperiences = createSelector(
  getOrganizationState,
  (state) => get(state, 'entities.experiences'),
);

/**
 * The region settings only returns results for previously set regions. The
 * full list of possible regions is that of the experience regions. This
 * selector starts with the list of experience regions and merges in ones
 * the API has records for.
 */
export const getRegionSettings = createSelector(
  getOrganizationState,
  (state) => {
    const regionSettings = map(get(state, 'entities.regionSettings.results'), (rs) => ({
      ...rs,
      region: findRegionById(rs.region),
    }));
    return regionSettings;
  },
);

export const getCurrencies = createSelector(
  getOrganizationState,
  (state) => get(state, 'entities.currencies'),
);

export const getFormError = (formName: string) => createSelector(
  getOrganizationState,
  (state) => get(state, `forms.${formName}.error`),
);

export const getIsFileEmpty = createSelector(
  getOrganizationState,
  (state) => get(state, 'entities.imports.isFileEmpty'),
);

export const getIsImportLastPage = createSelector(
  getOrganizationState,
  (state) => get(state, 'entities.imports.isLastPage'),
);

export const getIsImportFirstPage = createSelector(
  getOrganizationState,
  (state) => get(state, 'entities.imports.isFirstPage'),
);

export const getImportsCurrentPageNumber = createSelector(
  getOrganizationState,
  (state) => get(state, 'entities.imports.pageNumber'),
);

export const getOpenDialogStatus = createSelector(
  getOrganizationState,
  (state) => get(state, 'entities.imports.openDialog'),
);

export const getImportedFiles = createSelector(
  getOrganizationState,
  (state) => get(state, 'entities.imports.files'),
);

export const getImportEventSouce = createSelector(
  getOrganizationState,
  (state) => get(state, 'entities.imports.eventSource'),
);

export const getIsFormErrored = (formName: string) => createSelector(
  getFormError(formName),
  (error) => Boolean(error),
);

export const getIsFormSubmitted = (formName: string) => createSelector(
  getOrganizationState,
  (state) => get(state, `forms.${formName}.submitted`),
);

export const getIsFormSubmitting = (formName: string) => createSelector(
  getOrganizationState,
  (state) => get(state, `forms.${formName}.submitting`),
);

export const getOrgDialogOpenState = (dialogName: DialogName) => createSelector(
  getOrganizationState,
  (state) => state.dialogs[dialogName].open,
);
