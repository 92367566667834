import { combineReducers } from 'redux';
import accessTokenSummary from './accessTokenSummary';
import loginStatus from './loginStatus';
import ui from './ui';

export default combineReducers({
  accessTokenSummary,
  loginStatus,
  ui,
});
