import { connect } from 'react-redux';
import { compose } from 'redux';
import { withFetch } from '@flowio/redux-fetch';
import { verifyEmail } from '../../../actions';
import EmailVerificationComponent from '../components/email-verification';
import { RootState } from '../../../../../stores/types';
import { ThunkDispatcher } from '../../../../../middlewares/types';
import { StateProps, OwnProps } from '../types';
import { LegacyResponse } from '../../../../../utilities/clients/types/server';
import { FormattedErrorMessages } from '../../../../../utilities/format-error-response-v2';

const mapStateToProps = (state: RootState): StateProps => state.account.emailVerification;

const getAsyncState = (
  dispatch: ThunkDispatcher,
  _getState: () => RootState,
  ownProps: OwnProps,
): Promise<
LegacyResponse<io.flow.v0.models.EmailVerification | FormattedErrorMessages>
> => dispatch(verifyEmail(ownProps.params.token));

export default compose(
  withFetch(getAsyncState),
  connect<StateProps, {}, OwnProps, RootState>(mapStateToProps),
)(EmailVerificationComponent);
