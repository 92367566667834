import omitBy from 'lodash/omitBy';
import isUndefined from 'lodash/isUndefined';
import { ExportsPostResponse } from '@flowio/api-sdk';
import {
  RootState, ThunkDispatcher, ThunkExtraArguments, ThunkResult,
} from '../../../stores/types';
import ActionTypes from '../constants/action-types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import { getOrganizationId } from '../../organization';
import toLegacyResponse from '../../../utilities/clients/to-legacy-response';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import { ExportFormData } from '../components/export-form-dialog/types';
import { PropType } from '../../../types';

const omitByUndefined = (
  object: Record<string, any>,
): Record<string, any> => omitBy(object, isUndefined);

export type CreateCatalogExportResponse = PropType<ExportsPostResponse, 'body'>;

export default function createCatalogExport(
  formData: ExportFormData,
  q: string,
): ThunkResult<Promise<LegacyResponse<CreateCatalogExportResponse | FormattedErrorMessages>>> {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<LegacyResponse<CreateCatalogExportResponse | FormattedErrorMessages>> => {
    dispatch({
      type: ActionTypes.CREATE_CATALOG_EXPORT_REQUEST,
      formData: omitByUndefined({
        ...formData,
        q,
      }),
    });

    const state = getState();

    return extra.api(getState()).exports.post({
      organization: getOrganizationId(state),
      body: {
        type: {
          q,
          discriminator: 'catalog_item_export_type',
        },
        emails: [formData && formData.email],
      },
    }).then((response) => {
      if (response.ok) {
        dispatch({
          type: ActionTypes.CREATE_CATALOG_EXPORT_SUCCESS,
          payload: response.body,
          formData: omitByUndefined({
            ...formData,
            q,
          }),
        });
        return toLegacyResponse<io.flow.v0.models.Export>(response);
      }

      dispatch({
        params: {},
        type: ActionTypes.CREATE_CATALOG_EXPORT_FAILURE,
        payload: formatErrorResponseV2(response),
      });

      return toLegacyResponse({
        ...response,
        body: formatErrorResponseV2(response),
      });
    });
  };
}
