import { FeaturesDeleteByKeyResponse } from '@flowio/api-internal-sdk';
import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import ActionTypes from '../constants/action-types';

function createRequestAction(organizationId: string, featureKey: string): RootActionTypes {
  return {
    type: ActionTypes.DELETE_FEATURE_BY_KEY_REQUEST,
    meta: {
      organizationId,
      featureKey,
    },
  };
}

function createSuccessAction(
  organizationId: string,
  featureKey: string,
): RootActionTypes {
  return {
    type: ActionTypes.DELETE_FEATURE_BY_KEY_SUCCESS,
    meta: {
      organizationId,
      featureKey,
    },
  };
}

function createFailureAction(
  organizationId: string,
  featureKey: string,
  payload: FormattedErrorMessages,
): RootActionTypes {
  return {
    type: ActionTypes.DELETE_FEATURE_BY_KEY_FAILURE,
    meta: {
      organizationId,
      featureKey,
    },
    payload,
  };
}

export default function deleteFeatureByKey(
  organizationId: string,
  featureKey: string,
): ThunkResult<Promise<FeaturesDeleteByKeyResponse>> {
  return function deleteFeatureByKeyEffect(
    dispatch,
    getState,
    extra,
  ): Promise<FeaturesDeleteByKeyResponse> {
    dispatch(createRequestAction(organizationId, featureKey));

    return extra.apiInternal(getState()).features.deleteByKey({
      key: featureKey,
      organization: organizationId,
    }).then((response) => {
      if (response.ok) {
        dispatch(createSuccessAction(organizationId, featureKey));
        return response;
      }

      const error = formatErrorResponseV2(response);
      dispatch(createFailureAction(organizationId, featureKey, error));
      return response;
    });
  };
}
