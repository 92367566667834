/**
 * This simply validates the JWT is the valid format
 * It does not verify the JWT signature.
 */

let base64Decode: Function;

if (process.browser) {
  base64Decode = window.atob;
} else {
  base64Decode = (value: string): string => Buffer.from(value, 'base64').toString();
}

export function isValidJwt(jwt: string): boolean {
  if (typeof jwt !== 'string' || jwt === '') {
    return false;
  }
  try {
    const jwtParts = jwt.split('.').slice(0, -1).map((sec) => JSON.parse(base64Decode(sec)));
    return jwtParts[0].typ === 'JWT';
  } catch (e) {
    return false;
  }
}

export default {
  isValidJwt,
};
