import { DragHandle, Edit, Trash } from '@flowio/react-icons';
import React from 'react';

import { FlatButton } from '@flowio/react-button';
import { Draggable } from 'react-beautiful-dnd';
import { Tag } from '@flowio/react-tag-input';
import classNames from 'classnames';
import * as styles from './item-margin-summary.styles';

interface Props {
  itemMargin: io.flow.v0.models.ItemMargin;
  currency: string;
  organizationCountry: string;
  editable: boolean;
  index: number;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
}

const ItemMarginSummary: React.FC<Props> = ({
  itemMargin,
  currency,
  organizationCountry,
  editable = false,
  index,
  onEdit,
  onDelete,
}) => {
  const handleEdit = (): ReturnType<Props['onEdit']> => onEdit(itemMargin.key);
  const handleDelete = (): ReturnType<Props['onDelete']> => onDelete(itemMargin.key);
  const pattern = /(([^\s]+):("[^"]*"))|[^"\s]+|("[^"]*")/g;
  const items = itemMargin.q.match(pattern) || [];
  const itemMarginChips = items.map((item) => (<Tag className={styles.itemChip} intent="primary" content={item} />));
  const intlNumberFormatter = new Intl.NumberFormat(organizationCountry, {
    style: 'currency',
    currency,
  });
  return (
    <Draggable
      draggableId={itemMargin.id}
      isDragDisabled={!editable}
      index={index}
    >
      {(provided): JSX.Element => (
        <section
          className={['item-margin-summary', styles.itemMarginSummary].join(' ')}
          ref={provided.innerRef}
          data-name={itemMargin.name}
          {...provided.draggableProps}
        >
          <div
            {...provided.dragHandleProps}
            className={classNames(styles.position, { [styles.positionEditable]: editable })}
          >
            {((): React.ReactNode => {
              if (editable) {
                return <DragHandle width={16} />;
              }
              return null;
            })()}
          </div>
          <div className={styles.summary}>
            <p className={styles.name}>
              Rule:
              {` ${itemMargin.name}`}
            </p>
            <p className={styles.description}>
              <>
                {itemMargin.fixed > 0 && (
                  <div className={styles.fixed}>
                    Margin of
                    {` ${intlNumberFormatter.format(itemMargin.fixed)} `}
                    on
                    {' '}
                    <>
                      {itemMarginChips}
                    </>
                  </div>
                )}
                {itemMargin.percent > 0 && (
                  <div>
                    Margin of
                    {` ${itemMargin.percent}% `}
                    on
                    {' '}
                    <>
                      {itemMarginChips}
                    </>
                  </div>
                )}
              </>
            </p>
          </div>
          {((): React.ReactNode => {
            if (editable) {
              return (
                <div>
                  <FlatButton
                    onClick={handleEdit}
                  >
                    <Edit width={16} />
                  </FlatButton>
                  <FlatButton
                    onClick={handleDelete}
                  >
                    <Trash width={16} />
                  </FlatButton>
                </div>
              );
            }

            return null;
          })()}
        </section>
      )}
    </Draggable>
  );
};

ItemMarginSummary.defaultProps = {
  editable: false,
};

ItemMarginSummary.displayName = 'ItemMarginSummary';

export default ItemMarginSummary;
