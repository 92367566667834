import Platforms from './Platforms';

const EmptyStates = {
  NO_GMC_PRIMARY_FEEDS: `There are no Primary Feeds in this ${Platforms('google').platformName} account`,
  UPLOAD_PRIMARY_FEEDS: 'Please upload a Primary Feed so you can start localizing feeds.',
  NO_FACEBOOK_COUNTRY_FEEDS: "You don't have any Country Feeds set up yet",
  NO_FACEBOOK_CATALOG_TITLE: "You don't have a catalog in your connected Facebook account.",
  NO_FACEBOOK_CATALOG_SUBTITLE: "You don't have a catalog in your connected Facebook account.",
  NO_FACEBOOK_BUSINESSES_TITLE: 'No business account found.',
  NO_FACEBOOK_BUSINESSES_SUBTITLE: 'You don’t have a relevant account or permissions to connect a Facebook business account.',
  UPLOAD_FB_PRIMARY_FEEDS: 'Add localized product information to the catalog with Country Feeds created by Flow.',
  FB_COUNTRY_FEEDS_BUTTON: 'Add Country Feed',
};

export default EmptyStates;
