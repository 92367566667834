import { connect } from 'react-redux';
import find from 'lodash/find';
import isNumber from 'lodash/isNumber';
import get from 'lodash/get';
import map from 'lodash/map';
import FeedsCreationConfirmationDialog from '../components/FeedsCreationConfirmationDialog';
import {
  hideFeedsCreationConfirmationDialog,
  goToFeedsPage,
  goToAddCountriesPage,
} from '../actions';
import {
  getFeedsCreationConfirmationDialogData,
  getShowFeedsCreationConfirmationDialog,
  getSelectedFeedSource,
  getGoogleDataSources,
} from '../selectors';

import {
  getCountries,
} from '../../reference/selectors';

import { findCountryByCode } from '../utilities/findReferenceData';
import { RootState, ThunkDispatcher, RootActionTypes } from '../../../stores/types';
// import { MarketingGatewayFeedSourceGoogle } from '@flowio/api-internal-sdk';

interface StateProps {
  isOpen: boolean;
  numProducts?: number;
  countries: string[];
}

interface DispatchProps {
  onClose: () => void;
}

interface OwnProps {
  organizationId: string;
  platformId?: io.flow.internal.v0.enums.MarketingGatewayPlatform;
  accountId?: string;
}

const mapDispatchToProps = (
  dispatch: ThunkDispatcher,
  ownProps: OwnProps,
): DispatchProps => ({
  onClose: (): Promise<(RootActionTypes | void)[]> => {
    const actions: (RootActionTypes | void)[] = [dispatch(hideFeedsCreationConfirmationDialog())];
    if (ownProps.platformId) {
      if (ownProps.platformId === 'facebook' && isNumber(ownProps.accountId)) {
        actions.push(
          dispatch(goToAddCountriesPage({ ...ownProps, catalogId: ownProps.accountId })),
        );
      }
      actions.push(dispatch(goToFeedsPage({ ...ownProps, platformId: ownProps.platformId })));
    }
    return Promise.all(actions);
  },
});

const mapStateToProps = (state: RootState): StateProps => {
  const isOpen = getShowFeedsCreationConfirmationDialog(state);

  if (isOpen) {
    let numProducts;
    const feedSource = get(getSelectedFeedSource(state), 'source');

    if (get(feedSource, 'discriminator') === 'marketing_gateway_feed_source_google') {
      numProducts = get(
        find(getGoogleDataSources(state), (source) => (
          source.country === get(feedSource, 'country')
          && source.currency === get(feedSource, 'currency')
          && source.language === get(feedSource, 'language')
        )),
        ['statistics', 'total_count'],
      );
    }

    const countryNames: string[] = map(
      getFeedsCreationConfirmationDialogData(state).countries,
      (country) => get(findCountryByCode(getCountries(state), country), 'name', country),
    );

    return {
      isOpen,
      numProducts,
      countries: countryNames,
    };
  }

  return { isOpen, countries: [] };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FeedsCreationConfirmationDialog);
