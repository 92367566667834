import React, { useCallback } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { Banner } from '@flowio/react-banner';
import {
  Card,
  CardHeader,
  CardContent,
  CardFooter,
} from '@flowio/react-card';
import { css } from '@emotion/react';

import ExperienceNavigation from '../../navigation';
import PaymentMethodRulesDisplayOrder from '../../PaymentMethodRulesDisplayOrder';
import CreditCardsDisplayOrder from '../../CreditCardsDisplayOrder';
import { sortByDisplayPosition } from '../../../utilities/payment-method-rules';
import { MergedProps as Props } from '../types';

const pmContainer = css`
  display: flex;
  flex-flow: column wrap;
`;

const pmsFooter = css`
  font-size: 0.8em;

  ul {
    padding-left: 1.5em;
  }
`;

const warningBannerStyles = css({
  marginBottom: '1em',
  fontSize: '0.9rem',
  color: '#17181a',
});

const PaymentMethodRulesContainer: React.FC<Props> = ({
  experience,
  experienceKey,
  handleCreditCardsReorder,
  handlePaymentMethodRuleDisplayChange,
  handlePaymentMethodRuleSwap,
  handleCCOrderClose,
  handleCCOrderOpen,
  isCCSectionOpen,
  isFlowEmployee,
  organizationId,
  paymentMethods,
}) => {
  const cards = paymentMethods.filter((pm) => pm.payment_method.type === 'card');
  cards.sort(sortByDisplayPosition);

  const handleDrop = useCallback((
    dropResult,
  ) => {
    if (dropResult.destination != null) {
      if (dropResult.destination.index === dropResult.source.index) {
        return;
      }
      handlePaymentMethodRuleSwap({
        sourceIndex: dropResult.source.index,
        targetIndex: dropResult.destination.index,
      });
    }
  }, [handlePaymentMethodRuleSwap]);

  if (experience == null) {
    throw new Error('PaymentMethodRulesContainer: Missing required experience');
  }

  if (!experienceKey) {
    throw new Error('PaymentMethodRulesContainer: Missing required experience key');
  }

  return (
    <>
      <ExperienceNavigation
        organization={organizationId}
        experience={experienceKey}
      />
      {!isFlowEmployee && (
        <Banner
          intent="warning"
          css={warningBannerStyles}
        >
          You&apos;re viewing a read-only version of this page.
          Please contact your Client Success Manager at Flow or email
          {' '}
          <a href="mailto:help@flow.io">help@flow.io</a>
          {' '}
          to make changes.
        </Banner>
      )}
      <Card>
        <CardHeader
          caption="Payment Methods Display Order"
          description="Customize the display order of the payment methods on the Checkout Payment Info page"
          dividing
        />
        <CardContent>
          {paymentMethods.length ? (
            <div css={pmContainer}>
              <DragDropContext onDragEnd={handleDrop}>
                <PaymentMethodRulesDisplayOrder
                  handlePaymentMethodRuleDisplayChange={handlePaymentMethodRuleDisplayChange}
                  handleCCOrderOpen={handleCCOrderOpen}
                  paymentMethods={paymentMethods}
                  isFlowEmployee={isFlowEmployee}
                />
              </DragDropContext>
              <CreditCardsDisplayOrder
                experience={experience}
                isOpen={isCCSectionOpen}
                handleCCOrderClose={handleCCOrderClose}
                handleCreditCardsReorder={handleCreditCardsReorder}
                creditCards={cards}
              />
            </div>
          ) : (
            <div>No Payment Method Rules Found.</div>
          )}
        </CardContent>
        <CardFooter
          css={pmsFooter}
          dividing
        >
          <ul>
            <li>
              All payment methods must be integrated programmatically
              in order to be functional in Checkout.
            </li>
            <li>Showing/hiding a payment method does not change its integration status.</li>
            <li>
              Hidden payment methods are not displayed in Checkout
              but can still be accessed programmatically.
            </li>
            <li>
              Please see
              {' '}
              <a href="https://docs.flow.io/docs/payment-methods" target="_blank" rel="noopener noreferrer">payment method documentation</a>
              {' '}
              to see all available payment methods. Note KCP payment method supports 21 cards.
            </li>
          </ul>
        </CardFooter>
      </Card>
    </>
  );
};

PaymentMethodRulesContainer.displayName = 'PaymentMethodsContainer';

PaymentMethodRulesContainer.defaultProps = {
  isFlowEmployee: false,
  paymentMethods: [],
};

export default PaymentMethodRulesContainer;
