import { createSelector } from 'reselect';
import getFacebook from './getFacebook';

const getIsMissingPermissions = createSelector(
  getFacebook,
  (facebook) => (
    facebook.loginStatus.status === 'connected'
      && !!facebook.loginStatus.permissions?.declined.length
  ),
);

export default getIsMissingPermissions;
