import React from 'react';
import LabsPage from '../../LabsPage/containers/LabsPage';
import { MergedProps as Props } from '../types';

import * as styles from './PayLater.styles';

const content = (
  <>
    <p>
      Pay Later methods can help boost sales by giving shoppers the option to pay
      for purchases in multiple installments, over time. As a retailer, you’re paid
      up front and in full, while customers pay nothing or only a portion of the total
      sale at the time of purchase. What’s more, pay later providers take on the responsibility
      of each transaction and payment collection, meaning you get all of the upside and none of the
      risk.
    </p>
    <p>
      When retailers include pay later methods at checkout, they can expect:
      <ul>
        <li>Up to a 30% lift in conversion</li>
        <li>Up to 45% increase in average order value</li>
        <li>More return customers</li>
      </ul>
    </p>
    <p>
      To get started with Pay Later methods on Flow, review the terms &amp; conditions below
      and opt-in.
    </p>
    <p>
      <strong>Availability</strong>
    </p>
    <p>
      <strong>
        Below is a list of all markets for which Pay Later methods
        are currently available through Flow:
      </strong>
    </p>
    <p>
      <strong>Klarna: </strong>
      Austria, Belgium, Denmark, Finland, Germany, Italy, Netherlands, Norway, Spain, Sweden, UK.
    </p>
    <p>
      <strong>Afterpay: </strong>
      Australia, USA.
    </p>

    <div className={styles.greyed}>
      <h5 className={styles.termsHeader}>Terms &amp; Conditions</h5>
      <p>
        Notwithstanding any payment processing or transaction management fees agreed to in your
        order form,
        {' '}
        <a href="https://www.afterpay.com/" target="_blank" rel="noopener noreferrer"><strong>Afterpay</strong></a>
        {' '}
        and
        {' '}
        <a href="https://www.klarna.com/" target="_blank" rel="noopener noreferrer"><strong>Klarna</strong></a>
        {' '}
        require the following transaction
        management fees:
      </p>
      <ul>
        <li>Afterpay at 4%</li>
        <li>Klarna at 3.45%</li>
      </ul>
      <p>
        Client acknowledges that Flow is not required to provide the availability to consumers to
        utilize such payment methods in any particular jurisdiction on the checkout page for the
        purchase of goods. Flow may increase or decrease the available jurisdictions in its sole
        discretion.
      </p>
      <p>
        Further, Client acknowledges that these payment methods have unique terms and conditions,
        of which Client is aware and represents and warrants that it shall remain in compliance with
        these terms and conditions at all times if and when such methods are made available.
      </p>
      <p>
        Note that Afterpay is currently available on Flow-hosted checkouts.
      </p>
    </div>
  </>
);

const sidePanelContent = (
  <div className={styles.payLaterPartners}>
    <span>Pay Later Partners</span>
    <div className={styles.payLaterPartnersLogos}>
      <a href="https://www.klarna.com/" target="_blank" rel="noopener noreferrer">
        <img className={styles.partnerLogo} alt="Klarna logo" width="70" src="https://cdn.flow.io/console/labs/klarna_logo.png" />
      </a>
      <a href="https://www.afterpay.com/" target="_blank" rel="noopener noreferrer">
        <img className={styles.partnerLogo} alt="AfterPay logo" width="98" src="https://cdn.flow.io/console/labs/afterpay_logo.png" />
      </a>
    </div>
  </div>
);

const PayLaterLab: React.FC<Props> = ({
  onAcceptLab,
  acceptingUser,
  labProjectSettings,
  error,
}) => (
  <LabsPage
    acceptButtonText="Accept Pay Later terms"
    acceptedButtonText="Pay Later terms are accepted"
    content={content}
    title="Buy Now, Pay Later: A Win-Win for Retailers"
    lab={labProjectSettings}
    acceptingUser={acceptingUser}
    onAcceptLab={onAcceptLab}
    error={error}
    sidePanelContent={sidePanelContent}
  />
);

export default PayLaterLab;
