import { Box } from '@flowio/react-box';
import { Button } from '@flowio/react-button';
import { Tag } from '@flowio/react-tag-input';
import React from 'react';
import { ItemSearchOptions } from '../types';

type Props = {
  searchFilters: {
    label: string; value: string | number | boolean | undefined,
    key: keyof ItemSearchOptions,
  }[]
  onRemoveFilter: (value: keyof ItemSearchOptions) => void;
  onRemoveAllFilters: () => void;
};

const FilterTags: React.FC<Props> = ({ searchFilters, onRemoveFilter, onRemoveAllFilters }) => (
  <>
    <Box alignItems="center" spacing="loose">
      {searchFilters.map(({ label, value, key }) => (
        <Tag
          key={key}
          content={<p><b>{label}:</b> {key === 'physical_good' ? value?.toString() : value}</p>}
          intent="neutral"
          dismissible
          size="small"
          onRemove={(): void => onRemoveFilter(key)}
        />
      ))}
      {searchFilters.length > 0 && (
        <Button
          type="button"
          content="Remove all"
          onClick={onRemoveAllFilters}
          intent="neutral"
          disabled={false}
        />
      )}
    </Box>
  </>
);

export default FilterTags;
