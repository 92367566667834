import { css } from '@emotion/css';

export const group = css`
  margin: 0;
  padding: 0;
`;

export const item = css`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  vertical-align: flex-start;
  margin: 0;
  padding: 1rem 0;
  list-style: none;
`;

export const key = css`
  margin: 0 16px;
  width: calc(100% - 62px); /* side margin + material ui icon width */
  word-wrap: break-word;
`;

export const icon = css`
  height: 22px;
  width: 22px;
`;
