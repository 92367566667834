import React from 'react';
import {
  Field,
  Form,
  InjectedFormProps,
} from 'redux-form';

import { Box } from '@flowio/react-box';
import { Button } from '@flowio/react-button';
import { Card, CardContent } from '@flowio/react-card';
import { FormGroup } from '@flowio/react-form-group';
import { Page, PageTitle, PageContent } from '@flowio/react-page';
import {
  TextField, Checkbox, DateEdit, SelectField, MultiSelectField,
} from '../../../../../components/ReduxFormFieldAdapters';

import DiscountType from '../../../constants/DiscountType';
import { MergedProps, DiscountDetailsFormValues } from '../types';
import * as styles from './styles';

// eslint-disable-next-line
const { timeOptions, timeZones } = require('../../../constants/timeOptions.json');

const typeDropdownOptions = [
  {
    value: DiscountType.TAX,
    content: 'Tax Subsidy',
  },
  {
    value: DiscountType.DUTY,
    content: 'Duty Subsidy',
  },
];

class DiscountDetailsForm
  extends React.PureComponent<
  MergedProps & InjectedFormProps<DiscountDetailsFormValues, MergedProps>
  > {
  render(): React.ReactElement {
    const {
      validityFromDate,
      noEndDate,
      experiences,
      status,
      onCancel,
      handleSubmit,
    } = this.props;

    return (
      <Page>
        <Box justifyContent="between" className={styles.pageHeader}>
          <PageTitle content={status ? 'Edit Discount' : 'New Discount'} />
          <Box spacing="loose">
            <Button intent="neutral" content="Cancel" onClick={(): void => onCancel()} />
            {/* {(status && status !== Status.Expired) && (
              <Button intent="negative" leftIcon="CircleMinus" content="Disable" />
            )}
            {status && status === Status.Expired && (
              <Button intent="primary" leftIcon="Check" content="Enable" />
            )} */}
            <Button intent="positive" type="button" onClick={handleSubmit} content="Save" />
          </Box>
        </Box>
        <PageContent>
          <Form>
            <Card>
              <CardContent>
                <Field component="input" type="hidden" name="id" />
                <FormGroup gutter inline labelSize="medium" labelFor="name" labelText="Name">
                  <Field
                    className={styles.nameContainer}
                    component={TextField}
                    size="small"
                    name="name"
                    fluid
                  />
                </FormGroup>
                <FormGroup gutter inline labelSize="medium" labelFor="type" labelText="Type">
                  <Field
                    component={SelectField}
                    options={typeDropdownOptions}
                    hintText="Select Discount Type"
                    selection
                    size="small"
                    name="type"
                  />
                </FormGroup>
                <FormGroup gutter inline labelSize="medium" labelFor="experiences" labelText="Experiences">
                  <Field
                    component={MultiSelectField}
                    options={experiences}
                    valueKey="key"
                    labelKey="name"
                    name="experiences"
                    hintText="Select Experience(s)"
                  />
                </FormGroup>
                <FormGroup gutter inline labelSize="medium" labelFor="validityPeriod" labelText="Validity Period">
                  <Box direction="column" className={styles.validityPeriodRow}>
                    <span className={styles.validityPeriodLabel}>From</span>
                    <Box spacing="extraLoose">
                      <Field
                        component={DateEdit}
                        name="validityFromDate"
                        id="toDate"
                        size="small"
                      />
                      <Field
                        className={styles.promoTime}
                        selection
                        component={SelectField}
                        options={timeOptions}
                        size="small"
                        name="validityFromTime"
                        scrollableMenu
                      />
                      <Field
                        selection
                        className={styles.promoZone}
                        component={SelectField}
                        options={timeZones}
                        size="small"
                        name="validityFromZone"
                        scrollableMenu
                      />
                    </Box>
                  </Box>
                  <Box direction="column">
                    <span className={styles.validityPeriodLabel}>To</span>
                    <Box spacing="extraLoose">
                      <Field
                        minimumDate={validityFromDate}
                        component={DateEdit}
                        name="validityToDate"
                        id="toDate"
                        size="small"
                        disabled={noEndDate}
                      />
                      <Field
                        className={styles.promoTime}
                        disabled={noEndDate}
                        component={SelectField}
                        selection
                        options={timeOptions}
                        size="small"
                        name="validityToTime"
                        scrollableMenu
                      />
                      <Field
                        className={styles.promoZone}
                        component={SelectField}
                        disabled={noEndDate}
                        selection
                        options={timeZones}
                        size="small"
                        name="validityToZone"
                        scrollableMenu
                      />
                      <span className={styles.inlineCheckbox}>
                        <Field
                          component={Checkbox}
                          type="checkbox"
                          name="noEndDate"
                        />
                        <span className={styles.validityEndingLabel}>No end date</span>
                      </span>
                    </Box>
                  </Box>
                </FormGroup>
              </CardContent>
            </Card>
          </Form>
        </PageContent>
      </Page>
    );
  }
}

export default DiscountDetailsForm;
