import map from 'lodash/map';

import { ExperiencesPutStatusByKeyResponse } from '@flowio/api-sdk';
import ActionTypes from '../constants/action-types';
import ExperienceStatuses from '../constants/experience-status';
import {
  ThunkExtraArguments,
  RootState,
  ThunkDispatcher,
  ThunkResult,
} from '../../../stores/types';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import toLegacyResponse from '../../../utilities/clients/to-legacy-response';
import { PropType } from '../../../types';
import { createToast } from '../../console/actions';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';

type ResponseType = PropType<ExperiencesPutStatusByKeyResponse, 'body'>;

export default function updateExperienceStatus(
  organizationId: string,
  experienceKey: string,
  experienceStatus: io.flow.v0.enums.ExperienceStatus,
): ThunkResult<Promise<LegacyResponse<ResponseType>>> {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<LegacyResponse<ResponseType>> => {
    dispatch({
      type: ActionTypes.UPDATE_EXPERIENCE_STATUS_REQUEST,
    });

    return extra.api(getState()).experiences.putStatusByKey({
      organization: organizationId,
      key: experienceKey,
      body: { status: experienceStatus },
    }).then((response) => {
      if (response.ok) {
        const experienceName = response.body.name;
        const experienceStatusResponse = response.body.status;

        dispatch({
          type: ActionTypes.UPDATE_EXPERIENCE_STATUS_SUCCESS,
          payload: response.body,
        });

        switch (experienceStatusResponse) {
          case ExperienceStatuses.ACTIVE:
            dispatch(createToast({
              content: `${experienceName} has been activated`,
              icon: 'CheckCircle',
              intent: 'positive',
            }));
            break;
          case ExperienceStatuses.ARCHIVING:
          case ExperienceStatuses.ARCHIVED:
            dispatch(createToast({
              content: `${experienceName} has been archived`,
              icon: 'Archive',
              intent: 'info',
            }));
            break;
          default:
            break;
        }
      } else {
        dispatch({
          type: ActionTypes.UPDATE_EXPERIENCE_STATUS_FAILURE,
          payload: formatErrorResponseV2(response),
        });
        if (response.body?.messages) {
          map(response.body.messages, (message: string) => dispatch(createToast({
            content: message,
            intent: 'negative',
            icon: 'Alert',
          })));
        } else {
          dispatch(createToast({
            content: 'Failed to update experience status',
            intent: 'negative',
            icon: 'Alert',
          }));
        }
      }

      return toLegacyResponse<ResponseType>(response);
    });
  };
}
