import find from 'lodash/find';
import get from 'lodash/get';
import isNil from 'lodash/isNil';

export default function getSearchValuesFromQuery<T = never>(
  filters: io.flow.v0.unions.QueryFilter[],
): T[] {
  if (isNil(filters)) {
    return [];
  }

  if (filters.length === 1 && filters[0].discriminator === 'unstructured') {
    // eslint-disable-next-line no-useless-escape
    const reg = new RegExp('in \((.*)\)');
    const currentFilters = reg.exec(filters[0].q);

    if (currentFilters !== null && currentFilters[1]) {
      return currentFilters[1].substr(1, currentFilters[1].length - 2).split(', ') as unknown as T[];
    }

    return [];
  }

  const statusFilter = find(filters, { field: 'status' });
  return get(statusFilter, 'values', []);
}
