import { Field, Form } from 'redux-form';
import { IncludedLevyKey, PriceBookStatus } from '@flowio/api-constants';
import ApiPropTypes from '@flowio/api-prop-types';
import {
  Card, CardHeader, CardContent, CardFooter,
} from '@flowio/react-card';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import get from 'lodash/get';
import matches from 'lodash/matches';

import GenericError from '../../../../components/GenericError';
import { TextField, SelectField } from '../../../../components/ReduxFormFieldAdapters';

const includedOptions = [
  { content: 'Not Included', value: IncludedLevyKey.NONE },
  { content: 'Tax Only', value: IncludedLevyKey.VAT },
  { content: 'Duty Only', value: IncludedLevyKey.DUTY },
  { content: 'Included', value: IncludedLevyKey.VAT_AND_DUTY },
];

const statusOptions = [
  { content: 'Archived', value: PriceBookStatus.ARCHIVED },
  { content: 'Draft', value: PriceBookStatus.DRAFT },
  { content: 'Published', value: PriceBookStatus.PUBLISHED },
];

class UpsertPriceBook extends Component {
  constructor(props) {
    super(props);
    this.handleFormatting = this.handleFormatting.bind(this);
    this.handleParsing = this.handleParsing.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  handleParsing(value, name) {
    switch (name) {
      case 'currency':
        return get(value, 'iso_4217_3');
      case 'includes':
      case 'status':
        return get(value, 'value');
      default:
        return value;
    }
  }

  handleFormatting(value, name) {
    const { currencies } = this.props;
    switch (name) {
      case 'currency':
        return currencies.find(matches({ iso_4217_3: value }));
      case 'includes':
        return includedOptions.find(matches({ value }));
      case 'status':
        return statusOptions.find(matches({ value }));
      default:
        return value;
    }
  }

  render() {
    const {
      currencies,
      error,
      handleSubmit,
    } = this.props;
    return (
      <Card>
        <CardHeader caption="Price Book Configurations" dividing />
        <CardContent>
          {error && (
            <GenericError error={error} />
          )}
          <Form onSubmit={handleSubmit}>
            <Field component="input" type="hidden" name="organizationId" />
            <Field component="input" type="hidden" name="key" />
            <Field
              component={TextField}
              fluid
              inline
              labelCols={4}
              labelText="Price Book Name"
              name="name"
              gutter
            />
            <Field
              component={SelectField}
              format={this.handleFormatting}
              labelCols={4}
              labelKey="name"
              labelText="Currency"
              name="currency"
              options={currencies}
              parse={this.handleParsing}
              valueKey="iso_4217_3"
              searchable
              fluid
              inline
              gutter
              scrollableMenu
            />
            <Field
              component={SelectField}
              fluid
              format={this.handleFormatting}
              inline
              labelCols={4}
              labelText="Tax and Duty"
              name="includes"
              options={includedOptions}
              parse={this.handleParsing}
              gutter
            />
            <Field
              component={SelectField}
              fluid
              format={this.handleFormatting}
              inline
              labelCols={4}
              labelText="Status"
              name="status"
              options={statusOptions}
              parse={this.handleParsing}
              gutter
            />
          </Form>
        </CardContent>
        <CardFooter />
      </Card>
    );
  }
}

UpsertPriceBook.displayName = 'UpsertPriceBook';

UpsertPriceBook.propTypes = {
  currencies: PropTypes.arrayOf(ApiPropTypes.currency).isRequired,
  error: PropTypes.oneOfType([
    ApiPropTypes.genericError,
    ApiPropTypes.permissionError,
  ]),
  handleSubmit: PropTypes.func.isRequired,
};

UpsertPriceBook.defaultProps = {
  error: undefined,
};

export default UpsertPriceBook;
