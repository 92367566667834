import { createQueryParameters } from '@flowio/redux-filtering-paging-sorting';
import { OptinPromptsGetPromptsResponse } from '@flowio/api-internal-sdk';
import ActionTypes from '../constants/ActionTypes';
import {
  RootState, ThunkExtraArguments, ThunkResult, ThunkDispatcher,
} from '../../../stores/types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';

type FetchOptinPromptsArgs = {
  organizationId: string;
  pageNumber?: number;
  entriesPerPage?: number;
  queryParams?: object; // other query parameters supported by Flow API
};

const fetchOptinPrompts = ({
  organizationId,
  pageNumber = 1,
  entriesPerPage = 25,
  queryParams = {},
}: FetchOptinPromptsArgs): ThunkResult<Promise<OptinPromptsGetPromptsResponse>> => (
  dispatch: ThunkDispatcher,
  getState: () => RootState,
  extra: ThunkExtraArguments,
): Promise<OptinPromptsGetPromptsResponse> => {
  dispatch({
    type: ActionTypes.FETCH_OPTIN_PROMPTS_REQUEST,
    meta: {
      parameters: {
        organizationId,
        pageNumber,
        entriesPerPage,
      },
    },
  });

  return extra.apiInternal(getState()).optinPrompts.getPrompts({
    organization: organizationId,
    ...createQueryParameters({ entriesPerPage, pageNumber }),
    ...queryParams,
  }).then((response) => {
    if (response.ok) {
      dispatch({
        type: ActionTypes.FETCH_OPTIN_PROMPTS_SUCCESS,
        payload: response.body,
        meta: {
          parameters: {
            organizationId,
            pageNumber,
            entriesPerPage,
          },
        },
      });
    } else {
      dispatch({
        type: ActionTypes.FETCH_OPTIN_PROMPTS_FAILURE,
        payload: formatErrorResponseV2(response),
      });
    }

    return response;
  });
};

export default fetchOptinPrompts;
