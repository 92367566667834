import Client from './client';

export default class ExternalFulfillmentProofs extends Client {
  constructor(opts) {
    let options = opts;

    if (typeof opts === 'string') {
      options = { host: opts }; // convert host string to options object
    }

    options.serviceName = 'API Internal';

    super(options);
  }

  get(options = {}) {
    return this.makeRequest(`${this.host}/internal/billing/external/fulfillment/proof`, options);
  }

  getOrganizationAndOrderNumberByOrganizationIdAndOrderNumber(organizationId, orderNumber, options = {}) {
    return this.makeRequest(`${this.host}/internal/billing/external/fulfillment/proof/organization/${organizationId}/order_number/${orderNumber}`, options);
  }

  putOrganizationAndOrderNumberByOrganizationIdAndOrderNumber(organizationId, orderNumber, options = {}) {
    return this.makeRequest(`${this.host}/internal/billing/external/fulfillment/proof/organization/${organizationId}/order_number/${orderNumber}`, {
      ...options,
       method: 'PUT',
    });
  }

  deleteOrganizationAndOrderNumberByOrganizationIdAndOrderNumber(organizationId, orderNumber, options = {}) {
    return this.makeRequest(`${this.host}/internal/billing/external/fulfillment/proof/organization/${organizationId}/order_number/${orderNumber}`, {
      ...options,
       method: 'DELETE',
    });
  }

}
