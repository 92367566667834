import ActionTypes from '../constants/action-types';

import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';

const createRequestAction = (): RootActionTypes => ({
  type: ActionTypes.UPDATE_SHIPPING_ADDRESS_REQUEST,
});

const createSuccessAction = (payload: io.flow.v0.models.Order): RootActionTypes => ({
  type: ActionTypes.UPDATE_SHIPPING_ADDRESS_SUCCESS,
  payload,
});

const createFailureAction = (errors: FormattedErrorMessages): RootActionTypes => ({
  type: ActionTypes.UPDATE_SHIPPING_ADDRESS_FAILURE,
  payload: errors,
  error: true,
});

export default function updateShippingAddress(
  organizationId: string,
  orderNumber: string,
  values: io.flow.v0.models.OrderAddress,
): ThunkResult<Promise<void | io.flow.v0.models.Order>> {
  return (dispatch, getState, extra): Promise<void | io.flow.v0.models.Order> => {
    dispatch(createRequestAction());
    return extra.api(getState()).orders.putDestinationByNumber({
      number: orderNumber,
      organization: organizationId,
      body: {
        destination: values,
      },
    }).then((response) => {
      if (response.ok) {
        dispatch(createSuccessAction(response.body));
        return response.body;
      }

      const errors = formatErrorResponseV2(response);
      dispatch(createFailureAction(errors));
      return undefined;
    });
  };
}
