import { MarketingGatewayPlatform as Platform } from '@flowio/api-internal-constants';
import { $HttpResponse } from '@flowio/api-sdk';
import ActionTypes from '../constants/ActionTypes';
import standardAction from '../../../utilities/standard-action';
import { ApiError } from '../../../utilities/clients/api-error';
import {
  RootState, ThunkExtraArguments, ThunkResult, ThunkDispatcher,
} from '../../../stores/types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';

interface Props {
  organizationId: string;
  accountId: number;
  platformId: io.flow.internal.v0.enums.MarketingGatewayPlatform;
}

const createFacebookFeedSource = ({
  organizationId,
  accountId,
  platformId,
}: Props): ThunkResult<Promise<void>> => (
  dispatch: ThunkDispatcher,
  getState: () => RootState,
  extra: ThunkExtraArguments,
): Promise<void> => {
  dispatch(standardAction(ActionTypes.COMPLETE_CONNECTION_REQUEST));
  const state = getState();

  const request = platformId === Platform.FACEBOOK ? (
    extra.apiInternal(state).marketingGatewayFacebookConnections.putCatalog({
      organization: organizationId,
      body: {
        catalog_id: accountId,
      },
    })
  ) : (
    extra.apiInternal(state).marketingGatewayGoogleConnections.putAccount({
      organization: organizationId,
      body: {
        account_id: accountId,
      },
    })
  );

  return request.then((response) => {
    if (response.ok) {
      dispatch(standardAction(ActionTypes.COMPLETE_CONNECTION_SUCCESS));
      return;
    }

    const errorResponse = response as $HttpResponse<io.flow.v0.models.GenericError>;
    const error = new ApiError(errorResponse);

    dispatch({
      type: ActionTypes.COMPLETE_CONNECTION_FAILURE,
      payload: formatErrorResponseV2(errorResponse),
    });
    throw error;
  });
};

export default createFacebookFeedSource;
