/* eslint-disable global-require */

import Application from '../../modules/console/components/application/containers/application';
import NotFound from '../../modules/console/components/not-found';
import InternalServerError from '../../modules/console/components/internal-server-error';
import Home from '../../modules/home/containers/home';
import ApiDocs from '../../modules/auth/containers/api-docs';
import AuthRedirect from '../../modules/auth/components/auth-redirect/containers/auth-redirect';
import ShopifyOrderDetailRedirect from '../../modules/customer-service/containers/shopify-order-detail-redirect';

// REMEMBER: If adding a new "common" route please update the roleRoutes file with the new one
// It will 404 and YOU WILL BE CONFUSED
export default {
  path: '/',
  component: Application,
  indexRoute: {
    component: Home,
    hideLeftNavigation: true,
  },
  childRoutes: [
    {
      path: '404',
      component: NotFound,
      hideNavigation: true,
    },
    {
      path: '500',
      component: InternalServerError,
      hideNavigation: true,
    },
    {
      path: 'redirect',
      component: AuthRedirect,
      authRequired: true,
      hideNavigation: true,
    },
    {
      path: 'api/docs',
      component: ApiDocs,
      authRequired: true,
      hideNavigation: true,
    },
    {
      path: ':organization/redirects/shopify/order/:order',
      component: ShopifyOrderDetailRedirect,
      hideNavigation: true,
    },
    {
      path: ':organization/accounts',
      authRequired: false,
      onEnter: (nextState, replace) => replace(`/${nextState.params.organization}/organization/accounts`),
    },
    {
      path: ':organization/accounts/:currency/statements',
      authRequired: false,
      onEnter: (nextState, replace) => replace(`/${nextState.params.organization}/organization/accounts/${nextState.params.currency}/statements`),
    },
    {
      path: ':organization/accounts/:currency/transactions',
      authRequired: false,
      onEnter: (nextState, replace) => replace(`/${nextState.params.organization}/organization/accounts/${nextState.params.currency}/transactions`),
    },
    require('./price-books').default,
    require('./user').default,
    require('./account').default,
    require('../../modules/analytics').routes,
    require('./catalog').default,
    require('./docs').default,
    require('./auth').default,
    {
      path: ':organization',
      authRequired: false,
      onEnter: (nextState, replace) => replace(`/${nextState.params.organization}/experience`),
    },
    require('./experience').default,
    require('./experiments').default,
    require('./classification').default,
    require('../../modules/optin/routes').default,
    require('../../modules/logistics/routes').default,
    require('../../modules/fulfillment/routes').default,
    require('../../modules/checkout-configuration/routes').default,
    require('./order').default,
    require('./organization').default,
    require('./utilities').default,
    require('../../modules/marketing').routes,
    require('./pending-users').default,
    require('./failure-summaries').default,
    require('./discounts').default,
    require('./labs').default,
    {
      path: '*',
      component: NotFound,
      hideNavigation: true,
    },
  ],
};
