import ActionTypes from '../constants/action-types';
import { ExperimentsExperimentResultsState, ExperimentsActions } from '../types';

const initialState: ExperimentsExperimentResultsState = {
  results: [],
};

export default function (
  state = initialState,
  action: ExperimentsActions,
): ExperimentsExperimentResultsState {
  switch (action.type) {
    case ActionTypes.FETCH_EXPERIMENT_RESULTS_SUCCESS:
      return {
        ...state,
        results: action.payload,
      };
    default:
      return state;
  }
}
