import React from 'react';
import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';

const bem = new BemHelper('description');

const DescriptionTerm = ({
  children,
  className,
  content,
}) => (
  <dt className={bem.element('term', className)}>
    {children || content}
  </dt>
);

DescriptionTerm.displayName = 'DescriptionTerm';

DescriptionTerm.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  content: PropTypes.string,
};

DescriptionTerm.defaultProps = {
  children: undefined,
  className: '',
  content: '',
};

export default DescriptionTerm;
