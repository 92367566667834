import React from 'react';
import { Form, Field } from 'redux-form';

import GenericError from '../../../../components/GenericError';
import { FeatureReleaseConfirmFormProps } from '../../types/components';

const FeatureReleaseConfirmForm: React.FC<FeatureReleaseConfirmFormProps> = ({
  featureName,
  error,
  handleSubmit,
}) => (
  <Form onSubmit={handleSubmit}>
    <Field component="input" type="hidden" name="organizationId" />
    <Field component="input" type="hidden" name="featureKey" />
    {error && (<GenericError error={error} />)}
    <div>
      Are you sure you want to release
      `
      {featureName}
      `
      ? This action can not be undone.
    </div>
  </Form>
);

FeatureReleaseConfirmForm.displayName = 'FeatureReleaseConfirmForm';

FeatureReleaseConfirmForm.defaultProps = {
  error: undefined,
};

export default FeatureReleaseConfirmForm;
