export enum ActionTypes {
  CHANGE_ROLE_REQUEST = 'ORGANIZATION.CHANGE_ROLE_REQUEST',
  CHANGE_ROLE_SUCCESS = 'ORGANIZATION.CHANGE_ROLE_SUCCESS',
  CHANGE_ROLE_FAILURE = 'ORGANIZATION.CHANGE_ROLE_FAILURE',
  CLOSE_CLEAR_TEXT_KEY_DIALOG = 'ORGANIZATION.CLOSE_CLEAR_TEXT_KEY_DIALOG',
  FETCH_CURRENCIES_REQUEST = 'ORGANIZATION.FETCH_CURRENCIES_REQUEST',
  FETCH_CURRENCIES_SUCCESS = 'ORGANIZATION.FETCH_CURRENCIES_SUCCESS',
  FETCH_CURRENCIES_FAILURE = 'ORGANIZATION.FETCH_CURRENCIES_FAILURE',
  FETCH_MEMBERSHIPS_REQUEST = 'ORGANIZATION.FETCH_MEMBERSHIPS_REQUEST',
  FETCH_MEMBERSHIPS_SUCCESS = 'ORGANIZATION.FETCH_MEMBERSHIPS_SUCCESS',
  FETCH_MEMBERSHIPS_FAILURE = 'ORGANIZATION.FETCH_MEMBERSHIPS_FAILURE',
  FETCH_ORGANIZATION_REQUEST = 'ORGANIZATION.FETCH_ORGANIZATION_REQUEST',
  FETCH_ORGANIZATION_SUCCESS = 'ORGANIZATION.FETCH_ORGANIZATION_SUCCESS',
  FETCH_ORGANIZATION_FAILURE = 'ORGANIZATION.FETCH_ORGANIZATION_FAILURE',
  FETCH_ORGANIZATION_COUNTRIES_REQUEST = 'ORGANIZATION.FETCH_ORGANIZATION_COUNTRIES_REQUEST',
  FETCH_ORGANIZATION_COUNTRIES_SUCCESS = 'ORGANIZATION.FETCH_ORGANIZATION_COUNTRIES_SUCCESS',
  FETCH_ORGANIZATION_COUNTRIES_FAILURE = 'ORGANIZATION.FETCH_ORGANIZATION_COUNTRIES_FAILURE',
  FETCH_PUBLIC_KEYS_REQUEST = 'ORGANIZATION.FETCH_PUBLIC_KEYS_REQUEST',
  FETCH_PUBLIC_KEYS_SUCCESS = 'ORGANIZATION.FETCH_PUBLIC_KEYS_SUCCESS',
  FETCH_PUBLIC_KEYS_FAILURE = 'ORGANIZATION.FETCH_PUBLIC_KEYS_FAILURE',
  FETCH_REGION_SETTINGS_REQUEST = 'ORGANIZATION.FETCH_REGION_SETTINGS_REQUEST',
  FETCH_REGION_SETTINGS_SUCCESS = 'ORGANIZATION.FETCH_REGION_SETTINGS_SUCCESS',
  FETCH_REGION_SETTINGS_FAILURE = 'ORGANIZATION.FETCH_REGION_SETTINGS_FAILURE',
  FETCH_SETTINGS_REQUEST = 'ORGANIZATION.FETCH_SETTINGS_REQUEST',
  FETCH_SETTINGS_SUCCESS = 'ORGANIZATION.FETCH_SETTINGS_SUCCESS',
  FETCH_SETTINGS_FAILURE = 'ORGANIZATION.FETCH_SETTINGS_FAILURE',
  FETCH_USER_MEMBERSHIP_REQUEST = 'ORGANIZATION.FETCH_USER_MEMBERSHIP_REQUEST',
  FETCH_USER_MEMBERSHIP_SUCCESS = 'ORGANIZATION.FETCH_USER_MEMBERSHIP_SUCCESS',
  FETCH_USER_MEMBERSHIP_FAILURE = 'ORGANIZATION.FETCH_USER_MEMBERSHIP_FAILURE',
  FETCH_IMPORTS_REQUEST = 'ORGANIZATION.FETCH_IMPORTS_REQUEST',
  FETCH_IMPORTS_SUCCESS = 'ORGANIZATION.FETCH_IMPORTS_SUCCESS',
  FETCH_IMPORTS_FAILURE = 'ORGANIZATION.FETCH_IMPORTS_FAILURE',
  TOGGLE_IMPORT_DIALOG = 'ORGANIZATION.TOGGLE_IMPORT_DIALOG',
  IMPORT_EVENTSOURCE_CREATE = 'ORGANIZATION.IMPORT_EVENTSOURCE_CREATE',
  IMPORT_EVENTSOURCE_CLOSE = 'ORGANIZATION.IMPORT_EVENTSOURCE_CLOSE',
  IMPORT_FILE_EMPTY = 'ORGANIZATION.IMPORT_FILE_EMPTY',
  IMPORT_FILE_STATUS_FAILURE = 'ORGANIZATION.IMPORT_FILE_STATUS_FAILURE',
  IMPORT_FILE_STATUS_REQUEST = 'ORGANIZATION.IMPORT_FILE_STATUS_REQUEST',
  IMPORT_FILE_STATUS_SUCCESS = 'ORGANIZATION.IMPORT_FILE_STATUS_SUCCESS',
  IMPORT_FILE_REQUEST = 'ORGANIZATION.IMPORT_FILE_REQUEST',
  IMPORT_FILE_SUCCESS = 'ORGANIZATION.IMPORT_FILE_SUCCESS',
  IMPORT_FILE_FAILURE = 'ORGANIZATION.IMPORT_FILE_FAILURE',
  REMOVE_MEMBERSHIP_REQUEST = 'ORGANIZATION.REMOVE_MEMBERSHIP_REQUEST',
  REMOVE_MEMBERSHIP_SUCCESS = 'ORGANIZATION.REMOVE_MEMBERSHIP_SUCCESS',
  REMOVE_MEMBERSHIP_FAILURE = 'ORGANIZATION.REMOVE_MEMBERSHIP_FAILURE',
  RESET_ALERTS = 'ORGANIZATION.RESET_ALERTS',
  SEND_INVITATION_REQUEST = 'ORGANIZATION.SEND_INVITATION_REQUEST',
  SEND_INVITATION_SUCCESS = 'ORGANIZATION.SEND_INVITATION_SUCCESS',
  SEND_INVITATION_FAILURE = 'ORGANIZATION.SEND_INVITATION_FAILURE',
  UPDATE_IMPORT_FILES_STATUS = 'ORGANIZATION.UPDATE_IMPORT_FILES_STATUS',
  UPDATE_REGION_SETTING_REQUEST = 'ORGANIZATION.UPDATE_REGION_SETTING_REQUEST',
  UPDATE_REGION_SETTING_SUCCESS = 'ORGANIZATION.UPDATE_REGION_SETTING_SUCCESS',
  UPDATE_REGION_SETTING_FAILURE = 'ORGANIZATION.UPDATE_REGION_SETTING_FAILURE',
  OPEN_ORGANIZATION_DIALOG = 'OPEN_ORGANIZATION_DIALOG',
  CLOSE_ORGANIZATION_DIALOG = 'CLOSE_ORGANIZATION_DIALOG',
  SET_EDITING_MEMBERSHIP = 'SET_EDITING_MEMBERSHIP',
}

export default ActionTypes;
