import update from 'immutability-helper';

import { ActionTypes } from '../constants';
import { RootActionTypes } from '../../../stores/types';
import { SurchargesState } from '../types/state';

const initialState: SurchargesState = {
  settings: [],
};

export default function surchargesReducer(
  state = initialState,
  action: RootActionTypes,
) {
  switch (action.type) {
    case ActionTypes.FETCH_SURCHARGE_SETTINGS_FAILURE:
    case ActionTypes.FETCH_SURCHARGE_SETTINGS_REQUEST:
      return update(state, {
        settings: {
          $set: [],
        },
      });
    case ActionTypes.FETCH_SURCHARGE_SETTINGS_SUCCESS:
      return update(state, {
        settings: {
          $set: action.payload,
        },
      });
    default:
      return state;
  }
}
