import { css } from '@emotion/css';

export const productDetailsForm = css`
  & .select-field {
    margin-top: 1rem;
  }

  & .button {
    margin-top: 1.5rem;
  }
`;

export const label = css`
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
`;

export const experienceSelect = css`
  padding-top: 1rem !important;
  margin-bottom: 1rem !important;
`;
