import { Edit } from '@flowio/react-icons';
import React, { useState, MouseEventHandler } from 'react';
import { Switch } from '@flowio/react-switch';
import { RadioButtonGroup, RadioButton } from '@flowio/react-radio-button';
import noop from 'lodash/noop';
import remove from 'lodash/remove';
import { css } from '@emotion/react';
import {
  Card, CardHeader, CardContent, CardTitle, CardActions,
} from '@flowio/react-card';
import { OutlineButton } from '@flowio/react-button';
import { CurrencySymbolFormat, CurrencyLabelFormatter } from '@flowio/api-constants';
import { PanelContent, Panel } from '@flowio/react-panel';
import * as styles from './pricing-format.styles';

const panelStyles = css({
  border: 'none',
});

const subCardStyles = css({
  boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.05)',
});

interface Props {
  priceFormat: io.flow.v0.models.ExperienceCurrencyFormat;
  editable: boolean;
  onSave: Function;
  onEdit: MouseEventHandler;
  onCancel: Function;
  onChangeVisibility: (visible: boolean) => void;
  visible: boolean;
}

const PricingFormat: React.FC<Props> = ({
  editable,
  priceFormat,
  onSave,
  onEdit,
  onCancel,
  onChangeVisibility,
  visible,
}) => {
  const [
    selectedOption,
    setSelectedOption,
  ] = useState<io.flow.v0.models.ExperienceCurrencyFormat>({ ...priceFormat });

  const handleFormatChange = (
    format: string,
  ): void => {
    setSelectedOption({
      ...selectedOption,
      symbol: format as CurrencySymbolFormat,
    });
  };

  const handleFormatterChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    formatter: CurrencyLabelFormatter,
  ): void => {
    const labelFormatter = selectedOption.label_formatters;
    if (event.target.checked) {
      labelFormatter.push(formatter);
      setSelectedOption({
        ...selectedOption,
        label_formatters: labelFormatter,
      });
    } else {
      const index = selectedOption
        .label_formatters.findIndex((f) => f === formatter);

      if (index > -1) {
        remove(labelFormatter, (label) => label === labelFormatter[index]);
        setSelectedOption({
          ...selectedOption,
          label_formatters: labelFormatter,
        });
      }
    }
  };

  const handleCancel = (): void => {
    setSelectedOption(priceFormat);
    onCancel();
  };

  const handleSave = (): void => {
    onSave(selectedOption);
  };

  const handleVisibilityChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    onChangeVisibility(event.target.checked);
  };

  return (
    <Card className="pricing-format">
      <CardHeader>
        <CardTitle content="Currency Formats" className={styles.cardTitle} />
        <CardActions className={styles.cardActions}>
          <Switch
            width={75}
            offText="Show"
            onText="Hide"
            onChange={handleVisibilityChange}
            value="visibility"
            checked={visible}
          />
        </CardActions>
      </CardHeader>
      <CardContent>
        <Panel css={panelStyles} collapsible expanded={visible}>
          <PanelContent
            style={{
              border: 'none',
              backgroundColor: '#FFFFFF',
            }}
          >
            <Card css={subCardStyles}>
              <CardContent>
                <div>
                  <strong>Remove Trailing Zeroes</strong>
                  <Switch
                    className={styles.zeroesSwitch}
                    checked={selectedOption.label_formatters.includes('strip_trailing_zeros')}
                    disabled={!editable}
                    onChange={(e) => handleFormatterChange(
                      e, CurrencyLabelFormatter.STRIP_TRAILING_ZEROS,
                    )}
                  />
                </div>
                <div className={styles.description}>
                  &quot;Remove Trailing Zeros&quot; will remove the &quot;.0&quot; from prices
                  so that an item that is &quot;$25.00&quot;
                  will appear as &quot;$25&quot;. Note: This only applies
                  when pricing and rounding rules result in prices
                  ending in &quot;.00&quot; and does not apply
                  {' '}
                  to final order price formatting in checkout
                  (so that all decimals are used in checkout as needed).
                </div>
              </CardContent>
            </Card>
            <Card css={subCardStyles}>
              <CardContent>
                <strong>Currency Symbols</strong>
                <div className={styles.description}>
                  Choose which currency symbol format to display your prices in.
                  Note: In many currencies, the narrow symbol is the same as the primary.
                  View the full list at
                  {' '}
                  <a href="https://api.flow.io/reference/currencies">https://api.flow.io/reference/currencies</a>
                </div>
                <RadioButtonGroup
                  className={styles.formatGroup}
                  onValueChange={handleFormatChange}
                  value={selectedOption.symbol}
                >
                  <RadioButton
                    disabled={!editable}
                    fluid
                    className={styles.labelFormat}
                    name="primary"
                    onChange={noop}
                    value="primary"
                  >
                    Primary
                    {' '}
                    <span className={styles.note}>
                      (e.g. &quot;A$&quot; for AUD,
                      {' '}
                      &quot;HK$&quot; for HKD, &quot;CA$&quot; for CAD,
                      {' '}
                      &quot;CN¥&quot; for CNY, etc.)
                    </span>
                  </RadioButton>
                  <RadioButton
                    disabled={!editable}
                    className={styles.labelFormat}
                    fluid
                    name="narrow"
                    onChange={noop}
                    value="narrow"
                  >
                    Narrow
                    {' '}
                    <span className={styles.note}>
                      (e.g. &quot;$&quot;
                      {' '}
                      for AUD, &quot;$&quot; for HKD, &quot;$&quot;
                      {' '}
                      for CAD, &quot;¥&quot; for CNY, etc.)
                    </span>
                  </RadioButton>
                </RadioButtonGroup>
              </CardContent>
            </Card>
            <div>
              {editable ? (
                <div className={styles.btnContainer}>
                  <OutlineButton
                    content="Cancel"
                    onClick={handleCancel}
                  />
                  <OutlineButton
                    className={styles.buttonSave}
                    content="Save Currency Formats"
                    onClick={handleSave}
                    intent="primary"
                  />
                </div>
              ) : (
                <div className={styles.btnContainer}>
                  <OutlineButton
                    leftIcon={Edit}
                    content="Edit Currency Formats"
                    onClick={onEdit}
                    intent="primary"
                  />
                </div>
              )}
            </div>
          </PanelContent>
        </Panel>
      </CardContent>
    </Card>
  );
};

PricingFormat.displayName = 'PricingFormat';

PricingFormat.defaultProps = {
  priceFormat: {
    label_formatters: [],
    symbol: 'primary',
  },
  editable: false,
  onSave: noop,
  onEdit: noop,
  onCancel: noop,
};

export default PricingFormat;
