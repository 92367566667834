import ActionTypes from '../constants/action-types';
import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import standardAction from '../../../utilities/standard-action';

const createRequestAction = (): RootActionTypes => ({
  type: ActionTypes.FETCH_REFUND_ORDER_SUMMARY_REQUEST,
});

const createSuccessAction = (payload: io.flow.v0.models.RefundOrderSummary): RootActionTypes => ({
  type: ActionTypes.FETCH_REFUND_ORDER_SUMMARY_SUCCESS,
  payload,
});

const createFailureAction = (errors: FormattedErrorMessages): RootActionTypes => ({
  type: ActionTypes.FETCH_REFUND_ORDER_SUMMARY_FAILURE,
  payload: errors,
  error: true,
});

const createNotFoundAction = (): RootActionTypes => standardAction(
  ActionTypes.FETCH_REFUND_ORDER_SUMMARY_NOT_FOUND,
);

export default function fetchRefundOrderSummary(
  organization: string,
  orderNumber: string,
): ThunkResult<Promise<void>> {
  return (dispatch, getState, extra): Promise<void> => {
    dispatch(createRequestAction());
    return extra.api(getState()).refunds.getSummaryByOrderNumber({
      organization,
      order_number: orderNumber,
    }).then((response) => {
      if (response.ok) {
        dispatch(createSuccessAction(response.body));
        return;
      } if (response.status === 404) {
        dispatch(createNotFoundAction());
      } else {
        const errors = formatErrorResponseV2(response);
        dispatch(createFailureAction(errors));
      }
    });
  };
}
