import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { setOverlayProps } from '@flowio/redux-form-overlay';
import { withFetch } from '@flowio/redux-fetch';

import { submit, SubmissionError } from 'redux-form';
import { DELETE_MEMBERSHIP_FORM, MEMBERSHIP_FORM } from '../../../constants/form-names';
import {
  fetchMemberships,
  fetchOrganization,
  fetchUserMembership,
  updateRole,
  sendInvitation,
  setEditingMember,
} from '../../../actions';
import Memberships from '../components/memberships';
import {
  getOrganizationId,
  getMemberships,
  getUserMembership,
  getOrgDialogOpenState,
  getEditingMembership,
} from '../../../selectors';
import { getUserId } from '../../../../console/selectors';
import { ThunkDispatcher, RootState } from '../../../../../stores/types';
import { OwnProps, StateProps, DispatchProps } from '../types';
import { MembershipFormValues } from '../../membership-form/types';
import { getRolesForUser } from '../../../utilities';
import { createToast } from '../../../../console/actions';
import { ActionTypes, DialogName } from '../../../constants';
import closeDialog from '../../../actions/close-dialog';
import { getIsFlowEmployee } from '../../../../user';

function getAsyncState(
  dispatch: ThunkDispatcher,
  getState: () => RootState,
  ownProps: OwnProps,
): Promise<unknown[]> {
  const { organization } = ownProps.params;
  return Promise.all([
    dispatch(fetchOrganization(organization)),
    dispatch(fetchMemberships(organization)),
    dispatch(fetchUserMembership(organization, getUserId(getState()))),
  ]);
}

export function mapValuesToInvitationForm(
  values: MembershipFormValues,
): io.flow.v0.models.InvitationForm {
  return {
    ...values,
    ...getRolesForUser(values.role),
  };
}

const handleFormSubmit = (
  values: MembershipFormValues,
  editMembership: io.flow.v0.models.Membership | null,
) => (
  dispatch: ThunkDispatcher,
): Promise<unknown> => {
  const updatePromises: Promise<unknown>[] = [];
  if (editMembership) {
    updatePromises.push(dispatch(updateRole(
      editMembership,
      getRolesForUser(values.role),
    )).then((response) => {
      if (response.ok) {
        dispatch(fetchMemberships(values.organization));
        return response.result as io.flow.v0.models.Membership;
      }

      throw new SubmissionError({
        _error: response.result,
      });
    }).then(() => {
      dispatch(createToast({
        content: `Profile of "${(editMembership.user as io.flow.v0.models.User).email}" has been updated.`,
        icon: 'InfoCircle',
        intent: 'positive',
      }));
    }));
  } else {
  // TBD backend impl and resulting redux actions.
    updatePromises.push(
      dispatch(sendInvitation(mapValuesToInvitationForm(values))).then((response) => {
        if (!response.ok) {
          throw new SubmissionError({
            _error: response.result,
          });
        }

        return response.result as io.flow.v0.models.Invitation;
      }).then((invite) => {
        dispatch(createToast({
          content: `Invite sent to "${invite.email}"`,
          icon: 'CheckCircle',
        }));
      }),
    );
  }
  return Promise.all(updatePromises).then(() => {
    dispatch(closeDialog(DialogName.MembershipInvite));
  });
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = (state) => ({
  editingMembership: getEditingMembership(state),
  organizationKey: getOrganizationId(state),
  memberships: getMemberships(state),
  membershipDialogOpen: getOrgDialogOpenState(DialogName.MembershipInvite)(state),
  user: getUserMembership(state),
  isFlowEmployee: getIsFlowEmployee(state),
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch: ThunkDispatcher,
) => ({
  onRequestSubmitInvitationForm(): void {
    dispatch(submit(MEMBERSHIP_FORM));
  },
  onFormSubmit(
    values: MembershipFormValues,
    editMembership: io.flow.v0.models.Membership | null,
  ): void {
    dispatch(handleFormSubmit(values, editMembership));
  },
  onOpenMembershipDialog: (membership?: io.flow.v0.models.Membership): void => {
    dispatch(setEditingMember(membership));
    dispatch({
      type: ActionTypes.OPEN_ORGANIZATION_DIALOG,
      payload: DialogName.MembershipInvite,
    });
  },
  onCloseMembershipDialog: (): void => {
    dispatch(closeDialog(DialogName.MembershipInvite));
  },
  onRemoveMember(membership): void {
    dispatch(setOverlayProps(DELETE_MEMBERSHIP_FORM, {
      initialValues: {
        id: membership.id,
      },
      intent: 'negative',
      membership,
      open: true,
    }));
  },
});

export default withFetch(getAsyncState)(connect(mapStateToProps, mapDispatchToProps)(Memberships));
