import { combineReducers } from 'redux';
import assign from 'lodash/assign';
import map from 'lodash/map';
import update from 'immutability-helper';
import ActionTypes from '../constants/action-types';
import ReadyState from '../constants/ready-state';
import { RootActionTypes } from '../../../stores/types';
import { StatementsPageState, StatementsPagesState } from '../types';

function pages(state = {}, action: RootActionTypes): StatementsPagesState {
  switch (action.type) {
    case ActionTypes.FETCH_STATEMENTS_REQUEST:
      return update(state, {
        [action.params.pageNumber]: {
          $apply: (page: StatementsPageState): StatementsPageState => assign({}, page, {
            status: ReadyState.LOADING,
            entriesPerPage: action.params.entriesPerPage,
            pageNumber: action.params.pageNumber,
          }),
        },
      });
    case ActionTypes.FETCH_STATEMENTS_SUCCESS:
      return update(state, {
        [action.params.pageNumber]: {
          $merge: {
            ids: map(action.payload.results, 'id'),
            status: ReadyState.FULFILLED,
            isFirstPage: action.payload.isFirstPage,
            isLastPage: action.payload.isLastPage,
          },
        },
      });
    case ActionTypes.FETCH_STATEMENTS_FAILURE:
      return update(state, {
        [action.params.pageNumber]: {
          $merge: {
            error: action.payload,
            status: ReadyState.REJECTED,
          },
        },
      });
    default:
      return state;
  }
}

function currentPage(state = 1, action: RootActionTypes): number {
  switch (action.type) {
    case ActionTypes.FETCH_STATEMENTS_REQUEST:
      return state;
    case ActionTypes.FETCH_STATEMENTS_SUCCESS:
      return action.params.pageNumber;
    case ActionTypes.FETCH_STATEMENTS_FAILURE:
      return action.params.pageNumber;
    default:
      return state;
  }
}

export default combineReducers({
  currentPage,
  pages,
});
