import { ActionTypes } from './constants';
import { api } from '../../utilities/clients';
import { getOrganizationId } from '../organization';

export function getWebhook(organization, webhookId) {
  return {
    types: [
      ActionTypes.GET_WEBHOOK_REQUEST,
      ActionTypes.GET_WEBHOOK_SUCCESS,
      ActionTypes.GET_WEBHOOK_FAILURE,
    ],
    callAPI: (state) => api.webhooks(state).getById(organization, webhookId),
  };
}

export function getWebhookDelivery(organization, requestId) {
  return {
    types: [
      ActionTypes.GET_WEBHOOK_DELIVERY_REQUEST,
      ActionTypes.GET_WEBHOOK_DELIVERY_SUCCESS,
      ActionTypes.GET_WEBHOOK_DELIVERY_FAILURE,
    ],
    callAPI: (state) => api.webhookDeliveries(state).getById(organization, requestId),
  };
}

export function getWebhookDeliveries(organization, webhookId, params = {}) {
  return {
    types: [
      ActionTypes.GET_WEBHOOK_DELIVERIES_REQUEST,
      ActionTypes.GET_WEBHOOK_DELIVERIES_SUCCESS,
      ActionTypes.GET_WEBHOOK_DELIVERIES_FAILURE,
    ],
    callAPI: (state) => {
      const query = { webhook_id: webhookId, limit: 10, offset: 0 };

      if (params.entriesPerPage) {
        query.limit = parseInt(params.entriesPerPage, 10);
      }

      if (params.pageNumber) {
        query.offset = (parseInt(params.pageNumber, 10) - 1) * query.limit;
      }

      // By requesting an additional item as part of the request we can determine whether the user
      // has reached the last page. Later we just need to make sure we are not sending more items
      // than requested.
      query.limit += 1;

      return api.webhookDeliveries(state).get(organization, {
        params: query,
      }).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          const result = { results: response.result };

          result.isFirstPage = query.offset === 0;
          result.isLastPage = result.results.length !== query.limit;

          if (!result.isLastPage) {
            result.results = result.results.slice(0, result.results.length - 1);
          }

          return { status: response.status, result };
        }

        return response;
      });
    },
  };
}

export function createWebhook(formData) {
  return {
    types: [
      ActionTypes.CREATE_WEBHOOK_REQUEST,
      ActionTypes.CREATE_WEBHOOK_SUCCESS,
      ActionTypes.CREATE_WEBHOOK_FAILURE,
    ],
    callAPI: (state) => api.webhooks(state).post(getOrganizationId(state), {
      body: JSON.stringify(formData),
    }),
  };
}

export function updateWebhook(webhookId, formData) {
  return {
    types: [
      ActionTypes.UPDATE_WEBHOOK_REQUEST,
      ActionTypes.UPDATE_WEBHOOK_SUCCESS,
      ActionTypes.UPDATE_WEBHOOK_FAILURE,
    ],
    callAPI: (state) => api.webhooks(state).putById(getOrganizationId(state), webhookId, {
      body: JSON.stringify(formData),
    }),
    payload: { webhookId },
  };
}

export function deleteWebhook(webhookId) {
  return {
    types: [
      ActionTypes.DELETE_WEBHOOK_REQUEST,
      ActionTypes.DELETE_WEBHOOK_SUCCESS,
      ActionTypes.DELETE_WEBHOOK_FAILURE,
    ],
    callAPI: (state) => api.webhooks(state).deleteById(getOrganizationId(state), webhookId, {
      // Prevents weird error in API calls with empty body
      body: JSON.stringify({}),
    }),
    payload: { webhookId },
  };
}

export function redeliver(deliveryId) {
  return {
    types: [
      ActionTypes.REDELIVER_REQUEST,
      ActionTypes.REDELIVER_SUCCESS,
      ActionTypes.REDELIVER_FAILURE,
    ],
    callAPI: (state) => api.webhookDeliveries(state)
      .postRequestsById(getOrganizationId(state), deliveryId, {
        // Prevents weird error in API calls with empty body
        body: JSON.stringify({}),
      }),
    payload: { deliveryId },
  };
}

export function syncDeliveries(organization, deliveryIds) {
  return {
    types: [
      ActionTypes.SYNC_WEBHOOK_DELIVERIES_REQUEST,
      ActionTypes.SYNC_WEBHOOK_DELIVERIES_SUCCESS,
      ActionTypes.SYNC_WEBHOOK_DELIVERIES_FAILURE,
    ],
    callAPI: (state) => api.webhookDeliveries(state).get(organization, {
      params: {
        id: deliveryIds,
      },
    }),
  };
}
