import React from 'react';
import { Field } from 'redux-form';
import { Search } from '@flowio/react-icons';
import { FeatureListSearchProps } from '../../types/components';
import { TextField } from '../../../../components/ReduxFormFieldAdapters';

const FeatureListSearch: React.FC<FeatureListSearchProps> = ({
  classNames,
  disabled,
}) => (
  <Field
    name="featureSearch"
    hintText="Enter Feature Name or Key..."
    rightIcon={Search}
    className={classNames}
    type="text"
    fluid
    disabled={disabled}
    component={TextField}
    clearable
  />
);

FeatureListSearch.displayName = 'FeatureListSearch';

FeatureListSearch.defaultProps = {
  classNames: '',
  disabled: false,
};

export default FeatureListSearch;
