import { browserHistory } from 'react-router';

export default function changeOptinMessagesPage(
  organizationId: string,
  pageNumber: number,
  entriesPerPage: number,
): void {
  return browserHistory.push({
    pathname: `/${organizationId}/optin/messages`,
    query: {
      entriesPerPage,
      pageNumber,
    },
  });
}
