/* eslint-disable class-methods-use-this, global-require, max-len, prefer-destructuring, prefer-promise-reject-errors */

import { v1 as uuidV1 } from 'uuid';
import { EventEmitter } from 'events';
import querystring, { ParsedUrlQueryInput } from 'querystring';
import { $FetchFunction, $FetchOptions } from '@flowio/api-internal-sdk';
import {
  FlowRequestInitOptions,
  FlowResponse,
  LegacyResponse,
  Options,
} from '../types/server';

/**
 * Use the right implementation of fetch depending on the execution environment
 */

let fetch: $FetchFunction;

type FetchOptions = $FetchOptions & FlowRequestInitOptions;

interface BasicAuth {
  type: 'basic';
  value: string;
}
interface BearerAuth {
  type: 'bearer';
  value: string;
}
interface JwtAuth {
  type: 'jwt';
  value: string;
}
interface FailedAuth {
  type: 'none';
  value: string;
}

type Auth = BasicAuth | BearerAuth | JwtAuth | FailedAuth | string;

if (process.browser) {
  fetch = window.fetch;
} else {
  fetch = require('node-fetch');
}

export default class Client extends EventEmitter {
  host: string | undefined;

  auth: string;

  headers: Record<string, string> | undefined;

  serviceName: string | undefined;

  constructor(opts: Options = {}) {
    super();
    this.serviceName = opts.serviceName;
    this.auth = opts.auth || '';
    this.host = opts.host;
    this.headers = opts.headers || {};

    // Convert auth if provided in constructor
    if (this.auth) {
      this.auth = this.possiblyConvertAuthorizationHeader(this.auth);
    }
  }

  logRequest<T>(opts: T): void {
    this.emit('request', opts);
  }

  logResponse<T>(response: T): void {
    this.emit('response', response);
  }

  withAuth(auth: string): this {
    if (auth) {
      this.auth = this.possiblyConvertAuthorizationHeader(auth);
    }

    return this;
  }

  withHeaders(headers: Record<string, string>): this {
    if (headers) {
      this.headers = { ...this.headers, ...headers };
    }

    return this;
  }

  validateAuth(auth?: Auth): void {
    // string type === default to Basic auth.
    if (typeof auth === 'undefined' || typeof auth === 'string') {
      return;
    }

    if (Object.keys(auth).length !== 2
      || (!Object.prototype.hasOwnProperty.call(auth, 'type') || !Object.prototype.hasOwnProperty.call(auth, 'value'))) {
      throw new Error(
        'Expected auth to be either a string or a valid auth object. '
        + 'Example: { type: "jwt", value: "<jwtToken>" } -- '
        + 'Valid types are: basic, bearer and jwt',
      );
    }

    const validTypes = ['basic', 'bearer', 'jwt'];
    if (!validTypes.includes(auth.type)) {
      throw new Error('Auth type must be one of: basic, bearer, jwt');
    }
  }

  getBasicAuthHeaderValue(auth: string): string {
    return `Basic ${Buffer.from(auth).toString('base64')}`;
  }

  possiblyConvertAuthorizationHeader(auth: Auth): string {
    this.validateAuth(auth);

    if (typeof auth === 'string') {
      return this.getBasicAuthHeaderValue(auth);
    }

    if (auth.type === 'basic') {
      return this.getBasicAuthHeaderValue(auth.value);
    }

    if (auth.type === 'bearer' || auth.type === 'jwt') {
      return `Bearer ${auth.value}`;
    }

    throw new Error(`Cannot create authorization header of type[${auth.type}]`);
  }

  getFinalUrl(url: string, opts: { params?: ParsedUrlQueryInput }): string {
    const queryStr = querystring.stringify(opts.params);
    const paramString = queryStr ? `?${queryStr}` : '';
    return `${url}${paramString}`;
  }

  calculateFinalHeaders(opts: { params?: ParsedUrlQueryInput; headers?: Record<string, string> }): Record<string, string> {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: '',
    };

    if (this.auth) {
      headers.Authorization = this.auth;
    }

    return Object.assign(headers, opts.headers);
  }

  makeRequest(url: string, opts: { method?: string; params?: ParsedUrlQueryInput; headers?: Record<string, string> } = {}): Promise<LegacyResponse> {
    const startTimeMs = new Date().getTime();
    const finalUrl = this.getFinalUrl(url, opts);
    const requestId = `con${uuidV1()}`;
    const headers = this.calculateFinalHeaders(opts);

    const options: FetchOptions = {
      credentials: 'same-origin',
      requestId,
      ...opts as Partial<FetchOptions>,
      headers,
    };

    function handleResponse(response: Response, time: number, logger: (e: FlowResponse) => void): Promise<LegacyResponse> {
      return new Promise((resolve, reject) => {
        response.text().then((text) => {
          let result = text;

          logger({
            status: response.status, body: result, requestId, time,
          });

          try {
            if (text.length > 0) {
              result = JSON.parse(text);
            }
          } catch (ex) {
            // do nothing, let it be plain text.
          }

          const envelope = {
            status: response.status,
            ok: response.status >= 200 && response.status < 300,
            result,
          };

          resolve(envelope);
        }).catch((error) => {
          reject(error);
        });
      });
    }

    this.logRequest({ ...options, url });
    const logResponse = this.logResponse.bind(this);

    return fetch(finalUrl, options)
      .then((response: Response) => {
        const endTimeMs = new Date().getTime();
        const roundTripMs = endTimeMs - startTimeMs;

        if (response.ok || response.status < 500) {
          return handleResponse(response, roundTripMs, logResponse);
        }

        return response.text().then((text: string) => Promise.reject({
          status: response.status,
          error: new Error(`Request to url[${finalUrl}] failed with status[${response.status}].`),
          result: text,
        }));
      });
  }
}
