import { ExperiencesGetMarginsByExperienceKeyAndKeyResponse } from '@flowio/api-sdk';
import ActionTypes from '../constants/action-types';
import {
  ThunkExtraArguments,
  RootState,
  ThunkDispatcher,
  ThunkResult,
} from '../../../stores/types';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import toLegacyResponse from '../../../utilities/clients/to-legacy-response';
import { PropType } from '../../../types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';

type ResponseType = PropType<ExperiencesGetMarginsByExperienceKeyAndKeyResponse, 'body'>;

export default function getMargin(
  organization: string,
  experienceKey: string,
  marginKey: string,
): ThunkResult<Promise<LegacyResponse<ResponseType>>> {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<LegacyResponse<ResponseType>> => {
    dispatch({
      type: ActionTypes.GET_MARGIN_REQUEST,
    });

    return extra.api(getState()).experiences.getMarginsByExperienceKeyAndKey({
      organization,
      experience_key: experienceKey,
      key: marginKey,
    }).then((response) => {
      if (response.ok) {
        dispatch({
          type: ActionTypes.GET_MARGIN_SUCCESS,
          payload: response.body,
        });
      } else {
        dispatch({
          type: ActionTypes.GET_MARGIN_FAILURE,
          payload: formatErrorResponseV2(response),
        });
      }
      return toLegacyResponse<ResponseType>(response);
    });
  };
}
