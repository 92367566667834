import get from 'lodash/get';
import map from 'lodash/map';
import uniqueId from 'lodash/uniqueId';

import { DialogName, FormMethod } from '../constants';
import { createCenterQueryBuilder } from '../../query-builder/actions';
import { fetchCountries, fetchRegions } from '../../reference/actions';
import { getCenterQueryBuilder } from '../../query-builder/selectors';
import { getRegions } from '../../reference/selectors';
import queryCenters from './queryCenters';
import updateDialog from './updateDialog';
import { ThunkResult } from '../../../stores/types';

interface ShowShippingLaneWorksheetParams {
  method: FormMethod;
  organizationId: string;
  shippingConfigurationKey: string;
  shippingLane?: io.flow.v0.models.ShippingLane;
  shippingLaneDirection?: io.flow.v0.enums.LaneDirection;
  title: string;
}

function queryBuilderFormFromShippingLane(
  shippingLane?: io.flow.v0.models.ShippingLane,
): io.flow.v0.unions.QueryBuilderForm {
  return {
    discriminator: 'query',
    // Since a shipping lane is nullable, ensure at least an empty string
    // is sent to the query builder service.
    q: get(shippingLane, 'query.q', ''),
  };
}

/**
 * An action responsible for loading the asynchronous shipping lane worksheet.
 * @param {FormMethod} props.method
 * @param {String} props.organizationId
 * @param {String} props.shippingConfigurationKey
 * @param {?ShippingLane} props.shippingLane
 */
const showShippingLaneWorksheet = ({
  method,
  organizationId,
  shippingConfigurationKey,
  shippingLane,
  shippingLaneDirection,
  title,
}: ShowShippingLaneWorksheetParams): ThunkResult<Promise<void>> => (
  dispatch,
  getState,
) => Promise.all([
  // Show dialog in loading state.
  dispatch(updateDialog(DialogName.SHIPPING_LANE_WORKSHEET, {
    method,
    loading: true,
    open: true,
    title,
  })),
  // Create a query builder to compute available filters to display in
  // shipping lane upsert form.
  dispatch(createCenterQueryBuilder({
    organizationId,
    queryBuilderForm: queryBuilderFormFromShippingLane(shippingLane),
  })),
  // Fetch countries for CenterQueryResult container which renders country flags per center.
  // IMPORTANT! The countries are read directly from the reference
  // state by said container, therefore we do not include as part of the
  // the dialog data in the action dispatched below.
  dispatch(fetchCountries()),
  // Fetch regions for region selection in shipping lane upsert form.
  dispatch(fetchRegions()),
  // Query centers matching shipping lane query filter rules.
  dispatch(queryCenters({
    organizationId,
    queryBuilderForm: queryBuilderFormFromShippingLane(shippingLane),
  })),
]).then(() => {
  const regions = getRegions(getState());
  const queryBuilder = getCenterQueryBuilder(getState());

  dispatch(updateDialog(DialogName.SHIPPING_LANE_WORKSHEET, {
    availableFilters: queryBuilder.available,
    centers: [],
    loading: false,
    open: true,
    method,
    regions,
    title,
    initialValues: {
      organizationId,
      regionId: get(shippingLane, 'region'),
      shippingLaneId: get(shippingLane, 'id'),
      shippingLaneDirection: shippingLaneDirection || get(shippingLane, 'direction'),
      shippingConfigurationKey,
      queryFilters: map(queryBuilder.filters, (filter) => ({
        ...filter,
        // Add a unique identifier that we can use as the component key for
        // FLIP animation to work correctly.
        // https://github.com/erikras/redux-form/issues/2735
        uniqueId: uniqueId('filter'),
      })),
    },
  }));
});

export default showShippingLaneWorksheet;
