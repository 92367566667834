import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import generateColorScale from '../generate-color-scale';

export default function createExperimentTooltipHtml(
  data: io.flow.internal.v0.models.ExperimentResultsWithTimestamp,
  styles: Record<string, string>,
  valueKey: keyof io.flow.internal.v0.models.ExperimentResults,
  keyToLabel: Partial<io.flow.internal.v0.models.ExperimentResults>,
  idToName: { [prop: string]: string },
): string {
  const colorScale = generateColorScale();
  return ReactDOMServer.renderToStaticMarkup(
    <table>
      <thead>
        <tr>
          <th className={styles.nameColumn}>{moment.utc(data.timestamp).format('MMM DD, YYYY')}</th>
          <th className={styles.valueColumn}>{keyToLabel[valueKey]}</th>
        </tr>
      </thead>
      <tbody>
        {data.results.map((res, pointIndex) => (

          <tr>
            <td className={styles.nameColumn}>
              <div
                className={styles.legendRect}
                style={{ backgroundColor: colorScale(pointIndex) }}
              />
              <span>{idToName[res.experiment_variant_key]}</span>
            </td>
            <td className={styles.valueColumn}>{numeral((res)[valueKey]).format('0.[00]a')}</td>
          </tr>
        ))}
      </tbody>
    </table>,
  );
}
