import { css } from '@emotion/react';
import styled from '@emotion/styled';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';
import React from 'react';
import { Banner } from '@flowio/react-banner';
import { Button, OutlineButton } from '@flowio/react-button';
import { Alert } from '@flowio/react-icons';
import { colors, fontWeight, spacing } from '../../../../../theme/tokens';
import Platforms from '../../../constants/Platforms';
import MarketingDialog from '../../MarketingDialog';
import { Props } from '../types';

const MetaInfoList = styled.ul`
  list-style: none;
  font-weight: 400;
  padding: 0;
`;

const bannerStyles = css({
  alignItems: 'flex-start',
  p: {
    marginTop: '0',
  },
  a: {
    textDecoration: 'underline',
  },
  'p,a': {
    color: colors.yellow[500],
    fontWeight: fontWeight.medium,
  },
  svg: {
    width: spacing.xxl,
    color: colors.yellow[300],
    marginRight: spacing.l,
  },
});

const DisconnectChannelDialog: React.FC<Props> = ({
  hideModal,
  isOpen,
  platform,
  channels,
  onDisconnectChannel,
}) => {
  const title = `Are you sure you want to disconnect ${Platforms(platform).platformName}?`;
  const { manual } = channels;
  const connectedManualChannels = !isEmpty(manual.connected);
  const manualChannel = manual.connected && Object.keys(
    manual.connected,
  ).find((c) => c === platform);
  const manualChannelExists = manualChannel !== undefined;
  const getMetaInfo = () => {
    if (manualChannelExists) {
      return `These feeds will still be available on ${Platforms(platform).platformName}. To permanently delete them you will need to do so on ${Platforms(platform).platformName}. `;
    }
    if (platform === 'google' && connectedManualChannels) {
      const { connected } = manual;
      return (
        <>
          This will also prevent the following channels dependant on your
          {' '}
          { platform }
          {' '}
          product feed from updating.
          <MetaInfoList>
            { Object.keys(connected).map((c) => (
              <li>
                {'- '}
                {startCase(c)}
              </li>
            ))}
            {' '}
          </MetaInfoList>
        </>
      );
    }

    return '';
  };

  const getBannerText = (platformName: string) => {
    if (manualChannelExists) {
      return `Removing connection will stop Flow generated feeds from updating on ${platformName}. `;
    }
    return `Disconnecting will delete all Flow generated feeds from your ${platformName}. `;
  };

  const banner: JSX.Element = (
    <Banner
      intent="warning"
      css={bannerStyles}
      icon={Alert}
    >
      <p>
        {getBannerText(Platforms(platform).platformName)}
      </p>

      <p>
        {getMetaInfo()}
      </p>

    </Banner>
  );

  return (
    <MarketingDialog
      title={title}
      subtitle={banner}
      isOpen={isOpen}
    >
      <OutlineButton
        content="Cancel"
        onClick={hideModal}
      />
      <Button
        intent="primary"
        content={`Yes, ${manualChannelExists ? 'Remove Connection' : 'Disconnect'}`}
        onClick={() => onDisconnectChannel(platform, manualChannelExists !== undefined)}
      />
    </MarketingDialog>
  );
};

DisconnectChannelDialog.displayName = 'DisconnectChannelDialog';

export default DisconnectChannelDialog;
