import { Form, Field, FieldArray } from 'redux-form';
import { LaneDirection } from '@flowio/api-constants';
import { Card, CardHeader, CardContent } from '@flowio/react-card';
import React from 'react';
import { Stack } from '@flowio/react-stack';
import get from 'lodash/get';

import findRegionById from '../../../reference/utilities/findRegionById';
import GenericError from '../../../../components/GenericError';
import CenterQueryBuilderCard from '../CenterQueryBuilderCard';
import RegionFlag from '../../../reference/components/RegionFlag';
import { ShippingLaneUpsertFormCombinedProps } from '../../types/components';
import { SelectField } from '../../../../components/ReduxFormFieldAdapters';

const formatRegion = (
  regions: io.flow.v0.models.Region[],
) => (value: string) => findRegionById(regions, value);

const parseRegion = (value: io.flow.v0.models.Region) => get(value, 'id');

const ShippingLaneUpsertForm: React.FC<ShippingLaneUpsertFormCombinedProps> = ({
  availableFilters,
  error,
  handleSubmit,
  method,
  regions,
  selectedRegionId,
  shippingLaneDirection,
}) => {
  const region = findRegionById(regions, selectedRegionId);
  return (
    <Form method={method} onSubmit={handleSubmit}>
      <Field component="input" type="hidden" name="organizationId" />
      <Field component="input" type="hidden" name="shippingConfigurationKey" />
      <Field component="input" type="hidden" name="shippingLaneId" />
      <Stack orientation="vertical" spacing="loose">
        {error && (<GenericError error={error} />)}
        <Card>
          <CardHeader caption={shippingLaneDirection === LaneDirection.OUTBOUND ? 'To: Region' : 'From: Region'} dividing />
          <CardContent>
            <Field
              component={SelectField}
              name="regionId"
              format={formatRegion(regions)}
              parse={parseRegion}
              autoComplete="off"
              textInputProps={{ leftIcon: region && <RegionFlag region={region} /> }}
              fluid
              searchable
              selection
              options={regions}
              labelKey="name"
              valueKey="id"
              scrollableMenu
            />
          </CardContent>
        </Card>
        <FieldArray
          component={CenterQueryBuilderCard}
          name="queryFilters"
          props={{
            availableFilters,
            shippingLaneDirection,
          }}
        />
      </Stack>
    </Form>
  );
};

ShippingLaneUpsertForm.displayName = 'ShippingLaneUpsertForm';

ShippingLaneUpsertForm.defaultProps = {
  error: undefined,
};

export default ShippingLaneUpsertForm;
