import classNames from 'classnames';
import camelCase from 'lodash/camelCase';
import trim from 'lodash/trim';
import React from 'react';
import { ExperimentVariantSummary } from '../../../types';
import * as styles from './experiment-variant.styles';

interface Props {
  variant: io.flow.internal.v0.unions.Variant;
}

const ExperimentVariant: React.FC<Props> = ({
  variant,
}) => {
  let summary: ExperimentVariantSummary;
  if (variant.discriminator === 'experience_variant') {
    summary = variant.experience;
  } else {
    summary = variant.value;
  }
  const variantViewModel = {
    key: summary.key,
    name: summary.name,
    trafficPercentage: variant.traffic_percentage,
    className: camelCase(variant.discriminator),
  };

  return (
    <div
      className={classNames(styles.trafficRow, variantViewModel.className)}
      key={trim(variantViewModel.name)}
    >
      <span className={styles.trafficExperience}>
        {variantViewModel.name}
      </span>
      <span>
        {`${variantViewModel.trafficPercentage}% Traffic`}
      </span>
    </div>
  );
};

export default ExperimentVariant;
