import { Edit } from '@flowio/react-icons';
import { OutlineButton } from '@flowio/react-button';
import {
  Card, CardHeader, CardContent, CardFooter, CardDescription, CardTitle,
} from '@flowio/react-card';
import { Select } from '@flowio/react-select';
import React, { useState } from 'react';
import find from 'lodash/find';
import noop from 'lodash/noop';
import { PricingLevySetting } from '@flowio/api-constants';
import * as styles from './pricing-display.styles';
import getTextFieldValue from '../../../../utilities/getTextFieldValue';

const PricingLevySettingDisplayText = {
  [PricingLevySetting.INCLUDED]: 'Included',
  [PricingLevySetting.DISPLAYED]: 'Displayed',
  [PricingLevySetting.IGNORED]: 'Only in Checkout',
};

interface PricingLevySettingOption {
  content: string;
  value: PricingLevySetting;
}

const pricingLevySettingOptions: PricingLevySettingOption[] = [{
  content: PricingLevySettingDisplayText[PricingLevySetting.INCLUDED],
  value: PricingLevySetting.INCLUDED,
}, {
  content: PricingLevySettingDisplayText[PricingLevySetting.DISPLAYED],
  value: PricingLevySetting.DISPLAYED,
}, {
  content: PricingLevySettingDisplayText[PricingLevySetting.IGNORED],
  value: PricingLevySetting.IGNORED,
}];

interface Props {
  pricing: io.flow.v0.models.Pricing;
  editable: boolean;
  onEdit: Function;
  onCancel: Function;
  onSave: Function;
}

const PricingDisplay: React.FC<Props> = ({
  pricing,
  onEdit,
  onCancel,
  onSave,
  editable,
}) => {
  const [statePricing, setStatePricing] = useState<io.flow.v0.models.Pricing>({ ...pricing });

  const handleEdit = (): void => {
    onEdit();
  };

  const handleDutyChange = (
    value: string,
  ): void => {
    setStatePricing({
      ...statePricing,
      duty: value as PricingLevySetting,
    });
  };

  const handleVatChange = (
    value: string,
  ): void => {
    setStatePricing({
      ...statePricing,
      vat: value as PricingLevySetting,
    });
  };

  const handleCancel = (): void => {
    handleDutyChange(pricing.duty as PricingLevySetting);
    handleVatChange(pricing.vat as PricingLevySetting);
    onCancel();
  };

  const handleSave = (): void => {
    onSave(statePricing);
  };

  const renderFormIfNecessary = (): React.ReactNode => {
    if (editable) {
      return (
        <section className={styles.settingsForm}>
          <div className={styles.setting}>
            <div className={styles.settingLabel}>
              Duties
              <em className={styles.settingEm}>
                I.E. Product Price $9.99 + Duties $5.00 = $14.99
              </em>
            </div>
            <Select
              onValueChange={handleDutyChange}
              options={pricingLevySettingOptions}
              value={getTextFieldValue(
                find(pricingLevySettingOptions, { value: statePricing.duty }),
                'value',
              )}
            />
          </div>
          <div className={styles.setting}>
            <div className={styles.settingLabel}>
              Tax
              <em className={styles.settingEm}>
                I.E. Product Price $9.99 + Tax $0.63 = $10.62
              </em>
            </div>
            <Select
              onValueChange={handleVatChange}
              options={pricingLevySettingOptions}
              value={getTextFieldValue(
                find(pricingLevySettingOptions, { value: statePricing.vat }),
                'value',
              )}
            />
          </div>
        </section>
      );
    }

    return null;
  };

  const renderSummaryIfNeccesary = (): React.ReactNode => {
    if (editable) {
      return null;
    }

    return (
      <section className={styles.settings}>
        <div className={styles.setting}>
          <span className={styles.settingLabel}>
            Duties
          </span>
          <span className={styles.settingInclusion}>
            {PricingLevySettingDisplayText[pricing.duty]}
          </span>
        </div>
        <div className={styles.setting}>
          <span className={styles.settingLabel}>
            Tax
          </span>
          <span className={styles.settingInclusion}>
            {PricingLevySettingDisplayText[pricing.vat]}
          </span>
        </div>
      </section>
    );
  };

  return (
    <Card className="pricing-displays" id="display">
      <CardHeader dividing>
        <CardTitle>
          Pricing Display
        </CardTitle>
        <CardDescription>
          In this section, define what pricing elements are included in the item price on your
          site defined by you. For example, if the base price of your shoes is $10 and you want
          to add tax ($3) and duties ($2) to that, the new display price would be $15.
        </CardDescription>
      </CardHeader>
      <CardContent>
        {renderFormIfNecessary()}
        {renderSummaryIfNeccesary()}
      </CardContent>
      <CardFooter>
        {editable ? (
          <section className="right-align">
            <OutlineButton
              content="Cancel"
              name="cancel_edit"
              onClick={handleCancel}
            />
            <OutlineButton
              className="ml2"
              content="Save"
              name="save_display"
              onClick={handleSave}
              intent="primary"
            />
          </section>
        ) : (
          <section className="right-align">
            <OutlineButton
              leftIcon={Edit}
              className="ml2"
              content="Edit Display"
              name="edit_display"
              onClick={handleEdit}
              intent="primary"
            />
          </section>
        )}
      </CardFooter>
    </Card>
  );
};

PricingDisplay.displayName = 'PricingDisplay';

PricingDisplay.defaultProps = {
  editable: false,
  onEdit: noop,
  onCancel: noop,
  onSave: noop,
};

export default PricingDisplay;
