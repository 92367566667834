import curry from 'lodash/fp/curry';
import find from 'lodash/fp/find';
import map from 'lodash/fp/map';
import concat from 'lodash/fp/concat';

/**
 * @description Returns a new array with the value matching predicate replaced with the value
 * provided. If a value does not match, the provided value will be appended to
 * the array.
 */
const upsertBy = curry(<T>(predicate: Function, value: T, array: Array<T>): Array<T> => {
  const match = find((item: T, index: number) => predicate(value, item, index, array), array);
  if (match) return map((item) => (item === match ? value : item), array);
  return concat(value, array);
});

export default upsertBy;
