import { browserHistory } from 'react-router';
import invariant from 'invariant';

function changeExclusionRulesPage(
  organizationId: string,
  pageNumber: number,
  entriesPerPage: number,
) {
  invariant(organizationId, 'An organization identifier is required');
  invariant(pageNumber, 'A page number is required');
  invariant(entriesPerPage, 'A page size is required');
  return browserHistory.push(`/${organizationId}/catalog/exclusions?entriesPerPage=${encodeURIComponent(entriesPerPage)}&pageNumber=${encodeURIComponent(pageNumber)}`);
}

export default changeExclusionRulesPage;
