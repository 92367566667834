import isNil from 'lodash/isNil';
import filter from 'lodash/filter';
import { getRollbar } from '@flowio/redux-rollbar-middleware/lib/rollbar';

import ResultTypes from '../constants/ResultTypes';

export default function normalizeSearchResults(results) {
  const options = results.map((result, index) => {
    switch (result.discriminator) {
      case ResultTypes.SEARCH_ITEM_SUMMARY:
        return {
          index,
          q: result.name,
          id: result.number,
          type: 'Item',
        };
      case ResultTypes.SEARCH_EXPERIENCE_SUMMARY:
        return {
          index,
          q: `${result.key} (${result.status})`,
          id: result.key,
          type: 'Experience',
        };
      case ResultTypes.SEARCH_ORDER_SUMMARY:
        return {
          index,
          q: result.destination_country ? `${result.number} (${result.destination_country})` : `${result.number}`,
          id: result.number,
          type: 'Order',
        };
      case ResultTypes.SEARCH_EXPERIMENT_SUMMARY:
        return {
          index,
          q: `${result.name} (${result.status})`,
          id: result.key,
          type: 'Experiment',
        };
      default:
        getRollbar((rollbar, extra = {}) => {
          rollbar.warning('Invalid result type', { ...extra, result });
        });
        return null;
    }
  });

  return filter(options, (option) => !isNil(option));
}
