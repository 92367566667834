import { BaseType, select, Selection } from 'd3';
import { ResultsObject } from '../../modules/utilities/types';
import { ChartAttributes } from './types/Charts';

export default function createCrosshairs<T>(
  data: ResultsObject[],
  svg: Selection<SVGGElement, unknown, HTMLElement, undefined>,
  tooltip: Selection<HTMLDivElement, T, BaseType, T>,
  chartAttrs: ChartAttributes,
  tooltipHtml: string[],
  pointWidth: number,
  pointSpacing: number,
  xPositioner: (value: number) => number,
  tooltipWidth = 275,
): void {
  const {
    marginLeft,
    marginBtm,
    chartPadding,
    height,
  }: ChartAttributes = chartAttrs;

  svg.selectAll('.crosshair-box')
    .data(data)
    .enter()
    .append('rect')
    .attr('class', (_, index) => `crosshair-box index-${index}`)
    .attr('height', height)
    .attr('width', pointWidth + pointSpacing * 2)
    .attr('fill', 'rgba(0,0,0,0)')
    .attr('y', marginBtm)
    .attr('x', (_, index) => {
      const xPosition = xPositioner(index);
      if (index === 0) {
        return xPosition - chartPadding;
      }

      return xPosition - pointSpacing;
    })
    .on('mouseenter', (_, index) => {
      const selectedElementNode = select<Element, unknown>(`rect.crosshair-box.index-${index}`).node();
      const selectedSVGNode = svg.node();
      let crosshairBox;
      let chartBox;
      if (selectedElementNode) {
        crosshairBox = selectedElementNode.getBoundingClientRect();
        if (selectedSVGNode) {
          chartBox = selectedSVGNode.getBoundingClientRect();

          const tooltipHtmlString = tooltipHtml[index];
          const leftPosition = (chartBox.right + marginLeft) - crosshairBox.right < tooltipWidth
            ? crosshairBox.left - tooltipWidth - 25 : crosshairBox.left;
          tooltip
            .style('display', 'inline-block')
            .style('left', `${leftPosition + 10}px`)
            .style('top', `${window.pageYOffset + crosshairBox.top + (height / 3)}px`)
            .html(`${tooltipHtmlString}`);
        }

        svg.selectAll(`path.crosshair.index-${index}`)
          .transition()
          .duration(150)
          .attr('stroke-opacity', 0.25);
      }
    })
    .on('mouseleave', (_, index) => {
      svg.selectAll(`path.crosshair.index-${index}`)
        .transition()
        .duration(150)
        .attr('stroke-opacity', 0);

      tooltip
        .style('display', 'none');
    });
}
