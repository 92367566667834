import { createToast } from '../../console/actions';
import DialogName from '../constants/DialogName';
import hideDialog from './hideDialog';
import refreshOptinAttributes from './refreshOptinAttributes';
import { ThunkDispatcher } from '../../../stores/types';

function addOptinMessageSuccess() {
  return function addOptinMessageSuccessSideEffects(dispatch: ThunkDispatcher) {
    return Promise.all([
      dispatch(createToast({
        intent: 'positive',
        content: 'Opt-in message has been created.',
        icon: 'CheckCircle',
      })),
      dispatch(hideDialog(DialogName.CREATE_OPTIN_MESSAGE_WORKSHEET)()),
      dispatch(refreshOptinAttributes()),
    ]);
  };
}

export default addOptinMessageSuccess;
