import map from 'lodash/map';
import { createToast } from '../../console/actions';

import ActionTypes from '../constants/action-types';
import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import hideAddRuleWorksheet from './hideAddRuleWorksheet';

interface RequestOptions {
  featureRuleForm: io.flow.internal.v0.unions.FeatureRuleForm;
}

function createRequestAction(
  featureRuleForm: io.flow.internal.v0.unions.FeatureRuleForm,
): RootActionTypes {
  return {
    type: ActionTypes.ADD_RULE_BY_KEY_REQUEST,
    featureRuleForm,
  };
}

function createSuccessAction(
  featureRuleForm: io.flow.internal.v0.unions.FeatureRuleForm,
  payload: io.flow.internal.v0.models.Feature,
): RootActionTypes {
  return {
    type: ActionTypes.ADD_RULE_BY_KEY_SUCCESS,
    payload,
    featureRuleForm,
  };
}

function createFailureAction(payload: FormattedErrorMessages): RootActionTypes {
  return {
    type: ActionTypes.ADD_RULE_BY_KEY_FAILURE,
    payload,
  };
}

export default function addRuleByKey(
  organizationId: string,
  key: string,
  options: RequestOptions,
): ThunkResult<Promise<void>> {
  return function addRuleByKeyEffects(dispatch, getState, extra) {
    dispatch(createRequestAction(options.featureRuleForm));

    return extra.apiInternal(getState()).features.postRulesByKey({
      body: options.featureRuleForm,
      organization: organizationId,
      key,
    }).then((response) => {
      if (response.ok) {
        dispatch(createSuccessAction(options.featureRuleForm, response.body));
        dispatch(hideAddRuleWorksheet());
        dispatch(createToast({
          content: 'A new rule has been created.',
          icon: 'CheckCircle',
          intent: 'positive',
        }));
        return;
      }

      const errors = formatErrorResponseV2(response);

      dispatch(createToast({
        content: `Failed to create rule: ${map(errors.messages, ((msg) => msg.message)).join(', ')}`,
        icon: 'Error',
        intent: 'negative',
      }));

      dispatch(createFailureAction(errors));
    });
  };
}
