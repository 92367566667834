import { $HttpResponse } from '@flowio/api-sdk';
import ActionTypes from '../constants/action-types';
import {
  ThunkResult, RootState, ThunkExtraArguments, ThunkDispatcher,
} from '../../../stores/types';
import { ApiError } from '../../../utilities/clients/api-error';
import standardAction from '../../../utilities/standard-action';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';

export default function fetchExperimentVariants(
  organization: string,
  discriminator: io.flow.internal.v0.enums.ExperimentDiscriminatorKey = 'experience',
  value: string,
  scope?: io.flow.internal.v0.enums.Scope,
): ThunkResult<Promise<void>> {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<void> => {
    dispatch({
      type: ActionTypes.FETCH_EXPERIMENT_VARIANTS_REQUEST,
    });

    return extra.apiInternal(getState())
      .experimentFormDefaults.getDiscriminatorByDiscriminatorKeyAndValue({
        organization,
        discriminator_key: discriminator,
        value,
      }).then((response) => {
        if (response.ok) {
          dispatch(
            standardAction(ActionTypes.FETCH_EXPERIMENT_VARIANTS_SUCCESS, {
              variants: response.body.variants,
              scope,
            }),
          );
          return;
        }

        const errorResponse = response as $HttpResponse<io.flow.v0.models.GenericError>;

        const error = new ApiError(
          errorResponse as $HttpResponse<io.flow.v0.models.GenericError>,
        );

        dispatch({
          type: ActionTypes.FETCH_EXPERIMENT_VARIANTS_FAILURE,
          payload: formatErrorResponseV2(errorResponse),
        });
        throw error;
      });
  };
}
