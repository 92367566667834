import { RootState } from '../../../stores/types';
import { getOrganizationId } from '../../organization/selectors';
import getExclusionRuleIdForExclusionRuleWorksheet from './getExclusionRuleIdForExclusionRuleWorksheet';
import getQueryFiltersForExclusionRuleWorksheet from './getQueryFiltersForExclusionRuleWorksheet';
import getRegionsForExclusionRuleWorksheet from './getRegionsForExclusionRuleWorksheet';

const getInitialValuesForExclusionRuleWorksheet = (state: RootState) => ({
  exclusionRuleId: getExclusionRuleIdForExclusionRuleWorksheet(state),
  filters: getQueryFiltersForExclusionRuleWorksheet(state),
  organizationId: getOrganizationId(state),
  regions: getRegionsForExclusionRuleWorksheet(state),
});

export default getInitialValuesForExclusionRuleWorksheet;
