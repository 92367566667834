import { createSelector } from 'reselect';
import get from 'lodash/get';
import getGmvByTimeline from './get-gmv-by-timeline';
import { RootState } from '../../../stores/types';
import { AnalyticsState } from '../types';

export default createSelector<RootState, AnalyticsState['gmvByTimeline']['data'], string>(
  getGmvByTimeline,
  (state) => get(state, 'total.currency'),
);
