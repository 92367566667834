import { browserHistory } from 'react-router';
import {
  ThunkResult,
} from '../../../stores/types';

interface GoToFeedsParams {
  organizationId: string;
  platformId: io.flow.internal.v0.enums.MarketingGatewayPlatform;
  accountId?: string | number;
}

export default function goToFeedsPage({
  organizationId,
  platformId,
  accountId,
}: GoToFeedsParams): ThunkResult<void> {
  return (): void => browserHistory.push(`/${organizationId}/marketing/${platformId}/feeds${Number(accountId) ? `/${Number(accountId)}` : ''}`);
}
