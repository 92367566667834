enum ActionTypes {
  CLEAR_ORDER_ESTIMATE = 'CLEAR_ORDER_ESTIMATE',
  GET_CENTERS_REQUEST = 'GET_CENTERS_REQUEST',
  GET_CENTERS_SUCCESS = 'GET_CENTERS_SUCCESS',
  GET_CENTERS_FAILURE = 'GET_CENTERS_FAILURE',
  REMOVE_ORDER_SUMMARY_ITEM = 'REMOVE_ORDER_SUMMARY_ITEM',
  SET_ORDER_SUMMARY_SETTINGS = 'SET_ORDER_SUMMARY_SETTINGS',
  UPSERT_ORDER_ESTIMATE_REQUEST = 'UPSERT_ORDER_ESTIMATE_REQUEST',
  UPSERT_ORDER_ESTIMATE_SUCCESS = 'UPSERT_ORDER_ESTIMATE_SUCCESS',
  UPSERT_ORDER_ESTIMATE_FAILURE = 'UPSERT_ORDER_ESTIMATE_FAILURE',
  UPDATE_ITEM_QUANTITY = 'UPDATE_ITEM_QUANTITY',
  FETCH_SHOPIFY_SYNC_STATUS_REQUEST = 'FETCH_SHOPIFY_SYNC_STATUS_REQUEST',
  FETCH_SHOPIFY_SYNC_STATUS_SUCCESS = 'FETCH_SHOPIFY_SYNC_STATUS_SUCCESS',
  FETCH_SHOPIFY_SYNC_STATUS_FAILURE = 'FETCH_SHOPIFY_SYNC_STATUS_FAILURE',
  GET_CURRENCY_RATES_REQUEST = 'GET_CURRENCY_RATES_REQUEST',
  GET_CURRENCY_RATES_SUCCESS = 'GET_CURRENCY_RATES_SUCCESS',
  GET_CURRENCY_RATES_FAILURE = 'GET_CURRENCY_RATES_FAILURE',
}

export default ActionTypes;
