import React from 'react';
import {
  Dialog, DialogHeader, DialogFooter, DialogActions, DialogBody,
} from '@flowio/react-dialog';
import find from 'lodash/find';
import get from 'lodash/get';
import noop from 'lodash/noop';
import { Button } from '@flowio/react-button';
import {
  Card, CardHeader, CardContent, CardFooter, CardDescription, CardTitle,
} from '@flowio/react-card';

import Alert from '../../../../components/alert/alert';
import KeySummary from './key-summary';
import ApiKeyForm from './api-key-form';
import { ApiKeysProps, ApiKeysState } from './types';
import * as styles from './api-keys.styles';

export default class ApiKeys extends React.PureComponent<ApiKeysProps, ApiKeysState> {
  static displayName = 'ApiKeys';

  static defaultProps = {
    tokens: [],
    allowEnvironmentSelection: false,
    cleartextTokenDialogId: undefined,
    onCreateKey: noop,
    onViewClearText: noop,
    onCloseClearTextKeyDialog: noop,
    onDeleteApiKey: noop,
  };

  constructor(props: ApiKeysProps) {
    super(props);
    this.state = {};
  }

  handleCancelDeleteApiKey = () => this.setState({ showConfirmDeleteDialogId: undefined });

  handleCreateKey = (formData: any) => {
    const {
      onCreateKey = noop,
      organization,
    } = this.props;
    onCreateKey(organization.id, formData);
  };

  handleKeyDelete = (id: string) => {
    this.setState({ showConfirmDeleteDialogId: id });
  };

  handleDeleteApiKey = () => {
    const { onDeleteApiKey = noop } = this.props;
    const { showConfirmDeleteDialogId } = this.state;
    this.setState({ showConfirmDeleteDialogId: undefined });
    onDeleteApiKey(showConfirmDeleteDialogId);
  };

  getTokenField(id: string, fieldName: string) {
    const { tokens } = this.props;
    const token = find(tokens, { id });
    return get(token, fieldName, '');
  }

  renderApiKeyListIfNecessary() {
    const {
      tokens,
      onViewClearText,
    } = this.props;

    if (!tokens) {
      return null;
    }

    return (
      <section>
        {tokens.map((token) => (
          <KeySummary
            token={token}
            key={token.id}
            onViewClearText={onViewClearText}
            onDelete={this.handleKeyDelete}
          />
        ))}
      </section>
    );
  }

  render() {
    const {
      cleartextTokenDialogId = '',
      organization,
      allowEnvironmentSelection = false,
      onCloseClearTextKeyDialog = noop,
    } = this.props;
    const { showConfirmDeleteDialogId = '' } = this.state;

    return (
      <Card id="api-keys" className={styles.organizationApiKeys}>
        <CardHeader dividing>
          <CardTitle>API Keys</CardTitle>
          <CardDescription>
            API Keys used to access the Flow API.
            Generate a new key for every app utilizing the API.
          </CardDescription>
        </CardHeader>
        <CardContent>
          {this.renderApiKeyListIfNecessary()}
          <section>
            <h3 className={styles.subtitle}>New API Key</h3>
            {(() => {
              if (organization.environment === 'sandbox') {
                return (
                  <Alert type="info" className={styles.sandboxAlert}>
                    <strong>NOTE:</strong>
                    This is a sandbox organization.
                    Keys generated will only work for this organization.
                  </Alert>
                );
              }
              return null;
            })()}
            <ApiKeyForm
              allowEnvironmentSelection={allowEnvironmentSelection}
              onSubmit={this.handleCreateKey}
            />
          </section>
        </CardContent>
        <CardFooter />
        <Dialog
          backdrop
          closeOnEscape
          onClose={onCloseClearTextKeyDialog}
          open={!!cleartextTokenDialogId}
        >
          <DialogHeader content="Full API Key" />
          <DialogBody>
            <section className={styles.cleartextContainer}>
              {this.getTokenField(cleartextTokenDialogId, 'cleartext')}
            </section>
          </DialogBody>
          <DialogFooter>
            <DialogActions>
              <Button
                content="Close"
                onClick={onCloseClearTextKeyDialog}
                intent="primary"
              />
            </DialogActions>
          </DialogFooter>
        </Dialog>
        <Dialog
          backdrop
          closeOnEscape
          onClose={this.handleCancelDeleteApiKey}
          open={!!showConfirmDeleteDialogId}
        >
          <DialogHeader content={`Delete ${this.getTokenField(showConfirmDeleteDialogId, 'partial')}?`} />
          <DialogBody>
            <section>
              <p>
                Disabling this token will prevent any software using it
                from accessing the Flow API.
              </p>
              <p>
                Really delete API Key
                <em>
                  {this.getTokenField(showConfirmDeleteDialogId, 'partial')}
                </em>
                ?
              </p>
            </section>
          </DialogBody>
          <DialogFooter>
            <DialogActions style={{ textAlign: 'center' }}>
              <div>
                <Button
                  content="Cancel"
                  onClick={this.handleCancelDeleteApiKey}
                />
                <Button
                  content="Delete API Key"
                  onClick={this.handleDeleteApiKey}
                  css={{ marginLeft: '1rem' }}
                  intent="primary"
                />
              </div>
            </DialogActions>
          </DialogFooter>
        </Dialog>
      </Card>
    );
  }
}
