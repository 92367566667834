enum FormName {
  CREATE_SHIPPING_CONFIGURATION_FORM = 'createShippingConfiguration',
  SHIPPING_CONFIGURATION_COPY_FORM = 'copyShippingConfiguration',
  SHIPPING_CONFIGURATION_DELETE_FORM = 'deleteShippingConfiguration',
  SHIPPING_LANE_FORM = 'shippingLane',
  SHIPPING_LANE_DELETE_FORM = 'deleteShippingLane',
  TIER_DELETE_FORM = 'deleteTier',
  TIER_UPSERT_FORM = 'upsertTier',
  UPDATE_SHIPPING_CONFIGURATION_FORM = 'updateShippingConfiguration',
}

export default FormName;
