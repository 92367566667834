import { MinusCircle } from '@flowio/react-icons';
import styled from '@emotion/styled';
import { some } from 'lodash';
import { Box } from '@flowio/react-box';
import { Breadcrumbs } from '@flowio/react-breadcrumbs';
import { OutlineButton } from '@flowio/react-button';
import { PageTitle } from '@flowio/react-page';
import React from 'react';
import startCase from 'lodash/startCase';
import { css } from '@emotion/css';
import { colors } from '../../../../../theme/tokens';

const headingWrapperStyles = css`
  padding-bottom: 1rem;
  padding-top: 1rem;
`;

interface ExperimentDetailsHeadingsProps {
  organizationId: string;
  experiment: io.flow.internal.v0.unions.Experiment;
  onEndExperiment: (key: string) => void;
}

const EndExperimentIcon = styled(MinusCircle)`
  fill: ${colors.chart.red};
`;

const ExperimentDetailsHeading: React.FC<ExperimentDetailsHeadingsProps> = ({
  organizationId,
  experiment,
  onEndExperiment,
}) => (
  <div className={headingWrapperStyles}>
    <Breadcrumbs items={[{ content: 'Experiments', href: `/${organizationId}/experiments` }]} />
    <Box justifyContent="between" alignItems="center">
      <PageTitle>
        {startCase(experiment.name)}
        {' '}
        {experiment.status}
      </PageTitle>
      <OutlineButton
        disabled={!some(experiment.transitions, (status) => status === 'ended')}
        intent="negative"
        leftIcon={EndExperimentIcon}
        content="End Experiment"
        onClick={(): void => onEndExperiment(experiment.key)}
      />
    </Box>
  </div>
);

export default ExperimentDetailsHeading;
