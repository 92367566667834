import { Field, Form } from 'redux-form';
import React from 'react';
import { Stack } from '@flowio/react-stack';

import GenericError from '../../../../components/GenericError';
import { ShippingLaneDeleteFormCombinedProps } from '../../types/components';

const ShippingLaneDeleteForm: React.FC<ShippingLaneDeleteFormCombinedProps> = ({
  error,
}) => (
  <Form>
    <Field component="input" type="hidden" name="organizationId" />
    <Field component="input" type="hidden" name="shippingConfigurationKey" />
    <Field component="input" type="hidden" name="shippingLaneId" />
    <Stack orientation="vertical" spacing="loose">
      {error && (
        <GenericError error={error} />
      )}
      <p>
        This action cannot be undone.
        Are you sure you want to delete this shipping lane?
      </p>
    </Stack>
  </Form>
);

ShippingLaneDeleteForm.displayName = 'ShippingLaneDeleteForm';

export default ShippingLaneDeleteForm;
