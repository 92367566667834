import get from 'lodash/get';
import { createSelector } from 'reselect';
import { RootState } from '../../../stores/types';
import { MarketingState } from '../types';
import getMarketingState from './getMarketingState';

const getDataSources = (
  state: MarketingState,
): io.flow.internal.v0.unions.MarketingGatewayFeedSource[] => {
  const dataSources = get(state, 'feedSources.sources');
  return dataSources;
};

export default createSelector<
RootState, MarketingState, io.flow.internal.v0.unions.MarketingGatewayFeedSource[]
>(
  getMarketingState,
  getDataSources,
);
