import { ExperiencesGetCurrencyAndFormatsByExperienceKeyResponse } from '@flowio/api-sdk';
import ActionTypes from '../constants/action-types';
import { ThunkResult, ThunkExtraArguments } from '../../../stores/types';
import { PropType } from '../../../types';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import toLegacyResponse from '../../../utilities/clients/to-legacy-response';
import standardAction from '../../../utilities/standard-action';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';

type ResponseType = PropType<ExperiencesGetCurrencyAndFormatsByExperienceKeyResponse, 'body'>;

export default function getPriceFormat(
  organization: string,
  experienceKey: string,
): ThunkResult<Promise<LegacyResponse<ResponseType>>> {
  return function getPriceFormatEffects(
    dispatch,
    getState,
    extra: ThunkExtraArguments,
  ): Promise<LegacyResponse<ResponseType>> {
    dispatch({
      type: ActionTypes.GET_PRICE_FORMAT_REQUEST,
    });
    return extra.api(getState()).experiences.getCurrencyAndFormatsByExperienceKey({
      organization,
      experience_key: experienceKey,
    }).then((response) => {
      if (response.ok) {
        dispatch(standardAction(
          ActionTypes.GET_PRICE_FORMAT_SUCCESS,
          response.body,
        ));
      } else {
        dispatch(standardAction(
          ActionTypes.GET_PRICE_FORMAT_FAILURE,
          formatErrorResponseV2(response),
        ));
      }
      return toLegacyResponse<ResponseType>(response);
    });
  };
}
