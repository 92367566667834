import {
  Card, CardHeader, CardContent, CardFooter, CardEmptyState, CardTitle,
} from '@flowio/react-card';
import React from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import events from 'events';
import MetricByCountryChart from '../MetricByCountryChart';

import MetricByCountryMap from '../MetricByCountryMap';
import withCurrency from '../../utilities/with-currency';

interface Props {
  valueKey: string;
  currency: string;
  organizationCountry: string;
  organizationCurrency: string;
  data?: io.flow.internal.v0.models.OrderRevenueRegionChart;
}

const MetricByCountryCard: React.FC<Props> = ({
  valueKey,
  currency,
  data,
  organizationCountry,
  organizationCurrency,
}) => {
  const hasData = !isEmpty(get(data, 'data'));
  const title = valueKey === 'revenue.amount' ? withCurrency('Submitted Order Value By Country', currency) : 'Number of Orders by Country';
  const eventListener = new events.EventEmitter();
  return (
    <Card>
      <CardHeader dividing>
        <CardTitle content={title} />
      </CardHeader>
      <CardContent fitted={!hasData}>
        {hasData ? (
          <div>
            <MetricByCountryMap
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              results={data!}
              valueKey={valueKey}
              organizationCountry={organizationCountry}
              organizationCurrency={organizationCurrency}
              eventListener={eventListener}
            />
            <MetricByCountryChart
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              data={data!}
              valueKey={valueKey}
              organizationCountry={organizationCountry}
              organizationCurrency={organizationCurrency}
              eventListener={eventListener}
            />
          </div>
        ) : (
          <CardEmptyState content="No Data Available" />
        )}
      </CardContent>
      <CardFooter />
    </Card>
  );
};

MetricByCountryCard.displayName = 'MetricByCountryCard';

export default MetricByCountryCard;
