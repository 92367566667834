import { submit, isSubmitting } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import FormName from '../constants/FormName';
import DialogName from '../constants/DialogName';
import { getIsDialogOpen, getDialogProp } from '../selectors';
import { hideDialog } from '../actions';
import FeatureDeleteConfirm from '../components/FeatureDeleteConfirm';
import { RootState, ThunkDispatcher } from '../../../stores/types';
import {
  FeatureDeleteConfirmDispatchProps,
  FeatureDeleteConfirmStateProps,
  FeatureDeleteConfirmValues,
  FeatureDeleteConfirmOwnProps,
} from '../types/components';

const confirmation = DialogName.DELETE_FEATURE_CONFIRM;
const form = FormName.DELETE_FEATURE_FORM;

const handleSubmit = () => submit(form);

const mapStateToProps = (state: RootState): FeatureDeleteConfirmStateProps => ({
  disabled: isSubmitting(form)(state),
  initialValues: getDialogProp(confirmation, 'initialValues')(state) as FeatureDeleteConfirmValues,
  open: getIsDialogOpen(confirmation)(state) as boolean,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatcher,
): FeatureDeleteConfirmDispatchProps => bindActionCreators({
  onCancel: hideDialog(confirmation),
  onOk: handleSubmit,
}, dispatch);

export default compose<React.FC<FeatureDeleteConfirmOwnProps>>(
  connect(mapStateToProps, mapDispatchToProps),
)(FeatureDeleteConfirm);
