import { Field, Form } from 'redux-form';
import React from 'react';
import { Text } from '@flowio/react-text';
import get from 'lodash/get';
import classnames from 'classnames/bind';
import styles from './ExportPriceBook.module.css';
import { TextField, RadioButtonGroup } from '../../../../components/ReduxFormFieldAdapters';

const cx = classnames.bind(styles);

interface Props {
  handleSubmit: (...args: any) => void;
  priceBook?: io.flow.v0.models.PriceBook;
  priceBookExportTypes: io.flow.v0.enums.ItemIdentifier[];
}

const ExportPriceBook: React.FC<Props> = ({ handleSubmit, priceBook, priceBookExportTypes }) => (
  <Form onSubmit={handleSubmit}>
    <Field name="priceBookKey" type="hidden" component="input" />
    <Field name="organization" type="hidden" component="input" />
    <Text>
      An email containing the
      {' '}
      {get(priceBook, 'name')}
      {' '}
      price book will be sent.
      Please note this might take a few minutes.
    </Text>
    <Text className={cx('itemIdentifierLabel')}>
      <b>Select the item identifier for your exported price book:</b>
    </Text>
    <Field
      name="itemIdentifier"
      component={RadioButtonGroup}
      className={cx('radioBtnGroup')}
      options={[
        {
          labelText: 'Item number',
          value: 'item_number',
          className: cx('radioBtnLabel'),
          style: {
            display: !priceBookExportTypes.includes('item_number') ? 'none' : undefined,
          },
        },
        {
          labelText: 'SKU',
          value: 'sku',
          className: cx('radioBtnLabel'),
          style: {
            display: !priceBookExportTypes.includes('sku') ? 'none' : undefined,
          },
        },
      ]}
    />
    <Field
      className={cx('emailField')}
      name="email"
      component={TextField}
      labelText="Exported price book will be sent to this email."
      required
    />
  </Form>
);

ExportPriceBook.displayName = 'ExportPriceBook';

export default ExportPriceBook;
