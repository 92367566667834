import { compose } from 'redux';
import { connect, MapStateToProps } from 'react-redux';
import { reduxForm } from 'redux-form';
import FormName from '../../../constants/FormName';

import {
  getExperienceSearchValues,
  getExperienceStatusFilters,
} from '../../../selectors';
import ExperienceListFilterForm from '../components/ExperienceListFilterForm';
import ExperienceStatus from '../../../constants/experience-status';
import { StateProps, OwnProps, ExperienceListFilterFormValues } from '../types';
import { RootState, ThunkDispatcher } from '../../../../../stores/types';

function handleSubmit(
  values: ExperienceListFilterFormValues,
  _dispatch: ThunkDispatcher,
  props: OwnProps,
): void {
  const {
    experienceSearch,
    experienceStatusFilter,
  } = values;

  const defaultValues = [
    ExperienceStatus.ACTIVE,
    ExperienceStatus.DRAFT,
  ];

  const { onRequestExperienceListFilterChange } = props;
  const searchFilter = {
    q: experienceSearch,
    discriminator: 'unstructured',
  };
  const statusFilter = {
    field: 'status',
    operator: 'in',
    values: experienceStatusFilter.length < 1 ? defaultValues : experienceStatusFilter,
  };
  const filters = [searchFilter, statusFilter];

  onRequestExperienceListFilterChange(filters);
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = (state) => {
  const statusFilterValue = getExperienceStatusFilters(state);
  const searchValue = getExperienceSearchValues(state);

  return {
    initialValues: {
      experienceStatusFilter: statusFilterValue,
      experienceSearch: searchValue,
    },
  };
};

export default compose<React.FC<OwnProps>>(
  connect<StateProps, {}, OwnProps, RootState>(mapStateToProps),
  reduxForm<ExperienceListFilterFormValues, OwnProps>({
    form: FormName.EXPERIENCE_LIST_FILTER_FORM,
    onSubmit: handleSubmit,
  }),
)(ExperienceListFilterForm);
