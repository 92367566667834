import update from 'immutability-helper';
import { ActionTypes } from './constants';
import { AccountState } from './types';
import { RootActionTypes } from '../../stores/types';

const defaultState: AccountState = {
  emailVerification: {},
  invitationUserVerified: false,
  error: {},
  exports: {
    selectedExport: {},
    currentPage: 1,
    openDialog: false,
  },
  ui: {},
};

function nameToKey(name: string): string {
  if (!name) return name;
  return name
    .toLowerCase()
    // eslint-disable-next-line no-useless-escape
    .replace(/[^a-z0-9\-_\.]/g, '-')
    .replace(/[-]{2,}/g, '-')
    .replace(/[_]{2,}/g, '-')
    .replace(/-$/, '')
    .replace(/^-/, '');
}

export default function accountReducer(
  state = defaultState,
  action: RootActionTypes,
): AccountState {
  switch (action.type) {
    case ActionTypes.VERIFY_EMAIL_SUCCESS:
      return update(state, {
        emailVerification: {
          $merge: {
            email: action.payload.email,
          },
        },
      });
    case ActionTypes.VERIFY_EMAIL_FAILURE:
      return update(state, {
        emailVerification: {
          $merge: {
            error: action.payload,
          },
        },
      });
    case ActionTypes.ACCT_ORG_NEW_NAME_CHANGE:
      if (!state.keyChangedManually) {
        return {
          ...state,
          orgKey: nameToKey(action.payload),
          orgName: action.payload,
          fieldToFocus: 'org_name',
        };
      }

      return {
        ...state,
        orgName: action.payload,
        fieldToFocus: 'org_name',
      };
    case ActionTypes.ACCT_ORG_NEW_KEY_CHANGE:
      return {
        ...state,
        keyChangedManually: true,
        orgKey: nameToKey(action.payload),
        fieldToFocus: 'org_key',
      };
    case ActionTypes.ACCT_ORG_CREATE_COMPLETED:
      return { ...state, ...action.payload };
    case ActionTypes.GET_ORGANIZATION_INVITATION_SUCCESS:
      return { ...state, invitation: action.payload };
    case ActionTypes.GET_ORGANIZATION_INVITATION_FAILURE:
    case ActionTypes.ACCT_ORG_ACCEPT_INVITATION_ERROR:
      return update(state, { error: { invitation: { $set: action.payload } } });

    case ActionTypes.VERIFY_USER_SUCCESS: {
      if (action.payload && action.payload.length === 1) {
        return update(state, { invitationUserVerified: { $set: true } });
      }
      return state;
    }

    case ActionTypes.FETCH_SCHEDULED_EXPORTS_REQUEST:
      return {
        ...state,
        pageNumber: action.params.pageNumber,
      };
    case ActionTypes.CREATE_SCHEDULED_EXPORT_REQUEST:
    case ActionTypes.DELETE_SCHEDULED_EXPORT_REQUEST:
      return update(state, {
        ui: {
          $set: {
            exportCreated: false,
          },
        },
      });

    case ActionTypes.FETCH_SCHEDULED_EXPORTS_SUCCESS:
      return update(state, { exports: { $merge: action.payload } });

    case ActionTypes.TOGGLE_SCHEDULED_EXPORT_DIALOG:
      return update(state, {
        exports: {
          selectedExport: { $set: action.payload },
          openDialog: { $set: !state.exports.openDialog },
        },
      });

    case ActionTypes.CREATE_SCHEDULED_EXPORT_FAILURE:
      return update(state, {
        exports: {
          error: { $set: action.payload },
          openDialog: { $set: true },
        },
      });

    case ActionTypes.SELECT_SCHEDULED_EXPORT:
      return update(state, {
        exports: {
          selectedExport: { $set: action.payload },
          openDialog: { $set: true },
        },
      });

    case ActionTypes.CLOSE_SCHEDULED_EXPORT:
      return update(state, { exports: { selectedExport: {}, openDialog: { $set: false } } });

    case ActionTypes.CREATE_SCHEDULED_EXPORT_SUCCESS: {
      return update(state, {
        exports: {
          results: {
            $set: (state.exports.results || []).concat(action.payload),
          },
          openDialog: { $set: false },
        },
        ui: {
          $set: {
            exportCreated: true,
          },
        },
      });
    }

    case ActionTypes.DELETE_SCHEDULED_EXPORT_SUCCESS: {
      return update(state, {
        exports: {
          results: {
            $set: (state.exports.results || []).filter((e) => e.id !== action.id),
          },
        },
      });
    }

    default:
      return state;
  }
}
