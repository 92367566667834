import { ActionTypes, ActivityKind } from '../constants';

import createActivity from './createActivity';
import { getOrganizationId } from '../../organization';
import { ThunkResult } from '../../../stores/types';

const deselectItem = ({
  item,
  index,
}: {
  item: io.flow.v0.models.LocalizedLineItem;
  index: number;
}): ThunkResult<void> => (dispatch, getState) => {
  const organizationId = getOrganizationId(getState());
  dispatch({
    type: ActionTypes.DESELECT_ITEM,
    payload: {
      number: item.number,
      index,
    },
  });

  dispatch(createActivity({
    kind: ActivityKind.UNSCAN_ITEM,
    number: item.number,
    organizationId,
  }));
};

export default deselectItem;
