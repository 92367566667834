import React, { useEffect } from 'react';
import map from 'lodash/map';
import kebabCase from 'lodash/kebabCase';
import sortBy from 'lodash/sortBy';
import toPairs from 'lodash/toPairs';
import {
  Card, CardHeader, CardContent, CardFooter, CardTitle,
} from '@flowio/react-card';
import { Image } from '@flowio/react-image';
import ClassificationResults from '../../classification-results';
import { MergedProps } from '../types';
import * as styles from './product-details.styles';

const ProductDetails: React.FC<MergedProps> = ({
  item,
  classificationResults,
  onClearSelectedCountries,
}) => {
  useEffect(() => {
    onClearSelectedCountries();

    return () => {
      onClearSelectedCountries();
    };
  }, [item, onClearSelectedCountries]);

  if (!item) return null;
  const {
    attributes,
    categories,
    description,
    dimensions,
    images,
    locale,
    number,
    name,
    price,
  } = item;

  const sortedAttributes = sortBy(toPairs(attributes), ([key]) => key);

  return (
    <div className={styles.productDetails}>
      <Card>
        <CardHeader dividing>
          <CardTitle content="Product Info" />
        </CardHeader>
        <CardContent>
          <dl className={styles.info}>
            <dt className={styles.infoLabel}>Number</dt>
            <dd className={styles.infoValue}>
              {number}
            </dd>
            <dt className={styles.infoLabel}>Product Name</dt>
            <dd className={styles.infoValue}>
              {name}
            </dd>
            <dt className={styles.infoLabel}>Locale</dt>
            <dd className={styles.infoValue}>
              {locale}
            </dd>
            <dt className={styles.infoLabel}>Original Price</dt>
            <dd className={styles.infoValue}>
              {price.label}
            </dd>
            <dt className={styles.infoLabel}>Categories</dt>
            <dd className={styles.infoValue}>
              {categories.join(', ')}
            </dd>
            <dt className={styles.infoLabel}>Description</dt>
            <dd className={styles.infoValue}>
              {description}
            </dd>
            <dt className={styles.infoLabel}>Images</dt>
            <dd className={styles.infoValue}>
              <div className={styles.gallery}>
                {images.map(({ url, tags }) => (
                  <figure key={kebabCase(url)} className={styles.figure}>
                    <Image source={url} accessibilityLabel={name} className={styles.image} />
                    <figcaption className={styles.caption}>
                      {tags.join(', ')}
                    </figcaption>
                  </figure>
                ))}
              </div>
            </dd>
            <dt className={styles.infoLabel}>Attributes</dt>
            <dd className={styles.infoValue}>
              <div className={styles.attributes}>
                {map(sortedAttributes, ([key, attribute]) => (
                  <dl key={key} className={styles.attribute}>
                    <dt className={styles.attributeLabel}>
                      {key}
                    </dt>
                    <dd className={styles.attributeValue}>
                      {attribute}
                    </dd>
                  </dl>
                ))}
              </div>
            </dd>
            <dt className={styles.infoLabel}>Dimensions &amp; Weight</dt>
            <dd className={styles.infoValue}>
              {!dimensions.product ? null : (
                <div className={styles.dimension}>
                  <h3 className={styles.dimensionSubtitle}>Product</h3>
                  <div className={styles.attributes}>
                    {map<io.flow.v0.models.Dimension, React.ReactNode>(
                      dimensions.product,
                      (dimension, key) => dimension && (
                        <dl key={key} className={styles.attribute}>
                          <dt className={styles.attributeLabel}>
                            {key}
                          </dt>
                          <dd className={styles.attributeValue}>
                            {dimension.value}
                            {' '}
                            {dimension.units}
                          </dd>
                        </dl>
                      ),
                    )}
                  </div>
                </div>
              )}
              {!dimensions.packaging ? null : (
                <div className={styles.dimension}>
                  <h3 className={styles.dimensionSubtitle}>Packaging</h3>
                  <div className={styles.attributes}>
                    {map<io.flow.v0.models.Dimension, React.ReactNode>(
                      dimensions.packaging,
                      (dimension, key) => dimension && (
                        <dl key={key} className={styles.attribute}>
                          <dt className={styles.attributeLabel}>
                            {key}
                          </dt>
                          <dd className={styles.attributeValue}>
                            {dimension.value}
                            {' '}
                            {dimension.units}
                          </dd>
                        </dl>
                      ),
                    )}
                  </div>
                </div>
              )}
            </dd>
          </dl>
        </CardContent>
        <CardFooter />
      </Card>
      <Card>
        <CardHeader dividing caption="Classification" />
        <CardContent>
          {map(classificationResults, (classificationResult, index) => (
            <ClassificationResults key={index} classificationResult={classificationResult} />
          ))}
        </CardContent>
        <CardFooter />
      </Card>
    </div>
  );
};

ProductDetails.displayName = 'ProductDetails';

export default ProductDetails;
