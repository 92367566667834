import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import {
  SubmissionError,
  change,
  reduxForm,
  formValues,
} from 'redux-form';
import map from 'lodash/map';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import keys from 'lodash/keys';
import { createStructuredSelector } from 'reselect';
import { TierEstimateType } from '@flowio/api-constants';

import {
  FormName,
  FormMethod,
  TierRuleOutcomeFormDiscriminatorValues,
  ActionTypes,
} from '../constants';
import { createTier, updateTier } from '../actions';
import handleSubmitResult from '../utilities/handleSubmitResult';
import TierUpsertForm from '../components/TierUpsertForm';
import { getSurchargeSettings } from '../selectors';
import {
  FormTierRule,
  TierUpsertFormOwnProps,
  TierUpsertFormValues,
  TierUpsertFormStateProps,
} from '../types/components';
import { RootState, ThunkDispatcher } from '../../../stores/types';

const formName = FormName.TIER_UPSERT_FORM;

// Currency is passed separately because field value is not set at the rule level.
function tierRuleOutcomeFormFromRuleValues(
  rule: FormTierRule,
  currency: string,
): io.flow.v0.unions.TierRuleOutcomeForm {
  const amount = rule.outcome.value ? Number(rule.outcome.value) : 0;
  switch (rule.outcome.type) {
    case TierRuleOutcomeFormDiscriminatorValues.AMOUNT_MARGIN_FORM:
      return {
        discriminator: rule.outcome.type,
        margin: {
          amount,
          currency,
        },
      };
    case TierRuleOutcomeFormDiscriminatorValues.FLAT_RATE_FORM:
      return {
        discriminator: rule.outcome.type,
        price: {
          amount,
          currency,
        },
        zero_amount_indicator: rule.outcome.zeroAmountIndicator,
      };
    case TierRuleOutcomeFormDiscriminatorValues.PERCENT_MARGIN:
      return {
        discriminator: rule.outcome.type,
        percentage: amount,
      };
    default:
      return {
        discriminator: rule.outcome.type,
      };
  }
}

function tierRuleQueryFromRuleValues(rule: FormTierRule) {
  switch (rule.query.operator) {
    case 'any': return '*';
    default: return `${rule.query.operator}:${rule.query.value}`;
  }
}

function tierRuleFormFromRuleValues(rule: FormTierRule, currency = ''): io.flow.v0.models.TierRuleForm {
  return {
    position: rule.position,
    query: tierRuleQueryFromRuleValues(rule),
    outcome: tierRuleOutcomeFormFromRuleValues(rule, currency),
  };
}

function tierEstimateFromValues(values: TierUpsertFormValues) {
  const { displayEstimateLabel, displayEstimateType } = values;
  return omitBy({
    type: displayEstimateType,
    label: displayEstimateType === TierEstimateType.CUSTOM ? displayEstimateLabel : undefined,
  }, isNil);
}

function tierDisplayFormFromValues(values: TierUpsertFormValues) {
  return omitBy({
    estimate: tierEstimateFromValues(values),
  }, isNil);
}

function tierFormFromValues(values: TierUpsertFormValues): io.flow.v0.models.TierForm {
  const surchargeSettingKeys: io.flow.v0.enums.DeliveryOptionCostDetailComponentKey[] = keys(
    values.surchargeSettings,
  ) as io.flow.v0.enums.DeliveryOptionCostDetailComponentKey[];
  const surchargeSettings: io.flow.v0.models.SurchargeSetting[] = surchargeSettingKeys
    .map((surchargeSettingKey) => ({
      key: surchargeSettingKey,
      // We want responsible party to always default to organization if there is no value
      responsible_party: values.surchargeSettings[surchargeSettingKey].responsible_party ? values.surchargeSettings[surchargeSettingKey].responsible_party : 'organization',
    }));
  const settings: io.flow.v0.models.TierSettings = {
    availability: values.isBackup ? 'backup' : 'always',
  };

  return {
    currency: values.currency,
    integration: values.integration,
    name: values.name,
    message: values.message,
    rules: map(values.rules, (rule) => tierRuleFormFromRuleValues(rule, values.currency)),
    services: values.services,
    strategy: values.strategy,
    visibility: values.visibility,
    description: values.description,
    direction: values.direction,
    display: tierDisplayFormFromValues(values),
    shipping_lane: values.shippingLaneId,
    surcharge_settings: surchargeSettings,
    settings,
  };
}

function handleSubmit(
  values: TierUpsertFormValues,
  dispatch: ThunkDispatcher,
  props: TierUpsertFormOwnProps,
) {
  switch (props.method) {
    case FormMethod.POST:
      return dispatch(createTier(
        values.organizationId,
        values.shippingConfigurationKey,
        values.shippingLaneId,
        tierFormFromValues(values),
      )).then((result) => handleSubmitResult(result, [ActionTypes.CREATE_TIER_FAILURE]));
    case FormMethod.PUT:
      return dispatch(updateTier({
        organizationId: values.organizationId,
        shippingConfigurationKey: values.shippingConfigurationKey,
        shippingLaneId: values.shippingLaneId,
        tierId: values.tierId || '',
        tierForm: tierFormFromValues(values),
      })).then(((result) => handleSubmitResult(result, [ActionTypes.UPDATE_TIER_FAILURE])));
    default:
      throw new SubmissionError({
        _error: {
          code: 'generic_error',
          messages: 'You submission request is not implemented. Please try again later.',
        },
      });
  }
}

function handleSelectAllServices(availableServices: io.flow.v0.models.CarrierService[]) {
  return change(formName, 'services', map(availableServices, 'id'));
}

function handleResetServices() {
  return change(formName, 'services', []);
}

const mapStateToProps = createStructuredSelector<RootState, TierUpsertFormStateProps>({
  surchargeSettings: getSurchargeSettings,
});

const mapDispatchToProps = (dispatch: ThunkDispatcher) => bindActionCreators({
  onResetServices: handleResetServices,
  onSelectAllServices: handleSelectAllServices,
}, dispatch);

export default compose<React.FC<TierUpsertFormOwnProps>>(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    form: formName,
    onSubmit: handleSubmit,
  }),
  formValues({
    selectedServices: 'services',
  }),
)(TierUpsertForm);
