import update from 'immutability-helper';
import { ActionTypes } from '../constants';
import { RootActionTypes } from '../../../stores/types';
import { DialogState } from '../types';

const initialState: DialogState = {
  MEMBERSHIP_INVITIATION_DIALOG: {
    open: false,
  },
};

export default function dialogsReducer(
  state: DialogState = initialState,
  action: RootActionTypes,
): DialogState {
  switch (action.type) {
    case ActionTypes.OPEN_ORGANIZATION_DIALOG:
      return update(state, {
        [action.payload]: { open: { $set: true } },
      });
    case ActionTypes.CLOSE_ORGANIZATION_DIALOG:
      return update(state, {
        [action.payload]: { open: { $set: false } },
      });
    default: return state;
  }
}
