import {
  Form, Field, getFormValues, InjectedFormProps,
} from 'redux-form';
import React, {
  ReactNode, useCallback, useEffect, useRef, useState,
} from 'react';
import get from 'lodash/get';
import { countries } from '@flowio/lib-reference-javascript';
import { Panel } from '@flowio/react-panel';
import { Box } from '@flowio/react-box';
import { Button } from '@flowio/react-button';
import { useSelector } from 'react-redux';
import { ArcLoader } from '@flowio/react-icons';
import { Confirm } from '@flowio/react-confirm';
import { isEmpty, isNil, isNotNil } from '@flowio/is';
import { OrganizationType } from '@flowio/api-constants';
import { $HttpNotFound, $HttpUnauthorized, $HttpUnprocessableEntity } from '@flowio/api-internal-sdk';
import { TextField, Checkbox } from '../../../../components/ReduxFormFieldAdapters';
import { colors } from '../../../../theme/tokens';
import { updateOrderAddressValidation } from '../../actions';
import useThunkDispatcher from '../../../../hooks/useThunkDispatcher';
import formatErrorResponseV2 from '../../../../utilities/format-error-response-v2';
import { getOrganization } from '../../../organization';
import FeatureKey from '../../../console/constants/FeatureKey';
import FeatureValueSelector from '../../../console/components/FeatureValueSelector';
import { checkFeatureValueByKey } from '../../../console';

export type OrderShippingAddress = {
  contact: io.flow.v0.models.Contact;
  streets: string[];
  city: string;
  province: string;
  postal: string;
  countryCode: string;
};

interface EditShippingAddressFormProps extends InjectedFormProps<OrderShippingAddress> {
  countryCode: string;
  order: io.flow.v0.models.Order;
  organizationId: string;
  addressValidation: io.flow.internal.v0.models.OrderValidation | null;
  addressValidationUser: io.flow.v0.models.User | null;
  addressValidationMsg: string | null;
}

function orderFormAddressToShippingAddress(
  address: OrderShippingAddress,
  countryCode: string,
): io.flow.v0.models.ShippingAddress {
  return {
    contact: address.contact,
    location: {
      streets: address.streets.filter((s) => s.length > 0),
      city: isEmpty(address.city) ? undefined : address.city,
      province: isEmpty(address.province) ? undefined : address.province,
      postal: isEmpty(address.postal) ? undefined : address.postal,
      country: countryCode,
    },
  };
}

function isPhysicalDelivery(
  delivery: io.flow.v0.unions.Delivery | undefined,
): delivery is io.flow.v0.models.PhysicalDelivery {
  return isNotNil(delivery) && delivery.discriminator === 'physical_delivery';
}

function getDesiredService(deliveries: io.flow.v0.unions.Delivery[]): string | undefined {
  const physicalDeliveryWithService = deliveries.find(
    (delivery) => isPhysicalDelivery(delivery) && isNotNil(delivery.preferred_service),
  );

  if (isPhysicalDelivery(physicalDeliveryWithService)) {
    return physicalDeliveryWithService.preferred_service?.id;
  }
  return undefined;
}

type ErrorResponse =
  | $HttpUnauthorized<undefined>
  | $HttpNotFound<undefined>
  | $HttpUnprocessableEntity<io.flow.error.v0.models.GenericError>;

function formatResponseErrorMessages(response: ErrorResponse): string {
  return formatErrorResponseV2(response).messages?.map((m) => m.message).join(', ') || 'Unknown error';
}

const EditShippingAddressForm: React.FC<EditShippingAddressFormProps> = ({
  handleSubmit,
  countryCode,
  organizationId,
  addressValidation,
  addressValidationMsg,
  order,
  form,
}) => {
  const dispatch = useThunkDispatcher();
  const formValues = useSelector(
    getFormValues(form),
  ) as OrderShippingAddress & { bypass_house_number_validation: boolean };
  const [validationError, setValidationError] = useState<string | undefined>(undefined);
  const [
    validationMessage,
    setValidationMessage,
  ] = useState<string | undefined>(addressValidationMsg || undefined);
  const [loadingMessage, setLoadingMessage] = useState<string | undefined>(undefined);
  const [showCantFixConfirmation, setShowCantFixConfirmation] = useState<boolean>(false);
  const carrierServiceId = getDesiredService(order.deliveries);
  const organization = useSelector(getOrganization);
  const panelRef = useRef<HTMLDivElement>(null);
  const isOrderAddressValidationFeatureEnabled = useSelector(
    checkFeatureValueByKey(FeatureKey.GLOBAL_LABEL_ADDRESS_VALIDATION),
  );
  const showAddressCorrectionPanel = addressValidation !== null;

  useEffect(() => {
    if (isNil(carrierServiceId)) {
      setValidationError('No preferred carrier service found for this order.');
    }
  }, [carrierServiceId]);

  const handleTestAddressClick = useCallback(() => {
    if (isNotNil(carrierServiceId)) {
      setValidationError(undefined);
      setLoadingMessage('Testing Address...');
      dispatch(updateOrderAddressValidation(organizationId, {
        discriminator: 'console_label_request_form',
        order_number: order.number,
        destination: orderFormAddressToShippingAddress(formValues, countryCode),
        bypass_house_number_validation: formValues.bypass_house_number_validation,
      })).then((response) => {
        if (response.ok) {
          setValidationMessage('✔︎ Address is valid');
        } else {
          setValidationError(formatResponseErrorMessages(response));
        }
      }).finally(() => {
        setLoadingMessage(undefined);
      });
    }
  }, [carrierServiceId, countryCode, dispatch, formValues, order.number, organizationId]);

  const handleCantFixClickDialog = useCallback(() => {
    setShowCantFixConfirmation(true);
  }, []);

  const handleCantFixClickDialogCancel = useCallback(() => {
    setShowCantFixConfirmation(false);
  }, []);

  const handleCantFixClick = useCallback(() => {
    setShowCantFixConfirmation(false);
    if (isNotNil(carrierServiceId)) {
      setValidationError(undefined);
      setLoadingMessage('Setting as Can\'t fix...');
      dispatch(updateOrderAddressValidation(organizationId, {
        discriminator: 'console_mark_unresolvable_form',
        order_number: order.number,
        note: 'Address marked as can\'t fix by human',
      })).then((response) => {
        if (response.ok) {
          setValidationMessage('Address marked as can\'t fix.');
        } else {
          setValidationError(formatResponseErrorMessages(response));
        }
      }).finally(() => {
        setLoadingMessage(undefined);
      });
    }
  }, [carrierServiceId, dispatch, order.number, organizationId]);

  useEffect(() => {
    if (
      isOrderAddressValidationFeatureEnabled
      && showAddressCorrectionPanel
      && isNotNil(organization)
      && organization.type === OrganizationType.CHANNEL
      && isNotNil(panelRef.current)
    ) {
      try {
        // dirty hacks to manipulate buttons as ain't nobody got time for editing our old redux
        // libraries 😱
        const saveButton = panelRef.current.parentElement?.parentElement?.parentElement?.querySelectorAll('button')[1];
        const cancelButton = panelRef.current.parentElement?.parentElement?.parentElement?.querySelectorAll('button')[0];
        if (isNotNil(saveButton)) {
          saveButton.style.display = 'none';
        }
        if (isNotNil(cancelButton)) {
          cancelButton.textContent = 'Close';
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn('Tried to manipulate the buttons to no avail. 😖');
      }
    }
  }, [isOrderAddressValidationFeatureEnabled, organization, showAddressCorrectionPanel]);

  function getValidationStatusMessage(): ReactNode {
    if (isNotNil(loadingMessage)) {
      return (
        <Box justifyContent="start" alignItems="center" spacing="tight" css={{ color: colors['flow-blue'][400] }}>
          <ArcLoader width={16} css={{ marginTop: '6px' }} fill={colors['flow-blue'][400]} />
          <strong>{loadingMessage}</strong>
        </Box>
      );
    }
    if (validationError) {
      return <strong css={{ color: colors.red[300] }}>{validationError}</strong>;
    }

    return <strong css={{ color: colors['flow-blue'][400] }}>{validationMessage}</strong>;
  }

  function maybeRenderAddressValidationPanel(): ReactNode {
    const cantFixButtonDisabled = isNotNil(addressValidation) && (
      addressValidation.status === 'unresolvable'
    || addressValidation.status === 'success'
    || addressValidation.status === 'label_created'
    );

    if (isNotNil(organization) && organization.type === OrganizationType.CHANNEL) {
      return (
        <FeatureValueSelector
          featureKey={FeatureKey.GLOBAL_LABEL_ADDRESS_VALIDATION}
          render={({ isFeatureEnabled }): ReactNode => {
            if (!isFeatureEnabled || !showAddressCorrectionPanel) return null;
            return (
              <Panel
                css={{
                  backgroundColor: colors.gray[100], marginBottom: '16px', borderColor: colors.gray[300], padding: '16px',
                }}
              >
                <div ref={panelRef} />
                <Confirm
                  open={showCantFixConfirmation}
                  onOk={handleCantFixClick}
                  onCancel={handleCantFixClickDialogCancel}
                >
                  Please confirm you want to mark this address as <strong>Can&apos;t fix</strong>?
                </Confirm>
                <Box justifyContent="between" alignItems="center">
                  <>
                    {getValidationStatusMessage()}
                  </>
                  <Box spacing="tight">
                    <Field component={Checkbox} type="checkbox" name="bypass_house_number_validation" />
                    <span>Bypass House Number Validation</span>
                    <Button intent="primary" onClick={handleTestAddressClick}>Test Address</Button>
                    <Button disabled={cantFixButtonDisabled} intent="neutral" onClick={handleCantFixClickDialog}>Can&apos;t Fix</Button>
                  </Box>
                </Box>
              </Panel>
            );
          }}
        />
      );
    }
    return null;
  }

  const fullCountryName = get(countries.find(countryCode || ''), 'name', countryCode);
  return (
    <>
      {maybeRenderAddressValidationPanel()}
      <Form onSubmit={handleSubmit}>
        <Field
          fluid
          inline
          gutter
          component={TextField}
          hintText="First Name"
          name="contact.name.first"
          labelText="First Name"
          labelFor="contact.name.first"
          type="text"
        />
        <Field
          fluid
          inline
          gutter
          component={TextField}
          hintText="Last Name"
          labelText="Last Name"
          name="contact.name.last"
          type="text"
        />
        <Field
          fluid
          inline
          gutter
          component={TextField}
          hintText="Company"
          name="contact.company"
          labelText="Company"
          labelFor="contact.company"
          type="text"
        />
        <Field
          fluid
          inline
          gutter
          component={TextField}
          hintText="Address Line 1"
          name="streets[0]"
          labelText="Address Line 1"
          labelFor="streets[0]"
          type="text"
        />
        <Field
          fluid
          inline
          gutter
          component={TextField}
          hintText="Address Line 2"
          name="streets[1]"
          labelText="Address Line 2"
          labelFor="streets[1]"
          type="text"
        />
        <Field
          fluid
          inline
          gutter
          component={TextField}
          hintText="Address Line 3"
          name="streets[2]"
          labelText="Address Line 3"
          labelFor="streets[2]"
          type="text"
        />
        <Field
          fluid
          inline
          gutter
          component={TextField}
          hintText="Phone"
          name="contact.phone"
          labelText="Phone"
          labelFor="contact.phone"
          type="text"
        />
        <Field
          fluid
          inline
          gutter
          component={TextField}
          hintText="City/Town"
          name="city"
          labelText="City/Town"
          labelFor="city"
          type="text"
        />
        <Field
          fluid
          inline
          gutter
          component={TextField}
          hintText="State/Province"
          name="province"
          labelText="State/Province"
          labelFor="province"
          type="text"
        />
        <Field
          fluid
          inline
          gutter
          component={TextField}
          hintText="Postal Code"
          name="postal"
          labelText="Postal Code"
          labelFor="postal"
          type="text"
        />
        <Field
          fluid
          inline
          gutter
          component={TextField}
          readOnly
          hintText={fullCountryName}
          labelText="Country"
          labelFor="country"
          type="text"
        />
      </Form>
    </>
  );
};

export default EditShippingAddressForm;
