import reduce from 'lodash/reduce';
import { Status as StatusList } from '@flowio/api-internal-constants';
import { isExperienceExperiment } from './components/experiment-details/utilities/checkExperiment';

interface Summary {
  distribution: number[];
  sum: number;
}

export const isTrafficEquallyAllocated = (traffic: number[], sumTotal = 100): boolean => {
  if (!traffic.length) {
    return true;
  }

  const summary: Summary = reduce(traffic, ({ distribution, sum }: Summary, t) => {
    if (!distribution.includes(t)) {
      distribution.push(t);
    }

    return {
      distribution,
      sum: sum + t,
    };
  }, { distribution: [], sum: 0 });

  if (summary.sum !== sumTotal) {
    return false;
  }

  switch (summary.distribution.length) {
    case 1:
      return true;
    case 2:
      return Math.abs(summary.distribution[0] - summary.distribution[1]) === 1;
    default:
      return false;
  }
};

type Intent = 'positive' | 'warning' | 'negative' | 'neutral';

export const getLabelIntentByStatus = (status: io.flow.internal.v0.enums.Status): Intent => {
  switch (status) {
    case StatusList.LIVE:
      return 'positive';
    case StatusList.SCHEDULED:
    case StatusList.DRAFT:
      return 'warning';
    case StatusList.ENDED:
    case StatusList.ARCHIVED:
    default:
      return 'neutral';
  }
};

export const getSignificanceActionMsg = (experiment ?: io.flow.internal.v0.unions.Experiment) => {
  if (!experiment || !isExperienceExperiment(experiment)) {
    return '-';
  }

  switch (experiment.significance_action) {
    case 'end_and_implement_winner':
      return 'Experiment will automatically end when statistical significance is reached and the winner will be implemented';
    case 'end_and_revert':
      return 'Experiment will automatically end when statistical significance is reached and revert back to the default experience';
    default:
      return 'Experiment will continue running until it is manually ended';
  }
};

export default {
  getLabelIntentByStatus,
  isTrafficEquallyAllocated,
};
