/* eslint-disable */

let source = 'https://www.google-analytics.com/analytics.js';

// https://developers.google.com/analytics/devguides/collection/analyticsjs/debugging
if (process.env.NODE_ENV !== 'production') {
  source = 'https://www.google-analytics.com/analytics_debug.js';
}

// https://developers.google.com/analytics/devguides/collection/analyticsjs/
export default function addTrackingSnippet() {
  (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
  (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
  m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
  })(window,document,'script',source,'ga');
}
