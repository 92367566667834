import { css } from '@emotion/css';

export const row = css`
  line-height: 2rem;
  padding-bottom: 0.5rem;
`;

export const iconWrapper = css`
  background-color: #f2f5f7;
  border-radius: 4px;
  display: inline-block;
  padding: 0.5rem 1rem;
`;

export const field = css`
  padding-bottom: 6px;

  &.label {
    font-weight: 500;
    color: #9ba6b3;
  }

  &.align-right {
    text-align: right;
  }
`;

export const actionWrapper = css`
  display: inline-block;
  position: relative;
  top: 14px;
  padding-left: 1rem;
`;

export const invoiceIcon = css`
  height: 50px;
  stroke: none;
  fill: #0051b2 !important;
`;
export const labelIcon = invoiceIcon;

export const actionLabel = css`
  padding-right: 1rem;
  minWidth: 250px;
`;

export const itemTable = css`
  padding-left: 0;
  padding-right: 0;
`;

export const itemNumber = css`
  padding-left: 16px !important;
`;
