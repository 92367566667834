import hideAddRuleWorksheet from './hideAddRuleWorksheet';
import { createToast } from '../../console/actions';
import ActionTypes from '../constants/action-types';
import { ThunkResult } from '../../../stores/types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';

interface UpdateOptions {
  featureRuleForm: io.flow.internal.v0.unions.FeatureRuleForm;
}

export default function updateRuleByKeyAndId(
  organizationId: string,
  key: string,
  id: string,
  options: UpdateOptions,
): ThunkResult<Promise<void>> {
  return function updateRuleByKeyAndIdEffects(dispatch, getState, extra) {
    dispatch({
      type: ActionTypes.UPDATE_RULE_BY_KEY_AND_ID_REQUEST,
    });

    return extra.apiInternal(getState()).features.putRulesByKeyAndId({
      id,
      key,
      organization: organizationId,
      body: options.featureRuleForm,
    }).then((response) => {
      if (response.ok) {
        dispatch({
          type: ActionTypes.UPDATE_RULE_BY_KEY_AND_ID_SUCCESS,
          payload: response.body,
        });
        dispatch(hideAddRuleWorksheet());
        dispatch(createToast({
          content: 'A rule has been updated.',
          icon: 'CheckCircle',
          intent: 'positive',
        }));
        return;
      }

      const errors = formatErrorResponseV2(response);
      dispatch({
        type: ActionTypes.UPDATE_RULE_BY_KEY_AND_ID_FAILURE,
        payload: errors,
      });

      dispatch(hideAddRuleWorksheet());
      dispatch(createToast({
        content: errors.messages ? errors.messages.map((m) => m.message).join(', ') : 'Failed to update rule, try again later',
        icon: 'Error',
        intent: 'negative',
      }));
    });
  };
}
