import update from 'immutability-helper';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import upsertBy from '../../../utilities/arrays/upsertBy';
import ActionTypes from '../constants/action-types';

import { ExperienceState } from '../types';
import { RootActionTypes } from '../../../stores/types';

type State = ExperienceState['entities']['margins'];

const initialState: State = {
  isFirstPage: true,
  isLastPage: true,
  results: [],
};

const sort = (
  margins: io.flow.v0.models.ItemMargin[],
): io.flow.v0.models.ItemMargin[] => sortBy(margins, (m) => m.position);

export default function (state: State = initialState, action: RootActionTypes): State {
  switch (action.type) {
    case ActionTypes.GET_MARGINS_SUCCESS:
      return update(state, { results: { $set: sort(action.payload) } });
    case ActionTypes.CREATE_MARGIN_SUCCESS:
      return update(state, { results: { $set: sort([...state.results, action.payload]) } });
    case ActionTypes.GET_MARGIN_SUCCESS:
    case ActionTypes.UPDATE_MARGIN_SUCCESS: {
      const updated = upsertBy(
        (margin: io.flow.v0.models.ItemMargin) => margin.key === action.payload.key,
        action.payload,
        state.results,
      );
      return update(state, { results: { $set: sort(updated) } });
    }
    case ActionTypes.DELETE_MARGIN_SUCCESS: {
      const updated = filter(state.results, (m) => m.key !== action.marginKey);
      return update(state, { results: { $set: updated } });
    }
    case ActionTypes.UPDATE_ITEM_MARGINS:
      return update(state, { results: { $set: action.payload } });
    default:
      return state;
  }
}
