import update from 'immutability-helper';

import ActionTypes from '../constants/action-types';
import { type RootActionTypes } from '../../../stores/types';
import {
  type FullSelectedItem,
  type OrderRefundAmount,
} from '../types';

export type CustomerServiceUiState = {
  showRefundFormDialog: boolean;
  showReturnFormDialog: boolean;
  showReturnConfirmation: boolean;
  showApproveFraudReviewDialog: boolean;
  showDeclineFraudReviewDialog: boolean;
  refundObj: Partial<OrderRefundAmount>;
  returnDetailState: {
    showReturnDetailsDialog: boolean;
    items: FullSelectedItem[];
    pdfLabel?: string;
    pdfInvoice?: string;
    showReturnNumberInput: boolean;
  };
  orderTrackingDetails: {
    showOrderTrackingDetailsDialog: boolean;
    orderNumber: string | undefined;
    labelSummary: io.flow.internal.v0.models.LabelSummary | undefined;
    shippingNotification: io.flow.v0.models.ShippingNotification | undefined;
  };
  notification?: string;
};

const initialState: CustomerServiceUiState = {
  showRefundFormDialog: false,
  showReturnFormDialog: false,
  showReturnConfirmation: false,
  showApproveFraudReviewDialog: false,
  showDeclineFraudReviewDialog: false,
  refundObj: {},
  returnDetailState: {
    showReturnDetailsDialog: false,
    items: [],
    pdfLabel: '',
    pdfInvoice: '',
    showReturnNumberInput: false,
  },
  orderTrackingDetails: {
    showOrderTrackingDetailsDialog: false,
    orderNumber: undefined,
    labelSummary: undefined,
    shippingNotification: undefined,
  },
  notification: undefined,
};

export default function uiReducer(
  state = initialState,
  action: RootActionTypes,
): CustomerServiceUiState {
  switch (action.type) {
    case ActionTypes.SHOW_REFUND_FORM_DIALOG:
      return update(state, { showRefundFormDialog: { $set: true } });
    case ActionTypes.HIDE_REFUND_FORM_DIALOG:
      return update(state, { showRefundFormDialog: { $set: false } });
    case ActionTypes.SHOW_RETURN_FORM_DIALOG:
      return update(state, { showReturnFormDialog: { $set: true } });
    case ActionTypes.HIDE_RETURN_FORM_DIALOG:
      return update(state, { showReturnFormDialog: { $set: false } });
    case ActionTypes.SET_NOTIFICATION:
      return update(state, { notification: { $set: action.payload } });
    case ActionTypes.RESET_NOTIFICATION:
      return update(state, { notification: { $set: undefined } });
    case ActionTypes.SHOW_RETURN_CONFIRMATION:
      return update(state, { showReturnConfirmation: { $set: true } });
    case ActionTypes.HIDE_RETURN_CONFIRMATION:
      return update(state, { showReturnConfirmation: { $set: false } });
    case ActionTypes.SHOW_RETURN_DETAIL_DIALOG:
      return update(state, {
        returnDetailState: {
          $set: {
            showReturnDetailsDialog: true,
            items: action.payload.items,
            pdfLabel: action.payload.pdfLabel,
            pdfInvoice: action.payload.pdfInvoice,
            showReturnNumberInput: false,
          },
        },
      });
    case ActionTypes.HIDE_RETURN_DETAIL_DIALOG:
      return update(state, {
        returnDetailState: {
          $set: {
            showReturnDetailsDialog: false,
            items: [],
            pdfLabel: '',
            pdfInvoice: '',
            showReturnNumberInput: false,
          },
        },
      });
    case ActionTypes.SHOW_ORDER_TRACKING_DETAIL_DIALOG:
      return update(state, {
        orderTrackingDetails: {
          $set: {
            showOrderTrackingDetailsDialog: true,
            orderNumber: action.payload.orderNumber,
            labelSummary: action.payload.labelSummary,
            shippingNotification: action.payload.shippingNotification,
          },
        },
      });
    case ActionTypes.HIDE_ORDER_TRACKING_DETAIL_DIALOG:
      return update(state, {
        orderTrackingDetails: {
          $set: {
            showOrderTrackingDetailsDialog: false,
            orderNumber: undefined,
            labelSummary: undefined,
            shippingNotification: undefined,
          },
        },
      });
    case ActionTypes.SHOW_APPROVE_FRAUD_REVIEW_DIALOG:
      return update(state, { showApproveFraudReviewDialog: { $set: true } });
    case ActionTypes.HIDE_APPROVE_FRAUD_REVIEW_DIALOG:
      return update(state, { showApproveFraudReviewDialog: { $set: false } });
    case ActionTypes.SHOW_DECLINE_FRAUD_REVIEW_DIALOG:
      return update(state, { showDeclineFraudReviewDialog: { $set: true } });
    case ActionTypes.HIDE_DECLINE_FRAUD_REVIEW_DIALOG:
      return update(state, { showDeclineFraudReviewDialog: { $set: false } });
    case ActionTypes.SHOW_RETURN_NUMBER_INPUT:
      return update(state, { returnDetailState: { showReturnNumberInput: { $set: true } } });
    case ActionTypes.HIDE_RETURN_NUMBER_INPUT:
      return update(state, { returnDetailState: { showReturnNumberInput: { $set: false } } });
    case ActionTypes.UPDATE_REFUND_AMOUNT:
      return update(state, { refundObj: { $set: action.payload } });
    case ActionTypes.CLEAR_REFUND_AMOUNT:
      return update(state, { refundObj: { $set: {} } });
    default:
      return state;
  }
}
