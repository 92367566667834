import { browserHistory } from 'react-router';
import { ThunkDispatcher, RootState, ThunkResult } from '../../../stores/types';
import { getMemberships } from '../../user';
import isChannelOrgAdmin from '../utilities/is-channel-organization-admin-user';
import isCustomerServiceUser from '../utilities/is-customer-service-user';
import isFulfillmentUser from '../utilities/isFulfillmentUser';
import isClassificationUser from '../utilities/is-classification-user';

export default function defaultRedirect(organizationId: string): ThunkResult<void> {
  return (
    _: ThunkDispatcher,
    getState: () => RootState,
  ): void => {
    const state = getState();
    if (isCustomerServiceUser(organizationId, getMemberships(state))) {
      browserHistory.push(`/${organizationId}/orders/search`);
    } else if (isClassificationUser(organizationId, getMemberships(state))) {
      browserHistory.push(`/${organizationId}/catalog/items`);
    } else if (isChannelOrgAdmin(organizationId, getMemberships(state))) {
      browserHistory.push(`/${organizationId}/organization/accounts`);
    } else if (isFulfillmentUser(organizationId, getMemberships(state))) {
      browserHistory.push(`/${organizationId}/fulfillment`);
    } else {
      browserHistory.push(`/${organizationId}/experience`);
    }
  };
}
