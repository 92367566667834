import React from 'react';
import { Box } from '@flowio/react-box';
import { Grid, Column, Row } from '@flowio/react-grid';
import { RadioButtonGroup, RadioButton } from '@flowio/react-radio-button';
import classNames from 'classnames';
import { DeliveredDuty as DeliveredDuties } from '@flowio/api-constants';

import CurrencyConversion from '../currency-conversion';
import * as styles from './LandedCostListingPreview.styles';

interface Props {
  deliveredDuty: io.flow.v0.enums.DeliveredDuty;
  experienceKey: string;
}

interface State {
  value: string;
}

class LandedCostListingPreview extends React.Component<Props, State> {
  static displayName = 'LandedCostListingPreview';

  constructor(props: Props, context: unknown) {
    super(props, context);
    this.state = this.getInitialState();
    this.handleChange = this.handleChange.bind(this);
  }

  getInitialState(): State {
    return this.getStateFromProps(this.props);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: Props): void {
    this.setState(this.getStateFromProps(nextProps));
  }

  handleChange(value: string): void {
    this.setState({ value });
  }

  getStateFromProps(props = this.props): State {
    const { deliveredDuty } = props;
    return {
      value: (deliveredDuty === DeliveredDuties.UNPAID)
        ? 'opt-a8a725c6dc2240e8997259fea586992d'
        : 'opt-a8a725c6dc2240e8997259fea586992f',
    };
  }

  render(): JSX.Element {
    const {
      deliveredDuty,
      experienceKey,
    } = this.props;
    const { value } = this.state;

    return (
      <Grid>
        <Row>
          <Column span={12}>
            <div className={styles.preview}>
              <div className={styles.content}>
                <section className={styles.section}>
                  <h2 className={styles.heading}>Duties and Taxes</h2>
                  <p className={styles.deliveredDuty}>
                    <strong>Duties &amp; Taxes:</strong>
                    {' '}
                    You can choose
                    to pay duties and taxes now or at delivery in the options below.
                  </p>
                </section>
                <section className={styles.section}>
                  <div className={styles.watermark}>Example Only</div>
                  <h2 className={styles.heading}>Shipping Method</h2>
                  <Box direction="column" reverse={deliveredDuty === DeliveredDuties.PAID}>
                    <Box className={classNames({ [styles.first]: deliveredDuty === DeliveredDuties.UNPAID })} direction="column">
                      <Box alignItems="center">
                        <span className={classNames(styles.label, 'unpaid')}>Unpaid</span>
                        <h3 className={classNames(styles.subheading, 'unpaid')}>Pay Tax / Duty at Delivery</h3>
                      </Box>
                      <RadioButtonGroup
                        onValueChange={this.handleChange}
                        value={value}
                      >
                        <Box direction="column" spacing="loose">
                          <RadioButton value="opt-a8a725c6dc2240e8997259fea586992d">
                            <Box className={styles.radioLabel} inline alignItems="center" justifyContent="between">
                              <span>Standard Delivery (7-13 Business Days)</span>
                              <CurrencyConversion amount="0.00" base="USD" experienceKey={experienceKey} />
                            </Box>
                          </RadioButton>
                          <RadioButton value="opt-25591f241ab24502a12be7718a23d0be">
                            <Box className={styles.radioLabel} inline alignItems="center" justifyContent="between">
                              <span>Express Delivery (2-5 Business Days)</span>
                              <CurrencyConversion amount="25.00" base="USD" experienceKey={experienceKey} />
                            </Box>
                          </RadioButton>
                        </Box>
                      </RadioButtonGroup>
                    </Box>
                    <Box className={classNames({ [styles.first]: deliveredDuty === DeliveredDuties.PAID })} direction="column">
                      <Box alignItems="center">
                        <span className={classNames([styles.label], 'paid')}>Paid</span>
                        <h3 className={classNames(styles.subheading, 'paid')}>Pay Tax / Duty Now</h3>
                      </Box>
                      <RadioButtonGroup
                        onValueChange={this.handleChange}
                        value={value}
                      >
                        <Box direction="column" spacing="loose">
                          <RadioButton value="opt-a8a725c6dc2240e8997259fea586992f">
                            <Box className={styles.radioLabel} inline alignItems="center" justifyContent="between">
                              <span>Standard Delivery (7-13 Business Days)</span>
                              <CurrencyConversion amount="0.00" base="USD" experienceKey={experienceKey} />
                            </Box>
                          </RadioButton>
                          <RadioButton value="opt-25591f241ab24502a12be7718a23d0bg">
                            <Box className={styles.radioLabel} inline alignItems="center" justifyContent="between">
                              <span>Express Delivery (2-5 Business Days)</span>
                              <CurrencyConversion amount="25.00" base="USD" experienceKey={experienceKey} />
                            </Box>
                          </RadioButton>
                        </Box>
                      </RadioButtonGroup>
                    </Box>
                  </Box>
                </section>
              </div>
              <p className={styles.disclaimer}>
                <strong>Preview at Checkout</strong>
                : Above is what your checkout
                might look like with the current landed cost configuration.
                Your actual interface may differ.
              </p>
            </div>
          </Column>
        </Row>
      </Grid>
    );
  }
}

export default LandedCostListingPreview;
