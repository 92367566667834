import { OptinAttributesDeleteByKeyResponse } from '@flowio/api-internal-sdk';
import ActionTypes from '../constants/ActionTypes';
import {
  RootState, ThunkExtraArguments, ThunkResult, ThunkDispatcher,
} from '../../../stores/types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';
import standardAction from '../../../utilities/standard-action';

const deleteOptinPromptById = (
  organization: string,
  id: string,
): ThunkResult<Promise<OptinAttributesDeleteByKeyResponse>> => (
  dispatch: ThunkDispatcher,
  getState: () => RootState,
  extra: ThunkExtraArguments,
): Promise<OptinAttributesDeleteByKeyResponse> => {
  dispatch(standardAction(ActionTypes.DELETE_OPTIN_PROMPT_REQUEST));

  return extra.apiInternal(getState()).optinPrompts.deletePromptsById({
    organization,
    id,
  }).then((response) => {
    if (response.ok) {
      dispatch(standardAction(ActionTypes.DELETE_OPTIN_PROMPT_SUCCESS, response.body));
    } else {
      dispatch({
        type: ActionTypes.DELETE_OPTIN_PROMPT_FAILURE,
        payload: formatErrorResponseV2(response),
      });
    }

    return response;
  });
};

export default deleteOptinPromptById;
