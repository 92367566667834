import ActionTypes from '../constants/ActionTypes';
import {
  MarketingActions,
  MarketingFeedSourcesState,
} from '../types';

const defaultState: MarketingFeedSourcesState = {
  error: false,
  loading: false,
  sources: [],
};

export default function feedSources(
  state = defaultState,
  action: MarketingActions,
): MarketingFeedSourcesState {
  switch (action.type) {
    case ActionTypes.FETCH_FEED_SOURCES_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case ActionTypes.FETCH_FEED_SOURCES_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        sources: action.payload,
      };
    case ActionTypes.FETCH_FEED_SOURCES_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
        sources: [],
      };
    default:
      return state;
  }
}
