import { ArrowUp, ArrowDown, Close } from '@flowio/react-icons';
import { Box, BoxItem } from '@flowio/react-box';
import { IconButton } from '@flowio/react-button';
import React from 'react';
import { Stack } from '@flowio/react-stack';
import classNames from 'classnames/bind';

import TierRuleOutcome from '../TierRuleOutcome';
import TierRuleQuery from '../TierRuleQuery';
import styles from './TierRule.module.css';
import { TierRuleProps } from '../../types/components';

const cx = classNames.bind(styles);

const TierRule: React.FC<TierRuleProps> = ({
  bottom,
  name,
  onDemoteRule,
  onPromoteRule,
  onRemoveRule,
  top,
}) => (
  <Box className={cx('tierRule')}>
    <Stack className={cx('leftActions')} orientation="vertical" distribution="equalSpacing">
      <IconButton variant="flat" type="button" icon={ArrowUp} onClick={onPromoteRule} disabled={top} />
      <IconButton variant="flat" type="button" icon={ArrowDown} onClick={onDemoteRule} disabled={bottom} />
    </Stack>
    <BoxItem className={cx('content')} flexAuto>
      <Stack orientation="vertical" spacing="tight">
        <TierRuleQuery name={`${name}.query`} />
        <TierRuleOutcome name={`${name}.outcome`} />
      </Stack>
    </BoxItem>
    <Stack className={cx('rightActions')} orientation="vertical" distribution="equalCentering">
      <IconButton variant="flat" type="button" icon={Close} onClick={onRemoveRule} />
    </Stack>
  </Box>
);

TierRule.displayName = 'TierRule';

export default TierRule;
