import { combineReducers } from 'redux';
import update from 'immutability-helper';
import ActionTypes from '../constants/action-types';
import { RootActionTypes } from '../../../stores/types';
import { AnyAction } from '../../../middlewares/types';
import { AnyActionWithParams, AnyActionWithPayload } from '../../../utilities/redux/types';
import { ImportFormState } from '../types';

function createFormReducer(types: AnyAction['type']) {
  const [REQUEST, SUCCESS, FAILURE] = types;
  const defaultState: ImportFormState = {
    error: undefined,
    params: undefined,
    submitted: false,
    submitting: false,
  };
  return function formReducer(
    state = defaultState,
    action: RootActionTypes,
  ) {
    // TODO: Need action to reset form state so that successful submitted messages are not
    // visible between page transitions.
    switch (action.type) {
      case REQUEST:
        return update(state, {
          error: { $set: undefined },
          params: { $set: (action as AnyActionWithParams).params },
          submitted: { $set: false },
          submitting: { $set: true },
        });
      case SUCCESS:
        return update(state, {
          submitted: { $set: true },
          submitting: { $set: false },
        });
      case FAILURE:
        return update(state, {
          error: { $set: (action as AnyActionWithPayload).payload },
          submitting: { $set: false },
        });
      default:
        return state;
    }
  };
}

export default combineReducers({
  imports: createFormReducer([
    ActionTypes.IMPORT_FILE_REQUEST,
    ActionTypes.IMPORT_FILE_SUCCESS,
    ActionTypes.IMPORT_FILE_FAILURE,
  ]),
});
