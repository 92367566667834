import { css } from '@emotion/react';

export const inlineFormStyles = css({
  display: 'flex',
});

export const inlineInputStyles = css({
  flex: '1',
});

export const inlineButtonStyles = css({
  margin: '0 0 0 1rem',
});

export const tableColumn = css({
  fontSize: '0.825rem',
  verticalAlign: 'text-top',
});

export const dynamicWidthColumn = css({
  width: '1%',
  whiteSpace: 'nowrap',
});
