import createClient from '@flowio/node-sdk';

import { getRequiredEnvVar } from '../environment';
import ApiProxy from './api-proxy';

const client = createClient({
  host: getRequiredEnvVar('FLOW_API_URI'),
});

export default new ApiProxy(client);
