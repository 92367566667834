export const isGoogleFeedSource = (
  source: io.flow.internal.v0.unions.MarketingGatewayFeedSource,
): source is io.flow.internal.v0.models.MarketingGatewayFeedSourceGoogle => (
  source as io.flow.internal.v0.models.MarketingGatewayFeedSourceGoogle
).discriminator === 'marketing_gateway_feed_source_google';

export const isFacebookFeedSource = (
  source: io.flow.internal.v0.unions.MarketingGatewayFeedSource,
): source is io.flow.internal.v0.models.MarketingGatewayFeedSourceFacebook => (
  source as io.flow.internal.v0.models.MarketingGatewayFeedSourceFacebook
).catalog_id !== undefined;

export const isGoogleSourceSummary = (
  summary: io.flow.internal.v0.unions.MarketingGatewaySourceSummary,
): summary is io.flow.internal.v0.models.MarketingGatewayGoogleSourceSummary => (
  summary as io.flow.internal.v0.models.MarketingGatewayGoogleSourceSummary
).account_id !== undefined;

export const isFacebookSourceSummary = (
  summary: io.flow.internal.v0.unions.MarketingGatewaySourceSummary,
): summary is io.flow.internal.v0.models.MarketingGatewayFacebookSourceSummary => (
  summary as io.flow.internal.v0.models.MarketingGatewayFacebookSourceSummary
).catalog_id !== undefined;

export const hasGoogleSourceSummary = (
  summary: io.flow.internal.v0.unions.MarketingGatewaySourceSummary,
): summary is io.flow.internal.v0.models.MarketingGatewayGoogleSourceSummary => (
  summary as io.flow.internal.v0.models.MarketingGatewayGoogleSourceSummary).discriminator === 'marketing_gateway_google_source_summary';
