import React, { PureComponent } from 'react';
import assign from 'lodash/assign';
import noop from 'lodash/noop';
import get from 'lodash/get';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import kebabCase from 'lodash/kebabCase';
import {
  Card, CardHeader, CardContent, CardFooter, CardDescription, CardTitle,
} from '@flowio/react-card';
import { PageContent } from '@flowio/react-page';
import Alert from '../../../../../components/alert/alert';

import ExperienceSettings from '../../experience-settings';
import OrderSettings from './order-settings';
import OrderSummary from './order-summary';
import { MergedProps } from '../types';
import * as styles from './order-summary-check.styles';

class OrderSummaryCheck extends PureComponent<MergedProps> {
  static displayName = 'OrderSummaryCheck';

  static defaultProps = {
    selections: [],
    onAddItem: noop,
    onExperienceChange: noop,
    onCenterChange: noop,
    onDeliveryOptionsChange: noop,
    onQuantityChange: noop,
    onRemoveItem: noop,
    country: undefined,
    experience: undefined,
    center: undefined,
    orderEstimate: undefined,
    pricing: undefined,
    priceFormat: undefined,
    landedCostSetting: undefined,
    currency: undefined,
    error: undefined,
  };

  handleExperienceChange = (experience: io.flow.v0.models.Experience): void => {
    const { onExperienceChange } = this.props;
    onExperienceChange(experience.key);
  };

  handleCenterChange = (center: io.flow.v0.models.Center): void => {
    const { onCenterChange } = this.props;
    onCenterChange(center);
  };

  handleAddItem = (item: io.flow.v0.models.Item): void => {
    const { onAddItem } = this.props;
    const items = this.getItems();
    const foundItem = find(items, (i) => i.number === item.number);

    if (foundItem) {
      this.handleQuantityChange(foundItem.number, foundItem.quantity + 1);
    } else {
      onAddItem(assign({ item }, this.getSettings()));
    }
  };

  handleDeliveryOptionChange = (selections: Selection): void => {
    const { onDeliveryOptionsChange } = this.props;
    onDeliveryOptionsChange(selections);
  };

  handleQuantityChange = (number: string, quantity: number): void => {
    const { onQuantityChange } = this.props;
    onQuantityChange({ number, quantity });
  };

  handleRemoveItem = (number: string): void => {
    const { onRemoveItem } = this.props;
    onRemoveItem(number);
  };

  getSettings(): ({
    experience: MergedProps['experience'];
    country: MergedProps['country'];
    center: MergedProps['center'];
    selections: MergedProps['selections'];
  }) {
    const {
      experience, country, center, selections,
    } = this.props;
    return {
      experience, country, center, selections,
    };
  }

  getItems(): io.flow.v0.models.LocalizedLineItem[] {
    const { orderEstimate } = this.props;
    return get(orderEstimate, 'items', []);
  }

  render(): React.ReactElement {
    const {
      experience,
      country,
      center,
      experiences,
      centers,
      orderEstimate,
      pricing,
      priceFormat,
      landedCostSetting,
      currency,
      error,
    } = this.props;

    const orgSettingsProps = {
      experience,
      country,
      center,
      deliveries: get(orderEstimate, 'deliveries', []),
      selections: get(orderEstimate, 'selections', []),
      experiences,
      centers,
    };

    return (
      <PageContent className={styles.orderCheck}>
        <Card>
          <CardHeader dividing>
            <CardTitle content="Order Check" />
            <CardDescription>
              View and test what an order in checkout should look like, given the
              experience settings that have been set up for your organization.
              The implementation on your website&apos;s checkout should match the
              numbers provided here.
            </CardDescription>
          </CardHeader>
          <CardContent>
            {isEmpty(orderEstimate) && !isEmpty(error) && error.message && (
              <Alert key={kebabCase(error.message)} type="failure">
                {error.message}
              </Alert>
            )}
            <OrderSettings
              {...orgSettingsProps}
              onExperienceChange={this.handleExperienceChange}
              onCenterChange={this.handleCenterChange}
              onAddItem={this.handleAddItem}
              onDeliveryOptionChange={this.handleDeliveryOptionChange}
            />
          </CardContent>
          <CardFooter />
        </Card>

        {orderEstimate && (
          <OrderSummary
            orderEstimate={orderEstimate}
            onQuantityChange={this.handleQuantityChange}
            onRemoveItem={this.handleRemoveItem}
          />
        )}

        {currency
          && landedCostSetting
          && orderEstimate
          && pricing
          && priceFormat && (
          <Card>
            <CardHeader dividing>
              <CardTitle content="Settings" />
              <CardDescription content="The settings used to calculate this order" />
            </CardHeader>
            <CardContent>
              <ExperienceSettings
                pricing={pricing}
                priceFormat={priceFormat}
                landedCostSetting={landedCostSetting}
                currency={currency}
              />
            </CardContent>
            <CardFooter />
          </Card>
        )}
      </PageContent>
    );
  }
}

export default OrderSummaryCheck;
