import ActionTypes from '../constants/ActionTypes';
import { RootActionTypes } from '../../../stores/types';

export default function updateDialog(dialogType: string, dialogProps: any): RootActionTypes {
  return {
    type: ActionTypes.UPDATE_DIALOG,
    payload: {
      type: dialogType,
      props: dialogProps,
    },
  };
}
