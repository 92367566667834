import Client from './client';
import {
  LegacyResponse,
} from '../types/server';

export default class GraphqlClient extends Client {
  host: string;

  auth: string;

  headers: Record<string, string> | undefined;

  constructor(host: string, auth: string, headers: Record<string, string>) {
    super({ serviceName: 'server' });

    this.host = 'https://graphql.flow.io/' || host;
    this.auth = auth;
    this.headers = headers;
  }

  makeGraphqlQuery(options = {}): Promise<LegacyResponse> {
    Object.assign(options, { method: 'POST' });
    return this.makeRequest(this.host, options);
  }
}
