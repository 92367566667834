import React from 'react';
import Button from '../../MarketingButton';
import MarketingDialog from '../../MarketingDialog';
import { MergedProps as Props } from '../types';

const DeleteFeedConfirmationDialog: React.FC<Props> = ({
  feedData,
  isOpen = false,
  onCancel,
  onConfirmFeedDeletion,
}) => {
  if (!isOpen) {
    return null;
  }
  const { country } = feedData;
  const subtitle = `Are you sure you want to delete this country feed? Existing product ads for ${country.name} may become invalid once this feed is deleted and removed from your account.`;

  return (
    <MarketingDialog
      isOpen
      title="Delete Feed"
      subtitle={subtitle}
    >
      <Button intent="neutral" onClick={onCancel}>Cancel</Button>
      <Button intent="negative" onClick={(): void => onConfirmFeedDeletion(feedData)}>Delete</Button>
    </MarketingDialog>
  );
};

DeleteFeedConfirmationDialog.displayName = 'DeleteFeedConfirmationDialog';

export default DeleteFeedConfirmationDialog;
