import { Form, Field } from 'redux-form';
import { Button } from '@flowio/react-button';
import React from 'react';
import { Stack } from '@flowio/react-stack';
import classnames from 'classnames';
import map from 'lodash/map';
import slice from 'lodash/slice';

import { css } from '@emotion/css';
import GenericError from '../../../../components/GenericError';
import { ShippingConfigurationDeleteFormCombinedProps, ShippingConfigurationDeleteFormState } from '../../types/components';

const experiencesStyles = css`
  &.scrolling {
    max-height: 200px;
    overflow: auto;
`;

class ShippingConfigurationDeleteForm extends React.PureComponent<
ShippingConfigurationDeleteFormCombinedProps,
ShippingConfigurationDeleteFormState
> {
  static displayName = 'ShippingConfigurationDeleteForm';

  constructor(props: ShippingConfigurationDeleteFormCombinedProps, context: any) {
    super(props, context);
    this.state = { isExpanded: false };
    this.handleExpandButtonClick = this.handleExpandButtonClick.bind(this);
  }

  handleExpandButtonClick() {
    const { isExpanded } = this.state;
    this.setState({ isExpanded: !isExpanded });
  }

  renderExperienceWarning() {
    const { experiences = [] } = this.props;
    const { isExpanded } = this.state;
    const initial = slice(experiences, 0, 5);
    const remaining = slice(experiences, 5);
    return (
      <div>
        <p>
          This shipping configuration is currently being used in these
          Experiences below.
        </p>
        <ul className={classnames(experiencesStyles, { scrolling: remaining.length > 0 })}>
          {map(initial, (experience) => (
            <li key={experience.id}>
              {experience.name}
            </li>
          ))}
          {isExpanded && map(remaining, (experience) => (
            <li key={experience.id}>
              {experience.name}
            </li>
          ))}
        </ul>
        {remaining.length > 0 && (
          <Button
            content={isExpanded ? 'View Less' : `View all ${experiences.length} Experiences`}
            variant="flat"
            onClick={this.handleExpandButtonClick}
            type="button"
          />
        )}
        <p>
          If you delete this shipping configuration,
          {' '}
          <strong>Default Shipping Configuration</strong>
          {' '}
          will be assigned to these Experiences. You can modify the shipping
          configuration of these Experiences individually at any time. Are you
          sure you want to delete this shipping configuration?
        </p>
      </div>
    );
  }

  render() {
    const {
      experiences = [],
      error,
      handleSubmit,
    } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <Field component="input" type="hidden" name="organizationId" />
        <Field component="input" type="hidden" name="shippingConfigurationKey" />
        <Stack orientation="vertical" spacing="loose">
          {error && (
            <GenericError error={error} />
          )}
          {(experiences.length > 0) ? this.renderExperienceWarning() : (
            <p>
              This action cannot be undone. Are you sure you want to delete this
              shipping configuration?
            </p>
          )}
        </Stack>
      </Form>
    );
  }
}

export default ShippingConfigurationDeleteForm;
