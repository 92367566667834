import React, { Component, ReactElement } from 'react';
import get from 'lodash/get';
import { Label } from '@flowio/react-label';
import { RadioButtonGroup } from '@flowio/react-radio-button';
import * as styles from './shipping-form.styles';

interface ShippingFormProps {
  selectedShippingTier: string;
  shippingTiers: io.flow.v0.models.Tier[];
  onShippingTierSelection: (...args: any) => void;
  onShippingCenterSelection: (...args: any) => void;
}

interface FormattedCenters {
  labelText: string;
  value: string;
}

class ShippingForm extends Component<ShippingFormProps> {
  static displayName = 'ShippingForm';

  componentDidMount(): void {
    const { shippingTiers, onShippingTierSelection, onShippingCenterSelection } = this.props;

    const defaultTier = get(shippingTiers, '[0]');
    const defaultCenter = get(defaultTier, 'lane.centers.[0]', null);

    if (defaultTier && shippingTiers.length === 1) { onShippingTierSelection(defaultTier.id); }
    if (defaultCenter && defaultTier.lane.centers.length === 1) {
      onShippingCenterSelection(defaultCenter.id);
    }
  }

  onShippingTierSelect = (value: string): void => {
    const { onShippingTierSelection } = this.props;

    onShippingTierSelection(value);
  };

  onShippingCenterSelect = (value: string): void => {
    const { onShippingCenterSelection } = this.props;

    onShippingCenterSelection(value);
  };

  render(): ReactElement {
    const {
      shippingTiers = [],
      selectedShippingTier = '',
    } = this.props;

    const defaultTier = shippingTiers.length === 1 ? shippingTiers[0] : null;
    const defaultCenter = get(defaultTier, 'lane.centers.[0]');
    const currentTier = get(shippingTiers.find((tier) => tier.id === selectedShippingTier), '', defaultTier);
    const fallbackCenter = get(shippingTiers[0], 'lane.centers', '');
    const centers: io.flow.v0.models.Center[] = get(currentTier, 'lane.centers', fallbackCenter);

    const defaultCenterValue = defaultCenter
    && defaultTier && defaultTier.lane
    && defaultTier.lane.centers.length === 1
    && defaultCenter.id;

    const formattedCenters: FormattedCenters[] = centers.map((center: io.flow.v0.models.Center) => {
      const formattedLocationFields = {
        street: get(center, 'address.location.streets[0]', '') && `${center.address.location.streets![0]}, `,
        province: get(center, 'address.location.province', '') && `${center.address.location.province}, `,
        city: get(center, 'address.location.city', '') && `${center.address.location.city}, `,
        postal: get(center, 'address.location.postal', '') && `${center.address.location.postal}`,
      };

      const labelText = `${center.name} (${formattedLocationFields.street}${formattedLocationFields.province}${formattedLocationFields.city}${formattedLocationFields.postal})`;

      return {
        labelText,
        value: center.id,
      };
    });

    return (
      <div className="return-shipping">
        <div className={styles.shippingTiers}>
          <Label
            className={styles.shippingLabels}
            content="Shipping Tier"
          />
          <RadioButtonGroup
            className={styles.shippingTiersRadioButton}
            defaultValue={defaultTier ? defaultTier.id : undefined}
            onValueChange={this.onShippingTierSelect}
            options={shippingTiers.map((tier) => ({
              labelText: `${tier.name} (${tier.services.map((service) => service.name)})`,
              value: tier.id,
            }))}
          />
        </div>
        <div className="shipping-centers">
          <Label
            className={styles.shippingLabels}
            content="Return Center"
          />
          <RadioButtonGroup
            className={styles.shippingCentersRadioButton}
            defaultValue={defaultCenterValue}
            onValueChange={this.onShippingCenterSelect}
            options={formattedCenters.map((formattedCenter) => ({
              ...formattedCenter,
            }))}
          />
        </div>
      </div>
    );
  }
}

export default ShippingForm;
