import ActionTypes from '../constants/ActionTypes';
import {
  MarketingActions,
  MarketingChannelsState,
} from '../types';
import ReadyStates from '../../analytics/constants/ready-states';

const defaultState: MarketingChannelsState = {
  readyState: ReadyStates.PENDING,
};

export default function channelsReducer(
  state = defaultState,
  action: MarketingActions,
): MarketingChannelsState {
  let updatedChannels = state.channels;
  switch (action.type) {
    case ActionTypes.FETCH_ORGANIZATION_CHANNELS_REQUEST:
      return {
        ...state,
        error: undefined,
        readyState: ReadyStates.LOADING,
      };

    case ActionTypes.FETCH_ORGANIZATION_CHANNELS_SUCCESS:
      return {
        ...state,
        channels: action.payload,
        readyState: ReadyStates.FULFILLED,
      };

    case ActionTypes.DISCONNECT_CHANNEL_SUCCESS:
      if (state.channels) {
        updatedChannels = state.channels.map((c) => ({
          ...c,
          status: c.platform === action.payload ? 'not_connected' : c.status,
        }));
      }

      return {
        ...state,
        channels: updatedChannels,
        readyState: ReadyStates.FULFILLED,
      };
    case ActionTypes.FETCH_ORGANIZATION_CHANNELS_FAILURE:
      return {
        ...state,
        error: action.payload,
        readyState: ReadyStates.REJECTED,
      };
    default:
      return state;
  }
}
