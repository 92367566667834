import React from 'react';
import * as d3 from 'd3';
import numeral from 'numeral';
import map from 'lodash/map';

import createYScale from '../../../../utilities/charting/create-y-scale';
import createSyncTooltipHtml from '../../../../utilities/charting/create-sync-tooltip-html';
import createBarChartTooltips from '../../../../utilities/charting/create-bar-chart-tooltips';
import { ResultsObject } from '../../types';
import * as styles from './ShopifyStatusGraph.styles';

let tooltip: d3.Selection<HTMLDivElement, unknown, HTMLElement, any>;

const colors = {
  processing: '#D57C2C',
  pending: '#37A9DD',
};
let width = 850;
const height = 450;
const margin = {
  top: 20,
  right: 30,
  bottom: 10,
  left: 30,
};

interface ShopifySyncStatusGraphProps {
  syncStatus: io.flow.v0.models.ShopifySyncStatus;
  hasData: boolean;
}

export class ShopifySyncStatusGraph extends React.PureComponent<ShopifySyncStatusGraphProps> {
  static displayName = 'ShopifySyncStatusGraph';

  static defaultProps = {
    syncStatus: undefined,
    hasData: false,
  };

  componentDidMount(): void {
    const {
      hasData,
    } = this.props;

    if (d3.select(`.${styles.resultsTooltip}`).empty()) {
      tooltip = d3.select('body')
        .append('div')
        .attr('class', styles.resultsTooltip);
    } else {
      tooltip = d3.select(`.${styles.resultsTooltip}`);
    }

    if (hasData) {
      this.refreshChart();
    }
  }

  componentDidUpdate(): void {
    this.refreshChart();
  }

  createYGrid = (svg: d3.Selection<SVGGElement, unknown, HTMLElement, any>): void => {
    svg.selectAll('.yAxis')
      .selectAll('.tick')
      .append('line')
      .attr('class', 'yAxis-grid')
      .attr('x1', 1)
      .attr('y1', 0)
      .attr('x2', width)
      .attr('y2', 0)
      .attr('stroke-width', 1)
      .attr('stroke', (_, i: number) => i === 0 ? '#808080' : '#F2F2F2'); // eslint-disable-line no-confusing-arrow
  };

  refreshChart(): void {
    d3.select('#shopifySyncStatusChart svg').remove();
    this.generateChart('#shopifySyncStatusChart');
  }

  generateChart(chartSelector: string): void {
    const {
      syncStatus,
    } = this.props;

    const getBarWidth = (): number => Math.ceil(width / syncStatus.buckets.length) - 2;
    const getBarPosition = (i: number): number => margin.left + (
      (getBarWidth() + 2) * i
    );

    width = getBarPosition(syncStatus.buckets.length) - margin.left;

    const getBarColor = (_: object, index: number): string => {
      if (index === 0) {
        return colors.processing;
      }
      return colors.pending;
    };

    const chartValues: ResultsObject[] = map(syncStatus.buckets, (b) => ({
      count: b.count,
      timeStart: Number(b.range.from),
      timeEnd: Number(b.range.to),
    }));

    const x: d3.ScaleOrdinal<number, number> = d3.scaleOrdinal<number, number>()
      .range([margin.left, margin.left + width - 1])
      .domain([0, syncStatus.buckets.length]);

    const y = createYScale(chartValues, 'count', { height });

    const xAxis = d3.axisBottom(x)
      .tickValues([Number(syncStatus.range.from), Number(syncStatus.range.to)])
      .tickFormat((date: d3.AxisDomain) => {
        const dateFormatter = d3.timeFormat('%a %H:%M');
        return dateFormatter(date as Date);
      })
      .ticks(1);

    const yAxis = d3.axisLeft(y)
      .tickFormat((count) => numeral(count).format('0.[00]a'))
      .tickArguments([10])
      .tickSize(0)
      .tickPadding(10);

    const svg = d3.select(chartSelector)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g');

    svg.append('g')
      .attr('transform', `translate(-1,${height + 6})`)
      .style('text-anchor', 'middle')
      .call(xAxis);

    svg.append('g')
      .attr('class', 'yAxis')
      .attr('transform', `translate(${margin.right - 1},${margin.bottom - 4})`)
      .call(yAxis);

    svg.selectAll('.domain')
      .attr('stroke-opacity', 0.5)
      .attr('stroke', '#808080');

    svg.selectAll('.tick')
      .selectAll('text')
      .attr('fill', '#808080');

    svg.selectAll('.tick')
      .selectAll('line')
      .attr('stroke-opacity', 0.5)
      .attr('stroke', '#808080');

    this.createYGrid(svg);

    svg.selectAll('.tick text').attr('font-size', '12');

    const bars = svg.selectAll('rect')
      .data(chartValues)
      .enter()
      .append('g');

    bars.append('rect')
      .style('fill', (d, i) => getBarColor(d, i))
      .attr('x', (_, i) => getBarPosition(i))
      .attr('width', getBarWidth())
      .attr('y', (d) => (Math.ceil(y(d.count)) === height ? Math.floor(y(d.count)) + margin.bottom - 4 : y(d.count) + margin.bottom - 4))
      .attr('height', (d) => Math.max(1, height - y(d.count)));

    const tooltipHtml = chartValues.map(
      (res) => createSyncTooltipHtml(
        res,
        styles,
        'count',
        res.timeStart,
        res.timeEnd,
      ),
    );

    createBarChartTooltips(chartValues, svg, tooltip, {
      width,
      height,
      marginLeft: margin.left,
      marginBtm: margin.bottom,
      chartPadding: 0,
    }, tooltipHtml, getBarWidth(), 1, getBarPosition);
  }

  render(): React.ReactElement {
    return (<div className={styles.container} id="shopifySyncStatusChart" />);
  }
}

export default ShopifySyncStatusGraph;
