import { BillingTransactionStatus } from '@flowio/api-internal-constants';
import {
  Table,
  TableBody,
  TableHead,
  TableHeadColumn,
  TableRow, TableRowColumn,
} from '@flowio/react-table';
import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import * as OrderPaymentStyles from '../order-payment/order-payment.styles';
import StatusLabel from '../../../../components/status-label/StatusLabel';

const billingTransactionStatusText = {
  [BillingTransactionStatus.PENDING_PROOF]: 'Pending',
  [BillingTransactionStatus.POSTED]: 'Posted',
};

const displayName = 'OrderPayment';

interface OrderPaymentProps {
  debugTransactions: io.flow.internal.v0.models.InternalDebugTransaction[];
}

const getPayoutStatusIntent = (
  status?: io.flow.billing.v0.enums.PendingPayoutTransactionReasonCode,
): string => {
  switch (status) {
    case 'waiting_for_fulfillment':
      return 'info';
    case 'waiting_for_next_payout_date':
      return 'positive';
    case 'waiting_for_full_refund':
      return 'warning';
    case 'waiting_for_positive_account_balance':
      return 'positive';
    case 'waiting_for_in_transit':
      return 'warning';
    default:
      return 'warning';
  }
};

// Query results are available as the `data` variable
function getPayoutStatus(debug?: io.flow.internal.v0.models.DebugTransactionDetails): string {
  if (debug && debug.payout && debug.payout.code) {
    if (debug.transaction.statement) {
      return 'Paid';
    }
    return debug.payout.code;
  }
  return '???';
}

const DebugTransactionsTable: React.FC<OrderPaymentProps> = ({
  debugTransactions,
}) => (
  <>
    <Table striped displayDensity="compact">
      <TableHead>
        <TableRow>
          <TableHeadColumn className={OrderPaymentStyles.header}>Source</TableHeadColumn>
          <TableHeadColumn
            className={classNames(OrderPaymentStyles.header, OrderPaymentStyles.first)}
          >
            Statement #
          </TableHeadColumn>
          <TableHeadColumn className={OrderPaymentStyles.header}>Payout</TableHeadColumn>
          <TableHeadColumn className={OrderPaymentStyles.header}>Amount</TableHeadColumn>
          <TableHeadColumn className={OrderPaymentStyles.header}>Payout Updated</TableHeadColumn>
          <TableHeadColumn className={OrderPaymentStyles.header}>Description</TableHeadColumn>
        </TableRow>
      </TableHead>
      <TableBody>
        {debugTransactions.map(({ debug }) => {
          const { transaction, status, payout } = debug ?? {};
          const {
            id: transactionId, statement,
            source, net,
          } = transaction ?? {};
          const { status: statusValue, description } = status ?? {};
          const { code: payoutCode, created_at: payoutCreatedAt } = payout ?? {};

          return (
            <TableRow key={transactionId}>
              <TableRowColumn>
                {source}
              </TableRowColumn>
              <TableRowColumn>
                {
                  statement?.id ?? (statusValue && billingTransactionStatusText[statusValue])
                }
                {}
              </TableRowColumn>
              <TableHeadColumn>
                {
                  <StatusLabel
                    content={getPayoutStatus(debug)}
                    intent={getPayoutStatusIntent(payoutCode)}
                  />
                }
              </TableHeadColumn>
              <TableRowColumn>{net}</TableRowColumn>
              <TableRowColumn>{moment(payoutCreatedAt).format('MM/DD/YYYY')}</TableRowColumn>
              <TableRowColumn>{description}</TableRowColumn>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  </>
);

DebugTransactionsTable.displayName = displayName;

export default DebugTransactionsTable;
