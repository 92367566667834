import { compose } from 'redux';
import withValidation from '../../../components/with-validation';
import withSubmit from '../../../components/with-submit';
import validator from '../../../utilities/validator';
import { newPassword } from '../actions';
import ResetPassword from '../components/reset-password';
import { ValidationFieldsMap } from '../../../components/with-validation/with-validation';

interface OwnProps {
  params: {
    token: string;
  };
}

export default compose(
  withSubmit(({ token, password }) => newPassword(token, password)),
  withValidation((ownProps: OwnProps) => ({
    confirmPassword: {
      validations: (value: string, fields: ValidationFieldsMap): string | undefined => {
        if (!validator.validate(value, { required: true })) {
          return 'Password confirmation is required';
        }

        if (value !== fields.password.value) {
          return 'These passwords do not match. Try again?';
        }

        return undefined;
      },
    },
    password: {
      validations: [{
        required: true,
        message: 'Password is required',
      }, {
        minLength: 8,
        message: 'Short passwords are easy to guess. Try one with at least 8 characters.',
      }],
    },
    token: {
      defaultValue: ownProps.params.token,
      validations: {
        required: true,
      },
    },
  })),
)(ResetPassword);
