import { createSelector } from 'reselect';
import get from 'lodash/get';
import getExclusionRuleDeleteConfirmation from './getExclusionRuleDeleteConfirmation';
import { RootState } from '../../../stores/types';
import { ExclusionRuleDeleteConfirmationState } from '../types';

const getExclusionRuleToBeDeleted = createSelector<
RootState, ExclusionRuleDeleteConfirmationState, io.flow.v0.models.ExclusionRule | undefined>(
  getExclusionRuleDeleteConfirmation,
  (state) => get(state, 'exclusionRuleToBeDeleted'),
);

export default getExclusionRuleToBeDeleted;
