import { bindActionCreators, compose } from 'redux';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { isSubmitting, submit } from 'redux-form';

import { hideExclusionRuleWorksheet } from '../actions';
import {
  getAvailableRegionsForExclusionRuleWorksheet,
  getInitialValuesForExclusionRuleWorksheet,
  getIsExclusionRuleWorksheetVisible,
  getStatusForExclusionRuleWorksheet,
  getStructuredAvailableFiltersForExclusionRuleWorksheet,
  getExclusionRulesFormError,
} from '../selectors';
import FormName from '../constants/FormName';
import ExclusionRuleWorksheet from '../components/ExclusionRuleWorksheet';
import {
  ExclusionRuleWorksheetStateProps,
  ExclusionRuleWorksheetDispatchProps,
} from '../types';
import { RootState } from '../../../stores/types';

const mapStateToProps: MapStateToProps<
ExclusionRuleWorksheetStateProps, {}, RootState> = (state) => ({
  availableFilters: getStructuredAvailableFiltersForExclusionRuleWorksheet(state),
  availableRegions: getAvailableRegionsForExclusionRuleWorksheet(state),
  initialValues: getInitialValuesForExclusionRuleWorksheet(state),
  open: getIsExclusionRuleWorksheetVisible(state),
  status: getStatusForExclusionRuleWorksheet(state),
  submissionApiError: getExclusionRulesFormError(state),
  submitting: isSubmitting(FormName.ExclusionForm)(state),
});

const remoteSubmitExclusionForm = () => submit(FormName.ExclusionForm);

const mapDispatchToProps: MapDispatchToProps<
ExclusionRuleWorksheetDispatchProps, {}> = (dispatch) => bindActionCreators({
  onRequestCancel: hideExclusionRuleWorksheet,
  onSubmitSuccess: hideExclusionRuleWorksheet,
  onRequestSave: remoteSubmitExclusionForm,
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(ExclusionRuleWorksheet);
