import { createSelector } from 'reselect';
import property from 'lodash/property';

import getFeatures from './getFeatures';

const getDialogProp = (dialog: string, prop: string) => createSelector(
  getFeatures,
  property(`dialogs.${dialog}.${prop}`),
);

export default getDialogProp;
