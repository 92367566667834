import { ArcLoader } from '@flowio/react-icons';
import React from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Box } from '@flowio/react-box';
import {
  Table,
  TableHead,
  TableRow,
  TableHeadColumn,
  TableBody,
} from '@flowio/react-table';

import * as styles from './GoogleLocalizedFeeds.styles';
import Button from '../../MarketingButton';
import MarketingCard, { MarketingCardHeading } from '../../MarketingCard';
import {
  GOOGLE_LOCALIZED_FEEDS_TITLE,
  GOOGLE_LOCALIZED_FEEDS_SUBTITLE,
} from '../../../constants/Feeds';
import GoogleLocalizedFeed from '../../GoogleLocalizedFeed';

import {
  findCountryByCode,
  findLanguageByCode,
  findCurrencyByCode,
} from '../../../utilities/findReferenceData';
import { MergedProps } from '../types';
import MarketingEmptyState from '../../MarketingEmptyState/MarketingEmptyState';
import colors from '../../../../../theme/tokens/colors';

const GoogleLocalizedFeeds: React.FC<MergedProps> = ({
  feeds,
  loading,
  countries,
  currencies,
  languages,
  accountId,
  onDelete,
  onCreateNewFeed,
  organizationId,
  onDownloadFeedsButtonClick,
}) => {
  const handleCreateNewFeed = (): void => {
    onCreateNewFeed(organizationId, accountId);
  };

  const handleDownloadFeedsButtonClick = (): void => {
    onDownloadFeedsButtonClick(feeds.map((feed) => feed.id));
  };

  const loadingSpinner = (): JSX.Element => (
    <div className={styles.loadingContainer}>
      <ArcLoader width={64} fill={colors.marketing['light-slate-gray']} />
    </div>
  );

  const feedTableRow = (feed: io.flow.internal.v0.models.MarketingGatewayFeed): JSX.Element => {
    const props = {
      country: findCountryByCode(countries, feed.country),
      currency: findCurrencyByCode(currencies, feed.currency),
      language: findLanguageByCode(languages, feed.language),
      parentCountry: findCountryByCode(countries, get(feed, 'feed_source.country')),
      accountId,
      onDelete,
      feed,
    };
    // @ts-ignore
    return <GoogleLocalizedFeed key={feed.id} {...props} />;
  };

  return (
    <MarketingCard boxShadow>
      <MarketingCardHeading
        showDivider={false}
        title={GOOGLE_LOCALIZED_FEEDS_TITLE}
        subtitle={GOOGLE_LOCALIZED_FEEDS_SUBTITLE}
      >
        {!isEmpty(feeds) && (
          <Box justifyContent="end" spacing="loose">
            <Button
              onClick={handleDownloadFeedsButtonClick}
            >
              Download all feeds
            </Button>
            <Button
              data-automation-id="add-localized-feeds"
              intent="positive"
              onClick={handleCreateNewFeed}
            >
              Add Country
            </Button>
          </Box>
        )}
      </MarketingCardHeading>
      {isEmpty(feeds) ? (
        <MarketingEmptyState
          title="No localized product feeds set up yet for your Google Merchant Center account."
          subtitle="Set up localized product feeds for your target countries to start running international ads on Google."
        >
          <Button
            data-automation-id="google-add-feeds"
            intent="primary"
            onClick={handleCreateNewFeed}
            content="Add Country"
          />
        </MarketingEmptyState>
      ) : (
        <>
          <br />
          <Table className={styles.marketingTableStyleClass}>
            <TableHead>
              <TableRow>
                <TableHeadColumn>Feed Name</TableHeadColumn>
                <TableHeadColumn>Country</TableHeadColumn>
                <TableHeadColumn>Language</TableHeadColumn>
                <TableHeadColumn>Currency</TableHeadColumn>
                <TableHeadColumn>Data Source</TableHeadColumn>
                <TableHeadColumn>Available Products</TableHeadColumn>
                <TableHeadColumn>Feed Status</TableHeadColumn>
                <TableHeadColumn />
              </TableRow>
            </TableHead>
            <TableBody>{feeds.map(feedTableRow)}</TableBody>
          </Table>
        </>
      )}
      {loading && loadingSpinner()}
    </MarketingCard>
  );
};

GoogleLocalizedFeeds.displayName = 'GoogleLocalizedFeeds';
export default GoogleLocalizedFeeds;
