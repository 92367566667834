import { css } from '@emotion/css';

export const info = css`
  display: flex;
  flex-flow: row wrap;
`;

export const infoLabel = css`
  width: 200px;
  text-align: left;
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
  color: #77818f;
  padding-bottom: 8px;
`;

export const infoValue = css`
  width: calc(100% - 200px);
  margin: 0;
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
  color: #4d5866;
  padding-bottom: 8px;
`;

export const countryLabel = css`
  padding-right: 4px;
`;

export const showAll = css`
  display: inline-block;
  float: right;
  font-size: 14px;
  padding-top: 8px;
  border-bottom: 1px dotted black;
  color: #17181a;

  &:hover {
    color: black;
    cursor: pointer;
  }
`;

export const hsCodes = css`
  position: relative;
  top: 5px;
`;

export const tariffCodeCol = css`
  text-align: right;
`;

export const capitalize = css`
  text-transform: capitalize;
`;
