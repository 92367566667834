import React from 'react';
import {
  Table,
  TableHead,
  TableRow, TableRowColumn,
  TableHeadColumn,
  TableBody,
} from '@flowio/react-table';
import map from 'lodash/map';
import noop from 'lodash/noop';
import get from 'lodash/get';
import { currencies } from '@flowio/lib-reference-javascript';
import { css } from '@emotion/css';
import { CurrencyRatesTableProps, SortedColumn, SortOrder } from '../../types';

import SortableTableHeaderColumn from '../../../../components/sortable-table-header-column';

const displayName = 'CurrencyRatesTable';

const rowStyles = css`
  border-bottom: 1px solid rgb(224, 224, 224);
`;

const headerStyles = css`
  border-bottom: 1px solid rgb(224, 224, 224);
`;

const defaultProps = {
  onRequestSort: noop,
  rates: undefined,
  sortedColumn: undefined,
  sortOrder: undefined,
};

const getSortOrderForColumnHeader = (
  eventKey: SortedColumn,
  sortedColumn?: SortedColumn,
  sortOrder?: SortOrder,
) => {
  if (eventKey !== sortedColumn) {
    return SortableTableHeaderColumn.TYPE_SORT_ORDER_NONE;
  }

  if (sortOrder === 'ascending') {
    return SortableTableHeaderColumn.TYPE_SORT_ORDER_ASCENDING;
  } if (sortOrder === 'descending') {
    return SortableTableHeaderColumn.TYPE_SORT_ORDER_DESCENDING;
  }

  return SortableTableHeaderColumn.TYPE_SORT_ORDER_NONE;
};

const CurrencyRatesTable: React.FC<CurrencyRatesTableProps> = ({
  onRequestSort, rates, sortedColumn, sortOrder,
}) => (
  <Table striped>
    <TableHead>
      <TableRow className={headerStyles}>
        <SortableTableHeaderColumn
          label="Abbreviation"
          eventKey="target"
          sortOrder={getSortOrderForColumnHeader('target', sortedColumn, sortOrder)}
          onRequestSortColumn={onRequestSort}
        />
        <TableHeadColumn>Name</TableHeadColumn>
        <SortableTableHeaderColumn
          label="Current Rate"
          eventKey="value"
          sortOrder={getSortOrderForColumnHeader('value', sortedColumn, sortOrder)}
          onRequestSortColumn={onRequestSort}
        />
      </TableRow>
    </TableHead>
    <TableBody>
      {map(rates, ({ id, target, value }, index) => {
        const currency = currencies.find(target);
        return (
          <TableRow key={id} striped={index % 2 === 1}>
            <TableRowColumn className={rowStyles} style={{ width: '33%' }}>
              {get(currency, 'iso_4217_3')}
            </TableRowColumn>
            <TableRowColumn className={rowStyles} style={{ width: '33%' }}>
              {get(currency, 'name')}
            </TableRowColumn>
            <TableRowColumn className={rowStyles} style={{ width: '33%' }}>
              {value}
            </TableRowColumn>
          </TableRow>
        );
      })}
    </TableBody>
  </Table>
);

CurrencyRatesTable.displayName = displayName;
CurrencyRatesTable.defaultProps = defaultProps;

export default CurrencyRatesTable;
