import { css } from '@emotion/css';
import colors from '../../../../../theme/tokens/colors';

export const title = css`
  color: ${colors.marketing['dark-slate-blue']};
`;

export const feedFormsContainer = css`
  margin: 0 40px 40px;
`;

export const buttons = css`
  margin-top: 40px;

  &.withPadding {
    margin: 0 40px 40px 0;
  }
`;

export const footer = css`
  margin-top: 40px;
`;
