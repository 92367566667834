import Platforms from '../../constants/Platforms';
import { MarketingGatewayIntegratedPlatform } from '../../types';

type AuthObject = {
  modalTitle: string;
  modalBody: string;
};

export const Auth: Record<MarketingGatewayIntegratedPlatform, AuthObject> = {
  facebook: {
    modalTitle: `You will be redirected to ${Platforms('facebook').shortName} to grant Flow access to your Facebook Business Manager account.`,
    modalBody: 'Once your account is connected, you can then select which catalog you would like to add localized product information in Flow.',
  },
  google: {
    modalTitle: `You will be redirected to ${Platforms('google').platformName} to grant Flow access.`,
    modalBody: `If you don't have a ${Platforms('google').platformName} account, please create one and return here to connect your account.`,
  },
};

export const manualTitle = (platform: MarketingGatewayIntegratedPlatform) => `Import data from ${Platforms(platform).shortName}`;
export const manualSubtitle = (platform: MarketingGatewayIntegratedPlatform) => `You will be redirected to ${Platforms(platform).shortName} to grant Flow access to your ${Platforms(platform).platformName} account to import product data to Flow`;
