import {
  MarketingActions,
  MarketingSelectedFeedSourceState,
} from '../types';
import ActionTypes from '../constants/ActionTypes';

const defaultState: MarketingSelectedFeedSourceState = {};

export default (
  state = defaultState,
  action: MarketingActions,
): MarketingSelectedFeedSourceState => {
  switch (action.type) {
    case ActionTypes.CHANGE_SELECTED_FEED_SOURCE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
