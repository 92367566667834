import update from 'immutability-helper';
import keyBy from 'lodash/keyBy';
import toLower from 'lodash/toLower';
import ActionTypes from '../constants/action-types';
import { AccountState } from '../types';

import { RootActionTypes } from '../../../stores/types';

const keyByAccountKey = (accounts: io.flow.internal.v0.models.OrganizationAccount[]):
AccountState => keyBy(accounts, (account) => toLower(account.key));

const defaultState: AccountState = {};

export default function (state = defaultState, action: RootActionTypes): AccountState {
  switch (action.type) {
    case ActionTypes.FETCH_ORGANIZATION_ACCOUNT_SUCCESS:
      return update(state, { $merge: keyByAccountKey([action.payload]) });
    case ActionTypes.FETCH_ORGANIZATION_ACCOUNTS_SUCCESS:
      return update(state, { $merge: keyByAccountKey(action.payload.results) });
    default:
      return state;
  }
}
