import React from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableHeadColumn,
  TableBody,
} from '@flowio/react-table';
import map from 'lodash/map';

import ShippingConfigurationListItem from '../../containers/ShippingConfigurationListItem';
import { ShippingConfigurationListProps } from '../../types/components';

const ShippingConfigurationList: React.FC<ShippingConfigurationListProps> = ({
  onDeleteShippingConfiguration,
  onDuplicateShippingConfiguration,
  onViewShippingConfiguration,
  organizationId,
  shippingConfigurations,
}) => (
  <Table fixed selectable striped displayDensity="cozy">
    <TableHead>
      <TableRow>
        <TableHeadColumn>Name</TableHeadColumn>
        <TableHeadColumn>Type</TableHeadColumn>
        <TableHeadColumn>Shipping Lanes</TableHeadColumn>
        <TableHeadColumn>Active Experiences</TableHeadColumn>
        <TableHeadColumn />
      </TableRow>
    </TableHead>
    <TableBody>
      {map(shippingConfigurations, (shippingConfiguration, index) => (
        <ShippingConfigurationListItem
          currentIndex={index}
          key={shippingConfiguration.id}
          onDeleteShippingConfiguration={onDeleteShippingConfiguration}
          onDuplicateShippingConfiguration={onDuplicateShippingConfiguration}
          onViewShippingConfiguration={onViewShippingConfiguration}
          organizationId={organizationId}
          shippingConfiguration={shippingConfiguration}
        />
      ))}
    </TableBody>
  </Table>
);

ShippingConfigurationList.displayName = 'ShippingConfigurationList';

export default ShippingConfigurationList;
