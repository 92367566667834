import { FormattedErrorMessages } from '../../utilities/format-error-response-v2';
import ActionTypes from './constants/ActionType';

interface ErrorPayload {
  payload: FormattedErrorMessages;
}

export interface PaginationMetaData {
  parameters: {
    entriesPerPage: number;
    organizationId: string;
    pageNumber: number;
  }
}
export interface ExclusionRuleFormProps {
  availableFilters: io.flow.v0.models.AvailableFilterStructured[];
  availableRegions: io.flow.v0.models.Region[];
  error?: io.flow.v0.models.GenericError;
  submissionApiError?: io.flow.v0.models.GenericError;
}

export interface ExclusionRuleFormValues {
  regions: string[];
  organizationId: string;
  exclusionRuleId?: string;
  filters: any[];
}

export interface QueryFilterListOwnProps {
  availableFilters: io.flow.v0.models.AvailableFilterStructured[];
}

export interface ExclusionState {
  dialogs: {
    exclusionRuleDeleteConfirmation: ExclusionRuleDeleteConfirmationState;
    exclusionRuleWorksheet: ExclusionRuleWorksheetState;
    exclusionsExportDialog: ExclusionsExportDialogState;
  };
  entities: {
    exclusionRules: ExclusionRulePaginationState;
    exclusionRulesForm: ExclusionRulesFormState;
  };
}
export interface ExclusionRulePaginationParams {
  entriesPerPage: number;
  pageNumber: number;
  organizationId: string;
}

export enum PageStatus {
  LOADING = 'loading',
  FULFILLED = 'fulfilled',
  REJECTED = 'rejected',
}

export interface ExclusionsExportDialogState {
  visible: boolean;
}

export interface ExclusionRulePaginationState {
  entities: Record<string, io.flow.v0.models.ExclusionRule>;
  pagination: {
    currentPage: number;
    pages: Record<number, {
      parameters: ExclusionRulePaginationParams;
      error?: FormattedErrorMessages;
      isFirstPage: boolean;
      isLastPage: boolean;
      entities: string[];
      status: PageStatus;
    }>
  }
}

export interface ExclusionRuleWorksheetState {
  availableRegions: io.flow.v0.models.Region[];
  error?: io.flow.v0.models.GenericError;
  exclusionRule?: io.flow.v0.models.ExclusionRule;
  queryBuilder?: io.flow.v0.models.QueryBuilder;
  status: ExclusionRuleStatus;
  visibility: string;
}

export interface ExclusionRulesFormState {
  submissionError?: io.flow.v0.models.GenericError;
}

export interface ExclusionRuleDeleteConfirmationState {
  deleting: boolean;
  deleteFailed: boolean;
  deleteSucceeded: boolean;
  exclusionRuleToBeDeleted?: io.flow.v0.models.ExclusionRule;
  organizationId?: string;
  visibility: string;
}

export type ExclusionRuleStatus = 'pending' | 'loading' | 'fulfilled' | 'rejected';

export interface ExclusionRuleWorksheetValues {
  exclusionRuleId?: string;
  filters?: io.flow.v0.unions.QueryFilter[];
  organizationId?: string;
  regions?: string[];
}

export interface ExclusionRuleWorksheetStateProps {
  availableRegions?: io.flow.v0.models.Region[];
  availableFilters?: io.flow.v0.models.AvailableFilterStructured[];
  initialValues?: ExclusionRuleWorksheetValues;
  open?: boolean;
  status: ExclusionRuleStatus;
  submissionApiError?: io.flow.v0.models.GenericError;
  submitting?: boolean;
}

export interface ExclusionRuleWorksheetDispatchProps {
  onRequestCancel: (...args: any) => void;
  onRequestSave: (...args: any) => void;
  onSubmitSuccess: (...args: any) => void;
}

export type ExclusionRuleWorksheetProps =
  ExclusionRuleWorksheetStateProps &
  ExclusionRuleWorksheetDispatchProps;

export interface ExclusionRulesIndexStateProps {
  entriesPerPage: number;
  exclusionRules: io.flow.v0.models.ExclusionRule[];
  isFirstPage: boolean;
  isLastPage: boolean;
  organizationId: string;
  pageNumber: number;
  regions: io.flow.v0.models.Region[];
  isExclusionsExportDialogVisible: boolean;
}

export interface ExclusionRulesIndexDispatchProps {
  onAddExclusionRule: (...args: any) => void;
  onEditExclusionRule: (...args: any) => void;
  onPageChange: (...args: any) => void;
  onRemoveExclusionRule: (...args: any) => void;
  onExportExclusions: (...args: any) => void;
  onShowExportDialog: (...args: any) => void;
  onHideExportDialog: (...args: any) => void;
}

export type ExclusionRulesIndexProps =
  ExclusionRulesIndexStateProps &
  ExclusionRulesIndexDispatchProps;

export interface ExclusionRuleDeleteConfirmationStateProps {
  deleting: boolean;
  deleteFailed: boolean;
  open: boolean;
  organizationId?: string;
  exclusionRule?: io.flow.v0.models.ExclusionRule;
}

export interface ExclusionRuleDeleteConfirmationDispatchProps {
  onCancel: () => void;
  onOk: (organizationId: string, exclusionRuleId: string) => void;
}

export type ExclusionRuleDeleteConfirmationProps =
  ExclusionRuleDeleteConfirmationStateProps &
  ExclusionRuleDeleteConfirmationDispatchProps;

export interface ShowExclusionRuleWorksheetRequest {
  type: ActionTypes.ShowExclusionRuleWorksheetRequest;
  payload: {
    organizationId: string;
    exclusionRule: io.flow.v0.models.ExclusionRule;
  },
}

export interface ShowExclusionRuleWorksheetSuccess {
  type: ActionTypes.ShowExclusionRuleWorksheetSuccess;
  payload: {
    regions: io.flow.v0.models.Region[];
    exclusionRule: io.flow.v0.models.ExclusionRule;
    queryBuilder: io.flow.v0.models.QueryBuilder;
  }
}

export interface ShowExclusionRuleWorksheetFailure {
  type: ActionTypes.ShowExclusionRuleWorksheetFailure;
  payload: any;
}

export interface FetchExclusionRulesRequest {
  type: ActionTypes.FetchExclusionRulesRequest;
  meta: PaginationMetaData,
}

export interface FetchExclusionRulesSuccess {
  type: ActionTypes.FetchExclusionRulesSuccess;
  payload: io.flow.v0.models.ExclusionRule[];
  meta: PaginationMetaData,
}

export interface FetchExclusionRulesFailure extends ErrorPayload {
  type: ActionTypes.FetchExclusionRulesFailure;
  meta: PaginationMetaData,
}

export interface SubmitExclusionRuleForm {
  type: ActionTypes.SubmitExclusionRuleForm;
}

export interface SubmitExclusionRuleFormError {
  type: ActionTypes.SubmitExclusionRuleFormError;
  error: boolean;
  payload: any;
}

export interface DeleteExclusionRuleRequest {
  type: ActionTypes.DeleteExclusionRuleRequest;
  meta: {
    organizationId: string;
    exclusionRuleId: string;
  },
}

export interface DeleteExclusionRuleSuccess {
  type: ActionTypes.DeleteExclusionRuleSuccess;
  payload: any;
  meta: {
    organizationId: string;
    exclusionRuleId: string;
  },
}

export interface DeleteExclusionRuleFailure extends ErrorPayload {
  type: ActionTypes.DeleteExclusionRuleFailure;
  meta: {
    organizationId: string;
    exclusionRuleId: string;
  },
}

export interface UpdateExclusionRuleRequest {
  type: ActionTypes.UpdateExclusionRuleRequest;
  meta: {
    organizationId: string;
    exclusionRuleId: string;
    exclusionRuleForm: io.flow.v0.models.ExclusionRuleForm;
  },
}

export interface UpdateExclusionRuleSuccess {
  type: ActionTypes.UpdateExclusionRuleSuccess;
  payload: io.flow.v0.models.ExclusionRule;
  meta: {
    organizationId: string;
    exclusionRuleId: string;
    exclusionRuleForm: io.flow.v0.models.ExclusionRuleForm;
  },
}

export interface UpdateExclusionRuleFailure extends ErrorPayload {
  type: ActionTypes.UpdateExclusionRuleFailure;
  meta: {
    organizationId: string;
    exclusionRuleId: string;
    exclusionRuleForm: io.flow.v0.models.ExclusionRuleForm;
  },
}

export interface CreateExclusionRuleRequest {
  type: ActionTypes.CreateExclusionRuleRequest;
  meta: {
    organizationId: string;
    exclusionRuleForm: io.flow.v0.models.ExclusionRuleForm;
  },
}

export interface CreateExclusionRuleSuccess {
  type: ActionTypes.CreateExclusionRuleSuccess;
  payload: io.flow.v0.models.ExclusionRule;
  meta: {
    organizationId: string;
    exclusionRuleForm: io.flow.v0.models.ExclusionRuleForm;
  },
}

export interface CreateExclusionRuleFailure extends ErrorPayload {
  type: ActionTypes.CreateExclusionRuleFailure;
  meta: {
    organizationId: string;
    exclusionRuleForm: io.flow.v0.models.ExclusionRuleForm;
  },
}

export interface HideExclusionRuleWorksheet {
  type: ActionTypes.HideExclusionRuleWorksheet;
}

export interface HideExclusionRuleDeleteConfirmation {
  type: ActionTypes.HideExclusionRuleDeleteConfirmation;
}

export interface ShowExclusionRuleDeleteConfirmation {
  type: ActionTypes.ShowExclusionRuleDeleteConfirmation;
  payload: {
    organizationId: string;
    exclusionRule: io.flow.v0.models.ExclusionRule;
  }
}

export interface ShowExportDialog {
  type: ActionTypes.ShowExportDialog;
}

export interface HideExportDialog {
  type: ActionTypes.HideExportDialog;
}

export type ExclusionActions =
  SubmitExclusionRuleForm |
  SubmitExclusionRuleFormError |
  ShowExclusionRuleWorksheetRequest |
  ShowExclusionRuleWorksheetSuccess |
  ShowExclusionRuleWorksheetFailure |
  ShowExclusionRuleDeleteConfirmation |
  HideExclusionRuleDeleteConfirmation |
  HideExclusionRuleWorksheet |
  FetchExclusionRulesRequest |
  FetchExclusionRulesSuccess |
  FetchExclusionRulesFailure |
  UpdateExclusionRuleRequest |
  UpdateExclusionRuleSuccess |
  UpdateExclusionRuleFailure |
  DeleteExclusionRuleRequest |
  DeleteExclusionRuleSuccess |
  DeleteExclusionRuleFailure |
  CreateExclusionRuleRequest |
  CreateExclusionRuleSuccess |
  CreateExclusionRuleFailure |
  ShowExportDialog |
  HideExportDialog;
