import React from 'react';
import {
  Card, CardHeader, CardContent, CardFooter, CardTitle,
} from '@flowio/react-card';
import { MenuDivider } from '@flowio/react-menu';

import PolicyLayout from '../policy-layout/policy-layout';
import * as styles from './gdpr-faq.styles';

const defaultProps = {};

const GdprFaq = () => (
  <PolicyLayout>
    <Card>
      <CardHeader>
        <CardTitle content="GDPR FAQ" />
        <MenuDivider className={styles.divider} />
      </CardHeader>
      <CardContent>
        <h2 className={styles.h2First}>What is GDPR?</h2>
        <p>
          The General Data Protection Regulation (GDPR) is the EU’s privacy regulation
          intended to protect the privacy of individuals in the EU as of May 25, 2018.
        </p>
        <p>Under the GDPR, the definition of <strong>Personal Data</strong> is extremely
          broad and is defined as &quot;any information relating to an identified or
          identifiable natural person (&apos;data subject&apos;)...who can be identified...by
          reference to an identifier such as a name, an identification number, location
          data, an online identifier… .&quot;
        </p>
        <p>The primary intent of the regulation is to: </p>
        <ol>
          <li className={styles.li}>
            <span className={styles.span}>
              Ensure that EU Data Subject (see definition below) data are protected
            </span>
          </li>
          <li className={styles.li}>
            <span className={styles.span}>
              Provide a standard set of guidelines for data protection across EU member states
            </span>
          </li>
          <li className={styles.li}>
            <span className={styles.span}>
              Ensure that EU Data Subjects have visibility and control over their data and the way
              it is used (including the <strong>&quot;Right to Erasure&quot;</strong> or
              <strong> &quot;Right to be Forgotten&quot;</strong>)
            </span>
          </li>
          <li className={styles.li}>
            <span className={styles.span}>
              Ensure that companies are only collecting data they need to provide the
              service they offer
            </span>
          </li>
        </ol>

        <h2>Who is impacted by GDPR?</h2>
        <p>Any business (whether European or not) that interacts with consumers in the
          EU or EEA. It is expected that if/when Brexit is finalized, the UK will enact
          legislation that is in all material respects equivalent to GDPR.
        </p>
        <h2>What is Flow&apos;s role?</h2>
        <p>Flow is considered a Data Processor under GDPR. Flow prides itself on
          best-in-class security practices and procedures as such as set up its systems
          and platform to be GDPR compliant.
        </p>

        <h2>What is Flow doing for GDPR?</h2>
        <ol className={styles.noIndent}>
          <li className={styles.li}>
            <span className={styles.span}>
              Securing all EU Data Subject Personal Data with Flow&apos;s best-in-class
              security practices
            </span>
          </li>
          <li className={styles.li}>
            <span className={styles.span}>
              Updating our Information Security Policy, our Data Deletion Policy
            </span>
          </li>
          <li className={styles.li}>
            <span className={styles.span}>
              Providing consumer-facing Privacy Policy updated for GDPR compliance
            </span>
          </li>
          <li className={styles.li}>
            <span className={styles.span}>
              Providing you with a GDPR compliant Data Protection Addendum outlining all
              of the actions we have taken to comply with GDPR and our obligations to you
              as our customer under GDPR. This includes the basis under which Flow transfers
              Data Subject Data outside of the EU
            </span>
          </li>
          <li className={styles.li}>
            <span className={styles.span}>
              Incorporating additional consent form tools that enable clear acknowledgement
              of actions during site browsing and checkout, to ensure that Data Subjects
              geolocated in the EU meet the definition of Consent under the GDPR
            </span>
          </li>
          <li className={styles.li}>
            <span className={styles.span}>
              Ensuring clients have control over which of their users have access to PII
              data and which do not
            </span>
          </li>
          <li className={styles.li}>
            <span className={styles.span}>
              Training our entire staff on GDPR
            </span>
          </li>
        </ol>
        <p>Upon request, Flow can assist you in completing your Privacy Impact Assessment</p>

        <h2>How do I access Flow&apos;s GDPR Compliance Suite?</h2>
        <ol className={styles.noIndent}>
          <li className={styles.li}>
            <span className={styles.span}>
              Flow will provide a configurable Consent Service, in our Hosted Checkout and
              via API. This allows our clients the ability to manage and customize consents
              and opt-ins, such as marketing, cookies, and agreeing to terms of service.
              Flow will provide additional instructions and training for adjusting your
              settings the week of May 21st.
            </span>
          </li>
          <li className={styles.li}>
            <span className={styles.span}>
              Flow will provide secure and configurable user permissions, to help our
              clients manage their user access settings for Console and the Flow API. This
              provides the ability to indicate which users have access to Personal Data and
              what functionality is available to them. Flow will provide additional
              instructions and training for adjusting your settings at the end of June.
            </span>
          </li>
          <li className={styles.li}>
            <span className={styles.span}>
              If a Data Subject has exercised their &quot;Right to be Forgotten&quot;
              please email <a href="help@flow.io">help@flow.io</a> and we will initiate our
              Data Deletion Policy.
            </span>
          </li>
        </ol>

        <h2>What do I have to do beyond the actions Flow is taking?</h2>
        <ol className={styles.noIndent}>
          <li className={styles.li}>
            <span className={styles.span}>
              Reach out to your corporate counsel and seek their advice
            </span>
          </li>
          <li className={styles.li}>
            <span className={styles.span}>
              Decide what data from EU you want to store rather than rely upon Flow (this
              may include data that is relevant for marketing purposes)
            </span>
          </li>
          <li className={styles.li}>
            <span className={styles.span}>
              Complete a Privacy Impact Assessment (PIA)
            </span>
          </li>
          <li className={styles.li}>
            <span className={styles.span}>
              Update your Terms &amp; Conditions and Privacy Policy to be compliant with
              the GDPR per guidance from your corporate counsel and using the Flow Privacy Policy
            </span>
          </li>
        </ol>

        <h2>What are some of the key terms in the regulation?</h2>
        <p><strong>Data Subject</strong> - an identified or identifiable natural person</p>
        <p><strong>Data Controller</strong> - anyone who determines how Personal Data is
          handled and for what purposes. Where two or more Data Controllers jointly
          determine the purposes and means of processing, they are &apos;Joint Data
          Controllers&apos;.
        </p>
        <p><strong>Data Processor</strong> - anyone who handles Personal Information under
          the instructions of a Data Controller. Data Processors that have been instructed
          by other data processors are called &apos;Sub-processors&apos;.
        </p>
        <p><strong>Processing</strong> - any operation or set of operations performed upon
          personal data, whether or not by automatic means, such as collection, recording,
          organisation, storage, adaptation or alteration, retrieval, consultation, use,
          disclosure by transmission, dissemination or otherwise making available,
          alignment or combination, blocking, erasure or destruction.
        </p>
        <p><strong>Consent</strong> - Agreement by an individual to the processing of
          their information. The GDPR standard of consent requires it to be given:
        </p>
        <ul>
          <li className={styles.bullet}>
            <span className={styles.span}>
              Without outside pressures forcing him/her to agree (this includes the threat
              of not being able to benefit from the services if consent is withheld)
            </span>
          </li>
          <li className={styles.bullet}>
            <span className={styles.span}>
              Specifically to the precise type of processing for which consent is being
              sought
            </span>
          </li>
          <li className={styles.bullet}>
            <span className={styles.span}>
              With full knowledge and understanding of what is being done to/with their
              Personal Data
            </span>
          </li>
          <li className={styles.bullet}>
            <span className={styles.span}>
              In a manner that includes a positive action indicating their consent (for
              instance, having to check a tick-box)
            </span>
          </li>
        </ul>
        <p>
          <strong>Privacy Impact Assessment (PIA) or Data Protection Impact Assessment
            (DPIA)
          </strong> - Assessment, internal or external, to help organizations
          identify, assess and mitigate or minimize privacy risks with data processing
          activities.
        </p>
        <p className={styles.emFirst}>
          <em>Note: This document is for informational purposes and should not be taken
            as legal advice. Please consult your corporate counsel for further information.
          </em>
        </p>
        <p className={styles.emSecond}>
          <em> Source: The General Data Protection Regulation (GDPR) (EU) 2016/679,
            Cooley GDPR Readiness Questionnaire.
          </em>
        </p>
      </CardContent>
      <CardFooter />
    </Card>
  </PolicyLayout>
);

GdprFaq.displayName = 'GDPR FAQ';
GdprFaq.defaultProps = defaultProps;

export default GdprFaq;
