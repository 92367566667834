import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isPlainObject from 'lodash/isPlainObject';
import isString from 'lodash/isString';
import trim from 'lodash/trim';

interface ValidatorOptions {
  message: string;
  allowEmpty: boolean;
}

/**
 * The presence validator validates that the value is defined. You can use the
 * `message` option to customize the returned error message.
 *
 * These are the values that are considered empty:
 *
 *   - `null`
 *   - `undefined`
 *
 * Additionally, you can set the `allowEmpty` to false to disallow the following
 * values:
 *
 *   - `{}` (empty objects)
 *   - `[]` (empty arrays)
 *   - `""` (empty string)
 *   - `" "` (whitespace only string)
 *
 * @param {string} [options.message = "Cannot be blank"]
 * @param {boolean} [options.allowEmpty = true]
 * @returns {(value: *) => string}
 */
const presence = (options: ValidatorOptions) => (value: string | object): string | undefined => {
  const {
    message = 'Cannot be blank',
    allowEmpty = true,
  } = options;

  if (isNil(value)) {
    return message;
  }

  if (!allowEmpty) {
    if (isString(value)) {
      if (isEmpty(trim(value))) {
        return message;
      }
    } else if (isArray(value) || isPlainObject(value)) {
      if (isEmpty(value)) {
        return message;
      }
    }
  }

  return undefined;
};

export default presence;
