import PriceBookList from '../../../modules/price-books/containers/PriceBookList';
import PriceBookDetails from '../../../modules/price-books/containers/PriceBookDetails';

export default {
  path: ':organization/price-books',
  title: 'Price Books',
  indexRoute: {
    component: PriceBookList,
  },
  childRoutes: [
    {
      path: ':priceBookKey',
      component: PriceBookDetails,
      title: 'Price Book Details',
    },
  ],
};
