import { OptinPromptsPutPromptsByIdResponse } from '@flowio/api-internal-sdk';
import ActionTypes from '../constants/ActionTypes';
import {
  RootState, ThunkExtraArguments, ThunkResult, ThunkDispatcher,
} from '../../../stores/types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';
import standardAction from '../../../utilities/standard-action';

const deleteOptinAttributeByKey = (
  organization: string,
  optinPromptId: string,
  optinPromptForm: io.flow.internal.v0.models.OptinPromptForm,
): ThunkResult<Promise<OptinPromptsPutPromptsByIdResponse>> => (
  dispatch: ThunkDispatcher,
  getState: () => RootState,
  extra: ThunkExtraArguments,
): Promise<OptinPromptsPutPromptsByIdResponse> => {
  dispatch(standardAction(ActionTypes.UPDATE_OPTIN_PROMPT_REQUEST));

  return extra.apiInternal(getState()).optinPrompts.putPromptsById({
    organization,
    id: optinPromptId,
    body: optinPromptForm,
  }).then((response) => {
    if (response.ok) {
      dispatch(standardAction(ActionTypes.UPDATE_OPTIN_PROMPT_SUCCESS, response.body));
    } else {
      dispatch({
        type: ActionTypes.UPDATE_OPTIN_PROMPT_FAILURE,
        payload: formatErrorResponseV2(response),
      });
    }
    return response;
  });
};

export default deleteOptinAttributeByKey;
