import React from 'react';
import assign from 'lodash/assign';
import get from 'lodash/get';
import noop from 'lodash/noop';
import head from 'lodash/head';
import values from 'lodash/values';
import { Select } from '@flowio/react-select';
import kebabCase from 'lodash/kebabCase';

import QueryTargetField, { QueryTargetFieldType } from '../../../../../components/query-target-field';
import DeliveryOption from './delivery-option';
import withValidation from '../../../../../components/with-validation';
import { DataSource } from '../../../types';
import { WithValidationProps } from '../../../../../components/with-validation/with-validation';
import * as styles from './order-summary-check.styles';
import getTextFieldValue from '../../../../../utilities/getTextFieldValue';

interface SelectedDeliveryType {
  [k: string]: string;
}

interface OrderSettingsState {
  selectedDeliveryOptions: {
    [k in string]: string;
  };
}

interface OrderSettingsProps {
  experiences: io.flow.v0.models.Experience[];
  centers: io.flow.v0.models.Center[];
  deliveries: io.flow.v0.unions.Delivery[];
  selections: string[];
  onExperienceChange: (...args: any) => any;
  onCenterChange: (...args: any) => any;
  onAddItem: (...args: any) => any;
  onDeliveryOptionChange: (...args: any) => any;
}

class OrderSettings extends React.PureComponent<
OrderSettingsProps & WithValidationProps, OrderSettingsState
> {
  static displayName = 'OrderSettings';

  static defaultProps = {
    deliveries: [],
    selections: [],
    onExperienceChange: noop,
    onCenterChange: noop,
    onAddItem: noop,
    onDeliveryOptionChange: noop,
  };

  constructor(props: OrderSettingsProps & WithValidationProps) {
    super(props);

    this.state = {
      selectedDeliveryOptions: this.getFirstDeliveryOptions(),
    };
  }

  handleExperienceChange = (
    exp: string,
  ): void => {
    const { onExperienceChange, fields, experiences } = this.props;
    const selectedExperience: io.flow.v0.models.Experience | undefined = experiences
      .find((e) => e.key === exp);
    if (!selectedExperience) {
      return;
    }
    onExperienceChange(selectedExperience);
    if (fields && fields.experience.events.onChange) {
      fields.experience.events.onChange(selectedExperience);
    }
  };

  handleCenterChange = (center: string): void => {
    const { onCenterChange, fields, centers } = this.props;
    const selectedCenterOption: io.flow.v0.models.Center | undefined = centers
      .find((c) => c.key === center);
    if (!selectedCenterOption) {
      return;
    }
    onCenterChange(selectedCenterOption.key);

    if (fields && fields.experience.events.onChange) {
      fields.center.events.onChange(selectedCenterOption);
    }
  };

  handleNewRequest = (dataSource: DataSource): void => {
    const { fields, onAddItem } = this.props;
    if (fields) {
      fields.productSearchText.events.onChange(dataSource);
    }
    const number = get(dataSource, 'suggestion.number');
    if (number) {
      onAddItem({ number, quantity: 1 });
    }
  };

  handleDeliveryOptionClick = (deliveryId: string, deliveryOptionId: string): void => {
    const { selectedDeliveryOptions } = this.state;
    const { onDeliveryOptionChange } = this.props;
    const updatedState = {
      selectedDeliveryOptions: assign(
        {},
        selectedDeliveryOptions,
        { [deliveryId]: deliveryOptionId },
      ),
    };

    this.setState(updatedState);
    onDeliveryOptionChange(values(updatedState.selectedDeliveryOptions));
  };

  getFirstDeliveryOptions(): SelectedDeliveryType {
    const { deliveries } = this.props;
    const selected: SelectedDeliveryType = {};

    deliveries.forEach((delivery) => {
      if (delivery.discriminator === 'physical_delivery') {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        selected[delivery.id] = head(delivery.options)!.id;
      }
    });

    return selected;
  }

  render(): React.ReactNode {
    const {
      experiences,
      fields,
      centers,
      deliveries,
      selections,
    } = this.props;

    const digitalDeliveries = deliveries.length ? deliveries.filter((delivery) => delivery.discriminator === 'digital_delivery') : [];
    const physicalDeliveries = deliveries.length ? deliveries.filter((delivery) => delivery.discriminator === 'physical_delivery') as io.flow.v0.models.PhysicalDelivery[] : [];

    return (
      <div>
        <h3 className={styles.subtitle}>
          Choose Your Order Settings
        </h3>
        <p>
          Select experience and distribution center.
        </p>

        <section className={styles.expCountry}>
          <div className="exprience-field">
            <span className={styles.fieldLabel}>
              Experience
            </span>
            <Select
              onValueChange={this.handleExperienceChange}
              hintText="Select Experience"
              options={experiences.map((e) => ({ value: e.key, content: e.name }))}
              value={getTextFieldValue(fields.experience.value, 'key')}
            />
          </div>
          <div className={styles.centerField}>
            <span className={styles.fieldLabel}>
              Center
            </span>
            <Select
              onValueChange={this.handleCenterChange}
              hintText="Select Center"
              options={centers.map((c) => ({ value: c.key, content: c.name }))}
              value={getTextFieldValue(fields.center.value, 'key')}
            />
          </div>
        </section>

        <section>
          <h3 className={styles.subtitle}>
            Add Items
          </h3>
          <QueryTargetField
            hintText="Enter product name or number to add to order"
            onChange={this.handleNewRequest}
            style={{ display: 'block' }}
            type={QueryTargetFieldType.TYPE_ITEMS}
            value={fields && fields.productSearchText.value as object}
          />
        </section>

        {!!deliveries.length && (
          <section>
            <h3 className={styles.subtitle}>
              Select Delivery Options
            </h3>
            <div>
              {physicalDeliveries.map((delivery: io.flow.v0.models.PhysicalDelivery) => (
                <div key={kebabCase(delivery.id)} className={styles.delivery}>
                  {delivery.options.map((option) => (
                    <DeliveryOption
                      key={kebabCase(option.id)}
                      deliveryId={delivery.id}
                      deliveryOption={option}
                      selected={selections.includes(option.id)}
                      onClick={this.handleDeliveryOptionClick}
                    />
                  ))}
                </div>
              ))}
            </div>
            {physicalDeliveries.length === 0 && digitalDeliveries.length > 0 && (
              <div className={styles.delivery}>
                Digital Delivery Only
              </div>
            )}
          </section>
        )}
      </div>
    );
  }
}

export default withValidation<OrderSettingsProps>({
  experience: {
    validations: {
      required: true,
      message: 'You must select an experience.',
    },
  },
  center: {
    validations: {
      required: true,
      message: 'You must select a center.',
    },
  },
  productSearchText: {
    validations: {
      required: true,
      message: 'Please search for a product to continue.',
    },
  },
})(OrderSettings);
