import ActionTypes from '../constants/action-types';
import { RootActionTypes } from '../../../stores/types';

/**
 * @descriptionghCreates an action responsible for selecting the currenct experience
 * logistics settings.
 */
export default function selectExperienceLogisticsSettings(
  { experienceLogisticsSettingsId }: { experienceLogisticsSettingsId: string },
): RootActionTypes {
  return {
    type: ActionTypes.SELECT_EXPERIENCE_LOGISTICS_SETTINGS,
    payload: experienceLogisticsSettingsId,
  };
}
