import { ImportType } from '@flowio/api-constants';
import React, { ChangeEvent, PureComponent } from 'react';
import { FormGroup } from '@flowio/react-form-group';
import kebabCase from 'lodash/kebabCase';
import noop from 'lodash/noop';
import { Field, Form } from 'redux-form';
import Alert from '../../../../components/alert/alert';
import prettyLabel from '../../../../utilities/strings/pretty-label';
import presence from '../../../../utilities/validators/presence';
import { ImportFormProps, ImportFormState } from './types';
import FileUploadRedux from '../../../../components/FileUploadRedux';
import * as styles from './ImportSection.styles';
import { TextField, SelectField } from '../../../../components/ReduxFormFieldAdapters';

/* validators */

const handleEmailValidation = [
  presence({ allowEmpty: false, message: 'Email is required' }),
];

const handleFileValidation = [
  presence({ allowEmpty: false, message: 'A File is required' }),
];

const handleImportTypeValidation = [
  presence({ allowEmpty: false, message: 'Hour Required' }),
];

export class ImportFileForm extends PureComponent<ImportFormProps, ImportFormState> {
  static displayName = 'ImportForm';

  constructor(props: ImportFormProps) {
    super(props);
    const { submitted } = this.props;
    this.state = {
      hasSubmitted: submitted,
    };
  }

  static getDerivedStateFromProps(props: ImportFormProps, state: ImportFormState) {
    return {
      hasSubmitted: props.submitted && !state.hasSubmitted,
    };
  }

  render() {
    const {
      error,
      isFileEmpty = false,
      setEmailRef = noop,
      fileName,
    } = this.props;

    return (
      <div>
        <Form>
          <FormGroup className={styles.formGroup} inline labelSize="medium" labelFor="email" labelText="Your Email">
            <Field
              name="email"
              component={TextField}
              validate={handleEmailValidation}
              onChange={(event: ChangeEvent<HTMLInputElement>) => setEmailRef(event)}
              hintText="Email Address"
              className="field-email"
              min="0"
              max="59"
              step="1"
              gutter
            />
          </FormGroup>
          <FormGroup className={styles.formGroup} inline labelSize="medium" labelFor="import-code" labelText="Import Type">
            <Field
              selection
              name="import_code"
              validate={handleFileValidation}
              component={SelectField}
              id="import_type"
              hintText="Import Type"
              className="field"
              valueKey="value"
              labelKey="label"
              options={Object.values(ImportType).map((importType) => ({
                label: prettyLabel(importType),
                value: importType,
              }))}
              gutter
              mscrollableMenu
            />
          </FormGroup>

          <FormGroup className={styles.formGroup} inline labelSize="medium" labelFor="file-import" labelText="Choose File">
            <Field component={FileUploadRedux} dragAndDrop={false} name="import_file" validate={handleImportTypeValidation} allowedFileTypes={['csv', 'pdf', 'xls', 'xlsx']} />
            <span className="file-name">
              {fileName && fileName.length ? fileName : 'No file chosen' }
            </span>
          </FormGroup>
        </Form>

        {error && error.messages && error.messages.map((failure) => (
          <Alert key={kebabCase(failure.message)} type="failure">
            {failure.message}
          </Alert>
        ))}
        {isFileEmpty && (
          <Alert type="failure">
            The file you uploaded is empty. Please try again.
          </Alert>
        )}
      </div>

    );
  }
}

export default ImportFileForm;
