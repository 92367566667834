import keyMirror from '../../../utilities/keymirror';

export default keyMirror({
  CREATE_CATALOG_QUERY_BUILDER_REQUEST: null,
  CREATE_CATALOG_QUERY_BUILDER_SUCCESS: null,
  CREATE_CATALOG_QUERY_BUILDER_FAILURE: null,
  CREATE_CENTER_QUERY_BUILDER_REQUEST: null,
  CREATE_CENTER_QUERY_BUILDER_SUCCESS: null,
  CREATE_CENTER_QUERY_BUILDER_FAILURE: null,
});
