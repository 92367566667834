export enum FormName {
  CREATE_EXPERIENCE = 'createExperience',
  CLONE_EXPERIENCE = 'cloneExperience',
  EXPERIENCE_LOGISTICS_SETTING = 'experienceLogisticsSetting',
  EXPERIENCE_LIST_FILTER_FORM = 'experienceListFilterForm',
  LANDED_COST = 'landedCost',
  UPDATE_EXPERIENCE = 'updateExperience',
  EXPORT_EXPERIENCE = 'exportExperience',
}

export default FormName;
