import { UsersGetByIdResponse } from '@flowio/api-sdk';
import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import ActionTypes from '../constants/action-types';

function requestAction(): RootActionTypes {
  return {
    type: ActionTypes.FETCH_ACCEPTING_USER_REQUEST,
  };
}

function successAction(payload: io.flow.v0.models.User): RootActionTypes {
  return {
    type: ActionTypes.FETCH_ACCEPTING_USER_SUCCESS,
    payload,
  };
}

function failureAction(payload: FormattedErrorMessages): RootActionTypes {
  return {
    type: ActionTypes.FETCH_ACCEPTING_USER_FAILURE,
    payload,
  };
}

function fetchAcceptingUser(userId: string): ThunkResult<Promise<UsersGetByIdResponse>> {
  return function fetchAcceptingUserEffects(
    dispatch,
    getState,
    extra,
  ): Promise<UsersGetByIdResponse> {
    dispatch(requestAction());

    return extra.api(getState()).users.getById({
      id: userId,
    }).then((response) => {
      if (response.ok) {
        dispatch(successAction(response.body));
      }

      const errors = formatErrorResponseV2(response);
      dispatch(failureAction(errors));
      return response;
    });
  };
}

export default fetchAcceptingUser;
