import { LaneDirection } from '@flowio/api-constants';
import { Button } from '@flowio/react-button';
import {
  Card, CardHeader, CardContent, CardFooter, CardActions, CardTitle,
} from '@flowio/react-card';
import { css } from '@emotion/react';
import { Plus } from '@flowio/react-icons';
import FlipMove from 'react-flip-move';
import { NonIdealState } from '@flowio/react-non-ideal-state';
import React from 'react';
import uniqueId from 'lodash/uniqueId';

import QueryFilter from '../../../query-builder/containers/QueryFilter';
import CenterQueryResult from '../../containers/CenterQueryResult';
import { CenterQueryBuilderCardProps } from '../../types/components';

const cardTitleStyles = css({
  display: 'inline-block',
});

const cardActionStyles = css({
  float: 'right',
});

const CenterQueryBuilderCard: React.FC<CenterQueryBuilderCardProps> = ({
  availableFilters,
  fields,
  meta,
  shippingLaneDirection,
}) => (
  <Card>
    <CardHeader dividing>
      <CardTitle css={cardTitleStyles} content={shippingLaneDirection === LaneDirection.OUTBOUND ? 'From: Center(s)' : 'To: Center(s)'} />
      <CardActions css={cardActionStyles}>
        <Button
          content="Add Selection"
          intent="primary"
          leftIcon={Plus}
          variant="outline"
          type="button"
          onClick={() => {
            if (availableFilters.length) {
              const [availableFilter] = availableFilters;
              fields.unshift({
                discriminator: availableFilter.discriminator,
                field: availableFilter.field,
                operator: availableFilter.operators[0],
                values: [],
                // Add a unique identifier that we can use as the component key for
                // FLIP animation to work correctly.
                // https://github.com/erikras/redux-form/issues/2735
                uniqueId: uniqueId('filter'),
              });
            }
          }}
        />
      </CardActions>
    </CardHeader>
    <CardContent subdued>
      {(fields.length === 0) && (
        <NonIdealState
          description={'Create one or more query rules to select centers. To add a rule, click the "Add Selection" button above.'}
        />
      )}
      <FlipMove duration={300} easing="ease" staggerDelayBy={20} staggerDurationBy={15}>
        {fields.map((name: string, index: number) => (
          <QueryFilter
            availableFilters={availableFilters}
            key={fields.get(index).uniqueId}
            name={name}
            onRequestRemove={() => fields.remove(index)}
          />
        ))}
      </FlipMove>
      {meta.submitFailed && (
        <div>{meta.error}</div>
      )}
    </CardContent>
    {(fields.length > 0) && (
      <CardFooter dividing>
        <CenterQueryResult />
      </CardFooter>
    )}
  </Card>
);

CenterQueryBuilderCard.displayName = 'CenterQueryBuilderCard';

export default CenterQueryBuilderCard;
