import { ExperiencesPutByKeyResponse } from '@flowio/api-sdk';
import ActionTypes from '../constants/action-types';
import {
  ThunkExtraArguments,
  RootState,
  ThunkDispatcher,
  ThunkResult,
} from '../../../stores/types';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import toLegacyResponse from '../../../utilities/clients/to-legacy-response';
import { PropType } from '../../../types';

type ResponseType = PropType<ExperiencesPutByKeyResponse, 'body'>;

/**
 * Update a experience by name (key). Dispatches actions for each step of the
 * request cycle.
 */

export default function updateExperience(
  organization: string,
  experienceKey: string,
  formData: io.flow.v0.models.ExperienceForm,
): ThunkResult<Promise<LegacyResponse<ResponseType>>> {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<LegacyResponse<ResponseType>> => {
    dispatch({
      type: ActionTypes.UPDATE_EXPERIENCE_SUBMIT,
      formData,
    });

    return extra.api(getState()).experiences.putByKey({
      organization,
      key: experienceKey,
      body: formData,
    }).then((response) => {
      if (response.ok) {
        dispatch({
          type: ActionTypes.UPDATE_EXPERIENCE_SUCCESS,
          formData,
          payload: response.body,
        });
      }
      return toLegacyResponse<ResponseType>(response);
    });
  };
}
