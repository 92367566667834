import { createSelector } from 'reselect';
import getMarketingState from './getMarketingState';
import { RootState } from '../../../stores/types';
import { MarketingState } from '../types';

export default createSelector<
RootState, MarketingState, io.flow.internal.v0.unions.MarketingGatewayFeedSource[]
>(
  getMarketingState,
  ({ feedSources }: MarketingState):
  io.flow.internal.v0.unions.MarketingGatewayFeedSource[] => feedSources.sources,
);
