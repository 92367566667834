/**
 * Spacing tokens are used for padding, margins, and position coordinates.
 */
const spacing = {
  xxxs: '0.0625rem',
  xxs: '0.125rem',
  xs: '0.25rem',
  s: '0.5rem',
  m: '0.625rem',
  l: '0.75rem',
  xl: '1rem',
  xxl: '1.24rem',
  xxxl: '1.5rem',
  dbl: '2rem',
  marketing: '2.5rem',
} as const;

export default spacing;
