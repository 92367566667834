import React from 'react';
import {
  PaperPlane, Pencil, Plus, Ellipsis,
} from '@flowio/react-icons';
import { Box } from '@flowio/react-box';
import { Button, IconButton } from '@flowio/react-button';
import {
  Card, CardHeader, CardContent, CardFooter, CardEmptyState, CardTitle,
} from '@flowio/react-card';
import { Dropdown, DropdownMenu, DropdownItem } from '@flowio/react-dropdown';
import cond from 'lodash/cond';
import constant from 'lodash/constant';
import isEmpty from 'lodash/isEmpty';
import matches from 'lodash/matches';
import { PageContent } from '@flowio/react-page';
import { toOptinContentFormValues } from '../../../utilities/converters';
import toOptinAttributeFormValues from '../../../utilities/toOptinAttributeFormValues';
import FormattedDate from '../../../../../components/formatted-date';
import UpdateOptinMessageWorksheet from '../../UpdateOptinMessageWorksheet';
import OptinContentDeleteConfirm from '../../OptinContentDeleteConfirm';
import OptinContentList from '../../OptinContentList';
import OptinContentWorksheet from '../../OptinContentWorksheet/containers/OptinContentWorksheet';
import OptinMessageChangeStatusConfirm from '../../OptinMessageChangeStatusConfirm/containers/OptinMessageChangeStatusConfirm';
import OptinMessageDeleteConfirm from '../../OptinMessageDeleteConfirm/containers/OptinMessageDeleteConfirm';
import OptinMessageStatus from '../../OptinMessageStatus';
import OptinMessageTarget from '../../OptinMessageTarget';
import Pagination from '../../../../../components/pagination';
import * as styles from './OptinMessageDetailView.styles';
import ContentStatus from '../../../constants/ContentStatus';
import { Props } from '../types';
import { OptinAttributeFormValues } from '../../../types';

const OptinMessageDetailView: React.FC<Props> = ({
  languages,
  onDeleteOptinContent,
  onDeleteOptinContentSuccess,
  onDeleteOptinMessageSuccess,
  onEditOptinMessageSuccess,
  onActivateOptinMessage,
  onArchiveOptinMessage,
  onDeleteOptinMessage,
  onEditOptinMessage,
  onEditContent,
  optinAttribute,
  optinPrompts,
  organizationId,
  regions,
  pageNumber,
  isFirstPage,
  isLastPage,
  isSinglePage,
  entriesPerPage,
  onPageChange,
  onAddContent,
}) => {
  const handleAddContentButtonClick = (): void => {
    const initialValues = toOptinContentFormValues(organizationId, optinAttribute);
    onAddContent({ initialValues });
  };

  const handleActivateButtonClick = (): void => {
    onActivateOptinMessage(organizationId, optinAttribute);
  };

  const handleArchiveSelection = (): void => {
    onArchiveOptinMessage(organizationId, optinAttribute);
  };

  const handleDeleteSelection = (): void => {
    onDeleteOptinMessage(organizationId, optinAttribute);
  };

  const handleOptinPromptEdit = (
    orgId: string,
    attribute: io.flow.internal.v0.models.OptinAttribute,
    prompt: io.flow.internal.v0.models.OptinPrompt,
  ): void => {
    const initialValues = toOptinContentFormValues(orgId, attribute, prompt);
    onEditContent({ initialValues });
  };

  const handleUnarchiveSelection = (): void => {
    onActivateOptinMessage(organizationId, optinAttribute);
  };

  const handleEditButtonClick = (): void => {
    const initialValues: OptinAttributeFormValues = toOptinAttributeFormValues(
      organizationId,
      optinAttribute,
    );
    onEditOptinMessage({ initialValues });
  };

  const pageChange = (delta: number) => {
    const { optin_attribute_key: optinAttributeKey } = optinAttribute;

    const nextPage = Number.parseInt(pageNumber, 10) + delta;

    onPageChange(organizationId, optinAttributeKey, nextPage, entriesPerPage);
  };

  const handleRequestNextPage = (): void => {
    pageChange(1);
  };

  const handleRequestPreviousPage = (): void => {
    pageChange(-1);
  };

  const isOptinPromptsEmpty = isEmpty(optinPrompts);

  return (
    <PageContent>
      <Card>
        <CardHeader dividing>
          <Box flexAuto justifyContent="between">
            <CardTitle content="Profile" />
            <Box spacing="loose">
              {cond([
                [matches({ status: ContentStatus.DRAFT }), constant(
                  <Button
                    content="Activate"
                    disabled={isOptinPromptsEmpty}
                    leftIcon={PaperPlane}
                    onClick={handleActivateButtonClick}
                  />,
                )],
              ])(optinAttribute)}
              <Button
                content="Edit"
                leftIcon={Pencil}
                onClick={handleEditButtonClick}
              />
              <Dropdown
                trigger={(<IconButton icon={Ellipsis} size="medium" />)}
              >
                <DropdownMenu>
                  {cond([
                    [matches({ status: ContentStatus.ARCHIVED }), constant(
                      <DropdownItem content="Unarchive" onSelect={handleUnarchiveSelection} />,
                    )],
                    [matches({ status: ContentStatus.LIVE }), constant(
                      <DropdownItem content="Archive" onSelect={handleArchiveSelection} />,
                    )],
                    [matches({ status: ContentStatus.DRAFT }), constant(
                      <DropdownItem content="Delete" onSelect={handleDeleteSelection} />,
                    )],
                  ])(optinAttribute)}
                </DropdownMenu>
              </Dropdown>
            </Box>
          </Box>
        </CardHeader>
        <CardContent>
          <div className={styles.descriptionGroup}>
            <dl className={styles.description}>
              <dt className={styles.term}>
                Name
              </dt>
              <dd className={styles.definition}>
                {optinAttribute.name}
              </dd>
              <dt className={styles.term}>
                Target
              </dt>
              <dd className={styles.definition}>
                <OptinMessageTarget optinAttribute={optinAttribute} />
              </dd>
              <dt className={styles.term}>
                Created
              </dt>
              <dd className={styles.definition}>
                <FormattedDate format="LLL" value={optinAttribute.created_at} />
              </dd>
            </dl>
            <dl className={styles.description}>
              <dt className={styles.term}>
                Attribute
              </dt>
              <dd className={styles.definition}>
                {optinAttribute.optin_attribute_key}
              </dd>
              <dt className={styles.term}>
                Status
              </dt>
              <dd className={styles.definition}>
                <OptinMessageStatus optinAttribute={optinAttribute} />
              </dd>
              <dt className={styles.term}>
                Last Updated
              </dt>
              <dd className={styles.definition}>
                <FormattedDate format="LLL" value={optinAttribute.updated_at} />
              </dd>
            </dl>
          </div>
        </CardContent>
        <CardFooter />
      </Card>
      <Card>
        <CardHeader dividing>
          <Box flexAuto justifyContent="between">
            <CardTitle content="Content" />
            <Button
              content="Add Content"
              intent="primary"
              leftIcon={Plus}
              onClick={handleAddContentButtonClick}
              variant="outline"
            />
          </Box>
        </CardHeader>
        <CardContent fitted>
          {isOptinPromptsEmpty ? (
            <CardEmptyState content="No Content" />
          ) : (
            <OptinContentList
              languages={languages}
              onDeleteOptinPrompt={onDeleteOptinContent}
              onEditOptinPrompt={handleOptinPromptEdit}
              optinAttribute={optinAttribute}
              optinPrompts={optinPrompts}
              organizationId={organizationId}
              regions={regions}
            />
          )}
        </CardContent>
        <CardFooter>
          {!isSinglePage && (
            <Pagination
              isNextPageDisabled={isLastPage}
              isPreviousPageDisabled={isFirstPage}
              onRequestNextPage={handleRequestNextPage}
              onRequestPreviousPage={handleRequestPreviousPage}
              pageNumber={pageNumber}
            />
          )}
        </CardFooter>
      </Card>
      <OptinContentDeleteConfirm
        onSubmitSuccess={onDeleteOptinContentSuccess}
        regions={regions}
      />
      <OptinMessageChangeStatusConfirm />
      <OptinMessageDeleteConfirm
        onSubmitSuccess={onDeleteOptinMessageSuccess}
      />
      <UpdateOptinMessageWorksheet
        onSubmitSuccess={onEditOptinMessageSuccess}
      />
      <OptinContentWorksheet
        languages={languages}
        regions={regions}
        optinAttribute={optinAttribute}
      />
    </PageContent>
  );
};

OptinMessageDetailView.displayName = 'OptinMessageDetailView';

export default OptinMessageDetailView;
