import React, { PureComponent } from 'react';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import noop from 'lodash/noop';
import { Popover } from '@flowio/react-popover';
import { Button } from '@flowio/react-button';
import { Menu } from '@flowio/react-menu';
import { ChevronDown } from '@flowio/react-icons';
import { MultiCheckDropdownProps, MultiCheckDropdownOption } from '../../types/components';

interface MultiCheckDropdownState {
  checked: boolean;
  content: string;
}

export default class MultiCheckDropdown extends PureComponent<
MultiCheckDropdownProps,
MultiCheckDropdownState
> {
  static defaultProps = {
    options: [],
    onChange: noop,
    onClose: noop,
    value: [],
  };

  constructor(props: MultiCheckDropdownProps) {
    super(props);
    this.state = {
      content: this.generateLabelFromValues(props.options, props.value),
      checked: !!props.value,
    };
  }

  generateLabelFromValues = (options: MultiCheckDropdownOption[] = [], values: string[] = []) => {
    const selectedLabels = filter(
      options,
      (f) => includes(values, f.value),
    ).map((f) => f.label);

    return (options.length === values.length) ? 'Any' : selectedLabels.join(', ');
  };

  render() {
    const {
      options = [],
      onChange = noop,
      onClose = noop,
      value,
    } = this.props;

    const {
      checked,
      content,
    } = this.state;

    return (
      <Popover
        openOnFocus={false}
        openOnClick
        onClose={() => {
          onClose(checked);
        }}
        trigger={<Button variant="outline" content={content} rightIcon={ChevronDown} />}
        position="bottom right"
      >
        <Menu
          checkOnSelection
          multiple
          items={options.map((f) => ({ value: f.value, content: f.label, style: { paddingRight: '50px' } }))}
          value={value}
          onChange={(_i: number, values?: string | string[]) => {
            if (!values || !values.length || typeof values === 'string') {
              return;
            }
            this.setState({
              content: this.generateLabelFromValues(options, values),
              checked: !!values,
            });
            onChange(values);
          }}
        />
      </Popover>
    );
  }
}
