import React from 'react';
import PropTypes from 'prop-types';
import BemHelper from '@flowio/bem-helper';
import isNil from 'lodash/isNil';

import DescriptionTerm from './description-term';
import DescriptionDefinition from './description-definition';

if (process.browser) {
  require('./description.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('description');

export default function Description(props) {
  const {
    children, className, definition, term, ...unhandledProps
  } = props;

  return (
    <dl {...unhandledProps} className={bem.block(className)}>
      {children}
      {isNil(children) && <DescriptionTerm content={term} />}
      {isNil(children) && <DescriptionDefinition content={definition} />}
    </dl>
  );
}
Description.displayName = 'Description';

Description.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  definition: PropTypes.string,
  term: PropTypes.node,
};

Description.defaultProps = {
  children: undefined,
  className: '',
  definition: '',
  term: '',
};

Description.Term = DescriptionTerm;
Description.Definition = DescriptionDefinition;
