import { isNotNil } from '@flowio/is';
import React, { ComponentProps } from 'react';
import { NavItem, NavLink } from '@flowio/react-nav';
import { browserHistory } from 'react-router';

type Props = Omit<ComponentProps<typeof NavLink>, 'onClick'> & {
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
};

export default function ConsoleNavItem({
  onClick,
  ...unhandledProps
}: Props) {
  const onClickHandler = React.useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const { href } = e.currentTarget;
    browserHistory.push(href);
    if (isNotNil(onClick)) {
      onClick(e);
    }
  }, [onClick]);

  return (
    <NavItem>
      <NavLink {...unhandledProps} css={{ '&:hover': { textDecoration: 'none' } }} onClick={onClickHandler} />
    </NavItem>
  );
}

ConsoleNavItem.defaultProps = {
  onClick: undefined,
};
