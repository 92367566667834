import assign from 'lodash/assign';
import concat from 'lodash/concat';
import map from 'lodash/map';
import reject from 'lodash/reject';
import update from 'immutability-helper';
import { ActionTypes } from './constants';

const initialState = {
  deliveries: {},
  webhook: {},
  webhooks: {},
};

export default function webhookReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GET_WEBHOOK_DELIVERY_SUCCESS:
      return assign({}, state, {
        deliveries: assign({}, state.deliveries, {
          results: map(state.deliveries.results, (delivery) => {
            if (delivery.id === action.payload.id) return action.payload;
            return delivery;
          }),
        }),
      });

    case ActionTypes.REDELIVER_SUCCESS:
      return assign({}, state, {
        deliveries: assign({}, state.deliveries, {
          results: state.deliveries.results.map((delivery) => {
            if (delivery.id === action.deliveryId) {
              return assign({}, delivery, {
                latest: action.payload,
                status: action.payload.status,
              });
            }
            return delivery;
          }),
        }),
      });
    case ActionTypes.CREATE_WEBHOOK_SUCCESS:
      return assign({}, state, {
        webhooks: assign({}, state.webhooks, {
          results: concat([action.payload], state.webhooks.results),
        }),
      });
    case ActionTypes.DELETE_WEBHOOK_SUCCESS:
      return assign({}, state, {
        webhook: null,
        webhooks: assign({}, state.webhooks, {
          results: reject(state.webhooks.results, { id: action.webhookId }),
        }),
      });
    case ActionTypes.GET_WEBHOOK_SUCCESS:
      return assign({}, state, { webhook: action.payload });
    case ActionTypes.FETCH_WEBHOOKS_SUCCESS:
      if (action.payload.result.isFirstPage) {
        return assign({}, state, { webhooks: action.payload.result });
      }

      return assign({}, state, {
        webhooks: assign({}, state.webhooks, {
          isFirstPage: action.payload.isFirstPage,
          isLastPage: action.payload.isLastPage,
          results: concat(state.webhooks.results, action.payload.results),
        }),
      });
    case ActionTypes.GET_WEBHOOK_DELIVERIES_SUCCESS:
      if (action.payload.isFirstPage) {
        return assign({}, state, { deliveries: action.payload });
      }

      return assign({}, state, {
        deliveries: assign({}, state.deliveries, {
          isFirstPage: action.payload.isFirstPage,
          isLastPage: action.payload.isLastPage,
          results: concat(state.deliveries.results, action.payload.results),
        }),
      });
    case ActionTypes.UPDATE_WEBHOOK_SUCCESS:
      return assign({}, state, {
        webhook: action.payload,
        webhooks: assign({}, state.webhooks, {
          results: map(state.webhooks.results, (webhook) => {
            if (webhook.id === action.webhookId) return action.payload;
            return webhook;
          }),
        }),
      });
    case ActionTypes.SYNC_WEBHOOK_DELIVERIES_SUCCESS:
      return update(state, {
        deliveries: {
          results: {
            $set: state.deliveries.results.map((hook) => {
              const updated = action.payload.find((h) => h.id === hook.id);
              return updated || hook;
            }),
          },
        },
      });
    default:
      return state;
  }
}
