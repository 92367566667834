import DiscountListing from '../../../modules/discounts/components/DiscountListing/containers/DiscountListing';
import DiscountDetailsForm from '../../../modules/discounts/components/DiscountDetailsForm/containers/DiscountDetailsForm';

export default {
  path: ':organization/discounts',
  indexRoute: { component: DiscountListing },
  title: 'Discounts',
  childRoutes: [
    {
      path: 'new',
      component: DiscountDetailsForm,
      title: 'New Discount',
    },
    {
      path: ':discountId',
      component: DiscountDetailsForm,
      title: 'Edit Discount',
    },
  ],
};
