import { $HttpResponse } from '@flowio/api-sdk';
import ActionTypes from '../constants/action-types';
import { ApiError } from '../../../utilities/clients/api-error';
import standardAction from '../../../utilities/standard-action';
import {
  RootState, ThunkExtraArguments, ThunkResult, ThunkDispatcher,
} from '../../../stores/types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';

export default function createExperiment(
  organization: string,
  formData: io.flow.internal.v0.models.ExperimentForm,
): ThunkResult<Promise<io.flow.internal.v0.unions.Experiment>> {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<io.flow.internal.v0.unions.Experiment> => {
    dispatch({
      type: ActionTypes.CREATE_EXPERIMENT_REQUEST,
    });

    return extra.apiInternal(getState()).experiments.post({
      organization,
      body: formData,
    }).then((response) => {
      if (response.ok) {
        dispatch(
          standardAction(
            ActionTypes.CREATE_EXPERIMENT_SUCCESS,
            response.body,
          ),
        );
        return response.body;
      }

      const errorResponse = response as $HttpResponse<io.flow.v0.models.GenericError>;
      const error = new ApiError(errorResponse);

      dispatch({
        type: ActionTypes.CREATE_EXPERIMENT_FAILURE,
        payload: formatErrorResponseV2(response),
      });
      throw error;
    });
  };
}
