import ActionTypes from '../constants/action-types';

const defaultState = [];

export default function (state = defaultState, action) {
  switch (action.type) {
    case ActionTypes.FETCH_RATECARDS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
