import { ThunkDispatcher } from '../../../stores/types';
import { createToast } from '../../console/actions';
import DialogName from '../constants/DialogName';
import hideDialog from './hideDialog';

function updateOptinMessageSuccess() {
  return function updateOptinMessageSuccessSideEffects(
    dispatch: ThunkDispatcher,
  ) {
    return Promise.all([
      dispatch(createToast({
        content: 'Opt-in message has been updated.',
        icon: 'InfoCircle',
      })),
      dispatch(hideDialog(DialogName.UPDATE_OPTIN_MESSAGE_WORKSHEET)()),
    ]);
  };
}

export default updateOptinMessageSuccess;
