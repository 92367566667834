export enum ActionTypes {
  CREATE_SCHEDULED_EXPORT_REQUEST = 'CREATE_SCHEDULED_EXPORT_REQUEST',
  CREATE_SCHEDULED_EXPORT_SUCCESS = 'CREATE_SCHEDULED_EXPORT_SUCCESS',
  CREATE_SCHEDULED_EXPORT_FAILURE = 'CREATE_SCHEDULED_EXPORT_FAILURE',
  DELETE_SCHEDULED_EXPORT_REQUEST = 'DELETE_SCHEDULED_EXPORT_REQUEST',
  DELETE_SCHEDULED_EXPORT_SUCCESS = 'DELETE_SCHEDULED_EXPORT_SUCCESS',
  SELECT_SCHEDULED_EXPORT = 'SELECT_SCHEDULED_EXPORT',
  CLOSE_SCHEDULED_EXPORT = 'CLOSE_SCHEDULED_EXPORT',
  DELETE_SCHEDULED_EXPORT_FAILURE = 'DELETE_SCHEDULED_EXPORT_FAILURE',
  TOGGLE_SCHEDULED_EXPORT_DIALOG = 'TOGGLE_SCHEDULED_EXPORT_DIALOG',
  FETCH_SCHEDULED_EXPORTS_REQUEST = 'FETCH_SCHEDULED_EXPORTS_REQUEST',
  FETCH_SCHEDULED_EXPORTS_SUCCESS = 'FETCH_SCHEDULED_EXPORTS_SUCCESS',
  FETCH_SCHEDULED_EXPORTS_FAILURE = 'FETCH_SCHEDULED_EXPORTS_FAILURE',
  GET_ORGANIZATION_INVITATION_REQUEST = 'GET_ORGANIZATION_INVITATION_REQUEST',
  GET_ORGANIZATION_INVITATION_SUCCESS = 'GET_ORGANIZATION_INVITATION_SUCCESS',
  GET_ORGANIZATION_INVITATION_FAILURE = 'GET_ORGANIZATION_INVITATION_FAILURE',
  ACCT_ORG_NEW_NAME_CHANGE = 'ACCT_ORG_NEW_NAME_CHANGE',
  ACCT_ORG_NEW_KEY_CHANGE = 'ACCT_ORG_NEW_KEY_CHANGE',
  ACCT_ORG_CREATE_SUBMIT = 'ACCT_ORG_CREATE_SUBMIT',
  ACCT_ORG_CREATE_COMPLETED = 'ACCT_ORG_CREATE_COMPLETED',
  ACCT_ORG_CREATE_ERROR = 'ACCT_ORG_CREATE_ERROR',
  ACCT_ORG_ACCEPT_INVITATION_SUBMIT = 'ACCT_ORG_ACCEPT_INVITATION_SUBMIT',
  ACCT_ORG_ACCEPT_INVITATION_COMPLETED = 'ACCT_ORG_ACCEPT_INVITATION_COMPLETED',
  ACCT_ORG_ACCEPT_INVITATION_ERROR = 'ACCT_ORG_ACCEPT_INVITATION_ERROR',
  VERIFY_EMAIL_REQUEST = 'VERIFY_EMAIL_REQUEST',
  VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS',
  VERIFY_EMAIL_FAILURE = 'VERIFY_EMAIL_FAILURE',
  VERIFY_USER_REQUEST = 'VERIFY_USER_REQUEST',
  VERIFY_USER_SUCCESS = 'VERIFY_USER_SUCCESS',
  VERIFY_USER_FAILURE = 'VERIFY_USER_FAILURE',
}

export default ActionTypes;
