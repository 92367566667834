import { scaleOrdinal } from 'd3';
import getXPosition from './get-x-position';
import { ChartAttributes } from './types/Charts';

export default function createXScale<T>(
  data: T[],
  key: keyof T,
  chartAttrs: ChartAttributes,
) {
  const xDomain = data.map((res) => String(res[key]));
  xDomain.unshift('');

  const xRange = data.map((_, index) => getXPosition(data.length, index, chartAttrs));
  xRange.unshift(0);

  return scaleOrdinal<string, number>()
    .range(xRange)
    .domain(xDomain);
}
