export interface ExperimentType {
  key: io.flow.internal.v0.enums.ExperimentDiscriminatorKey;
  name: string;
  description: string;
}

export const experience: ExperimentType = {
  key: 'experience',
  name: 'Experience',
  description: 'Experience experiments test the settings across different experiences of a region.',
};

export const feature: ExperimentType = {
  key: 'feature',
  name: 'Feature',
  description: 'Feature experiments test different feature settings across all regions you sell to.',
};

export const experimentTypes = [experience, feature];

export default {
  experience,
  feature,
};
