import { createSelector } from 'reselect';
import get from 'lodash/get';
import getQueryBuilderForExclusionRuleWorksheet from './getQueryBuilderForExclusionRuleWorksheet';
import { RootState } from '../../../stores/types';

/**
 * Returns a list of filters supported by the query builder API.
 * @returns {AvailableFilter[]}
 */
const getAvailableFiltersForExclusionRuleWorksheet = createSelector<
RootState, io.flow.v0.models.QueryBuilder
| undefined, io.flow.v0.unions.AvailableFilter[]
| undefined
>(
  getQueryBuilderForExclusionRuleWorksheet,
  (queryBuilder) => get(queryBuilder, 'available'),
);

export default getAvailableFiltersForExclusionRuleWorksheet;
