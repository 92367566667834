import { $HttpResponse } from '@flowio/api-sdk';
import ActionTypes from '../constants/ActionTypes';
import { ApiError } from '../../../utilities/clients/api-error';
import standardAction from '../../../utilities/standard-action';
import disconnectPlatformAccount from './disconnectPlatformAccount';
import {
  RootState, ThunkExtraArguments, ThunkResult, ThunkDispatcher,
} from '../../../stores/types';

export default function disconnectChannel(
  organization: string,
  channel: io.flow.internal.v0.enums.MarketingGatewayPlatform,
): ThunkResult<Promise<void>> {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<void> => {
    // TODO This extra check/dispatch for google and fb will need to be removed when
    // the putDisconnectByChannelId BE bug is fixed
    if (channel === 'google' || channel === 'facebook') {
      return dispatch(
        disconnectPlatformAccount({ organizationId: organization, platformId: channel }),
      ).then(() => {
        dispatch(standardAction(ActionTypes.DISCONNECT_CHANNEL_SUCCESS, channel));
      });
    }
    dispatch({
      type: ActionTypes.DISCONNECT_CHANNEL_REQUEST,
    });

    return extra.apiInternal(getState()).marketingGatewayChannels.putDisconnectByChannelId({
      organization,
      channel_id: channel,
    }).then((response) => {
      if (response.ok) {
        dispatch(standardAction(ActionTypes.DISCONNECT_CHANNEL_SUCCESS, channel));
        return;
      }

      const errorResponse = response as $HttpResponse<io.flow.v0.models.GenericError>;
      const error = new ApiError(errorResponse);

      dispatch(standardAction(ActionTypes.DISCONNECT_CHANNEL_FAILURE));
      throw error;
    });
  };
}
