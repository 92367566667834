import React from 'react';
import PropTypes from 'prop-types';
import ApiInternalPropTypes from '@flowio/api-internal-prop-types';
import {
  Accordion, Panel, PanelContent, PanelHeader,
} from '@flowio/react-panel';
import { Box } from '@flowio/react-box';
import {
  Table,
  TableHead,
  TableRow, TableRowColumn,
  TableHeadColumn,
  TableBody,
} from '@flowio/react-table';
import { Button } from '@flowio/react-button';
import * as styles from './failure-summaries-list.styles';

const FailureSummariesList = ({ summaries, onRequeueFailures, onDeleteFailure }) => (
  <Accordion defaultActiveKey="false" id="accordion">
    {
      summaries.map((summary, i) => (
        summary.summaries.length > 0
        && (
          <Panel
            className={styles.panel}
            id={`accordion-${summary.database}`}
            eventKey={`${summary.database}-${i}`}
          >
            <PanelHeader>
              <Box spacing="tight" id={summary.database} justifyContent="between" alignContent="center" className={styles.panelHeader}>
                <label htmlFor="button">Database: {summary.database}</label>
                <Button
                  justifyContent="end"
                  alignContent="end"
                  id={`button-requeue-${summary.database}`}
                  className={styles.button}
                  content="Requeue All Event Failures"
                  intent="positive"
                  variant="outline"
                  onClick={(e) => {
                    e.stopPropagation();
                    onRequeueFailures(summary.database);
                  }}
                />
              </Box>
            </PanelHeader>
            <PanelContent>

              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeadColumn>Queue</TableHeadColumn>
                    <TableHeadColumn>Errors</TableHeadColumn>
                  </TableRow>
                </TableHead>
                {summary.summaries.map((s) => (
                  <TableBody>
                    <TableRow>
                      <TableRowColumn>{s.queue}<br />({s.count} errors)</TableRowColumn>
                      <TableRowColumn>

                        <Accordion defaultActiveKey="false" id={`accordion-errors-${s.queue}`}>
                          {s.errors && s.errors.map((e, idx) => (
                            <Panel
                              className={styles.errorPanel}
                              id={`accordion-delete-failure-${e.event_id}`}
                              eventKey={`event-key-${e.event_id}-${idx}`}
                            >
                              <PanelHeader>
                                <Box direction="column" spacing="tight" id={e.event_id} className={styles.panelHeader}>
                                  <label
                                    htmlFor="button-delete-failure"
                                  >{e.error.split('\n')[0]}
                                  </label>
                                  <Button
                                    id={`button-delete-failure-${e.event_id}`}
                                    content="Delete Failure from Queue"
                                    intent="negative"
                                    variant="outline"
                                    onClick={(evt) => {
                                      evt.stopPropagation();
                                      onDeleteFailure(summary.database, s.queue, e.event_id);
                                    }}
                                  />
                                </Box>
                              </PanelHeader>
                              <PanelContent>
                                {e.error}
                              </PanelContent>
                            </Panel>
                          ))}
                        </Accordion>
                      </TableRowColumn>
                    </TableRow>
                  </TableBody>
                ))}
              </Table>
            </PanelContent>
          </Panel>
        )
      ))
    }
  </Accordion>
);

FailureSummariesList.displayName = 'FailureSummariesList';

FailureSummariesList.propTypes = {
  summaries: PropTypes.arrayOf(ApiInternalPropTypes.alertFailureSummary),
  onRequeueFailures: PropTypes.func.isRequired,
  onDeleteFailure: PropTypes.func.isRequired,
};

FailureSummariesList.defaultProps = { summaries: [] };

export default FailureSummariesList;
