import { Ellipsis } from '@flowio/react-icons';
import { IconButton } from '@flowio/react-button';
import {
  Dropdown, DropdownMenu, DropdownItem, DropdownDivider,
} from '@flowio/react-dropdown';
import React from 'react';
import {
  TableRow,
  TableRowColumn,
} from '@flowio/react-table';
import get from 'lodash/get';
import size from 'lodash/size';
import { ShippingConfigurationListItemProps } from '../../types/components';

class ShippingConfigurationListItem extends React.PureComponent<
ShippingConfigurationListItemProps
> {
  static displayName = 'ShippingConfigurationListItem';

  constructor(props: ShippingConfigurationListItemProps, context: any) {
    super(props, context);
    this.handleTableRowClick = this.handleTableRowClick.bind(this);
    this.handleDuplicateItemSelection = this.handleDuplicateItemSelection.bind(this);
    this.handleDeleteItemSelection = this.handleDeleteItemSelection.bind(this);
  }

  handleTableRowClick() {
    const { onViewShippingConfiguration, organizationId, shippingConfiguration } = this.props;
    const shippingConfigurationKey = get(shippingConfiguration, 'key');
    onViewShippingConfiguration(organizationId, shippingConfigurationKey);
  }

  handleDeleteItemSelection() {
    const {
      experiences,
      onDeleteShippingConfiguration,
      organizationId,
      shippingConfiguration,
    } = this.props;
    onDeleteShippingConfiguration(organizationId, shippingConfiguration, experiences);
  }

  handleDuplicateItemSelection() {
    const { onDuplicateShippingConfiguration, organizationId, shippingConfiguration } = this.props;
    onDuplicateShippingConfiguration(organizationId, shippingConfiguration);
  }

  render() {
    const { experiences, shippingConfiguration, currentIndex } = this.props;
    const experienceCount = size(experiences);
    const shippingLanes = get(shippingConfiguration, 'shipping_lanes');
    const shippingLanesCount = size(shippingLanes);
    return (
      <TableRow selectable onClick={this.handleTableRowClick} striped={currentIndex % 2 === 1}>
        <TableRowColumn>{shippingConfiguration.name}</TableRowColumn>
        <TableRowColumn>{shippingConfiguration.type}</TableRowColumn>
        <TableRowColumn>{shippingLanesCount}</TableRowColumn>
        <TableRowColumn>{`${experienceCount >= 100 ? '99+' : experienceCount}`}</TableRowColumn>
        <TableRowColumn textAlign="right">
          <Dropdown
            openOnClick
            onClick={(e) => e.stopPropagation()}
            trigger={<IconButton icon={Ellipsis} size="small" />}
          >
            <DropdownMenu>
              <DropdownItem content="Duplicate" onClick={this.handleDuplicateItemSelection} />
              {shippingConfiguration.type !== 'default' && (
                <DropdownDivider />
              )}
              {shippingConfiguration.type !== 'default' && (
                <DropdownItem content="Delete" onClick={this.handleDeleteItemSelection} />
              )}
            </DropdownMenu>
          </Dropdown>
        </TableRowColumn>
      </TableRow>
    );
  }
}

export default ShippingConfigurationListItem;
