import update from 'immutability-helper';
import ActionTypes from '../constants/action-types';
import { RootActionTypes } from '../../../stores/types';
import { RefundOrderSummaryState } from '../types';

const initialState: RefundOrderSummaryState = {
  amounts: {
    balance: 0,
    balance_excluding_shipping: 0,
    shipping: 0,
  },
  captured: 0,
  currency: '',
  order_number: '',
  refunded: 0,
  error: undefined,
};

export default function reducer(
  state = initialState,
  action: RootActionTypes,
): RefundOrderSummaryState {
  switch (action.type) {
    case ActionTypes.RESET_REFUND_ORDER_SUMMARY:
      return initialState;
    case ActionTypes.FETCH_REFUND_ORDER_SUMMARY_REQUEST:
      return update(state, { error: { $set: undefined } });
    case ActionTypes.FETCH_REFUND_ORDER_SUMMARY_SUCCESS:
      return update(state, { $merge: action.payload });
    case ActionTypes.FETCH_REFUND_ORDER_SUMMARY_FAILURE:
      return update(state, { error: { $set: action.payload } });
    case ActionTypes.FETCH_REFUND_ORDER_SUMMARY_NOT_FOUND:
      return initialState;
    default:
      return state;
  }
}
