import { createSelector } from 'reselect';
import property from 'lodash/property';
import getMarketingState from './getMarketingState';
import { RootState } from '../../../stores/types';
import { MarketingState, DeleteFeedConfirmationDialogProps } from '../types';

export default createSelector<RootState, MarketingState, DeleteFeedConfirmationDialogProps>(
  getMarketingState,
  property(['deleteFeedConfirmationDialog', 'data']),
);
