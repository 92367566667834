import { css } from '@emotion/css';

export const chartLegend = css`
  margin-left: 30px;
`;

export const legendBox = css`
  margin-right: 5px;

  &.processing::before {
    background-color: $color-yellow-300;
  }
  &.pending::before {
    background-color: $color-flow-blue-200;
  }

  &:first-child::before {
    margin-left: 0;
  }

  &::before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 10px;
    border: solid 1px $color-gray-900;
  }
`;

export const clearIcon = css`
  color: $color-green-300;
  margin-right: 10px;
`;

export const loadingIcon = css`
  margin-left: 16px;
`;

export const totalEvents = css`
  margin-left: 50px;
`;

export const cardContent = css`
  padding-top: 16px;
`;

export const metricContainer = css`
  width: calc(33% - 6px);
  width: 30%;
`;

export const processingItemsNumber = css`
  color: #D57C2C;
  font-size: 32px;
  font-weight: 300;
  line-height: 38px;
  padding-bottom: 8px;
`;

export const unprocessedItemsNumber = css`
  color: #37A9DD;
  font-size: 32px;
  font-weight: 300;
  line-height: 38px;
  padding-bottom: 8px;
`;

export const totalItemsNumber = css`
  font-size: 32px;
  font-weight: 300;
  line-height: 38px;
  padding-bottom: 8px;
`;

export const unprocessedDescription = css`
  color: #37A9DD;
  font-size: 14px;
  line-height: 16px;
`;

export const processingDescription = css`
  color: #D57C2C;
  font-size: 14px;
  line-height: 16px;
`;

export const totalDescription = css`
  font-size: 14px;
  line-height: 16px;
`;

export const overviewContainer = css`
  padding-bottom: 12px;
`;
