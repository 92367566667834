enum FormName {
  ADD_FEATURE_FORM = 'addFeatureForm',
  ADD_RULE_FORM = 'addRuleForm',
  DELETE_FEATURE_FORM = 'deleteFeatureForm',
  DELETE_RULE_FORM = 'deleteRuleForm',
  EDIT_FEATURE_FORM = 'editFeatureForm',
  EDIT_RULE_FORM = 'editRuleForm',
  FEATURE_LIST_FILTER_FORM = 'featureListFilterForm',
  RELEASE_FEATURE_FORM = 'releaseFeatureForm',
}

export default FormName;
