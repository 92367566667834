import { css } from '@emotion/css';
import colors from '../../../../theme/tokens/colors';

export const subText = css`
  color: ${colors.gray[800]};
  font-size: 12px;
  padding-left: 23px;
  padding-top: 4px;
`;

export const additionalSettingsContainer = css`
  padding-top: 24px;
  padding-bottom: 24px;
`;

export const availabilityCheckbox = css`
  margin-right: 7px;
  margin-bottom: 3px;
`;
