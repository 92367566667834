import noop from 'lodash/noop';

export default class LocalStorageMap<T> {
  private storeKey: string;

  private map: Record<string, T>;

  constructor(key: string) {
    this.storeKey = key;
    this.map = LocalStorageMap.getStore<T>(key);
  }

  static getStore<T>(key: string): Record<string, T> {
    try {
      return JSON.parse(window.localStorage.getItem(key) || '{}');
    } catch (_e) {
      return {};
    }
  }

  static setStore<T>(key: string, value: Record<string, T>): void {
    try {
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (_e) { noop(); }
  }

  public getValue = (key: string): T | undefined => this.map[key];

  public setValue = (key: string, value: T): void => {
    this.map[key] = value;
    LocalStorageMap.setStore<T>(this.storeKey, this.map);
  };
}
