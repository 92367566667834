import { css } from '@emotion/react';
import React from 'react';
import { Tooltip } from '@flowio/react-tooltip';

type OwnProps = {
  position?: 'top' | 'bottom' | 'right' | 'left';
};

type InheritedProps = Omit<React.ComponentProps<typeof Tooltip>, keyof OwnProps> & OwnProps;

const TOOLTIP_WIDTH = '6px';
const TOOLTIP_BG_COLOUR = 'rgba(12,45,83,0.9)';

const tooltipStyles = ({ position }: OwnProps) => {
  const isVertical = position === 'top' || position === 'bottom';

  return css`
    background-color: ${TOOLTIP_BG_COLOUR};
    position: relative;
    overflow: visible;

    &:after {
      content: '';
      position: absolute;
      ${isVertical ? 'left' : 'top'}: calc(50% - 6px);
      border: ${TOOLTIP_WIDTH} solid transparent;
      ${position}: 100%;
      border-${position}: ${TOOLTIP_WIDTH} solid ${TOOLTIP_BG_COLOUR};
    }
  `;
};

const MarketingTooltip: React.FC<InheritedProps> = ({
  children,
  position = 'bottom',
  ...otherProps
}) => (
  <Tooltip
    css={tooltipStyles({ position })}
    position={position}
    {...otherProps}
  >
    {children}
  </Tooltip>
);

MarketingTooltip.displayName = 'MarketingTooltip';

export default MarketingTooltip;
