import React from 'react';
import Alert from './alert';

const displayName = 'ErrorAlert';

const ErrorAlert: React.FC<{}> = (props) => (<Alert {...props} type="failure" />);

ErrorAlert.displayName = displayName;

export default ErrorAlert;
