import range from 'lodash/range';
import d3, { scaleOrdinal } from 'd3';
import colors from '../theme/tokens/colors';

const { chart: { lineChartColorScale } } = colors;

export default function generateColorScale(): d3.ScaleOrdinal<number, string> {
  return scaleOrdinal<number, string>()
    .range(lineChartColorScale)
    .domain(range(18));
}
