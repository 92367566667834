import isEqual from 'lodash/isEqual';
import { $HttpResponse } from '@flowio/api-sdk';
import ActionTypes from '../constants/action-types';
import ReadyStates from '../constants/ready-states';
import { getOrganizationCountriesOrganization, getOrganizationCountriesReadyState } from '../selectors';
import {
  RootActionTypes,
  ThunkResult,
  ThunkExtraArguments,
  RootState,
} from '../../../stores/types';
import { ApiError } from '../../../utilities/clients/api-error';

export function fetchOrganizationCountriesRequest(): RootActionTypes {
  return {
    type: ActionTypes.FETCH_ORGANIZATION_COUNTRIES_REQUEST,
  };
}

export function fetchOrganizationCountriesSuccess(
  countries: io.flow.v0.models.Country[],
): RootActionTypes {
  return {
    type: ActionTypes.FETCH_ORGANIZATION_COUNTRIES_SUCCESS,
    payload: countries,
  };
}

export function fetchOrganizationCountriesFailure(
  error?: io.flow.v0.models.GenericError,
): RootActionTypes {
  return {
    type: ActionTypes.FETCH_ORGANIZATION_COUNTRIES_FAILURE,
    payload: error,
  };
}

export default function fetchOrganizationCountries(
  organization: string,
): ThunkResult<Promise<void>> {
  return function fetchOrganizationCountriesEffect(
    dispatch,
    getState,
    extra: ThunkExtraArguments,
  ): Promise<void> {
    const state: RootState = getState();
    const readyState = getOrganizationCountriesReadyState(state);
    const organizationCountriesOrganization = getOrganizationCountriesOrganization(state);

    if (!isEqual({
      readyState,
      organization: organizationCountriesOrganization,
    }, {
      readyState: ReadyStates.FULFILLED,
      organization,
    })) {
      dispatch(fetchOrganizationCountriesRequest());
      return extra.api(state).organizations.getCountriesByOrganization({
        organization,
      }).then((response) => {
        if (response.ok) {
          dispatch(fetchOrganizationCountriesSuccess(response.body));
          return;
        }
        // TODO conversion-revisit
        const error = new ApiError(
          response as $HttpResponse<io.flow.v0.models.GenericError>,
        );
        dispatch(fetchOrganizationCountriesFailure(response.body));
        throw error;
      });
    }

    return Promise.resolve();
  };
}
