import { RootActionTypes } from '../../../stores/types';
import { ActionTypes } from '../constants';

export default function updateDialog(dialogType: string, dialogProps?: any): RootActionTypes {
  return {
    type: ActionTypes.UPDATE_DIALOG,
    payload: {
      type: dialogType,
      props: dialogProps,
    },
  };
}
