import ActionTypes from '../constants/action-types';

import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';

const createRequestAction = (): RootActionTypes => ({
  type: ActionTypes.FETCH_ORDER_VERSIONS_REQUEST,
});

const createSuccessAction = (payload: io.flow.v0.models.OrderVersion[]): RootActionTypes => ({
  type: ActionTypes.FETCH_ORDER_VERSIONS_SUCCESS,
  payload,
});

const createErrorAction = (error: FormattedErrorMessages): RootActionTypes => ({
  type: ActionTypes.FETCH_ORDER_VERSIONS_FAILURE,
  payload: error,
  error: true,
});

export default function fetchOrderVersions(
  organizationId: string,
  number: string,
  submittedAt?: string,
): ThunkResult<Promise<void>> {
  return (dispatch, getState, extra): Promise<void> => {
    dispatch(createRequestAction());
    return extra.api(getState()).orders.getVersions({
      organization: organizationId,
      number: [number],
      journal_timestamp_on_or_after: submittedAt,
    }).then((response) => {
      if (response.ok) {
        dispatch(createSuccessAction(response.body));
        return;
      }

      const errors = formatErrorResponseV2(response);
      dispatch(createErrorAction(errors));
    });
  };
}
