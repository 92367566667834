import { actionTypes } from '@flowio/redux-resource';
import { ResponseError } from '@flowio/redux-async-middleware';

/**
 * Create FSA compliant type descriptors for @flowio/redux-async-middleware
 * compatible with @flowio/node-sdk and @flowio/redux-resource
 * @param {object} metadata
 * An object describing the meta properties required by @flowio/redux-resource
 * @example
 * import { operationTypes } from '@flowio/redux-resource';
 * createResourceTypeDescriptors({
 *  operationType: operationTypes.READ,
 *  resourceType: 'articles',
 * });
 */
export default function createResourceTypeDescriptors(metadata) {
  return [{
    type: actionTypes.REQUEST_PENDING,
    meta: metadata,
  }, {
    type: actionTypes.REQUEST_FULFILLED,
    meta: metadata,
    payload: (state, response) => ({
      resources: Array.isArray(response.result)
        ? response.result
        : [response.result],
    }),
  }, {
    type: actionTypes.REQUEST_REJECTED,
    meta: metadata,
    payload: (state, response) => new ResponseError(
      response.status,
      response.statusText,
      response.result,
    ),
  }];
}
