import { DeliveredDutyPreferencesPutResponse } from '@flowio/api-internal-sdk';
import ActionTypes from '../constants/action-types';
import {
  ThunkExtraArguments,
  RootState,
  ThunkDispatcher,
  ThunkResult,
} from '../../../stores/types';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import toLegacyResponse from '../../../utilities/clients/to-legacy-response';
import { PropType } from '../../../types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';

type ResponseType = PropType<DeliveredDutyPreferencesPutResponse, 'body'>;

export default function updateDeliveredDutyPreference(
  organizationId: string,
  experienceKey: string,
  deliveredDutyPreferenceForm: io.flow.internal.v0.models.DeliveredDutyPreferenceForm,
): ThunkResult<Promise<LegacyResponse<ResponseType>>> {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<LegacyResponse<ResponseType>> => {
    dispatch({
      type: ActionTypes.UPDATE_DELIVERED_DUTY_PREFERENCE_REQUEST,
    });

    return extra.apiInternal(getState()).deliveeredDutyPreferences.put({
      organization: organizationId,
      key: experienceKey,
      body: deliveredDutyPreferenceForm,
    }).then((response) => {
      if (response.ok) {
        dispatch({
          type: ActionTypes.UPDATE_DELIVERED_DUTY_PREFERENCE_SUCCESS,
          payload: response.body,
        });
      } else {
        dispatch({
          type: ActionTypes.UPDATE_DELIVERED_DUTY_PREFERENCE_FAILURE,
          payload: formatErrorResponseV2(response),
        });
      }
      return toLegacyResponse<ResponseType>(response);
    });
  };
}
