import { createSelector } from 'reselect';
import { LaneDirection } from '@flowio/api-constants';
import filter from 'lodash/fp/filter';

import getRatecards from './getRatecards';

export default createSelector(
  getRatecards,
  filter({ direction: LaneDirection.OUTBOUND }),
);
