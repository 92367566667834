import { compose } from 'redux';
import { InjectedFormProps, reduxForm, SubmissionError } from 'redux-form';
import { FeaturesPutReleaseByKeyResponse } from '@flowio/api-internal-sdk';
import FeatureReleaseConfirmForm from '../components/FeatureReleaseConfirmForm';

import createToast from '../../console/actions/createToast';
import {
  releaseFeatureByKey,
  hideReleaseFeatureConfirm,
} from '../actions';
import FormName from '../constants/FormName';
import isResponseOk from '../../../utilities/isResponseOk';
import { FeatureReleaseConfirmValues, FeatureReleaseConfirmFormOwnProps } from '../types/components';
import { ThunkDispatcher } from '../../../stores/types';

function submit(values: FeatureReleaseConfirmValues, dispatch: ThunkDispatcher) {
  const { organizationId, featureKey } = values;
  return dispatch(releaseFeatureByKey(organizationId, featureKey))
    .then((response: FeaturesPutReleaseByKeyResponse) => {
      if (isResponseOk(response)) return response;
      throw new SubmissionError({ _error: response.body });
    });
}

function handleSubmitSuccess(
  _: any,
  dispatch: ThunkDispatcher,
  props: InjectedFormProps<FeatureReleaseConfirmValues, {}, string>,
) {
  const { initialValues } = props;
  const { featureName } = initialValues;
  dispatch(hideReleaseFeatureConfirm());
  dispatch(createToast({
    content: `Feature \`${featureName}\` has been released.`,
    icon: 'Plane',
    intent: 'info',
  }));
}

export default compose<React.FC<FeatureReleaseConfirmFormOwnProps>>(
  reduxForm({
    form: FormName.RELEASE_FEATURE_FORM,
    onSubmit: submit,
    onSubmitSuccess: handleSubmitSuccess,
  }),
)(FeatureReleaseConfirmForm);
