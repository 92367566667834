import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@flowio/react-card';
import React from 'react';
import ClassificationSummaryChart from './summary-classification-chart';
import { SummaryChartsPropTypes } from './types';
import * as styles from './summary-charts.styles';

const ClassificationSummaryCharts: React.FC<SummaryChartsPropTypes> = ({
  statistics,
  onLegendClick,
  currentQuery,
}) => (
  <div className={styles.classificationSummaryCharts}>
    <Card>
      <CardContent fitted>
        <CardHeader>
          <CardTitle>
            Classification Status
          </CardTitle>
        </CardHeader>

        <div>
          <ClassificationSummaryChart
            data={statistics}
            onLegendClick={onLegendClick}
            currentQuery={currentQuery}
          />
        </div>
      </CardContent>
    </Card>
  </div>
);

ClassificationSummaryCharts.displayName = 'ClassificationSummaryCharts';

export default ClassificationSummaryCharts;
