import React from 'react';
import { InjectedRouter } from 'react-router';
import { NavLink } from '@flowio/react-nav';
import ConsoleNavItem from '../components/ConsoleNavItem';
import ConsoleNavItemGroup from '../components/ConsoleNavItemGroup';

type NavProps = {
  href?: string;
  icon?: string;
  text: string;
  disabled?: boolean;
  external?: boolean;
  defaultExpanded?: boolean;
  rel?: string;
  links?: NavProps[];
};

export default function createRoutesFactory(
  router: InjectedRouter,
): (links: NavProps[]) => React.ReactElement[] {
  function linkGenerator(links: NavProps[]) {
    return links
      .filter((link) => !link.disabled)
      .map((link) => {
        const { href, ...props } = link;
        const Element = link.external === true ? NavLink : ConsoleNavItem;

        if (link.links) {
          return (
            <ConsoleNavItemGroup
              header={link.text}
              icon={link.icon}
              defaultExpanded={link.defaultExpanded}
            >
              {linkGenerator(link.links)}
            </ConsoleNavItemGroup>
          );
        }

        return (
          <Element
            key={href}
            href={href}
            target={link.external ? '_blank' : undefined}
            selected={!!href && router.isActive(href, true)}
            {...props}
          />
        );
      });
  }
  return linkGenerator;
}
