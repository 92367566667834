import isArray from 'lodash/isArray';

export default function toQueryBuilderFilterForm(
  f: io.flow.v0.unions.QueryFilterForm | io.flow.v0.unions.QueryFilterForm[],
): io.flow.v0.models.QueryBuilderFilterForm {
  const filters = isArray(f) ? f : [f];
  return {
    discriminator: 'filter',
    filters,
  };
}
