import { css } from '@emotion/react';
import React from 'react';
import { Button } from '@flowio/react-button';
import { Trophy } from '@flowio/react-icons';
import { colors } from '../../../../../theme/tokens';

const variantBtnStyles = css`
  padding: 10px;
  background: ${colors.chart.green};
  color: white;
  pointer-events: none;
`;

const WinnerBadge: React.FC = () => (
  <Button
    css={variantBtnStyles}
    leftIcon={Trophy}
    content="Winning Variant"
    size="small"
    type="button"
  />
);

export default WinnerBadge;
