import React from 'react';
import classNames from 'classnames/bind';

import styles from './QueryFilterToken.module.css';

const cx = classNames.bind(styles);

type Props = {
  className?: string;
  queryFilter: io.flow.v0.unions.QueryFilter;
};

const QueryFilterToken: React.FC<Props> = ({
  className,
  queryFilter,
}) => {
  if (queryFilter.discriminator === 'unstructured') {
    return (
      <div className={cx('token', className)}>
        <div className={cx('values')}>
          {queryFilter.q}
        </div>
      </div>
    );
  }

  return (
    <div className={cx('token', className)}>
      <div className={cx('field')}>
        {queryFilter.field}
      </div>
      <div className={cx('operator')}>
        {queryFilter.operator}
      </div>
      <div className={cx('values')}>
        {queryFilter.values && (
          <div className={cx('truncated')}>
            {queryFilter.values.join(', ')}
          </div>
        )}
      </div>
    </div>
  );
};

QueryFilterToken.displayName = 'QueryFilterToken';

export default QueryFilterToken;
