import PropTypes from 'prop-types';
import React from 'react';
import { Box } from '@flowio/react-box';
import { Grid, Column, Row } from '@flowio/react-grid';
import { RadioButtonGroup, RadioButton } from '@flowio/react-radio-button';
import { DeliveredDuty as DeliveredDuties } from '@flowio/api-constants';

import CurrencyConversion from '../currency-conversion';
import * as styles from './LandedCostSinglePreview.styles';

interface Props {
  customerChoice: boolean;
  deliveredDuty: io.flow.v0.enums.DeliveredDuty;
  experienceKey: string;
}

const LandedCostSinglePreview: React.FC<Props> = ({
  customerChoice,
  deliveredDuty,
  experienceKey,
}) => (
  <Grid>
    <Row>
      <Column span={12}>
        <div className={styles.preview}>
          <div className={styles.content}>
            <div className={styles.watermark}>Example Only</div>
            <section className={styles.section}>
              <h2 className={styles.heading}>Duties and Taxes</h2>
              {deliveredDuty === DeliveredDuties.PAID && (
                <p className={styles.deliveredDuty}>
                  <strong>Duties &amp; Taxes Paid:</strong>
                  {' '}
                  You will pay
                  duties &amp; taxes with your order.
                </p>
              )}
              {deliveredDuty === DeliveredDuties.UNPAID && (
                <p className={styles.deliveredDuty}>
                  <strong>Duties &amp; Taxes Unpaid:</strong>
                  {' '}
                  You will pay
                  duties &amp; taxes at delivery.
                </p>
              )}
              {customerChoice && (
                <p className={styles.customerChoice}>
                  Edit Duties &amp; Taxes Preference
                </p>
              )}
            </section>
            <section className={styles.section}>
              <h2 className={styles.heading}>Shipping Method</h2>
              <RadioButtonGroup defaultValue="opt-a8a725c6dc2240e8997259fea586992d">
                <Box direction="column" spacing="loose">
                  <RadioButton value="opt-a8a725c6dc2240e8997259fea586992d">
                    <Box className={styles.radioLabel} inline alignItems="center" justifyContent="between">
                      <span>Standard Delivery (7-13 Business Days)</span>
                      <CurrencyConversion amount="0.00" base="USD" experienceKey={experienceKey} />
                    </Box>
                  </RadioButton>
                  <RadioButton value="opt-25591f241ab24502a12be7718a23d0be">
                    <Box className={styles.radioLabel} inline alignItems="center" justifyContent="between">
                      <span>Express Delivery (2-5 Business Days)</span>
                      <CurrencyConversion amount="25.00" base="USD" experienceKey={experienceKey} />
                    </Box>
                  </RadioButton>

                </Box>
              </RadioButtonGroup>
            </section>
          </div>
          <p className={styles.disclaimer}>
            <strong>Preview at Checkout</strong>
            : Above is what your checkout
            might look like with the current landed cost configuration.
            Your actual interface may differ.
          </p>
        </div>
      </Column>
    </Row>
  </Grid>
);

LandedCostSinglePreview.displayName = 'LandedCostSinglePreview';

LandedCostSinglePreview.propTypes = {
  customerChoice: PropTypes.bool.isRequired,
  deliveredDuty: PropTypes.oneOf([
    DeliveredDuties.PAID,
    DeliveredDuties.UNPAID,
  ]).isRequired,
  experienceKey: PropTypes.string.isRequired,
};

export default LandedCostSinglePreview;
