import { connect } from 'react-redux';
import { withFetch } from '@flowio/redux-fetch';
import { fetchOrganization } from '../../organization/actions';
import fetchCheckoutConfigurations from '../actions';
import { getCheckoutConfigurationIds } from '../selectors';
import CheckoutConfigrationComponent, { OwnProps, StateProps } from '../components/checkout-configuration';
import { ThunkDispatcher, RootState } from '../../../stores/types';

const fetchAsyncState = (
  dispatch: ThunkDispatcher,
  _getState: () => RootState,
  ownProps: OwnProps,
): Promise<unknown> => {
  const { organization } = ownProps.params;

  const promises: [
    Promise<unknown>,
    Promise<undefined | io.flow.internal.v0.models.CheckoutConfiguration[]>,
  ] = [
    dispatch(fetchOrganization(organization)),
    dispatch(fetchCheckoutConfigurations(organization)),
  ];

  return Promise.all(promises);
};

function mapStateToProps(state: RootState): StateProps {
  return {
    configurationIds: getCheckoutConfigurationIds(state),
  };
}

const ConnectedCheckoutConfiguration = withFetch(fetchAsyncState)(
  connect(mapStateToProps)(CheckoutConfigrationComponent),
);

export default ConnectedCheckoutConfiguration;
