import { type ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { type FormattedErrorMessages } from '../../../utilities/format-error-response-v2';

type FetchShippingLabelActionParams = {
  organizationId: string;
  labelId: string
};

type FetchShippingLabelActionRequest = {
  type: 'FETCH_SHIPPING_LABEL_REQUEST';
  params: FetchShippingLabelActionParams;
};

type FetchShippingLabelActionSuccess = {
  type: 'FETCH_SHIPPING_LABEL_SUCCESS';
  params: FetchShippingLabelActionParams;
  payload: io.flow.v0.models.ShippingLabel;
};

type FetchShippingLabelActionFailure = {
  type: 'FETCH_SHIPPING_LABEL_FAILURE';
  params: FetchShippingLabelActionParams;
  errors: FormattedErrorMessages;
};

export type FetchShippingLabelAction =
  | FetchShippingLabelActionRequest
  | FetchShippingLabelActionSuccess
  | FetchShippingLabelActionFailure;

export default function fetchShippingLabel(
  params: FetchShippingLabelActionParams,
): ThunkResult<Promise<void>> {
  return (
    dispatch,
    getState,
    client,
  ): Promise<void> => {
    dispatch({
      type: 'FETCH_SHIPPING_LABEL_REQUEST',
      params,
    });
    return client.api(getState()).shippingLabels.getById({
      organization: params.organizationId,
      id: params.labelId,
    }).then((response) => {
      if (response.ok) {
        dispatch({
          type: 'FETCH_SHIPPING_LABEL_SUCCESS',
          params,
          payload: response.body,
        });
        return undefined;
      }

      dispatch({
        type: 'FETCH_SHIPPING_LABEL_FAILURE',
        params,
        errors: formatErrorResponseV2(response),
      });
      return undefined;
    }).catch((e: unknown) => {
      dispatch({
        type: 'FETCH_SHIPPING_LABEL_FAILURE',
        params,
        errors: {
          messages: [{
            message: e instanceof Error ? e.message : 'Unknown error',
            code: 'FETCH_ERROR',
          }],
        },
      });
    });
  };
}
