import { Ellipsis, Lens, Plus } from '@flowio/react-icons';
import React, { Component } from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import get from 'lodash/get';
import ApiPropTypes from '@flowio/api-prop-types';
import {
  Card, CardHeader, CardContent, CardFooter, CardEmptyState, CardTitle,
} from '@flowio/react-card';
import { Box } from '@flowio/react-box';
import {
  Table,
  TableHead,
  TableRow, TableRowColumn,
  TableHeadColumn,
  TableBody,
} from '@flowio/react-table';
import {
  Page,
  PageContent,
  PageHeader,
  PageTitle,
  PageActions,
  PagePropertyList,
} from '@flowio/react-page';

import { Breadcrumbs } from '@flowio/react-breadcrumbs';
import { Button } from '@flowio/react-button';
import {
  Dropdown, DropdownMenu, DropdownItem, DropdownDivider,
} from '@flowio/react-dropdown';
import classNames from 'classnames/bind';
import numeral from 'numeral';

import styles from './PriceBookDetails.module.css';
import Pagination from '../../../../components/pagination';
import DeletePriceBookConfirm from '../../containers/DeletePriceBookConfirm';
import ExportPriceBookConfirm from '../../containers/ExportPriceBookConfirm';
import UpsertPriceBookWorksheet from '../../containers/UpsertPriceBookWorksheet';
import PriceBookImport from '../../containers/PriceBookImport';

const cx = classNames.bind(styles);

class PriceBookDetails extends Component {
  handleRequestNextPage = () => {
    const {
      onRequestPage,
      pageNumber,
    } = this.props;

    onRequestPage(parseInt(pageNumber, 10) + 1);
  }

  handleRequestPreviousPage = () => {
    const {
      onRequestPage,
      pageNumber,
    } = this.props;

    onRequestPage(parseInt(pageNumber, 10) - 1);
  }

  editPriceBook = () => {
    const {
      onEditPriceBook,
      priceBook,
    } = this.props;

    onEditPriceBook(priceBook.id);
  }

  render() {
    const {
      currencies,
      priceBook,
      priceBookItems,
      lastPage,
      firstPage,
      organization,
      onEditPriceBook,
      onDeletePriceBook,
      onExportPriceBook,
      onUploadPriceBook,
      email,
      priceBookExportTypes,
    } = this.props;

    let taxAndDutyMessage;

    switch (get(priceBook, 'includes.key')) {
      case 'duty':
        taxAndDutyMessage = 'Duty Only';
        break;
      case 'vat':
        taxAndDutyMessage = 'Tax Only';
        break;
      case 'vat_and_duty':
        taxAndDutyMessage = 'Included';
        break;
      default:
        taxAndDutyMessage = 'Excluded';
        break;
    }

    return (
      <Page>
        <PageHeader>
          <Breadcrumbs items={[{ content: 'Price Books', href: `/${organization.id}/price-books` }]} />
          <Box justifyContent="between" alignItems="center">
            <PageTitle content={priceBook.name} />
            <PageActions>
              <Box>
                <Button
                  className={cx('uploadBtn')}
                  content="Upload Prices via CSV"
                  intent="primary"
                  leftIcon={Plus}
                  onClick={() => onUploadPriceBook(organization.id)}
                />
                <Button
                  className={cx('exportBtn')}
                  type="button"
                  content="Export Price Book"
                  onClick={() => onExportPriceBook(organization.id, priceBook, email)}
                />
                <Dropdown
                  openOnClick
                  trigger={<Button leftIcon={Ellipsis} />}
                >
                  <DropdownMenu>
                    <DropdownItem
                      content="Edit"
                      onClick={() => {
                        onEditPriceBook(organization.id, priceBook);
                      }}
                    />
                    <DropdownDivider />
                    <DropdownItem
                      content="Delete"
                      onClick={() => {
                        onDeletePriceBook(organization.id, priceBook);
                      }}
                    />
                  </DropdownMenu>
                </Dropdown>
              </Box>
            </PageActions>
          </Box>
          <PagePropertyList>
            <div className={cx('prop')}>
              <dt className={cx('propertyLabel')}>Currency</dt>
              <dd className={cx('propertyValue')}>{priceBook.currency}</dd>
            </div>
            <div className={cx('prop')}>
              <dt className={cx('propertyLabel')}>Tax and Duty</dt>
              <dd className={cx('propertyValue')}>{taxAndDutyMessage}</dd>
            </div>
            <div className={cx('prop')}>
              <dt className={cx('propertyLabel')}>Status</dt>
              <dd className={cx('propertyValue')}>
                <Lens className={cx('statusIcon', priceBook.status)} />
                <span className={cx(priceBook.status)}>{priceBook.status}</span>
              </dd>
            </div>
          </PagePropertyList>
        </PageHeader>
        <PageContent>
          <Card>
            <CardHeader dividing>
              <Box justifyContent="between" alignItems="center">
                <CardTitle content="Prices" />
              </Box>
            </CardHeader>
            <CardContent fitted>
              {!isEmpty(priceBookItems) && (
                <Table striped className={cx('itemTable')}>
                  <TableHead>
                    <TableRow>
                      <TableHeadColumn>Number</TableHeadColumn>
                      <TableHeadColumn className={cx('rightAlign')}>Price ({priceBook.currency})</TableHeadColumn>
                      <TableHeadColumn className={cx('rightAlign')}>Compare at Price ({priceBook.currency})</TableHeadColumn>
                      <TableHeadColumn>Start</TableHeadColumn>
                      <TableHeadColumn>End</TableHeadColumn>
                      <TableHeadColumn>Status</TableHeadColumn>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {priceBookItems.map((item, index) => {
                      const startsAt = get(item, 'schedule.starts_at');
                      const endsAt = get(item, 'schedule.ends_at');
                      const isAfterStart = startsAt
                        ? moment.utc(Date.now()).isAfter(startsAt) : true;
                      const isBeforeEnd = endsAt ? moment.utc(Date.now()).isBefore(endsAt) : true;
                      const isActive = isAfterStart && isBeforeEnd;
                      const isUpcoming = !isAfterStart;

                      return (
                        <TableRow key={item.id} striped={index % 2 === 1}>
                          <TableRowColumn>
                            <Link to={`/${organization.id}/catalog/items/${item.item_number}`}>{item.item_number}</Link>
                          </TableRowColumn>
                          <TableRowColumn className={cx('rightAlign')}>{numeral(item.price.amount).format('0,0.[00]')}</TableRowColumn>
                          <TableRowColumn className={cx('rightAlign')}>{get(item, 'item_attributes.compare_at') ? numeral(item.item_attributes.compare_at).format('0,0.[00]') : '-'}</TableRowColumn>
                          <TableRowColumn>{get(item, 'schedule.starts_at') ? moment.utc(item.schedule.starts_at).format('MM/DD/YYYY [at] H:mm z') : '-'}</TableRowColumn>
                          <TableRowColumn>{get(item, 'schedule.ends_at') ? moment.utc(item.schedule.ends_at).format('MM/DD/YYYY [at] H:mm z') : '-'}</TableRowColumn>
                          <TableRowColumn>
                            {isActive && (
                              <span className={cx('activeStatus')}>Active</span>
                            )}
                            {!isActive && !isUpcoming && (
                              <span className={cx('inactiveStatus')}>Expired</span>
                            )}
                            {isUpcoming && (
                              <span className={cx('upcomingStatus')}>Upcoming</span>
                            )}
                          </TableRowColumn>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              )}
              {isEmpty(priceBookItems) && (<CardEmptyState content="No items in price book" />)}
            </CardContent>
            <CardFooter>
              <Pagination
                isNextPageDisabled={lastPage}
                isPreviousPageDisabled={firstPage}
                onRequestNextPage={this.handleRequestNextPage}
                onRequestPreviousPage={this.handleRequestPreviousPage}
              />
            </CardFooter>
          </Card>
          <DeletePriceBookConfirm />
          <PriceBookImport />
          <UpsertPriceBookWorksheet currencies={currencies} />
          <ExportPriceBookConfirm priceBookExportTypes={priceBookExportTypes} />
        </PageContent>
      </Page>
    );
  }
}

PriceBookDetails.propTypes = {
  organization: ApiPropTypes.organization.isRequired,
  priceBook: ApiPropTypes.priceBook.isRequired,
  currencies: PropTypes.arrayOf(ApiPropTypes.currency).isRequired,
  priceBookItems: PropTypes.arrayOf(ApiPropTypes.priceBookItem).isRequired,
  onEditPriceBook: PropTypes.func.isRequired,
  onDeletePriceBook: PropTypes.func.isRequired,
  onExportPriceBook: PropTypes.func.isRequired,
  onUploadPriceBook: PropTypes.func.isRequired,
  lastPage: PropTypes.bool.isRequired,
  firstPage: PropTypes.bool.isRequired,
  onRequestPage: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  priceBookExportTypes: PropTypes.arrayOf(ApiPropTypes.itemIdentifier).isRequired,
};

export default PriceBookDetails;
