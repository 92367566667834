import { $HttpResponse } from '@flowio/api-internal-sdk';
import { getOrganizationId } from '../../organization';

import { OrderRefundRequest, OrderRefundSuccess, OrderRefundFailure } from '../types';
import ActionTypes from '../constants/action-types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import { ThunkResult } from '../../../stores/types';
import { createToast } from '../../console/actions';
import hideRefundFormDialog from './hide-refund-form-dialog';
import setNotification from './set-notification';

function createRefundRequest(): OrderRefundRequest {
  return {
    type: ActionTypes.CREATE_REFUND_REQUEST,
  };
}

function createRefundSuccess(payload: io.flow.v0.models.Refund): OrderRefundSuccess {
  return {
    type: ActionTypes.CREATE_REFUND_SUCCESS,
    payload,
  };
}

function createRefundFailure(formattedErrorMessages: FormattedErrorMessages): OrderRefundFailure {
  return {
    type: ActionTypes.CREATE_REFUND_FAILURE,
    error: true,
    payload: formattedErrorMessages,
  };
}

export default function createRefund(
  formData: io.flow.v0.models.RefundForm,
): ThunkResult<Promise<void>> {
  return function createRefundEffects(
    dispatch,
    getState,
    extra,
  ): Promise<void> {
    const state = getState();
    dispatch(createRefundRequest());

    return extra.api(state).refunds.post({
      body: formData,
      organization: getOrganizationId(state),
    }).then((response) => {
      if (response.ok) {
        dispatch(createRefundSuccess(response.body));
        dispatch(hideRefundFormDialog());
        dispatch(setNotification('Refund has been processed. It will takes 5-10 business days to reflect on your customer’s statement.'));
        return;
      }

      const errorResponse = response as $HttpResponse<io.flow.v0.models.GenericError>;
      dispatch(createRefundFailure(formatErrorResponseV2(errorResponse)));
      dispatch(createToast({
        intent: 'negative',
        content: 'Error submitting refund please try again later',
      }));
    });
  };
}
