import get from 'lodash/get';

import tierRuleQueryToSentence from './tierRuleQueryToSentence';
import tierRuleOutcomeToSentence from './tierRuleOutcomeToSentence';

export default function tierRuleToSentence(
  tierRule: io.flow.v0.models.TierRule,
  tier: io.flow.v0.models.Tier,
) {
  return `${tierRuleQueryToSentence(tierRule, get(tier, 'currency'))}, ${tierRuleOutcomeToSentence(tierRule)}`;
}
