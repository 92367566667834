import { Dispatch } from 'redux';
import { $HttpResponse } from '@flowio/api-sdk';
import { ActionTypes } from '../constants';
import withPagingAndSorting, { isPagingAndSortingResponse, WithPagingAndSortingOptions } from '../../../utilities/with-paging-and-sorting-v2';
import {
  ThunkResult, RootState, ThunkExtraArguments,
} from '../../../stores/types';
import { WithPagingAndSortingResponse, WithPagingAndSortingResult } from '../../../utilities/types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';

export default function fetchItemSummaries(
  organization: string,
  options: WithPagingAndSortingOptions = {},
): ThunkResult<Promise<
  WithPagingAndSortingResult<io.flow.internal.v0.models.ItemClassificationSummary[]>
  | io.flow.v0.models.GenericError | void
  >> {
  return (
    dispatch: Dispatch,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<
  WithPagingAndSortingResult<io.flow.internal.v0.models.ItemClassificationSummary[]>
  | io.flow.v0.models.GenericError | void
  > => {
    const state = getState();

    const optionsWithPageNumber = {
      ...options,
      pageNumber: state.classification.hs6.pageNumber,
    };

    dispatch({
      params: optionsWithPageNumber,
      type: ActionTypes.FETCH_ITEM_SUMMARIES_REQUEST,
    });

    return withPagingAndSorting<io.flow.internal.v0.models.ItemClassificationSummary[]>(
      optionsWithPageNumber,
      (params) => extra.apiInternal(state)
        .itemClassificationSummaries.get({ organization, ...params }),
    )().then((
      response: WithPagingAndSortingResponse<io.flow.internal.v0.models.ItemClassificationSummary[]>
      | $HttpResponse<io.flow.v0.models.GenericError>,
    ) => {
      if (
        isPagingAndSortingResponse<io.flow.internal.v0.models.ItemClassificationSummary[]>(response)
      ) {
        dispatch({
          params: optionsWithPageNumber,
          type: ActionTypes.FETCH_ITEM_SUMMARIES_SUCCESS,
          payload: response.result,
        });
        return response.result;
      }

      dispatch({
        params: optionsWithPageNumber,
        type: ActionTypes.FETCH_ITEM_SUMMARIES_FAILURE,
        payload: formatErrorResponseV2(response),
      });
      return response.body;
    });
  };
}
