import update from 'immutability-helper';

import ActionTypes from '../../constants/action-types';
import { RootActionTypes } from '../../../../stores/types';
import { FeatureValuesState, FeatureValueStatus } from '../../types/state';

const initialState: FeatureValuesState = {
  status: FeatureValueStatus.pending,
  entities: [],
};

export default function featureValuesReducer(
  state = initialState,
  action: RootActionTypes,
): FeatureValuesState {
  switch (action.type) {
    case ActionTypes.CHECK_FEATURE_VALUES_REQUEST:
      return update(state, {
        status: { $set: FeatureValueStatus.loading },
      });
    case ActionTypes.CHECK_FEATURE_VALUES_SUCCESS:
      return update(state, {
        status: { $set: FeatureValueStatus.fulfilled },
        entities: { $set: action.payload.values },
      });
    case ActionTypes.CHECK_FEATURE_VALUES_FAILURE:
      return update(state, {
        status: { $set: FeatureValueStatus.rejected },
      });
    case ActionTypes.INVALIDATE_FEATURE_VALUES:
      return update(state, {
        status: { $set: FeatureValueStatus.invalidated },
      });
    default:
      return state;
  }
}

// export default createReducer(initialState, {
//   [ActionTypes.CHECK_FEATURE_VALUES_REQUEST]: (state) => update(state, {
//     status: { $set: 'loading' },
//   }),
//   [ActionTypes.CHECK_FEATURE_VALUES_SUCCESS]: (state, action) => update(state, {
//     status: { $set: 'fulfilled' },
//     entities: { $set: action.payload.values },
//   }),
//   [ActionTypes.CHECK_FEATURE_VALUES_FAILURE]: (state) => update(state, {
//     status: { $set: 'rejected' },
//   }),
//   [ActionTypes.INVALIDATE_FEATURE_VALUES]: (state) => update(state, {
//     status: { $set: 'invalidated' },
//   }),
// });
