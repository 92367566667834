import { Ellipsis } from '@flowio/react-icons';
import { Dropdown, DropdownMenu, DropdownItem } from '@flowio/react-dropdown';

import React from 'react';
import moment from 'moment';
import kebabCase from 'lodash/kebabCase';
import {
  TableRow,
  TableRowColumn,
} from '@flowio/react-table';
import prettyLabel from '../../../../utilities/strings/pretty-label';

interface Props {
  exportItem: io.flow.v0.models.ScheduledExport;
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
  key: string;
}

function stopPropagation(event: React.SyntheticEvent): void {
  event.stopPropagation();
}

const ExportsItem: React.FC<Props> = (props) => {
  const {
    exportItem: {
      hour,
      minute,
      organization_q: organizationQ,
      id,
      types,
      timezone,
    }, onDelete, onEdit, key,
  } = props;
  return (
    <TableRow key={kebabCase(key)}>
      <TableRowColumn>
        {organizationQ}
      </TableRowColumn>
      <TableRowColumn>
        {prettyLabel(types.map((t) => t.discriminator).join(', '))}
      </TableRowColumn>
      <TableRowColumn>
        {`${(moment(hour + minute, 'hh mm').format('LT')).toLowerCase()} ${timezone}`}

      </TableRowColumn>
      <TableRowColumn textAlign="right">
        <Dropdown
          trigger={(<Ellipsis id="export-dropdown" width={12} />)}
          onClick={stopPropagation}
        >
          <DropdownMenu>
            <DropdownItem content="Edit" id="edit-export-btn" onClick={(): void => onEdit(id)} />
            <DropdownItem content="Delete" id="delete-export-btn" onClick={(): void => onDelete(id)} />
          </DropdownMenu>
        </Dropdown>
      </TableRowColumn>
    </TableRow>
  );
};

ExportsItem.displayName = 'ExportsItem';

export default ExportsItem;
