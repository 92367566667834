import React, { FunctionComponent } from 'react';

if (process.browser) {
  require('./styles.css'); // eslint-disable-line global-require
}

const PageNavigation: FunctionComponent = ({ children }) => (
  <div className="page-navigation">
    {children}
  </div>
);

PageNavigation.displayName = 'PageNavigation';

export default PageNavigation;
