import update from 'immutability-helper';
import { RootActionTypes } from '../../../stores/types';
import ActionTypes from '../constants/ActionTypes';
import { OptinDialogState } from '../types';

const initialState: OptinDialogState = {};

const dialogs = (
  state = initialState,
  action: RootActionTypes,
): OptinDialogState => {
  switch (action.type) {
    case ActionTypes.SHOW_DIALOG:
      return update(state, {
        [action.meta.name]: {
          $set: { isOpen: true, ...action.payload },
        },
      });
    case ActionTypes.HIDE_DIALOG:
      return update(state, {
        [action.meta.name]: {
          $set: { isOpen: false },
        },
      });
    default:
      return state;
  }
};

export default dialogs;
