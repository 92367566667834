const FormName = {
  CREATE_OPTIN_ATTRIBUTE_FORM: 'createOptinAttribute',
  DELETE_OPTIN_ATTRIBUTE_FORM: 'deleteOptinAttribute',
  DELETE_OPTIN_PROMPT_FORM: 'deleteOptinPromptForm',
  OPTIN_ATTRIBUTE_STATUS_FORM: 'optinAttributeStatusForm',
  OPTIN_CONTENT_FORM: 'optinContentForm',
  OPTIN_BANNER_CONTENT_FORM: 'optinBannerContent',
  OPTIN_CHECKOUT_CONTENT_FORM: 'optinCheckoutContent',
  UPDATE_OPTIN_ATTRIBUTE_FORM: 'updateOptinAttribute',
} as const;

export default FormName;
