import Client from './client';

export default class OrderActionabilities extends Client {
  constructor(opts) {
    let options = opts;

    if (typeof opts === 'string') {
      options = { host: opts }; // convert host string to options object
    }

    options.serviceName = 'API Internal';

    super(options);
  }

  post(organization, number, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/orders/${number}/status/actions`, {
      ...options,
       method: 'POST',
    });
  }

  getAvailable(organization, number, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/orders/${number}/status/actions/available`, options);
  }

}
