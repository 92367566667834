import { RootActionTypes } from '../../../stores/types';
import { ActionTypes } from '../constants';

const changeActiveShippingConfiguration = ({
  shippingConfigurationKey,
}: { shippingConfigurationKey: string }): RootActionTypes => ({
  type: ActionTypes.CHANGE_ACTIVE_SHIPPING_CONFIGURATION,
  payload: shippingConfigurationKey,
});

export default changeActiveShippingConfiguration;
