const ActionTypes = {
  CHECK_CATALOG_ACCESS_REQUEST: 'FACEBOOK.CHECK_CATALOG_ACCESS_REQUEST',
  CHECK_CATALOG_ACCESS_SUCCESS: 'FACEBOOK.CHECK_CATALOG_ACCESS_SUCCESS',
  CHECK_CATALOG_ACCESS_FAILURE: 'FACEBOOK.CHECK_CATALOG_ACCESS_FAILURE',

  DESTROY_SDK_REQUEST: 'FACEBOOK.DESTROY_SDK_REQUEST',
  DESTROY_SDK_SUCCESS: 'FACEBOOK.DESTROY_SDK_SUCCESS',
  DESTROY_SDK_FAILURE: 'FACEBOOK.DESTROY_SDK_FAILURE',

  INITIALIZE_SDK_REQUEST: 'FACEBOOK.INITIALIZE_SDK_REQUEST',
  INITIALIZE_SDK_SUCCESS: 'FACEBOOK.INITIALIZE_SDK_SUCCESS',
  INITIALIZE_SDK_FAILURE: 'FACEBOOK.INITIALIZE_SDK_FAILURE',

  FETCH_LOGIN_STATUS_REQUEST: 'FACEBOOK.FETCH_LOGIN_STATUS_REQUEST',
  FETCH_LOGIN_STATUS_SUCCESS: 'FACEBOOK.FETCH_LOGIN_STATUS_SUCCESS',
  FETCH_LOGIN_STATUS_FAILURE: 'FACEBOOK.FETCH_LOGIN_STATUS_FAILURE',

  FETCH_ACCESS_TOKEN_SUMMARY_REQUEST: 'FACEBOOK.FETCH_ACCESS_TOKEN_SUMMARY_REQUEST',
  FETCH_ACCESS_TOKEN_SUMMARY_SUCCESS: 'FACEBOOK.FETCH_ACCESS_TOKEN_SUMMARY_SUCCESS',
  FETCH_ACCESS_TOKEN_SUMMARY_FAILURE: 'FACEBOOK.FETCH_ACCESS_TOKEN_SUMMARY_FAILURE',

  LOAD_SDK_REQUEST: 'FACEBOOK.LOAD_SDK_REQUEST',
  LOAD_SDK_SUCCESS: 'FACEBOOK.LOAD_SDK_SUCCESS',
  LOAD_SDK_FAILURE: 'FACEBOOK.LOAD_SDK_FAILURE',

  LOGIN_REQUEST: 'FACEBOOK.LOGIN_REQUEST',
  LOGIN_SUCCESS: 'FACEBOOK.LOGIN_SUCCESS',
  LOGIN_FAILURE: 'FACEBOOK.LOGIN_FAILURE',

  LOGOUT_REQUEST: 'FACEBOOK.LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'FACEBOOK.LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'FACEBOOK.LOGOUT_FAILURE',

  ONBOARD_REQUEST: 'FACEBOOK.ONBOARD_REQUEST',
  ONBOARD_SUCCESS: 'FACEBOOK.ONBOARD_SUCCESS',
  ONBOARD_FAILURE: 'FACEBOOK.ONBOARD_FAILURE',

  REAUTHENTICATION_REQUEST: 'FACEBOOK.REAUTHENTICATION_REQUEST',
  REAUTHENTICATION_SUCCESS: 'FACEBOOK.REAUTHENTICATION_SUCCESS',
  REAUTHENTICATION_FAILURE: 'FACEBOOK.REAUTHENTICATION_FAILURE',

  SAVE_ACCESS_TOKEN_REQUEST: 'FACEBOOK.SAVE_ACCESS_TOKEN_REQUEST',
  SAVE_ACCESS_TOKEN_SUCCESS: 'FACEBOOK.SAVE_ACCESS_TOKEN_SUCCESS',
  SAVE_ACCESS_TOKEN_FAILURE: 'FACEBOOK.SAVE_ACCESS_TOKEN_FAILURE',

  TOGGLE_REAUTHENTICATION_MODAL: 'FACEBOOK.TOGGLE_REAUTHENTICATION_MODAL',
  TOGGLE_LOGOUT_MODAL: 'TOGGLE_LOGOUT_MODAL',
} as const;

export default ActionTypes;
