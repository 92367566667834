import { PuffLoader } from '@flowio/react-icons';

import React from 'react';

import { css } from '@emotion/css';
import CenterList from '../CenterList';
import pluralize from '../../../../utilities/strings/pluralize';
import { CenterQueryResultProps } from '../../types/components';

export const headingStyles = css`
  margin: 0;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
  color: #999999;
`;

export const noResultMessageStyles = css`
  margin: 0;
  line-height: 32px;
  color: #999999;
`;

const CenterQueryResult: React.FC<CenterQueryResultProps> = ({
  centers,
  countries,
  isLoading,
}) => (
  <section>
    {isLoading ? (
      <PuffLoader height={32} />
    ) : (
      <div>
        {(centers.length > 0) && (
          <h3 className={headingStyles}>
            {centers.length}
            {' '}
            {pluralize(centers.length, 'center')}
            {' '}
            included:
          </h3>
        )}
        {(centers.length > 0) && (
          <CenterList centers={centers} countries={countries} />
        )}
        {(centers.length === 0) && (
          <p className={noResultMessageStyles}>
            No centers matching provided rules found.
          </p>
        )}
      </div>
    )}
  </section>
);

CenterQueryResult.displayName = 'CenterQueryResult';

export default CenterQueryResult;
