import { createSelector } from 'reselect';
import property from 'lodash/property';
import getMarketingState from './getMarketingState';
import { RootState } from '../../../stores/types';
import { MarketingGatewayIntegratedPlatform, MarketingState } from '../types';
import DialogName from '../constants/DialogName';

type DisconnectDialogData = {
  platformId: MarketingGatewayIntegratedPlatform;
};

export default createSelector<RootState, MarketingState, DisconnectDialogData | undefined>(
  getMarketingState,
  property(['dialogs', DialogName.DISCONNECT_PLATFORM]),
);
