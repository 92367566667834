import { bindActionCreators } from 'redux';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { hideReturnPolicyDeleteConfirmation, deleteReturnPolicy } from '../actions';
import {
  getHasReturnPolicyDeleteFailed,
  getIsDeletingReturnPolicy,
  getIsReturnPolicyDeleteConfirmationVisible,
  getOrganizationIdForReturnPolicyToBeDeleted,
  getReturnPolicyToBeDeleted,
} from '../selectors';
import ReturnPolicyDeleteConfirmation from '../components/ReturnPolicyDeleteConfirmation';
import {
  ReturnPolicyDeleteConfirmationStateProps,
  ReturnPolicyDeleteConfirmationDispatchProps,
} from '../types';
import { RootState } from '../../../stores/types';

const mapStateToProps: MapStateToProps<
ReturnPolicyDeleteConfirmationStateProps,
{},
RootState
> = createStructuredSelector({
  deleting: getIsDeletingReturnPolicy,
  deleteFailed: getHasReturnPolicyDeleteFailed,
  open: getIsReturnPolicyDeleteConfirmationVisible,
  organizationId: getOrganizationIdForReturnPolicyToBeDeleted,
  returnPolicy: getReturnPolicyToBeDeleted,
});

const mapDispatchToProps: MapDispatchToProps<
ReturnPolicyDeleteConfirmationDispatchProps,
{}
> = (dispatch) => bindActionCreators({
  onOk: deleteReturnPolicy,
  onCancel: hideReturnPolicyDeleteConfirmation,
}, dispatch);

export default connect<
ReturnPolicyDeleteConfirmationStateProps,
ReturnPolicyDeleteConfirmationDispatchProps,
{},
RootState
>(mapStateToProps, mapDispatchToProps)(ReturnPolicyDeleteConfirmation);
