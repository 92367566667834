enum ActionTypes {
  FETCH_COUNTRIES_REQUEST = 'REFERENCE.FETCH_COUNTRIES_REQUEST',
  FETCH_COUNTRIES_SUCCESS = 'REFERENCE.FETCH_COUNTRIES_SUCCESS',
  FETCH_COUNTRIES_FAILURE = 'REFERENCE.FETCH_COUNTRIES_FAILURE',
  FETCH_CURRENCIES_REQUEST = 'REFERENCE.FETCH_CURRENCIES_REQUEST',
  FETCH_CURRENCIES_SUCCESS = 'REFERENCE.FETCH_CURRENCIES_SUCCESS',
  FETCH_CURRENCIES_FAILURE = 'REFERENCE.FETCH_CURRENCIES_FAILURE',
  FETCH_LANGUAGES_REQUEST = 'REFERENCE.FETCH_LANGUAGES_REQUEST',
  FETCH_LANGUAGES_SUCCESS = 'REFERENCE.FETCH_LANGUAGES_SUCCESS',
  FETCH_LANGUAGES_FAILURE = 'REFERENCE.FETCH_LANGUAGES_FAILURE',
  FETCH_REGIONS_REQUEST = 'REFERENCE.FETCH_REGIONS_REQUEST',
  FETCH_REGIONS_SUCCESS = 'REFERENCE.FETCH_REGIONS_SUCCESS',
  FETCH_REGIONS_FAILURE = 'REFERENCE.FETCH_REGIONS_FAILURE',
}

export default ActionTypes;
