import find from 'lodash/find';
import toLower from 'lodash/toLower';

export const findCountryByCode = (
  countries: io.flow.v0.models.Country[],
  code: string,
): io.flow.v0.models.Country | undefined => find(
  countries,
  (c) => toLower(c.iso_3166_2) === toLower(code) || toLower(c.iso_3166_3) === toLower(code),
);

export const findLanguageByCode = (
  languages: io.flow.v0.models.Language[],
  code: string,
): io.flow.v0.models.Language | undefined => find(
  languages,
  (l) => toLower(l.iso_639_2) === toLower(code),
);

export const findCurrencyByCode = (
  currencies: io.flow.v0.models.Currency[],
  code: string,
): io.flow.v0.models.Currency | undefined => find(
  currencies,
  (c) => toLower(c.iso_4217_3) === toLower(code),
);
