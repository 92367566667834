import React from 'react';
import {
  Field,
  BaseFieldProps,
  submit,
  WrappedFieldProps,
} from 'redux-form';

import FormName from '../../constants/FormName';
import MultiCheckDropdown from '../../../features/components/MultiCheckDropdown';
import ExperienceStatus from '../../constants/experience-status';

const listingStatusFilters = [
  {
    label: 'Draft',
    value: ExperienceStatus.DRAFT,
  }, {
    label: 'Active',
    value: ExperienceStatus.ACTIVE,
  }, {
    label: 'Archiving',
    value: ExperienceStatus.ARCHIVING,
  }, {
    label: 'Archived',
    value: ExperienceStatus.ARCHIVED,
  },
];

interface Props {
  statusFilters: {
    label: string;
    value: string;
  }[];
  formName: FormName;
}

export const ReduxFormMultiCheckDropdown: React.FC<Props & WrappedFieldProps> = ({
  input,
  meta,
  statusFilters,
  formName,
}) => (
  <div>
    <MultiCheckDropdown
      options={statusFilters}
      value={input.value}
      onChange={(selected: string): void => input.onChange(selected)}
      onClose={(): void => {
        meta.dispatch(submit(formName));
      }}
    />
  </div>
);

ReduxFormMultiCheckDropdown.defaultProps = {
  statusFilters: [],
};

const ExperienceStatusFilter = (): JSX.Element => (
  <Field<BaseFieldProps<Props>> name="experienceStatusFilter" component={ReduxFormMultiCheckDropdown} props={{ statusFilters: listingStatusFilters, formName: FormName.EXPERIENCE_LIST_FILTER_FORM }} />
);

export default ExperienceStatusFilter;
