import { MembershipsGetResponse } from '@flowio/api-sdk';
import ActionTypes from '../constants/action-types';
import { ThunkExtraArguments, ThunkDispatcher, RootState } from '../../../stores/types';
import { PropType } from '../../../types';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';
import toLegacyResponse from '../../../utilities/clients/to-legacy-response';

type ResponseType = PropType<MembershipsGetResponse, 'body'>;
const PAGE_SIZE = 1000;

export default function fetchMemberships(id: string, type = 'organization', offset = 0) {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<LegacyResponse<ResponseType>> => {
    let params;

    if (type === 'organization') {
      params = {
        organization: id,
        limit: PAGE_SIZE,
        offset,
        expand: ['user'],
      };
    } else {
      params = {
        [type]: id,
        limit: PAGE_SIZE,
        offset,
        expand: ['user'],
      };
    }

    dispatch({
      type: ActionTypes.FETCH_MEMBERSHIPS_REQUEST,
    });

    return extra.api(getState()).memberships.get(params).then((response) => {
      if (response.ok) {
        dispatch({
          type: ActionTypes.FETCH_MEMBERSHIPS_SUCCESS,
          payload: response.body,
        });

        if (response.body.length === PAGE_SIZE) {
          return dispatch(fetchMemberships(id, type, offset + PAGE_SIZE));
        }
      } else {
        dispatch({
          type: ActionTypes.FETCH_MEMBERSHIPS_FAILURE,
          payload: formatErrorResponseV2(response),
        });
      }
      return toLegacyResponse<ResponseType>(response);
    });
  };
}
