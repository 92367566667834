import { TriangleDown } from '@flowio/react-icons';
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Dropdown, DropdownMenu, DropdownItem } from '@flowio/react-dropdown';
import {
  Table,
  TableHead,
  TableRow, TableRowColumn,
  TableHeadColumn,
  TableBody,
} from '@flowio/react-table';
import { OutlineButton } from '@flowio/react-button';
import sortBy from 'lodash/sortBy';
import FormattedDate from '../../../../components/formatted-date';

import type {
  InvoiceProp,
  BusinessInvoiceProp,
  DocumentProp,
  NormalizedMemo,
} from '../../types';
import * as styles from './order-invoices.styles';

interface OrderInvoicesProps {
  consumerInvoices: InvoiceProp[];
  businessCreditMemos: BusinessInvoiceProp[];
  businessInvoices: BusinessInvoiceProp[];
  creditMemos: InvoiceProp[];
  customerName: io.flow.v0.models.Name;
}

function normalizeInvoiceOrMemo(
  invoicesOrMemos: Array<InvoiceProp>,
  customerName: io.flow.v0.models.Name,
  type: string,
): NormalizedMemo[] {
  return invoicesOrMemos.map((invoiceOrMemo) => ({
    ...invoiceOrMemo,
    buyer: {
      name: `${customerName.first} ${customerName.last}`,
    },
    seller: {
      name: invoiceOrMemo.entity.name,
    },
    label: type,
  }));
}

const OrderInvoices: React.FC<OrderInvoicesProps> = ({
  businessCreditMemos,
  businessInvoices,
  consumerInvoices,
  creditMemos,
  customerName,
}) => {
  const normalizedConsumerInvoices = normalizeInvoiceOrMemo(consumerInvoices, customerName, 'Consumer Invoice');
  const normalizedCreditMemos = normalizeInvoiceOrMemo(creditMemos, customerName, 'Credit Memo');
  const normalizedBusinessInvoices = businessInvoices.map((businessInvoice) => ({
    ...businessInvoice,
    label: 'Business Invoice',
  }));
  const normalizedBusinessCreditMemos = businessCreditMemos.map((businessCreditMemo) => ({
    ...businessCreditMemo,
    label: 'Business Credit Memo',
  }));

  const invoices = sortBy(normalizedConsumerInvoices.concat(normalizedCreditMemos, normalizedBusinessInvoices, normalizedBusinessCreditMemos), 'date');

  return (
    <Table displayDensity="compact" striped>
      <TableHead>
        <TableRow>
          <TableHeadColumn>Invoice #</TableHeadColumn>
          <TableHeadColumn>Type</TableHeadColumn>
          <TableHeadColumn>Invoice Date</TableHeadColumn>
          <TableHeadColumn>Seller</TableHeadColumn>
          <TableHeadColumn>Buyer</TableHeadColumn>
          <TableHeadColumn />
        </TableRow>
      </TableHead>
      <TableBody>
        {invoices.map((invoice) => (
          <TableRow key={invoice.id}>
            <TableRowColumn>{invoice.number}</TableRowColumn>
            <TableRowColumn>{invoice.label}</TableRowColumn>
            <TableRowColumn>
              <FormattedDate value={invoice.date} format="MMM DD YYYY HH:mm" />
            </TableRowColumn>
            <TableRowColumn>{invoice.seller.name}</TableRowColumn>
            <TableRowColumn>{invoice.buyer.name}</TableRowColumn>
            <TableRowColumn className={styles.downloadButton}>
              <Dropdown
                openOnClick
                trigger={<OutlineButton size="small" content="Download" rightIcon={TriangleDown} />}
              >
                <DropdownMenu>
                  {invoice.documents.map((document: DocumentProp) => (
                    <DropdownItem
                      key={`${invoice.id}-${document.language}-${invoice.label}`}
                      className={styles.invoiceDownload}
                      style={{ backgroundColor: 'white' }}
                      content={`PDF (${document.language.toUpperCase()})`}
                    >
                      <a href={document.url} target="_blank" rel="noopener noreferrer">
                        {`PDF (${document.language.toUpperCase()})`}
                      </a>
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </TableRowColumn>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

OrderInvoices.displayName = 'OrderInvoices';

export default OrderInvoices;
