import { css } from '@emotion/css';

const legendChartContainerCommonStyles = css`
    width: 50%;
    display: inline-block;
`;

export const legendContainer = css`
  ${legendChartContainerCommonStyles}
  height: 340px;
  overflow-y: scroll;
`;

export const chartContainer = css`
  ${legendChartContainerCommonStyles}
  text-align: center;
`;

export const legendTable = css`
  border-collapse: collapse;
  font-size: 14px;
`;

export const tableHeaderRow = css`
  border-collapse: collapse;
  border-bottom: 1px solid var(--mercury);
`;

export const tableCommonStyles = css`
  padding-top: 9px;
  padding-bottom: 9px;
`;

export const tableColor = css`
  padding-top: 5px;
  text-align: center;
  width: 35px
  ${tableCommonStyles}
  padding-right: 3px;
  padding-left: 8px;
`;

export const tableLabel = css`
  ${tableCommonStyles}
  width: 200px;
`;

export const tableValue = css`
  ${tableCommonStyles}
  padding-left: 16px;
  text-align: left;
  width: 200px;
`;

export const tablePercentage = css`
  ${tableCommonStyles}
  text-align: center;
  width: 75px;
`;

export const headerCell = css`
  padding-bottom: 8px;
`;

export const headerCellPadded = css`
  padding-bottom: 8px;
  padding-left: 16px;
`;

export const legendRow = css`
  ${tableCommonStyles}
  &:hover {
    cursor: pointer;
    background-color: var(--concrete);
  }
`;

export const colorDiv = css`
  border-radius: 2px;
  display: inline-block;
  height: 16px;
  position: relative;
  top: 3px;
  width: 16px;
`;
