import moment from 'moment';
import { createSelector } from 'reselect';
import getFacebookTokenSummary from './getFacebookTokenSummary';
import getIsTokenOwner from './getIsTokenOwner';

const getDaysToExpiry = createSelector(
  getFacebookTokenSummary,
  getIsTokenOwner,
  (summary, isTokenOwner) => {
    if (!isTokenOwner || !summary?.expires_at) {
      return undefined;
    }

    return moment(summary.expires_at).diff(moment(), 'days');
  },
);

export default getDaysToExpiry;
