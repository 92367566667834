import update from 'immutability-helper';
import keyBy from 'lodash/keyBy';
import ActionTypes from '../constants/action-types';

import { RootActionTypes } from '../../../stores/types';
import { StatementsState } from '../types';

const defaultState: StatementsState = {};

export default function billingStatementsReducer(
  state = defaultState,
  action: RootActionTypes,
): StatementsState {
  switch (action.type) {
    case ActionTypes.FETCH_STATEMENTS_SUCCESS:
      return update(state, { $merge: keyBy<io.flow.internal.v0.models.OrganizationBillingStatement[]>(action.payload.results, 'id') });
    default:
      return state;
  }
}
