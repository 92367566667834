import React from 'react';
import { Stack } from '@flowio/react-stack';
import map from 'lodash/map';

import TierListItem from './TierListItem';
import * as styles from './TierList.styles';
import { TierListProps } from '../../types/components';

const TierList: React.FC<TierListProps> = ({
  onEditTier,
  onDeleteTier,
  tiers,
}) => (
  <Stack className={styles.tierList} orientation="vertical" spacing="loose">
    {map(tiers, (tier) => (
      <TierListItem
        key={tier.id}
        onEdit={onEditTier}
        onDelete={onDeleteTier}
        tier={tier}
      />
    ))}
  </Stack>
);

TierList.displayName = 'TierList';

export default TierList;
