import { Edit } from '@flowio/react-icons';
import React, { useState, MouseEventHandler } from 'react';
import find from 'lodash/find';
import noop from 'lodash/noop';
import {
  Card, CardHeader, CardContent, CardFooter, CardTitle,
} from '@flowio/react-card';
import { OutlineButton } from '@flowio/react-button';
import { MenuDivider } from '@flowio/react-menu';
import { SelectField } from '@flowio/react-select';
import { TextInput } from '@flowio/react-text-input';

import { RoundingMethod, RoundingType } from '@flowio/api-constants';
import getRoundingSentence from '../../utilities/get-rounding-sentence';
import * as styles from './price-rounding.styles';
import getTextFieldValue from '../../../../utilities/getTextFieldValue';

const roundingMethodOptions = [
  { content: 'Round up to the nearest', value: RoundingMethod.UP },
  { content: 'Round down to the nearest', value: RoundingMethod.DOWN },
  { content: 'Round to nearest', value: RoundingMethod.NEAREST },
];

const roundingTypeOptions = [
  { content: 'Pattern', value: RoundingType.PATTERN },
  { content: 'Multiple', value: RoundingType.MULTIPLE },
];

interface Props {
  rounding: io.flow.v0.models.Rounding;
  currency: string;
  editable: boolean;
  onEdit: MouseEventHandler;
  onCancel: Function;
  onSave: Function;
}

const PriceRounding: React.FC<Props> = (props) => {
  const {
    rounding: roundingProp,
  } = props;
  const [rounding, setRounding] = useState<io.flow.v0.models.Rounding>({ ...roundingProp });

  const handleValueChange = (event: React.SyntheticEvent<HTMLInputElement>): void => {
    const { value } = event.currentTarget;

    setRounding({
      ...rounding,
      value: parseFloat(value),
    });
  };

  const handleMethodChange = (
    value: string,
  ): void => {
    setRounding({
      ...rounding,
      method: value as RoundingMethod,
    });
  };

  const handleTypeChange = (value: string): void => {
    setRounding({
      ...rounding,
      type: value as RoundingType,
    });
  };

  const handleCancel = (): void => {
    const { onCancel } = props;
    setRounding({
      ...roundingProp,
    });
    onCancel();
  };

  const handleSave = (): void => {
    const { onSave } = props;
    onSave(rounding);
  };

  const { currency, editable, onEdit } = props;
  const sentence = getRoundingSentence(rounding, currency.toUpperCase());

  return (
    <Card className="price-rounding">
      <CardHeader dividing>
        <CardTitle content="Rounding" />
      </CardHeader>
      <CardContent>
        {editable && (
          <div className={styles.inputs}>
            <div>
              <SelectField
                onValueChange={handleMethodChange}
                options={roundingMethodOptions}
                value={getTextFieldValue(find(roundingMethodOptions, { value: rounding.method }))}
              />
            </div>
            <div>
              <SelectField
                className={styles.selectFieldType}
                onValueChange={handleTypeChange}
                options={roundingTypeOptions}
                value={getTextFieldValue(find(roundingTypeOptions, { value: rounding.type }))}
              />
            </div>
            <span className={styles.roundingText}>
              {((): string => {
                if (rounding.type === 'pattern') {
                  return 'to a price ending with';
                }

                if (rounding.type === 'multiple') {
                  return 'of';
                }

                return '=';
              })()}
            </span>
            <div>
              <TextInput
                className={styles.textField}
                clearable={false}
                style={{ width: '60px', marginLeft: '1rem' }}
                name="experience_rounding"
                value={getTextFieldValue(rounding.value)}
                type="number"
                onChange={handleValueChange}
              />
            </div>
            <span className={styles.roundingText}>
              {currency.toUpperCase()}
            </span>
          </div>
        )}
        {!editable && (
          <p className={styles.summary}>
            {sentence}
          </p>
        )}
        <MenuDivider className={styles.divider} />
        <div>
          <h2 className={styles.sectionTitle}>
            Rounding Types
          </h2>
          <p>
            <strong>
              Pattern
            </strong>
            : Round price so that the value ends with
            the given pattern
          </p>
          <p>
            <strong>
              Multiple
            </strong>
            : Round price to a multiple of some number
          </p>
        </div>
      </CardContent>
      <CardFooter>
        {editable ? (
          <div className={styles.actions}>
            <OutlineButton
              content="Cancel"
              name="cancel_edit"
              onClick={handleCancel}
            />
            <OutlineButton
              className="ml2"
              content="Save"
              name="save"
              onClick={handleSave}
              intent="primary"
            />
          </div>
        ) : (
          <div className={styles.actions}>
            <OutlineButton
              leftIcon={Edit}
              className="ml2"
              content="Edit Rounding"
              name="edit"
              onClick={onEdit}
              intent="primary"
            />
          </div>
        )}
      </CardFooter>
    </Card>
  );
};
PriceRounding.displayName = 'PriceRounding';

PriceRounding.defaultProps = {
  editable: false,
  onEdit: noop,
  onCancel: noop,
  onSave: noop,
};

export default PriceRounding;
