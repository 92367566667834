import roleRoutes from '../constants/roleRoutes';

function canViewRoute(routes: string[], pathname: string): boolean {
  return routes.reduce(
    (acc: boolean, currentRoute: string) => acc || pathname.includes(currentRoute),
    false,
  );
}

function canViewPage(pathname: string, orgRoles: io.flow.v0.enums.FlowRole[]): boolean {
  const isCommonRoute = canViewRoute(roleRoutes.commonRoutes, pathname);

  // redirect after login hits '/' path a view times, want to prevent a
  // 404 page splash
  // If its a common route we always show, this also covers the use
  // case where a user isn't logged in it will prevent 404ing the login page
  if (pathname.length <= 1 || isCommonRoute) {
    return true;
  }

  // No role? You get a 404!
  if (orgRoles.length < 1) {
    return false;
  }

  // Right now this may seem like overkill but it should allow easy
  // adding of more roles when we need
  const roleCanViewPage = orgRoles.reduce((acc, currentRole) => {
    switch (currentRole) {
      case 'organization_customer_service':
        return acc || canViewRoute(roleRoutes.customerServiceRoutes, pathname);
      case 'channel_organization_admin':
        return acc || canViewRoute(roleRoutes.channelOrganizationAdminRoutes, pathname);
      case 'organization_fulfillment':
        return acc || canViewRoute(roleRoutes.fulfilmentRoutes, pathname);
      case 'organization_classification':
        return acc || canViewRoute(roleRoutes.classificationRoutes, pathname);
      default:
        return true;
    }
  }, false);

  return isCommonRoute || roleCanViewPage;
}

export default canViewPage;
