import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import ActionTypes from '../constants/action-types';

function createRequestAction(
  organization: string,
  regionSetting: io.flow.v0.models.RegionSetting,
): RootActionTypes {
  return {
    type: ActionTypes.UPDATE_REGION_SETTING_REQUEST,
    organization,
    regionSetting,
  };
}

function createSuccessAction(
  payload: io.flow.v0.models.RegionSetting,
  organization: string,
  regionSetting: io.flow.v0.models.RegionSetting,
): RootActionTypes {
  return {
    type: ActionTypes.UPDATE_REGION_SETTING_SUCCESS,
    organization,
    regionSetting,
    payload,
  };
}

function createFailureAction(
  payload: FormattedErrorMessages,
  organization: string,
  regionSetting: io.flow.v0.models.RegionSetting,
): RootActionTypes {
  return {
    type: ActionTypes.UPDATE_REGION_SETTING_FAILURE,
    payload,
    organization,
    regionSetting,
  };
}

export default function updateRegionSetting(
  organization: string,
  regionSetting: io.flow.v0.models.RegionSetting,
  status: io.flow.v0.enums.AvailabilityStatus,
): ThunkResult<Promise<void>> {
  return function updateRegionSettingEffects(
    dispatch,
    getState,
    extra,
  ): Promise<void> {
    dispatch(createRequestAction(organization, regionSetting));

    return extra.api(getState()).organizations.putSettingsAndRegionsByOrganizationIdAndRegion({
      organization_id: organization,
      region: regionSetting.region,
      body: {
        status,
      },
    }).then((response) => {
      if (response.ok) {
        dispatch(createSuccessAction(response.body, organization, regionSetting));
        return;
      }

      const error = formatErrorResponseV2(response);
      dispatch(createFailureAction(error, organization, regionSetting));
    });
  };
}
