import ActionTypes from '../constants/ActionTypes';
import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';

interface FetchSurchargeSettingsOptions {
  organizationId: string;
}

function createRequestAction(parameters: FetchSurchargeSettingsOptions): RootActionTypes {
  return {
    type: ActionTypes.FETCH_SURCHARGE_SETTINGS_REQUEST,
    meta: { parameters },
  };
}

function createSuccessAction(
  payload: io.flow.v0.models.SurchargeSettingDisplay[],
  parameters: FetchSurchargeSettingsOptions,
): RootActionTypes {
  return {
    type: ActionTypes.FETCH_SURCHARGE_SETTINGS_SUCCESS,
    meta: { parameters },
    payload,
  };
}

function createFailureAction(
  payload: FormattedErrorMessages,
  parameters: FetchSurchargeSettingsOptions,
): RootActionTypes {
  return {
    type: ActionTypes.FETCH_SURCHARGE_SETTINGS_FAILURE,
    meta: { parameters },
    payload,
    error: true,
  };
}

/**
 * Creates an action responsible for fetching a possbile surcharge settings.
 * @param {String} props.organizationId
 */
function fetchSurchargeSettings(
  parameters: FetchSurchargeSettingsOptions,
): ThunkResult<Promise<void>> {
  return function fetchSurchargeSettingsEffects(
    dispatch,
    getState,
    extra,
  ): Promise<void> {
    dispatch(createRequestAction(parameters));
    return extra.api(getState()).surchargeSettings.getDisplays({
      organization: parameters.organizationId,
    }).then((response) => {
      if (response.ok) {
        dispatch(createSuccessAction(response.body, parameters));
        return;
      }

      const errors = formatErrorResponseV2(response);
      dispatch(createFailureAction(errors, parameters));
    });
  };
}

export default fetchSurchargeSettings;
