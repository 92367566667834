import { css } from '@emotion/css';

export const experienceList = css`
  padding: 0 0 0 1rem;
  margin: 0 0 0 20%;
  list-style: none;
`;

export const experienceListItem = css`
  padding: 0;
  margin: 0;
  line-height: normal;

  &:not(:first-child) {
    margin-top: 8px;
  }
`;

export const experienceName = css`
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.14;
  text-decoration: underline;
`;
