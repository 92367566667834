import { reduxForm, SubmissionError } from 'redux-form';
import { browserHistory } from 'react-router';

import { ActionTypes, DialogName, FormName } from '../constants';
import { deleteShippingLaneById, deleteDialog } from '../actions';
import { createToast } from '../../console/actions';
import ShippingLaneDeleteForm from '../components/ShippingLaneDeleteForm';
import { ShippingLaneDeleteFormProps, ShippingLaneDeleteFormValues } from '../types/components';
import { ThunkDispatcher } from '../../../stores/types';
import { DeleteShippingLaneSuccess } from '../types/actions';

function handleSubmit(values: ShippingLaneDeleteFormValues, dispatch: ThunkDispatcher) {
  const { organizationId, shippingConfigurationKey, shippingLaneId } = values;
  return dispatch(deleteShippingLaneById({
    organizationId,
    shippingConfigurationKey,
    shippingLaneId,
  })).then((result) => {
    if (result.type === ActionTypes.DELETE_SHIPPING_LANE_FAILURE) {
      throw new SubmissionError({
        _error: result.payload.messages,
      });
    }

    return result;
  });
}

function handleSubmitSuccess(result: DeleteShippingLaneSuccess, dispatch: ThunkDispatcher) {
  const { organizationId, shippingConfigurationKey } = result.meta;
  browserHistory.push(`/${organizationId}/logistics/shipping/configurations/${shippingConfigurationKey}`);
  dispatch(deleteDialog({ type: DialogName.SHIPPING_LANE_DELETE_CONFIRMATION }));
  dispatch(createToast({ content: 'Shipping lane has been deleted.' }));
}

const form = reduxForm<
ShippingLaneDeleteFormValues,
ShippingLaneDeleteFormProps
>({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  form: FormName.SHIPPING_LANE_DELETE_FORM,
  onSubmit: handleSubmit,
  onSubmitSuccess: handleSubmitSuccess,
});

export default form(ShippingLaneDeleteForm);
