import { ItemHarmonizationStatus } from '@flowio/api-internal-constants';
import {
  CLASSIFIED, EXEMPT, NON_CLASSIFIABLE, WAITING_FOR_CLASSIFICATION,
} from '../../constants';

export const getStatusColor = (type: string): string => {
  let color;
  switch (type) {
    case ItemHarmonizationStatus.WAITING_FOR_CLASSIFICATION:
      color = '#bab0ab';
      break;
    case ItemHarmonizationStatus.CLASSIFIED:
      color = '#59a14f';
      break;
    case ItemHarmonizationStatus.NON_CLASSIFIABLE_NOT_ENOUGH_INFORMATION:
      color = '#e15759';
      break;

    case ItemHarmonizationStatus.EXEMPT:
      color = '#4e79a7';
      break;

    default: color = '#bab0ab';
  }

  return color;
};

export const getTooltipText = (type: string): string => {
  let tooltipText;
  switch (type) {
    case ItemHarmonizationStatus.WAITING_FOR_CLASSIFICATION:
      tooltipText = WAITING_FOR_CLASSIFICATION;
      break;
    case ItemHarmonizationStatus.CLASSIFIED:
      tooltipText = CLASSIFIED;
      break;
    case ItemHarmonizationStatus.NON_CLASSIFIABLE_NOT_ENOUGH_INFORMATION:
      tooltipText = NON_CLASSIFIABLE;
      break;

    case ItemHarmonizationStatus.EXEMPT:
      tooltipText = EXEMPT;
      break;

    default: tooltipText = WAITING_FOR_CLASSIFICATION;
  }

  return tooltipText;
};
