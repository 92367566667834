import { compose } from 'redux';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { withFetch } from '@flowio/redux-fetch';
import last from 'lodash/last';
import { checkFeatureValues, invalidateFeatureValues, defaultRedirect } from '../../../actions';
import {
  getIsFetching,
  getToasts,
  checkFeatureValueByKey,
} from '../../../selectors';
import { getOrganization } from '../../../../organization';
import Application from '../components/application';
import deleteToast from '../../../actions/deleteToast';
import FeatureKey from '../../../constants/FeatureKey';
import { StateProps, DispatchProps, OwnProps } from '../types';
import { RootState, ThunkDispatcher } from '../../../../../stores/types';
import findOrgRoles from '../../../utilities/findOrgRoles';
import {
  fetchSpecificUserOrganizationMembership,
  getIsChannelOrgAdmin,
  getIsClassificationUser,
  getIsCustomerServiceUser,
  getIsFulfillmentUser,
} from '../../../../user';

function getAsyncState(
  dispatch: ThunkDispatcher,
  getState: () => RootState,
  ownProps: OwnProps,
): Promise<unknown> {
  const state = getState();

  if (!state.defaultReducer.isLoggedIn || ownProps.location.pathname === '/logout') {
    return Promise.resolve(state);
  }

  const promises = [];

  if (ownProps.params.organization) {
    promises.push(dispatch(checkFeatureValues({
      organizationId: ownProps.params.organization,
    })));
    promises.push(dispatch(fetchSpecificUserOrganizationMembership({
      organizationId: ownProps.params.organization,
    })));
    promises.push(dispatch(fetchSpecificUserOrganizationMembership({
      organizationId: 'flow',
    })));
  }
  return Promise.all(promises);
}

function mapStateToProps(
  state: RootState,
  { routes, params }: OwnProps,
): StateProps {
  const { defaultReducer, user } = state;
  const route = last(routes);

  return {
    ...defaultReducer,
    fetching: getIsFetching(state),
    organization: getOrganization(state),
    memberships: user.memberships,
    isGlobalFailureSummariesEnabled: checkFeatureValueByKey(
      FeatureKey.GLOBAL_FAILURE_SUMMARIES,
    )(state),
    isGlobalFulfillmentConsoleEnabled:
      checkFeatureValueByKey(FeatureKey.GLOBAL_CONSOLE_FULFILLMENT)(state),
    isGlobalPendingMembersEnabled: checkFeatureValueByKey(FeatureKey.GLOBAL_PENDING_MEMBERS)(state),
    isGlobalShopifySyncStatusEnabled: checkFeatureValueByKey(
      FeatureKey.GLOBAL_SHOPIFY_SYNC_STATUS,
    )(state),
    isGlobalPriceBooksEnabled: checkFeatureValueByKey(FeatureKey.GLOBAL_PRICE_BOOKS)(state),
    isGlobalConsoleReturnsEnabled: checkFeatureValueByKey(FeatureKey.GLOBAL_CONSOLE_RETURNS)(state),
    isAuthenticationRequired: !!route && route.authRequired !== false,
    isCustomerSerivceUser: getIsCustomerServiceUser(state),
    isChannelOrgAdmin: getIsChannelOrgAdmin(state),
    isFulfillmentUser: getIsFulfillmentUser(state),
    isClassificationUser: getIsClassificationUser(state),
    hideTopNavigation: !!route && (
      route.hideNavigation === true || route.hideTopNavigation === true
    ),
    hideLeftNavigation: !!route && (
      route.hideNavigation === true || route.hideLeftNavigation === true
    ),
    orgRoles: findOrgRoles(params.organization, user.memberships),
    toasts: getToasts(state),
    isFlowLabsEnabled: checkFeatureValueByKey(FeatureKey.GLOBAL_FLOW_LABS)(state),
  };
}

function mapDispatchToProps(
  dispatch: ThunkDispatcher,
  ownProps: OwnProps,
): DispatchProps {
  return {
    onLeftNavigationItemClick(url): void {
      browserHistory.push(url);
    },
    onFollowRedirect(url): void {
      browserHistory.push(url);
    },
    onRequestCloseToast(eventKey): void {
      dispatch(deleteToast(eventKey));
    },
    onRequestLoginRedirect(): void {
      browserHistory.push(`/login?return_url=${encodeURIComponent(ownProps.location.pathname)}`);
    },
    onMembershipChange(membershipId): void {
      dispatch(invalidateFeatureValues());
      dispatch(defaultRedirect(membershipId));
    },
  };
}

export default compose(
  withFetch(getAsyncState),
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
)(Application);
