import { createSelector } from 'reselect';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import normalizeSearchResults from './utilities/normalizeSearchResults';

export const getGlobalRecentSearches = (state) => get(state, 'globalSearch.recent');
export const getLoadingState = (state) => get(state, 'globalSearch.loading');
export const getGlobalResults = (state) => get(state, 'globalSearch.results');

export const getNormalizedResults = createSelector(
  getGlobalResults,
  getGlobalRecentSearches,
  (results, recents) => {
    if (isEmpty(recents)) {
      return normalizeSearchResults(results);
    }

    return recents;
  },
);
