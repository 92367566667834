import { submit, FormAction } from 'redux-form';
import { compose } from 'redux';
import { createSelector, createStructuredSelector } from 'reselect';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import identity from 'lodash/identity';
import get from 'lodash/get';
import { withRouter } from 'react-router';
import ExportDialog from '../components/ExportDialog';
import resetExportTimelineState from '../../../actions/resetExportTimelineState';
import FormName from '../../../constants/FormName';
import {
  StateProps, DispatchProps, OwnProps, ComponentProps,
} from '../types';
import { RootState, ThunkDispatcher, RootActionTypes } from '../../../../../stores/types';
import { AnalyticsState } from '../../../types';

const getAnalyticsState = createSelector<RootState, RootState, AnalyticsState['gmvByCountry']>(identity, (state) => get(state, 'analytics.gmvByCountry'));
const getExportSubmittedState = createSelector<RootState, AnalyticsState['gmvByCountry'], boolean>(getAnalyticsState, (state) => get(state, 'ui.submitted', false));
const getExportSubmittingState = createSelector<RootState, AnalyticsState['gmvByCountry'], boolean>(getAnalyticsState, (state) => get(state, 'ui.submitting'));
const getExportSubmitError = createSelector<RootState, AnalyticsState['gmvByCountry'], AnalyticsState['gmvByCountry']['ui']['error']>(getAnalyticsState, (state) => get(state, 'ui.error'));

const onResetExportState = (): RootActionTypes => resetExportTimelineState();

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch: ThunkDispatcher,
) => ({
  onResetExportState: (): RootActionTypes => dispatch<RootActionTypes>(onResetExportState()),
  onRequestSubmit: (): FormAction => dispatch<FormAction>(submit(FormName.ANALYTICS_EXPORT_DIALOG)),
});

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = createStructuredSelector({
  submitted: getExportSubmittedState,
  submitting: getExportSubmittingState,
  exportError: getExportSubmitError,
});

export default compose<React.FC<ComponentProps>>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ExportDialog);
