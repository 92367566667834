import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import {
  getCountries,
  getLanguages,
} from '../../../../reference/selectors';
import {
  toggleReauthenticationModal,
} from '../../../../facebook/actions';
import {
  showDownloadFeedsDialog,
  goToAddCountriesPage,
} from '../../../actions';
import {
  getLocalizedFeeds,
  getFeedSources,
} from '../../../selectors';
import {
  getFacebookTokenSummary,
  getIsInitialized,
} from '../../../../facebook/selectors';
import FacebookFeeds from '../components/FacebookFeeds';
import {
  RootState,
  RootActionTypes,
  ThunkDispatcher,
} from '../../../../../stores/types';
import { OwnProps, StateProps, DispatchProps } from '../types';
import { isFacebookFeedSource } from '../../../types/utilities';

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = (
  state: RootState,
  { catalogId }: OwnProps,
): StateProps => {
  const feedSources = getFeedSources(state).filter(
    (source) => isFacebookFeedSource(source) && source.catalog_id === catalogId,
  );

  const localizedFeeds = getLocalizedFeeds()(state);

  const localizedFeedsIds = localizedFeeds.map(({ id }) => id);

  return { // eslint-disable-line
    feedSources,
    localizedFeeds,
    localizedFeedsIds,
    accessTokenSummary: getFacebookTokenSummary(state),
    isFbInitialized: getIsInitialized(state),
    countries: getCountries(state),
    languages: getLanguages(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch: ThunkDispatcher,
): DispatchProps => ({
  onRenewAccess: () => dispatch(toggleReauthenticationModal(true)),
  onClickAddCountryFeed: (
    organizationId: string,
    catalogId: number,
  ): void => dispatch(goToAddCountriesPage({ organizationId, catalogId })),
  onHandleDownloadAllFeeds: (
    feeds: string[],
  ): RootActionTypes => dispatch(showDownloadFeedsDialog(feeds)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FacebookFeeds);
