import { Box } from '@flowio/react-box';
import { Button, ButtonGroup } from '@flowio/react-button';
import { DateInputField } from '@flowio/react-date-input';
import React, { useState } from 'react';
import { SelectField } from '@flowio/react-select';
import find from 'lodash/find';
import map from 'lodash/map';
import moment, { Moment } from 'moment';
import noop from 'lodash/noop';

import { toDateRange, toDateRangeLiteral } from '../../utilities/date-range';
import { PageHeader } from '../../../console/components/page-header';
import DateRangeLiterals from '../../constants/date-range-literals';
import Metric from '../../constants/metric';

const defaultDateRanges = [
  { label: 'Last 7 Days', value: DateRangeLiterals.LAST_7_DAYS },
  { label: 'Last Week', value: DateRangeLiterals.LAST_WEEK },
  { label: 'Last 30 Days', value: DateRangeLiterals.LAST_30_DAYS },
  { label: 'Last Month', value: DateRangeLiterals.LAST_MONTH },
  { label: 'Last 90 Days', value: DateRangeLiterals.LAST_90_DAYS },
  { label: 'Last 3 Months', value: DateRangeLiterals.LAST_3_MONTHS },
  { label: 'Last 12 Months', value: DateRangeLiterals.LAST_12_MONTHS },
  { label: 'Last Year', value: DateRangeLiterals.LAST_YEAR },
];

interface Props {
  currentDate?: string;
  endDate: Moment;
  metric: Metric;
  onRequestDateRangeChange: Function;
  onRequestMetricChange: Function;
  startDate: Moment;
}

interface DateRangeOptions {
  label: string;
  value: string;
  startDate: Moment;
  endDate: Moment;
}

const AnalyticsHeader: React.FC<Props> = ({
  currentDate,
  endDate,
  startDate,
  metric,
  onRequestDateRangeChange,
  onRequestMetricChange,
}) => {
  const getSelectedDateRange = (): DateRangeLiterals => toDateRangeLiteral(
    [moment(startDate), moment(endDate)], moment.parseZone(currentDate),
  );

  const [selectedDateRange, setSelectedDateRange] = useState(getSelectedDateRange());

  const getDateRanges = (): DateRangeOptions[] => map(defaultDateRanges, ({ label, value }) => {
    const [drStartDate, drEndDate] = toDateRange(value, moment.parseZone(currentDate));
    return {
      label, value, startDate: drStartDate, endDate: drEndDate,
    };
  });

  const triggerDateRangeChange = (drStartDate: Moment, drEndDate: Moment): void => {
    onRequestDateRangeChange({
      startDate: drStartDate.clone().startOf('day').toISOString(),
      endDate: drEndDate.clone().endOf('day').toISOString(),
    });
  };

  const handleDateRangeChange = (
    value: string,
  ): void => {
    setSelectedDateRange(value as DateRangeLiterals);

    if (value !== DateRangeLiterals.CUSTOM) {
      const dateRanges = getDateRanges();
      const dateRange = find(dateRanges, { value });
      if (dateRange) {
        triggerDateRangeChange(dateRange.startDate, dateRange.endDate);
      }
    }
  };

  const handleStartDateChange = (newStartDate: moment.Moment): void => {
    triggerDateRangeChange(moment.parseZone(newStartDate), moment.parseZone(endDate));
  };

  const handleEndDateChange = (newEndDate: moment.Moment): void => {
    triggerDateRangeChange(moment.parseZone(startDate), moment.parseZone(newEndDate));
  };

  const handleOrderCountMetricSelection = (event: React.SyntheticEvent): void => {
    onRequestMetricChange(event, Metric.ORDER_COUNT);
  };

  const handleGmvMetricSelection = (event: React.SyntheticEvent): void => {
    onRequestMetricChange(event, Metric.GMV);
  };

  const dateRanges = getDateRanges();
  const displayFormat = 'L';
  const currentMoment = moment.parseZone(currentDate);

  const dateRangeOptions = dateRanges.map((dateRange) => ({
    content: `${dateRange.label} (${moment(dateRange.startDate.toDate()).format(displayFormat)} - ${moment(dateRange.endDate.toDate()).format(displayFormat)})`,
    value: dateRange.value,
  })).concat([{
    content: 'Custom Range',
    value: DateRangeLiterals.CUSTOM,
  }]);

  return (
    <PageHeader>
      <Box flexAuto alignItems="center" justifyContent="start">
        <Box flexNone className="mr2">
          <ButtonGroup>
            <Button
              content="Submitted Order Value"
              intent={metric === Metric.GMV ? 'primary' : 'neutral'}
              onClick={handleGmvMetricSelection}
              size="small"
              type="button"
            />
            <Button
              content="Number of Orders"
              intent={metric === Metric.ORDER_COUNT ? 'primary' : 'neutral'}
              onClick={handleOrderCountMetricSelection}
              size="small"
              type="button"
            />
          </ButtonGroup>
        </Box>
        <Box flexNone className="mr2">
          <SelectField
            inline
            labelCols={4}
            labelFor="daterange"
            labelText="Date Range:"
            onValueChange={handleDateRangeChange}
            options={dateRangeOptions}
            size="small"
            value={find(dateRangeOptions, { value: selectedDateRange })?.value}
          />
        </Box>
        {(selectedDateRange === DateRangeLiterals.CUSTOM) && (
          <Box flexNone className="mr2">
            <DateInputField
              currentDate={currentMoment}
              labelText="From:"
              onValueChange={handleStartDateChange}
              maximumDate={moment.min(moment(endDate), currentMoment)}
              showTrailingDates={false}
              value={startDate}
              labelSize="small"
              labelCols={2}
              inline
            />
          </Box>
        )}
        {(selectedDateRange === DateRangeLiterals.CUSTOM) && (
          <Box flexNone>
            <DateInputField
              currentDate={currentMoment}
              labelText="To:"
              onValueChange={handleEndDateChange}
              minimumDate={startDate}
              maximumDate={currentMoment}
              showTrailingDates={false}
              value={endDate}
              labelSize="small"
              labelCols={2}
              inline
            />
          </Box>
        )}
      </Box>
    </PageHeader>
  );
};
AnalyticsHeader.displayName = 'AnalyticsHeader';

AnalyticsHeader.defaultProps = {
  onRequestDateRangeChange: noop,
  onRequestMetricChange: noop,
};

export default AnalyticsHeader;
