import fetchLoginStatus from './fetchLoginStatus';
import { ThunkDispatcher, ThunkResult } from '../../../stores/types';
import standardAction from '../../../utilities/standard-action';
import ActionTypes from '../constants/action-types';
import { init } from '../fbSDK';
import { createToast } from '../../console/actions';

const initializeFacebookSDK = (prompted = false): ThunkResult<Promise<void>> => (
  dispatch: ThunkDispatcher,
): Promise<void> => {
  dispatch(standardAction(ActionTypes.INITIALIZE_SDK_REQUEST));

  return init()
    .then(() => (
      dispatch(fetchLoginStatus())
        .then(({ status }) => {
          if (prompted && status === 'connected') {
            dispatch(createToast({
              icon: 'info-circle',
              intent: 'positive',
              content: 'Access to Facebook successfully renewed',
            }));
          }
          dispatch(standardAction(ActionTypes.INITIALIZE_SDK_SUCCESS));
        })
    ))
    .catch((error) => {
      dispatch(standardAction(ActionTypes.INITIALIZE_SDK_FAILURE, error));
      throw error;
    });
};

export default initializeFacebookSDK;
