import { css } from '@emotion/css';

export const globalMetrics = css`
  float: left;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 40%;
  padding: 0 1.75rem 0 0;
  box-sizing: border-box;
`;

export const localMetrics = css`
  float: left;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.75rem;
  width: 60%;
  /* outline: */
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  box-sizing: border-box;
`;

export const metric = css`
  &:not(:first-child) {
    margin-left: 3rem;
  }
`;

export const metricLabel = css`
  display: block;
  font-size: 13px;
  color: #4d5866;
  line-height: 20px;
  text-align: left;
  text-transform: uppercase;
`;

export const metricValue = css`
  display: block;
  margin: 0.45rem 0 0;
  padding: 0;
  font-size: 24px;
  color: #17181a;
  line-height: 32px;
  text-align: left;
`;

export const metricValueExclusion = css`
  color: rgb(245, 166, 35);
`;

export const metricValueRestriction = css`
  color: rgb(232, 48, 23);
`;

export const metricValueInclusion = css`
  color: rgb(33, 145, 236);
`;

export const metricValueZero = css`
  color: rgb(155, 155, 155);
`;

export const chartContainer = css`
  width: 3.5rem;
  height: 3.5rem;
`;

export const chart = css`
  width: 100%;
  height: auto;
`;
