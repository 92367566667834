enum ActionTypes {
  CHANGE_ITEM_QUANTITY = '@@fulfillment/CHANGE_ITEM_QUANTITY',
  CLEAR_CURRENT_ORDER = '@@fulfillment/CLEAR_CURRENT_ORDER',
  SELECT_ALL_ITEMS = '@@fulfillment/SELECT_ALL_ITEMS',
  CREATE_ACTIVITY = '@@fulfillment/CREATE_ACTIVITY',
  DESELECT_ITEM = '@@fulfillment/DESELECT_ITEM',
  SCAN_ITEM = '@@fulfillment/SCAN_ITEM',
  SET_CURRENT_ORDER = '@@fulfillment/SET_CURRENT_ORDER',
  SELECT_ITEM = '@@fulfillment/SELECT_ITEM',
  CLEAR_CURRENT_ITEMS = '@@fulfillment/CLEAR_CURRENT_ITEMS',
  PRINT_LABEL = '@@fulfillment/PRINT_LABEL',
  CHANGE_SEARCH_VALUE = '@@fullfillment/CHANGE_SEARCH_VALUE',
  CREATE_SHIPPING_LABEL_REQUEST = '@@fullfillment/CREATE_SHIPPING_LABEL_REQUEST',
  CREATE_SHIPPING_LABEL_SUCCESS = '@@fullfillment/CREATE_SHIPPING_LABEL_SUCCESS',
  CREATE_SHIPPING_LABEL_FAILURE = '@@fullfillment/CREATE_SHIPPING_LABEL_FAILURE',
  CLEAR_SHIPPING_LABEL = '@@fullfillment/CLEAR_SHIPPING_LABEL',
  READ_ORDER_BY_NUMBER_REQUEST = '@@fullfillment/READ_ORDER_BY_NUMBER_REQUEST',
  READ_ORDER_BY_NUMBER_SUCCESS = '@@fullfillment/READ_ORDER_BY_NUMBER_SUCCESS',
  READ_ORDER_BY_NUMBER_FAILURE = '@@fullfillment/READ_ORDER_BY_NUMBER_FAILURE',
  READ_ORDER_BY_IDENTIFIER_REQUEST = '@@fullfillment/READ_ORDER_BY_IDENTIFIER_REQUEST',
  READ_ORDER_BY_IDENTIFIER_SUCCESS = '@@fullfillment/READ_ORDER_BY_IDENTIFIER_SUCCESS',
  READ_ORDER_BY_IDENTIFIER_FAILURE = '@@fullfillment/READ_ORDER_BY_IDENTIFIER_FAILURE',
  FETCH_ITEM_CONTENT_REQUEST = '@@fullfillment/FETCH_ITEM_CONTENT_REQUEST',
  FETCH_ITEM_CONTENT_SUCCESS = '@@fullfillment/FETCH_ITEM_CONTENT_SUCCESS',
  FETCH_ITEM_CONTENT_FAILURE = '@@fullfillment/FETCH_ITEM_CONTENT_FAILURE',
}

export default ActionTypes;
