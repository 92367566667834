import React from 'react';
import {
  Card, CardHeader, CardContent, CardFooter, CardDescription, CardTitle,
} from '@flowio/react-card';
import { Description, DescriptionGroup } from '../../../../components/description';

const OrganizationId: React.FC<{ organization: io.flow.v0.models.Organization }> = ({
  organization,
}) => (
  <Card id="organization-id" className="organization-id">
    <CardHeader dividing>
      <CardTitle content="Organization" />
      <CardDescription content="Organization information to access the Flow API" />
    </CardHeader>
    <CardContent>
      <DescriptionGroup className="" spacing={false}>
        <Description term="ID for Flow API" definition={organization.id} />
        <Description term="Name" definition={organization.name} />
      </DescriptionGroup>
    </CardContent>
    <CardFooter />
  </Card>
);

OrganizationId.displayName = 'OrganizationId';

export default OrganizationId;
