import { createSelector } from 'reselect';
import get from 'lodash/get';
import getExclusionRuleWorksheet from './getExclusionRuleWorksheet';
import { RootState } from '../../../stores/types';
import { ExclusionRuleWorksheetState } from '../types';

/**
 * Returns the initial query builder model associated with the exclusion rule
 * being created or edited.
 * @returns {QueryBuilder}
 */
const getQueryBuilderForExclusionRuleWorksheet = createSelector<
RootState, ExclusionRuleWorksheetState, io.flow.v0.models.QueryBuilder | undefined>(
  getExclusionRuleWorksheet,
  (worksheet) => get(worksheet, 'queryBuilder'),
);

export default getQueryBuilderForExclusionRuleWorksheet;
