import { css } from '@emotion/css';

export const resetPassword = css`

  & .alert {
    margin-bottom: 1rem;
  }

  & .button {
    margin-top: 4rem;
  }
`;

export const password = css`
  margin-bottom: 1rem;
`;

export const button = css`
  margin-top: 146px;
`;
