import { Confirm } from '@flowio/react-confirm';
import React, { PureComponent } from 'react';
import get from 'lodash/get';
import defaultTo from 'lodash/defaultTo';

import { css } from '@emotion/css';
import { ReturnPolicyDeleteConfirmationProps } from '../../types';

const deleteConfirmationStyles = css`
  width: 480px !important;
`;

const errorMessageStyles = css`
  margin: 1rem 0 0;
  color: #d2431f;
`;

class ReturnPolicyDeleteConfirmation extends PureComponent<ReturnPolicyDeleteConfirmationProps> {
  static displayName = 'ReturnPolicyDeleteConfirmation';

  static defaultProps = {
    returnPolicy: undefined,
    organizationId: undefined,
  };

  constructor(props: ReturnPolicyDeleteConfirmationProps, context: any) {
    super(props, context);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  handleConfirm() {
    const {
      returnPolicy,
      onOk,
      organizationId = '',
    } = this.props;
    const returnPolicyId = get(returnPolicy, 'id', '');
    onOk(organizationId, returnPolicyId);
  }

  render() {
    const {
      deleting,
      deleteFailed,
      returnPolicy,
      onCancel,
      open,
    } = this.props;
    const returnPolicyDescription = get(returnPolicy, 'description');
    const returnPolicyQuery = get(returnPolicy, 'q');
    return (
      <Confirm
        className={deleteConfirmationStyles}
        caption="Are you absolutely sure?"
        disabled={deleting}
        onCancel={onCancel}
        onOk={this.handleConfirm}
        open={open}
        intent="negative"
      >
        <p>
          This action
          <strong> cannot </strong>
          be undone. Continue deleting the non-returnable rule
          <strong>{` ${defaultTo(returnPolicyDescription, returnPolicyQuery)}`}</strong>
          ?
        </p>
        {deleteFailed && (
          <p className={errorMessageStyles}>
            An error has occurred while trying to delete the non-returnable rule.
            Please try again later.
            Contact
            <a href="mailto:help@flow.io">help@flow.io</a>
            if this issue persists.
          </p>
        )}
      </Confirm>
    );
  }
}

export default ReturnPolicyDeleteConfirmation;
