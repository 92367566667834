import { createSelector } from 'reselect';
import get from 'lodash/get';
import getExclusionRuleWorksheet from './getExclusionRuleWorksheet';

/**
 * Returns whether exclusion rule worksheet is currently visible.
 * @returns {Boolean}
 */
const getIsExclusionRuleWorksheetVisible = createSelector(
  getExclusionRuleWorksheet,
  (worksheet) => get(worksheet, 'visibility') === 'visible',
);

export default getIsExclusionRuleWorksheetVisible;
