import { createSelector } from 'reselect';
import get from 'lodash/get';
import filter from 'lodash/filter';

import getQueryBuilderForEditRuleWorksheet from './getQueryBuilderForEditRuleWorksheet';

const getAvailableFiltersForEditRuleWorksheet = createSelector(
  getQueryBuilderForEditRuleWorksheet,
  (queryBuilder) => filter(get(queryBuilder, 'available'), { discriminator: 'structured' }),
);

export default getAvailableFiltersForEditRuleWorksheet;
