import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { browserHistory } from 'react-router';
import { withFetch } from '@flowio/redux-fetch';
import { fetchOrganization, getBaseOrganizationCurrency } from '../../../../organization';
import ItemMarginCreate from '../components/item-margin-create';
import { fetchExperience } from '../../../actions';
import { getMarginErrors } from '../../../selectors';
import { StateProps, OwnProps, DispatchProps } from '../types';
import { RootState, ThunkDispatcher } from '../../../../../stores/types';

function getAsyncState(
  dispatch: ThunkDispatcher,
  _getState: () => RootState,
  ownProps: OwnProps,
): Promise<unknown[]> {
  const { organization, experienceKey } = ownProps.params;
  return Promise.all([
    dispatch(fetchOrganization(organization)),
    dispatch(fetchExperience(organization, experienceKey)),
  ]);
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = (state) => ({
  errors: getMarginErrors(state),
  currency: getBaseOrganizationCurrency(state),
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (_dispatch, ownProps) => {
  const { organization, experienceKey } = ownProps.params;

  return {
    onCancel(): void {
      browserHistory.push(`/${organization}/experience/${experienceKey}/pricing`);
    },
  };
};

export default withFetch(getAsyncState)(
  connect(mapStateToProps, mapDispatchToProps)(ItemMarginCreate),
);
