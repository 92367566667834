import React, { useState, useEffect, MouseEventHandler } from 'react';
import { TextField } from '@flowio/react-text-field';
import map from 'lodash/map';
import noop from 'lodash/noop';
import {
  Dialog, DialogHeader, DialogFooter, DialogActions, DialogBody,
} from '@flowio/react-dialog';
import { Button } from '@flowio/react-button';
import { css } from '@emotion/react';

import Alert from '../../../../components/alert';
import withValidation from '../../../../components/with-validation';
import { WithValidationProps } from '../../../../components/with-validation/with-validation';
import { FormattedErrorMessages } from '../../../../utilities/format-error-response-v2';
import * as styles from './export-dialog.styles';
import getTextFieldValue from '../../../../utilities/getTextFieldValue';

interface Props {
  onSubmit: Function;
  submitted: boolean;
  error?: FormattedErrorMessages;
  onCancel: MouseEventHandler;
  onConfirm: MouseEventHandler;
  submitting: boolean;
  isOpen: boolean;
}

interface ExportFormData {
  email: string;
}

const dialogBodyStyles = css({
  padding: '1.5rem 2rem',
});

const dialogFooterStyles = css({
  padding: '1.5rem 2rem',
  borderTop: '1px solid #e4e7eb',
  display: 'flex',
  flexFlow: 'row nowrap',
  textAlign: 'center',
});

const dialogActionStyles = css({
  width: '100%',
});

const ExportDialog: React.FC<WithValidationProps & Props> = ({
  submitted: submittedProp,
  onSubmit,
  error,
  fields: { email },
  onCancel,
  onConfirm,
  onSubmitValidate,
  submitting,
  isOpen,
}) => {
  const [hasSubmitted, setHasSubmitted] = useState(submittedProp);

  useEffect(() => {
    if (submittedProp && !hasSubmitted) {
      setHasSubmitted(true);
    }
  }, [hasSubmitted, submittedProp]);

  const handleSubmit = ({ isValid }: WithValidationProps, formData: ExportFormData): void => {
    if (isValid && onSubmit) {
      onSubmit(formData);
    }
  };

  return (
    <Dialog
      open={isOpen}
      backdrop
      closeOnClickAway
      className={styles.exportDialog}
    >
      <DialogHeader content="Export Classification Data" />
      <DialogBody css={dialogBodyStyles}>
        {hasSubmitted ? (
          <p>
            Your request has been submitted successfully. We will send an
            email notification to
            {' '}
            <strong>{email.value}</strong>
            {' '}
            once the export
            CSV file is created.
          </p>
        ) : (
          <form method="post" noValidate onSubmit={onSubmitValidate(handleSubmit)}>
            {error && map(error.messages, ({ message }) => (
              <Alert type="failure">
                {message}
              </Alert>
            ))}
            <p>
              An email will be sent to the email address provided once the
              export file is generated.
            </p>
            <TextField
              onChange={(e) => email.events.onChange(e)}
              onBlur={(e) => email.events.onBlur(e)}
              inline
              autoFocus
              fluid
              type="email"
              name="email"
              className={styles.emailField}
              value={getTextFieldValue(email.value)}
              intent={email.error ? 'negative' : 'neutral'}
              feedback={email.error}
              hintText="Enter your email address"
              labelCols={2}
              labelText="Email"
            />
          </form>
        )}
      </DialogBody>
      <DialogFooter css={dialogFooterStyles}>
        {hasSubmitted ? (
          <DialogActions css={dialogActionStyles}>
            <Button className={styles.footerAction} content="Got It" onClick={onConfirm} />
          </DialogActions>
        ) : (
          <DialogActions css={dialogActionStyles}>
            <Button className={styles.footerAction} content="Cancel" disabled={submitting} onClick={onCancel} />
            <Button className={styles.footerAction} type="submit" intent="primary" content="Export" disabled={submitting} onClick={onSubmitValidate(handleSubmit)} />
          </DialogActions>
        )}
      </DialogFooter>
    </Dialog>
  );
};

ExportDialog.displayName = 'ExportDialog';

ExportDialog.defaultProps = {
  error: undefined,
  onCancel: noop,
  onConfirm: noop,
  onSubmit: noop,
};

export default withValidation<Props>(() => ({
  email: {
    validations: [{
      required: true,
      message: 'An email address is required',
    }, {
      pattern: 'email',
      message: 'Please enter a valid email address',
    }],
  },
}))(ExportDialog);
