import get from 'lodash/get';
import update from 'immutability-helper';
import ActionTypes from '../constants/action-types';
import { RootActionTypes } from '../../../stores/types';
import { FeatureListState } from '../types/state';

const defaultState: FeatureListState = {
  results: {
    isFirstPage: true,
    isLastPage: true,
    results: [],
  },
  params: {},
};

export default function (state = defaultState, action: RootActionTypes) {
  switch (action.type) {
    case ActionTypes.FETCH_FEATURES_SUCCESS:
      return update(state, {
        results: { $set: action.payload },
        params: { $set: action.options.params },
      });
    case ActionTypes.CHANGE_STATUS_SUCCESS:
      return update(state, {
        results: {
          results: {
            $set: state.results.results.map((result) => {
              const targetKey = get(action, 'payload.key');
              if (result.key === targetKey) {
                return action.payload;
              }
              return result;
            }),
          },
        },
      });
    default:
      return state;
  }
}
