import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import noop from 'lodash/noop';
import { TableHeadColumn } from '@flowio/react-table';
import { ArrowUp, ArrowDown } from '@flowio/react-icons';

if (process.browser) {
  require('./sortable-table-header-column.css'); // eslint-disable-line global-require
}

export default class SortableTableHeaderColumn extends PureComponent {
  constructor(props) {
    super(props);
    const { defaultSortOrder } = this.props;
    this.state = {
      isHovered: false,
      sortOrder: defaultSortOrder,
    };
  }

  handleMouseLeave = () => {
    this.setState({ isHovered: false });
  };

  handleMouseEnter = () => {
    this.setState({ isHovered: true });
  };

  handleClick = () => {
    const { onRequestSortColumn, eventKey } = this.props;
    const currentSortOrder = this.getSortOrder();
    const desiredSortOrder = this.getInvertedSortOrder(currentSortOrder);

    onRequestSortColumn(eventKey, desiredSortOrder, currentSortOrder);

    this.setState({ sortOrder: desiredSortOrder });
  };

  getSortOrder() {
    const { sortOrder: propsSortOrder } = this.props;
    const { sortOrder: stateSortOrder } = this.state;
    return propsSortOrder || stateSortOrder;
  }

  getInvertedSortOrder(sortOrder = this.getSortOrder()) {
    switch (sortOrder) {
      case SortableTableHeaderColumn.TYPE_SORT_ORDER_ASCENDING:
        return SortableTableHeaderColumn.TYPE_SORT_ORDER_DESCENDING;
      case SortableTableHeaderColumn.TYPE_SORT_ORDER_DESCENDING:
        return SortableTableHeaderColumn.TYPE_SORT_ORDER_ASCENDING;
      default:
        return SortableTableHeaderColumn.TYPE_SORT_ORDER_ASCENDING;
    }
  }

  maybeRenderArrows() {
    const { isHovered } = this.state;
    const sortOrder = this.getSortOrder();

    const iconStyles = {
      width: '16px',
      height: '16px',
      paddingRight: '4px',
      verticalAlign: 'bottom',
    };

    if (sortOrder === SortableTableHeaderColumn.TYPE_SORT_ORDER_ASCENDING) {
      return (<ArrowUp style={iconStyles} />);
    }

    if (sortOrder === SortableTableHeaderColumn.TYPE_SORT_ORDER_DESCENDING) {
      return (<ArrowDown style={iconStyles} />);
    // Render light sort icon when hovered to conform with material design for sortable data tables.
    // https://material.google.com/components/data-tables.html#data-tables-interaction
    }

    if (isHovered) {
      return (<ArrowUp className="arrow-hovered" style={iconStyles} />);
    }

    return null;
  }

  render() {
    const { style, label } = this.props;
    const sortOrder = this.getSortOrder();

    const columnStyles = { cursor: 'pointer', ...style };

    const labelStyles = {
      verticalAlign: 'middle',
    };

    // // Update styles to conform with material design guideline for sortable data tables.
    // // https://material.google.com/components/data-tables.html#data-tables-interaction
    // if (sortOrder !== SortableTableHeaderColumn.TYPE_SORT_ORDER_NONE) {
    //   labelStyles.fontWeight = 500;
    //   labelStyles.color = 'rgba(0,0,0,0.87)';
    // }

    return (
      <TableHeadColumn
        style={columnStyles}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onClick={this.handleClick}
      >
        {this.maybeRenderArrows()}
        <span className={sortOrder !== SortableTableHeaderColumn.TYPE_SORT_ORDER_NONE ? 'sorted-label' : ''} style={labelStyles}>
          {label}
        </span>
      </TableHeadColumn>
    );
  }
}
SortableTableHeaderColumn.displayName = 'SortableTableHeaderColumn';

SortableTableHeaderColumn.propTypes = {
  /**
   * @property {String} A value passed to the `onRequestSortColumn` handler when the user's
   * intention is to sort the column represented by this component. You can use this to handle
   * multiple sortable headers from one handler.
   */
  eventKey: PropTypes.string,

  /**
   * @property {String} Indicates the initial sort order for the component when rendered.
   * Assigning a value to this property indicates the component is uncontrolled.
   * @see {@link https://facebook.github.io/react/docs/forms.html#uncontrolled-components}
   */
  defaultSortOrder: PropTypes.oneOf([
    SortableTableHeaderColumn.TYPE_SORT_ORDER_NONE,
    SortableTableHeaderColumn.TYPE_SORT_ORDER_ASCENDING,
    SortableTableHeaderColumn.TYPE_SORT_ORDER_DESCENDING,
  ]),

  /**
   * @property {String} A text rendered as the title of the table header
   */
  label: PropTypes.string.isRequired,

  /**
   * @property {Function} A callback executed when the user intents to sort the column represented
   * by this component. The callback will be passed the `eventKey` along with the desired and
   * current sort order.
   */
  onRequestSortColumn: PropTypes.func,

  /**
   * @property {String} Indicates current sort order for the component when rendered. Assigning
   * a value to this property indicates the component is controlled.
   * @see {@link https://facebook.github.io/react/docs/forms.html#controlled-components}
   */
  sortOrder: PropTypes.oneOf([
    SortableTableHeaderColumn.TYPE_SORT_ORDER_NONE,
    SortableTableHeaderColumn.TYPE_SORT_ORDER_ASCENDING,
    SortableTableHeaderColumn.TYPE_SORT_ORDER_DESCENDING,
  ]),

  /**
   * @property {Object} Override the inline-styles of the root element.
   */
  // It's not possible for us to provide a more descriptive prop type validation for styles
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
};

SortableTableHeaderColumn.defaultProps = {
  eventKey: undefined,
  onRequestSortColumn: noop,
  defaultSortOrder: SortableTableHeaderColumn.TYPE_SORT_ORDER_NONE,
  sortOrder: undefined,
  style: undefined,
};

SortableTableHeaderColumn.TYPE_SORT_ORDER_ASCENDING = 'ascending';
SortableTableHeaderColumn.TYPE_SORT_ORDER_DESCENDING = 'descending';
SortableTableHeaderColumn.TYPE_SORT_ORDER_NONE = 'none';
