import { Button } from '@flowio/react-button';
import React from 'react';
import { Stack } from '@flowio/react-stack';
import { TextField } from '@flowio/react-text-field';
import { compose } from 'redux';
import map from 'lodash/map';
import noop from 'lodash/noop';

import {
  Dialog, DialogHeader, DialogBody, DialogFooter,
} from '@flowio/react-dialog';
import { css } from '@emotion/css';
import Alert from '../../../../components/alert';
import withValidation from '../../../../components/with-validation';
import withSubmit from '../../../../components/with-submit';
import { WithSubmitProps } from '../../../../components/with-submit/with-submit';
import { WithValidationProps } from '../../../../components/with-validation/with-validation';
import getTextFieldValue from '../../../../utilities/getTextFieldValue';

const billingExportDialtyles = css`
  & p {
    margin: 0;
  }

  & .text-field {
    margin-top: 1rem;
  }

  & .dialog__container {
    max-width: 560px;
  }
`;

export interface ExportDialogProps {
  defaultEmail: string;
  enableBackdropDismiss: boolean;
  isOpen: boolean;
  showBackdrop: boolean;
  onRequestClose: () => void;
  // A function that MUST return an action to dispatch.
  onRequestSubmit: (...args: any) => any;
  title: string;
}

interface ExportDialogState {
  hasSubmitted: boolean;
}

type MergedProps = ExportDialogProps & WithSubmitProps & WithValidationProps;

export class ExportDialog extends React.PureComponent<
MergedProps,
ExportDialogState
> {
  static defaultProps = {
    defaultEmail: undefined,
    error: undefined,
    onRequestClose: noop,
    onRequestSubmit: noop,
    open: false,
  };

  constructor(props: MergedProps) {
    super(props);
    const { submitted } = this.props;
    this.state = {
      hasSubmitted: submitted,
    };
  }

  static getDerivedStateFromProps(
    props: MergedProps,
    state: ExportDialogState,
  ): ExportDialogState {
    return {
      hasSubmitted: props.submitted && !state.hasSubmitted,
    };
  }

  handleSubmit = ({ isValid }: { isValid: boolean }, formData: any): void => {
    const { onSubmit } = this.props;
    if (isValid) onSubmit(formData);
  };

  render(): React.ReactElement {
    const {
      error,
      fields: { email },
      onRequestClose,
      onSubmitValidate,
      submitting,
      title,
      enableBackdropDismiss,
      showBackdrop,
      isOpen = false,
      onSubmit,
      ...unhandledProps
    } = this.props;

    const { hasSubmitted } = this.state;

    return (
      <Dialog
        {...unhandledProps}
        closeOnClickAway={enableBackdropDismiss}
        backdrop={showBackdrop}
        open={isOpen}
        className={billingExportDialtyles}
        onClose={onRequestClose}
      >
        <DialogHeader content={title} />
        <DialogBody>
          {hasSubmitted ? (
            <p>
              Your request has been submitted successfully. We will send an
              email notification to
              {' '}
              <strong>{email.value}</strong>
              {' '}
              once the export
              CSV file is created.
            </p>
          ) : (
            <form method="post" noValidate onSubmit={onSubmitValidate(this.handleSubmit)}>
              {error && map(error.messages, ({ message }) => (
                <Alert type="failure">
                  {message}
                </Alert>
              ))}
              <p>
                An email will be sent to the email address provided once the
                export file is generated.
              </p>
              <br />
              <TextField
                onChange={(e) => email.events.onChange(e)}
                onBlur={(e) => email.events.onBlur(e)}
                inline
                fluid
                autoFocus
                type="email"
                name="email"
                value={getTextFieldValue(email.value)}
                intent={email.error ? 'negative' : 'neutral'}
                feedback={email.error}
                hintText="Enter your email address"
                labelCols={2}
                labelText="Email"
              />
            </form>
          )}
        </DialogBody>
        {hasSubmitted ? (
          <DialogFooter>
            <Button
              content="Got It"
              intent="primary"
              fluid
              onClick={onRequestClose}
              size="large"
              type="button"
            />
          </DialogFooter>
        ) : (
          <DialogFooter>
            <Stack distribution="fillEqually" spacing="loose">
              <Button
                content="Cancel"
                disabled={submitting}
                fluid
                onClick={onRequestClose}
                size="large"
                type="button"
              />
              <Button
                content="Export"
                disabled={submitting}
                fluid
                intent="primary"
                onClick={onSubmitValidate(this.handleSubmit)}
                size="large"
                type="submit"
              />
            </Stack>
          </DialogFooter>
        )}
      </Dialog>
    );
  }
}

export default compose<React.FC<ExportDialogProps>>(
  withSubmit((formData, _state, { onRequestSubmit }) => onRequestSubmit(formData)),
  withValidation(({ defaultEmail }) => ({
    email: {
      defaultValue: defaultEmail,
      validations: [{
        required: true,
        message: 'An email address is required',
      }, {
        pattern: 'email',
        message: 'Please enter a valid email address',
      }],
    },
  })),
)(ExportDialog);
