import { Plus } from '@flowio/react-icons';
import {
  Card, CardHeader, CardContent, CardFooter, CardEmptyState, CardTitle,
} from '@flowio/react-card';
import { Box } from '@flowio/react-box';
import { OutlineButton } from '@flowio/react-button';
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { PageContent } from '@flowio/react-page';

import OptinMessageChangeStatusConfirm from '../../OptinMessageChangeStatusConfirm/containers/OptinMessageChangeStatusConfirm';
import OptinMessageDeleteConfirm from '../../OptinMessageDeleteConfirm/containers/OptinMessageDeleteConfirm';
import ContentStatus from '../../../constants/ContentStatus';
import OptinMessageList from '../../OptinMessageList';
import Pagination from '../../../../../components/pagination';
import CreateOptinMessageWorksheet from '../../CreateOptinMessageWorksheet';
import { Props } from '../types';

const OptinMessageHome: React.FC<Props> = ({
  entriesPerPage,
  onPageChange,
  organizationId,
  pageNumber,
  onAddOptinMessage,
  attributes,
  onActivateOptinMessage,
  onArchiveOptinMessage,
  onRemoveOptinMessage,
  onRemoveOptinMessageSuccess,
  onAddOptinMessageSuccess,
  onViewOptinMessage,
  isFirstPage,
  isLastPage,
  isSinglePage,
}) => {
  const pageChange = (delta: number): void => {
    const nextPage = Number.parseInt(pageNumber, 10) + delta;
    onPageChange(organizationId, nextPage, entriesPerPage);
  };

  const handleAddMessageButtonClick = (): void => {
    const initialValues = { organizationId, status: ContentStatus.DRAFT };
    onAddOptinMessage({ initialValues });
  };

  const handleRequestNextPage = (): void => {
    pageChange(1);
  };

  const handleRequestPreviousPage = (): void => {
    pageChange(-1);
  };

  return (
    <PageContent>
      <Card>
        <CardHeader dividing>
          <Box justifyContent="between">
            <CardTitle content="All Opt-In Messages" />
            <OutlineButton
              content="New Message"
              intent="primary"
              leftIcon={Plus}
              onClick={handleAddMessageButtonClick}
            />
          </Box>
        </CardHeader>
        <CardContent fitted>
          {isEmpty(attributes) ? (
            <CardEmptyState content="No Messages." />
          ) : (
            <OptinMessageList
              optinAttributes={attributes}
              organizationId={organizationId}
              onActivateOptinMessage={onActivateOptinMessage}
              onArchiveOptinMessage={onArchiveOptinMessage}
              onRemoveOptinMessage={onRemoveOptinMessage}
              onViewOptinMessage={onViewOptinMessage}
            />
          )}
        </CardContent>
        <CardFooter>
          {!isSinglePage && (
            <Pagination
              isNextPageDisabled={isLastPage}
              isPreviousPageDisabled={isFirstPage}
              onRequestNextPage={handleRequestNextPage}
              onRequestPreviousPage={handleRequestPreviousPage}
              pageNumber={pageNumber}
            />
          )}
        </CardFooter>
      </Card>
      <OptinMessageChangeStatusConfirm />
      <OptinMessageDeleteConfirm
        onSubmitSuccess={onRemoveOptinMessageSuccess}
      />
      <CreateOptinMessageWorksheet
        onSubmitSuccess={onAddOptinMessageSuccess}
      />
    </PageContent>
  );
};

OptinMessageHome.displayName = 'OptinMessageHome';

export default OptinMessageHome;
