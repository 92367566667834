const DialogName = {
  CONNECT_ACCOUNT_CONFIRM: 'connectAccountConfirm',
  EDIT_FEED_CONFIG: 'editFeedConfig',
  ADD_COUNTRY_FEED_CONFIG: 'addCountryFeedConfig',
  DELETE_FEED_CONFIRM: 'deleteFeedConfirm',
  DOWNLOAD_FEEDS_DIALOG: 'downloadFeedsDialog',
  FB_COUNTRY_FEED_DIALOG: 'addFacebookCountryFeedDialog',
  AUTH_DIALOG: 'addAuthDialog',
  MARKET_CONFIGURATIONS_WORKSHEET: 'addMarketConfigurationsWorksheet',
  DISCONNECT_PLATFORM: 'disconnectPlatform',
  DISCONNECT_CHANNEL: 'disconnectChannel',
  REQUST_CHANNELS: 'requestChannels',
};

export default DialogName;
