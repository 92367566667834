import ActionTypes from '../constants/action-types';
import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';

function createRequestAction(): RootActionTypes {
  return {
    type: ActionTypes.FETCH_QUERY_BUILDER_STATUS_FILTERS_REQUEST,
  };
}

function createSuccessAction(payload: io.flow.v0.models.QueryBuilder): RootActionTypes {
  return {
    type: ActionTypes.FETCH_QUERY_BUILDER_STATUS_FILTERS_SUCCESS,
    payload,
  };
}

function createFailureAction(payload: FormattedErrorMessages): RootActionTypes {
  return {
    type: ActionTypes.FETCH_QUERY_BUILDER_STATUS_FILTERS_FAILURE,
    payload,
  };
}

export default function fetchQueryBuilderFeatureStatusFilters(
  organization: string,
  options: io.flow.v0.models.QueryBuilderQueryForm,
): ThunkResult<Promise<void>> {
  return function getchQueryBuilderFeatureStatusFiltersEffect(
    dispatch,
    getState,
    extra,
  ): Promise<void> {
    dispatch(createRequestAction());

    return extra.apiInternal(getState()).features.postQueryAndBuilders({
      organization,
      body: options,
    }).then((response) => {
      if (response.ok) {
        dispatch(createSuccessAction(response.body));
        return;
      }

      const error = formatErrorResponseV2(response);
      dispatch(createFailureAction(error));
    });
  };
}
