import React from 'react';
import { FormGroup } from '@flowio/react-form-group';
import { Field } from 'redux-form';
import { TextField, Checkbox } from '../../../../../components/ReduxFormFieldAdapters';
import presence from '../../../../../utilities/validators/presence';
import * as styles from './order-export-form.styles';

const handleMinHoursValidation = [
  presence({ allowEmpty: false, message: 'Min hours is required' }),
];

const OrderExportsForm = () => (
  <div className={'order-form'}>
    <FormGroup className={styles.formGroup} gutter labelCols={4} inline labelSize="medium" labelFor="file-type" labelText="">
      <Field
        component={TextField}
        validate={handleMinHoursValidation}
        className={styles.minHoursSinceSubmittedAt}
        name="min_hours_since_submitted_at"
        type="number"
        inline
        labelText="Hours Since Last Submitted"
        hintText="24"
      />
    </FormGroup>
    <FormGroup className={styles.formGroup} labelCols={4} inline labelSize="medium" labelFor="file-type" labelText="">
      <Field
        className={styles.checkBox}
        component={Checkbox}
        type="checkbox"
        name="has_tracking_label"
      />  <span>Has tracking label?</span>
    </FormGroup>
  </div>
);

export default OrderExportsForm;
