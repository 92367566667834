import { css } from '@emotion/css';

export const billingExportDialog = css`
  max-width: 500px;

  & p {
  }

  & .text-field {
    margin-top: 1rem;
  }

  & .dialog__container {
    max-width: 560px;
  }
`;

export const textField = css`
  legend {
    margin-top: 10px;
  }
`;
