import React from 'react';
import { Breadcrumbs } from '@flowio/react-breadcrumbs';
import * as styles from './MarketingPage.styles';
import { BreadcrumbProps } from './types';

const MarketingBreadcrumbs: React.FC<BreadcrumbProps> = ({
  breadcrumbItems,
}) => (
  <div className={styles.marketingBreadcrumbs}>
    <Breadcrumbs items={breadcrumbItems} />
  </div>
);

MarketingBreadcrumbs.displayName = 'MarketingBreadcrumbs';

export default MarketingBreadcrumbs;
