import React from 'react';
import {
  Accordion, Panel, PanelContent, PanelHeader,
} from '@flowio/react-panel';
import OrderAddressComponent from './order-address';
import * as styles from '../order-details/order-details.styles';

const displayName = 'OrderShipping';

interface OrderShippingProps {
  isShippingAddressUnchanged?: boolean;
  originalAddress?: io.flow.v0.models.OrderAddress;
  destination: io.flow.v0.models.OrderAddress;
}

const defaultProps = {
  isShippingAddressUnchanged: true,
  originalAddress: undefined,
};

const OrderShipping: React.FC<OrderShippingProps> = ({
  destination,
  isShippingAddressUnchanged = true,
  originalAddress,
}) => (
  <div className="order-details">
    {!isShippingAddressUnchanged && (
      <div className={styles.addressHeader}>Updated Address</div>
    )}
    <OrderAddressComponent destination={destination} />
    {!isShippingAddressUnchanged && originalAddress && (
      <Accordion
        className={styles.updatedAddressAccordion}
        defaultActiveKey="false"
      >
        <Panel
          eventKey="1"
          css={styles.updatedAddressPanel}
          collapsible
        >
          <PanelHeader className={styles.updatedAddressTitle} title="Address Provided by the Customer" />
          <PanelContent>
            <OrderAddressComponent
              css={styles.updatedAddressContent}
              destination={originalAddress}
            />
          </PanelContent>
        </Panel>
      </Accordion>
    )}
  </div>
);

OrderShipping.displayName = displayName;
OrderShipping.defaultProps = defaultProps;

export default OrderShipping;
