import { Copy, DownloadOutline, InfoCircleOutline } from '@flowio/react-icons';
import { css } from '@emotion/react';
import {
  TableRow,
  TableRowColumn,
} from '@flowio/react-table';
import capitalize from 'lodash/capitalize';
import React, { useCallback } from 'react';
import { findCountryByCode } from '../../utilities/findReferenceData';
import * as styles from './FeedItem.styles';
import CountryFlag from '../../../reference/components/CountryFlag';
import withNumberFormat from '../../../../utilities/numbers/withNumberFormat';
import Platforms from '../../constants/Platforms';
import Tooltip from '../MarketingTooltip';
import { colors } from '../../../../theme/tokens';

const ctaStyles = (disabled = false) => css`
  pointer-events: ${disabled ? 'none' : 'inherit'};
  cursor: pointer;
  fill: ${disabled ? colors.neutral[200] : colors['flow-blue'][400]};

  :hover {
    fill: ${colors.marketing.positive};
  }
`;

const toolTipTriggerStyles = css`
  fill: ${colors.marketing['dark-slate-blue']};
  cursor: pointer;
  margin-left: 8px;
  padding-right: 2px;
`;

type Props = {
  isSourceFeed?: boolean;
  isCombinedFeedChannel?: boolean;
  feed: io.flow.internal.v0.models.MarketingGatewayFeed;
  dataSource?: string;
  onCopy: (url: string, country: string) => void;
  countries: io.flow.v0.models.Country[];
  languages: io.flow.v0.models.Language[];
  platform: io.flow.internal.v0.enums.MarketingGatewayPlatform;
};

type FeedStatusProps = {
  state: io.flow.internal.v0.enums.MarketingGatewayFeedState,
  platform: io.flow.internal.v0.enums.MarketingGatewayPlatform,
};

const FeedStatus: React.FC<FeedStatusProps> = ({ state, platform }) => {
  const { platformName } = Platforms(platform);

  switch (state) {
    case 'active':
      return <span className={styles.green}>Active</span>;
    case 'generated':
      return <span className={styles.green}>Ready</span>;
    case 'initialized':
      return (
        <span>
          <i>Generating</i>
          <Tooltip
            openOnHover
            position="right"
            trigger={<InfoCircleOutline css={toolTipTriggerStyles} width={15} />}
            content={`You can copy and paste this feed URL into ${platformName} now. Product data will be automatically updated in ${platformName} when this feed is ready.`}
          />
        </span>
      );
    default:
      return <span>{capitalize(state)}</span>;
  }
};

const FeedItem: React.FC<Props> = React.memo(({
  feed: {
    id, country, tsv_download_url: tsvDownloadUrl,
    feed_state: feedState,
    item_count: itemCount,
    tsv_channel_download_url: tsvChannelDownloadUrl,
  },
  isSourceFeed,
  isCombinedFeedChannel,
  onCopy,
  countries,
  platform,
}) => {
  const { platformName } = Platforms(platform);
  const countryObj = findCountryByCode(countries, country);

  const handleDownload = useCallback(() => {
    window.open(tsvChannelDownloadUrl || tsvDownloadUrl, '_blank');
  }, [tsvChannelDownloadUrl, tsvDownloadUrl]);

  const handleCopyLink = useCallback(() => {
    onCopy(tsvDownloadUrl, countryObj ? countryObj.name : 'Country');
  }, [countryObj, onCopy, tsvDownloadUrl]);

  return (
    <TableRow className={styles.feedItem} key={id}>
      <TableRowColumn textAlign="left">
        {isCombinedFeedChannel ? (
          <span className={styles.feedCountry}>
            <span>Localized Product Feed</span>
            <Tooltip
              openOnHover
              position="right"
              trigger={<InfoCircleOutline width={15} className="infoIcon" />}
              content={(
                <span>
                  {`Localized product feed for ${platformName} includes product data for all countries you have created country-specific Experiences for in Flow.`}
                  <br />
                  <br />
                  {`This customized product feed file enables you to run international ads under 1 ${platformName} account. Please contact ${platformName} support to set it up.`}
                </span>
              )}
            />
          </span>
        ) : (
          <span className={styles.feedCountry}>
            {countryObj && <CountryFlag country={countryObj} />}
            <span className="feedCountryName">{countryObj ? countryObj.name : country}</span>
            {isSourceFeed && (
              <Tooltip
                openOnHover
                position="right"
                trigger={<InfoCircleOutline width={15} className="infoIcon" />}
                content={`This feed contains the same data as the product feed from your data source, but formatted to meet ${platformName}-specific requirements.`}
              />
            )}
          </span>
        )}

      </TableRowColumn>
      <TableRowColumn textAlign="center">{itemCount ? withNumberFormat(itemCount) : '-'}</TableRowColumn>
      <TableRowColumn textAlign="center"><FeedStatus state={feedState} platform={platform} /></TableRowColumn>

      <TableRowColumn textAlign="center">
        <Copy
          width={15}
          css={ctaStyles(false)}
          onClick={(): void => handleCopyLink()}
        />
      </TableRowColumn>
      <TableRowColumn textAlign="center">
        <DownloadOutline
          width={15}
          css={ctaStyles(['generated', 'active'].indexOf(feedState) < 0)}
          onClick={(): void => handleDownload()}
        />
      </TableRowColumn>
    </TableRow>
  );
});

FeedItem.displayName = 'FeedItem';

export default FeedItem;
