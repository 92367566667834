import Client from './client';

export default class Features extends Client {
  constructor(opts) {
    let options = opts;

    if (typeof opts === 'string') {
      options = { host: opts }; // convert host string to options object
    }

    options.serviceName = 'API Internal';

    super(options);
  }

  get(organization, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/features`, options);
  }

  post(organization, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/features`, {
      ...options,
       method: 'POST',
    });
  }

  postQueryAndBuilders(organization, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/features/query/builders`, {
      ...options,
       method: 'POST',
    });
  }

  postRuleAndQueryAndBuilders(organization, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/features/rule/query/builders`, {
      ...options,
       method: 'POST',
    });
  }

  getByKey(organization, key, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/features/${key}`, options);
  }

  putByKey(organization, key, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/features/${key}`, {
      ...options,
       method: 'PUT',
    });
  }

  deleteByKey(organization, key, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/features/${key}`, {
      ...options,
       method: 'DELETE',
    });
  }

  putReleaseByKey(organization, key, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/features/${key}/release`, {
      ...options,
       method: 'PUT',
    });
  }

  postRulesByKey(organization, key, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/features/${key}/rules`, {
      ...options,
       method: 'POST',
    });
  }

  putRulesByKeyAndId(organization, key, id, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/features/${key}/rules/${id}`, {
      ...options,
       method: 'PUT',
    });
  }

  deleteRulesByKeyAndId(organization, key, id, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/features/${key}/rules/${id}`, {
      ...options,
       method: 'DELETE',
    });
  }

  putStatusByKey(organization, key, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/features/${key}/status`, {
      ...options,
       method: 'PUT',
    });
  }

}
