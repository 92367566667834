import { RootActionTypes } from '../../../stores/types';
import { ActionTypes } from '../constants';

/**
 * An action responsible for invalidating the experiences associated with the
 * specified shipping configuration. Ultimately, this will force the application
 * to re-fetch next time it needs to render the experiences associated with
 * the specified shipping configuration.
 * @param {String} props.shippingConfigurationKey
 * @returns {Object}
 */
function invalidateShippingConfigurationExperiences(
  shippingConfigurationKey: string,
): RootActionTypes {
  return {
    type: ActionTypes.INVALIDATE_SHIPPING_CONFIGURATION_EXPERIENCES,
    payload: shippingConfigurationKey,
  };
}

export default invalidateShippingConfigurationExperiences;
