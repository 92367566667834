import React from 'react';
import { Card, CardContent } from '@flowio/react-card';
import { Page, PageHeader, PageContent } from '@flowio/react-page';
import { Plus } from '@flowio/react-icons';
import DeleteMembershipConfirm from '../../../containers/delete-membership-confirm';

import MembershipList from '../../membership-list';
import { MergedProps as Props } from '../types';
import MembershipWorksheet from '../../membership-worksheet';
import { isFlowGlobaleMember } from '../../../utilities';

const Memberships: React.FC<Props> = ({
  editingMembership,
  organizationKey,
  membershipDialogOpen,
  memberships,
  user,
  isFlowEmployee,
  onFormSubmit,
  onCloseMembershipDialog,
  onOpenMembershipDialog,
  onRemoveMember,
  onRequestSubmitInvitationForm,
}) => {
  const onEditMember = (membership: io.flow.v0.models.Membership): void => {
    onOpenMembershipDialog(membership);
  };

  return (
    <Page>
      <PageHeader
        title={{
          content: 'Organization Members',
        }}
        primaryActions={[
          {
            content: 'Invite Member',
            intent: 'primary',
            leftIcon: Plus,
            onClick: (): void => onOpenMembershipDialog(),
          },
        ]}
      />
      <PageContent>
        <Card>
          <CardContent>
            <MembershipList
              onEditMember={onEditMember}
              memberships={isFlowEmployee ? memberships : memberships
                .filter((m) => !isFlowGlobaleMember(m))}
              onRemoveMember={onRemoveMember}
              user={user}
            />
          </CardContent>
        </Card>
      </PageContent>
      <MembershipWorksheet
        open={membershipDialogOpen}
        onRequestClose={onCloseMembershipDialog}
        onRequestSubmitInvitationForm={onRequestSubmitInvitationForm}
        onFormSubmit={onFormSubmit}
        userMembership={user}
        editMembership={editingMembership || null}
        organizationKey={organizationKey}
      />
      <DeleteMembershipConfirm />
    </Page>
  );
};

Memberships.displayName = 'Memberships';

export default Memberships;
