import { ActionTypes } from '../constants';
import {
  ThunkResult,
  ThunkDispatcher,
  RootState,
  ThunkExtraArguments,
} from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import toLegacyResponse from '../../../utilities/clients/to-legacy-response';

export default function verifyInvitationUser():
ThunkResult<Promise<LegacyResponse<io.flow.v0.models.User[] | FormattedErrorMessages>>> {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<LegacyResponse<io.flow.v0.models.User[] | FormattedErrorMessages>> => {
    dispatch({
      type: ActionTypes.VERIFY_USER_REQUEST,
    });
    const state = getState();
    const userId = state.user.id;
    const email = state.account.invitation?.email;

    if (userId == null) {
      throw new Error('verifyInvitationUser: missing required [userId].');
    }

    return extra.api(state).users.get({
      id: [userId],
      email,
    }).then((response) => {
      if (response.ok) {
        dispatch({
          type: ActionTypes.VERIFY_USER_SUCCESS,
          payload: response.body,
        });
        return toLegacyResponse(response);
      }

      const formattedErrors = formatErrorResponseV2(response);

      dispatch({
        type: ActionTypes.VERIFY_USER_FAILURE,
        payload: formattedErrors,
      });

      return toLegacyResponse({
        ...response,
        body: formattedErrors,
      });
    });
  };
}
