import { LegacyResponse } from '../types/server';
import Client from './client';
import { AuthResponse } from './types';

export default class Facebook extends Client {
  constructor(host: string, auth: string, headers: Record<string, string>) {
    super({
      serviceName: 'server',
      host,
      auth,
      headers,
    });
  }

  postToken(org: string, options = {}): Promise<LegacyResponse<AuthResponse>> {
    Object.assign(options, { method: 'POST' });
    return this.makeRequest(`${this.host}/${org}/auth/facebook/token`, options);
  }
}
