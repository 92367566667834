import { css } from '@emotion/css';
import colors from '../../../../theme/tokens/colors';

export const headerCol = css`
  color: ${colors.aluminium};
  font-weight: 500;
  &.first {
    padding-left: 1rem !important;
  }

  &.submitted {
    width: 170px;
  }

  &.price {
    width: 1%;
    white-space: nowrap;
    text-align: center;
  }
`;

export const notSubmitted = css`
  color: ${colors.steel};
`;
