import flow from 'lodash/flow';
import map from 'lodash/map';
import property from 'lodash/property';
import uniqBy from 'lodash/uniqBy';

export default flow([
  // Pluck services from ratecards...
  (ratecards) => map(ratecards, property('service')),
  // For some reason multiple ratecards may refer to the same service.
  // Remove duplicate services.
  (services) => uniqBy(services, property('id')),
]);
