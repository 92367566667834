import React, { FunctionComponent } from 'react';
import { PageErrorProps } from '../types/props';

const displayName = 'PageError';

const PageError: FunctionComponent<PageErrorProps> = ({ title, message }) => (
  <section className="full-screen flex items-center">
    <section className="mx-auto 500">
      <p className="h1 center">
        {title}
      </p>
      <p className="center">
        {message}
      </p>
    </section>
  </section>
);

PageError.displayName = displayName;

export default PageError;
