import {
  ThunkDispatcher, ThunkResult,
} from '../../../stores/types';
import standardAction from '../../../utilities/standard-action';
import ActionTypes from '../constants/action-types';
import { server } from '../../../utilities/clients';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import ErrorTypes from '../constants/error-types';

const saveToken = (
  organization: string,
  accessToken: string,
): ThunkResult<Promise<LegacyResponse>> => (
  dispatch: ThunkDispatcher,
): Promise<LegacyResponse> => {
  const { facebook: FBClient } = server;

  dispatch(standardAction(ActionTypes.SAVE_ACCESS_TOKEN_REQUEST));

  return FBClient.postToken(organization, {
    body: JSON.stringify({ accessToken }),
  }).then((response) => {
    if (response.ok) {
      dispatch(standardAction(ActionTypes.SAVE_ACCESS_TOKEN_SUCCESS));
      return response;
    }

    const error = new Error(ErrorTypes.TOKEN_SAVE_ERROR);
    dispatch(standardAction(ActionTypes.SAVE_ACCESS_TOKEN_FAILURE, error));
    throw error;
  });
};

export default saveToken;
