import { Field } from 'redux-form';
import React from 'react';
import curry from 'lodash/curry';
import get from 'lodash/get';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import property from 'lodash/property';
import map from 'lodash/map';
import isUndefined from 'lodash/isUndefined';
import stubTrue from 'lodash/stubTrue';
import { css } from '@emotion/react';
import { DeliveredDuty as DeliveredDuties } from '@flowio/api-constants';
import { DeliveredDutyOptionMessageType } from '@flowio/api-internal-constants';

import { InfoCircle } from '@flowio/react-icons';
import { Tooltip } from '@flowio/react-tooltip';
import { DeliveredDutyFormOption } from '../types';
import { SelectField } from '../../../../../components/ReduxFormFieldAdapters';

const infoCircleStyle = css`
  margin-left: 8px;
`;

const tooltipStyle = css`
  width: 200px !important;
  color: white !important;
`;

const mapDeliveredDutyMethodToAcronym = (
  deliveredDutyMethods: io.flow.v0.enums.DeliveredDuty[],
): string[] => (
  map(deliveredDutyMethods, (deliveredDutyMethod) => {
    switch (deliveredDutyMethod) {
      case DeliveredDuties.PAID:
        return 'Paid';
      case DeliveredDuties.UNPAID:
        return 'Unpaid';
      default:
        return deliveredDutyMethod;
    }
  })
);

const getDeliveredDutyOptionDisplay = (
  deliveredDutyOption: io.flow.internal.v0.models.DeliveredDutyOption,
): string => {
  const [methodA, methodB] = mapDeliveredDutyMethodToAcronym(deliveredDutyOption.methods);
  return isUndefined(methodB) ? `${methodA} only` : `Both ${methodA} and ${methodB}`;
};

const getDeliveredDutyOptionIdentifier = (
  deliveredDutyOption: io.flow.internal.v0.models.DeliveredDutyOption,
): io.flow.v0.enums.DeliveredDuty | 'all' => {
  const [methodA, methodB] = deliveredDutyOption.methods;
  return isUndefined(methodB) ? methodA : 'all';
};

// Our delivered duty option model does not have properties by which we can
// display or identify them in the dropdown. This method extends the model
// with displayBy and identifyBy properties to be used for that purpose in
// the dropdown. It is strictly based on the fact that there are only two
// possible delivered duty methods: paid and unpaid.
const getCompatibleDeliveredDutyOptions = (
  deliveredDutyOptions: io.flow.internal.v0.models.DeliveredDutyOption[],
): (
  DeliveredDutyFormOption & io.flow.internal.v0.models.DeliveredDutyOption
  )[] => map(deliveredDutyOptions, (deliveredDutyOption) => ({
  ...deliveredDutyOption,
  displayBy: getDeliveredDutyOptionDisplay(deliveredDutyOption),
  identifyBy: getDeliveredDutyOptionIdentifier(deliveredDutyOption),
}));

// Iterates through delivered duty options and returns the first to match a
// deep comparison of the specified delivered duty methods.
const findDeliveredDutyOption = curry((
  deliveredDutyOptions: io.flow.internal.v0.models.DeliveredDutyOption[],
  deliveredDutyMethods: io.flow.v0.enums.DeliveredDuty[],
) => (
  find(deliveredDutyOptions, (deliveredDutyOption) => (
    isEqual(deliveredDutyOption.methods, deliveredDutyMethods)))
));

const handleOptionProps = (
  option: io.flow.internal.v0.models.DeliveredDutyOption,
  _index: number,
  props: { labelKey: string; valueKey: string },
): { content: string; value: string; icon: string | undefined; disabled: boolean } => {
  const { labelKey, valueKey } = props;
  const hasWarningMessage = get(option, 'message.type') === DeliveredDutyOptionMessageType.WARNING;
  return {
    content: get(option, labelKey),
    value: get(option, valueKey),
    icon: hasWarningMessage ? 'Alert' : undefined,
    disabled: !option.selectable,
  };
};

interface Props {
  deliveredDutyOptions: io.flow.internal.v0.models.DeliveredDutyOptions;
  hasWarningMessage: boolean;
}

const DeliveredDutyMethods: React.FC<Props> = ({
  deliveredDutyOptions,
  hasWarningMessage,
}) => {
  const options = getCompatibleDeliveredDutyOptions(deliveredDutyOptions.options);

  return (
    <Field
      component={SelectField}
      name="methods"
      format={findDeliveredDutyOption(options)}
      parse={property('methods')}
      shouldWarn={stubTrue}
      fluid
      disabled={hasWarningMessage}
      hintText="Select Duty Method"
      id="methods"
      inline
      labelCols={5}
      labelIcon={(
        <Tooltip
          openOnHover
          css={tooltipStyle}
          trigger={<InfoCircle width={16} css={infoCircleStyle} />}
        >
          Choose which duty methods to offer to customers - paid, unpaid, or both.
        </Tooltip>
    )}
      labelFor="methods"
      labelKey="displayBy"
      labelText="Tax and Duty Method"
      options={options}
      optionProps={handleOptionProps}
      valueKey="identifyBy"
      gutter
    />
  );
};

DeliveredDutyMethods.displayName = 'DeliveredDutyMethods';

export default DeliveredDutyMethods;
