import { css } from '@emotion/css';

export const organizationApiKeys = css`
  & .product-catalog-query-target {
    margin-bottom: 1rem;
    display: flex;
  }
`;

export const shortKey = css`
  color: rgba(0, 0, 0, 0.6);
  margin-left: 0.5rem;
`;

export const newApiKey = css`
  display: flex;
  align-items: flex-start;

  & > div:first-child {
    flex: 1 1 auto;
  }
`;

export const newApiKeyDescription = css`
  flex: auto;
`;

export const newApiKeyButton = css`
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: 7px;
`;

export const description = css`
  flex: auto;
`;

export const activeKeysActions = css`
  line-height: 40px;
  z-index: 100;
`;

export const cleartextContainer = css`
  border: 1px solid rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  font-size: 2rem;
  padding: 1rem;
  word-wrap: break-word;
`;

export const environment = css`
  margin-left: 0.5rem;
`;

export const sandboxAlert = css`
  margin-top: 1rem;
`;

export const subtitle = css`
  font-size: 1.1rem;
  font-weight: 400;
`;
