import React from 'react';
import ContentStatus from '../../../constants/ContentStatus';
import StatusLabel from '../../StatusLabel';
import { Props } from '../types';

const OptinMessageStatus: React.FC<Props> = ({ optinAttribute }) => {
  switch (optinAttribute.status) {
    case ContentStatus.LIVE:
      return (<StatusLabel intent="positive" content="active" />);
    case ContentStatus.DRAFT:
      return (<StatusLabel intent="warning" content="draft" />);
    case ContentStatus.ARCHIVED:
      return (<StatusLabel intent="neutral" content="archived" />);
    default:
      return (<StatusLabel intent="neutral" content={optinAttribute.status} />);
  }
};

OptinMessageStatus.displayName = 'OptinMessageStatus';

export default OptinMessageStatus;
