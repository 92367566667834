import { createSelector } from 'reselect';
import get from 'lodash/get';

import getExclusionState from './getExclusionState';
import { ExclusionState, ExclusionRulePaginationState } from '../types';
import { RootState } from '../../../stores/types';

const getExclusionRulesPaginationState = createSelector<
RootState, ExclusionState, ExclusionRulePaginationState>(
  getExclusionState,
  (state) => get(state, 'entities.exclusionRules'),
);

export default getExclusionRulesPaginationState;
