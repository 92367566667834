import { Banner } from '@flowio/react-banner';
import styled from '@emotion/styled';
import { Box } from '@flowio/react-box';
import isEmpty from 'lodash/isEmpty';
import capitalize from 'lodash/capitalize';
import React, { useCallback } from 'react';
import { Link } from 'react-router';
import Button from '../../MarketingButton';
import {
  MANUAL_PRODUCT_FEEDS_TITLE,
  MANUAL_LOCALIZED_FEEDS_TITLE,
} from '../../../constants/Feeds';
import CountryFeeds from '../../CountryFeeds/CountryFeeds';
import MarketingEmptyState from '../../MarketingEmptyState/MarketingEmptyState';
import MarketingCard, { MarketingCardHeading } from '../../MarketingCard';
import ProductDataSource from '../../ProductDataSource/ProductDataSource';
import ExternalLink from '../../ExternalLink';
import { MergedProps as Props } from '../types';
import ManualChannelsCreatedDialog from '../../ManualChannelsCreatedDialog';
import MarketingPage from '../../MarketingPage';
import Platforms from '../../../constants/Platforms';
import { colors } from '../../../../../theme/tokens';
import Tooltip from '../../MarketingTooltip';
import FeedsPageTitle from '../../FeedsPageTitle';

const ChangeSource = styled.p({
  fontSize: '12px',
  margin: '24px 0 0',
  color: colors.marketing['light-slate-gray'],
  a: {
    color: 'currentcolor',
    textDecoration: 'underline',
  },
});

const InstructionsBanner = styled(Banner)({
  margin: '40px 0 16px',
  fontSize: '14px',
  fontWeight: 500,
  borderRadius: '4px',
  backgroundColor: colors['flow-blue'][100],
  color: colors['flow-blue'][400],
  lineHeight: '40px',
  padding: '0 16px',
  span: {
    fontSize: '12px',
    padding: '5px 10px',
    borderRadius: '4px',
    backgroundColor: colors['flow-blue'][200],
    color: colors['flow-blue'][500],
    marginRight: '8px',
  },
});

const dataSourceDescription = (p: io.flow.internal.v0.enums.MarketingGatewayPlatform) => `Flow imports product data (product IDs, titles, descriptions) from your data source to generate ${capitalize(p)}-specific localized product feeds.`;

const ManualFeeds: React.FC<Props> = React.memo(({
  params: { organization },
  location: { query: { isNew } },
  countries,
  onClickAddCountryFeed,
  onDownloadFeedsButtonClick,
  productFeeds,
  languages,
  localizedFeeds,
  feedSource,
  onCopied,
  platform,
}) => {
  const isFacebook = platform === 'facebook';
  const { platformName, accountUrl } = Platforms(platform);

  const localizedFeedsDescription = () => {
    const tooltip = (
      <Tooltip openOnHover trigger={<u>available country</u>}>
        Available countries include countries you have created a country
        specific Experience for in Flow.
      </Tooltip>
    );

    return (
      <>
        {`Flow generates ${platformName}-specific product feeds with product data from your selected data source and localized data from your Flow Experience settings for each `}
        {tooltip}
        {`. Set up these feeds in ${platformName} by simply copying and pasting the feed URLs into your `}
        {
          accountUrl ? (
            <ExternalLink href={accountUrl}>
              <u>
                {`${platformName} account`}
              </u>
            </ExternalLink>
          ) : (
            <span>
              {`${platformName} account`}
            </span>
          )
        }
        .
      </>
    );
  };

  const handleAddFeed = (): void => onClickAddCountryFeed(organization, productFeeds);

  const handleDownloadFeedsButtonClick = (): void => onDownloadFeedsButtonClick(
    localizedFeeds.map((feed) => feed.id),
  );

  const copyToClipboard = useCallback((
    url: string,
    country: string,
  ) => {
    navigator.clipboard.writeText(url);
    onCopied(country);
  }, [onCopied]);

  const hasCountryFeeds = !isEmpty(localizedFeeds);

  const breadcrumbItems = [
    {
      content: 'Product Feeds',
      as: Link,
      to: `/${organization}/marketing`,
    },
    {
      content: `${Platforms(platform).shortName} Feeds`,
      active: true,
    },
  ];

  return (
    <MarketingPage
      heading={<FeedsPageTitle platform={platform} />}
      breadcrumbItems={breadcrumbItems}
    >
      <MarketingCard boxShadow>
        <MarketingCardHeading
          title={MANUAL_PRODUCT_FEEDS_TITLE}
          showDivider={false}
          subtitle={dataSourceDescription(platform)}
        />
        {feedSource && (
          <ProductDataSource
            languages={languages}
            countries={countries}
            feedSource={feedSource}
          />
        )}
        <ChangeSource>
          If you wish to change the data source, please contact&nbsp;
          <a href="mailto:support@flow.io" target="_blank" rel="noopener noreferrer">support@flow.io</a>
          .
        </ChangeSource>
      </MarketingCard>
      <br />
      <MarketingCard data-automation-id="country-feeds-table" boxShadow>
        <MarketingCardHeading
          title={MANUAL_LOCALIZED_FEEDS_TITLE}
          showDivider={false}
          subtitle={localizedFeedsDescription()}
        >
          {hasCountryFeeds
            ? (
              <Box justifyContent="end" spacing="loose" alignItems="center">
                {accountUrl && (
                  <ExternalLink href={accountUrl} style={{ color: colors.marketing['dark-slate-blue'], fontSize: '14px' }}>
                    Link to
                    {' '}
                    {platformName}
                  </ExternalLink>
                )}
                <Button
                  disabled={localizedFeeds.some((feed) => feed.feed_state === 'initialized')}
                  onClick={handleDownloadFeedsButtonClick}
                >
                  Download All Feeds
                </Button>
                {isFacebook && (
                  <Button
                    intent="positive"
                    data-automation-id="manual-add-feeds"
                    onClick={handleAddFeed}
                  >
                    Add Country
                  </Button>
                )}
              </Box>
            )
            : null }
        </MarketingCardHeading>
        <InstructionsBanner>
          <span>
            Import Feeds to&nbsp;
            {platformName}
          </span>
          {platform === 'criteo'
            ? 'Please contact Criteo support to set up this localized product feed in your Criteo account.'
            : `Please copy and paste the localized product feed URLs into your ${platformName} account.`}
        </InstructionsBanner>
        {hasCountryFeeds ? (
          <CountryFeeds
            languages={languages}
            countries={countries}
            onCopy={copyToClipboard}
            countryFeeds={localizedFeeds}
            platform={platform}
            feedSource={feedSource}
          />
        ) : (
          <MarketingEmptyState
            title="You don't have any Localized Feeds set up yet"
            subtitle={`Add localized product information to ${platformName} with feeds created by Flow.`}
          >
            {isFacebook && (
            <Button
              data-automation-id="manual-add-feeds"
              intent="positive"
              onClick={handleAddFeed}
              content="Add Localized Product Feeds"
            />
            )}
          </MarketingEmptyState>
        )}
      </MarketingCard>
      <div>hello</div>
      {isNew && (
        <ManualChannelsCreatedDialog platform={platform} numFeeds={localizedFeeds.length} />
      )}
    </MarketingPage>
  );
});

ManualFeeds.displayName = 'ManualFeeds';

export default ManualFeeds;
