import {
  OrderAddressValidationsGetOrderValidationByOrganizationAndOrderNumberResponse,
} from '@flowio/api-internal-sdk';
import { ThunkResult } from '../../../stores/types';

export type FetchOrderAddressValidationAction = {
  type: 'FETCH_ORDER_ADDRESS_VALIDATION',
  payload: io.flow.internal.v0.models.OrderValidation | null,
};

export default function fetchOrderAddressValidation(
  organization: string,
  orderNumber: string,
): ThunkResult<Promise<
  OrderAddressValidationsGetOrderValidationByOrganizationAndOrderNumberResponse
  >> {
  return (dispatch, getState, extra) => extra.apiInternal(
    getState(),
  ).orderAddressValidations.getOrderValidationByOrganizationAndOrderNumber({
    organization,
    order_number: orderNumber,
  }).then((response) => {
    if (response.ok) {
      dispatch({
        type: 'FETCH_ORDER_ADDRESS_VALIDATION',
        payload: response.body,
      });
    } else {
      dispatch({
        type: 'FETCH_ORDER_ADDRESS_VALIDATION',
        payload: null,
      });
    }
    return response;
  });
}
