import { Download } from '@flowio/react-icons';
import {
  Card, CardHeader, CardContent, CardFooter, CardEmptyState, CardTitle,
} from '@flowio/react-card';
import { Box } from '@flowio/react-box';
import React, { useState } from 'react';
import { SelectField, SelectOption } from '@flowio/react-select';
import { IconButton } from '@flowio/react-button';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import get from 'lodash/get';
import find from 'lodash/find';
import { ReportInterval } from '@flowio/api-internal-constants';

import { css } from '@emotion/css';
import ExportDialog from '../ExportDialog/containers/ExportDialog';
import Metric from '../../constants/metric';
import TimelineChart from '../TimelineChart';
import withCurrency from '../../utilities/with-currency';

const firstActionStyles = css`
  padding-right: 12px;
`;

const exportButtonStyles = css`
  margin-left: 10px;
  cursor: pointer;
`;

interface Props {
  countries: io.flow.v0.models.Country[];
  currency: string;
  endDate: string;
  interval: ReportInterval;
  onRequestIntervalChange: Function;
  onRequestRegionChange: Function;
  organizationCountry: string;
  organizationCurrency: string;
  region: string;
  timeline?: io.flow.internal.v0.models.OrderRevenueTimelineChart;
  type: string;
}

interface IntervalOption {
  content: string;
  value: ReportInterval;
}

const TimelineCard: React.FC<Props> = ({
  countries,
  currency,
  endDate,
  interval,
  onRequestIntervalChange,
  onRequestRegionChange,
  organizationCountry,
  organizationCurrency,
  region,
  timeline,
  type,
}) => {
  const [exportDialogOpen, setExportDialogOpen] = useState<boolean>(false);

  const handleIntervalChange = (value: string): void => {
    onRequestIntervalChange({ interval: value });
  };

  const handleRegionChange = (value: string): void => {
    onRequestRegionChange({ region: value });
  };

  const handleOpenExportDialog = (): void => {
    setExportDialogOpen(true);
  };

  const handleCloseExportDialog = (): void => {
    setExportDialogOpen(false);
  };

  const hasTimelineData = timeline && !isEmpty(timeline.data);
  const selectedRegion = find(countries, { iso_3166_3: region.toUpperCase() });
  const regionName = selectedRegion ? get(selectedRegion, 'name') : 'All Countries';
  const valueKey = type === Metric.GMV ? 'revenue.amount' : 'count';
  const colorIndex = type === Metric.GMV ? 0 : 1;

  const intervalOptions: IntervalOption[] = [
    { content: 'Hourly', value: ReportInterval.HOURLY },
    { content: 'Daily', value: ReportInterval.DAILY },
    { content: 'Weekly', value: ReportInterval.WEEKLY },
    { content: 'Monthly', value: ReportInterval.MONTHLY },
  ];

  const regionOptions: SelectOption[] = [
    { name: 'All Countries', iso_3166_3: 'world' },
  ].concat(countries)
    .map((country) => ({ value: country.iso_3166_3, content: country.name }));

  return (
    <Card>
      <CardHeader dividing>
        <Box justifyContent="between" alignItems="center">
          {type === Metric.GMV && (
            <CardTitle content={withCurrency('Submitted Order Value Over Time', currency)} />
          )}
          {type === Metric.ORDER_COUNT && (
            <CardTitle content="Number of Orders Over Time" />
          )}
          <Box alignItems="center">
            <span>
              Time Zone: Coordinated Universal Time
            </span>
            <IconButton
              className={exportButtonStyles}
              icon={Download}
              onClick={handleOpenExportDialog}
              variant="flat"
            />
          </Box>
        </Box>
      </CardHeader>
      <CardContent fitted={!hasTimelineData}>
        {hasTimelineData ? (
          <TimelineChart
            organizationCountry={organizationCountry}
            organizationCurrency={organizationCurrency}
            endDate={endDate}
            interval={interval}
            regionName={regionName}
            timeline={timeline as io.flow.internal.v0.models.OrderRevenueTimelineChart}
            valueKey={valueKey}
            colorIndex={colorIndex}
          />
        ) : (
          <CardEmptyState content="No Data Available" />
        )}
      </CardContent>
      <CardFooter>
        <Box alignItems="center" spacing="loose">
          <SelectField
            inline
            className={firstActionStyles}
            labelFor="region"
            labelText="Country:"
            onValueChange={handleRegionChange}
            options={regionOptions}
            size="small"
            value={find(regionOptions, { value: region })?.value}
          />
          <SelectField
            inline
            labelCols={5}
            labelFor="interval"
            labelText="Time Interval:"
            onValueChange={handleIntervalChange}
            options={intervalOptions}
            size="small"
            value={find(intervalOptions, { value: interval })?.value}
          />
        </Box>
      </CardFooter>
      <ExportDialog
        isOpen={exportDialogOpen}
        onRequestClose={handleCloseExportDialog}
      />
    </Card>
  );
};

TimelineCard.displayName = 'TimelineCard';

TimelineCard.defaultProps = {
  currency: 'USD',
  onRequestIntervalChange: noop,
  onRequestRegionChange: noop,
  type: Metric.GMV,
};

export default TimelineCard;
