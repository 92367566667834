import {
  ThunkDispatcher, ThunkResult, RootState,
} from '../../../stores/types';
import { ActionTypes } from '../constants';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import toLegacyResponse from '../../../utilities/clients/to-legacy-response';
import { LegacyResponse } from '../../../utilities/clients/types/server';

export default function resetPassword(email: string):
ThunkResult<Promise<LegacyResponse<undefined | FormattedErrorMessages>>> {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra,
  ): Promise<LegacyResponse<undefined | FormattedErrorMessages>> => {
    dispatch({
      type: ActionTypes.AUTH_FORGOT_PASSWORD_SUBMIT,
    });
    return extra.api(getState()).passwordResetForms.postResets({
      body: { email },
    }).then((response) => {
      if (response.ok) {
        dispatch({
          type: ActionTypes.AUTH_FORGOT_PASSWORD_COMPLETED,
        });
        return toLegacyResponse(response);
      }

      dispatch({
        type: ActionTypes.AUTH_FORGOT_PASSWORD_ERROR,
        payload: formatErrorResponseV2(response),
      });

      return toLegacyResponse({
        ...response,
        body: formatErrorResponseV2(response),
      });
    });
  };
}
