export default function getRoundingSentence(
  rounding: io.flow.v0.models.Rounding | undefined,
  currency: string,
): string {
  const sentence = ['Your product prices are set to round'];

  if (!rounding) {
    return 'No rounding set';
  }

  if (rounding.method === 'up') {
    sentence.push('up to');
  }

  if (rounding.method === 'down') {
    sentence.push('down to');
  }

  if (rounding.method === 'nearest') {
    sentence.push('to');
  }

  sentence.push('nearest');

  if (rounding.type === 'multiple') {
    sentence.push('multiple of');
  }

  sentence.push(rounding.value.toString());
  sentence.push(currency);

  return sentence.join(' ');
}
