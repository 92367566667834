import ActionTypes from '../constants/action-types';

import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import hideReturnConfirmation from './hide-return-confirmation';
import standardAction from '../../../utilities/standard-action';

const createRequestAction = (): RootActionTypes => ({
  type: ActionTypes.FETCH_RETURN_SHIPPING_TIERS_REQUEST,
});

const createSuccessAction = (payload: io.flow.v0.models.Tier[]): RootActionTypes => ({
  type: ActionTypes.FETCH_RETURN_SHIPPING_TIERS_SUCCESS,
  payload,
});

const createFailureAction = (errors: FormattedErrorMessages): RootActionTypes => ({
  type: ActionTypes.FETCH_RETURN_SHIPPING_TIERS_FAILURE,
  payload: errors,
  error: true,
});

export default function showReturnFormDialog(
  organization: string,
  experienceKey: string,
): ThunkResult<Promise<void>> {
  return (dispatch, getState, extra): Promise<void> => {
    dispatch(createRequestAction());
    return extra.api(getState()).tiers.get({
      organization,
      experience: experienceKey,
      expand: ['center'],
    }).then((response) => {
      if (response.ok) {
        dispatch(createSuccessAction(response.body));
        dispatch(hideReturnConfirmation());
        dispatch(standardAction(ActionTypes.SHOW_RETURN_FORM_DIALOG));
        return;
      }

      const errors = formatErrorResponseV2(response);
      dispatch(createFailureAction(errors));
    });
  };
}
