import { css } from '@emotion/css';
import colors from '../../../../../theme/tokens/colors';

export const trafficRow = css`
  padding-bottom: 0.5rem;
`;

export const trafficExperience = css`
  background-color: ${colors['flow-blue'][100]};
  border-radius: 3px;
  margin-right: 0.5rem;
  padding: 0.125rem 0.5rem;
`;

export const externalVariant = css`
  .trafficExperience {
    background-color: ${colors.concrete};
  }
`;
