import { ActionTypes, ActivityKind } from '../constants';

import createActivity from './createActivity';
import { getOrganizationId } from '../../organization';
import { ThunkResult } from '../../../stores/types';

function selectItem({
  item,
  index,
}: {
  item: io.flow.v0.models.LocalizedLineItem,
  index: number,
}): ThunkResult<void> {
  return (dispatch, getState) => {
    const organizationId = getOrganizationId(getState());

    dispatch({
      type: ActionTypes.SELECT_ITEM,
      payload: {
        index,
        number: item.number,
        quantity: item.quantity,
      },
    });
    dispatch(createActivity({
      kind: ActivityKind.SCAN_ITEM,
      number: item.number,
      organizationId,
    }));
  };
}

export default selectItem;
