import { FeaturesPutByKeyResponse } from '@flowio/api-internal-sdk';
import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import ActionTypes from '../constants/action-types';
import { PutFeatureByKeyOptions } from '../types/actions';

function createRequestAction(): RootActionTypes {
  return {
    type: ActionTypes.PUT_FEATURE_BY_KEY_REQUEST,
  };
}

function createSuccessAction(payload: io.flow.internal.v0.models.Feature): RootActionTypes {
  return {
    type: ActionTypes.PUT_FEATURE_BY_KEY_SUCCESS,
    payload,
  };
}

function createFailureAction(payload: FormattedErrorMessages): RootActionTypes {
  return {
    type: ActionTypes.PUT_FEATURE_BY_KEY_FAILURE,
    payload,
  };
}

export default function putFeatureByKey(
  organization: string,
  key: string,
  options: PutFeatureByKeyOptions,
): ThunkResult<Promise<FeaturesPutByKeyResponse>> {
  return function putFeatureByKeyEffects(
    dispatch,
    getState,
    extra,
  ): Promise<FeaturesPutByKeyResponse> {
    dispatch(createRequestAction());

    return extra.apiInternal(getState()).features.putByKey({
      key,
      organization,
      body: options.featureForm,
    }).then((response) => {
      if (response.ok) {
        dispatch(createSuccessAction(response.body));
        return response;
      }

      const error = formatErrorResponseV2(response);
      dispatch(createFailureAction(error));
      return response;
    });
  };
}
