import { connect } from 'react-redux';
import get from 'lodash/get';

import { getExperiencesByShippingConfigurationKey } from '../selectors';
import ShippingConfigurationListItem from '../components/ShippingConfigurationListItem';
import { RootState } from '../../../stores/types';
import { ShippingConfigurationListItemStateProps, ShippingConfigurationListItemOwnProps } from '../types/components';

const mapStateToProps = (
  state: RootState,
  props: ShippingConfigurationListItemOwnProps,
): ShippingConfigurationListItemStateProps => {
  const shippingConfigurationKey = get(props, 'shippingConfiguration.key');
  return {
    experiences: getExperiencesByShippingConfigurationKey(shippingConfigurationKey)(state),
  };
};

export default connect<
ShippingConfigurationListItemStateProps,
{},
ShippingConfigurationListItemOwnProps,
RootState
>(mapStateToProps)(ShippingConfigurationListItem);
