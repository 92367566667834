// eslint-disable-next-line import/prefer-default-export
export const labColors = [
  { red: 68, green: 98, blue: 116 },
  { red: 91, green: 79, blue: 109 },
  { red: 100, green: 162, blue: 156 },
  { red: 102, green: 139, blue: 165 },
  { red: 103, green: 115, blue: 163 },
  { red: 146, green: 106, blue: 165 },
  { red: 102, green: 163, blue: 120 },
  { red: 159, green: 157, blue: 107 },
];
