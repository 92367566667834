import React from 'react';
import BemHelper from '@flowio/bem-helper';
import { Card, CardContent, CardFooter } from '@flowio/react-card';

import WebhookForm from '../../../containers/webhook-form';

if (process.browser) {
  require('./create-webhook.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('create-webhook');

const displayName = 'CreateWebhook';

const CreateWebhook = () => (
  <div className={bem.block()}>
    <Card>
      <CardContent>
        <WebhookForm />
      </CardContent>
      <CardFooter />
    </Card>
  </div>
);

CreateWebhook.displayName = displayName;

export default CreateWebhook;
