import { RootActionTypes } from '../../../stores/types';
import ActionTypes from '../constants/action-types';

function setShopifyAppError(error?: string): RootActionTypes {
  return {
    type: ActionTypes.SET_SHOPIFY_APP_ERROR,
    payload: error,
  };
}

export default setShopifyAppError;
