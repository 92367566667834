import { css } from '@emotion/css';

export const checkoutConfiguration = css`
  & .content-area-title {
    margin-top: 0;
    margin-bottom: 1.125rem;
  }
`;

export const field = css`
  width: 50%;
  min-width: 32rem;
`;

export const label = css`
  color: #77818f;
  font-weight: 500;
  display: inline-block;
  font-size: 0.875rem;
  padding: 0;
  margin: 0;
  width: 14rem;
`;

export const value = css`
  width: auto;
  margin: 0;
  padding: 0 0 0 1rem;
  font-size: 1rem;
`;
