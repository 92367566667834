import Client from './client';

export default class LocalizableContents extends Client {
  constructor(opts) {
    let options = opts;

    if (typeof opts === 'string') {
      options = { host: opts }; // convert host string to options object
    }

    options.serviceName = 'API Internal';

    super(options);
  }

  getContentAndSchemasBySchemaKey(organization, schemaKey, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/content/schemas/${schemaKey}`, options);
  }

  getContentsAndLocalizationByContentId(organization, contentId, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/contents/${contentId}/localization`, options);
  }

}
