import { Box } from '@flowio/react-box';
import { Button } from '@flowio/react-button';
import React from 'react';
import {
  Worksheet, WorksheetHeader, WorksheetTitle, WorksheetBody,
} from '@flowio/react-worksheet';

import FormName from '../../../constants/FormName';
import OptinMessageForm from '../../OptinMessageForm';
import { Props } from '../types';

const CreateOptinMessageWorksheet: React.FC<Props> = ({
  onSubmitSuccess,
  onCancel,
  onSave,
  initialValues,
  isCancelDisabled,
  isSaveDisabled,
  open = false,
}) => (
  <Worksheet open={open}>
    <WorksheetHeader>
      <Box flexAuto alignItems="center" justifyContent="between">
        <Button content="Cancel" disabled={isCancelDisabled} onClick={onCancel} />
        <WorksheetTitle content="Create Opt-in Message" />
        <Button content="Save" disabled={isSaveDisabled} intent="primary" onClick={onSave} />
      </Box>
    </WorksheetHeader>
    <WorksheetBody>
      <OptinMessageForm
        initialValues={initialValues}
        form={FormName.CREATE_OPTIN_ATTRIBUTE_FORM}
        onSubmitSuccess={onSubmitSuccess}
      />
    </WorksheetBody>
  </Worksheet>
);

CreateOptinMessageWorksheet.displayName = 'CreateOptinMessageWorksheet';

export default CreateOptinMessageWorksheet;
