import property from 'lodash/property';
import get from 'lodash/get';
import { getOrganizationId } from '../../organization';
import { RootState } from '../../../stores/types';
import { SelectedOrderItem, ShippingLabelRequestStatus } from '../types/components';

export const getActivities = (state: RootState) => {
  const organizationId = getOrganizationId(state);
  return get(state, `fulfillment.activities.${organizationId}`);
};
export const getCurrentOrderNumber = property<RootState, string>('fulfillment.order.currentOrderNumber');
export const getSelectedOrderItems = property<RootState, SelectedOrderItem[]>('fulfillment.order.selectedOrderItems');
export const getCurrentSearchTerm = property<RootState, string>('fulfillment.order.currentSearchTerm');
export const getCurrentOrder = property<RootState, io.flow.v0.models.Order>('fulfillment.order.currentOrder');
export const getShippingLabelRequestStatus = property<RootState, ShippingLabelRequestStatus>('fulfillment.shippingLabel.status');
export const getCurrentItemContents = property<RootState, io.flow.v0.models.CatalogItemDocument[]>('fulfillment.itemContent.itemContents');
export const getInvoiceOfCurrentLabelResponse = property<RootState, io.flow.v0.models.ShippingLabelDocument>('fulfillment.shippingLabel.label.invoice');
export const getShippingLabel = property<RootState, io.flow.v0.models.ShippingLabel>('fulfillment.shippingLabel.label');
export const getItemsOfPrevShippingLabel = property<RootState, SelectedOrderItem[]>('fulfillment.shippingLabel.items');
