import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import ActionTypes from '../constants/action-types';

function createRequestAction(): RootActionTypes {
  return {
    type: ActionTypes.GET_SHOP_REQUEST,
  };
}

function createSuccessAction(payload: io.flow.internal.v0.models.Shop): RootActionTypes {
  return {
    type: ActionTypes.GET_SHOP_SUCCESS,
    payload,
  };
}

function createFailureAction(error: FormattedErrorMessages): RootActionTypes {
  return {
    type: ActionTypes.GET_SHOP_FAILURE,
    payload: error,
  };
}

function getShop(shopId: string): ThunkResult<Promise<void>> {
  return function getShopEffects(
    dispatch,
    getState,
    extra,
  ): Promise<void> {
    dispatch(createRequestAction());

    return extra.apiInternal(getState()).shops.getByMyshopifyDomain({
      myshopify_domain: shopId,
    }).then((response) => {
      if (response.ok) {
        dispatch(createSuccessAction(response.body));
        return;
      }

      const errors = formatErrorResponseV2(response);
      dispatch(createFailureAction(errors));
    });
  };
}

export default getShop;
