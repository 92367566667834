import { createSelector } from 'reselect';
import property from 'lodash/property';
import getLogisticsState from './getLogisticsState';
import { RootState } from '../../../stores/types';
import { LogisticsRootState } from '../types/state';

export default createSelector<RootState, LogisticsRootState, io.flow.v0.models.Experience[]>(
  getLogisticsState,
  property('entities.experiences'),
);
