import { ActionTypes } from '../constants';
import { RootActionTypes } from '../../../stores/types';

function setCurrentOrder(orderNumber: string): RootActionTypes {
  return {
    type: ActionTypes.SET_CURRENT_ORDER,
    payload: {
      orderNumber,
    },
  };
}

export default setCurrentOrder;
