import { MembershipsGetResponse } from '@flowio/api-sdk';
import ActionTypes from '../constants/action-types';
import { PropType } from '../../../types';
import { ThunkDispatcher, RootState, ThunkExtraArguments } from '../../../stores/types';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';
import toLegacyResponse from '../../../utilities/clients/to-legacy-response';

type ResponseType = PropType<MembershipsGetResponse, 'body'>;

export default function fetchUserMemberships(organization: string, userId: string) {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<LegacyResponse<ResponseType>> => {
    dispatch({
      type: ActionTypes.FETCH_USER_MEMBERSHIP_REQUEST,
    });

    return extra.api(getState()).memberships.get({
      organization,
      user: userId,
      expand: ['user'],
      limit: 1,
    }).then((response) => {
      if (response.ok) {
        dispatch({
          type: ActionTypes.FETCH_USER_MEMBERSHIP_SUCCESS,
          payload: response.body,
        });
      } else {
        dispatch({
          type: ActionTypes.FETCH_USER_MEMBERSHIP_FAILURE,
          payload: formatErrorResponseV2(response),
        });
      }
      return toLegacyResponse<ResponseType>(response);
    });
  };
}
