import { RootActionTypes } from '../../../stores/types';
import ActionTypes from '../constants/ActionTypes';

const initialState = '';

export default function (state = initialState, action: RootActionTypes) {
  switch (action.type) {
    case ActionTypes.CHANGE_ACTIVE_SHIPPING_LANE:
      return action.payload;
    default:
      return state;
  }
}
