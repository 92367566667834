import { Alert, PuffLoader } from '@flowio/react-icons';
import React from 'react';
import {
  Dialog, DialogHeader, DialogFooter, DialogActions, DialogBody,
} from '@flowio/react-dialog';
import { Banner } from '@flowio/react-banner';
import { Button } from '@flowio/react-button';
import noop from 'lodash/noop';
import isUndefined from 'lodash/isUndefined';
import get from 'lodash/get';
import { css } from '@emotion/css';
import ExperienceCloneForm from './ExperienceCloneForm';
import { MergedProps as Props } from '../types';

const dialogStyle = css`
  max-width: 550px;
`;

const draftInfoStyle = css`
  color: #77818f;
`;

const dialogActionsStyle = css`
  min-width: 150px;
`;

class ExperienceCloneDialog extends React.Component<Props> {
  static displayName = 'ExperienceCloneDialog';

  static defaultProps = {
    cloneExperienceModel: undefined,
    cloneError: undefined,
    experienceShouldPoll: false,
    experienceToClone: undefined,
    onCloneCancellation: noop,
    onPollCloneExperienceModel: noop,
    isLoading: false,
  };

  componentDidMount(): void {
    this.pollCloneStatus();
  }

  componentDidUpdate(prevProps: Props): void {
    const {
      cloneExperienceModel,
    } = this.props;

    if (isUndefined(prevProps.cloneExperienceModel) && !isUndefined(cloneExperienceModel)) {
      this.pollCloneStatus();
    }
  }

  pollCloneStatus(): void {
    const {
      cloneExperienceModel,
      experienceShouldPoll,
      experienceToClone,
      onPollCloneExperienceModel: handlePollCloneExperienceModel,
    } = this.props;

    if (experienceShouldPoll && cloneExperienceModel) {
      handlePollCloneExperienceModel({
        experienceKey: experienceToClone.key,
        experienceCloneId: cloneExperienceModel.id,
      });
    }
  }

  render(): JSX.Element {
    const {
      cloneError,
      experienceShouldPoll,
      experienceToClone,
      handleCloneExperienceSubmit,
      isLoading,
      onCloneCancellation,
    } = this.props;

    return (
      <Dialog
        backdrop
        closeOnEscape
        open={!!experienceToClone}
        onClose={onCloneCancellation}
        className={dialogStyle}
      >
        <DialogHeader content="Copy Experience" />
        <DialogBody>
          { isLoading
            ? <PuffLoader width={30} />
            : (
              <>
                <p>
                  Copying &quot;
                  {get(experienceToClone, 'name')}
                  &quot; as a new Experience.
                  <br />
                  Please enter a name for the new Experience.
                </p>
                <ExperienceCloneForm experienceToClone={experienceToClone} />
                <p className={draftInfoStyle}>
                  New Experience will be created as a draft.
                </p>
                {cloneError && (
                  <Banner
                    heading="Error"
                    icon={Alert}
                    intent="negative"
                    text={cloneError}
                  />
                )}
              </>
            )}
        </DialogBody>
        <DialogFooter>
          <DialogActions>
            <Button className={dialogActionsStyle} content="Cancel" type="button" onClick={onCloneCancellation} />
            <Button className={dialogActionsStyle} content="Save" disabled={experienceShouldPoll} loading={experienceShouldPoll} intent="primary" type="submit" onClick={handleCloneExperienceSubmit} />
          </DialogActions>
        </DialogFooter>
      </Dialog>
    );
  }
}

export default ExperienceCloneDialog;
