import React from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableHeadColumn,
  TableBody,
} from '@flowio/react-table';
import map from 'lodash/map';
import { css } from '@emotion/css';
import ExclusionRuleItem from '../ExclusionRuleItem';

const statusColumnStyle = css({
  width: '120px',
});

const countColumnStyle = css({
  width: '120px',
});
interface ExclusionRuleListProps {
  exclusionRules: io.flow.v0.models.ExclusionRule[];
  organizationId: string;
  onEditExclusionRule: (...args: any) => void;
  onRemoveExclusionRule: (...args: any) => void;
  regions: io.flow.v0.models.Region[];
}

const ExclusionRuleList: React.FC<ExclusionRuleListProps> = ({
  exclusionRules,
  onEditExclusionRule,
  onRemoveExclusionRule,
  organizationId,
  regions,
}) => (
  <Table fixed striped singleLine>
    <TableHead>
      <TableRow>
        <TableHeadColumn>
          Rules
        </TableHeadColumn>
        <TableHeadColumn className={statusColumnStyle}>
          Status
        </TableHeadColumn>
        <TableHeadColumn className={countColumnStyle}>
          # of Items
        </TableHeadColumn>
        <TableHeadColumn>
          Excluded In
        </TableHeadColumn>
        <TableHeadColumn />
      </TableRow>
    </TableHead>
    <TableBody>
      {map(exclusionRules, (exclusionRule, index) => (
        <ExclusionRuleItem
          currentIndex={index}
          key={exclusionRule.id}
          exclusionRule={exclusionRule}
          onEdit={onEditExclusionRule}
          onRemove={onRemoveExclusionRule}
          organizationId={organizationId}
          regions={regions}
        />
      ))}
    </TableBody>
  </Table>
);

ExclusionRuleList.displayName = 'ExclusionRuleList';

export default ExclusionRuleList;
