import { createSelector } from 'reselect';
import get from 'lodash/get';
import getQueryBuilderForExclusionRuleWorksheet from './getQueryBuilderForExclusionRuleWorksheet';

/**
 * Returns initial list of query filters associated with the exclusion rule
 * being created or edited. Generally, when a new exclusion rule is being
 * created the result is an empty list.
 * @returns {QueryFilter[]}
 */
const getQueryFiltersForExclusionRuleWorksheet = createSelector(
  getQueryBuilderForExclusionRuleWorksheet,
  (queryBuilder) => get(queryBuilder, 'filters', []),
);

export default getQueryFiltersForExclusionRuleWorksheet;
