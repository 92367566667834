import { compose } from 'redux';
import {
  DecoratedFormProps,
  FormErrors,
  reduxForm,
  SubmissionError,
} from 'redux-form';
import get from 'lodash/get';

import {
  putFeatureByKey,
  hideEditFeatureWorksheet,
} from '../actions';
import createToast from '../../console/actions/createToast';
import EditFeatureWorksheetForm from '../components/EditFeatureWorksheetForm';
import FormName from '../constants/FormName';
import { EditFeatureWorksheetOwnProps, EditFeatureWorksheetFormValues } from '../types/components';
import { ThunkDispatcher } from '../../../stores/types';

type OwnProps = {
  initialValues: Partial<EditFeatureWorksheetFormValues>;
};

function handleValidation(
  values: EditFeatureWorksheetFormValues,
): FormErrors<EditFeatureWorksheetFormValues> {
  const errors: FormErrors<EditFeatureWorksheetFormValues> = {};

  if (!values.organizationId) {
    errors.organizationId = 'An organization identifier is required';
  }

  if (!values.key) {
    errors.key = 'A key is required';
  }

  return errors;
}

function handleSumbit(values: EditFeatureWorksheetFormValues, dispatch: ThunkDispatcher) {
  const { organizationId, key } = values;

  const promise = dispatch(putFeatureByKey(organizationId, key, {
    featureForm: {
      name: values.name,
      rules: values.rules,
      type: values.type,
      description: values.description,
    },
  }));

  return promise.then((response) => {
    if (!response.ok) {
      const errorMessages = get(response, 'body.messages', []);
      throw new SubmissionError({
        _error: {
          ...response.body,
          messages: errorMessages,
        },
      });
    }

    return response;
  });
}

function handleSumbitSuccess(
  _: any,
  dispatch: ThunkDispatcher,
  props: DecoratedFormProps<EditFeatureWorksheetFormValues, OwnProps, string>,
) {
  dispatch(hideEditFeatureWorksheet());
  dispatch(createToast({
    content: `Feature \`${props.initialValues.name}\` has been updated.`,
    icon: 'CheckCircle',
    intent: 'info',
  }));
}

export default compose<React.FC<EditFeatureWorksheetOwnProps>>(
  reduxForm<EditFeatureWorksheetFormValues, OwnProps, string>({
    form: FormName.EDIT_FEATURE_FORM,
    onSubmit: handleSumbit,
    validate: handleValidation,
    onSubmitSuccess: handleSumbitSuccess,
  }),
)(EditFeatureWorksheetForm);
