import {
  $HttpClientOptions,
  AllocationV2SResource,
  AttributesResource,
  CentersResource,
  CountriesResource,
  CurrenciesResource,
  CatalogsResource,
  DiscountRuleSettingsResource,
  DocumentsResource,
  EmailVerificationsResource,
  ExclusionRulesResource,
  ExperienceDefaultsResource,
  ExperienceLogisticsSettingsResource,
  ExperiencesResource,
  ExportsResource,
  Hs10Resource,
  ImportsResource,
  InvitationsResource,
  ItemsResource,
  LanguagesResource,
  MembershipsResource,
  OrderBuildersResource,
  OrdersResource,
  OrganizationsResource,
  OrganizationTokensResource,
  PasswordResetFormsResource,
  PriceBookItemExportOptionsResource,
  PriceBookItemsResource,
  PriceBooksResource,
  ProvincesResource,
  PublicKeysResource,
  RatecardsResource,
  RatesResource,
  RefundsResource,
  RegionsResource,
  ReturnPoliciesResource,
  ReturnsResource,
  ScheduledExportsResource,
  ShippingConfigurationCopiesResource,
  ShippingConfigurationsResource,
  ShippingLabelsResource,
  ShippingNotificationsResource,
  ShopifySyncStatusesResource,
  SubcatalogsResource,
  SuggestionsResource,
  SurchargeSettingsResource,
  TiersResource,
  TokensResource,
  UploadsResource,
  UsersResource,
  WebhookDeliveriesResource,
  WebhooksResource,
} from '@flowio/api-sdk';

export class ApiClientV2 {
  allocation: AllocationV2SResource;

  attributes: AttributesResource;

  centers: CentersResource;

  countries: CountriesResource;

  currencies: CurrenciesResource;

  catalogs: CatalogsResource;

  discountRuleSettings: DiscountRuleSettingsResource;

  documents: DocumentsResource;

  emailVerifications: EmailVerificationsResource;

  exclusionRules: ExclusionRulesResource;

  experienceDefaults: ExperienceDefaultsResource;

  experienceLogisticsSettings: ExperienceLogisticsSettingsResource;

  experiences: ExperiencesResource;

  exports: ExportsResource;

  hs10: Hs10Resource;

  imports: ImportsResource;

  invitations: InvitationsResource;

  items: ItemsResource;

  languages: LanguagesResource;

  memberships: MembershipsResource;

  orderBuilders: OrderBuildersResource;

  orders: OrdersResource;

  organizations: OrganizationsResource;

  organizationTokens: OrganizationTokensResource;

  passwordResetForms: PasswordResetFormsResource;

  priceBookItems: PriceBookItemsResource;

  priceBookItemsExportOptions: PriceBookItemExportOptionsResource;

  priceBooks: PriceBooksResource;

  provinces: ProvincesResource;

  publicKeys: PublicKeysResource;

  ratecards: RatecardsResource;

  rates: RatesResource;

  refunds: RefundsResource;

  regionsResource: RegionsResource;

  returnPolicies: ReturnPoliciesResource;

  returns: ReturnsResource;

  scheduledExports: ScheduledExportsResource;

  shippingConfigurations: ShippingConfigurationsResource;

  shippingConfigurationsCopies: ShippingConfigurationCopiesResource;

  shippingLabels: ShippingLabelsResource;

  shippingNotifications: ShippingNotificationsResource;

  shopifySyncStatuses: ShopifySyncStatusesResource;

  subcatalogs: SubcatalogsResource;

  suggestions: SuggestionsResource;

  surchargeSettings: SurchargeSettingsResource;

  tiers: TiersResource;

  tokens: TokensResource;

  uploads: UploadsResource;

  users: UsersResource;

  webhookDeliveries: WebhookDeliveriesResource;

  webhooks: WebhooksResource;

  constructor(options: $HttpClientOptions) {
    this.allocation = new AllocationV2SResource(options);
    this.attributes = new AttributesResource(options);
    this.centers = new CentersResource(options);
    this.countries = new CountriesResource(options);
    this.currencies = new CurrenciesResource(options);
    this.discountRuleSettings = new DiscountRuleSettingsResource(options);
    this.catalogs = new CatalogsResource(options);
    this.documents = new DocumentsResource(options);
    this.emailVerifications = new EmailVerificationsResource(options);
    this.exclusionRules = new ExclusionRulesResource(options);
    this.experienceDefaults = new ExperienceDefaultsResource(options);
    this.experienceLogisticsSettings = new ExperienceLogisticsSettingsResource(options);
    this.experiences = new ExperiencesResource(options);
    this.exports = new ExportsResource(options);
    this.hs10 = new Hs10Resource(options);
    this.imports = new ImportsResource(options);
    this.invitations = new InvitationsResource(options);
    this.items = new ItemsResource(options);
    this.languages = new LanguagesResource(options);
    this.memberships = new MembershipsResource(options);
    this.orderBuilders = new OrderBuildersResource(options);
    this.orders = new OrdersResource(options);
    this.organizations = new OrganizationsResource(options);
    this.organizationTokens = new OrganizationTokensResource(options);
    this.passwordResetForms = new PasswordResetFormsResource(options);
    this.priceBookItems = new PriceBookItemsResource(options);
    this.priceBookItemsExportOptions = new PriceBookItemExportOptionsResource(options);
    this.priceBooks = new PriceBooksResource(options);
    this.provinces = new ProvincesResource(options);
    this.publicKeys = new PublicKeysResource(options);
    this.ratecards = new RatecardsResource(options);
    this.rates = new RatesResource(options);
    this.refunds = new RefundsResource(options);
    this.regionsResource = new RegionsResource(options);
    this.returnPolicies = new ReturnPoliciesResource(options);
    this.returns = new ReturnsResource(options);
    this.scheduledExports = new ScheduledExportsResource(options);
    this.shippingConfigurations = new ShippingConfigurationsResource(options);
    this.shippingConfigurationsCopies = new ShippingConfigurationCopiesResource(options);
    this.shippingLabels = new ShippingLabelsResource(options);
    this.shippingNotifications = new ShippingNotificationsResource(options);
    this.shopifySyncStatuses = new ShopifySyncStatusesResource(options);
    this.subcatalogs = new SubcatalogsResource(options);
    this.suggestions = new SuggestionsResource(options);
    this.surchargeSettings = new SurchargeSettingsResource(options);
    this.tiers = new TiersResource(options);
    this.tokens = new TokensResource(options);
    this.uploads = new UploadsResource(options);
    this.users = new UsersResource(options);
    this.webhookDeliveries = new WebhookDeliveriesResource(options);
    this.webhooks = new WebhooksResource(options);
  }
}

export function createClient(options: $HttpClientOptions): ApiClientV2 {
  return new ApiClientV2(options);
}
