import { RootActionTypes } from '../../../stores/types';
import ActionTypes from '../constants/action-types';

const showDialog = (dialog: string) => (payload?: any): RootActionTypes => ({
  type: ActionTypes.SHOW_DIALOG,
  meta: { name: dialog },
  payload,
});

export default showDialog;
