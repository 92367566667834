import Client from './client';

export default class OrderDetails extends Client {
  constructor(opts) {
    let options = opts;

    if (typeof opts === 'string') {
      options = { host: opts }; // convert host string to options object
    }

    options.serviceName = 'API Internal';

    super(options);
  }

  get(organization, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/order-details`, options);
  }

  getByNumber(organization, number, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/order-details/${number}`, options);
  }

  getNotesByNumber(organization, number, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/order-details/${number}/notes`, options);
  }

  postNotesByNumber(organization, number, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/order-details/${number}/notes`, {
      ...options,
       method: 'POST',
    });
  }

  getNotesByNumberAndId(organization, number, id, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/order-details/${number}/notes/${id}`, options);
  }

  putNotesByNumberAndId(organization, number, id, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/order-details/${number}/notes/${id}`, {
      ...options,
       method: 'PUT',
    });
  }

  deleteNotesByNumberAndId(organization, number, id, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/order-details/${number}/notes/${id}`, {
      ...options,
       method: 'DELETE',
    });
  }

}
