import update from 'immutability-helper';

import { ActionTypes } from '../constants/ActionTypes';
import { createReducer } from '../../../utilities/redux';

const initialState = {};

export default createReducer(initialState, {
  [ActionTypes.UPDATE_DIALOG]: (state, action) => update(state, {
    [action.payload.type]: {
      $set: action.payload.props,
    },
  }),
  [ActionTypes.DELETE_DIALOG]: (state, action) => update(state, {
    $unset: [action.payload.type],
  }),
});
