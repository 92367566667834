import React, { FunctionComponent } from 'react';
import moment from 'moment-timezone';

interface FormattedDateProps {
  format?: string;
  timezone?: string;
  utc?: boolean;
  value?: string | Date;
}

const FormattedDate: FunctionComponent<FormattedDateProps> = ({
  value,
  format = 'L',
  utc = false,
  timezone,
  ...otherProps
}) => {
  let date = moment(value);
  if (utc) date = date.utc();
  if (timezone) date = date.tz(timezone);

  return (
    <time {...otherProps} dateTime={date.toISOString()}>
      {date.format(format)}
    </time>
  );
};

FormattedDate.displayName = 'FormattedDate';

export default FormattedDate;
