import update from 'immutability-helper';
import ActionTypes from '../constants/action-types';

import { CheckoutConfigurationState } from '../types';
import { RootActionTypes } from '../../../stores/types';

const defaultState: CheckoutConfigurationState = {
  configurations: [],
};

export default function (
  state: CheckoutConfigurationState = defaultState,
  action: RootActionTypes,
): CheckoutConfigurationState {
  switch (action.type) {
    case ActionTypes.FETCH_CHECKOUT_CONFIGURATION_SUCCESS:
      return update(state, { configurations: { $set: action.payload } });
    default:
      return state;
  }
}
