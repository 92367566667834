import React, { useState, useEffect } from 'react';
import { Box } from '@flowio/react-box';
import { Link } from 'react-router';
import { isNumber } from 'lodash';
import MarketingCard, { MarketingCardHeading } from '../../MarketingCard';
import * as styles from './AddFeeds.styles';
import WizardBreadCrumbs from '../../WizardBreadCrumbs/WizardBreadCrumbs';
import SelectFeedSource from '../../../containers/SelectFeedSource';
import SelectFeedForms from '../../../containers/SelectFeedForms';
import { MergedProps as Props } from '../types';
import Button from '../../MarketingButton';
import {
  STEP_1_TITLE,
  STEP_2_TITLE,
  STEP_1_SUBTITLE,
  STEP_2_SUBTITLE,
} from '../../../constants/Feeds';
import MarketingPage from '../../MarketingPage';
import Platforms from '../../../constants/Platforms';

const AddFeeds: React.FC<Props> = ({
  organizationId,
  platformId,
  accountId,
  onCancel,
  onFeedSourceConfirm,
  onFeedsFormsConfirm,
  canSubmitSource,
  canSubmitFeeds,
  isSubmittingSource,
  isSubmittingFeeds,
  isOnboarding,
}) => {
  const [stepNumber, setStepNumber] = useState(1);
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    if (hasMounted && !isSubmittingSource) {
      setStepNumber((s) => s + 1);
    }
  // adding hasMounted as a dependency introduces a bug :-(
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmittingSource]);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  const handleCancel = (): void => {
    onCancel(
      {
        organizationId,
        platformId,
        accountId,
      },
      isOnboarding,
    );
  };

  const renderStep = (): JSX.Element => {
    switch (stepNumber) {
      case 2:
        return (
          <div className={styles.feedFormsContainer}>
            <MarketingCard>
              <SelectFeedForms
                platformId={platformId}
                organizationId={organizationId}
              />
            </MarketingCard>
          </div>
        );
      case 1:
      default:
        return (
          <SelectFeedSource
            platformId={platformId}
            accountId={accountId}
            organizationId={organizationId}
          />
        );
    }
  };

  const breadrumbItems = [
    {
      content: 'Product Feeds',
      as: Link,
      to: `/${organizationId}/marketing`,
    },
    {
      content: `${Platforms(platformId).shortName} Feeds`,
      as: Link,
      to: `/${organizationId}/marketing/${platformId}/feeds${isNumber(accountId) ? `/${accountId}` : ''}`,
    },
    {
      content: 'Add Feeds',
      active: true,
    },
  ];

  return (
    <>
      <MarketingPage
        heading="Add Feeds"
        breadcrumbItems={breadrumbItems}
      >
        <MarketingCard>
          <WizardBreadCrumbs stepNumber={stepNumber} />
          <>
            <br />
            <MarketingCardHeading
              title={(stepNumber === 1 ? STEP_1_TITLE : STEP_2_TITLE)(platformId)}
              subtitle={(stepNumber === 1 ? STEP_1_SUBTITLE : STEP_2_SUBTITLE)(platformId)}
              showDivider={false}
            />
            <br />
          </>
          {renderStep()}
          <Box justifyContent="between" className={styles.footer}>
            <Box>
              {
                stepNumber !== 1 && (
                  <Button
                    data-automation-id="previous-step"
                    intent="primary"
                    onClick={(): void => setStepNumber((s) => s - 1)}
                  >
                    Go Back
                  </Button>
                )
              }
            </Box>
            <Box spacing="loose">
              <Button
                data-automation-id="cancel-add-feeds"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              {
                stepNumber === 1 && (
                  <Button
                    data-automation-id="next-step"
                    disabled={!canSubmitSource || isSubmittingSource}
                    intent="primary"
                    onClick={onFeedSourceConfirm}
                  >
                    Next
                  </Button>
                )
              }
              {
                stepNumber === 2 && (
                  <Button
                    type="submit"
                    data-automation-id="submit-add-feeds"
                    disabled={!canSubmitFeeds || isSubmittingFeeds}
                    onClick={onFeedsFormsConfirm}
                    intent="positive"
                  >
                    Create Localized Feeds
                  </Button>
                )
              }
            </Box>
          </Box>
        </MarketingCard>
      </MarketingPage>
    </>
  );
};

AddFeeds.displayName = 'AddFeeds';

export default AddFeeds;
