import React from 'react';
import { Box, BoxItem } from '@flowio/react-box';
import { Stack, StackItem } from '@flowio/react-stack';
import { Label } from '@flowio/react-label';

import tierRuleQueryToSentence from '../../utilities/tierRuleQueryToSentence';
import tierRuleOutcomeToSentence from '../../utilities/tierRuleOutcomeToSentence';
import * as styles from './TierList.styles';
import { TierListItemRulesProps } from '../../types/components';

const TierListItemRules: React.FC<TierListItemRulesProps> = ({
  tierRules,
  currencyCode,
}) => (
  <Box spacing="loose">
    <BoxItem className={styles.tierLabel}>
      <Label content="Price Rules" />
    </BoxItem>
    <BoxItem>
      <Stack orientation="vertical" className={styles.tierValueStack}>
        {tierRules.map((tierRule) => (
          <div>
            <StackItem>
              <span><b>{tierRuleQueryToSentence(tierRule, currencyCode)}</b></span>
            </StackItem>
            <StackItem>
              <span className={styles.tierRuleOutcome}>{tierRuleOutcomeToSentence(tierRule)}</span>
            </StackItem>
          </div>
        ))}
      </Stack>
    </BoxItem>
  </Box>
);
TierListItemRules.displayName = 'TierListItemRules';

export default TierListItemRules;
