import { Check, Close } from '@flowio/react-icons';
import React from 'react';
import PropTypes from 'prop-types';
import ApiPropTypes from '@flowio/api-prop-types';
import { Button } from '@flowio/react-button';
import {
  TableRow,
  TableRowColumn,
} from '@flowio/react-table';
import { css } from '@emotion/css';

const buttonStyles = css`
  margin: 8px;
  height: 24px !important;
`;

const PendingUsersItem = ({
  onItemDeclineClick, onItemAcceptClick, user,
}) => (
  <TableRow>
    <TableRowColumn>
      {user.email}
    </TableRowColumn>
    <TableRowColumn textAlign="right">
      <Button
        className={buttonStyles}
        content="Approve"
        leftIcon={Check}
        intent="positive"
        variant="outline"
        onClick={(e) => {
          e.stopPropagation();
          onItemAcceptClick(user);
        }}
      />
      <Button
        className={buttonStyles}
        content="Decline"
        leftIcon={Close}
        intent="negative"
        variant="outline"
        onClick={(e) => {
          e.stopPropagation();
          onItemDeclineClick(user);
        }}
      />
    </TableRowColumn>
  </TableRow>
);

PendingUsersItem.displayName = 'PendingUsersItem';

PendingUsersItem.propTypes = {
  user: ApiPropTypes.user.isRequired,
  onItemAcceptClick: PropTypes.func.isRequired,
  onItemDeclineClick: PropTypes.func.isRequired,
};

export default PendingUsersItem;
