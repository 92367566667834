type CheckableResponse = {
  status: number;
  ok: boolean;
  result?: unknown;
};

export class HttpStatusError extends Error {
  response: CheckableResponse;

  constructor(response: CheckableResponse) {
    super('Bad server response');
    this.name = 'HttpStatusError';
    this.response = response;
  }
}

/**
 * A simple utility that rejects a request on any non 2XX response received
 * from the Node SDK for Flow API.
 */
export default function checkHttpStatus<T extends CheckableResponse>(
  response: T,
): T {
  const { status } = response;

  if (status >= 200 && status < 300) {
    return response;
  }

  const error = new HttpStatusError(response);
  throw error;
}

export function isHttpStatusError(error: unknown): error is HttpStatusError {
  return !!error && (error as HttpStatusError).name === 'HttpStatusError';
}
