import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { submit, isSubmitting } from 'redux-form';
import { $HttpOk } from '@flowio/api-internal-sdk';
import { hideDialog } from '../../../actions';
import { createToast } from '../../../../console/actions';
import { getIsDialogOpen, getDialogProp } from '../../../selectors';
import DialogName from '../../../constants/DialogName';
import FormName from '../../../constants/FormName';
import OptinMessageChangeStatusConfirm from '../components/OptinMessageChangeStatusConfirm';
import ContentStatus from '../../../constants/ContentStatus';
import { RootState, ThunkDispatcher } from '../../../../../stores/types';
import { DispatchProps, StateProps } from '../types';

const confirmation = DialogName.OPTIN_MESSAGE_STATUS_CONFIRM;
const form = FormName.OPTIN_ATTRIBUTE_STATUS_FORM;

const submitForm = () => submit(form);

const submitSuccess = (
  response: $HttpOk<io.flow.internal.v0.models.OptinAttribute>,
) => (dispatch: ThunkDispatcher): void => {
  const optinAttribute = response.body;
  const isArchived = optinAttribute.status === ContentStatus.ARCHIVED;
  dispatch(hideDialog(confirmation)());
  dispatch(createToast({
    intent: isArchived ? 'neutral' : 'positive',
    content: isArchived ? `"${optinAttribute.name}" has been archived.` : `"${optinAttribute.name}" is active now.`,
    icon: isArchived ? 'Archive' : 'CheckCircle',
  }));
};

const mapStateToProps = (state: RootState): StateProps => ({
  disabled: isSubmitting(form)(state),
  open: getIsDialogOpen(confirmation)(state),
  initialValues: getDialogProp(confirmation, 'initialValues')(state),
});

const mapDispatchToProps = (
  dispatch: ThunkDispatcher,
): DispatchProps => bindActionCreators({
  onCancel: hideDialog(confirmation),
  onOk: submitForm,
  onSubmitSuccess: submitSuccess,
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(OptinMessageChangeStatusConfirm);
