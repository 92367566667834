import { css } from '@emotion/css';

export const registration = css`
  & .alert {
    margin-bottom: 1rem;
  }
`;

export const button = css`
  margin-top: 60px;
`;

export const email = css`
  margin-bottom: 1rem;
`;

export const password = css`
  margin-bottom: 1rem;
`;
