import { autofill } from 'redux-form';
import { batchActions } from 'redux-batched-actions';

import getExperienceDefaults from './get-experience-defaults';
import { ThunkResult, ThunkDispatcher } from '../../../stores/types';
import isResponseOk from '../../../utilities/isResponseOk';

export default function autofillExperienceForm(
  formName: string,
  organizationId: string,
  regionId: string,
): ThunkResult<Promise<void>> {
  return function autofillExperienceFormSideEffects(dispatch: ThunkDispatcher): Promise<void> {
    return dispatch(getExperienceDefaults(organizationId, regionId))
      .then((response) => {
        if (isResponseOk(response)) {
          const castResponse = response.result as io.flow.v0.models.ExperienceDefaults;
          dispatch(batchActions([
            autofill(formName, 'name', castResponse.name),
            autofill(formName, 'country', castResponse.country),
            autofill(formName, 'currency', castResponse.currency),
            autofill(formName, 'measurementSystem', castResponse.measurement_system),
            autofill(formName, 'language', castResponse.language),
          ]));
        }
      });
  };
}
