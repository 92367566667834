import Client from './client';

export default class ProcessorAccounts extends Client {
  constructor(opts) {
    let options = opts;

    if (typeof opts === 'string') {
      options = { host: opts }; // convert host string to options object
    }

    options.serviceName = 'API Internal';

    super(options);
  }

  get(processor, options = {}) {
    return this.makeRequest(`${this.host}/processors/${processor}/accounts`, options);
  }

  getByOrganizationAndKey(processor, organization, key, options = {}) {
    return this.makeRequest(`${this.host}/processors/${processor}/accounts/${organization}/${key}`, options);
  }

  putByOrganizationAndKey(processor, organization, key, options = {}) {
    return this.makeRequest(`${this.host}/processors/${processor}/accounts/${organization}/${key}`, {
      ...options,
       method: 'PUT',
    });
  }

  putModificationsByOrganizationAndKey(processor, organization, key, options = {}) {
    return this.makeRequest(`${this.host}/processors/${processor}/accounts/${organization}/${key}/modifications`, {
      ...options,
       method: 'PUT',
    });
  }

  putModificationsAndStatusByOrganizationAndKey(processor, organization, key, options = {}) {
    return this.makeRequest(`${this.host}/processors/${processor}/accounts/${organization}/${key}/modifications/status`, {
      ...options,
       method: 'PUT',
    });
  }

}
