import update from 'immutability-helper';
import { RootActionTypes } from '../../stores/types';
import { ReadyState } from './constants';
import { ActionTypes as ExperienceActionTypes } from '../experience';
import { ActionTypes as OrganizationActionTypes } from '../organization';
import { LoadingIndicatorState } from './types';

export default function (state = {}, action: RootActionTypes): LoadingIndicatorState {
  switch (action.type) {
    case ExperienceActionTypes.GET_ITEM_PRICE_DETAILS_REQUEST:
    case OrganizationActionTypes.UPDATE_REGION_SETTING_REQUEST:
      return update(state, {
        readyState: { $set: ReadyState.SHOW },
      });
    case ExperienceActionTypes.GET_ITEM_PRICE_DETAILS_SUCCESS:
    case ExperienceActionTypes.GET_ITEM_PRICE_DETAILS_FAILURE:
    case OrganizationActionTypes.UPDATE_REGION_SETTING_SUCCESS:
    case OrganizationActionTypes.UPDATE_REGION_SETTING_FAILURE:
      return update(state, {
        readyState: { $set: ReadyState.HIDE },
      });
    default:
      return state;
  }
}
