import { css } from '@emotion/css';
import colors from '../../../../../theme/tokens/colors';

const explainerPanel = css`
  margin-bottom: 16px;
  overflow: hidden;
  transition: max-height 350ms ease;
  max-height: 58px;
  border-radius: 8px !important;
  border-color: ${colors.marketing['dark-slate-blue']} !important; /*Else the StyledCard gray border color overrides*/

  &.open {
    max-height: 1000px;

    & .explainerPanelHeader {
      & .panelStateIndicator {
        transform: rotate(90deg);
      }
    }
  }

  & .explainerPanelHeader {
    background-color: ${colors.marketing['dark-slate-blue']};
    cursor: pointer;
    color: white;
    padding: 16px 24px;

    span {
      font-size: 14px;
      line-height: 20px;
    }

    & .panelStateIndicator {
      transform: rotate(-90deg);
      transition: transform 350ms ease;
    }
  }

  & .explainerImage {
    margin: 24px;
  }

  svg {
    fill: currentColor;
  }
`;

export default explainerPanel;
