import React from 'react';
import { Droppable, DroppableProvided } from 'react-beautiful-dnd';
import { ExperiencePaymentMethodTag } from '@flowio/api-constants';
import PaymentMethodDisplay from '../PaymentMethodRuleDisplay';
import { DroppableTypes } from '../../constants/PaymentMethods';
import { sortByDisplayPosition } from '../../utilities/payment-method-rules';

export const isDisplayedPredicate = (
  tag: io.flow.v0.unions.PaymentMethodTag,
): boolean => (
  tag.discriminator === 'experience_payment_method_tag'
  && tag.value === ExperiencePaymentMethodTag.DISPLAY
);

interface Props {
  handlePaymentMethodRuleDisplayChange: (
    paymentMethodId: string,
    display: boolean,
  ) => Promise<unknown>;
  handleCCOrderOpen: (event: React.SyntheticEvent) => void;
  isFlowEmployee: boolean;
  paymentMethods: io.flow.v0.models.PaymentMethodRule[];
}

const PaymentMethodsDisplayOrder: React.FC<Props> = ({
  handlePaymentMethodRuleDisplayChange,
  handleCCOrderOpen,
  isFlowEmployee,
  paymentMethods,
}) => {
  const cards = paymentMethods.filter((pm) => pm.payment_method.type === 'card');
  cards.sort(sortByDisplayPosition);

  return (
    <Droppable
      droppableId={DroppableTypes.PAYMENT_METHODS}
    >
      {(provided: DroppableProvided): JSX.Element => {
        let index = -1;
        let hasRenderedCreditCards = false;
        return (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {paymentMethods.map((pm) => {
              if (pm.payment_method.type !== 'card') {
                index += 1;
                const isShown = (pm && pm.tags.some(isDisplayedPredicate));
                return (
                  <React.Fragment key={pm.payment_method.id}>
                    <PaymentMethodDisplay
                      paymentMethodRule={pm}
                      isFlowEmployee={isFlowEmployee}
                      slot={index}
                      handlePaymentMethodRuleDisplayChange={handlePaymentMethodRuleDisplayChange}
                      isShown={isShown}
                      canToggle={isFlowEmployee}
                    />
                  </React.Fragment>
                );
              }
              if (!hasRenderedCreditCards) {
                index += 1;
                hasRenderedCreditCards = true;
                const isShown = cards && cards.some((card) => card.tags.some(isDisplayedPredicate));
                return (
                  <React.Fragment key="munged_cards">
                    <PaymentMethodDisplay
                      cards={cards}
                      isFlowEmployee={isFlowEmployee}
                      slot={index}
                      handlePaymentMethodRuleDisplayChange={handlePaymentMethodRuleDisplayChange}
                      handleCCOrderOpen={handleCCOrderOpen}
                      isShown={isShown}
                    />
                  </React.Fragment>
                );
              }
              return null;
            })}
            {provided.placeholder}
          </div>
        );
      }}
    </Droppable>
  );
};

PaymentMethodsDisplayOrder.displayName = 'PaymentMethodsDisplayOrder';

PaymentMethodsDisplayOrder.defaultProps = {
  isFlowEmployee: false,
  paymentMethods: [],
};

export default PaymentMethodsDisplayOrder;
