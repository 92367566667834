enum ActionTypes {
  CHANGE_ACTIVE_SHIPPING_CONFIGURATION = 'LOGISTICS.CHANGE_ACTIVE_SHIPPING_CONFIGURATION',
  CHANGE_ACTIVE_SHIPPING_LANE = 'LOGISTICS.CHANGE_ACTIVE_SHIPPING_LANE',
  CREATE_CENTER_QUERY_BUILDER_REQUEST = 'LOGISTICS.CREATE_CENTER_QUERY_BUILDER_REQUEST',
  CREATE_CENTER_QUERY_BUILDER_SUCCESS = 'LOGISTICS.CREATE_CENTER_QUERY_BUILDER_SUCCESS',
  CREATE_CENTER_QUERY_BUILDER_FAILURE = 'LOGISTICS.CREATE_CENTER_QUERY_BUILDER_FAILURE',
  CREATE_SHIPPING_CONFIGURATION_REQUEST = 'LOGISTICS.CREATE_SHIPPING_CONFIGURATION_REQUEST',
  CREATE_SHIPPING_CONFIGURATION_SUCCESS = 'LOGISTICS.CREATE_SHIPPING_CONFIGURATION_SUCCESS',
  CREATE_SHIPPING_CONFIGURATION_FAILURE = 'LOGISTICS.CREATE_SHIPPING_CONFIGURATION_FAILURE',
  CREATE_SHIPPING_CONFIGURATION_COPY_REQUEST = 'LOGISTICS.CREATE_SHIPPING_CONFIGURATION_COPY_REQUEST',
  CREATE_SHIPPING_CONFIGURATION_COPY_SUCCESS = 'LOGISTICS.CREATE_SHIPPING_CONFIGURATION_COPY_SUCCESS',
  CREATE_SHIPPING_CONFIGURATION_COPY_FAILURE = 'LOGISTICS.CREATE_SHIPPING_CONFIGURATION_COPY_FAILURE',
  CREATE_SHIPPING_LANE_REQUEST = 'LOGISTICS.CREATE_SHIPPING_LANE_REQUEST',
  CREATE_SHIPPING_LANE_SUCCESS = 'LOGISTICS.CREATE_SHIPPING_LANE_SUCCESS',
  CREATE_SHIPPING_LANE_FAILURE = 'LOGISTICS.CREATE_SHIPPING_LANE_FAILURE',
  CREATE_TIER_REQUEST = 'LOGISTICS.CREATE_TIER_REQUEST',
  CREATE_TIER_SUCCESS = 'LOGISTICS.CREATE_TIER_SUCCESS',
  CREATE_TIER_FAILURE = 'LOGISTICS.CREATE_TIER_FAILURE',
  DELETE_DIALOG = 'LOGISTICS.DELETE_DIALOG',
  DELETE_SHIPPING_CONFIGURATION_REQUEST = 'LOGISTICS.DELETE_SHIPPING_CONFIGURATION_REQUEST',
  DELETE_SHIPPING_CONFIGURATION_SUCCESS = 'LOGISTICS.DELETE_SHIPPING_CONFIGURATION_SUCCESS',
  DELETE_SHIPPING_CONFIGURATION_FAILURE = 'LOGISTICS.DELETE_SHIPPING_CONFIGURATION_FAILURE',
  DELETE_SHIPPING_LANE_REQUEST = 'LOGISTICS.DELETE_SHIPPING_LANE_REQUEST',
  DELETE_SHIPPING_LANE_SUCCESS = 'LOGISTICS.DELETE_SHIPPING_LANE_SUCCESS',
  DELETE_SHIPPING_LANE_FAILURE = 'LOGISTICS.DELETE_SHIPPING_LANE_FAILURE',
  DELETE_TIER_REQUEST = 'LOGISTICS.DELETE_TIER_REQUEST',
  DELETE_TIER_SUCCESS = 'LOGISTICS.DELETE_TIER_SUCCESS',
  DELETE_TIER_FAILURE = 'LOGISTICS.DELETE_TIER_FAILURE',
  FETCH_EXPERIENCES_REQUEST = 'LOGISTICS.FETCH_EXPERIENCES_REQUEST',
  FETCH_EXPERIENCES_SUCCESS = 'LOGISTICS.FETCH_EXPERIENCES_SUCCESS',
  FETCH_EXPERIENCES_FAILURE = 'LOGISTICS.FETCH_EXPERIENCES_FAILURE',
  FETCH_SHIPPING_CONFIGURATION_REQUEST = 'LOGISTICS.FETCH_SHIPPING_CONFIGURATION_REQUEST',
  FETCH_SHIPPING_CONFIGURATION_SUCCESS = 'LOGISTICS.FETCH_SHIPPING_CONFIGURATION_SUCCESS',
  FETCH_SHIPPING_CONFIGURATION_FAILURE = 'LOGISTICS.FETCH_SHIPPING_CONFIGURATION_FAILURE',
  FETCH_SHIPPING_CONFIGURATIONS_REQUEST = 'LOGISTICS.FETCH_SHIPPING_CONFIGURATIONS_REQUEST',
  FETCH_SHIPPING_CONFIGURATIONS_SUCCESS = 'LOGISTICS.FETCH_SHIPPING_CONFIGURATIONS_SUCCESS',
  FETCH_SHIPPING_CONFIGURATIONS_FAILURE = 'LOGISTICS.FETCH_SHIPPING_CONFIGURATIONS_FAILURE',
  FETCH_SURCHARGE_SETTINGS_REQUEST = 'LOGISTICS.FETCH_SURCHARGE_SETTINGS_REQUEST',
  FETCH_SURCHARGE_SETTINGS_SUCCESS = 'LOGISTICS.FETCH_SURCHARGE_SETTINGS_SUCCESS',
  FETCH_SURCHARGE_SETTINGS_FAILURE = 'LOGISTICS.FETCH_SURCHARGE_SETTINGS_FAILURE',
  FETCH_RATECARDS_REQUEST = 'LOGISTICS.FETCH_RATECARDS_REQUEST',
  FETCH_RATECARDS_SUCCESS = 'LOGISTICS.FETCH_RATECARDS_SUCCESS',
  FETCH_RATECARDS_FAILURE = 'LOGISTICS.FETCH_RATECARDS_FAILURE',
  INVALIDATE_SHIPPING_CONFIGURATION_EXPERIENCES = 'LOGISTICS.INVALIDATE_SHIPPING_CONFIGURATION_EXPERIENCES',
  QUERY_CENTERS_CANCELED = 'LOGISTICS.QUERY_CENTERS_CANCELED',
  QUERY_CENTERS_REQUEST = 'LOGISTICS.QUERY_CENTERS_REQUEST',
  QUERY_CENTERS_SUCCESS = 'LOGISTICS.QUERY_CENTERS_SUCCESS',
  QUERY_CENTERS_FAILURE = 'LOGISTICS.QUERY_CENTERS_FAILURE',
  UPDATE_SHIPPING_CONFIGURATION_REQUEST = 'LOGISTICS.UPDATE_SHIPPING_CONFIGURATION_REQUEST',
  UPDATE_SHIPPING_CONFIGURATION_SUCCESS = 'LOGISTICS.UPDATE_SHIPPING_CONFIGURATION_SUCCESS',
  UPDATE_SHIPPING_CONFIGURATION_FAILURE = 'LOGISTICS.UPDATE_SHIPPING_CONFIGURATION_FAILURE',
  UPDATE_SHIPPING_LANE_REQUEST = 'LOGISTICS.UPDATE_SHIPPING_LANE_REQUEST',
  UPDATE_SHIPPING_LANE_SUCCESS = 'LOGISTICS.UPDATE_SHIPPING_LANE_SUCCESS',
  UPDATE_SHIPPING_LANE_FAILURE = 'LOGISTICS.UPDATE_SHIPPING_LANE_FAILURE',
  UPDATE_TIER_REQUEST = 'LOGISTICS.UPDATE_TIER_REQUEST',
  UPDATE_TIER_SUCCESS = 'LOGISTICS.UPDATE_TIER_SUCCESS',
  UPDATE_TIER_FAILURE = 'LOGISTICS.UPDATE_TIER_FAILURE',
  UPDATE_DIALOG = 'LOGISTICS.UPDATE_DIALOG',
  UPDATE_RETURN_SHIPPING_LANES_PAGE = 'LOGISTICS.UPDATE_RETURN_SHIPPING_LANES_PAGE',
  UPDATE_OUTBOUND_SHIPPING_LANES_PAGE = 'LOGISTICS.UPDATE_OUTBOUND_SHIPPING_LANES_PAGE',
  RESET_SHIPPING_LANES_PAGES = 'LOGISTICS.RESET_SHIPPING_LANES_PAGES',
}

export default ActionTypes;
