import { FormGroup } from '@flowio/react-form-group';
import React, { SyntheticEvent } from 'react';
import { TextInput } from '@flowio/react-text-input';
import get from 'lodash/get';

import { Card, CardContent } from '@flowio/react-card';
import * as styles from './SearchBar.styles';
import { SearchBarProps } from '../../types/components';

class SearchBar extends React.Component<SearchBarProps> {
  static defaultProps = {
    shippingLabelRequestStatus: undefined,
    searchText: '',
  };

  constructor(props: SearchBarProps) {
    super(props);
    this.handleTextInputBlur = this.handleTextInputBlur.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleTextInputBlur(event: React.ChangeEvent<HTMLInputElement>) {
    const {
      organizationId,
      onSubmit,
      searchText,
      currentOrderNumber,
    } = this.props;

    // Only want to search on blur if search text exists (and is non empty)
    // AND if the search text has actually changed
    if (searchText && searchText.trim().length > 0 && currentOrderNumber !== searchText) {
      onSubmit({
        organizationId,
        number: event.target.value,
      });
    }
  }

  // handleSubmit(event: React.FormEvent<HTMLFormElement>) {
  handleSubmit(event: SyntheticEvent<HTMLFormElement>) {
    const { organizationId, onSubmit } = this.props;
    const formElement = event.currentTarget.elements.namedItem('number') as HTMLInputElement | undefined;

    event.preventDefault();

    onSubmit({
      organizationId,
      number: formElement?.value,
    });
  }

  render() {
    const {
      onSearchChange,
      shippingLabelRequestStatus,
      searchText,
    } = this.props;

    return (
      <Card>
        <CardContent className={styles.cardContent}>
          <div className={styles.labelContainer}>
            <b className={styles.labelTitle}>Order Number</b>
            <div className={styles.labelSubTitle}>Scan or enter an order number to start</div>
          </div>
          <form method="post" onSubmit={this.handleSubmit} className={styles.form}>
            <FormGroup
              labelFor="number"
              className={styles.formField}
            >
              <TextInput
                disabled={get(shippingLabelRequestStatus, 'pending')}
                autoComplete="off"
                autoCapitalize="off"
                autoCorrect="off"
                autoFocus
                fluid
                name="number"
                onChange={
                  (e: React.ChangeEvent<HTMLInputElement>) => onSearchChange(e.target.value)
                }
                value={searchText}
                onBlur={this.handleTextInputBlur}
                required
                type="text"
              />
            </FormGroup>
            <input className={styles.submitButton} type="submit" />
          </form>
        </CardContent>
      </Card>
    );
  }
}

export default SearchBar;
