import update from 'immutability-helper';
import keyBy from 'lodash/keyBy';
import ActionTypes from '../constants/action-types';

import { TransactionsState } from '../types';
import { RootActionTypes } from '../../../stores/types';

const defaultState = {};

export default function (state = defaultState, action: RootActionTypes): TransactionsState {
  switch (action.type) {
    case ActionTypes.FETCH_TRANSACTIONS_SUCCESS:
      return update(state, { $merge: keyBy<io.flow.internal.v0.unions.Transaction>(action.payload.results, 'id') });
    default:
      return state;
  }
}
