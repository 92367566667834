import ActionTypes from '../constants/ActionTypes';
import {
  MarketingActions,
  MarketingDeleteFeedConfirmationDialogState,
} from '../types';

const defaultState: MarketingDeleteFeedConfirmationDialogState = {
  showDialog: false,
  data: undefined,
};

export default function deleteFeedConfirmationDialogReducer(
  state = defaultState,
  action: MarketingActions,
): MarketingDeleteFeedConfirmationDialogState {
  switch (action.type) {
    case ActionTypes.DELETE_FEED_CONFIRMATION_DIALOG_OPEN:
      return {
        ...state,
        showDialog: true,
        data: {
          ...action.data,
        },
      };
    case ActionTypes.DELETE_FEED_CONFIRMATION_DIALOG_CLOSE:
      return {
        ...state,
        showDialog: false,
        data: undefined,
      };
    default:
      return state;
  }
}
