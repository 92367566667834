import Client from './client';

export default class MarketingGatewayChannels extends Client {
  constructor(opts) {
    let options = opts;

    if (typeof opts === 'string') {
      options = { host: opts }; // convert host string to options object
    }

    options.serviceName = 'API Internal';

    super(options);
  }

  get(organization, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/marketing/gateway/channels`, options);
  }

  postNewChannelRequest(organization, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/marketing/gateway/channels/new_channel_request`, {
      ...options,
       method: 'POST',
    });
  }

  putConnectByChannelId(organization, channelId, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/marketing/gateway/channels/${channelId}/connect`, {
      ...options,
       method: 'PUT',
    });
  }

  putDisconnectByChannelId(organization, channelId, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/marketing/gateway/channels/${channelId}/disconnect`, {
      ...options,
       method: 'PUT',
    });
  }

  getFeedsAndDownloadsByChannelIdAndFeedAndFormat(organization, channelId, feed, format, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/marketing/gateway/channels/${channelId}/feeds/${feed}/downloads/${format}`, options);
  }

}
