import get from 'lodash/get';

// It sucks we have to do it this way,
// just make sure to update this mapping when a new surcharge is added

const surchargeMappings = {
  ratecard_fuel_surcharge: 'Fuel Surcharges',
  ratecard_rural_shipment_fee: 'Remote Area Delivery Fee',
  ratecard_emergency_situation_surcharge_fee: 'Emergency Situation Surcharge',
  ratecard_duties_taxes_paid_surcharge_fee: 'DTP Fee',
};

export default function tierSurchargeToSentence(surchargeKey: string) {
  return get(surchargeMappings, surchargeKey, surchargeKey);
}
