import { Field } from 'redux-form';
import React from 'react';

import * as styles from './TierCarrierServiceStrategyOption.styles';
import { TierCarrierServiceStrategyOptionProps } from '../../types/components';

const TierCarrierServiceStrategyOption: React.FC<TierCarrierServiceStrategyOptionProps> = ({
  heading,
  iconName,
  id,
  name,
  tagline,
  value,
}) => (
  <div className={styles.strategy}>
    <Field
      type="radio"
      component="input"
      className={styles.input}
      id={id}
      name={name}
      value={value}
    />
    <label htmlFor={id} className={[styles.label, 'label'].join(' ')}>
      {(iconName != null) && (
        <span className={[styles.iconWrapper, 'iconWrapper'].join(' ')}>
          {iconName}
        </span>
      )}
      {(heading != null) && (
        <span className={styles.heading}>
          {heading}
        </span>
      )}
      {(tagline != null) && (
        <span className={styles.tagline}>
          {tagline}
        </span>
      )}
    </label>
  </div>
);

TierCarrierServiceStrategyOption.displayName = 'TierCarrierServiceStrategyOption';

TierCarrierServiceStrategyOption.defaultProps = {
  heading: undefined,
  iconName: undefined,
  id: undefined,
  tagline: undefined,
};

export default TierCarrierServiceStrategyOption;
