import { Field, Form } from 'redux-form';
import ApiPropTypes from '@flowio/api-prop-types';
import PropTypes from 'prop-types';
import React from 'react';
import { Text } from '@flowio/react-text';
import get from 'lodash/get';

const DeletePriceBook = ({ handleSubmit, priceBook }) => (
  <Form onSubmit={handleSubmit}>
    <Field name="priceBookKey" type="hidden" component="input" />
    <Field name="organizationId" type="hidden" component="input" />
    <Text>
      This action cannot be undone.
      Are you sure you want to delete price book {get(priceBook, 'name')}?
    </Text>
  </Form>
);

DeletePriceBook.displayName = 'DeletePriceBook';

DeletePriceBook.propTypes = {
  handleSubmit: PropTypes.func,
  priceBook: ApiPropTypes.priceBook,
};

DeletePriceBook.defaultProps = {
  handleSubmit: undefined,
  priceBook: undefined,
};

export default DeletePriceBook;
