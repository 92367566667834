import { Form, Field, InjectedFormProps } from 'redux-form';
import React from 'react';
import { Banner, BannerTitle } from '@flowio/react-banner';
import { FormGroup } from '@flowio/react-form-group';
import { Card, CardHeader, CardContent } from '@flowio/react-card';
import { Grid, Column, Row } from '@flowio/react-grid';
import { css } from '@emotion/css';

import { FlowRole, Role } from '@flowio/api-constants';
import { startCase } from 'lodash';
import classNames from 'classnames';
import GenericError from '../../../../../components/GenericError';
import { MembershipFormValues, MergedProps as Props } from '../types';
import * as styles from './memberships-form.styles';
import { TextField, RadioButtonGroup } from '../../../../../components/ReduxFormFieldAdapters';

const banner = css({
  padding: '8px',
});

const bannerTitle = css({
  fontSize: '14px',
});

const MembershipForm: React.FC<InjectedFormProps<MembershipFormValues, Props> & Props> = ({
  error,
  handleSubmit,
  hasAdminRole,
  isEditingSelf,
  editMembership,
  hasOnlyChannelOrgAdminRole,
}) => {
  const isEditingMember = !!editMembership;
  const defaultRoleRadioProps = {
    theme: {
      label: styles.radioLabel,
    },
  };

  return (
    <Form onSubmit={handleSubmit}>
      {error && (
        <GenericError error={error} />
      )}
      {editMembership && <Field name="id" type="hidden" component="input" />}
      <Field name="organization" type="hidden" component="input" />
      <Card>
        <CardHeader dividing>
          Info
        </CardHeader>
        <CardContent>
          <FormGroup
            className={styles.formGroup}
            inline
            labelText="Email"
          >
            <Field
              name="email"
              component={TextField}
              fluid
              disabled={isEditingMember}
              hintText="Enter Email"
            />
          </FormGroup>
        </CardContent>
      </Card>

      <Card>
        <CardHeader dividing>
          Role
        </CardHeader>
        <CardContent>
          {isEditingSelf
            && (
              <Banner
                css={banner}
                intent="neutral"
              >
                <BannerTitle
                  css={bannerTitle}
                  content="You are editing your own role. Please note that your new role may have different access and permission levels."
                />
              </Banner>
            )}
          <Grid>
            <Row>
              <Column span={4}>
                <Field
                  name="role"
                  className={styles.radioGroup}
                  component={RadioButtonGroup}
                  options={!hasOnlyChannelOrgAdminRole ? [
                    {
                      ...defaultRoleRadioProps,
                      labelText: startCase(Role.ADMIN),
                      value: Role.ADMIN,
                      className: classNames({ [styles.memberUser]: !hasAdminRole }),
                      disabled: !hasAdminRole,
                    },
                    {
                      ...defaultRoleRadioProps,
                      labelText: startCase(Role.MEMBER),
                      value: Role.MEMBER,
                    },
                    {
                      ...defaultRoleRadioProps,
                      labelText: 'Customer Support',
                      value: FlowRole.ORGANIZATION_CUSTOMER_SERVICE,
                    },
                    {
                      ...defaultRoleRadioProps,
                      labelText: 'Fulfillment',
                      value: FlowRole.ORGANIZATION_FULFILLMENT,
                    },
                    {
                      ...defaultRoleRadioProps,
                      labelText: 'Channel Organization Admin',
                      value: FlowRole.CHANNEL_ORGANIZATION_ADMIN,
                    },
                    {
                      ...defaultRoleRadioProps,
                      labelText: 'Channel Organization Classification',
                      value: FlowRole.ORGANIZATION_CLASSIFICATION,
                    },
                  ] : [
                    {
                      ...defaultRoleRadioProps,
                      labelText: 'Fulfillment',
                      value: FlowRole.ORGANIZATION_FULFILLMENT,
                    },
                    {
                      ...defaultRoleRadioProps,
                      labelText: 'Channel Organization Admin',
                      value: FlowRole.CHANNEL_ORGANIZATION_ADMIN,
                    },
                  ]}
                />
              </Column>
              <Column span={8} className="role-radio-desc">
                <Row className={styles.gridRow}>
                  { !hasOnlyChannelOrgAdminRole && (
                  <>
                    <Column span={12}>
                      <span className={classNames({ [styles.grey]: !hasAdminRole })}>
                        Admin has access to all areas of the Flow platform.
                      </span>
                    </Column>
                    <Column span={12}>
                      <span>
                        Member has access to all areas of the Flow platform, but
                        does not have permission to assign admin role or remove member.
                      </span>
                    </Column>
                    <Column span={12}>
                      <span>
                        Customer Support only has access to the Customer Service,
                        Fulfillment, and Utility & Tools areas of the Flow platform.
                      </span>
                    </Column>
                  </>
                  )}
                  <Column span={12}>
                    <span>
                      Fulfillment only has access to the Fulfillment area of the Flow platform.
                    </span>
                  </Column>
                  <Column span={12}>
                    <span>
                      Channel Organization Admin only has access to the
                      Billing and Members areas of the Flow platform.
                    </span>
                  </Column>
                  <Column span={12}>
                    <span>
                      Channel Organization Classification only has access to the
                      Product Catalog.
                    </span>
                  </Column>
                </Row>
              </Column>
            </Row>
          </Grid>
        </CardContent>
      </Card>
    </Form>
  );
};

MembershipForm.displayName = 'MembershipForm';

MembershipForm.defaultProps = {
  error: undefined,
};

export default MembershipForm;
