import { OrderDetailsGetByNumberResponse } from '@flowio/api-internal-sdk';
import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import { ActionTypes } from '../constants';

function createRequestAction(): RootActionTypes {
  return {
    type: ActionTypes.READ_ORDER_BY_NUMBER_REQUEST,
  };
}

function createSuccessAction(payload: io.flow.v0.models.Order): RootActionTypes {
  return {
    type: ActionTypes.READ_ORDER_BY_NUMBER_SUCCESS,
    payload,
  };
}

function createFailureAction(payload: FormattedErrorMessages): RootActionTypes {
  return {
    type: ActionTypes.READ_ORDER_BY_NUMBER_FAILURE,
    payload,
  };
}

function readOrderByNumber(
  organizationId: string,
  orderNumber: string,
): ThunkResult<Promise<OrderDetailsGetByNumberResponse>> {
  return function readOrderByNumberEffects(
    dispatch,
    getState,
    extra,
  ): Promise<OrderDetailsGetByNumberResponse> {
    dispatch(createRequestAction());

    return extra.apiInternal(getState()).orderDetails.getByNumber({
      organization: organizationId,
      number: orderNumber,
    }).then((response) => {
      if (response.ok) {
        dispatch(createSuccessAction(response.body.order));
        return response;
      }

      const error = formatErrorResponseV2(response);
      dispatch(createFailureAction(error));
      return response;
    });
  };
}

export default readOrderByNumber;
