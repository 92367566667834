import React from 'react';
import { Box, BoxItem } from '@flowio/react-box';
import { Label } from '@flowio/react-label';

import * as styles from './TierList.styles';
import { TierListItemNameProps } from '../../types/components';

const TierListItemName: React.FC<TierListItemNameProps> = ({
  name,
}) => (
  <Box spacing="loose">
    <BoxItem className={styles.tierLabel}>
      <Label content="Shipping Tier" />
    </BoxItem>
    <BoxItem>
      <span className={styles.tierName}>
        {name}
      </span>
    </BoxItem>
  </Box>
);

TierListItemName.displayName = 'TierListItemName';

export default TierListItemName;
