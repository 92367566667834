import { Link } from 'react-router';
import { LaneDirection as LaneDirections } from '@flowio/api-constants';
import { Box } from '@flowio/react-box';
import { FlatButton } from '@flowio/react-button';
import React from 'react';
import { Text } from '@flowio/react-text';
import find from 'lodash/find';
import property from 'lodash/property';
import matches from 'lodash/matches';
import RegionLabel from '../../../reference/components/RegionLabel';
import * as styles from './ExperienceShippingLane.styles';
import tierRuleToSentence from '../../../logistics/utilities/tierRuleToSentence';

interface Props {
  organizationId: string;
  regions: io.flow.v0.models.Region[];
  shippingLanes: io.flow.v0.models.ShippingLane[];
  tierDirection: io.flow.v0.enums.LaneDirection;
}

const ExperienceShippingLane: React.FC<Props> = ({
  organizationId,
  regions,
  shippingLanes,
  tierDirection,
}) => {
  const lanes = shippingLanes.filter((
    (shippingLane) => shippingLane.tiers.some((
      (tier) => tier.direction === tierDirection
    ))
  ));
  const titleText = tierDirection === LaneDirections.OUTBOUND ? 'Shipping Tiers' : 'Return Shipping Tiers';
  const noResultText = tierDirection === LaneDirections.OUTBOUND ? 'No Shipping Tiers' : 'No Return Shipping Tiers';
  return (
    <section className={styles.container}>
      <h1 className={styles.title}>
        {titleText}
      </h1>
      {(!lanes.length) ? (
        <Text>{noResultText}</Text>
      ) : lanes.map((lane) => {
        const region = find(regions, { id: lane.region });
        return (
          <div className="lane" key={lane.id}>
            <Box alignItems="center" justifyContent="between" spacing="loose">
              <div className={styles.laneDescription}>
                {tierDirection === LaneDirections.OUTBOUND && (
                  <>
                    <Text>Shipping from centers</Text>
                    <Text className={styles.centers} data-length={lane.centers.length}>
                      <strong>{lane.centers.map(property('name')).join(', ')}</strong>
                    </Text>
                    <Text>to</Text>
                    {region && (
                      <RegionLabel className={styles.region} region={region} />
                    )}
                  </>
                )}
                {tierDirection === LaneDirections.RETURN && (
                  <>
                    <Text className={styles.laneText}>Shipping from </Text>
                    {region && (
                      <RegionLabel className={styles.region} region={region} />
                    )}
                    <Text className={styles.laneText}>&nbsp;to centers </Text>
                    <Text className={styles.centers} data-length={lane.centers.length}>
                      <strong>{lane.centers.map(property('name')).join(', ')}</strong>
                    </Text>
                  </>
                )}
              </div>
              <Link to={`/${organizationId}/logistics/shipping/configurations/${lane.shipping_configuration.key}/lanes/${lane.id}`}>
                <FlatButton
                  content="View Details"
                />
              </Link>
            </Box>
            {lane.tiers
              .filter(matches({ direction: tierDirection }))
              .map((tier) => (
                <div key={tier.id} className={styles.tier}>
                  <div className={styles.tierName}>
                    {tier.name}
                  </div>
                  <div className={styles.tierRules}>
                    {tier.rules.map((tierRule) => tierRuleToSentence(tierRule, tier)).join('; ')}
                  </div>
                </div>
              ))}
          </div>
        );
      })}
    </section>
  );
};

ExperienceShippingLane.displayName = 'ExperienceShippingLane';

export default ExperienceShippingLane;
