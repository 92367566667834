import { css } from '@emotion/css';
import { css as cssReact } from '@emotion/react';
import colors from '../../../../theme/tokens/colors';

export const withPadding = css`
  padding: 0 40px 40px;
`;

export const formHeading = css`
  display: flex;
  align-items: center;
`;

export const pill = css`
  display: inline-block;
  text-align: center;
  text-transform: capitalize;
  font-weight: bold;
  color: ${colors.white};
  padding: 5px 10px;
  border-radius: 10px;
  min-width: 100px;

  &.google {
    background-color: ${colors.red[300]};
  }

  &.facebook {
    background-color: purple;
  }

  &.flow {
    background-color: ${colors['flow-blue'][400]};
  }
`;

export const loadingContainer = css`
  text-align: center;
  color: ${colors.marketing['dark-slate-blue']};
  margin: 20px;
`;

export const countryCheckbox = css`
  color: ${colors.marketing['dark-slate-blue']};

  box-sizing: border-box;
  padding: 15px 0;
  flex-basis: 25%;
`;

export const selectAllCountriesCheckbox = css`
  ${countryCheckbox}
  flex-basis: unset;
  & span {
    font-weight: bold !important;
  }
`;

export const countriesSelection = css`
  padding: 20px;
  border-radius: 8px;
  border: 1px solid ${colors.mercury};
  display: flex;
  flex-wrap: wrap;
`;

export const unavailableCountries = css`
  margin: 10px 0 0;
  padding: 0;
  font-size: 14px;

  & li {
    color: ${colors.marketing['light-slate-gray']};
    display: inline-block;
    list-style-type: none;
    font-weight: bold;

    &:not(:last-child):after {
      content: '•';
      padding: 0px 10px;
    }
  }
`;

export const unavailable = cssReact`
    border: none;
    margin: 20px 0 0;
    padding: 0;
`;

export const content = css`
  background-color: transparent;
  padding: 0;
`;

export const header = css`
  & h4 {
    padding: 0;
  }
`;

export const heading = css`
  display: flex;
  flex-flow: column;
  align-items: center;
  color: ${colors.marketing['dark-slate-blue']};

  & div:first-child {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 20px;

    & h3 {
      margin: 0;
    }
  }

  & p {
    margin-bottom: 20px;
    font-size: 14px;
    color: ${colors.marketing['light-slate-gray']};
    text-transform: none;

    & a {
      text-decoration: underline;

      &:hover {
        color: ${colors.marketing['dark-slate-blue']};
      }
    }
  }
`;

export const title = css`
    color: ${colors.marketing['dark-slate-blue']};
    flex-grow: 1;
`;

export const helpLink = css`
  color:  ${colors.marketing['light-slate-gray']};
  text-decoration: underline;

  &:hover {
    color: ${colors.marketing['dark-slate-blue']};
  }
`;
