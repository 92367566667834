import PropTypes from 'prop-types';

import ActionTypes from '../constants/ActionTypes';
import { MarketingActions } from '../types';
import { withAssertPropTypes } from '../../../utilities/prop-types';

const deleteDialog = ({ type }: { type: string }): MarketingActions => ({
  type: ActionTypes.DELETE_DIALOG,
  payload: {
    type,
  },
});

export default withAssertPropTypes({
  type: PropTypes.string.isRequired,
})(deleteDialog);
