import { createStructuredSelector } from 'reselect';
import { getPageNumber, getPageSize } from '@flowio/redux-filtering-paging-sorting';
import constant from 'lodash/constant';

import { getOptinPromptsPaginationState, getActiveOptinAttributeKey } from '../selectors';
import { getOrganizationId } from '../../organization/selectors';
import fetchOptinPrompts from './fetchOptinPrompts';
import { RootState, ThunkDispatcher } from '../../../stores/types';

type PageState = {
  organizationId: string;
  entriesPerPage: number;
  pageNumber: number;
  queryParams: {
    expand: string;
    optin_attribute_key?: string;
  };
};

const getPageState = createStructuredSelector<RootState, PageState>({
  organizationId: getOrganizationId,
  entriesPerPage: getPageSize(getOptinPromptsPaginationState),
  pageNumber: getPageNumber(getOptinPromptsPaginationState),
  queryParams: createStructuredSelector({
    expand: constant('content'),
    optin_attribute_key: getActiveOptinAttributeKey,
  }),
});

export default function refreshOptinPrompts() {
  return function refreshOptinPromptsSideEffects(
    dispatch: ThunkDispatcher,
    getState: () => RootState,
  ) {
    return dispatch(fetchOptinPrompts(getPageState(getState())));
  };
}
