import { withFetch } from '@flowio/redux-fetch';
import {
  fetchCountries, fetchLanguages, fetchCurrencies,
} from '../../../../reference/actions';
import {
  fetchFeeds,
  fetchFeedSources,
  fetchChannels,
} from '../../../actions';
import FeedsPage from '../components/FeedsPage';
import {
  ThunkDispatcher,
  RootState,
} from '../../../../../stores/types';
import { OwnProps } from '../types';

const getAsyncState = (
  dispatch: ThunkDispatcher,
  _getState: () => RootState,
  props: OwnProps,
): Promise<unknown> => {
  const { params } = props;
  const { organization: organizationId, accountId, platformId } = params;

  return Promise.all([
    dispatch(fetchFeeds({
      organizationId,
      channelId: platformId,
      marketingId: accountId,
    })),
    dispatch(fetchFeedSources({ organizationId, destinationPlatform: platformId })),
    dispatch(fetchChannels({ organizationId })),
    dispatch(fetchLanguages()),
    dispatch(fetchCountries()),
    dispatch(fetchCurrencies()),
  ]);
};

export default withFetch(getAsyncState)(FeedsPage);
