import { api } from '../../../utilities/clients';
import checkHttpStatus from '../../../utilities/check-http-status';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import { RootState } from '../../../stores/types';

export default function createQueryBuilderFromExperienceFilterForm(
  state: RootState,
  organizationId: string,
  queryBuilderFilterForm: io.flow.v0.models.QueryBuilderFilterForm,
): Promise<io.flow.v0.models.QueryBuilder> {
  return api.experiences(state)
    .postQueryAndBuilders(organizationId, {
      body: JSON.stringify(queryBuilderFilterForm),
    })
    .then(checkHttpStatus)
    .then((response: LegacyResponse<io.flow.v0.models.QueryBuilder>) => response.result);
}
