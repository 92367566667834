import React from 'react';
import FacebookChannelDetails from './FacebookChannelDetails';
import GoogleChannelDetails from './GoogleChannelDetails';
import SupportedChannelDetails from './SupportedChannelDetails';

type Props = {
  details?: io.flow.internal.v0.unions.MarketingGatewayChannelDetails;
  error?: io.flow.internal.v0.models.MarketingGatewayError;
  platform: io.flow.internal.v0.enums.MarketingGatewayPlatform;
};

const ChannelDetails: React.FC<Props> = ({
  platform,
  details,
  error,
}) => {
  switch (platform) {
    case 'facebook':
      return <FacebookChannelDetails details={details} error={error} />;
    case 'google':
      return <GoogleChannelDetails details={details} error={error} />;
    default:
      return <SupportedChannelDetails details={details} />;
  }
};

ChannelDetails.displayName = 'ChannelDetails';

export default ChannelDetails;
