import { Field, Form } from 'redux-form';
import React from 'react';
import { Stack } from '@flowio/react-stack';
import { Text } from '@flowio/react-text';

import GenericError from '../../../../components/GenericError';
import { TierDeleteFormCombinedProps } from '../../types/components';

const TierDeleteForm: React.FC<TierDeleteFormCombinedProps> = ({ error }) => (
  <Form>
    <Field type="hidden" component="input" name="organizationId" />
    <Field type="hidden" component="input" name="shippingConfigurationKey" />
    <Field type="hidden" component="input" name="shippingLaneId" />
    <Field type="hidden" component="input" name="tierId" />
    <Stack orientation="vertical" spacing="loose">
      {error && (
        <GenericError error={error} />
      )}
      <Text>
        This action cannot be undone. Are you sure you want to delete this shipping tier?
      </Text>
    </Stack>
  </Form>
);

TierDeleteForm.displayName = 'TierDeleteForm';

export default TierDeleteForm;
