import update from 'immutability-helper';
import ActionTypes from '../constants/ActionTypes';

const defaultState = { };

export default function googleFeedsFormsReducer(
  state = defaultState,
  action,
) {
  switch (action.type) {
    case ActionTypes.FETCH_ORGANIZATION_GOOGLE_FEEDS_SUCCESS:
      return [
        ...action.payload,
      ];

    case ActionTypes.FETCH_ORGANIZATION_GOOGLE_FEEDS_FAILURE:
      return update(state, {
        error: { $set: action.payload },
      });

    default:
      return state;
  }
}
