import { createSelector } from 'reselect';
import property from 'lodash/property';
import getDialogProperties from './getDialogProperties';
import { RootState } from '../../../stores/types';

export default (
  dialogName: string,
  dialogProp: string,
): any => createSelector<RootState, any, any>(
  getDialogProperties(dialogName),
  property(dialogProp),
);
