import { Archive, Transaction, Download } from '@flowio/react-icons';
import { Link } from 'react-router';
import React from 'react';
import { Box } from '@flowio/react-box';
import { Button, OutlineButton, ButtonGroup } from '@flowio/react-button';
import get from 'lodash/get';
import toNumber from 'lodash/toNumber';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import toLower from 'lodash/toLower';
import {
  Card,
  CardHeader,
  CardContent,
  CardFooter,
  CardEmptyState,
  CardTitle,
} from '@flowio/react-card';
import {
  Table,
  TableBody,
  TableHead,
  TableHeadColumn,
  TableRow,
  TableRowColumn,
} from '@flowio/react-table';
import { PageContent } from '@flowio/react-page';
import { css } from '@emotion/react';
import { Dropdown } from '@flowio/react-dropdown';

import AccountSelection from '../../account-selection';
import AccountSummary from '../../account-summary';
import FormattedDate from '../../../../../components/formatted-date';
import Pagination from '../../../../../components/pagination';
import { MergedProps as Props } from '../types';

const leftBtnStyles = css({
  borderRadius: '3px 0px 0px 3px',
});

const rightBtnStyles = css({
  borderRadius: '0px 3px 3px 0px',
});

const billingTableContainerStyles = css({
  minWidth: '768px',
  overflow: 'scroll',
  whiteSpace: 'nowrap',
});

export default class AccountStatements extends React.PureComponent<Props> {
  static displayName = 'AccountStatements';

  handleRequestNextPage = (): void => {
    const { pageNumber, onRequestPage } = this.props;
    const nextPage = toNumber(pageNumber) + 1;
    onRequestPage(nextPage);
  };

  handleRequestPreviousPage = (): void => {
    const { pageNumber, onRequestPage } = this.props;
    const nextPage = toNumber(pageNumber) - 1;
    onRequestPage(nextPage);
  };

  render(): React.ReactElement {
    const {
      accounts,
      account,
      firstPage,
      lastPage,
      organization,
      onRequestAccountChange,
      statements,
    } = this.props;

    const hasAccounts = !isEmpty(accounts);
    const hasMultipleAccounts = hasAccounts && accounts.length > 1;
    const hasStatements = !isEmpty(statements);

    return (
      <PageContent>
        {!hasAccounts && (
          <Card>
            <CardContent>
              <CardEmptyState content="No accounts available. Please contact Flow to set up your accounts." />
            </CardContent>
            <CardFooter />
          </Card>
        )}
        {hasMultipleAccounts && (
          <Card>
            <CardHeader>
              <Box alignItems="center" justifyContent="between">
                <CardTitle content="Select Account" />
                <AccountSelection
                  accounts={accounts}
                  onRequestChange={onRequestAccountChange}
                  organization={organization}
                  value={account.currency}
                />
              </Box>
            </CardHeader>
          </Card>
        )}
        {hasAccounts && (
          <Card>
            <CardHeader dividing>
              <CardTitle content="Account Summary" />
            </CardHeader>
            <CardContent>
              <AccountSummary account={account} />
            </CardContent>
            <CardFooter />
          </Card>
        )}
        {hasAccounts && (
          <Card>
            <CardHeader dividing>
              <ButtonGroup>
                <Link to={`/${organization.id}/organization/accounts/${toLower(account.key)}/statements`}>
                  <Button
                    content="Statements"
                    leftIcon={Archive}
                    intent="primary"
                    css={leftBtnStyles}
                  />
                </Link>
                <Link to={`/${organization.id}/organization/accounts/${toLower(account.key)}/transactions`}>
                  <OutlineButton
                    content="Transactions"
                    leftIcon={Transaction}
                    css={rightBtnStyles}
                  />
                </Link>
              </ButtonGroup>
            </CardHeader>
            {!hasStatements ? (
              <CardContent fitted>
                <CardEmptyState content="No statements available." />
              </CardContent>
            ) : (
              <CardContent fitted css={billingTableContainerStyles}>
                <Table striped displayDensity="compact">
                  <TableHead>
                    <TableRow>
                      <TableHeadColumn>
                        #
                      </TableHeadColumn>
                      <TableHeadColumn>
                        Statement Date
                      </TableHeadColumn>
                      <TableHeadColumn />
                      <TableHeadColumn textAlign="right">
                        Starting Balance
                      </TableHeadColumn>
                      <TableHeadColumn textAlign="right">
                        Captures
                      </TableHeadColumn>
                      <TableHeadColumn textAlign="right">
                        Refunds
                      </TableHeadColumn>
                      <TableHeadColumn textAlign="right">
                        Subscriptions
                      </TableHeadColumn>
                      <TableHeadColumn textAlign="right">
                        Adjustments
                      </TableHeadColumn>
                      <TableHeadColumn textAlign="right">
                        VCC
                      </TableHeadColumn>
                      <TableHeadColumn textAlign="right">
                        VCR
                      </TableHeadColumn>
                      <TableHeadColumn textAlign="right">
                        Transfers
                      </TableHeadColumn>
                      <TableHeadColumn textAlign="right">
                        Invoices
                      </TableHeadColumn>
                      <TableHeadColumn textAlign="right">
                        Ending Balance
                      </TableHeadColumn>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {map(statements, ({
                      attachments, id, period, totals,
                    }) => (
                      <TableRow key={id}>
                        <TableRowColumn>
                          {id}
                        </TableRowColumn>
                        <TableRowColumn>
                          <FormattedDate utc timezone={account.timezone.name} format="MMM. DD, YYYY" value={period.from} />
                          {' - '}
                          <FormattedDate utc timezone={account.timezone.name} format="MMM. DD, YYYY" value={period.to} />
                        </TableRowColumn>
                        <TableRowColumn>
                          {!isEmpty(attachments) && (
                            <Dropdown
                              trigger={{ content: <Download width={20} />, size: 'small', css: { cursor: 'pointer' } }}
                              onItemSelection={(index): void => {
                                document.location.href = attachments[index].url;
                              }}
                              items={attachments.map((att) => ({
                                content: att.name,
                              }))}
                            />
                          )}
                        </TableRowColumn>
                        <TableRowColumn textAlign="right">
                          {get(totals, 'starting_balance.label')}
                        </TableRowColumn>
                        <TableRowColumn textAlign="right">
                          {get(totals, 'capture.label')}
                        </TableRowColumn>
                        <TableRowColumn textAlign="right">
                          {get(totals, 'refund.label')}
                        </TableRowColumn>
                        <TableRowColumn textAlign="right">
                          {get(totals, 'subscription.label')}
                        </TableRowColumn>
                        <TableRowColumn textAlign="right">
                          {get(totals, 'adjustment.label')}
                        </TableRowColumn>
                        <TableRowColumn textAlign="right">
                          {get(totals, 'virtual_card_capture.label')}
                        </TableRowColumn>
                        <TableRowColumn textAlign="right">
                          {get(totals, 'virtual_card_refund.label')}
                        </TableRowColumn>
                        <TableRowColumn textAlign="right">
                          {get(totals, 'transfer.label')}
                        </TableRowColumn>
                        <TableRowColumn textAlign="right">
                          {get(totals, 'invoice.label')}
                        </TableRowColumn>
                        <TableRowColumn textAlign="right">
                          {get(totals, 'ending_balance.label')}
                        </TableRowColumn>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardContent>
            )}
            {!hasStatements ? (
              <CardFooter />
            ) : (
              <CardFooter>
                <Box alignItems="center" justifyContent="between">
                  <Pagination
                    isNextPageDisabled={lastPage}
                    isPreviousPageDisabled={firstPage}
                    onRequestNextPage={this.handleRequestNextPage}
                    onRequestPreviousPage={this.handleRequestPreviousPage}
                  />
                </Box>
              </CardFooter>
            )}
          </Card>
        )}
      </PageContent>
    );
  }
}
