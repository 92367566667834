import React from 'react';
import noop from 'lodash/noop';
import isEmpty from 'lodash/isEmpty';

import { PageContent } from '@flowio/react-page';

import AddRuleWorksheet from '../../containers/AddRuleWorksheet';
import AvailabilityRulesCard from '../AvailabilityRulesCard';
import EditFeatureWorksheet from '../../containers/EditFeatureWorksheet';
import EditRuleWorksheet from '../../containers/EditRuleWorksheet';
import FeatureDetailProfileCard from '../FeatureDetailProfileCard';
import FeatureDeleteConfirm from '../../containers/FeatureDeleteConfirm';
import FeatureReleaseConfirm from '../../containers/FeatureReleaseConfirm';
import GenericError from '../../../../components/GenericError';
import RuleDeleteConfirm from '../../containers/RuleDeleteConfirm';
import { FeatureDetailProps } from '../../types/components';

const FeatureDetail: React.FC<FeatureDetailProps> = ({
  errors,
  feature,
  ruleFilters,
  isControlOrganization,
  organizationId,
  onAddRule,
  onChangeFeature,
  onDeleteFeature,
  onDeleteRule,
  onEditFeature,
  onEditRule,
  onReleaseFeature,
  requestStatusChange,
}) => (
  <PageContent>
    {!isEmpty(errors) && (
      <GenericError error={errors} />
    )}
    <FeatureDetailProfileCard
      requestStatusChange={requestStatusChange}
      feature={feature}
      organizationId={organizationId}
      onDeleteFeature={onDeleteFeature}
      onEditFeature={onEditFeature}
      onReleaseFeature={onReleaseFeature}
      isControlOrganization={isControlOrganization}
    />
    <AvailabilityRulesCard
      organizationId={organizationId}
      feature={feature}
      ruleFilters={ruleFilters}
      onAddRule={onAddRule}
      onChangeFeature={onChangeFeature}
      onDeleteRule={onDeleteRule}
      onEditRule={onEditRule}
    />

    <AddRuleWorksheet />
    <EditFeatureWorksheet />
    <EditRuleWorksheet />
    <FeatureDeleteConfirm featureName={feature.name} />
    <FeatureReleaseConfirm featureName={feature.name} />
    <RuleDeleteConfirm />
  </PageContent>
);

FeatureDetail.displayName = 'FeatureDetail';

FeatureDetail.defaultProps = {
  requestStatusChange: noop,
  errors: {},
  onAddRule: noop,
  onChangeFeature: noop,
  onDeleteFeature: noop,
  onDeleteRule: noop,
  onEditFeature: noop,
  onEditRule: noop,
  onReleaseFeature: noop,
  isControlOrganization: false,
  ruleFilters: [],
};

export default FeatureDetail;
