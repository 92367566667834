import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxFormWorksheet } from '@flowio/redux-form-overlay';
import { formValueSelector } from 'redux-form';
import get from 'lodash/get';

import PriceBookImport from '../components/PriceBookImport';
import FormName from '../constants/FormName';
import { importFile } from '../../organization/actions';
import getUserEmail from '../../console/selectors/get-user-email';
import { createToast } from '../../console/actions';

const mapStateToProps = (state) => ({
  file: get(formValueSelector(FormName.IMPORT_PRICE_BOOK_FORM)(state, 'file'), '[0]'),
  initialValues: {
    userEmail: getUserEmail(state),
  },
});

const readFile = (file) => new Promise((resolve) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => resolve(reader.result.split(',')[1]), false);
  reader.readAsDataURL(file);
});

const onSubmit = (values, dispatch) => {
  const payload = {
    emails: [values.userEmail],
  };

  const file = get(values, 'file[0]');
  payload.name = get(file, 'name');

  return readFile(file).then((contents) => {
    payload.contents = contents;
    if (get(file, 'name', '').startsWith('price_book_items_query')) {
      payload.importType = 'price_book_items_query';
    } else if (get(file, 'name', '').startsWith('price_book_items')) {
      payload.importType = 'price_book_items';
    }
    return dispatch(importFile(payload));
  });
};

const handleValidation = (values) => {
  const file = get(values, 'file[0]', '');
  const fileName = get(file, 'name');
  const fileSize = get(file, 'size');

  const errors = {};
  if (!file || !fileName) {
    errors.file = 'Please select a file to upload';
    return errors;
  }

  if (!fileName.endsWith('.csv')) {
    errors.file = 'Please make sure your file is a CSV';
    return errors;
  }

  if (!fileName.startsWith('price_book_items') && !fileName.startsWith('price_book_items_query')) {
    errors.file = 'Please make sure your file name starts with price_book_items or price_book_items_query';
    return errors;
  }

  if (fileName.startsWith('price_book_items') || fileName.startsWith('price_book_items_query')) {
    if (fileSize < 1) {
      errors.file = 'Please make sure you did not upload an empty file';
      return errors;
    }
  }

  return errors;
};

const handleSuccess = (action, dispatch) => {
  if (action.status === 201) {
    dispatch(createToast({
      content: 'Uploading prices CSV. You will receive an email when upload is complete.',
      intent: 'positive',
    }));
  } else {
    dispatch(createToast({
      content: 'Failed to upload price book items, please try again later.',
      intent: 'negative',
    }));
  }
};

const handleFailure = (errors, dispatch) => {
  const messages = [];

  Object.keys(errors).forEach((key) => messages.push(errors[key]));

  dispatch(createToast({
    content: messages.join(' '),
    intent: 'negative',
  }));
};

export default compose(
  connect(mapStateToProps),
  reduxFormWorksheet({
    enableReinitialize: true,
    form: FormName.IMPORT_PRICE_BOOK_FORM,
    onSubmitSuccess: handleSuccess,
    onSubmitFail: handleFailure,
    onSubmit,
    validate: handleValidation,
  }),
)(PriceBookImport);
