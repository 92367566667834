import React from 'react';
import BemHelper from '@flowio/bem-helper';
import PropTypes from 'prop-types';

const bem = new BemHelper('description');

const DescriptionDefinition = ({
  children,
  className,
  content,
}) => (
  <dd className={bem.element('definition', className)}>
    {children || content}
  </dd>
);

DescriptionDefinition.displayName = 'DescriptionDefinition';

DescriptionDefinition.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  content: PropTypes.string,
};

DescriptionDefinition.defaultProps = {
  children: undefined,
  className: '',
  content: '',
};

export default DescriptionDefinition;
