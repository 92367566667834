import React from 'react';
import capitalize from 'lodash/capitalize';
import Button from '../../MarketingButton';
import { MergedProps } from '../types';
import MarketingDialog from '../../MarketingDialog';
import { Auth, manualTitle, manualSubtitle } from '../constants';
import AuthModalBackdrop from './AuthModalBackdrop';

const AddAuthDialog: React.FC<MergedProps> = ({
  open,
  isManual,
  platformId = 'google',
  onCancelClick,
  organizationId,
  onConnectFBClick,
  isFBSdkInitialized,
}) => {
  const platform = isManual ? 'google' : platformId;
  const { modalBody, modalTitle } = Auth[platform];

  const doConnect = (): void => {
    if (platformId === 'facebook') {
      onConnectFBClick(organizationId);
    } else {
      window.location.assign(`/${organizationId}/auth/${platform}${isManual ? '?intent=manual' : ''}`);
    }
  };

  return open ? (
    <MarketingDialog
      isOpen={open}
      topContent={<AuthModalBackdrop isManual={isManual} platformId={platformId} />}
      title={isManual ? manualTitle(platform) : modalTitle}
      subtitle={isManual ? manualSubtitle(platform) : modalBody}
    >
      <Button onClick={onCancelClick} intent="neutral">Cancel</Button>
      <Button
        onClick={doConnect}
        disabled={platformId === 'facebook' && !isFBSdkInitialized}
        intent="primary"
      >
        {`Go To ${capitalize(platform)}`}
      </Button>
    </MarketingDialog>
  ) : null;
};

AddAuthDialog.displayName = 'AddAuthDialog';

export default AddAuthDialog;
