import { $HttpResponse, ExperiencesGetItemsByNumberResponse } from '@flowio/api-sdk';
import ActionTypes from '../constants/action-types';
import { RootActionTypes, ThunkResult, ThunkExtraArguments } from '../../../stores/types';
import { ApiError } from '../../../utilities/clients/api-error';
import { PropType } from '../../../types';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import toLegacyResponse from '../../../utilities/clients/to-legacy-response';

interface Options {
  country?: string;
  currency?: string;
  language?: string;
  experience?: string;
  ip?: string;
}

export function fetchExperienceItemByNumberRequest(): RootActionTypes {
  return {
    type: ActionTypes.FETCH_EXPERIENCE_ITEM_REQUEST,
  };
}

export function fetchExperienceItemByNumberSuccess(item: io.flow.v0.models.Item): RootActionTypes {
  return {
    type: ActionTypes.FETCH_EXPERIENCE_ITEM_SUCCESS,
    payload: item,
  };
}

export function fetchExperienceItemByNumberFailure(
  error?: io.flow.v0.models.GenericError,
): RootActionTypes {
  return {
    type: ActionTypes.FETCH_EXPERIENCE_ITEM_FAILURE,
    payload: error,
    error: true,
  };
}

type ResponseType = PropType<ExperiencesGetItemsByNumberResponse, 'body'>;

export default function fetchExperienceItemByNumber(
  organization: string,
  number: string,
  options: Partial<Options>,
): ThunkResult<Promise<LegacyResponse<ResponseType>>> {
  return function fetchExperienceItemByNumberEffects(
    dispatch,
    getState,
    extra: ThunkExtraArguments,
  ): Promise<LegacyResponse<ResponseType>> {
    dispatch(fetchExperienceItemByNumberRequest());

    return extra.api(getState()).experiences.getItemsByNumber({
      organization,
      number,
      ...options,
    }).then((response) => {
      if (response.ok) {
        dispatch(fetchExperienceItemByNumberSuccess(response.body));
        return toLegacyResponse(response);
      }

      // TODO conversion-revisit
      const error = new ApiError(
        response as $HttpResponse<io.flow.v0.models.GenericError>,
      );
      dispatch(fetchExperienceItemByNumberFailure(response.body));
      throw error;
    });
  };
}
