import assign from 'lodash/assign';
import isEmpty from 'lodash/isEmpty';
import { defaultHttpStatusMessages, FormattedErrorMessages } from './format-error-response-v2';
import { LegacyResponse } from './clients/types/server';

/**
 * An utility that maps a non 2XX response from our Node SDK into an object suitable for console.
 * It simply ensures that a `messages` property is available when one cannot be derived from the
 * response for display purposes.
 * @param {Object} response A response object from Node SDK
 * @param {Object} [options.messages] An object literal used to override default messages based on
 * status code.
 * @return {Object}
 */

interface FormatErrorMessageOptions {
  messages?: Record<number, string>;
}

const formatErrorResponse = (
  response: LegacyResponse,
  options: FormatErrorMessageOptions = {},
): LegacyResponse<FormattedErrorMessages> => {
  const { status, ok } = response;
  const httpStatusMessages: Record<number, string> = {
    ...defaultHttpStatusMessages,
    ...options.messages,
  };

  let messages: { message: string }[] = [];

  // Use message matching HTTP status code
  if (httpStatusMessages[status]) {
    messages = [{ message: httpStatusMessages[status] }];
  // Use response result from Node SDK. Flow API only provides messages for 422 responses and Node
  // SDK adds a message to 5XX responses, we will honor both.
  } else if (response.result) {
    if (response.result.messages) {
      messages = response.result.messages.map((message: string) => ({ message }));
    // This is the message format from Hapi server errors:
    // {
    //  error, // string
    //  message, // string
    //  statusCode, // number
    // }
    } else if (!isEmpty(response.result.message)) {
      messages = [{ message: response.result.message }];
    } else {
      messages = [{ message: response.result }];
    }
  }

  // An object that has the following shape:
  //  {
  //    status: String,
  //    result: {
  //      messages: [{
  //        message: String,
  //        code: String
  //      }],
  //      ...response.result
  //    }
  // }
  if (response.result) {
    return { status, ok, result: assign({}, response.result, { messages }) };
  }

  return { status, ok, result: { messages } };
};

export default formatErrorResponse;
