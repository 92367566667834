import { CheckCircle, PuffLoader } from '@flowio/react-icons';
import React from 'react';
import includes from 'lodash/includes';
import get from 'lodash/get';
import {
  Card, CardActions, CardHeader, CardContent, CardEmptyState, CardTitle,
} from '@flowio/react-card';
import { Box, BoxItem } from '@flowio/react-box';
import { Button } from '@flowio/react-button';

import ShopifySyncStatusGraph from '../../ShopifySyncStatusGraph';
import ReadyStates from '../../../../../utilities/redux/ReadyState';
import withNumberFormat from '../../../../../utilities/numbers/withNumberFormat';
import { MergedProps } from '../types';
import * as styles from './ShopifySyncStatus.styles';

class ShopifySyncStatus extends React.PureComponent<MergedProps> {
  static defaultProps = {
    syncStatus: undefined,
    hasData: false,
    readyState: ReadyStates.PENDING,
    error: undefined,
  };

  renderEmptyState(): React.ReactNode {
    const {
      hasData,
      error,
    } = this.props;
    if (error) {
      return <CardEmptyState content={`API returned an error: (${error.code})`} />;
    }
    if (!hasData) {
      return (
        <CardEmptyState>
          <CheckCircle className={styles.clearIcon} width={30} />
          All prices are up to date.
        </CardEmptyState>
      );
    }
    return undefined;
  }

  render(): React.ReactNode {
    const {
      readyState,
      hasData,
      syncStatus,
      onRefresh: handleRefresh,
    } = this.props;

    const isLoading = includes([ReadyStates.PENDING, ReadyStates.LOADING], readyState);

    const processingItems = get(syncStatus, 'buckets[0].count', 0);

    return (
      <Card>
        <CardHeader dividing>
          <Box justifyContent="between" alignItems="center">
            <BoxItem>
              <Box alignItems="center">
                <CardTitle content="Shopify Sync Status Queue" />
                {isLoading && <PuffLoader className={styles.loadingIcon} width={28} />}
              </Box>
            </BoxItem>
            <CardActions>
              <Button
                content="Refresh"
                onClick={handleRefresh}
              />
            </CardActions>
          </Box>
        </CardHeader>
        <CardContent>
          {this.renderEmptyState() || (
            <div>
              <Box alignItems="center" justifyContent="between" className={styles.overviewContainer}>
                <BoxItem className={styles.metricContainer}>
                  <Card>
                    <CardContent>
                      <div className={styles.processingItemsNumber}>
                        {withNumberFormat(processingItems)}
                      </div>
                      <div className={styles.processingDescription}>
                        Processing
                      </div>
                    </CardContent>
                  </Card>
                </BoxItem>
                {syncStatus && (
                  <>
                    <BoxItem className={styles.metricContainer}>
                      <Card>
                        <CardContent>
                          <div className={styles.unprocessedItemsNumber}>
                            {withNumberFormat(syncStatus.total - processingItems)}
                          </div>
                          <div className={styles.unprocessedDescription}>
                            Pending
                          </div>
                        </CardContent>
                      </Card>
                    </BoxItem>
                    <BoxItem className={styles.metricContainer}>
                      <Card>
                        <CardContent>
                          <div className={styles.totalItemsNumber}>
                            {withNumberFormat(syncStatus.total)}
                          </div>
                          <div className={styles.totalDescription}>
                            Total Items
                          </div>
                        </CardContent>
                      </Card>
                    </BoxItem>
                  </>
                )}
              </Box>
              <ShopifySyncStatusGraph syncStatus={syncStatus} hasData={hasData} />
            </div>
          )}
        </CardContent>
      </Card>
    );
  }
}

export default ShopifySyncStatus;
