import { css } from '@emotion/css';

export const productDetailsPreview = css`
  display: flex;
  flex-flow: row nowrap;

  & .button {
    width: 229px;
    margin-top: 0.75rem;
  }

  & .product-price {
    margin-top: 0.5rem;
  }
`;

export const media = css`
  flex: 0 0 320px;
  margin-right: 1rem;
`;

export const mediaImage = css`
  width: 100%;
`;
