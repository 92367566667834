/**
 * @fileoverview
 * A module that provides utilities for parsing search queries commonly used
 * when refining results from Flow API resources.
 */

import isString from 'lodash/isString';
import map from 'lodash/map';
import remove from 'lodash/remove';
import some from 'lodash/some';

// A regular expression for splitting a query into its components. A query may
// consist of a combination of operators (e.g. `category:jeans` where the value
// before and after the colon are known as the "modifier" and "property" of the
// operator, respectively) and words (e.g. `pants`) separated by whitespaces.
// The property of operator can be separated by whitespaces if enclosed in double quotes.
// const pattern = /([^\s:]+)(?::(".*?"|[^\s]*))?/g;
const pattern = /([^\s]+):(("[^"]*")|([^\s]+))|([^\s"]+)|("[^"]*")/gm;
export interface ParsedQueryStringEntity {
  value?: string;
  modifier?: string;
  property?: string;
}

const parseOperator = (value: string | ParsedQueryStringEntity): ParsedQueryStringEntity => {
  if (!isString(value)) return value;
  const [modifier, property] = value.split(':');
  if (property !== null && property !== undefined) return { value, modifier, property };
  return { value };
};

/**
 * Returns a structured representation of the query string specified.
 * @example
 * // returns `[{ value: "jeans" }]`
 * parseQuery("jeans");
 * // returns `[{ value: "brand:diesel", modifier: "brand", property: "diesel" }]`
 * parseQuery("brand:diesel")
 */
export const parseQuery = (queryString = ''): ParsedQueryStringEntity[] => map(queryString.match(pattern), parseOperator);

/**
 * Given a parsed URI component returns its string representation.
 * @example
 * // returns "jeans"
 * formatQuery([{ value: "jeans" }])
 * // returns "brand:diesel"
 * formatQuery([{ value: "brand:diesel", modifier: "brand", property: "diesel" }])
 */
export const formatQuery = (queryObjects: ParsedQueryStringEntity[]): string => map(queryObjects, 'value').join(' ');

/**
 * Indicates whether the given query has a matching operator.
 */
export const hasOperator = (
  query: string,
  operator: string | ParsedQueryStringEntity,
): boolean => some(parseQuery(query), parseOperator(operator));

/**
 * Appends an operator to a query string if it doesn't already exists.
 */
export const addOperator = (query: string, operator: string | ParsedQueryStringEntity): string => {
  if (hasOperator(query, operator)) return query;
  const parsedQuery = parseQuery(query);
  parsedQuery.push(parseOperator(operator));
  return formatQuery(parsedQuery);
};

/**
 * Removes matching operator from specified query string.
 */
export const removeOperator = (
  query: string,
  operator: string | ParsedQueryStringEntity,
): string => {
  const parsedQuery = parseQuery(query);
  remove(parsedQuery, parseOperator(operator));
  return formatQuery(parsedQuery);
};

export const swapOperator = (
  query: string,
  operatorToRemove: string | ParsedQueryStringEntity,
  operatorToAdd: string | ParsedQueryStringEntity,
): string => addOperator(
  removeOperator(query, operatorToRemove), operatorToAdd,
);
