import {
  createStore, applyMiddleware, Store,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { browserHistory } from 'react-router';
import reduxThunk from 'redux-thunk';
import type { PartialDeep } from 'type-fest';
import { createAsyncMiddleware } from '@flowio/redux-async-middleware';
import { enableBatching } from 'redux-batched-actions';
import { createRollbarMiddleware } from '@flowio/redux-rollbar-middleware';
import reduxAsyncAction from '../middlewares/redux-async-action';
import rootReducer from './root-reducer';
import { ConsoleActionTypes } from '../modules/console/constants';
import reduxRollbarMiddlewareExtend from '../utilities/redux/reduxRollbarMiddlewareExtend';
import createThunkExtraArguments from './create-thunk-extra-arguments';
import { getInstance as getRollbarInstance } from '../utilities/rollbar';
import { ThunkDispatcher, RootState, RootActionTypes } from './types';

export default function configureStore(
  initialState?: PartialDeep<RootState>,
): Store<RootState, RootActionTypes> {
  const isBrowser = process.browser;

  const middlewares = [
    reduxThunk.withExtraArgument(createThunkExtraArguments()),
    reduxAsyncAction({
      failureType: ConsoleActionTypes.PUSH_ERROR,
      // When API response is 401 (Unauthorized) reset application state and redirect user to
      // login page to refresh access token.
      onBeforeFailure: ({ status }: { status: number; dispatch: ThunkDispatcher }) => {
        if (status !== 401) return true;
        browserHistory.push('/');
        return false;
      },
    }),
    createAsyncMiddleware(),
  ];

  // TODO: Redux Logger provides the ability to assign a custom logger. We may be able to assign it
  // the logger instance created from `debug` package. Also, we may want to turn off colors on the
  // server for better readability.
  if (isBrowser) {
    middlewares.push(
      createRollbarMiddleware({
        rollbar: getRollbarInstance(),
        extendOptionsFn: reduxRollbarMiddlewareExtend,
      }),
    );
  }

  const store = createStore<RootState, RootActionTypes, unknown, unknown>(
    enableBatching(rootReducer),
    initialState as RootState,
    composeWithDevTools(applyMiddleware(...middlewares)),
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./root-reducer', () => {
      // eslint-disable-next-line global-require
      store.replaceReducer(require('./root-reducer').default);
    });
  }

  return store;
}
