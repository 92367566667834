import { ExperiencesPutPriceAndBooksByExperienceKeyResponse } from '@flowio/api-sdk';
import ActionTypes from '../constants/action-types';
import {
  ThunkExtraArguments,
  RootState,
  ThunkDispatcher,
  ThunkResult,
} from '../../../stores/types';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import toLegacyResponse from '../../../utilities/clients/to-legacy-response';
import { PropType } from '../../../types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';
import { createToast } from '../../console/actions';

type ResponseType = PropType<ExperiencesPutPriceAndBooksByExperienceKeyResponse, 'body'>;

interface UpdateExperiencePriceBookParams {
  organizationId: string;
  experienceKey: string;
  priceBookKey: string;
}

export default function updateExperiencePriceBook({
  organizationId,
  experienceKey,
  priceBookKey,
}: UpdateExperiencePriceBookParams): ThunkResult<Promise<LegacyResponse<ResponseType>>> {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<LegacyResponse<ResponseType>> => {
    dispatch({
      type: ActionTypes.UPDATE_PRICE_BOOK_REQUEST,
    });

    const form: io.flow.v0.models.ExperiencePriceBookMappingPutForm = {
      price_books: [{
        price_book_key: priceBookKey,
      }],
    };

    return extra.api(getState()).experiences.putPriceAndBooksByExperienceKey({
      organization: organizationId,
      experience_key: experienceKey,
      body: form,
    }).then((response) => {
      if (response.ok) {
        dispatch({
          type: ActionTypes.UPDATE_PRICE_BOOK_SUCCESS,
          payload: response.body,
        });
        dispatch(createToast({
          content: 'Price book successfully updated',
          intent: 'positive',
          icon: 'CheckCircle',
        }));
      } else {
        dispatch({
          type: ActionTypes.UPDATE_PRICE_BOOK_FAILURE,
          payload: formatErrorResponseV2(response),
        });
        dispatch(createToast({
          content: 'Error while updating price book',
          intent: 'negative',
          icon: 'ErrorOutline',
        }));
      }
      return toLegacyResponse<ResponseType>(response);
    });
  };
}
