import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import mapValues from 'lodash/mapValues';
import moment from 'moment';
import prettyLabel from '../../../../../utilities/strings/pretty-label';
import { createScheduledExport } from '../../../actions';
import ScheduledExportsForm from '../components/scheduled-export-form';
import { getSelectedExport } from '../../../selectors';
import { RootState, ThunkDispatcher } from '../../../../../stores/types';
import {
  StateProps,
  OwnProps,
  MergedProps,
  ExportsFormValues,
} from '../types';
import { FormattedErrorMessages } from '../../../../../utilities/format-error-response-v2';
import { LegacyResponse } from '../../../../../utilities/clients/types/server';

const submit = (
  values: ExportsFormValues,
  dispatch: ThunkDispatcher,
): Promise<
LegacyResponse<io.flow.v0.models.ScheduledExport | FormattedErrorMessages>
> => dispatch(createScheduledExport(values));

function mapDropdownObject(
  selectedExport: io.flow.v0.models.ScheduledExport,
): { [P in keyof io.flow.v0.models.ScheduledExport]: object | string } {
  return mapValues<io.flow.v0.models.ScheduledExport, object | string>(
    selectedExport,
    (val: unknown, key: string) => {
      switch (key) {
        case 'hour':
        case 'minute':
          return {
            value: val as number,
            key: val as string,
          };
        case 'timezone':
          return {
            label: moment().tz(val as string).zoneAbbr(),
            value: val as string,
          };
        case 'types':
          return {
            content: prettyLabel((val as io.flow.v0.models.ScheduledExport['types'])[0].discriminator),
            value: (val as io.flow.v0.models.ScheduledExport['types'])[0].discriminator,
          };
        default:
          return val as string;
      }
    },
  );
}

const selector = formValueSelector('scheduledExportsForm');

export default compose<React.FC<OwnProps>>(
  connect<StateProps, {}, OwnProps, RootState>(
    (state: RootState) => {
      const types = selector(state, 'types');
      const initialFormValues = mapDropdownObject(getSelectedExport(state));
      return {
        types,
        initialValues: initialFormValues,
      };
    },
  ),
  reduxForm<ExportsFormValues, MergedProps>({
    form: 'scheduledExportsForm',
    enableReinitialize: true,
    onSubmit: submit, // submit function must be passed to onSubmit
  }),
)(ScheduledExportsForm);
