const ExperimentStatusKey: Record<string, io.flow.internal.v0.enums.Status> = {
  DRAFT: 'draft',
  LIVE: 'live',
  SCHEDULED: 'scheduled',
  ENDED: 'ended',
  ARCHIVED: 'archived',
};

const DefaultStatusFilter = [
  ExperimentStatusKey.DRAFT,
  ExperimentStatusKey.LIVE,
  ExperimentStatusKey.SCHEDULED,
  ExperimentStatusKey.ENDED,
];

const ExperimentStatusSortValue: Record<io.flow.internal.v0.enums.Status, number> = {
  draft: 1,
  live: 2,
  scheduled: 3,
  ended: 4,
  archived: 5,
};

const ExperimentStatusTransitionLabel: Record<io.flow.internal.v0.enums.Status, string> = {
  live: 'Launch',
  ended: 'End',
  archived: 'Archive',
  scheduled: 'Schedule',
  draft: 'Edit',
};

export {
  ExperimentStatusKey,
  ExperimentStatusSortValue,
  DefaultStatusFilter,
  ExperimentStatusTransitionLabel,
};
