import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  showDeleteFeedConfirmationDialog,
  goToAddFeedsPage,
  showDownloadFeedsDialog,
} from '../../../actions';
import {
  getCountries, getCurrencies, getLanguages,
} from '../../../../reference/selectors';
import {
  getMarketingId,
  getGoogleFeedsLoading,
  getGoogleLocalizedFeeds,
  getGoogleLocalizedFeedsIds,
} from '../../../selectors';
import GoogleLocalizedFeeds from '..';
import { RootState, RootActionTypes, ThunkDispatcher } from '../../../../../stores/types';
import {
  DeleteFeedConfirmationDialogProps,
  MarketingActions,
} from '../../../types';
import { StateProps, OwnProps } from '../types';

const handleDelete = (
  feed: DeleteFeedConfirmationDialogProps,
): MarketingActions => showDeleteFeedConfirmationDialog(feed);

const handleDownloadFeedsButtonClick = (
  feedIds: string[],
): MarketingActions => showDownloadFeedsDialog(feedIds);

const handleCreateNewFeed = (
  organizationId: string,
) => (dispatch: ThunkDispatcher, getState: () => RootState) => {
  const marketingId = getMarketingId(getState());
  return dispatch(goToAddFeedsPage({
    organizationId,
    accountId: marketingId,
    platformId: 'google',
  }));
};

const mapStateToProps = () => createStructuredSelector<RootState, {}, StateProps>({ // eslint-disable-line
  feeds: getGoogleLocalizedFeeds,
  feedsIds: getGoogleLocalizedFeedsIds,
  loading: getGoogleFeedsLoading,
  countries: getCountries,
  currencies: getCurrencies,
  languages: getLanguages,
});

interface DispatchProps {
  onDelete: (feed: DeleteFeedConfirmationDialogProps) => void;
  onDownloadFeedsButtonClick: (feedIds: string[]) => void;
  onCreateNewFeed: (
    organization: string,
    accountId?: string,
  ) => void;
}

const mapDispatchToProps = (
  dispatch: Dispatch<RootActionTypes>,
): DispatchProps => bindActionCreators({
  onDelete: handleDelete,
  onDownloadFeedsButtonClick: handleDownloadFeedsButtonClick,
  onCreateNewFeed: handleCreateNewFeed,
}, dispatch);

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(GoogleLocalizedFeeds);
