import { api } from '../../utilities/clients';
import * as ActionTypes from './constants';

export function getClearTextKey(id) {
  return {
    types: [ActionTypes.FETCH_CLEAR_TEXT_KEY_SUBMIT, ActionTypes.FETCH_CLEAR_TEXT_KEY_SUCCESS],
    callAPI(state) {
      return api.tokens(state).getCleartextById(id, {});
    },
    payload: { id },
  };
}

export function deleteApiKey(id) {
  return {
    types: [ActionTypes.DELETE_API_KEY_SUBMIT, ActionTypes.DELETE_API_KEY_SUCCESS],
    callAPI(state) {
      return api.tokens(state).deleteById(id, {});
    },
    payload: { id },
  };
}
