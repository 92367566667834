import { Form, Field, InjectedFormProps } from 'redux-form';
import { Banner, BannerText } from '@flowio/react-banner';
import { Box, BoxItem } from '@flowio/react-box';
import { FormGroup } from '@flowio/react-form-group';
import React from 'react';
import find from 'lodash/find';
import get from 'lodash/get';
import memoize from 'lodash/memoize';
import property from 'lodash/property';
import { MeasurementSystem } from '@flowio/api-constants';
import { SelectOption } from '@flowio/react-select';
import GenericError from '../../../../../components/GenericError';

import presence from '../../../../../utilities/validators/presence';
import { ComponentProps } from '../types';
import { ExperienceFormValues, MeasurementSystemOption } from '../../../types';
import * as styles from './ExperienceForm.styles';
import { TextField, SelectField } from '../../../../../components/ReduxFormFieldAdapters';

const EU_UK_MESSAGE = 'Starting from January 1, 2021, the United Kingdom is no longer a part of the European Union.';

/**
 * Returns whether the specified region consists of multiple countries.
 * @param {Region}
 */
const isMultiCountryRegion = memoize(
  (region: io.flow.v0.models.Region | undefined):
  boolean => (region || false) && region.countries.length > 1,
);

const searchFilter = (
  options: SelectOption,
  inputValue: string,
) => {
  const content = get(options, 'content').toLowerCase();
  const searchText = inputValue.toLowerCase();
  const searchRegex = new RegExp(`.*${searchText}.*`, 'gm');
  return !!searchRegex.exec(content);
};

/* parsers */

const handleCountryParse = property('iso_3166_3');
const handleCurrencyParse = property('iso_4217_3');
const handleLanguageParse = property('iso_639_2');
const handleMeasurementSystemParse = property('value');
const handleRegionIdParse = property('id');

/* validators */

const handleCountryValidation = [
  presence({ allowEmpty: false, message: 'Country is required' }),
];

const handleCurrencyValidation = [
  presence({ allowEmpty: false, message: 'Currency is required' }),
];

const handleNameValidation = [
  presence({ allowEmpty: false, message: 'An experience name is required' }),
];

const handleRegionIdValidation = [
  presence({ allowEmpty: false, message: 'Region is required' }),
];

const ExperienceForm: React.FC<
ComponentProps & InjectedFormProps<ExperienceFormValues, ComponentProps>
> = ({
  countries,
  currencies,
  error,
  handleSubmit,
  languages,
  measurementSystems,
  regions,
  selectedRegionId,
}) => {
  const regionMessages = [];
  const handleMeasurementSystemFormat = (
    value: MeasurementSystem,
  ): MeasurementSystemOption | undefined => measurementSystems && measurementSystems.find(
    (measurementSystem) => measurementSystem.value === value,
  );

  const handleLanguageFormat = (
    value: string,
  ): io.flow.v0.models.Language | undefined => find(languages, { iso_639_2: value });

  const handleCurrencyFormat = (
    value: string,
  ): io.flow.v0.models.Currency | undefined => find(currencies, { iso_4217_3: value });

  const handleCountryFormat = (
    value: string,
  ): io.flow.v0.models.Country | undefined => find(countries, { iso_3166_3: value });

  const findRegionById = (
    value?: string,
  ): io.flow.v0.models.Region | undefined => find(regions, { id: value });

  const handleRegionIdFormat = (
    value: string,
  ): io.flow.v0.models.Region | undefined => findRegionById(value);

  const selectedRegion = findRegionById(selectedRegionId);

  if (isMultiCountryRegion(selectedRegion)) {
    regionMessages.push('This region contains multiple countries.');
  }

  if (selectedRegionId === 'europeanunion' || selectedRegionId === 'gbr') {
    regionMessages.push(EU_UK_MESSAGE);
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Field component="input" name="organizationId" type="hidden" />
      <Field component="input" name="key" type="hidden" />
      <Field component="input" name="subcatalogId" type="hidden" />
      {!isMultiCountryRegion(selectedRegion) && (
        <Field component="input" name="country" type="hidden" />
      )}
      <Box direction="column" spacing="extraLoose">
        {error && (
          <BoxItem>
            <GenericError error={error} />
          </BoxItem>
        )}
        <BoxItem>
          <FormGroup
            inline
            labelFor="regionId"
            labelCols={3}
            labelText="Region"
          >
            <Field
              css={styles.fieldColumn}
              fluid
              filter={searchFilter}
              minimumCharacters={2}
              component={SelectField}
              format={handleRegionIdFormat}
              name="regionId"
              parse={handleRegionIdParse}
              autoComplete="off"
              inputProps={{ 'data-automationid': 'experience-region-input' }}
              labelKey="name"
              menuProps={{ 'data-automationid': 'experience-region-menu', style: { maxHeight: 280, overflowY: 'auto' } }}
              options={regions}
              scrolling
              searchable
              selection
              valueKey="id"
              validate={handleRegionIdValidation}
            />
          </FormGroup>
        </BoxItem>
        {regionMessages.length > 0 && (
          <BoxItem>
            <Box>
              <BoxItem css={styles.spacingBox} />
              <BoxItem css={styles.alertBox}>
                <Banner>
                  <BannerText>
                    <ul>
                      {regionMessages.map((message) => (
                        <li>{message}</li>
                      ))}
                    </ul>
                  </BannerText>
                </Banner>
              </BoxItem>
            </Box>
          </BoxItem>
        )}
        <BoxItem>
          <FormGroup
            labelCols={3}
            labelFor="name"
            inline
            labelText="Experience Name"
          >
            <Field
              css={styles.nameFieldColumn}
              fluid
              component={TextField}
              name="name"
              autoComplete="off"
              inputProps={{ 'data-automationid': 'experience-name-input' }}
              maxLength="64"
              validate={handleNameValidation}
            />
          </FormGroup>
        </BoxItem>
        {isMultiCountryRegion(selectedRegion) && (
          <BoxItem>
            <FormGroup
              labelCols={3}
              labelFor="country"
              inline
              labelText="Default Country"
            >
              <Field
                css={styles.fieldColumn}
                fluid
                filter={searchFilter}
                minimumCharacters={2}
                component={SelectField}
                format={handleCountryFormat}
                name="country"
                parse={handleCountryParse}
                autoComplete="off"
                inputProps={{ 'data-automationid': 'experience-country-input' }}
                labelKey="name"
                menuProps={{ 'data-automationid': 'experience-country-menu' }}
                options={countries}
                scrolling
                searchable
                selection
                valueKey="iso_3166_3"
                validate={handleCountryValidation}
              />
            </FormGroup>
          </BoxItem>
        )}
        <BoxItem>
          <FormGroup
            labelCols={3}
            labelFor="currency"
            inline
            labelText={isMultiCountryRegion(selectedRegion) ? 'Default Currency' : 'Currency'}
          >
            <Field
              css={styles.fieldColumn}
              fluid
              component={SelectField}
              format={handleCurrencyFormat}
              name="currency"
              parse={handleCurrencyParse}
              autoComplete="off"
              inputProps={{ 'data-automationid': 'experience-currency-input' }}
              labelKey="name"
              menuProps={{ 'data-automationid': 'experience-currency-menu', style: { maxHeight: 280, overflowY: 'auto' } }}
              options={currencies}
              scrolling
              searchable
              selection
              valueKey="iso_4217_3"
              validate={handleCurrencyValidation}
            />
          </FormGroup>
        </BoxItem>
        <BoxItem>
          <FormGroup
            labelCols={3}
            labelFor="language"
            inline
            labelText="Language"
          >
            <Field
              css={styles.fieldColumn}
              fluid
              component={SelectField}
              format={handleLanguageFormat}
              name="language"
              parse={handleLanguageParse}
              autoComplete="off"
              inputProps={{ 'data-automationid': 'experience-language-input' }}
              labelKey="name"
              menuProps={{ 'data-automationid': 'experience-language-menu', style: { maxHeight: 280, overflowY: 'auto' } }}
              options={languages}
              scrolling
              searchable
              selection
              valueKey="iso_639_2"
            />
          </FormGroup>
        </BoxItem>
        <BoxItem>
          <FormGroup
            labelCols={3}
            labelFor="measurementSystem"
            inline
            labelText="Measurements"
          >
            <Field
              css={styles.fieldColumn}
              fluid
              component={SelectField}
              format={handleMeasurementSystemFormat}
              name="measurementSystem"
              parse={handleMeasurementSystemParse}
              autoComplete="off"
              inputProps={{ 'data-automationid': 'experience-measurement-system-input' }}
              menuProps={{ 'data-automationid': 'experience-measurement-system-menu' }}
              options={measurementSystems}
              scrolling
              searchable
              selection
            />
          </FormGroup>
        </BoxItem>
      </Box>
    </Form>
  );
};

ExperienceForm.displayName = 'ExperienceForm';

ExperienceForm.defaultProps = {
  error: undefined,
  measurementSystems: [
    { content: 'Imperial', value: MeasurementSystem.IMPERIAL },
    { content: 'Metric', value: MeasurementSystem.METRIC },
  ],
  selectedRegionId: undefined,
};

export default ExperienceForm;
