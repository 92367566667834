import ActionTypes from './constants/action-types';
import { ApiError } from '../../utilities/clients/api-error';
import { FormattedErrorMessages, MessageObject } from '../../utilities/format-error-response-v2';
import { WithPagingAndSortingResult } from '../../utilities/types';

export type SortOrder = 'ascending' | 'descending' | 'none';

export type SortedColumn = 'target' | 'value';

export interface CurrencyDropdownOption {
  label: string;
  value: string;
}

export interface CurrencyError {
  status: number;
  messages: Array<{
    code: string;
    message: string;
  }>
}

export interface CurrencyRateStateProps {
  baseCurrency: string;
  baseCurrencies: io.flow.v0.models.Currency[];
  isFirstPage: boolean;
  isLastPage: boolean;
  rates?: io.flow.v0.models.Rate[];
  sortedColumn?: SortedColumn;
  sortOrder?: SortOrder;
}

export interface CurrencyRateDispatchProps {
  onRequestBaseCurrencyChange: (baseCurrency: string) => void;
  onRequestNextPage: () => void;
  onRequestPreviousPage: () => void;
  onRequestSort: (value: SortedColumn, direction: SortOrder) => void;
}

export type CurrencyRateProps = CurrencyRateStateProps & CurrencyRateDispatchProps;

export interface CurrencyRatesTableProps {
  onRequestSort: (...args: any) => void;
  rates?: io.flow.v0.models.Rate[];
  sortedColumn?: SortedColumn;
  sortOrder?: SortOrder;
}

export interface CurrencyRateState {
  errors?: MessageObject[];
  results: io.flow.v0.models.Rate[];
  isFirstPage: boolean;
  isLastPage: boolean;
}

export interface CurrencyState {
  rates: CurrencyRateState;
}

export interface GraphQLErrorLocation {
  line: number;
  column: number;
}

export interface GraphQLErrorResult {
  errors: {
    message: string;
    extensions: {
      code: string;
    };
    locations: GraphQLErrorLocation[];
  }[];
}
export interface GraphQLError {
  status: number;
  ok: boolean;
  result: GraphQLErrorResult;
}

export interface Message {
  message: string;
}

export interface Error {
  messages: Message[];
}

export interface ResultsObject {
  [key: string]: number;
}

export interface EventType {
  onBlur: (...args: any) => any;
  onChange: (...args: any) => any;
}

export interface DataSource {
  key: string;
  suggestion: any;
  text: string;
  value: string;
  type: DataSourceType;
}

export type DataSourceType = 'items' | 'modifiers' | 'operators' | 'queries';

export interface Range {
  from: string;
  to: string;
}

export enum ReadyStates {
  PENDING = 'pending',
  LOADING = 'loading',
  FULFILLED = 'fulfilled',
  REJECTED = 'rejected',
}

export interface ShopifySyncStatusState {
  data: io.flow.v0.models.ShopifySyncStatus;
  readyState: ReadyStates;
  error: ApiError;
}
export interface UtilityState {
  errors: Partial<{
    order_estimate: Partial<ApiError>;
  }>;
  centers: io.flow.v0.models.Center[];
  orderSummary: Partial<{
    settings?: Partial<OrderSummarySettings>;
    orderEstimate?: Partial<io.flow.v0.models.Order>;
  }>;
  shopifySyncStatus: Partial<ShopifySyncStatusState>;
  rates: {
    errors?: MessageObject[];
    results: io.flow.v0.models.Rate[];
    isFirstPage: boolean;
    isLastPage: boolean;
  }
}

export interface OrderSummarySettings {
  experience: io.flow.v0.models.Experience;
  items: io.flow.v0.models.LocalizedLineItem[];
  center: io.flow.v0.models.Center;
  selections: {
    [key in string]: string;
  };
}
/*
########################################
############### ACTIONS ################
########################################
*/

export interface CurrencyErrorPayload {
  payload: FormattedErrorMessages;
  error: boolean;
}

export interface ErrorPayload {
  payload: ApiError;
  error: boolean;
}

export interface GetCurrencyRatesRequest {
  type: ActionTypes.GET_CURRENCY_RATES_REQUEST;
}

export interface GetCurrencyRatesSuccess {
  type: ActionTypes.GET_CURRENCY_RATES_SUCCESS;
  payload: WithPagingAndSortingResult<io.flow.v0.models.Rate[]>;
}

export interface GetCurrencyRatesFailure extends CurrencyErrorPayload {
  type: ActionTypes.GET_CURRENCY_RATES_FAILURE;
}

export interface ClearOrderEstimateAction {
  type: ActionTypes.CLEAR_ORDER_ESTIMATE;
}

export interface FetchCentersRequestAction {
  type: ActionTypes.GET_CENTERS_REQUEST;
}

export interface FetchCentersSuccessAction {
  type: ActionTypes.GET_CENTERS_SUCCESS;
  payload: io.flow.v0.models.Center[];
}

export interface FetchCentersFailureAction extends ErrorPayload {
  type: ActionTypes.GET_CENTERS_FAILURE;
}

export interface FetchShopifySyncStatusRequestAction {
  type: ActionTypes.FETCH_SHOPIFY_SYNC_STATUS_REQUEST;
}
export interface FetchShopifySyncStatusSuccessAction {
  type: ActionTypes.FETCH_SHOPIFY_SYNC_STATUS_SUCCESS;
  meta: {
    organizationId: string;
  };
  payload: io.flow.v0.models.ShopifySyncStatus;
}
export interface FetchShopifySyncStatusFailureAction extends ErrorPayload {
  type: ActionTypes.FETCH_SHOPIFY_SYNC_STATUS_FAILURE;
}

export interface RemoveOrderSummaryItemAction {
  type: ActionTypes.REMOVE_ORDER_SUMMARY_ITEM;
  payload: string;
}

export interface SetOrderSummarySettingsAction {
  type: ActionTypes.SET_ORDER_SUMMARY_SETTINGS;
  payload: OrderSummarySettings;
}

export interface UpdateItemQuantityAction {
  type: ActionTypes.UPDATE_ITEM_QUANTITY;
  payload: {
    item: io.flow.v0.models.LocalizedLineItem;
  };
}

export interface UpsertOrderEstimateRequestAction {
  type: ActionTypes.UPSERT_ORDER_ESTIMATE_REQUEST;
}
export interface UpsertOrderEstimateSuccessAction {
  type: ActionTypes.UPSERT_ORDER_ESTIMATE_SUCCESS;
  payload: io.flow.v0.models.Order;
}
export interface UpsertOrderEstimateFailureAction extends ErrorPayload {
  type: ActionTypes.UPSERT_ORDER_ESTIMATE_FAILURE;
}

export type UtilityActions =
  ClearOrderEstimateAction |
  FetchCentersRequestAction |
  FetchCentersSuccessAction |
  FetchCentersFailureAction |
  FetchShopifySyncStatusRequestAction |
  FetchShopifySyncStatusSuccessAction |
  FetchShopifySyncStatusFailureAction |
  RemoveOrderSummaryItemAction |
  SetOrderSummarySettingsAction |
  UpdateItemQuantityAction |
  UpsertOrderEstimateRequestAction |
  UpsertOrderEstimateSuccessAction |
  UpsertOrderEstimateFailureAction |
  GetCurrencyRatesRequest |
  GetCurrencyRatesSuccess |
  GetCurrencyRatesFailure;
