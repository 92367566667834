import React from 'react';
import {
  Field,
  submit,
} from 'redux-form';

import FormName from '../../constants/FormName';
import MultiCheckDropdown from '../MultiCheckDropdown';
import { FeatureStatusFilterProps } from '../../types/components';

const statusFilters = [
  {
    label: 'Draft',
    value: 'draft',
  }, {
    label: 'Active',
    value: 'active',
  }, {
    label: 'Archived',
    value: 'archived',
  },
];

const ReduxFormMultiCheckDropdown: React.FC<FeatureStatusFilterProps> = ({ input, meta }) => (
  <div>
    <MultiCheckDropdown
      options={statusFilters}
      value={input.value}
      onChange={(selected: string) => input.onChange(selected)}
      onClose={() => {
        meta.dispatch(submit(FormName.FEATURE_LIST_FILTER_FORM));
      }}
    />
  </div>
);

const FeatureStatusFilter = () => (
  <Field name="featureStatusFilter" component={ReduxFormMultiCheckDropdown} />
);

export default FeatureStatusFilter;
