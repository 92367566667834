import ActionTypes from '../constants/action-types';
import { RootActionTypes } from '../../../stores/types';
import { OrderState } from '../types';

const initialState: OrderState = {
  results: [],
  isFirstPage: true,
  isLastPage: false,
};

export default function orderReducer(state = initialState, action: RootActionTypes): OrderState {
  switch (action.type) {
    case ActionTypes.FETCH_ORDERS_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
