/**
 * Session query supported when:
 *   - experiment already has a query defined
 *   - creating a global feature experiment
 */
export default function (
  organizationId: string,
  discriminator: string,
  sessionQuery: string | undefined,
) {
  return sessionQuery || (organizationId === 'flow' && discriminator === 'feature');
}
