import update from 'immutability-helper';

import { ActionTypes } from '../constants';
import { RootActionTypes } from '../../../stores/types';
import { CenterQueryState, ReadyStates } from '../types/state';

const initialState: CenterQueryState = {
  status: ReadyStates.PENDING,
  result: [],
};

export default function (state = initialState, action: RootActionTypes) {
  switch (action.type) {
    case ActionTypes.QUERY_CENTERS_CANCELED:
      return update(state, {
        status: {
          $set: ReadyStates.PENDING,
        },
      });
    case ActionTypes.QUERY_CENTERS_REQUEST:
      return update(state, {
        status: {
          $set: ReadyStates.LOADING,
        },
      });
    case ActionTypes.QUERY_CENTERS_SUCCESS:
      return update(state, {
        status: {
          $set: ReadyStates.FULFILLED,
        },
        result: {
          $set: action.payload,
        },
      });
    case ActionTypes.QUERY_CENTERS_FAILURE:
      return update(state, {
        status: {
          $set: ReadyStates.REJECTED,
        },
        result: {
          $set: [],
        },
      });
    default:
      return state;
  }
}
