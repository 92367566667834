import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Tooltip } from '@flowio/react-tooltip';
import isUndefined from 'lodash/isUndefined';
import React from 'react';
import { Box } from '@flowio/react-box';
import { Button } from '@flowio/react-button';
import { InfoCircleOutline } from '@flowio/react-icons';
import {
  Table, TableBody, TableCell, TableHead, TableHeadColumn, TableRow,
} from '@flowio/react-table';
import colors from '../../../../../theme/tokens/colors';
import metricSummaryValues from '../../../constants/metric-values';
import { isExperienceVariant, isFeatureVariant } from '../utilities/checkVariant';
import UpliftBadge from './uplift-badge';
import VariantBadge from './variant-badge';
import WinnerBadge from './winner-badge';

const StyledTableCell = styled(TableCell)<{ isPositive: boolean, isBold?: boolean }>`
  background: ${(props) => (props.isPositive ? colors.green[100] : 'none')};
  font-weight: ${(props) => (props.isBold ? '700' : '400')};
  color: ${(props) => (props.isBold ? colors.charcoal : 'inherit')};
`;
const StyledBox = styled(Box)`
  margin-top: 5px;
`;

const infoCircleStyle = css`
  fill: ${colors.steel};
  margin-left: 5px;
  vertical-align: middle;
  margin-bottom: 3px;
`;

const baselineBtnStyles = css`
  padding: 10px;
  margin-right: 5px;
  pointer-events: none;
`;

const BoxProperty = css`
  span {
    margin-right: 5px;
  }
`;

const tableBGStyles = (isWinningVariant: boolean) => css`
  background: ${isWinningVariant ? colors.green[100] : 'none'}
`;

type UpliftKeys = keyof Pick<io.flow.internal.v0.models.ExperimentResults, 'revenue_generated_uplift'
| 'conversion_rate_uplift' | 'average_order_value_uplift' | 'total_order_count'>;

type SummaryValueKeys = keyof (
  io.flow.internal.v0.models.ExperimentResults & { conversion_range: string }
);
interface Props {
  variants: io.flow.internal.v0.unions.Variant[];
  winningVariant?: io.flow.internal.v0.unions.Variant;
}

const ExperimentMetricsSummary: React.FC<Props> = ({
  variants,
  winningVariant,
}) => {
  const isWinningVariant = (
    variant: io.flow.internal.v0.unions.Variant,
  ) => variant === winningVariant;

  const getVariantName = (variant: io.flow.internal.v0.unions.Variant) => {
    if (variant) {
      if (isFeatureVariant(variant)) {
        return variant.value.name;
      } if (isExperienceVariant(variant)) {
        return variant.experience.name;
      }
    }
    return undefined;
  };

  const getConversionRange = (variant: io.flow.internal.v0.unions.Variant) => {
    if (!variant.experiment_results?.lower_bound && !variant.experiment_results?.upper_bound) {
      return '-';
    } return (
      <span data-automation-id="conversion-range">
        {variant.experiment_results?.lower_bound.toFixed(2)}
        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
        % - {variant.experiment_results?.upper_bound.toFixed(2)}%
      </span>
    );
  };

  const renderUpliftValue = (v: io.flow.internal.v0.unions.Variant,
    key: keyof io.flow.internal.v0.models.ExperimentResults,
    upliftKey: UpliftKeys) => {
    // if values to compare are available
    if (v.experiment_results) {
      const currentValue = v.experiment_results[key];
      const upliftValue = v.experiment_results[upliftKey];
      const noUpliftChange = currentValue !== upliftValue;

      if (!isUndefined(currentValue)) {
        if (key === 'conversion_rate') {
          return (
            <StyledBox css={BoxProperty} alignItems="center">
              <span id={`${key}_value`}>
                {currentValue}
                %
              </span>
              {noUpliftChange && upliftValue && (
              <UpliftBadge value={upliftValue} />)}
            </StyledBox>
          );
        }
        return (
          <Box css={BoxProperty} alignItems="center">
            <span id={`${key}_value`}>{currentValue.toLocaleString('en', { style: 'currency', currency: v?.experiment_results?.currency })}</span>
            {noUpliftChange && upliftValue && (
            <UpliftBadge value={upliftValue} />)}
          </Box>
        );
      }
    }
    return '-';
  };

  const renderCell = (variant: io.flow.internal.v0.unions.Variant, key: SummaryValueKeys) => {
    if (key === 'conversion_rate' || key === 'average_order_value') {
      return renderUpliftValue(variant,
        key,
        `${key}_uplift` as UpliftKeys);
    }
    if (key === 'conversion_range') {
      return getConversionRange(variant);
    }

    if (variant.experiment_results) {
      const currentResultValue = variant.experiment_results[key];
      if (key === 'probability_of_being_best') {
        return <span data-automation-id={`${key}_value`}>{currentResultValue !== undefined ? `${currentResultValue}%` : '-'}</span>;
      }

      if (key === 'revenue_generated') {
        return (
          <span id={`${key}_value`}>
            {
            currentResultValue !== undefined
              ? currentResultValue.toLocaleString('en', { style: 'currency', currency: variant?.experiment_results?.currency })
              : '-'
          }
          </span>
        );
      }

      return <span data-automation-id={`${key}_value`}>{currentResultValue !== undefined ? currentResultValue.toLocaleString() : '-'}</span>;
    }

    return null;
  };

  return (
    <Table striped displayDensity="cozy">
      <TableHead>
        <TableRow>
          <TableHeadColumn />
          {variants.map((e, i) => (
            <TableHeadColumn data-automation-id={`${isWinningVariant(e) ? 'winning-col' : ''}`} css={tableBGStyles(isWinningVariant(e))}>
              <Box>
                {i === 0 && (
                  <Button
                    css={baselineBtnStyles}
                    content="Baseline Variant"
                    size="small"
                    type="button"
                  />
                )}
                {isWinningVariant(e) && <WinnerBadge />}

              </Box>
            </TableHeadColumn>
          ))}

        </TableRow>
        <TableRow>
          <TableHeadColumn />
          {variants.map((e, i) => {
            const variantName = getVariantName(e);
            return (
              <>
                <TableHeadColumn data-automation-id={`${isWinningVariant(e) ? 'winning-col' : ''}`} css={tableBGStyles(isWinningVariant(e))}>
                  {variantName && <VariantBadge variantNumber={i} name={variantName} />}
                </TableHeadColumn>
              </>
            );
          })}

        </TableRow>

      </TableHead>

      <TableBody>

        {Object.values(metricSummaryValues).map((e) => (
          <TableRow>
            <TableCell>
              <Box alignContent="center">
                <span>{e.content}</span>
                <Tooltip
                  openOnHover
                  position="top"
                  trigger={<InfoCircleOutline width={16} css={infoCircleStyle} />}
                >
                  {e.tooltip}

                </Tooltip>
              </Box>
            </TableCell>
            {variants.map((v) => {
              const { value } = e;
              return (
                <StyledTableCell
                  isPositive={isWinningVariant(v)}
                  isBold={(value === 'probability_of_being_best' || value === 'conversion_range')}
                  data-automation-id={`${isWinningVariant(v) ? 'winning-col' : ''}`}
                >
                  {renderCell(v, value)}
                </StyledTableCell>
              );
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default ExperimentMetricsSummary;
