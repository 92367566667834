import { ActionTypes } from '../constants';
import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';

function createRequestAction(): RootActionTypes {
  return {
    type: ActionTypes.CREATE_SHIPPING_CONFIGURATION_REQUEST,
  };
}

function createSuccessAction(
  payload: io.flow.v0.models.ShippingConfiguration,
  organizationId: string,
): RootActionTypes {
  return {
    type: ActionTypes.CREATE_SHIPPING_CONFIGURATION_SUCCESS,
    meta: {
      organizationId,
    },
    payload,
  };
}

function createFailureAction(payload: FormattedErrorMessages): RootActionTypes {
  return {
    type: ActionTypes.CREATE_SHIPPING_CONFIGURATION_FAILURE,
    error: true,
    payload,
  };
}

/**
 * Creates an action responsible for creating a shipping configuration.
 * @param {String} props.organizationId
 * @param {ShippingConfigurationForm} props.shippingConfigurationForm
 */
function createShippingConfiguration(
  organizationId: string,
  shippingConfigurationForm: io.flow.v0.models.ShippingConfigurationForm,
): ThunkResult<Promise<RootActionTypes>> {
  return function createShippingConfigurationEffects(
    dispatch,
    getState,
    extra,
  ): Promise<RootActionTypes> {
    dispatch(createRequestAction());

    return extra.api(getState()).shippingConfigurations.post({
      body: shippingConfigurationForm,
      organization: organizationId,
    }).then((response) => {
      if (response.ok) {
        return dispatch(createSuccessAction(response.body, organizationId));
      }

      const errors = formatErrorResponseV2(response);
      return dispatch(createFailureAction(errors));
    });
  };
}

export default createShippingConfiguration;
