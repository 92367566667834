import { api } from '../../../utilities/clients';
import checkHttpStatus from '../../../utilities/check-http-status';
import { RootState } from '../../../stores/types';

interface SuccessResposne {
  result: io.flow.v0.models.Region[];
}

export default function getRegions(state: RootState) {
  return api.regions(state)
    .get()
    .then(checkHttpStatus)
    .then((response: SuccessResposne) => response.result);
}
