import { createSelector } from 'reselect';
import get from 'lodash/get';
import { RootState } from '../../stores/types';
import { AccountState } from './types';
import { FormattedErrorMessages } from '../../utilities/format-error-response-v2';

export const getAccountState = (state: RootState): AccountState => state.account;

export const getExports = createSelector<
RootState, AccountState, io.flow.v0.models.ScheduledExport[]
>(
  getAccountState,
  (state) => get(state, 'exports.results', []),
);

export const getExportsError = createSelector<
RootState, AccountState, FormattedErrorMessages
>(
  getAccountState,
  (state) => get(state, 'exports.error'),
);

export const getSelectedExport = createSelector<
RootState, AccountState, io.flow.v0.models.ScheduledExport
>(
  getAccountState,
  (state) => get(state, 'exports.selectedExport'),
);

export const getUiState = createSelector<RootState, AccountState, AccountState['ui']>(
  getAccountState,
  (state) => get(state, 'ui'),
);

export const getIsExportsLastPage = createSelector<RootState, AccountState, boolean>(
  getAccountState,
  (state) => get(state, 'exports.isLastPage'),
);

export const getIsExportsFirstPage = createSelector<RootState, AccountState, boolean>(
  getAccountState,
  (state) => get(state, 'exports.isFirstPage'),
);

export const getExportsCurrentPageNumber = createSelector<RootState, AccountState, number>(
  getAccountState,
  (state) => get(state, 'exports.pageNumber'),
);

export const getExportsDialogStatus = createSelector<RootState, AccountState, boolean>(
  getAccountState,
  (state) => get(state, 'exports.openDialog'),
);
