import ActionTypes from '../constants/action-types';
import { RootActionTypes, ThunkResult } from '../../../stores/types';
import { WithPagingAndSortingResult } from '../../../utilities/types';
import withPagingAndSorting, { isPagingAndSortingResponse, WithPagingAndSortingOptions } from '../../../utilities/with-paging-and-sorting-v2';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';

function createRequestAction(): RootActionTypes {
  return {
    type: ActionTypes.FETCH_PUBLIC_KEYS_REQUEST,
  };
}

function createSuccessAction(
  payload: WithPagingAndSortingResult<io.flow.v0.models.PublicKey[]>,
): RootActionTypes {
  return {
    type: ActionTypes.FETCH_PUBLIC_KEYS_SUCCESS,
    payload,
  };
}

function createFailureAction(payload: FormattedErrorMessages): RootActionTypes {
  return {
    type: ActionTypes.FETCH_PUBLIC_KEYS_FAILURE,
    payload,
  };
}

export default function fetchPublicKeys(
  organization: string,
  options: WithPagingAndSortingOptions = {},
): ThunkResult<Promise<void>> {
  return function fetchPublicKeysEffects(
    dispatch,
    getState,
    extra,
  ): Promise<void> {
    dispatch(createRequestAction());

    return withPagingAndSorting<io.flow.v0.models.PublicKey[]>(
      options,
      (params) => extra.api(getState()).publicKeys.get({
        organization,
        ...params,
      }),
    )().then((response) => {
      if (isPagingAndSortingResponse(response)) {
        dispatch(createSuccessAction(response.result));
        return;
      }

      const errors = formatErrorResponseV2(response);

      dispatch(createFailureAction(errors));
    });
  };
}
