export function hasOnlyChannelOrgAdminRoles(roles: io.flow.v0.enums.FlowRole[]): boolean {
  return roles.length === 1
      && roles[0] === 'channel_organization_admin'; // should not be hardcoded
}

export default function isChannelOrgAdmin(
  organizationId: string,
  memberships: io.flow.v0.models.Membership[],
): boolean {
  const orgMembership = memberships.find(
    (membership) => membership.organization.id === organizationId,
  );
  return (
    (orgMembership || false)
      && hasOnlyChannelOrgAdminRoles(orgMembership.roles)
  );
}
