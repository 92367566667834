import concat from 'lodash/concat';
import find from 'lodash/find';
import update from 'immutability-helper';

import ActionTypes from '../constants/action-types';
import { RootActionTypes } from '../../../stores/types';
import { RegionSettingsState } from '../types';

const defaultState: RegionSettingsState = [];

export default function regionSettingsReducer(
  state = defaultState,
  action: RootActionTypes,
): RegionSettingsState {
  switch (action.type) {
    case ActionTypes.FETCH_REGION_SETTINGS_SUCCESS:
      return update(state, { $set: action.payload });
    case ActionTypes.UPDATE_REGION_SETTING_SUCCESS: {
      const existing = find(state, (r) => r.region === action.payload.region);
      let updatedResults;

      if (existing) {
        updatedResults = state.map((r) => (
          r.region === action.payload.region ? action.payload : r
        ));
      } else {
        updatedResults = concat(state, action.payload);
      }

      return update(state, { $set: updatedResults });
    }
    default:
      return state;
  }
}
