import Client from './client';

export default class ShopifyOrderDetails extends Client {
  constructor(opts) {
    let options = opts;

    if (typeof opts === 'string') {
      options = { host: opts }; // convert host string to options object
    }

    options.serviceName = 'API Internal';

    super(options);
  }

  postChecksAndInventory(organization, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/shopify/orders/checks/inventory`, {
      ...options,
       method: 'POST',
    });
  }

  postEnsure(organization, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/shopify/orders/ensure`, {
      ...options,
       method: 'POST',
    });
  }

  getChecksAndInventoryByNumber(organization, number, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/shopify/orders/${number}/checks/inventory`, options);
  }

  postCodesByNumber(organization, number, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/shopify/orders/${number}/codes`, {
      ...options,
       method: 'POST',
    });
  }

  getContentsByNumber(organization, number, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/shopify/orders/${number}/contents`, options);
  }

  postPaymentsAndGiftCardByNumber(organization, number, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/shopify/orders/${number}/payments/gift-card`, {
      ...options,
       method: 'POST',
    });
  }

  putPromotionByNumber(organization, number, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/shopify/orders/${number}/promotion`, {
      ...options,
       method: 'PUT',
    });
  }

  deletePromotionByNumber(organization, number, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/shopify/orders/${number}/promotion`, {
      ...options,
       method: 'DELETE',
    });
  }

  deletePromotionAndDeleteByNumber(organization, number, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/shopify/orders/${number}/promotion/delete`, {
      ...options,
       method: 'DELETE',
    });
  }

  putPromotionsAndAutoAndApplyByNumber(organization, number, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/shopify/orders/${number}/promotions/auto/apply`, {
      ...options,
       method: 'PUT',
    });
  }

  getSubmissionsByNumberAndAuthorizationKey(organization, number, authorizationKey, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/shopify/orders/${number}/submissions/${authorizationKey}`, options);
  }

}
