import { createSelector } from 'reselect';
import get from 'lodash/get';
import getExclusionRulesForm from './getExclusionRulesForm';
import { RootState } from '../../../stores/types';
import { ExclusionRulesFormState } from '../types';

/**
 * Returns identifier of the exclusion rule to be updated by the exclusion rule worksheet.
 * @returns {String}
 */
const getExclusionRuleIdForExclusionRuleWorksheet = createSelector<
RootState, ExclusionRulesFormState, io.flow.v0.models.GenericError | undefined>(
  getExclusionRulesForm,
  (exclusionRuleForm) => get(exclusionRuleForm, 'submissionError'),
);

export default getExclusionRuleIdForExclusionRuleWorksheet;
