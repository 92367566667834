import Client from './client';

export default class MarketingGatewayFeeds extends Client {
  constructor(opts) {
    let options = opts;

    if (typeof opts === 'string') {
      options = { host: opts }; // convert host string to options object
    }

    options.serviceName = 'API Internal';

    super(options);
  }

  get(organization, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/marketing/gateway/feeds`, options);
  }

  post(organization, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/marketing/gateway/feeds`, {
      ...options,
       method: 'POST',
    });
  }

  postBulk(organization, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/marketing/gateway/feeds/bulk`, {
      ...options,
       method: 'POST',
    });
  }

  postExport(organization, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/marketing/gateway/feeds/export`, {
      ...options,
       method: 'POST',
    });
  }

  getByFeed(organization, feed, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/marketing/gateway/feeds/${feed}`, options);
  }

  deleteByFeed(organization, feed, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/marketing/gateway/feeds/${feed}`, {
      ...options,
       method: 'DELETE',
    });
  }

  getDownloadsByFeedAndFormat(organization, feed, format, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/marketing/gateway/feeds/${feed}/downloads/${format}`, options);
  }

}
