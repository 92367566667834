import { connect } from 'react-redux';
import { withFetch } from '@flowio/redux-fetch';
import CreateWebhook from '../components/create-webhook';
import { fetchOrganization, getOrganization, getOrganizationId } from '../../../../organization';
import { RootState, ThunkDispatcher } from '../../../../../stores/types';
import { OwnProps } from '../types';

function getAsyncState(
  dispatch: ThunkDispatcher,
  _getState: () => RootState,
  ownProps: OwnProps,
) {
  const { organization } = ownProps.params;
  return dispatch(fetchOrganization(organization));
}

function mapStateToProps(state: RootState) {
  return {
    organization: getOrganization(state),
    previousLocation: `/${getOrganizationId(state)}/organization/webhooks`,
  };
}

export default withFetch(getAsyncState)(
  connect(mapStateToProps)(CreateWebhook),
);
