import cuid from 'cuid';
import { RootActionTypes } from '../../../stores/types';

import { ActionTypes, ActivityKind } from '../constants';
import { CreateActivityOptions } from '../types/actions';

function createActivity(options: CreateActivityOptions): RootActionTypes {
  const {
    organizationId,
  } = options;
  const id = cuid();
  const timestamp = Date.now();

  switch (options.kind) {
    case ActivityKind.API_ERROR:
      return {
        type: ActionTypes.CREATE_ACTIVITY,
        payload: {
          id,
          timestamp,
          organizationId,
          kind: ActivityKind.API_ERROR,
          statusCode: options.statusCode,
          error: options.error,
        },
      };
    case ActivityKind.CHANGE_QUANTITY:
      return {
        type: ActionTypes.CREATE_ACTIVITY,
        payload: {
          id,
          timestamp,
          organizationId,
          kind: ActivityKind.CHANGE_QUANTITY,
          number: options.number,
          quantity: options.quantity,
        },
      };
    case ActivityKind.PRINT_LABEL:
      return {
        type: ActionTypes.CREATE_ACTIVITY,
        payload: {
          kind: options.kind,
          id,
          organizationId,
          timestamp,
          orderNumber: options.orderNumber,
        },
      };
    case ActivityKind.PRINT_INVOICE:
      return {
        type: ActionTypes.CREATE_ACTIVITY,
        payload: {
          kind: options.kind,
          id,
          organizationId,
          timestamp,
          orderNumber: options.orderNumber,
        },
      };
    case ActivityKind.SCAN_ORDER:
    case ActivityKind.CLEAR_ORDER:
      return {
        type: ActionTypes.CREATE_ACTIVITY,
        payload: {
          kind: options.kind,
          id,
          organizationId,
          timestamp,
          orderNumber: options.number,
        },
      };
    case ActivityKind.ALREADY_SCANNED:
    case ActivityKind.UNSCAN_ITEM:
    case ActivityKind.SCAN_ITEM:
    case ActivityKind.UNIDENTIFIED_ITEM:
    case ActivityKind.UNIDENTIFIED_NUMBER:
    case ActivityKind.LABEL_NOT_PRINTED:
    case ActivityKind.CLEAR_ITEMS:
    case ActivityKind.SELECT_ALL_ITEMS:
      return {
        type: ActionTypes.CREATE_ACTIVITY,
        payload: {
          kind: options.kind,
          id,
          organizationId,
          timestamp,
          number: options.number,
        },
      };
    default:
      return {
        type: ActionTypes.CREATE_ACTIVITY,
        payload: {
          kind: ActivityKind.UNIDENTIFIED_ACTIVITY,
          id,
          organizationId,
          timestamp,
        },
      };
  }
}

export default createActivity;
