import { ExperiencesGetByKeyResponse } from '@flowio/api-sdk';
import ActionTypes from '../constants/action-types';
import { RootActionTypes, ThunkResult, ThunkExtraArguments } from '../../../stores/types';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import { PropType } from '../../../types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';
import toLegacyResponse from '../../../utilities/clients/to-legacy-response';

function fetchExperienceRequest(
  organizationId: string,
  experienceKey: string,
): RootActionTypes {
  return {
    meta: {
      organizationId,
      experienceKey,
    },
    type: ActionTypes.FETCH_EXPERIENCE_REQUEST,
  };
}

type ResponseType = PropType<ExperiencesGetByKeyResponse, 'body'>;

export default function fetchExperience(
  organizationId: string,
  experienceKey: string,
): ThunkResult<Promise<LegacyResponse<ResponseType>>> {
  return function fetchExperienceEffects(
    dispatch,
    getState,
    extra: ThunkExtraArguments,
  ): Promise<LegacyResponse<ResponseType>> {
    dispatch(fetchExperienceRequest(organizationId, experienceKey));
    return extra.api(getState()).experiences.getByKey({
      organization: organizationId,
      key: experienceKey,
    }).then((response) => {
      if (response.ok) {
        dispatch({
          type: ActionTypes.FETCH_EXPERIENCE_SUCCESS,
          meta: {
            organizationId,
            experienceKey,
          },
          payload: response.body,
        });
      } else {
        dispatch({
          type: ActionTypes.FETCH_EXPERIENCE_FAILURE,
          payload: formatErrorResponseV2(response),
        });
      }
      return toLegacyResponse<ResponseType>(response);
    }).catch((error) => {
      throw error;
    });
  };
}
