import { type RouterState, browserHistory } from 'react-router';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  getIsFirstPage,
  getIsLastPage,
  getIsSinglePage,
  getPageNumber,
  getPageResults,
  getPageSize,
} from '@flowio/redux-filtering-paging-sorting';
import { setOverlayProps } from '@flowio/redux-form-overlay';
import { withFetch } from '@flowio/redux-fetch';

import { ActionTypes, DialogName, FormName } from '../constants';
import { fetchExperiences, fetchShippingConfigurations, updateDialog } from '../actions';
import { fetchOrganization } from '../../organization/actions';
import { getShippingConfigurationPaginationState } from '../selectors';
import { getOrganizationId } from '../../organization/selectors';
import ShippingConfigurationListingPage from '../components/ShippingConfigurationListingPage';
import { RootState, ThunkDispatcher } from '../../../stores/types';
import { ShippingConfigurationListingPageStateProps } from '../types/components';

function parseInteger(value: string, base?: number) {
  const number = Number.parseInt(value, base);

  if (!Number.isNaN(number)) {
    return number;
  }

  return undefined;
}

const fetchState = (
  dispatch: ThunkDispatcher,
  _getState: () => RootState,
  props: RouterState,
) => {
  const { location, params } = props;
  const { organization } = params;
  const { pageNumber, entriesPerPage } = location.query;
  return Promise.all([
    dispatch(fetchOrganization(organization)),
    dispatch(fetchShippingConfigurations({
      entriesPerPage: parseInteger(entriesPerPage, 10),
      organizationId: organization,
      pageNumber: parseInteger(pageNumber, 10),
    })).then((result) => {
      if (result.type === ActionTypes.FETCH_SHIPPING_CONFIGURATIONS_FAILURE) {
        return Promise.resolve([]);
      }

      const promises = result.payload.map((shippingConfiguration) => (
        dispatch(fetchExperiences({
          entriesPerPage: 100,
          organizationId: organization,
          shippingConfigurationKey: shippingConfiguration.key,
        }))
      ));

      return Promise.all(promises);
    }),
  ]);
};

const handleAddShippingConfiguration = (organizationId: string) => (
  setOverlayProps(FormName.CREATE_SHIPPING_CONFIGURATION_FORM, {
    initialValues: {
      organizationId,
      method: 'pristine',
    },
    open: true,
    title: 'Add Shipping Configuration',
  })
);

function handleDeleteShippingConfiguration(
  organizationId: string,
  shippingConfiguration: io.flow.v0.models.ShippingConfiguration,
  experiences: io.flow.v0.models.Experience[],
) {
  return updateDialog(DialogName.SHIPPING_CONFIGURATION_DELETE_CONFIRMATION, {
    experiences,
    initialValues: {
      organizationId,
      shippingConfigurationKey: shippingConfiguration.key,
    },
    open: true,
  });
}

function handleDuplicateShippingConfiguration(
  organizationId: string,
  shippingConfiguration: io.flow.v0.models.ShippingConfiguration,
) {
  return updateDialog(DialogName.SHIPPING_CONFIGURATION_COPY_WORKSHEET, {
    initialValues: {
      organizationId,
      shippingConfigurationKey: shippingConfiguration.key,
    },
    open: true,
    shippingConfiguration,
    title: 'Create Shipping Configuration',
  });
}

const handleViewShippingConfiguration = (
  organizationId: string,
  shippingConfigurationKey: string,
) => browserHistory.push({
  pathname: `/${organizationId}/logistics/shipping/configurations/${shippingConfigurationKey}`,
});

const handlePageChange = (
  organizationId: string,
  pageNumber: number,
  entriesPerPage: number,
) => browserHistory.push({
  pathname: `/${organizationId}/logistics/shipping/configurations`,
  query: { entriesPerPage, pageNumber },
});

const mapStateToProps = createStructuredSelector<
RootState,
ShippingConfigurationListingPageStateProps
>({
  entriesPerPage: getPageSize(getShippingConfigurationPaginationState),
  isFirstPage: getIsFirstPage(getShippingConfigurationPaginationState),
  isLastPage: getIsLastPage(getShippingConfigurationPaginationState),
  isSinglePage: getIsSinglePage(getShippingConfigurationPaginationState),
  organizationId: getOrganizationId,
  pageNumber: getPageNumber(getShippingConfigurationPaginationState),
  shippingConfigurations: getPageResults(getShippingConfigurationPaginationState),
});

const mapDispatchToProps = (dispatch: ThunkDispatcher) => bindActionCreators({
  onAddShippingConfiguration: handleAddShippingConfiguration,
  onDeleteShippingConfiguration: handleDeleteShippingConfiguration,
  onDuplicateShippingConfiguration: handleDuplicateShippingConfiguration,
  onViewShippingConfiguration: handleViewShippingConfiguration,
  onPageChange: handlePageChange,
}, dispatch);

export default compose<React.FC>(
  withFetch(fetchState),
  connect(mapStateToProps, mapDispatchToProps),
)(ShippingConfigurationListingPage);
