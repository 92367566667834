import { css } from '@emotion/css';
import colors from '../../../../theme/tokens/colors';

export const downloadBtnContainer = css`
  padding-top: 8px;
`;

export const iconWrapper = css`
  padding: 8px;
  border-radius: 8px;
  background-color: ${colors.gray[200]};
`;

export const labelInfoContainer = css`
  margin-top: 8px;
`;

export const labelIcon = css`
  fill: ${colors['flow-blue'][400]};
`;

export const labelContainer = css`
  padding-top: 8px;
  padding-bottom: 8px;
`;

export const labelDetails = css`
  label {
    color: ${colors.gray[700]};
  }
`;
