import { Hamburger, InfoCircleOutline, CreditCards } from '@flowio/react-icons';
import React from 'react';
import { Draggable, DraggableStateSnapshot, DraggableProvided } from 'react-beautiful-dnd';

import { Tooltip } from '@flowio/react-tooltip';
import { Switch } from '@flowio/react-switch';
import { ExperiencePaymentMethodTag } from '@flowio/api-constants';
import classNames from 'classnames';
import handleEnterKeyboardEvent from '../../../../utilities/enterKeyPressHandler';
import { SHOW_NUMBER_OF_CCS } from '../../constants/PaymentMethods';
import { colors } from '../../../../theme/tokens';
import * as styles from './PaymentMethodRuleDisplay.styles';

interface Props {
  canToggle?: boolean;
  cards?: io.flow.v0.models.PaymentMethodRule[];
  handlePaymentMethodRuleDisplayChange: Function;
  handleCCOrderOpen?: (event: React.SyntheticEvent) => void;
  isFlowEmployee: boolean;
  isShown: boolean;
  paymentMethodRule?: io.flow.v0.models.PaymentMethodRule;
  slot: number;
}

const PaymentMethodRuleDisplay: React.FC<Props> = React.memo(({
  canToggle,
  cards,
  handlePaymentMethodRuleDisplayChange,
  handleCCOrderOpen,
  isFlowEmployee,
  isShown,
  paymentMethodRule,
  slot,
}) => {
  const name = paymentMethodRule ? paymentMethodRule.payment_method.id : 'cards';
  const pm = paymentMethodRule && paymentMethodRule.payment_method;

  if (!cards && !pm) {
    throw new Error('PaymentMethodRuleDisplay: expected either `cards` or `paymentMethodRule` prop.');
  }

  const renderCardLogos = (): React.ReactNode => {
    if (!cards) return null;
    const visibleCards = cards
      .filter((card) => card.tags.some((tag) => (tag.discriminator === 'experience_payment_method_tag' && tag.value === ExperiencePaymentMethodTag.DISPLAY)))
      .slice(0, SHOW_NUMBER_OF_CCS);
    return (
      <div className={classNames(styles.cardLogos, 'card-logos')}>
        {visibleCards.map((card) => (
          <div key={card.payment_method.id} className={classNames(styles.logoContainer, 'logo-container')} style={{ backgroundImage: `url(${card.payment_method.images.medium.url})` }} />
        ))}
        {isFlowEmployee
          && (
            <span
              role="button"
              tabIndex={-1}
              className={styles.editCardsLink}
              onClick={handleCCOrderOpen}
              onKeyPress={handleEnterKeyboardEvent(handleCCOrderOpen)}
            >
              Edit
            </span>
          )}
      </div>
    );
  };

  const handleDisplayChange = (): void => {
    handlePaymentMethodRuleDisplayChange(
      name,
      !isShown,
    );
  };

  const showHideToggleSwitch = (): JSX.Element => (
    <Switch
      className={styles.showToggle}
      disabled={!!cards || !isFlowEmployee || !canToggle}
      offText="Hidden"
      onText="Shown"
      checked={!!cards || isShown}
      onChange={handleDisplayChange}
      width={75}
    />
  );

  return (
    <Draggable
      draggableId={paymentMethodRule ? paymentMethodRule.payment_method.id : 'cards'}
      index={slot}
      isDragDisabled={!isFlowEmployee}
    >
      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot): JSX.Element => (
        <div
          className={styles.outerContainer}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div className={classNames(styles.container, {
            [styles.disabled]: !isFlowEmployee, dragging: snapshot.isDragging,
          })}
          >
            <div className={classNames(styles.dragHandle, 'drag-handle')}>
              <Hamburger width={24} height={24} fill={colors.blue['500']} />
            </div>
            {cards
              ? <CreditCards fill={colors.marketing['dark-slate-blue']} className={styles.logoContainer} />
              : <div className={styles.logoContainer} style={{ backgroundImage: `url(${pm && pm.images.medium.url.replace(/\/logos\//, '/icons/')})` }} />}
            <span className={styles.name}>
              {cards ? 'Credit and Debit Cards' : pm && pm.name}
              {cards && (
                <Tooltip
                  openOnHover
                  trigger={<InfoCircleOutline className={styles.ccTooltip} width={16} />}
                >
                  All supported credit and debit are included. Up to 6 card icons can be displayed.
                </Tooltip>
              )}
            </span>
            {cards && renderCardLogos()}
            {cards
              ? (
                <Tooltip
                  openOnHover
                  content="Credit and debit cards are always shown in Checkout."
                  trigger={showHideToggleSwitch()}
                />
              )
              : showHideToggleSwitch()}
          </div>
        </div>
      )}
    </Draggable>
  );
});

PaymentMethodRuleDisplay.displayName = 'PaymentMethodDisplay';

PaymentMethodRuleDisplay.defaultProps = {
  canToggle: true,
  cards: undefined,
  isFlowEmployee: false,
  paymentMethodRule: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleCCOrderOpen: (): void => {},
  slot: undefined,
};

export default PaymentMethodRuleDisplay;
