import { createSelector } from 'reselect';
import get from 'lodash/get';
import identity from 'lodash/identity';
import { RootState } from '../../../stores/types';
import { AnalyticsState } from '../types';

// Returns the parameters for the organization GMV timeline request
export default createSelector<RootState, RootState, AnalyticsState['gmvByTimeline']['params']>(
  identity,
  (state) => get(state, 'analytics.gmvByTimeline.params'),
);
