import { css } from '@emotion/css';
import colors from '../../../../../theme/tokens/colors';

// eslint-disable-next-line import/prefer-default-export
export const detailsContainer = css`
  .column {
    font-size: 14px;
    max-width: 50%;

    .metricContainer {
      padding-bottom: 1rem;

      .trafficLabel,
      .sessionQuery,
      .label {
        color: ${colors.steel};
        font-weight: 500;
        display: inline-block;
        padding-right: 1rem;
        width: 139px;
      }
    }
  }
`;
