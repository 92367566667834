import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import ActionTypes from '../constants/action-types';

function requestAction(): RootActionTypes {
  return {
    type: ActionTypes.FETCH_LABS_SETTINGS_REQUEST,
  };
}

function successAction(payload: io.flow.internal.v0.models.LabProjectSettings[]): RootActionTypes {
  return {
    type: ActionTypes.FETCH_LABS_SETTINGS_SUCCESS,
    payload,
  };
}

function failureAction(payload: FormattedErrorMessages): RootActionTypes {
  return {
    type: ActionTypes.FETCH_LABS_SETTINGS_FAILURE,
    payload,
  };
}

function fetchLabsSettings(organizationId: string): ThunkResult<Promise<void>> {
  return function fetchLabByKeyEffects(
    dispatch,
    getState,
    extra,
  ): Promise<void> {
    const state = getState();

    dispatch(requestAction());

    return extra.apiInternal(state).flowLabsProjectSettings.get({
      organization: organizationId,
      limit: 101,
      offset: 0,
    }).then((response) => {
      if (response.ok) {
        dispatch(successAction(response.body));
        return;
      }

      const error = formatErrorResponseV2(response);
      dispatch(failureAction(error));
    });
  };
}

export default fetchLabsSettings;
