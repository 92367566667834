import ExperienceActionTypes from '../../experience/constants/action-types';
import { ExperimentsExperiencesState } from '../types';
import { RootActionTypes } from '../../../stores/types';

const initialState: ExperimentsExperiencesState = {
  isFirstPage: true,
  isLastPage: true,
  results: [],
};

export default function (
  state = initialState,
  action: RootActionTypes,
): ExperimentsExperiencesState {
  switch (action.type) {
    case ExperienceActionTypes.FETCH_ALL_EXPERIENCES_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
