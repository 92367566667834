import isEqual from 'lodash/isEqual';
import isUndefined from 'lodash/isUndefined';

function isQuotaExceeded(e) {
  let quotaExceeded = false;

  if (e) {
    if (e.code) {
      switch (e.code) {
        case 22:
        // Chrome Safari
          quotaExceeded = true;
          break;
        case 1014:
        // Firefox
          if (e.name === 'NS_ERROR_DOM_QUOTA_REACHED') {
            quotaExceeded = true;
          }
          break;
        default:
          break;
      }
    } else if (e.number === -2147024882) {
      // Internet Explorer 8
      quotaExceeded = true;
    }
  }
  return quotaExceeded;
}

export default function updateRecentSearches(organization, value) {
  const { localStorage } = window;

  if (!isUndefined(localStorage)) {
    const previousState = JSON.parse(localStorage.getItem('recentSearches')) || {};
    const previousValue = previousState[organization] || [];
    const nextValue = [value]
      .concat(previousValue.filter((item) => !isEqual(item, value)))
      .filter((item) => item != null)
      .slice(0, 5);
    const nextState = {
      ...previousState,
      [organization]: nextValue,
    };

    try {
      localStorage.setItem('recentSearches', JSON.stringify(nextState));
    } catch (e) {
      if (isQuotaExceeded(e)) {
        localStorage.removeItem('recentSearches');
      }
    }
    return nextValue;
  }
  return [];
}
