import React from 'react';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';

import BemHelper from '@flowio/bem-helper';

import AccountDropDownMenu from '../account-drop-down-menu';
import { TopNavigationActionsProps } from '../../types/props';
import TopNavOrganization from '../TopNavOrganization';

if (process.browser) {
  require('./top-navigation-actions.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('top-navigation-actions');

const TopNavigationActions: React.FC<TopNavigationActionsProps> = ({
  user,
  memberships,
  organization,
  onMembershipChange,
}) => (
  <div className={bem.block()}>
    {!isEmpty(memberships) && (
    <TopNavOrganization
      memberships={memberships}
      organizationId={organization}
      onMembershipChange={onMembershipChange}
    />
    )}
    {!isEmpty(user) && (
    <AccountDropDownMenu />
    )}
  </div>
);

TopNavigationActions.defaultProps = {
  organization: undefined,
  memberships: [],
  onMembershipChange: noop,
  showNotificationBell: true,
};

export default TopNavigationActions;
