import React from 'react';
import { Plus } from '@flowio/react-icons';
import {
  Card, CardHeader, CardContent, CardFooter, CardEmptyState, CardTitle,
} from '@flowio/react-card';
import { Box } from '@flowio/react-box';
import { OutlineButton } from '@flowio/react-button';
import size from 'lodash/size';
import { css } from '@emotion/css';
import { NonReturnableIndexProps } from '../../types';

import ReturnPolicyList from '../ReturnPolicyList';
import ReturnPolicyWorksheet from '../../containers/ReturnPolicyWorksheet';
import ReturnPolicyDeleteConfirmation from '../../containers/ReturnPolicyDeleteConfirmation';
import Pagination from '../../../../components/pagination';

const pageContentStyles = css`
  margin-top: 1rem;
`;

class NonReturnableIndex extends
  React.PureComponent<NonReturnableIndexProps> {
  static displayName = 'NonReturnableIndex';

  constructor(props: NonReturnableIndexProps, context: any) {
    super(props, context);
    this.handleAddReturnPolicy = this.handleAddReturnPolicy.bind(this);
    this.handleEditReturnPolicy = this.handleEditReturnPolicy.bind(this);
    this.handleRemoveReturnPolicy = this.handleRemoveReturnPolicy.bind(this);
  }

  handleRequestNextPage = () => {
    const {
      entriesPerPage,
      onPageChange,
      organizationId,
      pageNumber,
    } = this.props;

    const nextPage = pageNumber + 1;

    onPageChange(organizationId, nextPage, entriesPerPage);
  };

  handleRequestPreviousPage = () => {
    const {
      entriesPerPage,
      onPageChange,
      organizationId,
      pageNumber,
    } = this.props;

    const nextPage = pageNumber - 1;

    onPageChange(organizationId, nextPage, entriesPerPage);
  };

  handleAddReturnPolicy() {
    const { organizationId, onAddReturnPolicy } = this.props;
    onAddReturnPolicy(organizationId);
  }

  handleEditReturnPolicy(returnPolicy: io.flow.v0.models.ReturnPolicy) {
    const { organizationId, onEditReturnPolicy } = this.props;
    onEditReturnPolicy(organizationId, returnPolicy);
  }

  handleRemoveReturnPolicy(returnPolicy: io.flow.v0.models.ReturnPolicy) {
    const { organizationId, onRemoveReturnPolicy } = this.props;
    onRemoveReturnPolicy(organizationId, returnPolicy);
  }

  render() {
    const {
      returnPolicies,
      isFirstPage,
      isLastPage,
      organizationId,
      pageNumber,
    } = this.props;

    const isSinglePage = isFirstPage && isLastPage;
    const hasReturnPolicies = size(returnPolicies) > 0;

    return (
      <div className={pageContentStyles}>
        <Card>
          <CardHeader dividing>
            <Box alignItems="center" justifyContent="between">
              <CardTitle content="Non-Returnable Rules" />
              <OutlineButton
                content="New Rule"
                leftIcon={Plus}
                intent="primary"
                onClick={this.handleAddReturnPolicy}
              />
            </Box>
          </CardHeader>
          <CardContent fitted>
            {hasReturnPolicies ? (
              <ReturnPolicyList
                returnPolicies={returnPolicies}
                onEditReturnPolicy={this.handleEditReturnPolicy}
                onRemoveReturnPolicy={this.handleRemoveReturnPolicy}
                organizationId={organizationId}
              />
            ) : (
              <CardEmptyState
                content="No non-returnable rules found."
              />
            )}
          </CardContent>
          {hasReturnPolicies && !isSinglePage && (
            <CardFooter dividing>
              <Box alignItems="center" justifyContent="between">
                {!isSinglePage && (
                  <Pagination
                    pageNumber={pageNumber}
                    isNextPageDisabled={isLastPage}
                    isPreviousPageDisabled={isFirstPage}
                    onRequestNextPage={this.handleRequestNextPage}
                    onRequestPreviousPage={this.handleRequestPreviousPage}
                  />
                ) }
              </Box>
            </CardFooter>
          )}
        </Card>
        <ReturnPolicyWorksheet />
        <ReturnPolicyDeleteConfirmation />
      </div>
    );
  }
}

export default NonReturnableIndex;
