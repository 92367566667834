import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { browserHistory } from 'react-router';
import { isSubmitting, submit } from 'redux-form';

import { deleteDialog } from '../actions';
import { createToast } from '../../console/actions';
import { getDialogProperty } from '../selectors';
import DialogName from '../constants/DialogName';
import FormName from '../constants/FormName';
import ShippingConfigurationCopyWorksheet from '../components/ShippingConfigurationCopyWorksheet';
import { CreateShippingConfigurationCopySuccess } from '../types/actions';
import { RootState, ThunkDispatcher } from '../../../stores/types';
import { ShippingConfigurationCopyWorksheetStateProps, ShippingConfigurationCopyWorksheetValues } from '../types/components';

const dialogName = DialogName.SHIPPING_CONFIGURATION_COPY_WORKSHEET;
const formName = FormName.SHIPPING_CONFIGURATION_COPY_FORM;

function handleCancel() {
  return deleteDialog({ type: dialogName });
}

function handleSave() {
  return submit(formName);
}

function handleSuccess(result: CreateShippingConfigurationCopySuccess) {
  return function handleSuccessSideEffects(dispatch: ThunkDispatcher) {
    const { organizationId } = result.meta;
    const shippingConfigurationKey = result.payload.new.key;
    browserHistory.push(`/${organizationId}/logistics/shipping/configurations/${shippingConfigurationKey}`);
    dispatch(deleteDialog({ type: dialogName }));
    dispatch(createToast({ content: 'Shipping configuration has been created.' }));
  };
}

const mapStateToProps = createStructuredSelector <
RootState,
ShippingConfigurationCopyWorksheetStateProps
>({
  initialValues: getDialogProperty<ShippingConfigurationCopyWorksheetValues>(dialogName, 'initialValues'),
  isSubmitting: isSubmitting(formName),
  isOpen: getDialogProperty<boolean>(dialogName, 'open'),
  shippingConfiguration: getDialogProperty<io.flow.v0.models.ShippingConfiguration>(dialogName, 'shippingConfiguration'),
  title: getDialogProperty(dialogName, 'title'),
});

const mapDispatchToProps = (dispatch: ThunkDispatcher) => bindActionCreators({
  onCancel: handleCancel,
  onSave: handleSave,
  onSuccess: handleSuccess,
}, dispatch);

export default compose<React.FC>(
  connect(mapStateToProps, mapDispatchToProps),
)(ShippingConfigurationCopyWorksheet);
