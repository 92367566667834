import invariant from 'invariant';
import { ThunkResult } from '../../../stores/types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';
import { createToast } from '../../console/actions';
import ActionType from '../constants/ActionType';

/**
 * Creates an action responsible for marking an exclusion rule in the specified
 * organization for deletion.
 * @param {String} organizationId - identifier for the organization to be updated.
 * @param {String} exclusionRuleId - identifier for the exclusion rule to be deleted.
 */
export default function deleteExclusionRule(
  organizationId: string,
  exclusionRuleId: string,
): ThunkResult<Promise<void>> {
  invariant(organizationId != null, 'An organization identifier must be provided');
  invariant(exclusionRuleId != null, 'An exclusion rule identifier must be provided');

  return function deleteExclusionRuleEffects(
    dispatch,
    getState,
    extra,
  ): Promise<void> {
    dispatch({
      type: ActionType.DeleteExclusionRuleRequest,
      meta: {
        exclusionRuleId,
        organizationId,
      },
    });
    return extra.api(getState()).exclusionRules.putDeletionById({
      organization: organizationId,
      id: exclusionRuleId,
    })
      .then((response) => {
        if (response.ok) {
          dispatch({
            type: ActionType.DeleteExclusionRuleSuccess,
            meta: {
              exclusionRuleId,
              organizationId,
            },
            payload: response.body,
          });
          dispatch(createToast({
            intent: 'positive',
            content: 'Exclusion rule deleted successfully',
          }));
          return;
        }
        const error = formatErrorResponseV2(response);
        dispatch({
          payload: error,
          meta: {
            exclusionRuleId,
            organizationId,
          },
          type: ActionType.DeleteExclusionRuleFailure,
        });
      });
  };
}
