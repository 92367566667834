import { createSelector } from 'reselect';
import find from 'lodash/find';
import get from 'lodash/get';
import map from 'lodash/map';
import property from 'lodash/property';

export const getReferenceState = property('reference');
export const getCountries = createSelector(getReferenceState, property('entities.countries'));
export const getCurrencies = createSelector(getReferenceState, property('entities.currencies'));
export const getLanguages = createSelector(getReferenceState, property('entities.languages'));
export const getRegions = createSelector(getReferenceState, property('entities.regions'));

export const getRegionById = (regionId) => createSelector(
  getRegions,
  (regions) => find(regions, { id: regionId }),
);

export const getRegionCurrencies = (regionId) => createSelector(
  getRegionById(regionId),
  getCurrencies,
  (region, currencies) => map(get(region, 'currencies'), (
    (currencyCode) => find(currencies, { iso_4217_3: currencyCode })
  )),
);

export const getCountriesStatus = createSelector(getReferenceState, property('statuses.countries.status'));
export const getCurrenciesStatus = createSelector(getReferenceState, property('statuses.currencies.status'));
export const getLanguagesStatus = createSelector(getReferenceState, property('statuses.languages.status'));
export const getRegionsStatus = createSelector(getReferenceState, property('statuses.regions.status'));
