import { RSAA } from '@flowio/redux-async-middleware';
import { operationTypes } from '@flowio/redux-resource';
import { createQueryParams } from '@flowio/redux-resource-pagination-plugin';
import PropTypes from 'prop-types';

import { api } from '../../../../utilities/clients';
import { createResourceTypeDescriptors } from '../../helpers';
import { withAssertPropTypes } from '../../../../utilities/prop-types';

export default withAssertPropTypes({
  organizationId: PropTypes.string.isRequired,
  experienceKey: PropTypes.string.isRequired,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pageNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
})(({
  organizationId,
  experienceKey,
  pageNumber = 1,
  pageSize = 100,
}) => ({
  [RSAA]: {
    types: createResourceTypeDescriptors({
      operationType: operationTypes.READ,
      resourceType: 'priceBookMappings',
      list: organizationId,
      pageSize,
      pageNumber,
    }),
    callAPI: (state) => api.experiences(state)
      .getPriceAndBooksByExperienceKey(organizationId, experienceKey, {
        params: createQueryParams({ pageSize, pageNumber }),
      }),
  },
}));
