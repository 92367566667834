import { CardEmptyState } from '@flowio/react-card';
import React from 'react';
import classNames from 'classnames';
import * as styles from './MarketingEmptyState.styles';

interface Props {
  title: string;
  subtitle: string | JSX.Element;
}

const MarketingEmptyState: React.FC<Props> = ({
  title,
  subtitle,
  children,
}) => (
  <CardEmptyState className={classNames(styles.noFeeds, 'noFeedsCard')}>
    <p>
      {title}
    </p>
    <span>
      {subtitle}
    </span>

    <div className={styles.content}>
      {children}
    </div>
  </CardEmptyState>
);

MarketingEmptyState.displayName = 'MarketingEmptyState';

export default MarketingEmptyState;
