import { compose } from 'redux';
import { RouterState } from 'react-router';
import { loginUser } from '../actions';
import withSubmit from '../../../components/with-submit';
import withRedirectSubmitted from '../../../components/with-redirect-submitted';
import withRedirect from '../../../components/with-redirect';
import withValidation from '../../../components/with-validation';
import Login from '../components/login';
import getPostLoginLocation from '../utilities/get-post-login-location';
import { ThunkResult, RootState } from '../../../stores/types';
import { AuthResponse } from '../../../utilities/clients/server/types';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';

interface LoginForm {
  username: string;
  password: string;
}

const authRegex = /^(\/?(\w|-)*)?\/auth\/(readme|facebook|google|courthouse|sso)/;

function submitForm(formData: LoginForm):
ThunkResult<Promise<LegacyResponse<AuthResponse | FormattedErrorMessages>>> {
  return loginUser(formData.username, formData.password);
}

// for readme preview and marketing channel auth requests
const reloadPredicate = (_state: RootState, { location }: RouterState): boolean => {
  const returnUrl = location.query.return_url;
  if (returnUrl) {
    return authRegex.test(Array.isArray(returnUrl) ? returnUrl[0] : returnUrl);
  }
  return false;
};

export default compose(
  withSubmit<AuthResponse | FormattedErrorMessages>(submitForm),
  // withRedirect readme preview and marketing channel auth requests
  withRedirect(
    getPostLoginLocation,
    (_state: RootState, { submitted }: { submitted: boolean }) => submitted,
    { reloadPredicate },
  ),
  withRedirectSubmitted(getPostLoginLocation),
  withValidation({
    username: {
      validations: [{
        required: true,
        message: 'Email address is required',
      }, {
        pattern: 'email',
        message: 'That doesn\'t look like a valid email address',
      }],
    },
    password: {
      validations: {
        required: true,
        message: 'Password is required.',
      },
    },
  }),
)(Login);
