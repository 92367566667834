import React, { useCallback } from 'react';
import { isNil } from '@flowio/is';
import { css } from '@emotion/css';
import { IconButton } from '@flowio/react-button';
import { Apps } from '@flowio/react-icons';
import { browserHistory } from 'react-router';

const styles = {
  topNavOrganization: css`
    color: #fff;
    font-weight: 700;
    font-size: 14px;
    margin-right: 12px;
  `,
  sandboxPill: css`
    border: 1px solid gray;
    border-radius: 3px;
    padding: 0 5px;
    margin: 0 5px;
    font-weight: 300;
    font-size: 12px;
  `,
  appButton: css`
    margin-left: 12px;
    vertical-align: sub;
    cursor: pointer;

    & svg {
      color: var(--color-gray-500);
    }

    &:hover {
      background-color: var(--charcoal) !important;
    }
  `,
};

type TopNavOrganizationProps = {
  memberships: io.flow.v0.models.Membership[];
  organizationId: string;
  onMembershipChange: (...args: any) => void;
};

const TopNavOrganization: React.FC<TopNavOrganizationProps> = ({
  memberships,
  organizationId,
}) => {
  const isNotHomePage = /^\/[^\?|$]+/.test(browserHistory.getCurrentLocation().pathname);

  const currentMembership = memberships.find(
    (membership) => membership.organization.id === organizationId,
  );

  const handleAllOrgsClick = useCallback(() => {
    browserHistory.push('/');
  }, []);

  const currentOrganization = currentMembership && currentMembership.organization.discriminator === 'organization' ? currentMembership.organization : undefined;

  return (
    <div className={styles.topNavOrganization}>
      {isNil(currentOrganization)
        ? organizationId
        : (
          <>
            <span>
              {currentOrganization.name}
            </span>
            {currentOrganization.environment === 'sandbox' && (
              <span className={styles.sandboxPill}>
                sandbox
              </span>
            )}
          </>
        )}
      {isNotHomePage && (
        <IconButton
          className={styles.appButton}
          icon={<Apps />}
          onClick={handleAllOrgsClick}
          variant="flat"
          title="Choose Organization"
        />
      )}
    </div>
  );
};

export default TopNavOrganization;
