import ActionTypes from '../constants/action-types';
import { ExperimentUIState, ExperimentsActions } from '../types';

const initialState: ExperimentUIState = {
  selectedExperiment: undefined,
  endExperimentModalOpen: false,
};

export default function (
  state: ExperimentUIState = initialState,
  action: ExperimentsActions,
): ExperimentUIState {
  switch (action.type) {
    case ActionTypes.OPEN_EXPERIMENTS_MODAL:
      return {
        ...state,
        experimentModalOpen: true,
      };

    case ActionTypes.TOGGLE_END_EXPERIMENT_MODAL:
      return {
        ...state,
        selectedExperiment: action.payload,
        endExperimentModalOpen: !state.endExperimentModalOpen,
      };

    case ActionTypes.TOGGLE_IMPLEMENT_VARIANT_MODAL:
      return {
        ...state,
        implementVariantModalOpen: !state.implementVariantModalOpen,
      };

    case ActionTypes.CLOSE_EXPERIMENTS_MODAL:
    case ActionTypes.CREATE_EXPERIMENT_SUCCESS:
    case ActionTypes.UPDATE_EXPERIMENT_SUCCESS:
    case ActionTypes.CREATE_AND_START_EXPERIMENT_SUCCESS:
      return {
        ...state,
        experimentModalOpen: false,
      };
    default:
      return state;
  }
}
