import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Button from '../../MarketingButton';
import * as styles from './SelectGoogleAccount.styles';
import AccountSelection from '../../../constants/AccountSelection';
import MarketingCard, { MarketingCardActions, MarketingCardHeading } from '../../MarketingCard';
import ConnectionConfirmationDialog from '../../ConnectionConfirmationDialog';
import { Props } from '../types';

import SelectableTable from '../../SelectableTable';
import MarketingPage from '../../MarketingPage';
import Platforms from '../../../constants/Platforms';
import { marketingBannerStyles } from '../../../styles/styles';

const {
  NO_GMC_ACCOUNTS_FOUND,
  NO_GMC_FEEDS_FOUND,
} = AccountSelection;

// Returns the item in the array with the highest feeds_count, if there is one with feeds
const mostFeedsItem = (
  arr: io.flow.internal.v0.models.MarketingGatewayGoogleAccountSummary[],
): io.flow.internal.v0.models.MarketingGatewayGoogleAccountSummary | undefined => arr.reduce((
  acc: io.flow.internal.v0.models.MarketingGatewayGoogleAccountSummary | undefined,
  item,
) => {
  if (item.domestic_feeds_count > 0) {
    return (acc && acc.domestic_feeds_count >= item.domestic_feeds_count) ? acc : item;
  }
  return acc;
}, undefined);

const SelectGoogleAccount: React.FC<Props> = ({
  accounts,
  showConnectionConfirmDialog,
  onDisconnectButtonClicked,
  handleCreateChannel,
  onConnectionConfirmDialogClose,
}) => {
  const [selected, setSelected] = useState(mostFeedsItem(accounts));
  const [selectedAccountId, setSelectedAccountId] = useState(
    selected ? selected.account_id : undefined,
  );

  useEffect(() => {
    setSelected(mostFeedsItem(accounts));
    if (selected) {
      setSelectedAccountId(selected.account_id);
    }
  }, [accounts, selected]);

  const handleNextClick = (): void => {
    if (selectedAccountId) {
      handleCreateChannel({
        selectedAccountId,
      });
    }
  };

  const cancelAccountSelection = (): void => {
    onDisconnectButtonClicked();
  };

  const handleConnectConfirmClose = (): void => {
    if (selectedAccountId) {
      onConnectionConfirmDialogClose({
        selectedAccountId,
      });
    }
  };

  const maybeRenderErrorMessage = (): JSX.Element | null => {
    if (!accounts.length) {
      return <div className={classNames(marketingBannerStyles, 'negative')}>{NO_GMC_ACCOUNTS_FOUND}</div>;
    }
    if (!selected) {
      return <div className={classNames(marketingBannerStyles, 'negative')}>{NO_GMC_FEEDS_FOUND}</div>;
    }
    return null;
  };

  return (
    <MarketingPage heading={`Connecting to ${Platforms('google').platformName}`}>
      <MarketingCard extraClass={styles.selectionCard}>
        <MarketingCardHeading
          showDivider={false}
          title="Select an account to import data to Flow"
          subtitle="Please select the account with product data (product IDs, titles, descriptions) you would like to import to Flow to use as the product data source when generating localized product feeds."
        />
        <SelectableTable
          items={accounts}
          id={(
            account: io.flow.internal.v0.models.MarketingGatewayGoogleAccountSummary,
          ): number => account.account_id}
          selected={selectedAccountId}
          onChange={({ account_id }): void => setSelectedAccountId(account_id)}
          isSelectable={(account): boolean => !!account.domestic_feeds_count}
          columns={{
            name: 'Account Name',
            account_id: 'Account ID',
          }}
        />

        {maybeRenderErrorMessage()}
        <MarketingCardActions showDivider={false}>
          <Button onClick={cancelAccountSelection}>Cancel</Button>
          <Button intent="primary" onClick={handleNextClick} disabled={!selected}>Complete Connection to Google</Button>
        </MarketingCardActions>
        {showConnectionConfirmDialog && (
          <ConnectionConfirmationDialog
            isOpen
            onClick={handleConnectConfirmClose}
          />
        )}
      </MarketingCard>
    </MarketingPage>
  );
};

SelectGoogleAccount.displayName = 'SelectGoogleAccount';

export default SelectGoogleAccount;
