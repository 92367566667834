import { createSelector } from 'reselect';
import { RootState } from '../../stores/types';

export const getCheckoutConfigurationState = (state: RootState) => state.checkoutConfiguration;

export const getCheckoutConfigurationIds = createSelector(
  getCheckoutConfigurationState,
  (state) => (state.configurations.map((config) => config.id)),
);

export const getAnalyticsById = (id: string) => createSelector(
  getCheckoutConfigurationState,
  (state) => {
    const config = state.configurations.find((_) => _.id === id);
    if (config != null) return config.analytics;
    return undefined;
  },
);

export const getBehaviorById = (id: string) => createSelector(
  getCheckoutConfigurationState,
  (state) => {
    const config = state.configurations.find((_) => _.id === id);
    if (config != null) return config.behavior;
    return undefined;
  },
);

export const getSettingsById = (id: string) => createSelector(
  getCheckoutConfigurationState,
  (state) => {
    const config = state.configurations.find((_) => _.id === id);
    if (config != null) return config.settings;
    return undefined;
  },
);

export const getDomainById = (id: string) => createSelector(
  getCheckoutConfigurationState,
  (state) => {
    const config = state.configurations.find((_) => _.id === id);
    if (config != null) return config.domain;
    return undefined;
  },
);
