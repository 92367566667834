import { Ellipsis } from '@flowio/react-icons';
import {
  Dropdown, DropdownMenu, DropdownItem, DropdownDivider,
} from '@flowio/react-dropdown';
import React from 'react';
import { TableRow, TableRowColumn } from '@flowio/react-table';
import { IconButton } from '@flowio/react-button';
import ContentStatus from '../../../constants/ContentStatus';
import OptinMessageStatus from '../../OptinMessageStatus';
import OptinMessageTarget from '../../OptinMessageTarget';
import { OptinMessageItemProps } from '../types';

function stopPropagation(event: React.MouseEvent) {
  event.stopPropagation();
}

const OptinMessageItem: React.FC<OptinMessageItemProps> = ({
  currentIndex,
  optinAttribute,
  organizationId,
  onActivate,
  onArchive,
  onRemove,
  onViewDetails,
}) => {
  const activate = (): void => {
    onActivate(organizationId, optinAttribute);
  };

  const archive = (): void => {
    onArchive(organizationId, optinAttribute);
  };

  const remove = (): void => {
    onRemove(organizationId, optinAttribute);
  };

  const viewDetails = (): void => {
    onViewDetails(organizationId, optinAttribute);
  };

  const handleClick = (): void => {
    viewDetails();
  };

  const handleViewDetailsSelection = (): void => {
    viewDetails();
  };

  const handleActivateSelection = (): void => {
    activate();
  };

  const handleArchiveSelection = (): void => {
    archive();
  };

  const handleDeleteSelection = (): void => {
    remove();
  };

  return (
    <TableRow
      selectable
      onClick={handleClick}
      striped={currentIndex % 2 === 1}
    >
      <TableRowColumn>
        {optinAttribute.name}
      </TableRowColumn>
      <TableRowColumn>
        {optinAttribute.optin_attribute_key}
      </TableRowColumn>
      <TableRowColumn>
        <OptinMessageTarget optinAttribute={optinAttribute} />
      </TableRowColumn>
      <TableRowColumn>
        <OptinMessageStatus optinAttribute={optinAttribute} />
      </TableRowColumn>
      <TableRowColumn textAlign="right">
        <Dropdown
          trigger={<IconButton size="small" icon={Ellipsis} />}
          onClick={stopPropagation}
        >
          <DropdownMenu>
            <DropdownItem content="View Details" onSelect={handleViewDetailsSelection} />
            <DropdownDivider />
            {(optinAttribute.status === ContentStatus.ARCHIVED) && (
            <DropdownItem content="Unarchived" onSelect={handleActivateSelection} />
            )}
            {(optinAttribute.status === ContentStatus.LIVE) && (
            <DropdownItem content="Archive" onSelect={handleArchiveSelection} />
            )}
            {(optinAttribute.status === ContentStatus.DRAFT) && (
            <DropdownItem content="Activate" onSelect={handleActivateSelection} />
            )}
            {(optinAttribute.status === ContentStatus.DRAFT) && (
            <DropdownItem content="Delete" onSelect={handleDeleteSelection} />
            )}
          </DropdownMenu>
        </Dropdown>
      </TableRowColumn>
    </TableRow>
  );
};

OptinMessageItem.displayName = 'OptinMessageItem';

export default OptinMessageItem;
