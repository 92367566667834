import { createSelector } from 'reselect';
import get from 'lodash/get';
import getReturnPolicyWorksheet from './getReturnPolicyWorksheet';

/**
 * Returns whether return policy worksheet is currently visible.
 * @returns {Boolean}
 */
const getIsReturnPolicyWorksheetVisible = createSelector(
  getReturnPolicyWorksheet,
  (worksheet) => get(worksheet, 'visibility') === 'visible',
);

export default getIsReturnPolicyWorksheetVisible;
