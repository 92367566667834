import { withFetch } from '@flowio/redux-fetch';
import get from 'lodash/get';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { compose } from 'redux';
import { ExperiencesGetByKeyResponse } from '@flowio/api-sdk';
import { RootState, ThunkDispatcher } from '../../../../../stores/types';
import { PropType } from '../../../../../types';
import { LegacyResponse } from '../../../../../utilities/clients/types/server';
import setRedirectUrl from '../../../../console/actions/setRedirect';
import { fetchExperience, fetchExperiences } from '../../../../experience/actions';
import { getCurrentExperienceCountry, getExperiences } from '../../../../experience/selectors';
import { fetchOrganization, getOrganization } from '../../../../organization';
import {
  fetchCountries,
} from '../../../../reference/actions';
import {
  fetchExperiment,
  fetchExperimentResults,
  toggleEndExperimentModal,
  toggleImplementVariantModal,
  updateExperimentStatus,
} from '../../../actions';
import {
  getCurrentExperiment,
  getCurrentExperimentResults,
  getVariants,
  isEndExperimentModalOpen,
} from '../../../selectors';
import ExperimentDetails from '../components/experiment-details';
import { DispatchProps, OwnProps, StateProps } from '../types';
import { getVariantKey } from '../utilities/checkVariant';

type ResponseType = PropType<ExperiencesGetByKeyResponse, 'body'>;

const getAsyncState = (
  dispatch: ThunkDispatcher,
  getState: () => RootState,
  ownProps: OwnProps,
): Promise<void> => {
  const { organization, experimentKey } = ownProps.params;
  return Promise.all([
    dispatch(fetchExperiences(organization)),
    dispatch(fetchOrganization(organization)),
    dispatch(fetchExperiment(organization, experimentKey)),
    dispatch(fetchCountries()),
  ]).then(() => {
    const experiment = getCurrentExperiment(getState());
    const firstVariant = experiment.variants[0];
    const key = getVariantKey(firstVariant);
    if (experiment.status === 'draft') {
      return Promise.resolve();
    }
    const startDate = get(ownProps, 'location.query.start', experiment.started_at);
    const endDate = get(ownProps, 'location.query.end', experiment.ended_at);
    const queryParams: { start: string; end?: string } = {
      start: startDate,
      // Will re-enable once monthly and weekly are ready
      // timeseries: timeseriesType,
    };
    const promises: Promise<void | LegacyResponse<ResponseType>>[] = [
      dispatch(fetchExperimentResults(organization, experimentKey, queryParams))];

    if (key) {
      promises.push(dispatch(fetchExperience(organization, key)));
    }
    if (experiment.status === 'ended') {
      queryParams.end = endDate;
    }
    return Promise.all(promises).then(() => Promise.resolve());

    // Fetch experience by variant key to work out the correct region for implementing variant model
  }).catch(() => {
    dispatch(setRedirectUrl({
      url: `/${organization}/experiments`,
      toastMessage: `The experiment ${experimentKey} could not be found `,
    }));
  });
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = (
  state: RootState,
  ownProps: OwnProps,
) => ({
  startResultsDate: get(ownProps, 'location.query.start'),
  isEndExperimentModalOpen: isEndExperimentModalOpen(state),
  country: getCurrentExperienceCountry(state),
  variants: getVariants(state),
  endResultsDate: get(ownProps, 'location.query.end'),
  // Will re-enable once monthly and weekly are ready
  // timeseriesType: get(ownProps, 'location.query.timeseries'),
  organization: getOrganization(state),
  experiment: getCurrentExperiment(state) as io.flow.internal.v0.unions.Experiment,
  experimentResults: getCurrentExperimentResults(state),
  experiences: getExperiences(state),
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch: ThunkDispatcher,
  ownProps: OwnProps,
): DispatchProps => ({
  onEndExperiment(key): void {
    const { organization } = ownProps.params;
    dispatch(updateExperimentStatus(organization, key, 'ended'));
  },
  updateExperimentResults(startDate, endDate): void {
    const { organization, experimentKey } = ownProps.params;
    const queryParams = {
      start: startDate,
      end: endDate,
    };
    dispatch(fetchExperimentResults(organization, experimentKey, queryParams));
  },
  toggleEndExperimentModal(winningVariant): void {
    if (winningVariant) {
      dispatch(toggleImplementVariantModal(true));
    } else {
      dispatch(toggleEndExperimentModal());
    }
  },
});

export default compose(
  withFetch(getAsyncState),
  connect(mapStateToProps, mapDispatchToProps),
)(ExperimentDetails);
