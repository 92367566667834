import React from 'react';
import { Box } from '@flowio/react-box';
import CopyButton from '../../utilities/InfoCopyButton';
import * as styles from '../order-details/order-details.styles';

const displayName = 'OrderCustomer';

interface OrderCustomerProps {
  customer: io.flow.v0.models.OrderCustomer;
}

const OrderCustomerComponent: React.FC<OrderCustomerProps> = ({
  customer: {
    email,
    name,
    phone,
  },
}) => (
  <div className="order-details">
    <Box alignContent="center" className={styles.row}>
      <div className={styles.field}>
        <span className={styles.label}>Name</span>
        <span className={styles.value}>
          {name && (
            <span>
              {name.first}
              {' '}
              {name.last}
            </span>
          )}
          <CopyButton value={name && `${name.first} ${name.last}`} />
        </span>
      </div>

      <div className={styles.field}>
        <span className={styles.label}>Email</span>
        <span className={styles.value}>
          {email}
        </span>
        <CopyButton value={email} />
      </div>
    </Box>

    <Box alignContent="center" className={styles.row}>
      <div className={styles.field}>
        <span className={styles.label}>Phone</span>
        <span className={styles.value}>
          {phone}
        </span>
        <CopyButton value={phone} />
      </div>
    </Box>
  </div>
);

OrderCustomerComponent.displayName = displayName;

export default OrderCustomerComponent;
