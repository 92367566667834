import React from 'react';
import type { FieldRenderProps } from 'react-final-form';
import { TextInputField } from '@flowio/react-text-input';

type Props = FieldRenderProps<string> & React.ComponentProps<typeof TextInputField> & {
  showFeedback: boolean
};

const TextField: React.FC<Props> = ({
  input,
  meta,
  showFeedback = true,
  ...textFieldProps
}) => {
  const showError = (meta.error || meta.submitError) && meta.touched;

  return (
    <TextInputField
      {...input}
      {...textFieldProps}
      onValueChange={input.onChange}
      intent={showError ? 'negative' : 'neutral'}
      feedback={showFeedback && showError ? (meta.error || meta.submitError) : undefined}
    />
  );
};

export default TextField;
