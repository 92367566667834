/**
 * Returns whether the specified parameter is a React SyntheticEvent object.
 */
export default function isEvent(candidate: Event | unknown): candidate is Event {
  return Boolean(
    candidate
    && (candidate as Event).stopPropagation !== undefined
    && (candidate as Event).preventDefault !== undefined,
  );
}
