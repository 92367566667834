import React from 'react';
import PropTypes from 'prop-types';
import ApiPropTypes from '@flowio/api-prop-types';
import {
  Table,
  TableHead,
  TableRow,
  TableHeadColumn,
  TableBody,

} from '@flowio/react-table';

import PendingUsersItem from '../pending-users-item/pending-users-item';

const PendingUsersList = ({ users, onRequestApproveUser, onRequestDeclineUser }) => (
  <Table fixed striped displayDensity="cozy">
    <TableHead>
      <TableRow>
        <TableHeadColumn>
          Email
        </TableHeadColumn>
        <TableHeadColumn />
      </TableRow>
    </TableHead>
    <TableBody>
      {users.map((user) => (
        <PendingUsersItem
          key={user.id}
          user={user}
          onItemAcceptClick={onRequestApproveUser}
          onItemDeclineClick={onRequestDeclineUser}
        />
      ))}
    </TableBody>
  </Table>
);

PendingUsersList.displayName = 'PendingUsersList';

PendingUsersList.propTypes = {
  users: PropTypes.arrayOf(ApiPropTypes.user).isRequired,
  onRequestApproveUser: PropTypes.func.isRequired,
  onRequestDeclineUser: PropTypes.func.isRequired,
};

export default PendingUsersList;
