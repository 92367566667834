import { RootActionTypes, ThunkResult, ThunkExtraArguments } from '../../../stores/types';
import { ApiError } from '../../../utilities/clients/api-error';
import { ActionTypes } from '../constants';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';

export function fetchCatalogItemByNumberRequest(): RootActionTypes {
  return {
    type: ActionTypes.FETCH_CATALOG_ITEM_REQUEST,
  };
}

export function fetchCatalogItemByNumberSuccess(item: io.flow.v0.models.Item): RootActionTypes {
  return {
    type: ActionTypes.FETCH_CATALOG_ITEM_SUCCESS,
    payload: item,
  };
}

export function fetchCatalogItemByNumberFailure(error?: FormattedErrorMessages): RootActionTypes {
  return {
    type: ActionTypes.FETCH_CATALOG_ITEM_FAILURE,
    payload: error,
  };
}

export default function fetchCatalogItemByNumber(
  organization: string,
  number: string,
): ThunkResult<Promise<void>> {
  return function fetchCatalogItemByNumberEffect(
    dispatch,
    getState,
    extra: ThunkExtraArguments,
  ): Promise<void> {
    dispatch(fetchCatalogItemByNumberRequest());

    return extra.api(getState()).items.getByNumber({
      organization,
      number,
    }).then((response) => {
      if (response.ok) {
        dispatch(fetchCatalogItemByNumberSuccess(response.body));
        return;
      }

      const error = new ApiError(response);
      dispatch(fetchCatalogItemByNumberFailure(formatErrorResponseV2(response)));
      throw error;
    });
  };
}
