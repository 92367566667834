import { createSelector } from 'reselect';
import identity from 'lodash/identity';
import property from 'lodash/property';
import { LogisticsRootState } from '../types/state';
import { RootState } from '../../../stores/types';

export default createSelector<RootState, RootState, LogisticsRootState>(
  identity,
  property('logistics'),
);
