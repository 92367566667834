import { css } from '@emotion/css';

export const strategy = css`
  display: block;
`;

export const iconWrapper = css`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  box-sizing: border-box;
  border-radius: 50%;
`;

export const label = css`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  padding: 24px;
  border: solid 1px #e6e6e6;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #fff;
  color: #808080;
  cursor: pointer;
`;

export const input = css`
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;

  &:checked ~ .label {
    border-width: 2px;
    border-color: #479aff;
    color: #479aff;

    & .iconWrapper {
      background-color: #479aff;
    }
  }
`;

export const icon = css`
  width: 60px;
  height: 60px;
`;

export const heading = css`
  display: block;
  margin: 0.5rem 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
`;

export const tagline = css`
  display: block;
  font-size: 14px;
  text-align: center;
  line-height: normal;
`;
