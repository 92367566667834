import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { submit, isSubmitting } from 'redux-form';

import { DialogName, FormName } from '../constants';
import { createToast } from '../../console/actions';
import { deleteDialog } from '../actions';
import { getDialogProperty } from '../selectors';
import TierDeleteConfirmation from '../components/TierDeleteConfirmation';
import { RootState, ThunkDispatcher } from '../../../stores/types';
import { TierDeleteConfirmationDispatchProps, TierDeleteConfirmationStateProps } from '../types/components';

const dialogName = DialogName.TIER_DELETE_CONFIRMATION;
const formName = FormName.TIER_DELETE_FORM;

function handleSuccess() {
  return function handleSuccessSideEffects(dispatch: ThunkDispatcher) {
    dispatch(deleteDialog({ type: dialogName }));
    dispatch(createToast({ content: 'Shipping tier has been deleted' }));
  };
}

function handleCancel() {
  return deleteDialog({ type: dialogName });
}

function handleOk() {
  return submit(formName);
}

const mapStateToProps = createStructuredSelector<RootState, TierDeleteConfirmationStateProps>({
  initialValues: getDialogProperty(dialogName, 'initialValues'),
  isOpen: getDialogProperty(dialogName, 'open'),
  isSubmitting: isSubmitting(formName),
});

const mapDispatchToProps = (dispatch: ThunkDispatcher) => bindActionCreators({
  onCancel: handleCancel,
  onOk: handleOk,
  onSuccess: handleSuccess,
}, dispatch);

export default compose<React.FC>(
  connect<
  TierDeleteConfirmationStateProps, TierDeleteConfirmationDispatchProps, {}, RootState
  >(mapStateToProps, mapDispatchToProps),
)(TierDeleteConfirmation);
