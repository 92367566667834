import negate from 'lodash/negate';
import matches from 'lodash/matches';
import { ActionTypes } from '../constants';
import upsertBy from '../../../utilities/arrays/upsertBy';
import { RootActionTypes } from '../../../stores/types';
import { OrderState } from '../types/state';
import { SelectedOrderItem } from '../types/components';

const initialState: OrderState = {
  currentSearchTerm: undefined,
  currentOrderNumber: undefined,
  currentOrder: undefined,
  selectedOrderItems: [], // { number, quantity, index }
};

function deselectItem(state: OrderState, itemToDeselect: string, index: number) {
  return {
    ...state,
    selectedOrderItems: state.selectedOrderItems
      .filter(negate(matches({ number: itemToDeselect, index }))),
  };
}

function selectItems(state: OrderState, itemDetail: SelectedOrderItem) {
  return {
    ...state,
    selectedOrderItems: state.selectedOrderItems
      .filter(negate(matches({ number: itemDetail.number, index: itemDetail.index })))
      .concat([itemDetail]),
  };
}

function selectAllItems(state: OrderState) {
  const items = state.currentOrder
    ? state.currentOrder.items
      .map((item, idx) => ({
        number: item.number,
        index: idx,
        quantity: item.quantity,
      }))
    : state.selectedOrderItems;
  return {
    ...state,
    selectedOrderItems: items,
  };
}

export default function (previousState = initialState, action: RootActionTypes): OrderState {
  switch (action.type) {
    case ActionTypes.SCAN_ITEM:
      return {
        ...previousState,
        selectedOrderItems: upsertBy((
          _value: any,
          item: SelectedOrderItem,
        ) => item.number === action.payload.number
        && item.index === action.payload.index, {
          number: action.payload.number,
          quantity: action.payload.quantity,
          index: action.payload.index,
        }, previousState.selectedOrderItems),
      };
    case ActionTypes.CHANGE_ITEM_QUANTITY:
      if (!action.payload.quantity) {
        return deselectItem(previousState, action.payload.number, action.payload.index);
      }

      if (previousState.selectedOrderItems.filter(matches(
        {
          number: action.payload.number,
        },
      )).length < 1) {
        return selectItems(previousState, action.payload);
      }

      return {
        ...previousState,
        selectedOrderItems: previousState.selectedOrderItems.map((selectedOrderItem) => ({
          ...selectedOrderItem,
          quantity: (selectedOrderItem.number === action.payload.number
          && selectedOrderItem.index === action.payload.index)
            ? action.payload.quantity
            : selectedOrderItem.quantity,
        })),
      };
    case ActionTypes.CLEAR_CURRENT_ORDER:
      return {
        ...previousState,
        currentOrderNumber: undefined,
        currentOrder: undefined,
        selectedOrderItems: [],
      };
    case ActionTypes.DESELECT_ITEM:
      return deselectItem(previousState, action.payload.number, action.payload.index);
    case ActionTypes.CLEAR_CURRENT_ITEMS:
      return {
        ...previousState,
        selectedOrderItems: [],
      };
    case ActionTypes.SELECT_ITEM:
      return selectItems(previousState, action.payload);
    case ActionTypes.SET_CURRENT_ORDER:
      return {
        ...previousState,
        selectedOrderItems: [],
        currentOrderNumber: action.payload.orderNumber,
      };
    case ActionTypes.CHANGE_SEARCH_VALUE:
      return {
        ...previousState,
        currentSearchTerm: action.payload,
      };
    case ActionTypes.READ_ORDER_BY_NUMBER_SUCCESS:
    case ActionTypes.READ_ORDER_BY_IDENTIFIER_SUCCESS:
      return {
        ...previousState,
        selectedOrderItems: [],
        currentOrder: action.payload,
      };
    case ActionTypes.SELECT_ALL_ITEMS:
      return selectAllItems(previousState);
    default:
      return previousState;
  }
}
