import debugInit from 'debug';
import { v1 as uuidV1 } from 'uuid';
import get from 'lodash/get';

const debug = debugInit('console:client');

function jwtAuthorized(state) {
  let token = state;

  if (typeof token !== 'string') {
    token = get(state, 'defaultReducer.user.auth');
  }

  if (token) {
    return { type: 'jwt', value: token };
  }

  return undefined;
}

/**
 * Proxy the api to add wrapper function to handle application state. Uses state
 * to set auth and request-id headers.
 *
 * Changes syntax of api from
 *
 * `api.experiences.get()`
 *
 * to
 *
 * `api.experiences(state).get`
 *
 * It's assumed that `state` is provided from the `callAPI` function from
 * `redux-async-action`.
 *
 */
export default class ApiProxy {
  constructor(client) {
    this.client = client;

    Object.keys(client).forEach((resource) => {
      this[resource] = (state) => {
        let resourceRef = client[resource];

        if (state) {
          resourceRef = resourceRef.withAuth(jwtAuthorized(state));
        }

        return resourceRef.withHeaders({
          'X-Flow-Request-Id': `con${uuidV1()}`,
        });
      };
    });

    Object.keys(client)
      .filter((k) => !!client[k].on)
      .forEach((resource) => {
        client[resource].on('request', (req) => {
          const method = req.method || 'GET';
          let msg = `[request] ${method} ${req.url} \n Request Id: ${req.requestId}`;
          if (req.params) {
            msg += `\n Params: ${JSON.stringify(req.params, null, 2)}`;
          }
          debug(msg);
        });
        client[resource].on('response', (res) => {
          let msg = `[response] Request Id: ${res.requestId}, status[${res.status}]`;
          let {
            body,
          } = res;

          // TODO: Parse JSON depending on `res.type` when available in provided client(s)
          try {
            body = JSON.stringify(JSON.parse(res.body), null, 2);
          } catch (e) {
            // deliberately nothing.
          }
          if (res.body) {
            msg += `\n Body: ${body}`;
          }
          debug(msg);
        });
      });
  }
}
