import keyBy from 'lodash/keyBy';
import update from 'immutability-helper';

import { ActionTypes } from '../constants';
import { ExperiencesState } from '../types/state';
import { RootActionTypes } from '../../../stores/types';

const initialState: ExperiencesState = {};

export default function (state = initialState, action: RootActionTypes) {
  switch (action.type) {
    case ActionTypes.FETCH_EXPERIENCES_SUCCESS:
      return update(state, {
        $merge: keyBy(action.payload, 'id'),
      });
    default:
      return state;
  }
}
