import { connect, MapStateToProps } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RootActionTypes, RootState } from '../../../../../stores/types';
import { showDeleteFeedConfirmationDialog } from '../../../actions';
import getFacebookTokenSummaryFromChannel from '../../../selectors/getFacebookTokenSummaryFromChannel';
import {
  DeleteFeedConfirmationDialogProps,
  MarketingActions,
} from '../../../types';
import FacebookFeedsTable from '../components/FacebookFeedsTable';
import { DispatchProps, OwnProps, StateProps } from '../types';

const handleDelete = (
  feed: DeleteFeedConfirmationDialogProps,
): MarketingActions => showDeleteFeedConfirmationDialog(feed);

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = (
  state: RootState,
): StateProps => ({
  accessTokenSummary: getFacebookTokenSummaryFromChannel(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<RootActionTypes>,
): DispatchProps => bindActionCreators({
  onDeleteFeedClick: handleDelete,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FacebookFeedsTable);
