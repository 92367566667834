import { connect } from 'react-redux';
import { compose } from 'redux';
import { withFetch } from '@flowio/redux-fetch';
import SelectFacebookCatalog from '../components/SelectFacebookCatalog';
import { getFacebookConnectionCatalogs, getFacebookConnectionBusinesses } from '../../../selectors';

import {
  fetchFacebookCatalogs,
  goToFeedsPage,
  showFacebookCountryFeedDialog,
  toggleDisconnectPlatformDialog,
  completeConnection,
} from '../../../actions';
import { StateProps, DispatchProps, OwnProps } from '../types';
import { RootState, ThunkDispatcher, RootActionTypes } from '../../../../../stores/types';
import fetchFacebookBusinesses from '../../../actions/fetchFacebookBusinesses';
import { createToast } from '../../../../console/actions';

const getAsyncState = (
  dispatch: ThunkDispatcher,
  _: () => RootState,
  props: OwnProps,
): Promise<void> => {
  const { params: { organization } } = props;
  return dispatch(fetchFacebookBusinesses({ organizationId: organization }));
};

const mapDispatchToProps = (
  dispatch: ThunkDispatcher,
): DispatchProps => ({
  confirmBusiness: (
    organizationId: string,
    businessId: number,
  ): Promise<void> => dispatch(fetchFacebookCatalogs({
    organizationId,
    businessId,
  })),
  onCancelAuth: (): RootActionTypes => dispatch(
    toggleDisconnectPlatformDialog(true, { platformId: 'facebook' }),
  ),
  confirmCatalog: (
    organizationId: string,
    catalogId: number,
  ): Promise<void> => dispatch(completeConnection({
    organizationId,
    accountId: catalogId,
    platformId: 'facebook',
  }))
    .then(() => {
      dispatch(goToFeedsPage({ organizationId, platformId: 'facebook', accountId: catalogId }));
      dispatch(showFacebookCountryFeedDialog({ organizationId, catalogId }));
    })
    .catch(() => {
      dispatch(
        createToast({
          intent: 'negative',
          content: 'Sorry something went wrong, please try again',
        }),
      );
    }),
});

const mapStateToProps = (state: RootState): StateProps => ({
  catalogs: getFacebookConnectionCatalogs(state),
  businesses: getFacebookConnectionBusinesses(state),
});

export default compose(
  withFetch(getAsyncState),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(SelectFacebookCatalog);
