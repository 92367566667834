enum ConsoleActionTypes {
  PUSH_ERROR = 'CONSOLE.PUSH_ERROR',
  RESET_ERRORS = 'CONSOLE.RESET_ERRORS',
  REDIRECT = 'CONSOLE.REDIRECT',
  CREATE_TOAST = 'CONSOLE.CREATE_TOAST',
  SET_REDIRECT_URL = 'CONSOLE.SET_REDIRECT_URL',
  CHECK_FEATURE_VALUES_REQUEST = 'CONSOLE.CHECK_FEATURE_VALUES_REQUEST',
  CHECK_FEATURE_VALUES_SUCCESS = 'CONSOLE.CHECK_FEATURE_VALUES_SUCCESS',
  CHECK_FEATURE_VALUES_FAILURE = 'CONSOLE.CHECK_FEATURE_VALUES_FAILURE',
  INVALIDATE_FEATURE_VALUES = 'CONSOLE.INVALIDATE_FEATURE_VALUES',
  DELETE_TOAST = 'CONSOLE.DELETE_TOAST',
}
export default ConsoleActionTypes;
