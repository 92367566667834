export enum ActionTypes {
  FIND_HARMONIZATION_CODES_REQUEST = 'FIND_HARMONIZATION_CODES_REQUEST',
  FIND_HARMONIZATION_CODES_SUCCESS = 'FIND_HARMONIZATION_CODES_SUCCESS',
  FIND_HARMONIZATION_CODES_FAILURE = 'FIND_HARMONIZATION_CODES_FAILURE',
  FETCH_HARMONIZATION_STATISTICS_REQUEST = 'FETCH_HARMONIZATION_STATISTICS_REQUEST',
  FETCH_HARMONIZATION_STATISTICS_SUCCESS = 'FETCH_HARMONIZATION_STATISTICS_SUCCESS',
  FETCH_HARMONIZATION_STATISTICS_FAILURE = 'FETCH_HARMONIZATION_STATISTICS_FAILURE',
  FETCH_HARMONIZATION_SUMMARIES_REQUEST = 'FETCH_HARMONIZATION_SUMMARIES_REQUEST',
  FETCH_HARMONIZATION_SUMMARIES_SUCCESS = 'FETCH_HARMONIZATION_SUMMARIES_SUCCESS',
  FETCH_HARMONIZATION_SUMMARIES_FAILURE = 'FETCH_HARMONIZATION_SUMMARIES_FAILURE',
  FETCH_ITEM_SUMMARIES_REQUEST = ' FETCH_ITEM_SUMMARIES_REQUEST',
  FETCH_ITEM_SUMMARIES_SUCCESS = 'FETCH_ITEM_SUMMARIES_SUCCESS',
  FETCH_ITEM_SUMMARIES_FAILURE = 'FETCH_ITEM_SUMMARIES_FAILURE',
  RESET_HS6_PAGINATION = 'RESET_HS6_PAGINATION',
  EXPORT_HS6_REQUEST = 'EXPORT_HS6_REQUEST',
  EXPORT_HS6_SUCCESS = 'EXPORT_HS6_SUCCESS',
  EXPORT_HS6_FAILURE = 'EXPORT_HS6_FAILURE',
  NEXT_HARMONIZATION_HS6_CODES_PAGE = 'NEXT_HARMONIZATION_HS6_CODES_PAGE',
  FETCH_HARMONIZATION_SUMMARIES_BY_PAGE_REQUEST = 'FETCH_HARMONIZATION_SUMMARIES_BY_PAGE_REQUEST',
  FETCH_HARMONIZATION_SUMMARIES_BY_PAGE_SUCCESS = 'FETCH_HARMONIZATION_SUMMARIES_BY_PAGE_SUCCESS',
  FETCH_HARMONIZATION_SUMMARIES_BY_PAGE_FAILURE = 'FETCH_HARMONIZATION_SUMMARIES_BY_PAGE_FAILURE',

}

export default ActionTypes;
