enum ActionType {
  CreateReturnPolicyRequest = 'CreateReturnPolicyRequest',
  CreateReturnPolicySuccess = 'CreateReturnPolicySuccess',
  CreateReturnPolicyFailure = 'CreateReturnPolicyFailure',
  DeleteReturnPolicyFailure = 'DeleteReturnPolicyFailure',
  DeleteReturnPolicyRequest = 'DeleteReturnPolicyRequest',
  DeleteReturnPolicySuccess = 'DeleteReturnPolicySuccess',
  FetchReturnPoliciesFailure = 'FetchReturnPoliciesFailure',
  FetchReturnPoliciesRequest = 'FetchReturnPoliciesRequest',
  FetchReturnPoliciesSuccess = 'FetchReturnPoliciesSuccess',
  HideReturnPolicyDeleteConfirmation = 'HideReturnPolicyDeleteConfirmation',
  HideReturnPolicyWorksheet = 'HideReturnPolicyWorksheet',
  ShowReturnPolicyDeleteConfirmation = 'ShowReturnPolicyDeleteConfirmation',
  ShowReturnPolicyWorksheetFailure = 'ShowReturnPolicyWorksheetFailure',
  ShowReturnPolicyWorksheetRequest = 'ShowReturnPolicyWorksheetRequest',
  ShowReturnPolicyWorksheetSuccess = 'ShowReturnPolicyWorksheetSuccess',
  SubmitReturnPolicyForm = 'SubmitReturnPolicyForm',
  SubmitReturnPolicyFormError = 'SubmitReturnPolicyFormError',
  UpdateReturnPolicyRequest = 'UpdateReturnPolicyRequest',
  UpdateReturnPolicySuccess = 'UpdateReturnPolicySuccess',
  UpdateReturnPolicyFailure = 'UpdateReturnPolicyFailure',
}

export default ActionType;
