import StatusTypes from '../constants/status-types';
import { verifySDKSetup } from './utils';

type LogoutResponse = {
  success: boolean;
  status: facebook.LoginStatus;
};

const logout = (): Promise<LogoutResponse> => {
  try {
    verifySDKSetup();
  } catch (error) {
    return Promise.reject(error);
  }

  return new Promise((resolve) => {
    window.FB.logout((response: facebook.StatusResponse) => {
      const { status } = response;
      resolve({
        status,
        success: status !== StatusTypes.connected,
      });
    });
  });
};

export default logout;
