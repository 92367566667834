import update from 'immutability-helper';
import ActionTypes from '../constants/action-types';
import { RootActionTypes } from '../../../stores/types';
import { ExperienceState } from '../types';

type State = ExperienceState['entities']['errors'];

const initialState: State = {};

export default function (state: State = initialState, action: RootActionTypes): State {
  switch (action.type) {
    case ActionTypes.GET_ITEM_PRICE_DETAILS_REQUEST:
      return update(state, { pricing: { $set: undefined } });
    case ActionTypes.CREATE_MARGIN_FAILURE:
    case ActionTypes.UPDATE_MARGIN_FAILURE:
      return update(state, { margins: { $set: action.payload } });

    case ActionTypes.GET_EXPERIENCE_PRICING_FAILURE:
    case ActionTypes.GET_MARGINS_FAILURE:
    case ActionTypes.GET_MARGIN_FAILURE:
    case ActionTypes.DELETE_MARGIN_FAILURE:
    case ActionTypes.UPDATE_PRICING_FAILURE:
    case ActionTypes.GET_ITEM_SUGGESTIONS_FAILURE:
    case ActionTypes.GET_ITEM_PRICE_DETAILS_FAILURE:
      return update(state, { pricing: { $set: action.payload } });

    case ActionTypes.UPDATE_EXPERIENCE_STATUS_FAILURE:
      return update(state, { status: { $set: action.payload } });

    case ActionTypes.CREATE_EXPERIENCE_FAILURE:
      return update(state, { experience_form: { $set: action.payload } });

    case ActionTypes.GET_EXPERIENCE_FAILURE:
    case ActionTypes.FETCH_EXPERIENCE_FAILURE:
      return update(state, { experience: { $set: action.payload } });
    default:
      return state;
  }
}
