import { createSelector } from 'reselect';
import get from 'lodash/get';

import getFeatures from './getFeatures';

export const getIsAddFeatureWorksheetVisible = createSelector(
  getFeatures,
  (features) => get(features, 'dialogs.addFeatureWorksheet.visibility') === 'visible',
);

export default getIsAddFeatureWorksheetVisible;
