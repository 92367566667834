import moment from 'moment-timezone';
import { FormGroup } from '@flowio/react-form-group';
import noop from 'lodash/noop';
import React from 'react';
import { Field, Form, FormSubmitHandler } from 'redux-form';
import classNames from 'classnames';
import { TextField, SelectField } from '../../../../../components/ReduxFormFieldAdapters';
import OrderExportsForm from '../../order-export-form/components/order-export-form';
import Alert from '../../../../../components/alert/alert';
import * as styles from '../../exports/exports.styles';
import presence from '../../../../../utilities/validators/presence';
import { FormattedErrorMessages } from '../../../../../utilities/format-error-response-v2';
import { ExportsFormValues } from '../types';

const momentTimezones = moment.tz.names().map((tz) => moment().tz(tz).zoneAbbr());
const timezones = [...new Set(momentTimezones)];

const exportTypeOptions = [
  { content: 'Order', value: 'order_export_type' },
  { content: 'Unharmonized Item', value: 'unharmonized_item_export_type' },
];

/* validators */

const handleOrgValidation = [
  presence({ allowEmpty: false, message: 'An organization query is required' }),
];

const handleFileTypeValidation = [
  presence({ allowEmpty: false, message: 'A File Type is required' }),
];

const handleHourValidation = [
  presence({ allowEmpty: false, message: 'Hour Required' }),
];

const handleMinutesValidation = [
  presence({ allowEmpty: false, message: 'Minute required' }),
];

const handleFormatValidation = [
  presence({ allowEmpty: false, message: 'Time format required' }),
];

const handleTZValidation = [
  presence({ allowEmpty: false, message: 'Timezone required' }),
];

interface Props {
  error?: FormattedErrorMessages;
  handleSubmit: FormSubmitHandler<ExportsFormValues, {}, string>;
  hasSubmitted: boolean;
  orgQuery: string;
  types: ExportsFormValues['types'];
}

const ScheduledExportsForm: React.FC<Props> = (props) => {
  const {
    handleSubmit,
    types,
    hasSubmitted,
    orgQuery,
    error,
  } = props;

  return (

    <div className={styles.exportsForm}>
      {error && error.messages && error.messages.map((msg) => (
        <div>
          <Alert type="failure">
            {msg.message}
          </Alert>
        </div>
      ))}
      {hasSubmitted ? (
        <p>
          Your request has been submitted successfully. We will send an
          email notification to
          {' '}
          <strong>{orgQuery}</strong>
          {' '}
          once the export
          CSV file is created.
        </p>
      ) : null }

      <Form onSubmit={handleSubmit}>
        <FormGroup className={styles.formGroup} inline labelCols={4} labelSize="medium" labelFor="organization_q" labelText="Organization Query">
          <Field
            component={TextField}
            validate={handleOrgValidation}
            className="organization-q"
            name="organization_q"
            fluid
          />
        </FormGroup>
        <FormGroup className={styles.formGroup} inline labelCols={4} labelSize="medium" labelFor="file-type" labelText="File Type">
          <Field
            component={SelectField}
            name="types"
            hintText="File Type"
            valueKey="content"
            selection
            labelKey="content"
            className={styles.fileType}
            validate={handleFileTypeValidation}
            options={exportTypeOptions}
          />
        </FormGroup>

        {(types && types.value === 'order_export_type')
          ? (<OrderExportsForm />) : null }
        <FormGroup className={classNames(styles.formGroup, 'time')} inline labelCols={4} labelSize="medium" labelFor="time-dropdowns" labelText="Export Schedule">
          <Field
            component={SelectField}
            className={styles.timeDropdownWrapper}
            validate={handleHourValidation}
            selection
            name="hour"
            type="number"
            labelKey="label"
            valueKey="value"
            options={Array.from(Array(12), (_, index) => ({
              label: index,
              value: index,
            }))}
            hintText="Hour"
            min="1"
            max="59"
            step="1"
          />

          <Field
            component={SelectField}
            className={styles.timeDropdownWrapper}
            validate={handleMinutesValidation}
            selection
            name="minute"
            type="text"
            labelKey="label"
            valueKey="value"
            options={Array.from(Array(60), (_, index) => ({
              label: index,
              value: index,
            }))}
            hintText="Minute"
          />

          <Field
            component={SelectField}
            validate={handleFormatValidation}
            selection
            className={classNames([styles.timeDropdownWrapper, 'format'])}
            hintText="AM"
            name="timeFormat"
            labelKey="label"
            valueKey="value"
            options={['AM', 'PM'].map((format) => ({
              label: format,
              value: format,
            }))}
          />

          <Field
            component={SelectField}
            selection
            options={timezones.map((tz) => ({
              label: tz,
              value: tz,
            }))}
            className={classNames([styles.timeDropdownWrapper, 'tz'])}
            hintText="Time zone"
            validate={handleTZValidation}
            labelKey="label"
            valueKey="value"
            name="timezone"
          />
        </FormGroup>
      </Form>
    </div>
  );
};

ScheduledExportsForm.displayName = 'ScheduledExportsForm';

ScheduledExportsForm.defaultProps = {
  handleSubmit: noop,
  orgQuery: '',
  error: undefined,
  hasSubmitted: false,
  types: undefined,
};

export default ScheduledExportsForm;
