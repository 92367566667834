import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  reduxForm,
  formValueSelector,
  change,
  getFormSyncErrors,
  touch,
} from 'redux-form';
import FormNames from '../constants/FormNames';

import createRefund from '../actions/create-refund';
import clearRefundAmount from '../actions/clear-refund-amount';
import updateRefundAmount from '../actions/update-refund-amount';
import { getOrderDetail, getRefundObj, getAllocations } from '../selectors';
import { RootState, ThunkDispatcher } from '../../../stores/types';
import OrderRefundForm from '../components/OrderRefundDialog/OrderRefundForm';
import {
  RefundCalculationOptions,
  RefundDialogFormItems,
  OrderRefundPassedProps,
  OrderRefundFormDispatchProps,
  OrderRefundFormValues,
  OrderRefundStateProps,
  RefundAmountValues,
  RefundShippingValues,
  RefundTypeValues,
} from '../types';
import calculateRefundAmount from '../utilities/calculateRefundAmount';
import encodeItemNumber from '../utilities/encodeItemNumber';
import { getCurrencies } from '../../reference';

const formSelector = formValueSelector(FormNames.ORDER_REFUND_FORM);
const errorSelector = getFormSyncErrors(FormNames.ORDER_REFUND_FORM);

function submitForm(values: OrderRefundFormValues, dispatch: ThunkDispatcher): void {
  dispatch(createRefund({
    order_number: values.orderNumber,
    currency: values.orderCurrency,
    amount: values.refundAmount,
  }));
}

function mapStateToProps(state: RootState): OrderRefundStateProps {
  let refundObjToUse;
  const order: io.flow.v0.models.Order = getOrderDetail(state)?.order;
  const formItems: RefundDialogFormItems = {};
  order.items.forEach((item) => {
    formItems[encodeItemNumber(item.number)] = 0;
  });
  const refundObj = getRefundObj(state);

  if (Object.keys(refundObj).length < 1) {
    const allocations = getAllocations(state);
    refundObjToUse = calculateRefundAmount(
      order,
      {
        refundType: 'full',
        refundTax: true,
        refundDuty: true,
        refundShipping: true,
        refundTaxType: 'item',
        refundDutyType: 'item',
        refundShippingType: 'value',
        items: [],
      },
      allocations,
    );
  } else {
    refundObjToUse = refundObj;
  }

  return {
    currencies: getCurrencies(state),
    order,
    refundObj: refundObjToUse,
    formErrors: errorSelector(state),
    refundType: formSelector(state, 'refundType')?.value,
    refundTax: formSelector(state, 'refundTax') || false,
    refundDuty: formSelector(state, 'refundDuty') || false,
    refundShipping: formSelector(state, 'refundShipping') || false,
    refundTaxType: formSelector(state, 'refundTaxType')?.value,
    refundDutyType: formSelector(state, 'refundDutyType')?.value,
    refundShippingType: formSelector(state, 'refundShippingType')?.value,
    items: formSelector(state, 'items'),
    initialValues: {
      orderNumber: order.number,
      orderCurrency: order.total.currency,
      refundType: {
        value: RefundTypeValues.FULL,
        content: 'Full Refund',
      },
      refundTax: true,
      refundShipping: true,
      refundDuty: true,
      refundTaxType: {
        value: RefundAmountValues.ITEM,
        content: 'charged per item',
      },
      refundShippingType: {
        value: RefundShippingValues.VALUE,
        content: 'charged by value %',
      },
      refundDutyType: {
        value: RefundAmountValues.ITEM,
        content: 'charged per item',
      },
      refundAmount: refundObjToUse.refundAmount,
      items: formItems,
    },
  };
}

function mapDispatchToProps(dispatch: ThunkDispatcher): OrderRefundFormDispatchProps {
  return {
    onComponentLoad: (): void => {
      dispatch(touch(FormNames.ORDER_REFUND_FORM, 'refundAmount'));
      dispatch(touch(FormNames.ORDER_REFUND_FORM, 'refundTax'));
      dispatch(touch(FormNames.ORDER_REFUND_FORM, 'refundShipping'));
      dispatch(touch(FormNames.ORDER_REFUND_FORM, 'refundDuty'));
    },
    onComponentUnmount: (): void => {
      dispatch(clearRefundAmount());
    },
    onRefundParamsChange: (
      order: io.flow.v0.models.Order,
      options: RefundCalculationOptions,
      allocations: io.flow.v0.models.AllocationV2,
    ): void => {
      const refundObj = calculateRefundAmount(order, options, allocations);
      dispatch(updateRefundAmount(refundObj));
      dispatch(touch(FormNames.ORDER_REFUND_FORM, 'refundAmount'));
      dispatch(change(FormNames.ORDER_REFUND_FORM, 'refundAmount', refundObj.refundAmount));
    },
  };
}

export default compose<React.FC<OrderRefundPassedProps>>(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FormNames.ORDER_REFUND_FORM,
    onSubmit: submitForm,
  }),
)(OrderRefundForm);
