import { ActionTypes } from '../constants';
import { ThunkDispatcher, ThunkExtraArguments, RootState } from '../../../stores/types';
import { ThunkResult } from '../../../middlewares/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import toLegacyResponse from '../../../utilities/clients/to-legacy-response';

export default function verifyEmail(token: string):
ThunkResult<Promise<LegacyResponse<io.flow.v0.models.EmailVerification | FormattedErrorMessages>>> {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<LegacyResponse<io.flow.v0.models.EmailVerification | FormattedErrorMessages>> => {
    dispatch({ type: ActionTypes.VERIFY_EMAIL_REQUEST });
    return extra.api(getState()).emailVerifications.postByToken({ token })
      .then((response) => {
        if (response.ok) {
          dispatch({
            type: ActionTypes.VERIFY_EMAIL_SUCCESS,
            payload: response.body,
          });
          return toLegacyResponse(response);
        }

        const formattedErrors = formatErrorResponseV2(response);
        dispatch({
          type: ActionTypes.VERIFY_EMAIL_FAILURE,
          payload: formattedErrors,
        });
        return toLegacyResponse({
          ...response,
          body: formattedErrors,
        });
      });
  };
}
