import { ChartAttributes } from './types/Charts';

export default function getXPosition(
  dataLength: number,
  currentIndex: number,
  chartAttrs: ChartAttributes,
): number {
  const {
    width,
    marginLeft,
  } = chartAttrs;

  if (dataLength > 1) {
    return (((width - marginLeft) / (dataLength - 1)) * currentIndex);
  }
  return (((width - marginLeft)) * currentIndex);
}
