import React, { MouseEventHandler } from 'react';
import { Button } from '@flowio/react-button';
import noop from 'lodash/noop';

interface ComponentProps {
  organization: string;
  onConfirm: MouseEventHandler;
}

const defaultProps = {
  onConfirm: noop,
};

const ConfirmSplash: React.FC<ComponentProps> = ({ organization, onConfirm }) => (
  <section className="center">
    <Button
      onClick={onConfirm}
      className="col-4 mt2"
      content={`Join ${organization}`}
      name="join_organization"
      intent="primary"
      type="submit"
    />
  </section>
);

ConfirmSplash.defaultProps = defaultProps;

export default ConfirmSplash;
