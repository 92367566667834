import requiredPermissions from '../constants/required-permissions';
import { PermissionDetail, ValidatedPermissions } from '../types/store';
import ErrorTypes from '../constants/error-types';

export const verifySDKSetup = (): void => {
  if (!process.browser) {
    throw new Error(ErrorTypes.SERVERSIDE_ERROR);
  }

  if (!window.FB) {
    throw new Error(ErrorTypes.SDK_NOT_DETECTED_ERROR);
  }
};

export const interpretPermissions = (
  permissions: PermissionDetail[],
  required: Array<typeof requiredPermissions[number]>,
): ValidatedPermissions => {
  const validatedPermissions: ValidatedPermissions = required.reduce(
    (acc: ValidatedPermissions, permissionName) => {
      const matchedPermission = permissions.find(({ permission }) => permission === permissionName);
      if (matchedPermission && matchedPermission.status === 'granted') {
        acc.granted.push(permissionName);
      } else {
        acc.declined.push(permissionName);
      }
      return acc;
    },
    { declined: [], granted: [] },
  );

  return validatedPermissions;
};

export const isMissingPermission = (
  grantedScopes: string[],
  requiredScopes: string[],
): boolean => (
  requiredScopes.some((requiredScope) => grantedScopes.indexOf(requiredScope) < 0)
);
