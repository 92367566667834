import { $HttpResponse } from '@flowio/api-sdk';
import ActionTypes from '../constants/ActionTypes';
import { ApiError } from '../../../utilities/clients/api-error';
import standardAction from '../../../utilities/standard-action';
import {
  RootState, ThunkExtraArguments, ThunkResult, ThunkDispatcher,
} from '../../../stores/types';

export default function createChannel(
  organization: string,
  channel: io.flow.internal.v0.enums.MarketingGatewayPlatform,
  source: io.flow.internal.v0.unions.MarketingGatewayFeedSource,
): ThunkResult<Promise<void>> {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<void> => {
    dispatch({
      type: ActionTypes.CREATE_CHANNEL_REQUEST,
    });

    return extra.apiInternal(getState()).marketingGatewayChannels.putConnectByChannelId({
      organization,
      channel_id: channel,
      body: { default_feed_source: source },
    }).then((response) => {
      if (response.ok) {
        dispatch(standardAction(ActionTypes.CREATE_CHANNEL_SUCCESS, response.body));
        return;
      }

      const errorResponse = response as $HttpResponse<io.flow.v0.models.GenericError>;
      const error = new ApiError(errorResponse);

      dispatch(standardAction(ActionTypes.CREATE_CHANNEL_FAILURE));
      throw error;
    });
  };
}
