import { css } from '@emotion/css';
import colors from '../../../../../theme/tokens/colors';

export const pageHeader = css`
    margin-left: 17px;
    margin-right: 17px;
    margin-top: 16px;
`;

export const promoCodeRow = css`
  padding-bottom: 12px;
`;

export const embeddedUrlLabel = css`
  font-size: 14px;
`;

export const additionalSettingsLabel = css`
  font-size: 14px;

`;

export const validityEndingLabel = css`
  font-size: 14px;
  margin-left: 5px;
`;

export const embeddedUrlCheckboxContainer = css`
  display: inline-block;
  padding-top: 1px;
`;

export const validityPeriodLabel = css({
  fontSize: '12px',
  color: colors.charcoal,
});

export const validityPeriodRow = css`
  padding-bottom: 12px;
`;

export const removeRuleContainer = css`
  margin-left: auto !important;
`;

export const addRuleContainer = css`
  margin-left: auto !important;
`;

export const qualifierBox = css`
  background-color: #f2f5f7;
  border-radius: 3px;
  padding: 8px;
`;

export const qualifierNoRuleText = css({
  color: colors.steel,
  textSlign: 'center',
  paddingTop: '8px',
  paddingBottom: '8px',
});

export const qualifierCriteriaRow = css`
  padding-bottom: 12px;
`;

export const disabled = css({
  color: colors.steel,
});

export const noSpace = css` 
  margin-left: 0px !important;
`;

export const nameContainer = css`
  width: 45%;
`;

export const qualifierCriteria = css`
  width: 72px;
`;

export const promoTime = css` 
  width: 115px;
`;

export const promoZone = css` 
  width: 160px;
`;

export const limitAmount = css`
  width: 96px;
`;

export const discountAmountContainer = css` 
  width: 120px;
`;

export const discountType = css` 
  width: 127px;
`;

export const inlineCheckbox = css`
  display: flex;
`;
