import { css } from '@emotion/css';
import colors from '../../../../../theme/tokens/colors';

const iconWidth = '72px';

const onboardingCard = css`
  height: 240px;

  & .googleConnectButton {
    cursor: pointer;
    width: 50%;
  }

  & .textContainer {
    height: 100%;
    padding: 32px;
    box-sizing: border-box;
  }

  & .logoContainer {
    height: 100%;
    width: 320px;
    padding: 28px;
    box-sizing: border-box;
    background-color: ${colors.purple[200]};
    position: relative;

    & :first-child {
      z-index: 1;
    }

    &.facebook {
      background-color: ${colors['flow-blue'][200]};
    }

    &.google {
      background-color: ${colors.yellow[200]};
    }

    & .manualIcon,
    & .othersCard {
      background-color: ${colors.white};
      border-radius: 4px;
      margin: 8px;
    }

    & .manualIcon {
      padding: 8px;
    }

    & .othersCard {
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: ${iconWidth};
      width: ${iconWidth};
      color: ${colors.marketing['dark-slate-blue']};
    }

    & .facebookIcon, & .googleIcon {
      transform: translateY(-28px);
    }

    & .moreChannels {
      color: ${colors.white};
      font-size: 12px;
      font-weight: 500;
      position: absolute;
      top: calc(50% + 16px + #{${iconWidth} / 2});
    }

    & .productCards {
      position: absolute;
      bottom: 0;
      left: 30px;
      z-index: 0;
    }
  }
`;

export default onboardingCard;
