import { RootActionTypes, ThunkResult } from '../../../stores/types';
import ActionTypes from '../constants/ActionTypes';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import { ApiError } from '../../../utilities/clients/api-error';

function deleteDiscountRequestAction(): RootActionTypes {
  return {
    type: ActionTypes.DELETE_DISCOUNT_REQUEST,
  };
}

function deleteDiscountSuccessAction(): RootActionTypes {
  return {
    type: ActionTypes.DELETE_DISCOUNT_SUCCESS,
  };
}

function deleteDiscountFailureAction(payload: FormattedErrorMessages): RootActionTypes {
  return {
    type: ActionTypes.DELETE_DISCOUNT_FAILURE,
    payload,
    error: true,
  };
}

export default function deleteDiscount(
  organization: string,
  id: string,
): ThunkResult<Promise<void | boolean>> {
  return function deleteDiscountEffects(
    dispatch,
    getState,
    extra,
  ): Promise<void | boolean> {
    dispatch(deleteDiscountRequestAction());

    return extra.api(getState()).discountRuleSettings.deleteById({
      organization,
      id,
    }).then((response) => {
      if (response.ok) {
        dispatch(deleteDiscountSuccessAction());
        return true;
      }
      const error = new ApiError(response);

      dispatch(deleteDiscountFailureAction(formatErrorResponseV2(response)));
      throw error;
    });
  };
}
