import { Field, Form } from 'redux-form';
import React from 'react';

import AnnotatedSection from '../../../../components/AnnotatedSection';
import GenericError from '../../../../components/GenericError';
import presence from '../../../../utilities/validators/presence';
import * as segmentedControlTheme from '../featuresSegmentedControl.styles';
import { EditFeatureWorksheetFormProps } from '../../types/components';
import { TextField, SegmentedControl } from '../../../../components/ReduxFormFieldAdapters';

const validateName = [
  presence({ message: 'Enter Feature Name', allowEmpty: false }),
];

const validateKey = [
  presence({ message: 'Enter key name', allowEmpty: false }),
];

const EditFeatureWorksheetForm: React.FC<EditFeatureWorksheetFormProps> = ({
  handleSubmit,
  error,
}) => (
  <Form onSubmit={handleSubmit}>
    <Field component="input" type="hidden" name="organizationId" />
    <Field component="input" type="hidden" name="rules" />
    {error && (
      <GenericError error={error} />
    )}
    <AnnotatedSection.Group>
      <AnnotatedSection
        title="Name"
        description="For booking in Console only. Your customers will not see this."
      >
        <Field
          component={TextField}
          name="name"
          validate={validateName}
          autoComplete="off"
          fluid
          hintText="Enter Feature Name"
        />
      </AnnotatedSection>
      <AnnotatedSection
        title="Key"
        description="A unique identifier of this feature. Features created by Flow must start with `global_`."
      >
        <Field
          name="key"
          component={TextField}
          validate={validateKey}
          autoComplete="off"
          fluid
          disabled
          hintText="Enter Feature Key"
        />
      </AnnotatedSection>
      <AnnotatedSection
        title="Value Type"
        description="Select the type of values this feature will return."
      >
        <Field
          name="type"
          component={SegmentedControl}
          theme={segmentedControlTheme}
          segments={[{
            id: 'type-boolean',
            key: 'type-boolean',
            content: 'Boolean',
            value: 'boolean',
          }, {
            id: 'type-string',
            key: 'type-string',
            content: 'String',
            value: 'string',
          }]}
        />
      </AnnotatedSection>
      <AnnotatedSection
        title="Description"
        description="Briefly describe the functionality of this feature."
      >
        <Field
          name="description"
          component={TextField}
          autoComplete="off"
          multiLine
          fluid
          hintText="Enter Description"
        />
      </AnnotatedSection>
    </AnnotatedSection.Group>
  </Form>
);

EditFeatureWorksheetForm.displayName = 'EditFeatureWorksheetForm';

EditFeatureWorksheetForm.defaultProps = {
  error: undefined,
};

export default EditFeatureWorksheetForm;
