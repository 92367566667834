import { TierStrategy } from '@flowio/api-constants';
import {
  Card, CardHeader, CardContent, CardTitle,
} from '@flowio/react-card';
import React from 'react';
import { Stack } from '@flowio/react-stack';
import { Rocket, MoneyBulb } from '@flowio/react-icons';

import TierCarrierServiceStrategyOption from '../TierCarrierServiceStrategyOption';

const TierCarrierServiceStrategyCard: React.FC<{}> = () => (
  <Card>
    <CardHeader dividing>
      <CardTitle content="Choose Carrier Service Selection Strategy" />
    </CardHeader>
    <CardContent>
      <Stack spacing="loose">
        <TierCarrierServiceStrategyOption
          heading="Fastest Speed"
          iconName={<Rocket />}
          id={TierStrategy.FASTEST}
          name="strategy"
          value={TierStrategy.FASTEST}
        />
        <TierCarrierServiceStrategyOption
          heading="Lowest Cost"
          iconName={<MoneyBulb />}
          id={TierStrategy.LOWEST_COST}
          name="strategy"
          value={TierStrategy.LOWEST_COST}
        />
      </Stack>
    </CardContent>
  </Card>
);

TierCarrierServiceStrategyCard.displayName = 'TierCarrierServiceStrategyCard';

export default TierCarrierServiceStrategyCard;
