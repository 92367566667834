import React from 'react';
import { Box } from '@flowio/react-box';
import { css } from '@emotion/react';
import { Button, OutlineButton } from '@flowio/react-button';
import { Close } from '@flowio/react-icons';
import MarketingDialog from '../../../marketing/components/MarketingDialog';
import { findCountryByCode } from '../../../marketing/utilities/findReferenceData';
import { Props } from '../../containers/types';
import VariantCountry from '../experiment-details/components/variant-country';
import {
  getVariantName,
  isExperienceVariant,
  checkBaselineVariant,
  checkWinningVariant,
} from '../experiment-details/utilities/checkVariant';

const xButtonStyles = css`
  position: absolute;
  height: 20px;
  top: 15px;
  right: 15px;
  cursor: pointer;

  &:hover {
    opacity: 0.7
  }
`;

const ImplementWinningVariantModal: React.FC<Props> = ({
  hideModal,
  winningVariant,
  variants,
  countries,
  country,
  isOpen,
  handleImplementVariant,
}) => {
  const { experiment_results: experimentResults } = winningVariant;
  const countryObj = findCountryByCode(countries, country || '');
  const getText = () => {
    if (experimentResults) {
      const { conversion_rate_uplift: conversionRateUplift } = experimentResults;
      const isGreatResult = conversionRateUplift && conversionRateUplift > 1;
      if (conversionRateUplift) {
        return `${isGreatResult ? 'Great result. ' : ''}"${getVariantName(winningVariant)}" increased the conversion rate by ${conversionRateUplift}%. Do you want to implement this Experience now?`;
      }
      return `"${getVariantName(winningVariant)}" is the winning variant. Do you want to implement this Experience now?`;
    }
    return '';
  };

  const subtitle = `This will automatically implement '${getVariantName(winningVariant)}' as the default Experience for ${countryObj?.name} traffic.`;
  const handleClick = (shouldImplementWinner :boolean) => {
    if (experimentResults) {
      handleImplementVariant(
        experimentResults?.experiment_key, shouldImplementWinner ? winningVariant : undefined,
      );
    }
  };
  const sortedVariants = variants.sort((a) => (a === winningVariant ? 1 : -1));

  return (
    <MarketingDialog
      topContent={
        (
          <>
            <Close
              css={xButtonStyles}
              onClick={hideModal}
            />
            <Box direction="column">
              {sortedVariants.map((v) => {
                const { name, key } = isExperienceVariant(v) ? v.experience : v.value;
                return (
                  <VariantCountry
                    name={name}
                    country={countryObj}
                    isBaselineVariant={checkBaselineVariant(variants, key)}
                    isWinningVariant={checkWinningVariant(winningVariant, key)}
                  />
                );
              })}
            </Box>
          </>
        )
      }
      title={getText()}
      subtitle={subtitle}
      isOpen={isOpen && winningVariant !== undefined}
    >
      <OutlineButton
        content="End and Leave as Default"
        onClick={() => handleClick(false)}
      />
      <Button
        intent="primary"
        content="End and Implement Winner"
        onClick={() => handleClick(true)}
      />
    </MarketingDialog>
  );
};

ImplementWinningVariantModal.displayName = 'ImplementWinningVariantModal';

export default ImplementWinningVariantModal;
