import { createSelector } from 'reselect';
import { getPageResults } from '@flowio/redux-filtering-paging-sorting';
import { ShippingConfigurationType } from '@flowio/api-constants';
import find from 'lodash/fp/find';
import getShippingConfigurationPaginationState from './getShippingConfigurationPaginationState';

export default createSelector(
  getPageResults(getShippingConfigurationPaginationState),
  find({ type: ShippingConfigurationType.DEFAULT }),
);
