import Rollbar from 'rollbar';

const environment = process.env.NODE_ENV || 'development';

let rollbar: Rollbar;

/**
 * Returns instance of Rollbar with the appropriate configuration for this application.
 */
export function createRollbar(): Rollbar {
  return new Rollbar({
    accessToken: '5bddbdbf1b5b4349ac7e0f80cf496397', // This is a token to identify the app, not private
    captureUncaught: true,
    captureUnhandledRejections: true,
    captureIp: 'anonymize',
    payload: {
      environment,
    },
    scrubTelemetryInputs: true,
  });
}

/**
 * Returns instance of the rollbar object. Enables 'singleton' behavior by returning existing
 * instance if defined already.
 */
export function getInstance(): Rollbar {
  if (rollbar) {
    return rollbar;
  }

  rollbar = createRollbar();
  return rollbar;
}
