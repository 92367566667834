import { CreateToastOptions } from './actions';
import { RedirectState } from '../reducers/ui/redirect';

export interface ConsoleUser extends io.flow.v0.models.User {
  auth: string;
  extra?: {
    auth0Id?: string;
  }
}

export interface DefaultReducerState {
  __redirect?: string;
  isLoggedIn: boolean;
  errors: string[];
  user?: ConsoleUser;
  memberships?: io.flow.v0.models.Membership[];
}

export interface ConsoleExperienceState {
  updateSettingsSuccess: boolean;
  saveEditMarginsSuccess: boolean;
}

export enum FeatureValueStatus {
  pending = 'pending',
  loading = 'loading',
  fulfilled = 'fulfilled',
  rejected = 'rejected',
  invalidated = 'invalidated',
}

export interface FeatureValuesState {
  status: FeatureValueStatus;
  entities: io.flow.internal.v0.unions.FeatureValue[];
}

export type ToastState = CreateToastOptions[];

export interface TokenUiState {
  cleartextTokenDialogId?: string;
}

export interface UserState {
  updateBasicInformationSuccess: boolean;
}

export interface AccountDropDownMenuState {
  isOpen: boolean;
}

export interface FeatureValueSelectorStateProps {
  isFeatureEnabled?: boolean;
}

export interface MembershipDropdownMenuState {
  membershipDropDownOpen: boolean;
}

export interface OnlyFlowEmployeeStateProps {
  isFlowEmployee: boolean;
}

export interface UiState {
  experience: {
    updateSettingsSuccess: boolean;
    updatePricingSuccess: boolean;
    saveEditMarginsSuccess: boolean;
  };
  redirect: RedirectState;

  // remove below generic `[key: string]: any;` when fully typed.
  [key: string]: unknown;
}
