export enum ActionTypes {
  CHANGE_INTERVAL = 'ANALYTICS.CHANGE_INTERVAL',
  CHANGE_REGION = 'ANALYTICS.CHANGE_REGION',
  CHANGE_CURRENT_DATE = 'ANALYTICS.CHANGE_CURRENT_DATE',
  CHANGE_DATE_RANGE = 'ANALYTICS.CHANGE_DATE_RANGE',
  FETCH_COUNTRY_REVENUE_FAILURE = 'ANALYTICS.FETCH_COUNTRY_REVENUE_FAILURE',
  FETCH_COUNTRY_REVENUE_REQUEST = 'ANALYTICS.FETCH_COUNTRY_REVENUE_REQUEST',
  FETCH_COUNTRY_REVENUE_SUCCESS = 'ANALYTICS.FETCH_COUNTRY_REVENUE_SUCCESS',
  FETCH_DESTINATION_COUNTRIES_FAILURE = 'ANALYTICS.FETCH_DESTINATION_COUNTRIES_FAILURE',
  FETCH_DESTINATION_COUNTRIES_REQUEST = 'ANALYTICS.FETCH_DESTINATION_COUNTRIES_REQUEST',
  FETCH_DESTINATION_COUNTRIES_SUCCESS = 'ANALYTICS.FETCH_DESTINATION_COUNTRIES_SUCCESS',
  FETCH_TIMELINE_REVENUE_FAILURE = 'ANALYTICS.FETCH_TIMELINE_REVENUE_FAILURE',
  FETCH_TIMELINE_REVENUE_REQUEST = 'ANALYTICS.FETCH_TIMELINE_REVENUE_REQUEST',
  FETCH_TIMELINE_REVENUE_SUCCESS = 'ANALYTICS.FETCH_TIMELINE_REVENUE_SUCCESS',
  EXPORT_TIMELINE_REQUEST = 'ANALYTICS.EXPORT_TIMELINE_REQUEST',
  EXPORT_TIMELINE_SUCCESS = 'ANALYTICS.EXPORT_TIMELINE_SUCCESS',
  EXPORT_TIMELINE_FAILURE = 'ANALYTICS.EXPORT_TIMELINE_FAILURE',
  EXPORT_TIMELINE_RESET_STATE = 'ANALYTICS.EXPORT_TIMELINE_RESET_STATE',
}

export default ActionTypes;
