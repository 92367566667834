import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { Dispatch, compose } from 'redux';
import { withFetch } from '@flowio/redux-fetch';
import { browserHistory } from 'react-router';
import head from 'lodash/head';
import assign from 'lodash/assign';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import PriceCheck from '..';
import {
  fetchAllExperiences,
  getItemPriceDetails as fetchItemPriceDetails,
  getPricing as fetchPricing,
  clearItemPricing,
} from '../../../../experience/actions';
import {
  getItemPriceDetails,
  getPricing,
  getExperiences,
  getPricingErrors,
} from '../../../../experience/selectors';
import { fetchCatalogItemByNumber } from '../../../../catalog';
import { ThunkDispatcher, RootState, RootActionTypes } from '../../../../../stores/types';
import { fetchOrganization } from '../../../../organization';
import getCatalogItem from '../../../../catalog/selectors/get-catalog-item';
import {
  OwnProps, StateProps, DispatchProps, ChangeOptions,
} from '../types';
import getQueryParamValue from '../../../get-query-parameter-value';

function getAsyncState(
  dispatch: ThunkDispatcher,
  getState: () => RootState,
  ownProps: OwnProps,
): Promise<unknown[]> {
  const { organization } = ownProps.params;
  const { experience, item } = ownProps.location.query;
  const promises: Promise<unknown>[] = [];
  promises.push(dispatch(fetchOrganization(organization)));
  // Only want to fetch the active and draft experiences
  promises.push(dispatch(fetchAllExperiences(organization, { q: 'and status in (active, draft)' })));

  if (item) {
    promises.push(dispatch(fetchCatalogItemByNumber(organization, getQueryParamValue(item))));

    return Promise.all(promises).then(() => {
      const state = getState();
      const experiences = getExperiences(state);
      const itemDetails = getCatalogItem(state);
      const expKey = getQueryParamValue(experience) || get(head(experiences), 'key');

      if (!expKey) {
        throw new Error('Missing required exerpience key');
      }
      return Promise.all([
        dispatch(fetchPricing(organization, expKey)),
        dispatch(fetchItemPriceDetails(organization, expKey, itemDetails)),
      ]);
    });
  }

  return Promise.all(promises);
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = (
  state: RootState,
  ownProps: OwnProps,
) => {
  const experiences = sortBy(getExperiences(state), 'name');

  const props = {
    experiences,
    errors: getPricingErrors(state),
    experience: get(ownProps, 'location.query.experience', get(head(experiences), 'key')),
    priceCheck: getItemPriceDetails(state),
    pricing: getPricing(state),
  };
  return props;
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch: Dispatch<RootActionTypes>,
  ownProps: OwnProps,
) => ({
  onComponentUnmount(): void {
    dispatch(clearItemPricing());
  },
  onExperienceChange(options: ChangeOptions): void {
    const {
      experience,
      item,
    } = options;
    const nextLocation = assign({}, ownProps.location, {
      query: { experience, item },
    });

    browserHistory.push(nextLocation);
  },
  onPriceCheck(options: ChangeOptions): void {
    const {
      experience,
      item,
    } = options;

    const nextLocation = assign({}, ownProps.location, {
      query: { experience, item },
    });
    browserHistory.push(nextLocation);
  },
});

export default compose(
  withFetch(getAsyncState),
  connect(mapStateToProps, mapDispatchToProps),
)(PriceCheck);
