import React from 'react';
import { Box } from '@flowio/react-box';
import { MenuDivider } from '@flowio/react-menu';
import classNames from 'classnames';
import * as styles from './MarketingCard.styles';

interface Props {
  showDivider?: boolean;
  padding?: boolean;
}

const MarketingCardActions: React.FC<Props> = ({
  children,
  showDivider = true,
  padding = false,
}) => {
  if (children) {
    return (
      <div className={classNames(styles.actions, padding)}>
        {showDivider && <MenuDivider className="divider" />}
        <Box justifyContent="end" spacing="loose">
          {children}
        </Box>
      </div>
    );
  }

  return null;
};

MarketingCardActions.displayName = 'MarketingCardActions';

export default MarketingCardActions;
