import { $HttpResponse } from '@flowio/api-sdk';
import ActionTypes from '../constants/action-types';
import {
  RootState, ThunkExtraArguments, ThunkDispatcher, ThunkResult,
} from '../../../stores/types';
import { ApiError } from '../../../utilities/clients/api-error';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';
import standardAction from '../../../utilities/standard-action';

export default function deleteExperiment(
  organization: string,
  key: string,
): ThunkResult<Promise<void>> {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<void> => {
    dispatch(standardAction(ActionTypes.DELETE_EXPERIMENT_REQUEST));
    const state = getState();
    return extra.apiInternal(state).experiments.deleteByKey({
      organization,
      key,
    }).then((response) => {
      if (response.ok) {
        dispatch({
          type: ActionTypes.DELETE_EXPERIMENT_SUCCESS,
          payload: key,
        });
        return;
      }

      const errorResponse = response as $HttpResponse<io.flow.v0.models.GenericError>;

      const error = new ApiError(
        errorResponse as $HttpResponse<io.flow.v0.models.GenericError>,
      );
      dispatch({
        type: ActionTypes.DELETE_EXPERIMENT_FAILURE,
        payload: formatErrorResponseV2(errorResponse),
      });
      throw error;
    });
  };
}
