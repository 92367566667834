import React from 'react';
import { Switch } from '@flowio/react-switch';
import { WrappedFieldProps } from 'redux-form';

type ReduxFormSwitchProps = WrappedFieldProps & typeof Switch;

const ReduxFormSwitch: React.FC<ReduxFormSwitchProps> = (props) => {
  const { input, meta, ...unhandledProps } = props;

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    input.onChange(event.target.checked);
  }

  return (
    <Switch
      {...unhandledProps}
      checked={input.checked}
      name={input.name}
      onBlur={input.onBlur}
      onChange={handleChange}
      onFocus={input.onFocus}
      value={input.value.toString()}
    />
  );
};

export default ReduxFormSwitch;
