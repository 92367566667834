import React from 'react';
import { TableBody, TableRow, TableRowColumn } from '@flowio/react-table';
import formatCount from '../../../utilities/formatCount';
import FacebookAccessValue from './FacebookAccessValue';
import { getStatusFromError } from '../../../utilities/getStatusFromError';

type Props = {
  details?: io.flow.internal.v0.unions.MarketingGatewayChannelDetails;
  error?: io.flow.internal.v0.models.MarketingGatewayError;
};

const isFacebookDetails = (
  channelDetails?: io.flow.internal.v0.unions.MarketingGatewayChannelDetails,
): channelDetails is io.flow.internal.v0.models.MarketingGatewayFacebookChannelDetails => (
  !!channelDetails && (
    channelDetails as io.flow.internal.v0.models.MarketingGatewayFacebookChannelDetails
  ).discriminator === 'marketing_gateway_facebook_channel_details');

const FacebookChannelDetails: React.FC<Props> = ({ details, error }) => {
  const fbDetails = isFacebookDetails(details) ? details : undefined;
  const calculatedStatus = getStatusFromError(error);

  return (
    <TableBody>
      {fbDetails && (
        <TableRow>
          <TableRowColumn>Business Name</TableRowColumn>
          <TableRowColumn>{fbDetails.business_name}</TableRowColumn>
        </TableRow>
      )}
      {fbDetails && (
        <TableRow>
          <TableRowColumn>Catalog Name</TableRowColumn>
          <TableRowColumn>{fbDetails.catalog_name}</TableRowColumn>
        </TableRow>
      )}
      {fbDetails && (
        <TableRow>
          <TableRowColumn>Catalog ID</TableRowColumn>
          <TableRowColumn>
            {fbDetails.catalog_id || fbDetails.token_summary?.catalog_id}
          </TableRowColumn>
        </TableRow>
      )}
      {fbDetails && (
        <TableRow>
          <TableRowColumn># of Country Feeds</TableRowColumn>
          <TableRowColumn>{formatCount(fbDetails.localized_feed_count, 'feed')}</TableRowColumn>
        </TableRow>
      )}
      {calculatedStatus && (
        <TableRow>
          <TableRowColumn data-automation-id="fb-access-col">Facebook Access</TableRowColumn>
          <TableRowColumn>
            <FacebookAccessValue
              accessTokenSummary={fbDetails?.token_summary}
              status={calculatedStatus}
            />
          </TableRowColumn>
        </TableRow>
      )}
    </TableBody>
  );
};

FacebookChannelDetails.displayName = 'FacebookChannelDetails';

export default FacebookChannelDetails;
