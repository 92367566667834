import { createSelector } from 'reselect';
import { getIndexedEntities } from '@flowio/redux-filtering-paging-sorting';
import get from 'lodash/get';

import getActiveOptinAttributeKey from './getActiveOptinAttributeKey';
import getOptinAttributePaginationState from './getOptinAttributesPaginationState';

export default createSelector(
  getActiveOptinAttributeKey,
  getIndexedEntities(getOptinAttributePaginationState),
  (optinAttributeKey, optinAttributes) => get(optinAttributes, optinAttributeKey),
);
