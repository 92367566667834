import { createSelector } from 'reselect';
import get from 'lodash/get';
import getQueryBuilderForReturnPolicyWorksheet from './getQueryBuilderForReturnPolicyWorksheet';

/**
 * Returns initial list of query filters associated with the return policy
 * being created or edited. Generally, when a new return policy is being
 * created the result is an empty list.
 * @returns {QueryFilter[]}
 */
const getQueryFiltersForReturnPolicyWorksheet = createSelector(
  getQueryBuilderForReturnPolicyWorksheet,
  (queryBuilder) => get(queryBuilder, 'filters', []),
);

export default getQueryFiltersForReturnPolicyWorksheet;
