import { createQueryParameters } from '@flowio/redux-filtering-paging-sorting';

import ActionTypes from '../constants/ActionTypes';
import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import { FetchShippingConfigurationsFailure, FetchShippingConfigurationsOptions, FetchShippingConfigurationsSuccess } from '../types/actions';

function createRequestAction(parameters: FetchShippingConfigurationsOptions): RootActionTypes {
  return {
    type: ActionTypes.FETCH_SHIPPING_CONFIGURATIONS_REQUEST,
    meta: { parameters },
  };
}

function createSuccessAction(
  payload: io.flow.v0.models.ShippingConfiguration[],
  parameters: FetchShippingConfigurationsOptions,
): FetchShippingConfigurationsSuccess {
  return {
    type: ActionTypes.FETCH_SHIPPING_CONFIGURATIONS_SUCCESS,
    meta: { parameters },
    payload,
  };
}

function createFailureAction(
  payload: FormattedErrorMessages,
  parameters: FetchShippingConfigurationsOptions,
): FetchShippingConfigurationsFailure {
  return {
    type: ActionTypes.FETCH_SHIPPING_CONFIGURATIONS_FAILURE,
    meta: { parameters },
    payload,
    error: true,
  };
}

/**
 * Creates an action responsible for fetching a page of shipping configurations.
 * @param {String} props.organizationId
 * @param {?Number} [props.entriesPerPage = 25]
 * @param {?Number} [props.pageNumber = 1]
 * @param {?Object[]} [props.sortBy],
 */
function fetchShippingConfigurations(
  parameters: FetchShippingConfigurationsOptions,
): ThunkResult<Promise<FetchShippingConfigurationsSuccess | FetchShippingConfigurationsFailure>> {
  return function fetchShippingConfigurationsEffects(
    dispatch,
    getState,
    extra,
  ): Promise<FetchShippingConfigurationsSuccess | FetchShippingConfigurationsFailure> {
    const defaultOptions: Partial<FetchShippingConfigurationsOptions> = {
      entriesPerPage: 25,
      pageNumber: 1,
      sortBy: [
        { name: 'type', direction: 'ascending' },
        { name: 'name', direction: 'ascending' },
      ],
    };

    const resolvedOptions = {
      ...defaultOptions,
      ...parameters,
    };
    dispatch(createRequestAction(resolvedOptions));
    const query = createQueryParameters(resolvedOptions);

    return extra.api(getState()).shippingConfigurations.get({
      ...query,
      expand: ['center'],
      organization: resolvedOptions.organizationId,
      experience: resolvedOptions.experienceKey,
    }).then((response) => {
      if (response.ok) {
        return dispatch(createSuccessAction(response.body, resolvedOptions));
      }

      const errors = formatErrorResponseV2(response);
      return dispatch(createFailureAction(errors, resolvedOptions));
    });
  };
}

export default fetchShippingConfigurations;
