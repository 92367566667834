enum DialogName {
  SHIPPING_CONFIGURATION_WORKSHEET = 'shippingConfigurationWorksheet',
  SHIPPING_CONFIGURATION_COPY_WORKSHEET = 'shippingConfigurationCopyWorksheet',
  SHIPPING_CONFIGURATION_DELETE_CONFIRMATION = 'shippingConfigurationDeleteConfirmation',
  SHIPPING_LANE_WORKSHEET = 'shippingLaneWorksheet',
  SHIPPING_LANE_DELETE_CONFIRMATION = 'shippingLaneDeleteConfirmation',
  TIER_WORKSHEET = 'tierWorksheet',
  TIER_DELETE_CONFIRMATION = 'tierDeleteConfirmation',
}

export default DialogName;
