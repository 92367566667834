import { Ellipsis } from '@flowio/react-icons';
import React from 'react';
import { css } from '@emotion/react';
import noop from 'lodash/noop';

import {
  Table,
  TableHead,
  TableRow, TableRowColumn,
  TableHeadColumn,
  TableBody,
} from '@flowio/react-table';
import { Menu, MenuItem, MenuDivider } from '@flowio/react-menu';
import { IconButton } from '@flowio/react-button';
import { Popover } from '@flowio/react-popover';

import FormattedDate from '../../../../components/formatted-date';
import StatusLabel from '../../../../components/status-label';
import featureStatusIntent from '../../constants/feature-status-intent';
import FeatureStatus from '../../constants/feature-status';
import Pagination from '../../../../components/pagination';
import * as styles from '../features.styles';

import { FeatureListProps } from '../../types/components';

const tableStyles = {
  lastCol: css`
    padding: 0 16px 0 0;
  `,
};

const FeatureList: React.FC<FeatureListProps> = ({
  organization,
  onFeatureViewDetailClick = noop,
  features,
  isControlOrganization,
  onRequestNextPage = noop,
  onRequestPreviousPage = noop,
  onRequestStatusChange = noop,
  pageNumber,
}) => (
  <div>
    <Table displayDensity="compact" striped>
      <TableHead>
        <TableRow>
          <TableHeadColumn>Name</TableHeadColumn>
          <TableHeadColumn>Key</TableHeadColumn>
          <TableHeadColumn>Scope</TableHeadColumn>
          <TableHeadColumn>Status</TableHeadColumn>
          {isControlOrganization && (
            <TableHeadColumn>Released</TableHeadColumn>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {features && features.results && features.results.map(({
          name, key, scope, status, released_at: released,
        }, index) => (
          <TableRow
            striped={index % 2 === 1}
            key={key}
            className={styles.featureListRow}
          >
            <TableRowColumn
              onClick={() => onFeatureViewDetailClick(organization, key)}
            >
              {name}
            </TableRowColumn>
            <TableRowColumn
              onClick={() => onFeatureViewDetailClick(organization, key)}
            >
              {key}
            </TableRowColumn>
            <TableRowColumn
              className={styles.featureScopeLabel}
              onClick={() => onFeatureViewDetailClick(organization, key)}
            >
              {scope}
            </TableRowColumn>
            <TableRowColumn onClick={() => onFeatureViewDetailClick(organization, key)}>
              <StatusLabel content={status} intent={featureStatusIntent[status]} />
            </TableRowColumn>
            {isControlOrganization && (
              <TableRowColumn
                className={styles.releasedCol}
                onClick={() => onFeatureViewDetailClick(organization, key)}
              >
                { released ? (
                  <FormattedDate value={released} format="MMM D, YYYY h:m A" />
                ) : (
                  <span className={styles.label}>Not Released</span>
                )}
              </TableRowColumn>
            )}
            <TableRowColumn className={styles.actionCol} css={tableStyles.lastCol}>
              <Popover
                openOnClick
                propagateClickEvent={false}
                trigger={<IconButton size="small" icon={Ellipsis} />}
              >
                <Menu>
                  <MenuItem
                    content="View Details"
                    onClick={() => onFeatureViewDetailClick(organization, key)}
                  />
                  <MenuDivider />
                  {status === FeatureStatus.DRAFT && (
                    <MenuItem
                      onClick={() => onRequestStatusChange(key, 'active')}
                      content="Activate"
                    />
                  )}
                  {status === FeatureStatus.ACTIVE && (
                    <MenuItem
                      onClick={() => onRequestStatusChange(key, 'archived')}
                      content="Archive"
                    />
                  )}
                  {status === FeatureStatus.ARCHIVED && (
                    <MenuItem
                      onClick={() => onRequestStatusChange(key, 'active')}
                      content="Unarchive"
                    />
                  )}
                </Menu>
              </Popover>
            </TableRowColumn>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    <Pagination
      className={styles.pagination}
      pageNumber={pageNumber}
      isPreviousPageDisabled={features ? features.isFirstPage : true}
      isNextPageDisabled={features ? features.isLastPage : false}
      onRequestPreviousPage={() => onRequestPreviousPage(pageNumber)}
      onRequestNextPage={() => onRequestNextPage(pageNumber)}
    />
  </div>
);

FeatureList.displayName = 'FeatureList';

FeatureList.defaultProps = {
  features: {
    isFirstPage: true,
    isLastPage: true,
    results: [],
  },
  onFeatureViewDetailClick: noop,
  isControlOrganization: false,
  onRequestNextPage: noop,
  onRequestPreviousPage: noop,
  onRequestStatusChange: noop,
  pageNumber: 1,
};

export default FeatureList;
