import { type RouterState, browserHistory } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFetch } from '@flowio/redux-fetch';
import assign from 'lodash/assign';
import find from 'lodash/find';
import filter from 'lodash/filter';
import { getOrderDetailSearchResults } from '../selectors';

import { fetchOrganization, getBaseOrganizationCountry, getBaseOrganizationCurrency } from '../../organization';
import fetchOrderDetails from '../actions/fetch-order-details';
import OrderDetailSearchView from '../components/order-detail-search-view';
import {
  addOperator, hasOperator, formatQuery, parseQuery, removeOperator, ParsedQueryStringEntity,
} from '../../../utilities/query';
import { ThunkDispatcher, RootState } from '../../../stores/types';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import { OrderDetailSearchViewStateProps, OrderDetailSearchViewDispatchProps, OrderDetailSearchViewProps } from '../types';
import FeatureKey from '../../console/constants/FeatureKey';
import { checkFeatureValueByKey } from '../../console/selectors';

const getAsyncState = (
  dispatch: ThunkDispatcher,
  _getState: () => RootState,
  ownProps: RouterState,
): Promise<[
  LegacyResponse<io.flow.v0.models.Organization | undefined>,
  void,
]> => {
  // By default, filter search results by submitted status
  // when a filter is not specified.
  const { location, params } = ownProps;
  let query = location.query.q;

  if (!hasOperator(query, { modifier: 'status' })) {
    query = addOperator(query, 'status:submitted');
  }

  const options = assign({}, location.query, { q: query });

  return Promise.all([
    dispatch(fetchOrganization(params.organization)),
    dispatch(fetchOrderDetails(params.organization, options)),
  ]);
};

const mapStateToProps = (state: RootState): OrderDetailSearchViewStateProps => {
  const searchResults = getOrderDetailSearchResults(state);
  const baseCurrency = getBaseOrganizationCurrency(state);
  const organizationCountry = getBaseOrganizationCountry(state);
  const parsedQuery = parseQuery(searchResults.query);
  const statusQuery = find(parsedQuery, { modifier: 'status' });
  const orderTypeQuery = find(parsedQuery, { modifier: 'order_type' });
  const fraudStatusQuery = find(parsedQuery, { modifier: 'fraud_status' });
  const statusFilterValue = statusQuery ? statusQuery.property : 'all';
  const orderTypeFilterValue = orderTypeQuery ? orderTypeQuery.property : 'all';
  const fraudStatusFilterValue = fraudStatusQuery ? fraudStatusQuery.property : 'all';
  const searchText = removeOperator(searchResults.query, { modifier: 'status' });
  const pageNumber = state.customerService.orderDetail.params.pageNumber
    ? Number(state.customerService.orderDetail.params.pageNumber) : 1;
  const isGlobalReplacementOrdersEnabled = checkFeatureValueByKey(
    FeatureKey.GLOBAL_REPLACEMENT_ORDER,
  )(state);

  return {
    searchText,
    searchResults,
    statusFilterValue,
    fraudStatusFilterValue,
    orderTypeFilterValue,
    pageNumber,
    baseCurrency,
    organizationCountry,
    isGlobalReplacementOrdersEnabled,
  };
};

const updateQueryFilter = (
  query: string,
  queryFilter: string,
  option: ParsedQueryStringEntity,
): ParsedQueryStringEntity[] => {
  const parsedQuery = parseQuery(query || '');
  const filteredQuery = filter(parsedQuery, (q) => q.modifier !== queryFilter);
  filteredQuery.push({ value: `${queryFilter}:${option.value}` });

  return filteredQuery;
};

const mapDispatchToProps = (
  _: ThunkDispatcher,
  { location, params }: RouterState,
): OrderDetailSearchViewDispatchProps => ({
  onRequestSearch: (searchText: string): void => {
    const parsedQuery = parseQuery(location.query.q || '');
    const filteredQuery = filter(parsedQuery, (query) => query.modifier === 'status');
    const searchValue: ParsedQueryStringEntity = {
      value: searchText,
    };

    browserHistory.push({
      pathname: location.pathname,
      query: {
        ...location.query,
        q: formatQuery([searchValue].concat(filteredQuery)),
        pageNumber: 1,
      },
    });
  },
  onRequestStatusFilterChange: (option: ParsedQueryStringEntity): void => {
    const updatedQuery = updateQueryFilter(location.query.q, 'status', option);

    browserHistory.push({
      pathname: location.pathname,
      query: { ...location.query, pageNumber: 1, q: formatQuery(updatedQuery) },
    });
  },
  onRequestOrderTypeFilterChange: (option: ParsedQueryStringEntity): void => {
    const updatedQuery = updateQueryFilter(location.query.q, 'order_type', option);

    browserHistory.push({
      pathname: location.pathname,
      query: { ...location.query, pageNumber: 1, q: formatQuery(updatedQuery) },
    });
  },
  onRequestFraudStatusFilterChange: (option: ParsedQueryStringEntity): void => {
    const updatedQuery = updateQueryFilter(location.query.q, 'fraud_status', option);

    const combinedQuery = { ...location.query, q: formatQuery(updatedQuery) };

    browserHistory.push({
      pathname: location.pathname,
      query: combinedQuery,
    });
  },
  onRequestNextPage(entriesPerPage = 25): void {
    const currentPage = parseInt(location.query.pageNumber, 10) || 1;
    const pageNumber = currentPage + 1;

    browserHistory.push({
      pathname: location.pathname,
      query: { ...location.query, pageNumber, entriesPerPage },
    });
  },
  onRequestPreviousPage(entriesPerPage = 25): void {
    const currentPage = parseInt(location.query.pageNumber, 10) || 1;
    const pageNumber = Math.max(1, currentPage - 1);

    browserHistory.push({
      pathname: location.pathname,
      query: { ...location.query, pageNumber, entriesPerPage },
    });
  },
  onRequestOrderDetailSelection: (order: io.flow.v0.models.Order): void => {
    const path = (order.order_type === 'replacement' && !order.submitted_at)
      ? `/${params.organization}/orders/replacement/${order.number}`
      : `/${params.organization}/orders/${order.number}`;

    browserHistory.push({ pathname: path });
  },
});

export default compose<React.FC<Partial<OrderDetailSearchViewProps>>>(
  withFetch(getAsyncState),
  connect(mapStateToProps, mapDispatchToProps),
)(OrderDetailSearchView);
