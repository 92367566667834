/**
 * Use shadow tokens to set a box shadow.
 */
const shadows = {
  light: '0px 1px 2px rgba(0, 0, 0, 0.02), 0px 1px 4px rgba(20, 23, 26, 0.07)',
  medium: '0px 1px 3px rgba(0, 0, 0, 0.05), 0px 2px 6px 1px rgba(20, 23, 26, 0.05)',
  heavy: '0px 2px 4px rgba(0, 0, 0, 0.05), 0px 4px 12px 2px rgba(20, 23, 26, 0.08)',
  extraHeavy: '0px 2px 4px rgba(0, 0, 0, 0.05), 0px 8px 24px 2px rgba(20, 23, 26, 0.1)',
};

export default shadows;
