import ActionTypes from '../constants/action-types';
import { setPaymentMethodDisplayState, mapRulesWithSequentialDisplayPosition } from '../utilities/payment-method-rules';
import { getPaymentMethodsDisplayOrder } from '../selectors';
import { ThunkDispatcher, RootState } from '../../../stores/types';

interface SetPaymentMethodDisplayOrderOpts {
  paymentMethodId: string;
  display: boolean;
}

export default function setPaymentMethodRuleDisplayState({
  paymentMethodId,
  display,
}: SetPaymentMethodDisplayOrderOpts) {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
  ): void => {
    const state = getState();
    const pms = getPaymentMethodsDisplayOrder(state);

    const index = pms.findIndex(
      (pm: io.flow.v0.models.PaymentMethodRule) => pm.payment_method.id === paymentMethodId,
    );
    if (index > -1) {
      const updatedPm = setPaymentMethodDisplayState(pms[index], display);
      const updatedPms = [...pms];
      updatedPms.splice(index, 1, updatedPm);

      dispatch({
        type: ActionTypes.SET_PAYMENT_METHODS_DISPLAY_ORDER,
        payload: mapRulesWithSequentialDisplayPosition(updatedPms),
      });
    }
  };
}
