import { RouterState } from 'react-router';
import { RootState } from '../../../stores/types';

// Combine this utility with `withRedirect` or `withRedirectSubmitted` higher-order components to
// redirect user to correct route after successful login.
interface OwnProps extends RouterState {
  [key: string]: unknown;
}
export default function getPostLoginLocation(_state: RootState, ownProps: OwnProps): string {
  const returnUrlValue: string | string[] = ownProps.location.query.return_url || '';
  const returnUrl = Array.isArray(returnUrlValue) ? returnUrlValue[0] : returnUrlValue;
  const rootUrl = '/';

  let location = returnUrl || rootUrl;

  // Default incognito privacy settings actually block session storage and will crash the app
  try {
    if (typeof sessionStorage !== 'undefined') {
      const invitationToken = sessionStorage.getItem('invitationToken');
      if (invitationToken) {
        location = `/account/organization/invitations/${invitationToken}`;
      }
    }
  } catch (error) {
    console.warn('If you are coming from an invitation please try again in a non-incognito window');
  }

  return location;
}
