/**
 * Use these sizing tokens for font sizes.
 */
const fontSize = {
  100: '0.75rem',
  200: '0.875rem',
  300: '0.9375rem',
  400: '1rem',
  500: '1.0625rem',
  600: '1.125rem',
  700: '1.25rem',
  800: '1.375rem',
  900: '1.5rem',
} as const;

export default fontSize;
