import { createSelector } from 'reselect';
import get from 'lodash/get';
import getExclusionsExportDialog from './getExclusionsExportDialog';

const getIsExclusionExportDialogVisible = createSelector(
  getExclusionsExportDialog,
  (state) => get(state, 'visible') === true,
);

export default getIsExclusionExportDialogVisible;
