import { combineReducers } from 'redux';

import invoices from './invoices';
import order from './order';
import orderDetail from './order-detail';
import orderNotes from './orderNotes';
import refundOrderSummary from './refund-order-summary';
import replacementOrder from './replacementOrder';
import returns from './returns';
import returnShippingTiers from './return-shipping-tiers';
import shippingNotifications from './shippingNotifications';
import debugTransactions from './debug-transaction';
import ui from './ui';

export default combineReducers({
  invoices,
  order,
  orderDetail,
  debugTransactions,
  orderNotes,
  replacementOrder,
  refundOrderSummary,
  returns,
  returnShippingTiers,
  shippingNotifications,
  ui,
});
