import { createSelector } from 'reselect';

import getFeatures from './getFeatures';

const getFeatureList = createSelector(
  getFeatures,
  (features) => features.list.results || [],
);

export default getFeatureList;
