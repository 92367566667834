import { ErrorResponse, isError, ResourceApiResponse } from '../types/misc';
import { verifySDKSetup } from './utils';

const getHasResourceAccess = (resourceId?: string | number): Promise<boolean> => {
  try {
    verifySDKSetup();
  } catch (error) {
    return Promise.reject(error);
  }

  return new Promise((resolve) => {
    window.FB.api(`/${resourceId}`, (response: ErrorResponse | ResourceApiResponse) => {
      if (isError(response)) {
        resolve(false);
      } else {
        resolve(true);
      }
    });
  });
};

export default getHasResourceAccess;
