import invariant from 'invariant';
import ActionType from '../constants/ActionType';

/**
 * Creates an action responsible for displaying the exclusion rule delete confirmation.
 * @throws {InvariantError} when required arguments are not provided.
 * @param {ExclusionRule} exclusionRule The exclusion rule to be deleted.
 */
export default function showExclusionRuleDeleteConfirmation(
  organizationId: string,
  exclusionRule: io.flow.v0.models.ExclusionRule,
) {
  invariant(organizationId != null, 'An organization identifier must be provided');
  invariant(exclusionRule != null, 'An exclusion rule must be provided');
  return {
    type: ActionType.ShowExclusionRuleDeleteConfirmation,
    payload: { organizationId, exclusionRule },
  };
}
