import { Plus, Edit } from '@flowio/react-icons';
import { Box } from '@flowio/react-box';
import React, { MouseEventHandler } from 'react';
import update from 'immutability-helper';
import noop from 'lodash/noop';
import kebabCase from 'lodash/kebabCase';
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from '@flowio/react-dialog';

import {
  Card, CardHeader, CardContent, CardFooter, CardDescription, CardTitle,
} from '@flowio/react-card';
import { Button, OutlineButton } from '@flowio/react-button';

import {
  DragDropContext, Droppable, DropResult, DroppableProvided,
} from 'react-beautiful-dnd';
import ItemMarginSummary from './item-margin-summary';
import * as styles from './item-margins.styles';

interface Props {
  itemMargins: io.flow.v0.models.ItemMargin[];
  currency: string;
  editable: boolean;
  organizationCountry: string;
  onNewMargin: MouseEventHandler;
  onEditMargins: MouseEventHandler;
  onCancelEditMargins: MouseEventHandler;
  onSaveEditMargins: ({ marginSyncRequired, margins }:
  {
    marginSyncRequired: boolean;
    margins: io.flow.v0.models.ItemMargin[];
  }
  ) => void;
  onMarginEdit: (id: string) => void;
  onDeleteMargin: Function;
}

interface State {
  showDeleteDialog: boolean;
  marginSyncRequired: boolean;
  margins: io.flow.v0.models.ItemMargin[];
  itemMarginName?: string;
  itemMarginKey?: string;
}

class ItemMargins extends React.Component<Props, State> {
  static displayName = 'ItemMargins';

  static defaultProps = {
    currency: 'USD',
    onNewMargin: noop,
    onEditMargins: noop,
    onCancelEditMargins: noop,
    onSaveEditMargins: noop,
    onMarginEdit: noop,
    onDeleteMargin: noop,
    itemMargins: [],
    editable: undefined,
  };

  constructor(props: Props) {
    super(props);
    const { itemMargins } = this.props;
    this.state = {
      showDeleteDialog: false,
      marginSyncRequired: false,
      margins: itemMargins || [],
    };
  }

  static getDerivedStateFromProps(props: Props, state: State): Partial<State> | null {
    const { itemMargins } = props;
    const { margins } = state;

    if (margins.length > itemMargins.length) {
      return {
        margins: itemMargins,
      };
    }

    return state;
  }

  handleConfirmDelete = (): void => {
    const { onDeleteMargin } = this.props;
    const { itemMarginKey } = this.state;
    onDeleteMargin(itemMarginKey);
    this.hideDeleteDialog();
  };

  handleCancelDelete = (): void => {
    this.hideDeleteDialog();
  };

  handleSaveEditMargins = (e: React.MouseEvent): void => {
    const { onSaveEditMargins, onCancelEditMargins } = this.props;
    const { marginSyncRequired, margins } = this.state;
    const props = { marginSyncRequired };

    if (marginSyncRequired) {
      onSaveEditMargins({
        margins,
        ...props,
      });
    } else {
      onCancelEditMargins(e);
    }
  };

  handleMarginDelete = (id: string): void => {
    this.showDeleteDialog(id);
  };

  handleSummaryMove = (fromIndex: number, toIndex: number): void => {
    const { margins } = this.state;
    const dragMargin = margins[fromIndex];
    this.setState((prevState) => update(prevState, {
      marginSyncRequired: { $set: true },
      margins: {
        $splice: [
          [fromIndex, 1],
          [toIndex, 0, dragMargin],
        ],
      },
    }));
  };

  handleDrop = (dropResult: DropResult): void => {
    const {
      source: {
        index: source,
      },
    } = dropResult;

    const dest = dropResult.destination?.index;
    if (dest || dest === 0) {
      this.handleSummaryMove(source, dest);
    }
  };

  hideDeleteDialog(): void {
    this.setState({
      itemMarginName: '',
      showDeleteDialog: false,
    });
  }

  showDeleteDialog(key: string): void {
    const { itemMargins } = this.props;
    const margin = itemMargins.find((m) => m.key === key);
    if (margin) {
      this.setState({
        itemMarginName: margin.name,
        itemMarginKey: margin.key,
        showDeleteDialog: true,
      });
    }
  }

  render(): JSX.Element {
    const {
      currency,
      editable,
      onNewMargin,
      onMarginEdit,
      onEditMargins,
      onCancelEditMargins,
      organizationCountry,
    } = this.props;
    const { margins, itemMarginName, showDeleteDialog } = this.state;

    return (
      <Card id="functions" className="item-margins">
        <CardHeader dividing>
          <CardTitle content="Pricing Margins" />
          <CardDescription content="Organize your margin functions by priority from top to bottom. Margins are processed on an entire order." />
        </CardHeader>
        <CardContent>
          <DragDropContext onDragEnd={this.handleDrop}>
            <Droppable
              droppableId="item-margins"
              ignoreContainerClipping={false}
            >
              {(provided: DroppableProvided): JSX.Element => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {margins.map((itemMargin, index) => (
                    <ItemMarginSummary
                      itemMargin={itemMargin}
                      index={index}
                      currency={currency}
                      editable={editable}
                      onEdit={onMarginEdit}
                      onDelete={this.handleMarginDelete}
                      key={kebabCase(itemMargin.key)}
                      organizationCountry={organizationCountry}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <Dialog
            className={styles.deleteDialog}
            backdrop
            open={showDeleteDialog}
            onClose={this.handleCancelDelete}
          >
            <DialogHeader content="Delete Item Margin" />
            <DialogBody>
              Deleting this item margin will immediately impact the prices for
              items in this experience. Really delete item margin
              {' - '}
              {itemMarginName}
              ?
            </DialogBody>
            <DialogFooter>
              <Box alignContent="stretch">
                <Button className={styles.dialogCancel} content="Cancel" onClick={this.handleCancelDelete} />
                <Button className={styles.dialogDelete} content="Delete" intent="primary" onClick={this.handleConfirmDelete} />
              </Box>
            </DialogFooter>
          </Dialog>
        </CardContent>
        <CardFooter>
          {editable ? (
            <div className="right-align">
              <Button
                content="Cancel"
                onClick={onCancelEditMargins}
              />
              <OutlineButton
                className={styles.saveButton}
                content="Save"
                onClick={this.handleSaveEditMargins}
                intent="primary"
              />
            </div>
          ) : (
            <div className="right-align">
              <Button
                leftIcon={Plus}
                content="New Margin"
                onClick={onNewMargin}
              />
              <OutlineButton
                className={styles.editButton}
                content="Edit Margins"
                intent="primary"
                leftIcon={Edit}
                onClick={onEditMargins}
              />
            </div>
          )}
        </CardFooter>
      </Card>
    );
  }
}

export default ItemMargins;
