import ActionTypes from '../constants/action-types';
import { type RootActionTypes } from '../../../stores/types';

export default function showOrderTrackingDetailsDialog(
  orderNumber: string,
  labelSummary: io.flow.internal.v0.models.LabelSummary | undefined,
  shippingNotification: io.flow.v0.models.ShippingNotification | undefined,
): RootActionTypes {
  return {
    type: ActionTypes.SHOW_ORDER_TRACKING_DETAIL_DIALOG,
    payload: {
      orderNumber,
      labelSummary,
      shippingNotification,
    },
  };
}
