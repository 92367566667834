import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  RootActionTypes,
  RootState,
  ThunkDispatcher, ThunkResult,
} from '../../../../../stores/types';
import { goToAddFeedsPage, hideFacebookCountryFeedDialog, goToAddCountriesPage } from '../../../actions';
import DialogName from '../../../constants/DialogName';
import { getDialogProperty } from '../../../selectors';
import AddFacebookCountryFeedDialog from '../AddFacebookCountryFeedDialog';
import { DispatchProps, StateProps } from '../types';

const connectDialog = DialogName.FB_COUNTRY_FEED_DIALOG;

const handleCancel = (): ThunkResult<void> => (dispatch: ThunkDispatcher): void => {
  dispatch(hideFacebookCountryFeedDialog());
};

const handleAddCountryFeed = (
  organizationId: string,
  catalogId?: string,
): ThunkResult<void> => (dispatch: ThunkDispatcher) => {
  dispatch(hideFacebookCountryFeedDialog());
  return dispatch(catalogId ? goToAddCountriesPage({
    organizationId,
    catalogId: Number(catalogId),
  }) : goToAddFeedsPage({
    organizationId,
    platformId: 'facebook',
    isOnboarding: true,
  }));
};

const mapStateToProps = createStructuredSelector<RootState, {}, StateProps>({
  open: getDialogProperty(connectDialog, 'open'),
  platformId: getDialogProperty(connectDialog, 'platformId'),
  organizationId: getDialogProperty(connectDialog, 'organizationId'),
});

const mapDispatchToProps = (
  dispatch: Dispatch<RootActionTypes>,
): DispatchProps => bindActionCreators({
  onAddCountryClick: handleAddCountryFeed,
  onCancelClick: handleCancel,
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(AddFacebookCountryFeedDialog);
