import React from 'react';
import { isNotNil } from '@flowio/is';
import { Stack } from '@flowio/react-stack';
import { Label } from '@flowio/react-label';
import * as styles from './order-tracking-details-dialog.styles';

type Props = {
  label: io.flow.v0.models.ShippingLabel;
};

const LabelDetails: React.FC<Props> = ({
  label,
}) => {
  const hasDimension = isNotNil(label.package) && isNotNil(label.package.dimensions);
  const dims: (keyof io.flow.v0.models.Dimension)[] = ['weight', 'width', 'diameter', 'depth', 'length'];
  return (
    <Stack spacing="loose" className={styles.labelDetails}>
      <Stack orientation="vertical" distribution="fill" spacing="loose">
        <Stack distribution="fillEqually">
          <Label>Label Trigger Method</Label>
          <span>{label.label_trigger_method}</span>
        </Stack>
        <Stack distribution="fillEqually">
          <Label>Label Request Method</Label>
          <span>{label.label_request_method}</span>
        </Stack>
        <Stack distribution="fillEqually">
          <Label>Cost</Label>
          <span>{label.cost?.label}</span>
        </Stack>
        <Stack distribution="fillEqually">
          <Label>Cost Estimate Source</Label>
          <span>{label.cost_estimate_source}</span>
        </Stack>
        <Stack distribution="fillEqually">
          <Label>Rate Card Owner</Label>
          <span>{label.hop?.lane?.ratecard?.ratecard_owner}</span>
        </Stack>
        <Stack distribution="fillEqually">
          <Label>Rate Card ID</Label>
          <span>{label.hop?.lane?.ratecard?.id}</span>
        </Stack>
      </Stack>
      {hasDimension && (
      <Stack orientation="vertical">
        <Stack distribution="fillEqually">
          <Label>Package Dimensions</Label>
          <ul style={{ listStyleType: 'none', margin: 0, padding: 0 }}>
            {dims.map((dim) => {
              if (hasDimension) {
                const currDim = label.package.dimensions[dim];
                if (isNotNil(currDim)) {
                  return <li key={dim}>{currDim.value} {currDim.units} ({dim})</li>;
                }
              }
              return null;
            })}
          </ul>
        </Stack>
      </Stack>
      )}
    </Stack>
  );
};

export default LabelDetails;
