import { ExperiencesGetResponse } from '@flowio/api-sdk';
import ActionTypes from '../constants/action-types';
import withPagingAndSorting, { isPagingAndSortingResponse, WithPagingAndSortingOptions } from '../../../utilities/with-paging-and-sorting-v2';
import {
  ThunkResult,
  ThunkExtraArguments,
} from '../../../stores/types';
import { WithPagingAndSortingResponse } from '../../../utilities/types';
import { PropType } from '../../../types';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import toLegacyResponse from '../../../utilities/clients/to-legacy-response';
import standardAction from '../../../utilities/standard-action';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';

type ResponseType = PropType<ExperiencesGetResponse, 'body'>;

export default function fetchExperiences(
  organization: string,
  options: WithPagingAndSortingOptions = {},
): ThunkResult<Promise<
  WithPagingAndSortingResponse<io.flow.v0.models.Experience[]> | LegacyResponse<ResponseType>>
  > {
  return function fetchExperiencesEffects(
    dispatch,
    getState,
    extra: ThunkExtraArguments,
  ): Promise<WithPagingAndSortingResponse<io.flow.v0.models.Experience[]>
    | LegacyResponse<ResponseType>> {
    const mergedOptions = {
      limit: 16,
      sort: 'name',
      sortOrder: 'asc',
      ...options,
    };
    return withPagingAndSorting<io.flow.v0.models.Experience[]>(
      mergedOptions,
      (params: WithPagingAndSortingOptions) => {
        dispatch({
          type: ActionTypes.FETCH_EXPERIENCES_REQUEST,
        });

        return extra.api(getState()).experiences.get({
          organization,
          ...params,
        });
      },
    )().then((response) => {
      if (isPagingAndSortingResponse<io.flow.v0.models.Experience[]>(response)) {
        dispatch(standardAction(
          ActionTypes.FETCH_EXPERIENCES_SUCCESS,
          response.result,
        ));
        return response;
      }
      dispatch(standardAction(
        ActionTypes.FETCH_EXPERIENCES_FAILURE,
        formatErrorResponseV2(response),
      ));
      return toLegacyResponse<ResponseType>(response);
    });
  };
}
