import React, { useEffect } from 'react';
import { ArcLoader } from '@flowio/react-icons';
import ChooseOrganization from '../../../home/components/choose-organization';
import { ShopifyAuthProps } from '../../types/props';
import AuthLayout from '../auth-layout';
import ShopifyApp from '../../containers/ShopifyApp';

const ShopifyAuth: React.FC<ShopifyAuthProps> = ({
  needJwt,
  assignedOrg,
  auth,
  organizations,
  onOrganizationClick,
  incomingShop,
  redirectParams,
  onRedirect,
  redirectingInProgress,
}) => {
  useEffect(() => {
    // Maybe add a small timeout?
    if (needJwt && !redirectingInProgress) {
      onRedirect(redirectParams, auth);
    }
  });
  return (
    <div>
      {redirectingInProgress && (
        <AuthLayout>
          <span>Redirecting you to the proper page, sit tight</span>
          <ArcLoader />
        </AuthLayout>
      )}
      {assignedOrg && !redirectingInProgress && (
        <ShopifyApp />
      )}
      {!assignedOrg && !redirectingInProgress && (
        <ChooseOrganization
          className="shopify-auth"
          organizations={organizations}
          onOrganizationCardClick={
            (
              _event: Event,
              organization: io.flow.v0.models.Organization,
            ): void => onOrganizationClick(organization, incomingShop)
          }
        />
      )}
    </div>
  );
};

export default ShopifyAuth;
