import { createSelector } from 'reselect';
import { LaneDirection } from '@flowio/api-constants';
import get from 'lodash/get';
import filter from 'lodash/filter';

import getActiveShippingConfiguration from './getActiveShippingConfiguration';
import getOutboundShippingLanesPageNumber from './getOutboundShippingLanesPageNumber';
import { RootState } from '../../../stores/types';
import { PaginatedShippingLanes } from '../types/components';

export default createSelector<
RootState, io.flow.v0.models.ShippingConfiguration, number, PaginatedShippingLanes
>(
  getActiveShippingConfiguration,
  getOutboundShippingLanesPageNumber,
  (shippingConfiguration: io.flow.v0.models.ShippingConfiguration, pageNumber: number) => {
    const shippingLanes = get(shippingConfiguration, 'shipping_lanes');
    const outboundShippingLanes = filter(
      shippingLanes,
      (shippingLane) => shippingLane.direction === LaneDirection.OUTBOUND,
    );
    const begin = pageNumber * 10;
    const end = begin + 10;
    const paginatedOutboundLanes = outboundShippingLanes.slice(begin, end);

    return {
      pageNumber,
      shippingLanes: paginatedOutboundLanes,
      isFirstPage: begin === 0,
      isLastPage: end >= outboundShippingLanes.length,
    };
  },
);
