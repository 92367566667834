import React, { PureComponent } from 'react';
import noop from 'lodash/noop';
import kebabCase from 'lodash/kebabCase';
import {
  Card, CardHeader, CardContent, CardFooter, CardTitle,
} from '@flowio/react-card';
import {
  Table,
  TableHead,
  TableRow,
  TableHeadColumn,
  TableRowColumn,
  TableBody,
} from '@flowio/react-table';

import Line from './line';
import * as styles from './order-summary-check.styles';

interface OrderSummaryProps {
  orderEstimate: io.flow.v0.models.OrderEstimate;
  onQuantityChange: (...args: any[]) => void;
  onRemoveItem: (...args: any[]) => void;
}

class OrderSummary extends PureComponent<OrderSummaryProps> {
  static displayName = 'OrderSummary';

  handleQuantityChange = (number: string, quantity: number): void => {
    const { onQuantityChange } = this.props;
    onQuantityChange(number, quantity);
  };

  getItemName(number: string): string {
    const { orderEstimate } = this.props;
    const item = orderEstimate.items.find((i) => i.number === number);
    return item ? item.name : '-';
  }

  render(): React.ReactNode {
    const {
      orderEstimate,
      onQuantityChange = noop,
      onRemoveItem = noop,
    } = this.props;
    return (
      <Card>
        <CardHeader dividing>
          <CardTitle content="Order Summary" />
        </CardHeader>
        <CardContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeadColumn className={styles.lineNumber}>
                  Number
                </TableHeadColumn>
                <TableHeadColumn className="line-name">
                  Name
                </TableHeadColumn>
                <TableHeadColumn className={styles.lineQuantity}>
                  Quantity
                </TableHeadColumn>
                <TableHeadColumn className={styles.lineConvertedPrice}>
                  Converted Price
                </TableHeadColumn>
                <TableHeadColumn className={styles.lineTotal}>
                  Total
                </TableHeadColumn>
                <TableHeadColumn className="line-delete" />
              </TableRow>
            </TableHead>
            <TableBody>
              {(!!orderEstimate && orderEstimate.lines) ? orderEstimate.lines.map((line) => (
                <Line
                  key={kebabCase(line.item_number)}
                  line={line}
                  itemName={this.getItemName(line.item_number)}
                  onQuantityChange={onQuantityChange}
                  onRemoveItem={onRemoveItem}
                />
              )) : ''}
            </TableBody>
          </Table>
          <div className={styles.summary}>
            <Table className={styles.prices}>
              <TableHead>
                <TableRow>
                  <TableHeadColumn />
                  <TableHeadColumn>
                    Base
                  </TableHeadColumn>
                  <TableHeadColumn>
                    Local
                  </TableHeadColumn>
                </TableRow>
              </TableHead>
              <TableBody>
                {orderEstimate.prices.map((price) => (
                  <TableRow key={kebabCase(`${price.name} ${price.label}`)}>
                    <TableRowColumn>
                      {price.name}
                    </TableRowColumn>
                    <TableRowColumn>
                      {price.base.label}
                    </TableRowColumn>
                    <TableRowColumn>
                      {price.label}
                    </TableRowColumn>
                  </TableRow>
                ))}
                <TableRow>
                  <TableRowColumn>Total</TableRowColumn>
                  <TableRowColumn>
                    {orderEstimate.total.base.label}
                  </TableRowColumn>
                  <TableRowColumn>
                    {orderEstimate.total.label}
                  </TableRowColumn>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </CardContent>
        <CardFooter />
      </Card>
    );
  }
}

export default OrderSummary;
