import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';

import { RootState, ThunkDispatcher } from '../../../../../stores/types';
import { closeLabsAcceptDialog, openLabsAcceptDialog } from '../../../actions';
import { DispatchProps, OwnProps, StateProps } from '../types';
import LabsPage from '../components/LabsPage';
import { isLabsAcceptDialogOpen } from '../../../selectors';

const mapStateToProps: MapStateToProps<
StateProps,
OwnProps,
RootState
> = (state: RootState): StateProps => ({
  isOpen: isLabsAcceptDialogOpen(state),
});

const mapDispatchToProps: MapDispatchToProps<
DispatchProps,
OwnProps
> = (dispatch: ThunkDispatcher): DispatchProps => ({
  onAccept: () => {
    dispatch(openLabsAcceptDialog());
  },
  onCloseAcceptDialog: () => {
    dispatch(closeLabsAcceptDialog());
  },
  onAcceptConfirmation: (onAcceptLab: () => void) => {
    onAcceptLab();
    dispatch(closeLabsAcceptDialog());
  },
});

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(LabsPage);
