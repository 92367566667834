import uniqueId from 'lodash/uniqueId';
import get from 'lodash/get';
import ActionTypes from '../constants/ActionType';

import getRegions from '../utilities/getRegions';
import createQueryBuilder from '../utilities/createQueryBuilder';
import { toQueryBuilderQueryForm } from '../../features/utilities';
import { ThunkResult } from '../../../stores/types';

export default function showExclusionRuleWorksheet(
  organizationId: string,
  exclusionRule: io.flow.v0.models.ExclusionRule,
): ThunkResult<Promise<void>> {
  return function showExclusionRuleWorksheetEffects(dispatch, getState) {
    const state = getState();
    dispatch({
      type: ActionTypes.ShowExclusionRuleWorksheetRequest,
      payload: {
        organizationId,
        exclusionRule,
      },
    });
    return Promise.all([
      getRegions(state),
      createQueryBuilder(state, organizationId, toQueryBuilderQueryForm(get(exclusionRule, 'q'))),
    ]).then(([
      regions,
      queryBuilder,
    ]) => {
      dispatch({
        type: ActionTypes.ShowExclusionRuleWorksheetSuccess,
        payload: {
          exclusionRule,
          regions,
          queryBuilder: {
            ...queryBuilder,
            filters: queryBuilder.filters.map((filter: io.flow.v0.unions.QueryFilter) => ({
              ...filter, // Add a unique identifier that we can use as the component key for
              // FLIP animation to work correctly.
              // https://github.com/erikras/redux-form/issues/2735
              uniqueId: uniqueId('filter'),
            })),
          },
        },
      });
    }).catch((error) => {
      dispatch({
        type: ActionTypes.ShowExclusionRuleWorksheetFailure,
        payload: error.response ? error.response.result : error,
      });
    });
  };
}
