import { Box } from '@flowio/react-box';
import { Button } from '@flowio/react-button';
import { Dropdown, DropdownItem, DropdownMenu } from '@flowio/react-dropdown';
import React from 'react';
import { ItemSearchOptions, SearchFilter } from '../types';
import FilterTags from './filter-tags';
import ProductSearchFilter from './product-search-filter';

type Props = {
  setSelectedFilter: (value: keyof ItemSearchOptions | undefined) => void;
  onFilterSubmit: (searchType: keyof ItemSearchOptions, value: string | number | boolean) => void;
  onRemoveAllFilters: () => void;
  onRemoveFilter: (value: keyof ItemSearchOptions) => void;
  selectedFilter?: keyof ItemSearchOptions;
  toggledSearchFilters: SearchFilter[];
};

const ProductSearchTypeDropdown: React.FC<Props> = (
  {
    setSelectedFilter,
    selectedFilter,
    onFilterSubmit,
    toggledSearchFilters,
    onRemoveFilter,
    onRemoveAllFilters,
  },
) => {
  const searchTypes: { label: string; value: keyof ItemSearchOptions }[] = [
    { label: 'Number', value: 'number' },
    { label: 'ID', value: 'id' },
    { label: 'SKU', value: 'sku' },
    { label: 'Product Title', value: 'product_title' },
    { label: 'Product ID', value: 'product_id' },
    { label: 'Vendor', value: 'vendor' },
    { label: 'Barcode', value: 'barcode' },
    { label: 'Physical Good', value: 'physical_good' },
  ];

  return (
    <Box spacing="loose">
      <Dropdown
        onClose={(): void => setSelectedFilter(undefined)}
        trigger={(
          <Button
            type="button"
            content={'+ Add Search Filter'}
            intent="primary"
            disabled={false}
          />
        )}
      >
        {selectedFilter ? (
          <ProductSearchFilter selectedFilter={selectedFilter} onFilterSubmit={onFilterSubmit} />
        )
          : (
            <DropdownMenu>
              {searchTypes.map(({ value, label }) => (
                <DropdownItem
                  key={value}
                  disabled={toggledSearchFilters.some((filter) => filter.key === value)}
                  content={label}
                  onClick={(): void => setSelectedFilter(value)}
                />
              ))}
            </DropdownMenu>
          )}
      </Dropdown>

      <FilterTags
        onRemoveFilter={onRemoveFilter}
        onRemoveAllFilters={onRemoveAllFilters}
        searchFilters={toggledSearchFilters}
      />
    </Box>
  );
};

export default ProductSearchTypeDropdown;
