import React, { useEffect, useState } from 'react';
import { Form, InjectedFormProps, FormErrors } from 'redux-form';
import map from 'lodash/map';
import noop from 'lodash/noop';
import { TextField } from '@flowio/react-text-field';
import { Alert } from '@flowio/react-alert';
import * as styles from '../../ExportDialog/ExportDialog.styles';
import { WithValidationProps } from '../../../../../components/with-validation/with-validation';
import { ExportDialogFormData, MergedProps as Props } from '../types';
import getTextFieldValue from '../../../../../utilities/getTextFieldValue';

const ExportDialogForm: React.FC<
Props
& WithValidationProps
& InjectedFormProps<ExportDialogFormData, Props, FormErrors<ExportDialogFormData>>
> = ({
  onRequestExport,
  error,
  onSubmitValidate,
  submitted,
  fields: { email },
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(!!(error && error.messages));
  }, [error]);

  const handleSubmit = ({ isValid }: WithValidationProps, formData: ExportDialogFormData): void => {
    if (isValid) onRequestExport(formData);
  };

  return (
    <>
      {submitted ? (
        <p>
          Your request has been submitted successfully. We will send an
          email notification to
          {' '}
          <strong>{email.value}</strong>
          {' '}
          once the export
          CSV file is created.
        </p>
      ) : (
        <Form
          onSubmit={onSubmitValidate(handleSubmit)}
        >
          {error && error.messages && (
            <Alert open={isOpen} intent="negative" onConfirm={() => { setIsOpen(false); }}>
              {map(error.messages, ({ message }) => message).join('\n')}
            </Alert>
          )}
          <p>
            An email will be sent to the email address provided once the
            export file is generated.
          </p>
          <TextField
            onChange={(e) => email.events.onChange(e)}
            onBlur={(e) => email.events.onBlur(e)}
            inline
            fluid
            autoFocus
            type="email"
            className={styles.textField}
            name="email"
            value={getTextFieldValue(email.value)}
            intent={email.error ? 'negative' : 'neutral'}
            feedback={email.error}
            hintText="Enter your email address"
            labelCols={2}
            labelText="Email"
          />
        </Form>
      )}
    </>
  );
};

ExportDialogForm.displayName = 'ExportDialogForm';

ExportDialogForm.defaultProps = {
  defaultEmail: undefined,
  onRequestExport: noop,
  error: undefined,
  submitted: false,
};

export default ExportDialogForm;
