import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators, compose } from 'redux';
import {
  hideDeleteFeedConfirmationDialog,
  fetchFeeds,
  deleteFeed,
} from '../../../actions';
import {
  getShowDeleteFeedConfirmationDialog,
  getDeleteFeedConfirmationDialogData,
} from '../../../selectors';
import DeleteFeedConfirmationDialog from '../components/DeleteFeedConfirmationDialog';
import { ThunkDispatcher, RootState } from '../../../../../stores/types';
import { DeleteFeedConfirmationDialogProps } from '../../../types';
import { StateProps, DispatchProps, OwnProps } from '../types';

const handleDeleteFeed = (organizationId: string) => (feed: DeleteFeedConfirmationDialogProps) => (
  dispatch: ThunkDispatcher,
): void => {
  dispatch(deleteFeed({
    organizationId,
    feed: feed.id,
  })).then(() => {
    dispatch(hideDeleteFeedConfirmationDialog());
    dispatch(fetchFeeds({
      organizationId,
      channelId: feed.platform,
    }));
  }).catch();
};

const mapStateToProps = createStructuredSelector<RootState, {}, StateProps>({
  isOpen: getShowDeleteFeedConfirmationDialog,
  feedData: getDeleteFeedConfirmationDialogData,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatcher,
  props: OwnProps,
): DispatchProps => bindActionCreators({
  onCancel: hideDeleteFeedConfirmationDialog,
  onConfirmFeedDeletion: handleDeleteFeed(props.organizationId),
}, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(DeleteFeedConfirmationDialog);
