import { Confirm } from '@flowio/react-confirm';
import React from 'react';
import ShippingConfigurationDeleteForm from '../../containers/ShippingConfigurationDeleteForm';
import { ShippingConfigurationDeleteConfirmationProps } from '../../types/components';

const ShippingConfigurationDeleteConfirmation: React.FC<
ShippingConfigurationDeleteConfirmationProps
> = ({
  disabled = false,
  experiences = [],
  initialValues,
  onCancel,
  onOk,
  onSubmitSuccess,
  open = false,
}) => (
  <Confirm
    caption="Delete Shipping Configuration"
    disabled={disabled}
    intent="negative"
    onCancel={onCancel}
    onOk={onOk}
    open={open}
  >
    <ShippingConfigurationDeleteForm
      experiences={experiences}
      initialValues={initialValues}
      onSubmitSuccess={onSubmitSuccess}
    />
  </Confirm>
);

ShippingConfigurationDeleteConfirmation.displayName = 'ShippingConfigurationDeleteConfirmation';

ShippingConfigurationDeleteConfirmation.defaultProps = {
  experiences: [],
  disabled: false,
  initialValues: undefined,
  onCancel: undefined,
  onOk: undefined,
  onSubmitSuccess: undefined,
  open: false,
};

export default ShippingConfigurationDeleteConfirmation;
