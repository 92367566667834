import { createSelector } from 'reselect';
import getFacebookTokenSummary from './getFacebookTokenSummary';
import getIsTokenOwner from './getIsTokenOwner';
import TokenStatusTypes from '../constants/token-status-types';

const getIsTokenExpired = createSelector(
  getFacebookTokenSummary,
  getIsTokenOwner,
  (summary, isTokenOwner) => (
    isTokenOwner && summary?.token_status === TokenStatusTypes.expired
  ),
);

export default getIsTokenExpired;
