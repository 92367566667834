export default function toFeatureRuleForm(
  type: io.flow.internal.v0.enums.FeatureType,
  query: string,
  value?: string | boolean,
): io.flow.internal.v0.unions.FeatureRuleForm {
  if (type === 'string') {
    return {
      discriminator: 'string',
      query,
      value: value as string,
    };
  }

  return {
    discriminator: 'boolean',
    query,
    value: value as boolean,
  };
}
