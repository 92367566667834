import { FlowRole } from '@flowio/api-constants';

export function hasOnlyCustomerServiceRoles(membership: io.flow.v0.models.Membership): boolean {
  return membership.roles.length === 1
    && membership.roles[0] === FlowRole.ORGANIZATION_CUSTOMER_SERVICE;
}

export default function isCustomerServiceUser(
  organizationId: string,
  memberships: io.flow.v0.models.Membership[],
): boolean {
  const orgMembership = memberships.find(
    (membership) => membership.organization.id === organizationId,
  );
  return (
    (orgMembership || false)
    && hasOnlyCustomerServiceRoles(orgMembership)
  );
}
