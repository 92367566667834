import React from 'react';
import { TableRow, TableRowColumn } from '@flowio/react-table';
import { LaneDirection } from '@flowio/api-constants';
import classnames from 'classnames/bind';
import get from 'lodash/get';
import map from 'lodash/map';

import RegionLabel from '../../../reference/components/RegionLabel';
import findRegionById from '../../../reference/utilities/findRegionById';
import styles from './ShippingLaneListItem.module.css';
import { ShippingLaneListItemProps } from '../../types/components';

const cx = classnames.bind(styles);

const ShippingLaneListItem: React.FC<ShippingLaneListItemProps> = ({
  regions,
  shippingLane,
  shippingLaneDirection,
  onSelection,
  currentIndex,
}) => {
  const centerListText = map(shippingLane.centers, 'name').join(', ');
  const shippingTierList = get(shippingLane, 'tiers');
  const shippingTierListText = map(shippingTierList, 'name').join(', ');
  const region = findRegionById(regions, shippingLane.region);

  const renderRegion = region ? <RegionLabel region={region} /> : shippingLane.region;

  return (
    <TableRow selectable striped={currentIndex % 2 === 1} onClick={() => onSelection(shippingLane)}>
      <TableRowColumn>
        {shippingLaneDirection === LaneDirection.OUTBOUND ? (
          <span title={centerListText} className={cx('centers')} data-length={shippingLane.centers.length}>
            {centerListText}
          </span>
        ) : (
          renderRegion
        )}
      </TableRowColumn>
      <TableRowColumn>
        {shippingLaneDirection === LaneDirection.OUTBOUND ? (
          renderRegion
        ) : (
          <span title={centerListText} className={cx('centers')} data-length={shippingLane.centers.length}>
            {centerListText}
          </span>
        )}
      </TableRowColumn>
      <TableRowColumn title={shippingTierListText}>
        {shippingTierListText}
      </TableRowColumn>
    </TableRow>
  );
};

ShippingLaneListItem.displayName = 'ShippingLaneListItem';

export default ShippingLaneListItem;
