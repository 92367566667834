import update from 'immutability-helper';
import ActionTypes from '../constants/action-types';
import { ExperienceState } from '../types';
import { RootActionTypes } from '../../../stores/types';

type State = ExperienceState['entities']['paymentMethodRules'];

const initialState: State = [];

export default function (state: State = initialState, action: RootActionTypes): State {
  switch (action.type) {
    case ActionTypes.FETCH_PAYMENT_METHODS_DISPLAY_ORDER_SUCCESS:
    case ActionTypes.SET_PAYMENT_METHODS_DISPLAY_ORDER:
    case ActionTypes.UPDATE_PAYMENT_METHODS_DISPLAY_ORDER_SUCCESS:
      return update(state, {
        $set: [...action.payload].sort((a, b) => a.display_position - b.display_position),
      });
    default:
      return state;
  }
}
