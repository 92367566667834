import React from 'react';
import { useDispatch } from 'react-redux';
import { Field, Form, FormSpy } from 'react-final-form';
import { Card, CardContent, CardHeader } from '@flowio/react-card';
import { debounce, noop } from 'lodash';
import { Column, Grid, Row } from '@flowio/react-grid';
import TextField from '../../../../components/TextField';
import { ThunkDispatcher } from '../../../../stores/types';
import { updateShippingAddress } from '../../actions/replacementOrder';
import { AutoSaveState } from '../../types';
import { labelStyles } from './styles';

interface ShippingAddressFormProps {
  organizationId: string;
  order: io.flow.v0.models.Order;
  onStateChange: (state: AutoSaveState) => void;
  onError: (error: Error) => void;
}

let autoSaveState: AutoSaveState;

const ShippingAddressForm: React.FC<ShippingAddressFormProps> = ({
  organizationId,
  order,
  onStateChange,
  onError,
}) => {
  const dispatch = useDispatch<ThunkDispatcher>();

  const handleStateChange = (state: AutoSaveState) => {
    if (autoSaveState !== state) {
      autoSaveState = state;
      onStateChange(state);
    }
  };

  const saveToApi = debounce((address: io.flow.v0.models.OrderAddress) => {
    handleStateChange('saving');

    dispatch(updateShippingAddress(
      organizationId,
      order.number,
      address,
    ))
      .then(() => handleStateChange('saved'))
      .catch((error: Error) => {
        handleStateChange('error');
        onError(error);
      });
  }, 1500);

  const save = (values: io.flow.v0.models.OrderAddress) => {
    handleStateChange('dirty');
    saveToApi(values);
  };

  return (
    <Form
      onSubmit={noop}
      initialValues={order.destination}
      render={() => (
        <Card>
          <CardHeader caption="Shipping Address" dividing />
          <CardContent>
            <FormSpy
              subscription={{ values: true, touched: true, pristine: true }}
              onChange={(props) => {
                if (props.touched && !props.pristine) save(props.values);
              }}
            />
            <Grid gutter>
              <Row>
                <Column span={2} css={labelStyles}>First Name</Column>
                <Column span={4}>
                  <Field
                    component={TextField}
                    name="contact.name.first"
                    fluid
                  />
                </Column>
                <Column span={2} css={labelStyles}>Last Name</Column>
                <Column span={4}>
                  <Field
                    component={TextField}
                    name="contact.name.last"
                    fluid
                  />
                </Column>
              </Row>
              <Row>
                <Column span={2} css={labelStyles}>Company</Column>
                <Column span={4}>
                  <Field
                    component={TextField}
                    name="contact.company"
                    fluid
                  />
                </Column>
                <Column span={2} css={labelStyles}>Phone</Column>
                <Column span={4}>
                  <Field
                    component={TextField}
                    name="contact.phone"
                    fluid
                  />
                </Column>
              </Row>
              <Row>
                <Column span={2} css={labelStyles}>Address Line 1</Column>
                <Column span={4}>
                  <Field
                    component={TextField}
                    name="streets[0]"
                    fluid
                  />
                </Column>
                <Column span={2} css={labelStyles}>Address Line 2</Column>
                <Column span={4}>
                  <Field
                    component={TextField}
                    name="streets[1]"
                    fluid
                  />
                </Column>
              </Row>
              <Row>
                <Column span={2} css={labelStyles}>Address Line 3</Column>
                <Column span={4}>
                  <Field
                    component={TextField}
                    name="streets[2]"
                    fluid
                  />
                </Column>
              </Row>
              <Row>
                <Column span={2} css={labelStyles}>City/Town</Column>
                <Column span={4}>
                  <Field
                    component={TextField}
                    name="city"
                    fluid
                  />
                </Column>
                <Column span={2} css={labelStyles}>State/Province</Column>
                <Column span={4}>
                  <Field
                    component={TextField}
                    name="province"
                    fluid
                  />
                </Column>
              </Row>
              <Row>
                <Column span={2} css={labelStyles}>Postal Code</Column>
                <Column span={4}>
                  <Field
                    component={TextField}
                    name="postal"
                    fluid
                  />
                </Column>
                <Column span={2} css={labelStyles}>Country</Column>
                <Column span={4}>
                  <Field
                    component={TextField}
                    name="country"
                    fluid
                    readOnly
                  />
                </Column>
              </Row>
            </Grid>
          </CardContent>
        </Card>
      )}
    />
  );
};

export default ShippingAddressForm;
