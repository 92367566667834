import React, { CSSProperties } from 'react';
import { WrappedFieldInputProps, WrappedFieldMetaProps, WrappedFieldProps } from 'redux-form';
import { FormGroupIntent } from '@flowio/react-form-group';
import { SelectField, SelectFieldProps } from '@flowio/react-select';
import defaultShouldError from './utilities/defaultShouldError';
import defaultShouldWarn from './utilities/defaultShouldWarn';

type OwnProps = WrappedFieldProps &
{
  shouldError?: (
    input: WrappedFieldInputProps, meta: WrappedFieldMetaProps, props?: any
  ) => boolean,
  shouldWarn?: (input: WrappedFieldInputProps, meta: WrappedFieldMetaProps, props?: any) => boolean,
  labelKey: string,
  valueKey: string, options: any[],
  id?: string,
  scrollableMenu?: boolean,
};

type SelectFieldUnhandledProps = Omit<SelectFieldProps, keyof OwnProps>;

type ReduxFormSelectFieldProps = OwnProps & SelectFieldUnhandledProps;

const ReduxFormSelectField: React.FC<ReduxFormSelectFieldProps> = (props) => {
  const {
    input,
    meta,
    shouldWarn,
    shouldError, labelKey, valueKey, options, id, textInputProps, scrollableMenu, ...unhandledProps
  } = props;

  const { error, warning } = meta;

  let feedbackText;
  let intent: FormGroupIntent = 'neutral';

  if (error && shouldError && shouldError(input, meta)) {
    feedbackText = error;
    intent = 'negative';
  } else if (warning && shouldWarn && shouldWarn(input, meta)) {
    feedbackText = warning;
    intent = 'warning';
  }

  const handleValueChange = (value: string) => {
    const optionValue = options?.find((o) => o[valueKey] === value);
    input.onChange(optionValue);
  };

  const value = typeof input.value === 'object' ? input.value[valueKey] : input.value;

  const menuStyle: CSSProperties | undefined = scrollableMenu ? { ...unhandledProps.menuProps?.style, maxHeight: 280, overflowY: 'auto' } : { ...unhandledProps.menuProps?.style };

  return (
    <SelectField
      {...unhandledProps}
      options={options?.map((o) => ({ value: o[valueKey], content: o[labelKey] }))}
      onValueChange={handleValueChange}
      feedback={feedbackText}
      intent={intent}
      value={value}
      textInputProps={{
        ...textInputProps,
        name: input.name,
        onBlur: () => input.onBlur(undefined),
        onFocus: input.onFocus,
        id,
      }}
      menuProps={{ ...unhandledProps.menuProps, style: menuStyle }}
    />
  );
};

ReduxFormSelectField.defaultProps = {
  shouldError: defaultShouldError,
  shouldWarn: defaultShouldWarn,
  labelKey: 'content',
  valueKey: 'value',
};

export default ReduxFormSelectField;
