import { ArcLoader, Close } from '@flowio/react-icons';
import { css } from '@emotion/react';
import React from 'react';
import { Banner, BannerProps } from '@flowio/react-banner';
import { Box } from '@flowio/react-box';

import { RadioButton } from '@flowio/react-radio-button';
import {
  Table,
  TableHead,
  TableRow,
  TableRowColumn,
  TableHeadColumn,
  TableBody,
} from '@flowio/react-table';
import marketingTableStyle from '../../../styles/styles';
import spacing from '../../../../../theme/tokens/spacing';
import colors from '../../../../../theme/tokens/colors';

interface Props<T> {
  id: (item: T) => string | number;
  items?: T[];
  columns: Partial<Record<keyof T, string>>;
  onChange: (item: T) => void;
  selected?: string | number;
  isLoading?: boolean;
  isSelectable?: (item: T) => boolean;
  errorBanner?: BannerProps;
}

const marginStyle = `margin: ${spacing.marketing} 0;`;

const bannerStyle = `padding: ${spacing.xl} ${spacing.xxxl};`;

const mainContentStyles = (isTable = false) => css`
  ${isTable ? marketingTableStyle(14) : bannerStyle};
  ${marginStyle}
`;

const selectableColumnStyle = css`
  width: 100px;
`;

const notSelectableStyle = css`
  fill: ${colors.red[600]};
`;

const loadingContainerStyle = css`
  width: 100%;
  ${marginStyle}
`;

const loadingIconStyle = css`
  width: 50px;
  padding: 50px;
  opacity: 0.75;
  fill: ${colors.marketing['dark-slate-blue']};
`;

const selectableCellStyle = css`
  div label {
    padding: 0;
    span {
      margin-left: 0;
      margin-right: 0;
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/comma-dangle
const SelectableTable = <T, >({
  columns,
  items = [],
  id,
  selected,
  onChange,
  isLoading,
  isSelectable,
  errorBanner,
}: Props<T>) => {
  if (isLoading) {
    return (
      <Box css={loadingContainerStyle} alignContent="center" justifyContent="center">
        <ArcLoader css={loadingIconStyle} />
      </Box>
    );
  }

  const tableHead = (
    <TableHead>
      <TableRow>
        <TableHeadColumn textAlign="center" css={selectableColumnStyle}>Select</TableHeadColumn>
        {Object.keys(columns).map((key) => (
          <TableHeadColumn
            key={key}
          >
            {columns[key as keyof T]}
          </TableHeadColumn>
        ))}
      </TableRow>
    </TableHead>
  );

  const noEligibleSelection = isSelectable && !items.some(isSelectable);

  const tableBody = !isLoading && (
    <TableBody>
      {items.map((item: T) => (
        <TableRow>
          <TableRowColumn textAlign="center" css={selectableCellStyle}>
            {!isSelectable || isSelectable(item) ? (
              <RadioButton
                value={`${(id(item))}`} // id(item) can be number | string
                checked={selected === id(item)}
                onChange={(): void => onChange(item)}
              />
            ) : (
              <Close height={20} css={notSelectableStyle} />
            )}
          </TableRowColumn>
          {Object.keys(columns).map((key) => (
            <TableRowColumn
              key={`${id(item)}-${key}`}
            >
              {item[key as keyof T]}
            </TableRowColumn>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );

  return (
    <div>
      {noEligibleSelection && !!errorBanner && (
        <Banner intent="negative" css={mainContentStyles()} {...errorBanner} />
      )}
      {items.length !== 0 && (
        <Table css={mainContentStyles(true)}>
          {tableHead}
          {tableBody}
        </Table>
      )}
    </div>
  );
};

SelectableTable.defaultProps = {
  items: [],
  isLoading: undefined,
  selected: undefined,
  isSelectable: undefined,
  errorBanner: undefined,
};

SelectableTable.displayName = 'SelectableTable';

export default SelectableTable;
