import { css } from '@emotion/css';

export const row = css`
  line-height: 2rem;
  padding-bottom: 0.5rem;
`;

export const tooltipContainer = css`
  overflow: visible;
`;

export const tooltip = css`
  background-color: #77818f;
  border-radius: 0.25rem;
  color: white;
  font-size: 0.75rem;
  padding: 0.5rem;
  width: 170px;

  &:before {
    position: absolute;
    left: 88px;
    bottom: -7px;
    content: '';
    width: 0;
    height: 0;
    border-left: 0.3125rem solid transparent;
    border-right: 0.3125rem solid transparent;
    border-top: 0.5rem solid #77818f;
  }
`;

export const field = css`
&.label {
    font-weight: 500;
    color: #9ba6b3;
  }

  &.align-right {
    margin-left: 50px;
    text-align: right;
  }
`;

export const actionLabel = css`
  padding-right: 1rem;
`;

export const loaderContainer = css`
  text-align: center;
  position: relative;
  top: 100px;
`;

export const loaderIcon = css`
  height: 50px;
`;

export const loaderCopy = css`
  padding-top: 1rem;
  font-size: 1.125rem;
  font-weight: 300;
`;

export const divider = css`
  margin: 24px 0 18px 0;
`;

export const checkboxContainer = css`
  padding-bottom: 1rem;
`;

export const checkbox = css`
  margin-left: 19px;
`;

export const itemSelection = css`
  padding-left: 0;
  padding-right: 0;
`;

export const tierTooltip = css`
  display: inline-block;
`;

export const tooltipTrigger = css`
  height: 1rem;
  margin-left: 0.5rem;
  margin-bottom: 6px;
`;

export const errorBannerText = css`
  color: white;
`;
