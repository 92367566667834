import { shouldFetchFeatureValues } from '../selectors';

import ActionTypes from '../constants/action-types';
import createFeatureValuesQuery from '../utilities/createFeatureValuesQuery';
import { ThunkResult, RootActionTypes } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';

interface CheckFeatureParams {
  organizationId: string;
  featureContextForm?: io.flow.internal.v0.models.FeatureContextForm;
}

function createRequestAction(): RootActionTypes {
  return {
    type: ActionTypes.CHECK_FEATURE_VALUES_REQUEST,
  };
}

function createSuccessAction(
  payload: io.flow.internal.v0.models.FeatureValueResult,
): RootActionTypes {
  return {
    type: ActionTypes.CHECK_FEATURE_VALUES_SUCCESS,
    payload,
  };
}

function createFailureAction(payload: FormattedErrorMessages): RootActionTypes {
  return {
    type: ActionTypes.CHECK_FEATURE_VALUES_FAILURE,
    error: true,
    payload,
  };
}

/**
 * An action responsible for fetching ALL console related features given an
 * organization and optional evaluation context.
 * @param {String} props.organizationId
 * @param {?FeatureContextForm} props.featureContextForm
 */
function checkFeatureValues(params: CheckFeatureParams): ThunkResult<Promise<void>> {
  return function checkFeatureValuesEffects(
    dispatch,
    getState,
    extra,
  ): Promise<void> {
    const state = getState();
    const callApi = shouldFetchFeatureValues(state);
    if (callApi) {
      dispatch(createRequestAction());

      return extra.apiInternal(state).featureValues.post({
        organization: params.organizationId,
        body: {
          feature_query: createFeatureValuesQuery(params.organizationId),
          context: params.featureContextForm || {},
        },
      }).then((response) => {
        if (response.ok) {
          dispatch(createSuccessAction(response.body));
          return;
        }

        dispatch(createFailureAction(formatErrorResponseV2(response)));
      });
    }
    return Promise.resolve();
  };
}

export default checkFeatureValues;
