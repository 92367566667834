import { createSelector } from 'reselect';
import assign from 'lodash/assign';
import find from 'lodash/find';
import get from 'lodash/get';
import map from 'lodash/map';
import uniqueId from 'lodash/uniqueId';
import { isNotNil } from '@flowio/is';
import { getOrganization } from '../organization';

import { type RootState } from '../../stores/types';
import { type CustomerServiceState, type OrderItemDelivery } from './types';
import createDataStateStatusSelector from '../../utilities/redux/createDataStateStatusSelector';
import { type DataState } from '../../types';
import { type CustomerServiceUiState } from './reducers/ui';

/**
 * Returns the whole customer service state in the application state.
 * @param {Object} state An object representing the current application state.
 */
export const getCustomerServiceState = (
  state: RootState,
): CustomerServiceState => state.customerService;

export const getUser = (state: RootState) => state.user;

/**
 * Returns the whole customer service ui state in the application state.
 * @param {Object} state An object representing the current application state.
 */
export const getCustomerServiceUiState = createSelector<
RootState, CustomerServiceState, CustomerServiceUiState
>(
  getCustomerServiceState,
  ({ ui }) => ui,
);

export const getCustomerServiceInvoicesState = createSelector(
  getCustomerServiceState,
  ({ invoices }) => invoices,
);

/**
 * Returns the refund order summary in the application state.
 * @param {Object} state An object representing the current application state.
 */
export const getRefundOrderSummary = createSelector(
  getCustomerServiceState,
  ({ refundOrderSummary }) => refundOrderSummary,
);

export const getReturnShippingTiers = createSelector(
  getCustomerServiceState,
  ({ returnShippingTiers }) => returnShippingTiers,
);
/**
 * Returns the active order detail in the application state.
 * @param {Object} state An object representing the current application state.
 */
export const getOrderDetail = createSelector(
  getCustomerServiceState,
  ({ orderDetail }) => orderDetail.current,
);

/**
 * Returns the original order at submission time
 * @param {Object} state An object representing the current application state.
 */
export const getOriginalSubmittedOrder = createSelector(
  getCustomerServiceState,
  ({ orderDetail }) => orderDetail.currentOriginal,
);

/**
 * Returns the original order destination at submission time
 * @param {Object} state An object representing the current application state.
 */
export const getOriginalDestination = createSelector(
  getOriginalSubmittedOrder,
  (submittedOrder) => get(submittedOrder, 'destination'),
);

/**
 * Returns the order number for the active order detail in the application state.
 * @param {Object} state An object representing the current application state
 * @returns {?String}
 */
export const getOrderNumberOfCurrentOrderDetail = createSelector(
  getOrderDetail,
  (orderDetail) => {
    if (orderDetail.order) {
      return orderDetail.order.number;
    }

    return '';
  },
);

export const getAllocations = createSelector(
  getCustomerServiceState,
  ({ orderDetail }) => orderDetail.allocations,
);

export const getReturnConfirmationState = createSelector(
  getCustomerServiceState,
  getCustomerServiceUiState,
  (customerService, uiState) => ({
    showReturnConfirmation: uiState.showReturnConfirmation,
    returnData: {
      ...customerService.returns.return,
      orderItems: customerService.returns.items,
      inProgress: get(customerService, 'returns.inProgress'),
      error: get(customerService, 'returns.error'),
    },
  }),
);

export const getReturnDetailState = createSelector(
  getCustomerServiceUiState,
  (uiState) => ({
    showReturnDetailsDialog: uiState.returnDetailState.showReturnDetailsDialog,
    showReturnNumberState: uiState.returnDetailState.showReturnNumberInput,
    items: uiState.returnDetailState.items,
    pdfLabel: uiState.returnDetailState.pdfLabel,
    pdfInvoice: uiState.returnDetailState.pdfInvoice,
  }),
);

export const getOrderTrackingDetails = createSelector<RootState, CustomerServiceUiState, CustomerServiceUiState['orderTrackingDetails']>(
  getCustomerServiceUiState,
  (uiState) => ({
    ...uiState.orderTrackingDetails,
    showOrderTrackingDetailsDialog: get(uiState, 'orderTrackingDetails.showOrderTrackingDetailsDialog'),
  }),
);

/**
 * Returns the whole list of deliveries associated with the active order detail
 * in the applications state. Each delivery is normalized to include the detail
 * for each item and a `selected` property is injected to indicate whether the
 * delivery was selected by the order.
 * @param {Object} state An object representing the current application state.
 */
export const getOrderDeliveryList = createSelector(
  getOrderDetail,
  ({ order }): OrderItemDelivery[] => {
    if (!order) {
      return [];
    }

    return order.deliveries.map((delivery, index) => {
      const castedDelivery = delivery as io.flow.v0.models.PhysicalDelivery;

      const items = castedDelivery.items
        .map((item) => assign({}, item, find(order.items, { number: item.number })))
        .map((item) => assign({}, item, find(order.lines, { item_number: item.number })))
        .map((item) => ({ ...item, cid: uniqueId() }));
      const options = castedDelivery.options ? map(castedDelivery.options,
        (option) => assign({}, option, {
          selected: order.selections && option.id === order.selections[index],
        })) : [];

      return assign({}, castedDelivery, { items, options });
    });
  },
);

/**
 * Returns the whole list of order details reduced in the application state as
 * a result of performing a search. It includes page information.
 * @param {Object} state An object representing the current application state.
 */
export const getOrderDetailSearchResults = createSelector(
  getCustomerServiceState,
  ({ orderDetail }) => ({
    isFirstPage: orderDetail.isFirstPage,
    isLastPage: orderDetail.isLastPage,
    orderDetails: orderDetail.results,
    query: orderDetail.params && orderDetail.params.q,
  }),
);

/**
 * Returns the state of the refund dialog in the application state.
 * @param {Object} state An object representing the current application state.
 */
export const getRefundDialogState = createSelector(
  getCustomerServiceUiState,
  getRefundOrderSummary,
  (uiState, orderSummary) => ({
    showRefundFormDialog: orderSummary && !orderSummary.error && uiState.showRefundFormDialog,
  }),
);

/**
 * Returns the state of the return dialog in the application state.
 * @param {Object} state An object representing the current application state.
 */
export const getReturnDialogState = createSelector(
  getCustomerServiceUiState,
  getReturnShippingTiers,
  (uiState, shippingTiers) => ({
    showReturnFormDialog: shippingTiers && uiState.showReturnFormDialog,
  }),
);

/**
 * Returns the state of the approve fraud review dialog
 * @param {Object} state An object representing the current application state.
 */
export const getApproveFraudReviewDialogState = createSelector(
  getCustomerServiceUiState,
  (state) => get(state, 'showApproveFraudReviewDialog'),
);

/**
 * Returns the state of the approve fraud review dialog
 * @param {Object} state An object representing the current application state.
 */
export const getDeclineFraudReviewDialogState = createSelector(
  getCustomerServiceUiState,
  (state) => get(state, 'showDeclineFraudReviewDialog'),
);

/**
 * Returns notification message that should be displayed.
 * @param {Object} state An object representing the current application state.
 */
export const getNotificationMessage = createSelector(
  getCustomerServiceUiState,
  ({ notification }) => notification,
);

export const getRefundObj = createSelector(
  getCustomerServiceUiState,
  (state) => get(state, 'refundObj'),
);

export const getOrders = createSelector(
  getCustomerServiceState,
  (state) => get(state, 'order.results'),
);

export const getOrderDetailsUrl = createSelector(
  getOrganization,
  getOrders,
  (organization, orders) => {
    if (orders.length === 0) {
      return '/404';
    }

    if (orders.length === 1) {
      return `/${organization.id}/orders/${orders[0].number}`;
    }

    // Pick the first order until we know that multiple orders is an actual use case.
    if (orders.length > 1) {
      // eslint-disable-next-line
      console.log('Warning: There were more than 1 orders to redirect to. Picking the first one.');
      return `/${organization.id}/orders/${orders[0].number}`;
    }

    return undefined;
  },
);

export const getBusinessCreditMemos = createSelector(
  getCustomerServiceInvoicesState,
  (state) => state.results.businessCreditMemos,
);

export const getBusinessInvoices = createSelector(
  getCustomerServiceInvoicesState,
  (state) => state.results.businessInvoices,
);

export const getConsumerInvoices = createSelector(
  getCustomerServiceInvoicesState,
  (state) => state.results.consumerInvoices,
);

export const getCreditMemos = createSelector(
  getCustomerServiceInvoicesState,
  (state) => state.results.creditMemos,
);

export const getOrderNotes = createSelector(
  getCustomerServiceState,
  ({ orderNotes }) => createDataStateStatusSelector(orderNotes),
);

export const getReplacementOrderBuilder = createSelector(
  getCustomerServiceState,
  ({ replacementOrder }) => createDataStateStatusSelector(replacementOrder),
);

export const getShippingLabels = createSelector(
  getCustomerServiceState,
  ({ orderDetail }) => orderDetail.shippingLabels,
);

export const getShippingLabelErrors = createSelector(
  getCustomerServiceState,
  ({ orderDetail }) => orderDetail.shippingLabelsErrors,
);

export const getShippingNotifications = createSelector(
  [
    getCustomerServiceState,
    // Take the second arg, `category`, and forward to the output selector
    (
      _state: RootState,
      organizationId: string,
      orderNumber: string,
    ) => [organizationId, orderNumber],
  ],
  // Output selector gets (`items, category)` as args
  (
    custServState,
    [organizationId, orderNumber],
  ) => {
    const orgState: Record<
    string, DataState<io.flow.v0.models.ShippingNotification[]>
    > = custServState.shippingNotifications[organizationId];
    if (isNotNil(orgState) && isNotNil(orgState[orderNumber])) {
      return orgState[orderNumber].data;
    }
    return undefined;
  },
);

export const getAddressValidation = createSelector(
  getCustomerServiceState,
  ({ orderDetail }) => orderDetail.addressValidation,
);

export const getDebugTransactions = createSelector(
  getCustomerServiceState,
  ({ debugTransactions }) => debugTransactions,
);
