import { FeaturesGetByKeyResponse } from '@flowio/api-internal-sdk';
import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import ActionTypes from '../constants/action-types';

function createRequestAction(): RootActionTypes {
  return {
    type: ActionTypes.FETCH_FEATURE_BY_KEY_REQUEST,
  };
}

function createSuccessAction(payload: io.flow.internal.v0.models.Feature): RootActionTypes {
  return {
    type: ActionTypes.FETCH_FEATURE_BY_KEY_SUCCESS,
    payload,
  };
}

function createFailureAction(payload: FormattedErrorMessages): RootActionTypes {
  return {
    type: ActionTypes.FETCH_FEATURE_BY_KEY_FAILURE,
    payload,
  };
}

export default function fetchFeatureByKey(
  organizationId: string,
  featureKey: string,
): ThunkResult<Promise<FeaturesGetByKeyResponse>> {
  return function fetchFeatureByKeyEffects(
    dispatch,
    getState,
    extra,
  ): Promise<FeaturesGetByKeyResponse> {
    dispatch(createRequestAction());

    return extra.apiInternal(getState()).features.getByKey({
      organization: organizationId,
      key: featureKey,
    }).then((response) => {
      if (response.ok) {
        dispatch(createSuccessAction(response.body));
        return response;
      }

      const error = formatErrorResponseV2(response);
      dispatch(createFailureAction(error));
      return response;
    });
  };
}
