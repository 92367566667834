import React, { useState } from 'react';
import BemHelper from '@flowio/bem-helper';
import map from 'lodash/map';
import { Button } from '@flowio/react-button';
import { PanelGroup } from '@flowio/react-panel';
import WebhookDeliveryPanel from '../webhook-delivery-panel/webhook-delivery-panel';

if (process.browser) {
  require('./webhook-deliveries.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('webhook-deliveries');

type Props = {
  deliveries: io.flow.v0.models.WebhookDelivery[];
  hasMoreDeliveries?: boolean;
  onRequestRedeliver?: () => void;
  onRequestMoreDeliveries?: (args: {
    entriesPerPage: number;
    pageNumber: number;
  }) => void;
};

const WebhookDeliveries: React.FC<Props> = ({
  onRequestMoreDeliveries,
  deliveries = [],
  onRequestRedeliver,
  hasMoreDeliveries,
}) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [entriesPerPage] = useState(25);

  const handleLoadMoreDeliveries = () => {
    setPageNumber((current) => current + 1);

    if (onRequestMoreDeliveries) {
      onRequestMoreDeliveries({
        entriesPerPage,
        pageNumber,
      });
    }
  };

  return (
    <PanelGroup className={bem.block()}>
      {map(deliveries, (delivery) => (
        <WebhookDeliveryPanel
          key={delivery.id}
          delivery={delivery}
          onRequestRedeliver={onRequestRedeliver}
        />
      ))}
      {hasMoreDeliveries && (
        <Button
          css={{ marginTop: '1rem', display: 'flex', width: '100%' }}
          content="Load more deliveries"
          onClick={handleLoadMoreDeliveries}
        />
      )}
    </PanelGroup>
  );
};
WebhookDeliveries.displayName = 'WebhookDeliveries';

export default WebhookDeliveries;
