import React, { useState } from 'react';
import { Link } from 'react-router';
import { SelectField } from '@flowio/react-select';
import { Button } from '@flowio/react-button';
import { css } from '@emotion/css';
import MarketingCard, { MarketingCardHeading, MarketingCardActions } from '../../MarketingCard';
import MarketingPage from '../../MarketingPage';
import Platforms from '../../../constants/Platforms';
import { Props } from '../types';
import FeedsExplainer from '../../FeedsExplainer';
import colors from '../../../../../theme/tokens/colors';

const dataSourceSelectionStyles = css`
  width: 480px;
  margin: 40px 0 !important;

  & legend span,
  & input {
    color: ${colors.marketing['dark-slate-blue']} !important;
  }
`;

type FeedSourceOption = {
  value: string,
  content: string;
};

const sourceToSourceOption = (
  source: io.flow.internal.v0.unions.MarketingGatewayFeedSource,
): FeedSourceOption => ({
  value: source.name,
  content: `${source.name} (${source.country})`,
});

const AddChannels: React.FC<Props> = ({
  params: {
    platformId,
    organization,
  },
  onCancel,
  onConfirm,
  feedSources,
}) => {
  const { platformName, shortName } = Platforms(platformId);
  const [selectedSource, setSelectedSource] = useState<
  io.flow.internal.v0.unions.MarketingGatewayFeedSource | undefined
  >(
    feedSources.length === 1 ? feedSources[0] : undefined,
  );

  const handleChange = (value: string): void => {
    setSelectedSource(feedSources.find((source) => source.name === value));
  };

  const handleConfirm = () => {
    if (selectedSource) {
      onConfirm(selectedSource);
    }
  };

  const breadcrumbItems = [
    {
      content: 'Product Feeds',
      as: Link,
      to: `/${organization}/marketing`,
    },
    {
      content: `Add ${shortName}`,
      active: true,
    },
  ];

  return (
    <MarketingPage
      breadcrumbItems={breadcrumbItems}
      heading={`Add ${platformName}`}
    >
      <FeedsExplainer />
      <MarketingCard boxShadow>
        <MarketingCardHeading
          title="Select a Product Data Source"
          subtitle="Please select a product feed to import product IDs, titles, and descriptions for Criteo-specific localized product feeds. You may change the data source thereafter by contacting support@flow.io."
          showDivider={false}
        />
        <SelectField
          className={dataSourceSelectionStyles}
          textInputProps={{
            id: 'product-data-source-dropdown',
          }}
          fluid
          hintText="Select a product feed..."
          labelText={`What product feed should Flow use as the product data source for ${platformName}?`}
          value={selectedSource?.name}
          onValueChange={handleChange}
          options={feedSources.map(sourceToSourceOption)}
        />
        <MarketingCardActions showDivider={false}>
          <Button onClick={onCancel} content="Cancel" />
          <Button data-automation-id="generate-feeds-btn" onClick={handleConfirm} intent="primary" content={`Generate ${platformName} Feeds`} disabled={!selectedSource} />
        </MarketingCardActions>
      </MarketingCard>
    </MarketingPage>
  );
};

AddChannels.displayName = 'AddChannels';

export default AddChannels;
