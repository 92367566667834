import get from 'lodash/get';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import { ExperienceFormValues } from '../types';

/**
 * @param {?ExperienceFormValues} values
 */
export function toExperienceForm(values: ExperienceFormValues): io.flow.v0.models.ExperienceForm {
  if (!values.name || !values.regionId) {
    throw new Error('toExperienceForm: Missing require properties [name, regionId]');
  }
  return {
    name: values.name,
    region_id: values.regionId,
    ...omitBy({
      country: get(values, 'country'),
      currency: get(values, 'currency'),
      language: get(values, 'language'),
      measurement_system: get(values, 'measurementSystem'),

      subcatalog_id: get(values, 'subcatalogId'),
    }, isNil),
  };
}

/**
 * @param {?String} organizationId
 * @param {?Experience} experience
 */
export function toExperienceFormValues(
  organizationId: string,
  experience?: io.flow.v0.models.Experience,
): ExperienceFormValues {
  return {
    name: get(experience, 'name'),
    organizationId,
    regionId: get(experience, 'region.id'),
    ...omitBy({
      country: get(experience, 'country'),
      currency: get(experience, 'currency'),
      key: get(experience, 'key'),
      language: get(experience, 'language'),
      measurementSystem: get(experience, 'measurement_system'),
      subcatalogId: get(experience, 'subcatalog.id'),
    }, isNil),
  };
}
