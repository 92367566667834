import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { browserHistory } from 'react-router';
import { isSubmitting, submit } from 'redux-form';

import { deleteDialog } from '../actions';
import { createToast } from '../../console/actions';
import { getDialogProperty } from '../selectors';
import { ActionTypes, DialogName, FormName } from '../constants';
import ShippingLaneWorksheet from '../components/ShippingLaneWorksheet';
import { RootState, ThunkDispatcher } from '../../../stores/types';
import { CreateShippingLaneSuccess, UpdateShippingLaneSuccess } from '../types/actions';
import { ShippingLaneWorksheetDispatchProps, ShippingLaneWorksheetStateProps } from '../types/components';

const dialogName = DialogName.SHIPPING_LANE_WORKSHEET;
const formName = FormName.SHIPPING_LANE_FORM;

function handleCancel() {
  return deleteDialog({ type: dialogName });
}

function handleSave() {
  return submit(formName);
}

function handleSuccess(result: UpdateShippingLaneSuccess | CreateShippingLaneSuccess) {
  return function handleSuccessSideEffects(dispatch: ThunkDispatcher) {
    dispatch(deleteDialog({ type: dialogName }));
    if (result.type === ActionTypes.UPDATE_SHIPPING_LANE_SUCCESS) {
      dispatch(createToast({ content: 'Shipping lane has been updated' }));
    } else if (result.type === ActionTypes.CREATE_SHIPPING_LANE_SUCCESS) {
      const { organizationId, shippingConfigurationKey } = result.meta;
      const shippingLaneId = result.payload.id;
      dispatch(createToast({ content: 'Shipping lane has been added' }));
      browserHistory.push(`/${organizationId}/logistics/shipping/configurations/${shippingConfigurationKey}/lanes/${shippingLaneId}`);
    }
  };
}

const mapStateToProps = createStructuredSelector<RootState, ShippingLaneWorksheetStateProps>({
  availableFilters: getDialogProperty(dialogName, 'availableFilters'),
  initialValues: getDialogProperty(dialogName, 'initialValues'),
  isLoading: getDialogProperty(dialogName, 'loading'),
  isOpen: getDialogProperty(dialogName, 'open'),
  isSubmitting: isSubmitting(formName),
  method: getDialogProperty(dialogName, 'method'),
  regions: getDialogProperty(dialogName, 'regions'),
  title: getDialogProperty(dialogName, 'title'),
});

const mapDispatchToProps = (dispatch: ThunkDispatcher) => bindActionCreators({
  onCancel: handleCancel,
  onSave: handleSave,
  onSuccess: handleSuccess,
}, dispatch);

export default connect<
ShippingLaneWorksheetStateProps,
ShippingLaneWorksheetDispatchProps,
{},
RootState
>(mapStateToProps, mapDispatchToProps)(ShippingLaneWorksheet);
