import { css } from '@emotion/css';

export const itemQuantityInput = css`
  width: 20px;

  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const itemInputIcon = css`
  display: none !important;
`;

export const returnItemsFirstCol = css`
  padding-left: 16px !important;
`;

export const returnItemsSecondCol = css`
  display: flex;
  align-items: center;
`;

export const itemQtyTextInputLabel = css`
  margin-left: 5px;
`;
