import OrganizationCreate from '../../../modules/account/components/organization-create/containers/organization-create';
import OrganizationInvitations from '../../../modules/account/components/organization-invitations/containers/organization-invitations';
import AccountProfile from '../../../modules/account/containers/profile';
import EmailVerification from '../../../modules/account/components/email-verification/containers/email-verification';
import Exports from '../../../modules/account/containers/exports';

export default {
  path: 'account',
  childRoutes: [
    {
      path: 'organization/create',
      component: OrganizationCreate,
      hideNavigation: true,
      title: 'Create Organization',
    },
    {
      path: 'organization/invitations/:token',
      component: OrganizationInvitations,
      hideNavigation: true,
      title: 'Invitations',
    },
    {
      path: 'profile',
      component: AccountProfile,
      hideLeftNavigation: false,
      title: 'Account Settings',
    },
    {
      path: 'email/verify/:token',
      component: EmailVerification,
      authRequired: false,
      hideNavigation: true,
      title: 'Verify Email',
    },
    {
      path: 'exports',
      component: Exports,
      hideLeftNavigation: false,
    },
  ],
};
