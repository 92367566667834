import ActionTypes from '../constants/ActionTypes';
import { apiInternal } from '../../../utilities/clients';

export default function requeueFailure(db) {
  return {
    types: [
      ActionTypes.REQUEUE_FAILURES_REQUEST,
      ActionTypes.REQUEUE_FAILURES_SUCCESS,
      ActionTypes.REQUEUE_FAILURES_FAILURE,
    ],
    callAPI(state) {
      return apiInternal.alertRequeueSummaries(state).post(db);
    },
    payload: { db },
  };
}
