import React from 'react';
import { Worksheet } from '@flowio/react-worksheet';
import EditExperimentForm from '../../containers/edit-experiment-form';

interface Props {
  open: boolean;
  organizationId: string;
  onClose: () => void;
}

const EditExperimentWorksheet: React.FC<Props> = ({
  open,
  organizationId,
  onClose,
}) => (
  <Worksheet open={open} backdrop closeOnClickAway closeOnEscape>
    <EditExperimentForm organizationId={organizationId} onClose={onClose} />
  </Worksheet>
);

EditExperimentWorksheet.displayName = 'EditExperimentWorksheet';

export default EditExperimentWorksheet;
