import { combineReducers } from 'redux';

import exclusionRules from './exclusionRules';
import exclusionRuleWorksheet from './exclusionRuleWorksheet';
import exclusionRuleDeleteConfirmation from './exclusionRuleDeleteConfirmation';
import exclusionRulesForm from './exclusionRulesForm';
import exclusionsExportDialog from './exclusionsExportDialog';
import { ExclusionState } from '../types';

export default combineReducers<ExclusionState>({
  dialogs: combineReducers({
    exclusionsExportDialog,
    exclusionRuleDeleteConfirmation,
    exclusionRuleWorksheet,
  }),
  entities: combineReducers({
    exclusionRules,
    exclusionRulesForm,
  }),
});
