import update from 'immutability-helper';
import { ActionTypes } from '../constants';

const defaultState = {};

export default function (state = defaultState, action) {
  switch (action.type) {
    case ActionTypes.CREATE_CENTER_QUERY_BUILDER_SUCCESS:
      return update(state, { $set: action.payload });
    default:
      return state;
  }
}
