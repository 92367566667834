import update from 'immutability-helper';
import ActionTypes from '../constants/ActionTypes';

const defaultState = {
  users: [],
};

export default function pendingUsersReducer(state = defaultState, action) {
  switch (action.type) {
    case ActionTypes.FETCH_PENDING_USERS_SUCCESS:
      return update(state, { users: { $set: action.payload } });
    default:
      return state;
  }
}
