const DELIMITER = '-';
const properties: Array<(keyof Feed)> = ['country', 'language', 'currency'];

interface Feed {
  country: string;
  language: string;
  currency: string;
}

export const encodeFeedSource = (
  source: Feed,
): string | undefined => properties.map((property) => source[property]).join(DELIMITER);

export const decodeFeedSource = (source: string): Feed => {
  const split = source.split(DELIMITER);
  return split.reduce((acc, val, index) => {
    acc[properties[index]] = val;
    return acc;
  }, {} as Feed);
};

export const doesMatch = (
  encoded: string,
  source: Feed,
): boolean => encoded === encodeFeedSource(source);
