import { createSelector } from 'reselect';
import identity from 'lodash/identity';
import getDialogProp from './getDialogProp';

const getIsDialogOpen = (dialog: string) => createSelector(
  identity,
  getDialogProp(dialog, 'isOpen'),
);

export default getIsDialogOpen;
