import React from 'react';
import { css } from '@emotion/css';
import ChooseOrganization from './choose-organization';

const homeStyles = css`
  display: flex;
  align-items: center;
  min-height: calc(100vh - 68px);
  background-color: #f2f5f7;
`;

const defaultProps = {
  organizations: [],
};

type Props = {
  organizations: io.flow.v0.models.Organization[];
  onOrganizationCardClick?: (...args: any) => void;
  onRedirectToDefaultOrg?: (organization: io.flow.v0.models.Organization) => void;
};

const Home: React.FC<Props> = ({
  organizations,
  onOrganizationCardClick,
  onRedirectToDefaultOrg: redirectToDefaultOrg,
}) => (
  <section className={homeStyles}>
    <ChooseOrganization
      organizations={organizations}
      onOrganizationCardClick={onOrganizationCardClick}
      onRedirectToDefaultOrg={redirectToDefaultOrg}
    />
  </section>
);

Home.defaultProps = defaultProps;

export default Home;
