import moment from 'moment';
import { getUserId } from '../../console';
import { ActionTypes } from '../constants';
import { ExportsFormValues } from '../components/scheduled-export-form/types';
import {
  ThunkDispatcher, RootState, ThunkExtraArguments, ThunkResult,
} from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import toLegacyResponse from '../../../utilities/clients/to-legacy-response';

const getScheduledExportTypes = (formData: ExportsFormValues): io.flow.v0.unions.ExportType[] => {
  switch (formData.types.value) {
    case 'unharmonized_item_export_type':
      return [{
        discriminator: 'unharmonized_item_export_type',
      }];
    case 'order_export_type':
      return [{
        discriminator: 'order_export_type',
        min_hours_since_submitted_at:
        (formData as io.flow.v0.models.OrderExportType).min_hours_since_submitted_at,
        has_tracking_label: (formData as io.flow.v0.models.OrderExportType).has_tracking_label,
      }];
    default:
      throw new Error(`unhandled export type: ${formData.types}`);
  }
};

export default function createScheduledExport(
  formData: ExportsFormValues,
): ThunkResult<Promise<LegacyResponse<
  io.flow.v0.models.ScheduledExport | FormattedErrorMessages
  >>> {
  return function createScheduledExportEffects(
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<LegacyResponse<io.flow.v0.models.ScheduledExport | FormattedErrorMessages>> {
    dispatch({
      type: ActionTypes.CREATE_SCHEDULED_EXPORT_REQUEST,
    });
    const state = getState();
    const userId = getUserId(state);
    const convertedHour = parseInt(moment(`${formData.hour.value} ${formData.timeFormat.value}`, 'HH A').format('H'), 10);
    const m = moment({ hour: convertedHour, minute: formData.minute.value });

    return extra.api(getState()).scheduledExports.post({
      body: {
        organization_q: formData.organization_q,
        hour: m.utc().hours(),
        minute: m.utc().minutes(),
        timezone: formData.timezone.value,
        types: getScheduledExportTypes(formData),
        user_id: userId,
      },
    }).then((response) => {
      if (response.ok) {
        dispatch({
          type: ActionTypes.CREATE_SCHEDULED_EXPORT_SUCCESS,
          payload: response.body,
        });
        return toLegacyResponse(response);
      }
      const formatedErrors = formatErrorResponseV2(response);
      dispatch({
        type: ActionTypes.CREATE_SCHEDULED_EXPORT_FAILURE,
        payload: formatedErrors,
      });
      return toLegacyResponse({
        ...response,
        body: formatedErrors,
      });
    });
  };
}
