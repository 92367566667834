import { ExperiencesGetItemsAndPriceByKeyAndNumberResponse } from '@flowio/api-sdk';
import { RootActionTypes, ThunkResult, ThunkExtraArguments } from '../../../stores/types';
import ActionTypes from '../constants/action-types';
import toLegacyResponse from '../../../utilities/clients/to-legacy-response';
import { PropType } from '../../../types';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import standardAction from '../../../utilities/standard-action';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';

type ResponseType = PropType<ExperiencesGetItemsAndPriceByKeyAndNumberResponse, 'body'>;

function fetchItemPriceDetailsRequest(): RootActionTypes {
  return {
    type: ActionTypes.GET_ITEM_PRICE_DETAILS_REQUEST,
  };
}

// TODO conversion-revisit
function fetchItemPriceDetailsSuccess(
  itemPrice: io.flow.v0.models.PriceCheck,
  item: io.flow.v0.models.Item,
): RootActionTypes {
  return {
    type: ActionTypes.GET_ITEM_PRICE_DETAILS_SUCCESS,
    payload: itemPrice,
    item,
  };
}

// TODO conversion-revisit
export default function fetchItemPriceDetails(
  organization: string,
  experienceKey: string,
  item: any,
): ThunkResult<Promise<LegacyResponse<ResponseType>>> {
  return function fetchItemPriceDetailsEffects(
    dispatch,
    getState,
    extra: ThunkExtraArguments,
  ): Promise<LegacyResponse<ResponseType>> {
    dispatch(fetchItemPriceDetailsRequest());
    return extra.api(getState()).experiences.getItemsAndPriceByKeyAndNumber({
      organization,
      key: experienceKey,
      number: item.number,
    }).then((response) => {
      if (response.ok) {
        dispatch(fetchItemPriceDetailsSuccess(response.body, item));
      } else {
        dispatch(standardAction(
          ActionTypes.GET_ITEM_PRICE_DETAILS_FAILURE, formatErrorResponseV2(response),
        ));
      }
      return toLegacyResponse<ResponseType>(response);
    });
  };
}
