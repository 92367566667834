import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import ActionTypes from '../constants/ActionTypes';
import { DeleteShippingConfigurationFailure, DeleteShippingConfigurationSuccess } from '../types/actions';

function createRequestAction(): RootActionTypes {
  return {
    type: ActionTypes.DELETE_SHIPPING_CONFIGURATION_REQUEST,
  };
}

function createSuccessAction(
  organizationId: string,
  shippingConfigurationKey: string,
): DeleteShippingConfigurationSuccess {
  return {
    type: ActionTypes.DELETE_SHIPPING_CONFIGURATION_SUCCESS,
    meta: {
      organizationId,
      shippingConfigurationKey,
    },
  };
}

function createFailureAction(payload: FormattedErrorMessages): DeleteShippingConfigurationFailure {
  return {
    type: ActionTypes.DELETE_SHIPPING_CONFIGURATION_FAILURE,
    error: true,
    payload,
  };
}

function deleteShippingConfiguration(
  organizationId: string,
  shippingConfigurationKey: string,
): ThunkResult<Promise<DeleteShippingConfigurationSuccess | DeleteShippingConfigurationFailure>> {
  return function deleteShippingConfigurationEffects(
    dispatch,
    getState,
    extra,
  ): Promise<DeleteShippingConfigurationSuccess | DeleteShippingConfigurationFailure> {
    dispatch(createRequestAction());

    return extra.api(getState()).shippingConfigurations.deleteByKey({
      organization: organizationId,
      key: shippingConfigurationKey,
    }).then((response) => {
      if (response.ok) {
        const successAction = createSuccessAction(
          organizationId,
          shippingConfigurationKey,
        );

        dispatch(successAction);
        return successAction;
      }

      const errors = formatErrorResponseV2(response);
      const failureAction = createFailureAction(errors);
      dispatch(failureAction);
      return failureAction;
    });
  };
}

export default deleteShippingConfiguration;
