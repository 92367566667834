import { Field } from 'redux-form';
import React from 'react';
import find from 'lodash/find';
import property from 'lodash/property';
import { DeliveredDutyDisplayType } from '@flowio/api-constants';

import { InfoCircle } from '@flowio/react-icons';
import { Tooltip } from '@flowio/react-tooltip';
import { css } from '@emotion/react';
import { DeliveredDutyFormOption } from '../types';
import { SelectField } from '../../../../../components/ReduxFormFieldAdapters';

const infoCircleStyle = css`
  margin-left: 8px;
`;

const tooltipStyle = css`
  width: 200px !important;
  color: white !important;
`;

const options: DeliveredDutyFormOption<DeliveredDutyDisplayType>[] = [
  { displayBy: 'Listing', identifyBy: DeliveredDutyDisplayType.ALL },
  { displayBy: 'Focused', identifyBy: DeliveredDutyDisplayType.SINGLE },
];

const handleFormat = (
  value: string,
): DeliveredDutyFormOption | undefined => find(
  options,
  { identifyBy: value as DeliveredDutyDisplayType },
);

const handleParse = property('identifyBy');

interface Props {
  hasWarningMessage: boolean;
}

const DeliveredDutyDisplay: React.FC<Props> = ({
  hasWarningMessage,
}) => (
  <Field
    component={SelectField}
    name="display"
    format={handleFormat}
    parse={handleParse}
    disabled={hasWarningMessage}
    fluid
    hintText="Select Display Type"
    id="display"
    inline
    labelCols={5}
    labelIcon={(
      <Tooltip
        openOnHover
        css={tooltipStyle}
        trigger={<InfoCircle width={16} css={infoCircleStyle} />}
      >
        Choose how you would like to display shipping methods offered at checkout.
      </Tooltip>
    )}
    labelFor="display"
    labelKey="displayBy"
    labelText="Display Type"
    valueKey="identifyBy"
    options={options}
  />
);

DeliveredDutyDisplay.displayName = 'DeliveredDutyDisplay';

export default DeliveredDutyDisplay;
