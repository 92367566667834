import { createSelector } from 'reselect';
import get from 'lodash/get';
import getExclusionState from './getExclusionState';
import { RootState } from '../../../stores/types';
import { ExclusionState, ExclusionsExportDialogState } from '../types';

const getExclusionsExportDialog = createSelector <
RootState, ExclusionState, ExclusionsExportDialogState>(
  getExclusionState,
  (state) => get(state, 'dialogs.exclusionsExportDialog'),
);

export default getExclusionsExportDialog;
