import { apiInternal } from '../../../utilities/clients';

import checkHttpStatus from '../../../utilities/check-http-status';
import { RootState } from '../../../stores/types';

interface SuccessResponse {
  result: io.flow.v0.models.QueryBuilder;
}

export default function createQueryBuilder(
  state: RootState,
  organizationId: string,
  queryBuilderForm: io.flow.v0.unions.QueryBuilderForm,
) {
  return apiInternal.queryBuilders(state)
    .post(organizationId, { body: JSON.stringify(queryBuilderForm) })
    .then(checkHttpStatus)
    .then((response: SuccessResponse) => response.result);
}
