import React from 'react';
import { WrappedFieldInputProps, WrappedFieldMetaProps, WrappedFieldProps } from 'redux-form';
import { FormGroupIntent } from '@flowio/react-form-group';
import { MultiSelectField } from '@flowio/react-multi-select';
import defaultShouldError from './utilities/defaultShouldError';
import defaultShouldWarn from './utilities/defaultShouldWarn';

type PropsOf<
  T extends React.ElementType,
> = JSX.LibraryManagedAttributes<T, React.ComponentProps<T>>;

type OwnProps = WrappedFieldProps &
{
  shouldError?: (
    input: WrappedFieldInputProps, meta: WrappedFieldMetaProps, props?: any
  ) => boolean,
  shouldWarn?: (input: WrappedFieldInputProps, meta: WrappedFieldMetaProps, props?: any) => boolean,
  labelKey: string,
  valueKey: string, options: any[],
  id?: string,
};

type MultiSelectFieldProps = typeof MultiSelectField;

type MultiSelectFieldUnhandledProps = Omit<PropsOf<MultiSelectFieldProps>, keyof OwnProps>;

type ReduxFormMultiSelectFieldProps = OwnProps & MultiSelectFieldUnhandledProps;

const ReduxFormMultiSelectField: React.FC<ReduxFormMultiSelectFieldProps> = (props) => {
  const {
    input,
    meta,
    shouldWarn,
    shouldError, labelKey, valueKey, options, id, tagInputProps, ...unhandledProps
  } = props;

  const { error, warning } = meta;

  let feedbackText;
  let intent: FormGroupIntent = 'neutral';

  if (error && shouldError && shouldError(input, meta)) {
    feedbackText = error;
    intent = 'negative';
  } else if (warning && shouldWarn && shouldWarn(input, meta)) {
    feedbackText = warning;
    intent = 'warning';
  }

  const handleValueChange = (value: string[]) => {
    const optionValue: string[] = options?.filter((o) => value.includes(o[valueKey]));
    input.onChange(optionValue);
  };

  const value = input.value ? input.value.map((v: any) => v[valueKey]) : [];

  return (
    <MultiSelectField
      {...unhandledProps}
      options={options?.map((o) => ({ value: o[valueKey], content: o[labelKey] }))}
      onValueChange={handleValueChange}
      feedback={feedbackText}
      intent={intent}
      value={value}
      tagInputProps={{
        ...tagInputProps,
        id,
        name: input.name,
        onFocus: input.onFocus,
      }}
    />
  );
};

ReduxFormMultiSelectField.defaultProps = {
  shouldError: defaultShouldError,
  shouldWarn: defaultShouldWarn,
  labelKey: 'content',
  valueKey: 'value',
};

export default ReduxFormMultiSelectField;
