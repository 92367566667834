import { RootState } from '../../../stores/types';
import { getOrganizationId } from '../../organization/selectors';
import getReturnPolicyIdForReturnPolicyWorksheet from './getReturnPolicyIdForReturnPolicyWorksheet';
import getQueryFiltersForReturnPolicyWorksheet from './getQueryFiltersForReturnPolicyWorksheet';

const getInitialValuesForReturnPolicyWorksheet = (state: RootState) => ({
  returnPolicyId: getReturnPolicyIdForReturnPolicyWorksheet(state),
  filters: getQueryFiltersForReturnPolicyWorksheet(state),
  organizationId: getOrganizationId(state),
});

export default getInitialValuesForReturnPolicyWorksheet;
