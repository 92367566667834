import React from 'react';

type Props = {
  className?: string;
};

const BlazeIcon: React.FC<Props> = ({
  className,
}) => (
  <svg width="58" height="50" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M20.366 37.182H50.06c.43 0 .807-.161 1.13-.484.323-.323.484-.71.484-1.162 0-.495-.161-.904-.484-1.227-.323-.322-.7-.484-1.13-.484H20.753c-.753 0-1.355-.236-1.807-.71-.452-.473-.742-1.108-.872-1.904L13.847 2.614c-.129-.86-.403-1.511-.823-1.952-.42-.441-1.2-.662-2.34-.662H1.646C1.194 0 .806.167.484.5A1.638 1.638 0 000 1.678c0 .452.161.845.484 1.178.323.334.71.5 1.162.5h8.78l4.163 28.339c.258 1.7.85 3.04 1.775 4.018.925.98 2.26 1.469 4.002 1.469zm-4.583-8.973h34.213c1.785 0 3.141-.5 4.066-1.5.926-1.001 1.517-2.362 1.776-4.084l2.065-13.588c.022-.15.043-.317.065-.5.021-.183.032-.317.032-.403 0-.581-.199-1.05-.597-1.404-.398-.355-.92-.533-1.565-.533H13.427l.032 3.357h40.765l-1.905 12.652c-.258 1.764-1.118 2.647-2.582 2.647l-34.05.032.096 3.324zm6.875 21.206c1.119 0 2.065-.388 2.84-1.162.775-.775 1.162-1.722 1.162-2.84 0-1.12-.387-2.072-1.162-2.857-.775-.785-1.721-1.178-2.84-1.178-1.12 0-2.071.393-2.857 1.178-.785.785-1.178 1.738-1.178 2.856 0 1.12.393 2.066 1.178 2.84.786.775 1.738 1.163 2.857 1.163zm23.432 0c1.12 0 2.071-.388 2.857-1.162.785-.775 1.178-1.722 1.178-2.84 0-1.12-.393-2.072-1.178-2.857-.786-.785-1.738-1.178-2.857-1.178-1.119 0-2.07.393-2.856 1.178-.786.785-1.178 1.738-1.178 2.856 0 1.12.392 2.066 1.178 2.84.785.775 1.737 1.163 2.856 1.163z" fill="#fff" />
  </svg>
);

export default BlazeIcon;
