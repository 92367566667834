import { ActionTypes, ActivityKind } from '../constants';
import { getCurrentOrderNumber } from '../selectors';
import createActivity from './createActivity';
import { getOrganizationId } from '../../organization';
import changeSearchValue from './changeSearchValue';
import { ThunkResult } from '../../../stores/types';

export default function clearCurrentOrder(): ThunkResult<void> {
  return function clearCurrentOrderEffects(dispatch, getState) {
    const currentOrderNumber = getCurrentOrderNumber(getState());
    const organizationId = getOrganizationId(getState());
    if (currentOrderNumber != null) {
      dispatch(changeSearchValue(''));
      dispatch({ type: ActionTypes.CLEAR_CURRENT_ORDER });
      dispatch(createActivity({
        kind: ActivityKind.CLEAR_ORDER,
        number: currentOrderNumber,
        organizationId,
      }));
    }
  };
}
