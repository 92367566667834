import { createSelector } from 'reselect';
import filter from 'lodash/filter';
import get from 'lodash/get';
import getMarketingState from './getMarketingState';
import { RootState } from '../../../stores/types';
import { MarketingState } from '../types';

const getLocalizedFeeds = (
  schema?: io.flow.internal.v0.enums.MarketingGatewaySchemaCompatibility,
) => (state: MarketingState): io.flow.internal.v0.models.MarketingGatewayFeed[] => {
  const feeds = get(state, 'productFeeds.productFeeds');
  return schema ? filter(feeds, (feed) => get(feed, 'schema.schema_compatibility') === schema) : feeds;
};

export default (
  schema?: io.flow.internal.v0.enums.MarketingGatewaySchemaCompatibility,
) => createSelector<RootState, MarketingState, io.flow.internal.v0.models.MarketingGatewayFeed[]>(
  getMarketingState,
  getLocalizedFeeds(schema),
);
