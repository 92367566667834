import update from 'immutability-helper';
import ActionTypes from '../constants/action-types';
import { ExperienceState } from '../types';
import { RootActionTypes } from '../../../stores/types';

type State = ExperienceState['entities']['deliveredDutyPreference'];

const initialState: State = {};

export default function (state: State = initialState, action: RootActionTypes): State {
  switch (action.type) {
    case ActionTypes.FETCH_DELIVERED_DUTY_PREFERENCE_SUCCESS:
      return update(state, { $set: action.payload });
    case ActionTypes.UPDATE_DELIVERED_DUTY_PREFERENCE_SUCCESS:
      return update(state, { $set: action.payload });
    default:
      return state;
  }
}
