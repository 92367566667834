/**
 * @fileoverview
 * A wrapper for page/route components within the auth module.
 */

import React from 'react';
import styled from '@emotion/styled';
import { FlowLogo } from '@flowio/react-icons';
import classNames from 'classnames';
import colors from '../../../../theme/tokens/colors';
import * as styles from './auth-layout.styles';

const StyledLogo = styled(FlowLogo)`
  fill: ${colors.flowBlack};
  margin-bottom: 40px;
  height: 30px;
  align-self: center;
`;

const displayName = 'AuthLayout';

interface Props {
  className?: string;
}

const AuthLayout: React.FC<Props> = ({ children, className }) => (
  <div className={classNames(styles.authLayout, className)}>
    <div className={styles.content}>
      <StyledLogo />
      {children}
    </div>
    <div className={styles.footer}>
      &copy; 2016 -
      {' '}
      {new Date().getFullYear()}
      {' '}
      Flow. All rights reserved.
    </div>
  </div>
);

AuthLayout.displayName = displayName;
AuthLayout.defaultProps = {
  className: undefined,
};

export default AuthLayout;
