import PropTypes from 'prop-types';
/* global ga */

import React from 'react';
import { locationShape } from 'react-router';
import isEqual from 'lodash/isEqual';

import addTrackingSnippet from './add-tracking-snippet';

export default class GoogleAnalytics extends React.Component {
  componentDidMount() {
    const { trackingId } = this.props;

    addTrackingSnippet();

    ga('create', trackingId, 'auto');

    if (process.env.NODE_ENV !== 'production') {
      ga('set', 'sendHitTask', null);
    }

    this.sendPageView();
  }

  componentDidUpdate(prevProps) {
    const { routerProps: { location: prevLocation } } = prevProps;
    const { routerProps: { location: nextLocation } } = this.props;

    if (!isEqual(prevLocation, nextLocation)) {
      this.sendPageView();
    }
  }

  sendPageView() {
    const { routerProps: { location } } = this.props;
    ga('set', 'page', location.pathname + location.search);
    ga('send', 'pageview');
  }

  render() {
    const { children } = this.props;
    return React.Children.only(children);
  }
}
GoogleAnalytics.displayName = 'GoogleAnalytics';

GoogleAnalytics.propTypes = {
  children: PropTypes.node.isRequired,
  routerProps: PropTypes.shape({
    location: locationShape.isRequired,
  }).isRequired,
  trackingId: PropTypes.string.isRequired,
};
