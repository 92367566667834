import invariant from 'invariant';
import { getPageNumber, getPageSize } from '@flowio/redux-filtering-paging-sorting/lib/selectors';
import ActionType from '../constants/ActionType';

import { getExclusionRulesPaginationState } from '../selectors';
import fetchExclusionRules from './fetchExclusionRules';
import hideExclusionRuleWorksheet from './hideExclusionRuleWorksheet';
import { createToast } from '../../console/actions';
import { ThunkResult } from '../../../stores/types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';

/**
 * Creates an action responsible for marking an exclusion rule in the specified
 * organization for deletion.
 * @param {String} organizationId - identifier for the organization to be updated.
 * @param {String} exclusionRuleId - identifier for the exclusion rule to be deleted.
 */
export default function updateExclusionRule(
  organizationId: string,
  exclusionRuleId: string,
  exclusionRuleForm: io.flow.v0.models.ExclusionRuleForm,
): ThunkResult<Promise<void>> {
  invariant(organizationId != null, 'An organization identifier must be provided');
  invariant(exclusionRuleId != null, 'An exclusion rule identifier must be provided');
  invariant(exclusionRuleForm != null, 'An exclusion rule form must be provided');
  return function updateExclusionRuleEffects(dispatch, getState, extra) {
    const state = getState();
    const pageSize = getPageSize(getExclusionRulesPaginationState)(state);
    const pageNumber = getPageNumber(getExclusionRulesPaginationState)(state);
    dispatch({
      type: ActionType.UpdateExclusionRuleRequest,
      meta: {
        organizationId,
        exclusionRuleForm,
        exclusionRuleId,
      },
    });

    return extra.api(state).exclusionRules.putById({
      organization: organizationId,
      id: exclusionRuleId,
      body: exclusionRuleForm,
    }).then((response) => {
      if (response.ok) {
        dispatch({
          type: ActionType.UpdateExclusionRuleSuccess,
          payload: response.body,
          meta: {
            organizationId,
            exclusionRuleForm,
            exclusionRuleId,
          },
        });
        dispatch(fetchExclusionRules(organizationId, pageNumber, pageSize));
        dispatch(hideExclusionRuleWorksheet());
        dispatch(createToast({
          content: 'Exclusion rule updated successfully',
        }));
        return;
      }

      const errors = formatErrorResponseV2(response);
      dispatch({
        type: ActionType.UpdateExclusionRuleFailure,
        payload: errors,
        meta: {
          organizationId,
          exclusionRuleForm,
          exclusionRuleId,
        },
      });
    });
  };
}
