import { ExperimentsExperimentsState, ExperimentsActions } from '../types';

import ActionTypes from '../constants/action-types';

const initialState: ExperimentsExperimentsState = {
  results: [],
};

export default function (
  state: ExperimentsExperimentsState = initialState,
  action: ExperimentsActions,
): ExperimentsExperimentsState {
  switch (action.type) {
    case ActionTypes.FETCH_EXPERIMENTS_SUCCESS:
      return {
        ...state,
        results: action.payload,
      };
    case ActionTypes.CREATE_EXPERIMENT_SUCCESS:
      return {
        ...state,
        results: [action.payload, ...state.results],
      };
    case ActionTypes.UPDATE_EXPERIMENT_SUCCESS:
    case ActionTypes.UPDATE_EXPERIMENT_STATUS_SUCCESS: {
      return {
        ...state,
        results: state.results.map((e) => ((e.key === action.payload.key) ? action.payload : e)),
      };
    }
    default:
      return state;
  }
}
