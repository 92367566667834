import { combineReducers } from 'redux';

import nonReturnable from './nonReturnable';
import returnPolicyWorksheet from './returnPolicyWorksheet';
import returnPolicyDeleteConfirmation from './returnPolicyDeleteConfirmation';
import returnPolicyForm from './returnPolicyForm';
import { NonReturnableState } from '../types';

export default combineReducers<NonReturnableState>({
  dialogs: combineReducers({
    returnPolicyDeleteConfirmation,
    returnPolicyWorksheet,
  }),
  entities: combineReducers({
    nonReturnable,
    returnPolicyForm,
  }),
});
