import { connect } from 'react-redux';
import { RootState } from '../../../stores/types';
import OrganizationId from '../components/organization-id';
import { getOrganization } from '../selectors';

function mapStateToProps(state: RootState) {
  return {
    organization: getOrganization(state),
  };
}

export default (connect(mapStateToProps)(OrganizationId));
