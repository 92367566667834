import Client from './client';

export default class InternalHarmonizationStatistics extends Client {
  constructor(opts) {
    let options = opts;

    if (typeof opts === 'string') {
      options = { host: opts }; // convert host string to options object
    }

    options.serviceName = 'API Internal';

    super(options);
  }

  getRateFreshness(options = {}) {
    return this.makeRequest(`${this.host}/harmonization/statistics/rate-freshness`, options);
  }

  getRateSource(options = {}) {
    return this.makeRequest(`${this.host}/harmonization/statistics/rate-source`, options);
  }

  getTimeToClassifyAggregated(options = {}) {
    return this.makeRequest(`${this.host}/harmonization/statistics/time-to-classify-aggregated`, options);
  }

  getTimeToClassify(options = {}) {
    return this.makeRequest(`${this.host}/harmonization/statistics/time-to-classify`, options);
  }

}
