import React, { Component, ReactElement } from 'react';
import kebabCase from 'lodash/kebabCase';
import startCase from 'lodash/startCase';
import moment from 'moment';
import { Button } from '@flowio/react-button';
import {
  Table,
  TableHead,
  TableRow, TableRowColumn,
  TableHeadColumn,
  TableBody,
} from '@flowio/react-table';
import classNames from 'classnames';
import * as styles from './order-returns.styles';

interface OrderReturnsProps {
  onViewItems: (
    items: io.flow.v0.models.ReturnLineItem[],
    pdfLabel: string,
    pdfInvoice: string,
  ) => void;
  returns: io.flow.internal.v0.models.ReturnSummary[];
}

export default class OrderReturns extends Component<OrderReturnsProps> {
  static displayName = 'OrderReturns';

  viewItems = (items: io.flow.v0.models.ReturnLineItem[], pdfLabel = '', pdfInvoice = ''): void => {
    const { onViewItems } = this.props;
    onViewItems(items, pdfLabel, pdfInvoice);
  };

  render(): ReactElement {
    const {
      returns,
    } = this.props;

    return (
      <Table displayDensity="compact" striped>
        <TableHead>
          <TableRow>
            <TableHeadColumn className={classNames(styles.headerColumn, 'first')}>Tracking #</TableHeadColumn>
            <TableHeadColumn className={styles.headerColumn}>Status</TableHeadColumn>
            <TableHeadColumn className={styles.headerColumn}>Description</TableHeadColumn>
            <TableHeadColumn className={styles.headerColumn}>Last Updated</TableHeadColumn>
            <TableHeadColumn className={styles.headerColumn}># of Items</TableHeadColumn>
            <TableHeadColumn className={styles.headerColumn}>Reference #</TableHeadColumn>
            <TableHeadColumn className={styles.headerColumn}>&nbsp;</TableHeadColumn>
          </TableRow>
        </TableHead>
        <TableBody>
          {returns.map((ret) => {
            const trackingSummary = ret.tracking_summary || {
              number: '',
              description: '',
              timestamp: '',
              status: '',
            };
            return (
              <TableRow className="row" key={kebabCase(`${ret.id} ${ret.key}`)}>
                <TableRowColumn className={styles.tracking}>
                  <a href={`https://track.flow.io/${trackingSummary.number}`}>
                    {trackingSummary.number}
                  </a>
                </TableRowColumn>
                <TableRowColumn className={classNames(styles.status, trackingSummary.status)}>
                  {startCase(trackingSummary.status)}
                </TableRowColumn>
                <TableRowColumn>
                  {trackingSummary.description}
                </TableRowColumn>
                <TableRowColumn>
                  {moment(trackingSummary.timestamp).format('llll')}
                </TableRowColumn>
                <TableRowColumn>
                  {ret.items.reduce((sum, item) => item.quantity + sum, 0)}
                </TableRowColumn>
                <TableRowColumn>
                  {ret.key}
                </TableRowColumn>
                <TableRowColumn>
                  <Button
                    content="View Details"
                    size="small"
                    onClick={(): void => this.viewItems(
                      ret.items,
                      ret.tracking_summary ? ret.tracking_summary.label.pdf : '',
                      ret.tracking_summary ? ret.tracking_summary.label.commercial_invoice : '',
                    )}
                  />
                </TableRowColumn>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }
}
