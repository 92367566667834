import React, { useCallback, useState } from 'react';
import { Button } from '@flowio/react-button';
import { ChevronDown } from '@flowio/react-icons';
import { Popover } from '@flowio/react-popover';
import { Image } from '@flowio/react-image';
import { Box, BoxItem } from '@flowio/react-box';
import classNames from 'classnames';
import AddChannelsButtonPopover from './AddChannelsButtonPopover';
import * as styles from './AddChannelsButton.styles';
import { Props } from '../types';
import Platforms from '../../../constants/Platforms';
import ConnectGoogleFirst from './ConnectGoogleFirstMessage';

const AddChannelsButton: React.FC<Props> = ({
  manualChannels = [],
  integratedChannels = [],
  onAddChannel,
  onConnectForSupportedChannels,
}) => {
  const allChannelsAdded = (!manualChannels.length && !integratedChannels.length);
  const googleToBeAdded = integratedChannels.some(({ platform }) => platform === 'google');
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [buttonHintNoticed, setButtonHintNoticed] = useState(false);

  const togglePopover = (open: boolean) => () => {
    if (open) {
      setButtonHintNoticed(true);
    }
    setPopoverOpen(open);
  };

  const handleClick = useCallback((
    channel: io.flow.internal.v0.models.MarketingGatewayChannel,
  ) => () => {
    setPopoverOpen(false);
    onAddChannel(channel);
  }, [onAddChannel]);

  const handleAddSupportedChannelClick = useCallback(() => {
    setPopoverOpen(false);
    onConnectForSupportedChannels();
  }, [onConnectForSupportedChannels]);

  const triggerButton: React.ReactNode = (
    <Button
      className={styles.addChannelsTrigger}
      disabled={allChannelsAdded}
      intent="primary"
      rightIcon={ChevronDown}
      content="Add Channel"
      onClick={togglePopover(true)}
      data-automation-id="add-channels-button"
    />
  );

  const AddChannelsMenuItem = (
    channel: io.flow.internal.v0.models.MarketingGatewayChannel,
  ): React.ReactNode => {
    const {
      platform,
      status,
    } = channel;
    return (
      <Box
        direction="row"
        data-automation-id={`channels-item-${platform}`}
        alignItems="center"
        className={classNames('platformItem', (status === 'unavailable' ? 'disabled' : 'enabled'))}
        onClick={handleClick(channel)}
      >
        <BoxItem><Image width={24} accessibilityLabel={`${platform} logo`} source={`https://cdn.flow.io/console/logo_${platform}.svg`} /></BoxItem>
        <BoxItem className="platformName">{Platforms(platform).shortName}</BoxItem>
      </Box>
    );
  };

  return (
    <div className={styles.container}>
      <AddChannelsButtonPopover wasNoticed={buttonHintNoticed} />
      <Popover
        open={!allChannelsAdded ? popoverOpen : undefined}
        closeOnInvisible
        closeOnResize
        closeOnScroll
        onClose={!allChannelsAdded ? togglePopover(false) : undefined}
        onOpen={!allChannelsAdded ? togglePopover(true) : undefined}
        propagateClickEvent={false}
        className={classNames([styles.menuPopover], { allChannelsAdded })}
        openOnHover={allChannelsAdded}
        position={`bottom${allChannelsAdded ? '' : ' right'}`}
        trigger={triggerButton}
      >
        {allChannelsAdded ? (
          <div>You have successfully added all the channels.</div>
        ) : (
          <div className="addChannelsMenu">
            {integratedChannels.length ? (
              <div className={classNames('channelType', 'integrated')}>
                <p className="channelTypeTitle">Flow Integrated Channels</p>
                {integratedChannels.map(AddChannelsMenuItem)}
              </div>
            ) : null}
            {manualChannels.length ? (
              <div data-automation-id="manual-channels-list" className={classNames('channelType', 'manual')}>
                <p className="channelTypeTitle">Flow Supported Channels</p>
                {googleToBeAdded && <ConnectGoogleFirst onClick={handleAddSupportedChannelClick} />}
                {manualChannels.map(AddChannelsMenuItem)}
              </div>
            ) : null}
          </div>
        )}
      </Popover>
    </div>
  );
};

AddChannelsButton.displayName = 'AddChannelsButton';

export default AddChannelsButton;
