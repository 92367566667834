import * as ActionTypes from './constants';
import {
  RootState, ThunkDispatcher, ThunkExtraArguments, ThunkResult,
} from '../../stores/types';

const PAGE_SIZE = 1000;

export function getDetailedUser(userId: string): ThunkResult<Promise<void>> {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<void> => {
    dispatch({
      type: ActionTypes.FETCH_DETAILED_USER_SUBMIT,
      payload: { userId },
    });
    const state = getState();
    return extra.api(state).users.getById({ id: userId }).then((response) => {
      if (response.ok) {
        dispatch({
          type: ActionTypes.FETCH_DETAILED_USER_SUCCESS,
          payload: response.body,
        });
      }
    });
  };
}

export function updateUser(user: io.flow.v0.models.UserPutForm): ThunkResult<Promise<void>> {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<void> => {
    dispatch({
      type: ActionTypes.UPDATE_USER_SUBMIT,
    });
    const state = getState();

    if (!state.defaultReducer.user) {
      throw new Error('User ID expected');
    }

    return extra.api(state).users.putById({
      id: state.defaultReducer.user.id,
      body: user,
    }).then((response) => {
      if (response.ok) {
        dispatch({
          type: ActionTypes.UPDATE_USER_SUCCESS,
          payload: response.body,
        });
      }
    });
  };
}

export function fetchUserOrganizationMemberships(
  offset = 0,
): ThunkResult<Promise<void>> {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<void> => {
    const state = getState();
    dispatch({
      type: ActionTypes.FETCH_USER_ORG_MEMBERSHIPS_SUBMIT,
    });

    if (!state.defaultReducer.user) {
      throw new Error('User ID expected');
    }

    return extra.api(state).memberships.get({
      user: state.defaultReducer.user.id,
      expand: ['organization'],
      limit: PAGE_SIZE,
      offset,
      sort: 'organization_id',
    }).then((response) => {
      if (response.ok) {
        dispatch({
          type: ActionTypes.FETCH_USER_ORG_MEMBERSHIPS_SUCCESS,
          payload: response.body,
        });

        if (response.body.length === PAGE_SIZE) {
          return dispatch(fetchUserOrganizationMemberships(offset + PAGE_SIZE));
        }
      }
      return undefined;
    });
  };
}

type FetchSpecificUserOrganizationMembershipParams = {
  organizationId: string,
};

export function fetchSpecificUserOrganizationMembership({
  organizationId,
}: FetchSpecificUserOrganizationMembershipParams): ThunkResult<Promise<void>> {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<void> => {
    const state = getState();
    dispatch({
      type: ActionTypes.FETCH_USER_ORG_MEMBERSHIPS_SUBMIT,
    });

    if (!state.defaultReducer.user) {
      throw new Error('User ID expected');
    }

    return extra.api(state).memberships.get({
      user: state.defaultReducer.user.id,
      organization: organizationId,
      expand: ['organization'],
    }).then((response) => {
      if (response.ok) {
        dispatch({
          type: ActionTypes.FETCH_USER_ORG_MEMBERSHIPS_SUCCESS,
          payload: response.body,
        });
      }
    });
  };
}
