import styled from '@emotion/styled';
import BemHelper from '@flowio/bem-helper';
import React, { FunctionComponent } from 'react';
import { Box } from '@flowio/react-box';
import { FlowLogo } from '@flowio/react-icons';
import { Link } from 'react-router';
import colors from '../../../../theme/tokens/colors';
import { LogoProps } from '../../types/props';
import ContextLogo from './contextLogo';

const StyledLogo = styled(FlowLogo)`
  fill: ${colors.white};
  width: '110px';
`;

if (process.browser) {
  require('./logo.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('logo');

const displayName = 'Logo';

const defaultProps = {
  className: undefined,
};

const Logo: FunctionComponent<LogoProps> = ({ className, ...otherProps }) => (
  <h1 className={bem.block(className)} {...otherProps}>
    <Link className={bem.element('link')} to="/">
      <Box alignItems="center">
        <StyledLogo fill={colors.white} width={110} />
        <ContextLogo width={110} height="auto" />
      </Box>
    </Link>
  </h1>
);

Logo.displayName = displayName;
Logo.defaultProps = defaultProps;

export default Logo;
