import { ThunkDispatcher, ThunkResult } from '../../../stores/types';
import ActionTypes from '../constants/action-types';
import { PartialHttpResponse } from '../../../utilities/clients/api-error';
import { server } from '../../../utilities/clients';
import isResponseOk from '../../../utilities/isResponseOk';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import { AuthResponse } from '../../../utilities/clients/server/types';
import { LegacyResponse } from '../../../utilities/clients/types/server';

export default function loginUser(
  email: string,
  password: string,
): ThunkResult<Promise<LegacyResponse<AuthResponse | FormattedErrorMessages>>> {
  return (
    dispatch: ThunkDispatcher,
  ): Promise<LegacyResponse<AuthResponse | FormattedErrorMessages>> => {
    dispatch({
      type: ActionTypes.AUTH_LOGIN_USER_SUBMIT,
    });

    return server.auth.postLogin({
      body: JSON.stringify({ email, password }),
    }).then((response) => {
      if (isResponseOk(response)) {
        dispatch({
          type: ActionTypes.AUTH_LOGIN_USER_COMPLETED,
          payload: response.result,
        });
        return response;
      }

      const httpResponse: PartialHttpResponse = {
        body: response.result,
        status: response.status,
      };

      dispatch({
        type: ActionTypes.AUTH_LOGIN_USER_ERROR,
        payload: formatErrorResponseV2(httpResponse),
      });
      return {
        ...response,
        result: formatErrorResponseV2(httpResponse),
      };
    });
  };
}
