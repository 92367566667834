import { ExperiencesPutCurrencyAndFormatsByExperienceKeyResponse } from '@flowio/api-sdk';
import ActionTypes from '../constants/action-types';
import {
  ThunkExtraArguments,
  RootState,
  ThunkDispatcher,
  ThunkResult,
} from '../../../stores/types';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import toLegacyResponse from '../../../utilities/clients/to-legacy-response';
import { PropType } from '../../../types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';
import { createToast } from '../../console/actions';

type ResponseType = PropType<ExperiencesPutCurrencyAndFormatsByExperienceKeyResponse, 'body'>;

export default function updatePriceFormat(
  organization: string,
  experienceKey: string,
  format: io.flow.v0.models.ExperienceCurrencyFormatForm,
): ThunkResult<Promise<LegacyResponse<ResponseType>>> {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<LegacyResponse<ResponseType>> => {
    dispatch({
      type: ActionTypes.UPDATE_PRICE_FORMAT_REQUEST,
    });

    return extra.api(getState()).experiences.putCurrencyAndFormatsByExperienceKey({
      organization,
      experience_key: experienceKey,
      body: format,
    }).then((response) => {
      if (response.ok) {
        dispatch({
          type: ActionTypes.UPDATE_PRICE_FORMAT_SUCCESS,
          payload: response.body,
        });
        dispatch(createToast({
          content: 'Pricing Format Saved',
          intent: 'positive',
          icon: 'CheckCircle',
        }));
      } else {
        dispatch({
          type: ActionTypes.UPDATE_PRICE_FORMAT_FAILURE,
          payload: formatErrorResponseV2(response),
        });
        dispatch(createToast({
          content: 'Pricing Format was not saved, please try again',
          intent: 'negative',
        }));
      }
      return toLegacyResponse<ResponseType>(response);
    });
  };
}
