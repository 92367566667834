import React from 'react';
import { css } from '@emotion/react';
import { TagInput } from '@flowio/react-tag-input';
import { WrappedFieldProps } from 'redux-form';
import { colors } from '../../../../theme/tokens';
import validator from '../../../../utilities/validator';

const ExperimentEmailField: React.FC<WrappedFieldProps> = ({
  input,
  meta: { error },
}) => {
  const styles = css({
    borderColor: error ? colors.red[300] : colors.neutral[500],
    backgroundColor: error ? colors.red[100] : 'transparent',
    color: 'green',
  });

  const handleAdd = ([added]: string[]) => {
    if (validator.validate(added, { pattern: 'email' })) {
      input.onChange([...input.value, added]);
    }
  };

  const handleRemove = ([removed]: string[]) => {
    const newValue = input.value.filter((val: string) => val !== removed);
    input.onChange(newValue);
  };

  return (
    <TagInput
      css={styles}
      fluid
      hintText="At least one email is required"
      onAdd={handleAdd}
      onRemove={handleRemove}
      value={input.value}
      intent={error ? 'negative' : 'primary'}
      clearable={false}
    />
  );
};

ExperimentEmailField.displayName = 'ExperimentEmailField';

export default ExperimentEmailField;
