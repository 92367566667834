import { $HttpResponse } from '@flowio/api-sdk';
import ActionTypes from '../constants/ActionTypes';
import { ApiError } from '../../../utilities/clients/api-error';
import standardAction from '../../../utilities/standard-action';
import {
  RootState, ThunkExtraArguments, ThunkResult, ThunkDispatcher,
} from '../../../stores/types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';
import fetchFeedForms from './fetchFeedForms';

interface Props {
  organizationId: string;
  catalogId: number;
}

const fetchFacebookFeedForms = ({
  organizationId,
  catalogId,
}: Props): ThunkResult<Promise<void>> => (
  dispatch: ThunkDispatcher,
  getState: () => RootState,
  extra: ThunkExtraArguments,
): Promise<void> => {
  const state = getState();
  const api = extra.apiInternal(state);

  dispatch(standardAction(ActionTypes.FETCH_FACEBOOK_FEED_FORMS_REQUEST));

  return api.marketingGatewayFeedSources.get({
    organization: organizationId,
    channel_id: 'facebook',
  }).then((response) => {
    if (response.ok) {
      const sources = response.body;

      const source = sources.find(
        (src) => src.discriminator === 'marketing_gateway_feed_source_facebook' && src.catalog_id === catalogId,
      );

      if (source) {
        return dispatch(fetchFeedForms({
          organizationId,
          schemaCompatibilities: ['facebook_country_override'],
          feedSource: source,
        }));
      }
      dispatch({
        type: ActionTypes.FETCH_FACEBOOK_FEED_FORMS_FAILURE,
        payload: 'No feed sources were found under this catalog ID',
      });
      // eslint-disable-next-line consistent-return
      return;
    }

    const errorResponse = response as $HttpResponse<io.flow.v0.models.GenericError>;
    const error = new ApiError(errorResponse);

    dispatch({
      type: ActionTypes.FETCH_FACEBOOK_FEED_FORMS_FAILURE,
      payload: formatErrorResponseV2(errorResponse),
    });
    throw error;
  });
};

export default fetchFacebookFeedForms;
