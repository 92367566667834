import { css } from '@emotion/css';

export const activityItem = css`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  min-height: 48px;
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  color: #000;
  font-family: Roboto;
  font-size: 14px;
  line-height: 1;
  list-style: none;
`;

export const scanActivityItem = css`
  border-bottom: 1px solid #3283e6;
  margin-bottom: 12px;
`;

export const icon = css`
  width: 20px;
  height: auto;
  margin-right: 8px;
`;

export const content = css`
  display: flex;
  line-height: 18px;
  align-items: center;
  width: 100%;
  padding: 12px 8px;
`;

export const timestamp = css`
  margin-left: auto;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #a0a0a0;
  white-space: nowrap;
  float: right;
`;

export const clearOrder = css`
  color: #3283e6;
  fill: #3283e6;
`;

export const scanOrder = clearOrder;

export const printLabel = css`
  color: #128a00;
  fill: #128a00;
`;

export const apiError = css`
  color: #d2431f;
  fill: #d2431f;
  background: #fbd9cc;
`;

export const printErrorReport = css`
  color: #d2431f;
  fill: #d2431f;
`;

export const alreadyScanned = css`
  color: #7e5300;
  fill: #7e5300;
  background: #f9e6cf;
`;

export const unidentifiedItem = alreadyScanned;

export const unidentifiedNumber = alreadyScanned;

export const reset = css`
  color: #000;
  fill: #000;
`;

export const separate = css`
  display: inline-block;
  margin-left: 4px;
  margin-right: 4px;
`;

export const activityContentWrapper = css`
  width: 100%;
`;

export const activityContentIconWrapper = css`
  display: flex;
  align-items: center;
`;

export const activityContentMsgWrapper = css`
  width: 100%;
`;
