import { createPaginationReducer } from '@flowio/redux-filtering-paging-sorting';
import concat from 'lodash/concat';
import findIndex from 'lodash/findIndex';
import map from 'lodash/map';
import matches from 'lodash/matches';
import update from 'immutability-helper';

import ActionTypes from '../constants/ActionTypes';
import { ShippingConfigurationsState } from '../types/state';
import { RootActionTypes } from '../../../stores/types';

const replace = <T>(predicate: (...args: any) => any) => (replacement: T) => (item: T) => (
  predicate(item) ? replacement : item
);

const upsert = <T>(
  predicate: (...args: any) => any,
) => (replacement: T) => (collection: T[]) => (
    findIndex(collection, predicate) >= 0
      ? map(collection, replace<T>(predicate)(replacement))
      : concat(collection, replacement)
  );

export default createPaginationReducer({
  identifyBy: 'key',
  types: [
    ActionTypes.FETCH_SHIPPING_CONFIGURATIONS_REQUEST,
    ActionTypes.FETCH_SHIPPING_CONFIGURATIONS_SUCCESS,
    ActionTypes.FETCH_SHIPPING_CONFIGURATIONS_FAILURE,
  ],
}).plugin((state: ShippingConfigurationsState, action: RootActionTypes) => {
  switch (action.type) {
    case ActionTypes.CREATE_SHIPPING_CONFIGURATION_SUCCESS:
    case ActionTypes.FETCH_SHIPPING_CONFIGURATION_SUCCESS:
    case ActionTypes.UPDATE_SHIPPING_CONFIGURATION_SUCCESS:
      return update(state, {
        entities: {
          [action.payload.key]: {
            $set: action.payload,
          },
        },
      });
    case ActionTypes.DELETE_SHIPPING_CONFIGURATION_SUCCESS:
      return update(state, {
        entities: {
          $unset: [action.meta.shippingConfigurationKey],
        },
      });
    case ActionTypes.CREATE_SHIPPING_LANE_SUCCESS:
    case ActionTypes.UPDATE_SHIPPING_LANE_SUCCESS:
      return update(state, {
        entities: {
          [action.payload.shipping_configuration.key]: {
            shipping_lanes: {
              $apply: upsert<io.flow.v0.models.ShippingLane>(matches({
                id: action.payload.id,
              }))(action.payload),
            },
          },
        },
      });
    case ActionTypes.DELETE_SHIPPING_LANE_SUCCESS:
      return update(state, {
        entities: {
          [action.meta.shippingConfigurationKey]: {
            shipping_lanes: {
              $apply: (shippingLanes: io.flow.v0.models.ShippingLane[]) => shippingLanes.filter((
                (shippingLane) => shippingLane.id !== action.meta.shippingLaneId
              )),
            },
          },
        },
      });
    case ActionTypes.CREATE_TIER_SUCCESS:
    case ActionTypes.UPDATE_TIER_SUCCESS:
      return update(state, {
        entities: {
          [action.meta.shippingConfigurationKey]: {
            shipping_lanes: {
              $apply: (
                shippingLanes: io.flow.v0.models.ShippingLane[],
              ) => shippingLanes.map((shippingLane) => {
                if (shippingLane.id !== action.meta.shippingLaneId) {
                  return shippingLane;
                }

                return update(shippingLane, {
                  tiers: {
                    $apply: upsert<io.flow.v0.models.Tier>(matches({
                      id: action.payload.id,
                    }))(action.payload),
                  },
                });
              }),
            },
          },
        },
      });
    case ActionTypes.DELETE_TIER_SUCCESS:
      return update(state, {
        entities: {
          [action.meta.shippingConfigurationKey]: {
            shipping_lanes: {
              $apply: (
                shippingLanes: io.flow.v0.models.ShippingLane[],
              ) => shippingLanes.map((shippingLane) => update(shippingLane, {
                tiers: {
                  $apply: (
                    tiers: io.flow.v0.models.Tier[],
                  ) => tiers.filter((tier) => tier.id !== action.meta.tierId),
                },
              })),
            },
          },
        },
      });
    default:
      return state;
  }
});
