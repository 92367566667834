import { SubmissionError, reduxForm, formValues } from 'redux-form';
import { CancellationTokenSource } from '@flowio/cancellation-token';
import map from 'lodash/map';

import { ActionTypes, FormMethod, FormName } from '../constants';
import { createCenterQueryAndShippingLane, createCenterQueryAndUpdateShippingLane, queryCenters } from '../actions';
import ShippingLaneUpsertForm from '../components/ShippingLaneUpsertForm';
import handleSubmitResult from '../utilities/handleSubmitResult';
import { ShippingLaneUpsertFormCombinedProps, ShippingLaneUpsertFormProps, ShippingLaneUpsertFormValues } from '../types/components';
import { ThunkDispatcher } from '../../../stores/types';

let querySource: any;

function queryBuilderFormFromValues(
  values: ShippingLaneUpsertFormValues,
): io.flow.v0.unions.QueryBuilderForm {
  const { queryFilters } = values;
  return {
    discriminator: 'filter',
    filters: map(queryFilters, (queryFilter) => ({
      discriminator: queryFilter.discriminator,
      field: queryFilter.field,
      operator: queryFilter.operator,
      values: queryFilter.values,
    })),
  };
}

// fetch query centers matching query filters on change
function handleChange(values: ShippingLaneUpsertFormValues, dispatch: ThunkDispatcher) {
  const { organizationId } = values;
  if (querySource) querySource.cancel();
  querySource = new CancellationTokenSource();
  dispatch(queryCenters({
    cancellationToken: querySource.token,
    organizationId,
    queryBuilderForm: queryBuilderFormFromValues(values),
  }));
}

function handleSubmit(
  values: ShippingLaneUpsertFormValues,
  dispatch: ThunkDispatcher,
  props: ShippingLaneUpsertFormProps,
) {
  const {
    organizationId,
    regionId,
    shippingConfigurationKey,
    shippingLaneId,
    shippingLaneDirection,
  } = values;
  switch (props.method) {
    case FormMethod.POST:
      return dispatch(createCenterQueryAndShippingLane({
        organizationId,
        queryBuilderForm: queryBuilderFormFromValues(values),
        regionId,
        shippingConfigurationKey,
        shippingLaneDirection,
      })).then((result) => handleSubmitResult(
        result,
        [ActionTypes.CREATE_CENTER_QUERY_BUILDER_FAILURE, ActionTypes.CREATE_SHIPPING_LANE_FAILURE],
      ));
    case FormMethod.PUT:
      return dispatch(createCenterQueryAndUpdateShippingLane({
        organizationId,
        queryBuilderForm: queryBuilderFormFromValues(values),
        regionId,
        shippingConfigurationKey,
        shippingLaneId,
        shippingLaneDirection,
      })).then((result) => handleSubmitResult(
        result,
        [ActionTypes.CREATE_CENTER_QUERY_BUILDER_FAILURE, ActionTypes.UPDATE_SHIPPING_LANE_FAILURE],
      ));
    default:
      throw new SubmissionError({
        _error: {
          code: 'generic_error',
          messages: [
            'You submission request is not implemented. Please try again later.',
          ],
        },
      });
  }
}

const withForm = reduxForm<
ShippingLaneUpsertFormValues,
ShippingLaneUpsertFormProps
>({
  enableReinitialize: false,
  keepDirtyOnReinitialize: true,
  form: FormName.SHIPPING_LANE_FORM,
  onChange: handleChange,
  onSubmit: handleSubmit,
});

const withFormValues = formValues<any, ShippingLaneUpsertFormCombinedProps>({
  selectedRegionId: 'regionId',
  shippingLaneDirection: 'shippingLaneDirection',
});

export default withForm(withFormValues(ShippingLaneUpsertForm));
