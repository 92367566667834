import { createSelector } from 'reselect';
import reduce from 'lodash/fp/reduce';

import getGmvByTimeline from './get-gmv-by-timeline';
import { RootState } from '../../../stores/types';
import { AnalyticsState } from '../types';

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/MAX_SAFE_INTEGER
const MAX_SAFE_INTEGER = Number.MAX_SAFE_INTEGER || 9007199254740991;

// TODO: Update Flow API to return this value instead.
const computeNumberOfOrders = reduce<
io.flow.internal.v0.models.OrderRevenueTimelineDataPoint, number
>(
  (total, dataPoint) => (
    (total <= MAX_SAFE_INTEGER) ? total + dataPoint.count : total),
  0,
);

export default createSelector<RootState, AnalyticsState['gmvByTimeline']['data'], number>(
  getGmvByTimeline,
  (timeline) => {
    const dataPoints = timeline?.data;
    const numberOfOrders = computeNumberOfOrders(dataPoints);
    return numberOfOrders;
  },
);
