import moment, { Moment } from 'moment';
import numeral from 'numeral';
import { ReportInterval } from '@flowio/api-internal-constants';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import classNames from 'classnames';
import generateColorScale from '../generate-color-scale';

function getFormattedDate(interval: ReportInterval,
  timestamp: Moment | string, isLast: boolean, endDate: Moment | string): string {
  let formattedEndDate;
  switch (interval) {
    case ReportInterval.MONTHLY:
      formattedEndDate = isLast ? moment(endDate).utc().format('MMM DD, YYYY')
        : moment(timestamp).utc().endOf('month').format('MMM DD, YYYY');
      return `${moment(timestamp).utc().startOf('month').format('MMM DD, YYYY')} - ${formattedEndDate}`;
    case ReportInterval.WEEKLY:
      formattedEndDate = isLast ? moment(endDate).utc().format('MMM DD, YYYY')
        : moment(timestamp).utc().add(1, 'week').subtract(1, 'day')
          .format('MMM DD, YYYY');
      return `${moment(timestamp).utc().format('MMM DD, YYYY')} - ${formattedEndDate}`;
    case ReportInterval.HOURLY:
      return moment(timestamp).utc().format('MMM DD, YYYY HH:mm');
    default:
      return moment(timestamp).utc().format('MMM DD, YYYY');
  }
}

export default function createAnalyticsTooltipHtml(
  data: io.flow.internal.v0.models.OrderRevenueTimelineDataPoint,
  // ClassNamesFn isn't exposred by module so used typeof
  styles: Record<string, string>,
  valueKey: string,
  metricName: string,
  country: string,
  interval: ReportInterval,
  colorIndex: number,
  endDate: string,
  isLast: boolean,
  organizationCountry: string,
  organizationCurrency: string,
): string {
  const colorScale = generateColorScale();
  const formattedValue = valueKey === 'count'
    ? numeral(data.count).format('0.[00]a')
    : new Intl.NumberFormat(organizationCountry, {
      style: 'currency',
      currency: organizationCurrency,
      notation: 'compact',
      maximumSignificantDigits: 4,
    }).format(data.revenue.amount);

  return ReactDOMServer.renderToStaticMarkup(
    <table>
      <thead>
        <tr>
          <th className={classNames(styles.nameColumn, styles.headColumn)}>
            {getFormattedDate(interval, data.timestamp, isLast, endDate)}
          </th>
          <th className={classNames(styles.valueColumn, styles.headColumn)}>{metricName}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className={styles.nameColumn}>
            <div
              className={styles.legendRect}
              style={{ backgroundColor: colorScale(colorIndex) }}
            />
            <span>{country}</span>
          </td>
          <td className={styles.valueColumn}>{formattedValue}</td>
        </tr>
      </tbody>
    </table>,
  );
}
