import React, { useEffect } from 'react';
import AuthLayout from './auth-layout';

interface Props {
  redirect: string;
}

const ApiDocs: React.FC<Props> = ({
  redirect,
}) => {
  useEffect(() => {
    if (redirect) {
      window.location.href = redirect + window.location.search;
    }
  }, [redirect]);

  return (
    <AuthLayout>
      <p>You are now logged in to docs.flow.io and should be redirected there immediately.</p>
    </AuthLayout>
  );
};

ApiDocs.displayName = 'ApiDocs';

ApiDocs.defaultProps = {
  redirect: undefined,
};

export default ApiDocs;
