import { css } from '@emotion/css';
import colors from '../../../../../theme/tokens/colors';

export const resultsTooltip = css`
  background-color: rgba(25, 25, 25, 0.90);
  border-radius: 4px;
  color: white;
  display: none;
  font-size: 12px;
  line-height: 12px;
  padding: 4px;
  pointer-events: none;
  position: absolute;
  text-align: center;
  width: 275px;
`;

export const legendRect = css`
  border-radius: 2px;
  display: inline-block;
  height: 8px;
  margin-right: 4px;
  width: 8px;
`;

export const nameColumn = css`
  max-width: 195px;
  width: 195px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const valueColumn = css`
  text-align: right;
  width: 80px;
`;

export const chartContainer = css`
  padding-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid ${colors.mercury};
`;
