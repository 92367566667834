import { compose } from 'redux';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { browserHistory } from 'react-router';
import { withFetch } from '@flowio/redux-fetch';
import get from 'lodash/get';
import find from 'lodash/find';
import isArray from 'lodash/isArray';
import createToast from '../../../../console/actions/createToast';
import {
  fetchExperiments,
  editExperiment,
  changeNewExperiment,
  goToExperiment,
  fetchExperimentDiscriminatorValues,
  fetchExperimentFormDefaults,
  deleteExperiment,
  updateExperimentStatus,
  openExperimentsModal,
  toggleEndExperimentModal,
} from '../../../actions';
import {
  ExperimentsActions,
} from '../../../types';
import { fetchOrganization, getOrganizationId } from '../../../../organization';
import {
  getExperiments,
  getSelectedExperiment,
} from '../../../selectors';
import List from '../components/experimentsList';
import { DefaultStatusFilter } from '../../../constants/experiment-status';
import { ThunkDispatcher, RootState, RootActionTypes } from '../../../../../stores/types';
import Messages from '../../../constants/messages';
import { StateProps, DispatchProps, OwnProps } from '../types';

interface QueryObject {
  limit: number;
  sort: string;
  sortOrder: string;
  status: io.flow.internal.v0.enums.Status[];
}

const computeQueryObject = (status: io.flow.internal.v0.enums.Status[]): QueryObject => ({
  limit: 100,
  sort: 'status,-started_at',
  sortOrder: 'asc',
  status: status || DefaultStatusFilter,
});

const getAsyncState = (
  dispatch: ThunkDispatcher,
  _: () => RootState,
  ownProps: OwnProps,
): Promise<unknown> => {
  const { organization } = ownProps.params;
  const queryObject = computeQueryObject(get(ownProps, 'location.query.status', ''));
  return Promise.all([
    dispatch(fetchExperiments(organization, queryObject)),
    dispatch(fetchOrganization(organization)),
  ]);
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = (
  state: RootState,
  ownProps: OwnProps,
) => {
  const paramStatus = get(ownProps, 'location.query.status', DefaultStatusFilter);

  return {
    status: isArray(paramStatus) ? paramStatus : [paramStatus],
    selectedExperiment: getSelectedExperiment(state),
    experiments: getExperiments(state),
    organizationId: getOrganizationId(state),
  };
};

const createMessageToast = (intent: 'positive' | 'negative', message: string): RootActionTypes => createToast({
  content: message,
  icon: intent === 'positive' ? 'CheckCircle' : 'Error',
  intent,
});

const getToastMessage = (status: io.flow.internal.v0.enums.Status): string => {
  switch (status) {
    case 'live':
      return Messages.LAUNCHED_TOAST_MESSAGE;
    case 'scheduled':
      return Messages.SCHEDULED_TOAST_MESSAGE;
    default:
      return `Experiment has been ${status}`;
  }
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch: ThunkDispatcher,
  ownProps: OwnProps,
): DispatchProps => {
  const {
    params: {
      organization,
    },
    location,
  } = ownProps;
  const queryStatus = get(location, 'query.status', '');

  return {
    onRequestNextPage(): void {
      const currentPage = parseInt(get(location, 'query.pageNumber'), 10) || 1;
      const pageNumber = currentPage + 1;

      browserHistory.push({
        pathname: location.pathname,
        query: { ...get(location, 'query'), pageNumber },
      });
    },
    onRequestPreviousPage(): void {
      const currentPage = parseInt(get(location, 'query.pageNumber'), 10) || 1;
      const pageNumber = Math.max(1, currentPage - 1);

      browserHistory.push({
        pathname: location.pathname,
        query: { ...get(location, 'query'), pageNumber },
      });
    },
    onRequestFilter({ status }: { status: io.flow.internal.v0.enums.Status[] }): void {
      const query = status ? {
        status,
      } : {};

      const nextLocation = ownProps.location;
      nextLocation.query = query;

      browserHistory.push(nextLocation);
    },
    onNewExperiment(): Promise<ExperimentsActions> {
      return Promise.all([
        dispatch(changeNewExperiment()),
        dispatch(fetchExperimentDiscriminatorValues(organization)),
      ]).then(
        () => dispatch(openExperimentsModal()),
      );
    },
    onEditExperiment(key, experiments): Promise<ExperimentsActions> {
      const experiment = find(experiments, ['key', key]);

      if (experiment) {
        return Promise.all([
          dispatch(editExperiment(experiment)),
          dispatch(fetchExperimentFormDefaults(organization, key)),
        ]).then(
          () => dispatch(openExperimentsModal()),
        );
      }
      return Promise.reject();
    },
    onViewExperiment(key): void {
      dispatch(goToExperiment(key));
    },
    onUpdateExperimentStatus(key, status): Promise<RootActionTypes> {
      return dispatch(updateExperimentStatus(organization, key, status)).then(
        () => dispatch(
          createMessageToast('positive', getToastMessage(status)),
        ),
      ).catch(
        ({ message }) => dispatch(
          createMessageToast('negative', `Failed to update experiment: ${message}`),
        ),
      );
    },
    onDeleteExperiment(key): Promise<unknown> {
      return dispatch(deleteExperiment(organization, key)).then(
        () => Promise.all([
          dispatch(fetchExperiments(organization, computeQueryObject(queryStatus))),
          dispatch(
            createMessageToast('positive', 'Deleted!'),
          ),
        ]),
      ).catch(
        () => dispatch(
          createMessageToast('negative', 'Delete failed, please try again'),
        ),
      );
    },
    toggleEndExperimentModal(experiment?: io.flow.internal.v0.unions.Experiment): void {
      dispatch(toggleEndExperimentModal(experiment));
    },
    onCreateToast(message: string): void {
      dispatch(createMessageToast('negative', message));
    },
  };
};

export default compose(
  withFetch(getAsyncState),
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps),
)(List);
