import { compose } from 'redux';
import { RouterState, browserHistory } from 'react-router';
import assign from 'lodash/assign';
import { connect } from 'react-redux';
import { withFetch } from '@flowio/redux-fetch';

import getCurrencyRates from '../actions/getCurrencyRates';
import { fetchCurrencies, getCurrencies } from '../../organization';
import CurrencyRates from '../components/CurrencyRates/currency-rates';
import { RootState } from '../../../stores/types';
import { ThunkDispatcher } from '../../../middlewares/types';
import {
  CurrencyRateDispatchProps,
  CurrencyRateStateProps,
  SortedColumn,
  SortOrder,
} from '../types';

const getAsyncState = (
  dispatch: ThunkDispatcher,
  _getState: () => RootState,
  ownProps: RouterState,
) => {
  const { organization } = ownProps.params;
  return Promise.all([
    dispatch(getCurrencyRates(organization, ownProps.location.query)),
    dispatch(fetchCurrencies(organization)),
  ]);
};

const mapStateToProps = (state: RootState, ownProps: RouterState): CurrencyRateStateProps => ({
  baseCurrency: ownProps.location.query.baseCurrency,
  baseCurrencies: getCurrencies(state),
  isFirstPage: state.utilities.rates.isFirstPage,
  isLastPage: state.utilities.rates.isLastPage,
  rates: state.utilities.rates.results,
  sortedColumn: ownProps.location.query.sort,
  sortOrder: ownProps.location.query.sortOrder,
});

const mapDispatchToProps = (
  _dispatch: ThunkDispatcher,
  ownProps: RouterState,
): CurrencyRateDispatchProps => ({
  onRequestBaseCurrencyChange(baseCurrency: string) {
    browserHistory.push({
      pathname: ownProps.location.pathname,
      query: assign({}, ownProps.location.query, {
        baseCurrency,
        pageNumber: 1,
      }),
    });
  },
  onRequestNextPage(entriesPerPage = 25) {
    const currentPage = parseInt(ownProps.location.query.pageNumber, 10) || 1;
    const pageNumber = currentPage + 1;

    browserHistory.push({
      pathname: ownProps.location.pathname,
      query: assign({}, ownProps.location.query, { pageNumber, entriesPerPage }),
    });
  },
  onRequestPreviousPage(entriesPerPage = 25) {
    const currentPage = parseInt(ownProps.location.query.pageNumber, 10) || 1;
    const pageNumber = Math.max(1, currentPage - 1);

    browserHistory.push({
      pathname: ownProps.location.pathname,
      query: assign({}, ownProps.location.query, { pageNumber, entriesPerPage }),
    });
  },
  onRequestSort(value: SortedColumn, direction: SortOrder) {
    browserHistory.push({
      pathname: ownProps.location.pathname,
      query: assign({}, ownProps.location.query, {
        sort: value,
        sortOrder: direction,
      }),
    });
  },
});

export default compose<React.PureComponent>(
  withFetch(getAsyncState),
  connect<
  CurrencyRateStateProps,
  CurrencyRateDispatchProps,
  RouterState,
  RootState
  >(mapStateToProps, mapDispatchToProps),
)(CurrencyRates);
