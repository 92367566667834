import { css } from '@emotion/react';
import { Box } from '@flowio/react-box';
import { Banner, BannerText } from '@flowio/react-banner';
import { Alert } from '@flowio/react-icons';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useState } from 'react';
import { MergedProps } from '../types';
import { MarketingGatewayIntegratedPlatform } from '../../../types';
import OnboardingCard from '../../OnboardingCard';
import ChannelCard from '../../ChannelCard';
import MarketingPage from '../../MarketingPage';
import ManualChannelsDialog from '../../ManualChannelsDialog';
import AddChannelsButton from '../../AddChannelsButton';
import MarketingButton from '../../MarketingButton';

const LandingPage: React.FC<MergedProps> = ({
  location: {
    query,
  },
  organizationId,
  isFbInitialized,
  onAddAccountClicked,
  onAccountCardClicked,
  onDisconnectChannel,
  onRequestNewChannel,
  onRenewAccess,
  countries,
  languages,
  channels,
  error,
}) => {
  const [showManualChannelsDialog, setShowManualChannelsDialog] = useState(false);

  useEffect(() => {
    if (query.intent && query.intent === 'manual') {
      setShowManualChannelsDialog(true);
    }
  }, [query]);

  const onboardPlatform = useCallback((
    platform: MarketingGatewayIntegratedPlatform,
    isManual?: boolean,
  ) => {
    onAddAccountClicked(organizationId, platform, isManual);
  }, [organizationId, onAddAccountClicked]);

  if (error) {
    const { messages = [] } = error;
    return (
      <>
        {messages.map((message) => (
          <Banner icon={Alert} intent="negative">
            <BannerText content={JSON.stringify(message)} />
          </Banner>
        ))}
      </>
    );
  }

  if (isEmpty(channels.automated.connected) && isEmpty(channels.manual.connected)) {
    const platformsToOnboardIntegrated = Object.keys(
      channels.automated.unconnected,
    ) as io.flow.internal.v0.enums.MarketingGatewayPlatform[];

    return (
      <MarketingPage heading="Product Feeds">
        {(
          platformsToOnboardIntegrated.map(
            (channel: io.flow.internal.v0.enums.MarketingGatewayPlatform) => (
              <OnboardingCard integrationType="automated" platforms={[channels.automated.unconnected[channel]]} onboardPlatform={onboardPlatform} />
            ),
          )
        )}
        <OnboardingCard integrationType="manual" platforms={Object.values(channels.manual.unconnected)} onboardPlatform={onboardPlatform} />
      </MarketingPage>
    );
  }

  return (
    <MarketingPage
      heading="Product Feeds"
      cta={(
        <Box alignItems="center" spacing="loose">
          <MarketingButton css={css({ height: '40px' })} onClick={onRequestNewChannel}>Request New Channel</MarketingButton>
          <AddChannelsButton
            organizationId={organizationId}
            manualChannels={Object.values(channels.manual.unconnected)}
            integratedChannels={Object.values(channels.automated.unconnected)}
          />
        </Box>
      )}
    >
      {[
        ...Object.values(channels.automated.connected),
        ...Object.values(channels.manual.connected),
      ].map((channel) => (
        <ChannelCard
          organizationId={organizationId}
          isFbInitialized={isFbInitialized}
          onDisconnectChannel={onDisconnectChannel}
          onRenewAccess={onRenewAccess}
          onViewClick={onAccountCardClicked}
          channel={channel}
          countries={countries}
          languages={languages}
        />
      ))}
      <ManualChannelsDialog
        isOpen={showManualChannelsDialog}
        onClose={(): void => setShowManualChannelsDialog(false)}
        channels={Object.values(channels.manual.unconnected).map((c) => c.platform)}
      />
    </MarketingPage>
  );
};

LandingPage.displayName = 'LandingPage';

export default LandingPage;
