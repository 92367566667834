import { ThunkDispatcher, ThunkResult } from '../../../stores/types';
import ActionTypes from '../constants/action-types';
import ErrorTypes from '../constants/error-types';
import { getHasResourceAccess } from '../fbSDK';
import showAuthenticationToast from './showAuthenticationToast';
import standardAction from '../../../utilities/standard-action';

const checkCatalogAccess = (
  catalogId: number,
): ThunkResult<Promise<void>> => (
  dispatch: ThunkDispatcher,
): Promise<void> => {
  dispatch(standardAction(ActionTypes.CHECK_CATALOG_ACCESS_REQUEST));

  return getHasResourceAccess(catalogId)
    .then((hasAccess) => {
      if (!hasAccess) {
        dispatch(
          showAuthenticationToast(new Error(ErrorTypes.CATALOG_ACCESS_ERROR(catalogId))),
        );
      }
      dispatch(standardAction(ActionTypes.CHECK_CATALOG_ACCESS_SUCCESS, hasAccess));
    })
    .catch(() => {
      dispatch(standardAction(ActionTypes.CHECK_CATALOG_ACCESS_FAILURE));
    });
};

export default checkCatalogAccess;
