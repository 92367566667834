import { browserHistory } from 'react-router';
import map from 'lodash/map';
import standardAction from '../../../utilities/standard-action';

import { ActionTypes } from '../constants';
import { ThunkExtraArguments, ThunkDispatcher, RootState } from '../../../stores/types';

export default function createOrganization(_auth: string, name?: string, id?: string, environment: io.flow.v0.enums.Environment = 'production') {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<void> => {
    dispatch(standardAction(ActionTypes.ACCT_ORG_CREATE_SUBMIT));

    return extra.api(getState()).organizations.post({
      body: { name, id, environment },
    }).then((response) => {
      switch (response.status) {
        case 201:
          dispatch(standardAction(ActionTypes.ACCT_ORG_CREATE_COMPLETED, response.body));
          browserHistory.push('/');
          return;
        case 401:
          dispatch(standardAction(ActionTypes.ACCT_ORG_CREATE_ERROR, [
            { message: 'You are not authorized to create organizations.' },
          ]));
          return;
        case 422:
          dispatch(standardAction(
            ActionTypes.ACCT_ORG_CREATE_ERROR,
            map(response.body.messages, (m) => ({ message: m })),
          ));
          return;
        default:
          dispatch(standardAction(ActionTypes.ACCT_ORG_CREATE_ERROR, [
            { message: 'Unknown error while attempting to create organization' },
          ]));
      }
    }).catch(() => {
      dispatch(standardAction(ActionTypes.ACCT_ORG_CREATE_ERROR, [
        { message: 'Unknown error while attempting to create organization' },
      ]));
    });
  };
}
