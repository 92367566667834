import { createSelector } from 'reselect';
import assign from 'lodash/assign';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import map from 'lodash/map';

import { calculatePercentage, toPercentageBy } from '../../../utilities/numbers';
import getGmvByCountry from './get-gmv-by-country';
import getNumberOfOrders from './get-number-of-orders';
import { OrderRevenueRegionChartWithPercent, OrderRevenueRegionChartWithOrderCount } from '../types';
import { RootState } from '../../../stores/types';

const toPercentage = toPercentageBy(0);

// Returns order count by country with data list sorted by order count.
export default createSelector<
RootState,
number,
OrderRevenueRegionChartWithPercent,
OrderRevenueRegionChartWithOrderCount
>(
  getNumberOfOrders,
  getGmvByCountry,
  (numberOfOrders, gmvByCountry) => {
    // Compute percentage for each order count relative to total number of orders.
    const prevData = get(gmvByCountry, 'data');
    const nextData = map(prevData, (data) => assign({}, data, {
      orders: {
        count: data.count,
        percentage: toPercentage(calculatePercentage(data.count, numberOfOrders)),
      },
    }));

    // Return data sorted by highest amount
    return assign({}, gmvByCountry, {
      data: orderBy(nextData, (data) => get(data, 'count'), 'desc'),
    });
  },
);
