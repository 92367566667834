import { combineReducers } from 'redux';

import current from './current';
import experiments from './experiments';
import experiences from './experiences';
import experimentResults from './experiment-results';
import experimentValues from './experiment-values';
import experimentForm from './experiment-form';
import ui from './ui';

export default combineReducers({
  current,
  entities: combineReducers({
    experiments,
    experiences,
    experimentResults,
    experimentValues,
    experimentForm,
  }),
  ui,
});
