import { withRouter, WithRouterProps } from 'react-router';
import BemHelper from '@flowio/bem-helper';
import React, { PureComponent } from 'react';
import { Nav, NavLink } from '@flowio/react-nav';
import createRoutesFactory from '../../utilities/createRoutesFactory';
import { getZigZagReturnsUrl, getZigZagReturnsPortal } from '../../../../hacks/get-zig-zag-returns-url';
import { LeftNavigationProps } from '../../types/props';
import canViewPage from '../../utilities/canViewPage';
import { hasOnlyChannelOrgAdminRoles } from '../../utilities/is-channel-organization-admin-user';
import { hasOnlyFulfillmentRole } from '../../utilities/isFulfillmentUser';
import { hasOnlyClassificationRoles } from '../../utilities/is-classification-user';

if (process.browser) {
  require('./left-navigation.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('left-navigation');

export class LeftNavigation extends PureComponent<LeftNavigationProps & WithRouterProps> {
  static displayName = 'LeftNavigation';

  static defaultProps = {
    isGlobalFailureSummariesEnabled: false,
    isGlobalPriceBooksEnabled: false,
    isGlobalFulfillmentConsoleEnabled: false,
    isGlobalPendingMembersEnabled: false,
    isGlobalShopifySyncStatusEnabled: false,
    isGlobalConsoleReturnsEnabled: false,
    isFlowLabsEnabled: false,
    orgRoles: [],
  };

  render(): React.ReactElement {
    const {
      orgRoles = [],
      isGlobalFailureSummariesEnabled,
      isGlobalPendingMembersEnabled,
      isGlobalShopifySyncStatusEnabled,
      isGlobalFulfillmentConsoleEnabled,
      isGlobalPriceBooksEnabled,
      isGlobalConsoleReturnsEnabled,
      isFlowLabsEnabled,
      organization,
      router,
    } = this.props;

    const isChannelOrgAdmin = hasOnlyChannelOrgAdminRoles(orgRoles);
    const isFulfillmentUser = hasOnlyFulfillmentRole(orgRoles);
    const isClassificationUser = hasOnlyClassificationRoles(orgRoles);

    const createRoutes = createRoutesFactory(router);

    const classificationUserLinks = isClassificationUser ? createRoutes([
      {
        text: 'Items',
        icon: 'Book',
        href: `/${organization}/catalog/items`,
      },
    ]) : null;

    const channelOrgAdminLinks = isChannelOrgAdmin ? createRoutes([
      {
        text: 'Billing',
        icon: 'Paper',
        href: `/${organization}/organization/accounts`,
      },
      {
        text: 'Members',
        icon: 'UserCircle',
        href: `/${organization}/organization/members`,
      },
      {
        text: 'Fulfillment',
        icon: 'Fulfillment',
        href: `/${organization}/fulfillment`,
      },
    ]) : null;

    const fulfillmentUserLinks = isFulfillmentUser ? createRoutes([
      {
        text: 'Fulfillment',
        icon: 'Fulfillment',
        href: `/${organization}/fulfillment`,
      },
    ]) : null;

    const defaultLinks = createRoutes([{
      href: `/${organization}/experience`,
      icon: 'Globe',
      text: 'Experiences',
      disabled: !canViewPage(`/${organization}/experience`, orgRoles),
    }, {
      defaultExpanded: router.isActive(`/${organization}/catalog`, false),
      icon: 'Book',
      text: 'Product Catalog',
      links: [{
        href: `/${organization}/catalog/items`,
        disabled: !canViewPage(`/${organization}/catalog/items`, orgRoles),
        text: 'Items',
      }, {
        href: `/${organization}/catalog/exclusions`,
        disabled: !canViewPage(`/${organization}/catalog/exclusions`, orgRoles),
        text: 'Exclusions',
      }, {
        href: `/${organization}/catalog/non-returnable`,
        disabled: !canViewPage(`/${organization}/catalog/non-returnable`, orgRoles),
        text: 'Non-Returnable',
      }],
      disabled: !canViewPage(`/${organization}/catalog`, orgRoles),
    }, {
      defaultExpanded: router.isActive(`/${organization}/classification`, false),
      icon: 'Harmonization',
      text: 'Classification',
      href: `/${organization}/classification`,
      disabled: !canViewPage(`/${organization}/classification`, orgRoles),
    }, {
      icon: 'Discount',
      text: 'Discounts',
      disabled: !canViewPage(`/${organization}/discounts`, orgRoles),
      href: `/${organization}/discounts`,
    }, {
      disabled: !isGlobalPriceBooksEnabled || !canViewPage(`/${organization}/price-books`, orgRoles),
      href: `/${organization}/price-books`,
      icon: 'LocalOffer',
      text: 'Price Books',
    }, {
      disabled: !isGlobalFailureSummariesEnabled || !canViewPage(`/${organization}/events/failures/summaries`, orgRoles),
      icon: 'CircleCheck',
      text: 'Event Failure Summaries',
      href: `/${organization}/events/failures/summaries`,
    }, {
      href: `/${organization}/logistics/shipping/configurations`,
      icon: 'Package',
      text: 'Logistics',
      disabled: !canViewPage(`/${organization}/logistics/shipping/configurations`, orgRoles),
    }, {
      disabled: !isGlobalConsoleReturnsEnabled
        || !canViewPage(getZigZagReturnsUrl(organization), orgRoles)
        || !canViewPage(getZigZagReturnsPortal(organization), orgRoles),
      icon: 'PackageArrows',
      text: 'Returns',
      links: [{
        href: getZigZagReturnsUrl(organization),
        text: 'Manage Returns',
        rel: 'noopener noreferrer',
      }, {
        href: getZigZagReturnsPortal(organization),
        rel: 'noopener noreferrer',
        text: 'Returns Portal',
      }],
    }, {
      disabled: !isGlobalFulfillmentConsoleEnabled || !canViewPage(`/${organization}/fulfillment`, orgRoles),
      href: `/${organization}/fulfillment`,
      text: 'Fulfillment',
      icon: 'Fulfillment',
    },
    {
      disabled: !canViewPage(`/${organization}/marketing`, orgRoles),
      href: `/${organization}/marketing`,
      icon: 'Channels',
      text: 'Product Feeds',
    },
    {
      disabled: !canViewPage(`/${organization}/orders/search`, orgRoles),
      href: `/${organization}/orders/search`,
      icon: 'Headset',
      text: 'Customer Service',
    }, {
      href: `/${organization}/analytics`,
      icon: 'Chart',
      text: 'Analytics',
      disabled: !canViewPage(`/${organization}/analytics`, orgRoles),
    }, {
      href: `/${organization}/optin/messages`,
      icon: 'Consent',
      text: 'Customer Opt-Ins',
      disabled: !canViewPage(`/${organization}/optin/messages`, orgRoles),
    }, {
      defaultExpanded: router.isActive(`/${organization}/utilities`, false),
      icon: 'Wrench',
      text: 'Utility & Tools',
      links: [{
        href: `/${organization}/utilities/order-check`,
        disabled: !canViewPage(`/${organization}/utilities/order-check`, orgRoles),
        text: 'Order Check',
      }, {
        href: `/${organization}/utilities/item-check`,
        disabled: !canViewPage(`/${organization}/utilities/item-check`, orgRoles),
        text: 'Item Check',
      }, {
        href: `/${organization}/utilities/price-check`,
        disabled: !canViewPage(`/${organization}/utilities/price-check`, orgRoles),
        text: 'Price Check',
      },
      {
        href: `/${organization}/organization/imports`,
        disabled: !canViewPage(`/${organization}/utilities/imports`, orgRoles),
        text: 'Imports',
      },
      {
        href: `/${organization}/utilities/shopify-sync-status`,
        disabled: !isGlobalShopifySyncStatusEnabled || !canViewPage(`/${organization}/utilities/shopify-sync-status`, orgRoles),
        text: 'Shopify Sync Status',
      },
      {
        href: `/${organization}/utilities/rates`,
        disabled: !canViewPage(`/${organization}/utilities/rates`, orgRoles),
        text: 'Currency Rates',
      },
      ],
    }, {
      disabled: !isGlobalPendingMembersEnabled || !canViewPage(`/${organization}/pending`, orgRoles),
      icon: 'CircleCheck',
      text: 'Pending Members',
      href: `/${organization}/pending`,
    },
    {
      defaultExpanded: router.isActive(`/${organization}/organization`, false),
      icon: 'Organization',
      text: 'Organization Settings',
      disabled: !canViewPage(`/${organization}/organization`, orgRoles),
      links: [{
        href: `/${organization}/organization/members`,
        text: 'Members',
      }, {
        href: `/${organization}/organization/features`,
        text: 'Features',
      }, {
        href: `/${organization}/organization/integrations`,
        text: 'Integrations',
      }, {
        href: `/${organization}/organization/accounts`,
        text: 'Billing',
      }, {
        href: `/${organization}/organization/policies`,
        text: 'Policies',
      }],
    },
    {
      disabled: !isFlowLabsEnabled,
      icon: 'Lightbulb',
      href: `/${organization}/labs`,
      text: 'Control Labs',
    }]);

    return (
      <div className={bem.block()}>
        <Nav>
          {fulfillmentUserLinks || classificationUserLinks || channelOrgAdminLinks || defaultLinks}
        </Nav>

        { !isChannelOrgAdmin && !isFulfillmentUser && !isClassificationUser && (
          <div className={bem.element('api-reference')}>
            <NavLink href="https://docs.flow.io" text="API Reference" />
          </div>
        )}
      </div>
    );
  }
}

export default withRouter<LeftNavigationProps>(LeftNavigation);
