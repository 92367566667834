import React from 'react';
import { compose } from 'redux';
import noop from 'lodash/noop';
import { Button } from '@flowio/react-button';
import { SelectField } from '@flowio/react-select';
import QueryTargetField, { QueryTargetFieldType } from '../../../../components/query-target-field';

import withValidation from '../../../../components/with-validation';
import { DataSource } from '../../types';
import { WithValidationProps } from '../../../../components/with-validation/with-validation';
import * as styles from './product-details-form.styles';
import getTextFieldValue from '../../../../utilities/getTextFieldValue';

export interface ProductDetailsFormData {
  experience: io.flow.v0.models.Experience;
  product: DataSource;
}

interface ProductDetailsFormProps {
  experience: io.flow.v0.models.Experience | null;
  item: io.flow.v0.models.Item;
  experiences: io.flow.v0.models.Experience[];
  onValidSubmit: (...args: any) => void;
  onInvalidSubmit?: (...args: any) => void;
}

class ProductDetailsForm extends React.PureComponent<
ProductDetailsFormProps & WithValidationProps
> {
  static displayName = 'ProductDetailsForm';

  static defaultProps = {
    onValidSubmit: noop,
    onInvalidSubmit: noop,
  };

  handleSubmit = ({ isValid }: { isValid: boolean }, formData: ProductDetailsFormData): void => {
    const { onValidSubmit, onInvalidSubmit } = this.props;

    const formattedData = {
      ...formData,
      experience: formData.experience.key,
    };

    if (isValid && onValidSubmit) {
      onValidSubmit(formattedData);
    } else if (onInvalidSubmit) {
      onInvalidSubmit(formattedData);
    }
  };

  handleExperienceChange = (exp: string): void => {
    const { fields } = this.props;
    if (fields) {
      const { experience } = fields;
      experience.events.onBlur(exp);
      experience.events.onChange(exp);
    }
  };

  handleProductSearch = (option: DataSource): void => {
    const { fields } = this.props;
    if (fields) {
      const { product } = fields;

      product.events.onBlur(option);
      product.events.onChange(option);
    }
  };

  render(): React.ReactNode {
    const {
      experiences,
      fields: {
        experience,
        product,
      },
      isInvalid,
      onSubmitValidate,
    } = this.props;

    return (
      <form
        className={styles.productDetailsForm}
        onSubmit={onSubmitValidate(this.handleSubmit)}
        noValidate
      >
        <h2 className={styles.label}>
          Item Number or Name
        </h2>
        <QueryTargetField
          errorText={product.error || undefined}
          hintText="Enter an item number or name"
          onChange={this.handleProductSearch}
          defaultValue={product.value as string}
          style={{ display: 'block' }}
          type={QueryTargetFieldType.TYPE_ITEMS}
        />
        <SelectField
          feedback={experience.error}
          intent={experience.error ? 'negative' : 'neutral'}
          onValueChange={this.handleExperienceChange}
          className={styles.experienceSelect}
          hintText="Select Experience"
          options={experiences.map((e) => ({ value: e.key, content: e.name }))}
          value={getTextFieldValue(experience.value)}
          fluid
        />
        <Button
          disabled={isInvalid}
          intent="primary"
          content="Check"
          type="submit"
        />
      </form>
    );
  }
}

export default compose(
  withValidation<ProductDetailsFormProps>((
    { experience, item }: ProductDetailsFormProps,
  ) => ({
    experience: {
      defaultValue: experience,
      validations: {
        required: true,
        message: 'You must select an experience',
      },
    },
    product: {
      defaultValue: item ? {
        text: item.number,
        label: item.name,
      } : undefined,
      validations: {
        required: true,
        message: 'You must enter an item number or name',
      },
    },
  })),
)(ProductDetailsForm);
