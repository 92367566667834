import { createSelector } from 'reselect';
import { getIndexedEntities } from '@flowio/redux-filtering-paging-sorting';
import get from 'lodash/get';
import getActiveShippingConfigurationKey from './getActiveShippingConfigurationKey';
import getShippingConfigurationPaginationState from './getShippingConfigurationPaginationState';
import { RootState } from '../../../stores/types';

interface IndexShippingConfigurations {
  [key: string]: io.flow.v0.models.ShippingConfiguration;
}

export default createSelector<
RootState,
string,
IndexShippingConfigurations,
io.flow.v0.models.ShippingConfiguration
>(
  getActiveShippingConfigurationKey,
  getIndexedEntities(getShippingConfigurationPaginationState),
  (
    shippingConfigurationKey: string,
    shippingConfigurations: IndexShippingConfigurations,
  ): io.flow.v0.models.ShippingConfiguration => (
    get(shippingConfigurations, shippingConfigurationKey)
  ),
);
