import { OptinAttributeFormValues } from '../types';

export default (
  values: OptinAttributeFormValues,
): io.flow.internal.v0.models.OptinAttributeForm => ({
  name: values.name,
  optin_attribute_key: values.optinAttributeKey,
  status: values.status,
  target: values.target,
});
