import { combineReducers } from 'redux';
import countries from './countries';
import current from './current';
import currencies from './currencies';
import dialogs from './dialogs';
import experiences from './experiences';
import forms from './forms';
import memberships from './memberships';
import publicKeys from './publicKeys';
import regionSettings from './region-settings';
import statuses from './statuses';
import userMembership from './userMembership';
import imports from './imports';
import { OrganizationState } from '../types';

export default combineReducers<OrganizationState>({
  current,
  dialogs,
  entities: combineReducers({
    countries,
    currencies,
    experiences,
    imports,
    memberships,
    publicKeys,
    regionSettings,
    userMembership,
  }),
  forms,
  statuses,
});
