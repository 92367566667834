import ActionTypes from '../constants/action-types';
import { ExperimentCurrentExperiment, ExperimentsActions } from '../types';

const initialState: ExperimentCurrentExperiment = {};

const newExperiment: ExperimentCurrentExperiment = {
  discriminator: 'experience',
  name: '',
  variants: [],
};

export default function (
  state = initialState,
  action: ExperimentsActions,
): ExperimentCurrentExperiment {
  switch (action.type) {
    case ActionTypes.CHANGE_NEW_EXPERIMENT: {
      return newExperiment;
    }
    case ActionTypes.UPDATE_EXPERIMENT_STATUS_SUCCESS:
      if (state.key && (action.payload.key === state.key)) {
        return {
          ...state,
          ...action.payload,
        };
      }
      return state;
    case ActionTypes.FETCH_EXPERIMENT_SUCCESS:
    case ActionTypes.CHANGE_CURRENT_EXPERIMENT:
      return action.payload;
    default:
      return state;
  }
}
