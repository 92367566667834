import isEmpty from 'lodash/isEmpty';
import { $HttpResponse } from '@flowio/api-sdk';
import ActionTypes from '../constants/action-types';
import { getOrderEstimate, getOrderSummaryItems, getOrderSummarySettings } from '../selectors';
import {
  RootActionTypes,
  ThunkResult,
  ThunkExtraArguments,
  RootState,
} from '../../../stores/types';
import { ApiError } from '../../../utilities/clients/api-error';

export function upsertOrderEstimateRequest(): RootActionTypes {
  return {
    type: ActionTypes.UPSERT_ORDER_ESTIMATE_REQUEST,
  };
}

export function upsertOrderEstimateSuccess(order: io.flow.v0.models.Order): RootActionTypes {
  return {
    type: ActionTypes.UPSERT_ORDER_ESTIMATE_SUCCESS,
    payload: order,
  };
}

export function upsertOrderEstimateFailure(error: ApiError): RootActionTypes {
  return {
    type: ActionTypes.UPSERT_ORDER_ESTIMATE_FAILURE,
    error: true,
    payload: error,
  };
}

export default function upsertOrderEstimate(
  organization: string,
): ThunkResult<Promise<io.flow.v0.models.Order | void>> {
  return function upsertOrderEstimateEffects(
    dispatch,
    getState,
    extra: ThunkExtraArguments,
  ): Promise<io.flow.v0.models.Order | void> {
    const state: RootState = getState();
    const orderEstimate = getOrderEstimate(state);
    const items: io.flow.v0.models.LineItem[] = getOrderSummaryItems(state);
    const { experience, selections } = getOrderSummarySettings(state);

    if (!isEmpty(items)) {
      dispatch(upsertOrderEstimateRequest());
      if (orderEstimate) {
        return extra.api(state).orders.putByNumber(
          {
            number: orderEstimate.id,
            experience,
            organization,
            body: {
              items,
              selections,
            },
          },
        ).then((response) => {
          if (response.ok) {
            dispatch(upsertOrderEstimateSuccess(response.body));
            return response.body;
          }

          // TODO conversion-revisit
          const error = new ApiError(
            response as $HttpResponse<io.flow.v0.models.GenericError>,
          );
          dispatch(upsertOrderEstimateFailure(error));
          return undefined;
        }).catch((error) => {
          dispatch(upsertOrderEstimateFailure(new ApiError(error)));
          throw error;
        });
      }
      return extra.api(state).orders.post({
        organization,
        experience,
        body: {
          items,
        },
      }).then((response) => {
        if (response.ok) {
          dispatch(upsertOrderEstimateSuccess(response.body));
          return response.body;
        }

        // TODO conversion-revisit
        const error = new ApiError(
          response as $HttpResponse<io.flow.v0.models.GenericError>,
        );
        dispatch(upsertOrderEstimateFailure(error));
        return undefined;
      }).catch((error) => {
        dispatch(upsertOrderEstimateFailure(new ApiError(error)));
        throw error;
      });
    }

    return Promise.resolve();
  };
}
