import React, { useState, useEffect } from 'react';
import { WrappedFieldProps } from 'redux-form';
import { RadioButton, RadioButtonGroup } from '@flowio/react-radio-button';
import { SelectField } from '@flowio/react-select';
import { css } from '@emotion/react';
import spacing from '../../../../theme/tokens/spacing';
import { colors, fontSize } from '../../../../theme/tokens';

const followUpFields = [
  { content: 'implement the winner', value: 'implement_winner' },
  { content: 'revert back to the default experience', value: 'revert' },
];

const styles = {
  radioButton: css({
    paddingTop: spacing.s,
    paddingBottom: spacing.s,
    paddingRight: spacing.s,
    color: colors.black,
  }),
  dropdown: css({
    // a hack but this is a non standard field type
    transform: 'translateY(11px)',
    display: 'inline-block',
    div: {
      marginTop: 0,
    },
    input: {
      fontSize: fontSize[200],
    },
  }),
};

const ExperimentSignificanceActionsField: React.FC<WrappedFieldProps> = ({
  input,
}) => {
  const [action, setAction] = useState('end');
  const [followUp, setFollowUp] = useState('implement_winner');

  useEffect(() => {
    const [inputAction, inputFollowUp] = input.value.split('_and_');
    setAction(inputAction);

    if (inputFollowUp) {
      setFollowUp(inputFollowUp);
    }
  }, [input.value]);

  const handleActionChange = (value: string) => {
    input.onChange(value === 'do_nothing' ? value : `${value}_and_${followUp}`);
  };

  const handleFollowupChange = (value: string) => {
    if (action === 'end') {
      input.onChange(`end_and_${value}`);
    }
  };

  return (
    <RadioButtonGroup name="autoend" value={action} onValueChange={handleActionChange}>
      <RadioButton
        css={styles.radioButton}
        labelText="Automatically end the experiment and"
        value="end"
      />
      <SelectField
        css={styles.dropdown}
        disabled={action === 'do_nothing'}
        size="small"
        options={followUpFields}
        value={followUp}
        onValueChange={handleFollowupChange}
      />
      <RadioButton
        css={styles.radioButton}
        labelText="Continue running the Experiment until it is manually ended"
        value="do_nothing"
      />
    </RadioButtonGroup>
  );
};

ExperimentSignificanceActionsField.displayName = 'ExperimentSignificanceActionsField';

export default ExperimentSignificanceActionsField;
