import { MapStateToProps, connect } from 'react-redux';
import ContentErrorBoundary from '../components/content-error-boundary';
import { StateProps, OwnProps } from '../types';
import { RootState } from '../../../../../stores/types';
import { getOrganizationId } from '../../../../organization';

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = (state) => ({
  organization: getOrganizationId(state),
});

export default connect<StateProps, {}, OwnProps, RootState>(mapStateToProps)(ContentErrorBoundary);
