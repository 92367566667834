enum ActionTypes {
  AUTH_REGISTER_USER_SUBMIT = 'AUTHORIZE.AUTH_REGISTER_USER_SUBMIT',
  AUTH_REGISTER_USER_COMPLETED = 'AUTHORIZE.AUTH_REGISTER_USER_COMPLETED',
  AUTH_REGISTER_USER_ERROR = 'AUTHORIZE.AUTH_REGISTER_USER_ERROR',
  AUTH_LOGIN_USER_SUBMIT = 'AUTHORIZE.AUTH_LOGIN_USER_SUBMIT',
  AUTH_LOGIN_USER_COMPLETED = 'AUTHORIZE.AUTH_LOGIN_USER_COMPLETED',
  AUTH_LOGIN_USER_ERROR = 'AUTHORIZE.AUTH_LOGIN_USER_ERROR',
  AUTH_LOGOUT_USER_REQUEST = 'AUTHORIZE.AUTH_LOGOUT_USER_REQUEST',
  AUTH_LOGOUT_USER_SUCCESS = 'AUTHORIZE.AUTH_LOGOUT_USER_SUCCESS',
  AUTH_LOGOUT_USER_FAILURE = 'AUTHORIZE.AUTH_LOGOUT_USER_FAILURE',
  AUTH_FORGOT_PASSWORD_SUBMIT = 'AUTHORIZE.AUTH_FORGOT_PASSWORD_SUBMIT',
  AUTH_FORGOT_PASSWORD_COMPLETED = 'AUTHORIZE.AUTH_FORGOT_PASSWORD_COMPLETED',
  AUTH_FORGOT_PASSWORD_ERROR = 'AUTHORIZE.AUTH_FORGOT_PASSWORD_ERROR',
  AUTH_NEW_PASSWORD_SUBMIT = 'AUTHORIZE.AUTH_NEW_PASSWORD_SUBMIT',
  AUTH_NEW_PASSWORD_COMPLETED = 'AUTHORIZE.AUTH_NEW_PASSWORD_COMPLETED',
  AUTH_NEW_PASSWORD_ERROR = 'AUTHORIZE.AUTH_NEW_PASSWORD_ERROR',
  AUTHORIZE_DOCS_REQUEST = 'AUTHORIZE.AUTHORIZE_DOCS_REQUEST',
  AUTHORIZE_DOCS_COMPLETED = 'AUTHORIZE.AUTHORIZE_DOCS_COMPLETED',
  AUTHORIZE_DOCS_FAILURE = 'AUTHORIZE.AUTHORIZE_DOCS_FAILURE',
  AUTHORIZE_REDIRECT_REQUEST = 'AUTHORIZE.AUTHORIZE_REDIRECT_REQUEST',
  AUTHORIZE_REDIRECT_COMPLETED = 'AUTHORIZE.AUTHORIZE_REDIRECT_COMPLETED',
  AUTHORIZE_REDIRECT_FAILURE = 'AUTHORIZE.AUTHORIZE_REDIRECT_FAILURE',
  SHOPIFY_SET_PARAMS = 'AUTHORIZE.SHOPIFY_SET_PARAMS',
  GET_SHOP_REQUEST = 'AUTHORIZE.GET_SHOP_REQUEST',
  GET_SHOP_SUCCESS = 'AUTHORIZE.GET_SHOP_SUCCESS',
  GET_SHOP_FAILURE = 'AUTHORIZE.GET_SHOP_FAILURE',
  ASSIGN_ORG_TO_SHOP_REQUEST = 'AUTHORIZE.ASSIGN_ORG_TO_SHOP_REQUEST',
  ASSIGN_ORG_TO_SHOP_SUCCESS = 'AUTHORIZE.ASSIGN_ORG_TO_SHOP_SUCCESS',
  ASSIGN_ORG_TO_SHOP_FAILURE = 'AUTHORIZE.ASSIGN_ORG_TO_SHOP_FAILURE',
  REDIRECTING_TO_SHOPIFY_AUTH = 'AUTHORIZE.REDIRECTING_TO_SHOPIFY_AUTH',
  SET_SHOPIFY_APP_ERROR = 'SET_SHOPIFY_APP_ERROR',
}

export default ActionTypes;
