import { createSelector } from 'reselect';
import get from 'lodash/get';
import filter from 'lodash/filter';

import getQueryBuilderForAddRuleWorksheet from './getQueryBuilderForAddRuleWorksheet';

const getAvailableFiltersForAddRuleWorksheet = createSelector(
  getQueryBuilderForAddRuleWorksheet,
  (queryBuilder) => filter(get(queryBuilder, 'available'), { discriminator: 'structured' }),
);

export default getAvailableFiltersForAddRuleWorksheet;
