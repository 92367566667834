import { $HttpResponse } from '@flowio/api-sdk';
import { RootActionTypes, ThunkResult } from '../../../stores/types';
import ActionTypes from '../constants/action-types';
import { ApiError } from '../../../utilities/clients/api-error';
import { getOrganizationId } from '../../organization/selectors';

export function fetchExperienceItemByNumberRequest(): RootActionTypes {
  return {
    type: ActionTypes.FETCH_EXPERIENCE_ITEM_REQUEST,
  };
}

function exportExperienceSettingsRequest(): RootActionTypes {
  return {
    type: ActionTypes.EXPERIENCE_EXPORT_SETTINGS_REQUEST,
  };
}

function exportExperienceSettingsSuccess(payload: io.flow.v0.models.Export): RootActionTypes {
  return {
    type: ActionTypes.EXPERIENCE_EXPORT_SETTINGS_SUCCESS,
    payload,
  };
}

function exportExperienceSettingsFailure(
  error?: io.flow.v0.models.GenericError,
): RootActionTypes {
  return {
    type: ActionTypes.EXPERIENCE_EXPORT_SETTINGS_FAILURE,
    error: true,
    payload: error,
  };
}

export default function exportExperienceSettings(email: string):
ThunkResult<Promise<io.flow.v0.models.Export>> {
  return function exportExperienceSettingsEffect(
    dispatch,
    getState,
    extra,
  ): Promise<io.flow.v0.models.Export> {
    const state = getState();
    const organizationId: string = getOrganizationId(state);

    dispatch(exportExperienceSettingsRequest());
    return extra.api(getState()).exports.post({
      organization: organizationId,
      body: {
        type: {
          discriminator: 'experience_export_type',
          organization_id: organizationId,
        },
        emails: [
          email,
        ],
      },
    }).then((response) => {
      if (response.ok) {
        dispatch(exportExperienceSettingsSuccess(response.body));
        return response.body;
      }

      const error = new ApiError(
        response as $HttpResponse<io.flow.v0.models.GenericError>,
      );
      dispatch(exportExperienceSettingsFailure(response.body));
      throw error;
    });
  };
}
