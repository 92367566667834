import { ArrowUp, ArrowDown } from '@flowio/react-icons';
import styled from '@emotion/styled';
import React from 'react';
import colors from '../../../../../theme/tokens/colors';

const redOutline = colors.red[600];
const { green } = colors.chart;

const Wrapper = styled.div<{ isPositive: boolean }>`
 border: 1px solid ${(props) => (props.isPositive ? green : redOutline)};
 color: ${(props) => (props.isPositive ? green : redOutline)};
 display: flex;
 align-items: center;
 height: 20px;
 min-width: 50px;
 border-radius: 5px;

 span {
  font-size: 13px;
  font-weight: bold;
 }

 svg {
  font-weight: bold;
  height: 15px;
  margin-bottom: ${(props) => (props.isPositive ? '4px' : '0')};
  fill: currentColor;
 }
`;

type Props = {
  value: number;
};

const UpliftBadge: React.FC<Props> = ({ value }) => {
  const isPositive = Math.sign(value) === 1;
  return (
    <Wrapper isPositive={isPositive} data-automation-id={`${isPositive ? 'positive-uplift' : 'negative-uplift'}`}>
      {isPositive ? <ArrowUp width={16} /> : <ArrowDown width={16} />}
      <span>
        {/* remove minus from badge for cosmetic reasons */}
        {Math.abs(parseFloat(value.toFixed(2)))}
        %
      </span>
    </Wrapper>
  );
};

export default UpliftBadge;
