import { Box, BoxItem } from '@flowio/react-box';
import React from 'react';
import { TextInput } from '@flowio/react-text-input';
import { Image } from '@flowio/react-image';

import {
  Plus, Dash,
} from '@flowio/react-icons';
import { IconButton } from '@flowio/react-button';
import { OrderItemProps } from '../../types/components';
import { colors } from '../../../../theme/tokens';
import CheckBox from './CheckIcon';
import * as styles from './OrderItem.styles';

const OrderItem: React.FC<OrderItemProps> = ({
  index,
  item,
  itemContent = undefined,
  disabled = false,
  onSelection,
  onQuantityChange,
  selectedQuantity = 0,
}) => (
  <Box spacing="extraLoose" key={item.id} alignItems="center" justifyContent="between" className={styles.orderItem}>
    <Box spacing="extraLoose">
      {itemContent && itemContent.images.thumbnail && (
        <BoxItem>
          <Image
            accessibilityLabel={itemContent.name}
            className={styles.itemImage}
            source={itemContent.images.thumbnail.url}
          />
        </BoxItem>
      )}
      <BoxItem className={styles.itemDetailsWrapper}>
        <Box className={styles.itemInfo} direction="column" justifyContent="center" flexAuto>
          <Box alignItems="center" spacing="loose">
            <div className={styles.itemNumber}>
              {item.number}
            </div>
          </Box>
          <div className={styles.itemName}>{item.name}</div>
        </Box>
      </BoxItem>
    </Box>
    <BoxItem className={styles.selectorContainer}>
      <BoxItem className={styles.itemSelector}>
        <Box alignItems="center" spacing="tight">
          <IconButton
            className={styles.itemQuantityChangeBtn}
            disabled={selectedQuantity === 0}
            onClick={() => {
              onQuantityChange(item, `${selectedQuantity - 1}`, index);
            }}
          >
            <Dash
              className={styles.icon}
              fill={selectedQuantity === 0 ? colors.steel : colors.blue[600]}
            />
          </IconButton>
          <TextInput
            className={styles.quantityInput}
            disabled={disabled}
            clearable={false}
            max={item.quantity}
            min="0"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              onQuantityChange(item, event.target.value, index);
            }}
            type="number"
            value={selectedQuantity.toString()} // to remove the leading 0 in field
          />
          <IconButton
            className={styles.itemQuantityChangeBtn}
            disabled={selectedQuantity === item.quantity}
            onClick={() => {
              onQuantityChange(item, `${selectedQuantity + 1}`, index);
            }}
          >
            <Plus
              className={styles.icon}
              fill={selectedQuantity === item.quantity ? colors.steel : colors.blue[600]}
            />
          </IconButton>
          <span className={styles.quantityLabel}>
            of
            {` ${item.quantity}`}
          </span>
        </Box>
      </BoxItem>
      <div className={styles.checkIcon}>
        <CheckBox
          selectedItemsCount={selectedQuantity}
          totalItemsCount={item.quantity}
          onClick={(checked: boolean) => {
            onSelection(item, checked, index);
          }}
        />
      </div>
    </BoxItem>
  </Box>
);

OrderItem.displayName = 'OrderItem';

OrderItem.defaultProps = {
  disabled: false,
  itemContent: undefined,
  selectedQuantity: 0,
};

export default OrderItem;
