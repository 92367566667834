import { css } from '@emotion/css';
import colors from '../../../../theme/tokens/colors';

export const orderDetailSearchView = css`
  padding-top: 1rem;
`;

export const statusLabel = css`
  font-size: 0.875rem;
  padding-left: 0.75rem;
  padding-right: 0.5rem;
  color: ${colors.steel};
`;

export const searchResults = css`
  padding-left: 0px !important;
  padding-right: 0px !important;
`;

export const filters = css`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-top: 1rem;
`;

export const searchFilter = css`
  flex: auto;
`;

export const statusFilter = css`
  flex: none;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  white-space: nowrap;
`;
