import assign from 'lodash/assign';
import { connect, MapStateToProps } from 'react-redux';
import { withFetch } from '@flowio/redux-fetch';
import { authorizeDocs } from '../actions';
import ApiDocs from '../components/api-docs';
import { getAuthDocs } from '../selectors';
import { ThunkDispatcher, RootState } from '../../../stores/types';
import AuthState from '../types/state';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import { AuthRedirectResponse } from '../types';
import { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';

type StateProps = AuthState['docs'];

function getAsyncState(dispatch: ThunkDispatcher):
Promise<LegacyResponse<AuthRedirectResponse | FormattedErrorMessages> | void> {
  return dispatch(authorizeDocs());
}

const mapStateToProps: MapStateToProps<StateProps, {}, RootState> = (
  state: RootState,
) => assign({}, getAuthDocs(state));

export default withFetch(getAsyncState)((connect(mapStateToProps))(ApiDocs));
