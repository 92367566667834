import { Link } from 'react-router';
import { Lens, Ellipsis } from '@flowio/react-icons';
import { Button, IconButton } from '@flowio/react-button';
import { Dropdown } from '@flowio/react-dropdown';
import React from 'react';
import { TableRow, TableRowColumn } from '@flowio/react-table';
import map from 'lodash/map';
import classnames from 'classnames';
import QueryFilterToken from '../../../query-builder/components/QueryFilterToken';
import * as styles from './ReturnPolicyItem.styles';

interface ReturnPolicyItemProps {
  returnPolicy: io.flow.v0.models.ReturnPolicy;
  organizationId: string;
  onEdit: (...args: any) => void;
  onRemove: (...args: any) => void;
  currentIndex: number;
}

const ActionType = {
  Edit: 'edit',
  Delete: 'delete',
};

class ReturnPolicyItem extends React.PureComponent<ReturnPolicyItemProps> {
  static displayName = 'ReturnPolicyItem';

  constructor(props: ReturnPolicyItemProps, context: any) {
    super(props, context);
    this.handleOptionSelection = this.handleOptionSelection.bind(this);
  }

  handleOptionSelection(_index: number, value?: string) {
    const { returnPolicy, onEdit, onRemove } = this.props;
    if (value === ActionType.Edit) {
      onEdit(returnPolicy);
    } else if (value === ActionType.Delete) {
      onRemove(returnPolicy);
    }
  }

  render() {
    const {
      returnPolicy,
      organizationId,
      currentIndex,
    } = this.props;
    const returnPolicyState = returnPolicy.statistics.state;

    return (
      <TableRow key={returnPolicy.id} striped={currentIndex % 2 === 1}>
        <TableRowColumn>
          <div className={styles.rules}>
            {map(returnPolicy.query.filters, (queryFilter, index) => (
              <QueryFilterToken key={index} className={styles.rule} queryFilter={queryFilter} />
            ))}
          </div>
        </TableRowColumn>
        <TableRowColumn>
          {/* eslint-disable-next-line import/namespace */}
          <span className={classnames(styles.state, styles[returnPolicyState])}>
            <Lens width={7} />
            <span className={styles.stateText}>
              {returnPolicyState}
            </span>
          </span>
        </TableRowColumn>
        <TableRowColumn>
          {returnPolicy.statistics.number_items}
        </TableRowColumn>
        <TableRowColumn>
          <span>World</span>
        </TableRowColumn>
        <TableRowColumn>
          <div className={styles.actionGroup}>
            <Link to={`/${organizationId}/catalog/items?q=${encodeURIComponent(returnPolicy.q)}`}>
              <Button content="View Items" type="button" />
            </Link>
            <Dropdown
              trigger={(<IconButton className={styles.moreAction} icon={Ellipsis} size="medium" />)}
              onItemSelection={this.handleOptionSelection}
              items={[
                { content: 'Edit', value: ActionType.Edit },
                { content: 'Delete', value: ActionType.Delete },
              ]}
            />
          </div>
        </TableRowColumn>
      </TableRow>
    );
  }
}

export default ReturnPolicyItem;
