import { createSelector } from 'reselect';
import get from 'lodash/get';
import getExclusionRuleDeleteConfirmation from './getExclusionRuleDeleteConfirmation';

const getIsExclusionRuleDeleteConfirmationVisible = createSelector(
  getExclusionRuleDeleteConfirmation,
  (state) => get(state, 'visibility') === 'visible',
);

export default getIsExclusionRuleDeleteConfirmationVisible;
