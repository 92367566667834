import { createSelector } from 'reselect';
import assign from 'lodash/assign';
import get from 'lodash/get';
import { RootState } from '../../stores/types';
import { UtilityState } from './types';

export const getUtilitiesState:
(state: RootState) => UtilityState = (state: RootState) => state.utilities;

export const getCenters = createSelector(
  getUtilitiesState,
  (utilities) => utilities.centers,
);

export const getOrderEstimate = createSelector(
  getUtilitiesState,
  (utilities) => get(utilities, 'orderSummary.orderEstimate'),
);

export const getOrderEstimateErrors = createSelector(
  getUtilitiesState,
  (utilities) => get(utilities, 'errors.order_estimate'),
);

export const getOrderSummarySettings = createSelector(
  getUtilitiesState,
  (utilities) => get(utilities, 'orderSummary.settings'),
);

export const getOrderSummaryItems = createSelector(
  getUtilitiesState,
  (utilities) => {
    const settings = get(utilities, 'orderSummary.settings');
    const { center } = settings;
    return settings.items.map(
      (item: io.flow.v0.models.LocalizedLineItem) => assign({}, item, { center }),
    );
  },
);

export const getShopifySyncStatus = createSelector(
  getUtilitiesState,
  (utilities) => get(utilities, 'shopifySyncStatus.data'),
);

export const getShopifySyncStatusError = createSelector(
  getUtilitiesState,
  (utilities) => get(utilities, 'shopifySyncStatus.error'),
);

export const getShopifySyncStatusReadyState = createSelector(
  getUtilitiesState,
  (utilities) => get(utilities, 'shopifySyncStatus.readyState'),
);
