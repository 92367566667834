import { css } from '@emotion/css';

export const orderCardContainer = css`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const orderFooterActions = css`
  padding: 12px 0;
`;

export const selectedQuantity = css`
  padding-left: 5px;
  margin-right: 10px;
`;

export const orderCardActions = css`
  padding-top: 13px;
`;

export const viewOrderDetails = css`
  font-size: 14px;
  padding-left: 2px;
`;

export const orderCardTitle = css`
  margin-bottom: 0 !important;
`;

export const loadingIndicator = css`
  width: 32px;
  margin-right: 8px;
  height: auto;
`;

export const printLabelContainer = css`
  font-size: 20px;
  display: flex;
  justify-content: flex-end;
`;

export const orderCardContent = css`
  height: 100%;
  overflow-y: auto;
`;

export const invoiceBanner = css`
  margin: 0 16px;
`;
