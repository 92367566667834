import Client from './client';

export default class V2Checkouts extends Client {
  constructor(opts) {
    let options = opts;

    if (typeof opts === 'string') {
      options = { host: opts }; // convert host string to options object
    }

    options.serviceName = 'API Internal';

    super(options);
  }

  postSessionBySessionId(sessionId, options = {}) {
    return this.makeRequest(`${this.host}/v2/checkouts/session/${sessionId}`, {
      ...options,
       method: 'POST',
    });
  }

  postSessionAndOrderBySessionIdAndOrderNumber(sessionId, orderNumber, options = {}) {
    return this.makeRequest(`${this.host}/v2/checkouts/session/${sessionId}/order/${orderNumber}`, {
      ...options,
       method: 'POST',
    });
  }

  getById(id, options = {}) {
    return this.makeRequest(`${this.host}/v2/checkouts/${id}`, options);
  }

  getOrderAndViewsById(id, options = {}) {
    return this.makeRequest(`${this.host}/v2/checkouts/${id}/order/views`, options);
  }

  getResponseById(id, options = {}) {
    return this.makeRequest(`${this.host}/v2/checkouts/${id}/response`, options);
  }

}
