import ActionTypes from '../constants/action-types';
import { ExperimentsActions, ExperimentFormState } from '../types';

const defaultState: ExperimentFormState = {
  experiment: {
    name: '',
    variants: [],
    discriminator: 'experience',
    emails: [],
  },
  variants: [],
  discriminator: {
    key: 'experience',
    values: [],
  },
};

export default function (
  state = defaultState,
  action: ExperimentsActions,
): ExperimentFormState {
  switch (action.type) {
    case ActionTypes.CHANGE_EDIT_EXPERIMENT: {
      const { scope, ...experiment } = action.payload;
      return {
        ...state,
        experiment: {
          discriminator: 'experience',
          ...experiment,
        },
        scope,
      };
    }
    case ActionTypes.FETCH_EXPERIMENT_DISCRIMINATOR_VALUES_SUCCESS:
      return {
        ...state,
        discriminator: action.payload,
        variants: [],
      };
    case ActionTypes.FETCH_EXPERIMENT_VARIANTS_SUCCESS:
    case ActionTypes.FETCH_EXPERIMENT_FORM_DEFAULTS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case ActionTypes.CHANGE_NEW_EXPERIMENT:
    case ActionTypes.CLOSE_EXPERIMENTS_MODAL:
      return defaultState;
    default:
      return state;
  }
}
