import { compose } from 'redux';
import { reduxForm } from 'redux-form';

import {
  hideEditRuleWorksheet,
  submitFeatureRuleForm,
} from '../actions';
import createToast from '../../console/actions/createToast';
import EditRuleWorksheetForm from '../components/EditRuleWorksheetForm';
import FormName from '../constants/FormName';
import { EditRuleWorksheetValues, EditRuleWorksheetFormOwnProps } from '../types/components';
import { ThunkDispatcher } from '../../../stores/types';

// There is an issue with field arrays where you cannot actually assign an array of errors
// to fix this instead of doing FormErrors<EditRuleWorksheetValues> you just do any
function handleValidation(values: EditRuleWorksheetValues): any {
  const errors: any = {};
  const {
    organizationId,
    conditions,
    value,
  } = values;

  if (!organizationId) {
    errors.organizationId = 'An organization identifier is required';
  }

  if (!conditions || !conditions.length) {
    errors.conditions = {
      _error: 'At least one condition must be added',
    };
  } else {
    const conditionArrayErrors: any[] = [];

    conditions.forEach((condition, index) => {
      const conditionErrors: any = {};

      if (!condition.field) {
        conditionErrors.field = 'A field is required for each condition';
      }

      if (!condition.operator) {
        conditionErrors.operator = 'An operator must be selected for each condition';
      }

      if (!Array.isArray(condition.values)) {
        conditionErrors.values = 'ERROR: The value of each condition must be an Array.';
      } else if (!condition.values.length) {
        conditionErrors.values = 'A value must be supplied for each condition';
      }

      conditionArrayErrors[index] = conditionErrors;
    });

    if (conditionArrayErrors.length) {
      errors.conditions = conditionArrayErrors;
    }
  }

  if (!value) {
    errors.value = 'A value must be supplied for the rule';
  }

  return errors;
}

function handleSumbit(values: EditRuleWorksheetValues, dispatch: ThunkDispatcher) {
  dispatch(submitFeatureRuleForm(values));
}

function handleSumbitSuccess(_: any, dispatch: ThunkDispatcher) {
  dispatch(hideEditRuleWorksheet());
  dispatch(createToast({
    content: 'A rule has been updated.',
    icon: 'InfoCircle',
    intent: 'info',
  }));
}

export default compose<React.FC<EditRuleWorksheetFormOwnProps>>(
  reduxForm({
    form: FormName.EDIT_RULE_FORM,
    onSubmit: handleSumbit,
    validate: handleValidation,
    onSubmitSuccess: handleSumbitSuccess,
  }),
)(EditRuleWorksheetForm);
