import React from 'react';
import { Plus } from '@flowio/react-icons';
import {
  Card, CardHeader, CardContent, CardFooter, CardEmptyState, CardTitle,
} from '@flowio/react-card';
import { Box } from '@flowio/react-box';
import { Button, OutlineButton } from '@flowio/react-button';
import size from 'lodash/size';
import { css } from '@emotion/css';
import { ExclusionRulesIndexProps } from '../../types';

import ExclusionRuleList from '../ExclusionRuleList';
import ExclusionRuleWorksheet from '../../containers/ExclusionRuleWorksheet';
import ExclusionRuleDeleteConfirmation from '../../containers/ExclusionRuleDeleteConfirmation';
import Pagination from '../../../../components/pagination';
import ExclusionsExport from '../ExclusionsExport';

interface ExclusionRulesIndexState {
  showExportFormDialog: boolean;
}

const pageContentStyle = css`
  margin-top: 1rem;
`;

class ExclusionRulesIndex extends
  React.PureComponent<ExclusionRulesIndexProps, ExclusionRulesIndexState> {
  static displayName = 'ExclusionRulesIndex';

  constructor(props: ExclusionRulesIndexProps, context: any) {
    super(props, context);
    this.state = this.getInitialState();
    this.handleAddExclusionRule = this.handleAddExclusionRule.bind(this);
    this.handleEditExclusionRule = this.handleEditExclusionRule.bind(this);
    this.handleRemoveExclusionRule = this.handleRemoveExclusionRule.bind(this);
    this.handleExportExclusions = this.handleExportExclusions.bind(this);
    this.handleShowExportDialog = this.handleShowExportDialog.bind(this);
    this.handleHideExportDialog = this.handleHideExportDialog.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  getInitialState() {
    return {
      showExportFormDialog: false,
    };
  }

  handleRequestNextPage = () => {
    const {
      entriesPerPage,
      onPageChange,
      organizationId,
      pageNumber,
    } = this.props;

    const nextPage = pageNumber + 1;

    onPageChange(organizationId, nextPage, entriesPerPage);
  };

  handleRequestPreviousPage = () => {
    const {
      entriesPerPage,
      onPageChange,
      organizationId,
      pageNumber,
    } = this.props;

    const nextPage = pageNumber - 1;

    onPageChange(organizationId, nextPage, entriesPerPage);
  };

  handleAddExclusionRule() {
    const { organizationId, onAddExclusionRule } = this.props;
    onAddExclusionRule(organizationId);
  }

  handleEditExclusionRule(exclusionRule: io.flow.v0.models.ExclusionRule) {
    const { organizationId, onEditExclusionRule } = this.props;
    onEditExclusionRule(organizationId, exclusionRule);
  }

  handleRemoveExclusionRule(exclusionRule: io.flow.v0.models.ExclusionRule) {
    const { organizationId, onRemoveExclusionRule } = this.props;
    onRemoveExclusionRule(organizationId, exclusionRule);
  }

  handleExportExclusions() {
    const { onExportExclusions } = this.props;
    onExportExclusions();
  }

  handleShowExportDialog() {
    const { onShowExportDialog } = this.props;
    onShowExportDialog();
  }

  handleHideExportDialog() {
    const { onHideExportDialog } = this.props;
    onHideExportDialog();
  }

  render() {
    const {
      exclusionRules,
      isFirstPage,
      isLastPage,
      organizationId,
      regions,
      pageNumber,
      isExclusionsExportDialogVisible,
    } = this.props;

    const isSinglePage = isFirstPage && isLastPage;
    const hasExclusionRules = size(exclusionRules) > 0;

    return (
      <div className={pageContentStyle}>
        <Card>
          <CardHeader dividing>
            <Box alignItems="center" justifyContent="between">
              <CardTitle content="All Exclusions" />
              <OutlineButton
                content="New Exclusion"
                leftIcon={Plus}
                intent="primary"
                onClick={this.handleAddExclusionRule}
              />
            </Box>
          </CardHeader>
          <CardContent fitted>
            {hasExclusionRules ? (
              <ExclusionRuleList
                exclusionRules={exclusionRules}
                onEditExclusionRule={this.handleEditExclusionRule}
                onRemoveExclusionRule={this.handleRemoveExclusionRule}
                organizationId={organizationId}
                regions={regions}
              />
            ) : (
              <CardEmptyState
                content="No exclusion rules found."
              />
            )}
          </CardContent>
          {hasExclusionRules && (
            <CardFooter dividing>
              <Box alignItems="center" justifyContent="between">
                <Button
                  content="Export Exclusion Rules"
                  intent="primary"
                  onClick={this.handleShowExportDialog}
                />
                {!isSinglePage && (
                  <Pagination
                    pageNumber={pageNumber}
                    isNextPageDisabled={isLastPage}
                    isPreviousPageDisabled={isFirstPage}
                    onRequestNextPage={this.handleRequestNextPage}
                    onRequestPreviousPage={this.handleRequestPreviousPage}
                  />
                ) }
              </Box>
            </CardFooter>
          )}
        </Card>
        <ExclusionRuleWorksheet />
        <ExclusionRuleDeleteConfirmation />
        <ExclusionsExport
          isOpen={isExclusionsExportDialogVisible}
          onCloseExportDialog={this.handleHideExportDialog}
          onSubmitExport={this.handleExportExclusions}
        />
      </div>
    );
  }
}

export default ExclusionRulesIndex;
