import { Ellipsis } from '@flowio/react-icons';
import { css } from '@emotion/react';
import { css as cssClass } from '@emotion/css';
import React, { useCallback } from 'react';
import { Interpolation } from '@emotion/core';
import {
  Dropdown, DropdownMenu, DropdownItem, DropdownDivider,
} from '@flowio/react-dropdown';
import {
  TableRow,
  TableRowColumn,
} from '@flowio/react-table';

import withNumberFormat from '../../../../utilities/numbers/withNumberFormat';
import COLORS from '../../../../theme/tokens/colors';

interface DeleteArgs {
  country: io.flow.v0.models.Country;
  language: io.flow.v0.models.Language;
  id: string;
  platform: io.flow.internal.v0.enums.MarketingGatewayPlatform,
}

interface Props {
  feed: io.flow.internal.v0.models.MarketingGatewayFeed;
  country: io.flow.v0.models.Country;
  parentCountry: io.flow.v0.models.Country;
  currency: io.flow.v0.models.Currency;
  language: io.flow.v0.models.Language;
  onDelete: (args: DeleteArgs) => void;
}

const getStatus = (status: string): string => {
  switch (status) {
    case 'initialized':
      return 'Generating';
    case 'generated':
    case 'in_review':
      return 'In Review';
    case 'rejected':
      return 'Disapproved';
    case 'active':
      return 'Approved';
    default:
      return 'Unknown';
  }
};

const canBeDownloaded = (status: string): boolean => status !== 'initialized';

const ellipsisStyles = css`
  transform: rotate(90deg);
  cursor: pointer;
  fill: ${COLORS.marketing['light-slate-gray']};

  &:hover {
    fill: ${COLORS['flow-blue'][400]};
  }
  border-color: transparent;
`;

const menuItemStyle = cssClass`
  font-size: 14px;
`;

const GoogleLocalizedFeed: React.FC<Props> = ({
  feed,
  country,
  currency,
  language,
  parentCountry,
  onDelete,
}) => {
  const { id } = feed;
  const numProducts = feed.item_count ? withNumberFormat(feed.item_count) : '-';
  const tsvDownloadUrl = feed.tsv_download_url;
  const status = getStatus(feed.feed_state);
  const feedName = `${country.iso_3166_3} ${language.iso_639_2} ${currency.iso_4217_3}`.toUpperCase();

  const handleDownload = useCallback(() => {
    window.open(tsvDownloadUrl, '_blank');
  }, [tsvDownloadUrl]);

  const handleDelete = (): void => {
    onDelete({
      country, language, id: feed.id, platform: 'google',
    });
  };

  const singleLineStyle: Interpolation = {
    whiteSpace: 'nowrap',
  };

  return (
    <TableRow key={id}>
      <TableRowColumn css={{ ...singleLineStyle, fontWeight: 500 }}>
        {`Flow feed for ${feedName}`}
      </TableRowColumn>
      <TableRowColumn css={singleLineStyle}>{country.name}</TableRowColumn>
      <TableRowColumn css={singleLineStyle}>{language.name}</TableRowColumn>
      <TableRowColumn css={singleLineStyle}>{currency.name}</TableRowColumn>
      <TableRowColumn css={singleLineStyle}>{parentCountry.name}</TableRowColumn>
      <TableRowColumn textAlign="center">{numProducts === 'NaN' ? 'N/A' : numProducts}</TableRowColumn>
      <TableRowColumn css={singleLineStyle}>{status}</TableRowColumn>
      <TableRowColumn>
        <Dropdown
          position="bottom right"
          trigger={<Ellipsis width={24} css={ellipsisStyles} />}
        >
          <DropdownMenu>
            {
            canBeDownloaded(feed.feed_state)
            && (
            <DropdownItem
              className={menuItemStyle}
              onClick={handleDownload}
            >
              Download
            </DropdownItem>
            )
          }
            <DropdownDivider />
            <DropdownItem className={menuItemStyle} onClick={handleDelete}>
              Delete
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </TableRowColumn>
    </TableRow>
  );
};

GoogleLocalizedFeed.displayName = 'GoogleLocalizedFeed';

export default GoogleLocalizedFeed;
