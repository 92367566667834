import React from 'react';
import { Stack } from '@flowio/react-stack';
import map from 'lodash/map';
import QueryFilterToken from '../QueryFilterToken';

type Props = {
  className?: string;
  queryFilters: io.flow.v0.unions.QueryFilter[];
};

const QueryFilterTokenList: React.FC<Props> = ({
  className,
  queryFilters,
}) => (
  <Stack className={className} orientation="vertical" spacing="tight">
    {map(queryFilters, (queryFilter, index) => (
      <QueryFilterToken key={index} queryFilter={queryFilter} />
    ))}
  </Stack>
);

QueryFilterTokenList.displayName = 'QueryFilterTokenList';

export default QueryFilterTokenList;
