import find from 'lodash/find';
import get from 'lodash/get';
import round from 'lodash/round';
import {
  RefundCalculationOptions,
  RefundTypeValues,
  RefundAmountValues,
  RefundShippingValues,
  OrderRefundAmount,
} from '../types';
import encodeItemNumber from './encodeItemNumber';

const taxAllocationStrings = ['vat_deminimis', 'vat_freight', 'vat_insurance', 'vat_duties_item_price', 'vat_duties_freight', 'vat_duties_insurance', 'vat_subsidy'];
const taxAllocationItemStrings = ['vat_item_price'];
const dutyAllocationStrings = ['duty_deminimis', 'duties_freight', 'duties_insurance', 'duty_subsidy'];
const dutyAllocationItemStrings = ['duties_item_price'];
const shippingAllocationStrings = ['remote_area_surcharge', 'fuel_surcharge', 'emergency_situation_surcharge'];

export default function calculateRefundAmount(
  order: io.flow.v0.models.Order,
  options: RefundCalculationOptions,
  allocations: Partial<io.flow.v0.models.AllocationV2>,
): OrderRefundAmount {
  const roundingPrecision = order.total.currency === 'KWD' ? 3 : 2;
  const allocationDetails = allocations.details;
  let refundAmount = 0;
  let refundTaxAmount = 0;
  let refundDutyAmount = 0;
  let refundShippingAmount = 0;
  let refundItemSubtotalAmount = 0;
  let refundSurchargeAmount = 0;
  let refundDiscountAmount = 0;
  const subTotal = order.prices.find((price) => price.key === 'subtotal')?.amount || 0;
  const discount = order.prices.find((price) => price.key === 'discount')?.amount || 0;
  const surcharge = order.prices.find((price) => price.key === 'surcharges')?.amount || 0;

  if (options.refundType === RefundTypeValues.FULL) {
    refundDiscountAmount += discount;
    refundAmount += subTotal + discount;
    refundItemSubtotalAmount += subTotal;

    if (options.refundShipping) {
      const shipping = order.prices.find((price) => price.key === 'shipping')?.amount || 0;
      refundSurchargeAmount += surcharge;
      refundShippingAmount += shipping;
      refundAmount += shipping + surcharge;
    }

    if (options.refundTax) {
      const tax = order.prices.find((price) => price.key === 'vat')?.amount || 0;
      refundTaxAmount += tax;
      refundAmount += tax;
    }

    if (options.refundDuty) {
      const duty = order.prices.find((price) => price.key === 'duty')?.amount || 0;
      refundDutyAmount += duty;
      refundAmount += duty;
    }
  } else if (options.refundType === RefundTypeValues.PARTIAL) {
    const notIncludedAllocations = allocationDetails ? allocationDetails.reduce(
      (
        a: io.flow.v0.unions.AllocationComponent[],
        currentAllocation,
      ) => a.concat(currentAllocation.not_included),
      [],
    ) : [];
    let totalQuantity = 0;
    let itemValueTotal = 0;
    let refundItemQuantity = 0;

    order.items.forEach((item) => {
      totalQuantity += item.quantity;
    });

    options.items.forEach((item) => {
      const { itemNumber, quantity } = item;
      const currentFullItem = order.items.find((i) => encodeItemNumber(i.number) === itemNumber);
      const maxItemQuantity = currentFullItem?.quantity || 0;
      const itemSubTotal = currentFullItem?.local.prices.find((price) => price.key === 'localized_item_price')?.amount || 0;

      const allocationItemDetail = find(
        allocationDetails,
        (detail: io.flow.v0.models.AllocationLineDetail) => (
          encodeItemNumber(detail.number) === itemNumber
        ),
      );
      const allocationPrices = get(allocationItemDetail, 'not_included', []);

      refundAmount += itemSubTotal * quantity;
      itemValueTotal += itemSubTotal * quantity;
      refundItemSubtotalAmount += itemSubTotal * quantity;
      refundItemQuantity += quantity;

      if (options.refundTax && options.refundTaxType === RefundAmountValues.ITEM) {
        const tax = get(
          find(allocationPrices, (price) => taxAllocationItemStrings.includes(price.key)),
          'total.amount',
          0,
        );
        refundTaxAmount += tax * (quantity / maxItemQuantity);
        refundAmount += tax * (quantity / maxItemQuantity);
      }

      if (options.refundDuty && options.refundDutyType === RefundAmountValues.ITEM) {
        const duty = get(
          find(allocationPrices, (price) => dutyAllocationItemStrings.includes(price.key)),
          'total.amount',
          0,
        );
        refundDutyAmount += duty * (quantity / maxItemQuantity);
        refundAmount += duty * (quantity / maxItemQuantity);
      }
    });

    if (options.refundTax && options.refundTaxType === RefundAmountValues.ORDER) {
      const tax = order.prices.find((price) => price.key === 'vat')?.amount || 0;
      refundTaxAmount += tax;
      refundAmount += tax;
    }

    if (options.refundDuty && options.refundDutyType === RefundAmountValues.ORDER) {
      const duty = order.prices.find((price) => price.key === 'duty')?.amount || 0;
      refundDutyAmount += duty;
      refundAmount += duty;
    }

    if (options.refundDuty && options.refundDutyType === RefundAmountValues.ITEM) {
      const quantityRatio = refundItemQuantity / totalQuantity;
      const dutyAllocations = notIncludedAllocations
        .filter((allocation) => dutyAllocationStrings.includes(allocation.key));
      const totalDutyAllocationAmount = dutyAllocations.reduce(
        (amount: number, allocation) => amount + allocation.total.amount,
        0,
      );

      const partialRefundDutyAllocationAmount = totalDutyAllocationAmount * quantityRatio;
      refundDutyAmount += partialRefundDutyAllocationAmount;
      refundAmount += partialRefundDutyAllocationAmount;
    }

    if (options.refundTax && options.refundTaxType === RefundAmountValues.ITEM) {
      const quantityRatio = refundItemQuantity / totalQuantity;
      const taxAllocations = notIncludedAllocations
        .filter((allocation) => taxAllocationStrings.includes(allocation.key));
      const totalTaxAllocationAmount = taxAllocations.reduce(
        (amount: number, allocation) => amount + allocation.total.amount,
        0,
      );

      const partialRefundTaxAllocationAmount = totalTaxAllocationAmount * quantityRatio;
      refundTaxAmount += partialRefundTaxAllocationAmount;
      refundAmount += partialRefundTaxAllocationAmount;
    }

    if (discount !== 0) {
      const valueRatio = itemValueTotal / subTotal;
      const discountAmount = valueRatio * discount;
      refundDiscountAmount += discountAmount;
      refundAmount += discountAmount;
    }

    if (options.refundShipping) {
      const shipping = order.prices.find((price) => price.key === 'shipping')?.amount || 0;
      const shippingAllocations = notIncludedAllocations
        .filter((allocation) => shippingAllocationStrings.includes(allocation.key));
      const totalShippingAllocationAmount = shippingAllocations.reduce(
        (amount: number, allocation) => amount + allocation.total.amount,
        0,
      );

      if (options.refundShippingType === RefundShippingValues.ORDER) {
        refundShippingAmount += shipping;
        refundSurchargeAmount += surcharge;
        refundAmount += shipping + surcharge;
      }

      if (options.refundShippingType === RefundShippingValues.VALUE) {
        const valueRatio = itemValueTotal / subTotal;
        const shippingCost = valueRatio * shipping;
        const surchargeCost = valueRatio * surcharge;
        const allocationCost = valueRatio * totalShippingAllocationAmount;

        refundShippingAmount += shippingCost + allocationCost;
        refundSurchargeAmount += surchargeCost;
        refundAmount += shippingCost + surchargeCost + allocationCost;
      }

      if (options.refundShippingType === RefundShippingValues.QUANTITY) {
        const quantityRatio = refundItemQuantity / totalQuantity;
        const shippingCost = shipping * quantityRatio;
        const surchargeCost = surcharge * quantityRatio;
        const allocationCost = quantityRatio * totalShippingAllocationAmount;

        refundShippingAmount += shippingCost + allocationCost;
        refundSurchargeAmount += surchargeCost;
        refundAmount += shippingCost + surchargeCost + allocationCost;
      }
    }
  }
  return {
    refundAmount: round(refundAmount, roundingPrecision),
    refundTaxAmount: refundTaxAmount !== 0 ? round(refundTaxAmount, roundingPrecision) : undefined,
    refundDutyAmount: refundDutyAmount !== 0 ? round(
      refundDutyAmount,
      roundingPrecision,
    ) : undefined,
    refundShippingAmount: refundShippingAmount !== 0 ? round(
      refundShippingAmount,
      roundingPrecision,
    ) : undefined,
    refundSurchargeAmount: refundSurchargeAmount !== 0
      ? round(refundSurchargeAmount, roundingPrecision) : undefined,
    refundDiscountAmount: refundDiscountAmount !== 0 ? round(
      refundDiscountAmount,
      roundingPrecision,
    ) : undefined,
    refundItemSubtotalAmount: round(refundItemSubtotalAmount, roundingPrecision),
  };
}
