import { type UsersGetByIdResponse } from '@flowio/api-sdk';
import { type ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';

export type FetchAddressValidationUserAction =
  | FetchAddressValidationUserActionSuccess
  | FetchAddressValidationUserActionFailure
  | FetchAddressValidationUserActionRequest;

type FetchAddressValidationUserActionSuccess = {
  type: 'FETCH_ADDRESS_VALIDATION_USER_SUCCESS',
  payload: io.flow.v0.models.User,
};

type FetchAddressValidationUserActionFailure = {
  type: 'FETCH_ADDRESS_VALIDATION_USER_FAILURE',
  payload: FormattedErrorMessages,
};

type FetchAddressValidationUserActionRequest = {
  type: 'FETCH_ADDRESS_VALIDATION_USER_REQUEST',
};

export default function fetchAddressValidationUser(
  userId: string,
): ThunkResult<Promise<UsersGetByIdResponse>> {
  return (
    dispatch,
    getState,
    extra,
  ): Promise<UsersGetByIdResponse> => {
    dispatch({
      type: 'FETCH_ADDRESS_VALIDATION_USER_REQUEST',
    });

    return extra.api(getState()).users.getById({
      id: userId,
    }).then((response) => {
      if (response.ok) {
        dispatch({
          type: 'FETCH_ADDRESS_VALIDATION_USER_SUCCESS',
          payload: response.body,
        });
      }

      const errors = formatErrorResponseV2(response);
      dispatch({
        type: 'FETCH_ADDRESS_VALIDATION_USER_FAILURE',
        payload: errors,
      });
      return response;
    });
  };
}
