import requiredPermissions from '../constants/required-permissions';
import StatusTypes from '../constants/status-types';
import { isMissingPermission, verifySDKSetup } from './utils';

type LoginResponse = {
  success: boolean;
  accessToken?: string;
};

const login = (requiredScopes = requiredPermissions): Promise<LoginResponse> => {
  try {
    verifySDKSetup();
  } catch (error) {
    return Promise.reject(error);
  }

  const options = {
    scope: requiredScopes.join(','),
    return_scopes: true,
  };

  return new Promise((resolve) => {
    window.FB.login((response: facebook.StatusResponse) => {
      const { authResponse, status } = response;
      if (status !== StatusTypes.connected) {
        resolve({ success: false });
      } else {
        const grantedScopes = (
          authResponse.grantedScopes ? authResponse.grantedScopes.split(',') : []
        );
        resolve({
          success: !isMissingPermission(grantedScopes, requiredScopes),
          accessToken: authResponse.accessToken,
        });
      }
    }, options);
  });
};

export default login;
