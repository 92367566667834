import { RootState, ThunkDispatcher, ThunkResult } from '../../../stores/types';
import ActionTypes from '../constants/action-types';
import {
  getOrganization,
} from '../selectors';
import closeImportEventSource from './close-imports-stream';

export const eventSourceMessageHandler = (dispatch: ThunkDispatcher) => (e: any) => {
  const streamedImports = JSON.parse(e.data);
  const allCompleted = streamedImports.every((file: any) => (file.status !== 'processing') && (file.status !== 'created'));
  // if all completed or failed close connection
  if (allCompleted) {
    dispatch({ type: ActionTypes.UPDATE_IMPORT_FILES_STATUS, payload: streamedImports });
    dispatch(closeImportEventSource());
  } else {
    dispatch({ type: ActionTypes.UPDATE_IMPORT_FILES_STATUS, payload: streamedImports });
  }
};

export const createImportEventSource = (): ThunkResult<void> => (
  dispatch: ThunkDispatcher,
  getState: () => RootState,
) => {
  const state = getState();
  const organization = getOrganization(state);
  const eventSource = new EventSource(`/${organization.id}/imports`);
  dispatch({ type: ActionTypes.IMPORT_EVENTSOURCE_CREATE, payload: eventSource });
  eventSource.onmessage = eventSourceMessageHandler(dispatch);
};
