import { compose } from 'redux';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import isEmpty from 'lodash/isEmpty';

import { getOrganizationId } from '../../organization';
import fetchGlobalResults from '../actions/fetchGlobalResults';
import getRecentSearches from '../actions/getRecentSearches';
import setRecentSearches from '../actions/setRecentSearches';
import clearRecentSearches from '../actions/clearRecentSearches';
import updateLoadingState from '../actions/updateLoadingState';

import { getNormalizedResults, getLoadingState } from '../selectors';
import GlobalSearchBox, { GlobalSearchBoxPassedProps } from '../components/global-searchbox';
import { SearchResult, GlobalSearchBoxStateProps, GlobalSearchBoxDispatchProps } from '../components/global-searchbox/global-searchbox';
import { RootState } from '../../../stores/types';
import { DefaultReducerState } from '../../console/types/state';

function mapStateToProps(state: RootState): DefaultReducerState & GlobalSearchBoxStateProps {
  const { defaultReducer } = state;

  return {
    ...defaultReducer,
    organizationId: getOrganizationId(state),
    globalSearchResults: getNormalizedResults(state),
    loading: getLoadingState(state),
  };
}

const mapDispatchToProps = (dispatch: any): GlobalSearchBoxDispatchProps => ({
  onRequestOption(path: string, updatedRecents: SearchResult[]): void {
    dispatch(setRecentSearches(updatedRecents));
    browserHistory.push({
      pathname: path,
    });
  },
  onUpdateLoading(loading: boolean): void {
    dispatch(updateLoadingState(loading));
  },
  onInputChange(
    organization: string,
    searchText: string,
    recent: SearchResult[],
  ): Promise<unknown> {
    if (!isEmpty(searchText)) {
      return Promise.all([
        dispatch(clearRecentSearches()),
        dispatch(fetchGlobalResults(organization, searchText)),
      ]);
    }
    return dispatch(getRecentSearches(recent));
  },
});

export default compose(
  connect<
  GlobalSearchBoxStateProps, GlobalSearchBoxDispatchProps, GlobalSearchBoxPassedProps, RootState
  >(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(GlobalSearchBox);
