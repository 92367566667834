import React, { useCallback } from 'react';
import moment from 'moment';
import startCase from 'lodash/startCase';
import {
  Table,
  TableHead,
  TableRow, TableRowColumn,
  TableHeadColumn,
  TableBody,
} from '@flowio/react-table';
import kebabCase from 'lodash/kebabCase';
import { Button } from '@flowio/react-button';
import classNames from 'classnames';
import { Tooltip } from '@flowio/react-tooltip';
import { PackageArrows } from '@flowio/react-icons';
import * as styles from './order-tracking.styles';
import useThunkDispatcher from '../../../../hooks/useThunkDispatcher';
import { createToast } from '../../../console/actions';

const displayName = 'OrderTracking';

interface OrderTrackingProps {
  trackings: io.flow.internal.v0.models.SearchTrackingSummary[];
  orderNumber: string;
  onShowTrackingDetail: (
    orderNumber: string,
    label: io.flow.internal.v0.models.LabelSummary | undefined,
    notification: io.flow.v0.models.ShippingNotification | undefined,
  ) => void;
  shippingNotifications: io.flow.v0.models.ShippingNotification[] | undefined;
}

const OrderTracking: React.FC<OrderTrackingProps> = ({
  trackings = [],
  orderNumber,
  onShowTrackingDetail,
  shippingNotifications,
}) => {
  const dispatch = useThunkDispatcher();
  const copyToClipboard = useCallback((text: string) => (): void => {
    navigator.clipboard.writeText(text);
    dispatch(createToast({
      content: 'Copied to Clipboard.',
      autoCloseDuration: 3000,
    }));
  }, [dispatch]);

  return (
    <>
      <Table displayDensity="compact" striped>
        <TableHead>
          <TableRow>
            <TableHeadColumn style={{ padding: 0 }} />
            <TableHeadColumn className={classNames(styles.headerColumn, 'first')}>Tracking #</TableHeadColumn>
            <TableHeadColumn className={styles.headerColumn}>Carrier Name</TableHeadColumn>
            <TableHeadColumn className={styles.headerColumn}>Status</TableHeadColumn>
            <TableHeadColumn className={styles.headerColumn}>Description</TableHeadColumn>
            <TableHeadColumn className={styles.headerColumn}>Last Updated</TableHeadColumn>
            <TableHeadColumn className={styles.headerColumn} />
          </TableRow>
        </TableHead>
        <TableBody>
          {trackings.map(({
            timestamp, number, description, status, label,
          }) => (
            <TableRow key={kebabCase(`${timestamp} ${number} ${description} ${status}`)}>
              <TableRowColumn style={{ padding: 0 }} />
              <TableRowColumn>
                { label.carrier_tracking_number ? (
                  <a href={label.carrier_tracking_number_url} target="_blank" rel="noreferrer">
                    {label.carrier_tracking_number}
                  </a>
                ) : 'No tracking numbers available' }
              </TableRowColumn>
              <TableRowColumn>
                {label.service?.carrier.name}
              </TableRowColumn>
              <TableRowColumn className={classNames(styles.status, status)}>
                {startCase(status)}
              </TableRowColumn>
              <TableRowColumn>
                {description}
              </TableRowColumn>
              <TableRowColumn>
                {moment(timestamp).format('llll')}
              </TableRowColumn>
              <TableRowColumn>
                <Button
                  content="View Details"
                  size="small"
                  onClick={(): void => onShowTrackingDetail(orderNumber, label, undefined)}
                />
              </TableRowColumn>
            </TableRow>
          ))}
          {shippingNotifications && shippingNotifications.map((notif) => (
            <TableRow key={notif.id}>
              <TableRowColumn style={{ padding: 0 }}>
                <Tooltip
                  openOnHover
                  trigger={<PackageArrows width={25} fill="#0051b2" className={styles.byolIcon} />}
                  content="BYOL Shipment"
                />
              </TableRowColumn>
              <TableRowColumn>
                <Tooltip
                  openOnHover
                  trigger={(
                    <span
                      tabIndex={-1}
                      role="button"
                      onKeyDown={copyToClipboard(notif.carrier_tracking_number)}
                      onClick={copyToClipboard(notif.carrier_tracking_number)}
                      className={styles.pointerCursor}
                    >
                      {notif.carrier_tracking_number}
                    </span>
)}
                  content="Click to copy to clipboard"
                />

              </TableRowColumn>
              <TableRowColumn>
                {notif.service.name}
              </TableRowColumn>
              <TableRowColumn>
                UNKNOWN
              </TableRowColumn>
              <TableRowColumn>
                NON-FLOW SHIPMENT
              </TableRowColumn>
              <TableRowColumn>
                {moment(notif.updated_at).format('llll')}
              </TableRowColumn>
              <TableRowColumn>
                <Button
                  content="View Details"
                  size="small"
                  onClick={(): void => onShowTrackingDetail(orderNumber, undefined, notif)}
                />
              </TableRowColumn>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

OrderTracking.displayName = displayName;

export default OrderTracking;
