import {
  ThunkResult, ThunkDispatcher,
} from '../../../stores/types';
import { ActionTypes } from '../constants';
import { server } from '../../../utilities/clients';
import isResponseOk from '../../../utilities/isResponseOk';
import { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import { AuthRedirectResponse } from '../types';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import formatErrorResponse from '../../../utilities/format-error-response';

export default function authorizeRedirect(
  params?: Record<string, string[] | string | null | undefined>,
): ThunkResult<Promise<LegacyResponse<AuthRedirectResponse | FormattedErrorMessages>>> {
  return (
    dispatch: ThunkDispatcher,
  ): Promise<LegacyResponse<AuthRedirectResponse | FormattedErrorMessages>> => {
    dispatch({
      type: ActionTypes.AUTHORIZE_REDIRECT_REQUEST,
    });

    return server.auth.authorizeRedirect({ params }).then((response) => {
      if (isResponseOk(response)) {
        dispatch({
          type: ActionTypes.AUTHORIZE_REDIRECT_COMPLETED,
          payload: response.result,
        });
        return response;
      }

      const formattedErrorsResponse = formatErrorResponse(response);

      dispatch({
        type: ActionTypes.AUTH_LOGIN_USER_ERROR,
        payload: formattedErrorsResponse.result,
      });

      return formattedErrorsResponse;
    });
  };
}
