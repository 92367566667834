import { browserHistory } from 'react-router';

export default function goToItemMarginDetails(
  organization: string,
  experienceKey: string,
  id: string,
) {
  return (): void => {
    browserHistory.push(`/${organization}/experience/${experienceKey}/pricing/margins/${id}`);
  };
}
