import moment from 'moment';
import React, { useState } from 'react';
import noop from 'lodash/noop';

import AnalyticsHeader from '../../AnalyticsHeader';
import Metric from '../../../constants/metric';
import MetricPanel from '../../MetricPanel';

import { MergedProps as Props } from '../types';

const Analytics: React.FC<Props> = ({
  countries,
  currency,
  currentDate,
  endDate,
  gmvByCountry,
  gmvByTimeline,
  interval,
  numberOfOrders,
  onRequestDateRangeChange,
  onRequestIntervalChange,
  onRequestRegionChange,
  orderCountByCountry,
  organizationCountry,
  organizationCurrency,
  region,
  startDate,
}) => {
  const [metric, setMetric] = useState<Metric>(Metric.GMV);

  const handleMetricChange = (_event: React.SyntheticEvent, selectedMetric: Metric): void => {
    setMetric(selectedMetric);
  };

  // We do this so when it is formatted in Date Edit, it will keep the correct time
  // and not push us back or forward a day
  const startDateWithOffset = moment(startDate).utc();
  const endDateWithOffset = moment(endDate).utc();
  return (
    <div>
      <AnalyticsHeader
        currentDate={currentDate}
        metric={metric}
        startDate={startDateWithOffset}
        endDate={endDateWithOffset}
        onRequestMetricChange={handleMetricChange}
        onRequestDateRangeChange={onRequestDateRangeChange}
      />
      <MetricPanel
        organizationCountry={organizationCountry}
        organizationCurrency={organizationCurrency}
        type={metric}
        endDate={endDate}
        countries={countries || []}
        currency={currency}
        metricByCountry={metric === Metric.GMV ? gmvByCountry : orderCountByCountry}
        timeline={gmvByTimeline}
        numberOfOrders={numberOfOrders}
        interval={interval}
        onRequestIntervalChange={onRequestIntervalChange}
        onRequestRegionChange={onRequestRegionChange}
        region={region}
      />
    </div>
  );
};

Analytics.displayName = 'Analytics';

Analytics.defaultProps = {
  onRequestDateRangeChange: noop,
  onRequestIntervalChange: noop,
  onRequestRegionChange: noop,
};

export default Analytics;
