import React from 'react';
import { Box, BoxItem } from '@flowio/react-box';
import Button from '../../MarketingButton';
import { ConnectGoogleProps } from '../types';

const ConnectGoogleFirst: React.FC<ConnectGoogleProps> = ({
  onClick,
}) => (
  <Box className="connectGoogleFirst" direction="column" alignItems="start">
    <BoxItem>
      Import product data from your Google Merchant Center account to start
      generating localized product feeds for more channels.
    </BoxItem>
    <Button onClick={onClick} content="Import Product Data" intent="primary" />
  </Box>
);

ConnectGoogleFirst.displayName = 'ConnectGoogleFirst';

export default ConnectGoogleFirst;
