const ErrorTypes = {
  CATALOG_ACCESS_ERROR: (catalogId?: string | number) => `Unfortunately you don’t have the Facebook permissions required for Flow to access the Facebook Catalog${catalogId ? ` (ID: ${catalogId})` : ''}. The admin of the Facebook Business Manager account will need to assign you permission to the catalog for you to successfully connect.`,
  GENERIC_ERROR: 'Something went wrong. Please try again later.',
  INITIALIZATION_ERROR: 'Failed to load the Facebook SDK',
  PERMISSIONS_ERROR: 'Flow requires you to approve all requested permissions in order to update your Facebook Catalog.',
  SDK_NOT_DETECTED_ERROR: 'Facebook is not detected on the page',
  SERVERSIDE_ERROR: 'Facebook SDK can only be accessed from the browser',
  TOKEN_SAVE_ERROR: 'Error saving access token',
} as const;

export default ErrorTypes;
