import { css } from '@emotion/css';
import colors from '../../../../../theme/tokens/colors';

export const loadingContainer = css`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

export const ellipsis = css`
  transform: rotate(90deg);
  cursor: pointer;
  color: ${colors.marketing['light-slate-gray']};

  &:hover {
    color: ${colors.skyFlowBlue};
  }
`;

export const singleLine = css`
  white-space: nowrap;
`;

// this style orignally was imported from marketing/styles/styles.ts
// due to Emotion10 not supporting shallow render when css prop is used
// we used emotion/css package to create the style and provided through className prop
export const marketingTableStyleClass = css`
  font-size: 12px !important;
  color: ${colors.marketing['dark-slate-blue']};

  tr {
    th, td {
      padding-top: 15px !important;
      padding-bottom: 15px !important;
    }
  }

  thead {
    font-weight: 500;
    background-color: ${colors.concrete};

    th {
      color: ${colors.marketing['dark-slate-blue']};
    }
  }

  tbody {
    tr {
      line-height: 28px !important;
      border-bottom: 1px solid ${colors.concrete};
    }
  }
`;
