import React from 'react';
import { FlowLogo, File } from '@flowio/react-icons';
import { Image } from '@flowio/react-image';
import classNames from 'classnames';
import * as styles from './AddAuthDialog.styles';
import Platforms from '../../../constants/Platforms';

type Props = {
  isManual?: boolean,
  platformId: io.flow.internal.v0.enums.MarketingGatewayPlatform,
};

const AuthModalBackdrop: React.FC<Props> = ({
  platformId = 'google',
  isManual = false,
}) => (
  <div>
    <div className={styles.imageWrapper}>
      <FlowLogo height={30} />
      <span className="imageWrapperConnection" />
      <div>
        <Image
          className={classNames('platformWord', platformId)}
          source={`https://cdn.flow.io/console/logo_${platformId}_word.svg`}
          accessibilityLabel={`connect to ${platformId}`}
        />
        {isManual && (
          <div className={styles.manualIndicator}>
            {Platforms(platformId).platformName}
            <File height={20} />
          </div>
        )}
      </div>
    </div>
  </div>
);

AuthModalBackdrop.displayName = 'AuthModalBackdrop';

export default AuthModalBackdrop;
