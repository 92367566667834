import { css } from '@emotion/css';
import colors from '../../../../theme/tokens/colors';

export const headerColumn = css`
  &.first {
    padding-left: 16px;
  }
`;

export const pointerCursor = css`
  cursor: pointer;
`;

export const status = css`
  &.label_created {
    font-weight: 500;
    color: ${colors.aluminium};
  }

  &.in_transit {
    font-weight: 500;
    color: ${colors['flow-blue'][300]};
  }
`;

export const delivered = css`
  font-weight: 500;
  color: ${colors.chart.green};
`;

export const exception = css`
    font-weight: 500;
    color: var(--chart-red);
`;

export const notificationsHeader = css`
  margin: 1rem 0 0.5rem !important;
  padding-bottom: 0.5rem !important;
  border-bottom: solid 1px #ddd;
  color: #666 !important;
`;

export const byolIcon = css`
  display: block;
  text-align: center;
`;

export const attemptFailed = exception;
