import { bindActionCreators } from 'redux';
import {
  connect, MapStateToProps, MapDispatchToProps,
} from 'react-redux';
import { withFetch } from '@flowio/redux-fetch';
import { getDetailedUser, updateUser } from '../../user/actions';
import Profile from '../components/profile';
import { ThunkDispatcher, RootState } from '../../../stores/types';
import { getFacebook } from '../../facebook/selectors';
import toggleLogoutModal from '../../facebook/actions/toggleLogoutModal';

interface StateProps {
  user: io.flow.v0.models.User;
  updateBasicInformationSuccess?: boolean;
  isLoggedIntoFacebook: boolean;
}

interface DispatchProps {
  onBasicInformationUpdate?: (user: io.flow.v0.models.UserPutForm) => void;
  onDisconnectFacebook: () => void;
}

type OwnProps = {};

function getAsyncState(dispatch: ThunkDispatcher, getState: () => RootState): Promise<void> {
  const state = getState();
  if (state.defaultReducer.user) {
    return dispatch(getDetailedUser(state.defaultReducer.user.id));
  }
  return Promise.resolve();
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = (state: RootState) => {
  const {
    defaultReducer,
    account,
    user,
    ui,
  } = state;
  const uiDefault = {
    errors: [],
  };
  const isLoggedIntoFacebook = getFacebook(state).loginStatus?.status === 'connected';
  return Object.assign(
    uiDefault,
    defaultReducer,
    account,
    ui.user,
    { user, isLoggedIntoFacebook },
  );
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch: ThunkDispatcher,
) => bindActionCreators({
  onBasicInformationUpdate: updateUser,
  onDisconnectFacebook: () => dispatch(toggleLogoutModal(true)),
}, dispatch);

export default withFetch(getAsyncState)(
  connect<StateProps, DispatchProps, OwnProps, RootState>(
    mapStateToProps,
    mapDispatchToProps,
  )(Profile),
);
