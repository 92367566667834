import React, { useEffect } from 'react';
import { Link } from 'react-router';
import AuthLayout from '../../auth-layout';
import { DispatchProps } from '../types';

const Logout: React.FC<DispatchProps> = ({
  onRequestLogout,
}) => {
  useEffect(() => {
    onRequestLogout();
  });

  return (
    <AuthLayout>
      <p>You are now logged out.</p>
      <p>
        Please return to the
        {' '}
        <Link to="/login">login page</Link>
        {' '}
        to access your account.
      </p>
    </AuthLayout>
  );
};

Logout.displayName = 'Logout';

export default Logout;
