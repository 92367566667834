import Client from './client';

export default class Localizations extends Client {
  constructor(opts) {
    let options = opts;

    if (typeof opts === 'string') {
      options = { host: opts }; // convert host string to options object
    }

    options.serviceName = 'API Internal';

    super(options);
  }

  get(organization, contentId, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/contents/${contentId}/localizations`, options);
  }

  putById(organization, contentId, id, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/contents/${contentId}/localizations/${id}`, {
      ...options,
       method: 'PUT',
    });
  }

  putElementsByIdAndKey(organization, contentId, id, key, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/contents/${contentId}/localizations/${id}/elements/${key}`, {
      ...options,
       method: 'PUT',
    });
  }

}
