import { Package, Channels } from '@flowio/react-icons';
import { css } from '@emotion/react';
import React from 'react';
import map from 'lodash/map';

import Button from '../MarketingButton';
import MarketingDialog from '../MarketingDialog';
import withNumberFormat from '../../../../utilities/numbers/withNumberFormat';
import * as styles from './FeedsCreationConfirmationDialog.styles';
import { colors } from '../../../../theme/tokens';

const MAX_COUNTRIES_DISPLAYED = 3;

interface Props {
  onClose: () => void;
  numProducts?: number;
  countries: string[];
  isOpen: boolean;
}

const iconStyle = css`
  fill: ${colors.white};
`;

const FeedsCreationConfirmationDialog: React.FC<Props> = ({
  onClose,
  numProducts,
  countries,
  isOpen,
}) => {
  const topContent = (
    <div className={styles.summaryContainer}>
      {numProducts && (
        <>
          <div className={styles.infoContainer}>
            <div className={styles.productCount}>
              <Package width={25} height={25} css={iconStyle} />
            </div>
            <h3>Localized Products</h3>
            <span>{withNumberFormat(numProducts * countries.length)}</span>
          </div>
          <div className={styles.divider} />
        </>
      )}
      <div className={styles.infoContainer}>
        <div className={styles.countryCount}>
          <Channels width={25} height={25} css={iconStyle} />
        </div>
        <h3>Target Countries</h3>
        <span>
          {countries && map(
            countries.slice(0, MAX_COUNTRIES_DISPLAYED),
            (country) => <span key={country} className={styles.countryName}>{country}</span>,
          )}
          {countries && countries.length > MAX_COUNTRIES_DISPLAYED && <span className={styles.countryName}>{`${countries.length - MAX_COUNTRIES_DISPLAYED} others`}</span>}
        </span>
      </div>
    </div>
  );

  return isOpen ? (
    <MarketingDialog
      isOpen={isOpen}
      topContent={topContent}
      title={`Creating ${numProducts ? `${withNumberFormat(numProducts * countries.length)} ` : ''} localized products for ${withNumberFormat(countries.length)} countries`}
    >
      <Button
        data-automation-id="dismiss-feeds-creation-confirmation"
        onClick={onClose}
        intent="primary"
      >
        OK
      </Button>
    </MarketingDialog>
  ) : null;
};

FeedsCreationConfirmationDialog.displayName = 'FeedsCreationConfirmationDialog';

export default FeedsCreationConfirmationDialog;
