import { css } from '@emotion/css';

export const login = css`
  & .alert {
    margin-bottom: 16px;
  }
`;

export const button = css`
  margin-top: 110px;
`;

export const username = css`
  margin-bottom: 16px;
`;
