import { createQueryParameters } from '@flowio/redux-filtering-paging-sorting';
import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import ActionType from '../constants/ActionType';
import { PaginationMetaData } from '../types';

function createRequestAction(meta: PaginationMetaData): RootActionTypes {
  return {
    type: ActionType.FetchExclusionRulesRequest,
    meta,
  };
}

function createSuccessAction(
  payload: io.flow.v0.models.ExclusionRule[],
  meta: PaginationMetaData,
): RootActionTypes {
  return {
    type: ActionType.FetchExclusionRulesSuccess,
    payload,
    meta,
  };
}

function createFailureAction(
  payload: FormattedErrorMessages,
  meta: PaginationMetaData,
): RootActionTypes {
  return {
    type: ActionType.FetchExclusionRulesFailure,
    payload,
    meta,
  };
}

export default function fetchExclusionRules(
  organizationId: string,
  pageNumber = 1,
  entriesPerPage = 25,
): ThunkResult<Promise<void>> {
  return function fetchExclusionRulesEffects(
    dispatch,
    getState,
    extra,
  ): Promise<void> {
    const metaData = {
      parameters: {
        organizationId,
        pageNumber,
        entriesPerPage,
      },
    };

    const queryParams = createQueryParameters({
      entriesPerPage,
      pageNumber,
    });

    dispatch(createRequestAction(metaData));

    return extra.api(getState()).exclusionRules.get({
      organization: organizationId,
      ...queryParams,
    }).then((response) => {
      if (response.ok) {
        dispatch(createSuccessAction(response.body, metaData));
        return;
      }

      const errors = formatErrorResponseV2(response);
      dispatch(createFailureAction(errors, metaData));
    });
  };
}
