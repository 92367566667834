import React, { ChangeEvent } from 'react';
import { WrappedFieldInputProps } from 'redux-form';

interface FileInputProps {
  onChange: (files: FileList | null) => void;
  input: WrappedFieldInputProps;
}

const FileInput: React.FC<FileInputProps> = ({
  input,
  onChange,
  ...unhandledProps
}) => (
  <input
    {...unhandledProps}
    type="file"
    onChange={(e: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(e.target.files);
      } else if (input) {
        input.onChange(e.target.files);
      }
    }}
  />
);

FileInput.defaultProps = {
  input: undefined,
  onChange: undefined,
};

export default FileInput;
