import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError, formValueSelector } from 'redux-form';
import get from 'lodash/get';
import { IMPORTS_FORM } from '../constants/form-names';
import isResponseOk from '../../../utilities/isResponseOk';
import ImportFileForm from '../components/imports/import-form';
import {
  getUserState,
} from '../../user/selectors';
import { importFile, createImportEventSource } from '../actions';
import { RootState, ThunkDispatcher } from '../../../stores/types';
import { ImportFormValues } from '../components/imports/types';
import { ImportFileParams } from '../types';
import { createToast } from '../../console/actions';

const readFile = (file: File) => new Promise<string>((resolve) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => resolve((reader.result as string).split(',')[1]), false);
  reader.readAsDataURL(file);
});

const submit = (values: ImportFormValues, dispatch: ThunkDispatcher) => {
  readFile(values.import_file[0]).then((file: string) => {
    const payload: ImportFileParams = {
      emails: [values.email],
      importType: values.import_code.value,
      contents: file,
      name: values.import_file[0].name,
    };
    dispatch(importFile(payload)).then((response) => {
      dispatch(createImportEventSource());
      if (!isResponseOk(response)) {
        dispatch(createToast({
          intent: 'negative',
          content: `${values.import_file[0].name} has failed to be imported`,
        }));
        return new SubmissionError({ _error: response.result });
      }
      dispatch(createToast({
        intent: 'positive',
        content: `${values.import_file[0].name} has been successfully imported`,
      }));
      return response;
    });
  });
};

const importFormSelector = formValueSelector(IMPORTS_FORM);

function mapStateToProps(state: RootState) {
  const user = getUserState(state);
  return {
    types: importFormSelector(state, 'types'),
    initialValues: {
      email: get(user, 'email'),
    },
  };
}

export default
// Decorate with connect to read form values
compose<React.FC<{ fileName?: string }>>(
  connect(mapStateToProps),
  reduxForm({
    form: IMPORTS_FORM,
    enableReinitialize: true,
    onSubmit: submit, // submit function must be passed to onSubmit
  }),
)(ImportFileForm);
