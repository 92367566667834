import update from 'immutability-helper';
import { RootActionTypes } from '../../../stores/types';
import { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import ActionTypes from '../constants/action-types';

const defaultState: FormattedErrorMessages = {};

export default function (state = defaultState, action: RootActionTypes) {
  switch (action.type) {
    case ActionTypes.ADD_RULE_BY_KEY_SUCCESS:
    case ActionTypes.CHANGE_STATUS_SUCCESS:
    case ActionTypes.FETCH_FEATURES_SUCCESS:
    case ActionTypes.FETCH_FEATURE_BY_KEY_SUCCESS:
    case ActionTypes.SHOW_ADD_RULE_WORKSHEET_FAILURE:
      return update(state, {
        $set: defaultState,
      });
    case ActionTypes.CHANGE_STATUS_FAILURE:
      return update(state, {
        $set: action.payload,
      });
    default:
      return state;
  }
}
