import { css } from '@emotion/css';

export const buttonSave = css`
  margin-left: 1rem;
`;

export const subcard = css`
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.05);
`;

export const description = css`
  font-size: 12px;
  padding-top: 14px;
`;

export const zeroesSwitch = css`
  float: right;
`;

export const note = css`
  color: #77818f;
`;

export const labelFormat = css`
  border: solid 1px #e4e7eb;
  border-radius: 4px;
  margin-bottom: 1rem;
  padding: 15px;
`;

export const formatGroup = css`
  padding-top: 1rem;
`;

export const cardTitle = css`
  display: inline-block;
`;

export const cardActions = css`
  float: right;
  margin-top: 10px;
`;

export const btnContainer = css`
  margin-top: 14px;
  text-align: right;
`;
