import PropTypes from 'prop-types';

// maintained for backward compatibility during TS conversion
// can be removed once all of Console is migrated to TS.
export const fieldShape = PropTypes.shape({
  events: PropTypes.shape({
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  error: PropTypes.string,
  isDirty: PropTypes.bool.isRequired,
  isEmpty: PropTypes.bool.isRequired,
  isInvalid: PropTypes.bool.isRequired,
  isPristine: PropTypes.bool.isRequired,
  isRequired: PropTypes.bool.isRequired,
  isTouched: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  // eslint-disable-next-line
  value: PropTypes.any.isRequired,
});

export const propTypes = (...fieldNames: string[]): any => ({
  autofill: PropTypes.func.isRequired,
  isDirty: PropTypes.bool.isRequired,
  isInvalid: PropTypes.bool.isRequired,
  isPristine: PropTypes.bool.isRequired,
  isTouched: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  onSubmitValidate: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  fields: PropTypes.shape(fieldNames.reduce((fields, fieldName) => ({
    ...fields,
    [fieldName]: fieldShape.isRequired,
  }), {})),
});

export { default } from './with-validation';
