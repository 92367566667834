import React from 'react';
import { Confirm } from '@flowio/react-confirm';

import FeatureReleaseConfirmForm from '../../containers/FeatureReleaseConfirmForm';
import { FeatureReleaseConfirmProps } from '../../types/components';

const FeatureReleaseConfirm: React.FC<FeatureReleaseConfirmProps> = ({
  initialValues,
  featureName,
  open = false,
  ...unhandledProps
}) => (
  <Confirm
    {...unhandledProps}
    caption="Release Feature"
    intent="primary"
    open={open}
  >
    <FeatureReleaseConfirmForm
      featureName={featureName}
      initialValues={initialValues}
    />
  </Confirm>
);

FeatureReleaseConfirm.displayName = 'FeatureReleaseConfirm';

FeatureReleaseConfirm.defaultProps = {
  initialValues: undefined,
  open: false,
};

export default FeatureReleaseConfirm;
