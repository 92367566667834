import { createSelector } from 'reselect';
import partition from 'lodash/partition';
import getMarketingState from './getMarketingState';
import { RootState } from '../../../stores/types';
import {
  MarketingGatewayChannelObject,
  MarketingGatewayChannelsPartitioned,
  MarketingGatewayChannelsPartitionedByConnection,
  MarketingState,
} from '../types';

const splitByChannel = (
  channels: io.flow.internal.v0.models.MarketingGatewayChannel[],
): MarketingGatewayChannelObject => {
  const channelsObj = {} as MarketingGatewayChannelObject;

  channels.forEach((channel) => {
    channelsObj[channel.platform] = channel;
  });
  return channelsObj;
};

const partitionByConnectionStatus = (
  channels: io.flow.internal.v0.models.MarketingGatewayChannel[],
): MarketingGatewayChannelsPartitionedByConnection => {
  const [connected, unconnected] = partition((channels), (c) => c.status === 'connected' || c.status === 'connecting');

  return {
    connected: splitByChannel(connected),
    unconnected: splitByChannel(unconnected),
  };
};

export const partitionChannels = (
  {
    channels: {
      channels = [],
    },
  }: MarketingState,
): MarketingGatewayChannelsPartitioned => {
  const [automated, manual] = partition(channels, (c) => c.integration_type === 'automated');
  return {
    automated: partitionByConnectionStatus(automated),
    manual: partitionByConnectionStatus(manual),
  };
};

export default createSelector<RootState, MarketingState, MarketingGatewayChannelsPartitioned>(
  getMarketingState,
  partitionChannels,
);
