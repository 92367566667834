import { createSelector } from 'reselect';
import get from 'lodash/get';
import getExclusionRuleDeleteConfirmation from './getExclusionRuleDeleteConfirmation';

const getHasExclusionRuleDeleteFailed = createSelector(
  getExclusionRuleDeleteConfirmation,
  (state) => get(state, 'deleteFailed'),
);

export default getHasExclusionRuleDeleteFailed;
