import { withRouter, WithRouterProps } from 'react-router';
import BemHelper from '@flowio/bem-helper';
import React, { ReactElement, StatelessComponent } from 'react';
import { NavLink, Nav } from '@flowio/react-nav';
import createRoutesFactory from '../../utilities/createRoutesFactory';
import { AccountNavigationProps } from '../../types/props';

if (process.browser) {
  require('./account-navigation.css'); // eslint-disable-line global-require
}

const bem = new BemHelper('account-navigation');

export const AccountNavigation: StatelessComponent<AccountNavigationProps & WithRouterProps> = ({
  router,
}): ReactElement => {
  const createRoutes = createRoutesFactory(router);
  const links = createRoutes([{
    href: '/account/profile',
    icon: 'Gear',
    text: 'Account Settings',
  }, {
    href: '/account/exports',
    icon: 'PaperPlane',
    text: 'Exports',
  }]);
  return (
    <div className={bem.block()}>
      <Nav>
        {links}
      </Nav>
      <div className={bem.element('api-reference')}>
        <NavLink href="https://docs.flow.io" text="API Reference" />
      </div>
    </div>
  );
};

export default withRouter<AccountNavigationProps>(AccountNavigation);
AccountNavigation.displayName = 'AccountNavigation';
