import { combineReducers } from 'redux';

import activities from './activities';
import order from './order';
import shippingLabel from './shippingLabel';
import itemContent from './itemContent';
import { FulfillmentState } from '../types/state';

export default combineReducers<FulfillmentState>({
  activities,
  order,
  shippingLabel,
  itemContent,
});
