import { type ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { type FormattedErrorMessages } from '../../../utilities/format-error-response-v2';

type FetchShippingNotificationsActionParams = {
  organizationId: string;
  orderNumber: string;
};

type FetchShippingNotificationsActionRequest = {
  type: 'FETCH_SHIPPING_NOTIFICATIONS_REQUEST';
  params: FetchShippingNotificationsActionParams;
};

type FetchShippingNotificationsActionSuccess = {
  type: 'FETCH_SHIPPING_NOTIFICATIONS_SUCCESS';
  params: FetchShippingNotificationsActionParams;
  payload: io.flow.v0.models.ShippingNotification[];
};

type FetchShippingNotificationsActionFailure = {
  type: 'FETCH_SHIPPING_NOTIFICATIONS_FAILURE';
  params: FetchShippingNotificationsActionParams;
  errors: FormattedErrorMessages;
};

export type FetchShippingNotificationsAction =
  | FetchShippingNotificationsActionRequest
  | FetchShippingNotificationsActionSuccess
  | FetchShippingNotificationsActionFailure;

export default function fetchShippingNotifications(
  params: FetchShippingNotificationsActionParams,
): ThunkResult<Promise<void>> {
  return (
    dispatch,
    getState,
    client,
  ): Promise<void> => {
    dispatch({
      type: 'FETCH_SHIPPING_NOTIFICATIONS_REQUEST',
      params,
    });
    return client.api(getState()).shippingNotifications.get({
      order_number: [params.orderNumber],
      organization: params.organizationId,
    }).then((response) => {
      if (response.ok) {
        dispatch({
          type: 'FETCH_SHIPPING_NOTIFICATIONS_SUCCESS',
          params,
          payload: response.body,
        });
        return undefined;
      }

      dispatch({
        type: 'FETCH_SHIPPING_NOTIFICATIONS_FAILURE',
        params,
        errors: formatErrorResponseV2(response),
      });
      return undefined;
    }).catch((e: unknown) => {
      dispatch({
        type: 'FETCH_SHIPPING_NOTIFICATIONS_FAILURE',
        params,
        errors: {
          messages: [{
            message: e instanceof Error ? e.message : 'Unknown error',
            code: 'FETCH_ERROR',
          }],
        },
      });
    });
  };
}
