import React from 'react';
import { Link } from 'react-router';
import * as styles from './auth-navigation-item.styles';

interface Props {
  href: string;
  label: string;
}

const AuthNavigationItem: React.FC<Props> = ({ href, label }) => (
  <li className={styles.authNavigationItem}>
    <Link className={styles.link} to={href}>
      {label}
    </Link>
  </li>
);

AuthNavigationItem.displayName = 'AuthNavigationItem';
AuthNavigationItem.defaultProps = {
  href: undefined,
  label: undefined,
};

export default AuthNavigationItem;
