import { css } from '@emotion/css';

export const segment = css`
  display: flex;
  width: 100%;
`;
export const segmentedControl = css`
  display: flex !important; // else display: inline-flex is set 
  width: 100%;
`;
export const label = css`
  width: 100%;
`;
