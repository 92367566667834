import { RSAA } from '@flowio/redux-async-middleware';
import { operationTypes } from '@flowio/redux-resource';
import ApiPropTypes from '@flowio/api-prop-types';
import PropTypes from 'prop-types';

import { api } from '../../../../utilities/clients';
import { createResourceTypeDescriptors } from '../../helpers';
import { withAssertPropTypes } from '../../../../utilities/prop-types';

export default withAssertPropTypes({
  organizationId: PropTypes.string.isRequired,
  priceBookForm: ApiPropTypes.priceBookForm.isRequired,
})(({ organizationId, priceBookForm }) => ({
  [RSAA]: {
    types: createResourceTypeDescriptors({
      operationType: operationTypes.CREATE,
      resourceType: 'priceBooks',
    }),
    callAPI: (state) => api.priceBooks(state).post(organizationId, {
      body: priceBookForm,
    }),
  },
}));
