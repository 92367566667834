import { combineReducers } from 'redux';
import assign from 'lodash/assign';
import map from 'lodash/map';
import update from 'immutability-helper';
import ActionTypes from '../constants/action-types';
import ReadyState from '../constants/ready-state';
import { TransactionsPagesState, TransactionsPageState } from '../types';
import { RootActionTypes } from '../../../stores/types';

function pages(state = {}, action: RootActionTypes): TransactionsPagesState {
  switch (action.type) {
    case ActionTypes.FETCH_TRANSACTIONS_REQUEST:
      return update(state, {
        [action.params.pageNumber]: {
          $apply: (page: TransactionsPageState): TransactionsPageState => assign({}, page, {
            createdFrom: action.params.created_on_or_after,
            createdTo: action.params.created_on_or_before,
            entriesPerPage: action.params.entriesPerPage,
            error: null,
            pageNumber: action.params.pageNumber,
            statement: action.params.statement,
            status: ReadyState.LOADING,
            statusFilter: action.params.status,
          }),
        },
      });
    case ActionTypes.FETCH_TRANSACTIONS_SUCCESS:
      return update(state, {
        [action.params.pageNumber]: {
          $merge: {
            ids: map(action.payload.results, 'id'),
            isFirstPage: action.payload.isFirstPage,
            isLastPage: action.payload.isLastPage,
            status: ReadyState.FULFILLED,
          },
        },
      });
    case ActionTypes.FETCH_TRANSACTIONS_FAILURE:
      return update(state, {
        [action.params.pageNumber]: {
          $merge: {
            error: action.payload,
            status: ReadyState.REJECTED,
          },
        },
      });
    default:
      return state;
  }
}

function currentPage(state = 1, action: RootActionTypes): number {
  switch (action.type) {
    case ActionTypes.FETCH_TRANSACTIONS_REQUEST:
      return state;
    case ActionTypes.FETCH_TRANSACTIONS_SUCCESS:
      return action.params.pageNumber;
    case ActionTypes.FETCH_TRANSACTIONS_FAILURE:
      return action.params.pageNumber;
    default:
      return state;
  }
}

export default combineReducers({
  currentPage,
  pages,
});
