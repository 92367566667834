import { createPaginationReducer } from '@flowio/redux-filtering-paging-sorting';
import update from 'immutability-helper';
import { RootActionTypes } from '../../../stores/types';
import ActionTypes from '../constants/ActionTypes';
import { OptinAttributesState } from '../types';

const initialState: OptinAttributesState = {
  entities: {},
};

const attributes = createPaginationReducer({
  identifyBy: 'optin_attribute_key',
  types: [
    ActionTypes.FETCH_OPTIN_ATTRIBUTES_REQUEST,
    ActionTypes.FETCH_OPTIN_ATTRIBUTES_SUCCESS,
    ActionTypes.FETCH_OPTIN_ATTRIBUTES_FAILURE,
  ],
}).plugin((state: OptinAttributesState = initialState, action: RootActionTypes) => {
  switch (action.type) {
    case ActionTypes.FETCH_OPTIN_ATTRIBUTE_SUCCESS:
    case ActionTypes.UPDATE_OPTIN_ATTRIBUTE_SUCCESS:
      return update(state, {
        entities: {
          [action.payload.optin_attribute_key]: {
            $set: action.payload,
          },
        },
      });
    default:
      return state;
  }
});

export default attributes;

// export default createPaginationReducer({
//   identifyBy: 'optin_attribute_key',
//   types: [
//     ActionTypes.FETCH_OPTIN_ATTRIBUTES_REQUEST,
//     ActionTypes.FETCH_OPTIN_ATTRIBUTES_SUCCESS,
//     ActionTypes.FETCH_OPTIN_ATTRIBUTES_FAILURE,
//   ],
// }).plugin((state, action) => {
//   switch (action.type) {
//     case ActionTypes.FETCH_OPTIN_ATTRIBUTE_SUCCESS:
//     case ActionTypes.UPDATE_OPTIN_ATTRIBUTE_SUCCESS:
//       return update(state, {
//         entities: {
//           [action.payload.optin_attribute_key]: {
//             $set: action.payload,
//           },
//         },
//       });
//     default:
//       return state;
//   }
// });
