import PropTypes from 'prop-types';
import { ExperiencesDeletePriceAndBooksByExperienceKeyAndKeyResponse } from '@flowio/api-sdk';
import { withAssertPropTypes } from '../../../utilities/prop-types';
import ActionTypes from '../constants/action-types';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import { ThunkResult, ThunkExtraArguments, RootState } from '../../../stores/types';
import { PropType } from '../../../types';
import { ThunkDispatcher } from '../../../middlewares/types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';
import toLegacyResponse from '../../../utilities/clients/to-legacy-response';
import { createToast } from '../../console/actions';

 type ResponseType = PropType<ExperiencesDeletePriceAndBooksByExperienceKeyAndKeyResponse, 'body'>;

interface DeletePriceBookExperienceMappingParams {
  organizationId: string;
  experienceKey: string;
  priceBookKey: string;
}

const deletePriceBookExperienceMapping = ({
  organizationId,
  experienceKey,
  priceBookKey,
}: DeletePriceBookExperienceMappingParams): ThunkResult<Promise<LegacyResponse<ResponseType>>> => (
  dispatch: ThunkDispatcher,
  getState: () => RootState,
  extra: ThunkExtraArguments,
): Promise<LegacyResponse<ResponseType>> => {
  dispatch({
    type: ActionTypes.REMOVE_PRICE_BOOK_MAPPING_REQUEST,
  });

  return extra.api(getState()).experiences.deletePriceAndBooksByExperienceKeyAndKey({
    organization: organizationId,
    experience_key: experienceKey,
    key: priceBookKey,
  }).then((response) => {
    if (response.ok) {
      dispatch({
        type: ActionTypes.REMOVE_PRICE_BOOK_MAPPING_SUCCESS,
      });
      dispatch(createToast({
        content: 'Successfully removed price book mapping',
        intent: 'positive',
        icon: 'CheckCircle',
      }));
    } else {
      dispatch({
        type: ActionTypes.REMOVE_PRICE_BOOK_MAPPING_FAILURE,
        payload: formatErrorResponseV2(response),
      });
      dispatch(createToast({
        content: 'Error while removing price book mapping',
        intent: 'negative',
        icon: 'ErrorOutline',
      }));
    }
    return toLegacyResponse<ResponseType>(response);
  });
};

export default withAssertPropTypes({
  organizationId: PropTypes.string.isRequired,
  experienceKey: PropTypes.string.isRequired,
  priceBookKey: PropTypes.string,
})(deletePriceBookExperienceMapping);
