import { ActivityKind } from '../constants';
import createActivity from './createActivity';
import { ThunkResult } from '../../../stores/types';
import getShippingLabelToPrint from './getShippingLabelToPrint';

export default function printInvoicePdf(): ThunkResult<Promise<void>> {
  return function printInvoiceEffects(dispatch): Promise<void> {
    return dispatch(getShippingLabelToPrint()).then((res) => {
      if (res && res.label.invoice?.required) {
        dispatch(createActivity({
          kind: ActivityKind.PRINT_INVOICE,
          organizationId: res.organizationId,
          orderNumber: res.orderNumber,
        }));
        window.open(res.label.invoice?.pdf, '_blank');
      }
    });
  };
}
