import { createPaginationReducer } from '@flowio/redux-filtering-paging-sorting';
import update from 'immutability-helper';
import { RootActionTypes } from '../../../stores/types';
import ActionTypes from '../constants/ActionTypes';
import { OptinPromptState } from '../types';

const initialState: OptinPromptState = {
  entities: {},
};

const prompts = createPaginationReducer({
  identifyBy: 'id',
  types: [
    ActionTypes.FETCH_OPTIN_PROMPTS_REQUEST,
    ActionTypes.FETCH_OPTIN_PROMPTS_SUCCESS,
    ActionTypes.FETCH_OPTIN_PROMPTS_FAILURE,
  ],
}).plugin((
  state: OptinPromptState = initialState,
  action: RootActionTypes,
) => {
  switch (action.type) {
    case ActionTypes.UPDATE_OPTIN_PROMPT_SUCCESS:
      return update(state, {
        entities: {
          [action.payload.id]: {
            $set: action.payload,
          },
        },
      });
    default:
      return state;
  }
});

export default prompts;
