import React from 'react';
import noop from 'lodash/noop';
import isEmpty from 'lodash/isEmpty';
import {
  Card, CardHeader, CardContent, CardFooter, CardEmptyState, CardTitle,
} from '@flowio/react-card';

import { Box } from '@flowio/react-box';
import { OutlineButton } from '@flowio/react-button';
import { Plus } from '@flowio/react-icons';
import AvailabilityRulesList from '../AvailabilityRuleList';

import { AvailabilityRulesCardProps } from '../../types/components';

class AvailabilityRulesCard extends React.PureComponent<AvailabilityRulesCardProps> {
  static displayName = 'AvailabilityRulesCard';

  static defaultProps = {
    onAddRule: noop,
    onChangeFeature: noop,
    onDeleteRule: noop,
    onEditRule: noop,
    ruleFilters: [],
  };

  constructor(props: AvailabilityRulesCardProps, context: any) {
    super(props, context);
    this.handleAddRuleClick = this.handleAddRuleClick.bind(this);
    this.handleDeleteRuleClick = this.handleDeleteRuleClick.bind(this);
    this.handleEditRuleClick = this.handleEditRuleClick.bind(this);
    this.handleRuleChange = this.handleRuleChange.bind(this);
  }

  handleAddRuleClick() {
    const {
      organizationId,
      onAddRule = noop,
      feature,
    } = this.props;
    onAddRule(organizationId, feature);
  }

  handleDeleteRuleClick(ruleId: string) {
    const {
      organizationId,
      feature,
      onDeleteRule = noop,
    } = this.props;
    onDeleteRule(organizationId, feature, ruleId);
  }

  handleEditRuleClick(rule: io.flow.internal.v0.unions.FeatureRule) {
    const {
      organizationId,
      onEditRule = noop,
    } = this.props;
    onEditRule(organizationId, rule);
  }

  handleRuleChange(rules: io.flow.internal.v0.unions.FeatureRule[]) {
    const {
      organizationId,
      feature,
      onChangeFeature = noop,
    } = this.props;
    onChangeFeature(organizationId, feature.key, {
      featureForm: {
        ...feature,
        rules,
      },
    });
  }

  render() {
    const {
      feature,
      ruleFilters,
    } = this.props;

    const {
      rules,
    } = feature;

    return (
      <Card>
        <CardHeader dividing>
          <Box alignItems="center" justifyContent="between">
            <CardTitle>Availability Rules</CardTitle>
            <OutlineButton
              content="Add Rule"
              leftIcon={Plus}
              intent="primary"
              onClick={this.handleAddRuleClick}
            />
          </Box>
        </CardHeader>
        <CardContent>
          {!isEmpty(rules) && (
            <AvailabilityRulesList
              onRuleChange={this.handleRuleChange}
              onDeleteRuleClick={this.handleDeleteRuleClick}
              onEditRuleClick={this.handleEditRuleClick}
              ruleFilters={ruleFilters}
              rules={rules}
            />
          )}
          {isEmpty(rules) && (
            <CardEmptyState content="No Rules" />
          )}
        </CardContent>
        <CardFooter />
      </Card>
    );
  }
}

export default AvailabilityRulesCard;
