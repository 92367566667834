import { createSelector } from 'reselect';
import getMarketingState from './getMarketingState';
import { RootState } from '../../../stores/types';
import { MarketingState, MarketingFacebookConnectionState } from '../types';

const sortCatalogsByItems = (state: MarketingState): MarketingFacebookConnectionState['catalogs'] => {
  const { catalogs } = state.facebookConnection;
  let sortedList;

  if (catalogs.catalogList) {
    sortedList = [...catalogs.catalogList].sort(
      (
        item: io.flow.internal.v0.models.MarketingGatewayFacebookCatalogSummary,
        item2: io.flow.internal.v0.models.MarketingGatewayFacebookCatalogSummary,
      ) => (item2.item_count - item.item_count),
    );
  }

  return {
    ...catalogs,
    catalogList: sortedList,
  };
};

export default createSelector<RootState, MarketingState, MarketingFacebookConnectionState['catalogs']>(
  getMarketingState,
  sortCatalogsByItems,
);
