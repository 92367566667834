import { RootState, ThunkDispatcher } from '../../../stores/types';
import ActionTypes from '../constants/action-types';
import {
  getImportEventSouce,
} from '../selectors';

const closeImportEventSource = () => (dispatch: ThunkDispatcher, getState: () => RootState) => {
  const eventSource = getImportEventSouce(getState());
  if (eventSource) {
    eventSource.close();
  }
  dispatch({ type: ActionTypes.IMPORT_EVENTSOURCE_CLOSE });
};

export default closeImportEventSource;
