import { Form, Field } from 'redux-form';
import React from 'react';
import { css } from '@emotion/css';
import ContentStatus from '../../../constants/ContentStatus';
import GenericError from '../../../../../components/GenericError';
import { Props } from '../types';

const genericErrorStyles = css`
  margin-bottom: 1rem;
`;

const OptinMessageChangeStatusForm: React.FC<Props> = ({
  handleSubmit,
  error,
  name,
  status,
}) => (
  <Form onSubmit={handleSubmit}>
    <Field component="input" type="hidden" name="organizationId" />
    <Field component="input" type="hidden" name="optinAttributeKey" />
    <Field component="input" type="hidden" name="name" />
    <Field component="input" type="hidden" name="target" />
    <Field component="input" type="hidden" name="status" />
    {error && <GenericError className={genericErrorStyles} error={error} />}
    <p>
      Are you sure you want to
      {status === ContentStatus.LIVE ? 'activate' : 'archive'}
      {' '}
      <strong>{name}</strong>
      ?
    </p>
  </Form>
);

OptinMessageChangeStatusForm.displayName = 'OptinMessageChangeStatusForm';

export default OptinMessageChangeStatusForm;
