import { RouterState } from 'react-router';
import { connect, MapDispatchToProps } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { submit } from 'redux-form';
import {
  getPageSize,
  getPageResults,
  getIsFirstPage,
  getIsLastPage,
  getPageNumber,
} from '@flowio/redux-filtering-paging-sorting';
import { withFetch } from '@flowio/redux-fetch';

import { fetchRegions, getRegions } from '../../reference';
import { fetchOrganization, getOrganizationId } from '../../organization';
import { getExclusionRulesPaginationState } from '../selectors';
import {
  changeExclusionRulesPage,
  fetchExclusionRules,
  showExclusionRuleWorksheet,
  showExclusionRuleDeleteConfirmation,
} from '../actions';
import ExclusionRuleIndex from '../components/ExclusionRulesIndex';
import {
  ExclusionRulesIndexStateProps,
  ExclusionRulesIndexDispatchProps,
  ExclusionRulesIndexProps,
} from '../types';
import { RootState, ThunkDispatcher } from '../../../stores/types';
import FormName from '../constants/FormName';
import showExportDialog from '../actions/showExportDialog';
import hideExportDialog from '../actions/hideExportDialog';
import getIsExclusionExportDialogVisible from '../selectors/getIsExclusionExportDialogVisible';

function fetchAsyncState(
  dispatch: ThunkDispatcher,
  _getState: () => RootState,
  props: RouterState,
) {
  const { organization } = props.params;
  const { pageNumber, entriesPerPage } = props.location.query;
  return Promise.all([
    dispatch(fetchExclusionRules(organization, pageNumber, entriesPerPage)),
    dispatch(fetchOrganization(organization)),
    dispatch(fetchRegions()),
  ]);
}

const mapStateToProps = (state: RootState): ExclusionRulesIndexStateProps => {
  const pageNumber: number | string = getPageNumber(getExclusionRulesPaginationState)(state);
  const isFirstPage: boolean = getIsFirstPage(getExclusionRulesPaginationState)(state);
  const isLastPage: boolean = getIsLastPage(getExclusionRulesPaginationState)(state);

  return {
    isExclusionsExportDialogVisible: getIsExclusionExportDialogVisible(state),
    entriesPerPage: getPageSize(getExclusionRulesPaginationState)(state),
    exclusionRules: getPageResults(getExclusionRulesPaginationState)(state),
    organizationId: getOrganizationId(state),
    regions: getRegions(state),
    isFirstPage,
    isLastPage,
    pageNumber: Number(pageNumber),
  };
};

const mapDispatchToProps: MapDispatchToProps<
ExclusionRulesIndexDispatchProps,
{}
> = (dispatch) => bindActionCreators({
  onExportExclusions: () => submit(FormName.ExclusionsExportForm),
  onShowExportDialog: showExportDialog,
  onHideExportDialog: hideExportDialog,
  onAddExclusionRule: showExclusionRuleWorksheet,
  onEditExclusionRule: showExclusionRuleWorksheet,
  onPageChange: changeExclusionRulesPage,
  onRemoveExclusionRule: showExclusionRuleDeleteConfirmation,
}, dispatch);

export default compose<React.FC<ExclusionRulesIndexProps>>(
  withFetch(fetchAsyncState),
  connect(mapStateToProps, mapDispatchToProps),
)(ExclusionRuleIndex);
