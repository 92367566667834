import { withFetch } from '@flowio/redux-fetch';
import { connect } from 'react-redux';
import { RouterState } from 'react-router';
import { compose } from 'redux';
import { RootState, ThunkDispatcher } from '../../../stores/types';
import { getShop, setShopifyAppError } from '../actions';
import { getAssignedShop, getAssignedOrg, getShopifyAppError } from '../selectors';
import ShopifyApp from '../components/shopify-app';
import { ShopifyAppProps } from '../types/props';

function fetchAsyncState(
  dispatch: ThunkDispatcher,
  _getState: () => RootState,
  ownProps: RouterState,
): Promise<void> {
  const { shop, error } = ownProps.location.query;
  dispatch(setShopifyAppError(error));
  return dispatch(getShop(shop));
}

function mapStateToProps(state: RootState): ShopifyAppProps {
  return {
    assignedShop: getAssignedShop(state),
    assignedOrg: getAssignedOrg(state),
    error: getShopifyAppError(state),
  };
}

export default compose<React.FC>(
  withFetch(fetchAsyncState),
  connect<ShopifyAppProps, {}, {}, RootState>(mapStateToProps),
)(ShopifyApp);
