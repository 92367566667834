import Client from './client';

export default class ChannelDebugTransactions extends Client {
  constructor(opts) {
    let options = opts;

    if (typeof opts === 'string') {
      options = { host: opts }; // convert host string to options object
    }

    options.serviceName = 'API Internal';

    super(options);
  }

  post(channelId, options = {}) {
    return this.makeRequest(`${this.host}/channel/${channelId}/billing/debug/transactions`, {
      ...options,
       method: 'POST',
    });
  }

}
