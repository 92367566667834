import { createSelector } from 'reselect';
import property from 'lodash/property';
import getMarketingState from './getMarketingState';
import { RootState } from '../../../stores/types';
import { MarketingState } from '../types';
import { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';

export default createSelector<RootState, MarketingState, FormattedErrorMessages>(
  getMarketingState,
  property(['googleConnectionAccount', 'error']),
);
