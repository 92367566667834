/* eslint-disable max-len */
import find from 'lodash/find';
import get from 'lodash/get';
import { createSelector } from 'reselect';
import { RootState } from '../../stores/types';
import { getWinningVariant as checkWinningVariant } from './components/experiment-details/utilities/checkVariant';
import { ExperimentState } from './types';

export const getExperimentState = (state: RootState): ExperimentState => get(state, 'experiments');

export const getExperiments = createSelector<RootState, ExperimentState, io.flow.internal.v0.unions.Experiment[]>(
  getExperimentState,
  (state) => get(state, 'entities.experiments.results'),
);

export const getExperiences = createSelector<RootState, ExperimentState, io.flow.v0.models.Experience[]>(
  getExperimentState,
  (state) => get(state, 'entities.experiences.results'),
);

export const getExperimentsModalOpen = createSelector<RootState, ExperimentState, boolean>(
  getExperimentState,
  (state) => get(state, 'ui.experimentModalOpen'),
);

export const getCurrentExperiment = createSelector<RootState, ExperimentState, io.flow.internal.v0.unions.Experiment>(
  getExperimentState,
  (state) => get(state, 'current'),
);

export const getSelectedExperiment = createSelector<RootState, ExperimentState, io.flow.internal.v0.unions.Experiment>(
  getExperimentState,
  (state) => get(state, 'ui.selectedExperiment'),
);

export const getCurrentExperimentResults = createSelector<RootState, ExperimentState, io.flow.internal.v0.models.ExperimentResultsWithTimestamp[]>(
  getExperimentState,
  (state) => get(state, 'entities.experimentResults.results'),
);

export const isEndExperimentModalOpen = createSelector<RootState, ExperimentState, boolean>(
  getExperimentState,
  (state) => get(state, 'ui.endExperimentModalOpen'),
);

export const isImplementVariantModalOpen = createSelector<RootState, ExperimentState, boolean>(
  getExperimentState,
  (state) => get(state, 'ui.implementVariantModalOpen'),
);

export const getCurrentExperimentStatus = createSelector<RootState, ExperimentState, io.flow.internal.v0.enums.Status>(
  getExperimentState,
  (state) => get(state, 'current.status'),
);

export const getCurrentExperimentForm = createSelector<RootState, ExperimentState, io.flow.internal.v0.models.ExperimentForm>(
  getExperimentState,
  (state) => get(state, 'entities.experimentForm.experiment'),
);

export const getVariants = createSelector(
  [getExperiences, getCurrentExperiment],
  (experiences, currentExperiment) => (
    currentExperiment.variants as (
      io.flow.internal.v0.models.ExperienceVariant | io.flow.internal.v0.models.FeatureVariant
    )[]
  ).map((v) => {
    const variant = {
      ...v,
      trafficPercentage: get(v, 'traffic_percentage'),
      experimentResults: get(v, 'experiment_results'),
    };

    if (v.discriminator === 'experience_variant') {
      return {
        ...variant,
        experience: find(experiences, { key: get(v, 'experience.key') }) || v.experience,
      };
    }
    return variant;
  }),
);

export const getWinningVariant = createSelector(
  [getVariants, getCurrentExperiment],
  (variants, currentExperiment) => checkWinningVariant(variants, currentExperiment),
);

export const getExperimentValues = createSelector(
  getExperimentState,
  (state) => get(state, 'entities.experimentForm.discriminator.values'),
);

export const getExperimentVariants = createSelector<RootState, ExperimentState, io.flow.internal.v0.models.ExperimentFormDefaultVariant[]>(
  getExperimentState,
  (state) => get(state, 'entities.experimentForm.variants'),
);

export const getExperimentDiscriminator = createSelector<RootState, ExperimentState, io.flow.internal.v0.enums.ExperimentDiscriminatorKey>(
  getExperimentState,
  (state) => get(state, 'entities.experimentForm.discriminator.key'),
);

export const getExperimentFormScope = createSelector<RootState, ExperimentState, io.flow.internal.v0.enums.Scope>(
  getExperimentState,
  (state) => get(state, 'entities.experimentForm.scope'),
);
