import { OptinAttributeFormValues } from '../types';

export default (
  organizationId: string,
  optinAttribute: io.flow.internal.v0.models.OptinAttribute,
): OptinAttributeFormValues => ({
  name: optinAttribute.name,
  optinAttributeKey: optinAttribute.optin_attribute_key,
  organizationId,
  status: optinAttribute.status,
  target: optinAttribute.target,
});
