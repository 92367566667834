import React from 'react';
import { RadioButtonGroup, RadioButtonGroupProps } from '@flowio/react-radio-button';
import { WrappedFieldProps } from 'redux-form';

type ReduxFormRadioButtonGroupProps = WrappedFieldProps & RadioButtonGroupProps;

const ReduxFormRadioButtonGroup: React.FC<ReduxFormRadioButtonGroupProps> = (props) => {
  const { input, meta, ...unhandledProps } = props;

  return (
    <RadioButtonGroup
      {...unhandledProps}
      value={input.value}
      name={input.name}
      onBlur={input.onBlur}
      onValueChange={input.onChange}
      onFocus={input.onFocus}
    />
  );
};

export default ReduxFormRadioButtonGroup;
