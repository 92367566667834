import { first } from 'lodash';
import { ThunkExtraArguments, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import ActionTypes from '../constants/action-types';
import {
  FetchReplacementOrderBuilderFailure,
  FetchReplacementOrderBuilderRequest,
  FetchReplacementOrderBuilderSuccess,
} from '../types';

function fetchReplacementOrderBuilderRequest(): FetchReplacementOrderBuilderRequest {
  return {
    type: ActionTypes.FETCH_REPLACEMENT_ORDER_BUILDER_REQUEST,
  };
}

function fetchReplacementOrderBuilderSuccess(
  payload: io.flow.v0.models.OrderBuilder | null,
): FetchReplacementOrderBuilderSuccess {
  return {
    type: ActionTypes.FETCH_REPLACEMENT_ORDER_BUILDER_SUCCESS,
    payload,
  };
}

function fetchReplacementOrderBuilderFailure(
  payload: FormattedErrorMessages,
): FetchReplacementOrderBuilderFailure {
  return {
    type: ActionTypes.FETCH_REPLACEMENT_ORDER_BUILDER_FAILURE,
    error: true,
    payload,
  };
}

export function createReplacementOrder(
  organization: string,
  orderNumber: string,
): ThunkResult<Promise<io.flow.v0.models.OrderReplacement>> {
  return async function createReplacementOrderEffects(
    _dispatch,
    getState,
    extra: ThunkExtraArguments,
  ): Promise<io.flow.v0.models.OrderReplacement> {
    const result = await extra.api(getState()).orders.postReplacementsByNumber({
      organization,
      number: orderNumber,
      body: {},
    });

    if (!result.ok) {
      return Promise.reject(new Error(`${result.body?.code || 500}: ${first(result.body?.messages) || 'An Error Occurred'}`));
    }

    return Promise.resolve(result.body);
  };
}

export function fetchReplacementOrderBuilder(
  organization: string,
  orderNumber: string,
  reload = true,
): ThunkResult<Promise<void>> {
  return async function fetchReplacementOrderBuilderEffects(
    dispatch,
    getState,
    extra: ThunkExtraArguments,
  ): Promise<void> {
    if (reload) dispatch(fetchReplacementOrderBuilderRequest());

    const result = await extra.api(getState()).orderBuilders.getByNumber({
      organization,
      number: orderNumber,
    });

    if (result.ok) {
      if (result.body.order?.submitted_at) {
        dispatch(fetchReplacementOrderBuilderSuccess(null));
      } else {
        dispatch(fetchReplacementOrderBuilderSuccess(result.body));
      }
    } else if (result.status === 404) {
      dispatch(fetchReplacementOrderBuilderSuccess(null));
    } else {
      dispatch(fetchReplacementOrderBuilderFailure(formatErrorResponseV2(result)));
    }

    return Promise.resolve();
  };
}

export function submitReplacementOrder(
  organization: string,
  orderNumber: string,
): ThunkResult<Promise<void>> {
  return async function submitReplacementOrderEffects(
    _dispatch,
    getState,
    extra: ThunkExtraArguments,
  ): Promise<void> {
    const result = await extra.api(getState()).orders.putSubmissionsByNumber({
      organization,
      number: orderNumber,
      body: {},
    });

    if (!result.ok) {
      return Promise.reject(new Error(`${result.body?.code || 500}: ${first(result.body?.messages) || 'An Error Occurred'}`));
    }

    return Promise.resolve();
  };
}

export function updateCustomerInfo(
  organization: string,
  orderNumber: string,
  customerInfo: io.flow.v0.models.OrderCustomerForm,
): ThunkResult<Promise<io.flow.v0.models.OrderBuilder>> {
  return async function updateCustomerInfoEffects(
    _dispatch,
    getState,
    extra: ThunkExtraArguments,
  ): Promise<io.flow.v0.models.OrderBuilder> {
    const result = await extra.api(getState()).orderBuilders.putCustomerByNumber({
      organization,
      number: orderNumber,
      body: {
        customer: customerInfo,
      },
    });

    if (!result.ok) {
      return Promise.reject(new Error(`updateCustomerInfo: An Error Occurred: ${result.status}`));
    }

    return Promise.resolve(result.body);
  };
}

export function updateShippingAddress(
  organization: string,
  orderNumber: string,
  shippingAddress: io.flow.v0.models.OrderAddress,
): ThunkResult<Promise<io.flow.v0.models.OrderBuilder>> {
  return async function updateShippingAddressEffects(
    _dispatch,
    getState,
    extra: ThunkExtraArguments,
  ): Promise<io.flow.v0.models.OrderBuilder> {
    const result = await extra.api(getState()).orderBuilders.putDestinationByNumber({
      organization,
      number: orderNumber,
      body: {
        destination: shippingAddress,
      },
    });

    if (!result.ok) {
      return Promise.reject(new Error(`updateShippingAddress: An Error Occurred: ${result.status}`));
    }

    return Promise.resolve(result.body);
  };
}

export function updateReplacementOrderLineItemQuantity(
  organization: string,
  orderNumber: string,
  id: string,
  quantity: number,
): ThunkResult<Promise<void>> {
  return async function updateReplacementOrderLineitemQuantityEffects(
    _dispatch,
    getState,
    extra: ThunkExtraArguments,
  ): Promise<void> {
    const result = await extra.api(getState()).orderBuilders.putLinesAndQuantityByNumberAndId({
      organization,
      number: orderNumber,
      body: { quantity },
      id,
    });

    if (!result.ok) {
      return Promise.reject(new Error(`${result.body?.code || 500}: ${first(result.body?.messages) || 'An Error Occurred'}`));
    }

    return Promise.resolve();
  };
}

export function addReplacementOrderLineItem(
  organization: string,
  orderNumber: string,
  itemForm: io.flow.v0.models.LineItemForm,
): ThunkResult<Promise<void>> {
  return async function addReplacementOrderLineItemEffects(
    _dispatch,
    getState,
    extra: ThunkExtraArguments,
  ): Promise<void> {
    const result = await extra.api(getState()).orderBuilders.postLinesByNumber({
      organization,
      number: orderNumber,
      body: itemForm,
    });

    if (!result.ok) {
      return Promise.reject(new Error(`${result.body?.code || 500}: ${first(result.body?.messages) || 'An Error Occurred'}`));
    }

    return Promise.resolve();
  };
}

export function removeReplacementOrderLineItem(
  organization: string,
  orderNumber: string,
  id: string,
): ThunkResult<Promise<void>> {
  return async function removeReplacementOrderLineItemEffects(
    _dispatch,
    getState,
    extra: ThunkExtraArguments,
  ): Promise<void> {
    const result = await extra.api(getState()).orderBuilders.deleteLinesByNumberAndId({
      organization,
      number: orderNumber,
      id,
    });

    if (!result.ok) {
      return Promise.reject(new Error(`${result.body?.code || 500}: ${first(result.body?.messages) || 'An Error Occurred'}`));
    }

    return Promise.resolve();
  };
}

export function updateReplacementOrderSelections(
  organization: string,
  orderNumber: string,
  selections: string[],
): ThunkResult<Promise<void>> {
  return async function updateReplacementOrderSelectionsEffects(
    _dispatch,
    getState,
    extra: ThunkExtraArguments,
  ): Promise<void> {
    const result = await extra.api(getState()).orderBuilders.putSelectionsByNumber({
      organization,
      number: orderNumber,
      body: { selections },
    });

    if (!result.ok) {
      return Promise.reject(new Error(`updateReplacementOrderSelections: An Error Occurred: ${result.status}`));
    }

    return Promise.resolve();
  };
}
