import { RootActionTypes } from '../../../stores/types';
import { RedirectOptions } from '../types/actions';
import ActionTypes from '../constants/action-types';

/**
 * Creates an action responsible for setting a redirect value in application state.
 * @param {Object} redirectOptions - properties passed to the redirect payload.
 */
export default function setRedirectUrl(redirectOptions: RedirectOptions): RootActionTypes {
  return {
    type: ActionTypes.SET_REDIRECT_URL,
    payload: redirectOptions,
  };
}
