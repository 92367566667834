import assign from 'lodash/assign';
import every from 'lodash/every';
import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';

import checkHttpStatus from '../utilities/check-http-status';
import formatErrorResponse from '../utilities/format-error-response';

const omitByUndefined = (object) => omitBy(object, isUndefined);

/**
 * Async action creator middleware for Redux.
 *
 * Usage:
 *
 *  function createExperience(organization, experienceForm) {
 *    return {
 *      types: [
 *        'CREATE_EXPERIENCE_REQUEST',
 *        'CREATE_EXPERIENCE_SUCCESS',
 *        'CREATE_EXPERIENCE_FAILURE',
 *      ],
 *      shouldCallAPI: (state) => true,
 *      callAPI: ({ authorization }) => experiences.experiences.post(organization, {
 *        body: JSON.stringify(experienceForm),
 *        headers: {
 *          Authorization: `Bearer ${authorization}`,
 *        },
 *      }),
 *      payload: { experienceForm },
 *      messages: {
 *        401: 'You are not authorized to perform this action at this time.',
 *        404: 'We could not find the resource you requested.',
 *      }
 *      onBeforeFailure: ({ dispatch, status, payload, prevState }) => {},
 *      onBeforeSuccess: ({ dispatch, status, payload, prevState }) => {},
 *      onFailure: ({ dispatch, status, payload, prevState, nextState }) => {},
 *      onSuccess: ({ dispatch, status, payload, prevState, nextState }) => {},
 *    }
 *  }
 *
 * Remarks:
 *
 * The options injected to the middleware creator take lower precedense than
 * those assigned as attributes to the returned object in action creators.
 *
 * Arguments:
 *
 * @param {String} [options.failureType] The default action type for failure
 * scenarios.
 * @param {Function} [options.onBeforeFailure] A callback function that is
 * triggered before a failure action type is dispatched. If it returns `false`
 * the failure action type will not be dispatched.
 * @param {Function} [options.onBeforeSuccess] A callback function that is
 * triggered before a success action type is dispatched. If it returns `false`
 * the success action type will not be dispatched.
 * @param {Function} [options.onFailure] A callback function that is triggered
 * after a success action type is dispatched.
 * @param {Function} [options.onSuccess] A callback function that is triggered
 * after a success action type is dispatched.
 */

export default function createAsyncActionMiddleware(options = {}) {
  return ({ dispatch, getState }) => (next) => (action) => {
    const {
      callAPI,
      messages = {},
      onBeforeFailure = options.onBeforeFailure,
      onBeforeSuccess = options.onBeforeSuccess,
      onFailure = options.onFailure,
      onSuccess = options.onSuccess,
      payload = {},
      shouldCallAPI = () => true,
      types,
    } = action;

    if (!types) {
      return next(action);
    }

    if (!isArray(types) || types.length < 2 || types.length > 3 || !every(types, isString)) {
      throw new Error('`types` must be an array of at least length 2 containing strings.');
    }

    if (!isFunction(callAPI)) {
      throw new Error('`callAPI` must be a function.');
    }

    const state = getState();

    if (!shouldCallAPI(state)) {
      return Promise.resolve();
    }

    const [requestType, successType, failureType = options.failureType] = types;

    return new Promise((resolve, reject) => {
      dispatch(assign({}, payload, omitByUndefined({
        type: requestType,
      })));

      callAPI(state)
        .then(checkHttpStatus)
        .then((response) => {
        // Materialize success action object
          const actionObject = assign({}, payload, omitByUndefined({
            type: successType,
          }));

          if (response.result) {
            actionObject.payload = response.result;
          }

          // Materialize parameter object for lifecycle callbacks
          const paramsObject = {
            dispatch,
            status: response.status,
            prevState: state,
            result: actionObject,
          };

          // Trigger success lifecycle callbacks
          if (!isFunction(onBeforeSuccess) || onBeforeSuccess(paramsObject) !== false) {
            dispatch(actionObject);
            if (isFunction(onSuccess)) {
              paramsObject.nextState = getState();
              onSuccess(paramsObject);
            }
          }

          // Resolve with response
          resolve(response);
        })
        .catch((error) => {
        // Reject immediately when error is a runtime error. A `response` object is attached
        // by `checkHttpStatus` when status code for response is not in the 2XX range.
          if (!error.response) {
            return reject(error);
          }

          const response = formatErrorResponse(error.response, { messages });

          // Materialize failure action object.
          const actionObject = assign({}, payload, omitByUndefined({
            payload: response.result,
            type: failureType,
          }));

          // Materialize parameter object for lifecycle callbacks.
          const paramsObject = {
            dispatch,
            status: response.status,
            prevState: state,
            result: actionObject,
          };

          // Trigger failure lifecycle callbacks
          if (!isFunction(onBeforeFailure) || onBeforeFailure(paramsObject) !== false) {
            dispatch(actionObject);
            if (isFunction(onFailure)) {
              paramsObject.nextState = getState();
              onFailure(paramsObject);
            }
          }

          return resolve(response);
        });
    });
  };
}
