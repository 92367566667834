import { Card, CardHeader, CardTitle } from '@flowio/react-card';
import { Box } from '@flowio/react-box';
import React from 'react';
import get from 'lodash/get';
import noop from 'lodash/noop';
import { ReportInterval } from '@flowio/api-internal-constants';

import { css } from '@emotion/css';
import MetricByCountryCard from '../MetricByCountryCard';
import TimelineCard from '../TimelineCard';
import Metric from '../../constants/metric';
import withNumberFormat from '../../../../utilities/numbers/withNumberFormat';
import withCurrency from '../../utilities/with-currency';

const totalAmountStyles = css`
  font-size: 2rem;
  font-weight: 300;
  text-align: right;
`;

interface Props {
  countries: io.flow.v0.models.Country[];
  currency: string;
  endDate: string;
  metricByCountry?: io.flow.internal.v0.models.OrderRevenueRegionChart;
  timeline?: io.flow.internal.v0.models.OrderRevenueTimelineChart;
  numberOfOrders: number;
  interval: ReportInterval;
  onRequestIntervalChange: Function;
  onRequestRegionChange: Function;
  organizationCountry: string;
  organizationCurrency: string;
  region: string;
  type: Metric;
}

const MetricPanel: React.FC<Props> = ({
  countries,
  currency,
  endDate,
  timeline,
  interval,
  numberOfOrders,
  onRequestIntervalChange,
  onRequestRegionChange,
  organizationCountry,
  organizationCurrency,
  metricByCountry,
  region,
  type,
}) => (
  <div>
    <Card>
      <CardHeader>
        <Box alignItems="center" justifyContent="between">
          <CardTitle>
            {type === Metric.GMV && (
              withCurrency('Submitted Order Value', currency)
            )}
            {type === Metric.ORDER_COUNT && (
              <span>Total Number of Orders</span>
            )}
          </CardTitle>
          <div className={totalAmountStyles}>
            {type === Metric.GMV && (
              get(timeline, 'total.label')
            )}
            {type === Metric.ORDER_COUNT && (
              withNumberFormat(numberOfOrders)
            )}
          </div>
        </Box>
      </CardHeader>
    </Card>
    <TimelineCard
      countries={countries}
      currency={currency}
      interval={interval}
      endDate={endDate}
      onRequestIntervalChange={onRequestIntervalChange}
      onRequestRegionChange={onRequestRegionChange}
      organizationCountry={organizationCountry}
      organizationCurrency={organizationCurrency}
      region={region}
      type={type}
      timeline={timeline}
    />
    <MetricByCountryCard
      valueKey={type === Metric.GMV ? 'revenue.amount' : 'count'}
      currency={currency}
      organizationCountry={organizationCountry}
      organizationCurrency={organizationCurrency}
      data={metricByCountry}
    />
  </div>
);

MetricPanel.displayName = 'MetricPanel';

MetricPanel.propTypes = {

};

MetricPanel.defaultProps = {
  onRequestIntervalChange: noop,
  onRequestRegionChange: noop,
  type: Metric.GMV,
  numberOfOrders: 0,
  currency: 'USD',
};

export default MetricPanel;
