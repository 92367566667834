import update from 'immutability-helper';
import { RootActionTypes } from '../../../stores/types';
import ActionTypes from '../constants/action-types';
import { PublicKeysState } from '../types';

const defaultState: PublicKeysState = {
  results: [],
  isFirstPage: true,
  isLastPage: true,
};

export default function publicKeysReducer(
  state = defaultState,
  action: RootActionTypes,
): PublicKeysState {
  switch (action.type) {
    case ActionTypes.FETCH_PUBLIC_KEYS_SUCCESS:
      return update(state, { $set: action.payload });
    default:
      return state;
  }
}
