import { browserHistory, type RouterState } from 'react-router';
import get from 'lodash/get';
import { toQueryBuilderFilterForm } from '../../features/utilities';
import ActionTypes from '../constants/action-types';
import createQueryBuilderFromExperienceFilterForm from './createQueryBuilderFromExperienceFilterForm';
import type {
  RootState,
  RootActionTypes,
  ThunkResult,
} from '../../../stores/types';

export default function pushLocationWithExperienceFilters(
  organization: string,
  location: RouterState['location'],
  filters: io.flow.v0.unions.QueryFilter[],
): ThunkResult<Promise<RootActionTypes | void>> {
  return function pushLocationWithExperienceFiltersSideEffects(
    dispatch,
    getState: () => RootState,
  ) {
    dispatch({ type: ActionTypes.LOCATION_CHANGE_UPDATE_QUERY_REQUEST });
    return Promise.all([
      createQueryBuilderFromExperienceFilterForm(
        getState(),
        organization,
        toQueryBuilderFilterForm(filters),
      ),
    ]).then(([queryBuilder]) => {
      browserHistory.push({
        pathname: location.pathname,
        query: { ...location.query, q: get(queryBuilder, 'q', '') },
      });
    }).catch((error) => {
      if (error.response) {
        return dispatch({
          type: ActionTypes.LOCATION_CHANGE_UPDATE_QUERY_FAILURE,
          payload: error.response.result,
        });
      }

      throw error;
    });
  };
}
