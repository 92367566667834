import capitalize from 'lodash/capitalize';
import { MarketingGatewayPlatform as Platform } from '@flowio/api-internal-constants';

export const GOOGLE_LOCALIZED_FEEDS_TITLE = 'Localized Feeds';
export const GOOGLE_LOCALIZED_FEEDS_SUBTITLE = 'Flow generates Google-specific localized product feeds with product data from your selected data source and localized data from your Flow Experience settings.';

export const GOOGLE_DATA_SOURCES_TITLE = 'Data Sources';
export const GOOGLE_DATA_SOURCES_SUBTITLE = 'Your data source includes product data for all products in your Flow product catalog. ';

export const FACEBOOK_PRODUCT_FEEDS_TITLE = 'Data Feed';
export const FACEBOOK_COUNTRY_FEEDS_TITLE = 'Country Feeds';

export const MANUAL_PRODUCT_FEEDS_TITLE = 'Product Data Source';
export const MANUAL_LOCALIZED_FEEDS_TITLE = 'Localized Product Feeds';

export const STEP_1_TITLE = (
  platform: io.flow.internal.v0.enums.MarketingGatewayPlatform,
): string => {
  switch (platform) {
    case Platform.GOOGLE:
      return '1. Choose a source feed';
    default:
      return '1. Build the main catalog feed';
  }
};

export const STEP_2_TITLE = (
  platform: io.flow.internal.v0.enums.MarketingGatewayPlatform,
): string => {
  switch (platform) {
    case Platform.GOOGLE:
      return '2. Choose your target countries';
    default:
      return '2. Add supplementary country information';
  }
};

export const STEP_1_SUBTITLE = (
  platform: io.flow.internal.v0.enums.MarketingGatewayPlatform,
): string => {
  switch (platform) {
    case Platform.GOOGLE:
      return 'Choose a feed to use as the source for your localized product feeds.';
    default:
      return `Choose an existing feed to use as the data source for your main catalog feed in ${capitalize(platform)}`;
  }
};

export const STEP_2_SUBTITLE = (
  platform: io.flow.internal.v0.enums.MarketingGatewayPlatform,
): string => {
  switch (platform) {
    case Platform.GOOGLE:
      return 'Create localized feeds for countries that you haven\'t already targeted with the selected source, have created a Flow experience for, and have a language set matching the target country.';
    default:
      return 'Choose target countries to create supplementary country information with localized data based on your Flow Experience settings.';
  }
};
