import assign from 'lodash/assign';
import OrganizationActionTypes from '../../../organization/constants/action-types';
import * as TokensActionTypes from '../../../tokens/constants';
import { TokenUiState } from '../../types/state';
import { RootActionTypes } from '../../../../stores/types';

const initialState: TokenUiState = {};

export default function tokenUiReducer(
  state = initialState,
  action: RootActionTypes,
): TokenUiState {
  switch (action.type) {
    case TokensActionTypes.FETCH_CLEAR_TEXT_KEY_SUCCESS:
      return assign({}, state, { cleartextTokenDialogId: action.id });
    case OrganizationActionTypes.CLOSE_CLEAR_TEXT_KEY_DIALOG:
      return assign({}, state, { cleartextTokenDialogId: undefined });
    default:
      return state;
  }
}
