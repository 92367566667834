import {
  ThunkDispatcher, ThunkResult,
} from '../../../stores/types';
import { server } from '../../../utilities/clients';
import { ActionTypes } from '../constants';
import isResponseOk from '../../../utilities/isResponseOk';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import { RegisterForm, AuthResponse } from '../../../utilities/clients/server/types';
import { PartialHttpResponse } from '../../../utilities/clients/api-error';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import formatErrorResponse from '../../../utilities/format-error-response';

export default function registerUser(formData: RegisterForm):
ThunkResult<Promise<LegacyResponse<AuthResponse | FormattedErrorMessages>>> {
  return (
    dispatch: ThunkDispatcher,
  ): Promise<LegacyResponse<AuthResponse | FormattedErrorMessages>> => {
    dispatch({
      type: ActionTypes.AUTH_REGISTER_USER_SUBMIT,
    });

    return server.auth.postRegister({
      body: JSON.stringify(formData),
    }).then((response) => {
      if (isResponseOk(response)) {
        dispatch({
          type: ActionTypes.AUTH_REGISTER_USER_COMPLETED,
          payload: response.result,
        });
        return response;
      }

      const httpResponse: PartialHttpResponse = {
        body: response.result,
        status: response.status,
      };

      dispatch({
        type: ActionTypes.AUTH_REGISTER_USER_ERROR,
        payload: formatErrorResponseV2(httpResponse),
      });

      return formatErrorResponse(response);
    });
  };
}
