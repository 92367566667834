import update from 'immutability-helper';
import { RootActionTypes } from '../../../stores/types';
import { ActionTypes, MAX_HS_RECORDS } from '../constants';
import { ClassificationState } from '../types';

const initialState: ClassificationState = {
  hs6: {
    error: null,
    results: [],
    isFirstPage: true,
    isLastPage: false,
    pageNumber: 1,
  },
  statistics: [],
  ui: {
    hs6: {},
  },
};

export default function (
  state: ClassificationState = initialState,
  action: RootActionTypes,
): ClassificationState {
  switch (action.type) {
    case ActionTypes.FETCH_ITEM_SUMMARIES_SUCCESS: {
    // if first page replace results, otherwise concatenate.
      const isFirstPage = (action.params.pageNumber || 1) === 1;
      const hs6Results: ClassificationState['hs6']['results'] = isFirstPage
        ? action.payload.results
        : (state.hs6.results || []).concat(action.payload.results);
      return update(state, {
        hs6: {
          results: {
            $set: hs6Results,
          },
          isFirstPage: { $set: action.payload.isFirstPage },
          isLastPage: { $set: action.payload.isLastPage },
        },
        ui: {
          hs6: {
            maxRecordsFetched: {
              $set: action.payload.isLastPage
              || hs6Results.length >= MAX_HS_RECORDS,
            },
          },
        },
      });
    }

    case ActionTypes.RESET_HS6_PAGINATION:
      return update(state, {
        hs6: {
          pageNumber: { $set: 1 },
        },
        ui: {
          hs6: {
            maxRecordsFetched: { $set: false },
          },
        },
      });
    case ActionTypes.FETCH_HARMONIZATION_STATISTICS_SUCCESS:
      return { ...state, statistics: action.payload };

    case ActionTypes.EXPORT_HS6_SUCCESS:
      return update(state, { ui: { $merge: { hs6: { exportSuccessful: true } } } });

    case ActionTypes.NEXT_HARMONIZATION_HS6_CODES_PAGE: {
      const { isLastPage } = state.hs6;
      const currentPage = state.hs6.pageNumber;
      return update(state, {
        hs6: {
          pageNumber: {
            $set: isLastPage ? currentPage : currentPage + 1,
          },
        },
      });
    }
    default:
      return state;
  }
}
