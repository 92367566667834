import Client from './client';

export default class RestrictionRules extends Client {
  constructor(opts) {
    let options = opts;

    if (typeof opts === 'string') {
      options = { host: opts }; // convert host string to options object
    }

    options.serviceName = 'API Internal';

    super(options);
  }

  get(options = {}) {
    return this.makeRequest(`${this.host}/jailhouse/restriction/rules`, options);
  }

  getById(id, options = {}) {
    return this.makeRequest(`${this.host}/jailhouse/restriction/rules/${id}`, options);
  }

  getByOrganizationAndStatus(organization, status, options = {}) {
    return this.makeRequest(`${this.host}/jailhouse/restriction/rules/${organization}/${status}`, options);
  }

  postInternalAndRestrictionAndRules(options = {}) {
    return this.makeRequest(`${this.host}/internal/restriction/rules`, {
      ...options,
       method: 'POST',
    });
  }

  putInternalAndRestrictionAndRulesById(id, options = {}) {
    return this.makeRequest(`${this.host}/internal/restriction/rules/${id}`, {
      ...options,
       method: 'PUT',
    });
  }

}
