import React from 'react';
import noop from 'lodash/noop';
import { BackToTop } from '@flowio/react-icons';
import { css } from '@emotion/css';
import classnames from 'classnames';
import { colors } from '../../../../theme/tokens';

const backToTopButtonStyles = css`
  border: solid 1px ${colors.silver};
  border-radius: 100px;
  background-color: ${colors.gray[100]};
  cursor: pointer;
  height: 48px;
  width: 48px;
`;

interface Props {
  className: string;
  onClick: React.MouseEventHandler;
}

const BackToTopButton: React.FC<Props> = ({ className, onClick }) => (
  <button className={classnames(backToTopButtonStyles, className)} type="button" onClick={onClick}>
    <BackToTop height={24} fill={colors.charcoal} />
  </button>
);

BackToTopButton.displayName = 'BackToTopButton';
BackToTopButton.defaultProps = {
  className: '',
  onClick: noop,
};

export default BackToTopButton;
