import { MarketingGatewayFeedFormsPostResponse } from '@flowio/api-internal-sdk';
import { $HttpResponse } from '@flowio/api-sdk';
import ActionTypes from '../constants/ActionTypes';
import standardAction from '../../../utilities/standard-action';
import { ApiError, isApiError } from '../../../utilities/clients/api-error';
import {
  RootState, ThunkExtraArguments, ThunkResult, ThunkDispatcher,
} from '../../../stores/types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';

interface Props {
  organizationId: string;
  schemaCompatibilities: io.flow.internal.v0.enums.MarketingGatewaySchemaCompatibility[];
  feedSource: io.flow.internal.v0.unions.MarketingGatewayFeedSource;
}

function fetchFeedForms({
  organizationId,
  schemaCompatibilities,
  feedSource,
}: Props): ThunkResult<Promise<void>> {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<void> => {
    dispatch(standardAction(ActionTypes.FETCH_FEED_FORMS_REQUEST));

    const feedFormsRequestResource = extra.apiInternal(getState()).marketingGatewayFeedForms;

    const requests: Promise<MarketingGatewayFeedFormsPostResponse>[] = schemaCompatibilities.map(
      (sc) => feedFormsRequestResource.post({
        organization: organizationId,
        body: {
          schema_compatibility: sc,
          feed_source: feedSource,
        },
      }),
    );

    return Promise.all(requests)
      .then((responses) => {
        try {
          const feedForms: Record<
          io.flow.internal.v0.enums.MarketingGatewaySchemaCompatibility,
          io.flow.internal.v0.models.MarketingGatewayFeedForm[]
          > = responses.reduce((acc, response, index) => {
            if (response.ok) {
              acc[schemaCompatibilities[index]] = response.body;
              return acc;
            }

            const errorResponse = response as $HttpResponse<io.flow.v0.models.GenericError>;
            const error = new ApiError(errorResponse);
            throw error;
          }, {} as Record<io.flow.internal.v0.enums.MarketingGatewaySchemaCompatibility,
          io.flow.internal.v0.models.MarketingGatewayFeedForm[]>);
          dispatch(standardAction(ActionTypes.FETCH_FEED_FORMS_SUCCESS, feedForms));
          return;
        } catch (error) {
          if (isApiError(error)) {
            dispatch({
              type: ActionTypes.FETCH_FEED_FORMS_FAILURE,
              payload: formatErrorResponseV2(error.response),
            });
          }
          throw error;
        }
      });
  };
}

export default fetchFeedForms;
