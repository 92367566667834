import {
  Edit,
  Refund,
  Return,
} from '@flowio/react-icons';
import React, { Component, ReactElement } from 'react';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { OrganizationType } from '@flowio/api-constants';
import map from 'lodash/map';
import matchesProperty from 'lodash/matchesProperty';
import noop from 'lodash/noop';
import overEvery from 'lodash/overEvery';
import partition from 'lodash/partition';
import { Worksheet, WorksheetHeader, WorksheetBody } from '@flowio/react-worksheet';
import {
  Dialog, DialogFooter, DialogBody, DialogActions,
} from '@flowio/react-dialog';
import {
  Card, CardHeader, CardContent, CardFooter, CardEmptyState, CardTitle, CardDescriptionShorthand,
} from '@flowio/react-card';
import { Box } from '@flowio/react-box';
import { Button } from '@flowio/react-button';
import { Page } from '@flowio/react-page';
import classNames from 'classnames';
import { isNil, isNotNil } from '@flowio/is';
import { OrderValidationStatus } from '@flowio/api-internal-constants';
import { colors } from '@flowio/react-theme';
import { startCase } from 'lodash';
import EditShippingAddressForm from '../../containers/EditShippingAddressForm';

import orderAddressFromAuthorization from '../../utilities/converters';
import Alert from '../../../../components/alert';
import OrderCustomer from '../order-customer';
import OrderItems from '../../containers/order-items';
import OrderPayment from '../order-payment';
import OrderReturnDetailsDialog from '../order-return-details-dialog';
import OrderRefundDialog from '../OrderRefundDialog';
import OrderReturnFormDialog from '../order-return-form-dialog';
import OrderReturns from '../order-returns';
import OrderShipping from '../order-shipping';
import OrderTracking from '../order-tracking';
import OrderInvoices from '../order-invoices';
import OrderTrackingDetailsDialog from '../order-tracking-details-dialog';
import OrderFraudStatus from '../order-fraud-status';
import FeatureValueSelector from '../../../console/components/FeatureValueSelector/FeatureValueSelector';
import FeatureKey from '../../../console/constants/FeatureKey';
import {
  OrderDetailProps,
} from '../../types';
import OrderNotes from '../OrderNotes';
import OrderDetailsHeader from './OrderDetailsHeader';
import ReplaceOrderButton from './ReplaceOrderButton';
import OrderDetailsOverview from './OrderDetailsOverview';
import * as styles from './order-details.styles';
import DebugTransactionsTable from './debug-transactions-table';

const SYSTEM_USER_ID = 'usr-20151006-1';

const defaultProps = {
  onEditShippingAddressEdit: noop,
  onRequestDismissNotificationMessage: noop,
  onRequestHideRefundFormDialog: noop,
  onRequestShowRefundFormDialog: noop,
  onRequestHideReturnFormDialog: noop,
  onRequestShowReturnFormDialog: noop,
  onRequestShowReturnDetailDialog: noop,
  onRequestHideReturnDetailDialog: noop,
  onRequestHideorderTrackingDetailsDialog: noop,
  onRequestShowOrderTrackingDetailsDialog: noop,
  onRequestHideApproveFraudReviewDialog: noop,
  onRequestShowApproveFraudReviewDialog: noop,
  onRequestHideDeclineFraudReviewDialog: noop,
  onRequestShowDeclineFraudReviewDialog: noop,
  onRequestShowReturnNumberInput: noop,
  onRequestHideReturnNumberInput: noop,
  onRequestOrganizationFraudReviewApprove: noop,
  onRequestOrganizationFraudReviewDecline: noop,
  onSubmitRefund: noop,
  onCopyAuthorizationIdentifier: noop,
  isCustomerServiceUser: true,
  isFlowEmployee: false,
  returnShippingTiers: [],
  notificationMessage: undefined,
  refundDialogState: undefined,
  returnDialogState: {
    showReturnFormDialog: false,
  },
  approveFraudReviewDialogState: undefined,
  declineFraudReviewDialogState: undefined,
  returnConfirmationState: undefined,
  debugTransactions: undefined,
  orderTrackingDetails: {
    showOrderTrackingDetailsDialog: false,
    orderNumber: undefined,
    labelSummary: undefined,
  },
  returnDetailState: {
    showReturnDetailsDialog: false,
    showReturnNumberState: false,
    items: [],
    pdfLabel: '',
    pdfInvoice: '',
  },
  refundOrderSummary: undefined,
  refundFormErrors: {},
  originalShippingAddress: undefined,
};

type State = {
  addressValidationMsg: CardDescriptionShorthand | undefined;
};

export default class OrderDetails extends Component<OrderDetailProps, State> {
  static displayName = 'OrderDetails';

  static defaultProps = defaultProps;

  constructor(props: OrderDetailProps) {
    super(props);
    this.handleEditShippingAddressEdit = this.handleEditShippingAddressEdit.bind(this);
  }

  componentDidMount(): void {
    const {
      location,
      router,
      isOrderAddressValidationEnabled,
      debugTransactions,
      organization,
      orderDetail: {
        order,
      },
      onRequestOrderAddressValidation,
    } = this.props;

    console.log(debugTransactions, 'debugTransactions');
    if (location.hash.substring(1) === 'open-shipping-address') {
      this.handleEditShippingAddressEdit();
      router.replace({
        ...location,
        hash: '',
      });
    }
    if (isOrderAddressValidationEnabled) {
      onRequestOrderAddressValidation(organization.id, order.number);
    }
  }

  static getDerivedStateFromProps(nextProps: OrderDetailProps): State {
    const {
      organization,
      orderDetail: {
        order,
      },
      addressValidation,
      addressValidationUser,
      isOrderAddressValidationEnabled,
    } = nextProps;

    const getAddressValidationMessageColor = () => {
      if (isNil(addressValidation)) {
        return colors.FLOW_BLUE_300;
      }
      switch (addressValidation.status) {
        case OrderValidationStatus.SUCCESS:
        case OrderValidationStatus.LABEL_CREATED:
          return colors.GREEN_300;
        default:
          return colors.RED_300;
      }
    };

    return {
      addressValidationMsg: (
        isOrderAddressValidationEnabled
        && isNotNil(addressValidation)
        && addressValidation.organization_id === organization.id
        && addressValidation.order_number === order.number
        && isNotNil(addressValidationUser)
        && addressValidation.status !== OrderValidationStatus.FAILED
        && !(
          addressValidation.status === OrderValidationStatus.SUCCESS
            && addressValidation.updated_by_user_id === SYSTEM_USER_ID
        )
      )
        ? (
          <span style={{ color: getAddressValidationMessageColor() }}>
            Status: {startCase(addressValidation.status)}, updated by: {addressValidationUser.email}
          </span>
        )
        : undefined,
    };
  }

  handleEditShippingAddressEdit(): void {
    const {
      addressValidation,
      addressValidationUser,
      orderDetail: {
        order,
      },
      organization,
      onEditShippingAddressEdit,
    } = this.props;

    const {
      addressValidationMsg,
    } = this.state;

    onEditShippingAddressEdit(
      order.destination,
      organization.id,
      order.number,
      addressValidation,
      addressValidationUser,
      addressValidationMsg,
    );
  }

  handleRefundButtonPressed = (): void => {
    const { onRequestShowRefundFormDialog } = this.props;
    onRequestShowRefundFormDialog();
  };

  handleReturnButtonPressed = (): void => {
    const { onRequestShowReturnFormDialog, organization, orderDetail } = this.props;
    const experienceKey = orderDetail.order.experience ? orderDetail.order.experience.key : '';
    onRequestShowReturnFormDialog(organization.id, experienceKey);
  };

  handleFraudReviewApproveButtonPressed = (): void => {
    const { onRequestOrganizationFraudReviewApprove, orderDetail } = this.props;
    const fraudReview = this.getFraudReview(orderDetail.transactions);
    onRequestOrganizationFraudReviewApprove(fraudReview.organization_id, fraudReview.id);
  };

  handleFraudReviewDeclineButtonPressed = (): void => {
    const { onRequestOrganizationFraudReviewDecline, orderDetail } = this.props;
    const fraudReview = this.getFraudReview(orderDetail.transactions);
    onRequestOrganizationFraudReviewDecline(fraudReview.organization_id, fraudReview.id);
  };

  handleViewReturnItems = (
    returnItemsRef: io.flow.v0.models.ReturnLineItem[],
    pdfLabel: string,
    pdfInvoice: string,
  ): void => {
    const { orderDetail, onRequestShowReturnDetailDialog } = this.props;
    const orderItems = orderDetail.order.items;
    const fullReturnItems = orderItems.map((orderItem) => {
      const returnItem = returnItemsRef.find((retItem) => retItem.item_number === orderItem.number);

      return {
        number: orderItem.number,
        name: orderItem.name,
        quantity: returnItem ? returnItem.quantity : 0,
        maxQuantity: orderItem.quantity,
      };
    }).sort((a, b) => (a >= b ? -1 : 1));
    onRequestShowReturnDetailDialog(fullReturnItems, pdfLabel, pdfInvoice);
  };

  getFraudSummary = (transactions: io.flow.internal.v0.unions.TransactionSummary[]): io.flow.internal.v0.models.FraudSummary => transactions.find((transaction) => transaction.discriminator === 'fraud_summary') as io.flow.internal.v0.models.FraudSummary;

  getFraudReview = (transactions: io.flow.internal.v0.unions.TransactionSummary[]): io.flow.internal.v0.models.FraudReview => get(this.getFraudSummary(transactions), 'fraud_review');

  getFraudPendingReview = (transactions: io.flow.internal.v0.unions.TransactionSummary[]): io.flow.internal.v0.models.FraudPendingReview | undefined => get(this.getFraudSummary(transactions), 'fraud_pending_review');

  getBillingAddress(): io.flow.v0.models.BillingAddress {
    const { orderDetail } = this.props;
    const { transactions } = orderDetail;

    let billingAddress;

    // Attempt to find billing address in payment authorizations.
    const paymentSummaries = filter(transactions, overEvery([
      matchesProperty('discriminator', 'payment_summary_v2'),
      matchesProperty('details.discriminator', 'search_authorization'),
    ]));

    const authorizations = map(paymentSummaries, 'details');

    const [
      successfulAuthorizations,
      unsuccessfulAuthorizations,
    ] = partition(authorizations, matchesProperty('result.status', 'authorized'));

    if (successfulAuthorizations.length) {
      billingAddress = orderAddressFromAuthorization(successfulAuthorizations[0]);
    } else if (unsuccessfulAuthorizations.length) {
      billingAddress = orderAddressFromAuthorization(unsuccessfulAuthorizations[0]);
    }
    return billingAddress;
  }

  getOrderPayments = (
    paymentTransactions: io.flow.internal.v0.models.PaymentSummaryV2[],
    order: io.flow.experience.v0.models.Order,
  ): JSX.Element => {
    const { isFlowEmployee, onCopyAuthorizationIdentifier } = this.props;
    const isManualPayment = (p: io.flow.experience.v0.models.OrderPayment) => p.reference === 'manual';
    const renderPayment = (p: io.flow.experience.v0.models.OrderPayment) => (
      <p>Manual payment recorded for this order. Id: {p.id}</p>
    );

    if (isEmpty(paymentTransactions) && order.payments?.find(isManualPayment)) {
      return (
        <Alert fullWidth={false} type="info"> {
          order.payments?.filter(isManualPayment).map(renderPayment)
        }
        </Alert>
      );
    }
    if (!isEmpty(paymentTransactions)) {
      return (
        <OrderPayment
          isFlowEmployee={isFlowEmployee}
          payments={paymentTransactions}
          onCopyAuthorizationIdentifier={onCopyAuthorizationIdentifier}
        />
      );
    }
    return (<CardEmptyState content="No payment information available." />);
  };

  render(): ReactElement {
    const {
      addressValidation,
      addressValidationUser,
      allocations,
      businessCreditMemos,
      businessInvoices,
      consumerInvoices,
      creditMemos,
      isCustomerServiceUser,
      isFlowEmployee,
      notificationMessage,
      onRequestDismissNotificationMessage,
      onRequestHideRefundFormDialog,
      onRequestHideReturnFormDialog,
      onRequestHideReturnDetailDialog,
      onRequestHideorderTrackingDetailsDialog,
      onRequestShowOrderTrackingDetailsDialog,
      onRequestHideApproveFraudReviewDialog,
      onRequestShowApproveFraudReviewDialog,
      onRequestHideDeclineFraudReviewDialog,
      onRequestShowDeclineFraudReviewDialog,
      onRequestHideReturnNumberInput,
      onRequestShowReturnNumberInput,
      onSubmitRefund,
      orderTrackingDetails,
      returnConfirmationState,
      returnDetailState,
      orderDetail: {
        order,
        returns,
        transactions,
        trackings,
        created_at: createdAt,
        shopify_order_id: shopifyOrderId,
      },
      originalShippingAddress,
      organization,
      refundFormErrors,
      refundOrderSummary,
      returnShippingTiers,
      refundDialogState,
      debugTransactions,
      returnDialogState,
      declineFraudReviewDialogState,
      approveFraudReviewDialogState,
      shippingNotifications,
      userEmail,
    } = this.props;
    const { addressValidationMsg } = this.state;
    const paymentTransactions: io.flow.internal.v0.models.PaymentSummaryV2[] = transactions.filter((payment): payment is io.flow.internal.v0.models.PaymentSummaryV2 => payment.discriminator === 'payment_summary_v2');
    const billingAddress = this.getBillingAddress();
    const hasCaptured = refundOrderSummary && refundOrderSummary.captured > 0;
    const amountRefunded = refundOrderSummary ? refundOrderSummary.refunded : 0;
    const fraudReview = this.getFraudReview(transactions);
    const hasCustomerInfo = (
      Object.keys(order.customer.name).length > 0
      || order.customer.email
      || order.customer.phone
    );

    console.log(debugTransactions, 'debugTransactions');

    const isChannelOrg = organization.type === OrganizationType.CHANNEL;
    const invoicesEmpty = isEmpty(businessInvoices)
      && isEmpty(consumerInvoices)
      && isEmpty(creditMemos);

    const isShippingAddressUnchanged = isEqual(originalShippingAddress, order.destination)
      || !originalShippingAddress;

    return (
      <Page>
        {isEmpty(notificationMessage) ? null : (
          <Alert fullWidth dismissible onRequestDismiss={onRequestDismissNotificationMessage}>
            <p>
              {notificationMessage}
            </p>
          </Alert>
        )}
        <OrderDetailsHeader
          order={order}
          organizationId={organization.id}
        >{!isChannelOrg && (
          <Button
            content="Issue Refund"
            intent="primary"
            disabled={!hasCaptured}
            leftIcon={Refund}
            onClick={() => this.handleRefundButtonPressed()}
            variant="outline"
          />
        )}
          <Button
            content="Create Return"
            intent="primary"
            disabled={!order.submitted_at}
            leftIcon={Return}
            onClick={() => this.handleReturnButtonPressed()}
            variant="outline"
          />
          <ReplaceOrderButton order={order} organizationId={organization.id} />
        </OrderDetailsHeader>

        <OrderDetailsOverview
          order={order}
          createdAt={createdAt}
          organizationId={organization.id}
          showExperienceLink={isCustomerServiceUser !== true}
          refundOrderSummary={refundOrderSummary}
          shopifyOrderId={shopifyOrderId}
        />

        <OrderNotes
          orderNumber={order.number}
          organizationId={organization.id}
        />

        <Card>
          <CardHeader dividing>
            <CardTitle content="Customer Info" />
          </CardHeader>
          <CardContent>
            {hasCustomerInfo ? (
              <OrderCustomer customer={order.customer} />
            ) : (
              <CardEmptyState content="No customer info provided" />
            )}
          </CardContent>
          <CardFooter />
        </Card>

        <Card>
          <CardHeader
            caption="Shipping Address"
            description={addressValidationMsg}
            actions={[{
              onClick: this.handleEditShippingAddressEdit,
              variant: 'outline',
              leftIcon: Edit,
              intent: 'primary',
              content: 'Update',
            }]}
            dividing
          />
          <CardContent>
            {!isEmpty(order.destination) ? (
              <OrderShipping
                destination={order.destination}
                isShippingAddressUnchanged={isShippingAddressUnchanged}
                originalAddress={originalShippingAddress}
              />
            ) : (
              <CardEmptyState content="No shipping address provided" />
            )}
          </CardContent>
          <CardFooter />
        </Card>

        <Card>
          <CardHeader dividing>
            <CardTitle content="Billing Address" />
          </CardHeader>
          <CardContent>
            {!isEmpty(billingAddress) ? (
              <OrderShipping destination={billingAddress} />
            ) : (
              <CardEmptyState content="No billing address provided" />
            )}
          </CardContent>
          <CardFooter />
        </Card>

        <Card>
          <CardHeader dividing>
            <CardTitle content="Items &amp; Deliveries" />
          </CardHeader>
          <CardContent style={{ padding: 0 }}>
            <OrderItems
              allocations={allocations}
              organization={organization}
              order={order}
            />
          </CardContent>
          <CardFooter />
        </Card>

        <Card>
          <CardHeader dividing>
            <CardTitle content="Fraud Protection" />
          </CardHeader>
          <CardContent>
            <OrderFraudStatus
              status={get(fraudReview, 'status')}
              lastUpdated={get(fraudReview, 'status_updated_at')}
              description={get(fraudReview, 'description')}
              riskEvaluation={get(fraudReview, 'risk_evaluation')}
              organization={get(fraudReview, 'organization_id')}
              responsibleParty={get(fraudReview, 'responsible_party')}
              referenceId={get(fraudReview, 'id')}
              isFlowEmployee={isFlowEmployee}
              pendingReview={!isEmpty(this.getFraudPendingReview(transactions))}
              handleApprove={onRequestShowApproveFraudReviewDialog}
              handleDecline={onRequestShowDeclineFraudReviewDialog}
            />
          </CardContent>
          <CardFooter />
        </Card>

        <FeatureValueSelector
          featureKey={FeatureKey.GLOBAL_GIFT_MESSAGING}
          render={({ isFeatureEnabled }): ReactElement => {
            if (isFeatureEnabled) {
              return (
                <Card>
                  <CardHeader dividing>
                    <CardTitle content="Gift Message" />
                  </CardHeader>
                  <CardContent>
                    <Box>
                      <span className={styles.label}>Message Content</span>
                      <div className={classNames(styles.giftMessage, {
                        [styles.giftMessageEmpty]: !get(order, 'attributes.gift_message'),
                      })}
                      >
                        {get(order, 'attributes.gift_message', 'No Message provided')}
                      </div>
                    </Box>
                  </CardContent>
                </Card>
              );
            }
            return (<div />);
          }}
        />
        <Card>
          <CardHeader
            caption="Payment Activities"
            actions={[{
              content: 'Issue Refund',
              disabled: !hasCaptured,
              intent: 'primary',
              leftIcon: Refund,
              onClick: this.handleRefundButtonPressed,
              variant: 'outline',
            }]}
            dividing
          />
          <CardContent
            style={{ paddingLeft: 0, paddingRight: 0 }}
            fitted={isEmpty(transactions)}
            className={styles.paymentCard}
          >
            {
              this.getOrderPayments(paymentTransactions, order)
            }

          </CardContent>
        </Card>

        <Card>
          <CardHeader
            caption="Payment Status"
            dividing
          />
          <CardContent
            style={{ paddingLeft: 0, paddingRight: 0 }}
            fitted={isEmpty(transactions)}
            className={styles.paymentCard}
          >
            {debugTransactions && (
              <DebugTransactionsTable debugTransactions={debugTransactions} />
            )}
          </CardContent>
        </Card>
        <Card>
          <CardHeader dividing>
            <CardTitle content="Shipments" />
          </CardHeader>
          <CardContent className={styles.card} fitted={isEmpty(trackings)}>
            {isEmpty(trackings) && isEmpty(shippingNotifications) ? (
              <CardEmptyState content="No tracking information available." />
            ) : (
              <OrderTracking
                orderNumber={order.number}
                trackings={trackings}
                shippingNotifications={shippingNotifications}
                onShowTrackingDetail={onRequestShowOrderTrackingDetailsDialog}
              />
            )}
          </CardContent>
        </Card>
        <Card>
          <CardHeader dividing>
            <CardTitle content="Returns" />
          </CardHeader>
          <CardContent className={styles.card} fitted={isEmpty(returns)}>
            {isEmpty(returns) ? (
              <CardEmptyState content="No return information available." />
            ) : (
              <OrderReturns returns={returns} onViewItems={this.handleViewReturnItems} />
            )}
          </CardContent>
        </Card>
        <Card>
          <CardHeader dividing>
            <CardTitle content="Invoices" />
          </CardHeader>
          <CardContent
            className={styles.invoiceCard}
            fitted={invoicesEmpty}
          >
            {invoicesEmpty ? (
              <CardEmptyState content="No invoice information available." />
            ) : (
              <OrderInvoices
                businessCreditMemos={businessCreditMemos}
                businessInvoices={businessInvoices}
                consumerInvoices={consumerInvoices}
                creditMemos={creditMemos}
                customerName={order.customer.name}
              />
            )}
          </CardContent>
        </Card>
        <EditShippingAddressForm
          countryCode={get(order, 'destination.country')}
          order={order}
          organizationId={organization.id}
          addressValidation={addressValidation}
          addressValidationUser={addressValidationUser}
          addressValidationMsg={addressValidationMsg}
        />
        <OrderReturnFormDialog
          isOpen={returnDialogState && returnDialogState.showReturnFormDialog}
          returnNumberState={returnDetailState ? returnDetailState.showReturnNumberState : false}
          onRequestHideReturnNumber={onRequestHideReturnNumberInput}
          onRequestShowReturnNumber={onRequestShowReturnNumberInput}
          items={order.items}
          orderNumber={order.number}
          shippingTiers={returnShippingTiers}
          userEmail={userEmail || ''}
          customerEmail={order.customer.email}
          returnConfirmationState={returnConfirmationState}
          onRequestCancel={onRequestHideReturnFormDialog}
        />
        <Worksheet
          style={{ paddingTop: 0 }}
          backdrop
          closeOnEscape
          open={returnDetailState && returnDetailState.showReturnDetailsDialog}
          onClose={onRequestHideReturnDetailDialog}
        >
          <WorksheetHeader
            content="Return Details"
            rightDecoration={<Button content="Close" size="medium" onClick={onRequestHideReturnDetailDialog} />}
          />
          <WorksheetBody>
            <OrderReturnDetailsDialog
              items={returnDetailState ? returnDetailState.items : []}
              pdfLabel={returnDetailState ? returnDetailState.pdfLabel : ''}
              pdfInvoice={returnDetailState ? returnDetailState.pdfInvoice : ''}
            />
          </WorksheetBody>
        </Worksheet>
        <Worksheet
          style={{ paddingTop: 0 }}
          backdrop
          open={get(orderTrackingDetails, 'showOrderTrackingDetailsDialog')}
        >
          <WorksheetHeader
            content={isNotNil(orderTrackingDetails?.shippingNotification) ? 'Shipment Notification Details' : 'Shipment Details'}
            rightDecoration={<Button content="Close" size="medium" onClick={onRequestHideorderTrackingDetailsDialog} />}
          />
          <WorksheetBody>
            <OrderTrackingDetailsDialog
              fullItems={order.items}
              organizationId={organization.id}
              orderTrackingDetails={orderTrackingDetails}
            />
          </WorksheetBody>
        </Worksheet>
        <OrderRefundDialog
          formErrors={refundFormErrors}
          amountRefunded={amountRefunded}
          isOpen={refundDialogState ? refundDialogState.showRefundFormDialog : false}
          allocations={allocations}
          onSubmit={onSubmitRefund}
          onRequestCancel={onRequestHideRefundFormDialog}
        />
        <Dialog
          backdrop
          className={styles.fraudDialog}
          open={!!declineFraudReviewDialogState}
        >
          <DialogBody>
            <p>
              {
                `Decline Order #${order.number}? Once the order is declined,
                this decision cannot be reversed.`
              }
            </p>
          </DialogBody>
          <DialogFooter>
            <DialogActions>
              <Button
                content="Cancel"
                className={styles.fraudAction}
                onClick={onRequestHideDeclineFraudReviewDialog}
                intent="neutral"
              />
              <Button
                content="Decline"
                className={styles.fraudAction}
                onClick={this.handleFraudReviewDeclineButtonPressed}
                intent="negative"
              />
            </DialogActions>
          </DialogFooter>
        </Dialog>
        <Dialog
          backdrop
          className={styles.fraudDialog}
          open={!!approveFraudReviewDialogState}
        >
          <DialogBody>
            <p>
              {
                `Approve Order #${order.number}? Once the order is declined,
                this decision cannot be reversed.`
              }
            </p>
          </DialogBody>
          <DialogFooter>
            <DialogActions>
              <Button
                content="Cancel"
                className={styles.fraudAction}
                onClick={onRequestHideApproveFraudReviewDialog}
                intent="neutral"
              />
              <Button
                content="Approve"
                className={styles.fraudAction}
                onClick={this.handleFraudReviewApproveButtonPressed}
                intent="primary"
              />
            </DialogActions>
          </DialogFooter>
        </Dialog>
      </Page>
    );
  }
}
