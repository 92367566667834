import { createSelector } from 'reselect';
import get from 'lodash/get';
import identity from 'lodash/identity';
import { RootState } from '../../../stores/types';
import { AnalyticsState } from '../types';

export default createSelector<RootState, RootState, AnalyticsState['destinationCountries']['data']>(
  identity,
  (state) => get(state, 'analytics.destinationCountries.data'),
);
