export function getZigZagReturnsUrl(organization) {
  switch (organization) {
    case 'fashionnova-scrape':
    case 'fashionnova-demo':
      return 'https://zigzag-adminportal-demo2.azurewebsites.net/';
    default:
      return '';
  }
}

export function getZigZagReturnsPortal(organization) {
  switch (organization) {
    case 'fashionnova-scrape': // uses default;
    default:
      return 'https://flow.zigzag-demo.co.uk/';
  }
}
