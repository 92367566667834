import { css } from '@emotion/css';

export const shippingTiers = css`
  margin-bottom: 20px;
`;

export const shippingLabels = css`
  display: block;
  margin: 10px 0;
`;

export const shippingTiersRadioButton = css`
  & > label {
    font-size: 16px !important;
    padding: 10px 10px 0 44px;
    display: block;
  }

  & > label > span {
    &::before {
      left: 0;
      top: 6px;
    }
  }
`;

export const shippingCentersRadioButton = css`
  & > label {
    font-size: 16px !important;
    padding: 10px 10px 0 44px;
    display: block;
  }

  & > label > span {
    &::before {
      left: 0;
      top: 6px;
    }
  }
`;
