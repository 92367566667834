enum ActionTypes {
  GET_CATALOG_SUGGESTION_REQUEST = 'GET_CATALOG_SUGGESTION_REQUEST',
  GET_CATALOG_SUGGESTION_SUCCESS = 'GET_CATALOG_SUGGESTION_SUCCESS',
  GET_CATALOG_SUGGESTION_FAILURE = 'GET_CATALOG_SUGGESTION_FAILURE',
  FETCH_CATALOG_ITEM_REQUEST = 'FETCH_CATALOG_ITEM_REQUEST',
  FETCH_CATALOG_ITEM_SUCCESS = 'FETCH_CATALOG_ITEM_SUCCESS',
  FETCH_CATALOG_ITEM_FAILURE = 'FETCH_CATALOG_ITEM_FAILURE',
  FETCH_CATALOG_ITEMS_REQUEST = 'FETCH_CATALOG_ITEMS_REQUEST',
  FETCH_CATALOG_ITEMS_SUCCESS = 'FETCH_CATALOG_ITEMS_SUCCESS',
  FETCH_CATALOG_ITEMS_FAILURE = 'FETCH_CATALOG_ITEMS_FAILURE',
  GET_ATTRIBUTES_REQUEST = 'GET_ATTRIBUTES_REQUEST',
  GET_ATTRIBUTES_SUCCESS = 'GET_ATTRIBUTES_SUCCESS',
  GET_ATTRIBUTES_FAILURE = 'GET_ATTRIBUTES_FAILURE',
  FETCH_ITEM_HARMONIZATION_REQUEST = 'FETCH_ITEM_HARMONIZATION_REQUEST',
  FETCH_ITEM_HARMONIZATION_SUCCESS = 'FETCH_ITEM_HARMONIZATION_SUCCESS',
  FETCH_ITEM_HARMONIZATION_FAILURE = 'FETCH_ITEM_HARMONIZATION_FAILURE',
  SET_SELECTED_CLASSIFICATION_COUNTRIES = 'SET_SELECTED_CLASSIFICATION_COUNTRIES',
  SHOW_ALL_TARIFF_CODES = 'SHOW_ALL_TARIFF_CODES',
  HIDE_ALL_TARIFF_CODES = 'HIDE_ALL_TARIFF_CODES',
  CHANGED_CATALOG_SEARCH = 'CHANGED_CATALOG_SEARCH',
}

export default ActionTypes;
