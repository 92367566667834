import React, {
  useCallback, useState, useEffect, MouseEventHandler,
} from 'react';
import partition from 'lodash/partition';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  DroppableProvided,
} from 'react-beautiful-dnd';
import { css } from '@emotion/react';
import { Banner } from '@flowio/react-banner';
import { Worksheet, WorksheetHeader, WorksheetBody } from '@flowio/react-worksheet';
import { Button } from '@flowio/react-button';

import { DroppableTypes, SHOW_NUMBER_OF_CCS } from '../../constants/PaymentMethods';
import { isDisplayedPredicate } from '../PaymentMethodRulesDisplayOrder/PaymentMethodRulesDisplayOrder';
import { setPaymentMethodDisplayState, sortByDisplayThenPosition } from '../../utilities/payment-method-rules';
import * as styles from './CreditCardDisplayOrder.styles';

interface Props {
  creditCards: io.flow.v0.models.PaymentMethodRule[];
  experience: io.flow.v0.models.Experience;
  handleCCOrderClose: MouseEventHandler;
  handleCreditCardsReorder: Function;
  isOpen: boolean;
}

const worksheetStyles = css({
  transform: 'none',
});

const warningBannerStyles = css({
  marginTop: '1rem',
});

const CreditCardsDisplayOrder: React.FC<Props> = ({
  creditCards,
  experience,
  handleCCOrderClose,
  handleCreditCardsReorder,
  isOpen,
}) => {
  const [cards, setCards] = useState(creditCards.sort(sortByDisplayThenPosition));
  const [draggingType, setDraggingType] = useState();

  useEffect(() => {
    setCards(creditCards.sort(sortByDisplayThenPosition));
  }, [creditCards]);

  let shownCardsFound = 0;

  const [ccsShown, ccsAvail] = partition(
    cards, (card) => {
      const isShown = card.tags.some(isDisplayedPredicate);
      if (isShown) {
        shownCardsFound += 1;
      }
      return shownCardsFound <= SHOW_NUMBER_OF_CCS && isShown;
    },
  );

  const handleDragStart = useCallback((
    dragResult,
  ) => {
    setDraggingType(dragResult.source.droppableId);
  }, []);

  const handleDrop = useCallback((
    dropResult,
  ) => {
    const {
      destination: dest,
      source,
    } = dropResult;

    if (dest != null) {
      if (dest.index === source.index
        && dest.droppableId === source.droppableId) {
        // dragged to the same location.. no nothing
        return;
      }

      const sourceIsVisible = source.droppableId === DroppableTypes.CREDIT_CARDS_SHOWN;
      const sourceIsAvail = source.droppableId === DroppableTypes.CREDIT_CARDS_AVAIL;
      const destIsAvail = dest.droppableId === DroppableTypes.CREDIT_CARDS_AVAIL;

      const sourceIndex = sourceIsAvail ? source.index + ccsShown.length : source.index;
      const normalisedDestIndex = destIsAvail ? dest.index + ccsShown.length : dest.index;
      const destIndex = sourceIsVisible && destIsAvail
        ? normalisedDestIndex - 1 : normalisedDestIndex;

      const display = dest.droppableId === DroppableTypes.CREDIT_CARDS_SHOWN;
      const cardsCopy = [...cards];
      const itemMoving = cardsCopy.splice(sourceIndex, 1);
      cardsCopy.splice(
        destIndex,
        0,
        setPaymentMethodDisplayState(itemMoving[0], display),
      );
      setCards(cardsCopy.map((card, i) => ({
        ...card,
        display_position: i,
      })));
    }
  }, [cards, ccsShown.length]);

  function getStyle(
    style: React.CSSProperties | undefined,
    snapshot: DraggableStateSnapshot,
  ): React.CSSProperties {
    if (!snapshot.isDropAnimating) {
      return style || {};
    }
    return {
      ...style,
      // cannot be 0, but make it super tiny
      transitionDuration: '0.001s',
    };
  }

  const renderCreditCardIcon = (
    cc: io.flow.v0.models.PaymentMethodRule,
    i: number,
  ): JSX.Element => {
    const image = cc.payment_method.images.medium;
    return (
      <Draggable draggableId={cc.payment_method.id} index={i} key={cc.payment_method.id}>
        {(provided: DraggableProvided, snapshot: DraggableStateSnapshot): JSX.Element => {
          const dragContainerStyles = [styles.dragContainer];
          const dropDisallowed = snapshot.isDragging
              && (snapshot.draggingOver === DroppableTypes.CREDIT_CARDS_SHOWN
                    && ccsShown.length >= SHOW_NUMBER_OF_CCS
                    && draggingType !== DroppableTypes.CREDIT_CARDS_SHOWN);
          if (dropDisallowed) {
            dragContainerStyles.push('not-allowed');
          }
          if (snapshot.isDragging) {
            dragContainerStyles.push('dragging');
          }

          return (
            <div
              className={dragContainerStyles.join(' ')}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={getStyle(provided.draggableProps.style, snapshot)}
            >
              <div
                className={styles.cc}
                style={{ backgroundImage: `url(${image.url.replace(/\/logos\//, '/icons/')})` }}
              />
              <span className={styles.ccName}>{cc.payment_method.name}</span>
            </div>
          );
        }}
      </Draggable>
    );
  };

  const saveCreditCardOrder = useCallback(() => {
    handleCreditCardsReorder({ cards });
  }, [cards, handleCreditCardsReorder]);

  if (!isOpen) return null;

  return (
    <Worksheet
      open={isOpen}
      css={worksheetStyles}
    >
      <WorksheetHeader
        content="Edit Cards Icons"
        leftDecoration={<Button className={styles.button} content="Cancel" onClick={handleCCOrderClose} />}
        rightDecoration={<Button className={styles.button} intent="primary" content="Save" onClick={saveCreditCardOrder} />}
      />
      <WorksheetBody>
        <ol className={styles.instructions}>
          <li>Drag &amp; drop icons to customize.</li>
          <li>
            At least 1 card icon must be displayed.
            Can display up to
            {' '}
            {SHOW_NUMBER_OF_CCS}
            {' '}
            card icons.
          </li>
          <li>All supported cards are accepted regardless of icons displayed.</li>
        </ol>
        <DragDropContext onDragEnd={handleDrop} onDragStart={handleDragStart}>
          <div className={styles.dndContainer}>
            <div className={styles.droppableContainer}>
              <h2>Card Icons Displayed</h2>
              <Droppable
                droppableId={DroppableTypes.CREDIT_CARDS_SHOWN}
                direction="horizontal"
                isDropDisabled={
                  draggingType !== DroppableTypes.CREDIT_CARDS_SHOWN
                  && ccsShown.length >= SHOW_NUMBER_OF_CCS
                }
              >
                {(provided: DroppableProvided): JSX.Element => (
                  <div
                    className={styles.ccsDroppable}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {ccsShown.map(renderCreditCardIcon)}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
            <div className={styles.droppableContainer}>
              <h2>Available Card Icons</h2>
              <Droppable
                droppableId={DroppableTypes.CREDIT_CARDS_AVAIL}
                direction="horizontal"
                isDropDisabled={ccsShown.length <= 1}
              >
                {(provided: DroppableProvided): JSX.Element => (
                  <div
                    className={styles.ccsDroppable}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {ccsAvail.map(renderCreditCardIcon)}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </div>
        </DragDropContext>
        {experience.region.id === 'swe' && (
          <Banner css={warningBannerStyles} intent="warning">
            As required by Swedish Payment Services Act (2010:751), debit card
            options will be displayed first as a separate group for all customers
            located in Sweden.
          </Banner>
        )}
      </WorksheetBody>
    </Worksheet>
  );
};

CreditCardsDisplayOrder.defaultProps = {
  isOpen: false,
};

CreditCardsDisplayOrder.displayName = 'CreditCardsDisplayOrder';

export default CreditCardsDisplayOrder;
