import { Button } from '@flowio/react-button';
import React from 'react';
import { Stack } from '@flowio/react-stack';
import {
  Worksheet,
  WorksheetHeader,
  WorksheetTitle,
  WorksheetBody,
} from '@flowio/react-worksheet';

import ShippingConfigurationCopyForm from '../../containers/ShippingConfigurationCopyForm';
import { ShippingConfigurationCopyWorksheetProps } from '../../types/components';

const ShippingConfigurationCopyWorksheet: React.FC<ShippingConfigurationCopyWorksheetProps> = ({
  initialValues = {},
  isOpen = false,
  isSubmitting = false,
  onCancel,
  onSave,
  onSuccess,
  shippingConfiguration,
  title,
}) => (
  <Worksheet open={isOpen}>
    <WorksheetHeader>
      <Stack alignment="center" distribution="equalSpacing">
        <Button content="Cancel" disabled={isSubmitting} onClick={onCancel} />
        <WorksheetTitle content={title} />
        <Button content="Save" disabled={isSubmitting} intent="primary" onClick={onSave} />
      </Stack>
    </WorksheetHeader>
    <WorksheetBody>
      <ShippingConfigurationCopyForm
        initialValues={initialValues}
        onSubmitSuccess={onSuccess}
        shippingConfiguration={shippingConfiguration}
      />
    </WorksheetBody>
  </Worksheet>
);

ShippingConfigurationCopyWorksheet.displayName = 'ShippingConfigurationCopyWorksheet';

export default ShippingConfigurationCopyWorksheet;
