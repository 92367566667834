import moment, { Moment } from 'moment';
import curry from 'lodash/fp/curry';
import every from 'lodash/fp/every';
import find from 'lodash/fp/find';
import isUndefined from 'lodash/fp/isUndefined';
import negate from 'lodash/fp/negate';
import spread from 'lodash/fp/spread';
import zip from 'lodash/fp/zip';

import DateRangeLiterals from '../constants/date-range-literals';

const isDefined = negate(isUndefined);

export const isSameDate = curry((d1: string, d2: string) => every(isDefined, [d1, d2]) && moment(d1).isSame(d2, 'day'));

export const isSameDateRange = curry(
  (d1: Moment[], d2: Moment[]) => every(spread(isSameDate), zip(d1, d2)),
);

/**
 * Returns a date range matching the specified date range literal. By default, the date range are
 * computed relative to the current time.
 */
export const toDateRange = (
  literal: DateRangeLiterals,
  currentDate = moment(),
): moment.Moment[] => {
  switch (literal) {
    case DateRangeLiterals.TODAY:
      return [
        currentDate.clone().startOf('day'),
        currentDate.clone().endOf('day'),
      ];
    case DateRangeLiterals.YESTERDAY:
      return [
        currentDate.clone().subtract(1, 'day').startOf('day'),
        currentDate.clone().subtract(1, 'day').endOf('day'),
      ];
    case DateRangeLiterals.LAST_WEEK:
      return [
        currentDate.clone().subtract(1, 'week').startOf('week'),
        currentDate.clone().subtract(1, 'week').endOf('week'),
      ];
    case DateRangeLiterals.LAST_7_DAYS:
      return [
        currentDate.clone().subtract(6, 'days').startOf('day'),
        currentDate.clone().endOf('day'),
      ];
    case DateRangeLiterals.LAST_30_DAYS:
      return [
        currentDate.clone().subtract(29, 'days').startOf('day'),
        currentDate.clone().endOf('day'),
      ];
    case DateRangeLiterals.LAST_90_DAYS:
      return [
        currentDate.clone().subtract(89, 'days').startOf('day'),
        currentDate.clone().endOf('day'),
      ];
    case DateRangeLiterals.THIS_MONTH:
      return [
        currentDate.clone().startOf('month'),
        currentDate.clone().endOf('month'),
      ];
    case DateRangeLiterals.LAST_MONTH:
      return [
        currentDate.clone().subtract(1, 'month').startOf('month'),
        currentDate.clone().subtract(1, 'month').endOf('month'),
      ];
    case DateRangeLiterals.LAST_3_MONTHS:
      return [
        currentDate.clone().subtract(3, 'months').startOf('month'),
        currentDate.clone().subtract(1, 'month').endOf('month'),
      ];
    case DateRangeLiterals.LAST_12_MONTHS:
      return [
        currentDate.clone().subtract(12, 'months').startOf('month'),
        currentDate.clone().subtract(1, 'month').endOf('month'),
      ];
    case DateRangeLiterals.THIS_YEAR:
      return [
        currentDate.clone().startOf('year'),
        currentDate.clone().endOf('year'),
      ];
    case DateRangeLiterals.LAST_YEAR:
      return [
        currentDate.clone().subtract(1, 'year').startOf('year'),
        currentDate.clone().subtract(1, 'year').endOf('year'),
      ];
    default:
      return [];
  }
};

export const toDateRangeLiteral = (
  dateRange: Moment[],
  currentDate = moment(),
): DateRangeLiterals => {
  const predicate = isSameDateRange(dateRange);
  const literal = find((value) => predicate(toDateRange(value, currentDate)), DateRangeLiterals);
  return literal || DateRangeLiterals.CUSTOM;
};
