import { createSelector } from 'reselect';
import { RootState } from '../../stores/types';
import { UserState } from './types';
import { getOrganization } from '../organization/selectors';
import isCustomerServiceUser from '../console/utilities/is-customer-service-user';
import isChannelOrgAdmin from '../console/utilities/is-channel-organization-admin-user';
import isFulfillmentUser from '../console/utilities/isFulfillmentUser';
import isClassificationUser from '../console/utilities/is-classification-user';

export const getUserState = (state: RootState): UserState => state.user;

export const getMemberships = createSelector(
  getUserState,
  (state) => state.memberships,
);

export const getUserMembershipOrganizations = createSelector(
  getMemberships,
  (state) => {
    const orgs = state.map((membership) => membership.organization);
    if (orgs.some((org) => org.discriminator === 'organization_reference')) {
      // double check our cast below is appropriate
      // throw new Error('Unexpected Organization reference found in user memberships');
    }
    return orgs as io.flow.v0.models.Organization[];
  },
);

export const getIsFlowEmployee = createSelector(
  getMemberships,
  (memberships) => !!memberships.find((membership) => membership.organization.id === 'flow'),
);

export const getIsCustomerServiceUser = createSelector(
  [
    getMemberships,
    getOrganization,
  ],
  (memberships, organization) => isCustomerServiceUser(organization.id, memberships),
);

export const getIsChannelOrgAdmin = createSelector(
  [
    getMemberships,
    getOrganization,
  ],
  (memberships, organization) => isChannelOrgAdmin(organization.id, memberships),
);

export const getIsFulfillmentUser = createSelector(
  [
    getMemberships,
    getOrganization,
  ],
  (memberships, organization) => isFulfillmentUser(organization.id, memberships),
);

export const getIsClassificationUser = createSelector(
  [
    getMemberships,
    getOrganization,
  ],
  (memberships, organization) => isClassificationUser(organization.id, memberships),
);
