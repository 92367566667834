import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames/bind';

import styles from './StatusLabel.module.css';

const cx = classNames.bind(styles);

const StatusLabel = ({ content, intent }) => (
  <div className={cx('content', intent)}>
    {content}
  </div>
);

StatusLabel.displayName = 'StatusLabel';

StatusLabel.propTypes = {
  content: PropTypes.string.isRequired,
  intent: PropTypes.oneOf(['info', 'neutral', 'negative', 'positive', 'warning']),
};

StatusLabel.defaultProps = {
  intent: 'neutral',
};

export default StatusLabel;
