import { RSAA } from '@flowio/redux-async-middleware';
import { operationTypes } from '@flowio/redux-resource';
import { createQueryParams } from '@flowio/redux-resource-pagination-plugin';
import PropTypes from 'prop-types';

import { api } from '../../../../utilities/clients';
import { createResourceTypeDescriptors } from '../../helpers';
import { withAssertPropTypes } from '../../../../utilities/prop-types';

export default withAssertPropTypes({
  priceBookKey: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
  pageNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
})(({ organizationId, pageNumber, priceBookKey }) => ({
  [RSAA]: {
    types: createResourceTypeDescriptors({
      operationType: operationTypes.READ,
      pageSize: 25,
      resourceType: 'priceBookItems',
      list: organizationId,
      pageNumber,
    }),
    callAPI: (state) => api.priceBookItems(state).get(organizationId, priceBookKey, {
      params: createQueryParams({ pageSize: 25, pageNumber }),
    }),
  },
}));
