import { css } from '@emotion/css';

export const experienceLocalization = css`
  & .product-catalog-overview {
    margin-bottom: 2rem;
  }

  & .product-catalog-targeting-overview {
    margin-top: 2rem;
  }
`;

export const categoryStats = css`
  display: flex;
`;

export const categoryStatsTitle = css`
  color: #1f1f1f;
  font-size: 0.75rem;
  font-weight: 500;
`;

export const categoryStatsCount = css`
  font-size: 1.5rem;
`;
