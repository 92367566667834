import { Archive } from '@flowio/react-icons';
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import classNames from 'classnames/bind';
import { Card, CardContent } from '@flowio/react-card';
import { Label } from '@flowio/react-label';

import styles from './PriceBookImport.module.css';
import FileUploadRedux from '../../../../components/FileUploadRedux/FileUploadRedux';

const cx = classNames.bind(styles);

const PriceBookImport = ({
  file,
}) => (
  <Card>
    <CardContent>
      <Field component="input" type="hidden" name="userEmail" />
      <div className={cx('selectedFileContainer')}>
        <Label className={cx('selectedFileLabel')}>Selected file:</Label>
        {file && (
          file.name
        )}
        {!file && (
          'None'
        )}
      </div>
      <div className={cx('helpTextContainer')}>
        <Label className={cx('helpText')}>You can upload prices for single or multiple price books.</Label>
      </div>
      <Field component={FileUploadRedux} accept=".csv" name="file">
        <div className={cx('dragAreaContainer')}>
          <div className={cx('dragArea')}>
            <Archive className={cx('importIcon')} />
            <div className={cx('importDragInstructions')}>Or drag CSV file here</div>
          </div>
        </div>
      </Field>
    </CardContent>
  </Card>
);

PriceBookImport.propTypes = {
  file: PropTypes.shape({
    lastModified: PropTypes.number.isRequired,
    lastModifiedDate: PropTypes.instanceOf(Date).isRequired,
    name: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    webkitRelativePath: PropTypes.string.isRequired,
  }),
};

PriceBookImport.defaultProps = {
  file: undefined,
};

export default PriceBookImport;
