import { OrderDetailsGetResponse } from '@flowio/api-internal-sdk';
import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import { ActionTypes } from '../constants';

function createRequestAction(): RootActionTypes {
  return {
    type: ActionTypes.READ_ORDER_BY_IDENTIFIER_REQUEST,
  };
}

function createSuccessAction(payload: io.flow.v0.models.Order): RootActionTypes {
  return {
    type: ActionTypes.READ_ORDER_BY_IDENTIFIER_SUCCESS,
    payload,
  };
}

function createFailureAction(payload: FormattedErrorMessages): RootActionTypes {
  return {
    type: ActionTypes.READ_ORDER_BY_IDENTIFIER_FAILURE,
    payload,
  };
}

function readOrderByIdentifier(
  organizationId: string,
  identifier: string,
): ThunkResult<Promise<OrderDetailsGetResponse>> {
  return function readOrderByNumberEffects(
    dispatch,
    getState,
    extra,
  ): Promise<OrderDetailsGetResponse> {
    dispatch(createRequestAction());

    return extra.apiInternal(getState()).orderDetails.get({
      organization: organizationId,
      q: identifier,
    }).then((response) => {
      if (response.ok && response.body[0]) {
        dispatch(createSuccessAction(response.body[0].order));
        return response;
      }

      const error = formatErrorResponseV2(response);
      dispatch(createFailureAction(error));
      return response;
    });
  };
}

export default readOrderByIdentifier;
