import { InfoCircle, CircleLoader } from '@flowio/react-icons';
import { compose } from 'redux';
import React, { ReactElement } from 'react';
import { Banner, BannerText } from '@flowio/react-banner';
import { Button } from '@flowio/react-button';
import {
  Card, CardHeader, CardContent, CardEmptyState, CardTitle,
} from '@flowio/react-card';
import { MenuDivider } from '@flowio/react-menu';
import { Checkbox } from '@flowio/react-checkbox';
import { Collapse } from '@flowio/react-collapse';
import { Worksheet, WorksheetHeader, WorksheetBody } from '@flowio/react-worksheet';

import { Popover } from '@flowio/react-popover';
import { Box } from '@flowio/react-box';
import { TextInput } from '@flowio/react-text-input';
import noop from 'lodash/noop';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { css } from '@emotion/react';
import classNames from 'classnames';
import ItemForm from './item-form';

import ShippingForm from './shipping-form';
import OrderReturnDetailsDialog from '../order-return-details-dialog';
import withSubmit from '../../../../components/with-submit';
import withValidation from '../../../../components/with-validation';
import createReturn from '../../actions/create-return';
import { WithSubmitProps } from '../../../../components/with-submit/with-submit';
import { WithValidationProps } from '../../../../components/with-validation/with-validation';
import {
  SelectedItem,
  ReturnConfirmationState,
  FullSelectedItem,
  ReturnData,
} from '../../types';
import {
  DefaultReturnSource,
  DefaultReturnService,
  DefaultReturnOrigin,
  DefaultReturnDestination,
} from '../../constants/default-store-values';
import * as styles from './order-return-form-dialog.styles';
import getTextFieldValue from '../../../../utilities/getTextFieldValue';

interface FormData {
  selectedItems: SelectedItem[];
  shippingMethod: string;
  shippingCenter: string;
  returnKey: string;
}

interface OrderReturnFormDialogProps {
  onRequestCancel: (...args: any) => void;
  onRequestHideReturnNumber: (...args: any) => void;
  onRequestShowReturnNumber: (...args: any) => void;
  orderNumber: string;
  customerEmail?: string;
  userEmail: string;
  isOpen?: boolean;
  returnNumberState: boolean;
  returnConfirmationState?: ReturnConfirmationState;
  shippingTiers?: io.flow.v0.models.Tier[];
  items: io.flow.v0.models.LocalizedLineItem[];
}

type MergedProps = OrderReturnFormDialogProps & WithSubmitProps & WithValidationProps;

const defaultReturnData: ReturnData = {
  orderItems: [] as io.flow.v0.models.LocalizedLineItem[],
  inProgress: false,
  id: '',
  key: '',
  items: [] as io.flow.v0.models.ReturnLineItem[],
  labels: [] as io.flow.v0.models.ShippingLabel[],
  source: DefaultReturnSource,
  service: DefaultReturnService,
  origin: DefaultReturnOrigin,
  destination: DefaultReturnDestination,
};

const errorBanner = css({
  backgroundColor: '#c24942',
  marginBottom: '1.5rem',
  borderRadius: '4px',
});

const infoBanner = css({
  marginBottom: '1.5rem',
  borderRadius: '4px',
});

const errorBannerText = css({
  color: 'white',
});

const tooltipStyles = css({
  overflow: 'visible',
});

class OrderReturnFormDialog extends React.Component<MergedProps> {
  static displayName = 'OrderReturnFormDialog';

  static defaultProps = {
    isOpen: false,
    customerEmail: '',
    userEmail: '',
    onRequestCancel: noop,
    onRequestHideReturnNumber: noop,
    onRequestShowReturnNumber: noop,
    returnNumberState: false,
    returnConfirmationState: {
      showReturnConfirmation: false,
      returnData: defaultReturnData,
    },
    orderNumber: null,
    shippingTiers: [],
    items: [],
  };

  handleRequestClose = (): void => {
    const { onRequestCancel, reset } = this.props;
    // Reset form fields managed by internal state of higher-order components.
    // Necessary because worksheets are not unmounted on close.
    reset();
    onRequestCancel();
  };

  handleSubmit = ({ isValid }: { isValid: boolean }, formData: FormData): void => {
    const {
      onSubmit,
      orderNumber,
      items: propItems,
    } = this.props;

    if (isValid) {
      onSubmit({
        items: formData.selectedItems.filter((item: SelectedItem) => item.quantity > 0),
        tier_id: formData.shippingMethod,
        center_key: formData.shippingCenter,
        key: formData.returnKey,
        order_number: orderNumber,
        orderItems: propItems,
      });
    }
  };

  handleReturnNumberCheck = (): void => {
    const {
      returnNumberState,
      onRequestHideReturnNumber,
      onRequestShowReturnNumber,
      fields,
    } = this.props;

    if (returnNumberState) {
      onRequestHideReturnNumber();
      fields.returnKey.events.onBlur('');
    } else {
      onRequestShowReturnNumber();
    }
  };

  onShippingCenterSelection = (shippingCenter: string): void => {
    const { fields } = this.props;
    fields.shippingCenter.events.onBlur(shippingCenter);
  };

  onShippingTierSelection = (shippingMethod: string): void => {
    const { fields } = this.props;

    fields.shippingMethod.events.onBlur(shippingMethod);
  };

  onItemChange = (selectedItems: SelectedItem[]): void => {
    const formattedReturnItems = selectedItems.map((item) => ({
      item_number: item.number,
      quantity: item.quantity,
    }));
    const { fields } = this.props;
    fields.selectedItems.events.onBlur(formattedReturnItems);
  };

  getErrors = (
    returnConfirmationState: ReturnConfirmationState,
    validationErrors?: string | null,
  ): ReturnConfirmationState | string => {
    const apiError = get(returnConfirmationState, 'returnData.error', false);
    return apiError || validationErrors;
  };

  formatReturnItems = (
    returnItemsRef: io.flow.v0.models.ReturnLineItem[],
    orderItems: io.flow.v0.models.LocalizedLineItem[],
  ): FullSelectedItem[] => {
    const fullReturnItems = orderItems.map((orderItem) => {
      const returnItem = returnItemsRef.find((retItem) => (
        retItem.item_number === orderItem.number));

      return {
        number: orderItem.number,
        name: orderItem.name,
        quantity: returnItem ? returnItem.quantity : 0,
        maxQuantity: orderItem.quantity,
      };
    }).sort((a, b) => (a >= b ? -1 : 1));

    return fullReturnItems;
  };

  render(): ReactElement {
    const {
      isOpen,
      items,
      shippingTiers = [],
      fields,
      returnNumberState,
      returnConfirmationState = {
        showReturnConfirmation: false,
        returnData: defaultReturnData,
      },
      onSubmitValidate,
      customerEmail,
      userEmail,
    } = this.props;
    const returnDetailsItems = returnConfirmationState.showReturnConfirmation
      ? this.formatReturnItems(get(returnConfirmationState, 'returnData.items', []),
        get(returnConfirmationState, 'returnData.orderItems', []))
      : [];

    const isCreateDisabled = shippingTiers.length < 1
      || returnConfirmationState.returnData.inProgress;
    const isCancelDisabled = returnConfirmationState.returnData.inProgress;
    const selectedItemsError = fields.selectedItems.error;

    return (
      <Worksheet
        closeOnEscape
        closeOnClickAway
        open={isOpen}
        onClose={this.handleRequestClose}
      >
        <WorksheetHeader
          content={!returnConfirmationState.showReturnConfirmation ? 'New Return' : 'Return Details'}
          className="return-form-header"
          leftDecoration={!returnConfirmationState.showReturnConfirmation ? <Button disabled={isCancelDisabled} content="Cancel" onClick={this.handleRequestClose} /> : undefined}
          rightDecoration={!returnConfirmationState.showReturnConfirmation ? <Button disabled={isCreateDisabled} content="Create Return" type="submit" intent="primary" onClick={onSubmitValidate(this.handleSubmit)} form="return-form" /> : <Button content="Close" onClick={this.handleRequestClose} />}
        />
        <WorksheetBody className="return-form-body">
          {!returnConfirmationState.showReturnConfirmation
            && !returnConfirmationState.returnData.inProgress && (
            <div>
              {this.getErrors(returnConfirmationState, selectedItemsError) && (
                <Banner css={errorBanner} intent="negative">
                  <BannerText css={errorBannerText}>
                    {get(returnConfirmationState, 'returnData.error.messages[0].message',
                      selectedItemsError)}
                  </BannerText>
                </Banner>
              )}
              <Banner css={infoBanner}>
                <BannerText>
                  A return label & a commercial invoice will be generated after the return is
                  created. Once generated, the return label & the commercial invoice
                  cannot be modified. You can create a new return if any changes are needed.
                </BannerText>
              </Banner>
              <form id="return-form">
                <Card>
                  <CardHeader dividing>
                    <CardTitle content="Return Items" />
                  </CardHeader>
                  <CardContent className={styles.itemSelection}>
                    <ItemForm
                      items={items}
                      onItemChange={this.onItemChange}
                    />
                  </CardContent>
                </Card>
                <Card>
                  <CardHeader dividing>
                    <CardTitle className={styles.tierTooltip} content="Select Return Shipping Tier" />
                    <Popover
                      openOnHover
                      css={tooltipStyles}
                      offset="0px -10px"
                      position="top"
                      trigger={<InfoCircle className={styles.tooltipTrigger} />}
                    >
                      <div className={styles.tooltip}>
                        Return Shipping Tiers are configured in the Logistics
                        section of each Experience.
                      </div>
                    </Popover>
                  </CardHeader>
                  <CardContent>
                    {isEmpty(shippingTiers) ? (
                      <CardEmptyState content="No return shipping tiers available. Please go into experience settings and create one." />
                    ) : (
                      <ShippingForm
                        selectedShippingTier={fields.shippingMethod.value as string}
                        onShippingTierSelection={this.onShippingTierSelection}
                        onShippingCenterSelection={this.onShippingCenterSelection}
                        shippingTiers={shippingTiers}
                      />
                    )}
                  </CardContent>
                </Card>
                <MenuDivider className={styles.divider} />
                <Box alignContent="center" className={styles.checkboxContainer}>
                  <Checkbox
                    className={styles.checkbox}
                    labelText="Add RMA/RGA Number (optional)"
                    checked={returnNumberState}
                    onChange={this.handleReturnNumberCheck}
                  />
                  <Popover
                    openOnHover
                    css={tooltipStyles}
                    offset="0px -10px"
                    position="top"
                    trigger={<InfoCircle className={styles.tooltipTrigger} />}
                  >
                    <div className={styles.tooltip}>
                      Enter a return authorization number associated with this return if available.
                    </div>
                  </Popover>
                </Box>
                <Collapse expanded={returnNumberState}>
                  <Box alignContent="center">
                    <div className={classNames(styles.field, 'align-right')}>
                      <TextInput
                        clearable={false}
                        onBlur={(e) => fields.returnKey.events.onBlur(e)}
                        onChange={(e) => fields.returnKey.events.onChange(e)}
                        value={getTextFieldValue(fields.returnKey.value)}
                      />
                    </div>
                  </Box>
                </Collapse>
              </form>
            </div>
          )}
          {returnConfirmationState.showReturnConfirmation
            && !returnConfirmationState.returnData.inProgress && (
            <OrderReturnDetailsDialog
              items={returnDetailsItems}
              message="A return has been created. You may download the return label and the commercial invoice below. You may download them again in the Returns secion on the Order Details page if needed."
              pdfLabel={get(returnConfirmationState, ['returnData', 'labels', '0', 'label', 'pdf'], '')}
              pdfInvoice={get(returnConfirmationState, ['returnData', 'labels', '0', 'invoice', 'pdf'], '')}
              userEmail={userEmail}
              customerEmail={customerEmail}
            />
          )}
          {returnConfirmationState.returnData.inProgress && (
            <div className={styles.loaderContainer}>
              <CircleLoader className={styles.loaderIcon} />
              <div className={styles.loaderCopy}>
                Creating your return, please wait...
              </div>
            </div>
          )}
        </WorksheetBody>
      </Worksheet>
    );
  }
}

export default compose<React.FC<OrderReturnFormDialogProps>>(
  withSubmit((formData) => createReturn({ ...formData })),
  withValidation(() => ({
    selectedItems: {
      defaultValue: [],
      validations: (value: SelectedItem[]): string | undefined => {
        if (!value) {
          return 'Selected items are required';
        }

        const filteredItems = value.filter((item) => item.quantity > 0);

        if (filteredItems.length < 1) {
          return 'Please select items with more than 0 quantity';
        }

        return undefined;
      },
    },
    shippingMethod: {
      defaultValue: '',
      validations: (): void => undefined,
    },
    shippingCenter: {
      defaultValue: '',
      validations: (): void => undefined,
    },
    returnKey: {
      defaultValue: '',
      validations: (): void => undefined,
    },
  })),
)(OrderReturnFormDialog);
