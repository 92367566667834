import { connect } from 'react-redux';
import React, { FunctionComponent } from 'react';

import { checkFeatureValueByKey } from '../../selectors';
import { RootState } from '../../../../stores/types';
import { FeatureValueSelectorProps } from '../../types/props';
import { FeatureValueSelectorStateProps } from '../../types/state';

const isEmptyChildren = (
  children: ((...args: any) => any | Node),
): boolean => React.Children.count(children) === 0;

const FeatureValueSelector: FunctionComponent<FeatureValueSelectorProps> = ({
  component,
  children,
  featureKey,
  render,
  isFeatureEnabled,
  ...unhandledProps
}) => {
  const props = {
    ...unhandledProps,
    featureKey,
    isFeatureEnabled,
    isFeatureDisabled: !isFeatureEnabled,
  };

  if (component != null) {
    return React.createElement(component, props);
  }

  if (render != null) {
    return render(props);
  }

  if (typeof children === 'function') {
    return children(props);
  }

  if (children != null && !isEmptyChildren(children)) {
    return React.Children.only(children);
  }

  return null;
};

FeatureValueSelector.displayName = 'FeatureValueSelector';

FeatureValueSelector.defaultProps = {
  children: undefined,
  component: undefined,
  isFeatureEnabled: false,
  render: undefined,
};

function mapStateToProps(
  state: RootState,
  props: FeatureValueSelectorProps,
): FeatureValueSelectorStateProps {
  const { featureKey } = props;
  return {
    isFeatureEnabled: checkFeatureValueByKey(featureKey)(state),
  };
}

export default connect(mapStateToProps)(FeatureValueSelector);
