import { ExperiencesPostResponse } from '@flowio/api-sdk';
import ActionTypes from '../constants/action-types';
import {
  ThunkDispatcher,
  ThunkExtraArguments,
  RootState,
  RootActionTypes,
  ThunkResult,
} from '../../../stores/types';
import standardAction from '../../../utilities/standard-action';
import { PropType } from '../../../types';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import toLegacyResponse from '../../../utilities/clients/to-legacy-response';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';

type ResponseType = PropType<ExperiencesPostResponse, 'body'>;

function createExperienceSuccessAction(
  experience: io.flow.v0.models.Experience,
  payload: { formData: io.flow.v0.models.ExperienceForm },
): RootActionTypes {
  return {
    ...standardAction(ActionTypes.CREATE_EXPERIENCE_SUCCESS, experience),
    ...payload,
  };
}

function createExperienceFailureAction(error?: FormattedErrorMessages): RootActionTypes {
  return standardAction(ActionTypes.CREATE_EXPERIENCE_FAILURE, error);
}

export default function createExperience(
  organization: string,
  formData: io.flow.v0.models.ExperienceForm,
): ThunkResult<Promise<LegacyResponse<ResponseType>>> {
  return function createExperienceEffects(
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<LegacyResponse<ResponseType>> {
    dispatch({
      type: ActionTypes.CREATE_EXPERIENCE_SUBMIT,
      formData,
    });

    return extra.api(getState()).experiences.post({
      organization,
      body: formData,
    }).then((response) => {
      if (response.ok) dispatch(createExperienceSuccessAction(response.body, { formData }));
      else dispatch(createExperienceFailureAction(formatErrorResponseV2(response)));
      return toLegacyResponse<ResponseType>(response);
    });
  };
}
