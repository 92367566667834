import { InfoCircle } from '@flowio/react-icons';
import { Field, WrappedFieldProps } from 'redux-form';
import { Box, BoxItem } from '@flowio/react-box';
import { Popover } from '@flowio/react-popover';
import { css } from '@emotion/react';

import { FormGroup, FormGroupIntent } from '@flowio/react-form-group';
import { RadioButton } from '@flowio/react-radio-button';
import React from 'react';
import { Text } from '@flowio/react-text';
import { TextField } from '../../../../components/ReduxFormFieldAdapters';

const radioLabel = css({
  padding: '10px',
});

const tooltipStyles = css({
  overflow: 'visible',
});

const TierDeliveryWindow: React.FC<WrappedFieldProps> = ({ input, meta }) => {
  let feedbackText;
  let intent: FormGroupIntent = 'neutral';

  if (meta.error && meta.touched) {
    feedbackText = meta.error;
    intent = 'negative';
  } else if (meta.warning && meta.touched) {
    feedbackText = meta.warning;
    intent = 'warning';
  }

  return (
    <FormGroup feedback={feedbackText} intent={intent}>
      <RadioButton
        checked={input.value === 'calculated'}
        name={input.name}
        onBlur={input.onBlur}
        onChange={input.onChange}
        onFocus={input.onFocus}
        value="calculated"
      >
        <Box css={radioLabel} inline spacing="loose">
          <Text>Use Flow Delivery Window Estimates</Text>
          <Popover
            openOnHover
            css={tooltipStyles}
            offset="-99px -10px"
            position="top left"
            trigger={<InfoCircle width={16} />}
          >
            <div>
              Delivery window estimates calculated by
              data provided by carriers and other known variables
            </div>
          </Popover>
        </Box>
      </RadioButton>
      <Box alignItems="center">
        <RadioButton
          checked={input.value === 'custom'}
          labelText="Use Custom Copy"
          name={input.name}
          onBlur={input.onBlur}
          onChange={input.onChange}
          onFocus={input.onFocus}
          value="custom"
        />
        <BoxItem flexAuto>
          <Field
            component={TextField}
            name="displayEstimateLabel"
            disabled={input.value !== 'custom'}
            fluid
            hintText="Example: 2-8 Business Days"
            size="medium"
          />
        </BoxItem>
      </Box>
    </FormGroup>
  );
};

TierDeliveryWindow.displayName = 'TierDeliveryWindow';

export default TierDeliveryWindow;
