import { compose } from 'redux';
import { reduxForm, SubmissionError, formValues } from 'redux-form';
import { $HttpResponse } from '@flowio/api-sdk';
import { updateOptinAttribute } from '../../../actions';
import toOptinAttributeForm from '../../../utilities/toOptinAttributeForm';
import FormName from '../../../constants/FormName';
import OptinMessageChangeStatusForm from '../components/OptinMessageChangeStatusForm';
import isResponseOk from '../../../../../utilities/isResponseOk';
import { ComponentProps, OwnProps } from '../types';
import { ThunkDispatcher } from '../../../../../stores/types';
import { OptinAttributeFormValues } from '../../../types';

function handleResponse(
  response: $HttpResponse,
): $HttpResponse {
  if (isResponseOk(response)) return response;
  throw new SubmissionError({ _error: response.body });
}

function submit(
  values: OptinAttributeFormValues,
  dispatch: ThunkDispatcher,
) {
  const { organizationId, optinAttributeKey } = values;
  return dispatch(updateOptinAttribute(
    organizationId,
    optinAttributeKey,
    (toOptinAttributeForm(values) as io.flow.internal.v0.models.OptinAttributeForm),
  )).then(handleResponse);
}

export default compose<React.FC<OwnProps>>(
  reduxForm<OptinAttributeFormValues, OwnProps>({
    form: FormName.OPTIN_ATTRIBUTE_STATUS_FORM,
    onSubmit: submit,
  }),
  formValues<ComponentProps, OwnProps>({
    status: 'status',
    name: 'name',
  }),
)(OptinMessageChangeStatusForm);
