import { createSelector } from 'reselect';

import getFeatures from './getFeatures';

export const getFeaturesQuery = createSelector(
  getFeatures,
  (features) => features.list.params.q || '',
);

export default getFeaturesQuery;
