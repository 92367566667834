import { Box } from '@flowio/react-box';
import { LaneDirection } from '@flowio/api-constants';
import React from 'react';
import get from 'lodash/get';
import { PageContent } from '@flowio/react-page';

import ShippingLaneListCard from '../ShippingLaneListCard';
import ShippingConfigurationOverviewCard from '../ShippingConfigurationOverviewCard';
import { ShippingConfigurationDetailPageProps } from '../../types/components';

const ShippingConfigurationDetailPage: React.FC<ShippingConfigurationDetailPageProps> = ({
  experiences,
  onAddShippingLane,
  onDeleteShippingConfiguration,
  onDuplicateShippingConfiguration,
  onEditShippingConfiguration,
  onViewShippingLane,
  onUpdateOutboundShippingLanePage,
  onUpdateReturnShippingLanePage,
  organizationId,
  regions,
  shippingConfiguration,
  outboundPaginatedShippingLanes,
  returnPaginatedShippingLanes,
}) => (
  <PageContent>
    <Box direction="column" spacing="extraLoose">
      <ShippingConfigurationOverviewCard
        experiences={experiences}
        onDeleteShippingConfiguration={onDeleteShippingConfiguration}
        onDuplicateShippingConfiguration={onDuplicateShippingConfiguration}
        onEditShippingConfiguration={onEditShippingConfiguration}
        organizationId={organizationId}
        shippingConfiguration={shippingConfiguration}
      />
      <ShippingLaneListCard
        onUpdateShippingLanePage={onUpdateOutboundShippingLanePage}
        onAddShippingLane={onAddShippingLane}
        onViewShippingLane={onViewShippingLane}
        organizationId={organizationId}
        regions={regions}
        shippingConfigurationKey={get(shippingConfiguration, 'key')}
        paginatedShippingLanes={outboundPaginatedShippingLanes}
        shippingLaneDirection={LaneDirection.OUTBOUND}
      />
      <ShippingLaneListCard
        onUpdateShippingLanePage={onUpdateReturnShippingLanePage}
        onAddShippingLane={onAddShippingLane}
        onViewShippingLane={onViewShippingLane}
        organizationId={organizationId}
        regions={regions}
        shippingConfigurationKey={get(shippingConfiguration, 'key')}
        paginatedShippingLanes={returnPaginatedShippingLanes}
        shippingLaneDirection={LaneDirection.RETURN}
      />
    </Box>
  </PageContent>
);

ShippingConfigurationDetailPage.displayName = 'ShippingConfigurationDetailPage';

export default ShippingConfigurationDetailPage;
