import { createSelector } from 'reselect';
import { RootState } from '../../../stores/types';
import { MarketingState } from '../types';
import getMarketingState from './getMarketingState';

const getFacebookTokenSummaryFromChannel = (
  {
    channels,
  }: MarketingState,
): io.flow.internal.v0.models.MarketingGatewayFacebookConnectionAccessTokenSummary | undefined => {
  const fbChannelDetails = channels.channels?.find((c) => c.platform === 'facebook')?.details;

  if (fbChannelDetails && fbChannelDetails.discriminator === 'marketing_gateway_facebook_channel_details') {
    return fbChannelDetails.token_summary;
  }

  return undefined;
};

export default createSelector<RootState, MarketingState,
io.flow.internal.v0.models.MarketingGatewayFacebookConnectionAccessTokenSummary | undefined>(
  getMarketingState,
  getFacebookTokenSummaryFromChannel,
);
