import { Button } from '@flowio/react-button';
import { WorksheetHeader } from '@flowio/react-worksheet';
import React, { useCallback } from 'react';
import { Card, CardHeader, CardContent } from '@flowio/react-card';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import kebabCase from 'lodash/kebabCase';
import noop from 'lodash/noop';
import { Dialog, DialogBody } from '@flowio/react-dialog';
import Alert from '../../../../components/alert/alert';
import ScheduledExportsForm from '../scheduled-export-form';
import * as styles from './exports.styles';
import { FormattedErrorMessages } from '../../../../utilities/format-error-response-v2';
import { ThunkDispatcher } from '../../../../middlewares/types';

interface Props {
  defaultEmail?: string;
  error?: FormattedErrorMessages;
  onRequestClose: () => void;
  handleSubmit: Function;
  dispatch: ThunkDispatcher;
  onCreateExport: Function;
  // A function that MUST return an action to dispatch.
  onRequestSubmit: Function;
  selectedExport?: io.flow.v0.models.ScheduledExport;
  isFileEmpty?: boolean;
  valid?: boolean;
  setImportRef?: Function;
  setEmailRef?: Function;
  isInvalid?: boolean;
  onFileChange?: Function;
  importSuccess?: boolean;
  addMode?: boolean;
  onSubmit: Function;
  onSubmitValidate: Function;
  submitted: boolean;
  submitting: boolean;
  title: string;
  isOpen: boolean;
}

const ExportDialog: React.FC<Props> = (props) => {
  const { dispatch } = props;

  const handleSubmit = useCallback(() => {
    dispatch(submit('scheduledExportsForm'));
  }, [dispatch]);

  const {
    error,
    valid,
    onRequestClose,
    addMode,
    isFileEmpty,
    importSuccess,
    submitting,
    isOpen,
    onSubmit,
    ...unhandledProps
  } = props;

  return (
    <Dialog {...unhandledProps} open={isOpen} onClose={onRequestClose} backdrop>
      <WorksheetHeader
        content={addMode ? 'Add Export Schedule' : 'Edit Export Schedule'}
        rightDecoration={<Button id="save-btn" content="Save" intent="primary" onClick={handleSubmit} disabled={submitting || !valid} size="medium" />}
        leftDecoration={<Button id="cancel-btn" content="Cancel" size="medium" onClick={onRequestClose} />}
      />
      <DialogBody className={styles.exportsDialog}>
        <div>
          <Card className={styles.exportsCard}>
            <CardHeader
              caption="Export Schedule"
              dividing
            />
            <CardContent>
              <ScheduledExportsForm />
              {!!error && error.messages && error.messages.map((failure: { message: string }) => (
                <Alert key={kebabCase(failure.message)} type="failure">
                  {failure.message}
                </Alert>
              ))}
              {isFileEmpty && (
                <Alert type="failure">
                  The file you uploaded is empty. Please try again.
                </Alert>
              )}
              {importSuccess && (
                <Alert type="info">
                  Import file uploaded. You will receive an email once
                  the data has been successfully imported.
                </Alert>
              )}
            </CardContent>
          </Card>
        </div>
      </DialogBody>
    </Dialog>
  );
};

ExportDialog.displayName = 'ExportDialog';

ExportDialog.defaultProps = {
  defaultEmail: undefined,
  valid: false,
  handleSubmit: noop,
  selectedExport: undefined,
  onCreateExport: noop,
  addMode: false,
  setEmailRef: noop,
  setImportRef: noop,
  onFileChange: noop,
  isInvalid: false,
  isFileEmpty: false,
  importSuccess: false,
  error: undefined,
  onRequestClose: noop,
  onRequestSubmit: noop,
};

export default connect()(ExportDialog);
