import Client from './client';

export default class CheckoutBundles extends Client {
  constructor(opts) {
    let options = opts;

    if (typeof opts === 'string') {
      options = { host: opts }; // convert host string to options object
    }

    options.serviceName = 'API Internal';

    super(options);
  }

  postCartById(id, options = {}) {
    return this.makeRequest(`${this.host}/bundles/checkout/cart/${id}`, {
      ...options,
       method: 'POST',
    });
  }

  getOrderByOrgAndNumber(org, number, options = {}) {
    return this.makeRequest(`${this.host}/bundles/checkout/${org}/order/${number}`, options);
  }

  postOrdersByOrg(org, options = {}) {
    return this.makeRequest(`${this.host}/bundles/checkout/${org}/orders`, {
      ...options,
       method: 'POST',
    });
  }

}
