import { reduxForm, SubmissionError } from 'redux-form';

import { ActionTypes, FormName } from '../constants';
import { deleteTier } from '../actions';
import TierDeleteForm from '../components/TierDeleteForm';
import { TierDeleteFormProps, TierDeleteFormValues } from '../types/components';
import { ThunkDispatcher } from '../../../stores/types';

function handleSubmit(values: TierDeleteFormValues, dispatch: ThunkDispatcher) {
  return dispatch(deleteTier(values)).then((result) => {
    if (result.type === ActionTypes.DELETE_TIER_FAILURE) {
      throw new SubmissionError({
        _error: result.payload.messages,
      });
    }

    return result;
  });
}

const withForm = reduxForm<TierDeleteFormValues, TierDeleteFormProps>({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  form: FormName.TIER_DELETE_FORM,
  onSubmit: handleSubmit,
});

export default withForm(TierDeleteForm);
