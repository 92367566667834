import React, { Component, ReactElement } from 'react';
import { FlowLogo } from '@flowio/react-icons';
import { ShopifyOrderDetailRedirectProps } from '../../types';
import * as styles from './shopify-order-detail-redirect.styles';

export default class ShopifyOrderDetailRedirect extends Component<ShopifyOrderDetailRedirectProps> {
  static displayName = 'ShopifyOrderDetailRedirect';

  componentDidMount(): void {
    const { onRequestRedirectToOrderDetails } = this.props;
    onRequestRedirectToOrderDetails();
  }

  render(): ReactElement {
    return (
      <div className={styles.shopifyOrderDetailRedirect}>
        <div className={styles.container}>
          <FlowLogo />
          <p>
            You are now being redirected to the order details page for this Shopify order.
          </p>
        </div>
      </div>
    );
  }
}
