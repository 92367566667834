const ActionTypes = {
  CREATE_OPTIN_ATTRIBUTE_FAILURE: 'OPTIN.CREATE_OPTIN_ATTRIBUTE_FAILURE',
  CREATE_OPTIN_ATTRIBUTE_REQUEST: 'OPTIN.CREATE_OPTIN_ATTRIBUTE_REQUEST',
  CREATE_OPTIN_ATTRIBUTE_SUCCESS: 'OPTIN.CREATE_OPTIN_ATTRIBUTE_SUCCESS',
  CREATE_OPTIN_PROMPT_FAILURE: 'OPTIN.CREATE_OPTIN_PROMPT_FAILURE',
  CREATE_OPTIN_PROMPT_REQUEST: 'OPTIN.CREATE_OPTIN_PROMPT_REQUEST',
  CREATE_OPTIN_PROMPT_SUCCESS: 'OPTIN.CREATE_OPTIN_PROMPT_SUCCESS',
  DELETE_OPTIN_ATTRIBUTE_FAILURE: 'OPTIN.DELETE_OPTIN_ATTRIBUTE_FAILURE',
  DELETE_OPTIN_ATTRIBUTE_REQUEST: 'OPTIN.DELETE_OPTIN_ATTRIBUTE_REQUEST',
  DELETE_OPTIN_ATTRIBUTE_SUCCESS: 'OPTIN.DELETE_OPTIN_ATTRIBUTE_SUCCESS',
  DELETE_OPTIN_PROMPT_FAILURE: 'OPTIN.DELETE_OPTIN_PROMPT_FAILURE',
  DELETE_OPTIN_PROMPT_REQUEST: 'OPTIN.DELETE_OPTIN_PROMPT_REQUEST',
  DELETE_OPTIN_PROMPT_SUCCESS: 'OPTIN.DELETE_OPTIN_PROMPT_SUCCESS',
  FETCH_OPTIN_ATTRIBUTE_FAILURE: 'OPTIN.FETCH_OPTIN_ATTRIBUTE_FAILURE',
  FETCH_OPTIN_ATTRIBUTE_REQUEST: 'OPTIN.FETCH_OPTIN_ATTRIBUTE_REQUEST',
  FETCH_OPTIN_ATTRIBUTE_SUCCESS: 'OPTIN.FETCH_OPTIN_ATTRIBUTE_SUCCESS',
  FETCH_OPTIN_ATTRIBUTES_FAILURE: 'OPTIN.FETCH_OPTIN_ATTRIBUTES_FAILURE',
  FETCH_OPTIN_ATTRIBUTES_REQUEST: 'OPTIN.FETCH_OPTIN_ATTRIBUTES_REQUEST',
  FETCH_OPTIN_ATTRIBUTES_SUCCESS: 'OPTIN.FETCH_OPTIN_ATTRIBUTES_SUCCESS',
  FETCH_OPTIN_PROMPTS_FAILURE: 'OPTIN.FETCH_OPTIN_PROMPTS_FAILURE',
  FETCH_OPTIN_PROMPTS_REQUEST: 'OPTIN.FETCH_OPTIN_PROMPTS_REQUEST',
  FETCH_OPTIN_PROMPTS_SUCCESS: 'OPTIN.FETCH_OPTIN_PROMPTS_SUCCESS',
  HIDE_DIALOG: 'OPTIN.HIDE_DIALOG',
  SHOW_DIALOG: 'OPTIN.SHOW_DIALOG',
  UPDATE_OPTIN_ATTRIBUTE_FAILURE: 'OPTIN.UPDATE_OPTIN_ATTRIBUTE_FAILURE',
  UPDATE_OPTIN_ATTRIBUTE_REQUEST: 'OPTIN.UPDATE_OPTIN_ATTRIBUTE_REQUEST',
  UPDATE_OPTIN_ATTRIBUTE_SUCCESS: 'OPTIN.UPDATE_OPTIN_ATTRIBUTE_SUCCESS',
  UPDATE_OPTIN_PROMPT_FAILURE: 'OPTIN.UPDATE_OPTIN_PROMPT_FAILURE',
  UPDATE_OPTIN_PROMPT_REQUEST: 'OPTIN.UPDATE_OPTIN_PROMPT_REQUEST',
  UPDATE_OPTIN_PROMPT_SUCCESS: 'OPTIN.UPDATE_OPTIN_PROMPT_SUCCESS',
} as const;

export default ActionTypes;
