import { css } from '@emotion/css';

export const tierList = css`
  counter-reset: tier;
`;

export const tier = css`
  border-radius: 4px;
  box-sizing: border-box;
`;

export const tierHeader = css`
  background-color: #f3f5f7;
`;

export const tierTitle = css`
  display: inline-block;
  font-family: Roboto;
  font-size: 24px;
  font-weight: normal;
  color: #0051b2;
  padding-left: 8px;
`;

export const deleteAction = css`
  margin-right: 8px;
`;

export const tierActions = css`
  float: right;
  padding-top: 10px;
`;

export const tierDeliveryCustomLabel = css`
  padding-left: 4px;
  color: #4d5866;
  font-size: 14px;
`;

export const tierListItem = css`
  min-width: 0 !important;
`;

export const fastestTier = css`
  background-color: #479aff !important;
  color: white !important;
  border: none;
  margin-left: 8px;
  position: relative;
  top: -3px;
`;

export const lowCostTier = css`
  background-color: #50e3c2 !important;
  color: white !important;
  border: none;
  margin-left: 8px;
  position: relative;
  top: -3px;
`;

export const tierOptionTitle = css`
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  color: #1a1a1a;
`;

export const bold = css`
  font-weight: bold;
`;

export const tierSubHeader = css`
  color: #0051b2;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px solid #0051b2;
  padding-bottom: 4px;
  padding-left: 8px;
`;

export const tierStrategyIcon = css`
  margin: 0 4px;
`;

export const tierValueStack = css`
  font-size: 14px;
  color: #4d5866;
  margin-top: 2px;
`;

export const tierDeliveryWindow = css`
  font-size: 14px;
  color: #4d5866;
  font-weight: bold;
`;

export const tierName = css`
  font-family: Roboto;
  font-size: 0.875rem;
  color: #4d5866;
`;

export const tierMessage = css`
  color: #4d5866;
  display: inline-block;
  font-family: Roboto;
  font-size: 0.875rem;
  max-width: 350px;
`;

export const tierServices = css`
  padding-left: 16px;
`;

export const tierSurchargeKey = tierServices;
export const tierRuleOutcome = tierServices;

export const tierLabel = css`
  width: 192px;
  padding-left: 8px;
`;

export const customCopyLabel = css`
  display: inline-block;
  font-weight: bold;
  padding-right: 4px;
`;

export const tierOptionSelectionSummary = css`
  display: inline-block;
  margin-left: 24px;
  padding: 4px 6px;
  border-radius: 2px;
  border: solid 1px #0051b2;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.14;
  text-align: center;
  color: #0051b2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const tierOptionSelectionList = css`
  margin: 0 0 0 24px;
  padding: 0;
  list-style: none;
`;

export const tierOptionSelectionItem = css`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #479aff;
`;
