import { createSelector } from 'reselect';
import property from 'lodash/property';
import getLogisticsState from './getLogisticsState';
import { LogisticsRootState } from '../types/state';
import { RootState } from '../../../stores/types';

export default createSelector<RootState, LogisticsRootState, string>(
  getLogisticsState,
  property('activeShippingLane'),
);
