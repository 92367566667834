export interface Error {
  field?: string;
  message?: string;
}

export default function getErrorMessageForField(fieldName: string, errors: Error[] = []): string {
  const errorsForField = errors.filter((e) => {
    if (e.field) {
      return e.field === fieldName;
    }

    return false;
  });

  return errorsForField.map((e) => e.message).join('\n');
}
