import { css } from '@emotion/css';
import colors from '../../../../theme/tokens/colors';

export const copy = css`
  color: ${colors.steel};
  font-size: 1.5rem;
  font-weight: 300;
  margin: 1.125rem 0 3.125rem 0;
`;

export const buttonContainer = css`
  display: flex;
  justify-content: center;
`;

export const button = css`
  width: 408px;
`;
