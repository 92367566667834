import { LegacyResponse } from '../types/server';
import Client from './client';
import { AuthResponse } from './types';

export default class Auth extends Client {
  constructor(host: string, auth: string, headers: Record<string, string>) {
    super({
      serviceName: 'server',
      host,
      auth,
      headers,
    });
  }

  postRegister(options = {}): Promise<LegacyResponse<AuthResponse>> {
    Object.assign(options, { method: 'POST' });
    return this.makeRequest(`${this.host}/auth/register`, options);
  }

  postLogin(options = {}): Promise<LegacyResponse<AuthResponse>> {
    Object.assign(options, { method: 'POST' });
    return this.makeRequest(`${this.host}/auth/login`, options);
  }

  logout(options = {}): Promise<LegacyResponse<{}>> {
    return this.makeRequest(`${this.host}/auth/logout`, { ...options, method: 'POST' });
  }

  authorizeDocs(options = {}): Promise<LegacyResponse> {
    return this.makeRequest(`${this.host}/auth/docs`, { ...options, method: 'POST' });
  }

  authorizeRedirect(options = {}): Promise<LegacyResponse> {
    return this.makeRequest(`${this.host}/auth/redirect`, { ...options, method: 'POST' });
  }
}
