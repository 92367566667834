import { compose } from 'redux';
import withValidation from '../../../components/with-validation';
import withSubmit from '../../../components/with-submit';
import withRedirectSubmitted from '../../../components/with-redirect-submitted';
import validator from '../../../utilities/validator';
import { registerUser } from '../actions';
import Registration from '../components/registration';
import { ValidationFieldsMap } from '../../../components/with-validation/with-validation';

export default compose(
  withSubmit((formData) => registerUser(formData)),
  withRedirectSubmitted('/register/confirmation'),
  withValidation({
    confirmPassword: {
      validations: (value: string, fields: ValidationFieldsMap): string | undefined => {
        if (!validator.validate(value, { required: true })) {
          return 'Password confirmation is required';
        }

        if (value !== fields.password.value) {
          return 'These passwords do not match. Try again?';
        }

        return undefined;
      },
    },
    email: {
      validations: [{
        required: true,
        message: 'Email address is required',
      }, {
        pattern: 'email',
        message: 'That doesn\'t look like a valid email address',
      }],
    },
    password: {
      validations: [{
        required: true,
        message: 'Password is required',
      }, {
        minLength: 8,
        message: 'Short passwords are easy to guess. Try one with at least 8 characters.',
      }],
    },
  }),
)(Registration);
