import {
  Printer, AlertOutline, Trash, PackageOutline, ErrorOutline, RectangleOutline, ListBulletRectangle,
} from '@flowio/react-icons';

import React from 'react';
import classNames from 'classnames';

import ActivityKind from '../../constants/ActivityKind';
import { ActivityItemProps } from '../../types/components';
import * as styles from './ActivityItem.styles';
import { FulfillmentActivity } from '../../types/activities';
import ActivityItemContent from './ActivityItemContent';

const getContent = (activity: FulfillmentActivity) => {
  switch (activity.kind) {
    case ActivityKind.SCAN_ITEM:
      return (
        <span className={styles.content}>
          <ActivityItemContent icon={PackageOutline} title="Item Scanned" subtitle={`Item #${activity.number}`} timestamp={activity.timestamp} />
        </span>
      );
    case ActivityKind.CHANGE_QUANTITY:
      return (
        <span className={styles.content}>
          <ActivityItemContent icon={PackageOutline} title={`Item Qty Changed to ${activity.quantity}`} subtitle={`Item #${activity.number}`} timestamp={activity.timestamp} />
        </span>
      );
    case ActivityKind.UNSCAN_ITEM:
      return (
        <span className={styles.content}>
          <ActivityItemContent icon={PackageOutline} title="Item Unscanned" subtitle={`Item #${activity.number}`} timestamp={activity.timestamp} />
        </span>
      );
    case ActivityKind.SCAN_ORDER:
      return (
        <span className={classNames(styles.content, styles.scanOrder)}>
          <ActivityItemContent icon={ListBulletRectangle} title="Order Scanned" subtitle={`Order #${activity.orderNumber}`} timestamp={activity.timestamp} />
        </span>
      );
    case ActivityKind.PRINT_LABEL:
      return (
        <span className={classNames(styles.content, styles.printLabel)}>
          <ActivityItemContent icon={Printer} title="Label Printed" subtitle={`Order #${activity.orderNumber}`} timestamp={activity.timestamp} />
        </span>
      );
    case ActivityKind.PRINT_INVOICE:
      return (
        <span className={classNames(styles.content, styles.printLabel)}>
          <ActivityItemContent icon={Printer} title="Invoice Printed" subtitle={`Order #${activity.orderNumber}`} timestamp={activity.timestamp} />
        </span>
      );
    case ActivityKind.API_ERROR:
      return (
        <span className={classNames(styles.content, styles.apiError)}>
          <ActivityItemContent icon={ErrorOutline} title={activity.error} subtitle="" timestamp={activity.timestamp} />
        </span>
      );
    case ActivityKind.PRINT_ERROR_REPORT:
      return (
        <span className={classNames(styles.content, styles.printErrorReport)}>
          <ActivityItemContent icon={Printer} title="Error Report Printed" subtitle={`Order #${activity.orderNumber}`} timestamp={activity.timestamp} />
        </span>
      );
    case ActivityKind.UNIDENTIFIED_ITEM:
      return (
        <span className={classNames(styles.content, styles.unidentifiedItem)}>
          <ActivityItemContent icon={AlertOutline} title="Item Not Part of Order" subtitle="" timestamp={activity.timestamp} />
        </span>
      );
    case ActivityKind.UNIDENTIFIED_NUMBER:
      return (
        <span className={classNames(styles.content, styles.unidentifiedNumber)}>
          <ActivityItemContent icon={AlertOutline} title="Invalid Item or Order Scanned" subtitle="" timestamp={activity.timestamp} />
        </span>
      );
    case ActivityKind.CLEAR_ITEMS:
      return (
        <span className={styles.content}>
          <ActivityItemContent icon={PackageOutline} title="All Items Unselected" subtitle={`Order #${activity.number}`} timestamp={activity.timestamp} />
        </span>
      );
    case ActivityKind.SELECT_ALL_ITEMS:
      return (
        <span className={styles.content}>
          <ActivityItemContent icon={PackageOutline} title="All Items Selected" subtitle={`Order #${activity.number}`} timestamp={activity.timestamp} />
        </span>
      );
    case ActivityKind.CLEAR_ORDER:
      return (
        <span className={classNames(styles.content, styles.clearOrder)}>
          <ActivityItemContent icon={RectangleOutline} title="Order Cleared" subtitle="" timestamp={activity.timestamp} />
        </span>
      );
    case ActivityKind.ALREADY_SCANNED:
      return (
        <span className={classNames(styles.content, styles.alreadyScanned)}>
          <ActivityItemContent icon={Trash} title="Already scanned maximum quantity for item" subtitle="" timestamp={activity.timestamp} />
        </span>
      );
    case ActivityKind.LABEL_NOT_PRINTED:
      return (
        <span className={classNames(styles.content, styles.printErrorReport)}>
          <ActivityItemContent icon={AlertOutline} title="No Label Printed" subtitle={`Order #${activity.number}`} timestamp={activity.timestamp} />
        </span>
      );
    default:
      return null;
  }
};

const ActivityItem: React.FC<ActivityItemProps> = ({ activity }) => {
  const content = getContent(activity);
  const isScanOrder = activity.kind === ActivityKind.SCAN_ORDER;
  return (
    <li className={classNames(styles.activityItem, { [styles.scanActivityItem]: isScanOrder })}>
      {content}
    </li>
  );
};

export default ActivityItem;
