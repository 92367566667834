import { css } from '@emotion/css';

export const orderItem = css`
  margin: 28px 0;
  justify-content: space-between;
`;

export const itemNumber = css`
  font-size: 24px;
  font-weight: 500;
  line-height: 1.21;
  color: #17181a;
`;

export const itemName = css`
  font-size: 14px;
  line-height: 1.29;
  color: #77818f;
`;

export const itemInfo = css`
  height: 100%;
`;

export const selectorContainer = css`
  display: flex;
  align-items: center;
`;

export const itemSelector = css`
  margin-right: 34px !important;
`;

export const itemQuantityChange = css`
  &:disabled {
    border: none !important; /*to overide style of button*/
    background-color: white !important; /*to overide style of button*/
    outline: none;
    cursor: pointer;
  }
`;

export const itemQuantityChangeBtn = itemQuantityChange;

export const quantityInput = css`
  color: #0051b2 !important;
  max-width: 50px;
  width: 40px !important;
  & input {
    text-align: center;
    -moz-appearance: textfield;
    width: 100% !important; /* due to width: auto, in firfox the text field expands out of the container */
  }
  & ::-webkit-outer-spin-button,
  & ::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    appearance: none !important;
    margin: 0  !important;
  }
  padding-top: 0 !important; // else the padding in textInput hides the value in it
  padding-right: 0 !important;
  padding-bottom: 0 !important;
`;

export const icon = css`
  width: 16px;
  height: auto;
`;

export const quantityLabel = css`
  color: #17181a;
  white-space: nowrap;
`;

export const quantityTextField = css`
  text-align: center;
`;

export const itemImage = css`
  border: solid 1px #e4e7eb;
  height: 64px !important;
  width: 64px;
`;

export const checkIcon = css`
  cursor: pointer;
`;

export const itemDetailsWrapper = css`
  flex: 1 1;
`;
