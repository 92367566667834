import { RootState } from '../../stores/types';
import { FormattedErrorMessages } from '../../utilities/format-error-response-v2';
import { LabKey } from './constants/lab-keys';
import { LabsState } from './types';

export const getLabsState = (state: RootState): LabsState => (state.labs);

export const isLabsAcceptDialogOpen = (state: RootState): boolean => {
  const labsState = getLabsState(state);
  return labsState.ui.acceptDialogOpen;
};

export const getCurrentLabProjectSettings = (
  state: RootState,
): io.flow.internal.v0.models.LabProjectSettings | undefined => {
  const labState = getLabsState(state);
  return labState.current;
};

export const getLabProjectErrors = (state: RootState): FormattedErrorMessages | undefined => {
  const labState = getLabsState(state);
  return labState.error;
};

export const getAcceptingUser = (state: RootState): io.flow.v0.models.User | undefined => {
  const labState = getLabsState(state);
  return labState.acceptingUser;
};

export const getLabs = (state: RootState): LabsState['labsSettings'] => {
  const labState = getLabsState(state);
  return labState.labsSettings;
};

export const getLabSettings = (
  state: RootState,
  key: LabKey,
): io.flow.internal.v0.models.LabProjectSettings | undefined => {
  const labState = getLabsState(state);
  const { labsSettings } = labState;

  return labsSettings.find((setting) => setting.flow_lab_project_key === key);
};
