/**
 * @fileoverview
 *
 * A set of utilities for formatting numbers.
 *
 * If this starts growing too big there are some related projects out in the
 * community that we can consider installing to avoid reinventing the wheel:
 *
 * - http://numeraljs.com/
 */

import round from 'lodash/round';
import ceil from 'lodash/ceil';
import floor from 'lodash/floor';
import map from 'lodash/map';
import remove from 'lodash/remove';
import reduce from 'lodash/reduce';
import orderBy from 'lodash/orderBy';
import some from 'lodash/some';

/**
 * Formats an integer or floating number to be delimited with commas.
 * If you're using this you may be doing it wrong... see `withNumberFormat`
 */
export function toNumberWithCommas(number: number): string {
  const parts = number.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}

export function pow(base: number, exponent: number): number {
  return base ** exponent;
}

export function getRoundingMethodByName(method: string): typeof round | typeof ceil | typeof floor {
  switch (method) {
    case 'round': return round;
    case 'ceil': return ceil;
    case 'floor': return floor;
    default: return round;
  }
}

/**
 * Computes the floating number representation for the percentage of amount
 * relative to total.
 * @param {Number} amount
 * @param {Number} total
 * @return {Number} An integer or floating number between 0 and 1, not rounded.
 */
export const calculatePercentage = (amount: number, total: number): number => {
  if (Number.isNaN(amount) || Number.isNaN(total) || total === 0) return 0;
  return (amount / total);
};

/**
 * Converts number into a percentage string representation.
 * @param {Number} number The value to be turned into a percentage string.
 * @return {String}
 */
export function toPercentage(number: number): string {
  return `${number}%`;
}

/**
 * Converts floating number into a rounded percentage string representation.
 * @param {Number} precision The number of digits to appear after the decimal point
 * @param {String} method The rounding method to use. Could be one of `"round"`,
 * `"floor"`, or `"ceil"`
 * @param {Number} float The floating number to be converted. It must be a
 * number between 0 and 1, inclusive.
 */
export function toPercentageBy(precision = 2, method = 'round'): (float: number) => string {
  const roundingMethod = getRoundingMethodByName(method);
  return function toPercentageReturn(float: number): string { // eslint-disable-line no-shadow
    const percentage = roundingMethod((float * pow(10, 4)) / pow(10, 2), precision);

    // When rounded result is zero, but actual value is greater than zero,
    // returns a `<1%` string. This is Flow specific UI requirement.
    if (float > 0 && percentage === 0) {
      return '<1%';
    }

    return `${percentage}%`;
  };
}

/**
 * Round percentage values to whole numbers and ensure their sum is equal to
 * specified target value. Uses the Hare-Niemeyer method algorithm.
 * @see https://en.wikipedia.org/wiki/Largest_remainder_method
 */
export function roundPercentagesBy(target = 100): (...numbers: number[]) => number[] {
  interface RoundPercentageReturn {
    integer: number;
    decimal: number;
    include: boolean;
    index: number;
  }

  return function roundPercentage(...numbers: number[]): number[] {
    let parts = map<number, RoundPercentageReturn>(numbers, (number, index) => {
      const integer = number | 0; // eslint-disable-line no-bitwise
      const decimal = number % 1;
      const include = number !== 0;
      return {
        integer, decimal, include, index,
      };
    });

    // bail when all numbers cannot be used to distribute remaining values.
    if (!some(parts, 'include')) {
      return numbers;
    }

    const total = reduce(parts, (sum, part) => sum + part.integer, 0);
    const diff = target - total;

    // distribute remaining values evenly across non-zero values
    if (diff !== 0) {
      parts = orderBy(parts, 'decimal', 'desc');
      const removed = remove(parts, { include: false });
      const { length } = parts;

      let i = 0;
      let j = 0;

      while (i < Math.abs(diff)) {
        j = i < length ? i : i % length;
        if (diff > 0) parts[j].integer += 1;
        else parts[j].integer -= 1;
        i += 1;
      }

      parts = parts.concat(removed);
    }

    parts = orderBy(parts, 'index', 'asc');
    return map(parts, 'integer');
  };
}

/**
 * An enhanced parseInt utility with default value.
 * @param {*} value
 * @param {Number} base
 * @param {Number} defaultValue
 */
export const parseInteger = (value: string, base: number, defaultValue: number): number => {
  const parsedValue = Number.parseInt(value, base);
  return (Number.isNaN(parsedValue)) ? defaultValue : parsedValue;
};
