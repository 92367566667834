import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { isInvalid, isSubmitting, submit } from 'redux-form';
import overSome from 'lodash/overSome';
import {
  getIsDialogOpen,
  getDialogProp,
} from '../selectors';
import {
  hideEditFeatureWorksheet,
} from '../actions';
import EditFeatureWorksheet from '../components/EditFeatureWorksheet';
import FormName from '../constants/FormName';
import DialogName from '../constants/DialogName';
import {
  EditFeatureWorksheetStateProps,
  EditFeatureWorksheetFormValues,
  EditFeatureWorksheetDispatchProps,
} from '../types/components';
import { RootState, ThunkDispatcher } from '../../../stores/types';

const form = FormName.EDIT_FEATURE_FORM;
const worksheet = DialogName.EDIT_FEATURE_WORKSHEET;

const submitForm = () => submit(form);

const mapStateToProps = (state: RootState): EditFeatureWorksheetStateProps => ({
  isSaveDisabled: overSome([isSubmitting(form), isInvalid(form)])(state),
  initialValues: getDialogProp(worksheet, 'initialValues')(state) as Partial<EditFeatureWorksheetFormValues>,
  open: getIsDialogOpen(worksheet)(state) as boolean,
});

function mapDispatchToProps(dispatch: ThunkDispatcher): EditFeatureWorksheetDispatchProps {
  return bindActionCreators({
    onCancel: hideEditFeatureWorksheet,
    onSave: submitForm,
  }, dispatch);
}

export default compose<React.FC>(
  connect(mapStateToProps, mapDispatchToProps),
)(EditFeatureWorksheet);
