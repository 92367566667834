import { css } from '@emotion/css';

export const positionText = css`
  display: inline-block;
`;

export const subtext = css`
  font-size: 12px;
`;

export const text = css`
  color: #000000;
`;

export const indicator = css`
  top: -2px;
  vertical-align: unset !important;
`;

export const firstLabel = css`
  padding: 10px
`;

export const lastLabel = css`
  padding: 10px;
`;

export const positionRadioGroup = css`
  display: flex;
  flex-direction: column;
`;
