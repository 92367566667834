import React, { useState, useEffect } from 'react';
import map from 'lodash/map';
import { Button, FlatButton } from '@flowio/react-button';
import {
  Dialog, DialogHeader, DialogFooter, DialogActions, DialogBody,
} from '@flowio/react-dialog';
import { TextInputField } from '@flowio/react-text-input';
import noop from 'lodash/noop';

import Alert from '../../../../../components/alert/alert';
import { Props } from '../types';
import { WithValidationProps, ValidationSubmitFunction } from '../../../../../components/with-validation/with-validation';
import { WithSubmitProps } from '../../../../../components/with-submit/with-submit';
import getTextFieldValue from '../../../../../utilities/getTextFieldValue';

const ExportFormDialog: React.FC<Props & WithSubmitProps & WithValidationProps> = ({
  onSubmit,
  onSubmitValidate,
  submitted,
  submitting,
  open,
  onRequestClose,
  fields: {
    email,
  },
  error,
}) => {
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(submitted);

  useEffect(() => {
    if (!hasSubmitted && submitted) {
      setHasSubmitted(submitted);
    }
  }, [hasSubmitted, submitted]);

  const handleSubmit: ValidationSubmitFunction = ({ isValid }, formData) => {
    if (isValid) {
      onSubmit(formData);
    }
  };

  const actions = [];

  if (hasSubmitted) {
    actions.push(
      <Button css={{ minWidth: '128px' }} key="gotIt" intent="primary" content="Got It!" onClick={onRequestClose} />,
    );
  } else {
    actions.push(
      <FlatButton css={{ minWidth: '128px' }} key="close" content="Cancel" disabled={submitting} onClick={onRequestClose} />,
    );
    actions.push(
      <Button
        key="confirm"
        intent="primary"
        content={submitting ? 'Exporting...' : 'Export'}
        disabled={submitting}
        css={{ marginLeft: '1rem', minWidth: '128px' }}
        onClick={onSubmitValidate(handleSubmit)}
      />,
    );
  }

  return (
    <Dialog css={{ maxWidth: '480px' }} backdrop closeOnEscape closeOnClickAway open={open}>
      <DialogHeader content="Export Products" />
      <DialogBody>
        {((): React.ReactNode => {
          if (hasSubmitted) {
            return (
              <p>
                Your request has been submitted successfully. We will send an email notification
                to
                {' '}
                <strong>{email.value}</strong>
                {' '}
                once the export CSV file is created.
              </p>
            );
          }

          return (
            <form method="post" noValidate onSubmit={onSubmitValidate(handleSubmit)}>
              {map(error && error.messages, ({ message }) => (
                <Alert type="failure">
                  <p>
                    {message}
                  </p>
                </Alert>
              ))}
              <p>
                An email will be sent to the email address provided once the
                export file is generated.
              </p>
              <TextInputField
                onChange={(e) => email.events.onChange(e)}
                onBlur={(e) => email.events.onChange(e)}
                fluid
                autoFocus
                type="email"
                name="email"
                value={getTextFieldValue(email.value)}
                feedback={email.error}
                hintText="Enter your email address"
                intent={email.error ? 'negative' : 'neutral'}
                clearable
                onClear={() => email.events.onChange('')}
              />
            </form>
          );
        })()}
      </DialogBody>
      <DialogFooter>
        <DialogActions>
          {actions}
        </DialogActions>
      </DialogFooter>
    </Dialog>
  );
};

ExportFormDialog.displayName = 'ExportFormDialog';

ExportFormDialog.defaultProps = {
  email: undefined,
  numbers: [],
  q: undefined,
  sort: undefined,
  onRequestClose: noop,
};

export default ExportFormDialog;
