import ActionTypes from '../constants/ActionTypes';
import { MarketingActions, FeedsCreationConfirmationDialogProps } from '../types';

function showFeedsCreationConfirmationDialog(
  data: FeedsCreationConfirmationDialogProps,
): MarketingActions {
  return {
    type: ActionTypes.FEEDS_CREATION_CONFIRMATION_DIALOG_OPEN,
    data,
  };
}

export default showFeedsCreationConfirmationDialog;
