import Client from './client';

export default class LabelCreationJobs extends Client {
  constructor(opts) {
    let options = opts;

    if (typeof opts === 'string') {
      options = { host: opts }; // convert host string to options object
    }

    options.serviceName = 'API Internal';

    super(options);
  }

  post(options = {}) {
    return this.makeRequest(`${this.host}/internal/label/creation/requests`, {
      ...options,
       method: 'POST',
    });
  }

  putCancelByReferenceId(referenceId, options = {}) {
    return this.makeRequest(`${this.host}/internal/label/creation/requests/cancel/${referenceId}`, {
      ...options,
       method: 'PUT',
    });
  }

  getByReferenceId(referenceId, options = {}) {
    return this.makeRequest(`${this.host}/internal/label/creation/requests/${referenceId}`, options);
  }

}
