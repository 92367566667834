import { Confirm } from '@flowio/react-confirm';
import React from 'react';

import TierDeleteForm from '../../containers/TierDeleteForm';
import { TierDeleteConfirmationProps } from '../../types/components';

const TierDeleteConfirmation: React.FC<TierDeleteConfirmationProps> = ({
  initialValues = {},
  isOpen = false,
  isSubmitting = false,
  onCancel,
  onOk,
  onSuccess,
}) => (
  <Confirm
    caption="Delete Shipping Tier"
    disabled={isSubmitting}
    intent="negative"
    onCancel={onCancel}
    onOk={onOk}
    open={isOpen}
  >
    <TierDeleteForm
      initialValues={initialValues}
      onSubmitSuccess={onSuccess}
    />
  </Confirm>
);

TierDeleteConfirmation.displayName = 'TierDeleteConfirmation';

export default TierDeleteConfirmation;
