import { $FetchOptions, $HttpHeaders } from '@flowio/api-sdk';
import { getInstance } from '../rollbar';
import { RootState } from '../../stores/types';
import randomString from '../random-string';
import { isValidJwt } from '../jwt';

// Generate a random request identifier for logging
function getRequestId(): string {
  return `con${randomString(20)}`;
}

export default function wrappedFetch(
  url: string,
  options: $FetchOptions = {},
): Promise<Response> {
  const requestId = getRequestId();

  const headers: $HttpHeaders = {
    ...options.headers,
    'x-flow-request-id': requestId,
  };

  return fetch(url, {
    ...options,
    credentials: 'same-origin',
    headers,
  });
}

export function getHeaderOptions(url: string, auth?: string, options?: $HttpHeaders): $HttpHeaders {
  if (auth && !isValidJwt(auth)) {
    const rollbar = getInstance();
    const authError = new Error('Bad JWT passed for auth');
    rollbar.error(
      authError,
      {
        fetchUrl: url,
        authToken: auth,
      },
    );
  }

  let fetchOptions;

  if (!auth) {
    fetchOptions = {
      ...options,
    };
  } else {
    fetchOptions = {
      ...options,
      authorization: `Bearer ${auth}`,
    };
  }

  return fetchOptions;
}

export function getFetchWithAuth(
  state: RootState,
): (url: string, options: $FetchOptions | undefined) => Promise<Response> {
  if (!state.defaultReducer.user) {
    throw new Error('Missing user auth in application state');
  }
  const {
    auth,
  } = state.defaultReducer.user;

  return (
    url: string,
    options: $FetchOptions = {},
  ): Promise<Response> => fetch(
    url,
    {
      ...options,
      headers: {
        ...getHeaderOptions(url, auth, options.headers),
      },
    },
  );
}
