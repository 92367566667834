import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import map from 'lodash/map';
import noop from 'lodash/noop';
import {
  Card, CardHeader, CardContent, CardFooter, CardEmptyState, CardTitle,
} from '@flowio/react-card';
import { Box } from '@flowio/react-box';
import { Button } from '@flowio/react-button';
import { OrganizationType } from '@flowio/api-constants';
import { isBoolean, isString, startCase } from 'lodash';
import CatalogTable from './catalog-table';
import Pagination from '../../../../../components/pagination';
import ExportFormDialog from '../../../../search/components/export-form-dialog/containers/export-form-dialog';
import SearchFunctionDialog from '../../search-functions-dialog';
import SearchBox from '../../SearchBox';
import { MergedProps, ItemSearchOptions, SearchFilter } from '../types';
import getQueryParamValue from '../../../../utilities/get-query-parameter-value';
import { SearchDropdownValue } from '../../../types';
import * as styles from './product-catalog.styles';
import ProductSearchTypeDropdown from './product-search-type-dropdown';
import getItemSearchFiltersFromUrl from '../../../../utilities/get-item-search-filters-from-url';

const ProductCatalog: React.FC<MergedProps> = ({
  location,
  items,
  onRequestSearch,
  onRequestSMPItemsSearch,
  apiToken,
  organizationId,
  isFirstPage,
  organization,
  isLastPage,
  onRequestProduct,
  items: propItems,
  email: propEmail,
  attributes: propAttributes,
  onRequestNextPage: propOnRequestNextPage,
  onRequestPreviousPage: propOnRequestPreviousPage,
  catalogSearchTerm,
}) => {
  const [showExportFormDialog, setShowExportFormDialog] = useState(false);
  const [toggledSearchFilters, setToggledSearchFilters] = useState<SearchFilter[]>([]);
  const [
    selectedFilter,
    setSelectedFilter,
  ] = useState<keyof ItemSearchOptions | undefined>(undefined);
  const [isSearchFunctionDialogOpened, setIsSearchFunctionDialogOpened] = useState(false);
  const isChannelOrg = organization.type === OrganizationType.CHANNEL;
  const getDefaultSearchBoxValue = (): SearchDropdownValue[] | undefined => {
    const qParam = get(location, 'query.q');

    return qParam ? map(qParam.split(' and '), (q) => ({ q })) : undefined;
  };

  const handleExportButtonClick = (): void => {
    setShowExportFormDialog(true);
  };

  const handleExportFormCloseRequest = (): void => {
    setShowExportFormDialog(false);
  };

  const handleSearchFunctionDialogRequestClose = (): void => {
    setIsSearchFunctionDialogOpened(false);
  };

  const handleSearchChange = (
    options: string[],
  ): void => {
    const searchString = options.join(' and ');
    onRequestSearch(searchString);
  };

  const hasItems = (): boolean => !isEmpty(items);

  const empty = !hasItems();

  useEffect(() => {
    const searchFilters = getItemSearchFiltersFromUrl();
    setToggledSearchFilters(searchFilters);
  }, []);

  const handleFilterSubmit = (
    searchType: keyof ItemSearchOptions,
    value: string | number | boolean | undefined,
  ): void => {
    if (value && (isString(value) || (isBoolean(value)))) {
      const url = new URL(window.location.href);
      url.searchParams.set('pageNumber', '1');
      url.searchParams.set('entriesPerPage', '25');
      url.searchParams.set(searchType, isBoolean(value) ? value.toString() : value);
      window.history.pushState({}, '', url.toString());
    }

    setToggledSearchFilters([
      ...toggledSearchFilters, { label: startCase(searchType), value, key: searchType },
    ]);
    setSelectedFilter(undefined);
    const filterValues = toggledSearchFilters.map((filter) => ({ [filter.key]: filter.value }));
    const s = Object.assign({}, { [searchType]: value }, ...filterValues);
    onRequestSMPItemsSearch(s);
  };

  const handleRemoveAllFilters = () => {
    const url = new URL(window.location.href);
    toggledSearchFilters.forEach((filter) => {
      url.searchParams.delete(filter.key);
    });
    window.history.pushState({}, '', url.toString());
    setToggledSearchFilters([]);
    onRequestSMPItemsSearch({});
  };

  const handleRemoveFilter = (key:keyof ItemSearchOptions) => {
    const updatedFilters = toggledSearchFilters.filter(
      (filter) => filter.key !== key,
    );
    setToggledSearchFilters(updatedFilters);
    onRequestSMPItemsSearch(
      Object.assign(
        {},
        ...updatedFilters.map((filter) => ({ [filter.key]: filter.value })),
      ),
    );
    const url = new URL(window.location.href);
    url.searchParams.delete(key);
    window.history.pushState({}, '', url.toString());
  };

  return (
    <div className={styles.productCatalog}>
      <Card>
        <CardHeader dividing>
          <Box alignItems="center">
            <CardTitle content="Catalog Items" />
          </Box>
          <div className={styles.searchContainer}>
            {isChannelOrg
              ? (
                <ProductSearchTypeDropdown
                  setSelectedFilter={setSelectedFilter}
                  selectedFilter={selectedFilter}
                  toggledSearchFilters={toggledSearchFilters}
                  onRemoveFilter={handleRemoveFilter}
                  onRemoveAllFilters={handleRemoveAllFilters}
                  onFilterSubmit={handleFilterSubmit}
                />
              ) : (
                <SearchBox
                  triggerTheme={styles.searchTrigger}
                  hintText="Search Categories, SKUs, Name, Experience & More"
                  onChange={handleSearchChange}
                  organizationId={organizationId}
                  apiToken={apiToken}
                  defaultValue={getDefaultSearchBoxValue()}
                />
              )}

          </div>
        </CardHeader>
        <CardContent>
          {empty ? (
            <CardEmptyState content="No items found." />
          ) : (
            <CatalogTable
              items={propItems}
              onItemSelection={onRequestProduct}
              attributes={propAttributes}
            />
          )}
        </CardContent>
        <CardFooter>
          <Box alignItems="center" justifyContent="between" className={styles.pagination}>
            <Button
              content="Export Product List"
              intent="primary"
              onClick={handleExportButtonClick}
            />
            <Pagination
              isPreviousPageDisabled={isFirstPage}
              isNextPageDisabled={isLastPage}
              onRequestPreviousPage={propOnRequestPreviousPage}
              onRequestNextPage={propOnRequestNextPage}
            />
          </Box>
          <ExportFormDialog
            open={showExportFormDialog}
            email={propEmail}
            q={catalogSearchTerm}
            sort={getQueryParamValue(get(location, 'query.sort'))}
            onRequestClose={handleExportFormCloseRequest}
          />
          <SearchFunctionDialog
            open={isSearchFunctionDialogOpened}
            onRequestClose={handleSearchFunctionDialogRequestClose}
          />
        </CardFooter>
      </Card>
    </div>
  );
};

ProductCatalog.displayName = 'ProductCatalog';

ProductCatalog.defaultProps = {
  isFirstPage: false,
  isLastPage: false,
  items: [],
  onRequestNextPage: noop,
  onRequestPreviousPage: noop,
  onRequestProduct: noop,
  onRequestSearch: noop,
  catalogSearchTerm: '',
  email: undefined,
  attributes: undefined,
};

export default ProductCatalog;
