import update from 'immutability-helper';

import ActionTypes from '../constants/action-types';
import { ExperienceState } from '../types';
import { RootActionTypes } from '../../../stores/types';

type State = ExperienceState['entities']['pricing'];

export default function (state = {}, action: RootActionTypes): State {
  switch (action.type) {
    case ActionTypes.UPDATE_PRICING_SUCCESS:
    case ActionTypes.GET_EXPERIENCE_PRICING_SUCCESS:
      return update(state, { current: { $set: action.payload } });
    case ActionTypes.CLEAR_ITEM_PRICING:
      return update(state, { $unset: ['current'] });
    default:
      return state;
  }
}
