/**
 * @fileoverview
 * Provides utilities for Flow Commerce API sort parameter resolution and parsing.
 */

import isString from 'lodash/isString';
import isPlainObject from 'lodash/isPlainObject';

const regex = /^([\+\-]?)(.+)/;

/**
 * Returns a formatted sort parameter string derived from `sortObject`
 * @param {Object} sortObject
 * @throws {TypeError} when `sortObject` is not an object or a string.
 */
export function format(sortObject) {
  if (isString(sortObject)) {
    return sortObject;
  }

  if (!isPlainObject(sortObject)) {
    throw new TypeError(`${typeof sortObject} is an invalid type.`);
  }

  const { value, direction } = sortObject;

  let symbol = '';

  if (direction === 'ascending') symbol = '+';
  if (direction === 'descending') symbol = '-';

  return `${symbol}${value}`;
}

/**
 * Returns a sort object after parsing the provided string.
 * @param {String} sortString
 * @throws {TypeError} when `sortString` is not a string.
 */
export function parse(sortString) {
  if (!isString(sortString)) {
    throw new TypeError(`${typeof sortObject} is an invalid type.`);
  }

  const [, $1, $2] = sortString.match(regex);

  let direction = 'ascending';

  if ($1 === '-') direction = 'descending';

  return { direction, value: $2 };
}
