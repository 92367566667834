import { RouterState } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFetch } from '@flowio/redux-fetch';
import { setOverlayProps } from '@flowio/redux-form-overlay';
import { submit, getFormSyncErrors } from 'redux-form';

import { OrganizationType } from '@flowio/api-constants';
import OrderDetails from '../components/order-details';
import { fetchOrganization, getOrganization } from '../../organization';
import {
  getOrderDetail,
  getNotificationMessage,
  getRefundDialogState,
  getRefundOrderSummary,
  getReturnShippingTiers,
  getReturnDialogState,
  getReturnConfirmationState,
  getReturnDetailState,
  getApproveFraudReviewDialogState,
  getDeclineFraudReviewDialogState,
  getBusinessInvoices,
  getConsumerInvoices,
  getCreditMemos,
  getOriginalDestination,
  getOrderTrackingDetails,
  getAllocations,
  getShippingNotifications,
  getBusinessCreditMemos,
  getCustomerServiceState,
  getDebugTransactions,
} from '../selectors';
import fetchOrderDetailByNumber from '../actions/fetch-order-detail-by-number';
import fetchRefundOrderSummary from '../actions/fetch-refund-order-summary';
import getUserEmail from '../../console/selectors/get-user-email';
import hideRefundFormDialog from '../actions/hide-refund-form-dialog';
import hideReturnFormDialog from '../actions/hide-return-form-dialog';
import hideReturnDetailsDialog from '../actions/hide-return-detail-dialog';
import hideReturnNumberInput from '../actions/hide-return-number-input';
import hideApproveFraudReviewDialog from '../actions/hide-approve-fraud-review-dialog';
import showApproveFraudReviewDialog from '../actions/show-approve-fraud-review-dialog';
import hideDeclineFraudReviewDialog from '../actions/hide-decline-fraud-review-dialog';
import showDeclineFraudReviewDialog from '../actions/show-decline-fraud-review-dialog';
import showOrderTrackingDetailsDialog from '../actions/show-order-tracking-detail-dialog';
import hideOrderTrackingDetailsDialog from '../actions/hide-order-tracking-detail-dialog';
import fetchOrderVersions from '../actions/fetch-order-versions';
import resetNotification from '../actions/reset-notification';
import resetRefundOrderSummary from '../actions/reset-refund-order-summary';
import showRefundFormDialog from '../actions/show-refund-form-dialog';
import showReturnDetailsDialog from '../actions/show-return-detail-dialog';
import showReturnFormDialog from '../actions/show-return-form-dialog';
import showReturnNumberInput from '../actions/show-return-number-input';
import createFraudReviewDecision from '../actions/create-fraud-review-decision';
import { fetchCurrencies } from '../../reference';
import fetchInvoices from '../actions/fetch-invoices';
import FormNames from '../constants/FormNames';
import fetchAllocations from '../actions/fetch-allocations';
import { getPriceFormat } from '../../experience/actions';
import { getIsCustomerServiceUser, getIsFlowEmployee } from '../../user';
import { createToast } from '../../console/actions';
import { RootState, ThunkDispatcher } from '../../../stores/types';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import {
  FullSelectedItem,
  OrderDetailStateProps,
  OrderDetailDispatchProps,
  OrderDetailProps,
} from '../types';
import fetchShippingNotifications from '../actions/fetchShippingNotifications';
import { fetchFeatures } from '../../features/actions';
import { checkFeatureValueByKey } from '../../console';
import { FeatureKeys } from '../../console/constants';
import { fetchAddressValidationUser, fetchOrderAddressValidation } from '../actions';
import fetchDebugTransactions from '../actions/fetch-debug-transactions';

const refundFormErrorSelector = getFormSyncErrors(FormNames.ORDER_REFUND_FORM);
const getAsyncState = (
  dispatch: ThunkDispatcher,
  _getState: () => RootState,
  { params }: RouterState,
): Promise<[
  void,
  LegacyResponse<io.flow.v0.models.ExperienceCurrencyFormat | undefined>,
  void,
]> => {
  dispatch(resetNotification());
  dispatch(resetRefundOrderSummary());
  return Promise.all([
    dispatch(fetchOrganization(params.organization)),
    dispatch(fetchDebugTransactions({
      order_number: params.number,
      organization_id: params.organization,
    })),
    dispatch(fetchRefundOrderSummary(params.organization, params.number)),
    dispatch(fetchOrderDetailByNumber(params.organization, params.number)),
    dispatch(fetchShippingNotifications({
      orderNumber: params.number,
      organizationId: params.organization,
    })),
    dispatch(fetchInvoices(params.organization, params.number)),
    // currencies used for order refund form currency rounding.
    dispatch(fetchCurrencies()),
    dispatch(fetchFeatures(params.organization)),
  ]).then(([,,, orderDetailsResponse]) => {
    const order = orderDetailsResponse ? orderDetailsResponse.order : undefined;
    return Promise.all([
      dispatch(fetchOrderVersions(
        params.organization,
        params.number,
        order ? order.submitted_at : undefined,
      )),
      dispatch(getPriceFormat(params.organization, order && order.experience ? order.experience.key : '')),
      dispatch(fetchAllocations(order ? order.number : '')),
    ]);
  });
};

const mapStateToProps = (state: RootState, ownProps: RouterState): OrderDetailStateProps => ({
  addressValidation: getCustomerServiceState(state).orderDetail.addressValidation,
  addressValidationUser: getCustomerServiceState(state).orderDetail.addressValidationUser,
  allocations: getAllocations(state) as io.flow.v0.models.AllocationV2,
  approveFraudReviewDialogState: getApproveFraudReviewDialogState(state),
  businessCreditMemos: getBusinessCreditMemos(state),
  businessInvoices: getBusinessInvoices(state),
  consumerInvoices: getConsumerInvoices(state),
  creditMemos: getCreditMemos(state),
  declineFraudReviewDialogState: getDeclineFraudReviewDialogState(state),
  debugTransactions: getDebugTransactions(state),
  isCustomerServiceUser: getIsCustomerServiceUser(state),
  isFlowEmployee: getIsFlowEmployee(state),
  isOrderAddressValidationEnabled: (
    getOrganization(state).type === OrganizationType.CHANNEL
    && checkFeatureValueByKey(FeatureKeys.GLOBAL_LABEL_ADDRESS_VALIDATION)(state)
  ),
  notificationMessage: getNotificationMessage(state),
  orderDetail: getOrderDetail(state),
  orderTrackingDetails: getOrderTrackingDetails(state),
  organization: getOrganization(state),
  originalShippingAddress: getOriginalDestination(state),
  refundFormErrors: refundFormErrorSelector(state),
  refundOrderSummary: getRefundOrderSummary(state),
  returnShippingTiers: getReturnShippingTiers(state),
  refundDialogState: getRefundDialogState(state),
  returnDialogState: getReturnDialogState(state),
  returnConfirmationState: getReturnConfirmationState(state),
  returnDetailState: getReturnDetailState(state),
  shippingNotifications: getShippingNotifications(
    state,
    ownProps.params.organization,
    ownProps.params.number,
  ),
  userEmail: getUserEmail(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatcher): OrderDetailDispatchProps => ({
  onEditShippingAddressEdit: (
    shippingAddress: io.flow.v0.models.OrderAddress,
    organizationId: string,
    orderNumber: string,
  ): void => {
    dispatch(setOverlayProps(FormNames.EDIT_SHIPPING_ADDRESS_FORM, {
      initialValues: {
        organizationId,
        orderNumber,
        ...shippingAddress,
      },
      open: true,
      title: 'Update Shipping Address',
    }));
  },
  onRequestDismissNotificationMessage: (): void => {
    dispatch(resetNotification());
  },
  onRequestHideRefundFormDialog: (): void => {
    dispatch(hideRefundFormDialog());
  },
  onRequestShowRefundFormDialog: (): void => {
    dispatch(showRefundFormDialog());
  },
  onRequestHideReturnFormDialog: (): void => {
    dispatch(hideReturnFormDialog());
  },
  onRequestShowReturnFormDialog: (organization: string, experienceKey: string): void => {
    dispatch(showReturnFormDialog(organization, experienceKey));
  },
  onRequestShowReturnDetailDialog: (
    items: FullSelectedItem[],
    pdfLabel: string,
    pdfInvoice: string,
  ): void => {
    dispatch(showReturnDetailsDialog(items, pdfLabel, pdfInvoice));
  },
  onRequestShowOrderTrackingDetailsDialog: (
    orderNumber: string,
    label: io.flow.internal.v0.models.LabelSummary | undefined,
    notification: io.flow.v0.models.ShippingNotification | undefined,
  ): void => {
    dispatch(showOrderTrackingDetailsDialog(orderNumber, label, notification));
  },
  onRequestHideorderTrackingDetailsDialog: (): void => {
    dispatch(hideOrderTrackingDetailsDialog());
  },
  onRequestHideReturnDetailDialog: (): void => {
    dispatch(hideReturnDetailsDialog());
  },
  onRequestHideApproveFraudReviewDialog: (): void => {
    dispatch(hideApproveFraudReviewDialog());
  },
  onRequestShowApproveFraudReviewDialog: (): void => {
    dispatch(showApproveFraudReviewDialog());
  },
  onRequestHideDeclineFraudReviewDialog: (): void => {
    dispatch(hideDeclineFraudReviewDialog());
  },
  onRequestShowDeclineFraudReviewDialog: (): void => {
    dispatch(showDeclineFraudReviewDialog());
  },
  onRequestShowReturnNumberInput: (): void => {
    dispatch(showReturnNumberInput());
  },
  onRequestHideReturnNumberInput: (): void => {
    dispatch(hideReturnNumberInput());
  },
  onSubmitRefund: (): void => {
    dispatch(submit(FormNames.ORDER_REFUND_FORM));
  },
  onCopyAuthorizationIdentifier: (orderNumber: string): void => {
    navigator.clipboard.writeText(orderNumber).then(() => {
      dispatch(createToast({
        intent: 'positive',
        content: 'Successfully copied Authorization Identifier.',
      }));
    }).catch(() => {
      dispatch(createToast({
        intent: 'negative',
        content: 'Could not copy Authorization Identifier.',
      }));
    });
  },
  onRequestOrganizationFraudReviewApprove: (organization: string, fraudReviewId: string): void => {
    const status: io.flow.v0.enums.FraudStatus = 'approved';

    const form = { status };
    dispatch(createFraudReviewDecision(
      organization,
      fraudReviewId,
      form,
    ));
  },
  onRequestOrganizationFraudReviewDecline: (organization: string, fraudReviewId: string): void => {
    const status: io.flow.v0.enums.FraudStatus = 'declined';
    const form = { status };
    dispatch(createFraudReviewDecision(
      organization,
      fraudReviewId,
      form,
    ));
  },
  onRequestOrderAddressValidation: (organizationId: string, orderNumber: string): void => {
    dispatch(fetchOrderAddressValidation(organizationId, orderNumber)).then((response) => {
      if (response.ok) {
        dispatch(fetchAddressValidationUser(response.body.updated_by_user_id));
      }
    });
  },
});

export default compose<React.FC<OrderDetailProps>>(
  withFetch(getAsyncState),
  connect(mapStateToProps, mapDispatchToProps),
)(OrderDetails);
