import matches from 'lodash/matches';

import { getSelectedOrderItems } from '../selectors';
import { ActionTypes, ActivityKind } from '../constants';
import createActivity from './createActivity';
import { getOrganizationId } from '../../organization';
import { ThunkResult } from '../../../stores/types';
import { ScanItemOptions } from '../types/actions';
import { SelectedOrderItem } from '../types/components';

const scanItem = ({
  item,
  index,
}: ScanItemOptions): ThunkResult<void> => (dispatch, getState) => {
  const selectedItems: SelectedOrderItem[] = getSelectedOrderItems(getState());
  const selectedItem: SelectedOrderItem | undefined = selectedItems.find(matches({
    number: item.number,
    index,
  }));
  const selectedQuantity = selectedItem != null ? selectedItem.quantity : 0;
  const organizationId = getOrganizationId(getState());

  if (selectedQuantity === item.quantity) {
    dispatch(createActivity({
      kind: ActivityKind.ALREADY_SCANNED,
      number: item.number,
      organizationId,
    }));
    return;
  }

  dispatch({
    type: ActionTypes.SCAN_ITEM,
    payload: {
      index,
      number: item.number,
      quantity: Math.min(selectedQuantity + 1, item.quantity),
    },
  });

  dispatch(createActivity({
    kind: ActivityKind.SCAN_ITEM,
    number: item.number,
    organizationId,
  }));
};

export default scanItem;
