import { css } from '@emotion/css';

export const productCatalog = css`
  padding-top: 1rem;
`;

export const searchContainer = css`
  padding-top: 1rem;
`;

export const pagination = css`
  background-color: #ffffff;
  bottom: 0;
  padding: 0 1rem;
`;

export const catalogTableRow = css`
  padding: 10px 0;
  font-size: 12px;
  border-bottom: 1px solid rgb(224, 224, 224);

  &:hover {
    background-color: rgb(250, 250, 250);
  }
`;

export const catalogTableHeader = css`
  border-bottom: 1px solid rgb(224, 224, 224);
`;

export const searchTrigger = css`
  & > div > div > div {
    height: auto;
    padding-top: 4px;
    padding-bottom: 4px;
  }
`;

export const filterContainer = css`
  padding: 1rem;
`;
