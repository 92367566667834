import React, { useState } from 'react';
import classNames from 'classnames';
import { Box, BoxItem } from '@flowio/react-box';
import { ChevronLeft, InfoCircle } from '@flowio/react-icons';
import { Card } from '@flowio/react-card';
import { Image } from '@flowio/react-image';
import explainerPanelStyles from './FeedsExplainer.styles';

const FeedsExplainer: React.FC = () => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const togglePanel = () => setIsPanelOpen((isOpen: boolean) => !isOpen);

  return (
    <Card className={classNames(explainerPanelStyles, { open: isPanelOpen })}>
      <Box
        spacing="loose"
        onClick={togglePanel}
        className="explainerPanelHeader"
      >
        <InfoCircle width={16} />
        <BoxItem flexAuto>
          <span>How does Flow generate localized product feeds?</span>
        </BoxItem>
        <ChevronLeft
          className="panelStateIndicator"
          width={16}
        />
      </Box>
      <Image accessibilityLabel="Explainer Image" className="explainerImage" source="https://cdn.flow.io/console/feed_explainer.svg" width="100%" />
    </Card>
  );
};

FeedsExplainer.displayName = 'FeedsExplainer';

export default FeedsExplainer;
