import { createSelector } from 'reselect';
import getOptinState from './getOptinState';
import { RootState } from '../../../stores/types';
import { OptinState } from '../types';

const getDialogProp = (
  dialog: string,
  prop: string,
) => createSelector<RootState, OptinState, any | undefined>(
  getOptinState,
  (optinState: OptinState) => {
    const dialogProps = optinState.dialogs[dialog];
    return dialogProps ? dialogProps[prop] : undefined;
  },
);

export default getDialogProp;
