import React from 'react';
import { ShopifyAppProps } from '../../types/props';
import AuthLayout from '../auth-layout';

const ShopifyApp: React.FC<ShopifyAppProps> = ({
  assignedShop,
  assignedOrg,
  error,
}) => (
  <AuthLayout>
    {assignedShop && assignedOrg && !error && (
      <span>
        You have successfully installed the Flow Bridge App.
        Your shop now has access to Flow&rsquo;s APIs.
      </span>
    )}
    {((!assignedShop || !assignedOrg) && !error) && (
      <span>
        You have not installed the Flow Bridge app properly.
        Please contact support or reference our docs for help
      </span>
    )}
    {error && (
      <span>
        {error}
      </span>
    )}
  </AuthLayout>
);

export default ShopifyApp;
