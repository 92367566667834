import { compose } from 'redux';
import withSubmit from '../../../components/with-submit';
import withValidation from '../../../components/with-validation';
import { resetPassword } from '../actions';
import ForgotPassword from '../components/forgot-password';

export default compose(
  withSubmit(({ email }: { email: string }) => resetPassword(email)),
  withValidation({
    email: {
      validations: [{
        required: true,
        message: 'Email address is required to reset your password',
      }, {
        pattern: 'email',
        message: 'That doesn\'t look like a valid email address',
      }],
    },
  }),
)(ForgotPassword);
