import { createSelector } from 'reselect';
import get from 'lodash/get';
import getReturnPolicyDeleteConfirmation from './getReturnPolicyDeleteConfirmation';

const getHasReturnPolicyDeleteFailed = createSelector(
  getReturnPolicyDeleteConfirmation,
  (state) => get(state, 'deleteFailed'),
);

export default getHasReturnPolicyDeleteFailed;
