import { combineReducers } from 'redux';
import ActionTypes from '../constants/action-types';
import createStatusReducer from '../../../utilities/redux/createStatusReducer';
import { StatusState } from '../../../utilities/redux/types';

export default combineReducers<Record<string, StatusState>>({
  deliveredDutyOptions: createStatusReducer([
    ActionTypes.FETCH_DELIVERED_DUTY_OPTIONS_REQUEST,
    ActionTypes.FETCH_DELIVERED_DUTY_OPTIONS_SUCCESS,
    ActionTypes.FETCH_DELIVERED_DUTY_OPTIONS_FAILURE,
  ]),
  deliveredDutyPreference: createStatusReducer([
    ActionTypes.FETCH_DELIVERED_DUTY_PREFERENCE_REQUEST,
    ActionTypes.FETCH_DELIVERED_DUTY_PREFERENCE_SUCCESS,
    ActionTypes.FETCH_DELIVERED_DUTY_PREFERENCE_FAILURE,
  ]),
  priceBooks: createStatusReducer([
    ActionTypes.UPDATE_PRICE_BOOK_REQUEST,
    ActionTypes.UPDATE_PRICE_BOOK_SUCCESS,
    ActionTypes.UPDATE_PRICE_BOOK_FAILURE,
  ]),
});
