import React, { FunctionComponent } from 'react';
import { OutlineButton } from '@flowio/react-button';
import find from 'lodash/find';
import first from 'lodash/first';
import includes from 'lodash/includes';
import { Image as ImageComponent } from '@flowio/react-image';
import ProductDescription from './product-description';
import ProductOverview from './product-overview';
import ProductPrice from './product-price';
import * as styles from './product-details.styles';

const displayName = 'ProductDetails';

interface ProductDetailsProps {
  item: io.flow.v0.models.Item;
}

const getImageSource = (images: io.flow.v0.models.Image[] = []) => {
  const image = find(images, ({ tags }) => includes(tags, 'thumbnail')) || first(images);
  return image && image.url;
};

const ProductDetails: FunctionComponent<ProductDetailsProps> = ({ item }) => {
  const imageSource = getImageSource(item.images);

  return (
    <article className={styles.productDetailsPreview}>
      <section className={styles.media}>
        {imageSource && (
          <ImageComponent
            className={styles.mediaImage}
            source={imageSource}
            accessibilityLabel={item.name}
          />
        )}
      </section>
      <section>
        <ProductOverview
          productCode={item.number}
          shortDescription={item.name}
        />
        {item.local && (
          <ProductPrice prices={item.local.prices} />
        )}
        <OutlineButton
          content="Add to Cart"
          intent="primary"
          type="button"
        />
        <ProductDescription
          attributes={item.attributes}
          categories={item.categories}
          description={item.description}
        />
      </section>
    </article>
  );
};

ProductDetails.displayName = displayName;

export default ProductDetails;
