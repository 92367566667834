import { createSelector } from 'reselect';
import get from 'lodash/get';

import getNonReturnableState from './getNonReturnableState';
import { NonReturnableState, NonReturnablePaginationState } from '../types';
import { RootState } from '../../../stores/types';

const getNonReeturnablePaginationState = createSelector<
RootState, NonReturnableState, NonReturnablePaginationState>(
  getNonReturnableState,
  (state) => get(state, 'entities.nonReturnable'),
);

export default getNonReeturnablePaginationState;
