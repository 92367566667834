enum DialogName {
  ADD_FEATURE_WORKSHEET = 'addFeatureWorksheet',
  ADD_RULE_WORKSHEET = 'addRuleWorksheet',
  ARCHIVE_FEATURE_CONFIRM = 'archiveFeatureConfirm',
  DELETE_FEATURE_CONFIRM = 'deleteFeatureConfirm',
  DELETE_RULE_CONFIRM = 'deleteRuleConfirm',
  EDIT_FEATURE_WORKSHEET = 'editFeatureWorksheet',
  EDIT_RULE_WORKSHEET = 'editRuleWorksheet',
  RELEASE_FEATURE_CONFIRM = 'releaseFeatureConfirm',
}

export default DialogName;
