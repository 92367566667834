import { getResources } from '@flowio/redux-resource';
import {
  getPageResults,
  getCurrentPageNumber,
  getIsLastPage,
  getIsFirstPage,
} from '@flowio/redux-resource-pagination-plugin';
import { getOrganizationId } from '../../organization';

export const getPriceBooksResource = (state) => state.resources.priceBooks;

export const getPriceBook = (priceBookKey) => (state) => {
  const organizationId = getOrganizationId(state);
  const resources = getPriceBooksResource(state);
  return getResources(resources, organizationId).find((pb) => pb.key === priceBookKey);
};

export const getPriceBooks = (state) => {
  const organizationId = getOrganizationId(state);
  const priceBooks = getPageResults(getPriceBooksResource(state), organizationId);
  return priceBooks;
};

export const getCurrentPriceBooksPage = (state) => getCurrentPageNumber(
  getPriceBooksResource(state),
);

export const getIsLastPriceBooksPage = (state) => getIsLastPage(
  getPriceBooksResource(state),
);

export const getIsFirstPriceBooksPage = (state) => getIsFirstPage(
  getPriceBooksResource(state),
);
