import { connect } from 'react-redux';
import { withFetch } from '@flowio/redux-fetch';
import head from 'lodash/head';
import assign from 'lodash/assign';
import get from 'lodash/get';
import OrderSummaryCheck from '../components/order-summary-check';
import { fetchOrganizationCountries, fetchOrganization } from '../../../../organization';
import {
  fetchExperiences, getPricing, getPriceFormat, fetchExperience,
} from '../../../../experience/actions';
import {
  getExperiences, getItemSuggestions,
  getPricing as getPricingSelector,
  getCurrentExperienceCurrency,
  getPriceFormat as getPriceFormatSelector,
  getCurrentExperienceDeliveredDuty,
} from '../../../../experience/selectors';
import {
  fetchCenters, setOrderSummarySettings, upsertOrderEstimate,
  updateItemQuantity, removeOrderSummaryItem,
} from '../../../actions';
import { getCenters, getOrderEstimate, getOrderEstimateErrors } from '../../../selectors';
import { ThunkDispatcher, RootState } from '../../../../../stores/types';
import { OwnProps, StateProps, DispatchProps } from '../types';

interface ChangeItem {
  number: string;
  quantity: string;
}

function getAsyncState(
  dispatch: ThunkDispatcher,
  getState: () => RootState,
  ownProps: OwnProps,
): Promise<any> {
  const { organization } = ownProps.params;
  const { query } = ownProps.location;
  query.limit = 300;
  query.q = 'status in (active,draft)';

  const promises: Promise<unknown>[] = [
    dispatch(fetchOrganization(organization)),
    dispatch(fetchExperiences(organization, query)),
    dispatch(fetchCenters({ organization })),
    dispatch(fetchOrganizationCountries(organization)),
  ];

  return Promise.all(promises).then(() => {
    const state = getState();
    const experiences = getExperiences(state);
    const centers = getCenters(state);
    const experience: string = get(head(experiences), 'key', '');
    const center: string = get(head(centers), 'key', '');
    const morePromises: Promise<any>[] = [
      dispatch(fetchExperience(organization, experience)),
      dispatch(getPricing(organization, experience)),
      dispatch(getPriceFormat(organization, experience)),
    ];

    return Promise.all(morePromises)
      .then(() => dispatch(setOrderSummarySettings({ experience, center, items: [] })));
  });
}

const mapStateToProps = (
  state: RootState,
  ownProps: OwnProps,
): StateProps => {
  const props = {
    experiences: getExperiences(state),
    centers: getCenters(state),
    itemSuggestions: getItemSuggestions(state),
    orderEstimate: getOrderEstimate(state),
    pricing: getPricingSelector(state),
    priceFormat: getPriceFormatSelector(state),
    landedCostSetting: getCurrentExperienceDeliveredDuty(state),
    currency: getCurrentExperienceCurrency(state),
    error: getOrderEstimateErrors(state),
  };

  const center = get(ownProps, 'location.query.center');
  const country = get(ownProps, 'location.query.country');
  const experience = get(ownProps, 'location.query.experience');

  if (center || country || experience) {
    assign(props, {
      experience: experience || get(head(props.experiences), 'key'),
      country: country || get(head(props.experiences), 'country'),
      center: center || get(head(props.centers), 'key'),
    });
  } else {
    assign(props, {
      experience: get(head(props.experiences), 'key'),
      country: get(head(props.experiences), 'country'),
      center: get(head(props.centers), 'key'),
    });
  }

  return props;
};

function mapDispatchToProps(
  dispatch: ThunkDispatcher,
  ownProps: OwnProps,
): DispatchProps {
  const { organization } = ownProps.params;

  return {
    onAddItem({
      item,
    }: { item: io.flow.v0.models.LocalizedLineItem }): void {
      const items = [item];
      dispatch(setOrderSummarySettings({ items }));
      dispatch(upsertOrderEstimate(organization));
    },
    onExperienceChange(experience: string): void {
      dispatch(setOrderSummarySettings({ experience }));
      dispatch(upsertOrderEstimate(organization));
      // These can happen async, the settings at the bottom of the page will
      // eventually be consistent
      dispatch(fetchExperience(organization, experience));
      dispatch(getPricing(organization, experience));
      dispatch(getPriceFormat(organization, experience));
    },
    onCenterChange(center: string): void {
      dispatch(setOrderSummarySettings({ center }));
      dispatch(upsertOrderEstimate(organization));
    },
    onDeliveryOptionsChange(selections: string): void {
      dispatch(setOrderSummarySettings({ selections }));
      dispatch(upsertOrderEstimate(organization));
    },
    onQuantityChange(item: ChangeItem): void {
      dispatch(updateItemQuantity(item));
      dispatch(upsertOrderEstimate(organization));
    },
    onRemoveItem(number: string): void {
      dispatch(removeOrderSummaryItem(number));
      dispatch(upsertOrderEstimate(organization));
    },
  };
}

export default withFetch(getAsyncState)(
  connect(mapStateToProps, mapDispatchToProps)(OrderSummaryCheck),
);
