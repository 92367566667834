import React from 'react';
import {
  Dialog, DialogHeader, DialogFooter, DialogActions, DialogBody,
} from '@flowio/react-dialog';
import { Button } from '@flowio/react-button';

const displayName = 'DeleteConfirmationDialog';

type Props = {
  open?: boolean;
  onRequestClose?: () => void;
  onRequestConfirm?: () => void;
  webhookUrl: string;
};
const DeleteConfirmationDialog: React.FC<Props> = ({
  open = false,
  webhookUrl,
  onRequestClose,
  onRequestConfirm,
}) => (
  <Dialog
    backdrop
    closeOnEscape
    closeOnClickAway
    open={open}
    onClose={onRequestClose}
  >
    <DialogHeader content="Delete webhook?" />
    <DialogBody>
      <p>
        This action cannot be undone. Future events will no longer be delivered
        to this webhook: (
        {webhookUrl}
        )
      </p>
    </DialogBody>
    <DialogFooter>
      <DialogActions>
        <Button content="Cancel" onClick={onRequestClose} variant="outline" />
        <Button content="Delete" intent="primary" onClick={onRequestConfirm} variant="outline" />
      </DialogActions>
    </DialogFooter>
  </Dialog>
);

DeleteConfirmationDialog.displayName = displayName;

export default DeleteConfirmationDialog;
