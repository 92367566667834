import { Field, Form, InjectedFormProps } from 'redux-form';
import { MenuDivider } from '@flowio/react-menu';
import React from 'react';
import find from 'lodash/find';

import { css } from '@emotion/css';
import ExperienceShippingConfiguration from '../../ExperienceShippingConfiguration';
import GenericError from '../../../../../components/GenericError';
import { MergedProps as Props, ExperienceLogisticsSettingFormValues } from '../types';
import { SelectField } from '../../../../../components/ReduxFormFieldAdapters';

const selectConfigurationLabel = css`
  margin-bottom: 15px !important;
`;

const formatShippingConfiguration = (
  (
    shippingConfigurations: io.flow.v0.models.ShippingConfiguration[],
  ) => (
    value: string,
  ): io.flow.v0.models.ShippingConfiguration | undefined => shippingConfigurations.find(
    (sc) => sc.key === value,
  )
);

const parseShippingConfiguration = (
  shippingConfiguration: io.flow.v0.models.ShippingConfiguration,
): string => shippingConfiguration.key;

const ExperienceLogisticsSettingForm: React.FC<
Props & InjectedFormProps<ExperienceLogisticsSettingFormValues, Props>
> = ({
  error,
  handleSubmit,
  organizationId,
  regions,
  selectedShippingConfigurationKey,
  shippingConfigurations,
}) => (
  <Form onSubmit={handleSubmit}>
    <Field
      component={SelectField}
      className={selectConfigurationLabel}
      format={formatShippingConfiguration(shippingConfigurations)}
      labelKey="name"
      labelText="Select Shipping Configuration"
      inline
      name="shippingConfigurationKey"
      options={shippingConfigurations}
      parse={parseShippingConfiguration}
      valueKey="key"
    />
    <MenuDivider />
    {error && (
      <GenericError
        error={error}
        style={{ marginTop: '1rem', marginBottom: '1rem' }}
      />
    )}
    {(selectedShippingConfigurationKey != null) && (
      <ExperienceShippingConfiguration
        organizationId={organizationId}
        regions={regions}
        shippingConfiguration={find(shippingConfigurations, {
          key: selectedShippingConfigurationKey,
        })}
      />
    )}
  </Form>
);

ExperienceLogisticsSettingForm.displayName = 'ExperienceLogisticsSettingForm';

ExperienceLogisticsSettingForm.defaultProps = {
  error: undefined,
  selectedShippingConfigurationKey: undefined,
};

export default ExperienceLogisticsSettingForm;
