import DialogName from '../constants/DialogName';
import updateDialog from './updateDialog';
import { RootActionTypes } from '../../../stores/types';

interface ToggleAuthDialogProps {
  organizationId?: string;
  platformId?: io.flow.internal.v0.enums.MarketingGatewayPlatform;
  isManual?: boolean;
}

const toggleAuthDialog = (
  open: boolean,
  { organizationId, platformId, isManual }: ToggleAuthDialogProps = {},
): RootActionTypes => updateDialog(
  DialogName.AUTH_DIALOG, {
    organizationId,
    platformId,
    isManual,
    open,
  },
);

export default toggleAuthDialog;
