import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { RootState, ThunkDispatcher } from '../../../stores/types';
import createToast from '../../console/actions/createToast';
import {
  getCountries,
} from '../../reference/selectors';
import { toggleImplementVariantModal, updateExperimentStatus } from '../actions';
import {
  getVariantKey,
} from '../components/experiment-details/utilities/checkVariant';
import ImplementWinningVariantModal from '../components/implement-variant-modal/implement-winning-variant-modal';
import { getVariantSuccessMsg } from '../constants/messages';
import { getVariants, isImplementVariantModalOpen } from '../selectors';
import { DispatchProps, OwnProps, StateProps } from './types';

const mapStateToProps = (state: RootState): StateProps => ({
  variants: getVariants(state),
  countries: getCountries(state),
  isOpen: isImplementVariantModalOpen(state),
});

const createMessageToast = (intent: 'positive' | 'negative', message: string) => createToast({
  content: message,
  icon: intent === 'positive' ? 'CheckCircle' : 'Error',
  intent,
});

const getToastMessage = (status: io.flow.internal.v0.enums.Status,
  winningVariant?: io.flow.internal.v0.unions.Variant): string => {
  const isEnded = status === 'ended';
  if (winningVariant && isEnded) {
    return getVariantSuccessMsg(winningVariant);
  }
  return `Experiment has been ${status}`;
};

const mapDispatchToProps = (
  dispatch: ThunkDispatcher,
  ownProps: OwnProps,
): DispatchProps => {
  const {
    organization,
  } = ownProps;
  const { id: orgName } = organization;
  return ({
    handleImplementVariant(expKey, winningVariant) {
      const winningVariantKey = winningVariant ? getVariantKey(winningVariant) : undefined;
      return dispatch(updateExperimentStatus(orgName, expKey, 'ended', winningVariantKey)).then(
        () => {
          dispatch(
            createMessageToast('positive', getToastMessage('ended', winningVariant)),
          );
          browserHistory.push(`/${orgName}/experience`);
        },
      ).catch(
        ({ message }) => dispatch(
          createMessageToast('negative', `Failed to update experiment: ${message}`),
        ),
      ).finally(() => {
        dispatch(toggleImplementVariantModal(false));
      });
    },

    hideModal: () => dispatch(toggleImplementVariantModal(false)),
  });
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ImplementWinningVariantModal);
