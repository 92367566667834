import LogisticsRoot from '../components/LogisticsRoot';
import ShippingConfigurationListingPage from '../containers/ShippingConfigurationListingPage';
import ShippingConfigurationDetailPage from '../containers/ShippingConfigurationDetailPage';
import ShippingLaneDetailPage from '../containers/ShippingLaneDetailPage';

export default {
  path: ':organization/logistics/shipping/configurations',
  title: 'Logistics',
  component: LogisticsRoot,
  indexRoute: {
    component: ShippingConfigurationListingPage,
  },
  childRoutes: [{
    path: ':shippingConfigurationKey',
    title: 'Shipping',
    component: ShippingConfigurationDetailPage,
  }, {
    path: ':shippingConfigurationKey/lanes/:shippingLaneId',
    title: 'Shipping',
    component: ShippingLaneDetailPage,
  }],
};
