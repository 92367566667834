import { browserHistory } from 'react-router';
import {
  ThunkResult,
} from '../../../stores/types';

type LocationDescriptorObject = {
  query?: Record<string, any>,
};

function goToLandingPage(
  organizationId: string,
  otherArgs: LocationDescriptorObject = {},
): ThunkResult<void> {
  return (): void => browserHistory.push({
    pathname: `/${organizationId}/marketing`,
    ...otherArgs,
  });
}

export default goToLandingPage;
