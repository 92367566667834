export default function tierStrategyToWord(tierStrategy: io.flow.v0.enums.TierStrategy) {
  switch (tierStrategy) {
    case 'fastest':
      return 'Fastest Speed';
    case 'lowest_cost':
      return 'Lowest Cost';
    default:
      return tierStrategy;
  }
}
