import React, { useEffect } from 'react';
import includes from 'lodash/includes';
import { ArcLoader } from '@flowio/react-icons';
import AuthLayout from '../../auth-layout';
import ReadyState from '../../../constants/ready-state';
import { ComponentProps } from '../types';

const AuthRedirect: React.FC<ComponentProps> = ({
  onRequestRedirect,
  redirect,
  status,
}) => {
  useEffect(() => {
    onRequestRedirect();
  });

  if (redirect) {
    window.location.href = redirect;
  }

  return (
    <AuthLayout>
      {((): JSX.Element => {
        if (includes([ReadyState.PENDING, ReadyState.LOADING], status)) {
          return (<ArcLoader width={40} />);
        }

        if (status === ReadyState.FULFILLED) {
          return (
            <p>
              You are now logged in and are being redirected to
              {' '}
              {redirect}
            </p>
          );
        }

        return (
          <p>Probably set the cookie, but failed to redirect. Blame Nathan.</p>
        );
      })()}
    </AuthLayout>
  );
};

AuthRedirect.displayName = 'ApiRedirect';

AuthRedirect.defaultProps = {
  redirect: undefined,
};

export default AuthRedirect;
