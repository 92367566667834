import { compose } from 'redux';
import { connect, MapStateToProps } from 'react-redux';
import {
  SubmissionError, change, formValueSelector, reduxForm, FormErrors,
} from 'redux-form';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import { DeliveredDutyPreferencesPutResponse } from '@flowio/api-internal-sdk';
import { updateDeliveredDutyPreference } from '../../../actions';

import { getOrganizationId } from '../../../../organization/selectors';
import {
  getDeliveredDutyOptions,
  getDeliveredDutyMethodPreference,
  getDeliveredDutyDefaultPreference,
  getDeliveredDutyDisplayPreference,
  getCurrentExperienceKey,
} from '../../../selectors';
import FormName from '../../../constants/FormName';
import LandedCostForm from '../components/LandedCostForm';
import checkHttpStatus from '../../../../../utilities/check-http-status';
import {
  StateProps, OwnProps, LandedCostFormValues, MergedProps, ComponentProps,
} from '../types';
import { RootState, ThunkDispatcher } from '../../../../../stores/types';
import { LegacyResponse } from '../../../../../utilities/clients/types/server';
import { FormattedErrorMessages } from '../../../../../utilities/format-error-response-v2';
import { PropType } from '../../../../../types';

function handleSubmit(
  values: LandedCostFormValues,
  dispatch: ThunkDispatcher,
): Promise<LegacyResponse<PropType<DeliveredDutyPreferencesPutResponse, 'body'>>> {
  const { organizationId, experienceKey, ...deliveredDutyPreferenceForm } = values;
  return dispatch(updateDeliveredDutyPreference(
    organizationId,
    experienceKey,
    deliveredDutyPreferenceForm,
  )).then(checkHttpStatus).catch((error) => {
    if (error.response) {
      throw new SubmissionError<LandedCostFormValues, FormattedErrorMessages>(
        { _error: error.response.result },
      );
    }

    throw error;
  });
}

function handleValidation(
  values: LandedCostFormValues,
): FormErrors<LandedCostFormValues> {
  const errors: FormErrors<LandedCostFormValues> = {};

  if (isEmpty(values.methods)) {
    errors.methods = 'A duty method must be selected';
  } else if (size(values.methods) > 1) {
    if (isEmpty(values.default)) {
      errors.default = 'A default duty method must be selected';
    }

    if (isEmpty(values.display)) {
      errors.display = 'A display type must be selected';
    }
  }

  return errors;
}

function handleChange(
  values: LandedCostFormValues,
  dispatch: ThunkDispatcher,
): void {
  if (values && values.methods && values.methods.length === 1) {
    dispatch(change(FormName.LANDED_COST, 'default', values.methods[0]));
    dispatch(change(FormName.LANDED_COST, 'display', 'single'));
  }
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = (state) => ({
  deliveredDutyOptions: getDeliveredDutyOptions(state),
  initialValues: {
    organizationId: getOrganizationId(state),
    experienceKey: getCurrentExperienceKey(state),
    methods: getDeliveredDutyMethodPreference(state),
    default: getDeliveredDutyDefaultPreference(state),
    display: getDeliveredDutyDisplayPreference(state),
  },
  selectedDeliveredDutyMethods: formValueSelector(FormName.LANDED_COST)(state, 'methods'),
  selectedDeliveredDutyDefault: formValueSelector(FormName.LANDED_COST)(state, 'default'),
  selectedDeliveredDutyDisplay: formValueSelector(FormName.LANDED_COST)(state, 'display'),
});

export default compose<React.FC<ComponentProps>>(
  connect(mapStateToProps),
  reduxForm<LandedCostFormValues, MergedProps>({
    form: FormName.LANDED_COST,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    onChange: handleChange,
    onSubmit: handleSubmit,
    updateUnregisteredFields: true,
    validate: handleValidation,
  }),
)(LandedCostForm);
