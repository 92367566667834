import { currencies, format } from '@flowio/lib-reference-javascript';
import get from 'lodash/get';

export default function tierRuleQueryToSentence(
  tierRule: io.flow.v0.models.TierRule,
  currencyCode: string,
): string {
  const tierCurrency = currencies.find(currencyCode);
  const tierCurrencyLocale = get(tierCurrency, 'default_locale');

  // Sentence prefix
  if (tierRule.query === '*') {
    return 'For all order values';
  }

  if (tierRule.query.startsWith('gte')) {
    const [, price] = tierRule.query.split(':');
    return `For orders >= ${format.currency(parseFloat(price), tierCurrencyLocale)}`;
  }

  if (tierRule.query.startsWith('lte')) {
    const [, price] = tierRule.query.split(':');
    return `For orders <= ${format.currency(parseFloat(price), tierCurrencyLocale)}`;
  }
  return '';
}
