import { compose } from 'redux';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { withFetch } from '@flowio/redux-fetch';
import { RouteComponentProps } from 'react-router';
import { fetchOrganization } from '../../../../organization';
import { fetchCatalogItemByNumber, fetchItemHarmonization, setSelectedClassificationItems } from '../../../actions';
import ProductDetails from '../components/product-details';
import { getHarmonizationResults } from '../../../selectors';
import { ThunkDispatcher, RootState } from '../../../../../stores/types';
import { StateProps, DispatchProps } from '../types';

interface RouteParams {
  number: string;
  organization: string;
}

type OwnProps = RouteComponentProps<{}, RouteParams>;

function getAsyncState(
  dispatch: ThunkDispatcher,
  _getState: () => RootState,
  ownProps: OwnProps,
): Promise<any> {
  const { number, organization } = ownProps.params;
  return Promise.all([
    dispatch(fetchOrganization(organization)),
    dispatch(fetchCatalogItemByNumber(organization, number)),
    dispatch(fetchItemHarmonization(organization, number)),
  ]);
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = (state) => ({
  item: state.catalog.item,
  classificationResults: getHarmonizationResults(state),
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = (dispatch) => ({
  onClearSelectedCountries(): void {
    dispatch(setSelectedClassificationItems([]));
  },
});

export default compose(
  withFetch(getAsyncState),
  connect(mapStateToProps, mapDispatchToProps),
)(ProductDetails);
