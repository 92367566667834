import { RootActionTypes } from '../../../stores/types';
import ActionTypes from '../constants/action-types';
import { FacebookLoginStatusState } from '../types/store';

const initialState = {
  loading: false,
};

const loginStatus = (
  state = initialState,
  action: RootActionTypes,
): FacebookLoginStatusState => {
  switch (action.type) {
    case ActionTypes.FETCH_LOGIN_STATUS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionTypes.FETCH_LOGIN_STATUS_SUCCESS: {
      return {
        loading: false,
        ...action.payload,
      };
    }
    case ActionTypes.FETCH_LOGIN_STATUS_FAILURE: {
      return {
        loading: false,
        error: action.payload,
      };
    }
    case ActionTypes.LOGOUT_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionTypes.LOGOUT_SUCCESS: {
      return {
        ...state,
        loading: false,
        status: action.payload,
      };
    }
    case ActionTypes.LOGOUT_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default loginStatus;
