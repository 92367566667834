import Client from './client';

export default class OptinPrompts extends Client {
  constructor(opts) {
    let options = opts;

    if (typeof opts === 'string') {
      options = { host: opts }; // convert host string to options object
    }

    options.serviceName = 'API Internal';

    super(options);
  }

  getPrompts(organization, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/optin/prompts`, options);
  }

  postPrompts(organization, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/optin/prompts`, {
      ...options,
       method: 'POST',
    });
  }

  putPromptsById(organization, id, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/optin/prompts/${id}`, {
      ...options,
       method: 'PUT',
    });
  }

  deletePromptsById(organization, id, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/optin/prompts/${id}`, {
      ...options,
       method: 'DELETE',
    });
  }

  getTargetsByTargetAndRegion(organization, target, region, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/optin/targets/${target}/${region}`, options);
  }

  getById(organization, id, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/optin/${id}`, options);
  }

}
