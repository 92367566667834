import { FlowLogo } from '@flowio/react-icons';
import { css } from '@emotion/core';
import React, { useEffect } from 'react';
import ConfirmSplash from '../../confirm-splash';
import LoginSplash from '../../login-splash';
import PageError from '../../../../console/components/page-error';
import { Props } from '../types';

const logoStyle = css({
  width: '150px',
  height: '42px',
  marginBottom: '4rem',
  alignSelf: 'center',
});

const isOrganizationReference = (
  organization: io.flow.v0.unions.ExpandableOrganization,
): organization is io.flow.v0.models.OrganizationReference => (
  organization as io.flow.v0.models.OrganizationReference
).discriminator === 'organization_reference';

const OrganizationInvitations: React.FC<Props> = ({
  params,
  isLoggedIn,
  invitationUserVerified,
  onRequestJoinOrganization,
  invitation,
  error,
}) => {
  useEffect(() => {
    if (typeof sessionStorage !== 'undefined' && params.token) {
      sessionStorage.setItem('invitationToken', params.token);
    }
  }, [params.token]);

  const renderLoginOrConfirmSplash = (organization: string): JSX.Element => {
    if (isLoggedIn && invitationUserVerified) {
      return (
        <ConfirmSplash
          onConfirm={onRequestJoinOrganization}
          organization={organization}
        />
      );
    }

    return <LoginSplash />;
  };

  if (!invitation) {
    if (error?.messages?.length) {
      return <PageError title={error.messages[0].message} />;
    }

    return <PageError title="Invitation Not Found" />;
  }

  return (
    <section className="page-account full-screen flex items-center">
      <section className="col-6 mx-auto">
        <div className="center">
          <FlowLogo css={logoStyle} />
        </div>

        {invitationUserVerified ? (
          <h1 className="h1 center">
            You have been invited to
            {' '}
            {isOrganizationReference(invitation.organization) ? 'the organization' : invitation.organization?.name}
            !
          </h1>
        ) : (
          <h1 className="h1 center">
            {invitation.email}
            {' '}
            has been invited to
            {' '}
            {isOrganizationReference(invitation.organization) ? 'the organization' : invitation.organization?.name}
            !
          </h1>
        )}
        {invitationUserVerified && (
          <p className="center">To join this organization, click the button below.</p>
        )}

        {renderLoginOrConfirmSplash(
          isOrganizationReference(invitation.organization)
            ? invitation.organization.id
            : invitation.organization.name,
        )}
      </section>
    </section>
  );
};

OrganizationInvitations.displayName = 'OrganizationInvitations';

export default OrganizationInvitations;
