import { css } from '@emotion/css';

export const memberActions = css`
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const memberEditCta = css`
  font-weight: normal;
  padding: 0 10px;
  cursor: pointer;
  & button {
    cursor: pointer;
  }
`;

export const memberMenuItem = css`
  font-size: 14px;
  cursor: pointer;
`;

export const memberMenuCta = css`
  margin-left: 8px;
`;
