import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { browserHistory } from 'react-router';
import { withFetch } from '@flowio/redux-fetch';
import get from 'lodash/get';

import { compose } from 'redux';
import { isNotNil } from '@flowio/is';
import {
  fetchOrganization,
  getOrganizationId,
  getBaseOrganizationCurrency,
  getBaseOrganizationCountry,
} from '../../../../organization';
import Pricing from '../components/pricing';
import {
  fetchExperience,
  updatePricing,
  getMargins as fetchMargins,
  getPricing as fetchPricing,
  getPriceFormat as fetchPriceFormat,
  getItemPriceDetails as fetchItemPriceDetails,
  goToItemMarginCreate,
  goToItemMarginDetails,
  deleteMargin,
  updateItemMargins,
  updatePriceFormat,
  clearActivePricingSection,
  setActivePricingSection,
  updateExperiencePriceBook,
  deletePriceBookExperienceMapping,
  clearItemPricing,
} from '../../../actions';
import {
  getCurrentExperience,
  getMargins,
  getPricing,
  getPriceFormat,
  getItemSuggestions,
  getItemPriceDetails,
  getActivePricingSection,
  getPriceBookStatus,
  getIsPricingFormatVisible,
} from '../../../selectors';
import { readPriceBookMappingByExperience, readPriceBooksByOrg } from '../../../../resources/operations';
import { getPriceBookMappings, getPriceBooks } from '../../../../resources/selectors';
import { StateProps, OwnProps, DispatchProps } from '../types';
import { RootState, ConsoleDispatcher } from '../../../../../stores/types';
import changePricingFormatVisibility from '../../../actions/changePricingFormatVisibility';

function getAsyncState(
  dispatch: ConsoleDispatcher,
  _getState: () => RootState,
  ownProps: OwnProps,
): Promise<unknown> {
  const { organization, experienceKey } = ownProps.params;
  return Promise.all([
    dispatch(fetchExperience(organization, experienceKey)),
    dispatch(fetchOrganization(organization)),
    dispatch(fetchMargins(organization, experienceKey)),
    dispatch(fetchPricing(organization, experienceKey)),
    dispatch(fetchPriceFormat(organization, experienceKey)),
    dispatch(readPriceBooksByOrg({ organizationId: organization, pageSize: 100 })),
    dispatch(readPriceBookMappingByExperience({ organizationId: organization, experienceKey })),
  ]);
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = (state) => {
  const {
    defaultReducer, experience, ui,
  } = state;
  return {
    ...defaultReducer,
    ...ui.experience,
    ...ui.catalog,
    ...experience.entities.ui,
    priceBookStatus: getPriceBookStatus(state),
    initialPriceBook: getPriceBookMappings(state),
    priceBooks: getPriceBooks(state),
    experience: getCurrentExperience(state),
    itemMargins: getMargins(state),
    pricing: getPricing(state),
    priceFormat: getPriceFormat(state),
    itemSuggestions: getItemSuggestions(state),
    priceCheck: getItemPriceDetails(state),
    organization: getOrganizationId(state),
    activeSection: getActivePricingSection(state),
    baseCurrency: getBaseOrganizationCurrency(state),
    isPricingFormatVisible: getIsPricingFormatVisible(state),
    organizationCountry: getBaseOrganizationCountry(state),
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch: ConsoleDispatcher,
  ownProps: OwnProps,
) => {
  const { organization, experienceKey } = ownProps.params;
  return {
    onSavePriceBook(
      priceBook: io.flow.v0.models.PriceBook | undefined,
      initialPriceBook: io.flow.v0.models.ExperiencePriceBookMapping,
    ): void {
      if (isNotNil(priceBook)) {
        Promise.resolve(
          dispatch(updateExperiencePriceBook({
            organizationId: organization,
            experienceKey,
            priceBookKey: get(priceBook, 'key'),
          })),
        ).then(() => dispatch(clearActivePricingSection()))
          .then(() => dispatch(readPriceBookMappingByExperience({
            organizationId: organization,
            experienceKey,
          })));
      } else {
        Promise.resolve(
          dispatch(deletePriceBookExperienceMapping({
            organizationId: organization,
            experienceKey,
            priceBookKey: get(initialPriceBook, 'price_book.key'),
          })),
        ).then(() => dispatch(clearActivePricingSection()))
          .then(() => dispatch(readPriceBookMappingByExperience({
            organizationId: organization,
            experienceKey,
          })));
      }
    },
    onCreatePriceBook(): void {
      browserHistory.push(`/${organization}/price-books`);
    },
    onSetActiveSection(section): void {
      dispatch(setActivePricingSection(section));
    },
    onNewMargin(): void {
      dispatch(goToItemMarginCreate(organization, experienceKey));
    },

    onSaveEditMargins({ marginSyncRequired, margins }): void {
      if (marginSyncRequired) {
        dispatch(updateItemMargins(margins));
      }
      dispatch(clearActivePricingSection());
    },

    onMarginEdit(id): void {
      dispatch(goToItemMarginDetails(organization, experienceKey, id));
    },

    onDeleteMargin(id): void {
      dispatch(deleteMargin(organization, experienceKey, id));
    },

    onSaveDisplay(pricing): void {
      dispatch(updatePricing(organization, experienceKey, pricing, true));
    },

    onRequestManagePriceBook(): void {
      browserHistory.push(`/${organization}/experience/${experienceKey}/pricing/pricebook`);
    },

    onPriceRoundingSave(pricing): void {
      dispatch(updatePricing(organization, experienceKey, pricing, false));
      dispatch(clearActivePricingSection());
    },

    onPriceCheck(item): void {
      dispatch(fetchItemPriceDetails(organization, experienceKey, item));
    },

    onPriceFormatSave(format): void {
      dispatch(updatePriceFormat(organization, experienceKey, format));
      dispatch(clearActivePricingSection());
    },

    clearPriceCheck(): void {
      dispatch(clearItemPricing());
    },

    onChangePricingFormatVisibility(visible: boolean): void {
      dispatch(changePricingFormatVisibility(visible));
      dispatch(setActivePricingSection(''));
    },
  };
};

export default compose<React.Component>(
  withFetch(getAsyncState),
  connect(mapStateToProps, mapDispatchToProps),
)(Pricing);
