import React, { FunctionComponent } from 'react';
import {
  Page,
  PageHeader,
  PageTitle,
  PageContent,
} from '@flowio/react-page';

import ConfigurationDetail from '../containers/configuration-detail';

const defaultProps = {
  configurationIds: [],
};

export interface StateProps {
  configurationIds: string[];
}

export interface OwnProps {
  params: {
    organization: string;
  };
}

type CheckoutConfigurationProps = StateProps & OwnProps;

const CheckoutConfiguration: FunctionComponent<CheckoutConfigurationProps> = ({
  configurationIds,
}) => (
  <Page>
    <PageHeader>
      <PageTitle content="Checkout Configurations" />
    </PageHeader>
    <PageContent>
      {configurationIds.map((id) => (
        <ConfigurationDetail id={id} />
      ))}
    </PageContent>
  </Page>
);

CheckoutConfiguration.defaultProps = defaultProps;

export default CheckoutConfiguration;
