import { createSelector } from 'reselect';
import get from 'lodash/get';
import getReturnPolicyDeleteConfirmation from './getReturnPolicyDeleteConfirmation';

const getIsReturnPolicyDeleteConfirmationVisible = createSelector(
  getReturnPolicyDeleteConfirmation,
  (state) => get(state, 'visibility') === 'visible',
);

export default getIsReturnPolicyDeleteConfirmationVisible;
