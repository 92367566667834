import { css } from '@emotion/css';
import colors from '../../../../theme/tokens/colors';

export const chooseOrganization = css`
  align-self: flex-start;
  width: 880px;
  margin: 0 auto;

  & .organization-card {
    margin: 16px;
    background-color: #fafbfc;
  }
`;

export const title = css`
  margin-top: 48px;
  margin-bottom: 48px;
  font-size: 32px;
  font-weight: normal;
  text-align: center;
  color: ${colors.charcoal};
`;

export const options = css`
  display: flex;
  flex-flow: row wrap;
  margin: -16px -16px 0;
  padding: 0;
  list-style: none;
`;
