import ActionTypes from '../constants/action-types';
import {
  ThunkExtraArguments,
  RootState,
  ThunkDispatcher,
  ThunkResult,
  RootActionTypes,
} from '../../../stores/types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';

interface UpdateExperienceLogisticsSettingParams {
  organizationId: string;
  experienceKey?: string;
  experienceLogisticsSettingsPutForm: io.flow.v0.models.ExperienceLogisticsSettingsPutForm;
}

export default function updateExperienceLogisticsSetting({
  organizationId,
  experienceKey,
  experienceLogisticsSettingsPutForm,
}: UpdateExperienceLogisticsSettingParams): ThunkResult<Promise<RootActionTypes>> {
  if (!experienceKey) {
    throw new Error('updateExperienceLogisticsSetting: Missing experience key');
  }
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<RootActionTypes> => {
    dispatch({
      type: ActionTypes.UPDATE_EXPERIENCE_LOGISTICS_SETTING_REQUEST,
    });

    return extra.api(getState()).experienceLogisticsSettings.put({
      organization: organizationId,
      experience_key: experienceKey,
      body: experienceLogisticsSettingsPutForm,
    }).then((response) => {
      if (response.ok) {
        return dispatch({
          type: ActionTypes.UPDATE_EXPERIENCE_LOGISTICS_SETTING_SUCCESS,
          meta: {
            organizationId,
            experienceKey,
          },
          payload: response.body,
        });
      }

      const errors = formatErrorResponseV2(response);
      return dispatch({
        type: ActionTypes.UPDATE_EXPERIENCE_LOGISTICS_SETTING_FAILURE,
        error: true,
        meta: {
          organizationId,
          experienceKey,
        },
        payload: errors,
      });
    });
  };
}
