import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { fieldInputPropTypes } from 'redux-form';
import classNames from 'classnames/bind';

import styles from './FileUploadRedux.module.css';
import FileInput from '../FileInput';
import FileDragAndDrop from '../FileDragAndDrop';

const cx = classNames.bind(styles);

// Please note this component can only be used within a redux form
class FileUploadRedux extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (files) => {
    const { input } = this.props;
    if (input) {
      input.onChange(files);
    }
  }

  render() {
    const {
      children,
      accept,
      inputLabel,
      theme,
      dragAndDrop,
      ...unhandledProps
    } = this.props;
    return (
      <div {...unhandledProps}>
        <div className={`${cx('uploadBtnContainer')} ${theme.fileInput ? theme.fileInput : ''}`}>
          <span className={`${cx('uploadBtnLabel')} ${theme.fileInputLabel ? theme.fileInputLabel : ''}`}>
            {!inputLabel ? 'Click here to select file' : inputLabel }
          </span>
          <FileInput onChange={this.handleChange} accept={accept} className={cx('uploadBtn')} />
        </div>
        {dragAndDrop && (
          <FileDragAndDrop onChange={this.handleChange}>
            {children}
          </FileDragAndDrop>
        )}
      </div>
    );
  }
}

FileUploadRedux.propTypes = {
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  dragAndDrop: PropTypes.bool,
  inputLabel: PropTypes.string,
  accept: PropTypes.string,
  children: PropTypes.node,
  theme: PropTypes.shape({
    fileInput: PropTypes.string,
    fileInputLabel: PropTypes.string,
    dragAndDrop: PropTypes.string,
  }),
};

FileUploadRedux.defaultProps = {
  children: undefined,
  inputLabel: undefined,
  dragAndDrop: true,
  accept: '',
  theme: {},
};

export default FileUploadRedux;
