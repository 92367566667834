import { compose } from 'redux';
import { ConfigProps, reduxForm } from 'redux-form';
import React from 'react';

import ExclusionRuleForm from '../components/ExclusionRuleForm';
import FormName from '../constants/FormName';
import { submitExclusionRuleForm } from '../actions';
import { ExclusionRuleFormProps, ExclusionRuleFormValues } from '../types';
import { ThunkDispatcher } from '../../../stores/types';

interface MultipleFilterError {
  field: string;
  operator: string;
  values: string;
}

function handleSubmit(values: ExclusionRuleFormValues, dispatch: ThunkDispatcher) {
  return dispatch(submitExclusionRuleForm(values));
}

function handleValidation(values: ExclusionRuleFormValues) {
  // Need to use any type here, there is an issue with the typings
  // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/23922
  // Unlikely to get fixed since redux-form is deprecated (open issue from 2018)
  const errors: any = {};

  if (!values.organizationId) {
    errors.organizationId = 'An organization identifier is required';
  }

  if (!values.filters || !values.filters.length) {
    errors.filters = {
      _error: 'At least one exclusion rule must be entered',
    };
  } else {
    const filterArrayErrors: Partial<MultipleFilterError>[] = [];

    values.filters.forEach((filter, index) => {
      const filterErrors: Partial<MultipleFilterError> = {};

      if (!filter.field) {
        filterErrors.field = 'This field is required';
        filterArrayErrors[index] = filterErrors;
      }

      if (!filter.operator) {
        filterErrors.operator = 'This field is required';
        filterArrayErrors[index] = filterErrors;
      }

      if (!filter.values) {
        filterErrors.values = 'This field is required';
        filterArrayErrors[index] = filterErrors;
      } else if (!Array.isArray(filter.values)) {
        filterErrors.values = 'This field must be an array. Contact the engineer that implemented this form.';
        filterArrayErrors[index] = filterErrors;
      } else if (!filter.values.length) {
        filterErrors.values = 'This field is required';
        filterArrayErrors[index] = filterErrors;
      }
    });

    if (filterArrayErrors.length) {
      errors.filters = filterArrayErrors;
    }
  }

  if (!values.regions || !values.regions.length) {
    errors.regions = 'At least one region must be selected';
  }

  return errors;
}

export default compose<React.FC<
ExclusionRuleFormProps & ConfigProps<ExclusionRuleFormValues, ExclusionRuleFormProps>>>(
  reduxForm({
    form: FormName.ExclusionForm,
    onSubmit: handleSubmit,
    validate: handleValidation,
  }),
)(ExclusionRuleForm);
