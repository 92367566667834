import invariant from 'invariant';
import { ThunkResult } from '../../../stores/types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';
import { createToast } from '../../console/actions';
import ActionType from '../constants/ActionType';

/**
 * Creates an action responsible for marking a return policy in the specified
 * organization for deletion.
 * @param {String} organizationId - identifier for the organization to be updated.
 * @param {String} returnPolicyId - identifier for the return policy to be deleted.
 */
export default function deleteNonReturnableRule(
  organizationId: string,
  returnPolicyId: string,
): ThunkResult<Promise<void>> {
  invariant(organizationId != null, 'An organization identifier must be provided');
  invariant(returnPolicyId != null, 'A return policy identifier must be provided');

  return function deleteNonReturnableRuleEffects(
    dispatch,
    getState,
    extra,
  ): Promise<void> {
    dispatch({
      type: ActionType.DeleteReturnPolicyRequest,
      meta: {
        returnPolicyId,
        organizationId,
      },
    });
    return extra.api(getState()).returnPolicies.putDeletionById({
      organization: organizationId,
      id: returnPolicyId,
    })
      .then((response) => {
        if (response.ok) {
          dispatch({
            type: ActionType.DeleteReturnPolicySuccess,
            meta: {
              returnPolicyId,
              organizationId,
            },
            payload: response.body,
          });
          dispatch(createToast({
            intent: 'positive',
            content: 'Non-Returnable rule deleted successfully',
          }));
          return;
        }
        const error = formatErrorResponseV2(response);
        dispatch({
          payload: error,
          meta: {
            returnPolicyId,
            organizationId,
          },
          type: ActionType.DeleteReturnPolicyFailure,
        });
      });
  };
}
