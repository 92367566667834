import { css } from '@emotion/css';

export const li = css`
  color: #3283e6;
  font-weight: bold;
  padding-left: 10px;
`;

export const noIndent = css`
  padding-left: 16px;
`;

export const bullet = css`
  color: #3283e6;
`;

export const span = css`
  color: black;
  font-weight: normal;
`;

export const divider = css`
  margin-bottom: 0;
`;

export const h2First = css`
  margin-top: 0;
`;

export const emFirst = css`
  margin-bottom: 0;
`;

export const emSecond = css`
  margin-top: 0;
`;
