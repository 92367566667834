import { compose } from 'redux';
import {
  connect, MapStateToProps, MapDispatchToProps,
} from 'react-redux';
import { submit } from 'redux-form';
import startCase from 'lodash/startCase';
import { withFetch } from '@flowio/redux-fetch';
import { MeasurementSystem } from '@flowio/api-constants';
import {
  fetchCountries, fetchCurrencies, fetchLanguages, fetchRegions,
} from '../../../../reference/actions';
import { fetchOrganization, getOrganizationId } from '../../../../organization';
import {
  getCountries, getCurrencies, getLanguages, getRegions,
} from '../../../../reference/selectors';
import { goToExperienceOverviewPage, goToExperienceLocalizationPage } from '../../../actions';
import Create from '../components/create';
import FormName from '../../../constants/FormName';
import { StateProps, OwnProps, DispatchProps } from '../types';
import { RootState, ThunkDispatcher } from '../../../../../stores/types';

function getAsyncState(
  dispatch: ThunkDispatcher,
  _getState: () => RootState,
  ownProps: OwnProps,
): Promise<unknown[]> {
  const { organization } = ownProps.params;
  return Promise.all([
    dispatch(fetchCountries()),
    dispatch(fetchCurrencies()),
    dispatch(fetchLanguages()),
    dispatch(fetchOrganization(organization)),
    dispatch(fetchRegions()),
  ]);
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = (state: RootState) => ({
  countries: getCountries(state),
  currencies: getCurrencies(state),
  languages: getLanguages(state),
  measurementSystems: [
    { content: startCase(MeasurementSystem.METRIC), value: MeasurementSystem.METRIC },
    { content: startCase(MeasurementSystem.IMPERIAL), value: MeasurementSystem.IMPERIAL },
  ],
  organizationId: getOrganizationId(state),
  regions: getRegions(state),
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch: ThunkDispatcher,
): DispatchProps => ({
  onCancel(): void {
    dispatch(goToExperienceOverviewPage());
  },
  onSave(): void {
    dispatch(submit(FormName.CREATE_EXPERIENCE));
  },
  onSuccess(experience): void {
    dispatch(goToExperienceLocalizationPage(experience.key));
  },
});

export default compose(
  withFetch(getAsyncState),
  connect(mapStateToProps, mapDispatchToProps),
)(Create);
