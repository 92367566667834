import React from 'react';
import { css } from '@emotion/css';

const listStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  margin: 1rem 0 0;
  padding: 0;
  list-style: none;
`;

interface Props {
  className?: string;
}

const AuthNavigation: React.FC<Props> = ({ children, className }) => (
  <nav className={className}>
    <ul className={listStyles}>
      {children}
    </ul>
  </nav>
);

AuthNavigation.displayName = 'AuthNavigation';

AuthNavigation.defaultProps = {
  className: undefined,
};

export default AuthNavigation;
