import { browserHistory } from 'react-router';
import {
  ThunkResult,
} from '../../../stores/types';

interface GoToAddCountriesPageParams {
  organizationId: string;
  catalogId: number;
  platformId?: io.flow.internal.v0.enums.MarketingGatewayPlatform;
}

function goToAddCountriesPage({
  organizationId,
  catalogId,
  platformId = 'facebook',
}: GoToAddCountriesPageParams): ThunkResult<void> {
  return (): void => browserHistory.push({
    pathname: `/${organizationId}/marketing/${platformId}/${catalogId}/add-countries`,
  });
}

export default goToAddCountriesPage;
