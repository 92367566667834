import update from 'immutability-helper';

import ActionTypes from '../constants/action-types';
import { ExperienceState } from '../types';
import { RootActionTypes } from '../../../stores/types';

type State = ExperienceState['current'];

const initialState: State = null;

export default function (state: State = initialState, action: RootActionTypes): State {
  switch (action.type) {
    case ActionTypes.SELECT_EXPERIENCE:
    case ActionTypes.GET_EXPERIENCE_SUCCESS:
    case ActionTypes.FETCH_EXPERIENCE_SUCCESS:
    case ActionTypes.CREATE_EXPERIENCE_SUCCESS:
    case ActionTypes.UPDATE_EXPERIENCE_SUCCESS:
      return update(state, { $set: action.payload });

      /*
    This is used by product details check. Product details check should have
    its own reducer node. Remove this once that is the case.
   */
    case ActionTypes.RESET_EXPERIENCE:
      return update(state, { $set: null });
    default:
      return state;
  }
}
