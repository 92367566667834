import React from 'react';
import { Link } from 'react-router';

const LoginSplash: React.FC<{}> = () => (
  <section>
    <p className="center">
      To join this organization,
      please
      {' '}
      <Link to="/login">log in</Link>
      {' '}
      or
      {' '}
      <Link to="/register">register</Link>
      {' '}
      with that email address.
    </p>
  </section>
);

export default LoginSplash;
