import { compose } from 'redux';
import {
  reduxForm, formValues, SubmissionError, ConfigProps,
} from 'redux-form';

import { autofillExperienceForm, updateExperience, createExperience } from '../../../actions';
import { toExperienceForm } from '../../../utilities/converters';
import ExperienceForm from '../components/ExperienceForm';
import isResponseOk from '../../../../../utilities/isResponseOk';
import { ExperienceFormValues } from '../../../types';
import { ComponentProps } from '../types';
import { LegacyResponse } from '../../../../../utilities/clients/types/server';
import { ThunkDispatcher } from '../../../../../stores/types';
import FormName from '../../../constants/FormName';

function handleResponse(response: LegacyResponse): io.flow.v0.models.Experience {
  if (isResponseOk(response) && (response.result as io.flow.v0.models.Experience).id) {
    return response.result;
  }
  throw new SubmissionError({ _error: response.result });
}

function handleSubmit(
  values: ExperienceFormValues,
  dispatch: ThunkDispatcher,
): Promise<io.flow.v0.models.Experience> {
  const { organizationId, key } = values;

  if (!organizationId) {
    throw new SubmissionError({
      _error: 'Missing Required Organization ID',
    });
  }

  const action = (key != null)
    ? updateExperience(organizationId, key, toExperienceForm(values))
    : createExperience(organizationId, toExperienceForm(values));
  return dispatch(action).then((response) => handleResponse(response));
}

function handleChange(
  nextValues: ExperienceFormValues,
  dispatch: ThunkDispatcher,
  props: ComponentProps,
  prevValues: ExperienceFormValues,
): void {
  const {
    initialValues = {},
    form,
  }: {
    initialValues: ExperienceFormValues;
    form: FormName;
  } = props;

  const {
    organizationId,
    regionId: nextRegionId,
  } = nextValues;

  const {
    regionId: prevRegionId,
  } = prevValues;

  const {
    regionId: initialRegionId,
  } = initialValues;

  // Autofill form fields when a different region is selected.
  if (
    organizationId != null
    && nextRegionId != null
    && nextRegionId !== prevRegionId
    && nextRegionId !== initialRegionId
  ) {
    dispatch(autofillExperienceForm(
      form,
      organizationId,
      nextRegionId,
    ));
  }
}

export default compose<React.FC<
ComponentProps & ConfigProps<ExperienceFormValues, ComponentProps>
>>(
  reduxForm<ExperienceFormValues, ComponentProps>({
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    onChange: handleChange,
    onSubmit: handleSubmit,
  }),
  formValues<ExperienceFormValues, ComponentProps>({
    selectedRegionId: 'regionId',
  }),
)(ExperienceForm);
