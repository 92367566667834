import { InfoCircle } from '@flowio/react-icons';
import React from 'react';
import { css } from '@emotion/react';
import { MultiSelect } from '@flowio/react-multi-select';
import { countries } from '@flowio/lib-reference-javascript';
import { Tag } from '@flowio/react-tag-input';
import { Tooltip } from '@flowio/react-tooltip';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@flowio/react-table';
import keys from 'lodash/keys';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';

import classnames from 'classnames';

import CountryLabel from '../../../../reference/components/CountryLabel';
import handleEnterKeyboardEvent from '../../../../../utilities/enterKeyPressHandler';
import { ClassificationResultCountry } from '../../../types/misc';
import { MergedProps } from '../types';
import colors from '../../../../../theme/tokens/colors';
import * as styles from './classification-results.styles';
import CountryFlag from '../../../../reference/components/CountryFlag';

const infoCircleStyle = css`
  fill: ${colors.steel};
`;

const ClassificationResults: React.FC<MergedProps> = ({
  classificationResult,
  selectedCountries,
  showAllTariffCodes,
  onHideAllTariffCodes,
  onShowAllTariffCodes,
  onDropdownChange,
}) => {
  const dropdownOptions = sortBy(keys(classificationResult.tariffs)
    .map<ClassificationResultCountry>((key) => {
    const country = countries.find(key);
    return {
      tariffCode: get(classificationResult.tariffs, `${key}.tariff_code`),
      ...country,
    };
  }), ['name']);

  const bulkActionText = showAllTariffCodes ? 'Hide All Tariff Codes' : 'Show All Tariff Codes';
  const bulkAction = showAllTariffCodes ? onHideAllTariffCodes : onShowAllTariffCodes;
  const displayedCodes = (showAllTariffCodes ? dropdownOptions : selectedCountries) || [];
  const coos = classificationResult.countries_of_origin || [];

  const hsCodeClass = classificationResult.hs6
    ? classnames(styles.infoLabel, styles.hsCodes)
    : styles.infoLabel;

  const handleValueChange = (values: string[]) => {
    const allCountriesSelected: ClassificationResultCountry[] = [];

    values.forEach((value) => {
      const country = dropdownOptions.find((o) => o.iso_3166_3 === value);
      if (country) {
        allCountriesSelected.push(country);
      }
    });

    dropdownOptions
      .filter((o) => values.includes(o.iso_3166_3));
    onDropdownChange(allCountriesSelected, showAllTariffCodes);
  };

  return (
    <dl className={styles.info}>
      <dt className={styles.infoLabel}>
        Country of Origin
      </dt>
      <dd className={styles.infoValue}>
        {coos.length < 1 && (
          <span>
            N/A
          </span>
        )}
        {coos.map((coo) => {
          const country = countries.find(coo);
          return country ? (
            <CountryLabel key={coo} country={country} />
          ) : coo;
        })}
      </dd>
      <dt className={styles.infoLabel}>
        Customs Description
      </dt>
      <dd className={classnames(styles.infoValue, styles.capitalize)}>
        {!classificationResult.customs_description && (
          <span>
            N/A
          </span>
        )}
        {classificationResult.customs_description}
      </dd>
      <dt className={hsCodeClass}>
        HS Code&nbsp;
        <Tooltip
          openOnHover
          trigger={<InfoCircle width={16} css={infoCircleStyle} />}
        >
          A Harmonized Schedule (HS) Code is a 6-digit code,
          shared by all WTO nations, to classify an item
        </Tooltip>
      </dt>
      <dd className={styles.infoValue}>
        {classificationResult.hs6 && (
          <Tag
            intent="primary"
            content={classificationResult.hs6}
          />
        )}
        {!classificationResult.hs6 && (
          <span>
            N/A
          </span>
        )}
      </dd>
      <dt className={styles.infoLabel}>
        Tariff Codes&nbsp;
        <Tooltip
          openOnHover
          trigger={<InfoCircle width={16} css={infoCircleStyle} />}
        >
          A Tariff Code is a nation-specific code used to
          classify an item in order to determine duties.
        </Tooltip>
      </dt>
      <dd className={styles.infoValue}>
        {dropdownOptions.length > 0 && (
          <div>
            <MultiSelect
              fluid
              searchable
              onValueChange={handleValueChange}
              options={dropdownOptions
                .map((o) => ({
                  value: o.iso_3166_3, content: o.name, icon: <CountryFlag country={o} />,
                }))}
              value={selectedCountries?.map((c) => c.iso_3166_3)}
            />
            <div
              className={classnames(styles.showAll)}
              onClick={bulkAction}
              onKeyPress={handleEnterKeyboardEvent(bulkAction)}
              role="button"
              tabIndex={0}
            >
              {bulkActionText}
            </div>
          </div>
        )}
        {dropdownOptions.length < 1 && (
          <span>
            N/A
          </span>
        )}
      </dd>
      <dt className={styles.infoLabel} />
      <dd className={styles.infoValue}>
        {displayedCodes.length > 0 && (
          <Table striped>
            <TableHead>
              <TableRow>
                <TableCell>
                  Destination Country
                </TableCell>
                <TableCell className={styles.tariffCodeCol}>
                  Tariff Code
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {displayedCodes.map((country) => (
                <TableRow key={country.iso_3166_3}>
                  <TableCell>
                    <CountryLabel country={country} />
                  </TableCell>
                  <TableCell className={styles.tariffCodeCol}>
                    {country.tariffCode}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </dd>
    </dl>
  );
};

ClassificationResults.displayName = 'ClassificationResults';

ClassificationResults.defaultProps = {
  selectedCountries: [],
};

export default ClassificationResults;
