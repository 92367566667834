import Client from './client';

export default class V1Checkouts extends Client {
  constructor(opts) {
    let options = opts;

    if (typeof opts === 'string') {
      options = { host: opts }; // convert host string to options object
    }

    options.serviceName = 'API Internal';

    super(options);
  }

  post(options = {}) {
    return this.makeRequest(`${this.host}/checkouts`, {
      ...options,
       method: 'POST',
    });
  }

  getV1ByV2CheckoutId(v2CheckoutId, options = {}) {
    return this.makeRequest(`${this.host}/checkouts/v1/${v2CheckoutId}`, options);
  }

  getById(id, options = {}) {
    return this.makeRequest(`${this.host}/checkouts/${id}`, options);
  }

  putSubmissionsById(id, options = {}) {
    return this.makeRequest(`${this.host}/checkouts/${id}/submissions`, {
      ...options,
       method: 'PUT',
    });
  }

}
