export function mark(
  markName: string,
): void {
  try {
    performance.mark(markName);
  } catch (error) {
    // Ignore this error
  }
}

export function measure(
  measureName: string,
  startMark?: string,
  endMark?: string,
): void {
  try {
    performance.measure(measureName, startMark, endMark);
  } catch (error) {
    // Ignore this error
  }
}
