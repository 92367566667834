import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { isSubmitting, submit } from 'redux-form';

import { createToast } from '../../console/actions';
import { deleteDialog } from '../actions';
import { getDialogProperty } from '../selectors';
import { ActionTypes, DialogName, FormName } from '../constants';
import TierWorksheet from '../components/TierWorksheet';
import { RootState, ThunkDispatcher } from '../../../stores/types';
import { TierWorksheetDispatchProps, TierWorksheetStateProps } from '../types/components';
import { CreateTierSuccess, UpdateTierSuccess } from '../types/actions';

const dialogName = DialogName.TIER_WORKSHEET;
const formName = FormName.TIER_UPSERT_FORM;

function handleCancel() {
  return deleteDialog({ type: dialogName });
}

function handleSave() {
  return submit(formName);
}

function handleSuccess(result: CreateTierSuccess | UpdateTierSuccess) {
  return function handleSuccessSideEffects(dispatch: ThunkDispatcher) {
    dispatch(deleteDialog({ type: dialogName }));
    if (result.type === ActionTypes.UPDATE_TIER_SUCCESS) {
      dispatch(createToast({ content: 'Shipping tier has been updated.' }));
    } else if (result.type === ActionTypes.CREATE_TIER_SUCCESS) {
      dispatch(createToast({ content: 'Shipping tier has been added.' }));
    }
  };
}

const mapStateToProps = createStructuredSelector<RootState, TierWorksheetStateProps>({
  currencies: getDialogProperty(dialogName, 'currencies'),
  initialValues: getDialogProperty(dialogName, 'initialValues'),
  isLoading: getDialogProperty(dialogName, 'loading'),
  isOpen: getDialogProperty(dialogName, 'open'),
  isSubmitting: isSubmitting(formName),
  method: getDialogProperty(dialogName, 'method'),
  ratecards: getDialogProperty(dialogName, 'ratecards'),
  title: getDialogProperty(dialogName, 'title'),
});

const mapDispatchToProps = (dispatch: ThunkDispatcher) => bindActionCreators({
  onCancel: handleCancel,
  onSave: handleSave,
  onSuccess: handleSuccess,
}, dispatch);

export default connect<
TierWorksheetStateProps,
TierWorksheetDispatchProps,
{},
RootState
>(mapStateToProps, mapDispatchToProps)(TierWorksheet);
