import Client from './client';

export default class MerchantApplicationSummaries extends Client {
  constructor(opts) {
    let options = opts;

    if (typeof opts === 'string') {
      options = { host: opts }; // convert host string to options object
    }

    options.serviceName = 'API Internal';

    super(options);
  }

  getChannelAndMerchantAndApplicationAndSummariesByChannelId(channelId, options = {}) {
    return this.makeRequest(`${this.host}/channel/${channelId}/merchant/application/summaries`, options);
  }

  getChannelAndMerchantAndApplicationAndSummariesAndV2ByChannelId(channelId, options = {}) {
    return this.makeRequest(`${this.host}/channel/${channelId}/merchant/application/summaries/v2`, options);
  }

}
