import { bindActionCreators, compose } from 'redux';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { changeSearchValue, scanNumber } from '../actions';
import { getOrganizationId } from '../../organization/selectors';
import SearchBar from '../components/SearchBar';
import { getCurrentSearchTerm, getCurrentOrderNumber, getShippingLabelRequestStatus } from '../selectors';
import { SearchBarStateProps, SearchBarDispatchProps } from '../types/components';
import { RootState } from '../../../stores/types';

const mapStateToProps: MapStateToProps<
SearchBarStateProps,
{},
RootState
> = createStructuredSelector({
  organizationId: getOrganizationId,
  searchText: getCurrentSearchTerm,
  currentOrderNumber: getCurrentOrderNumber,
  shippingLabelRequestStatus: getShippingLabelRequestStatus,
});

const mapDispatchToProps: MapDispatchToProps<
SearchBarDispatchProps,
{}
> = (dispatch) => bindActionCreators({
  onSubmit: scanNumber,
  onSearchChange: changeSearchValue,
}, dispatch);

const ConnectedSearchBar = compose<React.FC>(
  connect(mapStateToProps, mapDispatchToProps),
)(SearchBar);

export default ConnectedSearchBar;
