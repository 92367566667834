import { RootActionTypes } from '../../../stores/types';
import { ItemContentState } from '../types/state';
import { ActionTypes } from '../constants';

const initialState: ItemContentState = {
  itemContents: [],
};

export default function (previousState = initialState, action: RootActionTypes) {
  switch (action.type) {
    case ActionTypes.READ_ORDER_BY_IDENTIFIER_REQUEST:
    case ActionTypes.READ_ORDER_BY_NUMBER_REQUEST:
    case ActionTypes.FETCH_ITEM_CONTENT_REQUEST:
      return initialState;
    case ActionTypes.FETCH_ITEM_CONTENT_SUCCESS:
      return {
        itemContents: action.payload,
      };
    default:
      return previousState;
  }
}
