import ActionTypes from '../constants/action-types';
import withPagingAndSorting, { isPagingAndSortingResponse, WithPagingAndSortingOptions } from '../../../utilities/with-paging-and-sorting-v2';
import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import { WithPagingAndSortingResult } from '../../../utilities/types';

function createRequestAction(params: WithPagingAndSortingOptions): RootActionTypes {
  return {
    type: ActionTypes.FETCH_IMPORTS_REQUEST,
    params,
  };
}

function createSuccessAction(
  payload: WithPagingAndSortingResult<io.flow.v0.models.Import[]>,
  params: WithPagingAndSortingOptions,
): RootActionTypes {
  return {
    type: ActionTypes.FETCH_IMPORTS_SUCCESS,
    params,
    payload,
  };
}

function createFailureAction(
  payload: FormattedErrorMessages,
  params: WithPagingAndSortingOptions,
): RootActionTypes {
  return {
    type: ActionTypes.FETCH_IMPORTS_FAILURE,
    payload,
    params,
  };
}

export default function getAllImportedFiles(
  options: WithPagingAndSortingOptions = {},
): ThunkResult<Promise<void>> {
  return function getAllImportedFilesEffects(
    dispatch,
    getState,
    extra,
  ): Promise<void> {
    const {
      entriesPerPage,
      pageNumber,
      organization,
      sort,
      sortOrder,
    } = options;

    dispatch(createRequestAction(options));
    return withPagingAndSorting<
    io.flow.v0.models.Import[]
    >({
      pageNumber,
      entriesPerPage,
      sort,
      sortOrder,
    }, (params) => (
      extra.api(getState()).imports.get({
        organization,
        ...params,
      })
    ))().then((response) => {
      if (isPagingAndSortingResponse(response)) {
        dispatch(createSuccessAction(response.result, options));
        return;
      }

      const errors = formatErrorResponseV2(response);
      dispatch(createFailureAction(errors, options));
    });
  };
}
