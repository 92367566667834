import { browserHistory } from 'react-router';

function visitOptinMessageDetailsPage(
  organizationId: string,
  optinAttribute: io.flow.internal.v0.models.OptinAttribute,
  pageNumber = 1,
  entriesPerPage = 25,
): void {
  return browserHistory.push({
    pathname: `/${organizationId}/optin/messages/${optinAttribute.optin_attribute_key}`,
    query: {
      entriesPerPage,
      pageNumber,
    },
  });
}

export default visitOptinMessageDetailsPage;
