import { RSAA } from '@flowio/redux-async-middleware';
import { operationTypes } from '@flowio/redux-resource';
import { createQueryParams } from '@flowio/redux-resource-pagination-plugin';
import PropTypes from 'prop-types';

import { api } from '../../../../utilities/clients';
import { createResourceTypeDescriptors } from '../../helpers';
import { withAssertPropTypes } from '../../../../utilities/prop-types';

export default withAssertPropTypes({
  organizationId: PropTypes.string.isRequired,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pageNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
})(({ organizationId, pageNumber = 1, pageSize = 25 }) => ({
  [RSAA]: {
    types: createResourceTypeDescriptors({
      operationType: operationTypes.READ,
      resourceType: 'priceBooks',
      list: organizationId,
      pageSize,
      pageNumber,
    }),
    callAPI: (state) => api.priceBooks(state).get(organizationId, {
      params: createQueryParams({ pageSize, pageNumber }),
    }),
  },
}));
