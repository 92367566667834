import { createSelector } from 'reselect';
import { getDefaultReducer } from '../../console';
import getFacebookTokenSummary from './getFacebookTokenSummary';

const getIsTokenOwner = createSelector(
  getFacebookTokenSummary,
  getDefaultReducer,
  (tokenSummary, defaultState) => (
    !!(tokenSummary && tokenSummary.user_id === defaultState.user?.id)
  ),
);

export default getIsTokenOwner;
