import { createSelector } from 'reselect';

import getFeatures from './getFeatures';

export const getRuleFilters = createSelector(
  getFeatures,
  (features) => features.entities.ruleFilters || [],
);

export default getRuleFilters;
