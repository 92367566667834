import React from 'react';

import LabsPage from '../../LabsPage';
import { ComponentProps } from '../types';

const content = `As a client in our Strategic Accounts program, we're excited to invite you to join the alpha version of our newest product: Blaze - the World's Fastest Checkout. Our mission is to create the highest converting checkout that meets the needs of consumers globally. We are offering use of this premium module to you at no incremental cost.

Blaze provides:

 • Instant Load Time: built to be the world's fastest checkout
 • Amazing user experience on all devices
 • Boosted completion rates: Simple and high quality feedback to streamline user input

Setup is automatic and new countries will launch automatically as soon as they are ready.`;

const BlazeLab: React.FC<ComponentProps> = ({
  onAcceptLab,
  acceptingUser,
  labProjectSettings,
  error,
}) => (
  <LabsPage
    imageSource="https://cdn.flow.io/console/labs-blaze-hero-image.jpg"
    content={content}
    title="Blaze"
    featureLink="https://docs.flow.io/docs/blaze"
    lab={labProjectSettings}
    acceptingUser={acceptingUser}
    onAcceptLab={onAcceptLab}
    error={error}
  />
);

export default BlazeLab;
