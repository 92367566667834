import {
  reduxForm,
  formValueSelector,
  submit,
  FormErrors,
} from 'redux-form';
import moment from 'moment';
import { compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withFetch } from '@flowio/redux-fetch';
import { type RouterState, browserHistory } from 'react-router';
import find from 'lodash/find';
import FormName from '../../../constants/FormName';

import DiscountDetailsForm from '../components/DiscountDetailsForm';
import { RootState, ThunkDispatcher } from '../../../../../stores/types';
import { fetchAllExperiences } from '../../../../experience/actions';
import { getExperiences } from '../../../../experience';
import { getCurrentDiscount } from '../../../selectors';
import DiscountType from '../../../constants/DiscountType';
import {
  DropdownValue,
} from '../../../types';
import updateDiscount from '../../../actions/updateDiscount';
import createDiscount from '../../../actions/createDiscount';
import { createToast } from '../../../../console/actions';
import fetchDiscount from '../../../actions/fetchDiscount';
import {
  DiscountDetailsFormValues, MergedProps, StateProps, DispatchProps,
} from '../types';

// eslint-disable-next-line
const { timeOptions, timeZones } = require('../../../constants/timeOptions.json');

const getAsyncState = (
  dispatch: ThunkDispatcher,
  _getState: () => RootState,
  ownProps: RouterState,
): Promise<unknown[]> => {
  const {
    params: {
      organization,
      discountId,
    },
  } = ownProps;

  return Promise.all([
    dispatch(fetchAllExperiences(organization, {
      q: 'and status in (active, draft)',
      sort: 'name',
      sortOrder: 'ascending',
    })),
    dispatch(fetchDiscount(organization, discountId)),
  ]);
};

const defaultTime = {
  value: '0000',
  content: '12:00 am',
};

const defaultZone = {
  value: '0',
  content: 'GMT (UTC +0:00)',
};

const defaultInitialValues = {
  validityFromDate: moment().toDate(),
  validityFromTime: defaultTime,
  validityFromZone: defaultZone,
  validityToTime: defaultTime,
  validityToZone: defaultZone,
  validityToDate: moment().toDate(),
  type: {
    content: 'Tax Subsidy',
    value: DiscountType.TAX,
  },
  noEndDate: true,
};

function formatDate(date: Date, time: DropdownValue): string {
  const hours = Number(time.value.substring(0, 2));
  const minutes = Number(time.value.substring(2, 4));
  const momentDate = moment.utc(date);
  const formattedDate = moment().utc()
    .date(momentDate.date())
    .month(momentDate.month())
    .year(momentDate.year())
    .hours(hours)
    .minutes(minutes)
    .seconds(0);

  return formattedDate.toISOString(true);
}

function handleSubmit(
  values: DiscountDetailsFormValues,
  dispatch: ThunkDispatcher,
  props: MergedProps,
): Promise<void | io.flow.v0.models.DiscountRuleSettings> {
  const {
    params: {
      organization,
    },
  } = props;
  const discountForm: io.flow.v0.models.DiscountRuleSettingsForm = {
    name: values.name,
    from_with_tz: {
      datetime: formatDate(values.validityFromDate, values.validityFromTime),
      timezone: values.validityFromZone.value,
    },
    to_with_tz: !values.noEndDate ? {
      datetime: formatDate(values.validityToDate, values.validityToTime),
      timezone: values.validityToZone.value,
    } : undefined,
    experience_keys: values.experiences.map((experience) => experience.key),
    rule: {
      offers: [
        {
          discount: {
            discriminator: 'discount_offer_percent',
            percent: 100,
          },
          entitlement: {
            discriminator: 'subsidy',
            targets: [values.type.value],
          },
        },
      ],
    },
  };

  if (values.id) {
    return dispatch(updateDiscount(organization, values.id, discountForm));
  }
  return dispatch(createDiscount(organization, discountForm));
}

function mapStateToProps(state: RootState):
StateProps {
  const discountFormSelector = formValueSelector(FormName.DISCOUNT_DETAILS_FORM);
  const currentDiscount = getCurrentDiscount(state);
  const status = currentDiscount?.status;
  const experiences = getExperiences(state);
  const currentTimezone = moment().zone();
  let initialValuesToUse;

  if (currentDiscount) {
    const fromDate = moment(currentDiscount.from_with_tz.datetime);
    let toDate = currentDiscount.to_with_tz ? moment(currentDiscount.to_with_tz.datetime)
      : undefined;

    if (currentDiscount.to_with_tz) {
      toDate = moment(currentDiscount.to_with_tz.datetime);
    }

    const fromDateTime = find(timeOptions, (option) => option.value === fromDate.utc().format('HHmm'));
    const fromDateTimeZone = find(timeZones, (zone) => {
      if (currentDiscount.from_with_tz) {
        return zone.value === currentDiscount.from_with_tz.timezone;
      }
      return zone.value === fromDate.format('ZZ');
    });

    let toDateTime;
    let toDateTimeZone;

    if (toDate) {
      toDateTime = find(timeOptions, (option) => {
        if (toDate) {
          return option.value === toDate.utc().format('HHmm');
        }
        return false;
      });
      toDateTimeZone = find(timeZones, (zone) => {
        if (toDate && currentDiscount.to_with_tz) {
          return zone.value === currentDiscount.to_with_tz.timezone;
        }

        return false;
      });
    } else {
      toDateTime = defaultTime;
      toDateTimeZone = defaultZone;
    }

    const selectedExperienceOptions = currentDiscount.experience_keys.map((key) => {
      const fullExperience = find(experiences, (experience) => experience.key === key);
      return {
        name: fullExperience?.name || key,
        key,
      };
    });

    let discountType;

    const subsidy = find(currentDiscount.rule.offers, (offer) => offer.entitlement.discriminator === 'subsidy');

    if (subsidy) {
      const { targets } = subsidy.entitlement;
      const isTax = targets.includes('vat');

      discountType = isTax ? {
        content: 'Tax Subsidy',
        value: DiscountType.TAX,
      } : {
        content: 'Duty Subsidy',
        value: DiscountType.DUTY,
      };
    }

    // Need to add in the timezone offset since date edit will parse it into the local timezone
    initialValuesToUse = {
      id: currentDiscount.id,
      name: currentDiscount.name,
      type: discountType,
      experiences: selectedExperienceOptions,
      validityFromDate: moment(currentDiscount.from_with_tz.datetime).add(currentTimezone / 60, 'h').toDate(),
      validityFromTime: fromDateTime,
      validityFromZone: fromDateTimeZone || defaultZone,
      validityToDate: moment(currentDiscount.to_with_tz?.datetime).add(currentTimezone / 60, 'h').toDate() || moment().toDate(),
      validityToTime: toDateTime,
      validityToZone: toDateTimeZone || defaultZone,
      noEndDate: !toDate,
    };
  } else {
    initialValuesToUse = defaultInitialValues;
  }

  return {
    validityFromDate: discountFormSelector(state, 'validityFromDate') || moment().toDate(),
    experiences,
    status,
    noEndDate: discountFormSelector(state, 'noEndDate') || false,
    initialValues: initialValuesToUse,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  ownProps: RouterState,
): DispatchProps {
  return {
    onCancel: (): void => {
      browserHistory.push({
        pathname: `/${ownProps.params.organization}/discounts`,
      });
    },
    handleSubmit: (): void => {
      dispatch(submit(FormName.DISCOUNT_DETAILS_FORM));
    },
  };
}

export default compose(
  withFetch(getAsyncState),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<DiscountDetailsFormValues, MergedProps>({
    form: FormName.DISCOUNT_DETAILS_FORM,
    onSubmit: handleSubmit,
    validate: (values: DiscountDetailsFormValues): FormErrors<DiscountDetailsFormValues> => {
      const errors: FormErrors<DiscountDetailsFormValues> = {};

      if (!values.name || values.name.length < 1) {
        errors.name = 'Required';
      }

      if (!values.experiences || values.experiences.length < 1) {
        errors.experiences = 'Please select at least one experience';
      }

      if (!values.noEndDate) {
        const fromDateInMilis = moment(values.validityFromDate).valueOf();
        const toTimeDateInMilis = moment(values.validityToDate).valueOf();

        if (fromDateInMilis > toTimeDateInMilis) {
          errors.validityToDate = 'Please select a later date';
        }
      }

      return errors;
    },
    onSubmitSuccess: (
      result: void | io.flow.v0.models.DiscountRuleSettings,
      dispatch: Dispatch,
      props: MergedProps,
    ) => {
      if (result) {
        const {
          params: {
            organization,
          },
        } = props;
        dispatch(createToast({
          intent: 'positive',
          content: 'Discount rule setting saved',
        }));
        browserHistory.push({
          pathname: `/${organization}/discounts`,
        });
      } else {
        dispatch(createToast({
          intent: 'negative',
          content: 'Failed to update discount rule settings',
        }));
      }
    },
  }),
)(DiscountDetailsForm);
