import { Confirm } from '@flowio/react-confirm';
import React from 'react';
import OptinMessageDeleteForm from '../../OptinMessageDeleteForm/containers/OptinMessageDeleteForm';
import { Props } from '../types';

const OptinMessageDeleteConfirm: React.FC<Props> = ({
  initialValues,
  onSubmitSuccess,
  open = false,
  optinAttribute,
  ...unhandledProps
}) => (
  <Confirm
    {...unhandledProps}
    intent="negative"
    open={open}
  >
    <OptinMessageDeleteForm
      initialValues={initialValues}
      onSubmitSuccess={onSubmitSuccess}
      optinAttribute={optinAttribute}
    />
  </Confirm>
);

OptinMessageDeleteConfirm.displayName = 'OptinMessageDeleteConfirm';

export default OptinMessageDeleteConfirm;
