import ActionTypes from '../constants/action-types';

import { getOrganizationId } from '../../organization';
import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';

function createRequestAction(): RootActionTypes {
  return {
    type: ActionTypes.FETCH_ALLOCATIONS_REQUEST,
  };
}

function createSuccessAction(payload: io.flow.v0.models.AllocationV2): RootActionTypes {
  return {
    type: ActionTypes.FETCH_ALLOCATIONS_SUCCESS,
    payload,
  };
}

function createFailureAction(error: FormattedErrorMessages): RootActionTypes {
  return {
    type: ActionTypes.FETCH_ALLOCATIONS_FAILURE,
    error: true,
    payload: error,
  };
}

export default function fetchAllocations(orderNumber: string): ThunkResult<Promise<void>> {
  return function fetchAllocationsEffects(
    dispatch,
    getState,
    extra,
  ): Promise<void> {
    const state = getState();
    dispatch(createRequestAction());

    return extra.api(state).allocation.get({
      organization: getOrganizationId(state),
      number: orderNumber,
    }).then((response) => {
      if (response.ok) {
        dispatch(createSuccessAction(response.body));
        return;
      }

      const formattedErrors = formatErrorResponseV2(response);
      dispatch(createFailureAction(formattedErrors));
    });
  };
}
