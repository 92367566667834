import { isNotNil } from '@flowio/is';

const getTextFieldValue = (value: any, key?: string): string => {
  if (typeof value === 'object' && key) {
    return getTextFieldValue(value[key]);
  }
  return (isNotNil(value) ? String(value) : '');
};

export default getTextFieldValue;
