import {
  Card, CardHeader, CardContent, CardFooter, CardTitle,
} from '@flowio/react-card';
import React from 'react';
import get from 'lodash/get';
import { PropertyPath } from 'lodash';

import { roundPercentagesBy } from '../../../../../utilities/numbers';
import Navigation from '../../navigation';
import ProductCatalogOverview from '../../product-catalog-overview';
import UpdateExperienceForm from '../../UpdateExperienceForm';
import { MergedProps as Props } from '../types';
import * as styles from './localization.styles';

function toPercentage(number: number, total: number): number {
  if (Number.isNaN(number) || Number.isNaN(total) || total === 0) return 0;
  return (number / total) * 100;
}

const ExperienceLocalization: React.FC<Props> = ({
  countries,
  currencies,
  experience,
  languages,
  organization,
  regions,
  statistics,
}) => {
  const roundPercentages = roundPercentagesBy(100);

  const stats = (path: PropertyPath): number => get(statistics, path, NaN);

  const percentages = [
    toPercentage(stats('included.items'), stats('catalog.items')),
    toPercentage(stats('excluded.items'), stats('catalog.items')),
    toPercentage(stats('restricted.items'), stats('catalog.items')),
  ];

  const [includedPercent, excludedPercent, restrictedPercent] = roundPercentages(...percentages);

  if (!experience) {
    throw new Error('ExperienceLocalization: Missing expected experience');
  }

  return (
    <section className={[styles.experienceLocalization, 'experience-localization'].join(' ')}>
      <Navigation
        organization={organization}
        experience={experience.key}
      />
      <UpdateExperienceForm
        countries={countries}
        currencies={currencies}
        experience={experience}
        organizationId={organization}
        languages={languages}
        regions={regions}
      />
      <Card id="catalog-overview">
        <CardHeader dividing>
          <CardTitle content="Product Catalog Overview" />
        </CardHeader>
        <CardContent>
          <ProductCatalogOverview
            experience={experience}
            productCount={statistics.included && statistics.included.items}
            includedPercent={includedPercent}
            excludedPercent={excludedPercent}
            restrictedPercent={restrictedPercent}
          />
        </CardContent>
        <CardFooter />
      </Card>
    </section>
  );
};

ExperienceLocalization.displayName = 'ExperienceLocalization';

export default ExperienceLocalization;
