import React from 'react';
import noop from 'lodash/noop';
import {
  Card, CardHeader, CardContent, CardTitle,
} from '@flowio/react-card';

import Errors from '../../../../console/components/errors';
import ItemMarginForm from '../../item-margins/item-margin-form';

import { MergedProps as Props } from '../types';
import * as styles from '../../item-margins/item-margin-summary.styles';

const ItemMarginEdit: React.FC<Props> = ({
  currency,
  errors,
  itemMargin,
  onCancel,
}) => (
  <section className={[styles.itemMarginEdit, 'item-margin-edit'].join(' ')}>
    <Card>
      <CardHeader dividing>
        <CardTitle content="Edit Margin" />
      </CardHeader>
      <CardContent>
        <Errors errors={errors && errors.messages} />
        <ItemMarginForm
          itemMargin={itemMargin}
          currency={currency}
          onCancel={onCancel}
          editing
        />
      </CardContent>
    </Card>
  </section>
);

ItemMarginEdit.defaultProps = {
  currency: 'USD',
  errors: {},
  onCancel: noop,
};
ItemMarginEdit.displayName = 'ItemMarginEdit';

export default ItemMarginEdit;
