import { css } from '@emotion/css';
import colors from '../../../../theme/tokens/colors';

export const authNavigationItem = css`
  line-height: 1;

  &:not(:first-child) {
    margin-left: 8px;
    padding-left: 8px;
    border-left: 1px solid ${colors.steel};
  }
`;

export const link = css`
  color: ${colors.steel};
  font-size: 0.875rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  text-align: left;
  text-decoration: none;

  &:active,
  &:link,
  &:visited {
    color: ${colors.steel};
  }
`;
