import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { ThemeProvider as EmotionProvider } from '@emotion/react';
import { applyRouterMiddleware, Router } from 'react-router';
import { useFetch } from '@flowio/redux-fetch';
import { useScroll } from 'react-router-scroll';

import themeCorporate from '../../common/theme';
import { useGoogleAnalytics } from '../../common/modules/google-analytics';
import InternalServerError from '../../common/modules/console/components/internal-server-error';

const Root = ({ renderProps, store }) => {
  const enhancers = applyRouterMiddleware(
    useGoogleAnalytics('UA-77747620-3'),
    useFetch({ renderFailure: () => (<InternalServerError />) }),
    useScroll(),
  );
  return (
    <Provider store={store}>
      <EmotionProvider theme={themeCorporate}>
        <Router {...renderProps} render={enhancers} />
      </EmotionProvider>
    </Provider>
  );
};

Root.displayName = 'Root';

/* eslint-disable react/forbid-prop-types */
Root.propTypes = {
  renderProps: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
};

export default Root;
