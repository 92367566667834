import { compose } from 'redux';
import { FormErrors, reduxForm, SubmissionError } from 'redux-form';
import isEmpty from 'lodash/isEmpty';
import { ThunkDispatcher } from '../../../stores/types';

import {
  submitFeatureRuleForm,
} from '../actions';
import AddRuleWorksheetForm from '../components/AddRuleWorksheetForm';
import FormName from '../constants/FormName';
import { AddRuleWorksheetFormOwnProps, AddRuleWorksheetFormValues } from '../types/components';

interface ConditionErrors {
  field: string;
  operator: string;
  values: string;
}

interface AddRuleWorksheetFormErrors {
  organizationId: string;
  filters: {
    _error: string;
  };
  conditions: Partial<ConditionErrors>[];
  value: string;
}

function handleValidation(
  values: AddRuleWorksheetFormValues,
): FormErrors {
  const errors: Partial<AddRuleWorksheetFormErrors> = {};
  const {
    organizationId,
    conditions,
    value,
  } = values;

  if (!organizationId) {
    errors.organizationId = 'An organization identifier is required';
  }

  if (!conditions || (Array.isArray(conditions) && !conditions.length)) {
    errors.filters = {
      _error: 'At least one condition must be added',
    };
  } else {
    const conditionArrayErrors: Partial<ConditionErrors>[] = [];

    if (Array.isArray(conditions)) {
      conditions.forEach((condition, index) => {
        const conditionErrors: Partial<ConditionErrors> = {};

        if (condition.discriminator === 'structured') {
          if (condition.discriminator === 'structured' && !condition.field) {
            conditionErrors.field = 'A field is required for each condition';
          }

          if (!condition.operator) {
            conditionErrors.operator = 'An operator must be selected for each condition';
          }

          if (!Array.isArray(condition.values)) {
            conditionErrors.values = 'ERROR: The value of each condition must be an Array.';
          } else if (!condition.values.length) {
            conditionErrors.values = 'A value must be supplied for each condition';
          }
        }

        conditionArrayErrors[index] = conditionErrors;
      });
    }

    if (conditionArrayErrors.length) {
      errors.conditions = conditionArrayErrors;
    }
  }

  if (!value) {
    errors.value = 'A value must be supplied for the rule';
  }

  return errors;
}

function handleSumbit(values: AddRuleWorksheetFormValues, dispatch: ThunkDispatcher) {
  const {
    conditions,
  } = values;

  if (!conditions || isEmpty(conditions)) {
    throw new SubmissionError({
      _error: {
        messages: ['At least one condition is required for to create a rule'],
        code: 'generic_error',
      },
    });
  }

  return dispatch(submitFeatureRuleForm(values));
}

export default compose<React.FC<AddRuleWorksheetFormOwnProps>>(
  reduxForm({
    form: FormName.ADD_RULE_FORM,
    onSubmit: handleSumbit,
    validate: handleValidation,
  }),
)(AddRuleWorksheetForm);
