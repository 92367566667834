import map from 'lodash/map';
import uniqBy from 'lodash/uniqBy';
import ActionTypes from '../constants/action-types';

const defaultState = [];

const mapRateCardsToServices = (ratecards) => {
  const services = map(ratecards, 'service');
  return uniqBy(services, 'id');
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case ActionTypes.FETCH_RATECARDS_SUCCESS:
      return mapRateCardsToServices(action.payload);
    default:
      return state;
  }
}
