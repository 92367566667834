import { Field, FieldArray, Form } from 'redux-form';
import React from 'react';
import { Stack, StackItem } from '@flowio/react-stack';

import GenericError from '../../../../components/GenericError/GenericError';
import TierNameCard from '../TierNameCard';
import TierCarrierServiceLevelCard from '../TierCarrierServiceLevelCard';
import TierCarrierServiceStrategyCard from '../TierCarrierServiceStrategyCard';
import TierShippingPriceRuleBuilderCard from '../TierShippingPriceRuleBuilderCard';
import TierDeliveryWindowCard from '../TierDeliveryWindowCard';
import length from '../../../../utilities/validators/length';
import presence from '../../../../utilities/validators/presence';
import TierSurchargeCard from '../TierSurchargeCard';
import TierAdditionalSettingsCard from '../TierAdditionalSettingsCard';
import { TierUpsertFormCombinedProps } from '../../types/components';

const validateRules = [
  presence({ allowEmpty: false, message: 'Add one or more shipping price rules' }),
  length({ minimum: 1, message: 'Add one or more shipping price rules' }),
];

const TierUpsertForm: React.FC<TierUpsertFormCombinedProps> = ({
  currencies,
  error,
  handleSubmit,
  method,
  onResetServices,
  onSelectAllServices,
  ratecards,
  selectedServices = [],
  surchargeSettings,
}) => (
  <Form method={method} onSubmit={handleSubmit}>
    <Field type="hidden" component="input" name="action" />
    <Field type="hidden" component="input" name="direction" />
    <Field type="hidden" component="input" name="tierId" />
    <Field type="hidden" component="input" name="integration" />
    <Field type="hidden" component="input" name="shippingConfigurationKey" />
    <Field type="hidden" component="input" name="shippingLaneId" />
    <Field type="hidden" component="input" name="visibility" />
    <Stack orientation="vertical" spacing="loose">
      {error != null && (
        <StackItem>
          <GenericError error={error} />
        </StackItem>
      )}
      <StackItem>
        <TierNameCard />
      </StackItem>
      <StackItem>
        <TierCarrierServiceLevelCard
          onSelectAll={onSelectAllServices}
          onSelectNone={onResetServices}
          selectedServices={selectedServices}
          ratecards={ratecards}
        />
      </StackItem>
      <StackItem>
        <TierCarrierServiceStrategyCard />
      </StackItem>
      <StackItem>
        <FieldArray
          component={TierShippingPriceRuleBuilderCard}
          currencies={currencies}
          name="rules"
          validate={validateRules}
        />
      </StackItem>
      <StackItem>
        <TierDeliveryWindowCard />
      </StackItem>
      <StackItem>
        <TierSurchargeCard surchargeSettings={surchargeSettings} />
      </StackItem>
      <StackItem>
        <TierAdditionalSettingsCard />
      </StackItem>
    </Stack>
  </Form>
);

TierUpsertForm.displayName = 'TierUpsertForm';

export default TierUpsertForm;
