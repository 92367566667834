import PropTypes from 'prop-types';
import React from 'react';
import { Checkbox } from '@flowio/react-checkbox';
import noop from 'lodash/noop';
import get from 'lodash/get';

// Usage
// -----
//
// <CheckboxGroup name="events" onCheck={(selectedValues) => { console.log(selectedValues); }}>
//   <Checkbox value="catalog_upserted" />
//   <Checkbox value="catalog_deleted" />
//   <Checkbox value="subcatalog_upserted" />
//   <Checkbox value="subcatalog_deleted" />
// <CheckboxGroup />

export default class CheckboxGroup extends React.Component {
  constructor(props) {
    super(props);
    const { value } = this.props;
    this.state = { selected: value || [] };
  }

  static getDerivedStateFromProps(props) {
    if (Object.prototype.hasOwnProperty.call(props, 'value')) {
      return { selected: props.value };
    }
    return null;
  }

  handleChange = (event) => {
    const { value } = event.target;
    const { selected } = this.state;
    const { onCheck } = this.props;

    if (event.target.checked) {
      selected.push(value);
    } else {
      const index = selected.indexOf(value);
      selected.splice(index, 1);
    }

    this.setState({ selected });

    if (onCheck) {
      onCheck(selected);
    }
  }

  render() {
    const {
      items, itemClassName, itemValueKey, itemLabelKey, name, value, onCheck, ...otherParentProps
    } = this.props;
    const { selected } = this.state;
    return (
      <div {...otherParentProps}>
        {items.map((item) => (
          <Checkbox
            checked={selected.indexOf(get(item, itemValueKey)) >= 0}
            className={itemClassName}
            key={`${get(item, itemLabelKey)}_${get(item, itemValueKey)}`}
            labelText={get(item, itemLabelKey)}
            value={get(item, itemValueKey)}
            onChange={this.handleChange}
          />
        ))}
      </div>
    );
  }
}
CheckboxGroup.displayName = 'CheckboxGroup';

CheckboxGroup.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.array.isRequired,
  itemValueKey: PropTypes.string.isRequired,
  itemLabelKey: PropTypes.string.isRequired,
  itemClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.string.isRequired),
  onCheck: PropTypes.func,
};

CheckboxGroup.defaultProps = {
  value: undefined,
  itemClassName: '',
  onCheck: noop,
};
