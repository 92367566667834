import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';
import { Box } from '@flowio/react-box';
import { Button } from '@flowio/react-button';
import {
  Card, CardHeader, CardContent, CardFooter, CardTitle,
} from '@flowio/react-card';
import { MenuDivider } from '@flowio/react-menu';

import PolicyLayout from '../policy-layout/policy-layout';
import * as styles from './policy-overview.styles';

const PolicyOverview = ({ organization }) => (
  <PolicyLayout>
    <Card className="policy">
      <CardHeader>
        <CardTitle content="Policies" />
        <MenuDivider className={styles.policiesDivider} />
      </CardHeader>
      <CardContent fitted>
        <Box className={styles.policyContent} justifyContent="between">
          <div className={styles.policyContentBody}>
            Terms &amp; Conditions
          </div>
          <Link to={`/${organization}/organization/policies/terms`}>
            <Button className={styles.policyContentButton} content="View" />
          </Link>
        </Box>
        <Box className={styles.policyContent} justifyContent="between">
          <div className={styles.policyContentBody}>
            GDPR FAQ
          </div>
          <Link to={`/${organization}/organization/policies/gdpr`}>
            <Button className={styles.policyContentButton} content="View" />
          </Link>
        </Box>
      </CardContent>
      <CardFooter />
    </Card>
  </PolicyLayout>
);

PolicyOverview.displayName = 'PolicyOverview';

PolicyOverview.propTypes = {
  organization: PropTypes.string.isRequired,
};

export default PolicyOverview;
