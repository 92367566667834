import { createSelector } from 'reselect';
import get from 'lodash/get';
import getReturnPolicyDeleteConfirmation from './getReturnPolicyDeleteConfirmation';

const getHasReturnPolicyDeleteSucceeded = createSelector(
  getReturnPolicyDeleteConfirmation,
  (state) => get(state, 'deleteSucceeded'),
);

export default getHasReturnPolicyDeleteSucceeded;
