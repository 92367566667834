import { Button, OutlineButton } from '@flowio/react-button';
import React from 'react';
import { Props } from '../types';
import MarketingDialog from '../../../../marketing/components/MarketingDialog';
import AuthModalBackdrop from '../../../../marketing/components/AddAuthDialog/components/AuthModalBackdrop';

const FacebookReauthenticationModal: React.FC<Props> = ({
  hideModal,
  isOpen,
  isTokenOwner,
  accessTokenSummary,
  isAuthenticating,
  handleReauthenticationRequest,
}) => {
  const renewMessageTitle = isTokenOwner ? 'To successfully renew access to Facebook you need to be logged into your Facebook Business Manager Account.'
    : 'Please read before continuing.';

  const renewMessageSubtitle = isTokenOwner ? 'To complete this, click \'Go to Facebook\' and log in. After you log in, access to your account will automatically be renewed and you\'ll be redirected back to Flow.'
    : `Access to the Facebook Business Manager account is linked to the Facebook account owned by ${accessTokenSummary?.user_email || 'another user'}. For you to successfully renew access, you will need to have permission on Facebook to access the Facebook Catalog ${accessTokenSummary?.catalog_id ? `(ID: ${accessTokenSummary?.catalog_id}) ` : ''}currently being used.`;

  const handleConfirmClick = () => handleReauthenticationRequest(accessTokenSummary?.catalog_id);

  return (
    <MarketingDialog
      topContent={<AuthModalBackdrop platformId="facebook" />}
      title={renewMessageTitle}
      subtitle={renewMessageSubtitle}
      isOpen={isOpen}
    >
      <OutlineButton
        content="Cancel"
        onClick={hideModal}
        disabled={isAuthenticating}
      />
      <Button
        intent="primary"
        content="Go to Facebook"
        loading={isAuthenticating}
        disabled={isAuthenticating}
        onClick={handleConfirmClick}
      />
    </MarketingDialog>
  );
};

FacebookReauthenticationModal.displayName = 'FacebookReauthenticationModal';

export default FacebookReauthenticationModal;
