import { InfoCircle } from '@flowio/react-icons';
import { Link } from 'react-router';
import { TierEstimateType, DeliveredDuty } from '@flowio/api-constants';
import React from 'react';
import numeral from 'numeral';
import map from 'lodash/map';
import isUndefined from 'lodash/isUndefined';
import get from 'lodash/get';
import find from 'lodash/find';
import filter from 'lodash/filter';
import { Popover } from '@flowio/react-popover';
import {
  Table,
  TableRow,
  TableRowColumn,
  TableHeadColumn,
  TableBody,
} from '@flowio/react-table';
import { css } from '@emotion/react';
import classNames from 'classnames';
import { Image } from '@flowio/react-image';
import FormattedDate from '../../../../components/formatted-date';
import * as styles from './order-items.styles';
import { OrderItemDeliveryItem, OrderItemsMergedProps } from '../../types';

interface AllocationCostMap {
  [key: string]: {
    tax: number;
    duty: number;
  };
}

const displayName = 'OrderItems';

const tooltipStyles = css({
  overflow: 'visible',
});

const getDeliveryItemPrice = (
  deliveryItem: OrderItemDeliveryItem,
  order: io.flow.v0.models.Order,
): io.flow.v0.models.PriceWithBase | undefined => {
  const { line_number: lineNumber } = deliveryItem;
  const line = lineNumber !== undefined && order.lines ? order.lines[lineNumber] : undefined;

  return line?.price;
};

const getPriceNameWithFallback = (price: io.flow.v0.models.OrderPriceDetail): string => {
  if (price.name) return price.name;
  if (price.key === 'subtotal') return 'Item Subtotal';
  return price.key;
};

const getPriceBaseLabel = (price: Price): string => {
  if (price.component) {
    return get(price.component.base, 'label', '');
  }
  return get(price.base, 'label', '');
};

const getPriceLabel = (price: Price): string => {
  if (price.component) {
    return get(price.component, 'label', '');
  }
  return get(price, 'label', '');
};

const getAllocationsCostMapping = (
  allocations: io.flow.v0.models.AllocationV2,
  orderItems: io.flow.v0.models.LocalizedLineItem[],
): AllocationCostMap => {
  if (isUndefined(allocations)) {
    return {};
  }

  const allocationDetails = get(allocations, 'details');
  const orderItemAllocations = orderItems.map((orderItem) => {
    const itemNumber = orderItem.number;
    const allocationItemDetail = find(
      allocationDetails,
      (detail: io.flow.v0.models.AllocationLineDetail) => detail.number === itemNumber,
    );
    const allocationPrices = get(allocationItemDetail, 'included', []).concat(get(allocationItemDetail, 'not_included', []));
    const tax = get(find(allocationPrices, (price) => price.key === 'vat_item_price'), 'rate', 0);
    const duty = get(find(allocationPrices, (price) => price.key === 'duties_item_price'), 'rate', 0);
    return {
      [itemNumber]: {
        tax,
        duty,
      },
    };
  });
  return Object.assign(
    {},
    ...orderItemAllocations,
  );
};

type Price = (
  io.flow.v0.models.OrderPriceDetail & {
    component?: io.flow.v0.models.OrderPriceDetailComponent }
);

const OrderItems: React.FC<OrderItemsMergedProps> = ({
  allocations,
  deliveries = [],
  order,
  organization,
  isCustomerServiceUser = true,
}) => {
  const itemAllocations = getAllocationsCostMapping(allocations, order.items);
  // eslint-disable-next-line prefer-destructuring
  const prices: Price[] = order.prices;
  const discountIdx = prices.findIndex((price) => price.key === 'discount');
  if (discountIdx !== -1) {
    const discounts = prices[discountIdx];
    if (discounts.amount !== 0) {
      const discountComponents: Price[] = discounts.components
        .filter((component) => component.amount !== 0).map((component) => ({
          ...discounts,
          component,
        }));

      prices.splice(discountIdx, 1, ...discountComponents);
    }
  }
  return (
    <div className={styles.orderItems}>
      <Table striped displayDensity="compact">
        {map(deliveries, (delivery, index) => (
          <TableBody key={delivery.id} id={delivery.id}>
            {map(filter(delivery.options, { selected: true }), (deliveryOption) => (
              <TableRow key={deliveryOption.id} className={styles.deliveryOption}>
                <TableRowColumn>
                  <span className="delivery-number">
                    Delivery #
                    {index + 1}
                    :
                  </span>
                </TableRowColumn>
                <TableRowColumn
                  className={classNames(styles.deliveryOption, styles.deliveryOptionTier)}
                >
                  <span className="delivery-label">Shipping Tier: </span>
                  {deliveryOption.tier.name}
                  <br />
                  <span className="delivery-label">Carrier & Service Level: </span>
                  {' '}
                  {deliveryOption.service.id}
                  {' '}
                  <br />
                  {(get(deliveryOption.tier, 'display.estimate.type', 'calculated') === TierEstimateType.CUSTOM) ? ( // eslint-disable-line max-len
                    <span>
                      <span className="delivery-label">Custom Delivery Window Speed:</span>
                      {' '}
                      <span>
                        &ldquo;
                        {get(deliveryOption.tier, 'display.estimate.label')}
                        &rdquo;
                      </span>
                      <br />
                    </span>
                  ) : (
                    <span>
                      <span className="delivery-label">Speed:</span>
                      {' '}
                      <span>{deliveryOption.window.label}</span>
                      <br />
                      <span className="delivery-label">Original Delivery Window:</span>
                      {' '}
                      <FormattedDate value={deliveryOption.window.from} format="MMM DD YYYY" utc />
                      {' '}
                      &mdash;
                      {' '}
                      <FormattedDate value={deliveryOption.window.to} format="MMM DD YYYY" utc />
                      <br />
                    </span>
                  )}
                  <span className="delivery-label">Center: </span>
                  {get(delivery, 'center.key')}
                  <br />
                </TableRowColumn>
              </TableRow>
            ))}
            <TableRow>
              <TableHeadColumn className={classNames(styles.columnHead, 'first')} />
              <TableHeadColumn className={classNames(styles.columnHead, 'first')}>Number</TableHeadColumn>
              <TableHeadColumn className={classNames(styles.columnHead)}>Name</TableHeadColumn>
              <TableHeadColumn className={classNames(styles.columnHead)}>Quantity</TableHeadColumn>
              <TableHeadColumn className={classNames(styles.columnHead)}>Duty Rate</TableHeadColumn>
              <TableHeadColumn className={classNames(styles.columnHead)}>Tax Rate</TableHeadColumn>
              <TableHeadColumn
                className={classNames(
                  styles.columnHead, styles.currency, styles.basePrice,
                )}
              >
                Base Price
                <Popover
                  openOnHover
                  css={tooltipStyles}
                  offset="-99px -10px"
                  position="top left"
                  style={{ overflow: 'visible' }}
                  trigger={<InfoCircle css={{ height: '1rem', marginLeft: '0.5rem' }} />}
                >
                  <div className={classNames(styles.tooltip, 'center')}>
                    Base Price is the original item price & currency in the product catalog.
                  </div>
                </Popover>
              </TableHeadColumn>
              <TableHeadColumn
                className={classNames(
                  styles.columnHead, styles.localPrice, { [styles.currency]: true },
                )}
              >
                Local Price
                <Popover
                  openOnHover
                  css={tooltipStyles}
                  offset="17px -10px"
                  position="top right"
                  trigger={<InfoCircle css={{ height: '1rem', marginLeft: '0.5rem' }} />}
                >
                  <div className={classNames(styles.tooltip, 'right')}>Local Price is the localized item price & currency your customer saw and paid.</div>
                </Popover>
              </TableHeadColumn>
            </TableRow>
            {map(delivery.items, (deliveryItem) => {
              const {
                cid,
                number,
                name,
                image,
                quantity,
              } = deliveryItem;
              const price = getDeliveryItemPrice(deliveryItem, order);
              const cost = itemAllocations[number];
              const duty = get(cost, 'duty', 0);
              const tax = get(cost, 'tax', 0);
              const taxAndDutyNotAvailable = order.delivered_duty === DeliveredDuty.UNPAID
                && duty === 0
                && tax === 0;

              return (
                <TableRow key={cid}>
                  <TableRowColumn className={classNames(styles.itemImage)}>
                    {image && image.url && <Image source={image?.url} accessibilityLabel={name} />}
                  </TableRowColumn>
                  <TableRowColumn className={classNames(styles.itemNumber)}>
                    {isCustomerServiceUser
                      ? number
                      : (
                        <Link to={`/${organization.id}/catalog/items/${number}`}>
                          {number}
                        </Link>
                      )}
                  </TableRowColumn>
                  <TableRowColumn>
                    {name}
                  </TableRowColumn>
                  <TableRowColumn>
                    {quantity}
                  </TableRowColumn>
                  <TableRowColumn>
                    {taxAndDutyNotAvailable ? '-' : `${numeral(duty).format('0.00[000]%')}`}
                  </TableRowColumn>
                  <TableRowColumn>
                    {taxAndDutyNotAvailable ? '-' : `${numeral(tax).format('0.00[000]%')}`}
                  </TableRowColumn>
                  <TableRowColumn className={classNames(styles.currency, styles.basePrice)}>
                    {get(price, 'base.label', '')}
                  </TableRowColumn>
                  <TableRowColumn className={classNames(styles.currency)}>
                    {get(price, 'label', '')}
                  </TableRowColumn>
                </TableRow>
              );
            })}
          </TableBody>
        ))}
        <TableBody style={{ borderTop: '1px solid rgb(230, 230, 230)' }}>
          {map(prices, (price) => (
            <TableRow key={price.key} className={styles.subtotal}>
              <TableRowColumn colSpan={4}>&nbsp;</TableRowColumn>
              <TableRowColumn>
                {getPriceNameWithFallback(price)}
                { price.component && (
                  <div className={styles.discountNameTag}>{price.component.name}</div>
                ) }
              </TableRowColumn>
              <TableRowColumn className={styles.currency}>
                {price.components.length > 1 && price.key !== 'discount' ? (
                  <Popover
                    openOnHover
                    css={tooltipStyles}
                    position="bottom left"
                    trigger={<span className={styles.subtotalLabel}>{get(price.base, 'label', '')}</span>}
                  >
                    <div className={styles.subtotalDetailContainer}>
                      {map(price.components, (component) => (
                        <div key={price.key}>
                          <span className={styles.subtotalDetailLabel}>{component.name}</span>
                          <span className={styles.subtotalDetailValue}>{component.base.label}</span>
                        </div>
                      ))}
                    </div>
                  </Popover>
                ) : getPriceBaseLabel(price)}
              </TableRowColumn>
              <TableRowColumn className={styles.currency}>
                {price.components.length > 1 && price.key !== 'discount' ? (
                  <Popover
                    openOnHover
                    css={tooltipStyles}
                    position="bottom left"
                    trigger={<span className={styles.subtotalLabel}>{get(price, 'label', '')}</span>}
                  >
                    <div className={styles.subtotalDetailContainer}>
                      {map(price.components, (component) => (
                        <div key={price.key}>
                          <span className={styles.subtotalDetailLabel}>{component.name}</span>
                          <span className={styles.subtotalDetailValue}>{component.label}</span>
                        </div>
                      ))}
                    </div>
                  </Popover>
                ) : getPriceLabel(price)}
              </TableRowColumn>
            </TableRow>
          ))}
          <TableRow className={styles.total}>
            <TableRowColumn colSpan={4}>&nbsp;</TableRowColumn>
            <TableRowColumn>Total</TableRowColumn>
            <TableRowColumn className={classNames(styles.currency, styles.basePrice)}>
              {order.total.base.label}
            </TableRowColumn>
            <TableRowColumn className={classNames(styles.currency)}>
              {order.total.label}
            </TableRowColumn>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

OrderItems.displayName = displayName;

export default OrderItems;
