import { Box } from '@flowio/react-box';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useState } from 'react';
import Button from '../../MarketingButton';
import EmptyStates from '../../../constants/EmptyStates';
import MarketingCard, { MarketingCardHeading } from '../../MarketingCard';
import MarketingEmptyState from '../../MarketingEmptyState/MarketingEmptyState';
import SelectableTable from '../../SelectableTable';
import ExternalLink from '../../ExternalLink';
import STEPS from '../constants';
import { Props } from '../types';
import * as styles from './SelectFacebookCatalog.styles';
import MarketingPage from '../../MarketingPage';

const isSelectableCatalog = (
  catalog: io.flow.internal.v0.models.MarketingGatewayFacebookCatalogSummary,
): boolean => !!catalog.item_count;

const SelectFacebookCatalog: React.FC<Props> = ({
  catalogs: {
    catalogList,
    isLoading: catalogsLoading,
  },
  businesses: {
    businessList = [],
    isLoading: businessesLoading,
  },
  params: { organization },
  confirmBusiness,
  onCancelAuth,
  confirmCatalog,
}) => {
  const [selectedCatalog, setSelectedCatalog] = useState<number>();
  const [selectedBusiness, setSelectedBusiness] = useState<number>();
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    setSelectedCatalog(
      catalogList && catalogList.length && catalogList.find((catalog) => catalog.item_count > 0)
        ? catalogList[0].catalog_id
        : undefined,
    );
  }, [catalogList, catalogsLoading]);

  useEffect(() => {
    setSelectedBusiness(
      businessList.length && businessList.length === 1 ? businessList[0].business_id : undefined,
    );
  }, [businessList]);

  const noCatalogsBanner = {
    heading: 'You don\'t have a Facebook Catalog containing product information',
    text: {
      content: (
        <span>
          At least one item is required in a catalog for Flow to create localized feeds. Please
          {' '}
          <b><ExternalLink href="https://business.facebook.com">finish setting up a catalog</ExternalLink></b>
          {' '}
          on Facebook with your product information first.
        </span>
      ),
    },
  };

  const handleBusinessConfirm = useCallback(() => {
    if (selectedBusiness) {
      confirmBusiness(organization, selectedBusiness);
      setCurrentStep(1);
    }
  }, [organization, confirmBusiness, selectedBusiness]);

  const handelCatalogConfirm = useCallback(() => {
    if (selectedCatalog) {
      confirmCatalog(organization, selectedCatalog);
    }
  }, [confirmCatalog, organization, selectedCatalog]);

  const noCatalogText = (
  ): JSX.Element => {
    const link = 'https://business.facebook.com/products/catalogs/new';
    return (
      <>
        Please
        {' '}
        <ExternalLink className={styles.helpLink} href={link}>set up a catalog</ExternalLink>
        &nbsp;in Facebook with your product information first.
      </>
    );
  };

  const onChangeCatalog = (
    {
      catalog_id: catalogId,
    }: io.flow.internal.v0.models.MarketingGatewayFacebookCatalogSummary,
  ): void => setSelectedCatalog(catalogId);

  const onChangeBusiness = (
    {
      business_id: id,
    }: io.flow.internal.v0.models.MarketingGatewayFacebookBusinessSummary,
  ): void => setSelectedBusiness(id);

  return (
    <MarketingPage heading="Connecting to Facebook Business Manager">
      <MarketingCard extraClass={styles.selectionContainer}>
        <MarketingCardHeading
          showDivider={false}
          title={STEPS[currentStep].title}
          subtitle={STEPS[currentStep].subtitle}
        />
        {currentStep === 0
          && (isEmpty(businessList) && !businessesLoading
            ? (
              <MarketingEmptyState
                title={EmptyStates.NO_FACEBOOK_BUSINESSES_TITLE}
                subtitle={EmptyStates.NO_FACEBOOK_BUSINESSES_SUBTITLE}
              />
            )
            : (
              <SelectableTable
                isLoading={businessesLoading}
                items={businessList}
                id={(
                  business: io.flow.internal.v0.models.MarketingGatewayFacebookBusinessSummary,
                ): number => business.business_id}
                selected={selectedBusiness}
                onChange={onChangeBusiness}
                columns={{
                  name: 'Account Name',
                  business_id: 'Account ID',
                }}
              />
            ))}
        {(currentStep === 1)
        && (isEmpty(catalogList) && !catalogsLoading
          ? (
            <MarketingEmptyState
              title={EmptyStates.NO_FACEBOOK_CATALOG_TITLE}
              subtitle={noCatalogText()}
            />
          )
          : (
            <SelectableTable
              isLoading={catalogsLoading}
              items={catalogList}
              id={(
                catalog: io.flow.internal.v0.models.MarketingGatewayFacebookCatalogSummary,
              ): number => catalog.catalog_id}
              selected={selectedCatalog}
              onChange={onChangeCatalog}
              isSelectable={isSelectableCatalog}
              errorBanner={noCatalogsBanner}
              columns={{
                name: 'Catalog Name',
                catalog_id: 'Catalog ID',
                item_count: '# of Items',
              }}
            />
          ))}
        <Box justifyContent="between">
          <Box>
            {currentStep > 0 && <Button intent="primary" onClick={(): void => setCurrentStep(0)}>Back</Button>}
          </Box>
          <Box spacing="loose">
            <Button onClick={onCancelAuth}>Cancel</Button>
            {currentStep === 0 && <Button intent="primary" disabled={!selectedBusiness} onClick={handleBusinessConfirm}>Next</Button>}
            {currentStep === 1 && <Button intent="positive" disabled={!selectedCatalog} onClick={handelCatalogConfirm}>Complete Connection to Facebook</Button>}
          </Box>
        </Box>
      </MarketingCard>
    </MarketingPage>
  );
};

SelectFacebookCatalog.displayName = 'SelectFacebookCatalog';

export default SelectFacebookCatalog;
