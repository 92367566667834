import ActionTypes from '../constants/ActionTypes';
import { RootActionTypes } from '../../../stores/types';
import { MarketingFacebookConnectionState } from '../types';

const initialState: MarketingFacebookConnectionState = {
  catalogs: {
    isLoading: false,
    isError: false,
    catalogList: [],
  },
  businesses: {
    isLoading: false,
    isError: false,
    businessList: [],
  },
};

export default (
  state = initialState,
  action: RootActionTypes,
): MarketingFacebookConnectionState => {
  switch (action.type) {
    case ActionTypes.FETCH_FACEBOOK_BUSINESSES_REQUEST:
      return {
        ...state,
        businesses: {
          isLoading: true,
          isError: false,
        },
      };
    case ActionTypes.FETCH_FACEBOOK_BUSINESSES_SUCCESS:
      return {
        ...state,
        businesses: {
          isLoading: false,
          isError: false,
          businessList: action.payload,
        },
      };
    case ActionTypes.FETCH_FACEBOOK_BUSINESSES_FAILURE:
      return {
        ...state,
        businesses: {
          isLoading: false,
          isError: true,
        },
      };
    case ActionTypes.FETCH_FACEBOOK_CATALOGS_REQUEST:
      return {
        ...state,
        catalogs: {
          isLoading: true,
          isError: false,
        },
      };
    case ActionTypes.FETCH_FACEBOOK_CATALOGS_SUCCESS:
      return {
        ...state,
        catalogs: {
          isLoading: false,
          isError: false,
          catalogList: action.payload,
        },
      };
    case ActionTypes.FETCH_FACEBOOK_CATALOGS_FAILURE:
      return {
        ...state,
        catalogs: {
          isLoading: true,
          isError: true,
        },
      };
    default:
      return state;
  }
};
