import assign from 'lodash/assign';
import update from 'immutability-helper';

import * as ActionTypes from './constants';
import { ActionTypes as AccountActionTypes } from '../account/constants/action-types';
import AuthActionTypes from '../auth/constants/action-types';
import { UserState } from './types';
import { RootActionTypes } from '../../stores/types';

const initialState: UserState = {
  memberships: [],
};

export default function userReducer(
  state: UserState = initialState,
  action: RootActionTypes,
): UserState {
  switch (action.type) {
    case ActionTypes.FETCH_DETAILED_USER_SUCCESS:
      return assign({}, state, action.payload);
    case ActionTypes.FETCH_USER_ORG_MEMBERSHIPS_SUCCESS: {
      if (action.payload.length === 0) {
        return state;
      }
      const newState: UserState = {
        ...state,
        memberships: [
          ...state.memberships,
        ],
      };

      action.payload.forEach((val) => {
        const index = state.memberships.findIndex((membership) => (
          membership.organization.id === val.organization.id
          && membership.user.id === val.user.id
        ));
        if (index > -1) {
          newState.memberships[index] = val;
        } else {
          newState.memberships.push(val);
        }
      });

      return newState;
    }
    case AccountActionTypes.ACCT_ORG_ACCEPT_INVITATION_COMPLETED:
      return update(state, {
        memberships: {
          $push: [action.payload],
        },
      });
    case AuthActionTypes.AUTH_LOGOUT_USER_SUCCESS:
      return assign({}, state, initialState);
    default:
      return state;
  }
}
