import React from 'react';
import { Box, BoxItem } from '@flowio/react-box';
import { Label } from '@flowio/react-label';

import * as styles from './TierList.styles';
import { TierListAvailabilityProps } from '../../types/components';

const TierListAvailability: React.FC<TierListAvailabilityProps> = ({
  availabilityString,
}) => (
  <Box spacing="loose">
    <BoxItem className={styles.tierLabel}>
      <Label content="Availability" />
    </BoxItem>
    <BoxItem>
      <span className={styles.tierName}>
        {availabilityString}
      </span>
    </BoxItem>
  </Box>
);

TierListAvailability.displayName = 'TierListAvailability';

export default TierListAvailability;
