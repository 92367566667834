import React from 'react';
import kebabCase from 'lodash/kebabCase';
import * as styles from './price-check-form.styles';

interface Props {
  components: io.flow.v0.models.PriceDetailComponent[];
}

const Components: React.FC<Props> = ({ components }) => (
  <section className={styles.components}>
    {components.map((component) => (
      <div className={styles.componentsRow} key={kebabCase([component.name || '', component.label].join('-'))}>
        <div className={styles.componentsKey}>
          {component.name}
        </div>
        <div>
          {component.label}
        </div>
      </div>
    ))}
  </section>
);

export default Components;
