import { css } from '@emotion/css';

export const imports = css`
  margin-top: 1rem;
`;

export const uploadImportForm = css`
  padding: 20px;
`;

export const label = css`
  display: block;
  margin-bottom: 1rem;
`;

export const labelText = css`
  display: block;
  font-weight: 500;
`;

export const uploadButton = css`
  margin-top: 1rem;
`;

export const importBtn = css`
  margin-left: auto;
`;

export const failImport = css`
  color: #d9021b;
  font-weight: 400;
`;

export const successfulImport = css`
  color: #6cab35;
  font-weight: 400;
`;

export const intermediateImport = css`
  color: #e6b137;
  font-weight: 400;
`;

export const downloadIcon = css`
  height: 24px !important;
  width: 24px !important;
`;

export const resultFileColumn = css`
  text-align: center;
`;
