import { Checkbox } from '@flowio/react-checkbox';
import { Grid, Column, Row } from '@flowio/react-grid';
import { FormGroup, FormGroupIntent } from '@flowio/react-form-group';
import React, { ChangeEvent } from 'react';
import chunk from 'lodash/chunk';
import map from 'lodash/map';

import { css } from '@emotion/css';
import mapRatecardsToServices from '../../utilities/mapRatecardsToServices';
import { TierCarrierServiceLevelGroupProps } from '../../types/components';

const checkboxStyles = css`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

class TierCarrierServiceLevelGroup extends React.PureComponent<TierCarrierServiceLevelGroupProps> {
  static displayName = 'TierCarrierServiceLevelGroup';

  constructor(props: TierCarrierServiceLevelGroupProps, context: any) {
    super(props, context);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { input } = this.props;
    const selection: string = event.target.value;
    const prevValue: string[] = input.value;
    const nextValue = (prevValue.indexOf(selection) >= 0)
      ? prevValue.filter((value) => value !== selection)
      : prevValue.concat(selection);
    input.onChange(nextValue);
  }

  render() {
    const { input, meta, ratecards } = this.props;
    const { error, touched, warning } = meta;
    const services: io.flow.v0.models.RatecardServiceSummary[] = mapRatecardsToServices(ratecards);

    let feedbackText;
    let intent: FormGroupIntent = 'neutral';

    if (error && touched) {
      feedbackText = error;
      intent = 'negative';
    } else if (warning && touched) {
      feedbackText = warning;
      intent = 'warning';
    }

    return (
      <Grid gutter={false}>
        <FormGroup intent={intent} feedback={feedbackText}>
          {map(chunk(services, 4), (chunks, index) => (
            <Row key={index}>
              {map(chunks, (service) => (
                <Column key={service.id} span={3}>
                  <Checkbox
                    checked={input.value.indexOf(service.id) >= 0}
                    className={checkboxStyles}
                    labelText={`${service.carrier.name} - ${service.name}`}
                    name={input.name}
                    onChange={this.handleChange}
                    value={service.id}
                  />
                </Column>
              ))}
            </Row>
          ))}
        </FormGroup>
      </Grid>
    );
  }
}

export default TierCarrierServiceLevelGroup;
