import React, { useCallback } from 'react';
import { Box } from '@flowio/react-box';
import Button from '../../MarketingButton';
import MarketingDialog from '../../MarketingDialog';
import { Props } from '../types';
import Platforms from '../../../constants/Platforms';

const DisconnectConfirmDialog: React.FC<Props> = ({
  isOpen = false,
  onCancel,
  onConfirm,
  organizationId,
  dialogData = {},
}) => {
  const handleConfirmClick = useCallback((): void => {
    if (dialogData.platformId) {
      onConfirm({ organizationId, platformId: dialogData.platformId });
    }
  }, [dialogData, organizationId, onConfirm]);

  return dialogData.platformId ? (
    <MarketingDialog
      isOpen={isOpen}
      title={`Cancel connecting to ${Platforms(dialogData.platformId).platformName}?`}
      subtitle={`Flow will not have access to your ${Platforms(dialogData.platformId).platformName} account information.`}
    >
      <Box spacing="loose" justifyContent="center">
        <Button intent="neutral" onClick={onCancel}>Cancel</Button>
        <Button intent="negative" onClick={handleConfirmClick}>Disconnect</Button>
      </Box>
    </MarketingDialog>
  ) : null;
};

export default DisconnectConfirmDialog;
