/* eslint-disable max-len */

import { Form, Field } from 'redux-form';
import React from 'react';
import find from 'lodash/find';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import property from 'lodash/property';
import size from 'lodash/size';
import {
  RadioButtonGroup, TextField, Switch, SelectField,
} from '../../../../../components/ReduxFormFieldAdapters';
import AnnotatedSection from '../../../../../components/AnnotatedSection';
import ConsentType from '../../../constants/ConsentType';
import GenericError from '../../../../../components/GenericError';
import OptinConsentTypeOptions from '../../OptinConsentTypeOptions';
import PromptTarget from '../../../constants/PromptTarget';
import presence from '../../../../../utilities/validators/presence';
import DisplayPosition from '../../../constants/DisplayPosition';
import * as styles from './OptinContentForm.styles';
import { Props } from '../types';

const parseLanguage = property('iso_639_2');
const parseRegion = property('id');

const validateButtonText = [
  presence({ message: 'Enter button text', allowEmpty: false }),
];

const validateConsentType = [
  presence({ message: 'Choose consent type', allowEmpty: false }),
];

const validateLanguage = [
  presence({ message: 'Choose language', allowEmpty: false }),
];

const validateMessage = [
  presence({ message: 'Enter message', allowEmpty: false }),
];

const validateRegion = [
  presence({ message: 'Choose region', allowEmpty: false }),
];

const OptinContentForm: React.FC<Props & { clearFields: any }> = ({
  languages,
  regions,
  change,
  autofill,
  clearFields,
  selectedLanguageCode,
  error,
  handleSubmit,
  isCheckbox,
  optinAttribute,
  selectedConsentType,
  selectedRegionId,
}) => {
  /**
   * Returns the language matching the provided ISO code, otherwise undefined.
   * @param {String} value
   * @returns {Language}
   */
  const findLanguageByCode = (value: string): io.flow.v0.models.Language | undefined => find(languages, { iso_639_2: value });

  /**
   * Returns the region matching the provided identifier, otherwise undefined.
   * @param {String} value
   * @returns {Region}
   */
  const findRegionById = (value: string): io.flow.v0.models.Region | undefined => find(regions, { id: value });

  const handleCheckboxChange = (value: React.ChangeEvent<HTMLInputElement>) => {
    // Turn off other checkbox options.
    if (!value) {
      change('isCheckboxRequired', false);
      change('isCheckboxDefaultChecked', false);
      change('consentType', ConsentType.IMPLICIT);
    } else {
      change('consentType', ConsentType.EXPLICIT);
    }
  };

  const handleLanguageFormat = (value: string): io.flow.v0.models.Language | undefined => findLanguageByCode(value);

  const handleRegionChange = (value: string): void => {
    const region = findRegionById(value);

    if (region) {
      // Autofill language for regions with a single language
      if (size(region.languages) === 1) {
        autofill('language', region.languages[0]);
      // Keep selected language if selected region supports it
      // Otherwise, clear selected language to force user to select another.
      } else if (!includes(region.languages, selectedLanguageCode)) {
        clearFields(true, false, 'language');
      }
    }
  };

  const handleRegionFormat = (value: string): io.flow.v0.models.Region | undefined => findRegionById(value);

  return (
    <Form onSubmit={handleSubmit}>
      <Field component="input" type="hidden" name="organizationId" />
      <Field component="input" type="hidden" name="optinAttributeKey" />
      <Field component="input" type="hidden" name="optinAttributeTarget" />
      <Field component="input" type="hidden" name="contentStatus" />
      <Field component="input" type="hidden" name="optinPromptId" />
      {error && (<GenericError error={error} />)}
      <AnnotatedSection.Group>
        <AnnotatedSection title="Region" description="Message content is region specific">
          <Field
            component={SelectField}
            format={handleRegionFormat}
            name="region"
            onChange={(v: any) => handleRegionChange(v.id)}
            parse={parseRegion}
            validate={validateRegion}
            fluid
            hintText="Select Region"
            options={regions}
            labelKey="name"
            valueKey="id"
          />
        </AnnotatedSection>
        <AnnotatedSection title="Language" description="Choose content language">
          <Field
            component={SelectField}
            name="language"
            format={handleLanguageFormat}
            parse={parseLanguage}
            validate={validateLanguage}
            disabled={isEmpty(selectedRegionId)}
            fluid
            hintText="Select Language"
            options={languages}
            scrolling
            searchable
            labelKey="name"
            valueKey="iso_639_2"
            scrollableMenu
          />
        </AnnotatedSection>
        {(optinAttribute.target === PromptTarget.CHECKOUT) && (
          <AnnotatedSection title="Display a Checkbox?">
            <Field
              component={Switch}
              name="isCheckbox"
              onChange={handleCheckboxChange}
              type="checkbox"
              offText="No"
              onText="Yes"
            />
          </AnnotatedSection>
        )}
        {(optinAttribute.target === PromptTarget.CHECKOUT) && (
          <AnnotatedSection title="Checkbox must be selected to continue?">
            <Field
              component={Switch}
              name="isCheckboxRequired"
              disabled={!isCheckbox}
              type="checkbox"
              offText="No"
              onText="Yes"
            />
          </AnnotatedSection>
        )}
        {(optinAttribute.target === PromptTarget.CHECKOUT) && (
          <AnnotatedSection title="Checkbox is selected by default?">
            <Field
              component={Switch}
              name="isCheckboxDefaultChecked"
              disabled={!isCheckbox}
              type="checkbox"
              offText="No"
              onText="Yes"
            />
          </AnnotatedSection>
        )}
        {(optinAttribute.target === PromptTarget.CHECKOUT) && (
          <AnnotatedSection title="Message Position">
            <Field
              component={RadioButtonGroup}
              name="displayPosition"
              className={styles.positionRadioGroup}
              options={[
                {
                  labelText: (
                    <div className={styles.positionText}>
                      <div className={styles.text}>First page of checkout steps</div>
                      <div className={styles.subtext}>After Email text field</div>
                    </div>
                  ),
                  className: styles.firstLabel,
                  value: DisplayPosition.EMAIL,
                },
                {
                  labelText: (
                    <div className={styles.positionText}>
                      <div className={styles.text}>Last page of checkout steps</div>
                      <div className={styles.subtext}>Before Submit Order button</div>
                    </div>
                  ),
                  className: styles.lastLabel,
                  value: DisplayPosition.SUBMISSION,
                },
              ]}
            />
          </AnnotatedSection>
        )}
        {(optinAttribute.target === PromptTarget.BROWSE) && (
          <AnnotatedSection title="Consent Type">
            <Field
              component={OptinConsentTypeOptions}
              name="consentType"
              validate={validateConsentType}
            />
          </AnnotatedSection>
        )}
        {(optinAttribute.target === PromptTarget.BROWSE && selectedConsentType === ConsentType.EXPLICIT) && (
          <AnnotatedSection title="Accept Button Text">
            <Field
              component={TextField}
              name="acceptButtonText"
              validate={validateButtonText}
              fluid
              hint="Plain text only."
              hintText="Enter Button Text"
            />
          </AnnotatedSection>
        )}
        {(optinAttribute.target === PromptTarget.BROWSE && selectedConsentType === ConsentType.EXPLICIT) && (
          <AnnotatedSection title="Reject Button Text">
            <Field
              component={TextField}
              name="rejectButtonText"
              validate={validateButtonText}
              fluid
              hint="Plain text only."
              hintText="Enter Button Text"
            />
          </AnnotatedSection>
        )}
        {(optinAttribute.target === PromptTarget.BROWSE && selectedConsentType === ConsentType.IMPLICIT) && (
          <AnnotatedSection title="Dismiss Button Text">
            <Field
              component={TextField}
              name="dismissButtonText"
              validate={validateButtonText}
              fluid
              hint="Plain text only."
              hintText="Enter Button Text"
            />
          </AnnotatedSection>
        )}
        <AnnotatedSection title="Message">
          <Field
            component={TextField}
            name="message"
            validate={validateMessage}
            fluid
            hint="Use plain text or Markdown"
            hintText="Enter Message"
            multiLine
          />
        </AnnotatedSection>
        {(optinAttribute.target === PromptTarget.CHECKOUT) && (
          <AnnotatedSection title="Error message if Checkbox is not selected">
            <Field
              component={TextField}
              name="errorText"
              fluid
              hint="Plain text only."
              hintText="Enter Error Message"
              multiLine
            />
          </AnnotatedSection>
        )}
      </AnnotatedSection.Group>
    </Form>
  );
};

OptinContentForm.displayName = 'OptinContentForm';

export default OptinContentForm;
