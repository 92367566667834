import { ActionTypes } from '../constants';
import { ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';
import toLegacyResponse from '../../../utilities/clients/to-legacy-response';
import { LegacyResponse } from '../../../utilities/clients/types/server';

export default function deleteExport(id: string):
ThunkResult<Promise<LegacyResponse<undefined | FormattedErrorMessages>>> {
  return (
    dispatch,
    getState,
    extra,
  ): Promise<LegacyResponse<undefined | FormattedErrorMessages>> => {
    dispatch({
      id,
      type: ActionTypes.DELETE_SCHEDULED_EXPORT_REQUEST,
    });

    return extra.api(getState()).scheduledExports.deleteById({ id })
      .then((response) => {
        if (response.ok) {
          dispatch({
            type: ActionTypes.DELETE_SCHEDULED_EXPORT_SUCCESS,
            id,
          });
          return toLegacyResponse(response);
        }

        const formattedErrors = formatErrorResponseV2(response);

        dispatch({
          id,
          type: ActionTypes.DELETE_SCHEDULED_EXPORT_FAILURE,
          payload: formattedErrors,
        });

        return toLegacyResponse({
          ...response,
          body: formattedErrors,
        });
      });
  };
}
