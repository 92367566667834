import { createSelector } from 'reselect';
import assign from 'lodash/assign';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import map from 'lodash/map';

import { calculatePercentage, toPercentageBy } from '../../../utilities/numbers';
import { RootState } from '../../../stores/types';
import { AnalyticsState, OrderRevenueRegionChartWithPercent } from '../types';

const toPercentage = toPercentageBy(0);

// Returns GMV by country with data list sorted by revenue amount.
export default createSelector<RootState, AnalyticsState['gmvByCountry']['data'], OrderRevenueRegionChartWithPercent>(
  (state) => get(state, 'analytics.gmvByCountry.data'),
  (state) => {
    // Compute percentage for each revenue amount relative to revenue total.
    const prevData = get(state, 'data');
    const total = get(state, 'total.amount');
    const nextData = map(prevData, (data) => assign({}, data, {
      revenue: assign({}, data.revenue, {
        percentage: toPercentage(calculatePercentage(data.revenue.amount, total)),
      }),
    }));

    // Return data sorted by highest amount
    return assign({}, state, {
      data: orderBy(nextData, (data) => get(data, 'revenue.amount'), 'desc'),
    });
  },
);
