import { css } from '@emotion/css';
import { colors } from '../../../../theme/tokens';

export const salePrice = css`
  margin: 0;
  color: ${colors['flow-blue'][400]};
  font-size: 2rem;
  font-weight: 300;
  line-height: 2.375rem;
`;

export const salePriceSettings = css`
  margin: 0;
  color: ${colors.steel};
  font-size: 0.875rem;
`;

export const levy = css`
  display: flex;
  flex-flow: row nowrap;
  margin: 0.5rem 0 0;
`;

export const levyLabel = css`
  color: ${colors['flow-blue'][400]};
  font-size: 0.875rem;
  line-height: 1rem;
`;

export const levyAmount = css`
  margin-left: 0.25rem;
  color: ${colors['flow-blue'][400]};
  font-size: 0.875rem;
  line-height: 1rem;
`;
