import update from 'immutability-helper';
import { RootActionTypes } from '../../../stores/types';
import ActionTypes from '../constants/action-types';
import { CountriesState } from '../types';

const defaultState: CountriesState = [];

export default function countriesReducer(
  state = defaultState,
  action: RootActionTypes,
): CountriesState {
  switch (action.type) {
    case ActionTypes.FETCH_ORGANIZATION_COUNTRIES_SUCCESS:
      return update(state, { $set: action.payload });
    default:
      return state;
  }
}
