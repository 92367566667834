import update from 'immutability-helper';
import { RootActionTypes } from '../../../stores/types';
import ActionType from '../constants/ActionType';
import { ReturnPolicyDeleteConfirmationState } from '../types';

const initialState = {
  deleting: false,
  deleteFailed: false,
  deleteSucceeded: false,
  returnPolicyToBeDeleted: undefined,
  organizationId: undefined,
  visibility: 'hidden',
};

export default function returnPolicyDeleteConfirmationReducer(
  state: ReturnPolicyDeleteConfirmationState = initialState,
  action: RootActionTypes,
) {
  switch (action.type) {
    case ActionType.ShowReturnPolicyDeleteConfirmation:
      return update(state, {
        deleting: { $set: false },
        deleteFailed: { $set: false },
        deleteSucceeded: { $set: false },
        returnPolicyToBeDeleted: { $set: action.payload.returnPolicy },
        organizationId: { $set: action.payload.organizationId },
        visibility: { $set: 'visible' },
      });
    case ActionType.HideReturnPolicyDeleteConfirmation:
      return update(state, {
        returnPolicyToBeDeleted: { $set: undefined },
        organizationId: { $set: undefined },
        visibility: { $set: 'hidden' },
      });
    case ActionType.DeleteReturnPolicyRequest:
      return update(state, {
        deleting: { $set: true },
        // Reset in case user tries to delete once again after the an error has occured
        deleteFailed: { $set: false },
        deleteSucceeded: { $set: false },
      });
    case ActionType.DeleteReturnPolicySuccess:
      return update(state, {
        deleting: { $set: false },
        deleteSucceeded: { $set: true },
        visibility: { $set: 'hidden' },
      });
    case ActionType.DeleteReturnPolicyFailure:
      return update(state, {
        deleting: { $set: false },
        deleteFailed: { $set: true },
      });
    default:
      return state;
  }
}
