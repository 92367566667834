import React from 'react';
import noop from 'lodash/noop';
import {
  Worksheet, WorksheetHeader, WorksheetTitle, WorksheetBody,
} from '@flowio/react-worksheet';
import { Button } from '@flowio/react-button';
import { Box } from '@flowio/react-box';

import AddFeatureWorksheetForm from '../../containers/AddFeatureWorksheetForm';
import * as styles from '../features.styles';
import { AddFeatureWorksheetProps } from '../../types/components';

class AddFeatureWorksheet extends React.PureComponent<AddFeatureWorksheetProps> {
  static displayName = 'AddFeatureWorksheet';

  static defaultProps = {
    initialValues: undefined,
    open: false,
    onCancel: noop,
    onSave: noop,
    isControlOrganization: false,
  };

  constructor(props: AddFeatureWorksheetProps, context: any) {
    super(props, context);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
  }

  handleCancelClick() {
    const {
      onCancel = noop,
    } = this.props;
    onCancel();
  }

  handleSaveClick() {
    const {
      onSave = noop,
    } = this.props;
    onSave();
  }

  render() {
    const {
      open,
      initialValues,
      isControlOrganization,
      isSaveDisabled,
    } = this.props;

    return (
      <Worksheet open={open}>
        <WorksheetHeader>
          <Box alignItems="center" justifyContent="between" className={styles.dialogHeader}>
            <Button
              type="button"
              onClick={this.handleCancelClick}
              content="Cancel"
            />
            <WorksheetTitle content="Add Feature" />
            <Button
              type="button"
              intent="primary"
              disabled={isSaveDisabled}
              onClick={this.handleSaveClick}
              content="Save"
            />
          </Box>
        </WorksheetHeader>
        <WorksheetBody>
          <AddFeatureWorksheetForm
            isControlOrganization={isControlOrganization}
            initialValues={initialValues}
          />
        </WorksheetBody>
      </Worksheet>
    );
  }
}

export default AddFeatureWorksheet;
