import { compose } from 'redux';
import {
  submit,
  isSubmitting,
  getFormValues,
  FormAction,
} from 'redux-form';
import { withFetch } from '@flowio/redux-fetch';
import { connect } from 'react-redux';
import get from 'lodash/get';
import FormName from '../../../constants/FormName';
import { DispatchProps, StateProps, OwnProps } from '../types';
import AddFeeds from '../components/AddFeeds';

import {
  fetchLanguages,
  fetchCountries,
} from '../../../../reference/actions';

import {
  goToLandingPage,
  goToFeedsPage,
  fetchFeedSources,
  changeSelectedFeedSource,
} from '../../../actions';
import { ThunkDispatcher, RootState } from '../../../../../stores/types';

const FEED_SOURCE_FORM = FormName.SELECT_FEED_SOURCE_FORM;
const FEED_FORMS_FORM = FormName.SELECT_FEED_FORMS_FORM;

const getAsyncState = (
  dispatch: ThunkDispatcher,
  _getState: () => RootState,
  { params }: OwnProps,
): Promise<void> => {
  const {
    organization: organizationId,
    platformId: destinationPlatform,
  } = params;

  return Promise.all([
    // countries and languages are selected by child components
    // @ts-ignore
    dispatch(fetchCountries()),
    // @ts-ignore
    dispatch(fetchLanguages()),
    // @ts-ignore
    dispatch(changeSelectedFeedSource()),
  ]).then(() => dispatch(fetchFeedSources(
    { organizationId, destinationPlatform },
  )));
};

const submitFeedForms = () => (
  dispatch: ThunkDispatcher,
): FormAction => dispatch(submit(FEED_FORMS_FORM));

const submitFeedSource = () => (
  dispatch: ThunkDispatcher,
): FormAction => dispatch(submit(FEED_SOURCE_FORM));

const mapDispatchToProps = (
  dispatch: ThunkDispatcher,
): DispatchProps => ({
  onCancel: (
    values,
    isOnboarding = false,
  ): void => dispatch(
    isOnboarding ? goToLandingPage(values.organizationId) : goToFeedsPage(values),
  ),
  onFeedSourceConfirm: (): FormAction => dispatch(submitFeedSource()),
  onFeedsFormsConfirm: (): FormAction => dispatch(submitFeedForms()),
});

const mapStateToProps = (
  state: RootState,
  props: OwnProps,
): StateProps => ({
  organizationId: get(props, ['params', 'organization']),
  platformId: get(props, ['params', 'platformId']),
  accountId: get(props, ['params', 'accountId']),
  isSubmittingSource: isSubmitting(FEED_SOURCE_FORM)(state),
  isSubmittingFeeds: isSubmitting(FEED_FORMS_FORM)(state),
  canSubmitFeeds: !!get(getFormValues(FEED_FORMS_FORM)(state), ['forms', 'length']),
  canSubmitSource: !!get(getFormValues(FEED_SOURCE_FORM)(state), ['feed_source']),
  isOnboarding: get(props, 'location.query.isOnboarding') === 'true',
});

export default compose(
  withFetch(getAsyncState),
  connect(mapStateToProps, mapDispatchToProps),
)(AddFeeds);
