const toQueryBuilderQueryForm = (query?: string): io.flow.v0.models.QueryBuilderQueryForm => {
  const q = query || '';

  return {
    discriminator: 'query',
    q,
  };
};

export default toQueryBuilderQueryForm;
