import { createSelector } from 'reselect';
import property from 'lodash/property';
import getMarketingState from './getMarketingState';
import { RootState } from '../../../stores/types';
import { MarketingState, MarketingFacebookConnectionState } from '../types';

export default createSelector<RootState, MarketingState, MarketingFacebookConnectionState['businesses']>(
  getMarketingState,
  property(['facebookConnection', 'businesses']),
);
