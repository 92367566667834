import { connect } from 'react-redux';
import DisconnectModal from '../components/DisconnectModal';
import { RootState, ThunkDispatcher } from '../../../../../stores/types';
import { StateProps, DispatchProps } from '../types';
import { getShowLogoutModal } from '../../../selectors';
import toggleLogoutModal from '../../../actions/toggleLogoutModal';
import { logout } from '../../../actions';
import { createToast } from '../../../../console/actions';
import { getOrganization } from '../../../../organization';

const mapStateToProps = (state: RootState): StateProps => ({
  isOpen: getShowLogoutModal(state),
  organizationId: getOrganization(state)?.id,
});

const handleConfirm = (dispatch: ThunkDispatcher) => () => {
  dispatch(logout()).then(() => {
    dispatch(createToast({
      intent: 'positive',
      icon: 'info-circle',
      content: 'Your Facebook account has successfully been disconnected.',
    }));
    dispatch(toggleLogoutModal(false));
  })
    .catch(() => {
      dispatch(createToast({
        intent: 'negative',
        icon: 'info-circle',
        content: 'Something went wrong disconnecting your account, please try again.',
      }));
    });
};

const handleClose = (dispatch: ThunkDispatcher) => () => {
  dispatch(toggleLogoutModal(false));
};

const mapDispatchToProps = (
  dispatch: ThunkDispatcher,
): DispatchProps => ({
  onClose: handleClose(dispatch),
  onConfirm: handleConfirm(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DisconnectModal);
