export enum ActionTypes {
  DELETE_PLATFORM_REQUEST = 'MARKETING.DELETE_PLATFORM_REQUEST',
  DELETE_PLATFORM_SUCCESS = 'MARKETING.DELETE_PLATFORM_SUCCESS',
  DELETE_PLATFORM_FAILURE = 'MARKETING.DELETE_PLATFORM_FAILURE',

  COMPLETE_CONNECTION_REQUEST = 'MARKETING.COMPLETE_CONNECTION_REQUEST',
  COMPLETE_CONNECTION_SUCCESS = 'MARKETING.COMPLETE_CONNECTION_SUCCESS',
  COMPLETE_CONNECTION_FAILURE = 'MARKETING.COMPLETE_CONNECTION_FAILURE',

  CREATE_CHANNEL_REQUEST = 'MARKETING.CREATE_CHANNEL_ACCOUNT_REQUEST',
  CREATE_CHANNEL_SUCCESS = 'MARKETING.CREATE_CHANNEL_ACCOUNT_SUCCESS',
  CREATE_CHANNEL_FAILURE = 'MARKETING.CREATE_CHANNEL_ACCOUNT_FAILURE',

  DISCONNECT_CHANNEL_REQUEST = 'MARKETING.DISCONNECT_CHANNEL_ACCOUNT_REQUEST',
  DISCONNECT_CHANNEL_SUCCESS = 'MARKETING.DISCONNECT_CHANNEL_ACCOUNT_SUCCESS',
  DISCONNECT_CHANNEL_FAILURE = 'MARKETING.DISCONNECT_CHANNEL_ACCOUNT_FAILURE',

  CREATE_CHANNEL_ACCOUNT_REQUEST = 'MARKETING.CREATE_CHANNEL_ACCOUNT_REQUEST',
  CREATE_CHANNEL_ACCOUNT_SUCCESS = 'MARKETING.CREATE_CHANNEL_ACCOUNT_SUCCESS',
  CREATE_CHANNEL_ACCOUNT_FAILURE = 'MARKETING.CREATE_CHANNEL_ACCOUNT_FAILURE',

  CONNECTION_CONFIRMATION_DIALOG_OPEN = 'MARKETING.CONNECTION_CONFIRMATION_DIALOG_OPEN',
  CONNECTION_CONFIRMATION_DIALOG_CLOSE = 'MARKETING.CONNECTION_CONFIRMATION_DIALOG_CLOSE',

  DELETE_FEED_CONFIRMATION_DIALOG_OPEN = 'MARKETING.DELETE_FEED_CONFIRMATION_DIALOG_OPEN',
  DELETE_FEED_CONFIRMATION_DIALOG_CLOSE = 'MARKETING.DELETE_FEED_CONFIRMATION_DIALOG_CLOSE',

  DELETE_FEED_REQUEST = 'MARKETING.DELETE_FEED_REQUEST',
  DELETE_FEED_SUCCESS = 'MARKETING.DELETE_FEED_SUCCESS',
  DELETE_FEED_FAILURE = 'MARKETING.DELETE_FEED_FAILURE',

  DOWNLOAD_FEEDS_DIALOG_OPEN = 'MARKETING.DOWNLOAD_FEEDS_DIALOG_OPEN',
  DOWNLOAD_FEEDS_DIALOG_CLOSE = 'MARKETING.DOWNLOAD_FEEDS_DIALOG_CLOSE',

  FEEDS_CREATION_CONFIRMATION_DIALOG_OPEN = 'MARKETING.FEEDS_CREATION_CONFIRMATION_DIALOG_OPEN',
  FEEDS_CREATION_CONFIRMATION_DIALOG_CLOSE = 'MARKETING.FEEDS_CREATION_CONFIRMATION_DIALOG_CLOSE',

  FETCH_ORGANIZATION_FEEDS_REQUEST = 'MARKETING.FETCH_ORGANIZATION_FEEDS_REQUEST',
  FETCH_ORGANIZATION_FEEDS_SUCCESS = 'MARKETING.FETCH_ORGANIZATION_FEEDS_SUCCESS',
  FETCH_ORGANIZATION_FEEDS_FAILURE = 'MARKETING.FETCH_ORGANIZATION_FEEDS_FAILURE',

  FETCH_ORGANIZATION_CHANNELS_REQUEST = 'MARKETING.FETCH_ORGANIZATION_CHANNELS_REQUEST',
  FETCH_ORGANIZATION_CHANNELS_SUCCESS = 'MARKETING.FETCH_ORGANIZATION_CHANNELS_SUCCESS',
  FETCH_ORGANIZATION_CHANNELS_FAILURE = 'MARKETING.FETCH_ORGANIZATION_CHANNELS_FAILURE',

  FETCH_ORGANIZATION_CHANNEL_SUMMARIES_REQUEST = 'MARKETING.FETCH_ORGANIZATION_CHANNEL_SUMMARIES_REQUEST',
  FETCH_ORGANIZATION_CHANNEL_SUMMARIES_SUCCESS = 'MARKETING.FETCH_ORGANIZATION_CHANNEL_SUMMARIES_SUCCESS',
  FETCH_ORGANIZATION_CHANNEL_SUMMARIES_FAILURE = 'MARKETING.FETCH_ORGANIZATION_CHANNEL_SUMMARIES_FAILURE',

  FETCH_ORGANIZATION_GOOGLE_ACCOUNTS_REQUEST = 'MARKETING.FETCH_ORGANIZATION_GOOGLE_ACCOUNTS_REQUEST',
  FETCH_ORGANIZATION_GOOGLE_ACCOUNTS_SUCCESS = 'MARKETING.FETCH_ORGANIZATION_GOOGLE_ACCOUNTS_SUCCESS',
  FETCH_ORGANIZATION_GOOGLE_ACCOUNTS_FAILURE = 'MARKETING.FETCH_ORGANIZATION_GOOGLE_ACCOUNTS_FAILURE',

  FETCH_ORGANIZATION_GOOGLE_FEEDS_REQUEST = 'MARKETING.FETCH_ORGANIZATION_GOOGLE_FEEDS_REQUEST',
  FETCH_ORGANIZATION_GOOGLE_FEEDS_SUCCESS = 'MARKETING.FETCH_ORGANIZATION_GOOGLE_FEEDS_SUCCESS',
  FETCH_ORGANIZATION_GOOGLE_FEEDS_FAILURE = 'MARKETING.FETCH_ORGANIZATION_GOOGLE_FEEDS_FAILURE',

  FETCH_FACEBOOK_FEED_FORMS_REQUEST = 'FETCH_FACEBOOK_FEED_FORMS_REQUEST',
  FETCH_FACEBOOK_FEED_FORMS_SUCCESS = 'FETCH_FACEBOOK_FEED_FORMS_SUCCESS',
  FETCH_FACEBOOK_FEED_FORMS_FAILURE = 'FETCH_FACEBOOK_FEED_FORMS_FAILURE',

  FETCH_FACEBOOK_BUSINESSES_REQUEST = 'MARKETING.FETCH_FACEBOOK_BUSINESSES_REQUEST',
  FETCH_FACEBOOK_BUSINESSES_SUCCESS = 'MARKETING.FETCH_FACEBOOK_BUSINESSES_SUCCESS',
  FETCH_FACEBOOK_BUSINESSES_FAILURE = 'MARKETING.FETCH_FACEBOOK_BUSINESSES_FAILURE',

  FETCH_FACEBOOK_CATALOGS_REQUEST = 'MARKETING.FETCH_FACEBOOK_CATALOGS_REQUEST',
  FETCH_FACEBOOK_CATALOGS_SUCCESS = 'MARKETING.FETCH_FACEBOOK_CATALOGS_SUCCESS',
  FETCH_FACEBOOK_CATALOGS_FAILURE = 'MARKETING.FETCH_FACEBOOK_CATALOGS_FAILURE',

  FETCH_FEED_SOURCES_REQUEST = 'MARKETING.FETCH_FEED_SOURCES_REQUEST',
  FETCH_FEED_SOURCES_SUCCESS = 'MARKETING.FETCH_FEED_SOURCES_SUCCESS',
  FETCH_FEED_SOURCES_FAILURE = 'MARKETING.FETCH_FEED_SOURCES_FAILURE',

  FETCH_FEED_FORMS_REQUEST = 'MARKETING.FETCH_FEED_FORMS_REQUEST',
  FETCH_FEED_FORMS_SUCCESS = 'MARKETING.FETCH_FEED_FORMS_SUCCESS',
  FETCH_FEED_FORMS_FAILURE = 'MARKETING.FETCH_FEED_FORMS_FAILURE',

  CREATE_FEEDS_BULK_REQUEST = 'MARKETING.CREATE_FEEDS_BULK_REQUEST',
  CREATE_FEEDS_BULK_SUCCESS = 'MARKETING.CREATE_FEEDS_BULK_SUCCESS',
  CREATE_FEEDS_BULK_FAILURE = 'MARKETING.CREATE_FEEDS_BULK_FAILURE',

  REQUEST_CHANNEL_REQUEST = 'MARKETING.REQUEST_CHANNEL_REQUEST',
  REQUEST_CHANNEL_SUCCESS = 'MARKETING.REQUEST_CHANNEL_SUCCESS',
  REQUEST_CHANNEL_FAILURE = 'MARKETING.REQUEST_CHANNEL_FAILURE',

  HIDE_DIALOG = 'MARKETING.HIDE_DIALOG',
  UPDATE_DIALOG = 'MARKETING.UPDATE_DIALOG',
  DELETE_DIALOG = 'MARKETING.DELETE_DIALOG',
  CHANGE_SELECTED_FEED_SOURCE = 'MARKETING.CHANGE_SELECTED_FEED_SOURCE',
}

export default ActionTypes;
