import { css } from '@emotion/css';

export const row = css`
  line-height: 2rem;
`;

export const field = css`
  width: 50%;
  min-width: 32rem;
`;

export const label = css`
  color: #9ba6b3;
  font-weight: 500;
  display: inline-block;
  font-size: 0.875rem;
  padding: 0;
  margin: 0;
  width: 8rem;
`;

export const value = css`
  width: auto;
  margin: 0;
  padding: 0 0 0 1rem;
  font-size: 0.875rem;
`;

export const unavailableValue = css`
  color: #9ba6b3;
`;

export const capitalize = css`
  text-transform: capitalize;
`;
