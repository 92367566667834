import { createSelector } from 'reselect';
import get from 'lodash/get';
import getExclusionRuleDeleteConfirmation from './getExclusionRuleDeleteConfirmation';

const getOrganizationIdForExclusionRuleToBeDeleted = createSelector(
  getExclusionRuleDeleteConfirmation,
  (state) => get(state, 'organizationId'),
);

export default getOrganizationIdForExclusionRuleToBeDeleted;
