import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import { Status } from '@flowio/api-internal-constants';
import map from 'lodash/map';
import { isTrafficEquallyAllocated } from '../utils';
import EditExperimentForm from '../components/edit-experiment-form';
import FormNames from '../constants/form-names';
import createToast from '../../console/actions/createToast';

import {
  fetchExperimentDiscriminatorValues,
  fetchExperimentVariants,
  updateExperiment,
  createExperiment,
  updateExperimentStatus,
} from '../actions';

import {
  getCurrentExperimentForm,
  getExperimentValues,
  getExperimentVariants,
  getExperimentFormScope,
} from '../selectors';

import MESSAGES from '../constants/messages';
import { getUserEmail } from '../../console';

const handleSuccess = ({ launch, key, transitions }, dispatch, props) => {
  if (launch && transitions.length) {
    dispatch(updateExperimentStatus(props.organizationId, key, transitions[0])).then(
      ({ status }) => {
        dispatch(createToast({
          content: status === Status.SCHEDULED
            ? MESSAGES.SCHEDULED_TOAST_MESSAGE : MESSAGES.LAUNCHED_TOAST_MESSAGE,
          icon: 'CheckCircle',
          intent: 'positive',
        }));
        props.onClose();
      },
    );
  } else {
    dispatch(createToast({
      content: 'Saved!',
      icon: 'CheckCircle',
      intent: 'positive',
    }));
    props.onClose();
  }
};

const handleFailure = (action, dispatch) => {
  dispatch(createToast({
    content: 'Something went wrong, please try again',
    icon: 'Error',
    intent: 'negative',
  }));
};

const handleSubmit = ({
  key,
  variants,
  name,
  discriminator,
  status,
  description,
  emails,
  significance_action: significanceAction,
  sessionQuery,
}, dispatch, { organizationId }) => {
  const launch = status !== Status.DRAFT;

  const form = {
    name: name.trim(),
    description,
    emails,
    significance_action: discriminator === 'experience' ? significanceAction : undefined,
    variants: map(variants, ({ key: k, traffic_percentage: tp }) => ({
      discriminator,
      key: k,
      traffic_percentage: tp,
    })),
    session_query: sessionQuery,
  };

  if (key) {
    return dispatch(updateExperiment(organizationId, key, form)).then(
      ({ transitions }) => ({ key, launch, transitions }),
    );
  }

  return dispatch(createExperiment(organizationId, form)).then(
    ({ key: k, transitions }) => ({ key: k, launch, transitions }),
  );
};

const formSelector = formValueSelector(FormNames.EDIT_EXPERIMENT_WORKSHEET);

const mapStateToProps = (state) => {
  const {
    name,
    key,
    description,
    discriminator,
    variants,
    emails,
    // eslint-disable-next-line camelcase
    significance_action = 'end_and_implement_winner',
    session_query: sessionQuery,
  } = getCurrentExperimentForm(state);

  const traffic = map(formSelector(state, 'variants'), ({ traffic_percentage: tpc }) => Number(tpc));

  return {
    isNew: !key,
    discriminatorValues: getExperimentValues(state),
    variants: getExperimentVariants(state),
    initialValues: {
      name,
      key,
      description,
      discriminator,
      variants,
      emails: emails?.length ? emails : [getUserEmail(state)],
      significance_action,
      status: undefined,
      sessionQuery,
    },
    isEquallyAllocated: isTrafficEquallyAllocated(traffic),
    experimentFormScope: getExperimentFormScope(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { organizationId } = ownProps;

  return {
    onChangeDiscriminator(key) {
      return dispatch(fetchExperimentDiscriminatorValues(organizationId, key));
    },
    onChangeExperimentValue(discriminator, { key, scope }) {
      return dispatch(fetchExperimentVariants(organizationId, discriminator, key, scope));
    },
  };
};

const ConnectedEditExperimentForm = reduxForm({
  form: FormNames.EDIT_EXPERIMENT_WORKSHEET,
  enableReinitialize: true,
  onSubmit: handleSubmit,
  onSubmitSuccess: handleSuccess,
  onSubmitFail: handleFailure,
})(EditExperimentForm);

export default connect(
  mapStateToProps, mapDispatchToProps,
)(ConnectedEditExperimentForm);
