import { css } from '@emotion/react';
import { isNotNil } from '@flowio/is';
import { Copy, InfoCircleOutline } from '@flowio/react-icons';
import {
  Table,
  TableBody,
  TableHead,
  TableHeadColumn,
  TableRow, TableRowColumn,
} from '@flowio/react-table';
import { Tooltip } from '@flowio/react-tooltip';
import classNames from 'classnames';
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router';
import { colors, spacing } from '../../../../theme/tokens';
import * as OrderPaymentStyles from './order-payment.styles';

const displayName = 'OrderPayment';

const styles = {
  infoIcon: css({
    width: '1rem',
    height: '1rem',
    fill: colors['flow-blue'][300],
    verticalAlign: 'text-bottom',
    marginLeft: spacing.xs,
  }),
  copyIcon: css({
    width: '1rem',
    height: '1rem',
    fill: colors['flow-blue'][300],
    verticalAlign: 'bottom',
    border: `solid 1px ${colors['flow-blue'][300]}`,
    borderRadius: '4px',
    marginLeft: spacing.xs,
    padding: '4px',
    cursor: 'pointer',
  }),
};

const getInfoIcon = () => (
  <InfoCircleOutline css={styles.infoIcon} />
);

const getPaymentProcessor = (payment: io.flow.internal.v0.models.PaymentSummaryV2) => {
  if (payment.details?.processor) {
    const processorName = capitalize(payment.details.processor.processor);
    const hasIdentifier = payment.details.processor.operation_identifier;
    return (
      <>
        {processorName}
        {hasIdentifier && (
          <Tooltip
            openOnHover
            openOnClick
            content={`${processorName} Identifier type is ${payment.details.processor.operation_identifier!.label}`}
            trigger={getInfoIcon()}
          />
        )}
      </>
    );
  }
  return null;
};

interface OrderPaymentProps {
  isFlowEmployee: boolean;
  onCopyAuthorizationIdentifier: (identifier: string) => void;
  payments?: io.flow.internal.v0.models.PaymentSummaryV2[];
}

const OrderPayment: React.FC<OrderPaymentProps> = ({
  isFlowEmployee,
  onCopyAuthorizationIdentifier,
  payments = [],
}) => {
  const handleCopyAuthorizationIdentifier = (identifier: string) => () => {
    onCopyAuthorizationIdentifier(identifier);
  };

  const getPaymentReferenceIdentifier = (payment: io.flow.internal.v0.models.PaymentSummaryV2) => {
    const identifier = payment.details?.processor?.operation_identifier;
    if (identifier) {
      return (
        <>
          {identifier.id}
          <Copy
            css={styles.copyIcon}
            onClick={handleCopyAuthorizationIdentifier(identifier.id)}
          />
        </>
      );
    }
    return null;
  };

  const getAccountIdentifier = (payment: io.flow.internal.v0.models.PaymentSummaryV2) => {
    const identifier = payment.details?.processor?.account?.identifier;

    if (isNotNil(identifier)) {
      return (
        <>
          {identifier.id}
        </>
      );
    }
    return null;
  };

  function isStripeProcessor(payment: io.flow.internal.v0.models.PaymentSummaryV2): boolean {
    return payment.details?.processor?.processor === 'stripe';
  }

  function isAdyenProcessor(payment: io.flow.internal.v0.models.PaymentSummaryV2): boolean {
    return payment.details?.processor?.processor === 'adyen';
  }

  function isPaypalProcessor(payment: io.flow.internal.v0.models.PaymentSummaryV2): boolean {
    return payment.details?.processor?.processor === 'paypal';
  }

  const getTransactionLink = (payment: io.flow.internal.v0.models.PaymentSummaryV2) => {
    const operationIdentifier = get(payment, 'details.processor.operation_identifier.id');
    const operationIdLabel = get(payment, 'details.processor.operation_identifier.label');
    let url = '';

    if (isAdyenProcessor(payment) && operationIdLabel === 'PSP Reference') {
      url = `https://ca-live.adyen.com/ca/ca/accounts/showTx.shtml?pspReference=${operationIdentifier}&txType=Payment`;
    } else if (isStripeProcessor(payment)) {
      url = `https://dashboard.stripe.com/payments/${operationIdentifier}`;
    } else if (isPaypalProcessor(payment)) {
      url = `https://www.paypal.com/activity/payment/${operationIdentifier}`;
    }

    if (!url) {
      return null;
    }

    return (
      <Link target="_blank" to={url}>
        {url}
      </Link>
    );
  };

  return (
    <>
      <Table className="order-payment" striped displayDensity="compact">
        <TableHead>
          <TableRow>
            <TableHeadColumn
              className={classNames(OrderPaymentStyles.header, OrderPaymentStyles.first)}
            >
              Date
            </TableHeadColumn>
            <TableHeadColumn className={OrderPaymentStyles.header}>Type</TableHeadColumn>
            <TableHeadColumn className={OrderPaymentStyles.header}>Status</TableHeadColumn>
            <TableHeadColumn className={OrderPaymentStyles.header}>Method</TableHeadColumn>
            <TableHeadColumn className={OrderPaymentStyles.header}>Amount</TableHeadColumn>
            <TableHeadColumn className={OrderPaymentStyles.header}>Reference #</TableHeadColumn>
            {isFlowEmployee && (
              <>
                <TableHeadColumn className={OrderPaymentStyles.header}>Processor</TableHeadColumn>
                <TableHeadColumn className={OrderPaymentStyles.header}>Identifier</TableHeadColumn>
                <TableHeadColumn className={OrderPaymentStyles.header}>
                  Account Identifer
                </TableHeadColumn>
                <TableHeadColumn className={OrderPaymentStyles.header}>Link</TableHeadColumn>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {payments.map((payment) => (
            <TableRow key={`${payment.id}--${payment.timestamp}`}>
              <TableRowColumn className={classNames(OrderPaymentStyles.cellWrap, OrderPaymentStyles.cellSelect)}>{moment(payment.timestamp).format('llll')}</TableRowColumn>
              <TableRowColumn className={OrderPaymentStyles.cellSelect}>
                {capitalize(payment.type)}
              </TableRowColumn>
              <TableRowColumn className={OrderPaymentStyles.cellSelect}>{capitalize(get(payment, 'status'))}</TableRowColumn>
              <TableRowColumn
                className={classNames(OrderPaymentStyles.cellWrap, OrderPaymentStyles.cellSelect)}
              >
                {payment.description}
              </TableRowColumn>
              <TableRowColumn className={OrderPaymentStyles.cellSelect}>
                {payment.local.label}
              </TableRowColumn>
              <TableRowColumn
                className={classNames(OrderPaymentStyles.cellWrap, OrderPaymentStyles.cellSelect)}
              >
                {payment.id}
              </TableRowColumn>
              {isFlowEmployee && (
                <>
                  <TableRowColumn
                    className={
                      classNames(OrderPaymentStyles.cellWrap, OrderPaymentStyles.cellSelect)
                    }
                  >
                    {getPaymentProcessor(payment)}
                  </TableRowColumn>
                  <TableRowColumn
                    className={
                      classNames(OrderPaymentStyles.cellWrap, OrderPaymentStyles.cellSelect)
                    }
                  >
                    {getPaymentReferenceIdentifier(payment)}
                  </TableRowColumn>
                  <TableRowColumn
                    className={
                      classNames(OrderPaymentStyles.cellWrap, OrderPaymentStyles.cellSelect)
                    }
                  >
                    {getAccountIdentifier(payment)}
                  </TableRowColumn>

                  <TableRowColumn
                    className={
                      classNames(OrderPaymentStyles.cellWrap, OrderPaymentStyles.cellSelect)
                    }
                  >
                    {getTransactionLink(payment)}
                  </TableRowColumn>
                </>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

OrderPayment.displayName = displayName;

export default OrderPayment;
