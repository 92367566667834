import update from 'immutability-helper';

import ActionTypes from '../constants/action-types';
import { ExperienceState } from '../types';
import { RootActionTypes } from '../../../stores/types';

const initialState: ExperienceState['entities']['clone'] = {};

export default function (state: ExperienceState['entities']['clone'] = initialState, action: RootActionTypes): ExperienceState['entities']['clone'] {
  switch (action.type) {
    case ActionTypes.CLONE_EXPERIENCE_REQUEST:
      return update(state, { $unset: ['data', 'error'] });
    case ActionTypes.CLONE_EXPERIENCE_SUCCESS:
      return update(state, {
        data: { $set: action.payload },
        poll: { $set: true },
      });
    case ActionTypes.FETCH_EXPERIENCE_CLONE_SUCCESS:
      return update(state, {
        data: { $set: action.payload },
      });
    case ActionTypes.CLEAR_CLONE_EXPERIENCE:
      return update(state, {
        $unset: ['data', 'poll', 'error'],
      });
    case ActionTypes.CLONE_EXPERIENCE_FAILED:
      return update(state, {
        $unset: ['poll'],
      });
    case ActionTypes.CLONE_EXPERIENCE_ERROR:
      return update(state, {
        error: { $set: action.payload },
      });
    default:
      return state;
  }
}
