import React from 'react';
import classNames from 'classnames';
import * as styles from '../order-details/order-details.styles';

interface ValueOrPlaceholderProps {
  placeholder?: string;
  value?: string;
}

const ValueOrPlaceholder: React.FC<ValueOrPlaceholderProps> = ({
  placeholder = 'Not Provided by the Customer',
  value,
}) => (
  <span className={classNames(styles.value, {
    [styles.valuePlaceholder]: !value || value.trim().length < 1,
  })}
  >
    {!value || value.trim().length < 1 ? placeholder : value}
  </span>
);

export default ValueOrPlaceholder;
