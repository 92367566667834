import { Button } from '@flowio/react-button';
import { NonIdealState } from '@flowio/react-non-ideal-state';
import React from 'react';
import { Stack } from '@flowio/react-stack';
import {
  Worksheet, WorksheetHeader, WorksheetTitle, WorksheetBody,
} from '@flowio/react-worksheet';

import { PuffLoader } from '@flowio/react-icons';
import { css } from '@emotion/react';
import ShippingLaneUpsertForm from '../../containers/ShippingLaneUpsertForm';
import { ShippingLaneWorksheetProps } from '../../types/components';

const puffloaderStyle = css({
  width: '90px',
  height: 'auto',
  marginBottom: '20px',
  color: '#77818f',
});

const ShippingLaneWorksheet: React.FC<ShippingLaneWorksheetProps> = ({
  availableFilters = [],
  initialValues = {},
  isLoading = false,
  isOpen = false,
  isSubmitting = false,
  method,
  onCancel,
  onSave,
  onSuccess,
  regions = [],
  title,
}) => (
  <Worksheet open={isOpen}>
    <WorksheetHeader>
      <Stack alignment="center" distribution="equalSpacing">
        <Button type="button" content="Cancel" disabled={isSubmitting} onClick={onCancel} />
        <WorksheetTitle content={title} />
        <Button type="button" content="Save" disabled={isSubmitting || isLoading} intent="primary" onClick={onSave} />
      </Stack>
    </WorksheetHeader>
    <WorksheetBody>
      {isLoading ? (
        <NonIdealState
          icon={<PuffLoader css={puffloaderStyle} />}
          heading="Loading"
          description="Shipping lane builder is loading, please wait..."
        />
      ) : (
        <ShippingLaneUpsertForm
          availableFilters={availableFilters}
          initialValues={initialValues}
          onSubmitSuccess={onSuccess}
          method={method}
          regions={regions}
        />
      )}
    </WorksheetBody>
  </Worksheet>
);

ShippingLaneWorksheet.displayName = 'ShippingLaneWorksheet';

export default ShippingLaneWorksheet;
