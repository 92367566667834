import { $HttpResponse } from '@flowio/api-sdk';
import ActionTypes from '../constants/ActionTypes';
import { ApiError } from '../../../utilities/clients/api-error';
import standardAction from '../../../utilities/standard-action';
import {
  RootState, ThunkExtraArguments, ThunkResult, ThunkDispatcher,
} from '../../../stores/types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';

interface Props {
  organizationId: string;
}

const fetchFacebookBusinesses = ({
  organizationId,
}: Props): ThunkResult<Promise<void>> => (
  dispatch: ThunkDispatcher,
  getState: () => RootState,
  extra: ThunkExtraArguments,
): Promise<void> => {
  dispatch(standardAction(ActionTypes.FETCH_FACEBOOK_BUSINESSES_REQUEST));

  return extra.apiInternal(getState()).marketingGatewayFacebookConnections.get({
    organization: organizationId,
  }).then((response) => {
    if (response.ok) {
      dispatch(
        standardAction(ActionTypes.FETCH_FACEBOOK_BUSINESSES_SUCCESS, response.body.businesses),
      );
      return;
    }

    const errorResponse = response as $HttpResponse<io.flow.v0.models.GenericError>;
    const error = new ApiError(errorResponse);

    dispatch({
      type: ActionTypes.FETCH_FACEBOOK_BUSINESSES_FAILURE,
      payload: formatErrorResponseV2(errorResponse),
    });
    throw error;
  });
};

export default fetchFacebookBusinesses;
