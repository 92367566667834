import React from 'react';
import { Field } from 'redux-form';
import {
  Card, CardHeader, CardContent, CardTitle,
} from '@flowio/react-card';
import { InfoCircle } from '@flowio/react-icons';
import { Tooltip } from '@flowio/react-tooltip';
import { css } from '@emotion/react';
import { SelectField } from '../../../../components/ReduxFormFieldAdapters';
import { TierSurchargeCardProps } from '../../types/components';

const infoCircleStyle = css`
  margin-left: 8px;
`;

const tooltipStyle = css`
  width: 200px !important;
  color: white !important;
`;

const TierSurchargeCard: React.FC<TierSurchargeCardProps> = ({
  surchargeSettings,
}) => (
  <Card>
    <CardHeader dividing>
      <CardTitle content="Shipping Surcharges" />
    </CardHeader>
    <CardContent>
      {surchargeSettings.map((surchargeSetting) => (
        <Field
          selection
          key={`surchargeSettings.${surchargeSetting.key}`}
          component={SelectField}
          name={`surchargeSettings.${surchargeSetting.key}`}
          fluid
          inline
          hintText="Set Surchage Setting"
          labelIcon={(
            <Tooltip
              openOnHover
              css={tooltipStyle}
              trigger={<InfoCircle width={16} css={infoCircleStyle} />}
            >
              {surchargeSetting.description}
            </Tooltip>
          )}
          labelFor={surchargeSetting.key}
          labelKey="name"
          labelCols={6}
          labelText={surchargeSetting.name}
          valueKey="responsible_party"
          options={surchargeSetting.available}
          gutter
        />
      ))}
    </CardContent>
  </Card>
);

TierSurchargeCard.displayName = 'TierSurchargeCard';

export default TierSurchargeCard;
