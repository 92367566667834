import { createSelector } from 'reselect';

import getFeatures from './getFeatures';

const getCurrentFeature = createSelector(
  getFeatures,
  (features) => features.current,
);

export default getCurrentFeature;
