import { css } from '@emotion/css';

export const database = css`
  color: black;
  padding-right: 100px;
  font-size: 1rem;
`;

export const button = css`
  top: -5px;
`;

export const panel = css`
  &:hover {
    background-color: rgb(236, 236, 236);
  }
`;

export const panelHeader = css`
  padding: 16px
`;

export const errorPanel = css`
  &:hover {
    background-color: rgb(236, 236, 236);
  }
`;
