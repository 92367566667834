import ServerClient from './server';

export { default as api } from './api';
export { default as apiInternal } from './api-internal';

/*
 * The Server client doesn't require a host. It's used client side so all paths
 * will be absolute against the current host of the page. For testing however,
 * the app will be running in a container, and so a host is required to reach
 * the auth endpoints.
 */
const serverHost = process.env.NODE_ENV === 'test' ? 'http://localhost:3000' : '';

export const server = new ServerClient(serverHost); // no host (except tests) - client use only
