import { css } from '@emotion/css';

export const editButton = css`
  margin-left: 1rem;
`;

export const saveButton = css`
  margin-left: 1rem;
`;

export const deleteDialog = css`
  .dialog__container {
    max-width: 560px;
  }
`;

export const dialogCancel = css`
  flex: 1;
`;

export const dialogDelete = css`
  flex: 1;
  margin-left: 1rem;
`;
