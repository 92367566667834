import { ExperienceDefaultsGetResponse } from '@flowio/api-sdk';
import ActionTypes from '../constants/action-types';
import {
  ThunkExtraArguments, RootState, ThunkDispatcher, RootActionTypes, ThunkResult,
} from '../../../stores/types';
import { PropType } from '../../../types';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import toLegacyResponse from '../../../utilities/clients/to-legacy-response';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';

type ResponseType = PropType<ExperienceDefaultsGetResponse, 'body'>;

function getExperienceDefaultsRequestAction(): RootActionTypes {
  return {
    type: ActionTypes.GET_EXPERIENCE_DEFAULTS_REQUEST,
  };
}

function getExperienceDefaultsSuccessAction(
  responseBody: io.flow.v0.models.ExperienceDefaults,
): RootActionTypes {
  return {
    type: ActionTypes.GET_EXPERIENCE_DEFAULTS_SUCCESS,
    payload: responseBody,
  };
}

function getExperienceDefaultsFailureAction(
  response: ExperienceDefaultsGetResponse,
): RootActionTypes {
  return {
    type: ActionTypes.GET_EXPERIENCE_DEFAULTS_FAILURE,
    payload: formatErrorResponseV2(response),
  };
}

export default function getExperienceDefaults(
  organization: string,
  regionId: string,
): ThunkResult<Promise<LegacyResponse<ResponseType>>> {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<LegacyResponse<ResponseType>> => {
    dispatch(getExperienceDefaultsRequestAction());

    return extra.api(getState()).experienceDefaults.get({
      organization,
      region_id: regionId,
    }).then((response) => {
      if (response.ok) dispatch(getExperienceDefaultsSuccessAction(response.body));
      else dispatch(getExperienceDefaultsFailureAction(response));
      return toLegacyResponse<ResponseType>(response);
    });
  };
}
