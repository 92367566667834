import Client from './client';

export default class CheckoutContentSummaries extends Client {
  constructor(opts) {
    let options = opts;

    if (typeof opts === 'string') {
      options = { host: opts }; // convert host string to options object
    }

    options.serviceName = 'API Internal';

    super(options);
  }

  getCheckout(organization, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/content/checkout`, options);
  }

  getUrlByKey(organization, key, options = {}) {
    return this.makeRequest(`${this.host}/${organization}/content/${key}/url`, options);
  }

}
