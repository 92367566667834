import ActionTypes from '../constants/ActionTypes';
import { RootActionTypes, ThunkResult } from '../../../stores/types';
import formatErrorResponseV2, { FormattedErrorMessages } from '../../../utilities/format-error-response-v2';

interface FetchShippingConfigurationByKeyOptions {
  experienceKey?: string;
  organizationId: string;
  shippingConfigurationKey: string;
}

// TODO: Prevent fetch when resource is already in application state.
function createRequestAction(): RootActionTypes {
  return {
    type: ActionTypes.FETCH_SHIPPING_CONFIGURATION_REQUEST,
  };
}

function createSuccessAction(
  payload: io.flow.v0.models.ShippingConfiguration,
): RootActionTypes {
  return {
    type: ActionTypes.FETCH_SHIPPING_CONFIGURATION_SUCCESS,
    payload,
  };
}

function createFailureAction(payload: FormattedErrorMessages): RootActionTypes {
  return {
    type: ActionTypes.FETCH_SHIPPING_CONFIGURATION_FAILURE,
    error: true,
    payload,
  };
}

/**
 * An action responsible for fetching a shipping configuration by key.
 * @param {String} organizationId - Refers to the organization identifier.
 * @param {String} shippingConfigurationKey - Refers to the shipping configuration key.
 */
function fetchShippingConfigurationByKey({
  experienceKey,
  organizationId,
  shippingConfigurationKey,
}: FetchShippingConfigurationByKeyOptions): ThunkResult<Promise<RootActionTypes>> {
  return function fetchShippingConfigurationByKeyEffects(
    dispatch,
    getState,
    extra,
  ): Promise<RootActionTypes> {
    dispatch(createRequestAction());

    return extra.api(getState()).shippingConfigurations.getByKey({
      key: shippingConfigurationKey,
      organization: organizationId,
      expand: ['center'],
      experience: experienceKey,
    }).then((response) => {
      if (response.ok) {
        return dispatch(createSuccessAction(response.body));
      }

      const errors = formatErrorResponseV2(response);
      return dispatch(createFailureAction(errors));
    });
  };
}

export default fetchShippingConfigurationByKey;
