import { ExperiencesGetMarginsByExperienceKeyResponse } from '@flowio/api-sdk';
import ActionTypes from '../constants/action-types';
import {
  ThunkExtraArguments,
  RootState,
  ThunkDispatcher,
  ThunkResult,
} from '../../../stores/types';
import { LegacyResponse } from '../../../utilities/clients/types/server';
import toLegacyResponse from '../../../utilities/clients/to-legacy-response';
import { PropType } from '../../../types';
import formatErrorResponseV2 from '../../../utilities/format-error-response-v2';

type ResponseType = PropType<ExperiencesGetMarginsByExperienceKeyResponse, 'body'>;

export default function getMargins(
  organization: string,
  experienceKey: string,
): ThunkResult<Promise<LegacyResponse<ResponseType>>> {
  return (
    dispatch: ThunkDispatcher,
    getState: () => RootState,
    extra: ThunkExtraArguments,
  ): Promise<LegacyResponse<ResponseType>> => {
    dispatch({
      type: ActionTypes.GET_MARGINS_REQUEST,
    });

    return extra.api(getState()).experiences.getMarginsByExperienceKey({
      organization,
      experience_key: experienceKey,
    }).then((response) => {
      if (response.ok) {
        dispatch({
          type: ActionTypes.GET_MARGINS_SUCCESS,
          payload: response.body,
        });
      } else {
        dispatch({
          type: ActionTypes.GET_MARGINS_FAILURE,
          payload: formatErrorResponseV2(response),
        });
      }
      return toLegacyResponse<ResponseType>(response);
    });
  };
}
