import {
  $HttpClientOptions,
  CountriesResource,
  OrderRevenueRegionChartsResource,
  OrderRevenueTimelineChartsResource,
  OrganizationAccountsResource,
  OrganizationBillingStatementsResource,
  ItemHarmonizationsResource,
  FeatureValuesResource,
  FraudReviewDecisionsResource,
  QueryBuildersResource,
  DeliveredDutyOptionsResource,
  DeliveredDutyPreferencesResource,
  ExperimentsResource,
  AlertFailureSummariesResource,
  AlertRequeueSummariesResource,
  FeaturesResource,
  CenterQueryBuildersResource,
  ClassificationStatisticsResource,
  MarketingGatewayFacebookConnectionAccessTokenSummariesResource,
  MarketingGatewayChannelsResource,
  MarketingGatewayFacebookBusinessesResource,
  MarketingGatewayFacebookConnectionsResource,
  MarketingGatewayFeedFormsResource,
  MarketingGatewayFeedsResource,
  MarketingGatewayFeedSourcesResource,
  MarketingGatewayGoogleConnectionsResource,
  MembershipsResource,
  OptinAttributesResource,
  OptinPromptsResource,
  OrderAddressValidationsResource,
  UsersResource,
  InternalDebugTransactionsResource,
  UserOneTimeTokensResource,
  ItemClassificationSummariesResource,
  CheckoutConfigurationsResource,
  ExperimentFormDefaultsResource,
  ExperimentResultsWithTimestampsResource,
  OrderDetailsResource,
  ShopsResource,
  FlowLabProjectsResource,
  LabProjectSettingsResource,
} from '@flowio/api-internal-sdk';

export class ApiInternalClientV2 {
  countries: CountriesResource;

  orderRevenueRegionCharts: OrderRevenueRegionChartsResource;

  orderRevenueTimelineCharts: OrderRevenueTimelineChartsResource;

  organizationAccounts: OrganizationAccountsResource;

  organizationBillingStatements: OrganizationBillingStatementsResource;

  checkoutConfigurations: CheckoutConfigurationsResource;

  itemHarmonizations: ItemHarmonizationsResource;

  itemClassificationSummaries: ItemClassificationSummariesResource;

  debugTransactions: InternalDebugTransactionsResource;

  featureValues: FeatureValuesResource;

  fraudReviewDecisions: FraudReviewDecisionsResource;

  queryBuilders: QueryBuildersResource;

  deliveredDutyOptions: DeliveredDutyOptionsResource;

  deliveeredDutyPreferences: DeliveredDutyPreferencesResource;

  experiments: ExperimentsResource;

  experimentFormDefaults: ExperimentFormDefaultsResource;

  experimentResultsWithTimestamps: ExperimentResultsWithTimestampsResource;

  alertFailureSummaries: AlertFailureSummariesResource;

  alertRequeueSummaries: AlertRequeueSummariesResource;

  features: FeaturesResource;

  flowLabs: FlowLabProjectsResource;

  flowLabsProjectSettings: LabProjectSettingsResource;

  centerQueryBuilders: CenterQueryBuildersResource;

  classificationStatistics: ClassificationStatisticsResource;

  // eslint-disable-next-line max-len
  marketingGatewayFacebookConnectionAccessTokenSummaries: MarketingGatewayFacebookConnectionAccessTokenSummariesResource;

  marketingGatewayChannels: MarketingGatewayChannelsResource;

  marketingGatewayFacebookConnections: MarketingGatewayFacebookConnectionsResource;

  marketingGatewayFeedForms: MarketingGatewayFeedFormsResource;

  marketingGatewayFeeds: MarketingGatewayFeedsResource;

  marketingGatewayFeedSources: MarketingGatewayFeedSourcesResource;

  marketingGatewayFacebookBusinesses: MarketingGatewayFacebookBusinessesResource;

  marketingGatewayGoogleConnections: MarketingGatewayGoogleConnectionsResource;

  memberships: MembershipsResource;

  optinAttributes: OptinAttributesResource;

  optinPrompts: OptinPromptsResource;

  orderAddressValidations: OrderAddressValidationsResource;

  users: UsersResource;

  userOneTimeTokens: UserOneTimeTokensResource;

  orderDetails: OrderDetailsResource;

  shops: ShopsResource;

  constructor(options: $HttpClientOptions) {
    this.countries = new CountriesResource(options);
    this.orderRevenueRegionCharts = new OrderRevenueRegionChartsResource(options);
    this.orderRevenueTimelineCharts = new OrderRevenueTimelineChartsResource(options);
    this.organizationAccounts = new OrganizationAccountsResource(options);
    this.organizationBillingStatements = new OrganizationBillingStatementsResource(options);
    this.itemHarmonizations = new ItemHarmonizationsResource(options);
    this.itemClassificationSummaries = new ItemClassificationSummariesResource(options);
    this.featureValues = new FeatureValuesResource(options);
    this.flowLabs = new FlowLabProjectsResource(options);
    this.flowLabsProjectSettings = new LabProjectSettingsResource(options);
    this.fraudReviewDecisions = new FraudReviewDecisionsResource(options);
    this.queryBuilders = new QueryBuildersResource(options);
    this.deliveredDutyOptions = new DeliveredDutyOptionsResource(options);
    this.deliveeredDutyPreferences = new DeliveredDutyPreferencesResource(options);
    this.experiments = new ExperimentsResource(options);
    this.experimentFormDefaults = new ExperimentFormDefaultsResource(options);
    this.experimentResultsWithTimestamps = new ExperimentResultsWithTimestampsResource(options);
    this.alertFailureSummaries = new AlertFailureSummariesResource(options);
    this.alertRequeueSummaries = new AlertRequeueSummariesResource(options);
    this.features = new FeaturesResource(options);
    this.centerQueryBuilders = new CenterQueryBuildersResource(options);
    this.classificationStatistics = new ClassificationStatisticsResource(options);
    // eslint-disable-next-line max-len
    this.marketingGatewayFacebookConnectionAccessTokenSummaries = new MarketingGatewayFacebookConnectionAccessTokenSummariesResource(options);
    this.marketingGatewayChannels = new MarketingGatewayChannelsResource(options);
    // eslint-disable-next-line max-len
    this.marketingGatewayFacebookConnections = new MarketingGatewayFacebookConnectionsResource(options);
    this.marketingGatewayFacebookBusinesses = new MarketingGatewayFacebookBusinessesResource(
      options,
    );
    this.marketingGatewayFeedForms = new MarketingGatewayFeedFormsResource(options);
    this.marketingGatewayFeeds = new MarketingGatewayFeedsResource(options);
    this.marketingGatewayFeedSources = new MarketingGatewayFeedSourcesResource(options);
    this.marketingGatewayGoogleConnections = new MarketingGatewayGoogleConnectionsResource(options);
    this.memberships = new MembershipsResource(options);
    this.optinAttributes = new OptinAttributesResource(options);
    this.optinPrompts = new OptinPromptsResource(options);
    this.orderAddressValidations = new OrderAddressValidationsResource(options);
    this.users = new UsersResource(options);
    this.userOneTimeTokens = new UserOneTimeTokensResource(options);
    this.checkoutConfigurations = new CheckoutConfigurationsResource(options);
    this.orderDetails = new OrderDetailsResource(options);
    this.shops = new ShopsResource(options);
    this.debugTransactions = new InternalDebugTransactionsResource(options);
  }
}

export function createClient(options: $HttpClientOptions): ApiInternalClientV2 {
  return new ApiInternalClientV2(options);
}
