import React from 'react';
import noop from 'lodash/noop';
import {
  Card, CardHeader, CardContent, CardTitle,
} from '@flowio/react-card';

import Errors from '../../../../console/components/errors';
import ItemMarginForm from '../../item-margins/item-margin-form';
import { MergedProps as Props } from '../types';
import * as styles from '../../item-margins/item-margin-summary.styles';

const ItemMarginCreate: React.FC<Props> = ({
  currency,
  errors,
  onCancel,
}) => (
  <section className={['item-margin-create', styles.itemMarginCreate].join(' ')}>
    <Card>
      <CardHeader dividing>
        <CardTitle content="Create New Item Margin" />
      </CardHeader>
      <CardContent>
        <Errors errors={errors && errors.messages} />
        <ItemMarginForm
          currency={currency}
          onCancel={onCancel}
        />
      </CardContent>
    </Card>
  </section>
);

ItemMarginCreate.defaultProps = {
  currency: 'USD',
  errors: {},
  onCancel: noop,
};
ItemMarginCreate.displayName = 'ItemMarginCreate';

export default ItemMarginCreate;
