import { RootActionTypes } from '../../../stores/types';
import ActionTypes from '../constants/action-types';
import { RuleFilter } from '../types/components';

const initialState: RuleFilter[] = [];

export default function (state = initialState, action: RootActionTypes) {
  switch (action.type) {
    case ActionTypes.FETCH_RULE_QUERY_FILTER_REQUEST:
      return initialState;
    case ActionTypes.FETCH_RULE_QUERY_FILTER_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
