import { browserHistory } from 'react-router';

export default function goToItemMarginCreate(
  organization: string,
  experienceKey: string,
) {
  return (): void => {
    browserHistory.push(`/${organization}/experience/${experienceKey}/pricing/margins/create`);
  };
}
