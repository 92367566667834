import React, { useEffect, useState } from 'react';

import { ReactJSXIntrinsicElements } from '@emotion/react/types/jsx-namespace';
import { ActionTypes } from '@flowio/redux-fetch';
import { isNotNil } from '@flowio/is';
import { OrganizationCard } from '../../../organization';
import * as styles from './choose-organization.styles';
import useThunkDispatcher from '../../../../hooks/useThunkDispatcher';
import { fetchUserOrganizationMemberships } from '../../../user';

type Props = {
  className?: string;
  onOrganizationCardClick?: (...args: any) => void;
  onRedirectToDefaultOrg?: (organization: io.flow.v0.models.Organization) => void;
  organizations: io.flow.v0.models.Organization[];
} & ReactJSXIntrinsicElements['div'];

const ChooseOrganization: React.FC<Props> = ({
  className,
  onOrganizationCardClick,
  onRedirectToDefaultOrg,
  organizations,
  ...otherProps
}) => {
  const dispatch = useThunkDispatcher();
  const [loadedAllOrganizations, setLoadedAllOrganizations] = useState(false);

  useEffect(() => {
    let ignore = false;
    // activate global loader indicator
    dispatch({
      type: ActionTypes.FETCH_REQUEST,
    });
    dispatch(fetchUserOrganizationMemberships()).then(() => {
      if (!ignore) {
        setLoadedAllOrganizations(true);
        dispatch({
          type: ActionTypes.FETCH_SUCCESS,
        });
      }
    });

    return () => {
      ignore = true;
    };
  }, [dispatch]);

  // Run this once and only once
  useEffect(() => {
    if (isNotNil(onRedirectToDefaultOrg) && loadedAllOrganizations && organizations.length === 1) {
      onRedirectToDefaultOrg(organizations[0]);
    }
  }, [loadedAllOrganizations, onRedirectToDefaultOrg, organizations]);

  return (
    <div {...otherProps} className={styles.chooseOrganization}>
      <h2 className={styles.title}>
        Select an Organization
      </h2>
      <div className={styles.options}>
        {loadedAllOrganizations && organizations.length === 0 ? (
          'No organizations found'
        ) : (
          <>
            {organizations.map((organization) => (
              <OrganizationCard
                key={organization.id}
                organization={organization}
                className="organization-card"
                onClick={onOrganizationCardClick}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

ChooseOrganization.displayName = 'ChooseOrganization';

ChooseOrganization.defaultProps = {
  className: undefined,
  onOrganizationCardClick: undefined,
};

export default ChooseOrganization;
