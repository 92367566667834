import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { css } from '@emotion/react';
import {
  Card,
  CardContent,
  CardEmptyState,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@flowio/react-card';
import OrderNoteDeleteConfirmation from './OrderNoteDeleteConfirmation';
import OrderNotesList from './OrderNotesList';
import { fetchOrderNotes } from '../../actions/orderNotes';
import { getOrderNotes } from '../../selectors';
import { DataStateStatus } from '../../../../utilities/redux/types';
import CreateOrderNoteForm from './CreateOrderNoteForm';
import EditOrderNoteForm from './EditOrderNoteForm';

interface OrderNotesProps {
  organizationId: string;
  orderNumber: string;
}

const emptyCardStyles = css({
  height: '20px',
});

const OrderNotes: React.FC<OrderNotesProps> = ({
  organizationId,
  orderNumber,
}) => {
  const [editNote, setEditNote] = useState<io.flow.internal.v0.models.OrderNote | undefined>();
  const [deleteNote, setDeleteNote] = useState<io.flow.internal.v0.models.OrderNote | undefined>();

  const dispatch = useDispatch();

  const { data: notes, dataStateStatus } = useSelector(getOrderNotes);

  useEffect(() => {
    dispatch(fetchOrderNotes(organizationId, orderNumber));
  }, [dispatch, orderNumber, organizationId]);

  return (
    <>
      <Card>
        <CardHeader dividing>
          <CardTitle content="Customer Service Log" />
        </CardHeader>
        <CardContent style={{ padding: 0 }}>
          {
            dataStateStatus === DataStateStatus.LOADED && (
              <OrderNotesList
                notes={notes!}
                onDelete={setDeleteNote}
                onEdit={setEditNote}
              />
            )
          }
          {
            (
              dataStateStatus === DataStateStatus.EMPTY
              || dataStateStatus === DataStateStatus.UNKNOWN
            ) && <CardEmptyState content="No log messages found." css={emptyCardStyles} />
          }
          {
            dataStateStatus === DataStateStatus.LOADING && <CardEmptyState content="Loading log messages." css={emptyCardStyles} />
          }
          {
            dataStateStatus === DataStateStatus.ERROR && <CardEmptyState content="An error occurred loading the log messages." css={emptyCardStyles} />
          }
        </CardContent>
        <CardFooter>
          { !editNote && (
            <CreateOrderNoteForm
              organizationId={organizationId}
              orderNumber={orderNumber}
            />
          ) }
          { editNote && (
            <EditOrderNoteForm
              organizationId={organizationId}
              orderNumber={orderNumber}
              note={editNote}
              onClose={() => setEditNote(undefined)}
            />
          ) }
        </CardFooter>
      </Card>
      <OrderNoteDeleteConfirmation
        note={deleteNote}
        organizationId={organizationId}
        orderNumber={orderNumber}
        onClose={() => setDeleteNote(undefined)}
      />
    </>
  );
};

export default OrderNotes;
