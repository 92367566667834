import { compose, Dispatch } from 'redux';
import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import React, { PureComponent } from 'react';
import debounce from 'lodash/debounce';
import filter from 'lodash/filter';
import find from 'lodash/find';
import flatMap from 'lodash/flatMap';
import map from 'lodash/map';
import noop from 'lodash/noop';
import {
  LocalOffer, Dashboard, Search, ArcLoader as Spinner, ChevronDown,
} from '@flowio/react-icons';
import { Popover } from '@flowio/react-popover';
import { TextInput } from '@flowio/react-text-input';
import { Menu, MenuItemProps, MenuValue } from '@flowio/react-menu';
import { TagInput } from '@flowio/react-tag-input';
import { findCatalogSuggestion, clearCatalogOptions } from '../../modules/search/actions';
import { RootState } from '../../stores/types';
import { AnyAction } from '../../middlewares/types';
import { ConsoleServerSuggestions } from '../../types';
import { LiteralValue, Value } from '../../utilities/validator';

export enum QueryTargetFieldType {
  TYPE_ALL = 'all',
  TYPE_ITEMS = 'items',
  TYPE_QUERY_TARGETS = 'query-targets',
}

type DataSourceType = 'items' | 'modifiers' | 'operators' | 'queries';

type QueryTargetValue = LiteralValue | LiteralValue[] |
{ [key: string]: any } | { [key: string]: any }[] | null | undefined;

export interface DataSource {
  key?: string | number;
  suggestion: any;
  text: string;
  value?: string;
  type: DataSourceType;
  label: string;
}

interface QueryTargetFieldOwnProps {
  debounceTime?: number;
  errorText?: string;
  hintText?: string;
  defaultValue?: string | string[];
  filter?: Function;
  multiple?: boolean;
  onChange?: Function;
  style?: React.CSSProperties;
  type?: QueryTargetFieldType;
  value?: QueryTargetValue;
}

interface DispatchProps {
  onRequestSuggestions: (searchText?: QueryTargetValue) => void;
  onComponentUnmount: () => void;
}

interface StateProps {
  dataSource: DataSource[];
  type?: QueryTargetFieldType;
  value?: QueryTargetValue;
  isLoading: boolean;
}

interface QueryTargetFieldState {
  searchTextFieldValue: string;
}

class QueryTargetField extends PureComponent<
QueryTargetFieldOwnProps & StateProps & DispatchProps, QueryTargetFieldState
> {
  static displayName = 'QueryTargetField';

  static defaultProps = {
    debounceTime: 250,
    filter: () => true,
    multiple: false,
    onChange: noop,
    onRequestSuggestions: noop,
    onComponentUnmount: noop,
    type: QueryTargetFieldType.TYPE_ALL,
    value: undefined,
  };

  constructor(props: any) {
    super(props);
    this.state = {
      searchTextFieldValue: Array.isArray(props.defaultValue) ? '' : props.defaultValue,
    };
  }

  componentDidMount(): void {
    const { onRequestSuggestions, value } = this.props;
    if (onRequestSuggestions && value) {
      onRequestSuggestions(value);
    }
  }

  componentWillUnmount(): void {
    const { onComponentUnmount = noop } = this.props;
    onComponentUnmount();
  }

  handleRequestSuggestions = debounce((searchText) => {
    const { onRequestSuggestions } = this.props;
    if (onRequestSuggestions) {
      onRequestSuggestions(searchText);
    }
  }, this.props.debounceTime); // eslint-disable-line react/destructuring-assignment

  handleFocus = (): void => {
    const { onRequestSuggestions } = this.props;
    if (onRequestSuggestions) {
      onRequestSuggestions();
    }
  };

  handleUpdateInput = (searchText: string): void => {
    this.setState({ searchTextFieldValue: searchText });
    this.handleRequestSuggestions(searchText);
  };

  handleChange = (_: Event, dataSource: DataSource): void => {
    const { onChange } = this.props;

    if (onChange) {
      if (dataSource.type === 'items') {
        const updatedText = dataSource.text.split(':')[0];
        onChange({
          ...dataSource,
          text: updatedText,
        });
      } else {
        onChange(dataSource);
      }
    }
  };

  handleChangeForMultiple = (values: MenuValue): void => {
    const { onChange, dataSource: options } = this.props;
    const dataSource: (DataSource | { text: string, label: string })[] = [];
    if (Array.isArray(values)) {
      values.forEach((d) => {
        const matchedOp = options.find((o) => o.text === d);
        if (matchedOp) {
          dataSource.push(matchedOp);
        } else {
          dataSource.push({ text: d, label: d });
        }
      });

      if (onChange) {
        this.setState({ searchTextFieldValue: '' });
        onChange(dataSource);
      }
    }
  };

  handleOnRemove = (values: string[]) => {
    const { value, onChange } = this.props;
    const updatedValues: Value[] = [];
    if (value && Array.isArray(value) && onChange) {
      value.forEach((v) => {
        if (typeof v === 'object' && !values.includes(v.text)) {
          updatedValues.push(v);
        }
      });
      onChange(updatedValues);
    }
  };

  handleChangeForSingleSelect = (index: number): void => {
    const { onChange, dataSource: options } = this.props;
    const dataSource: DataSource = options[index];
    this.setState({ searchTextFieldValue: dataSource.label });

    if (onChange) {
      if (dataSource.type === 'items') {
        const updatedText = dataSource.text.split(':')[0];
        onChange({
          ...dataSource,
          text: updatedText,
        });
      } else {
        onChange(dataSource);
      }
    }
  };

  getOptionItem = (option: DataSource): MenuItemProps | null => {
    switch (option.type) {
      case 'items':
        return {
          value: option.text,
          content: (
            <span>
              {' '}
              {option.suggestion.name}
              {' '}
              <span className="muted-text">
                {' '}
                {option.suggestion.number}
              </span>
            </span>
          ),
          icon: (<Dashboard />),
        };
      case 'modifiers':
        return {
          value: option.text,
          content: (
            <span>
              {option.suggestion.label}
              :
              {' '}
              <span className="muted-text">{option.suggestion.description}</span>
            </span>
          ),
          icon: (<LocalOffer />),
        };
      case 'operators':
        return {
          value: option.text,
          content: (
            <span>
              {option.suggestion.modifier}
              :
              <span className="muted-text">{option.suggestion.property}</span>
            </span>
          ),
          icon: (<LocalOffer />),
        };
      case 'queries':
        return {
          value: option.text,
          content: (
            <span>
              {option.suggestion.label}
              :
              <span className="muted-text">{option.suggestion.count}</span>
            </span>
          ),
          icon: (<Search />),
        };
      default: return null;
    }
  };

  getChosenRequestFromSearchText(searchText: string): DataSource | undefined {
    const { dataSource } = this.props;
    return find<DataSource>(dataSource, { text: searchText });
  }

  render(): React.ReactNode {
    const {
      dataSource,
      multiple,
      value,
      isLoading,
      hintText,
    } = this.props;

    const { searchTextFieldValue } = this.state;
    const menuItems = dataSource
      .map(this.getOptionItem).filter((o) => o !== null) as MenuItemProps[];

    if (multiple) {
      const tagInputValues: string[] = Array.isArray(value) ? value.map((v) => (typeof v === 'object' ? v.label : String(v))) : [];
      const menuValues: string[] = Array.isArray(value) ? value?.map((v) => (typeof v === 'object' ? v.text : String(v))) : [];

      return (
        <Popover
          openOnFocus
          trigger={(
            <TagInput
              hintText={hintText}
              onInputValueChange={(s) => this.handleUpdateInput(s)}
              clearable
              value={tagInputValues}
              fluid
              addKeyCodes={[]}
              onFocus={this.handleFocus}
              onRemove={(r) => this.handleOnRemove(r)}
              inputValue={searchTextFieldValue}
              rightIcon={isLoading ? 'ArcLoader' : 'ChevronDown'}
            />
        )}
        >
          <Menu
            onChange={(_i, v) => this.handleChangeForMultiple(v)}
            items={menuItems.filter((m) => !menuValues.includes(String(m.value)))}
            style={{ maxHeight: 280, overflowY: 'auto' }}
            multiple
            value={menuValues}
            selectItemOnSpace={false}
            checkOnSelection={false}
          />
        </Popover>
      );
    }

    return (
      <Popover
        openOnFocus
        trigger={(
          <TextInput
            rightIcon={isLoading ? <Spinner /> : <ChevronDown />}
            hintText={hintText}
            onValueChange={(s) => this.handleUpdateInput(s)}
            clearable
            value={searchTextFieldValue}
            fluid
            onFocus={this.handleFocus}
          />
        )}
      >
        <Menu
          onChange={this.handleChangeForSingleSelect}
          items={menuItems}
          style={{ maxHeight: 280, overflowY: 'auto' }}
        />
      </Popover>
    );
  }
}

const getIsLoading = (state: RootState): boolean => state.search.loading;

const getDataSource = (state: RootState, props: QueryTargetFieldOwnProps): DataSource[] => {
  const suggestionsMap = state.search.catalogSuggestions;

  if (!suggestionsMap) {
    return [];
  }
  const dataSourceKeys = Object.keys(suggestionsMap) as (keyof ConsoleServerSuggestions)[];

  const filteredDataSourceKeys = dataSourceKeys.filter((key) => {
    switch (props.type) {
      case QueryTargetFieldType.TYPE_ITEMS: return key === 'items';
      case QueryTargetFieldType.TYPE_QUERY_TARGETS: return key !== 'items';
      default: return true;
    }
  });

  return filter<DataSource | null, DataSource>(
    flatMap<keyof ConsoleServerSuggestions, DataSource | null>(filteredDataSourceKeys, (key) => {
      const suggestions = suggestionsMap[key];

      switch (key) {
        case 'items':
          return map(suggestions as ConsoleServerSuggestions['items'], (suggestion) => ({
            type: key,
            text: `${suggestion.number}:item`,
            label: suggestion.name,
            suggestion,
          }));
        case 'modifiers':
          return map(suggestions as ConsoleServerSuggestions['modifiers'], (suggestion) => ({
            type: key,
            text: suggestion.label,
            label: `${suggestion.label}:${suggestion.description}`,
            suggestion,
          }));
        case 'operators':
          return map(suggestions as ConsoleServerSuggestions['operators'], (suggestion) => ({
            type: key,
            text: `${suggestion.modifier}:${suggestion.property}`,
            label: `${suggestion.modifier}:${suggestion.property}`,
            suggestion,
          }));
        case 'queries':
          return map(suggestions as ConsoleServerSuggestions['queries'], (suggestion) => ({
            type: key,
            text: suggestion.label,
            label: suggestion.label,
            suggestion,
          }));
        default: return null;
      }
    }),
    (dataSource): dataSource is DataSource => dataSource !== null,
  );
};

const mapStateToProps: MapStateToProps<StateProps, QueryTargetFieldOwnProps, RootState> = (
  state: RootState,
  ownProps: QueryTargetFieldOwnProps,
): StateProps => ({
  dataSource: getDataSource(state, ownProps),
  isLoading: getIsLoading(state),
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, QueryTargetFieldOwnProps> = (
  dispatch: Dispatch<AnyAction>,
  props: QueryTargetFieldOwnProps,
): DispatchProps => ({
  onComponentUnmount(): void {
    dispatch(clearCatalogOptions());
  },
  onRequestSuggestions(searchText?: QueryTargetValue): void {
    // A regular expression for splitting a query into its components. A query may
    // consist of a combination of operators (e.g. `category:jeans` where the value
    // before and after the colon are known as the modifier and property of the
    // operator, respectively) and words (e.g. `pants`) separated by whitespaces.
    // The property of operator can be separated by whitespaces if enclosed in double quotes.
    const pattern = /([^\s]+):(("[^"]*")|([^\s]+))|([^\s"]+)|("[^"]*")/gm;

    // Suggestions for dropdown with multiple values are always based on the last
    // component in the query.
    if (props.multiple) {
      // searchText sometimes comes through as an empty array so we're checking
      // for string to pattern match
      if (typeof searchText === 'string') {
        const tags = searchText.match(pattern) || [''];
        const tag = tags.pop() || '';
        // @ts-ignore conversion-revisit
        dispatch(findCatalogSuggestion(tag));
      } else {
        // @ts-ignore conversion-revisit
        dispatch(findCatalogSuggestion(''));
      }
    } else {
      // @ts-ignore conversion-revisit
      dispatch(findCatalogSuggestion(searchText));
    }
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(QueryTargetField);
