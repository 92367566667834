import React, { FunctionComponent } from 'react';
import noop from 'lodash/noop';
import classNames from 'classnames';
import FormattedDate from '../../../../../components/formatted-date';
import handleEnterKeyboardEvent from '../../../../../utilities/enterKeyPressHandler';
import * as styles from './order-summary-check.styles';

const displayName = 'DeliveryOption';

const defaultProps = {
  onClick: noop,
  selected: undefined,
};

interface DeliveryOptionProps {
  deliveryId: string;
  deliveryOption: io.flow.v0.models.DeliveryOption;
  selected: boolean;
  onClick: (...args: any) => any;
}

const DeliveryOptionComponent: FunctionComponent<DeliveryOptionProps> = ({
  deliveryId, deliveryOption, selected, onClick,
}) => {
  function handleClick(): void {
    onClick(deliveryId, deliveryOption.id);
  }

  return (
    <div
      className={classNames(styles.deliveryOption, { [styles.deliveryOptionSelected]: selected })}
      onClick={handleClick}
      onKeyPress={handleEnterKeyboardEvent(handleClick)}
      tabIndex={0}
      role="button"
    >
      <p>
        <FormattedDate value={deliveryOption.window.from} format="ddd, MMM DD" />
        &nbsp;to&nbsp;
        <FormattedDate value={deliveryOption.window.to} format="ddd, MMM DD" />
      </p>
      <p>
        {deliveryOption.tier.name}
      </p>
      <p>
        {deliveryOption.price.label}
      </p>
    </div>
  );
};

DeliveryOptionComponent.displayName = displayName;
DeliveryOptionComponent.defaultProps = defaultProps;

export default DeliveryOptionComponent;
