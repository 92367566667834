import React from 'react';
import get from 'lodash/get';
import { countries } from '@flowio/lib-reference-javascript';
import { Box } from '@flowio/react-box';
import ValueOrPlaceholder from './value-or-placeholder';
import * as styles from '../order-details/order-details.styles';
import CopyButton from '../../utilities/InfoCopyButton';

interface OrderAddressProps {
  destination: io.flow.v0.models.OrderAddress;
}

const OrderAddressComponent: React.FC<OrderAddressProps> = ({
  destination: {
    city, contact, country, postal, province, streets,
  },
}) => (
  <div className="order-details">
    <Box alignContent="center" className={styles.row}>
      <div className={styles.field}>
        <span className={styles.label}>
          Name
        </span>
        <ValueOrPlaceholder value={`${get(contact, 'name.first', '')} ${get(contact, 'name.last', '')}`} />
        <CopyButton value={`${get(contact, 'name.first', '')} ${get(contact, 'name.last', '')}`} />
      </div>

      <div className={styles.field}>
        <span className={styles.label}>
          Company
        </span>
        <ValueOrPlaceholder value={get(contact, 'company')} />
        <CopyButton value={get(contact, 'company')} />
      </div>
    </Box>

    <Box alignContent="center" className={styles.row}>
      <div className={styles.field}>
        <span className={styles.label}>
          Address Line 1
        </span>
        <ValueOrPlaceholder value={get(streets, '[0]')} />
        <CopyButton value={get(streets, '[0]')} />
      </div>

      <div className={styles.field}>
        <span className={styles.label}>
          Address Line 2
        </span>
        <ValueOrPlaceholder value={get(streets, '[1]')} />
        <CopyButton value={get(streets, '[1]')} />
      </div>
    </Box>

    <Box alignContent="center" className={styles.row}>
      <div className={styles.field}>
        <span className={styles.label}>
          Address Line 3
        </span>
        <ValueOrPlaceholder value={get(streets, '[2]')} />
        <CopyButton value={get(streets, '[2]')} />
      </div>

      <div className={styles.field}>
        <span className={styles.label}>
          Phone
        </span>
        <ValueOrPlaceholder value={get(contact, 'phone')} />
        <CopyButton value={get(contact, 'phone')} />
      </div>
    </Box>

    <Box alignContent="center" className={styles.row}>
      <div className={styles.field}>
        <span className={styles.label}>
          City/Town
        </span>
        <ValueOrPlaceholder value={city} />
        <CopyButton value={city} />
      </div>

      <div className={styles.field}>
        <span className={styles.label}>
          State/Province
        </span>
        <ValueOrPlaceholder value={province} />
        <CopyButton value={province} />
      </div>
    </Box>

    <Box alignContent="center" className={styles.row}>
      <div className={styles.field}>
        <span className={styles.label}>
          Postal Code
        </span>
        <ValueOrPlaceholder value={postal} />
        <CopyButton value={postal} />
      </div>

      <div className={styles.field}>
        <span className={styles.label}>
          Country
        </span>
        <ValueOrPlaceholder value={get(countries.find(country || ''), 'name')} />
        <CopyButton value={get(countries.find(country || ''), 'name')} />
      </div>
    </Box>
  </div>
);

OrderAddressComponent.displayName = 'OrderAddress';

export default OrderAddressComponent;
