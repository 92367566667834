import classNames from 'classnames/bind';
import React, { FunctionComponent } from 'react';
import * as styles from './step.styles';

export interface StepGroupProps {
  children?: React.ReactNode;
  className?: string;
  as?: React.ElementType;
}

const StepGroup: FunctionComponent<StepGroupProps> = ({
  as: ElementType = 'ol',
  className = '',
  children,
  ...unhandledProps
}) => (
  <ElementType {...unhandledProps} className={classNames(className, styles.stepGroup)}>
    {React.Children.map(children, (child) => (
      <>
        {child}
        {' '}
      </>
    ))}
  </ElementType>
);

StepGroup.displayName = 'StepGroup';

export default StepGroup;
