import { combineReducers } from 'redux';
import destinationCountries from './destination-countries';
import gmvByCountry from './gmv-by-country';
import gmvForm from './gmv-form';
import gmvByTimeline from './gmv-by-timeline';
import { AnalyticsState } from '../types';

export default combineReducers<AnalyticsState>({
  destinationCountries,
  gmvByCountry,
  gmvForm,
  gmvByTimeline,
});
