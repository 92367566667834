import { ShippingLabel, Invoice } from '@flowio/react-icons';
import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Banner, BannerText } from '@flowio/react-banner';
import { Box, BoxItem } from '@flowio/react-box';
import { Button, OutlineButton } from '@flowio/react-button';
import {
  Card,
  CardHeader,
  CardContent,
  CardTitle,
} from '@flowio/react-card';
import { Tag } from '@flowio/react-tag-input';
import { TextInput } from '@flowio/react-text-input';
import {
  Table,
  TableHead,
  TableRow, TableRowColumn,
  TableHeadColumn,
  TableBody,
} from '@flowio/react-table';
import classNames from 'classnames';
import * as orderReturnDialogStyles from './order-return-details-dialog.styles';

const displayName = 'OrderReturnDetailsDialog';

const propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  message: PropTypes.string,
  pdfLabel: PropTypes.string.isRequired,
  pdfInvoice: PropTypes.string.isRequired,
  customerEmail: PropTypes.string,
  userEmail: PropTypes.string,
};

interface OrderReturnFullItems {
  number: string;
  name: string;
  quantity: number;
  maxQuantity: number;
}
interface OrderReturnDetailsDialogProps {
  items: OrderReturnFullItems[];
  message?: string;
  pdfLabel?: string;
  pdfInvoice?: string;
  customerEmail?: string;
  userEmail?: string;
}

const styles = css({
  marginBottom: '1.5rem',
  borderRadius: '4px',
});

const OrderReturnDetailsDialog: React.FC<OrderReturnDetailsDialogProps> = ({
  items,
  message = '',
  pdfLabel,
  pdfInvoice,
  customerEmail = '',
  userEmail = '',
}) => (
  <div>
    {message.length > 0 && (
      <Banner css={styles}>
        <BannerText>
          {message}
        </BannerText>
      </Banner>
    )}
    <Card>
      <CardHeader dividing>
        <CardTitle>Return Label & Invoice</CardTitle>
      </CardHeader>
      <CardContent>
        <Box alignContent="center" justifyContent="between">
          <BoxItem>
            <div style={{ paddingLeft: '1rem', paddingBottom: '1rem' }}>
              <div className={orderReturnDialogStyles.iconWrapper}>
                <ShippingLabel className={orderReturnDialogStyles.labelIcon} />
              </div>
              <div className={orderReturnDialogStyles.actionWrapper}>
                <span>Return Shipping Label(s)</span>
                <div style={{ marginTop: '8px' }}>
                  <a href={pdfLabel} target="_blank" rel="noopener noreferrer">
                    <OutlineButton intent="primary" content="Download" size="small" />
                  </a>
                </div>
              </div>
            </div>
          </BoxItem>
          <BoxItem>
            <div>
              <div className={orderReturnDialogStyles.iconWrapper}>
                <Invoice className={orderReturnDialogStyles.invoiceIcon} />
              </div>
              <div className={orderReturnDialogStyles.actionWrapper}>
                <span>Return Commercial Invoice</span>
                <div style={{ textAlign: 'left', marginTop: '8px' }}>
                  <a href={pdfInvoice} target="_blank" rel="noopener noreferrer">
                    <OutlineButton intent="primary" content="Download" size="small" />
                  </a>
                </div>
              </div>
            </div>
          </BoxItem>
        </Box>
      </CardContent>
    </Card>
    <Card>
      <CardHeader dividing>
        <CardTitle style={{ display: 'inline-block', paddingRight: '0.5rem' }}>Return Items</CardTitle>
        {items.reduce((acc, item) => acc + item.quantity, 0) > 1 && (
          <Tag style={{ bottom: '2px' }} content={`${items.reduce((acc, item) => acc + item.quantity, 0)} Items`} intent="primary" />
        )}
        {items.reduce((acc, item) => acc + item.quantity, 0) === 1 && (
          <Tag style={{ bottom: '2px' }} content="1 Item" intent="primary" />
        )}
        {items.reduce((acc, item) => acc + item.quantity, 0) === 0 && (
          <div />
        )}
      </CardHeader>
      <CardContent className={orderReturnDialogStyles.itemTable}>
        <Table striped displayDensity="compact">
          <TableHead>
            <TableRow>
              <TableHeadColumn className={orderReturnDialogStyles.itemNumber}>
                Item #
              </TableHeadColumn>
              <TableHeadColumn>Item Name</TableHeadColumn>
              <TableHeadColumn>Qty (Returned / Total)</TableHeadColumn>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.number}>
                <TableRowColumn className={orderReturnDialogStyles.itemNumber}>
                  {item.number}
                </TableRowColumn>
                <TableRowColumn>
                  {item.name}
                </TableRowColumn>
                <TableRowColumn>
                  {`${item.quantity} / ${item.maxQuantity}`}
                </TableRowColumn>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
    {(customerEmail || userEmail) && false && (
      <Card>
        <CardHeader dividing>
          Email Label & Invoice
        </CardHeader>
        <CardContent>
          {customerEmail && (
            <Box alignContent="center" className={orderReturnDialogStyles.row}>
              <div className={classNames(orderReturnDialogStyles.field, 'label')}>
                Send to Customer Email
              </div>
              <div className={classNames(orderReturnDialogStyles.field, 'align-right')}>
                <span className={orderReturnDialogStyles.actionLabel}>
                  {customerEmail}
                </span>
                <Button content="Send Now" size="small" />
              </div>
            </Box>
          )}
          {userEmail && (
            <Box alignContent="center" className={orderReturnDialogStyles.row}>
              <div className={classNames(orderReturnDialogStyles.field, 'label')}>
                Send to Myself
              </div>
              <div className={classNames(orderReturnDialogStyles.field, 'align-right')}>
                <span className={orderReturnDialogStyles.actionLabel}>
                  {userEmail}
                </span>
                <Button content="Send Now" size="small" />
              </div>
            </Box>
          )}
          <Box alignContent="center" className={orderReturnDialogStyles.row}>
            <div className={classNames(orderReturnDialogStyles.field, 'label')}>
              Send to Other Email Addresses
            </div>
            <div className={classNames(orderReturnDialogStyles.field, 'align-right')}>
              <TextInput className={orderReturnDialogStyles.actionLabel} size="small" />
              <Button content="Send Now" size="small" />
            </div>
          </Box>
        </CardContent>
      </Card>
    )}
  </div>
);

OrderReturnDetailsDialog.displayName = displayName;
OrderReturnDetailsDialog.propTypes = propTypes;
OrderReturnDetailsDialog.defaultProps = {
  customerEmail: '',
  message: '',
  userEmail: '',
};

export default OrderReturnDetailsDialog;
