import { css } from '@emotion/css';
import colors from '../../../../../theme/tokens/colors';

export const cardHeaderRow = css`
  margin-bottom: 0.5rem;
`;

export const filters = css`
  & .content-area__container {
    position: relative;
  }
`;

export const backToTopButton = css`
  bottom: 1rem;
  opacity: 0;
  position: fixed;
  right: 1rem;
  transition: opacity 1s;
`;

export const backToTopButtonVisible = css`
  opacity: 1;
`;

export const maxRecordsFetched = css`
  background: ${colors.white};
  color: ${colors.steel};
  height: 48px;
  line-height: 48px;
  padding-bottom: 1rem;
  text-align: center;
`;

export const noMatchingItems = css`
  color: ${colors.steel};
  text-align: center;
`;

export const maxRecordsExportButton = css`
  position: relative;
  width: inherit;
`;

export const mediumWidth = css`
  width: 15%;
`;
